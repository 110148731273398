export const GOOGLE_STATE = 'random_string';
const GOOGLE_SCOPE = 'email profile';
const GOOGLE_RIDERECT = `${window.document.location.origin}/auth/google`;
const GOOGLE_CLIENT_ID = '753524339222-orqpi939a71a06e9juvc7mki8h0hjhro.apps.googleusercontent.com';

const query = new URLSearchParams({
  response_type: 'code',
  client_id: GOOGLE_CLIENT_ID,
  redirect_uri: GOOGLE_RIDERECT,
  state: GOOGLE_STATE,
  scope: GOOGLE_SCOPE
});

export const GOOGLE_URL = `https://accounts.google.com/o/oauth2/v2/auth?${query}`;
