import React, { useRef } from 'react';
import Icon from 'common/Icon';
import * as Styled from './StyledUpload';

const Upload = ({ handleNewFile, isDisabled }) => {
  const inputTypeFileRef = useRef();

  const handleInputWrapperClick = () => {
    inputTypeFileRef.current.click();
  };

  return (
    <Styled.UploadInputWrapper onClick={handleInputWrapperClick} isDisabled={isDisabled}>
      <Icon icon="attachment" />
      <input
        type="file"
        id="messagee-file-upload"
        accept="*"
        onChange={handleNewFile}
        ref={inputTypeFileRef}
        style={{ display: 'none' }}
        disabled={isDisabled}
      />
    </Styled.UploadInputWrapper>
  );
};

export default Upload;
