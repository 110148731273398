import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import Typography from 'components/shared/Typography';
import { backOfficeService } from 'api';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const StyledTypography = styled(Typography)`
  text-align: left;
`;

const BackofficeDeleteCompanyMemberModal = ({ closePortal, data, name, refetch }) => {
  const [loading, toggleLoading] = React.useState(false);

  const removeMember = () => {
    toggleLoading(true);

    backOfficeService
      .deleteMemberFromCompany(data.companyId, data.memberId)
      .then(() => {
        closePortal();
        refetch();
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  return (
    <TxplModal
      title="Remove company member?"
      name={name}
      size="small"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <StyledButtonsContainer>
          {loading && <Spinner />}
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={removeMember} color="white" backgroundColor="#FF5151">
            Delete
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <StyledTypography variant="medium">
        Are you sure you want to remove this member from the company?
      </StyledTypography>
    </TxplModal>
  );
};

export default withRenderPortal('delete-company-member-bo-modal')(BackofficeDeleteCompanyMemberModal);
