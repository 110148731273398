import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import filter from 'lodash/fp/filter';

import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import FormInput from 'common/form/FormInput';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { companyProfileService, talentService } from 'api';
import FormSelect from 'common/form/FormSelect';
import { profileActions, profileSelectors } from 'store/profile';
import Typography from 'components/shared/Typography';
import FormCheckbox from 'common/form/FormCheckbox';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import { selectTags } from 'store/tags/tagSelectors';
import styled from '@emotion/styled';
import CollaboratorsForm from '../Collaborators/CollaboratorsForm';
import VettingStatus from './VettingStatus';
import { Spacer, StyledDeleteButton } from './EditCreditModalStyles';

const StyledTypography = styled(Typography)`
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
`;
const StyledSubTitle = styled.div`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.35);
  width: 520px;

  @media ${pageSize.XS} {
    width: 320px;
    margin-bottom: ${props => (props.subMarginBottom ? props.tsubMarginBottom : '24px')};
    width: ${props => (props.titlewidth ? props.titlewidth : 'unset')};
  } ;
`;

const fields = initialValues => ({
  game: { initialValue: initialValues.game, validate: ['isRequired'] },
  collaborators: { initialValue: initialValues.collaborators },
  display: { initialValue: initialValues.display },
  role: {
    initialValue: {
      label: initialValues.role?.name,
      value: initialValues.role?._id
    }
  }
});

const getTags = type => createSelector([selectTags], tags => filter(tag => tag.type === type)(tags));

const transform = values => ({
  ...values,
  role: values.role.value,
  gameId: values.game._id,
  name: values.game.name
});

const steps = {
  collaborators: {
    name: 'collaborators',
    title: 'Credits',
    subTitle:
      'For existing credits, only editing collaborators is available. To make changes to your role or game\n' +
      '                            title,\n' +
      '                            claim new credits',
    index: 1,
    modalSize: 'large'
  },
  add: {
    name: 'add',
    title: 'Add new collaborator',
    index: 2,
    nextStep: 'message',
    modalSize: 'large',
    titleMB: 32
  },
  message: {
    title: 'Collaborator Added',
    nextStep: 'collaborators',
    index: 3,
    name: 'message',
    modalSize: 'small',
    titleMB: 24
  }
};

const EditCreditModal = ({ closePortal, data, name }) => {
  const { credit } = data;
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const rolesTags = useSelector(getTags('role'));
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(steps.collaborators.name);
  const [addOpen, setAddOpen] = useState(false);

  const collaboratorsRef = useRef(null);

  const onValidate = formFields => {
    const customErrors = {};

    if (!formFields.getIn(['game', 'value', 'title'])) {
      customErrors.game = 'Game is required';
    }

    if (!formFields.getIn(['role', 'value'])) {
      customErrors.role = 'Role is required';
    }

    return customErrors;
  };

  const callApi = params => {
    const service = isCompanyView ? companyProfileService.updateCredit : talentService.updateCredit;

    return service(profileId, credit._id, params).then(response => {
      dispatch(
        profileActions.updateProfileProperty({
          property: 'credits',
          updateType: 'update',
          value: response.data.data,
          profileId
        })
      );

      return response;
    });
  };

  const onSubmitSuccess = () => {
    closePortal();
  };
  const { errors, onFieldChange, onSubmit, submitting, triggerErrors, values } = useForm({
    fields: fields(credit),
    onValidate,
    callApi: data => callApi(transform(data)),
    onSuccess: onSubmitSuccess
  });

  const handleSubmit = () => {
    const { errors } = triggerErrors()(onValidate);
    if (errors?.collaborators) return;

    onSubmit();
  };

  const handleNextStep = e => {
    e.preventDefault();

    if (steps[step].index === 2) {
      collaboratorsRef.current && collaboratorsRef.current.click();
      return;
    }
    if (steps[step].index === 3) {
      handleDone();
      return;
    }

    if (steps[step].nextStep) return setStep(steps[step].nextStep);

    handleSubmit();
  };

  const setAddStep = () => {
    if (steps[step].index === 1) {
      setStep('add');
    }
  };

  const setDoneStep = () => {
    if (steps[step].index === 2) setStep('message');
  };

  const handleDone = () => {
    setStep('collaborators');
  };

  const handleCancel = () => {
    switch (steps[step].index) {
      case 1:
        closePortal();
        break;
      case 2:
        setAddOpen(false);
        setStep('collaborators');
        break;
      case 3:
        setAddOpen(true);
        setStep('add');
        break;
    }
  };

  return (
    <TxplModal
      subTitleMargin={'0 0 16px'}
      subMarginBottom
      appElement={document.getElementById('root-modal')}
      name={name}
      titlePadding="32px"
      padding={`${steps[step].index === 1 ? '0' : '56px'} 0 80px 0`}
      XSpadding="0 0 98px"
      addPaddingTop
      XSaddPaddingTop={'14px'}
      allowLine
      title={steps[step].title}
      subTitle={
        steps[step].subTitle && (
          <>
            <StyledSubTitle mb={16}>{steps[step].subTitle}</StyledSubTitle>
          </>
        )
      }
      titleMB={steps[step].titleMB}
      renderFooter={
        <>
          {steps[step].index === 1 && (
            <StyledDeleteButton
              opens="delete-credit-modal"
              closes="edit-credit"
              backgroundColor="transparent"
              border="2px solid rgba(204, 213, 255, 0.11)"
              iconColor="#FF5151"
              color="#FF5151"
              data={{ talentId: profileId, credit: credit }}
              icon="delete"
              data-testid="delete-credit"
            >
              DELETE CREDITS
            </StyledDeleteButton>
          )}
          {submitting && <Spinner />}
          {step !== 'message' && (
            <ContainedButton ml={6} mr={6} backgroundColor="secondary" onClick={handleCancel}>
              {steps[step].index === 1 ? 'Cancel' : 'Back'}
            </ContainedButton>
          )}
          <ContainedButton onClick={handleNextStep} form="editCredit" type="submit">
            {steps[step].nextStep === 'message' && 'ADD COLLABORATOR'}
            {steps[step].nextStep === 'collaborators' && 'DONE'}
            {!steps[step].nextStep && 'SAVE'}
          </ContainedButton>
        </>
      }
    >
      {steps[step].index === 1 && (
        <>
          <FormInput
            label="Official game title"
            required
            error={errors.game?.title}
            value={values.game?.title}
            onChange={option => onFieldChange('game')({ id: option._id, name: option.label })}
            mt={56}
            mb={24}
            disabled
          />

          <FormSelect
            label="Your Role"
            isDisabled
            required
            mt={56}
            mtXS={40}
            error={errors.role}
            value={values.role}
            options={rolesTags.map(curr => ({ label: curr.name, value: curr._id }))}
            onChange={option => onFieldChange('role')(option)}
          />

          <Spacer margin={'56px 0 25px'} />

          <VettingStatus vetted={credit.vetted} />

          <Spacer margin={'23px 0 20px'} />

          <FormCheckbox
            value={values.display}
            checked={values.display}
            onChange={event => onFieldChange('display')(event.target.checked)}
            text="Show this credits on the “About” page"
            textColor
          />
          <Spacer margin={'20px 0 56px'} />

          <StyledTypography variant="text" mb={24}>
            Collaborators
          </StyledTypography>
        </>
      )}

      <form onSubmit={handleNextStep} id="editCredit">
        <CollaboratorsForm
          mt={56}
          collaborators={values.collaborators}
          step={steps[step].index}
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          setAddStep={setAddStep}
          setDoneStep={setDoneStep}
          handleDone={handleDone}
          ref={collaboratorsRef}
          project={{ name: values.game.title }}
          handleChange={onFieldChange('collaborators')}
        />
        {errors.collaborators && <Typography error>{errors.collaborators}</Typography>}
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('edit-credit')(EditCreditModal);
