import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Body, Button, Content, HeadSection, Header, Tab, Tabs, Title } from './ProgressFunnelCard.styled';
import WidgetCardItem from './WidgetCardItem.component';
import { all, any, compose, equals, pluck, splitAt } from 'ramda';
import withWidgetCardLayout from '../hocs/WidgetCardLayout';

const allComplete = compose(all(equals(true)), pluck('isCompleted'));
const atLeastOneComplete = compose(any(equals(true)), pluck('isCompleted'));

/**
 * ProgressFunnelCard
 *
 * @param {*} param0
 * @returns
 */
function ProgressFunnelCard({ open, items, onClickItem, onSubmitProfile }) {
  const [basicItems, enhancedItems] = splitAt(2, items);
  const isBasicStepComplete = useMemo(() => allComplete(basicItems), [basicItems]);
  const canSubmit = useMemo(() => isBasicStepComplete && atLeastOneComplete(enhancedItems), [basicItems]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => setActiveTab(isBasicStepComplete ? 1 : 0), [open]);
  const handleTabClick = useCallback(
    e => {
      const index = parseInt(e.target.id, 0);
      if (index !== activeTab) {
        setActiveTab(index);
      }
    },
    [activeTab]
  );

  return (
    <>
      <Header activeTab={activeTab}>
        <HeadSection>
          <Title>Complete your profile</Title>
        </HeadSection>
        <Tabs>
          <Tab onClick={handleTabClick} active={activeTab === 0} id={0}>
            Basic profile
          </Tab>
          <Tab onClick={handleTabClick} active={activeTab === 1} id={1}>
            Enhanced profile
          </Tab>
        </Tabs>
      </Header>

      <Body>
        <Content data-testid="progress-funnel-card-tab-0" active={activeTab === 0}>
          {basicItems.map(item => (
            <WidgetCardItem
              key={item.id}
              id={item.id}
              isCompleted={item.isCompleted}
              iconName={item.icon}
              title={item.title}
              onClick={onClickItem}
            />
          ))}
        </Content>
        <Content data-testid="progress-funnel-card-tab-1" active={activeTab === 1}>
          {items.map(item => (
            <WidgetCardItem
              id={item.id}
              key={item.id}
              isCompleted={item.isCompleted}
              iconName={item.icon}
              title={item.title}
              onClick={onClickItem}
            />
          ))}
          <Button
            data-testid="progress-funnel-card-submit-profile"
            type="submit"
            onClick={onSubmitProfile}
            disabled={!canSubmit}
          >
            Submit Profile
          </Button>
        </Content>
      </Body>
    </>
  );
}

export default withWidgetCardLayout({ withFooter: true, animation: 'bottom-up' })(ProgressFunnelCard);
