import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';
import unset from 'lodash/fp/unset';
import update from 'lodash/fp/update';
import normalize from 'utils/normalize';
import {
  CLEAR_APPLICANTS,
  DELETE_APPLICANTS,
  UPDATE_APPLICANTS,
  UPDATE_APPLICANT_CONTRACT_STATUS,
  UPDATE_APPLICANT_STATUS
} from './applicantsActionTypes';

const initialState = {
  applicants: {},
  pagination: {
    hasMore: true,
    page: 1,
    limit: 8
  }
};

const usaveApplicantProps = {
  savedApplicant: false,
  contactedApplicant: false,
  hiredApplicant: false,
  rejectedApplicant: false
};

const savedApplicantProps = {
  savedApplicant: true,
  contactedApplicant: false,
  hiredApplicant: false,
  rejectedApplicant: false
};
const contactedApplicantProps = {
  savedApplicant: false,
  contactedApplicant: true,
  hiredApplicant: false,
  rejectedApplicant: false
};
const hiredApplicantProps = {
  savedApplicant: false,
  contactedApplicant: false,
  hiredApplicant: true,
  rejectedApplicant: false
};
const rejectedApplicantProps = {
  savedApplicant: false,
  contactedApplicant: false,
  hiredApplicant: false,
  rejectedApplicant: true,
  status: 'rejected'
};

const applicantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APPLICANTS': {
      const { applicants } = action.payload;
      const key = 'id';

      return set(`applicants`, normalize(applicants, key), state);
    }
    case UPDATE_APPLICANTS: {
      const { applicants } = action.payload;
      const key = '_id';

      return update(
        `applicants`,
        values =>
          Array.isArray(applicants)
            ? assign(values, normalize(applicants, key))
            : assign(values, { [applicants[key]]: applicants }),

        state
      );
    }
    case UPDATE_APPLICANT_STATUS: {
      const { applicantId, status } = action.payload;

      return update(
        `applicants.${applicantId}`,
        values =>
          assign(
            values,
            status === 'saved'
              ? savedApplicantProps
              : status === 'unsave'
              ? usaveApplicantProps
              : status === 'hired'
              ? hiredApplicantProps
              : status === 'rejected'
              ? rejectedApplicantProps
              : contactedApplicantProps
          ),
        state
      );
    }
    case UPDATE_APPLICANT_CONTRACT_STATUS: {
      const { applicantId, contractStatus } = action.payload;
      return update(`applicants.${applicantId}`, values => assign(values, { contractStatus: contractStatus }), state);
    }
    case DELETE_APPLICANTS: {
      const { applicant } = action.payload;
      const key = 'id';

      return update('applicants', values => unset(applicant[key] ?? applicant, values), state);
    }
    case CLEAR_APPLICANTS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default applicantsReducer;
