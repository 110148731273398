import { useState } from 'react';
import projectService from 'api/projectService';

const useProjectService = () => {
  const [loading, setLoading] = useState({ getProjectTags: false });

  const getProjectTags = async projectId => {
    try {
      setLoading({ getProjectTags: true });
      const res = await projectService.getProjectTags(projectId);
      return res.data.data;
    } finally {
      setLoading({ getProjectTags: false });
    }
  };

  return {
    getProjectTags,
    loading
  };
};

export default useProjectService;
