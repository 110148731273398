import moment from 'moment';

export const groupMessagesByDate = messages => {
  let currentDate = moment(new Date(messages[0].createdAt || messages[0].timestamp)).format('MM YY DD');
  const result = messages.reduce((newArr, message, index) => {
    const prevMessage = messages[index - 1];
    let modifiedMessage = { ...message };
    if (message.attachment && message.custom.file) {
      modifiedMessage = {
        ...modifiedMessage,
        custom: {
          ...modifiedMessage.custom,
          file: JSON.parse(modifiedMessage.custom.file)
        }
      };
    }

    if (index === 0) {
      modifiedMessage.separationDate = message.createdAt || message.timestamp;
      modifiedMessage.createDate = message.createdAt || message.timestamp;
    }

    if (prevMessage) {
      const prevMessageDate = moment(new Date(prevMessage.createdAt || prevMessage.timestamp));
      const currentMessageDate = moment(new Date(message.createdAt || message.timestamp));
      const diff = moment.duration(currentMessageDate.diff(prevMessageDate)).asMinutes();
      if (prevMessage.senderId === message.senderId && diff <= 5) {
        modifiedMessage.createDate = null;
      } else {
        modifiedMessage.createDate = message.createdAt || message.timestamp;
      }
    }

    const messageDate = moment(new Date(message.createdAt || message.timestamp)).format('MM YY DD');
    if (messageDate === currentDate && index !== 0) {
      modifiedMessage.separationDate = null;
    }

    if (messageDate !== currentDate) {
      modifiedMessage.separationDate = message.createdAt || message.timestamp;
      currentDate = messageDate;
    }
    newArr = [...newArr, modifiedMessage];
    return newArr;
  }, []);
  return result;
};
