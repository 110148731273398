import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import React from 'react';
import isString from 'lodash/fp/isString';
import GameNotFoundTooltip from 'common/GameNotFoundTooltip';
import styled from '@emotion/styled';
import Icon from 'common/Icon';
import LimitedTypography from 'components/shared/LimitedTypography';
import { buildCoverUrl } from 'utils';
const CreditImage = styled.div`
  position: relative;
  width: 62px;
  height: 90px;
  border-radius: 8px;
  background-color: #1a1b1e;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledGameNotFoundTooltip = styled(GameNotFoundTooltip)`
  top: -2px;
  right: -2px;
`;

const UserCredits = ({ credits, limit }) => {
  if (!credits?.length) {
    return null;
  }

  const limited = credits.slice(0, limit ?? credits.length);

  return (
    <Flex column gap={16}>
      <Typography variant="caption" color="rgba(230, 234, 255, 0.35)">
        top matching credits &nbsp;
      </Typography>
      <Flex className="wrap" gap={16}>
        {limited.map(({ game, gameCover, gameId, gameTitle }) => (
          <Flex column gap={8} key={game?._id ?? gameId} width={62}>
            {isString(game?.cover ?? gameCover) && <CreditImage src={buildCoverUrl(game?.cover ?? gameCover)} />}
            {!isString(game?.cover ?? gameCover) && (
              <CreditImage>
                <Icon icon="blank-image" size={22} />
                <StyledGameNotFoundTooltip />
              </CreditImage>
            )}
            <LimitedTypography
              variant="tiny"
              color="rgba(230, 234, 255, 0.6)"
              limit={15}
              tooltipId={`tooltip-for-credit-${game?._id ?? gameId}`}
            >
              {game?.title ?? gameTitle}
            </LimitedTypography>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default UserCredits;
