import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const Dot = styled.div`
  background-color: white;
  border-radius: 50%;
  height: 6px;
  width: 6px;
`;

export const Line = styled.div`
  background-color: white;
  border-radius: 6px;
  height: 6px;
  width: 33px;
`;
