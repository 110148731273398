import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';

const StyledSliderArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-48px);
  height: calc(100% - 10px);
  z-index: 5;
  width: 88px;
  cursor: pointer;
  &.disabled {
    display: none;
  }
  &.arrow-right {
    ${props =>
      props.modified
        ? `left: 50px;
               top: 578px;
               background: rgba(204, 212, 255, 0.11);
               width: 48px;
               height: 40px;`
        : `right: -22px;
               background: linear-gradient(270deg, #111112 35.97%, rgba(17, 17, 18, 0) 100%);`}
    ${props => props.isXS && 'display: none'}
  }
  &.arrow-left {
    ${props =>
      props.modified
        ? `left: 0;
               top: 578px;
               background: rgba(204, 212, 255, 0.11);
               width: 48px;
               height: 40px;`
        : `left: -22px;
               background: linear-gradient(90deg, #111112 35.97%, rgba(17, 17, 18, 0) 100%);`}
    ${props => props.isXS && 'display: none'}
  }
  &.forward,
  &.back {
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    background: rgba(17, 17, 18, 0.8);
    border-radius: 50%;
  }
  &.forward:hover,
  &.back:hover {
    background: #ffffff;
    .icon {
      path {
        fill: #111112;
      }
    }
  }
  &.forward {
    right: 24px;
  }
  &.back {
    left: 24px;
  }
`;

const SliderArrow = ({ className, icon, isXS, modified, onClick }) => (
  <StyledSliderArrow
    className={`${icon} ${className.includes('disabled') && 'disabled'}`}
    onClick={onClick}
    modified={modified}
    isXS={isXS}
  >
    <Icon className="icon" icon={icon} />
  </StyledSliderArrow>
);

export default SliderArrow;
