import styled from '@emotion/styled';
import React from 'react';

import commonStyles from 'utils/common-styles';

const Wrapper = styled.div`
  ${commonStyles.ui_heading_3}
`;

const BackofficeActionHeader = ({ children }) => <Wrapper>{children}</Wrapper>;

export default BackofficeActionHeader;
