import React, { useEffect, useState } from 'react';
import { LifeContainer } from './StyledLife';
import { getCategories } from '../../../api/categoryServices';
import LifePageBar from './LifePageBar/LifePageBar';
import LifeFrames from './LifeFrames/LifeFrames';
import LifePagesEditModal from './LifePagesEditModal/LifePagesEditModal';
import LifeTextModal from './LifeBlockModals/LifeTextModal';
import { profileSelectors } from 'store/profile';
import { useSelector } from 'react-redux';
import LifeBlocks from './LifeBlocks/LifeBlocks';
import LifeTextMediaModal from './LifeBlockModals/LifeTextMediaModal';
import LifePhotoGalleryModal from './LifeBlockModals/LifePhotoGalleryModal';
import first from 'lodash/fp/first';
import get from 'lodash/fp/get';
import kebabCase from 'lodash/fp/kebabCase';

const Life = ({ categories, jobsPage }) => {
  const [listOfCategories, setListOfCategories] = useState([]);
  const [activeButton, setActiveButton] = useState(kebabCase(get('name', first(categories))) ?? '');
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const selectedCategory = listOfCategories?.find(category => kebabCase(category.name) === activeButton);
  const lives = useSelector(profileSelectors.selectProfileProperty('lives'));
  const categoryLives = lives?.filter(life => life.categoryId === selectedCategory?._id);

  useEffect(() => {
    if (!jobsPage) {
      getCategories(companyId, 'life').then(res => {
        setListOfCategories(res.data.data);
        res.data.data && setActiveButton(kebabCase(res.data.data[0]?.name));
      });
    }
  }, []);

  useEffect(() => {
    setListOfCategories(categories);
    setActiveButton(kebabCase(get('name', first(categories))) ?? '');
  }, [categories]);

  return (
    <>
      <LifeTextModal />
      <LifeTextMediaModal />
      <LifePhotoGalleryModal />
      <LifeContainer>
        {listOfCategories && (
          <LifePageBar
            listOfCategories={listOfCategories}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            jobsPage={jobsPage}
          />
        )}
        {categoryLives?.length ? (
          <LifeBlocks jobsPage={jobsPage} lives={categoryLives} categoryId={selectedCategory._id} />
        ) : (
          <LifeFrames
            jobsPage={jobsPage}
            listOfCategories={listOfCategories}
            activeButton={activeButton}
            categoryId={selectedCategory?._id}
          />
        )}
        {!jobsPage && (
          <LifePagesEditModal
            listOfCategories={listOfCategories}
            setListOfCategories={setListOfCategories}
            setActiveButton={setActiveButton}
          />
        )}
      </LifeContainer>
    </>
  );
};

export default Life;
