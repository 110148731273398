import jobService from 'api/jobService';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions, jobsSelectors } from 'store/jobs';
import values from 'lodash/fp/values';

function usePostedJobs() {
  const postedJobs = useSelector(jobsSelectors.selectJobs('postedJobs'));
  const postedJobsPagination = useSelector(jobsSelectors.selectJobsPagination('postedJobs'));
  const { hasMore, limit: posteJobsLimit, totalJobs } = postedJobsPagination;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getPostedJobs = useCallback(
    async ({ profileId, skip = 0, limit = 8, callback = () => ({}) }) => {
      if (!isLoading && profileId !== undefined && hasMore) {
        try {
          setIsLoading(true);
          const res = await jobService.getMyJobs(profileId, 'published', skip, limit);
          const { data, pagination } = res.data;

          dispatch(
            jobActions.updateJob({
              jobType: 'postedJobs',
              profileId,
              jobs: data
            })
          );
          dispatch(
            jobActions.updateJobPagination({
              jobType: 'postedJobs',
              profileId,
              pagination
            })
          );
        } catch (e) {
          console.log(e);
          dispatch(
            jobActions.updateJobPagination({
              jobType: 'postedJobs',
              profileId,
              pagination: {
                hasMore: false,
                limit
              }
            })
          );
        } finally {
          callback && callback();
          setIsLoading(false);
        }
      }
    },
    [dispatch, hasMore, isLoading]
  );

  const updatePostedJobsOrder = useCallback(async ({ jobs, profileId }) => {
    try {
      await jobService.updatePostedJobsOrder(profileId, { jobs });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const showFirstEightJob = ({ profileId }) => {
    dispatch(
      jobActions.setJobs({
        jobType: 'postedJobs',
        profileId,
        jobs: values(postedJobs).slice(0, posteJobsLimit)
      })
    );
    dispatch(
      jobActions.updateJobPagination({
        jobType: 'postedJobs',
        profileId,
        pagination: {
          hasMore: true
        }
      })
    );
  };

  return {
    postedJobs,
    loadingPostedJobs: isLoading,
    hasMorePostedJobs: hasMore,
    postedJobsPagination,
    totalJobs,
    getPostedJobs,
    updatePostedJobsOrder,
    showFirstEightJob
  };
}

export default usePostedJobs;
