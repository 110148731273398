import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';

const Route = ({ component: Component, padLayout, ...rest }) => (
  <ReactRoute
    {...rest}
    render={props => {
      if (padLayout) {
        return <Component {...props} />;
      }

      return <Component {...props} />;
    }}
  />
);

export default Route;
