import React from 'react';
import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import FormInput from 'common/form/FormInput';

const Wrapper = styled.div``;

const Title = styled.div`
  ${commonStyles.ui_heading_3}
`;

const HintText = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(230, 234, 255, 0.35);
`;

const isPositiveInteger = str => parseInt(str).toString() === str && parseInt(str) >= 0;

const isEmptyInput = str => str === '';

const isValidIntegerInput = str => isPositiveInteger(str) || isEmptyInput(str);

const AboutExperienceInput = props => {
  const { autofocus, error, hintText, onChange, title, value } = props;
  return (
    <Wrapper>
      <Title>{title}</Title>
      <HintText>{hintText}</HintText>
      <FormInput
        value={value || ''}
        onChange={e => isValidIntegerInput(e.target.value) && onChange(e)}
        autoFocus={autofocus}
        error={error}
        style={{ width: '50px' }}
      />
    </Wrapper>
  );
};

export default AboutExperienceInput;
