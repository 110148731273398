import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';
import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { pageSize } from 'utils/devices';
import getDisciplinesIconAndColor from 'utils/disciplines';
import { profileSelectors } from '../../../store/profile';
import AllAdditionalTags from '../AllAdditionalTags/AllAdditionalTags';
import ServiceOverview from './ServiceOverview';

const StyledContainer = styled.div`
  ${props => props.mode !== 'add' && 'padding-top: 32px;'}
  ${props => props.mode !== 'add' && 'border-top: 2px solid rgba(204, 213, 255, 0.11);'}
  margin-bottom: 80px;
`;
const StyledTitleContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 32px auto 80px;
  grid-column-gap: 16px;
  align-items: center;
`;
const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 32px !important;
`;
const StyledCompanyServiceFormFooter = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(204, 213, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
  justify-content: end;
`;
const CompanyServiceFromFooterActionButtons = styled.div`
  display: flex;
  align-items: center;
`;
const StyledIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => `background: ${props.backgroundColor}`}
`;
const StyledTypography = styled(Typography)`
  width: 85%;
  @media ${pageSize.XS} {
    width: 65%;
  }
`;

const CompanyServiceForm = ({
  dragHandleProps,
  mode,
  onCancel,
  onFieldChange,
  onSubmit,
  showDragIcon,
  submitting,
  values
}) => {
  const { customName, service, serviceOverview } = values;
  const { color, icon } = getDisciplinesIconAndColor(service?.name);
  const hasProfileEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const onTagAdd = ({ data, type }) => {
    onFieldChange(type)(data);
  };

  const onServiceEdit = data => {
    onFieldChange('service')(data.service);
    onFieldChange('customName')(data.customName);
  };

  return (
    <StyledContainer mode={mode}>
      <StyledTitleContainer>
        <StyledIcon backgroundColor={color}>
          <Icon size={16} icon={icon} />
        </StyledIcon>

        <StyledTypography wordBreak="break-all" variant="h2">
          {customName ?? service?.name} services
        </StyledTypography>
        <Flex className="nowrap" gap={6}>
          {dragHandleProps && showDragIcon && mode === 'edit' && (
            <ContainedButton {...dragHandleProps} backgroundColor="secondary" icon={'drag-i'} />
          )}

          <ContainedButton
            icon="edit"
            backgroundColor="secondary"
            opens="create-service-modal"
            data={{
              service,
              customName,
              onEdit: onServiceEdit
            }}
          />
        </Flex>
      </StyledTitleContainer>

      <ServiceOverview
        wordBreak="break-all"
        serviceOverview={serviceOverview}
        onInputChange={value => onFieldChange('serviceOverview')(value)}
        tabindex="0"
      />

      <StyledAllAdditionalTags
        data={values}
        onAdd={onTagAdd}
        hasDragPermission={hasProfileEditPermission}
        order={['skills', 'platforms', 'tools', 'genres', 'tags']}
        callService={false}
        afterDrag={(type, ids) => onFieldChange(type)(ids)}
      />

      {mode !== 'view' && (
        <StyledCompanyServiceFormFooter>
          <CompanyServiceFromFooterActionButtons>
            {submitting && <Spinner />}
            <ContainedButton backgroundColor="secondary" onClick={onCancel} ml={6} mr={6}>
              Cancel
            </ContainedButton>
            <ContainedButton onClick={() => onSubmit()}>Save</ContainedButton>
          </CompanyServiceFromFooterActionButtons>
        </StyledCompanyServiceFormFooter>
      )}
    </StyledContainer>
  );
};

export default CompanyServiceForm;
