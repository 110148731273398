import styled from '@emotion/styled';
import TxplModal from 'common/TxplModal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const StyledTpxlModal = styled(TxplModal)`
  ${props => props.notXS && 'padding: 44px 40px 40px 40px !important;'}
  max-width: 918px !important;
  width: 918px !important;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(204, 212, 255, 0.11);
  padding-bottom: 44px;
`;

export const HeaderXS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(204, 212, 255, 0.11);
  padding: 16px;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  word-break: break-all;
  margin-right: 8px;
  ${props => (props.isXS ? 'font-size: 14px;' : 'font-size: 24px;')}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(204, 212, 255, 0.11);

  &:not(:last-child) {
    margin-right: 10px;
  }
  ${props => props.isXS && 'width: 48px;'}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 463px;
  ${props => props.notXS && 'border-radius: 12px;'}
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const ImageListWrapper = styled.div`
  width: 100%;
`;

export const ImageListContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageItem = styled.div`
  cursor: pointer;
  width: 96px !important;
  height: 72px;
  ${props => props.notXS && 'border-radius: 12px;'}
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s background ease;
  }

  &.selected:after,
  &:hover:after {
    background: transparent;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledSlider = styled(Slider)`
  .slick-list {
    ${props => (props.isXS ? 'padding: 66px 0 90px;' : 'padding: 32px 0 32px; margin-bottom: 72px;')}
  }
`;

export const StyledNavSlider = styled(Slider)`
  .slick-track {
    display: flex;
    height: 72px;
  }
`;

export const GoBackIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  background-color: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
  margin-right: 8px;
`;
