import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { portalActions } from 'store/portals';
import commonStyles from 'utils/common-styles';

import EditButton from 'common/EditButton';

import { pageSize } from 'utils/devices';
import { profileSelectors } from 'store/profile';
import AboutExperienceItem from './AboutExperienceItem';

const Wrapper = styled.div`
  position: relative;
  border-top: 2px solid rgba(204, 213, 255, 0.11);
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  @media ${pageSize.S} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${pageSize.L} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const Title = styled.div`
  ${commonStyles.card_label}
  padding: 32px 0;
  font-weight: 700 !important;
  ${props => props.titlePadding && 'padding: 24px 0'}
`;
const StyledEditButton = styled(EditButton)`
  position: absolute;
  top: 16px;
  right: 10px;
`;

const AboutExperience = ({ aaaGames, canEdit, games, indieGames, years, aaGames, mobileGames }) => {
  const dispatch = useDispatch();
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);

  const openExperienceModal = focusField => () => {
    dispatch(
      portalActions.openPortal({
        name: 'about-experience-modal',
        data: {
          focusField
        }
      })
    );
  };

  const isAdd = React.useMemo(
    () => !years && !games && !aaaGames && !indieGames && !aaGames && !mobileGames,
    [years, games, aaaGames, indieGames, aaGames, mobileGames]
  );

  return (
    <Wrapper>
      <Title titlePadding={isCompanyView}>AT A GLANCE</Title>
      {canEdit && <StyledEditButton iconName={isAdd ? 'add' : 'edit'} onClick={openExperienceModal('years')} />}
      <ItemsWrapper>
        {years || canEdit ? (
          <AboutExperienceItem
            type="years"
            value={years}
            isAddMode={!years}
            canEdit={canEdit}
            onEdit={openExperienceModal('years')}
          />
        ) : (
          <></>
        )}

        {games || canEdit ? (
          <AboutExperienceItem
            type="games"
            value={games}
            isAddMode={!games}
            canEdit={canEdit}
            onEdit={openExperienceModal('games')}
          />
        ) : (
          <></>
        )}

        {aaaGames || canEdit ? (
          <AboutExperienceItem
            type="aaaGames"
            value={aaaGames}
            isAddMode={!aaaGames}
            canEdit={canEdit}
            onEdit={openExperienceModal('aaaGames')}
          />
        ) : (
          <></>
        )}
        {aaGames || canEdit ? (
          <AboutExperienceItem
            type="aaGames"
            value={aaGames}
            isAddMode={!aaGames}
            canEdit={canEdit}
            onEdit={openExperienceModal('aaGames')}
          />
        ) : (
          <></>
        )}
        {indieGames || canEdit ? (
          <AboutExperienceItem
            type="indieGames"
            value={indieGames}
            isAddMode={!indieGames}
            canEdit={canEdit}
            onEdit={openExperienceModal('indieGames')}
          />
        ) : (
          <></>
        )}
        {mobileGames || canEdit ? (
          <AboutExperienceItem
            type="mobileGames"
            value={mobileGames}
            isAddMode={!mobileGames}
            canEdit={canEdit}
            onEdit={openExperienceModal('mobileGames')}
          />
        ) : (
          <></>
        )}
      </ItemsWrapper>
    </Wrapper>
  );
};

export default AboutExperience;
