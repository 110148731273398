import Api from './api';

const api = new Api('users');

const acceptInvitation = params => api.post('/accept-invitation', params);

const changeEmail = email => api.post('/change-email', email);

const validateChangeEmail = params => api.post('/change-email/confirm', params);

const resetPassword = params => api.put('/reset-password', params);

const forgotPassword = body => api.post('/forgot-password', body);

const me = () => api.get('/me');

const getTeamUsers = teamId => api.get(`/team/${teamId}`);

const contactSupport = data => api.post('/contact-support', data);

const changePublicUrl = data => api.post('/change-public-url', data);

const deleteAccount = () => api.delete('/delete-account');

const updateProfile = (userId, data) => api.put(`/${userId}/profile`, data);

const switchProfile = profileId =>
  api({
    url: '/active-profile',
    method: 'PUT',
    data: {
      profileId
    }
  });

const requestVerification = () => api.post('/request-verification');

const currentVerification = () => api.get('/current-verification');

const searchUserByEmail = email => api.get('/', { params: { email } });

const checkUserEmail = email => api.get('/email', { params: { email } });

const lockProfile = profileId => api.put(`/lock-profile/${profileId}`);

const removeWelcomeMessage = data => api.put('/remove-welcomeMessage', data);

const setSettings = data => api.put('/settings', data);

const passwordResetLinkSend = data => api.post('/forgot-password', data);

const agreeTermsOfService = data => api.put('/agree-terms-of-service', data);

export default {
  acceptInvitation,
  resetPassword,
  changeEmail,
  validateChangeEmail,
  me,
  getTeamUsers,
  contactSupport,
  changePublicUrl,
  deleteAccount,
  forgotPassword,
  requestVerification,
  currentVerification,
  searchUserByEmail,
  updateProfile,
  switchProfile,
  checkUserEmail,
  lockProfile,
  removeWelcomeMessage,
  setSettings,
  passwordResetLinkSend,
  agreeTermsOfService
};
