import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'store/auth';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import { pageSize } from 'utils/devices';
import UserCardModal from 'components/UserCardModal';
import GalleryMenu from 'pages/talent/GalleryMenu/GalleryMenu';
import ScrollToMe from 'common/ScrollToMe';
import TalentGallery from 'pages/talent/TalentGallery';
import TalentAbout from 'pages/talent/about/TalentAbout';
import CompanyServices from 'pages/talent/CompanyServices';
import TalentMediaCoverage from 'pages/talent/MediaCoverage/TalentMediaCoverage';
import TalentExperience from 'pages/talent/TalentExperience/TalentExperience';
import UserCardWrapper from 'pages/talent/UserCardWrapper';
import CompanyProjects from 'pages/talent/CompanyProjects';
import Jobs from 'pages/talent/Jobs';
import Life from 'pages/talent/Life';
import PageLoader from 'common/PageLoader';
import { portalActions, portalSelectors } from 'store/portals';
import StickyJoinBanner from 'components/StickyJoinBanner/StickyJoinBanner.component';
import TalentInfoCover from './TalentInfoCover';
import Footer from '../../pages/Footer';
import QuickOnboardingModal from 'components/modals/QuickOnboardingModal/QuickOnboardingModal';
import ProgressFunnelWidget from 'components/Widgets/ProgressFunnelWidget/ProgressFunnelWidget.component';
import useFeatureFlags from 'hooks/useFeatureFlags';
import VettingResultModal from 'components/modals/VettingResultModal/VettingResultModal';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import { profileSelectors } from 'store/profile';
import ReadyToSubmitModal from 'components/modals/ReadyToSubmitModal';
import VerificationSubmittedModal from 'components/modals/VerificationSubmittedModal/VerificationSubmittedModal';
import JoinEventsNowModal from 'components/modals/JoinEventsNowModal/JoinEventsNowModal';
import { missingBasicInfo, showJoinEventsModal } from 'utils/validateProfileData';
import CompanyContractModal from 'pages/talent/Jobs/Applicants/CompanyContractModal';
import AdminContractModal from 'pages/talent/Jobs/Applicants/AdminContractModal';

const StyledContentContainer = styled.div`
  @media ${pageSize.XL}, ${pageSize.L}, ${pageSize.M} {
    max-width: 1240px;
    margin: 0px auto;
  }
`;
const StyledTabContentWrapper = styled.div`
  padding: 0 16px 0;
  @media ${pageSize.S} {
    padding: 0 81px;
  }
  @media ${pageSize.M} {
    padding: 0 58px;
    padding-left: 410px;
  }
  @media ${pageSize.L} {
    padding-left: 356px;
  }
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 16px;
  z-index: 99;
  width: calc(100vw - 32px);

  @media ${pageSize.XS} {
    width: calc(100vw - 32px);
  }
  @media ${pageSize.S} {
    width: calc(100vw - 164px);
  }
  @media (min-width: 1150px) and (max-width: 1250px) {
    width: calc(100vw - 472px);
  }
  @media (min-width: 1251px) and (max-width: 1349px) {
    width: 772px;
  }
  @media ${pageSize.L} {
    width: 868px;
  }
`;

const getBannerTitle = () => {
  if (window.location.href.endsWith('credits')) {
    return 'Claim your credits now';
  }
  return 'Get your own profile now';
};

const getBannerUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const joinUrl = new URL(`${process.env.REACT_APP_NEXT_BASE_URL}/onboarding`);
  joinUrl.search = queryParams.toString();

  return joinUrl.toString();
};

// validate if quickonboarding is needed
const showQuickonboarding = (profile, isCollaborator) => {
  return profile && profile.type === 'talent' && profile?.basicInfo && !isCollaborator && missingBasicInfo(profile);
};

const TalentInfoPageLayout = ({ isProfileLoaded, path, pathname, profile }) => {
  const history = useHistory();
  const { search, hash } = useLocation();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const { enableProgressFunnelWidget } = useFeatureFlags();
  const isTermsOfServiceModalOpened = useSelector(portalSelectors.isPortalOpenedSelector('terms-of-service-modal'));

  const isCompany = useSelector(profileSelectors.selectIsMyProfileCompany);
  const { vetted, userId } = profile || { vetted: false, userId: '' };
  const authUserId = useSelector(selectCurrentUserProperty('_id'));
  const isAuthUserEqualProfileUser = userId === authUserId;
  const hasVettedMessageRead = useSelector(selectCurrentUserProperty('settings'))?.hasVettedMessageRead;
  const hasJoinEventMessageRead = useSelector(selectCurrentUserProperty('settings'))?.hasJoinEventMessageRead;
  const verificationStatus = useSelector(selectCurrentUserProperty('verificationStatus'));

  const shouldShowVettedMessage = useMemo(
    () => vetted && !hasVettedMessageRead && isAuthUserEqualProfileUser,
    [vetted, hasVettedMessageRead, isAuthUserEqualProfileUser]
  );
  const selectedTab = useMemo(() => pathname.split('/').slice(-1)[0], [pathname]);
  const isAuthed = useSelector(authSelectors.isAuthed);
  const isCollaborator = useSelector(currentUserPropertySelector('isCollaborator'));
  const isQuickOnboarding = useMemo(
    () => showQuickonboarding(profile, isCollaborator) && isAuthUserEqualProfileUser,
    [profile, isCollaborator]
  );
  const shouldShowProgressFunnel = enableProgressFunnelWidget && isAuthed && isAuthUserEqualProfileUser && !isCompany;
  const shouldOpenProgressFunnel = params?.get('open-progress-funnel') === 'true';
  const shouldShowJoinEventsModal = useMemo(
    () => showJoinEventsModal({ profile, isCollaborator, verificationStatus, hasJoinEventMessageRead }),
    [profile, isCollaborator, verificationStatus]
  );

  useEffect(() => {
    if (shouldShowJoinEventsModal) {
      setTimeout(() => dispatch(portalActions.openPortal({ name: 'join-events-now-modal' })), [100]);
    }
  }, [shouldShowJoinEventsModal]);

  useEffect(() => {
    if (pathname.endsWith('/about') && isQuickOnboarding && isAuthed) {
      setTimeout(() => dispatch(portalActions.openPortal({ name: 'quick-onboarding-modal' }), [100]));
    }
  }, [isQuickOnboarding]);

  useEffect(() => {
    if (params.get('open-profile-details') === 'true') {
      setTimeout(() => dispatch(portalActions.openPortal({ name: 'edit-talent-modal' })), [100]);
    }

    if (pathname.endsWith('/')) {
      history.push(pathname.slice(0, -1));
    }
  }, []);

  useEffect(() => {
    if (shouldShowVettedMessage && !isTermsOfServiceModalOpened) {
      const timeout = setTimeout(() => {
        dispatch(portalActions.openPortal({ name: 'vetting-result-modal' }));
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [shouldShowVettedMessage, isTermsOfServiceModalOpened]);

  return (
    <>
      <UserCardModal />
      {!isCompany && <QuickOnboardingModal />}
      <JoinEventsNowModal />
      <ReadyToSubmitModal />
      <VettingResultModal />
      <VerificationSubmittedModal />
      {!isProfileLoaded && <PageLoader />}
      {isProfileLoaded && (
        <>
          <TalentInfoCover />
          <StyledContentContainer>
            <UserCardWrapper />

            <StyledTabContentWrapper className="rg">
              {!hash && <ScrollToMe offsetY={50} />}
              <GalleryMenu selectedTab={selectedTab} pathname={pathname} />
              <Switch>
                <Route exact path={`${path}/about`} component={TalentAbout} />
                <Route exact path={`${path}/portfolio`} component={TalentGallery} />
                <Route exact path={`${path}/services`} component={CompanyServices} />
                <Route exact path={`${path}/projects`} component={CompanyProjects} />
                <Route exact path={`${path}/media-coverage`} component={TalentMediaCoverage} />
                <Route exact path={`${path}/experience`} component={TalentExperience} />
                <Route exact path={`${path}/jobs`} component={Jobs} />
                <Route exact path={`${path}/life`} component={Life} />
              </Switch>
              {!isAuthed && (
                <FixedFooter>
                  <StickyJoinBanner title={getBannerTitle()} buttonTitle="Join early access" url={getBannerUrl()} />
                </FixedFooter>
              )}
            </StyledTabContentWrapper>
          </StyledContentContainer>
          {shouldShowProgressFunnel && (
            <ProgressFunnelWidget dataTestId="progress-funnel-widget" opened={shouldOpenProgressFunnel} />
          )}
          <Footer />
          <div id="talent-modal-placeholder" />
        </>
      )}
      <CompanyContractModal />
      <AdminContractModal />
    </>
  );
};

export default TalentInfoPageLayout;
