import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import * as Styled from './StyledMessageList';

import useChatConversationService from 'hooks/chatApi/useChatConversation';

import { groupMessagesByDate } from '../groupMessagesByDate';
import values from 'lodash/fp/values';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import Icon from 'common/Icon';
import ImageGalleryModal from '../Upload/ImageGalleryModal';
import MessageItem from '../MessageItem/MessageItem';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Flex from 'common/styles/Flex';

const LIMIT = 20;

const defaultMessages = [
  {
    id: 0,
    text: 'To protect your proposal, always communicate through TheXPlace'
  },
  {
    id: 1,
    text: 'TheXPlace is not responsible for any misuse of information'
  }
];

const MessageList = ({ selectedConversation }) => {
  const dispatch = useDispatch();
  const conversationId = typeof selectedConversation === 'string' ? selectedConversation : null;

  const messageContainer = useRef();

  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const conversations = useSelector(profileSelectors.selectMyProfileProperty('conversations'));
  const conversation = conversationId ? get(`${conversationId}`, conversations) : null;
  const messages = useMemo(
    () => (conversation && conversation.messages ? conversation.messages : []),
    [conversation?.messages]
  );
  const typing = conversation?.typing?.filter(id => id !== profileId);

  const { getMessages, markConversation } = useChatConversationService();

  const [messageList, setMessageList] = useState([]);
  const [lastMessageId, setLastMessageId] = useState('');
  const [hasMore, setHasMore] = useState(true);

  const lastMessageWithCreatedDate = messageList.reduce((lastId, message) => {
    if (message.senderId === profileId && message.createDate) {
      lastId = message.id;
    }
    return lastId;
  }, '');

  const fetchMore = async () => {
    if (conversationId && lastMessageId) {
      const data = await getMessages(conversationId, lastMessageId, LIMIT, () => setHasMore(false));
      if (data) {
        const sortedData = data.reverse();
        const updatedMessages = [...sortedData, ...messages];
        dispatch(
          profileActions.updateProfileProperty({
            property: `conversations.${conversationId}.messages`,
            value: updatedMessages,
            profileId
          })
        );
      }
    }
  };

  const loadMore = () => {
    const lastMessageId = messages[0].id;
    setLastMessageId(lastMessageId);
  };

  const scrollToBottom = () => {
    const scrollHeight = messageContainer.current.scrollHeight;
    messageContainer.current.scrollTop = scrollHeight;
  };

  const getTypingIndicatorText = () => {
    const participants = values(get('custom.participants', conversation));

    if (!typing || !typing?.length) {
      return;
    }

    if (typing?.length === 1 && !!participants.length) {
      const user = participants.find(({ id }) => id === typing[0]);
      return user.name + ' is typing';
    }

    return 'Multiple people is typing';
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const data = await getMessages(conversationId, '', LIMIT, () => {});
      if (data) {
        dispatch(
          profileActions.updateProfileProperty({
            property: `conversations.${conversationId}.messages`,
            value: data.reverse(),
            profileId
          })
        );
      }
    };
    if (conversationId && messages.length === 0) {
      fetchMessages();
    }
  }, [conversationId]);

  useEffect(() => {
    let timeout = null;
    if (typing?.length) {
      timeout = setTimeout(() => {
        dispatch(
          profileActions.updateProfileProperty({
            updateType: 'update',
            property: 'conversations',
            profileId: profileId,
            updateKey: 'id',
            value: {
              id: conversationId,
              typing: []
            }
          })
        );
      }, [1000]);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [typing]);

  const markConversationAsMark = useCallback(() => {
    const { offsetHeight, scrollHeight, scrollTop } = messageContainer.current;
    const positionInScroll = Math.floor(scrollTop + offsetHeight);

    if (positionInScroll === scrollHeight && conversationId && conversation?.custom?.unreadMessages) {
      const participantsProfileIds = keys(conversation.participants);
      const messageIds = conversation.messages.reduce((curr, message) => {
        if (message.senderId !== profileId) {
          curr.push(message.id);
        }
        return curr;
      }, []);
      markConversation({ conversationId, participantsProfileIds, messageIds });
    }
  }, [conversation, conversationId, markConversation, profileId]);

  useScrollPosition(
    () => {
      markConversationAsMark();
    },
    [markConversationAsMark],
    false,
    false,
    150,
    messageContainer
  );

  useEffect(() => {
    if (messageContainer && !!conversation?.messages?.length) {
      markConversationAsMark();
    }
  }, [messageContainer.current, conversationId, markConversationAsMark]);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  useEffect(() => {
    if (messageContainer && typing?.length !== 0) {
      const { offsetHeight, scrollHeight, scrollTop } = messageContainer.current;
      if (scrollTop + offsetHeight + 34 >= scrollHeight) {
        scrollToBottom();
      }
    }
  }, [typing]);

  useEffect(() => {
    if (messages.length > 0) {
      const messagesArray = groupMessagesByDate(messages);
      setMessageList(messagesArray);
    }
  }, [conversation?.messages]);

  useEffect(() => {
    if (hasMore) fetchMore();
  }, [lastMessageId]);

  return (
    <Styled.Container ref={messageContainer}>
      <ImageGalleryModal conversationId={conversationId} />
      {!conversationId && <></>}
      {conversationId && messages && messages.length > 0 && (
        <Styled.MessageListWrapper pageStart={0} loadMore={loadMore} hasMore={hasMore} isReverse>
          <Flex column minHeight={'100%'}>
            <Flex column flexGrow={1}>
              {conversation?.custom?.job && (
                <Styled.DefaultMessagesWrapper>
                  {defaultMessages.map(defaultMessage => (
                    <Styled.DefaultMessage key={defaultMessage.id}>
                      <Styled.DefaultMessageIcon>
                        <Icon icon="warning" color="#fff" />
                      </Styled.DefaultMessageIcon>
                      <Styled.DefaultMessageText>{defaultMessage.text}</Styled.DefaultMessageText>
                    </Styled.DefaultMessage>
                  ))}
                </Styled.DefaultMessagesWrapper>
              )}
              {messageList.map((message, index) => (
                <MessageItem
                  key={message.id}
                  {...message}
                  conversationId={conversationId}
                  prevMessage={messageList[index - 1]}
                  isFirstMessage={index === 0}
                  isLastMessage={index === messageList.length - 1}
                  isLastSeenMessage={lastMessageWithCreatedDate === message.id && message?.readBy?.length}
                  isLastMessageWithCreatedDate={lastMessageWithCreatedDate === message.id}
                  images={messageList.filter(
                    message => message.attachment && message.custom?.file?.fileType?.includes('image')
                  )}
                />
              ))}
            </Flex>
            {typing && <Styled.TypingIndicatorWrapper>{getTypingIndicatorText()}</Styled.TypingIndicatorWrapper>}
          </Flex>
        </Styled.MessageListWrapper>
      )}
    </Styled.Container>
  );
};

export default MessageList;
