import React from 'react';
import styled from '@emotion/styled';

import useForm from 'hooks/useForm';
import withRenderPortal from 'hocs/withRenderPortal';
import FormSelect from 'common/form/FormSelect';
import FormInput from 'common/form/FormInput';
import FormCheckbox from 'common/form/FormCheckbox';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import GameSearch from 'components/GameSearch';
import CompanySearch from 'components/CompanySearch';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;

const StyledYearInput = styled(FormInput)`
  width: 114px;
`;

const StyledForm = styled.form`
  display: grid;
  grid-row-gap: 50px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
  margin-left: auto;
`;

const statuses = [
  { value: 'in-progress', label: 'Game in progress' },
  { value: 'shipped', label: 'Game shipped' },
  { value: 'cancelled', label: 'Project cancelled' }
];

const fields = {
  _id: { initialValue: '' },
  project: { initialValue: '' },
  talent: { initialValue: '' },
  status: { initialValue: '', validate: ['isRequired'] },
  game: { initialValue: '' },
  gameTitlePermitted: { initialValue: true },
  developer: { initialValue: '' },
  developerPermitted: { initialValue: true },
  publisher: { initialValue: '' },
  publisherPermitted: { initialValue: true },
  year: { initialValue: '' },
  role: { initialValue: '' },
  rolePermitted: { initialValue: true },
  receivedCredits: { initialValue: false }
};

const onValidate = values => {
  const errors = {};

  if (values.getIn(['status', 'value', 'value']) === 'shipped' && !values.getIn(['game', 'value'])) {
    errors.game = 'Required';
  }

  return errors;
};

const transform = ({
  developer,
  developerPermitted,
  game,
  gameTitlePermitted,
  publisher,
  publisherPermitted,
  role,
  rolePermitted,
  status,
  year,
  ...rest
}) => {
  const data = {
    ...rest,
    role: {
      name: role,
      permitted: rolePermitted
    },
    status: status.value,
    game: {
      id: game || undefined,
      permitted: gameTitlePermitted
    },
    developer: {
      company: developer?._id,
      permitted: developerPermitted
    },
    publisher: {
      company: publisher?._id,
      permitted: publisherPermitted
    },
    year: year ? parseInt(year) : ''
  };

  return data;
};

const ProjectProductionModal = ({ closePortal, data, name, onSave }) => {
  const { errors, initialize, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    onSubmitSuccess: closePortal,
    onValidate,
    callApi: params => Promise.resolve(onSave(transform(params)))
  });

  React.useEffect(
    () => {
      const initial = {
        status: statuses.find(status => status.value === data.production.status),
        game: data.production?.game.id,
        gameTitlePermitted: data.production?.game.permitted,
        developer: data.production?.developer?.company,
        developerPermitted: data.production?.developer.permitted,
        publisher: data.production?.publisher?.company,
        publisherPermitted: data.production?.publisher.permitted,
        year: data.production?.year,
        role: data.production?.role.name,
        rolePermitted: data.production?.role.permitted,
        receivedCredits: data.production?.receivedCredits,
        _id: data.production?._id,
        project: data.production?.project,
        talent: data.production?.talent
      };

      initialize(initial);
    },
    [] // eslint-disable-line
  );

  const handleClear = () => {
    initialize({
      status: '',
      game: '',
      gameTitlePermitted: true,
      developer: '',
      developerPermitted: true,
      publisher: '',
      publisherPermitted: true,
      year: '',
      role: '',
      rolePermitted: true,
      receivedCredits: ''
    });
  };

  return (
    <TxplModal
      title="Production"
      subTitle="Let us know if this project was part of commercial production."
      name={name}
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <ContainedButton icon="delete" onClick={handleClear} backgroundColor="#FF5151" />
          <StyledButtonsContainer>
            <StyledContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
              Cancel
            </StyledContainedButton>

            <StyledContainedButton onClick={onSubmit} backgroundColor="#7266EE" forceDisplayText>
              Save
            </StyledContainedButton>
          </StyledButtonsContainer>
        </>
      }
    >
      <StyledForm>
        <FormSelect
          label="Project status"
          isRequired
          value={values.status}
          options={statuses}
          onChange={onFieldChange('status')}
          error={errors.status}
        />

        <div>
          <GameSearch
            label="Official game title"
            value={values.game ? values.game.name : undefined}
            onChange={option => onFieldChange('game')(option)}
            error={errors.game}
          />

          <FormCheckbox
            value={!values.gameTitlePermitted}
            checked={!values.gameTitlePermitted}
            text="I’m not permitted to disclose this"
            onChange={event => onFieldChange('gameTitlePermitted')(!event.target.checked)}
          />
        </div>

        <div>
          <CompanySearch
            label="Developer"
            value={values.developer?.name}
            onChange={onFieldChange('developer')}
            error={errors.developer}
          />
          <FormCheckbox
            value={!values.developerPermitted}
            checked={!values.developerPermitted}
            text="I’m not permitted to disclose this"
            onChange={event => onFieldChange('developerPermitted')(!event.target.checked)}
          />
        </div>

        <div>
          <CompanySearch
            label="Publisher"
            value={values.publisher?.name}
            onChange={onFieldChange('publisher')}
            error={errors.publisher}
          />
          <FormCheckbox
            value={!values.publisherPermitted}
            checked={!values.publisherPermitted}
            text="I’m not permitted to disclose this"
            onChange={event => onFieldChange('publisherPermitted')(!event.target.checked)}
          />
        </div>

        <StyledYearInput
          label="Year"
          hintText="When this game was—or will be—shipped (leave blank if undisclosed)"
          value={values.year}
          onChange={onFieldChange('year')}
          error={errors.year}
        />

        <div>
          <FormInput label="Role" value={values.role} onChange={onFieldChange('role')} error={errors.role} />
          <FormCheckbox
            value={!values.rolePermitted}
            checked={!values.rolePermitted}
            text="I’m not permitted to disclose this"
            onChange={event => onFieldChange('rolePermitted')(!event.target.checked)}
          />
        </div>

        <FormCheckbox
          value={values.receivedCredits}
          checked={values.receivedCredits}
          text="Yep yep, I got credit for this one"
          onChange={event => onFieldChange('receivedCredits')(event.target.checked)}
        />
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('project-production-modal')(ProjectProductionModal);
