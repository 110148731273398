import React, { useState } from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import TxplModal from 'common/TxplModal';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import moment from 'moment';
import { vettingService } from '../../api';
import Icon from '../../common/Icon';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;
const CommentHistoryHeader = styled.div`
  display: flex;
`;
const CommentHistoryItem = styled.div`
  display: block;
  margin-left: 12px;
`;
const CommentAuthor = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(242, 244, 255, 0.9);
`;
const CommentDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: rgba(230, 234, 255, 0.35);
  padding-top: 4px;
`;
const CommentText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
  padding-top: 19px;
  margin-bottom: 60px;
`;
const Loader = styled(Spinner)`
  margin: 15px 0 5px 0;
`;

const fields = (additionalComment, element) => ({
  additionalComment: { initialValue: additionalComment, validate: ['isRequired'] },
  element: { initialValue: element }
});

const transformParams = params => ({
  ...params
});

const VettingCommentModal = ({ closePortal, data, name }) => {
  const { additionalComment, comments, element, id } = data;
  const [isLoading] = useState(false);

  const callApi = params => {
    const apiParams = transformParams(params);

    const requestPromise = vettingService.addCommentToVetting(id, { ...apiParams });

    return requestPromise.then(({ data: { success } }) => {
      (success === true && data.onSuccess()) || closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(additionalComment, element),
    callApi
  });

  return (
    <TxplModal
      title="Comments"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton onClick={onSubmit}>Add a comment</ContainedButton>
        </StyledActionsContainer>
      }
    >
      {isLoading ? (
        <Loader centered />
      ) : (
        <>
          {comments.map((comment, index) => {
            return (
              <>
                <CommentHistoryHeader key={index}>
                  <Icon icon={'comment-avatar-violet'} size={48} />
                  <CommentHistoryItem>
                    <>
                      <CommentAuthor>{`${comment.user.firstName} ${comment.user.lastName}`}</CommentAuthor>
                      <CommentDate>{moment.utc(comment.date).fromNow()}</CommentDate>
                    </>
                  </CommentHistoryItem>
                </CommentHistoryHeader>
                <CommentText>{comment.comment}</CommentText>
              </>
            );
          })}
          <form onSubmit={onSubmit}>
            <FormTextarea
              label="Additional comments"
              placeholder="Add any comment here"
              onChange={onFieldChange('additionalComment')}
              value={values.additionalComment}
            />
          </form>
        </>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('vetting-comment-modal')(VettingCommentModal);
