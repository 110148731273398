import React from 'react';
import PropTypes from 'prop-types';
import { StyledIcon } from 'pages/talent/Collaborators/CollaboratorCard/CollaboratorCardStyles';
import Tooltip from 'components/Tooltip';
import Typography from 'components/shared/Typography';
import { HiddenAvatarContainer, HiddenAvatarLogoContainer } from './styled-components';

const HiddenAvatar = ({ changeMode, credit, overrideTooltipPosition, place = 'left' }) => (
  <HiddenAvatarContainer onClick={changeMode} data-tip data-for={`bias-reduced-mode-${credit}`}>
    <HiddenAvatarLogoContainer>
      <StyledIcon icon="hidden" />
    </HiddenAvatarLogoContainer>
    <Tooltip tooltipId={`bias-reduced-mode-${credit}`} place={place} overrideTooltipPosition={overrideTooltipPosition}>
      <Typography color="rgba(230, 234, 255, 0.6)">
        Bias-Reduced mode removes
        <br />
        pictures and names in order
        <br />
        to focus on candidates skills.
        <br />
      </Typography>
    </Tooltip>
  </HiddenAvatarContainer>
);

HiddenAvatar.propTypes = {
  changeMode: PropTypes.func,
  credit: PropTypes.string
};

export default HiddenAvatar;
