import React from 'react';

import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import {
  AddJobFooterButtons,
  AddJobFooterContainer,
  AddJobFooterRightButtons,
  StyledAddJobFooter
} from './styled.components';

const AddJobFooter = ({ dirty, handleCancel, jobStatus, onSave, submitting, isMember = false }) => (
  <StyledAddJobFooter>
    <AddJobFooterContainer>
      <AddJobFooterButtons>
        {dirty && (
          <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleCancel} disabled={submitting}>
            Cancel
          </ContainedButton>
        )}
        {jobStatus !== 'closed' && (
          <AddJobFooterRightButtons>
            {submitting && <Spinner />}
            {jobStatus !== 'published' && (
              <ContainedButton
                backgroundColor="rgba(204, 213, 255, 0.11)"
                onClick={e => onSave(e, 'draft')}
                icon="save"
                mr={6}
                disabled={submitting || isMember}
                data-testid="btn-save-draft-job"
              >
                Save Draft
              </ContainedButton>
            )}
            <ContainedButton
              onClick={e => onSave(e, 'published')}
              disabled={(jobStatus === 'published' && !dirty) || submitting || isMember}
              data-testid="btn-save-or-publish-job"
            >
              {jobStatus === 'published' ? 'save changes' : 'publish job'}
            </ContainedButton>
          </AddJobFooterRightButtons>
        )}
      </AddJobFooterButtons>
    </AddJobFooterContainer>
  </StyledAddJobFooter>
);

export default AddJobFooter;
