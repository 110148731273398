import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { getSeoData } from 'utils/seo';

const Seo = () => {
  const location = useLocation();
  const [seo, setSeo] = useState();

  useEffect(() => {
    if (location && location.pathname) {
      const pageSeo = { ...getSeoData('/'), ...getSeoData(location.pathname) };
      setSeo(pageSeo);
    }
  }, [location]);

  if (!seo) return null;

  return (
    <Helmet>
      {seo.title && <title>{seo.title}</title>}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}

      {seo.title && <meta name="og:title" property="og:title" content={seo.title} />}
      {seo.type && <meta name="og:type" property="og:type" content={seo.type} />}
      {seo.description && <meta name="og:description" property="og:description" content={seo.description} />}
      {seo.image && <meta name="og:image" property="og:image" content={seo.image} />}
    </Helmet>
  );
};

export default Seo;
