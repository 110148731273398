import React, { useState } from 'react';
import useJobSearch from 'hooks/api/useJobSearch';
import withProfiles from 'hocs/withProfiles';
import InfiniteScroll from 'react-infinite-scroller';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { selectMyProfileId } from 'store/profile/profileSelectors';
import filtersQueryString from 'utils/filtersQueryString';
import Loader from 'common/Loader';
import PageWrapper from 'common/styles/PageWrapper';
import useSearch from 'hooks/useSearch';
import { pageSize } from 'utils/devices';
import JobSearchFilters from './JobSearchFilters';
import JobSearchResult from './JobSearchResult';
import JobSearchFilterModal from './modal/JobSearchFilterModal';
import Footer from '../../Footer';

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
`;

const StyledPageWrapper = styled(PageWrapper)`
  gap: 54px;
  margin: 120px auto 240px;
  min-height: calc(100vh - 496px);
  @media ${pageSize.XS}, ${pageSize.S} {
    gap: 32px;
    flex-flow: column;
  }
`;

const initialFilters = {
  search: {},
  discipline: [],
  role: [],
  title: [],
  type: [],
  genre: [],
  platform: [],
  tool: [],
  tag: [],
  location: [],
  preference: [],
  remote: [],
  relocation: [],
  createdAt: {},
  teamRole: [],
  requiredAvailability: []
};

function JobSearch() {
  const myProfileId = useSelector(selectMyProfileId);
  const [sort, setSort] = React.useState('desc');

  const [localFilters, setLocalFilters] = useState({});

  const {
    hasMoreJobs: hasMore,
    jobFilters,
    jobs,
    jobsFound,
    jobsPage: page,
    loading: { loadingJobs },
    searchJobs
  } = useJobSearch();

  const { clearFilters, filters, loadingSearch, setFilters } = useSearch({
    initialFilters,
    apiCall: (query, callback) => searchJobs(myProfileId, query, 1, sort, { clear: true }, callback)
  });

  const onSort = e => {
    searchJobs(myProfileId, filtersQueryString(filters), 1, e.value, { clear: true });
    setSort(e.value);
  };

  return (
    <>
      <JobSearchFilterModal localFilters={localFilters} />

      <JobSearchFilters
        initialFilters={filters}
        filters={jobFilters}
        setFilters={setFilters}
        setLocalFilters={setLocalFilters}
        clearFilters={clearFilters}
        searchJobs={searchJobs}
        found={jobsFound}
      />
      <StyledPageWrapper>
        <StyledInfiniteScroll
          loadMore={() => !loadingSearch && searchJobs(myProfileId, filtersQueryString(filters), page, sort)}
          hasMore={hasMore || loadingJobs}
          loader={<Loader margin="43px 0 0" key={0} />}
          useWindow
        >
          <JobSearchResult
            jobs={jobs}
            loading={loadingSearch}
            hasMore={hasMore}
            jobsFound={jobsFound}
            initialSort={sort}
            onSort={onSort}
          />
        </StyledInfiniteScroll>
      </StyledPageWrapper>
      <Footer />
    </>
  );
}

export default withProfiles(JobSearch);
