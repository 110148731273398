import { CHANGE_AUDIO_VOLUME } from './audioActionTypes';

const initialState = {
  volume: 1
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_AUDIO_VOLUME:
      return {
        ...state,
        volume: action.payload.volume
      };
    default:
      return state;
  }
};

export default audioReducer;
