import io from 'socket.io-client';

import { handleSocketEvents } from './socketManager';
import socketEvents from './socketEvents';
import useSocket from './useSocket';
import { cookieAuthName, getCookie } from 'utils/cookies';

class Socket {
  static socket;

  static disconnect = () => {
    if (Socket.socket) Socket.socket.disconnect();
  };

  static connect = () =>
    new Promise((resolve, reject) => {
      if (Socket.socket?.connected) return;

      const token = getCookie(cookieAuthName);
      const socket = io(process.env.REACT_APP_BASE_API_URL, {
        reconnectionDelayMax: 10000,
        transports: ['websocket'],
        secure: true,
        auth: { token: token?.startsWith('Bearer') ? token.slice(7) : token }
      });

      socket.on('connect', () => {
        Socket.socket = socket;
        resolve();
      });

      socket.on(socketEvents.ID_VEIFICATION_RESPONSE, data =>
        handleSocketEvents(socketEvents.ID_VEIFICATION_RESPONSE, data)
      );

      socket.on(socketEvents.NOTIFICATION, data => handleSocketEvents(socketEvents.NOTIFICATION, data));

      socket.on(socketEvents.DELETE_NOTIFICATION, data => handleSocketEvents(socketEvents.DELETE_NOTIFICATION, data));

      socket.on(socketEvents.WELCOME_MESSAGE, data => handleSocketEvents(socketEvents.WELCOME_MESSAGE, data));

      socket.on(socketEvents.NEW_COLLABORATION, data => handleSocketEvents(socketEvents.NEW_COLLABORATION, data));

      socket.on(socketEvents.NEW_CONVERSATION, data => handleSocketEvents(socketEvents.NEW_CONVERSATION, data));

      socket.on(socketEvents.USER_TYPING_MESSAGE, data => handleSocketEvents(socketEvents.USER_TYPING_MESSAGE, data));

      socket.on(socketEvents.MARK_CONVERSATION_AS_READ, data =>
        handleSocketEvents(socketEvents.MARK_CONVERSATION_AS_READ, data)
      );

      socket.on(socketEvents.UPDATE_FUNNEL_STATUS, data => handleSocketEvents(socketEvents.UPDATE_FUNNEL_STATUS, data));

      socket.on('error', () => {
        reject();
      });
    });
}

export { useSocket };

export default Socket;
