import React from 'react';

import Spinner from 'common/Spinner';
import Icon from 'common/Icon';
import * as Styled from './StyledConversationMessageTo';
import ContactItem from './ContactItem';

const ContactList = ({ contactList, contactListRef, loading, setSelectedConversation }) => (
  <Styled.ContactListContainer ref={contactListRef} noResult={contactList?.length === 0}>
    {loading && (
      <Styled.ContactListLoader>
        <Spinner />
      </Styled.ContactListLoader>
    )}
    {!loading && contactList?.length === 0 && (
      <Styled.NoResultsWrapper>
        <Styled.NoResultsIcon>
          <Icon icon="alert" color="#fff" />
        </Styled.NoResultsIcon>
        <Styled.NoResultsText>No results found</Styled.NoResultsText>
      </Styled.NoResultsWrapper>
    )}
    {contactList?.map(contact => (
      <ContactItem key={contact._id} contact={contact} setSelectedConversation={setSelectedConversation} />
    ))}
  </Styled.ContactListContainer>
);

export default ContactList;
