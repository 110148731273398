import set from 'lodash/fp/set';
import update from 'lodash/fp/update';

import * as userActionTypes from './userActionTypes';

const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.INITIALIZE_USER: {
      return action.payload.user;
    }

    case userActionTypes.UPDATE_USER_PROPERTY: {
      const { property, updateKey, updateType, value } = action.payload;

      if (updateType === 'insert') {
        return update(property, values => (values || []).concat([value]), state);
      }

      if (updateType === 'unshift') {
        return update(property, values => [value].concat(values || []), state);
      }

      if (updateType === 'delete') {
        return update(property, values => (values || []).filter(currValue => currValue[updateKey] !== value), state);
      }

      if (updateType === 'update') {
        return update(
          property,
          values => (values || []).map(curr => (curr[updateKey] === value[updateKey] ? value : curr)),
          state
        );
      }

      return set(property, value, state);
    }

    case userActionTypes.CLEAR_USER: {
      return {};
    }

    default:
      return state;
  }
};

export default userReducer;
