import React from 'react';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import sanitizeAndParseHTML from 'utils/sanitizeAndParseHTML';
import { parseJson } from 'utils';
import { LifeBlockHeading, LifeBlockParagraph, LifeTextBlockWrapper } from './styled.components';

const TextMediaWrapper = styled.div`
  display: flex;
  gap: 24px;
  &.left {
    flex-direction: row-reverse;
  }
  @media ${pageSize.XS} {
    flex-direction: column-reverse !important;
  }
`;
const LifeMedia = styled.div``;
const LifeImage = styled.img`
  width: 268px;
  border-radius: 8px;
  @media ${pageSize.XS} {
    width: 100%;
  }
`;
const LifeVideo = styled.video`
  width: 268px;
  border-radius: 8px;
  @media ${pageSize.XS} {
    width: 100%;
  }
`;

const LifeTextMediaBlock = ({ life }) => {
  const { attachment, description, mediaPosition, title } = life;

  return (
    <LifeTextBlockWrapper className="life-block">
      <LifeBlockHeading className="life-block-heading">{title}</LifeBlockHeading>
      <TextMediaWrapper className={mediaPosition}>
        <LifeBlockParagraph>{sanitizeAndParseHTML(parseJson(description))}</LifeBlockParagraph>
        <LifeMedia>
          {attachment.type === 'image' && <LifeImage src={attachment.url} />}
          {attachment.type === 'video' && <LifeVideo src={attachment.url} controls />}
        </LifeMedia>
      </TextMediaWrapper>
    </LifeTextBlockWrapper>
  );
};

export default LifeTextMediaBlock;
