import React, { useCallback } from 'react';
import { Card, CardBody, CardHeader, CardLabel, CardLegend, TextIcon } from './Steps.styled';
import Icon from 'common/Icon';

const StepCard = ({ card, handleClick }) => {
  const onClick = useCallback(() => {
    if (card.disabled) return;
    handleClick(card);
  }, []);

  return (
    <Card key={card.icon} disabled={card.disabled} onClick={onClick}>
      <CardHeader>
        <Icon icon={card.icon} size="24" color={card.color} />
        {card.disabled ? <TextIcon>Coming soon</TextIcon> : <Icon icon="arrow-forward" size="24" />}
      </CardHeader>
      <CardBody>
        <CardLabel disabled={card.disabled}>{card.label}</CardLabel>
        <CardLegend>{card.legend}</CardLegend>
      </CardBody>
    </Card>
  );
};

export default StepCard;
