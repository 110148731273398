import React from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import styled from '@emotion/styled';
import useOutsideClick from 'hooks/useOutsideClick';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import get from 'lodash/fp/get';
import values from 'lodash/fp/values';

const Wrapper = styled.div`
  margin-right: 6px;
`;
const EmojiSelectorWrapper = styled.div`
  position: absolute;
  top: -16px;
  right: 0;
  transform: translateY(-100%);
  z-index: 300;

  .emoji-mart-category .emoji-mart-emoji:hover:before {
    top: 2px;
    left: 2px;
  }

  @media ${pageSize.XS} {
    .emoji-mart {
      width: 100% !important;
    }
  }
`;

const excludeEmojis = [
  'White Smiling Face',
  'Black Spade Suit',
  'Black Heart Suit',
  'Black Diamond Suit',
  'Black Club Suit',
  'North East Arrow',
  'South East Arrow',
  'South West Arrow',
  'North West Arrow',
  'Up Down Arrow',
  'Left Right Arrow',
  'Female Sign',
  'Male Sign',
  'Double Exclamation Mark',
  'Copyright Sign',
  'Registered Sign',
  'Trade Mark Sign',
  'Black Small Square',
  'White Small Square'
];

function EmojiSelector({ isDisabled, onSelect }) {
  const [showEmojiPicker, setShowEmojiPicker] = React.useState();
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const node = React.useRef(null);
  const emojis = JSON.parse(localStorage.getItem('emojis')) ?? {};
  const recent = values(get(profileId, emojis) ?? [])
    .sort((a, b) => {
      if (a.count) {
        return b.count - a.count;
      }
      return 0;
    })
    .slice(0, 18);

  let emojiPickerEl = null;

  if (showEmojiPicker) {
    emojiPickerEl = (
      <EmojiSelectorWrapper>
        <Picker
          title=""
          emoji=""
          theme="dark"
          native
          exclude={!recent.length ? ['recent'] : []}
          recent={recent}
          onSelect={onSelect}
          emojiSize={22}
          showPreview={false}
          showSkinTones={false}
          emojisToShowFilter={emoji => !excludeEmojis.includes(emoji.name)}
        />
      </EmojiSelectorWrapper>
    );
  }

  useOutsideClick(node, () => setShowEmojiPicker(false));

  return (
    <Wrapper ref={node}>
      <ContainedButton
        icon="smile"
        height="40px"
        padding="0 16px"
        backgroundColor="secondary"
        disabled={isDisabled}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
      />
      {emojiPickerEl}
    </Wrapper>
  );
}

export default EmojiSelector;
