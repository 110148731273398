import React from 'react';
import styled from '@emotion/styled';

import { api } from 'api';

import ContainedButton from './ContainedButton';

const url = `${process.env.REACT_APP_BASE_API_URL}api/`;

const StyledButton = styled(ContainedButton)`
  min-height: 100%;
`;

const ExportCsv = ({ path }) => {
  const onClick = async () => {
    const elem = document.createElement('a');

    const res = await api.get(`${url}${path}`, { responseType: 'blob' });

    const blob = URL.createObjectURL(new Blob([res.data]));

    elem.setAttribute('href', blob);
    elem.setAttribute('download', 'text.csv');
    elem.setAttribute('style', 'display: none;');

    document.getElementById('root').appendChild(elem);

    elem.click();
    document.getElementById('root').removeChild(elem);
  };

  return (
    <StyledButton type="button" onClick={onClick} backgroundColor="rgba(204, 213, 255, 0.11)" icon="import">
      EXPORT CSV
    </StyledButton>
  );
};

export default ExportCsv;
