import React from 'react';

const Gradients = () => {
  return (
    <>
      <radialGradient
        id="f"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-29.01817 12.55789 -17.99724 -41.58719 89.843 108.22)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF55" />
        <stop offset={1} stopColor="#FF8A01" />
      </radialGradient>
      <linearGradient id="b" x1={72} x2={72} y1={18} y2={102} gradientUnits="userSpaceOnUse">
        <stop stopColor="#10C62E" />
        <stop offset={1} stopColor="#2B883D" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h144v144H0z" />
      </clipPath>
    </>
  );
};

export default Gradients;
