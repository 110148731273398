/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ gap }) => gap || 'gap: 6px;'}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
		${({ pb }) => pb && `padding-bottom: ${pb}px;`}
		${({ mt }) => mt && `margin-top: ${mt}px;`}		
		${({ mb }) => mb && `margin-bottom: ${mb}px;`}
`;
const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  display: flex;
  flex-direction: row;
  color: white;
  ${props => props.boldWeight && 'font-weight: 700;'}
`;
const StyledHintText = styled.label`
  ${commonStyles.ui_text_small};
  color: rgba(230, 234, 255, 0.35);
  ${props => props.lightWeight && 'font-weight: 400;'}
  ${props => props.noHintText && 'display: none;'}
`;
const StyledTextarea = styled.textarea`
  ${commonStyles.ui_text_medium};
  ${props => (props.innerPadding ? props.innerPadding : 'padding: 15px 18px 15px 18px;')}
  outline: none;
  background: transparent;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 !important;
  width: 100% !important;
  ${props => (props.error ? 'border: 2px solid #FF5151;' : '')}
  min-height: ${props => props.minHeight ?? '100px'};
  ${props => props.lightWeight && 'font-weight: 400;'}
  color: ${props => props.color ?? 'inherit'};
  &:hover {
    border: 2px solid rgba(230, 234, 255, 0.6);
  }
  &:focus {
    border: 2px solid #7266ee;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.noFlexRow && 'display: none'}
`;
const StyledError = styled.div`
  ${commonStyles.ui_text_small}
  color: #FF5151;
`;
const MaxLength = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(230, 234, 255, 0.35);
  margin-left: auto;
`;
const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);
  padding-left: 3px;
  &::after {
    content: '*';
  }
`;

const FormTextarea = forwardRef(
  (
    {
      boldWeight,
      color,
      error,
      gap = false,
      hintText,
      innerPadding = false,
      label,
      lightWeight,
      maxLength,
      mb,
      mt,
      noFlexRow,
      noHintText,
      onChange,
      pb,
      pt,
      required,
      value,
      ...rest
    },
    ref
  ) => {
    useEffect(() => {
      if (ref) {
        ref.current.style.height = '1px';
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }, [value]);

    return (
      <InputWrapper pt={pt} pb={pb} mb={mb} mt={mt} gap={gap} id={rest.id} className={rest.className}>
        {label && (
          <StyledLabel boldWeight={boldWeight}>
            {' '}
            {label}
            {required && <Required />}
          </StyledLabel>
        )}
        {hintText && (
          <StyledHintText lightWeight={lightWeight} noHintText={noHintText}>
            {hintText}
          </StyledHintText>
        )}
        <StyledTextarea
          innerPadding={innerPadding}
          lightWeight={lightWeight}
          ref={ref}
          value={value}
          onChange={onChange}
          error={error}
          maxLength={maxLength}
          color={color}
          {...rest}
        />
        {error ||
          (maxLength && (
            <FlexRow noFlexRow={noFlexRow}>
              {error && <StyledError>{error}&nbsp;</StyledError>}
              {maxLength && maxLength > 0 && (
                <MaxLength>
                  {value && value.length ? (value.length > maxLength ? maxLength : value.length) : '0'}/{maxLength}
                </MaxLength>
              )}
            </FlexRow>
          ))}
      </InputWrapper>
    );
  }
);

export default FormTextarea;
