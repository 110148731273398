import styled from '@emotion/styled';
import Loader from 'common/Loader';
import PurpleGradient from 'common/PurpleGradient';
import PageWrapper from 'common/styles/PageWrapper';
import withProfiles from 'hocs/withProfiles';
import useTalentSearch from 'hooks/api/useTalentSearch';
import useSearch from 'hooks/useSearch';
import Footer from 'pages/Footer';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { selectMyProfileId } from 'store/profile/profileSelectors';
import { pageSize } from 'utils/devices';
import filtersQueryString from 'utils/filtersQueryString';
import TalentCardModal from './TalentCardModal';
import TalentsFilter from './TalentsFilter';
import TalentsFilterModal from './TalentsFilterModal';
import TalentsSearchResult from './TalentsSearchResult';

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
`;

const Wrapper = styled(PageWrapper)`
  @media ${pageSize.L} {
    min-height: calc(100vh - 632px);
    margin: 80px auto 240px;
  }
  @media ${pageSize.M} {
    margin: 64px auto 240px;
    min-height: calc(100vh - 668px);
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    margin: 56px auto 240px;
    min-height: calc(100vh - 758px);
  }
  @media ${pageSize.XS} {
    min-height: calc(100vh - 884px);
  }
`;

const initialFilters = () => ({
  search: {},
  firstName: {},
  lastName: {},
  discipline: [],
  tool: [],
  platform: [],
  genre: [],
  skill: [],
  role: [],
  tag: [],
  experience: [],
  gamesShipped: [],
  location: [],
  hoursPerWeek: [],
  jobType: [],
  language: []
});

function TalentsSearch() {
  const myProfileId = useSelector(selectMyProfileId);
  const {
    hasMoreTalents: hasMore,
    loading: { loadingTalents },
    relatedSearch,
    searchTalents,
    talents,
    talentsFilters,
    talentsFound,
    talentsPage: page
  } = useTalentSearch();

  const { clearFilters, filters, loadingSearch, setFilters } = useSearch({
    initialFilters: initialFilters(),
    apiCall: (query, callback) => searchTalents(myProfileId, query, 1, true, callback)
  });

  return (
    <>
      <PurpleGradient />
      <TalentsFilter
        initialFilters={filters}
        filters={talentsFilters}
        setFilters={setFilters}
        clearFilters={clearFilters}
      />
      <Wrapper>
        <StyledInfiniteScroll
          loadMore={() => !loadingSearch && searchTalents(myProfileId, filtersQueryString(filters), page)}
          hasMore={hasMore || loadingTalents}
          loader={<Loader key={0} />}
          useWindow
        >
          <TalentsSearchResult
            loading={loadingSearch}
            talents={talents}
            talentsFound={talentsFound}
            relatedSearch={relatedSearch}
            hasMore={hasMore}
          />
        </StyledInfiniteScroll>
      </Wrapper>

      <TalentCardModal />

      <TalentsFilterModal />

      <Footer />
    </>
  );
}

export default withProfiles(TalentsSearch);
