import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import delay from 'lodash/delay';

import { userSelectors } from 'store/user';
import commonStyles from 'utils/common-styles';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import Spinner from 'common/Spinner';
import userService from 'api/userService';

const SuccessMessage = styled.div`
  ${commonStyles.ui_text_medium}
  color: #00FF00;
`;
const StyledSuccessIcon = styled(Icon)`
  margin-right: 10px;
`;
const StyledButtonContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const ChangePassword = () => {
  const email = useSelector(userSelectors.selectCurrentUserProperty('email'));

  const [linkSent, updateLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendResetLink = async () => {
    setLoading(true);
    await userService.passwordResetLinkSend({ email });
    setLoading(false);
    updateLinkSent(true);
    delay(() => updateLinkSent(false), 5000);
  };

  return (
    <>
      <Typography transform="capitalize" variant="h3" mb={32}>
        Password
      </Typography>

      <Typography mb={24} variant="medium" color="rgba(230, 234, 255, 0.6)">
        To change your password, request a reset link and head over to the inbox you used for registration.
      </Typography>

      <StyledButtonContainer>
        {linkSent ? (
          <SuccessMessage>
            <StyledSuccessIcon icon="checkmark" color="#00FF00" />A reset link has been sent, check your inbox to reset
            your password
          </SuccessMessage>
        ) : (
          <>
            <ContainedButton disabled={loading} onClick={sendResetLink}>
              Send a link
            </ContainedButton>
            {loading && <Spinner />}
          </>
        )}
      </StyledButtonContainer>
    </>
  );
};

export default ChangePassword;
