import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import ContainedButton from 'common/ContainedButton';
import FormCheckbox from 'common/form/FormCheckbox';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import Typography from 'components/shared/Typography';
import { authActions } from 'store/auth';
import { getAssetIcon } from 'utils/assets';
import { authService } from 'api';
import { pageSize } from 'utils/devices';

const StyledContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 3fr;
  @media ${pageSize.XS} {
    grid-template-rows: 1fr 3fr;
  }
  gap: 32px;
`;

const StyledImage = styled.img`
  width: 110px;
  height: 110px;
`;

const StyledAgreementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const StyledLink = styled(Link)`
  color: #7266ee;
`;

const StyledCheckboxText = styled.div`
  display: inline-flex;
  gap: 5px;
`;

const fields = () => ({
  agreed: { initialValue: false, validate: ['isRequired'] }
});

const OauthModal = ({ data, name }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loginFunc = React.useMemo(() => {
    if (data.type === 'google') return authService.authWithGoogle;
    if (data.type === 'linkedin') return authService.authWithLinkedIn;
    if (data.type === 'fb') return authService.authWithFB;
  }, [data.type]);

  const { errors, onFieldChange, onSubmit, submitted, submitting, values } = useForm({
    fields: fields(data),
    callApi: params =>
      loginFunc({ code: data.code, state: data.state, ...params, tokens: data.tokens }).then(response => {
        const { accessToken, user } = response.data;
        localStorage.setItem('token', accessToken);

        dispatch(authActions.login(user));
        history.push('/profile');
      })
  });

  return (
    <TxplModal
      title="Welcome to TheXPlace"
      name={name}
      hideCloseIcon
      size="small"
      transparentBackground
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          {!submitted && (
            <ContainedButton forceDisplayText onClick={onSubmit}>
              CONTINUE
            </ContainedButton>
          )}
        </>
      }
    >
      <StyledContainer>
        <StyledImage src={getAssetIcon('TERMS_OF_SERVICE')} />
        <div>
          <StyledAgreementContainer>
            <Typography variant="medium" opacity="0.6">
              There’s one more step to join: you need to accept the Terms of Service.
            </Typography>
            <FormCheckbox
              value={values.agreed}
              checked={values.agreed}
              error={errors.agreed}
              text={
                <StyledCheckboxText>
                  <Typography variant="medium" opacity="0.35">
                    I agree to the
                  </Typography>
                  <StyledLink href="#">Terms Of Service</StyledLink>
                </StyledCheckboxText>
              }
              onChange={event => onFieldChange('agreed')(event.target.checked)}
            />
          </StyledAgreementContainer>
        </div>
      </StyledContainer>
    </TxplModal>
  );
};

export default withRenderPortal('oauth-modal')(OauthModal);
