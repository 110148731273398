import React, { useEffect, useRef, useState } from 'react';

import * as Styled from './StyledMobileSearch';
import Icon from 'common/Icon';
import useSearchService from 'hooks/api/useSearchService';
import { useSelector } from 'react-redux';
import { selectMyProfileId } from 'store/profile/profileSelectors';

const MobileSearch = ({
  error,
  icon,
  iconBottom,
  iconRight,
  iconTop,
  maxLength,
  onClick,
  onIconClick,
  searchIconColor,
  setShowMobileSearch,
  setShowSearch,
  showMobileSearch,
  showSearch,
  ...rest
}) => {
  const inputRef = useRef();
  const searchInDropDownRef = useRef();
  const profileId = useSelector(selectMyProfileId);

  const [search, setSearch] = useState(new URL(window.location.href).searchParams.get('search') ?? '');
  const [, setShowSuggestions] = useState(false);
  const [showSearchIn, setShowSearchIn] = useState(false);

  const { handleClear, handleKeyDown, handleSearch, handleSearchInJobs, handleSearchInPeople } = useSearchService();

  useEffect(() => {
    if (error) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus();
    }
  }, [error]);

  useEffect(() => {
    window.location.search.length === 0 && setSearch('');
    setShowSearchIn(false);
  }, [window.location.search]);

  const handleKeyDownMobile = e => {
    if (e.keyCode === 13) {
      handleKeyDown(e, search, setShowSearchIn);
      setShowMobileSearch(false);
      onClick(e);
    }
  };

  const handleSearchInJobsMobile = e => {
    handleSearchInJobs(e, search, setShowSearchIn);
    setShowMobileSearch(false);
    onClick(e);
  };

  const handleSearchInPeopleMobile = e => {
    handleSearchInPeople(e, search, setShowSearchIn);
    setShowMobileSearch(false);
    onClick(e);
  };

  return (
    <>
      {showMobileSearch && (
        <Styled.MobileSearchWrapper>
          <Styled.InputWrapper>
            <Styled.MobileSearch
              value={search}
              onChange={e => handleSearch(e, setSearch, setShowSearchIn, setShowSuggestions, profileId)}
              onFocus={e => handleSearch(e, setSearch, setShowSearchIn, setShowSuggestions, profileId)}
              placeholder="Search"
              error={error}
              onKeyDown={e => handleKeyDownMobile(e)}
              ref={inputRef}
              maxLength={maxLength}
              showSearch={showSearch}
              {...rest}
              autoComplete="off"
            />
            {search.length > 0 && (
              <Styled.ClearSearchWrapper>
                <Styled.ClearSearch onClick={() => handleClear(setSearch)}>
                  <Icon size={8} icon="close" />
                </Styled.ClearSearch>
              </Styled.ClearSearchWrapper>
            )}
            {search.length > 0 && showSearchIn && (
              <Styled.SearchBarDropDown ref={searchInDropDownRef}>
                <Styled.SearchBarDropDownItem onClick={e => handleSearchInJobsMobile(e)}>
                  <Styled.SearchTextWrapper>
                    <Icon icon="jobs" className="search-dropdown-icon" />
                    <Styled.SearchText>{search}</Styled.SearchText>
                  </Styled.SearchTextWrapper>
                  <Styled.SearchInText>in Jobs</Styled.SearchInText>
                </Styled.SearchBarDropDownItem>
                <Styled.SearchBarDropDownItem onClick={e => handleSearchInPeopleMobile(e)}>
                  <Styled.SearchTextWrapper>
                    <Icon icon="about" className="search-dropdown-icon" />
                    <Styled.SearchText>{search}</Styled.SearchText>
                  </Styled.SearchTextWrapper>
                  <Styled.SearchInText>in People</Styled.SearchInText>
                </Styled.SearchBarDropDownItem>
                <Styled.SearchBarDropDownItemDisabled>
                  <Styled.SearchTextWrapper>
                    <Icon icon="company" className="search-dropdown-icon" />
                    <Styled.SearchText>{search}</Styled.SearchText>
                  </Styled.SearchTextWrapper>
                  <Styled.SearchInText>in Companies</Styled.SearchInText>
                </Styled.SearchBarDropDownItemDisabled>
              </Styled.SearchBarDropDown>
            )}
          </Styled.InputWrapper>
          {icon && (
            <Styled.IconWrapper
              iconBottom={iconBottom}
              iconTop={iconTop}
              iconRight={iconRight}
              onClick={e => {
                onIconClick && onIconClick(e);
                setShowSearch(!showSearch);
              }}
            >
              <Icon icon={icon} color={searchIconColor || '#ffffff'} size={16} />
            </Styled.IconWrapper>
          )}
        </Styled.MobileSearchWrapper>
      )}
    </>
  );
};

export default MobileSearch;
