import jobService from 'api/jobService';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions, jobsSelectors } from 'store/jobs';

const useSavedJobs = () => {
  const jobs = useSelector(jobsSelectors.selectMyJobs('savedJobs'));
  const { hasMore } = useSelector(jobsSelectors.selectMyJobsPagination('savedJobs'));

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const getSavedJobs = React.useCallback(
    (profileId, skip, limit = 4) => {
      if (!isLoading && hasMore) {
        setIsLoading(true);
        return jobService
          .getSavedJobs(profileId, skip, limit)
          .then(res => {
            const { data, hasMore } = res.data;

            dispatch(
              jobActions.updateJob({
                jobType: 'savedJobs',
                profileId,
                jobs: data
              })
            );

            dispatch(
              jobActions.updateJobPagination({
                jobType: 'savedJobs',
                profileId,
                pagination: {
                  hasMore,
                  limit
                }
              })
            );
          })
          .catch(() => {
            dispatch(
              jobActions.updateJobPagination({
                jobType: 'savedJobs',
                profileId,
                pagination: {
                  hasMore: false,
                  limit
                }
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [isLoading, hasMore, dispatch]
  );

  return {
    savedJobs: jobs,
    loadingSavedJobs: isLoading,
    hasMoreSavedJobs: !!hasMore,
    getSavedJobs
  };
};

export default useSavedJobs;
