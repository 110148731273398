import * as profileActionTypes from './profileActionTypes';

export const initializeProfiles = profiles => ({
  type: profileActionTypes.INITIALIZE_PROFILES,
  payload: {
    profiles
  }
});

export const setProfile = profile => ({
  type: profileActionTypes.SET_PROFILE,
  payload: {
    profile
  }
});

export const updateProfileProperty = ({ profileId, property, updateKey = '_id', updateType, value }) => ({
  type: profileActionTypes.UPDATE_PROFILE_PROPERTY,
  payload: {
    property,
    updateKey,
    updateType,
    value,
    profileId
  }
});

export const setActiveProfileId = profileId => ({
  type: profileActionTypes.SET_ACTIVE_PROFILE_ID,
  payload: {
    profileId
  }
});

export const markConversationAsRead = ({ conversationId, messageIds, profileId, readBy }) => ({
  type: profileActionTypes.MARK_CONVERSATION_AS_READ,
  payload: {
    profileId,
    conversationId,
    messageIds,
    readBy
  }
});

export const clearProfiles = () => ({
  type: profileActionTypes.CLEAR_PROFILES
});

export const addNewConversation = ({ newConversation, profileId }) => ({
  type: profileActionTypes.ADD_NEW_CONVERSATION,
  payload: {
    profileId,
    newConversation
  }
});

export const newMessage = ({ message, profileId }) => ({
  type: profileActionTypes.NEW_MESSAGE,
  payload: {
    profileId,
    message
  }
});
