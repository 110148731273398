/* eslint-disable react/display-name */
import React from 'react';
import styled from '@emotion/styled';

import FormInput from 'common/form/FormInput';

const StyledColumn = styled.tr`
  height: 50px;
  position: sticky;
  top: 50px;
  & td:last-child {
    border-right: none !important;
  }
`;

const StyledColumnItem = styled.td`
  height: 50px;
  text-align: center;
  background-color: #111112;
  position: sticky;
  top: 50px;
  padding: 0 5px;
  border-right: 1px solid rgba(204, 213, 255, 0.11);
`;

const StyledFormInputContainer = styled.div`
  position: sticky;
  top: 50px;
  & div input {
    padding: 6px 27px 6px 18px;
    box-sizing: border-box;
    width: 100%;
    min-width: 70px;
  }
  & div {
    gap: 3px;
  }
`;

const SearchColumn = React.memo(({ handleSearch, keyName, search, shouldDisplay, title }) => (
  <StyledColumnItem key={title}>
    {shouldDisplay && (
      <StyledFormInputContainer>
        <FormInput
          onIconClick={console.log}
          onChange={e => handleSearch(keyName, e.target.value)}
          value={search[keyName]}
          icon="search"
          iconRight={10}
          iconBottom={10}
        />
      </StyledFormInputContainer>
    )}
    {!shouldDisplay && <div style={{ height: 47 }} />}
  </StyledColumnItem>
));

const SearchRow = ({ children, handleSearch, search }) => (
  <StyledColumn>
    {children.map(({ props }) => (
      <SearchColumn
        keyName={props.keyName}
        key={props.keyName + props.title}
        title={props.title}
        shouldDisplay={props.search}
        search={search}
        handleSearch={handleSearch}
      />
    ))}
  </StyledColumn>
);

export default SearchRow;
