import ServicePage from 'common/ServicePage';
import useViewport from 'hooks/useViewport';
import FeedbackModal from 'pages/ContactSupport/FeedbackModal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { apiCallActions } from 'store/apiCall';
import { portalActions } from 'store/portals';
import { ClickableText, Text } from './styled.components';

function ServerOutage() {
  const dispatch = useDispatch();
  const { isL } = useViewport();
  const { pathname } = useLocation();

  const toggleFeedbackModal = () => {
    dispatch(portalActions.openPortal({ name: 'feedback-modal' }));
  };

  React.useEffect(() => () => dispatch(apiCallActions.clearRequest({ name: pathname })), []);

  return (
    <>
      <ServicePage
        icon="server-outage"
        iconColor="linear-gradient(180deg, #0057FF 0%, #00C1DB 100%)"
        title="It’s not you. It’s US"
        infoText={
          <>
            <div>
              <Text>
                {`
                Our system is down, and we'll get things back to normal soon. If you have any questions, our friendly
                support team is standing by to help.`}{' '}
                {isL && <br />}
                <ClickableText color="#7266ee" onClick={toggleFeedbackModal}>
                  Contact them here.
                </ClickableText>
              </Text>
            </div>
          </>
        }
      />
      <FeedbackModal />
    </>
  );
}

export default ServerOutage;
