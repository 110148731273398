import React from 'react';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import JobsSidebar from './JobsSidebar';
import useProfiles from 'hooks/useProfiles';
import { Redirect } from 'react-router';
import PurpleGradient from 'common/PurpleGradient';
import JobWithDrawModal from './JobWithDrawModal';
import Icon from 'common/Icon';
import Footer from 'pages/Footer';
import AllJobs from './all-jobs/AllJobs';
import MyJobs from './my-jobs/MyJobs';
import JobShareModal from 'pages/talent/Jobs/JobSidebar/JobShareModal';
import JobsIPosted from './jobs-i-posted/JobsIPosted';
import JobCloseModal from './JobCloseModal';
import { DisplayContext } from 'common/DisplaySwitcher';
import { useDispatch } from 'react-redux';
import { jobActions } from 'store/jobs';
import UnpublishJobModal from '../../talent/Jobs/JobModals/UnpublishJobModal/UnpublishJobModal';

const Wrapper = styled.div`
  display: flex;
  gap: 54px;
  max-width: 1240px;
  margin: 120px auto 240px;
  min-height: calc(100vh - 496px);
  @media ${pageSize.L} {
    padding: 0 58px;
  }
  @media ${pageSize.M} {
    padding: 0 54px;
  }
  @media ${pageSize.S} {
    padding: 0 81px;
  }
  @media ${pageSize.XS} {
    padding: 0 16px;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    gap: 32px;
    flex-flow: column;
  }
`;
const PageLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px);
`;

const JobsPage = ({
  location: { pathname },
  match: {
    params: { talentId }
  }
}) => {
  const { isAllowed, isAuthLoading, isLoading, profile } = useProfiles(talentId);

  const dispatch = useDispatch();

  const isAllJobs = pathname.includes('all-jobs');
  const isMyJobs = pathname.includes('my-jobs');
  const isJobsIPosted = pathname.includes('jobs-i-posted');

  const isCompany = profile?.type === 'company';
  const publicUrl = profile?.publicUrl;

  const path = `/${isCompany ? 'company' : 'talent'}/${publicUrl}`;

  const [status, setStatus] = React.useState('applied');
  const [postedStatus, setPostedStatus] = React.useState('unstaffed');
  const [display, setDisplay] = React.useState('grid');

  React.useEffect(() => {
    return () => {
      dispatch(jobActions.clearJobs());
    };
  }, []);

  if (isAuthLoading || isLoading) {
    return (
      <PageLoader>
        <Icon icon="loader" size="40" spin />
      </PageLoader>
    );
  }

  if (!isAuthLoading && !isAllowed && !profile) {
    return <Redirect push to="/home" />;
  }

  if ((!isAuthLoading && !isAllowed && !!publicUrl && publicUrl !== talentId) || pathname.includes('jobs/details')) {
    return <Redirect push to={`${path}/all-jobs`} />;
  }

  if (isCompany && pathname.includes('app/talent/')) {
    return <Redirect push to={pathname.replace('talent', 'company')} />;
  }

  if (!isCompany && pathname.includes('app/company/')) {
    return <Redirect push to={pathname.replace('company', 'talent')} />;
  }

  return (
    <>
      <JobWithDrawModal />
      <JobCloseModal />
      <JobShareModal />

      <UnpublishJobModal />

      <PurpleGradient />
      <Wrapper>
        <JobsSidebar />
        <DisplayContext.Provider value={{ display, setDisplay }}>
          {isAllJobs && <AllJobs />}
          {isMyJobs && <MyJobs status={status} setStatus={setStatus} />}
          {isJobsIPosted && <JobsIPosted status={postedStatus} setStatus={setPostedStatus} />}
        </DisplayContext.Provider>
      </Wrapper>

      <Footer />
    </>
  );
};

export default JobsPage;
