import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

const authDomain = state => state.currentUser;

export const selectCurrUser = createSelector([authDomain], currentUser => currentUser);

export const selectCurrentUserProperty = property =>
  createSelector([authDomain], currentUser => get(property, currentUser));

export const selectIsAuthLoading = state => !!get('ui.apiCalls.currentUser.loading', state);

export const isAuthed = state => !!get('_id', state.currentUser);
