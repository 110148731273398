import React, { useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import { getAssetIcon } from 'utils/assets';
import Icon from 'common/Icon';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';
import AssetPdf from './AssetPdf';
import { AssetContainer } from './AssetContainer';
import { audioSelectors } from 'store/audio';
import { useSelector } from 'react-redux';
import useAudio from 'hooks/useAudioWaveSurfer';

const StyledAsset = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
`;
const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: black;
`;
const StyledWaveSurfer = styled.div`
  width: 100%;
  height: 380px;
  object-fit: cover;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #7d61f0;

  & wave {
    width: 100%;
  }

  @media ${pageSize.XS} {
    height: 343px;
  }
`;
const StyledPlayButton = styled(ContainedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  backdrop-filter: blur(25px);
  z-index: 4;
  position: absolute;
  border: none;
  left: calc(50% - 40px);
  top: calc(50% - 40px);

  &.play-button.hidden {
    display: none;
  }
`;
const VideoPlay = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;

  .play {
    transition-duration: 0.2s;
    background-color: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-align: center;

    > img {
      width: 24px;
      height: 30px;

      margin: 24px auto;
      display: block;
    }
  }
`;
const PdfIconWrapper = styled(VideoPlay)`
  background-color: rgba(114, 102, 238, 0.7);
`;
const ZoomIconWrapper = styled(VideoPlay)`
  .zoom {
    display: none;
  }
  &:hover .zoom {
    display: block;
  }
`;

const transformFile = file => {
  if (!file) return null;

  return {
    type: file._id ? file?.file?.type || file.type : file.file?.asset?.type ?? file.file.type,
    src: file._id ? file?.file?.url || file.url : window.URL.createObjectURL(file.file?.asset ?? file.file),
    _id: file.id || file._id,
    orderIndex: file.orderIndex,
    name: file?.name || file.name,
    description: file.description || file.description
  };
};

const Asset = props => {
  const { border, file, id, lockUnlockStatus, onClick, onRemove, orderIndex, project, single, ...rest } = props;
  const asset = transformFile(file);
  const lastIndexOfDot = asset?.src?.lastIndexOf('.');
  const videoType = asset?.src?.slice(lastIndexOfDot);
  const videoPoster = asset?.url?.replace(videoType, '.png');
  const audioRef = useRef();
  const surferRef = useRef();
  const videoRef = useRef();
  const [videoPlaying, setVideoPlaying] = useState(single);
  const { volume } = useSelector(audioSelectors.selectAudio);

  const { loadingAudio, pauseAudio, playingAudio, toggleAudio } = useAudio({
    audioRef,
    surferRef,
    url: asset.src
  });

  const pauseVideo = React.useCallback(() => {
    setVideoPlaying(false);
    videoRef.current.pause();
  }, [videoPlaying]);

  if (!asset || !asset.type) return null;

  if (asset.type.includes('video')) {
    if (single) {
      return (
        <AssetContainer
          className={videoPlaying ? 'video-playing' : 'video-paused'}
          project={project}
          lockUnlockStatus={lockUnlockStatus}
          asset={asset}
          border={border}
          onRemove={onRemove(asset, id)}
          onClick={e => {
            pauseVideo();
            videoRef.current.currentTime = 0;
            onClick(e);
          }}
          autoHeight
          {...rest}
        >
          <StyledVideo
            ref={videoRef}
            key={asset.src}
            loop
            muted
            controls
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            poster={videoPoster}
            autoPlay
            onClickCapture={(e) => e.preventDefault()}
          >
            <source src={asset.src} />
          </StyledVideo>
        </AssetContainer>
      );
    }
    return (
      <Draggable index={orderIndex} draggableId={asset._id}>
        {({ dragHandleProps, draggableProps, innerRef }) => (
          <AssetContainer
            className={videoPlaying ? 'video-playing' : 'video-paused'}
            project={project}
            lockUnlockStatus={lockUnlockStatus}
            innerRef={innerRef}
            asset={asset}
            border={border}
            dragHandleProps={dragHandleProps}
            draggableProps={draggableProps}
            onRemove={onRemove(asset, id)}
            onClick={e => {
              pauseVideo();
              onClick(e);
            }}
            {...rest}
          >
            <StyledVideo
              ref={videoRef}
              controls
              loop
              controlsList="nodownload noplaybackrate"
              disablePictureInPicture
              poster={videoPoster}
              onClickCapture={(e) => e.preventDefault()}
            >
              <source src={asset.src} />
            </StyledVideo>
          </AssetContainer>
        )}
      </Draggable>
    );
  }

  if (asset.type.includes('audio')) {
    audioRef.current && audioRef.current.setVolume(volume);
    return (
      <Draggable index={orderIndex} draggableId={asset._id}>
        {({ dragHandleProps, draggableProps, innerRef }) => (
          <AssetContainer
            project={project}
            lockUnlockStatus={lockUnlockStatus}
            innerRef={innerRef}
            asset={asset}
            dragHandleProps={dragHandleProps}
            draggableProps={draggableProps}
            onRemove={onRemove(asset, id)}
            onClick={e => {
              pauseAudio();
              onClick(e);
            }}
            {...rest}
          >
            <StyledWaveSurfer ref={surferRef} id={`audio_${asset._id}`} />
            <StyledPlayButton onClick={toggleAudio}>
              <Icon
                icon={loadingAudio ? 'loader' : playingAudio ? 'pause' : 'play'}
                size={30}
                color="#7D61f0"
                hoverColor="#7D61f0"
              />
            </StyledPlayButton>
          </AssetContainer>
        )}
      </Draggable>
    );
  }

  if (asset.type.includes('pdf') || asset.type.includes('raw')) {
    return (
      <Draggable index={orderIndex} draggableId={asset._id}>
        {({ dragHandleProps, draggableProps, innerRef }) => (
          <AssetContainer
            project={project}
            lockUnlockStatus={lockUnlockStatus}
            innerRef={innerRef}
            asset={asset}
            border={border}
            dragHandleProps={dragHandleProps}
            draggableProps={draggableProps}
            onRemove={onRemove(asset, id)}
            {...rest}
          >
            <div>
              <AssetPdf url={asset.src} />
            </div>

            <PdfIconWrapper onClick={onClick}>
              <img className="play" src={getAssetIcon(asset.type.toUpperCase())} width="80px" alt="play" />
            </PdfIconWrapper>
          </AssetContainer>
        )}
      </Draggable>
    );
  }

  return (
    <Draggable index={orderIndex} draggableId={asset._id}>
      {({ dragHandleProps, draggableProps, innerRef }) => (
        <AssetContainer
          project={project}
          lockUnlockStatus={lockUnlockStatus}
          innerRef={innerRef}
          border={border}
          asset={asset}
          dragHandleProps={dragHandleProps}
          draggableProps={draggableProps}
          onRemove={onRemove(asset, id)}
          autoHeight
          {...rest}
        >
          <StyledAsset src={asset.src} alt="test" />

          <ZoomIconWrapper onClick={onClick}>
            <img className="zoom" src={getAssetIcon(asset.type.toUpperCase())} width="80px" alt="zoom" />
          </ZoomIconWrapper>
        </AssetContainer>
      )}
    </Draggable>
  );
};

export default Asset;
