import isEmpty from 'lodash/fp/isEmpty';

export default function filtersQueryString(values) {
  let query = '';
  if (isEmpty(values)) {
    return '';
  }

  Object.keys(values).forEach(key => {
    if (Array.isArray(values[key])) {
      if (values[key].length) {
        query += `${values[key].map(({ value }) => `filters[${key}]=${value}`).join('&')}&`;
      }
    } else {
      if (values[key]?.value) {
        if (key === 'search' || key === 'firstName' || key === 'lastName' || key === 'sort' || key === 'recommended') {
          query += `${key}=${values[key].value}&`;
        } else {
          query += `filters[${key}]=${values[key].value}&`;
        }
      }
    }
  });

  return query.slice(0, -1);
}
