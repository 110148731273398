import { useDispatch } from 'react-redux';

import { tagService } from 'api';
import { tagActions } from 'store/tags';
import { tagKeys } from '../../constants';

const useTagService = () => {
  const dispatch = useDispatch();

  const getTags = async type => {
    try {
      const tagKey = tagKeys[type];
      dispatch(tagActions.setTagsLoading(tagKey));
      const res = await tagService.getAllTags({ type });
      const tags = res.data.data;
      dispatch(tagActions.initializeTags(tagKey, tags));
    } finally {
      const tagKey = tagKeys[type];
      dispatch(tagActions.setTagsLoaded(tagKey));
    }
  };

  return {
    getTags
  };
};

export default useTagService;
