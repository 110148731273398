import ContainedButton from 'common/ContainedButton';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import useViewport from 'hooks/useViewport';
import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import LifeBarButton from './LifeBarButton/LifeBarButton';
import { StyledLifePageBarContainer } from './StyledLifePageBar';
import kebabCase from 'lodash/fp/kebabCase';

const LifePageBar = ({ activeButton, jobsPage, listOfCategories, setActiveButton }) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const { isXS } = useViewport();

  const limit = isXS ? 1 : 3;

  const first3 = listOfCategories?.slice(0, limit);
  const rest = listOfCategories?.slice(limit);

  return (
    <StyledLifePageBarContainer className={listOfCategories && listOfCategories.length === 0 ? 'lifePageBar' : ''}>
      {first3.map(it => (
        <LifeBarButton key={it._id} name={it.name} activeButton={activeButton} setActiveButton={setActiveButton} />
      ))}
      {!!rest.length && (
        <DropDown className="transparent text-uppercase center-on-xs" icon="arrow-down" text="more" stretchOnXs>
          {rest.map(it => (
            <DropDownItem key={it._id} onClick={() => setActiveButton(kebabCase(it.name))}>
              {it.name}
            </DropDownItem>
          ))}
        </DropDown>
      )}
      {hasPermission && !jobsPage && (
        <ContainedButton
          type="button"
          opens="life-pages-edit-modal"
          backgroundColor="transparent"
          icon="edit"
          size={14.98}
        />
      )}
    </StyledLifePageBarContainer>
  );
};

export default LifePageBar;
