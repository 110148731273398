import React from 'react';
import styled from '@emotion/styled';
import JobsHeader from '../commons/JobsHeader';
import JobsIpostedList from './JobsIpostedList';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  flex-grow: 1;
`;

const JobsIPosted = ({ setStatus, status }) => (
  <Wrapper>
    <JobsHeader header="posted-jobs" status={status} setStatus={setStatus} />
    <JobsIpostedList status={status} />
  </Wrapper>
);

export default JobsIPosted;
