import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { heading_2, paragraph } from 'utils/common-styles';
import { portalActions } from 'store/portals';
import { pageSize } from 'utils/devices';

import ContainedButton from 'common/ContainedButton';
import FeedbackModal from './FeedbackModal';

const StyledContainer = styled.div`
  margin: auto;
  margin-top: 105px;

  @media ${pageSize.XS} {
    content: 'XS';
    width: auto;
    margin-top: 80px;
    margin-left: 16px;
    margin-right: 16px;
  }

  @media ${pageSize.S} {
    content: 'S';
    width: 560px;
  }

  @media ${pageSize.M} {
    content: 'M';
    width: 585px;
  }

  @media ${pageSize.L} {
    content: 'L';
    width: 700px;
    margin-top: 105px;
  }
`;

const StyledBackgroundImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #6655cc 0%, rgba(74, 41, 204, 0) 100%);

  @media ${pageSize.XS} {
    width: 389px;
    height: 389px;
    top: -198px;
  }

  @media ${pageSize.S} {
    width: 971px;
    height: 971px;
    top: -650px;
  }

  @media ${pageSize.M} {
    width: 1341px;
    height: 1341px;
    top: -1023px;
  }

  @media ${pageSize.L} {
    width: 1572px;
    height: 1572px;
    top: -1200px;
  }
`;

const StyledTitle = styled.div`
  ${heading_2}
  margin-top: 24px;
  margin-bottom: 32px;
`;

const StyledParagraph = styled.p`
  ${paragraph};
`;

const StyledButton = styled(ContainedButton)`
  margin-top: 40px;
`;

const modalOptions = {
  name: 'feedback-modal',
};

const ContactSupport = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(portalActions.openPortal(modalOptions));
  }, []);    

  const toggleFeedbackModal = () => {
    dispatch(portalActions.openPortal(modalOptions));
  };

  const closeFeedbackModal = () => {
    dispatch(portalActions.closePortal(modalOptions));
  };

  return (
    <>
      <StyledBackgroundImage />

      <StyledContainer>
        <StyledTitle>
          GOT QUESTIONS?
        </StyledTitle>
        <StyledParagraph>
        We are here to help and answer any question you might have. <br></br> We look foward to hearing from you 😊
        </StyledParagraph>
        <StyledButton onClick={toggleFeedbackModal}>
            Contact Support
        </StyledButton>
      </StyledContainer>

      <FeedbackModal closeModal={closeFeedbackModal}/>
    </>
  );
};

export default ContactSupport;
