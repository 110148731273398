import * as React from 'react';

const FilterChildren = () => {
  return (
    <>
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={9} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_215_22065" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={7.2} />
      <feGaussianBlur stdDeviation={10.8} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.0705834 0 0 0 0 0.320833 0 0 0 0.3 0" />
      <feBlend in2="effect1_backgroundBlur_215_22065" result="effect2_dropShadow_215_22065" />
      <feBlend in="SourceGraphic" in2="effect2_dropShadow_215_22065" result="shape" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={1.8} />
      <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
      <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0" />
      <feBlend in2="shape" result="effect3_innerShadow_215_22065" />
    </>
  );
};

const PartyConeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144} height={120} fill="none" viewBox="0 0 144 120">
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          d="M46.504 29.953a5.654 5.654 0 0 0-1.072 1.58L45.4 31.5.536 132.565l.044.044c-.832 1.612.56 4.892 3.412 7.748 2.852 2.852 6.132 4.244 7.744 3.412l.04.04L112.84 98.941l-.032-.036c.588-.28 1.128-.62 1.58-1.076 6.248-6.248-3.884-26.508-22.625-45.252-18.748-18.744-39.008-28.872-45.26-22.624Z"
          fill="url(#c)"
        />
        <path
          d="M92.049 52.263c18.68 18.688 29.052 38.608 23.156 44.496-5.892 5.896-25.812-4.472-44.504-23.152C52.017 54.919 41.649 34.99 47.54 29.099c5.896-5.892 25.816 4.476 44.508 23.164Z"
          fill="#463EA5"
        />
        <g filter="url(#d)">
          <path
            d="M73.681 56.295a4.054 4.054 0 0 1-3.004.88c-3.553-.384-6.54-1.62-8.632-3.573-2.215-2.067-3.308-4.842-3.009-7.621.524-4.88 5.42-9.357 13.765-8.456 3.246.347 4.695-.696 4.744-1.196.058-.495-1.133-1.825-4.38-2.177-3.552-.385-6.54-1.621-8.636-3.574-2.214-2.066-3.311-4.842-3.008-7.62.532-4.88 5.423-9.358 13.76-8.453 2.367.254 3.615-.233 4.143-.549.422-.257.59-.503.606-.646.049-.495-1.126-1.826-4.38-2.178a4.085 4.085 0 0 1-3.626-4.51 4.08 4.08 0 0 1 4.506-3.627c8.338.896 12.169 6.312 11.64 11.195-.531 4.887-5.422 9.357-13.768 8.46-2.366-.258-3.602.234-4.134.549-.422.253-.594.503-.61.642-.053.5 1.13 1.826 4.384 2.178 8.337.9 12.168 6.311 11.64 11.194-.528 4.88-5.419 9.357-13.76 8.453-2.367-.254-3.61.237-4.143.548-.426.262-.59.508-.606.647-.053.495 1.13 1.825 4.38 2.177a4.093 4.093 0 0 1 2.128 7.258Z"
            fill="#BFCAFF"
            fillOpacity={0.1}
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#e)">
          <path
            d="M62.225 86a6.289 6.289 0 0 1-3.933-11.189c1.37-1.1 34.038-26.756 80.207-20.154a6.288 6.288 0 0 1 5.369 4.64 6.291 6.291 0 0 1-2.38 6.684 6.289 6.289 0 0 1-4.767 1.11c-40.791-5.793-70.28 17.3-70.57 17.533A6.276 6.276 0 0 1 62.225 86Z"
            fill="#BFCAFF"
            fillOpacity={0.1}
            shapeRendering="crispEdges"
          />
        </g>
        <path
          d="M23.015 63.999a4 4 0 0 1-3.832-5.148c4.532-15.092 8.64-39.176 3.592-45.456-.564-.712-1.416-1.412-3.368-1.264-3.752.288-3.396 8.204-3.392 8.284a4 4 0 1 1-7.976.596c-.412-5.516 1.304-16.14 10.768-16.856 4.224-.32 7.732 1.148 10.208 4.228 9.484 11.804-.144 46.024-2.168 52.768a4 4 0 0 1-3.832 2.848ZM98.044 36.583a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
          fill="#00FF40"
        />
        <g filter="url(#f)">
          <path
            d="M8 80a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
            fill="#BDC8FF"
            fillOpacity={0.05}
            shapeRendering="crispEdges"
          />
        </g>
        <path d="M130 84a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="#00FF40" />
        <path d="M120.044 115.401a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="url(#g)" />
        <path d="M112 24a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" fill="url(#h)" />
        <path d="M114.044 48.584a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="url(#i)" />
        <path d="M30 100.001a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="url(#j)" />
      </g>
    </g>
    <defs>
      <linearGradient id="g" x1={120.044} y1={115.401} x2={120.044} y2={103.401} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF3C8E" />
        <stop offset={1} stopColor="#A400F1" />
      </linearGradient>
      <linearGradient id="h" x1={112} y1={24} x2={112} y2={8} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF3C8E" />
        <stop offset={1} stopColor="#A400F1" />
      </linearGradient>
      <linearGradient id="i" x1={114.044} y1={48.584} x2={114.044} y2={36.584} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF3C8E" />
        <stop offset={1} stopColor="#A400F1" />
      </linearGradient>
      <linearGradient id="j" x1={30} y1={100.001} x2={30} y2={88.001} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF3C8E" />
        <stop offset={1} stopColor="#A400F1" />
      </linearGradient>
      <filter
        id="d"
        x={37.388}
        y={-15.03}
        width={72.431}
        height={101.03}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <filter
        id="e"
        x={34.345}
        y={35.573}
        width={131.331}
        height={79.227}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <filter
        id="f"
        x={-17.259}
        y={46.741}
        width={50.518}
        height={50.518}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h144v144H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h144v144H0z" />
      </clipPath>
      <radialGradient
        id="c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-57.90379 103.95997 -103.9521 -57.8994 48.957 53.673)"
      >
        <stop offset={0.395} stopColor="#8A80FF" />
        <stop offset={1} stopColor="#4539C5" />
      </radialGradient>
    </defs>
  </svg>
);

export default PartyConeIcon;
