import styled from '@emotion/styled';
import React from 'react';

const ActivityStatusWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusIcon = styled.div`
  display: inline-flex;
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: white;
  ${props => (props.color ? `background-color: ${props.color};` : '')}
`;

const StatusText = styled.span`
  text-transform: capitalize;
`;

const getStatusColor = status => {
  switch (status) {
    case 'PASS':
    case 'APPROVED':
      return '#2BFF26';
    case 'SECOND_REVIEW':
    case 'FURTHER_EXAMINATION':
      return '#7266EE';
    case 'PROBLEM':
      return '#FF9C40';
    case 'FAIL':
    case 'DECLINED':
      return '#FF5151';
    default:
      return 'white';
  }
};

const VettingOutcome = ({ icon = true, status }) => {
  const outcome = () => (status === '' || status === null ? '' : status && status.replace('_', ' ').toLowerCase());

  return (
    <ActivityStatusWrapper>
      {icon && status !== '' && status !== null && <StatusIcon color={getStatusColor(status)} />}
      <StatusText>{outcome()}</StatusText>
    </ActivityStatusWrapper>
  );
};

export default VettingOutcome;
