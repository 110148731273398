import React from 'react';

import TxplModal from 'common/TxplModal';
import styled from '@emotion/styled';
import withRenderPortal from '../../hocs/withRenderPortal';

const EmailText = styled.div`
  color: #8b8d9b;
`;

const EmailChangeResend = ({ name }) => (
  <TxplModal
    title="Change Email Request"
    name={name}
    appElement={document.getElementById('root-modal')}
    size="small"
    hideBorder
  >
    <EmailText>{`We've resent a verification link`}</EmailText>
  </TxplModal>
);

export default withRenderPortal('change-email-resend-modal')(EmailChangeResend);
