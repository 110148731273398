import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { vettingStatuses } from '../../constants';
import { vettingService } from '../../api';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = (initialStatus, element) => ({
  status: { initialValue: vettingStatuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  element: { initialValue: element }
});

const transformParams = params => ({
  ...params,
  status: params.status.value
});

const VettingOnlyStatusModal = ({ closePortal, data, name }) => {
  const { element, id, status } = data;

  const callApi = params => {
    const apiParams = transformParams(params);

    const requestPromise = vettingService.updateVettingStatus(id, { ...apiParams });

    return requestPromise.then(({ data: { success } }) => {
      (success === true && data.onSuccess()) || closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({ fields: fields(status, element), callApi });

  return (
    <TxplModal
      size="small"
      title="Vetting status"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="vettingOnlyStatusModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="vettingOnlyStatusModal">
        <FormSelect
          label="Vetting status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={vettingStatuses}
          autoFocus
        />
        <br />
        <br />
        <br />
        <br />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('vetting-only-status-modal')(VettingOnlyStatusModal);
