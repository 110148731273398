import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import commonStyles from 'utils/common-styles';

export const StyledSearchBoxLoader = styled.div`
  display: flex;
`;

export const StyledSearchedBox = styled.div`
  display: inline-flex !important;
  width: ${({ width }) => width || 'auto'}!important;
  min-width: 122px;
  gap: 8px;
  padding: 12px 16px;
  margin-left: 6px;
  background-color: ${({ color }) => color || 'rgba(204, 213, 255, 0.11)'};
  border-radius: 8px;
  cursor: pointer;
  &.empty-state {
    padding: 10px 16px;
  }
  &:not(.empty-state):hover {
    ${({ color }) => color && `box-shadow: 0px 2px 16px ${color}`}
  }
`;
export const TextWrapper = styled.div``;

export const DeleteButton = styled(ContainedButton)`
  button.delete-btn {
    padding: 8px 10px;
    border: 2px solid rgba(204, 213, 255, 0.11);
  }
  button.delete-btn: hover {
    background-color: rgba(204, 213, 255, 0.11);
  }
`;
export const TextSkeleton = styled.div`
  background: rgba(204, 213, 255, 0.5);
  width: 122px;
  height: ${({ height }) => (height ? `${height}px` : '16px')};
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
`;
export const SearchedText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${commonStyles.ui_text_medium}
`;
export const SmallText = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(230, 234, 255, 0.6);
`;
export const EmptyStateText = styled.div`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.6);
  border-radius: 8px;
`;
export const SearchedBoxColors = ['#E04F4F', '#E97721', '#9C4FFF', '#0091BF'];
