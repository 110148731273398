import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import { cookieAuthAsName, getCookie } from 'utils/cookies';

const Wrapper = styled.div`
  ${commonStyles.ui_text_tiny}
  margin-right: 16px;
  padding: 2px 5px;
  color: black;
  background-color: #00ff00;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  white-space: nowrap;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

const LoginAsMode = () => {
  const loginAs = getCookie(cookieAuthAsName);
  if (!loginAs) return null;

  return (
    <Wrapper>
      Admin
      <br />
      Mode
    </Wrapper>
  );
};

export default LoginAsMode;
