import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';

import useQuery from 'hooks/useQuery';
import ActivityStatus from './ActivityStatus';

const format = date => moment(date).format('MMM DD YYYY');

const BackOfficeSoloistStatusLog = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useQuery({
    url: `/backoffice/talents/${params.userId}`,
    onSuccess: talent => {
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'OPERATIONS' },
          { label: 'SOLOIST ACCOUNTS', url: '/backoffice/operations/soloists' },
          {
            label: `${talent.talent.userId.firstName} ${talent.talent.userId.lastName}`,
            url: `/backoffice/operations/soloists/${talent.talent._id}`
          },
          { label: 'Activity Log' }
        ])
      );
    }
  });

  return (
    <div>
      <Table keyId="_id" endpoint={`/backoffice/accounts/${params.userId}/status-log`} dataKey="data">
        <Column
          title="CHANGED TO"
          keyName="status"
          render={statusLog => <ActivityStatus status={statusLog.status} />}
          width="108px"
        />
        <Column title="CHANGED ON" keyName="createdAt" render={statusLog => format(statusLog.createdAt)} />
        <Column
          title="CHANGED BY"
          keyName="actionUserId"
          render={statusLog => `${statusLog.actionUserId.firstName} ${statusLog.actionUserId.lastName}`}
        />
        <Column title="REASON" keyName="reason" />
        <Column title="ADDITIONAL COMMENTS" keyName="additionalComment" />
      </Table>
    </div>
  );
};

export default BackOfficeSoloistStatusLog;
