import React from 'react';
import styled from '@emotion/styled';

import { getDate } from 'utils';
import commonStyles from 'utils/common-styles';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const PostedByDate = styled.div`
  ${commonStyles.ui_text_tiny}
  text-transform: uppercase;
  color: rgba(230, 234, 255, 0.35);
`;

const PostedByCredits = ({ updatedAt }) => (
  <Wrapper>
    <PostedByDate>Posted On {getDate(updatedAt)}</PostedByDate>
  </Wrapper>
);

export default PostedByCredits;
