import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import { backOfficeService } from 'api';

const StyledDeleteButton = styled(ContainedButton)`
  margin-right: auto;
`;

const BackOfficeMemberModal = ({ closePortal, data: { setUsers, userId }, name, tableRef }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [user, setUser] = useState();
  const [permissions, setPermissions] = useState();
  const [permissionsError, setPermissionsError] = useState('');

  const permissionOptions = React.useMemo(
    () => ['super-admin', 'super-editor', 'analyst'].map(permission => ({ value: permission, label: permission })),
    []
  );

  const formIsValid = () => {
    if (!permissions) {
      setPermissionsError('Please select permission');
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const postData = Array.isArray(permissions) ? permissions.map(p => p.value) : [permissions.value];

    if (formIsValid()) {
      backOfficeService
        .setBackOfficeMemberPermission(userId, { permissions: postData.map(permission => ({ role: permission })) })
        .then(() => {
          tableRef.current.refetch();
          closePortal();
        })
        .catch(error => {
          console.log('error: ', error);
        });
    }
  };

  useEffect(() => {
    const loadUser = () => {
      backOfficeService
        .getBackOfficeUser(userId)
        .then(response => {
          const responseUser = get('data.user', response);
          setFullName(`${responseUser.firstName} ${responseUser.lastName}`);
          setEmail(responseUser.email);
          setUser(responseUser);

          const { roles } = responseUser;
          setPermissions(permissionOptions.filter(per => roles.map(r => r.role).includes(per.value)));
        })
        .catch(error => {
          console.log('error: ', error);
        });
    };

    if (userId) {
      loadUser();
    }
  }, [userId, permissionOptions]);

  const isInvitePending = React.useMemo(() => user?.status === 'invite-pending', [user]);

  return (
    <TxplModal
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      title="Edit team member"
      addPaddingTopBig
      padding="64px 0 58px 0"
      titleMB="16"
      size="large"
      renderFooter={
        <>
          <StyledDeleteButton
            backgroundColor="rgba(221, 226, 255, 0.2)"
            style={{ border: 'none' }}
            iconColor="#FFFFFF"
            icon="delete"
            opens="backOffice-delete-account-modal"
            data={{ userId, setUsers, accountType: 'user', onSuccess: closePortal }}
          />
          <ContainedButton
            mr={8}
            type="button"
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
          >
            Cancel
          </ContainedButton>
          <ContainedButton type="submit" form="backOfficeMemberModal" forceDisplayText>
            Save
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={handleSubmit} id="backOfficeMemberModal" style={{ maxWidth: '600px' }}>
        <FormInput label="Name" value={fullName} disabled />

        <FormInput label="Email" value={email} disabled mt={40} mb={40} />

        <FormSelect
          autoFocus
          label="Permission"
          value={isInvitePending ? { value: 'invite-pending', label: 'Invite pending' } : permissions}
          isDisabled={isInvitePending}
          options={permissionOptions}
          error={permissionsError}
          onChange={e => setPermissions(e)}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-edit-team-member')(BackOfficeMemberModal);
