import styled from '@emotion/styled';

export const Container = styled.div`
  max-height: 645px;
  overflow: auto;
  overflow-y: overlay;

  &:hover {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      height: 458px;
      border-radius: 3px;
      background: rgba(221, 226, 255, 0.2);
    }
  }
`;
