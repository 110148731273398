import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { portalActions } from 'store/portals';
import useViewport from 'hooks/useViewport';
import { HeaderContainer, HeaderTitle, HeaderWrapper, StyledIcon, TitleWrapper } from './Steps.styled';
import Stepper from 'common/Stepper/Stepper';

const StepHeader = ({ activeStep }) => {
  const { isXS } = useViewport();
  const dispatch = useDispatch();

  const iconSize = isXS ? '56' : '144';

  const handleClick = useCallback(() => {
    dispatch(portalActions.closePortal({ name: 'quick-onboarding-modal' }));
  }, []);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <TitleWrapper>
          {activeStep.step && <Stepper steps={3} activeStep={activeStep.step} />}
          <HeaderTitle>{activeStep.label}</HeaderTitle>
        </TitleWrapper>
        {activeStep.icon && <activeStep.icon iconSize={iconSize} />}
        {activeStep.closeIcon && <StyledIcon icon="close" color="rgba(229, 232, 255, 0.65)" onClick={handleClick} />}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default StepHeader;
