import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import commonStyles from 'utils/common-styles';
import withRenderPortal from 'hocs/withRenderPortal';
import { userActions } from 'store/user';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';
import { backOfficeService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledWarning = styled.p`
  ${commonStyles.ui_text_medium}
  color: #FF5151;
  margin: 0px;
`;
const StyledIcon = styled(Icon)`
  margin-top: 5px;
  margin-right: 12px;
`;
const StyledText = styled.p`
  ${commonStyles.ui_text_medium}
`;
const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;
const StyledLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;

const BackOfficeDeleteAccountModal = ({ closePortal, data, name }) => {
  const [isLoading, updateIsLoading] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const talentOwner = useSelector(profileSelectors.selectProfileProperty('owner'));
  const talentUserId = useSelector(profileSelectors.selectProfileProperty('userId'));
  const userId = talentOwner || talentUserId;

  const deleteAccount = () => {
    const params = { ...data, status: 'deleted', accountType: data.accountType };
    updateIsLoading(true);

    backOfficeService
      .setAccountStatus(data.userId ?? data.accountId, params)
      .then(({ data: { accounts, statusLog } }) => {
        const action =
          data.accountType === 'user' ? userActions.updateUserProperty : profileActions.updateProfileProperty;

        data.setUsers && data.setUsers(items => items.filter(item => item._id !== data.userId));

        dispatch(action({ property: 'status', value: 'deleted', profileId: data.accountId }));
        dispatch(action({ property: 'statusLogs', updateType: 'insert', value: statusLog, profileId: data.accountId }));

        if (data.accountType === 'user') {
          dispatch(action({ property: 'accounts', value: accounts }));
        }
        if (data.onSuccess) data.onSuccess();
        closePortal();
      })
      .finally(() => updateIsLoading(false));
  };

  const onLinkClick = () => {
    closePortal();
    history.push(`/backoffice/operations/users/${userId}`);
  };

  return (
    <TxplModal
      name={name}
      title={data.userId ? 'Delete user' : 'Delete soloist'}
      appElement={document.getElementById('root-modal')}
      size="small"
      renderFooter={
        <StyledActionsContainer>
          {isLoading && <Spinner />}

          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </ContainedButton>

          <ContainedButton
            onClick={deleteAccount}
            icon="delete"
            backgroundColor="transparent"
            color="#FF5151"
            forceDisplayText
          >
            Delete
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <StyledContentContainer>
        {data.accountType === 'user' && <StyledIcon icon="alert" color="#FF5151" />}

        <div>
          {data.accountType === 'user' && (
            <>
              <StyledWarning>
                This will delete all accounts associated with this user, including soloists as well as team and company
                memberships.
              </StyledWarning>
              <StyledText>If you want to delete only particular account, proceed to an account page</StyledText>
              <StyledText>Delete this user?</StyledText>
            </>
          )}

          {data.accountType === 'talent' && (
            <>
              <StyledText>
                This will delete only this soloist account. If you want to delete the entire user,
                <StyledLink onClick={onLinkClick}>proceed to the user page</StyledLink>
              </StyledText>

              <StyledText>Delete this account?</StyledText>
            </>
          )}
        </div>
      </StyledContentContainer>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-delete-account-modal')(BackOfficeDeleteAccountModal);
