import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';

import { currentUserIdSelector, currentUserPropertySelector } from '../selectors/currentUser';

const selectDomain = state => state.profiles;
const selectCurrentUserDomain = state => state.currentUser;

export const selectActiveProfileId = state => get('ui.profiles._id', state);

export const selectMyProfileId = createSelector([selectCurrentUserDomain], state =>
  get('activeProfile.profile', state)
);

export const selectIsMyProfileCompany = createSelector(
  [selectDomain, selectMyProfileId],
  (state, myProfileId) => get(`${myProfileId}.type`, state) === 'company'
);

export const selectMyProfile = createSelector([selectDomain, selectMyProfileId], (state, myProfileId) =>
  get(myProfileId, state)
);

export const selectMyProfilePublicUrl = createSelector([selectDomain, selectMyProfileId], (state, myProfileId) =>
  get(`${myProfileId}.publicUrl`, state)
);

export const selectMyProfileList = createSelector([selectDomain, currentUserIdSelector], (profiles, currentUserId) =>
  (Object.values(profiles) ?? []).reduce((arr, profile) => {
    const isMine =
      profile._id === currentUserId ||
      profile.owner === currentUserId ||
      profile.userId === currentUserId ||
      (profile?.members && profile?.members?.map(member => member.user).includes(currentUserId));
    if (isMine && profile.state !== 'deleted') {
      arr.push({
        _id: profile._id,
        name: profile.name,
        firstName: profile.firstName,
        lastName: profile.lastName,
        additionalName: profile.additionalName,
        useAdditionalName: profile.useAdditionalName,
        pronouns: profile.basicInfo.pronouns,
        avatar: profile.basicInfo?.imageUri?.url,
        type: profile.type,
        publicUrl: profile.publicUrl,
        owner: profile.owner,
        userId: profile.userId,
        status: profile.status,
        members: profile.members
      });
    }
    return arr;
  }, [])
);

export const selectMyTalentProfile = createSelector([selectMyProfileList], myProfiles =>
  myProfiles.find(({ type }) => type === 'talent')
);

export const selectProfile = createSelector(
  [selectDomain, selectActiveProfileId],
  (profiles, activeProfileId) => profiles[activeProfileId]
);

export const selectIsProfileLoaded = createSelector(
  [selectDomain, selectActiveProfileId],
  (profiles, activeProfileId) => get(`${activeProfileId}._id`, profiles)
);

export const selectIsProfileLoading = state => !!get('ui.apiCalls.profiles.loading', state);

export const selectProfileProperty = property =>
  createSelector([selectDomain, selectActiveProfileId], (profiles, activeProfileId) =>
    get(`${activeProfileId}.${property}`, profiles)
  );

export const selectMyProfileProperty = property =>
  createSelector([selectDomain, selectMyProfileId], (profiles, myProfileId) =>
    get(`${myProfileId}.${property}`, profiles)
  );

export const selectProfileByPublicUrl = publicUrl =>
  createSelector([selectDomain], profiles =>
    find(profile => profile.publicUrl === publicUrl || profile.talentId === publicUrl, profiles)
  );

export const selectProfileItems = createSelector([selectDomain], profiles =>
  map(
    profile => ({
      _id: profile._id,
      name: profile.name,
      avatar: profile.basicInfo?.imageUri?.url,
      type: profile.type,
      publicUrl: profile.publicUrl,
      owner: profile.owner,
      userId: profile.userId,
      status: profile.status,
      members: profile.members
    }),
    profiles
  )
);

export const hasProfileEditPermission = createSelector(
  [
    currentUserPropertySelector('_id'),
    selectProfileProperty('userId'),
    selectProfileProperty('owner'),
    selectProfileProperty('members')
  ],
  (currentUserId, profileUserId, profileOwner, companyMembers) => {
    const companyMembersIdList = companyMembers ? companyMembers.map(member => member.user) : [];

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    }

    const memberIndex = getKeyByValue(companyMembersIdList, currentUserId);

    const permissionsArr = [];
    if (profileOwner) permissionsArr.push(profileOwner);
    if (profileUserId) permissionsArr.push(profileUserId);
    return (
      permissionsArr.includes(currentUserId) ||
      (memberIndex !== undefined && companyMembers[memberIndex].role === 'admin')
    );
  }
);

export const canPublishProject = createSelector(
  [selectProfileProperty('projects')],
  projects => size(filter(project => project.status === 'published', projects)) < 8
);

export const selectIsCompanyView = createSelector([selectProfileProperty('owner')], owner => Boolean(owner));
