import React from 'react';

import placesService from 'api/placesService';

import FormAsyncSelect from './FormAsyncSelect';

function FormSelectLocation({
  value,
  onChange,
  isClearable,
  label,
  hintText,
  error,
  noOptionsMessage,
  locations = [],
  ...rest
}) {
  const searchPlace = React.useCallback(
    search =>
      placesService.searchPlace(search).then(({ data }) => {
        const res = data.data.predictions
          .filter(place => {
            let match = false;
            locations.every(loc => {
              match = loc.officeAddress === place.description;
              if (match) return false;
              return true;
            });
            if (!match) return place;
          })
          .map(place => ({
            label: place.description,
            name: place.description,
            value: place.description,
            id: place.place_id
          }));
        return res;
      }),
    []
  );

  return (
    <FormAsyncSelect
      label={label}
      apiCall={searchPlace}
      hintText={hintText}
      menuPosition="absolute"
      noOptionsMessage={noOptionsMessage}
      menuPlacement="bottom"
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      value={value}
      onChange={onChange}
      isClearable={isClearable}
      error={error}
      {...rest}
    />
  );
}

export default FormSelectLocation;
