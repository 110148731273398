import React, { useState } from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import Spinner from 'common/Spinner';
import { backOfficeService, userService } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth';
import { userSelectors } from '../../store/user';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const DeleteAccountConfirmation = ({ closePortal, data, name, onSuccess }) => {
  const [submitting, updateSubmitting] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  const deleteAccount = () => {
    updateSubmitting(true);

    const request =
      data.from === 'backOffice' ? backOfficeService.deleteUserAccount(data.userId) : userService.deleteAccount();

    request
      .then(() => updateSubmitting(false))
      .then(closePortal)
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('activeProfileType');
        localStorage.removeItem('activeProfileId');

        dispatch(authActions.logout(user));
        if (data.onConfirmation) data.onConfirmation();
        if (onSuccess) onSuccess();
      });
  };

  const message =
    data.from === 'backOffice' ? (
      <div>
        This will delete only this soloist account. If you want to delete the entire user, proceed to the user page.{' '}
        <br />
        <br /> Delete this account?
      </div>
    ) : (
      <Typography variant="medium">
        Are you absolutely sure you want to delete your account? <br /> This cannot be undone.
      </Typography>
    );

  return (
    <TxplModal
      title="Delete account"
      name={name}
      backgroundColor="#FF5151"
      headerBorderColor="rgba(221, 226, 255, 0.2)"
      size="small"
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <StyledButtonsContainer>
          {submitting && <Spinner isOnGreen />}

          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </ContainedButton>

          <ContainedButton onClick={deleteAccount} backgroundColor="white" color="#FF5151" forceDisplayText>
            Delete Account
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      {message}
    </TxplModal>
  );
};

export default withRenderPortal('delete-account-confirmation')(DeleteAccountConfirmation);
