import styled from '@emotion/styled';
import React from 'react';
import { pageSize } from 'utils/devices';

const StyledSidebar = styled.div`
  position: sticky;
  top: 24px;
  padding: 12px;
  background-color: rgba(26, 27, 30, 1);
  border-radius: 16px;
  box-sizing: border-box;
  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    width: 324px;
  }
  @media ${pageSize.S} {
    display: flex;
    justify-content: space-between;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    padding: 8px;
  }
`;

const Sidebar = ({ children, className }) => <StyledSidebar className={className}>{children}</StyledSidebar>;

export default Sidebar;
