import React from 'react';
import capitalize from 'lodash/fp/capitalize';
import get from 'lodash/fp/get';
import isUndefined from 'lodash/fp/isUndefined';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import options from 'utils/options';
import FormSelect from 'common/form/FormSelect';
import useViewport from 'hooks/useViewport';
import Flex from 'common/styles/Flex';
import ContainedButton from 'common/ContainedButton';
import commonStyles from 'utils/common-styles';
import isPropertyPopulated from 'utils/isPropertyPopulated';
import Icon from 'common/Icon';

const GridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px;
  margin-top: 6px;
  @media ${pageSize.M} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${pageSize.S} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

const ButtonsWrapper = styled(Flex)`
  @media ${pageSize.S}, ${pageSize.L} {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  @media ${pageSize.XS} {
    display: grid;
    gap: 6px;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledButton = styled(ContainedButton)`
  & .button-text {
    display: flex;
    align-items: center;
    white-space: pre;
  }
  @media ${pageSize.XS} {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const MoreFiltersCount = styled.span`
  ${commonStyles.ui_text_tiny}
  font-weight: 600;
  background: #ff5151;
  border-radius: 8px;
  padding: 0px 4px;
  margin-left: 8px;
`;

function Filters({
  filters,
  found,
  initialFilters,
  loading,
  moreFiltersCount,
  notFoundText = 'No Matching Results Found',
  onChange,
  onReset,
  onSave,
  onSubmit,
  openFilterModal,
  order,
  show = true,
  values,
  xsLimit
}) {
  const { isXS } = useViewport();

  const foundEl = <>show {loading ? <Icon size={13} icon="loader" color="#fff" /> : found} results</>;

  const hasInitialFilters = isPropertyPopulated(initialFilters);
  const isFiltersSelected = isPropertyPopulated(values) || hasInitialFilters;
  const matchValue = str => {
    return str.replace('-', '');
  };

  return (
    <GridView>
      {!!order?.length &&
        order.map((item, index) => {
          const key = item.key ?? item;
          const placeholder = item.placeholder ?? capitalize(key);
          const selectOptions = options(get(key, filters), key).sort((a, b) =>
            key === 'experience'
              ? matchValue(a?.value) - matchValue(b?.value)
              : a?.value?.toString().localeCompare(b?.value?.toString())
          );
          if (isXS && index + 1 > xsLimit) return null;
          return (
            <FormSelect
              key={index}
              isMulti
              inputGap={'0px'}
              padding={'13px 18px'}
              placeholder={placeholder}
              name={key}
              onChange={onChange}
              value={get(key, values)}
              options={selectOptions}
            />
          );
        })}
      {isXS && (
        <ContainedButton
          className="stretched"
          icon="filter"
          backgroundColor="rgba(204, 213, 255, 0.11)"
          height={'max-content'}
          padding="12px 16px"
          width="100%"
          onClick={openFilterModal}
          disabled={!isPropertyPopulated(filters)}
        >
          more Filters
          {isFiltersSelected && !!moreFiltersCount && <MoreFiltersCount>{moreFiltersCount}</MoreFiltersCount>}
        </ContainedButton>
      )}
      {isFiltersSelected && (
        <>
          <ButtonsWrapper gap={6} justifyEnd alignCenter>
            {isXS && onSave && hasInitialFilters && (
              <StyledButton
                className="transparent"
                backgroundColor={isXS ? 'transparent' : 'rgba(204, 213, 255, 0.11)'}
                height="40px"
                padding="12px 16px"
                onClick={onSave}
              >
                Save this search
              </StyledButton>
            )}
            <StyledButton
              className={isXS ? 'transparent' : ''}
              backgroundColor={isXS ? 'transparent' : 'rgba(204, 213, 255, 0.11)'}
              height="40px"
              padding="12px 24px"
              onClick={onReset}
            >
              Reset
            </StyledButton>
            {show && (
              <StyledButton className={'grid-align-right'} height="40px" padding="12px 24px" onClick={onSubmit}>
                {!isUndefined(found) ? found > 0 || loading ? <>{foundEl}</> : <>{notFoundText}</> : <>show results</>}
              </StyledButton>
            )}
          </ButtonsWrapper>
        </>
      )}
    </GridView>
  );
}

export default Filters;
