import styled from '@emotion/styled';

export const IconWrapper = styled.div`
  width: 100%;
  margin-bottom: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
