import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash/fp';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';
import CircleButton from 'common/CircleButton';

import Typography from 'components/shared/Typography';
import Asset from './Asset';
import LightBoxUser from './LightBoxUser';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow-y: scroll;

  & .close-icon {
    cursor: pointer;
    color: rgba(230, 234, 255, 0.35);
  }
`;

const HeaderWithClose = styled.div`
  ${commonStyles.ui_heading_3}
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px 0px;
  margin: 0px 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);

  @media ${pageSize.XS} {
    align-items: flex-start;
    border: none;
  }
`;

const OneLineAndUserData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-right: 16px;

  & > div {
    ${commonStyles.ui_heading_3}
    text-decoration: underline;

    @media ${pageSize.XS} {
      margin-bottom: 16px;
    }
  }

  @media ${pageSize.XS} {
    margin-top: 10px;
    flex-direction: column;
    align-items: start;
  }
`;

const UserWrapper = styled.div`
  @media ${pageSize.S} {
    margin-left: auto;
  }
`;

const StyledCircleButton = styled(CircleButton)`
  margin-left: auto;
  color: red;
`;

const CarouselItem = styled.div`
  width: calc(100% - 32px);
  height: 100%;
  margin: auto;
`;

const Title = styled.div`
  ${commonStyles.ui_heading_3}
  padding: 16px 16px 0 16px;
  vertical-align: center;
`;

const Description = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(242, 244, 255, 0.9);
  padding: 4px 16px 24px 16px;
`;

const StyledIndicators = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`;

const StyledIndicator = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${props => (props.selected ? 'white' : 'rgba(221, 226, 255, 0.2)')};
  border-radius: 8px;
  margin: 0px 4px;
  cursor: pointer;
`;
const CarouselWrapper = styled.div`
  @media ${pageSize.S} {
    height: calc(100% - 210px);
  }
  @media ${pageSize.XS} {
    height: calc(100% - 282px);
  }
  .carousel-root,
  .carousel,
  .slider-wrapper,
  .slider {
    height: 100%;
  }
`;

const getTitle = (title = '') => {
  const sliced = title.slice(0, 30);

  return title.length > 30 ? `${sliced}...` : sliced;
};

const AssetsLightBoxSmall = ({ assetIndex, assets, className, closeModal }) => {
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChange = slideIndex => {
    setSelectedIndex(slideIndex);
  };

  useEffect(() => {
    setSelectedAsset(assets[selectedIndex]);
  }, [selectedIndex, assets, setSelectedIndex]);

  useEffect(() => {
    setSelectedIndex(assetIndex);
  }, [assetIndex, setSelectedIndex]);

  if (!assets || isEmpty(assets)) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <HeaderWithClose>
        <OneLineAndUserData>
          <div>{getTitle('Rich library of sounds to prov...')}</div>

          <UserWrapper>
            <LightBoxUser />
          </UserWrapper>
        </OneLineAndUserData>

        <div style={{ marginLeft: 'auto' }}>
          <StyledCircleButton iconName="close" onClick={closeModal} alt="Close" />
        </div>
      </HeaderWithClose>

      <CarouselWrapper>
        <Carousel
          onChange={onChange}
          selectedItem={selectedIndex}
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
        >
          {assets &&
            assets.map(asset => (
              <CarouselItem key={asset.assetId}>
                <Asset asset={asset} isSmall />
              </CarouselItem>
            ))}
        </Carousel>

        <StyledIndicators>
          {Array.from({ length: assets.length })
            .map((_, index) => index)
            .map(index => (
              <StyledIndicator key={index} selected={selectedIndex === index} onClick={() => setSelectedIndex(index)} />
            ))}
        </StyledIndicators>
      </CarouselWrapper>

      <div>
        <Title>{selectedAsset?.title}</Title>
      </div>

      <div>
        <Typography mb={8} ml={16} variant="h3">
          {selectedAsset?.name}
        </Typography>
        <Description>{selectedAsset?.description?.slice(0, 250)}</Description>
      </div>
      <div />
    </Wrapper>
  );
};

export default AssetsLightBoxSmall;
