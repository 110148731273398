import React from 'react';
import { Container, Dot, Line } from './styled.components';

const Stepper = ({ steps, activeStep }) => {
  if (!activeStep) return null;

  return (
    <Container>
      {Array.from({ length: steps }, (_, i) => (activeStep === i + 1 ? <Line key={i} /> : <Dot key={i} />))}
    </Container>
  );
};

export default Stepper;
