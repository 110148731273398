import React from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput, { Required, StyledLabel } from 'common/form/FormInput';

import ContainedButton from 'common/ContainedButton';
import { lifeServices } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import Spinner from 'common/Spinner';
import FormSelect from 'common/form/FormSelect';
import FormDropZone from 'common/form/FormDropZone';
import isEmpty from 'lodash/isEmpty';
import useForm from 'hooks/useForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Icon from 'common/Icon';
import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import Tooltip from 'components/Tooltip/Tooltip';
import RichTextEditor from 'common/RichTextEditor';

const initialLayout = {
  label: 'Media on the left',
  value: 'left'
};

const layoutOptions = [
  initialLayout,
  {
    label: 'Media on the right',
    value: 'right'
  }
];

const TabsWrapper = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  .react-tabs__tab-list {
    display: flex;
    width: fit-content;
    border: 2px solid rgba(204, 213, 255, 0.11);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0;
    padding: 0;
  }

  .react-tabs__tab {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: none;
    bottom: unset;
    ${commonStyles.caption}
    color: rgba(230, 234, 255, 0.35);
    padding: 6px 10px;

    .tab-icon {
      margin-right: 8px;
      path {
        stroke: rgba(230, 234, 255, 0.35);
        fill: rgba(230, 234, 255, 0.35);
      }
    }
  }

  .react-tabs__tab.react-tabs__tab--selected {
    background-color: rgba(204, 213, 255, 0.11);
    color: #fff;

    .tab-icon {
      path {
        stroke: #fff;
        fill: #fff;
      }
    }
  }

  .react-tabs__tab:focus {
    box-shadow: none;
  }
  .react-tabs__tab:focus:after {
    background: transparent;
  }
`;

const fields = life => ({
  title: { initialValue: life?.title ?? '', validate: ['isRequired'] },
  description: { initialValue: life?.description ?? '', validate: ['isRequired'] },
  attachment: { initialValue: life?.attachment?._id ?? '', validate: ['isRequired'] },
  layout: {
    initialValue: life?.mediaPosition ? layoutOptions.find(it => it.value === life?.mediaPosition) : initialLayout
  }
});

const transform = ({ layout, ...rest }) => ({
  ...rest,
  type: 'text_media',
  mediaPosition: layout.value
});

const onValidate = formFields => {
  const customErrors = {};
  if (!formFields.getIn(['title', 'value'])) {
    customErrors.title = 'Title is required';
  }
  if (!formFields.getIn(['description', 'value'])) {
    customErrors.description = 'Description is required';
  }
  if (!formFields.getIn(['attachment', 'value'])) {
    customErrors.attachment = 'Media is required';
  }

  return customErrors;
};

const LifeTextMediaModal = ({ closePortal, data, name }) => {
  const { life } = data;
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const dispatch = useDispatch();

  const categoryId = data?.categoryId ?? life?.categoryId;

  const { errors, onFieldChange, onSubmit, submitting, triggerErrors, values } = useForm({
    fields: fields(life),
    onValidate,
    callApi: formFields => callApi(transform(formFields)),
    onSuccess: resData => onSuccess(resData),
    closePortal: () => {
      setTimeout(() => {
        closePortal();
      }, [250]);
    }
  });

  const callApi = async formFields => {
    return isEmpty(life)
      ? lifeServices.addLife(profileId, categoryId, formFields)
      : lifeServices.editLife(profileId, life?._id, formFields);
  };

  const onSuccess = async ({ data: updatedLife }) =>
    dispatch(
      profileActions.updateProfileProperty({
        property: 'lives',
        updateType: isEmpty(life) ? 'insert' : 'update',
        value: updatedLife,
        profileId
      })
    );

  const handleSubmit = e => {
    e.preventDefault();
    const { errors } = triggerErrors()(onValidate);
    if (errors) return;

    onSubmit();
  };

  return (
    <TxplModal
      title="Add text + media"
      name={name}
      size="large"
      appElement={document.getElementById('root-modal')}
      allowLine
      padding="56px 0 80px"
      XSpadding="56px 0 80px"
      addPaddingTop
      XSaddPaddingTop={'14px'}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton form="lifeTextMediaModal" type="submit" disabled={submitting}>
            Save
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={handleSubmit} id="lifeTextMediaModal" className="job-section-rich-text">
        <FormInput
          mb={56}
          label="Heading"
          value={values.title}
          error={errors.title}
          maxLength={50}
          onChange={e => onFieldChange('title')(e.target.value)}
          required
          autoFocus
          className="title-required"
        />
        <StyledLabel>
          Paragraph <Required />
        </StyledLabel>
        <RichTextEditor
          containerStyle={{ marginTop: '0.5rem' }}
          label="Paragraph"
          modules={{
            toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']]
          }}
          value={values.description}
          onChange={text => onFieldChange('description')(text)}
          hasError={!!errors.description}
          maxLength={1000}
          className="description-required"
        />
        <TabsWrapper mt={56}>
          <Tabs>
            <TabList>
              <Tab>
                <Icon className="tab-icon" icon="upload" />
                upload file
              </Tab>
              <Tab disabled data-tip data-for="job-tooltip-embedded-link">
                <Icon className="tab-icon" icon="link" />
                embed link
              </Tab>
              <Tooltip className="secondary" tooltipId="job-tooltip-embedded-link" place="top">
                Coming Soon 😉
              </Tooltip>
            </TabList>
            <TabPanel>
              <FormDropZone
                mt={16}
                hintText="Images 5Mb or less. Video files 50Mb or less"
                single
                accept="image/*, video/quicktime, video/mp4"
                assets={life?.attachment ? [life?.attachment] : []}
                error={errors.attachment}
                setAssetIds={file => onFieldChange('attachment')(file)}
                className="attachment-required"
              />
            </TabPanel>
            <TabPanel>
              <FormInput mt={16} disabled />
            </TabPanel>
          </Tabs>
        </TabsWrapper>

        <FormSelect
          mt={56}
          label="Layout"
          inputGap={'6px'}
          value={values.layout}
          options={layoutOptions}
          isSearchable={false}
          borderRadius={8}
          padding={'13px 18px'}
          onChange={onFieldChange('layout')}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('life-text-media-modal')(LifeTextMediaModal);
