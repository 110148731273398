import React from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput, { Required, StyledLabel } from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import { lifeServices } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import Spinner from 'common/Spinner';
import isEmpty from 'lodash/isEmpty';
import useForm from 'hooks/useForm';
import RichTextEditor from 'common/RichTextEditor';

const fields = life => ({
  title: { initialValue: life?.title ?? '', validate: ['isRequired'] },
  description: { initialValue: life?.description ?? '', validate: ['isRequired'] }
});

const transform = ({ description, title }) => ({
  title,
  description
});

const onValidate = formFields => {
  const customErrors = {};

  if (!formFields.getIn(['title', 'value'])) {
    customErrors.title = 'Title is required';
  }
  if (!formFields.getIn(['description', 'value'])) {
    customErrors.description = 'Description is required';
  }

  return customErrors;
};

const LifeTextModal = ({ closePortal, data, name }) => {
  const { life } = data;
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const dispatch = useDispatch();
  const categoryId = data?.categoryId ?? life?.categoryId;

  const { errors, onFieldChange, onSubmit, submitting, triggerErrors, values } = useForm({
    fields: fields(life),
    onValidate,
    callApi: data => callApi(transform(data)),
    closePortal
  });

  const callApi = ({ description, title }) => {
    const data = {
      title,
      description,
      type: 'text'
    };

    const promise = isEmpty(life)
      ? lifeServices.addLife(profileId, categoryId, data)
      : lifeServices.editLife(profileId, life?._id, data);

    return promise.then(res => {
      const { data } = res.data;
      dispatch(
        profileActions.updateProfileProperty({
          property: 'lives',
          updateType: isEmpty(life) ? 'insert' : 'update',
          value: data,
          profileId
        })
      );
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { errors } = triggerErrors()(onValidate);
    if (errors) return;

    onSubmit();
  };

  return (
    <TxplModal
      title="Add text"
      name={name}
      size="large"
      appElement={document.getElementById('root-modal')}
      allowLine
      titleFontSize={24}
      padding="56px 0 80px"
      XSpadding="56px 0 80px"
      addPaddingTop
      XSaddPaddingTop={'14px'}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton form="lifeTextModal" type="submit" disabled={submitting}>
            Save
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={handleSubmit} id="lifeTextModal" className="job-section-rich-text">
        <FormInput
          mb={56}
          label="Heading"
          value={values.title}
          error={errors.title}
          maxLength={50}
          onChange={e => onFieldChange('title')(e.target.value)}
          required
          autoFocus
          className="title-required"
        />
        <StyledLabel>
          Paragraph <Required />
        </StyledLabel>
        <RichTextEditor
          containerStyle={{ marginTop: '0.5rem' }}
          label="Paragraph"
          modules={{
            toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']]
          }}
          value={values.description}
          hasError={!!errors.description}
          maxLength={1000}
          className="description-required"
          onChange={text => onFieldChange('description')(text)}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('life-text-modal')(LifeTextModal);
