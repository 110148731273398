import React, { useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { excludeByType } from 'utils/tagUtils';

import { Column, Table } from 'components/Table';
import ContainedButton from 'common/ContainedButton';
import ExportCsv from 'common/ExportCsv';

import BackOfficeTagModal from './BackOfficeTagModal';

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 6px;
`;
const StyledImage = styled.img`
  max-width: 30px;
  margin: 0 auto;
  max-height: 30px;
`;
const StyledImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const tagRouteToType = {
  platforms: 'platform',
  tools: 'tool',
  skills: 'skill',
  genres: 'genre',
  tags: 'tag',
  companies: 'company',
  'disciplines-and-roles': 'entry'
};

const typeToLabel = {
  platform: 'Platforms',
  tool: 'Tools',
  skill: 'Skills',
  genre: 'Genres',
  tag: 'Tags',
  company: 'Companies',
  entry: 'Disciplines and roles'
};

const imageBaseUrl = `${process.env.REACT_APP_CLOUDINARY_BASE_IMAGE_URL}`;

const BackOfficeTags = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const routeName = useMemo(() => location.pathname.split('/').slice(-1)[0], [location.pathname]);
  const type = useMemo(() => tagRouteToType[routeName], [routeName]);

  useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Knowledge base' }, { label: typeToLabel[type] }]));
  }, [type, dispatch]);

  const exclude = useMemo(() => excludeByType[type], [type]);

  return (
    <>
      <BackOfficeTagModal />

      <ActionsRow>
        <ExportCsv path={`tags/export-csv?type=${type}`} />
        <ContainedButton opens="backOffice-tag-modal" data={{ type: tagRouteToType[routeName], exclude, tableRef }}>
          Add new {tagRouteToType[routeName].toUpperCase()}
        </ContainedButton>
      </ActionsRow>

      <Table
        ref={tableRef}
        keyId="_id"
        paginate
        endpoint="/tags/paginate"
        dataKey="tags"
        queryParams={{ type: tagRouteToType[routeName] }}
      >
        {!exclude?.lists && type !== 'tag' && <Column title="List" keyName="lists" search />}
        {!exclude?.name && <Column title="Name" keyName="name" width="11%" search />}
        {type === 'tag' && <Column title="List" keyName="lists" width="10%" search />}
        {!exclude?.logo && (
          <Column
            title="Logo"
            keyName="imageUrl"
            width={68}
            render={tag => (
              <StyledImageContainer>
                {!tag.image && 'NO DATA'}
                {tag.image && <StyledImage src={tag.image?.url} alt="No Data" />}
              </StyledImageContainer>
            )}
          />
        )}

        {!exclude?.theXPlaceLogo && (
          <Column
            title="TheXPlace LOGO"
            keyName="imageUrl"
            render={tag => (
              <StyledImageContainer>
                {!tag.theXPlaceLogo && 'NO DATA'}
                {tag.theXPlaceLogo && <StyledImage src={`${imageBaseUrl}/${tag.theXPlaceLogo}`} alt="No Data" />}
              </StyledImageContainer>
            )}
          />
        )}
        {!exclude?.igdbLogo && (
          <Column
            title="IGDB LOGO"
            keyName="imageUrl"
            render={tag => (
              <StyledImageContainer>
                {!tag.igdbLogo && 'NO DATA'}
                {tag.igdbLogo && <StyledImage src={`${imageBaseUrl}/${tag.igdbLogo}`} alt="No Data" />}
              </StyledImageContainer>
            )}
          />
        )}
        {!exclude?.associates && (
          <Column title="Associations" keyName="associates" render={tag => tag.associates.join(', ')} search />
        )}
        {!exclude?.usage && <Column title="Usage" keyName="usage" search width={47} />}
        <Column
          title=""
          type="action"
          width={46}
          opens="backOffice-tag-modal"
          data={tag => ({
            tableRef,
            tagId: tag._id,
            isEdit: true,
            type: tagRouteToType[routeName],
            exclude
          })}
        />
      </Table>
    </>
  );
};

export default BackOfficeTags;
