const jobTypes = [
  {
    value: 'ON_SITE',
    label: 'On-site'
  },
  {
    value: 'REMOTE',
    label: 'Remote'
  },
  {
    value: 'BOTH',
    label: 'Both remote and on-site'
  }
];

export default jobTypes;
