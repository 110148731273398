import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const StyledLifePageBarButton = styled.button`
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  height: 32px;
  border-radius: 4px;
  background-color: transparent;
  &.active {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    height: 32px;
    background: rgba(204, 213, 255, 0.11);
    border-radius: 4px;
  }
  @media ${pageSize.XS} {
    width: 100%;
  }
`;
export const StyledText = styled.span`
  width: fit-content;
  height: 16px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  &.activeText {
    color: rgba(230, 234, 255, 0.35);
  }
`;
