import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { backOfficeActions } from 'store/backOffice';
import commonStyles from 'utils/common-styles';

import DashboardBox from './DashboardBox';
import menuItems from './menu';
import analystMenuItems from './analystMenu';
import { getDecodedIdToken } from 'utils/cookies';

const Wrapper = styled.div`
  ${commonStyles.ui_text_medium}
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const operationsItems = menuItems.filter(itm => itm.label === 'Operations')[0].subMenuItems;
const analystOperationsItems = analystMenuItems.filter(itm => itm.label === 'Operations')[0].subMenuItems;
const knowledgeBaseItems = menuItems.filter(itm => itm.label === 'Knowledge Base')[0].subMenuItems;
const adminPanelItems = menuItems.filter(itm => itm.label === 'Admin Panel')[0].subMenuItems;

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'TheXPlace Back Office' }]));
  }, [dispatch]);

  const decoded = getDecodedIdToken();
  const isSuperUser = decoded.roles.includes('super-admin');

  return (
    <Wrapper>
      <DashboardBox
        title="Operations"
        iconColor="white"
        icon="operations"
        items={isSuperUser ? operationsItems : analystOperationsItems}
      />
      {isSuperUser && <DashboardBox title="Knowledge Base" icon="knowledge-base" items={knowledgeBaseItems} />}
      {isSuperUser && <DashboardBox title="Admin Panel" icon="admin-panel" items={adminPanelItems} />}
    </Wrapper>
  );
};

export default Dashboard;
