const useScrollToMandatoryField = () => {
  const handleScroll = error => {
    if (error && Object.keys(error)[0]) {
      const firstError = document.getElementsByClassName(`${Object.keys(error)[0]}-required`);
      firstError?.length &&
        Array.from(firstError).forEach(element =>
          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        );
    }
  };

  return { handleScroll };
};

export default useScrollToMandatoryField;
