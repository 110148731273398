import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import FormCropper from 'common/form/FormCropper';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import { fileSizeInMb, getImageDimensions } from 'utils/assets';
import Typography from 'components/shared/Typography';

const StyledCancelButton = styled(ContainedButton)`
  margin-right: 6px;
`;

const CropModal = ({ closePortal, data, name }) => {
  const { aspectRatio, initialImage, isResizable, minHeight, minWidth, viewMode } = data;
  const cropRef = React.useRef();
  const [error, setError] = React.useState(false);

  const handleCancel = () => closePortal();

  const handleSubmit = async () => {
    const img = cropRef.current.getImage();

    const size = fileSizeInMb(img);
    if (size > 10) {
      return setError('File size must be less than 10mb.');
    }

    const { height } = await getImageDimensions(img);

    const overlay = {
      height: height / 20,
      width: (height / 20) * 4.5
    };

    data.onCrop(img, { overlay });
    closePortal();
  };

  return (
    <TxplModal
      name={name}
      size="small"
      title="Crop image to fit dimensions"
      nonFullScreenOnMobile
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <StyledCancelButton forceDisplayText onClick={handleCancel} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </StyledCancelButton>
          <ContainedButton type="button" forceDisplayText onClick={handleSubmit}>
            Done
          </ContainedButton>
        </>
      }
    >
      <FormCropper
        aspect={aspectRatio}
        ref={cropRef}
        image={URL.createObjectURL(initialImage)}
        isResizable={isResizable}
        minWidth={minWidth}
        minHeight={minHeight}
        viewMode={viewMode}
      />
      {error && (
        <Typography color="red" error variant="small">
          {error}
        </Typography>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('cropper-modal')(CropModal);
