import React, { useEffect, useState } from 'react';

import { Container, ToastItem, ToastList } from './styled.components';

const Toast = ({ data }) => {
  const [list, setList] = useState(data);
  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = data.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    data.splice(toastListItem, 1);
    setList([...list]);
  };

  useEffect(() => {
    setList([...data]);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (data.length && list.length) {
        deleteToast(data[0].id);
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [data, list]);

  return (
    <Container>
      <ToastList>
        {list.map(item => (
          <ToastItem key={item.id} status={item.status}>
            {item.message}
          </ToastItem>
        ))}
      </ToastList>
    </Container>
  );
};

export default Toast;
