import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const Container = styled.div`
  @media ${pageSize.S}, ${pageSize.XS} {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    right: -100%;
    transition: 0.5s;
    transition-timing-function: cubic-bezier(0.41, 0.18, 0.59, 0.76);
    ${props => props.visible === true && 'right: 0;'}
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: #1a1b1e;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  height: 72px;
  @media ${pageSize.XS} {
    padding: 0 16px;
  }

  ${({ isEmpty }) => isEmpty && 'padding:0; height: 72px'}
`;

export const Content = styled.div`
  height: calc(100% - 92px);
  background: #111112;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 2px solid rgba(204, 212, 255, 0.11);
  padding: 0 6px 16px 24px;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  @media ${pageSize.S} {
    padding: 0 21px 16px 21px;
  }
  @media ${pageSize.XS} {
    padding: 0 16px 16px 16px;
  }
`;
