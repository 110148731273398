import React from 'react';

const TextURL = ({ text }) => {
  if (!text) return null;

  const urlRegex = /((?:(?:https?:\/\/)|(?:www\.))[^\s]+)/g;
  const parts = text.split(urlRegex).map((part, index) => {
    const match = part?.match(urlRegex);
    if (match) {
      const url = match[0];
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'https://' + hyperlink;
      }
      return (
        <a key={index} href={hyperlink} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }
    return part;
  });

  return <span>{parts}</span>;
};

export default TextURL;
