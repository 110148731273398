import styled from '@emotion/styled';
import Tag from 'common/Tag/Tag';
import commonStyles from 'utils/common-styles';

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  background: rgba(204, 213, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(221, 226, 255, 0.2);
`;

export const TagItem = styled(Tag)`
  ${commonStyles.ui_text_tiny}
  font-family: Inter;
  height: 18px;
  border-radius: 4px;
  color: ${props => props.color ?? '#ffffff'};
`;

export const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  :last-child {
    border-bottom: none;
  }
`;

export const NotificationListItem = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  cursor: pointer;
  padding: 16px 16px 24px;
  margin: 8px 0;
  border-radius: 8px;
  ${props => props.isFirst && 'margin-top: 3px;'}
  &:hover {
    background-color: rgba(204, 213, 255, 0.05);
  }
`;

export const NotificationListContent = styled.div`
  display: inline-block;
  width: 100%;
`;

export const NotificationMessage = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(230, 234, 255, 0.6);
  text-transform: none;
  text-align: left;
  line-height: 22px;
  white-space: normal;
  word-break: break-word;
  margin: 0;
`;

export const ActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const ReadIndicatorWrapper = styled.div`
  position: absolute;
  right: 0;
`;

export const Timestamp = styled.span`
  display: inline-block;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  float: left;
  color: rgba(138, 128, 255, 1);

  text-transform: lowercase;
`;
