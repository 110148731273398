import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';

export const StyledCancelButton = styled(ContainedButton)`
  margin: 0 3px;
`;

export const StyledTitle = styled.span`
  ${commonStyles.ui_heading_3}
`;

export const StyledFormInput = styled(FormInput)`
  margin-bottom: 40px;
`;

export const StyledIconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(15, 1fr);
  gap: 8px;
  margin: 8px 0 64px 0;
`;

export const StyledColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  margin-top: 8px;
`;

export const StyledColorButton = styled.button`
  height: 32px;
  width: 32px;
  border: none;
  ${props => (props.color ? `background-color: ${props.color};` : '')}
  &.benefit_color-selected {
    border: 4px solid #ffffff;
    box-sizing: border-box;
  }
`;
export const Required = styled.span`
  padding-left: 3px;
  font-size: 19px;
  &::after {
    content: '*';
  }
  ${props => (props.error ? 'color: #FF5151' : 'color: rgba(229,234,255,0.35)')}
`;
