import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import get from 'lodash/fp/get';
import { pageSize } from 'utils/devices';
import { ReactComponent as IconPurpleGradient } from 'images/assets/icons_16x16/purple-gradient.svg';
import TalentPortfolioView from 'pages/talent/portfolio/TalentPortfolioView';
import AddTalentProject from 'pages/talent/portfolio/AddTalentProject';
import PortfolioMenu from 'pages/talent/PortfolioMenu';
import Spinner from 'common/Spinner';
import { profileSelectors } from 'store/profile';
import Page404 from 'pages/service-page/Page404';

const StyledContainer = styled.div`
  position: absolute;
  max-width: 1350px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  @media ${pageSize.XS} {
    padding: 0 16px;
  }

  @media ${pageSize.S} {
    padding: 0 81px;
  }

  @media ${pageSize.M} {
    padding: 0 58px;
  }

  @media ${pageSize.L} {
    padding: 0 54px;
  }
`;
const StyledPurpleGradient = styled(IconPurpleGradient)`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: -1;
`;
const GridCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px 16px;
  align-self: center;
  ${props => !props.noPaddingBottom && 'padding-bottom: 100px;'}

  @media ${pageSize.XS} {
    grid-gap: 16px 16px;
  }

  @media ${pageSize.S} {
    grid-gap: 16px 16px;
  }

  @media ${pageSize.M} {
    grid-gap: 0px 54px;
  }

  @media ${pageSize.L} {
    grid-gap: 0px 45px;
  }

  .grid-intro {
    padding-bottom: 16px;
  }

  @media ${pageSize.S}, ${pageSize.XS} {
    .grid-intro {
      padding-bottom: 40px;
      border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    }

    .grid-assets {
      padding-top: 24px;
    }
  }

  @media ${pageSize.L} {
    grid-template-columns: 1fr 1fr;
    .grid-assets {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 4;
    }
  }

  @media ${pageSize.M} {
    grid-template-columns: 1fr 1fr;

    .grid-assets {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 4;
    }
  }
`;
const StyledSpinner = styled(Spinner)`
  display: block;
  margin: auto;
  margin-top: 48px;
`;

const TalentPortfolioPageLayout = ({ fetchingTalent, portfolioId }) => {
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const isAddEdit = ['add'].includes(portfolioId) || hasEditPermission;
  const [project, setProject] = useState();

  const portfolioList = useSelector(profileSelectors.selectProfileProperty('projects')) || {};
  const portfolio = get(portfolioId, portfolioList);

  const showPortfolio = portfolioId !== 'add' ? !!portfolio : hasEditPermission;

  return (
    <>
      <StyledPurpleGradient />
      <StyledContainer>
        {fetchingTalent && <StyledSpinner />}

        {!fetchingTalent && !showPortfolio && <Page404 />}

        {!fetchingTalent && showPortfolio && (
          <>
            <PortfolioMenu hideNav={portfolioId === 'add'} portfolioId={portfolioId} project={project} />

            <GridCardsContainer noPaddingBottom={!isAddEdit}>
              {!isAddEdit ? (
                <TalentPortfolioView portfolioId={portfolioId} />
              ) : (
                <AddTalentProject projectId={portfolioId} setProject={setProject} />
              )}
            </GridCardsContainer>
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default TalentPortfolioPageLayout;
