import Frame from 'common/Frame';
import RichTextEditor from 'common/RichTextEditor';
import React from 'react';

const JobOverview = ({ error, hasEditPermission, overview }) => (
  <Frame
    className="overview-required job-section-rich-text"
    transparent
    titlePB={24}
    text="job overview"
    color="#CAFF33"
    canEdit={hasEditPermission}
    editMode={overview ? 'edit' : 'add'}
    noDataText="Explain the basics of this job. What is the project? What is required from a candidate? What will be the main objective(s) of this job? Who will this role report to? Will this role manage staff?"
    data={{
      field: 'overview',
      title: 'Job overview',
      subTitle: 'Explain the basics that this job implies: what the project is and what is required from an applicant.',
      text: overview,
      limit: 1200,
      required: true
    }}
    opens="text-editor-modal"
    filled={overview}
    error={error}
    required
  >
    {overview && <RichTextEditor value={overview} readOnly />}
  </Frame>
);

export default JobOverview;
