import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';
import VettingStatus from '../VettingStatus';
import Icon from '../../../common/Icon';
import VettingOutcome from '../VettingOutcome';
import VettingDate from '../VettingDate';
import VettingDueDate from '../VettingDueDate';

const StyledIdContainer = styled.p`
  overflow-x: scroll;
`;
const StatusFilterText = styled.div`
  padding-top: 5px;
`;
const StyledIcon = styled(Icon)`
  padding-top: 8px;
  padding-right: 8px;
`;
const StatusFilter = styled.div`
  width: fit-content;
  background: rgba(221, 226, 255, 0.2);
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 12px 18px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
`;
const BackOfficeVetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showCompletedStatus, setShowCompletedStatus] = useState(false);

  React.useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Operations' }, { label: 'Vetting' }]));
  }, [dispatch]);

  const handleStatusFilter = () => {
    setShowCompletedStatus(!showCompletedStatus);
    window.location.href.includes('completed') ? history.push('vetting') : history.push('vetting?completed=hide');
  };

  return (
    <>
      <StatusFilter onClick={() => handleStatusFilter()}>
        <StyledIcon icon={showCompletedStatus ? 'visible' : 'hidden'} />
        <StatusFilterText>
          {window.location.href.includes('completed') ? 'Show' : 'Hide'} Completed Items
        </StatusFilterText>
      </StatusFilter>
      <br />
      <Table keyId="_id" paginate endpoint="/backoffice/vetting" dataKey="tasks">
        <Column
          title="Task"
          keyName="task"
          render={vetting => vetting.task.toLowerCase().charAt(0).toUpperCase() + vetting.task.toLowerCase().slice(1)}
          search
          sort
        />
        <Column title="Id" keyName="vetting_id" search sort />
        <Column
          title="USER ID"
          keyName="talent"
          render={vetting => (
            <StyledIdContainer>
              {vetting.task === 'PORTFOLIO' ? vetting.project?.talent : vetting.credit?.talent}
            </StyledIdContainer>
          )}
          search
        />
        <Column
          title="Assigned"
          keyName="assigned"
          render={vetting => (vetting?.user?.firstName + ' ' + vetting?.user?.lastName).substr(0, 30)}
          search
          sort
        />
        <Column
          title="Due Date"
          keyName="due_date"
          search
          sort
          render={vetting => <VettingDueDate date={vetting.due_date} />}
        />
        <Column
          title="Updated"
          keyName="updated"
          search
          render={vetting => (
            <VettingDate date={vetting.task === 'PORTFOLIO' ? vetting.project?.updatedAt : vetting.credit?.updatedAt} />
          )}
        />
        <Column
          title="Status"
          keyName="status"
          render={vetting => <VettingStatus status={vetting.status} />}
          search
          sort
        />
        <Column
          title="Outcomes"
          keyName="outcome"
          render={vetting => <VettingOutcome status={vetting.outcome} />}
          search
          sort
        />
        <Column
          title=""
          type="action"
          width={46}
          onClick={vetting => vetting.task === 'CREDIT' && history.push(`vetting/${vetting._id}`)}
          opens="some-modal"
        />
      </Table>
    </>
  );
};

export default BackOfficeVetting;
