import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import size from 'lodash/fp/size';
import { useSelector } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import commonStyles from 'utils/common-styles';
import { profileSelectors } from 'store/profile';

import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';

import AddedMediaCoverageItems from './AddedMediaCoverageItems';

const StyledFooterContainer = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
const StyledDescription = styled.div`
  ${commonStyles.ui_text_medium}
  margin-bottom: 32px;
`;
const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const ChooseMediaCoverageModal = ({ name, data, items = [], closePortal, onChoose, onDelete, loading, single }) => {
  const mediaCoverages = useSelector(profileSelectors.selectProfileProperty('mediaCoverage'));
  const [showExisting, updateShowExisting] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(items);

  useEffect(() => {
    setSelectedMedia(items);
  }, [items]);

  const handleChoose = mediaId => {
    let updatedMedia = [...selectedMedia];
    if (selectedMedia.includes(mediaId)) {
      const index = selectedMedia.findIndex(media => media === mediaId);
      if (single) {
        updatedMedia = [];
      } else {
        updatedMedia.splice(index, 1);
      }
    } else {
      updatedMedia = single ? [mediaId] : [...updatedMedia, mediaId];
    }
    setSelectedMedia(updatedMedia);
  };

  const handleAdd = () => {
    onChoose(selectedMedia);

    closePortal();
  };

  const btnDisabled = !data.notClosePortal ? single && items.length > 0 : single && selectedMedia.length > 0;

  return (
    <TxplModal
      title="Media coverage"
      name={name}
      appElement={document.getElementById('root-modal')}
      addPaddingTop
      XSaddPaddingTop="14px"
      renderFooter={
        <StyledFooterContainer>
          {loading && <Spinner />}
          <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal} disabled={loading}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleAdd} disabled={loading} data-testid="save-portfolio-mediacoverage">
            {data.notClosePortal ? 'save' : 'add'}
          </ContainedButton>
        </StyledFooterContainer>
      }
    >
      {items.length === 0 && <StyledDescription>Did any reviews brighten your day? Share away.</StyledDescription>}

      <AddedMediaCoverageItems
        onChoose={mediaId => handleChoose(mediaId)}
        onDelete={onDelete}
        showChooseCheckbox={showExisting}
        showExisting={showExisting}
        items={selectedMedia}
        skipDeleteConfirm={data.skipDeleteConfirm}
        notClosePortal={data.notClosePortal}
      />

      {!showExisting && (
        <StyledButtonsContainer>
          {!!size(mediaCoverages) && (
            <ContainedButton
              onClick={() => updateShowExisting(!showExisting)}
              forceDisplayText
              backgroundColor="rgba(221,226,255,0.2)"
              disabled={btnDisabled}
            >
              Add from your list
            </ContainedButton>
          )}

          <ContainedButton
            opens="media-coverage-modal"
            data={data}
            backgroundColor="secondary"
            closes={name}
            disabled={btnDisabled}
          >
            Add new
          </ContainedButton>
        </StyledButtonsContainer>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('choose-media-coverage-modal')(ChooseMediaCoverageModal);
