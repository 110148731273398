import styled from '@emotion/styled';
import { companyProfileService } from 'api';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import withRenderPortal from 'hocs/withRenderPortal';
import { useErrorSnackbar, useSuccessSnackbar } from 'hooks/useReactSnackbar';
import React, { useState } from 'react';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  margin-left: auto;
`;

const DeleteCompanyMemberModal = ({ callback, closePortal, data, name }) => {
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { openErrorSnackbar } = useErrorSnackbar();

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (data.companyId && data.memberId) {
      try {
        setLoading(true);
        await companyProfileService.deleteMember(data.companyId, data.memberId);
        openSuccessSnackbar('Member deleted');
        callback && callback(data.memberId);
        closePortal();
      } catch {
        openErrorSnackbar('Failed to delete member');
      }
    }
  };

  return (
    <TxplModal
      title="Delete this member?"
      appElement={document.getElementById('root-modal')}
      hideCloseIcon
      size="small"
      nonFullScreenOnMobile
      name={name}
      padding={'40px 0'}
      headerPaddingBottom={16}
      maxWidth={520}
      contentPadding="24px 16px 16px"
      renderFooter={
        <StyledActionsContainer>
          {loading && <Spinner />}
          <ContainedButton
            icon="delete"
            color="#FF5151"
            className="transparent"
            backgroundColor="transparent"
            disabled={loading}
            onClick={handleDelete}
          >
            Yes, Delete
          </ContainedButton>
          <ContainedButton type="button" disabled={loading} onClick={closePortal}>
            no, keep
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <Typography fontSize={'17px'} color="rgba(242, 244, 255, 0.9)">
        This cannot be undone.
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('delete-company-member-modal')(DeleteCompanyMemberModal);
