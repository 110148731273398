import styled from '@emotion/styled';
import React from 'react';

const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 6px;
`;

const BackofficeActionButtons = ({ children }) => <Wrapper>{children}</Wrapper>;

export default BackofficeActionButtons;
