import styled from '@emotion/styled';

export const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 64px);
  border-top: 2px solid rgba(230, 234, 255, 0.35);
`;
export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
`;
export const StyledHeader = styled.thead`
  position: sticky;
  top: 0;
`;
export const StyledHeaderColumn = styled.tr`
  height: 50px;
  position: sticky;
  top: 0;

  & th:first-of-type {
    border-left: none !important;
  }
  & th:last-of-type {
    border-right: none !important;
  }
`;
export const StyledBody = styled.tbody`
  ${({ renderSearch }) => renderSearch && '& tr:nth-of-type(even) { background: rgba(204, 213, 255, 0.04); }'}
  ${({ renderSearch }) => !renderSearch && ' & tr:nth-of-type(odd) { background: rgba(204, 213, 255, 0.04); }'}
`;
export const StyledColumn = styled.tr`
  height: max-content;
  & td:last-child {
    border-right: none !important;
  }
`;
export const StyledInnerColumn = styled.div``;
export const StyledColumnItem = styled.td`
  height: max-content;
  white-space: nowrap;
  ${({ preWrap }) => preWrap && 'white-space: pre-line !important;'}
  word-break: break-all;
  text-align: left;
  ${({ padding }) => (padding ? `padding: ${padding};` : 'padding: 7px 20px;')}
  border-right: 1px solid rgba(204, 213, 255, 0.11);
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.width && `width: ${props.width}`};
  ${props => props.maxWidth && `max-width: ${props.maxWidth} !important`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
`;
export const StyledFormInputContainer = styled.div`
  & div input {
    padding: 6px 18px;
    box-sizing: border-box;
    width: 100%;
    min-width: 70px;
  }
  & div {
    gap: 3px;
  }
`;
export const StyledTableWrapper = styled.div`
  height: 100%;
  overflow: auto;
  ${({ adjustHeight }) => adjustHeight && 'height: auto;'}
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(221, 226, 255, 0.2);
  }
`;
export const StyledCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;
