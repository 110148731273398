import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroller';
import commonStyles from 'utils/common-styles';

export const Container = styled.div`
  flex-grow: 1;
  max-height: 740px;
  overflow: auto;
  padding-right: 12px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    height: 458px;
    border-radius: 3px;
    background: rgba(221, 226, 255, 0.2);
  }
`;
export const DefaultMessagesWrapper = styled.div`
  margin-top: 24px;
`;

export const DefaultMessage = styled.div`
  display: flex;
  align-items: center;
  background: rgba(204, 212, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 8px;
`;

export const DefaultMessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
  width: 36px;
  height: 32px;
`;
export const DefaultMessageText = styled.div`
  color: #ffffff;
  font-size: 16px;
  margin-left: 8px;
`;

export const TypingIndicatorWrapper = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(229,234,255,0.35);
  margin-bottom: 16px;
`;

export const MessageListWrapper = styled(InfiniteScroll)`
  height: 100%;
`;
