import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

const Wrapper = styled.span`
  display: inline-block;
  width: 80px;
  height: 88px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${props => (props.hasDragPermission ? 'grab' : 'unset')};

  & .image-border {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    padding: 8px;
    background: rgba(204, 213, 255, 0.11);
    border-radius: 12px;

    & > img {
      width: 40px;
      height: 40px;
    }
  }

  .image-text {
    ${commonStyles.ui_text_tiny}
    margin-top: 4px;
    font-family: 'inter';
    height: 30px;
    overflow: hidden;
    max-width: 68px;
  }
`;

const Letter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 40px;

  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  background: rgba(204, 213, 255, 0.11);
  border-radius: 12px;
  margin: 0 auto;
  user-select: none;
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-size: cover;
  margin: 0 auto;
  background: url('${props => props.url}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const IconTag = ({ hasDragPermission = false, icon, key, onClick, tag }) => {
  const firstLetter = tag?.name?.charAt(0);

  return (
    <Wrapper title={tag?.name} onClick={onClick} key={key} hasDragPermission={hasDragPermission}>
      <IconContainer>
        {tag?.image?.url ? <IconWrapper url={tag?.image?.url} /> : <Letter>{icon || firstLetter}</Letter>}
      </IconContainer>

      <div className="image-text">{tag?.name}</div>
    </Wrapper>
  );
};

export default IconTag;
