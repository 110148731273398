import styled from '@emotion/styled';
import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';
import ContainedButton from 'common/ContainedButton';

export const Container = styled.div`
  display: flex
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  margin-top: -32px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 24px 40px 0px 40px;

  background: radial-gradient(circle at 105% -58%, #bd00ff 14.06%, rgba(170, 0, 173, 0) 30%),
    radial-gradient(circle at 0% -58%, #5941ed 0%, rgba(66, 43, 189, 0.541667) 36.21%, rgba(38, 17, 131, 0) 200%);
  mixer-blend-mode: color-dodge;
`;

export const Row = styled.div`
  @media ${pageSize.XS} {
    display: flex;
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WelcomeFooterWrapper = styled.div`
  @media ${pageSize.XS} {
    display: flex;
    justify-content: flex-end;
  }
  @media ${pageSize.S} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media ${pageSize.M} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media ${pageSize.L} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const StyledContent = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: rgba(230, 234, 255, 0.6);
  text-align: center;
`;

export const StyledTextContent = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: rgba(229, 232, 255, 0.65);
`;

export const BasicProfileInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

export const VerifyIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const ToolkitFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const VerifyFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const SkipVerifyFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media ${pageSize.XS} {
    display: flex;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  border-radius: 8px;
  border: ${props => (!props.disabled ? '2px solid rgba(191, 202, 255, 0.1);' : '')};
  background: ${props => (!props.disabled ? '' : 'rgba(204, 213, 255, 0.03);')};
  padding-bottom: 24px;

  &:hover {
    ${props => (!props.disabled ? 'cursor: pointer;' : '')};
  }

  @media ${pageSize.XS} {
    width: 99%;
  }
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: rgba(230, 234, 255, 0.6);
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  gap: 8px;
`;

export const CardLabel = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${props => (props.disabled ? 'rgba(255, 255, 255, 0.6);' : 'rgba(255, 255, 255, 1);')};
`;

export const CardLegend = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(229, 234, 255, 0.6);
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TextIcon = styled.div`
  display: flex;
  border-radius: 4px;
  background: rgba(204, 213, 255, 0.11);
  border: 1px solid rgba(204, 213, 255, 0.11);
  padding: 4px 8px 4px 8px;

  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media ${pageSize.S} {
    svg:nth-of-type(1) {
      margin-left: 120px;
      margin-right: 18px;
    }
  }

  @media ${pageSize.M} {
    svg:nth-of-type(1) {
      margin-left: 120px;
      margin-right: 18px;
    }
  }

  @media ${pageSize.L} {
    svg:nth-of-type(1) {
      margin-left: 120px;
      margin-right: 18px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  width: 272px;
  word-wrap: break-word;
`;

export const HeaderSubtitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;
export const StyledContainedButton = styled(ContainedButton)`
  button {
    padding: 9px 16px;

    & .button-text {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
`;
export const StyledStartButton = styled(ContainedButton)`
  button {
    padding: 13px 20px;

    & .button-text {
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
    }
  }
`;
