import React from 'react';
import { Container, Subtitle, TextContainer, Title } from './ProgressFunnelResult.styled';
import withWidgetCardLayout from '../hocs/WidgetCardLayout';
import AirplaneGreenCheckIcon from 'common/IconComponents/AirplaneGreenCheckIcon';

const ProgressFunnelResult = ({ title, description, dataTestId }) => {
  return (
    <Container data-testid={dataTestId}>
      <AirplaneGreenCheckIcon width="200" height="200" style={{ paddingTop: '20px' }} />
      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
      </TextContainer>
    </Container>
  );
};

export default withWidgetCardLayout({ withFooter: true, closable: true })(ProgressFunnelResult);
