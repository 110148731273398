import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';

import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import TxplModal from 'common/TxplModal';

const VetIcon = styled(Icon)`
  padding: 0 10px;
`;

const StyledContainer = styled.div`
  max-width: 255px;
  text-align: center;
  margin: auto;
`;

const ClaimedCreditModal = ({ closePortal, data, name }) => (
  <TxplModal
    title="Credits"
    subTitle="Credits added"
    name={name}
    size="small"
    padding="56px 0 82px"
    appElement={document.getElementById('root-modal')}
    renderFooter={
      <ContainedButton onClick={closePortal} data-testid="claimed-credit-button">
        Done
      </ContainedButton>
    }
  >
    <StyledContainer>
      {`You have claimed credits for `}
      <b>{`"${data.gameName}"`}</b>.{` While we vet it, you’ll see this icon`}
      <VetIcon icon="vetting" color="black" size={12} />
      {`next to the project. Once vetted, the icon will change to`}
      <VetIcon icon="vetted" color="black" size={12} />
    </StyledContainer>
  </TxplModal>
);

export default withRenderPortal('claimed-credit-modal')(ClaimedCreditModal);
