import React from 'react';
import { useSelector } from 'react-redux';
import keys from 'lodash/fp/keys';

import * as Styled from './StyledConversationMessageTo';
import { profileSelectors } from 'store/profile';

import NA from 'images/NA.png';

const ContactItem = ({ contact, setSelectedConversation }) => {
  const conversations = useSelector(profileSelectors.selectProfileProperty('conversations'));

  const name = contact?.name;
  const profileImageUrl = contact?.basicInfo?.imageUri?.url;

  const handleClick = async e => {
    e.preventDefault();
    for (const conversation in conversations) {
      const ids = keys(conversations[conversation].participants);
      if (ids.includes(contact._id)) {
        setSelectedConversation(conversation);
        return false;
      }
    }

    setSelectedConversation({ name: contact.name, id: contact.id, photoUrl: contact.basicInfo?.imageUri?.url });
  };

  return (
    <Styled.ContactItem onClick={handleClick}>
      <Styled.ContactItemImage>
        <img src={profileImageUrl || NA} />
      </Styled.ContactItemImage>
      <Styled.ContactItemName>{name}</Styled.ContactItemName>
    </Styled.ContactItem>
  );
};

export default ContactItem;
