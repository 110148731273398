import React, { useEffect, useState } from 'react';

import withRenderPortal from 'hocs/withRenderPortal';

import useChatConversationService from 'hooks/chatApi/useChatConversation';

import Icon from 'common/Icon';
import useViewport from 'hooks/useViewport';
import * as Styled from './StyledImageGallery';
import ImageGallery from './ImageGallery';

const ImageGalleryModal = ({ closePortal, conversationId, data, name }) => {
  const { isXS } = useViewport();
  const { getAttachments } = useChatConversationService();

  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(data.currentImage);

  const downloadImage = () => window.open(selectedImage.url);

  const renderHeader = () => (
    <Styled.Header>
      <Styled.Title>{selectedImage.fileName}</Styled.Title>
      <Styled.ActionsWrapper>
        <Styled.ActionItem onClick={downloadImage}>
          <Icon icon="download" size={24} />
        </Styled.ActionItem>
        <Styled.ActionItem onClick={closePortal}>
          <Icon icon="close" size={16} />
        </Styled.ActionItem>
      </Styled.ActionsWrapper>
    </Styled.Header>
  );

  const renderHeaderXS = () => (
    <Styled.HeaderXS>
      <Styled.ActionsWrapper>
        <Styled.GoBackIcon onClick={closePortal}>
          <Icon icon="back" color="#fff" />
        </Styled.GoBackIcon>
        <Styled.Title isXS>{selectedImage.fileName}</Styled.Title>
      </Styled.ActionsWrapper>
      <Styled.ActionItem onClick={downloadImage} isXS>
        <Icon icon="download" size={24} />
      </Styled.ActionItem>
    </Styled.HeaderXS>
  );

  useEffect(() => {
    const fetchImages = async () => {
      const attachments = await getAttachments(conversationId);
      const images = attachments.filter(attachment => attachment.custom.file.fileType.includes('image'));
      setImageList(images);
    };

    fetchImages();
  }, []);

  return (
    <Styled.StyledTpxlModal
      name={name}
      appElement={document.getElementById('root-modal')}
      size="large"
      padding="0"
      renderHeader={isXS ? renderHeaderXS : renderHeader}
      modalXS
      contentPadding="0"
      notXS={!isXS}
    >
      <>
        {!!imageList.length && (
          <ImageGallery images={imageList} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
        )}
      </>
    </Styled.StyledTpxlModal>
  );
};

export default withRenderPortal('image-gallery-modal')(ImageGalleryModal);
