import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import { useDispatch, useSelector } from 'react-redux';

import commonStyles from 'utils/common-styles';
import { profileSelectors } from 'store/profile';
import { portalActions } from 'store/portals';

import ContainedButton from 'common/ContainedButton';
import VettingStatus from 'pages/talent/Credits/VettingStatus';
import FormCheckbox from 'common/form/FormCheckbox';

const StyledAddedMediaCoverageItemsContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 16px;
`;

const StyledMediaCoverageContainer = styled.div`
  background: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  ${props => props.active && 'border: 2px solid green;'}
`;

const StyledTitle = styled.h3`
  ${commonStyles.ui_heading_3}
  margin: 0 0 13px;
  word-break: break-all;
`;

const StyledDescription = styled.p`
  margin: 0px;
  ${commonStyles.ui_text_small}
  color: rgba(230, 234, 255, 0.35);
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  word-break: break-all;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
`;

const StyledCheckbox = styled(FormCheckbox)`
  padding-bottom: 14px;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  margin-bottom: 14px;
`;

const AddedMediaCoverageItems = ({
  items,
  notClosePortal,
  onChoose,
  onDelete,
  showChooseCheckbox,
  showExisting,
  skipDeleteConfirm
}) => {
  const mediaCoverages = useSelector(profileSelectors.selectProfileProperty('mediaCoverage'));
  const dispatch = useDispatch();

  const list = showExisting
    ? mediaCoverages
    : items.map(curr => (typeof curr === 'string' ? mediaCoverages[curr] : curr)).filter(Boolean);

  const onMediaCoverageClick = item => () => onChoose(item._id);

  const handleDelete = item => () => {
    if (skipDeleteConfirm) {
      if (notClosePortal) {
        return onChoose(item._id);
      }
      return onDelete({ mediaId: item._id, localMediaId: item.localId });
    }
    dispatch(
      portalActions.openPortal({
        name: 'delete-media-coverage-modal',
        data: { mediaId: item._id, localMediaId: item.localId }
      })
    );
  };

  if (!size(list)) return null;

  return (
    <StyledAddedMediaCoverageItemsContainer>
      {map(
        item => (
          <StyledMediaCoverageContainer key={item._id || item.localId}>
            {showChooseCheckbox && onChoose && (
              <StyledCheckbox
                checked={items.find(mediaCoverageId => mediaCoverageId === item._id)}
                onChange={onMediaCoverageClick(item)}
              />
            )}

            <StyledTitle>
              {item.mediaTitle} {moment(item.articlePublishDate).format('MMM DD, YYYY')}
            </StyledTitle>
            <StyledDescription>{item.quote}</StyledDescription>
            <StyledFooter>
              <VettingStatus vetted={item.vetted} />

              {!showExisting && (
                <ContainedButton backgroundColor="transparent" onClick={handleDelete(item)} icon="delete">
                  Remove
                </ContainedButton>
              )}
            </StyledFooter>
          </StyledMediaCoverageContainer>
        ),
        list
      )}
    </StyledAddedMediaCoverageItemsContainer>
  );
};

export default AddedMediaCoverageItems;
