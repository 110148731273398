import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';

import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

import Icon from 'common/Icon';
import useViewport from 'hooks/useViewport';
import Typography from '../../components/shared/Typography';

const JoinForm = styled.div`
  display: inline-block;
  cursor: pointer;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  margin-left: 8px;
  text-decoration: underline;
  @media ${pageSize.XS} {
    display: none;
  }
`;
const JoinFormShow = styled.div`
  display: none;
  cursor: pointer;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-decoration: underline;
  margin-left: 5px;
  @media ${pageSize.XS} {
    display: inline-block;
  }
`;
const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  @media ${pageSize.XS} {
    margin-left: 0px;
    margin-top: 32px;
  }
`;
const StyledTypography = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.35);
`;
const StyledTxplModalHeaderContainer = styled.div`
  ${props => props.TxplModalHeaderContainerPT && `padding-top: ${props.TxplModalHeaderContainerPT}px;`}
  ${props => props.TxplModalHeaderContainerPB && `padding-bottom: ${props.TxplModalHeaderContainerPB};`}
  ${commonStyles.ui_heading_2}
  ${props =>
    props.isSmallModal &&
    !props.sameFontSize &&
    css`
      @media ${pageSize.XS} {
        ${commonStyles.ui_heading_3}
      } ;
    `}
  border-bottom: ${props => (props.headerBorderSize ? props.headerBorderSize : '2px')} solid ${props =>
    props.headerBorderColor ? props.headerBorderColor : 'rgba(204, 213, 255, 0.11)'};
  ${props => props.fromJob && 'border-bottom: none !important;'}
  ${props => (!props.title ? 'border-bottom: none;' : 'border-bottom: 2px solid rgba(204, 213, 255, 0.11);')};
  ${({ headerPaddingBottom }) => headerPaddingBottom >= 0 && `padding-bottom: ${headerPaddingBottom}px;`}
  display: flex;
  flex-direction: column;
  ${props => props.titleFs && `font-size: ${props.titleFs}px;`}
  ${props => props.hideHeaderBorder && 'border: none;'}
  @media ${pageSize.XS} {
    ${({ allowLine }) => !allowLine && 'border-bottom: 0;'}
  }
`;
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ titleMB }) => (titleMB ? `${titleMB}px` : '8px')} !important;
  ${props => props.boldWeight && 'font-weight: 700;'}
  @media ${pageSize.XS} {
    margin-bottom: ${props => (props.titlePadding ? props.titlePadding : '16px')};
  } ;
`;
const StyledTitleContainerInner = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 24px;
  width: 100%;

  span {
    word-break: break-word;
  }

  ${({ titleFontSize }) => titleFontSize && `font-size: ${titleFontSize}px;`}
  @media ${pageSize.XS} {
    flex-direction: column;
  }
`;
const StyledIcon = styled(Icon)`
  margin-top: 8px;
  margin-left: auto;
  cursor: pointer;
`;
const StyledSubTitle = styled.div`
  ${commonStyles.ui_text_medium}
  margin-top: 16px;
  margin-bottom: 24px;
  color: rgba(230, 234, 255, 0.35);
  width: 520px;

  ${props => props.fromJob && 'margin-top: 0; margin-bottom: 18px;'}
  ${props => props.subTitleFs && `font-size: ${props.subTitleFs}px;`}
  margin-bottom: ${props => (props.subtitlemarginBottom ? props.subtitlemarginBottom : '0px')};
  margin-top: ${props => (props.subtitlemarginTop ? props.subtitlemarginTop : '0px')};
  ${props => props.subTitleMaxWidth && `max-width: ${props.subTitleMaxWidth};`}
  ${props => props.subTitleMaxWidth && 'width: 100%;'}
  ${({ subTitleMargin }) => subTitleMargin && `margin: ${subTitleMargin};`}
  @media ${pageSize.XS} {
    width: 320px;
    margin-bottom: ${props => (props.subMarginBottom ? props.tsubMarginBottom : '24px')};
    width: ${props => (props.titlewidth ? props.titlewidth : 'unset')};
  } ;
`;

const TxplModalHeader = ({
  TxplModalHeaderContainerPB,
  TxplModalHeaderContainerPT,
  allowLine,
  boldWeight,
  fromJob,
  headerBorderColor,
  headerBorderSize,
  headerPaddingBottom,
  hideCloseIcon,
  hideHeaderBorder,
  nonFullScreenOnMobile,
  onClose,
  renderAdditionalHeader,
  sameFontSize,
  showSignUp,
  size,
  subMarginBottom,
  subTitle,
  subTitleFs,
  subTitleMargin,
  subTitleMaxWidth,
  subtitlemarginBottom,
  subtitlemarginTop,
  testTitle,
  title,
  titleFontSize,
  titleFs,
  titleMB,
  titlePadding,
  titlewidth
}) => {
  const { isXS } = useViewport();

  return (
    <>
      {renderAdditionalHeader}
      <StyledTxplModalHeaderContainer
        isSmallModal={size === 'small'}
        TxplModalHeaderContainerPB={TxplModalHeaderContainerPB}
        TxplModalHeaderContainerPT={TxplModalHeaderContainerPT}
        noBorder={nonFullScreenOnMobile}
        title={title}
        headerPaddingBottom={headerPaddingBottom}
        headerBorderColor={headerBorderColor}
        hideHeaderBorder={hideHeaderBorder}
        headerBorderSize={headerBorderSize}
        allowLine={allowLine}
        titleFs={titleFs}
        fromJob={fromJob}
        sameFontSize={sameFontSize}
      >
        <StyledTitleContainer
          boldWeight={boldWeight}
          testTitle={testTitle}
          title={title}
          titlePadding={titlePadding}
          titleMB={titleMB}
        >
          <StyledTitleContainerInner titleFontSize={titleFontSize}>
            <span>{title}</span>
            {showSignUp && (
              <FormWrapper>
                <StyledTypography color="rgba(230, 234, 255, 0.35)">Already have an account?</StyledTypography>
                <JoinForm> Sign in</JoinForm>
                <JoinFormShow> Sign up</JoinFormShow>
              </FormWrapper>
            )}
          </StyledTitleContainerInner>
          {nonFullScreenOnMobile
            ? !isXS && <StyledIcon icon="close" onClick={onClose} />
            : !hideCloseIcon && <StyledIcon icon="close" onClick={onClose} />}
        </StyledTitleContainer>

        {subTitle && (
          <StyledSubTitle
            subMarginBottom={subMarginBottom}
            subtitlemarginTop={subtitlemarginTop}
            subtitlemarginBottom={subtitlemarginBottom}
            titlewidth={titlewidth}
            subTitleMaxWidth={subTitleMaxWidth}
            subTitleMargin={subTitleMargin}
            subTitleFs={subTitleFs}
          >
            {subTitle}
          </StyledSubTitle>
        )}
      </StyledTxplModalHeaderContainer>
    </>
  );
};

export default TxplModalHeader;
