import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Card } from '../../../../common/styles/Card';
import { pageSize } from '../../../../utils/devices';
import Flex from '../../../../common/styles/Flex';
import commonStyles from '../../../../utils/common-styles';

export const StyledCard = styled(Card)`
  &:hover .buttons-wrapper {
    display: flex;
  }

  @media ${pageSize.XS} {
    padding: 72px 16px 16px;
  }
`;
export const ApplicantInfo = styled(Flex)`
  gap: 24px;
  flex-flow: column;
  color: rgba(230, 234, 255, 0.6);
  @media ${pageSize.L} {
    flex-flow: row;
  }
`;
export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;

  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    width: 64px;
    height: 64px;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const ApplicantName = styled(Link)`
  ${commonStyles.ui_heading_2}
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
export const ApplicantDescription = styled.div`
  display: flex;
  align-items: center;
  ${commonStyles.caption}
  color: rgba(230, 234, 255, 0.35);
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ${pageSize.L} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const AbsoluteButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: none;

  @media ${pageSize.XS} {
    display: flex;
  }
`;

export const AbsoluteContractStatusWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  margin-right: 30px;
`;

export const AppliedInfo = styled.span`
  display: inline-block;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const UnderLinedText = styled.div`
  ${commonStyles.ui_text_medium}
  text-decoration: underline;
  padding: 8px 16px;
  color: rgba(230, 234, 255, 0.35);
  &:hover {
    cursor: pointer;
  }
`;

export const ContractStatus = styled.span`
  display: inline-block;
  margin-right: 16px;
  color: rgba(230, 234, 255, 0.35);
`;
