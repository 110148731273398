import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { SelectComponent } from 'common/form/FormSelect';

import {
  StyledBackButton,
  StyledCount,
  StyledForwardButton,
  StyledPaginationContainer,
  StyledPaginationMainContainer,
  StyledRowsPerPageContainer
} from './PaginationStyles';

const rowsPerPageOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 }
];

const Pagination = ({ dataLen = 100, handlePageChange, handleRowsPerPageChange, page, rowsPerPage }) => {
  const [styles, setStyles] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const specificPage = pathname.includes('profile-unlock');
    specificPage && setStyles(true);
  }, []);

  const handleGoNext = () => {
    if ((page + 1) * rowsPerPage >= dataLen) return;
    handlePageChange(page + 1);
  };

  const handleGoBack = () => {
    handlePageChange(page === 0 ? page : page - 1);
  };

  const displayCount = React.useMemo(() => {
    const lastI = (page + 1) * rowsPerPage;
    const startI = lastI - rowsPerPage + 1;

    return `${startI}-${dataLen > lastI ? lastI : dataLen} of ${dataLen}`;
  }, [page, rowsPerPage, dataLen]);

  const backDisabled = React.useMemo(() => page === 0, [page]);
  const forwardDisabled = React.useMemo(() => (page + 1) * rowsPerPage >= dataLen, [page, rowsPerPage, dataLen]);

  return (
    <StyledPaginationMainContainer>
      <StyledPaginationContainer paginationStyles={styles}>
        <StyledRowsPerPageContainer paginationStyles={styles}>
          Rows per page:
          <SelectComponent
            label="Project status"
            value={{ label: rowsPerPage, value: rowsPerPage }}
            indicatorStyles={{ padding: '8px 0px', paddingLeft: 6 }}
            options={rowsPerPageOptions}
            onChange={val => handleRowsPerPageChange(val.value)}
          />
        </StyledRowsPerPageContainer>
        <StyledCount>{displayCount}</StyledCount>
        <StyledBackButton disabled={backDisabled} icon="arrow-left" onClick={handleGoBack} paginationStyles={styles} />
        <StyledForwardButton
          disabled={forwardDisabled}
          icon="arrow-right"
          onClick={handleGoNext}
          paginationStyles={styles}
        />
      </StyledPaginationContainer>
    </StyledPaginationMainContainer>
  );
};

export default Pagination;
