import styled from '@emotion/styled';

import ContainedButton from 'common/ContainedButton';

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding-top: 20px;
  ${({ paginationStyles }) => paginationStyles && 'grid-gap: 32px; margin-right: 26px;'}

  .class-container .react-select__input input {
    width: 2px !important;
  }
`;

export const StyledPaginationMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledRowsPerPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  ${({ paginationStyles }) => paginationStyles && 'grid-gap: 16px;'}

  & .react-select__control {
    padding: 0px 10px !important;
  }
`;

export const StyledCount = styled.p``;

export const StyledBackButton = styled(ContainedButton)`
  background-color: rgba(204, 213, 255, 0.11);
  ${({ paginationStyles }) => paginationStyles && 'border-radius: 8px;'}
`;

export const StyledForwardButton = styled(ContainedButton)`
  background-color: rgba(204, 213, 255, 0.11);
  ${({ paginationStyles }) => paginationStyles && 'border-radius: 8px;'}
`;
