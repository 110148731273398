import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './App.css';
import './richtext.css';
import './fonts/fonts.css';

import { Provider } from 'react-redux';
import DisplayPageSize from './components/DisplayPageSize';
import LeavePageModal from './common/LeavePageModal';
import store from './store';
import Routes from './Router/Routes';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Elements stripe={stripePromise}>
          {process.env.NODE_ENV !== 'production' && <DisplayPageSize />}
          <LeavePageModal />
          <Routes />
        </Elements>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
