import React from 'react';

function BigCheckmarkIcon({ width, height, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 37 36" {...rest}>
      <path stroke="#fff" strokeLinecap="round" strokeWidth="6.333" d="M3.372 16.706L14.166 27.5 32.879 8.789"></path>
    </svg>
  );
}

export default BigCheckmarkIcon;
