import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import Icon from 'common/Icon';
import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import useProfilePath from 'hooks/useProfilePath';
import React from 'react';
import { useHistory } from 'react-router';

function AddFirstJob() {
  const { myProfilePath } = useProfilePath();
  const history = useHistory();

  return (
    <Frame padding="80px 24px">
      <Flex gap={32} column center>
        <Icon icon="jobs" size={64} color="rgba(221, 226, 255, 0.2)" />
        <Typography maxWidth="296px" textAlign="center" color="rgba(230, 234, 255, 0.6)">
          Post jobs and hire from our top vetted talent community.
        </Typography>
        <ContainedButton icon="add" onClick={() => history.push(`${myProfilePath}/jobs/add`)}>
          create job
        </ContainedButton>
      </Flex>
    </Frame>
  );
}

export default AddFirstJob;
