import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { portalActions } from 'store/portals';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import { userActions } from 'store/user';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import backOfficeService from 'api/backOfficeService';
import { profileActions } from 'store/profile';
import { reasons, statuses } from '../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = initialStatus => ({
  status: { initialValue: statuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  reason: { initialValue: '', validate: ['isRequired'] },
  additionalComment: { initialValue: '' }
});

const transformParams = params => ({
  ...params,
  reason: params.reason.value,
  status: params.status.value
});

const ActivityStatusModal = ({ closePortal, data, name }) => {
  const { onSuccess, status, talentId, userId } = data;
  const dispatch = useDispatch();

  const callApi = params => {
    const apiParams = transformParams(params);
    const accountId = userId || talentId;
    const accountType = userId ? 'user' : 'talent';

    const requestPromise = ['suspended', 'deleted'].includes(apiParams.status)
      ? Promise.resolve()
      : backOfficeService.setAccountStatus(accountId, { ...apiParams, accountType });

    if (['suspended', 'deleted'].includes(apiParams.status)) {
      const modal =
        apiParams.status === 'suspended' ? 'backOffice-suspend-account-modal' : 'backOffice-delete-account-modal';

      dispatch(
        portalActions.openPortal({
          name: modal,
          data: {
            ...apiParams,
            accountId: userId || talentId,
            accountType: userId ? 'user' : 'talent',
            onSuccess: () => onSuccess && onSuccess()
          }
        })
      );
      closePortal();
    }

    return requestPromise.then(({ data: { accounts, statusLog } }) => {
      if (!['deleted', 'suspended'].includes(apiParams.status)) {
        const action = userId ? userActions.updateUserProperty : profileActions.updateProfileProperty;

        dispatch(action({ property: 'status', value: apiParams.status, profileId: accountId }));
        dispatch(action({ property: 'statusLogs', value: statusLog, updateType: 'insert', profileId: accountId }));

        if (userId) {
          dispatch(action({ property: 'accounts', value: accounts, profileId: accountId }));
        }
      }
      if (data.onSuccess) data.onSuccess();
      closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({ fields: fields(status), callApi });

  useEffect(() => {
    const newReasons = reasons[values?.status?.value];
    if (newReasons) onFieldChange('reason')(newReasons[0]);
  }, [values.status?.value]);

  return (
    <TxplModal
      title="Activity status"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="activityStatusModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="activityStatusModal">
        <FormSelect
          autoFocus
          label="Current status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={statuses}
        />

        <FormSelect
          mt={56}
          label="Reason"
          value={values.reason}
          onChange={onFieldChange('reason')}
          options={reasons[values.status?.value]}
        />

        <FormTextarea
          mt={56}
          maxLength={200}
          label="Additional comments"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('activity-status-modal')(ActivityStatusModal);
