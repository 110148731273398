import * as apiCallActionTypes from './apiCallActionTypes';

export const sendRequest = ({ name }) => ({
  type: apiCallActionTypes.SEND_REQUEST,
  payload: {
    name
  }
});

export const sendRequestSuccess = ({ data, name, persist }) => ({
  type: apiCallActionTypes.SEND_REQUEST_SUCCESS,
  payload: {
    name,
    data,
    persist
  }
});

export const sendRequestFailure = ({ error, name, persist }) => ({
  type: apiCallActionTypes.SEND_REQUEST_FAILURE,
  payload: {
    name,
    error,
    persist
  }
});

export const clearRequest = ({ name }) => ({
  type: apiCallActionTypes.CLEAR_REQUEST,
  payload: {
    name
  }
});
