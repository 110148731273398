import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { portalActions } from 'store/portals';
import useForm from 'hooks/useForm';
import { talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';

const fields = {
  quote: { initialValue: '', title: 'Media quote', validate: ['isRequired'] },
  mediaTitle: { initialValue: '', title: 'Media title', validate: ['isRequired'] },
  articlePublishDate: {
    initialValue: '',
    title: 'Article publish date',
    validate: ['isRequired', 'isDate', 'isDateBefore']
  },
  articleLink: { initialValue: '', title: 'Article link', validate: ['isRequired', 'isURL'] },
  game: { initialValue: null, title: 'Game' }
};

const transformParams = ({ game, ...rest }) => ({
  ...rest,
  game: game?._id
});

const useMediaCoverage = ({ mediaId, onAdd }) => {
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const media = useSelector(profileSelectors.selectProfileProperty(`mediaCoverage.${mediaId}`));

  const addRequest = React.useCallback(
    formValues =>
      talentService.createMediaCoverage(profileId, transformParams(formValues)).then(({ data }) => {
        dispatch(
          profileActions.updateProfileProperty({
            profileId,
            property: 'mediaCoverage',
            value: data.data,
            updateType: 'insert'
          })
        );
      }),
    [profileId, dispatch]
  );

  const updateRequest = React.useCallback(
    formValues =>
      talentService
        .updateMediaCoverage(profileId, { ...transformParams(formValues), _id: mediaId })
        .then(({ data }) => {
          dispatch(
            profileActions.updateProfileProperty({
              profileId,
              property: 'mediaCoverage',
              value: data.data,
              updateType: 'update'
            })
          );
        }),
    [mediaId, profileId, dispatch]
  );

  const onSubmitSuccess = () => {
    if (!mediaId) {
      dispatch(portalActions.openPortal({ name: 'claim-media-coverage-modal' }));
    }

    dispatch(portalActions.closePortal({ name: 'media-coverage-modal' }));
  };

  const { errors, initialize, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    onSubmitSuccess,
    callApi: formValues => (mediaId ? updateRequest(formValues) : onAdd ? onAdd(formValues) : addRequest(formValues))
  });

  React.useEffect(() => {
    if (mediaId) initialize(media);
  }, [mediaId]); // eslint-disable-line

  return {
    values,
    onFieldChange,
    initialize,
    onSubmit,
    errors,
    submitting
  };
};

export default useMediaCoverage;
