import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import { companyProfileService, talentService } from 'api';

import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import FormTextarea from 'common/form/FormTextarea';
import { profileActions, profileSelectors } from 'store/profile';

const StyledHeader = styled.p`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: hsl(0deg 0% 100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: hsl(0deg 0% 100%);
  margin: 0;
`;
const StyledText = styled.p`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: hsl(230deg 100% 95% / 35%);
  margin: 6px 0;
`;

const AboutTextModal = ({ closePortal, name }) => {
  const [aboutText, setAboutText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const initialAboutText = useSelector(profileSelectors.selectProfileProperty('about.aboutText'));
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profileId = useSelector(profileSelectors.selectActiveProfileId);

  const dispatch = useDispatch();

  const handleSave = () => {
    const postData = { aboutText };
    setIsLoading(true);

    const service = isCompanyView ? companyProfileService.setCompanyAboutText : talentService.setAboutText;

    service(profileId, postData)
      .then(() =>
        dispatch(profileActions.updateProfileProperty({ profileId, property: 'about.aboutText', value: aboutText }))
      )
      .finally(closePortal);
  };

  useEffect(() => {
    setAboutText(initialAboutText);
  }, [initialAboutText]);

  return (
    <TxplModal
      appElement={document.getElementById('talent-modal-placeholder')}
      name={name}
      size="large"
      addPaddingTop
      padding="0 0 42px 0"
      contentPadding="40px 40px 24px"
      titlePadding="24px"
      addPaddingTopBig
      XSaddPaddingTop="16px"
      titleMB="8"
      renderFooter={
        <>
          {isLoading && <Spinner />}
          <ContainedButton ml={6} onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </ContainedButton>
          <ContainedButton ml={6} onClick={handleSave} backgroundColor="#7266EE" forceDisplayText>
            Save
          </ContainedButton>
        </>
      }
    >
      <StyledHeader>Overview</StyledHeader>
      <StyledText>
        Bragging rights, current projects, and future goals are all fair game here—a bit of your personality too.
      </StyledText>
      <FormTextarea
        value={aboutText}
        maxLength={800}
        minHeight="146px"
        color="rgba(242, 244, 255, 0.9)"
        onChange={e => setAboutText(e.target.value)}
      />
    </TxplModal>
  );
};

export default withRenderPortal('about-text-modal')(AboutTextModal);
