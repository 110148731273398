import React from 'react';
import { Card, CloseIcon, Footer, FooterDescription, Link } from './WidgetCardLayout.styled';
import Icon from 'common/Icon';
import redirectToLandingPage from 'utils/redirectToLandingPage';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/auth';
import redirectToCalendly from 'utils/redirectToCalendly';

const withWidgetCardLayout =
  ({ closable = true, withFooter = true, animation = null }) =>
  ComposedComponent => {
    const WidgetCardLayout = props => {
      const isCollaborator = useSelector(authSelectors.selectCurrentUserProperty('isCollaborator'));
      return (
        <Card open={props.open} animation={animation}>
          {closable && <CloseIcon icon="close" size={10} onClick={props.onClose} />}
          <div style={{ height: '400px' }}>
            <ComposedComponent {...props} />
          </div>
          {withFooter && (
            <Footer>
              <Icon icon="question" color="rgba(230, 234, 255, 0.35)" />
              <FooterDescription>
                {`Question? See our `}
                <Link onClick={() => redirectToLandingPage('/faq', 'TheXPlace FAQ.')}>FAQ</Link>
                {` or `}
                <Link onClick={() => redirectToCalendly(isCollaborator)}>Book a call</Link>
                {` with us.`}
              </FooterDescription>
            </Footer>
          )}
        </Card>
      );
    };

    return WidgetCardLayout;
  };

export default withWidgetCardLayout;

withWidgetCardLayout.defaultProps = {
  closable: true,
  withFooter: true
};
