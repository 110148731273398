import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Wrapper } from './ProgressFunnelWidget.styled';
import ProgressButton from './ProgressButton/ProgressButton.component';

import { useProgressFunnel } from './hooks/useProgressFunnel';
import { selectMyProfile } from 'store/profile/profileSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { portalActions } from 'store/portals';
import { all, compose, equals, pathSatisfies, pluck, splitAt } from 'ramda';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';
import useProfilePath from 'hooks/useProfilePath';
import useProfileOnBoarding from 'hooks/useProfileOnBoarding';
import ProgressFunnelLoading from './ProgressFunnelLoading/ProgressFunnelLoading';
import ProgressFunnelCard from './ProgressFunnelCard/ProgressFunnelCard';
import ProgressFunnelResult from './ProgressFunnelResult/ProgressFunnelResult';
import { isOneOf } from '@flybondi/ramda-land';

const isVettingInProgressOrPending = pathSatisfies(isOneOf(['IN_PROGRESS', 'VETTING_PENDING']), [
  'vettingStatus',
  'status'
]);
const allComplete = compose(all(equals(true)), pluck('isCompleted'));

export default function ProgressFunnelWidget({ dataTestId, opened = false }) {
  const [open, setOpen] = useState(opened);
  const { funnelItems, percentage } = useProgressFunnel();
  const [basicItems, enhancedItems] = splitAt(2, funnelItems);
  const { openErrorSnackbar } = useErrorSnackbar();
  const profile = useSelector(selectMyProfile);
  const { vetted } = profile || { vetted: false };
  const history = useHistory();
  const dispatch = useDispatch();
  const { myProfilePath } = useProfilePath();
  const { lockProfile } = useProfileOnBoarding();

  const [profileSubmitted, setProfileSubmitted] = useState(isVettingInProgressOrPending(profile));
  const [isLoading, setIsLoading] = useState(false);

  const isBasicStepComplete = useMemo(() => allComplete(basicItems), [basicItems]);
  const isEnhancedStepComplete = useMemo(() => allComplete(enhancedItems), [enhancedItems]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleProgressButtonClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(portalActions.closePortal({ name: 'ready-submit-modal' }));
    handleSubmitProfile();
  }, []);

  const handleCancel = useCallback(() => {
    console.log('handleCancel');
    dispatch(portalActions.closePortal({ name: 'ready-submit-modal' }));
  }, []);

  const handleReadyToSubmit = useCallback(() => {
    if (isBasicStepComplete && isEnhancedStepComplete) {
      handleSubmitProfile();
    } else {
      dispatch(
        portalActions.openPortal({
          name: 'ready-submit-modal',
          data: { onCancel: handleCancel, onConfirm: handleSubmit }
        })
      );
    }
  }, [isBasicStepComplete, isEnhancedStepComplete]);

  const handleSubmitProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      await lockProfile();
      setProfileSubmitted(true);
      setIsLoading(false);
    } catch {
      openErrorSnackbar('Opps! something went wrong. Try again later!');
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, open]);

  const shouldShowLoading = useMemo(() => open && isLoading, [open, isLoading]);
  const shouldShowCard = useMemo(() => !isLoading && !profileSubmitted && open, [open, isLoading, profileSubmitted]);
  const shouldShowButton = useMemo(() => !vetted && !open, [vetted, open]);
  const shouldShowResult = useMemo(() => open && profileSubmitted && !isLoading, [open, profileSubmitted, isLoading]);

  const handleClickItem = id => {
    switch (id) {
      case 'you-in-a-nutshell':
        dispatch(portalActions.openPortal({ name: 'quick-onboarding-modal' }));
        break;
      case 'verify-identity':
        dispatch(
          portalActions.openPortal({
            name: 'id-verification-video-modal'
          })
        );
        break;
      case 'claim-your-game-credits':
        history.push(`${myProfilePath}/about#credits`);
        break;
      case 'showcase-portfolio':
        history.push(`${myProfilePath}/portfolio`);
        break;
      case 'experience':
        history.push(`${myProfilePath}/experience`);
        break;
    }
  };

  useEffect(() => {
    if (opened && !open) setOpen(true);
  }, [opened]);

  return (
    <Wrapper data-testid={dataTestId}>
      {shouldShowButton && (
        <ProgressButton
          dataTestId="progress-funnel-button"
          completed={profileSubmitted}
          percentage={percentage}
          onClick={handleProgressButtonClick}
        />
      )}
      {shouldShowCard && (
        <ProgressFunnelCard
          dataTestId="progress-funnel-card"
          open={open}
          items={funnelItems}
          onClose={handleClose}
          onClickItem={handleClickItem}
          onSubmitProfile={handleReadyToSubmit}
        />
      )}
      {shouldShowLoading && (
        <ProgressFunnelLoading dataTestId="progress-funnel-loading" loadingText="Submitting profile..." />
      )}
      {shouldShowResult && (
        <ProgressFunnelResult
          dataTestId="progress-funnel-result"
          title="Your profile has been submitted"
          description="It usually takes 3-4 days to verify a profile, we'll let you know once it's done."
          onClose={handleClose}
        />
      )}
    </Wrapper>
  );
}
