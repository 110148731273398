import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/fp/isEmpty';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import Icon from 'common/Icon';
import IconTag from './IconTag';
import { profileActions, profileSelectors } from '../store/profile';
import talentService from '../api/talentService';
import companyService from '../api/companyService';

const Clickable = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const NoPointerWrapper = styled.div`
  pointer-events: none;

  div {
    transition: none !important;
  }
`;
const MoreWrapper = styled.div`
  font-family: Space Grotesk;
  font-style: normal;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
`;
const SortableItem = styled(GridItem)`
  cursor: ${props => (props?.hasDragPermission ? 'grab' : 'unset !important')};
`;
const StyledMoreButton = styled.button`
  cursor: pointer;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: rgba(204, 213, 255, 0.11);
  margin-bottom: 5px;
`;

const IconTags = ({
  afterDrag,
  callService = true,
  hasDragPermission = false,
  page = 'about',
  portfolio = null,
  project = false,
  service = null,
  tags,
  type
}) => {
  const profile = useSelector(profileSelectors.selectProfile);
  const [width, setWidth] = useState(window.innerWidth);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const dispatch = useDispatch();
  const [sliceTo, setSliceTo] = useState(
    localStorage.getItem(`slice_${type}`) ? parseInt(localStorage.getItem(`slice_${type}`)) : 11
  );
  const dndRef = useRef(null);

  const sortedTags = tags;

  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if (sortedTags) {
      const filteredTags = sortedTags.filter(item => item !== undefined);
      setTagList(filteredTags);
    }
  }, [sortedTags]);

  const onResize = useCallback(() => {
    dndRef?.current && setWidth(dndRef.current.getBoundingClientRect().width * 0.9);
  }, [dndRef.current, setWidth]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [dndRef.current]);

  useEffect(() => {
    window.addEventListener('beforeunload', clearSlice);
    return () => {
      window.removeEventListener('beforeunload', clearSlice);
    };
  }, []);

  const clearSlice = () => {
    localStorage.setItem(`slice_${type}`, '11');
  };

  const updateTags = params => {
    const serviceType = isCompanyView ? companyService : talentService;
    const id = isCompanyView ? myProfileId : profile.id;
    serviceType
      .setTags(id, params)
      .then(response => {
        if (isCompanyView) {
          if (project) {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'generalProjects',
                updateType: 'update',
                value: response.data.data,
                profileId: id
              })
            );
          } else {
            const services = {};
            response.data.data.map(service => (services[service._id] = service));
            dispatch(
              profileActions.updateProfileProperty({
                property: 'services',
                value: services,
                profileId: id
              })
            );
          }
        } else if (portfolio) {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'projects',
              updateType: 'update',
              value: response.data.data,
              profileId: id
            })
          );
        } else {
          if (page === 'services') {
            const services = {};
            response.data.data.map(item => {
              services[item._id] = item;
            });
            dispatch(
              profileActions.updateProfileProperty({
                property: 'services',
                value: services,
                profileId: id
              })
            );
          } else if (project) {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'generalProjects',
                updateType: 'update',
                value: response.data.data,
                profileId: id
              })
            );
          } else {
            dispatch(
              profileActions.updateProfileProperty({
                property: [`about.${type}`],
                value: response.data.data.about[type],
                profileId: id
              })
            );
          }
        }
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  function onChange(sourceId, sourceIndex, targetIndex) {
    const nextState = swap(tagList, sourceIndex, targetIndex);
    setTagList(nextState);

    const tagIdList = nextState.map(tag => tag._id);
    if (afterDrag) {
      afterDrag(type, nextState);
    }

    const params = {
      [type.toString()]: tagIdList,
      type,
      service,
      portfolio,
      project
    };

    if (callService) {
      updateTags(params);
    }
  }
  const handleMoreClick = () => {
    if (sliceTo === 11) {
      localStorage.setItem(`slice_${type}`, '24');
      setSliceTo(24);
    } else {
      localStorage.setItem(`slice_${type}`, '11');
      setSliceTo(11);
    }
  };

  const slicedTagList = tagList.slice(0, sliceTo);
  const moreThanTwelve = tagList.length > 12;
  const data = moreThanTwelve ? slicedTagList : tagList;

  const itemWidth = 80;
  const itemHeight = 100;

  const columnsPerRow = Math.round(width / itemWidth);

  let rows = data.length <= columnsPerRow ? 1 : Math.ceil(data.length / columnsPerRow);
  if (data.length > 12 && data.length % columnsPerRow === 0) {
    rows += 1;
  }

  let height = 0;
  if (!isEmpty(tags)) {
    height = rows === 1 ? itemWidth : itemHeight * rows;
  }

  return (
    <div ref={dndRef}>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          disableDrag={!hasDragPermission}
          disableDrop={!hasDragPermission}
          id="tagList"
          boxesPerRow={columnsPerRow}
          rowHeight={itemHeight}
          style={{ height: `${height}px`, width: '90%' }}
        >
          {!isEmpty(tags) &&
            data.map(
              tag =>
                tag && (
                  <SortableItem key={tag._id} hasDragPermission={hasDragPermission} style={{ width: 80 }}>
                    <IconTag key={tag._id} tag={tag} hasDragPermission={hasDragPermission} />
                  </SortableItem>
                )
            )}
          {moreThanTwelve && (
            <Clickable onClick={handleMoreClick} key="more">
              <NoPointerWrapper>
                <GridItem>
                  <MoreWrapper>
                    <StyledMoreButton>
                      {sliceTo > 11 ? (
                        <Icon icon="close" width={20} height={20} />
                      ) : (
                        <Icon icon="dots" width={40} height={40} />
                      )}
                    </StyledMoreButton>
                    {sliceTo > 11 ? 'Show Less' : 'Show More'}
                  </MoreWrapper>
                </GridItem>
              </NoPointerWrapper>
            </Clickable>
          )}
        </GridDropZone>
      </GridContextProvider>
    </div>
  );
};

export default IconTags;
