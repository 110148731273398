import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  z-index: 11;
  top: 20px;
  right: 20px;
`;

export const ToastList = styled.div``;

export const ToastItem = styled.div`
  background-color: ${props => (props.status === 'success' ? '#00FF00' : '#d9534f')};
  padding: 13px 16px;
  border-radius: 8px;
  color: ${props => (props.status === 'success' ? 'rgba(0, 0, 0, 1)' : 'white')};
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
