import React from 'react';
import styled from '@emotion/styled';

import Icon from 'common/Icon';
import DeleteExperienceModal from './DeleteExperienceModal';

const DropdownList = styled.div`
  width: 240px;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  color: rgba(230, 234, 255, 0.6);
  padding: 8px 16px;
  transition: color 0.2s ease;
  cursor: pointer;
  &:hover {
    color: white;
  }

  &:hover svg path {
    fill: white;
  }
`;

const TalentDropdown = ({ editExperience, handleDelete }) => (
  <DropdownList>
    <DropdownItem onClick={editExperience}>
      <Icon icon="edit" color="rgba(230, 234, 255, 0.6)" mr={16} />
      Edit
    </DropdownItem>
    <DropdownItem onClick={handleDelete}>
      <Icon icon="delete" color="rgba(230, 234, 255, 0.6)" mr={16} />
      Delete
    </DropdownItem>
    <DeleteExperienceModal />
  </DropdownList>
);

export default TalentDropdown;
