import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import ContainedButton from 'common/ContainedButton';
import LinkWrapper from 'common/LinkWrapper';
import Typography from 'components/shared/Typography';
import Icon from 'common/Icon';
import { profileSelectors } from 'store/profile';
import useProfilePath from 'hooks/useProfilePath';

const StyledAddProjectContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  min-height: 483px;
`;

const StyledTypography = styled(Typography)`
  max-width: 70%;
`;

const emptyText =
  "It's the quality of your assets that scores the highest points with clients. Showcase your strongest projects, to catch the hiring eyes you want.";

const AddProjectCard = ({ fullScreen }) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const { myProfilePath } = useProfilePath();

  if (!hasPermission) return null;

  return (
    <StyledAddProjectContainer>
      {fullScreen && <Icon icon="portfolio" size="64" color="rgba(221, 226, 255, 0.2);" />}
      {fullScreen && (
        <StyledTypography mb={32} mt={38} variant="medium">
          {emptyText}
        </StyledTypography>
      )}
      <LinkWrapper to={`${myProfilePath}/portfolio/add`}>
        <ContainedButton data-testid="add-project-button" icon="plus" forceDisplayText>
          Add a Project
        </ContainedButton>
      </LinkWrapper>
    </StyledAddProjectContainer>
  );
};

export default AddProjectCard;
