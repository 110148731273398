import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import commonStyles from 'utils/common-styles';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import { talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';

const StyledActionsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const StyledDescription = styled.p`
  ${commonStyles.ui_text_medium}
  margin: 0;
`;

const DeleteCreditModal = ({ closePortal, data, fromPortfolio, name, onDelete }) => {
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const { openSuccessSnackbar } = useSuccessSnackbar();

  const deleteCredit = () => {
    if (onDelete) {
      onDelete({ creditId: data.credit._id, localCreditId: data.credit.localId });
      fromPortfolio && openSuccessSnackbar('The project has been updated');
    }
    dispatch(
      profileActions.updateProfileProperty({
        property: 'credits',
        updateType: 'delete',
        value: data.credit._id,
        profileId
      })
    );

    talentService.deleteTalentCredit(profileId, data.credit._id);
    closePortal();
  };

  return (
    <TxplModal
      title="Delete credit"
      titleMB={24}
      titlePadding="16px"
      name={name}
      appElement={document.getElementById('root-modal')}
      size="small"
      nonFullScreenOnMobile
      padding="24px 0 44px"
      paddingXS="0 0 24px"
      renderFooter={
        <StyledActionsContainer>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </ContainedButton>

          <ContainedButton
            onClick={deleteCredit}
            backgroundColor="#FF5151"
            forceDisplayText
            data-testid="confirm-delete"
          >
            Delete
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <StyledDescription>
        Warning! If you delete here, it’s game over for this credit throughout your profile.
      </StyledDescription>
    </TxplModal>
  );
};

export default withRenderPortal('delete-credit-modal')(DeleteCreditModal);
