import * as React from 'react';

const ESuiteBadge = ({ active, ...svgProps }) =>
  active ? (
    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M15.2977 3.41641C18.1016 1.37925 21.8984 1.37926 24.7023 3.41641L34.3188 10.4033C37.1227 12.4404 38.296 16.0513 37.225 19.3475L33.5518 30.6525C32.4808 33.9487 29.4092 36.1803 25.9434 36.1803H14.0566C10.5908 36.1803 7.51918 33.9487 6.44818 30.6525L2.77498 19.3475C1.70399 16.0513 2.87725 12.4404 5.68115 10.4033L15.2977 3.41641Z"
        fill="url(#paint0_radial_85_7728)"
      />
      <path
        d="M16.4733 5.56231C18.5762 4.03444 21.4238 4.03444 23.5267 5.56231L32.6412 12.1844C34.7442 13.7123 35.6241 16.4205 34.8209 18.8926L31.3394 29.6074C30.5362 32.0795 28.2325 33.7533 25.6331 33.7533H14.3669C11.7676 33.7533 9.46381 32.0795 8.66057 29.6074L5.17912 18.8926C4.37588 16.4205 5.25583 13.7123 7.35875 12.1844L16.4733 5.56231Z"
        fill="#0B7A8E"
      />
      <g filter="url(#filter0_d_85_7728)">
        <path
          d="M20 10L21.569 16.2121L27.0711 12.9289L23.7879 18.431L30 20L23.7879 21.569L27.0711 27.0711L21.569 23.7879L20 30L18.431 23.7879L12.9289 27.0711L16.2121 21.569L10 20L16.2121 18.431L12.9289 12.9289L18.431 16.2121L20 10Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_85_7728"
          x="6"
          y="8"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.184679 0 0 0 0 0.184679 0 0 0 0 0.184679 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_85_7728" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_85_7728" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_85_7728"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.57628e-06 40) rotate(-90) scale(40 60)"
        >
          <stop stopColor="#0B7A8E" />
          <stop offset="1" stopColor="#0BBEDA" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M15.2977 3.41641C18.1016 1.37925 21.8984 1.37926 24.7023 3.41641L34.3188 10.4033C37.1227 12.4404 38.296 16.0513 37.225 19.3475L33.5518 30.6525C32.4808 33.9487 29.4092 36.1803 25.9434 36.1803H14.0566C10.5908 36.1803 7.51918 33.9487 6.44818 30.6525L2.77498 19.3475C1.70399 16.0513 2.87725 12.4404 5.68115 10.4033L15.2977 3.41641Z"
        fill="C7C7FF"
        fillOpacity="0.21"
      />
      <path
        d="M16.4733 5.56231C18.5762 4.03444 21.4238 4.03444 23.5267 5.56231L32.6412 12.1844C34.7442 13.7123 35.6241 16.4205 34.8209 18.8926L31.3394 29.6074C30.5362 32.0795 28.2325 33.7533 25.6331 33.7533H14.3669C11.7676 33.7533 9.46381 32.0795 8.66057 29.6074L5.17912 18.8926C4.37588 16.4205 5.25583 13.7123 7.35875 12.1844L16.4733 5.56231Z"
        fill="#C7C7FF"
        fillOpacity="0.21"
      />
      <path
        d="M20 10L21.569 16.2121L27.0711 12.9289L23.7879 18.431L30 20L23.7879 21.569L27.0711 27.0711L21.569 23.7879L20 30L18.431 23.7879L12.9289 27.0711L16.2121 21.569L10 20L16.2121 18.431L12.9289 12.9289L18.431 16.2121L20 10Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
    </svg>
  );

export default ESuiteBadge;
