import React from 'react';
import styled from '@emotion/styled';
import Icon from 'common/Icon';

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border: 2px dashed rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  width: 100%;
  height: 364px;
`;

const Legend = styled.span`
  weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.6);
`;

const StateIcon = styled(Icon)`
  width: 58px;
  height: 64px;
`;

const JobsEmptyList = ({ iconName, text }) => {
  return (
    <EmptyList>
      <StateIcon icon={iconName} />
      <Legend>{text}</Legend>
    </EmptyList>
  );
};

export default JobsEmptyList;
