import React from 'react';
import jobService from 'api/jobService';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions, jobsSelectors } from 'store/jobs';

const useHiredJobs = () => {
  const jobs = useSelector(jobsSelectors.selectMyJobs('hiredJobs'));
  const { hasMore } = useSelector(jobsSelectors.selectMyJobsPagination('hiredJobs'));
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const getHiredJobs = React.useCallback(
    async (profileId, skip, limit = 4) => {
      if (!isLoading && hasMore && profileId !== undefined) {
        try {
          setIsLoading(true);
          const {
            data: { data, hasMore }
          } = await jobService.getHiredJobs(profileId, skip, limit);

          dispatch(
            jobActions.updateJob({
              jobType: 'hiredJobs',
              profileId,
              jobs: data
            })
          );

          dispatch(
            jobActions.updateJobPagination({
              jobType: 'hiredJobs',
              profileId,
              pagination: {
                hasMore,
                limit
              }
            })
          );
        } catch (err) {
          dispatch(
            jobActions.updateJobPagination({
              jobType: 'hiredJobs',
              profileId,
              pagination: {
                hasMore: false,
                limit
              }
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isLoading, hasMore, dispatch]
  );

  return {
    hiredJobs: jobs,
    loadingHiredJobs: isLoading,
    hasMoreHiredJobs: hasMore,
    getHiredJobs,
  };
};

export default useHiredJobs;
