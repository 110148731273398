import { userService } from 'api';
import CollaboratorsVideoModal from 'components/modals/CollaboratorsVideoModal';
import IDVerificationVideoModal from 'components/modals/IDVerificationVideoModal';
import IDVerifiedModal from 'components/modals/IDVerifiedModal';
import SignupModal from 'components/modals/SignupModal/SignupModal';
import VideoaskModal from 'components/modals/VideoaskModal';
import useAPICall from 'hooks/useAPICall';
import useCollaborationsService from 'hooks/useCollaboration';
import useCurrentVerification from 'hooks/useCurrentVerification';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';
import DeclineCollaboration from 'pages/community/DeclineCollaboration';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUserProperty } from 'store/auth/authActions';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import { portalActions } from 'store/portals';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import * as Styled from './StyledHome';
import SubmitProfileModal from './SubmitProfileModal';

const Home = ({ location: { search } }) => {
  const showIDVerifiedModal = localStorage.getItem('showIDVerifiedModal');

  const dispatch = useDispatch();
  const { openErrorSnackbar } = useErrorSnackbar();

  const activeProfile = useSelector(selectCurrentUserProperty('activeProfile'));
  const currentUserId = useSelector(currentUserPropertySelector('_id'));

  const { getCollaboratorByExternalId } = useCollaborationsService(true, 1000);

  useCurrentVerification();

  const { callApi: fetchBiasReducedMode } = useAPICall({
    name: 'fetch-bias-reduced-mode',
    url: `/users/${currentUserId}/bias-reduced-mode`,
    method: 'get',
    transformData: response => response,
    onSuccess: ({ biasReducedMode }) => {
      dispatch(updateCurrentUserProperty({ property: 'activeProfile', value: { ...activeProfile, biasReducedMode } }));
    },
    onFailure: err => console.log('err: ', err)
  });

  const openSignupModal = data => {
    dispatch(
      portalActions.openPortal({
        name: 'signup',
        data
      })
    );
  };

  useEffect(() => {
    if (showIDVerifiedModal) {
      dispatch(portalActions.openPortal({ name: 'id-verified-modal' }));
    }
  }, [showIDVerifiedModal]);

  useEffect(() => {
    if (currentUserId) {
      fetchBiasReducedMode();
    }
  }, [currentUserId]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const externalId = query.get('collaborator');
    const email = query.get('email');
    const name = query.get('name');

    const userExists = async (email, name) => {
      try {
        const {
          data: { exists }
        } = await userService.checkUserEmail(email);
        if (!exists) {
          openSignupModal({
            email,
            name
          });
        }
      } catch (err) {
        openErrorSnackbar(err.response?.data.message);
      }
    };

    const checkCollaborator = async externalId => {
      const collaborator = await getCollaboratorByExternalId(externalId);
      if (collaborator) {
        userExists(collaborator.email, collaborator.name);
      }
    };

    if (externalId) {
      checkCollaborator(externalId);
    } else if (email && name) {
      userExists(email, name);
    }
  }, []);

  return (
    <>
      <Styled.Wrapper>
        <Styled.Background />
        <Styled.StyledContainer>
          <Styled.StyledHeader>
            <img
              alt="The X Place"
              src="https://thexplace.ai/wp-content/themes/thexplace/images/thexplace-logo.svg"
              width={156}
              height={24}
            />
          </Styled.StyledHeader>
          <main id="home-main-root"></main>
        </Styled.StyledContainer>
      </Styled.Wrapper>
      <DeclineCollaboration />
      <VideoaskModal />
      <SignupModal />
      <SubmitProfileModal />
      <CollaboratorsVideoModal />
      <IDVerificationVideoModal />
      <IDVerifiedModal />
    </>
  );
};

export default Home;
