import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { backOfficeActions } from 'store/backOffice';
import { userSelectors } from 'store/user';
import { Column, Table } from 'components/Table';
import { portalActions } from 'store/portals';
import { talentService } from 'api';
import Switcher from 'common/form/Switcher';

const LockPage = props => {
  const {
    match: {
      params: { talentId }
    }
  } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const user = useSelector(userSelectors.selectUser);

  const getLockStatus = ({ lockStatus, statusChangedOn }) => {
    if (lockStatus && lockStatus === 'UNLOCK_REQUESTED') {
      return 'Pending';
    } else if (!!statusChangedOn && moment(statusChangedOn).isValid()) {
      return 'Done';
    } else {
      return 'N/A';
    }
  };

  const handleEditClick = e => {
    dispatch(
      portalActions.openPortal({
        name: 'lock-page-edit-modal',
        data: {
          id: e.entity?.entityId,
          entity: e.entity?.entityType,
          title: e.entity?.title,
          locked: e.entity?.lockStatus
            ? e.entity?.lockStatus === 'LOCKED' || e.entity?.lockStatus === 'UNLOCK_REQUESTED'
            : null,
          comment: e.entity ? e.entity?.comment : null,
          vettingStatus: e.status
        }
      })
    );
  };

  const contentText = content => (content?.length > 20 ? `${content.substr(0, 20)}...` : content);

  useEffect(() => {
    talentService.getTalent(talentId).then(res => {
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'Operations' },
          { label: 'Soloist accounts', url: '/backoffice/operations/soloists' },
          {
            label: `${user.firstName || res.data.data.name || ''} ${user.lastName || ''}`,
            url: `/backoffice/operations/soloists/${talentId}`
          },
          { label: 'Lock-Unlock inputs' }
        ])
      );
    });
  }, [user, talentId]);

  return (
    <>
      <Table
        ref={tableRef}
        keyId="_id"
        dataKey="data"
        endpoint={`/backoffice/accounts/${talentId}/profile-entities`}
        paginate
      >
        <Column title="section" keyName="section" render={item => <div>{item.entity?.entityType}</div>} />
        <Column
          title="input title"
          keyName="input title"
          render={item => <div>{contentText(item.entity?.title)}</div>}
        />
        <Column
          title="Request Date"
          keyName="unlock request date"
          render={item => (
            <div>{item.entity?.unlockRequestDate ? moment(item.entity?.unlockRequestDate).format('LLL') : 'N/A'}</div>
          )}
        />
        <Column
          title="changed by"
          keyName="changed by"
          render={item =>
            item.entity ? (
              <div>
                <span>{contentText(item.entity?.changedBy)}&nbsp;</span>
              </div>
            ) : (
              <div>N/A</div>
            )
          }
        />
        <Column
          title="status changed on"
          keyName="status changed on"
          render={item =>
            item.entity && item.entity.statusChangedOn ? (
              <div>{moment(item.entity?.statusChangedOn).format('LLL')}</div>
            ) : (
              <div>N/A</div>
            )
          }
        />
        <Column
          title="icon"
          keyName="icon"
          render={item => {
            return (
              <>
                {item.status === 'PENDING' && <div>⏰ Pending</div>}
                {item.status === 'IN_PROGRESS' && <div>In Progress</div>}
                {item.status === 'COMPLETE' && <div>✔ Vetted</div>}
              </>
            );
          }}
        />
        <Column
          title="Status"
          keyName="itemsLockStatus"
          render={item => (
            <Switcher
              checked={!item.entity?.lockStatus || item.entity?.lockStatus === 'UNLOCKED'}
              icon
              noFill
              noCursor
            />
          )}
        />
        <Column title="request status" keyName="request status" render={item => getLockStatus(item?.entity)} />
        <Column
          title="comments"
          keyName="comments"
          render={item => (item.entity ? <div>{contentText(item.entity?.comment)}</div> : <div>N/A</div>)}
        />
        <Column title="" width={46} type="action" onClick={handleEditClick} />
      </Table>
    </>
  );
};

export default LockPage;
