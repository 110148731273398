import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';
import { selectProfileProperty } from 'store/profile/profileSelectors';
import { getArray } from 'utils/convertObjToArray';
import Frame from 'common/Frame';
import TagsModal from 'common/TagsModal';
import { Description, StyledContainedButton } from './styled.components';
import ProjectItem from './ProjectItem';
import AddProjectModal from './AddProject/AddProjectModal';
import EditProjectModal from './EditProject/EditProjectModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from '../../../common/Icon';
import styled from '@emotion/styled';
import { pageSize } from '../../../utils/devices';
import companyService from '../../../api/companyService';

const DragIcon = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 1;
  right: 64px;
  top: 22px;
  cursor: grab;
`;

const Wrapper = styled.div`
  &:hover .drag-icon-${props => props.id} {
    display: block;
  }
  @media ${pageSize.XS} {
    .drag-icon-${props => props.id} {
      display: block;
    }
  }
`;

const Projects = () => {
  const dispatch = useDispatch();
  const talent = useSelector(profileSelectors.selectProfile);
  const projects = useSelector(selectProfileProperty('generalProjects')) || [];
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const [projectsArr, setProjectsArr] = useState(getArray(projects));
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const [collapsedItem, setCollapsedItem] = useState({ name: '', overview: '', platforms: [], genres: [], tags: [] });

  useEffect(() => {
    setProjectsArr(Object.values(projects));
  }, [projects]);

  function handleOnDragEnd(result) {
    const items = Array.from(projectsArr);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setProjectsArr(items);

    const sortedIdList = items.map(item => item._id);

    companyService
      .updateProjectSort(profileId, sortedIdList)
      .then(() => {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'generalProjects',
            value: items,
            profileId: profileId
          })
        );
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }

  const handleTagsSave = async data => {
    try {
      const formData = new FormData();
      const bodyParams = { ...collapsedItem, [data.type]: data.data.map(item => item.value || item._id) };
      formData.append('data', JSON.stringify(bodyParams));
      const res = await talentService.updateProject(talent._id, collapsedItem._id, formData);
      dispatch(
        profileActions.updateProfileProperty({
          property: 'generalProjects',
          updateType: 'update',
          value: res.data.data,
          profileId
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Frame
      filled={projectsArr.length !== 0}
      color="#A4BFD8"
      text="Current Projects"
      hidden={!hasEditPermission && projectsArr.length === 0}
    >
      {projectsArr.length === 0 && <Description>Tell about the projects you are currently working on</Description>}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {projectsArr.map((project, index) => (
                <Wrapper id={project._id} key={index}>
                  <Draggable
                    key={project._id}
                    draggableId={project._id}
                    index={index}
                    isDragDisabled={!hasEditPermission && true}
                  >
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          style={{
                            position: 'relative'
                          }}
                        >
                          {hasEditPermission && projectsArr.length > 1 && (
                            <DragIcon {...provided.dragHandleProps} className={`drag-icon-${project._id}`}>
                              <Icon icon="draggable_touch" size={32} noFill />
                            </DragIcon>
                          )}
                          <ProjectItem
                            key={project._id}
                            {...project}
                            project={project}
                            setCollapsedItem={setCollapsedItem}
                            hasEditPermission={hasEditPermission}
                            hideTagsEditIcon
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                </Wrapper>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {hasEditPermission && (
        <>
          <Frame filled={false}>
            <StyledContainedButton
              icon="add"
              type="button"
              opens="add-project-modal"
              data={{}}
              backgroundColor="secondary"
            >
              Add Project
            </StyledContainedButton>
          </Frame>
          <AddProjectModal />
        </>
      )}
      <EditProjectModal />
      <TagsModal
        tags={{ platforms: collapsedItem.platforms, genres: collapsedItem.genres, tags: collapsedItem.tags }}
        onAdd={data => handleTagsSave(data)}
      />
    </Frame>
  );
};

export default Projects;
