import React from 'react';

import { ImagesGrid } from './styled.components';
import JobThumbnailGalleryItem from './JobThumbnailGalleryItem';

const JobThumbnailGallery = ({ images, selected, setSelected }) => (
  <ImagesGrid>
    {images.map(image => (
      <JobThumbnailGalleryItem key={image._id} image={image} selected={selected} setSelected={setSelected} />
    ))}
  </ImagesGrid>
);

export default JobThumbnailGallery;
