import React from 'react';
import styled from '@emotion/styled';
import HeaderLinkContainer from './HeaderLinkContainer';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
`;

const PageHeaderLinks = () => (
  <FlexRow>
    <HeaderLinkContainer />
  </FlexRow>
);

export default PageHeaderLinks;
