import styled from '@emotion/styled';

export const Card = styled.div`
  position: relative;
  background: #111112;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius || '16'}px;
  padding: ${({ padding }) => padding || '24px'};
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
`;
