import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import commonStyles from 'utils/common-styles';
import { isLoggedInSelector } from 'store/selectors/currentUser';
import ProtectedRoute from 'Router/ProtectedRoute';
import AdminPanel from './AdminPanel';
import BackofficeMenu from './BackofficeMenu';
import Dashboard from './Dashboard';
import KnowledgeBase from './KnowledgeBase';
import Operations from './Operations';
import BackofficeBreadCrumbs from './BackofficeBreadCrumbs';
import Soloists from './Soloists';
import UsersNew from './UsersNew';
import Soloist from './Soloist';
import LockPage from './lockPage/LockPage';
import BackOfficeMembers from './BackOfficeMembers';
import BackOfficeMembersNew from './BackOfficeMembers/BackOfficeMembersNew';
import BackOfficeUserDetails from './BackOfficeUserDetails';
import BackOfficeUsers from './BackOfficeUsers/index';
import ActivityStatusModal from './ActivityStatusModal';
import FunnelStatusModal from './FunnelStatusModal';
import VettingStatusModal from './VettingStatusModal';
import BackOfficeDeleteAccountModal from './modals/BackOfficeDeleteAccountModal';
import UserInfoModal from './UserInfoModal';
import BackOfficeSuspendAccountModal from './modals/BackOfficeSuspendAccountModal';
import BackOfficeTags from './BackOfficeTags';
import BackOfficeCompanies from './BackOfficeCompanies';
import BackOfficeMissingItems from './BackOfficeMissingItems';
import BackOfficeUserStatusLog from './BackOfficeUserStatusLog';
import BackOfficeSoloistStatusLog from './BackOfficeSoloistStatusLog';
import BackofficeCompanyAccounts from './BackofficeCompanyAccounts';
import BackofficeCreateCompany from './BackofficeCreateCompany';
import BackofficeCompanyDetails from './BackofficeCompanyDetails';
import BackOfficeSoloistVettingLog from './BackOfficeSoloistVettingLog';
import BackOfficeSoloistFunnelLog from './BackOfficeSoloistFunnelLog';
import FlagModal from './FlagModal';
import CompanyFlagModal from './CompanyFlagModal';
import CompanyInfoModal from './CompanyInfoModal';
import CompanyActivityStatusModal from './CompanyActivityStatusModal';
import CompanyFunnelStatusModal from './CompanyFunnelStatusModal';
import BackOfficeVetting from './BackOfficeVetting/BackOfficeVetting';
import IdVerificationStatusModal from './IdVerificationStatusModal';
import BackOfficeUserVerificationStatusLog from './BackOfficeUserVerificationStatusLog';
import BackOfficeVettingInner from './BackOfficeVetting/BackOfficeVettingInner';
import VettingCommentModal from './VettingCommentModal';
import VettingOnlyStatusModal from './VettingOnlyStatusModal';
import VettingOutcomeModal from './VettingOutcomeModal';
import VettingAssigneeModal from './VettingAssigneeModal';
import BackOfficeUserFunnelLog from './BackOfficeUserFunnelLog';
import BackofficeCreateSoloist from './BackofficeCreateSoloist';
import BackOfficeUnlockRequests from './BackOfficeUnlockRequests';
import BackOfficeCandidates from './BackOfficeCandidates/BackOfficeCandidates';
import { getDecodedIdToken } from 'utils/cookies';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const MainPageWrapper = styled.div`
  height: 100%;
  min-width: 600px;
`;

const MainPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  padding-left: 280px;
  padding-right: 40px;
`;
const specificPageCSS = `
  padding-top: 25px;
  text-transform: none;
  font-size: 16px;
`;
const Title = styled.div`
  ${commonStyles.ui_heading_1}
  color: rgba(230, 234, 255, 0.35);
  box-sizing: border-box;
  height: fit-content;
  padding-top: 104px;
  ${props => props.specificPage && specificPageCSS}
`;
const Content = styled.div``;
const Signin = styled.span`
  ${commonStyles.caption}
  position: absolute;
  top: 40px;
  right: 40px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px 10px;
  cursor: pointer;
`;

const Backoffice = ({ match: { path } }) => {
  const history = useHistory();
  const location = useLocation();

  const handleLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.append('login', 'true');
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const decoded = getDecodedIdToken();

  const isSuperUser = decoded.roles.includes('super-admin');
  const isAnalyst = decoded.roles.includes('analyst');
  const isLoggedIn = useSelector(isLoggedInSelector);

  const { pathname } = useLocation();
  const specificPage = pathname.includes('lock-page') || pathname.includes('profile-unlock');

  return (
    <>
      <div id="backoffice-modal-placeholder" />
      <FlexRow>
        <BackofficeMenu />

        <MainPage specificPage={specificPage}>
          <MainPageWrapper>
            {!isLoggedIn && <Signin onClick={handleLogin}>Sign In</Signin>}

            <Title specificPage={specificPage}>
              <BackofficeBreadCrumbs specificPage={specificPage} />
            </Title>

            <Content>
              {isSuperUser && (
                <Switch>
                  <Redirect exact from={`${path}`} to={`${path}/dashboard`} />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/dashboard`}
                    component={props => <Dashboard {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations`}
                    component={props => <Operations {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/users`}
                    component={BackOfficeUsers}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/vetting`}
                    component={BackOfficeVetting}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/candidates`}
                    component={BackOfficeCandidates}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/vetting/:id`}
                    component={BackOfficeVettingInner}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/profile-unlock`}
                    component={BackOfficeUnlockRequests}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/company-accounts`}
                    component={BackofficeCompanyAccounts}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/company/:companyId`}
                    component={BackofficeCompanyDetails}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/company-accounts/new`}
                    component={BackofficeCreateCompany}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/users/new`}
                    component={props => <UsersNew {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/users/:userId`}
                    component={BackOfficeUserDetails}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    path={`${path}/operations/users/:userId/status-log`}
                    component={BackOfficeUserStatusLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    path={`${path}/operations/users/:userId/funnel-log`}
                    component={BackOfficeUserFunnelLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    path={`${path}/operations/users/:userId/id-verification-logs`}
                    component={BackOfficeUserVerificationStatusLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists`}
                    component={Soloists}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists/new`}
                    component={BackofficeCreateSoloist}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists/:talentId`}
                    component={props => <Soloist {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists/:talentId/lock-page`}
                    component={props => <LockPage {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists/:userId/status-log`}
                    component={BackOfficeSoloistStatusLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists/:userId/vetting-inputs`}
                    component={BackOfficeSoloistVettingLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/soloists/:userId/funnel-log`}
                    component={BackOfficeSoloistFunnelLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/company/:userId/funnel-log`}
                    component={BackOfficeSoloistFunnelLog}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base`}
                    component={props => <KnowledgeBase {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/disciplines-and-roles`}
                    component={BackOfficeTags}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/platforms`}
                    component={BackOfficeTags}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/tools`}
                    component={BackOfficeTags}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/skills`}
                    component={BackOfficeTags}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/genres`}
                    component={BackOfficeTags}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/tags`}
                    component={BackOfficeTags}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/companies`}
                    component={BackOfficeCompanies}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/knowledge-base/missing-items`}
                    component={BackOfficeMissingItems}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/admin-panel`}
                    component={props => <AdminPanel {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/admin-panel/members`}
                    component={BackOfficeMembers}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/admin-panel/members/new`}
                    component={props => <BackOfficeMembersNew {...props} />}
                  />
                  <Route component={() => <div>404 - PAGE NOT EXISTS</div>} />
                </Switch>
              )}
              {isAnalyst && (
                <Switch>
                  <Redirect exact from={`${path}`} to={`${path}/dashboard`} />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/dashboard`}
                    component={props => <Dashboard {...props} />}
                  />
                  <ProtectedRoute
                    roles={['super-admin']}
                    exact
                    path={`${path}/operations/vetting`}
                    component={BackOfficeVetting}
                  />
                  <Route component={() => <div>404 - PAGE NOT EXISTS</div>} />
                </Switch>
              )}
            </Content>
          </MainPageWrapper>
        </MainPage>
      </FlexRow>

      <ActivityStatusModal />
      <FunnelStatusModal />
      <IdVerificationStatusModal />
      <CompanyFunnelStatusModal />
      <VettingStatusModal />
      <VettingCommentModal />
      <VettingOnlyStatusModal />
      <VettingOutcomeModal />
      <VettingAssigneeModal />
      <FlagModal />
      <CompanyFlagModal />
      <CompanyInfoModal />
      <CompanyActivityStatusModal />
      <BackOfficeDeleteAccountModal />
      <BackOfficeSuspendAccountModal />
      <UserInfoModal />
    </>
  );
};

export default Backoffice;
