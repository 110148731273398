import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';

export const Container = styled.div`
  display: flex;
  height: calc(100% - 72px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  margin: 16px 0;
  font-size: 16px;
  color: rgba(229, 234, 255, 0.6);
`;

export const NewMessageButton = styled(ContainedButton)`
  background: rgba(204, 212, 255, 0.11);
  font-size: 14px;
  border-radius: 6px;
`;
