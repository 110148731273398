import { useCallback, useEffect, useState } from 'react';
import { companyService, talentService } from 'api';
import queryString from 'query-string';

function useCheckPublicUrl({ isCompany, publicUrl }) {
  const [existsMessage, setExistsMessage] = useState('');

  const checkIfPublicUrlExists = useCallback(
    url => {
      if (url) {
        const service = isCompany ? companyService.checkCompany : talentService.checkTalent;

        service(queryString.stringify({ publicUrl: url }))
          .then(({ data: { exists, message, success } }) => {
            if (exists && success) {
              setExistsMessage(message);
            }
            if (!exists && success && existsMessage.length) {
              setExistsMessage('');
            }
          })
          .catch(err => {
            const errors = err.response?.data?.errors;
            if (errors) {
              const error = errors.find(({ param }) => param === 'publicUrl');
              setExistsMessage(error.msg);
            }
          });
      }
    },
    [existsMessage, setExistsMessage]
  );

  useEffect(() => {
    const timeout = setTimeout(() => checkIfPublicUrlExists(publicUrl), [500]);
    return () => {
      clearTimeout(timeout);
    };
  }, [publicUrl, checkIfPublicUrlExists]);

  return {
    existsMessage
  };
}

export default useCheckPublicUrl;
