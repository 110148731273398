import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useJobServices from 'hooks/api/useJobServices.js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import {
  ScopeDescription,
  ScopeFooterWrapper,
  ScopeRadioButtons,
  StyledRadioButton
} from './StyledUnpublishJobModal.js';

const UnpublishJobModal = ({ closePortal, data }) => {
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const { changeJobStatus } = useJobServices();
  const { jobId, setStatus } = data;

  const [value, setValue] = useState('');

  const onSubmit = () => {
    changeJobStatus(profileId, jobId, value, () => {
      setStatus(value);
      closePortal();
    });
  };

  return (
    <TxplModal
      title="Unpublish job"
      name="unpublish-job-modal"
      appElement={document.getElementById('root-modal')}
      size="small"
      padding="40px 0"
      hideBorder
      noPaddingTop
      renderFooter={
        <ScopeFooterWrapper>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            CANCEL
          </ContainedButton>
          <ContainedButton onClick={onSubmit} backgroundColor="#7266EE" forceDisplayText disabled={!value}>
            unpublish
          </ContainedButton>
        </ScopeFooterWrapper>
      }
    >
      <ScopeDescription>
        The job will be removed from the search results. Are you going to keep staffing once the job is unpublished?
      </ScopeDescription>
      <ScopeRadioButtons>
        <StyledRadioButton
          margin="0 0 16px 0"
          circleColor="rgba(204, 213, 255, 0.11)"
          value="unpublished"
          selected={value}
          onChange={() => setValue('unpublished')}
          text="Unpublish and keep staffing"
        />
        <StyledRadioButton
          margin="0 0 16px 0"
          circleColor="rgba(204, 213, 255, 0.11)"
          value="closed"
          selected={value}
          onChange={() => setValue('closed')}
          text="Unpublish and close"
        />
      </ScopeRadioButtons>
    </TxplModal>
  );
};

export default withRenderPortal('unpublish-job-modal')(UnpublishJobModal);
