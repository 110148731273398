import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { pageSize } from 'utils/devices';
import UserCard from 'components/UserCard';
import { profileSelectors } from 'store/profile';
import useViewport from 'hooks/useViewport';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  width: 324px;
  top: -380px;
  top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 54px;
  height: fit-content;
  @media ${pageSize.S} {
    padding: 0px 81px;
    width: calc(100% - 161px);
  }
  @media ${pageSize.S}, ${pageSize.XS} {
    position: relative;
    padding: initial;
    margin: 0 auto;
    left: 0px;
    top: 0px;
  }
  @media ${pageSize.XS} {
    width: calc(100% - 32px);
  }
  @media ${pageSize.L} {
    padding-left: 0px;
  }
`;

const UserCardWrapper = () => {
  const cardRef = React.useRef();
  const basicInfo = useSelector(profileSelectors.selectProfileProperty('basicInfo'));
  const profile = useSelector(profileSelectors.selectProfile);
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const { width } = useViewport();

  const [fitsInViewport, setFitsInViewport] = React.useState(true);

  React.useLayoutEffect(() => {
    const onWindowResize = () => {
      if (window.innerWidth < 1150) {
        cardRef.current.removeAttribute('style');
        return;
      }

      const coords = cardRef.current.getBoundingClientRect();
      const fits = coords.height + 160 <= window.innerHeight;
      setFitsInViewport(fits);
    };

    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  React.useEffect(() => {
    cardRef.current.removeAttribute('style');
  }, [fitsInViewport]);

  useScrollPosition(
    ({ prevPos }) => {
      if (width >= 1150) {
        const { clientHeight, scrollHeight, scrollTop } = document.documentElement;
        const lastScrollPosition = Math.abs(prevPos.y);

        const isScrollingDown = scrollTop - lastScrollPosition > 0;
        const isScrollingUp = scrollTop - lastScrollPosition < 0;
        const currentTop = parseFloat(window.getComputedStyle(cardRef.current).getPropertyValue('top'));

        const windowHeight = window.innerHeight;
        const sidebarHeight = cardRef.current.offsetHeight;

        const defaultTop = 24;
        const scrollSidebar = windowHeight - defaultTop * 2 - sidebarHeight < 0;
        const marginBottom = 296;

        const scrolledLength = Math.abs(scrollTop - lastScrollPosition);

        const maxTop = scrollSidebar ? windowHeight - defaultTop - sidebarHeight : defaultTop;
        const maxBottom = windowHeight - marginBottom - sidebarHeight;

        const add = !scrollSidebar ? Math.abs(maxBottom) + defaultTop : marginBottom - defaultTop;

        const isOnBottom = scrollHeight < clientHeight + scrollTop + add;

        if (isScrollingDown) {
          const newTop = currentTop - scrolledLength;

          let top = newTop > maxTop ? newTop : maxTop;

          if (maxBottom < 0 && isOnBottom) {
            top = newTop > maxBottom ? newTop : maxBottom;
          } else if (maxBottom > 0 && isOnBottom) {
            top = currentTop + scrolledLength < maxBottom ? currentTop + scrolledLength : maxBottom;
          }

          cardRef.current.setAttribute('style', `top: ${top}px`);
        } else if (isScrollingUp) {
          const newTop = currentTop + scrolledLength;
          let top = 0;

          if (scrollTop < 160 - defaultTop) {
            top = newTop < 160 ? newTop : 160;
          } else {
            top = newTop < defaultTop ? newTop : defaultTop;
          }
          cardRef.current.setAttribute('style', `top: ${top}px`);
        }
      }
    },
    [width]
  );

  const data = { basicInfo, profile, canEdit: hasEditPermission };

  return (
    <Wrapper ref={cardRef}>
      <UserCard {...data} />
    </Wrapper>
  );
};

export default UserCardWrapper;
