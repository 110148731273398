import React from 'react';
import { useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';

const StyledHeaderColumnItem = styled.th`
  text-align: left;
  white-space: nowrap;
  border: 1px solid rgba(204, 213, 255, 0.11);
  border-top: none;
  border-bottom: none;
  vertical-align: initial;
  position: sticky;
  background-color: #111112;
  top: 0;
`;

const StyledHeaderColumnItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid rgba(204, 213, 255, 0.11);
  ${({ sort }) =>
    sort &&
    `
    :hover {
      cursor: pointer;
    }
  `}
  ${({ addStyles }) =>
    addStyles &&
    `
    justify-content: space-between;
    padding-right: 18.67px;
`}
`;

const StyledHeaderTitle = styled.p`
  ${({ isHidden }) => isHidden && 'visibility: hidden;'}
  ${commonStyles.caption}
  vertical-align: initial;
  color: rgba(230, 234, 255, 0.35);
  padding: 0 15px;
`;

const StyledIconWrapper = styled.div`
  padding-left: 10px;
  padding-right: 16px;
  display: flex;
  color: rgba(230, 234, 255, 0.35);
  align-items: center;
`;

const HeaderColumn = ({ handleSort, sort, sortDir, title, type }) => {
  const sortIconName = React.useMemo(() => (sortDir === 'asc' ? 'up' : 'down'), [sortDir]);

  const handleClick = () => {
    if (!sort) return;
    handleSort();
  };

  return (
    <StyledHeaderColumnItem key={title}>
      <StyledHeaderColumnItemWrapper
        sort={sort}
        onClick={handleClick}
        addStyles={useLocation().pathname.includes('profile-unlock')}
      >
        <StyledHeaderTitle isHidden={type === 'action'}>
          {title}
          {type === 'action' && 'A'}
        </StyledHeaderTitle>
        {sort && (
          <StyledIconWrapper>
            {sortIconName === 'up' && '↑'}
            {sortIconName === 'down' && '↓'}
          </StyledIconWrapper>
        )}
      </StyledHeaderColumnItemWrapper>
    </StyledHeaderColumnItem>
  );
};

export default HeaderColumn;
