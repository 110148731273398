import React, { useState } from 'react';
import styled from '@emotion/styled';

import { companyService } from 'api';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import FormUpload from 'common/form/FormUpload';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
  margin-left: auto;
`;

const BackOfficeCompanyModal = ({
  closePortal,
  data: { companyId, initialName, isEdit, tableRef },
  name: modalName
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [associates, setAssociates] = useState([]);
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState();

  const handleSubmit = async e => {
    setIsLoading(true);
    try {
      e.preventDefault();

      const body = {
        name,
        associates
      };

      const data = new FormData();

      if (isEdit) {
        data.append('company', JSON.stringify(body));
        if (image) {
          data.append('file', image);
        }
        await companyService.updateCompany(companyId, data);
      } else {
        if (image) {
          data.append('file', image);
        }
        data.append('company', JSON.stringify(body));
        await companyService.createCompany(data);
      }

      if (tableRef) {
        tableRef.current.refetch();
      }
      closePortal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await companyService.deleteCompany(companyId);
      if (tableRef) {
        tableRef.current.refetch();
      }
      closePortal();
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isEdit || isLoading) return;

    setIsLoading(true);
    companyService
      .getCompany(companyId)
      .then(({ data: { company } }) => {
        setName(company.name);
        setAssociates(company.associates);
        setInitialImage(company.image?.url);
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line
  }, [companyId])

  return (
    <TxplModal
      name={modalName}
      size="small"
      appElement={document.getElementById('root')}
      title={isEdit ? 'Edit Company' : 'Add New Company'}
      renderFooter={
        <SubmitRow>
          {isEdit && (
            <ContainedButton
              icon="delete"
              disabled={isLoading}
              onClick={handleDelete}
              backgroundColor="rgba(204, 213, 255, 0.11)"
            />
          )}

          <StyledButtonsContainer>
            {isLoading && <Spinner />}
            <ContainedButton
              type="button"
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              disabled={isLoading}
            >
              Cancel
            </ContainedButton>
            <ContainedButton type="submit" form="backOfficeCompanyModal" forceDisplayText disabled={isLoading}>
              Save
            </ContainedButton>
          </StyledButtonsContainer>
        </SubmitRow>
      }
    >
      <form onSubmit={handleSubmit} id="backOfficeCompanyModal" style={{ maxWidth: '600px' }}>
        <FormInput autoFocus label="Name" value={name} onChange={e => setName(e.target.value)} />
        <FormUpload
          wrapperStyles={{ flexDirection: 'column' }}
          disabled
          label="TheXPlace Logo"
          onChange={setImage}
          noCrop
          value={initialImage}
          hintText="Should be an SVG image"
          mt={20}
          preview
          aspectRatio={208 / 300}
          mw="100px"
          accept="image/svg+xml"
          padding
        />
        <FormInput
          label="Associations"
          value={associates?.join(', ')}
          onChange={e => setAssociates(e.target.value.split(', '))}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-company-modal')(BackOfficeCompanyModal);
