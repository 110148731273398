import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { backOfficeActions } from 'store/backOffice';

import { Column, Table } from 'components/Table';
import { funnelStatusesEnum } from '../../constants';

import ActivityStatus from './ActivityStatus';
import SupplyDemandStatus from './SupplyDemandStatus';
import FunnelStatus from './FunnelStatus';
import ContainedButton from '../../common/ContainedButton';

const StyledIdContainer = styled.p`
  width: 108px;
  overflow-x: scroll;
`;

const StyledButton = styled(ContainedButton)`
  margin-left: auto;
  margin-bottom: 16px;
  button {
    padding: 12px 16px;
    height: auto;
  }
`;

const Soloists = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Operations' }, { label: 'Soloist accounts' }]));
  }, [dispatch]);

  return (
    <>
      <StyledButton onClick={() => history.push('/backoffice/operations/soloists/new')}>
        Create new soloist account
      </StyledButton>

      <Table keyId="_id" paginate endpoint="/backoffice/talents" dataKey="talents">
        <Column
          title="ACCOUNT ID"
          keyName="_id"
          render={talent => <StyledIdContainer>{talent._id}</StyledIdContainer>}
          width="108px"
          search
        />
        <Column title="First Name" keyName="userId.firstName" width="13%" search sort />
        <Column title="Last Name" keyName="userId.lastName" search sort />
        <Column title="Email" keyName="userId.email" search sort />
        <Column
          title="Public profile URL"
          keyName="talentId"
          render={talent => <Link to={`/talent/${talent.talentId}`}>/{talent.talentId}</Link>}
          search
          sort
        />
        <Column
          title="Supply / Demand"
          keyName="isSupply"
          width="13%"
          render={talent => (
            <>
              <SupplyDemandStatus isSupply={talent.isSupply} />
            </>
          )}
        />
        <Column
          title="Funnel Status"
          keyName="status"
          render={talent => (
            <FunnelStatus
              value={talent.funnelStatusLogs?.[0]?.status}
              status={funnelStatusesEnum[talent.funnelStatusLogs?.[0]?.status] || 'Account Created'}
            />
          )}
          search
        />
        <Column
          title="Activity Status"
          keyName="status"
          render={talent => <ActivityStatus status={talent.status} />}
          search
          sort
        />
        <Column title="" type="action" width={46} onClick={user => history.push(`soloists/${user._id}`)} />
      </Table>
    </>
  );
};

export default Soloists;
