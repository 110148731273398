import React from 'react';
import styled from '@emotion/styled';

import Icon from 'common/Icon';

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 2px solid white;
  ${props => (props.checked ? 'background-color: white;' : '')};
  ${props => (props.color ? `background-color: ${props.color};` : '')};
  ${props => (props.color || props.border ? `border: 2px solid ${props.color || props.border};` : '')};
  border-radius: ${({ borderRadius }) => borderRadius || 8}px;
  padding: 2px;

  ${({ disabled }) => disabled && 'border-color: rgb(84, 86, 91);'}
`;

const CheckboxIcon = ({ border, checked, color, ...rest }) => (
  <StyledCheckbox className="checkbox" color={color} border={border} checked={checked} {...rest}>
    {checked && <Icon icon="checkmark" size="16px" color="black" hoverColor="black" />}
  </StyledCheckbox>
);

export default CheckboxIcon;
