import React from 'react';
import { useDispatch } from 'react-redux';
import { authService } from 'api';
import { authActions } from 'store/auth';
import useForm from 'hooks/useForm';
import useViewport from 'hooks/useViewport';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import FormCheckbox from 'common/form/FormCheckbox';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import * as Styled from './StyledSignupModal';
import { profileActions } from 'store/profile';
import useProfileSwitcher from 'hooks/useProfileSwitcher';

const fields = {
  firstName: { initialValue: '', validate: [] },
  lastName: { initialValue: '', validate: [] },
  name: { initialValue: '', validate: ['isRequired'] },
  email: { initialValue: '', validate: ['isRequired'] },
  password: { initialValue: '', validate: ['isRequired', 'strongPassword'] },
  isCollaborator: { initialValue: true },
  termsOfService: { initialValue: true, validate: ['isRequired'] },
  emailNotifications: { initialValue: false }
};

const onValidate = formFields => {
  const customErrors = {};
  if (!formFields.getIn(['termsOfService', 'value'])) {
    customErrors.termsOfService = 'Please accept TheXPlace Terms of Service before continuing';
  }
  return customErrors;
};

const SignupModal = ({ closePortal, data }) => {
  const { email, name: fullName } = data;
  const { isXS } = useViewport();
  const dispatch = useDispatch();
  const { switchProfile } = useProfileSwitcher();

  const [firstName, lastName] = fullName.split(' ');

  const callApi = formValues => authService.signUp(formValues);

  const onSuccess = ({ talent, user }) => {
    dispatch(authActions.signup(user));
    dispatch(profileActions.initializeProfiles([talent]));
    dispatch(profileActions.setActiveProfileId(talent?._id));
    switchProfile(talent);

    window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}/onboarding`;
  };

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    callApi,
    onSuccess,
    onSubmitSuccess: closePortal,
    onValidate,
    fields: {
      ...fields,
      email: { ...fields.email, initialValue: email },
      name: { ...fields.name, initialValue: fullName },
      firstName: { ...fields.firstName, initialValue: firstName },
      lastName: { ...fields.lastName, initialValue: lastName ?? '' }
    }
  });

  return (
    <TxplModal
      contentPosition="unset"
      overlayPosition="unset"
      nonFullScreenOnMobileCenter
      transparentBackground
      title="Thanks for helping a colleague, you rock!"
      contentPadding="32px 40px 40px 24px"
      XSpadding="24px 0 56px"
      XSaddPaddingTop="14px"
      padding="32px 0"
      noPaddingTop
      size="large"
      sameFontSize
      hideCloseIcon={true}
      titleMB={isXS ? 16 : 24}
      parentSelector={() => document.querySelector('#home-main-root')}
      renderFooter={
        <Styled.FooterWrapper>
          {submitting && <Spinner />}
          <ContainedButton ml={6} type="submit" form="signUpForm" disabled={submitting}>
            SIGN UP
          </ContainedButton>
        </Styled.FooterWrapper>
      }
    >
      <Styled.CreateAccount>
        First, let&apos;s create a password for you so we can securely capture your feedback.
      </Styled.CreateAccount>
      <Styled.Form onSubmit={onSubmit} autoComplete="off" id="signUpForm">
        <Styled.InputContainer>
          <Styled.Input
            label="Create a password"
            type="password"
            hintText="Password must contain at least 8 characters, one number and one uppercase."
            fullWidth
            value={values.password}
            hintError={errors.password?.length}
            error={errors.password}
            noErrorMessage
            onChange={onFieldChange('password')}
          />
        </Styled.InputContainer>
        <FormCheckbox
          iconBorder="rgba(204, 213, 255, 0.11)"
          mt={isXS ? 16 : 12}
          checkBoxRadius={4}
          text={
            <Styled.CheckboxText>
              <Typography variant="small" color="rgba(230, 234, 255, 0.6)">
                Yes! I agree to receive useful emails every now and then to help me get the most out of TheXPlace.
              </Typography>
            </Styled.CheckboxText>
          }
          value={values.emailNotifications}
          checked={values.emailNotifications}
          onChange={event => onFieldChange('emailNotifications')(event.target.checked)}
        />
        <FormCheckbox
          iconBorder="rgba(204, 213, 255, 0.11)"
          checkBoxRadius={4}
          text={
            <Styled.CheckboxText>
              <Typography variant="small" color="rgba(230, 234, 255, 0.6)">
                Yes, I understand and agree to the TheXPlace Terms of Service, including the Privacy Policy.*
              </Typography>
            </Styled.CheckboxText>
          }
          value={values.termsOfService}
          checked={values.termsOfService}
          error={errors.termsOfService}
          onChange={event => onFieldChange('termsOfService')(event.target.checked)}
        />
        {errors?.response?.data?.message && <Styled.ErrorMessage>{errors.response.data.message}</Styled.ErrorMessage>}
      </Styled.Form>
    </TxplModal>
  );
};

export default withRenderPortal('signup')(SignupModal);
