const analystMenuItems = [
  {
    label: 'Dashboard',
    url: '/backoffice/dashboard',
    icon: 'portfolio-grid',
    subMenuItems: []
  },
  {
    label: 'Operations',
    icon: 'operations',
    subMenuItems: [
      {
        label: 'Vetting',
        url: '/backoffice/operations/vetting'
      }
    ]
  }
];

export default analystMenuItems;
