import Api from './api';

const api = new Api('tags');

const getAllTags = params => api.get('/', { params });

const getTagsByType = data => api.post('/byTypes', data);

const deleteTag = tagId => api.delete(`/${tagId}`);

const requestTag = data => api.post('/request', data);

const approveMissingItem = (tagId, data) =>
  api.post(`/${tagId}/approve-missing-item`, data, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const getTag = tagId => api.get(`/${tagId}`);

const createTag = data =>
  api.post('/create', data, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const createTagWithImage = formData =>
  api.post('/', formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const updateTag = (tagId, data) =>
  api.put(`/${tagId}`, data, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

export default {
  getAllTags,
  getTagsByType,
  deleteTag,
  requestTag,
  approveMissingItem,
  getTag,
  createTag,
  updateTag,
  createTagWithImage
};
