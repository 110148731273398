import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import styled from '@emotion/styled';
import Icon from 'common/Icon';
import FormInput from './FormInput';

const StyledContainer = styled.div`
  outline: none;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  z-index: 2000;
  height: 301px;
  .DayPicker_transitionContainer {
    border-radius: 8px;
  }
`;
const StyledFormInput = styled(FormInput)`
  width: 280px;
`;
const StyledLeftIcon = styled(Icon)`
  position: absolute;
  top: 27px;
  left: 35px;
`;
const StyledRightIcon = styled(Icon)`
  position: absolute;
  top: 27px;
  right: 35px;
`;
const StyledInputContainer = styled.div`
  position: relative;
`;

const FormDateInput = ({ onChange, value, ...rest }) => {
  const calendarRef = useRef();
  const itemRef = useRef(null);
  const [focused, updateFocused] = useState(false);
  const [inputVal, setInputVal] = useState((value && moment(value).format('MM/DD/YYYY')) ?? '');

  const handleMouseDown = useCallback(e => {
    itemRef.current = e.target;
    if (
      itemRef.current.nodeName !== 'svg' &&
      itemRef.current.nodeName !== 'path' &&
      !itemRef.current.className.includes('CalendarDay')
    ) {
      updateFocused(false);
    }
  }, []);

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Tab') {
      updateFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown, false);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown, false);
    };
  }, [handleMouseDown]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const onFocus = () => {
    updateFocused(true);
  };

  const onIconClick = () => {
    updateFocused(!focused);
  };

  const onBlur = event => {
    if (!event.relatedTarget) return updateFocused(false);

    const { value: classListValue } = event.relatedTarget.classList;
    if (
      itemRef.current.nodeName !== 'svg' &&
      itemRef.current.nodeName !== 'path' &&
      !classListValue.includes('DayPicker') &&
      !classListValue.includes('Calendar')
    )
      updateFocused(false);
  };

  const onCalendarBlur = event => {
    event.stopPropagation();

    if (!event.relatedTarget) return;

    const { value: classListValue } = event.relatedTarget.classList;

    if (
      !classListValue.includes('DayPicker') &&
      !classListValue.includes('Calendar') &&
      event.relatedTarget.tagName !== 'INPUT'
    ) {
      setTimeout(() => updateFocused(false), 0);
    } else {
      calendarRef.current.focus();
    }
  };

  const onCalendarChange = date => {
    onChange(date.toDate());
    setInputVal(moment(date.toDate()).format('MM/DD/YYYY'));
    setTimeout(() => updateFocused(false), 0);
  };

  const onInputChange = e => {
    const { value: targetValue } = e.target;
    if (moment(targetValue, 'MM/DD/YYYY', true).isValid()) {
      const date = moment(targetValue, 'MM/DD/YYYY', true);
      onChange(moment(date.toDate()));
      return setInputVal(moment(date.toDate()).format('MM/DD/YYYY'));
    }
    setInputVal(targetValue);
    onChange(targetValue);
    updateFocused(false);
  };

  return (
    <StyledInputContainer>
      <StyledFormInput
        onFocus={onFocus}
        onBlur={onBlur}
        value={inputVal}
        onChange={onInputChange}
        onIconClick={onIconClick}
        icon="calendar"
        iconBottom={21}
        fitContent
        iconRight={20}
        autoComplete={'off'}
        {...rest}
      />

      {focused && (
        <StyledContainer
          top={calendarRef.current?.getBoundingClientRect()?.height}
          className="Calendar-container"
          tabIndex={0}
          onBlur={onCalendarBlur}
          ref={calendarRef}
        >
          <DayPickerSingleDateController
            navPrev={<StyledLeftIcon icon="arrow-left" />}
            navNext={<StyledRightIcon icon="arrow-right" />}
            date={inputVal.length && moment(inputVal).isValid() ? moment(inputVal) : null}
            onBlur={onCalendarBlur}
            hideKeyboardShortcutsPanel
            focused
            onDateChange={onCalendarChange}
            numberOfMonths={1}
          />
        </StyledContainer>
      )}
    </StyledInputContainer>
  );
};

export default FormDateInput;
