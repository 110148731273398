import * as React from 'react';
import Gradients from './common';
const IdVerificationSubmittedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144} height={120} fill="none" viewBox="0 0 144 120">
    <g clipPath="url(#a)">
      <path
        fill="#FF8A01"
        d="M99.462 90.808a10 10 0 0 0-8.574-3.204L79.71 88.926c-6.126.725-10.142 6.769-8.436 12.697l9.178 31.888c1.628 5.655 7.764 8.689 13.246 6.549l23.76-9.274c6.532-2.55 8.48-10.854 3.763-16.042L99.462 90.808Z"
      />
      <rect width={120} height={84} x={12} y={18} fill="url(#b)" rx={8} />
      <rect width={112} height={64} x={16} y={34} fill="#172B1C" fillOpacity={0.2} rx={4} />
      <g filter="url(#c)">
        <rect width={52} height={52} y={40} fill="#BFCAFF" fillOpacity={0.1} rx={26} shapeRendering="crispEdges" />
      </g>
      <g filter="url(#d)">
        <rect width={51} height={6} x={62} y={56} fill="#fff" rx={3} />
      </g>
      <g filter="url(#e)">
        <rect width={37} height={6} x={62} y={69} fill="#fff" rx={3} />
      </g>
      <path
        fill="url(#f)"
        d="M80.115 134.629c8.08-1.698 13.255-9.625 11.557-17.706L87.17 95.5c-1.698-8.08-9.626-13.254-17.706-11.556-8.081 1.698-13.255 9.625-11.557 17.706l4.502 21.422c1.698 8.081 9.625 13.255 17.706 11.557Z"
      />
      <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={6} d="M13 66.429 21.387 75 39 57" />
    </g>
    <defs>
      <filter
        id="c"
        width={135.2}
        height={135.2}
        x={-41.6}
        y={-1.6}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={20.8} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_792_23216" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={8} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00641666 0 0 0 0 0.320833 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend in2="effect1_backgroundBlur_792_23216" result="effect2_dropShadow_792_23216" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_792_23216" result="shape" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={2.6} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect3_innerShadow_792_23216" />
      </filter>
      <filter id="d" width={55} height={12} x={60} y={56} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.0897 0 0 0 0 0.1703 0 0 0 0 0.108507 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_792_23216" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_792_23216" result="shape" />
      </filter>
      <filter id="e" width={41} height={12} x={60} y={69} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.0897 0 0 0 0 0.1703 0 0 0 0 0.108507 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_792_23216" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_792_23216" result="shape" />
      </filter>
      <Gradients />
    </defs>
  </svg>
);
export default IdVerificationSubmittedIcon;
