export const companyItems = [
  {
    id: 0,
    icon: 'company',
    text: 'About',
    tabName: 'about',
    size: '50px',
    color: 'red',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 1,
    icon: 'portfolio',
    text: 'Portfolio',
    tabName: 'portfolio',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 2,
    icon: 'services',
    text: 'Services',
    tabName: 'services',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 3,
    icon: 'projects',
    text: 'Projects',
    tabName: 'projects',
    size: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 4,
    icon: 'media-coverage',
    text: 'Media-coverage',
    tabName: 'media-coverage',
    size: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 5,
    icon: 'jobs',
    text: 'Jobs',
    tabName: 'jobs',
    size: '',
    color: '',
    disabled: false,
    views: ['isL', 'isS']
  },
  {
    id: 7,
    icon: 'life',
    text: 'Life',
    tabName: 'life',
    size: '',
    color: '',
    views: [],
    disabled: false
  },
  {
    id: 6,
    icon: 'community',
    text: 'Community',
    tabName: 'community',
    size: '',
    color: '',
    tooltip: 'Coming Soon 😉',
    views: [],
    disabled: true
  }
];

export const userItems = [
  {
    id: 0,
    icon: 'about',
    text: 'About',
    tabName: 'about',
    size: '50px',
    color: 'red',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 1,
    icon: 'portfolio',
    text: 'Portfolio',
    tabName: 'portfolio',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 2,
    icon: 'services',
    text: 'Services',
    tabName: 'services',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 4,
    icon: 'media-coverage',
    text: 'Media-coverage',
    tabName: 'media-coverage',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 5,
    icon: 'experience',
    text: 'Experience',
    tabName: 'experience',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 6,
    icon: 'jobs',
    text: 'Jobs',
    tabName: 'jobs',
    size: '',
    color: '',
    views: ['isL', 'isM', 'isS']
  },
  {
    id: 7,
    icon: 'community',
    text: 'Community',
    tabName: 'community',
    size: '',
    color: '',
    tooltip: 'Coming Soon 😉',
    disabled: true,
    views: []
  }
];
