import * as React from 'react';

const VerifiedBadgeIcon = ({ active, ...svgProps }) =>
  active ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M14.4525 0.890146C15.2527 -0.0872188 16.7473 -0.0872187 17.5475 0.890146L18.5218 2.08015C19.0657 2.74445 19.9728 2.98752 20.776 2.68416L22.2147 2.14073C23.3964 1.6944 24.6908 2.44172 24.8951 3.68823L25.1439 5.20596C25.2827 6.05319 25.9468 6.71728 26.794 6.85614L28.3118 7.10489C29.5583 7.3092 30.3056 8.60358 29.8593 9.78525L29.3158 11.224C29.0125 12.0272 29.2556 12.9343 29.9198 13.4782L31.1099 14.4525C32.0872 15.2527 32.0872 16.7473 31.1099 17.5475L29.9198 18.5218C29.2556 19.0657 29.0125 19.9728 29.3158 20.776L29.8593 22.2147C30.3056 23.3964 29.5583 24.6908 28.3118 24.8951L26.794 25.1439C25.9468 25.2827 25.2827 25.9468 25.1439 26.794L24.8951 28.3118C24.6908 29.5583 23.3964 30.3056 22.2147 29.8593L20.776 29.3158C19.9728 29.0125 19.0657 29.2556 18.5218 29.9198L17.5475 31.1099C16.7473 32.0872 15.2527 32.0872 14.4525 31.1099L13.4782 29.9198C12.9343 29.2556 12.0272 29.0125 11.224 29.3158L9.78525 29.8593C8.60358 30.3056 7.3092 29.5583 7.10489 28.3118L6.85614 26.794C6.71728 25.9468 6.05319 25.2827 5.20596 25.1439L3.68823 24.8951C2.44172 24.6908 1.6944 23.3964 2.14073 22.2147L2.68416 20.776C2.98752 19.9728 2.74445 19.0657 2.08015 18.5218L0.890146 17.5475C-0.0872188 16.7473 -0.0872187 15.2527 0.890146 14.4525L2.08015 13.4782C2.74445 12.9343 2.98752 12.0272 2.68416 11.224L2.14073 9.78525C1.6944 8.60358 2.44172 7.3092 3.68823 7.10489L5.20596 6.85614C6.05319 6.71728 6.71728 6.05319 6.85614 5.20596L7.10489 3.68823C7.3092 2.44172 8.60358 1.6944 9.78525 2.14073L11.224 2.68416C12.0272 2.98752 12.9343 2.74445 13.4782 2.08015L14.4525 0.890146Z"
        fill="url(#paint0_radial_93_7735)"
      />
      <g filter="url(#filter0_d_93_7735)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2676 9H11C12.6569 9 14 10.3431 14 12C14 12.7684 13.7112 13.4692 13.2361 14C13.7112 14.5308 14 15.2316 14 16C14 16.7684 13.7112 17.4692 13.2361 18C13.7112 18.5308 14 19.2316 14 20C14 21.6569 12.6569 23 11 23H10.2676C10.6134 23.5978 11.2597 24 12 24H22C23.1046 24 24 23.1046 24 22V10C24 8.89543 23.1046 8 22 8H12C11.2597 8 10.6134 8.4022 10.2676 9ZM19 15C20.1046 15 21 14.1046 21 13C21 11.8954 20.1046 11 19 11C17.8954 11 17 11.8954 17 13C17 14.1046 17.8954 15 19 15ZM19 16C20.6569 16 22 17.3431 22 19V20C22 20.5523 21.5523 21 21 21H17C16.4477 21 16 20.5523 16 20V19C16 17.3431 17.3431 16 19 16Z"
          fill="white"
        />
        <path
          d="M8 12C8 11.4477 8.44772 11 9 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H9C8.44772 13 8 12.5523 8 12Z"
          fill="white"
        />
        <path
          d="M8 16C8 15.4477 8.44772 15 9 15H11C11.5523 15 12 15.4477 12 16C12 16.5523 11.5523 17 11 17H9C8.44772 17 8 16.5523 8 16Z"
          fill="white"
        />
        <path
          d="M8 20C8 19.4477 8.44772 19 9 19H11C11.5523 19 12 19.4477 12 20C12 20.5523 11.5523 21 11 21H9C8.44772 21 8 20.5523 8 20Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_93_7735"
          x="6"
          y="7"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.148568 0 0 0 0 0.0247613 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_93_7735" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_93_7735" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_93_7735"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-0.999997 33) rotate(-90) scale(34 51)"
        >
          <stop stopColor="#2B883D" />
          <stop offset="1" stopColor="#00FF40" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M14.4525 0.890146C15.2527 -0.0872188 16.7473 -0.0872187 17.5475 0.890146L18.5218 2.08015C19.0657 2.74445 19.9728 2.98752 20.776 2.68416L22.2147 2.14073C23.3964 1.6944 24.6908 2.44172 24.8951 3.68823L25.1439 5.20596C25.2827 6.05319 25.9468 6.71728 26.794 6.85614L28.3118 7.10489C29.5583 7.3092 30.3056 8.60358 29.8593 9.78525L29.3158 11.224C29.0125 12.0272 29.2556 12.9343 29.9198 13.4782L31.1099 14.4525C32.0872 15.2527 32.0872 16.7473 31.1099 17.5475L29.9198 18.5218C29.2556 19.0657 29.0125 19.9728 29.3158 20.776L29.8593 22.2147C30.3056 23.3964 29.5583 24.6908 28.3118 24.8951L26.794 25.1439C25.9468 25.2827 25.2827 25.9468 25.1439 26.794L24.8951 28.3118C24.6908 29.5583 23.3964 30.3056 22.2147 29.8593L20.776 29.3158C19.9728 29.0125 19.0657 29.2556 18.5218 29.9198L17.5475 31.1099C16.7473 32.0872 15.2527 32.0872 14.4525 31.1099L13.4782 29.9198C12.9343 29.2556 12.0272 29.0125 11.224 29.3158L9.78525 29.8593C8.60358 30.3056 7.3092 29.5583 7.10489 28.3118L6.85614 26.794C6.71728 25.9468 6.05319 25.2827 5.20596 25.1439L3.68823 24.8951C2.44172 24.6908 1.6944 23.3964 2.14073 22.2147L2.68416 20.776C2.98752 19.9728 2.74445 19.0657 2.08015 18.5218L0.890146 17.5475C-0.0872188 16.7473 -0.0872187 15.2527 0.890146 14.4525L2.08015 13.4782C2.74445 12.9343 2.98752 12.0272 2.68416 11.224L2.14073 9.78525C1.6944 8.60358 2.44172 7.3092 3.68823 7.10489L5.20596 6.85614C6.05319 6.71728 6.71728 6.05319 6.85614 5.20596L7.10489 3.68823C7.3092 2.44172 8.60358 1.6944 9.78525 2.14073L11.224 2.68416C12.0272 2.98752 12.9343 2.74445 13.4782 2.08015L14.4525 0.890146Z"
        fill="#C7C7FF"
        fillOpacity="0.21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2676 9H11C12.6569 9 14 10.3431 14 12C14 12.7684 13.7112 13.4692 13.2361 14C13.7112 14.5308 14 15.2316 14 16C14 16.7684 13.7112 17.4692 13.2361 18C13.7112 18.5308 14 19.2316 14 20C14 21.6569 12.6569 23 11 23H10.2676C10.6134 23.5978 11.2597 24 12 24H22C23.1046 24 24 23.1046 24 22V10C24 8.89543 23.1046 8 22 8H12C11.2597 8 10.6134 8.4022 10.2676 9ZM19 15C20.1046 15 21 14.1046 21 13C21 11.8954 20.1046 11 19 11C17.8954 11 17 11.8954 17 13C17 14.1046 17.8954 15 19 15ZM19 16C20.6569 16 22 17.3431 22 19V20C22 20.5523 21.5523 21 21 21H17C16.4477 21 16 20.5523 16 20V19C16 17.3431 17.3431 16 19 16Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        d="M8 12C8 11.4477 8.44772 11 9 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H9C8.44772 13 8 12.5523 8 12Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        d="M8 16C8 15.4477 8.44772 15 9 15H11C11.5523 15 12 15.4477 12 16C12 16.5523 11.5523 17 11 17H9C8.44772 17 8 16.5523 8 16Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        d="M8 20C8 19.4477 8.44772 19 9 19H11C11.5523 19 12 19.4477 12 20C12 20.5523 11.5523 21 11 21H9C8.44772 21 8 20.5523 8 20Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
    </svg>
  );

export default VerifiedBadgeIcon;
