import React from 'react';
import styled from '@emotion/styled';

import useForm from 'hooks/useForm';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';

const StyledContainer = styled.div`
  background-color: rgba(204, 213, 255, 0.05);
  padding: 16px;
  border-radius: 8px;
`;

const fields = initialValues => ({
  name: { initialValue: initialValues.name ?? '', validate: ['isRequired'] },
  email: { initialValue: initialValues.email ?? '', validate: ['isRequired', 'isEmail'] },
  workRelationship: { initialValue: initialValues.workRelationship ?? '', validate: ['isRequired'] }
});

const AddCreditReference = ({ onAdd, onValidate }) => {
  const onSubmitSuccess = params => {
    onAdd(params);
  };

  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields: fields({}),
    onSubmitSuccess,
    onValidate
  });

  const handleSubmit = e => {
    e.stopPropagation();
    onSubmit(e);
  };

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit}>
        <FormInput label="Name" required value={values.name} error={errors.name} onChange={onFieldChange('name')} />

        <FormInput
          label="Email"
          required
          hintText="The automated message we send is polite and tactful—we promise"
          value={values.email}
          error={errors.email}
          onChange={onFieldChange('email')}
        />

        <FormInput
          label="Work Relationship"
          required
          value={values.workRelationship}
          error={errors.workRelationship}
          placeholder="e.g. teammate, company CEO, etc."
          onChange={onFieldChange('workRelationship')}
        />

        <ContainedButton mt={30} mb={30} type="submit" backgroundColor="rgba(204, 213, 255, 0.11);">
          SAVE REFERENCE
        </ContainedButton>
      </form>
    </StyledContainer>
  );
};

export default AddCreditReference;
