import set from 'lodash/fp/set';
import map from 'lodash/fp/map';
import update from 'lodash/fp/update';
import unset from 'lodash/fp/unset';
import concat from 'lodash/fp/concat';
import isObject from 'lodash/fp/isObject';
import union from 'lodash/fp/union';
import * as profileActionTypes from './profileActionTypes';

const normalize = arr => (arr || []).reduce((result, curr) => ({ ...result, [curr._id ?? curr.id]: curr }), {});

const normalizeProfile = profile => ({
  credits: [],
  ...profile,
  favorites: {
    ...profile?.favorites,
    jobs: normalize(profile?.favorites?.jobs)
  },
  mediaCoverage: normalize(profile?.mediaCoverage),
  experience: normalize(profile?.experience),
  projects: normalize(profile?.projects),
  jobs: normalize(profile?.jobs),
  appliedJobs: normalize(profile?.appliedJobs),
  services: normalize(profile?.services),
  generalProjects: normalize((profile?.projects || []).filter(({ isGeneral }) => isGeneral)),
  projectsList: normalize(
    (profile?.projects || []).filter(({ isGeneral, status }) => status !== 'in-progress' && !isGeneral)
  ),
  conversations: normalize(profile?.conversations)
});

const addNewMessage = ({ message, profileId, state }) => {
  const conversationId = message.conversation.id;

  return {
    ...state,
    [profileId]: {
      ...state[profileId],
      conversations: {
        ...state[profileId].conversations,
        [conversationId]: {
          ...state[profileId].conversations[conversationId],
          messages: [...state[profileId].conversations[conversationId].messages, { ...message, conversationId }],
          typing: [],
          lastMessage: {
            id: message.id,
            attachment: message.attachment,
            text: message.body,
            readBy: [],
            senderId: message.senderId,
            conversationId: message.conversation.id
          }
        }
      }
    }
  };
};

const createNewConversationWithMessage = ({ message, profileId, state }) => {
  const conversationId = message.conversation.id;

  return {
    ...state,
    [profileId]: {
      ...state[profileId],
      conversations: {
        [conversationId]: {
          id: conversationId,
          unreadMessages: 0,
          participants: {
            [JSON.parse(message.conversation.custom.participants).recipient.id]: JSON.parse(
              message.conversation.custom.participants
            ).recipient,
            [JSON.parse(message.conversation.custom.participants).me.id]: JSON.parse(
              message.conversation.custom.participants
            ).me
          },
          messages: [message],
          custom: {
            job: JSON.parse(message.conversation.custom.job),
            participants: JSON.parse(message.conversation.custom.participants),
            unreadMessages: message.isByMe ? JSON.parse(message.conversation.custom.unreadMessages) : 0
          },
          typing: [],
          lastMessage: {
            id: message.id,
            attachment: message.attachment,
            text: message.body,
            readBy: [],
            senderId: message.senderId,
            conversationId: message.conversation.id
          }
        },
        ...state[profileId].conversations
      }
    }
  };
};

const profilesReducer = (state = {}, action) => {
  switch (action.type) {
    case profileActionTypes.INITIALIZE_PROFILES: {
      const { profiles } = action.payload;

      return profiles.reduce((result, curr) => ({ ...result, [curr?._id]: normalizeProfile(curr) }), {});
    }

    case profileActionTypes.SET_PROFILE: {
      const { profile } = action.payload;

      return set(profile._id, normalizeProfile(profile), state);
    }

    case profileActionTypes.UPDATE_PROFILE_PROPERTY: {
      const { profileId, property, updateKey, updateType, value } = action.payload;
      if (updateType === 'insert') {
        return update(
          `${profileId}.${property}`,
          values => (Array.isArray(values) ? concat(values, [value]) : set(value[updateKey], value, values)),
          state
        );
      }

      if (updateType === 'delete') {
        return update(
          `${profileId}.${property}`,
          values =>
            Array.isArray(values) ? values.filter(currValue => currValue[updateKey] !== value) : unset(value, values),
          state
        );
      }

      if (updateType === 'update') {
        const updated = params =>
          map(
            curr =>
              curr[updateKey] === value[updateKey]
                ? Object.keys(curr).reduce(
                    (res, currKey) => ({
                      ...res,
                      [currKey]: value[currKey] !== undefined ? value[currKey] : curr[currKey]
                    }),
                    {}
                  )
                : curr,
            params
          );

        return update(
          `${profileId}.${property}`,
          values => {
            if (Array.isArray(values)) {
              return updated(values);
            } else if (isObject(values)) {
              return normalize(updated(values));
            } else {
              return value;
            }
          },
          state
        );
      }

      if (updateType === 'replace') {
        const replace = params => map(curr => (curr[updateKey] === value[updateKey] ? value : curr), params);

        return update(
          `${profileId}.${property}`,
          values => (Array.isArray(values) ? replace(values) : normalize(replace(values))),
          state
        );
      }

      return set(`${profileId}.${property}`, value, state);
    }

    case profileActionTypes.CLEAR_PROFILES: {
      return {};
    }

    case profileActionTypes.NEW_MESSAGE: {
      const { message, profileId } = action.payload;

      const conversationId = message.conversation.id;
      const exists = state[profileId].conversations[conversationId];

      return exists
        ? addNewMessage({ state, profileId, message })
        : createNewConversationWithMessage({ state, profileId, message });
    }

    case profileActionTypes.ADD_NEW_CONVERSATION: {
      const { newConversation, profileId } = action.payload;
      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          conversations: {
            [newConversation.id]: { ...newConversation },
            ...state[profileId].conversations
          }
        }
      };
    }

    case profileActionTypes.MARK_CONVERSATION_AS_READ: {
      const { conversationId, messageIds, profileId, readBy } = action.payload;

      return update(
        `${profileId}.conversations.${conversationId}`,
        values => {
          const lastMessage = { ...values.lastMessage, readBy: union(values.lastMessage.readBy, readBy) };
          const messages = values.messages.map(value => {
            if (messageIds.includes(value.id)) {
              return {
                ...value,
                readBy
              };
            }
            return value;
          });

          return {
            ...values,
            lastMessage,
            messages
          };
        },
        state
      );
    }

    default:
      return state;
  }
};

export default profilesReducer;
