import React from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import { lifeServices } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import Spinner from 'common/Spinner';
import FormDropZone from 'common/form/FormDropZone';
import isEmpty from 'lodash/isEmpty';
import useForm from 'hooks/useForm';

const fields = life => ({
  title: { initialValue: life?.title ?? '', validate: ['isRequired'] },
  images: { initialValue: life?.images.map(it => it._id) ?? '', validate: ['isRequired'] }
});

const transform = ({ images, title }) => ({
  title,
  images
});

const onValidate = formFields => {
  const customErrors = {};
  if (!formFields.getIn(['title', 'value'])) {
    customErrors.title = 'Title is required';
  }
  if (!formFields.getIn(['images', 'value']).size > 0) {
    customErrors.images = 'At least one image is required';
  }

  return customErrors;
};

const LifePhotoGalleryModal = ({ closePortal, data, name }) => {
  const { life } = data;
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const dispatch = useDispatch();
  const categoryId = data?.categoryId;

  const { errors, onFieldChange, onSubmit, submitting, triggerErrors, values } = useForm({
    fields: fields(life),
    onValidate,
    callApi: data => callApi(transform(data)),
    closePortal: () => {
      setTimeout(() => {
        closePortal();
      }, [250]);
    }
  });

  const callApi = ({ images, title }) => {
    const data = {
      title,
      type: 'image_gallery',
      images
    };

    const promise = isEmpty(life)
      ? lifeServices.addLife(profileId, categoryId, data)
      : lifeServices.editLife(profileId, life?._id, data);

    return promise.then(res => {
      const { data } = res.data;
      dispatch(
        profileActions.updateProfileProperty({
          property: 'lives',
          updateType: isEmpty(life) ? 'insert' : 'update',
          value: data,
          profileId
        })
      );
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { errors } = triggerErrors()(onValidate);
    if (errors) return;

    onSubmit();
  };

  return (
    <TxplModal
      title="Add photo gallery"
      name={name}
      size="large"
      appElement={document.getElementById('root-modal')}
      allowLine
      padding="56px 0 80px"
      XSpadding="56px 0 80px"
      addPaddingTop
      XSaddPaddingTop={'14px'}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton form="lifePhotoGalleryModal" type="submit" disabled={submitting}>
            Save
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={handleSubmit} id="lifePhotoGalleryModal">
        <FormInput
          mb={56}
          label="Heading"
          value={values.title}
          error={errors.title}
          maxLength={50}
          onChange={e => onFieldChange('title')(e.target.value)}
          required
          autoFocus
          className="title-required"
        />

        <FormDropZone
          label="Images"
          required
          hintText="Images 5Mb or less."
          deleteIconColor="#fff"
          assets={life?.images}
          error={errors.images}
          setAssetIds={onFieldChange('images')}
          className="images-required"
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('life-photo-gallery-modal')(LifePhotoGalleryModal);
