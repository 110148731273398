import styled from '@emotion/styled';

export const BiasReducedModeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const BiasReducedModeTitle = styled.span`
  font-size: 19px;
  line-height: 26px;
  font-family: 'Space Grotesk';
  font-weight: 700;

  &:hover {
    user-select: none;
  }
`;
export const BiasReducedModeHint = styled.span`
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Space Grotesk';
  margin: 6px 0;
  color: rgba(230, 234, 255, 0.35);

  &:hover {
    user-select: none;
  }
`;
