import React from 'react';
import styled from '@emotion/styled';

import { FACEBOOK_URL } from 'utils/auth-facebook';
import { LINKEDIN_URL } from 'utils/auth-linkedin';
import { GOOGLE_URL } from 'utils/auth-google';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';

const StyledContainer = styled.div`
  display: flex;
  gap: 6px;

  @media ${pageSize.XS} {
    flex-wrap: wrap;
  }
`;

const StyledButton = styled(ContainedButton)``;

const LoginModalOauth = () => {
  const loginWithFb = () => (window.location.href = FACEBOOK_URL);
  const loginWithLinkedIn = () => (window.location.href = LINKEDIN_URL);
  const loginWithGoogle = () => (window.location.href = GOOGLE_URL);

  return (
    <StyledContainer>
      <StyledButton left disabled icon="facebook" onClick={loginWithFb} backgroundColor="#4267B2;">
        USE FACEBOOK
      </StyledButton>
      <StyledButton left disabled icon="google" onClick={loginWithGoogle} backgroundColor="#3F81ED;">
        USE GOOGLE
      </StyledButton>
      <StyledButton left disabled icon="linkedin" onClick={loginWithLinkedIn} backgroundColor="#0277B5;">
        USE LINKEDIN
      </StyledButton>
    </StyledContainer>
  );
};

export default LoginModalOauth;
