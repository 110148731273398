import React from 'react';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';

import ContainedButton from 'common/ContainedButton';
import VideoJS from 'common/Video';
import { explanataionVideosUrls } from '../../../constants';
import useVideoAsk from '../../../hooks/useVideoask';

const CollaboratorsVideoModal = ({ closePortal, data }) => {
  const { openVideoAskModal } = useVideoAsk();

  const sources = [
    {
      src: explanataionVideosUrls.collaborators,
      type: 'video/mp4'
    }
  ];

  const onClose = () => {};

  const onMessage = () => {
    console.log('[CollaboratorsVideoModal] videoAsk data', data);
  };

  const handleClose = () => {
    closePortal();
    openVideoAskModal(`${data.url}&redirect_target=self`, onClose, onMessage);
  };

  return (
    <TxplModal
      title="Collaborators"
      name="collaborators-video-modal"
      appElement={document.getElementById('root-modal')}
      titleMB="24"
      padding="32px 0"
      renderFooter={
        <>
          <ContainedButton onClick={handleClose} type="submit" backgroundColor="primary">
            SEE MY COLLABORATION REQUESTS
          </ContainedButton>
        </>
      }
    >
      <VideoJS
        options={{
          sources,
          controls: true
        }}
      />
    </TxplModal>
  );
};

export default withRenderPortal('collaborators-video-modal')(CollaboratorsVideoModal);
