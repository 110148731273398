import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background-color: ${props => props.color};
  color: ${props => props.textColor};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : '8px')};
  padding: 0px 4px;
  width: fit-content;
  text-transform: capitalize;
  height: fit-content;
  padding-top: ${props => (props.paddingTop ? `${props.paddingTop}px` : '0')};
  padding-bottom: ${props => (props.paddingBottom ? `${props.paddingBottom}px` : '0')};
  position: absolute;
  right: 3px;
  top: 4px;
  white-space: nowrap;
`;

const Badge = ({ borderRadius, color, paddingBottom, paddingTop, text, textColor }) => (
  <Wrapper
    color={color}
    textColor={textColor}
    borderRadius={borderRadius}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
  >
    {text}
  </Wrapper>
);

export default Badge;
