import React from 'react';
import _ from 'lodash';

import getDisciplinesIconAndColor from 'utils/disciplines';

import Frame from 'common/Frame';
import Icon from 'common/Icon';

import { DetailsList, DetailsListItem, DetailsListItemText, DisDetailsListItemText } from './styled.components';

const JobAtGlance = ({ details, error, hasEditPermission }) => {
  const {
    anythingElse,
    compensation,
    compensationRange,
    disciplines,
    experienceLevel,
    hideExperience,
    hideRemuneration,
    jobType,
    language,
    location,
    relocationAssistance,
    requiredAvailability,
    teamRole,
    workplaceType
  } = details;

  const { _id, ...valuesToCheck } = details;

  return (
    <Frame
      className={'details-required'}
      transparent
      titlePB={24}
      text="job at a glance"
      color="#26FFB1"
      canEdit={hasEditPermission}
      editMode={_.values(valuesToCheck).every(_.isEmpty) > 0 ? 'add' : 'edit'}
      noDataText="List the parameters and perks of this job."
      data={{ details }}
      opens="job-at-glance-modal"
      filled={!_.values(valuesToCheck).every(_.isEmpty)}
      error={error}
      required
    >
      {!_.values(valuesToCheck).every(_.isEmpty) && (
        <DetailsList>
          {disciplines && (
            <>
              {disciplines.map((discipline, index) => {
                const disciplineCard = getDisciplinesIconAndColor(discipline.label);
                return (
                  <DetailsListItem key={index} className="discipline" bg={disciplineCard?.color}>
                    <Icon icon={disciplineCard?.icon} />
                    <DisDetailsListItemText>{discipline.label}</DisDetailsListItemText>
                  </DetailsListItem>
                );
              })}
            </>
          )}
          {workplaceType && (
            <DetailsListItem>
              <Icon icon="location" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>
                {workplaceType.label} {location ? <>({location})</> : ''}
              </DetailsListItemText>
            </DetailsListItem>
          )}
          {jobType && (
            <DetailsListItem>
              <Icon icon={jobType?.value === 'internship' ? 'internship' : 'time'} color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>
                {requiredAvailability ? requiredAvailability.label.replace(/ *\([^)]*\) */g, '') : ''} · {jobType.label}
              </DetailsListItemText>
            </DetailsListItem>
          )}
          {experienceLevel && !hideExperience && (
            <DetailsListItem>
              <Icon icon="experience" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>Experience: {experienceLevel.label}</DetailsListItemText>
            </DetailsListItem>
          )}
          {teamRole && (
            <DetailsListItem>
              <Icon icon="community" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText> {teamRole.label}</DetailsListItemText>
            </DetailsListItem>
          )}
          {compensation && compensationRange && !hideRemuneration && (
            <DetailsListItem>
              <Icon icon="wallet" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>
                {' '}
                ${compensationRange.from} {compensationRange.to && '- $'}
                {compensationRange.to} {compensation.label}
              </DetailsListItemText>
            </DetailsListItem>
          )}
          {language && (
            <DetailsListItem>
              <Icon icon="language" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>{language.label}</DetailsListItemText>
            </DetailsListItem>
          )}
          {relocationAssistance && (
            <DetailsListItem>
              <Icon icon="suitcase" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>Relocation assistance</DetailsListItemText>
            </DetailsListItem>
          )}
          {anythingElse && (
            <DetailsListItem>
              <Icon icon="checkmark" color="rgba(229, 234, 255, 0.6)" />
              <DetailsListItemText>{anythingElse}</DetailsListItemText>
            </DetailsListItem>
          )}
        </DetailsList>
      )}
    </Frame>
  );
};

export default JobAtGlance;
