import getProfileInfoFromJob from 'pages/jobs/card/JobCard/getProfileInfoFromJob';

function useJobPath(job) {
  const { publicUrl, type, url } = getProfileInfoFromJob(job);
  const { profileType, talentModel } = job ?? {};
  const jobId = job?._id ?? job?.id;

  const profilePath =
    (type !== undefined && type === 'talent') ||
    (profileType !== undefined && profileType === 'talent') ||
    (talentModel !== undefined && talentModel === 'Talent')
      ? 'talent'
      : 'company';

  const jobPagePath = `/${profilePath}/${publicUrl ?? url}/jobs`;
  const jobPath = `${jobPagePath}/${jobId}`;
  const jobDescriptionPage = `${jobPagePath}/${jobId}/description`;

  return {
    jobPath,
    jobPagePath,
    jobDescriptionPage
  };
}

export default useJobPath;
