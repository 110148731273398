import styled from '@emotion/styled';
import useProfilePath from 'hooks/useProfilePath';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelectors } from 'store/profile';
import { DEFAULT_AVATAR } from 'utils/assets';
import ContainedButton from './ContainedButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const Avatar = styled.img`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
`;

const AvatarBy = styled.div`
  padding-left: 12px;
  padding-right: 16px;
  text-transform: uppercase;
`;

const LinkName = styled.span`
  white-space: nowrap;
`;

const LightBoxUser = () => {
  const avatar = useSelector(profileSelectors.selectMyProfileProperty('basicInfo.imageUri'));
  const name = useSelector(profileSelectors.selectMyProfileProperty('name'));
  const { myProfilePath } = useProfilePath();

  return (
    <Wrapper>
      <Avatar src={avatar?.url || DEFAULT_AVATAR} alt="User Avatar" />

      <AvatarBy>
        By&nbsp;
        <Link to={myProfilePath}>
          <LinkName>{name}</LinkName>
        </Link>
      </AvatarBy>
      <ContainedButton icon="kebab" backgroundColor="secondary" disabled />
    </Wrapper>
  );
};

export default LightBoxUser;
