const initialState = {};

function listsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LIST_PLATFORMS':
      return {
        ...state,
        [action.payload.listName]: action.payload.listData
      };
    case 'SET_COMPANY_LOGO':
      return {
        ...state,
        companies: state.companies.map(company =>
          company.id === action.payload.companyId
            ? { ...company, thexplace_logo: action.payload.thexplace_logo }
            : company
        )
      };
    default:
      return state;
  }
}

export default listsReducer;
