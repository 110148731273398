import styled from '@emotion/styled';

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FooterStep = styled.span`
  font-size: 16px;
  color: rgba(230, 234, 255, 0.6);
`;
