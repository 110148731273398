import styled from '@emotion/styled';
import { backOfficeService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import get from 'lodash/fp/get';
import React from 'react';
import { pageSize } from 'utils/devices';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;
const GridWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const CompanyInfoModal = ({ closePortal, data: { data, showPublicUrl = true, userId }, name }) => {
  const fields = {
    companyName: { initialValue: data.name ?? '', validate: ['isRequired'] },
    publicUrl: { initialValue: data.publicUrl ?? '', validate: ['isRequired'] }
  };

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: async formData => {
      return backOfficeService.updateCompanyInfo(userId, { ...formData, userId: data._id }).then(response => {
        const responseStatus = get('data.status', response);
        if (responseStatus === 'OK') {
          if (data.onSuccess) data.onSuccess();
          closePortal();
        }
      });
    }
  });

  const handleSave = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <TxplModal
      title="Account info"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <StyledContainedButton
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            disabled={submitting}
          >
            Cancel
          </StyledContainedButton>
          <StyledContainedButton form="companyInfoModal" type="submit" backgroundColor="#7266EE" disabled={submitting}>
            Save
          </StyledContainedButton>
        </>
      }
    >
      <GridWrapper onSubmit={handleSave} id="companyInfoModal">
        <FormInput
          autoFocus
          label="Company Name"
          error={errors.companyName}
          value={values.companyName}
          onChange={onFieldChange('companyName')}
        />

        {showPublicUrl && (
          <FormInput
            label="Public url"
            error={errors.publicUrl}
            value={values.publicUrl}
            onChange={onFieldChange('publicUrl')}
          />
        )}
      </GridWrapper>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-company-info-modal')(CompanyInfoModal);
