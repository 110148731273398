import set from 'lodash/fp/set';
import map from 'lodash/fp/map';
import update from 'lodash/fp/update';
import unset from 'lodash/fp/unset';
import concat from 'lodash/fp/concat';

import * as talentActionTypes from './talentActionTypes';

const initialState = {};

const normalize = arr => (arr || []).reduce((result, curr) => ({ ...result, [curr._id]: curr }), {});

const normalizeTalent = talent => ({
  ...talent,
  mediaCoverage: normalize(talent.mediaCoverage),
  experience: normalize(talent.experience),
  projects: normalize(talent.projects),
  projectsList: (talent.projects || []).filter(({ status }) => status !== 'in-progress')
});

const talentReducer = (state = initialState, action) => {
  switch (action.type) {
    case talentActionTypes.INITIALIZE_TALENT:
      return normalizeTalent(action.payload.talent);

    case talentActionTypes.UPDATE_TALENT_PROPERTY:
      try {
        const { property, updateKey, updateType, value } = action.payload;

        if (updateType === 'insert') {
          return update(
            property,
            values => (Array.isArray(values) ? concat(values, [value]) : set(value[updateKey], value, values)),
            state
          );
        }

        if (updateType === 'delete') {
          return update(
            property,
            values =>
              Array.isArray(values) ? values.filter(currValue => currValue[updateKey] !== value) : unset(value, values),
            state
          );
        }

        if (updateType === 'update') {
          const updated = params =>
            map(
              curr =>
                curr[updateKey] === value[updateKey]
                  ? Object.keys(curr).reduce(
                      (res, currKey) => ({
                        ...res,
                        [currKey]: value[currKey] !== undefined ? value[currKey] : curr[currKey]
                      }),
                      {}
                    )
                  : curr,
              params
            );

          return update(
            property,
            values => (Array.isArray(values) ? updated(values) : normalize(updated(values))),
            state
          );
        }

        return set(property, value, state);
      } catch (error) {
        console.log('🚀 ~ file: talentReducer.js ~ line 75 ~ talentReducer ~ error', error);
      }
      break;

    case talentActionTypes.CLEAR_TALENT:
      return {};

    default:
      return state;
  }
};

export default talentReducer;
