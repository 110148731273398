import FormCheckbox from 'common/form/FormCheckbox';
import FormInput from 'common/form/FormInput';
import FormPronounSelect from 'common/form/FormPronounSelect';
import Flex from 'common/styles/Flex';
import React from 'react';

function EditTalentNameFromFields({ errors, onFieldChange, values }) {
  return (
    <>
      <Flex>
        <FormInput
          mb={56}
          value={values.firstName}
          onChange={e => {
            onFieldChange('firstName')(e.target.value);
          }}
          error={errors.firstName}
          label="Your first name"
          maxLength={50}
          required
          className="firstName-required"
          width="48%"
          marginRight="2%"
          data-testid="firstName-field"
        />
        <FormInput
          mb={56}
          value={values.lastName}
          onChange={e => {
            onFieldChange('lastName')(e.target.value);
          }}
          error={errors.lastName}
          label="Your last name"
          maxLength={50}
          required
          className="lastName-required"
          width="48%"
          marginRight="2%"
          data-testid="lastName-field"
        />
      </Flex>
      <FormInput
        value={values.additionalName}
        onChange={e => onFieldChange('additionalName')(e.target.value)}
        label="Preferred name"
        maxLength={50}
        hintText="Add any preferred name that can help your colleagues find you."
        data-testid="additionalName-field"
      />
      <FormCheckbox
        mb={56}
        gap={0}
        checked={values.useAdditionalName}
        onChange={event => onFieldChange('useAdditionalName')(event.target.checked)}
        text="Show this on my user card"
        hintText="Otherwise, we will use this information only for search purposes."
        data-testid="useAdditionalName-field"
      />
      <FormPronounSelect errors={errors} onFieldChange={onFieldChange} values={values} />
    </>
  );
}

export default EditTalentNameFromFields;
