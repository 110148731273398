import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { backOfficeActions } from 'store/backOffice';

import { Column, Table } from 'components/Table';
import ContainedButton from 'common/ContainedButton';
import ExportCsv from 'common/ExportCsv';
import BackOfficeCompanyModal from './BackOfficeCompanyModal';

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 6px;
`;
const StyledImage = styled.img`
  max-width: 100px;
  margin: 0 auto;
  padding: 5px;
  max-height: 100px;
`;
const StyledImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackOfficeCompanies = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();

  useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Knowledge base' }, { label: 'Companies' }]));
  }, [dispatch]);

  return (
    <>
      <BackOfficeCompanyModal />

      <ActionsRow>
        <ExportCsv path="companies/export-csv" />
        <ContainedButton opens="backOffice-company-modal" data={{ tableRef }}>
          Add new Company
        </ContainedButton>
      </ActionsRow>

      <Table ref={tableRef} keyId="_id" paginate endpoint="/companies/paginate" dataKey="companies">
        <Column title="Name" keyName="name" width="11%" search sort />
        <Column
          title="TheXPlace LOGO"
          keyName="imageUrl"
          width={100}
          render={company => (
            <StyledImageContainer>
              {!company.image && 'NO DATA'}
              {company.image && <StyledImage src={company.image.url} alt="No Data" />}
            </StyledImageContainer>
          )}
        />
        <Column
          width={100}
          title="IGDB LOGO"
          keyName="imageUrl"
          render={company => (
            <StyledImageContainer>
              {!company.logo?.url && 'NO DATA'}
              {company.logo?.url && <StyledImage src={company.logo?.url} alt="No Data" />}
            </StyledImageContainer>
          )}
        />
        <Column title="Associations" keyName="associates" render={company => company.associates?.join(', ')} search />
        <Column title="Usage" keyName="usage" search width={47} sort />
        <Column
          title=""
          type="action"
          width={46}
          opens="backOffice-company-modal"
          data={company => ({
            tableRef,
            companyId: company._id,
            isEdit: true
          })}
        />
      </Table>
    </>
  );
};

export default BackOfficeCompanies;
