function getBiosReducedName({ isBiosReduced, name }) {
  if (isBiosReduced && !!name) {
    return name
      .split(' ')
      .filter(it => it.length)
      .map(it => (it.length === 1 ? it : it[0] === '(' ? `${it[1]}.` : `${it[0]}.`))
      .join(' ');
  }
  return name;
}

export default getBiosReducedName;
