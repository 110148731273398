import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

export const LifeBlocksWrapper = styled.div`
  display: grid;
  gap: 16px;

  @media ${pageSize.XS} {
    &.jobs-page {
      .life-block {
        padding: 24px;
        .life-block-heading {
          padding-bottom: 16px;
        }
      }
      .life-block.gallery {
        padding: 24px 0;
        .gallery-heading {
          padding: 0 24px 16px;
        }
        .slick-slider {
          padding-left: 24px;
        }
      }
    }
  }
`;
export const LifeTextBlockWrapper = styled.div`
  background: #111112;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 24px 64px;
  min-width: 0;
  &.gallery {
    padding: 32px 0 64px;
    .gallery-heading {
      padding: 0 24px 24px;
    }
    @media ${pageSize.XS} {
      padding: 24px 0 40px;
    }
  }
`;
export const LifeBlockHeading = styled.h2`
  ${commonStyles.ui_heading_2}
  padding-bottom: 24px;
  margin: 0;
`;
export const LifeBlockParagraph = styled.span`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
  width: 100%;
  word-break: break-word;
  p {
    margin: 0;
  }

  @media ${pageSize.S}, ${pageSize.M}, ${pageSize.L} {
    font-size: 20px;
    line-height: 32px;
  }
`;
