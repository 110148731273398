import React from 'react';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';

import Icon from './Icon';

const Wrapper = styled.span`
  ${commonStyles.ui_text_medium}
  display: inline-flex;
  flex-direction: row;

  padding: 0 10px;
  align-items: center;
  outline: none;
  border: 0px;
  margin-right: 0px;
  background: ${props => (props.background ? props.background : '')};

  border-radius: 8px;
  height: 26px;

  text-align: center;
  color: rgba(242, 244, 255, 0.9);
  margin-right: 6px;
  margin-bottom: 6px;

  & .button-text {
    ${commonStyles.ui_text_medium}
    padding-left: 6px;
    white-space: nowrap;
    color: rgba(242, 244, 255, 0.9);
    @media ${pageSize.XS} {
      ${props => (props.forceDisplayText ? '' : 'display: none;')}
    }
  }
`;

const Label = ({ background, children, className, forceDisplayText, icon, iconColor }) => {
  let backgroundGradient = '';
  switch (background) {
    case 'blue':
      backgroundGradient = 'linear-gradient(180deg, #0057FF 0%, #00ECEC 100%)';
      break;
    case 'violet':
      backgroundGradient = 'linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%)';
      break;
    case 'x':
    default:
      backgroundGradient = background;
      break;
  }

  return (
    <Wrapper className={className} forceDisplayText={forceDisplayText} background={backgroundGradient}>
      {icon && <Icon color={iconColor} icon={icon} />}
      <span className="button-text">{children}</span>
    </Wrapper>
  );
};

export default Label;
