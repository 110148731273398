export const getFileType = fileType => {
  const pdfTypes = ['application/pdf'];
  const svgTypes = ['image/svg+xml'];
  const imageTypes = ['image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/png'];
  const videoTypes = ['video/x-msvideo', 'video/mp4', 'video/ogg', 'video/webm'];
  const audioTypes = ['audio/mpeg', 'audio/wav', 'audio/webm'];
  const txtTypes = ['text/plain'];
  const wordTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  const excelTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
  ];
  const pptTypes = [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ];

  if (svgTypes.includes(fileType)) return 'svg';
  if (pdfTypes.includes(fileType)) return 'pdf';
  if (imageTypes.includes(fileType)) return 'image';
  if (videoTypes.includes(fileType)) return 'video';
  if (audioTypes.includes(fileType)) return 'audio';
  if (txtTypes.includes(fileType)) return 'text';
  if (wordTypes.includes(fileType)) return 'word';
  if (excelTypes.includes(fileType)) return 'excel';
  if (pptTypes.includes(fileType)) return 'ppt';
  return 'default';
};
