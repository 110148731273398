import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { closePortal } from 'store/portals/portalActions';
import { StyledButtonsContainer, StyledContainedButton } from './VerificationSubmitted.styled';

const Footer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(profileSelectors.selectProfile);

  const handleClick = () => {
    history.push(`/talent/${profile.talentId}/about?open-progress-funnel=true`);
    dispatch(closePortal({ name: 'verification-submitted-modal' }));
  };

  return (
    <StyledButtonsContainer>
      <StyledContainedButton backgroundColor="primary" onClick={handleClick}>
        Keep building profile
      </StyledContainedButton>
    </StyledButtonsContainer>
  );
};

export default Footer;
