import ContainedButton from 'common/ContainedButton';
import RichTextEditor from 'common/RichTextEditor';
import Required from 'common/styles/Required';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import React, { useState } from 'react';

const TextEditorModal = ({ closePortal, data, name, onSave }) => {
  const { field, limit = 2000, required = false, size = 'large', subTitle = '', text = '', title = '' } = data;
  const [value, setValue] = useState(text);
  const [error, setError] = useState('');

  const handleSave = () => {
    if (required && !value) {
      setError(`${title} is required`);
      return;
    }
    if (value.length) {
      onSave(field, value);
      closePortal();
    }
  };

  return (
    <TxplModal
      title={
        <>
          {title} {required && <Required />}
        </>
      }
      titleMB={6}
      subTitle={subTitle}
      size={size}
      padding="0 0 58px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      width
      addPaddingTopBig
      fromJob
      renderFooter={
        <>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleSave} backgroundColor="#7266EE" forceDisplayText>
            Save
          </ContainedButton>
        </>
      }
    >
      <RichTextEditor
        containerStyle={{
          marginTop: '1rem'
        }}
        modules={{
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']]
        }}
        value={value}
        onChange={text => setValue(text)}
        maxLength={limit}
        hasError={error}
      />
    </TxplModal>
  );
};

export default withRenderPortal('text-editor-modal')(TextEditorModal);
