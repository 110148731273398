const disciplineColors = {
  dis_analytics: 'linear-gradient(0deg, #0066FF 0%, #0400C9 100%)',
  dis_animation: 'linear-gradient(0deg, #00E941 0%, #027800 100%)',
  dis_art: 'linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)',
  dis_audio: 'linear-gradient(180deg, #0057FF 0%, #00C1DB 100%)',
  dis_business_development: 'linear-gradient(180deg, #303543 0%, #7B7C9A 100%)',
  dis_cinematics: 'linear-gradient(180deg, #121418 0%, #3B3C4E 100%)',
  dis_engineering: 'linear-gradient(0deg, #0066FF 0%, #0400C9 100%)',
  dis_game_design: 'linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%)',
  dis_game_security: 'radial-gradient(150% 100% at 0% 100%, #5448DB 0%, #8A80FF 100%)',
  dis_legal: 'linear-gradient(180deg, #121418 0%, #3B3C4E 100%)',
  dis_localization: 'linear-gradient(0deg, #FF7A00 0%, #C40000 100%)',
  dis_marketing: 'linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%)',
  dis_player_support: 'linear-gradient(0deg, #00E941 0.44%, #027800 100%)',
  dis_product_management: 'linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)',
  dis_production_dev_operations: 'linear-gradient(180deg, #0057FF 0%, #00C1DB 100%)',
  dis_publishing: 'linear-gradient(0deg, #FF7A00 0%, #C40000 100%)',
  dis_qa_and_certification: 'linear-gradient(0deg, #0066FF 0%, #0400C9 100%)',
  dis_story: 'linear-gradient(180deg, #303543 0%, #7B7C9A 100%)',
  dis_ui_ux_design: 'linear-gradient(0deg, #FF7A00 0%, #C40000 100%)',
  dis_visual_effects_and_lightning: 'linear-gradient(0deg, #00E941 0.44%, #027800 100%)',
  dis_leadership: 'linear-gradient(180deg, #0057FF 0%, #00C1DB 100%)',
  dis_board_member: 'linear-gradient(180deg, #121418 0%, #3B3C4E 100%)',
  dis_advisory_board: 'linear-gradient(180deg, #303543 0%, #7B7C9A 100%)',
  dis_investment_funding: 'linear-gradient(359.43deg, #00BC35 0.44%, #027100 99.47%)',
  dis_other: 'linear-gradient(180deg, #303543 0%, #7B7C9A 100%)',
  dis_entrepreneurship: 'linear-gradient(0deg, #0066FF 0%, #0400C9 100%)',
  dis_customer_experience: 'linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)',
  dis_visual_effects_and_lighting: 'linear-gradient(359.43deg, #00BC35 0.44%, #027100 99.47%)',
  dis_people_operations: 'linear-gradient(180deg, #0057FF 0%, #00C1DB 100%)',
  dis_finance: 'linear-gradient(359.43deg, #00BC35 0.44%, #027100 99.47%)',
  dis_dev_operations: 'linear-gradient(180deg, #0057FF 0%, #00C1DB 100%)',
  dis_production: 'linear-gradient(180deg, #121418 0%, #3B3C4E 100%)',
  dis_economy_monetization: 'linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)',
  dis_community_management: 'linear-gradient(180deg, #303543 0%, #7B7C9A 100%)',
  dis_economymonetization: 'linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)'
};

const disciplinesNames = {
  analytics: 'Analytics',
  animation: 'Animation',
  art: 'Art',
  audio: 'Audio',
  'business-development': 'Business Development',
  cinematics: 'Cinematics',
  'game-design': 'Game Design',
  'game-security': 'Game Security',
  engineering: 'Engineering',
  'economy-monetization': 'Economy/Monetization',
  legal: 'Legal',
  localization: 'Localization',
  marketing: 'Marketing',
  'player-support': 'Player Support',
  production: 'Production',
  'dev-operations': 'Dev Operations',
  'product-management': 'Product Management',
  publishing: 'Publishing',
  'qa-and-certification': 'QA and Certification',
  story: 'Story',
  'ui-ux-design': 'UI/UX Design',
  'visual-effects-and-lighting': 'Visual Effects and Lighting',
  leadership: 'Leadership',
  'board-member': 'Board Member',
  'advisory-board': 'Advisory Board',
  'investment-funding': 'Investment/Funding',
  entrepreneurship: 'Entrepreneurship',
  'customer-experience': 'Customer Experience',
  'people-operations': 'People Operations',
  finance: 'Finance',
  'community-management': 'Community Management',
  economymonetization: 'Economy/Monetization'
};

const defaultColor = 'linear-gradient(180deg, #303543 0%, #7B7C9A 100%)';

const getDisciplinesNameIconAndColor = discipline => {
  const disciplineName = `dis_${discipline
    ?.toLowerCase()
    .replaceAll(' ', '_')
    .replaceAll('-', '_')
    .replaceAll('/', '_')}`;
  const disciplineColor = disciplineColors[disciplineName] || defaultColor;
  const disciplineLabel = disciplinesNames[discipline] || discipline;

  return {
    color: disciplineColor,
    icon: disciplineName,
    name: disciplineLabel
  };
};

export default getDisciplinesNameIconAndColor;
