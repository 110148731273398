import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';
import React from 'react';
import ReactTooltip from 'react-tooltip';

const StyledTooltip = styled.div`
  .show {
    opacity: 1 !important;
    background: #000;
    width: max-content;
    border: 1px solid rgba(204, 213, 255, 0.11);
    border-radius: 8px;
    padding-bottom: 12px;
    text-align: center;
  }

  .is-clickable {
    pointer-events: auto !important;
  }

  .__react_component_tooltip.place-left::after {
    border-left: 7px solid #000 !important;
  }

  .__react_component_tooltip.place-right::after {
    border-right: 7px solid #000 !important;
  }

  .__react_component_tooltip.place-top::after {
    border-top: 7px solid #000 !important;
  }

  .__react_component_tooltip.place-bottom::after {
    border-bottom: 7px solid #000 !important;
  }
  &.secondary {
    .__react_component_tooltip.show {
      background: #25262c;
      border: none;
    }
    .__react_component_tooltip.place-top::after {
      border-top-color: #25262c !important;
    }
    .__react_component_tooltip.place-bottom::after {
      border-bottom-color: #25262c !important;
    }
  }
`;

const StyledTypography = styled(Typography)`
  color: rgba(230, 234, 255, 0.6);
`;

const Tooltip = ({
  children,
  className,
  delayHide,
  effect = 'solid',
  isClickable = false,
  overrideTooltipPosition,
  place = 'top',
  tooltipId,
  hidden = false
}) => (
  <StyledTooltip className={className} hidden={hidden}>
    <ReactTooltip
      effect={effect}
      place={place}
      id={tooltipId}
      delayHide={delayHide}
      class={isClickable ? 'is-clickable' : null}
      overridePosition={({ left, top }, _event, target, node) => {
        let newTop = top;
        let newLeft = left;
        if (overrideTooltipPosition) {
          newTop = target.offsetTop - node.clientHeight - 5;
          newLeft = target.offsetLeft - node.clientWidth / 2 + target.clientWidth / 2;
        }

        return {
          top: newTop,
          left: newLeft
        };
      }}
    >
      <StyledTypography variant="small">{children}</StyledTypography>
    </ReactTooltip>
  </StyledTooltip>
);

export default Tooltip;
