import React from 'react';
import FormAvatar from 'common/form/FormAvatar';
import FormInput from 'common/form/FormInput';
import FormCheckbox from 'common/form/FormCheckbox';
import FormSelect from 'common/form/FormSelect';
import FormSelectLocation from 'common/form/FormSelectLocation';
import { Container, Row } from './Steps.styled';
import { getLanguagesOptions } from 'utils/languages';
import { experienceLevelUserOptions } from 'utils/selectOptions';

function BasicProfileInfo({ formik, handleOptionsOpen, tagsOptions, setDirtyLocation }) {
  const { values, errors, handleChange, setFieldValue, touched } = formik;
  const { disciplines, roles } = tagsOptions;

  const handleSelect = (field, event) => {
    setFieldValue(field, event);
  };

  return (
    <Container>
      <Row>
        <FormAvatar
          name="avatarImage"
          value={values.avatarImage}
          onChange={e => handleSelect('avatarImage', e)}
          label={'Your game face'}
          hintText={'Should be at least 300x300 pixels. PNG, JPG, GIF, 500Kb or less.'}
          required
          errors={touched.avatarImage ? errors.avatarImage : ''}
        />
      </Row>
      <Row>
        <FormInput
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          error={touched.firstName ? errors.firstName : ''}
          label="Your first name"
          maxLength={50}
          required
        />
        <FormInput
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          error={touched.lastName ? errors.lastName : ''}
          label="Your last name"
          maxLength={50}
          required
        />
      </Row>
      <FormInput
        mb={16}
        name="additionalName"
        value={values.additionalName}
        onChange={handleChange}
        label="Preferred name"
        maxLength={50}
        hintText="Add any preferred name that can help your colleagues find you."
      />
      <FormCheckbox
        mb={40}
        name="useAdditionalName"
        gap={0}
        checked={values.useAdditionalName}
        onChange={handleChange}
        text="Show this on my user card"
        hintText="Otherwise, we will use this information only for search purposes."
      />
      <FormInput
        mb={40}
        name="yourX"
        value={values.yourX}
        onChange={handleChange}
        error={touched.yourX ? errors.yourX : ''}
        label="Your X"
        maxLength={50}
        placeholder="e.g. Funny sounds for funny games"
        hintText="Tight tagline, best describing what you do, your experience and style."
      />
      <FormSelect
        mb={40}
        name="disciplines"
        value={values.disciplines}
        options={disciplines.options}
        isMulti
        maxOptions={2}
        onMenuOpen={() => handleOptionsOpen('discipline')}
        onChange={e => handleSelect('disciplines', e)}
        label="Your disciplines"
        customNoOptMessage="2 will do here"
        hintText="Your craft is likely legendary. Your top 1-2 disciplines will do here."
        isLoading={disciplines.tagDisciplines.loading}
        error={touched.disciplines ? errors.disciplines : ''}
        required
      />
      <FormSelect
        mb={40}
        name="roles"
        value={values.roles}
        options={roles.options}
        isLoading={roles.tagRoles.loading}
        isMulti
        maxOptions={3}
        onMenuOpen={() => handleOptionsOpen('role')}
        onChange={e => handleSelect('roles', e)}
        label="Your Roles"
        hintText="Give us 1-3 favorite roles that best represent your work."
        customNoOptMessage="3 will do here"
        error={touched.roles ? errors.roles : ''}
        required
        className="roles-required"
      />

      <FormSelectLocation
        mb={40}
        name="location"
        isClearable
        value={values?.location?.name || values?.location}
        onChange={e => {
          handleSelect('location', e);
          setDirtyLocation(true);
        }}
        label="Your location"
        hintText="Where do you currently live? Game worlds not accepted."
        error={touched.location ? errors.location : ''}
        required
      />
      <FormSelect
        mb={40}
        name="languages"
        value={values.languages}
        options={getLanguagesOptions()}
        isMulti
        onChange={e => handleSelect('languages', e)}
        label="Your languages"
        hintText="The ones you can talk business with..."
        error={touched.languages ? errors.languages : ''}
        required
      />
      <FormSelect
        mb={40}
        name="experienceLevel"
        value={values.experienceLevel}
        options={experienceLevelUserOptions}
        onChange={e => handleSelect('experienceLevel', e)}
        label="Experience level"
        placeholder="Select an option"
        isClearable
        error={touched.experienceLevel ? errors.experienceLevel : ''}
        required
      />
    </Container>
  );
}

export default BasicProfileInfo;
