import React from 'react';
import { StyledContent } from './Steps.styled';

const WelcomeToXplace = () => {
  return (
    <StyledContent>
      We are thrilled to welcome you to TheXPlace — a place to connect and find work in the video games industry.
    </StyledContent>
  );
};

export default WelcomeToXplace;
