import { always, cond, equals } from 'ramda';
import { Buffer } from 'buffer';
/**
 * longToShort
 *
 * @param {string} longID
 * @returns {string}
 */
function longToShort(longID) {
  const buffer = Buffer.from(longID, 'hex');
  return buffer.toString('base64').replace(/\+/g, '-').replace(/\//g, '_');
}

const getTypeCode = cond([
  [equals('credit'), always('C')],
  [equals('project'), always('P')],
  [equals('profile'), always('T')],
  [equals('job'), always('J')]
]);

/**
 * createSharingUrl
 *
 * @param {object} param0
 * @returns { string }
 */
function createSharingUrl({ id, type }) {
  return `${process.env.REACT_APP_NEXT_BASE_URL}/sharing?id=${getTypeCode(type)}-${longToShort(id)}&latest`;
}

export default createSharingUrl;
