import React from 'react';
import styled from '@emotion/styled';

import { getDate } from 'utils';
import commonStyles from 'utils/common-styles';

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 230px;
  height: 56px;
  display: inline-block;

  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 12px;
`;

const Avatar = styled.img`
  display: block;
  position: absolute;
  top: 10px;
  left: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

const TestimonialName = styled.div`
  ${commonStyles.ui_text_small}
  position: absolute;
  top: 10px;
  left: 64px;
`;

const TestimonialDate = styled.div`
  ${commonStyles.ui_text_tiny}
  position: absolute;
  top: 32px;
  left: 64px;
  color: rgba(230, 234, 255, 0.35);
`;

const TestimonialCredits = ({ avatar, date, name }) => (
  <Wrapper>
    <Avatar src={avatar} />
    <TestimonialName>{name}</TestimonialName>
    <TestimonialDate>{getDate(date)}</TestimonialDate>
  </Wrapper>
);

export default TestimonialCredits;
