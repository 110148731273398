import TxplModal from 'common/TxplModal';
import React, { useCallback } from 'react';
import ModalHeader from './ModalHeader';
import { Content, ModalBody, StyledButton, StyledFormInput, Subtitle, Title } from './VettingResultModal.styled';
import withRenderPortal from 'hocs/withRenderPortal';
import { portalActions } from 'store/portals';
import { useDispatch, useSelector } from 'react-redux';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import { userService } from 'api';
import createSharingUrl from 'utils/create-sharing-url';

function VettingResultModal() {
  const dispatch = useDispatch();
  const publicUrl = useSelector(currentUserPropertySelector('publicUrl'));

  const url = createSharingUrl({ id: publicUrl, type: 'profile' });
  const onClose = useCallback(async () => {
    dispatch(portalActions.closePortal({ name: 'vetting-result-modal' }));
    await userService.setSettings({ hasVettedMessageRead: true });
  }, []);

  const { openSuccessSnackbar } = useSuccessSnackbar();
  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(url);
    openSuccessSnackbar('Profile url copied', 6000);
  }, []);

  return (
    <TxplModal
      noPadding={true}
      appElement={document.getElementById('root')}
      hideCloseIcon
      size="small"
      renderHeader={() => <ModalHeader title="Congrats! Now you're vetted!" onClose={onClose} />}
      padding="25px"
    >
      <ModalBody>
        <Title>Your profile has been vetted! Now you can proudly show it to the world and apply for jobs.</Title>
        <Subtitle>Share your profile:</Subtitle>
        <Content>
          <StyledFormInput disabled value={url}></StyledFormInput>
          <StyledButton
            onClick={handleCopyClick}
            icon="copy"
            type="button"
            mt={5}
            height="54px"
            backgroundColor="rgba(204, 213, 255, 0.11)"
          >
            Copy link
          </StyledButton>
        </Content>
      </ModalBody>
    </TxplModal>
  );
}

export default withRenderPortal('vetting-result-modal')(VettingResultModal);
