import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import withOpenPortal from 'hocs/withOpenPortal';

import Tooltip from 'components/Tooltip';
import Icon from './Icon';

const getBackgroundColor = background => (background === 'secondary' ? 'rgba(204, 213, 255, 0.11)' : background);

const StyledButton = styled.button`
  ${commonStyles.caption}
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  outline: none;
  border: 1px;
  cursor: pointer;
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  ${({ mr }) => mr && `margin-right: ${mr}px;`}


  background: #7266EE;
  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    cursor: auto;
  `}
  ${props => (props.backgroundColor ? `background-color: ${getBackgroundColor(props.backgroundColor)};` : '')}
  ${props => (props.color && !props.noBorder ? `border: 1px solid ${props.color};` : '')}
  border-radius: 6px;
  height: 32px;

  &:hover {
    box-shadow: 0px 2px 16px rgba(114, 102, 238, 0.5);
    box-shadow: 0px 2px 16px
      ${props => (props.backgroundColor ? getBackgroundColor(props.backgroundColor) : 'rgba(114, 102, 238, 0.5);')};
  }

  & .button-text {
    ${commonStyles.caption}
    white-space: nowrap;
    ${props => (props.color ? `color: ${props.color};` : '')}
    ${props => (props.icon ? 'padding-left: 8px;' : '')}
  }
`;

const StyledIcon = styled(Icon)``;

const SaveContainedButton = props => {
  const {
    buttonRef,
    children,
    color,
    disabled,
    forceDisplayText = true,
    icon,
    iconColor,
    onClick,
    style,
    tooltip,
    tooltipId
  } = props;

  return (
    <>
      <StyledButton
        style={style}
        ref={buttonRef}
        {...props}
        onClick={onClick}
        forceDisplayText={forceDisplayText}
        disabled={disabled}
      >
        {icon && <StyledIcon icon={icon} color={iconColor || color} />}
        {children && <span className="button-text">{children}</span>}
      </StyledButton>

      {tooltip && (
        <Tooltip tooltipId={tooltipId} overrideTooltipPosition>
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

export default withOpenPortal(SaveContainedButton);
