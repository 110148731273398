import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Icon from 'common/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 46px;
  cursor: ${props => (props.isCompleted ? '' : 'pointer')};
`;

const Title = styled.p`
  color: ${props => (props.disabled ? '#E5E8FFA6' : 'white')};
  font-size: 15px;
  margin: 0;
  &:hover {
    color: #e5e8ffa6;
  }
`;

const getIconComponent = ({ iconName, isCompleted, isInProgress }) => {
  if (isCompleted) {
    return <Icon icon="checkmark-circle" size={16} color="#00D624" />;
  } else if (isInProgress) {
    return <Icon icon="time" size={16} color="#F0C800" />;
  } else {
    return <Icon icon={iconName} size={16} color="#E5E8FFA6" />;
  }
};

export default function WidgetCardItem({ id, iconName, title, isCompleted, isInProgress, onClick }) {
  const onClickItem = useCallback(() => {
    if (!isCompleted) {
      onClick(id);
    }
  }, [id, isCompleted]);
  return (
    <Wrapper isCompleted={isCompleted} onClick={onClickItem}>
      {getIconComponent({ iconName, isCompleted, isInProgress })}
      <Title disabled={isCompleted || isInProgress}>{title}</Title>
    </Wrapper>
  );
}
