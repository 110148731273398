export const device = {
  mobile: '(min-width: 50px) and (max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1199px)',
  desktop: '(min-width: 1200px)',
  desktop_keep: '(min-width: 1200px)'
};

export const pageSize = {
  XS: '(min-width: 50px) and (max-width: 759px)',
  S: '(min-width: 760px) and (max-width: 1149px)',
  M: '(min-width: 1150px) and (max-width: 1349px)',
  M1: '(min-width: 1150px) and (max-width: 1249px)',
  M2: '(min-width: 1250px) and (max-width: 1349px)',
  L: '(min-width: 1350px)',
  XL: '(min-width: 1920px)'
};
