import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getFileType } from 'utils/getFileType';
import useViewport from 'hooks/useViewport';

import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';

import Icon from 'common/Icon';
import TextURL from 'common/TextURL';
import * as Styled from './StyledMessageItem';
import MessageStatus from '../MessageStatus';
import Flex from 'common/styles/Flex';

const MessageItem = ({
  attachment,
  body,
  conversationId,
  createDate,
  createdAt,
  custom,
  id,
  isFirstMessage,
  isLastMessage,
  isLastMessageWithCreatedDate,
  isLastSeenMessage,
  prevMessage,
  readBy,
  senderId,
  separationDate,
  text
}) => {
  const dispatch = useDispatch();
  const { isXS } = useViewport();

  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const isByMe = profileId === senderId;
  const sameSender = prevMessage?.senderId === senderId;
  const file = attachment ? custom.file : null;
  const message = text || body;

  const [dateOnXS, setDateOnXS] = useState(false);

  const downloadAttachment = () => window.open(attachment.url);

  const openImageGallery = () => {
    dispatch(
      portalActions.openPortal({
        name: 'image-gallery-modal',
        data: { currentImage: { ...attachment, fileName: file.fileName, fileType: file.fileType } }
      })
    );
  };

  const renderAttachmentMessage = attachment => {
    const { fileName, fileType } = file || {};
    const typeOfFile = fileType && getFileType(fileType);
    const ext = fileName?.split('.').reverse()[0];
    const nameLength = fileName && ext ? fileName?.length - ext?.length : 0;
    return (
      <Styled.AttachmentWrapper key={attachment.id}>
        {typeOfFile === 'image' ? (
          <Styled.ImageContainer onClick={openImageGallery}>
            <Styled.ImageDownloadButton className="download-button" onClick={downloadAttachment}>
              <Icon icon="download" />
            </Styled.ImageDownloadButton>
            <img src={attachment.url} alt="" />
          </Styled.ImageContainer>
        ) : (
          <Styled.DocumentContainer onClick={downloadAttachment}>
            <Styled.DocumentWrapper>
              <Icon icon={`file_${typeOfFile ? typeOfFile : 'default'}`} noFill size={24} />
              <Styled.DocumentName>
                {nameLength > 30 && fileName ? `${fileName.substr(0, 30)}...${ext}` : attachment.url}
              </Styled.DocumentName>
            </Styled.DocumentWrapper>
          </Styled.DocumentContainer>
        )}
      </Styled.AttachmentWrapper>
    );
  };
  const date = createDate ? moment(createDate).format('LT') : null;
  const messageDate = !isXS ? date : createdAt ? moment(createdAt).format('LT') : null;

  return (
    <>
      {separationDate && (
        <Styled.DateSeperatorWrapper isFirstMessage={isFirstMessage}>
          <Styled.DateSeperatorContent>{moment(new Date(separationDate)).format('LL')}</Styled.DateSeperatorContent>
        </Styled.DateSeperatorWrapper>
      )}
      <Styled.Wrapper
        isByMe={isByMe}
        isSameSender={sameSender}
        hasDate={date}
        isAttachment={attachment}
        isLastMessage={isLastMessage}
      >
        <Styled.Container isByMe={isByMe}>
          <Flex gap={12} order={2}>
            {messageDate && <Styled.MessageDate dateOnXS={dateOnXS}>{messageDate}</Styled.MessageDate>}
            {(isLastSeenMessage || isLastMessageWithCreatedDate) && isByMe && (
              <MessageStatus conversationId={conversationId} id={id} readBy={readBy} senderId={senderId} />
            )}
          </Flex>
          <Styled.TextContainer
            onClick={() => {
              isXS && text && setDateOnXS(!dateOnXS);
            }}
            pointer={attachment}
            isByMe={isByMe}
            isDocument={attachment && !file?.fileType?.includes('image')}
            isImage={attachment && file?.fileType?.includes('image')}
          >
            {attachment && renderAttachmentMessage(attachment)}
            {!attachment && <TextURL text={message} />}
          </Styled.TextContainer>
        </Styled.Container>
      </Styled.Wrapper>
    </>
  );
};

export default MessageItem;
