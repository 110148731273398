import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import { profileSelectors } from 'store/profile';
import { getProgressFunnelItems } from '../Utils/getProgressFunnelItems';
import { isNotNilOrEmpty } from '@flybondi/ramda-land';
import { allPass, pathSatisfies, propSatisfies } from 'ramda';

const hasTools = pathSatisfies(isNotNilOrEmpty, ['about', 'tools']);
const hasSkills = pathSatisfies(isNotNilOrEmpty, ['about', 'skills']);
const hasAbout = propSatisfies(isNotNilOrEmpty, 'about');
const hasBasicInfo = propSatisfies(isNotNilOrEmpty, 'basicInfo');

const hasImagePic = pathSatisfies(isNotNilOrEmpty, ['basicInfo', 'imageUri']);
const hasYouInNutshellComplete = allPass([hasBasicInfo, hasAbout, hasSkills, hasTools, hasImagePic]);

export function useProgressFunnel() {
  const projects = useSelector(profileSelectors.selectMyProfileProperty('projects'));
  const creditsList = useSelector(profileSelectors.selectMyProfileProperty('credits'));
  const verificationStatus = useSelector(selectCurrentUserProperty('verificationStatus'));
  const experience = useSelector(profileSelectors.selectMyProfileProperty('experience'));
  const publishedProjects = projects && Object.values(projects).filter(item => item.status === 'published');
  const profile = useSelector(profileSelectors.selectProfile);
  const isUserCardCompleted = hasYouInNutshellComplete(profile);

  const isIdentityVerified = verificationStatus === 'verified';
  const hasAtLeastOneCredit = creditsList?.length > 0;
  const hasAtLeastOneProject = publishedProjects?.length >= 1;
  const hasExperience = isNotNilOrEmpty(experience);
  const { items: funnelItems, percentage } = useMemo(
    () =>
      getProgressFunnelItems({
        isUserCardCompleted,
        isIdentityVerified,
        hasAtLeastOneCredit,
        hasAtLeastOneProject,
        hasExperience
      }),
    [isUserCardCompleted, isIdentityVerified, hasAtLeastOneCredit, hasAtLeastOneProject, hasExperience]
  );

  return {
    funnelItems,
    percentage
  };
}
