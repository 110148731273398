import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'common/Icon';
import FormUpload from 'common/form/FormUpload';
import Typography from 'components/shared/Typography';
import { pageSize } from 'utils/devices';
import ContainedButton from 'common/ContainedButton';
import { companyProfileService, talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';
import Loader from 'common/Loader';
import Flex from 'common/styles/Flex';

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  margin: 40px 70px auto auto;

  @media ${pageSize.L} {
    display: none;
  }

  @media ${pageSize.M} {
    margin: 40px 70px auto auto;
    display: none;
  }

  @media ${pageSize.S} {
    margin: 24px 70px auto auto;
  }
  @media ${pageSize.XS} {
    margin: 16px 16px auto auto;
  }
`;

const StyledCoverContainer = styled.div`
  position: relative;
  width: 100%;
  height: 385px;
  background-position: center;
  background-size: cover;
  ${props =>
    !props.img &&
    'background: linear-gradient(360deg, #101012 0%, rgba(16, 16, 18, 0.69) 8.9%, rgba(16, 16, 18, 0) 24.92%), #29292F;'}
  ${props =>
    props.img &&
    `background-image: linear-gradient(360deg, #101012 0%, rgba(16, 16, 18, 0.69) 8.9%, rgba(16, 16, 18, 0) 24.92%), url(${props.img});`}
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover div {
    display: flex;
  }

  @media ${pageSize.M} {
    height: 436px;
  }
  @media ${pageSize.L} {
    height: 528px;
  }
  @media ${pageSize.S} {
    margin-bottom: -185px;
  }
  @media ${pageSize.XS} {
    margin-bottom: -169px;
  }
`;

const StyledUploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  @media ${pageSize.S}, ${pageSize.XS} {
    position: absolute;
    z-index: 3;
    flex-flow: row;
    gap: 12px;
  }
  @media ${pageSize.S} {
    top: 96px;
  }
  @media ${pageSize.XS} {
    top: 80px;
  }
`;

const NaIcon = styled(Icon)`
  width: 100%;
  height: 146px;

  @media ${pageSize.S}, ${pageSize.XS} {
    height: 53px;
  }
`;

const UploadWrapper = styled(Flex)`
  width: 169px;
  @media ${pageSize.S}, ${pageSize.XS} {
    gap: 8px;
  }
`;

const TalentInfoCover = () => {
  const profile = useSelector(profileSelectors.selectProfile);
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const dispatch = useDispatch();
  const { openErrorSnackbar } = useErrorSnackbar();

  const coverImageUrl = profile?.basicInfo?.coverImage?.url;
  const [loading, setLoading] = React.useState(false);

  const handleSetCoverImage = async img => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', img);

      const service = isCompanyView ? companyProfileService.setCoverImage : talentService.setCoverImage;
      const {
        data: { data }
      } = await service(profile._id, formData);

      dispatch(
        profileActions.updateProfileProperty({
          property: 'basicInfo.coverImage',
          value: data.basicInfo.coverImage,
          profileId: profile._id
        })
      );
    } catch {
      openErrorSnackbar('Ooops! Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCoverImage = async () => {
    setLoading(true);
    try {
      const service = isCompanyView ? companyProfileService.deleteCoverImage : talentService.deleteCoverImage;
      await service(profile._id);

      dispatch(
        profileActions.updateProfileProperty({
          property: 'basicInfo.coverImage',
          value: null,
          profileId: profile._id
        })
      );
    } catch (err) {
      console.log('err', err);
      openErrorSnackbar('Ooops! Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledCoverContainer img={coverImageUrl}>
      {loading && <Loader size={40} />}
      {!coverImageUrl && hasEditPermission && !loading && (
        <StyledUploadContainer>
          <NaIcon icon="na" />
          <UploadWrapper column center gap={16}>
            <FormUpload onChange={handleSetCoverImage} center aspectRatio={1920 / 528} uploadText="ADD COVER IMAGE" />
            <Typography variant="tiny" color="rgba(230,234,255,0.6)" fontFamily="Inter">
              Optimal dimensions: 1920x528
            </Typography>
          </UploadWrapper>
        </StyledUploadContainer>
      )}
      {coverImageUrl && hasEditPermission && !loading && (
        <StyledButtonsContainer>
          <ContainedButton backgroundColor="#1A1B1E;" forceDisplayText icon="delete" onClick={handleRemoveCoverImage}>
            REMOVE
          </ContainedButton>
          <FormUpload
            onChange={handleSetCoverImage}
            backgroundColor="#1A1B1E;"
            aspectRatio={1920 / 528}
            center
            uploadText="Replace image"
          />
        </StyledButtonsContainer>
      )}
    </StyledCoverContainer>
  );
};

export default TalentInfoCover;
