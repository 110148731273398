import ContainedButton from 'common/ContainedButton';
import FormSwitcher from 'common/form/FormSwitcher.jsx';
import Loader from 'common/Loader.jsx';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useApplicantService from 'hooks/api/useApplicantService.js';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  ScopeFooterWrapper,
  StyledCandidate,
  StyledCandidateImage,
  StyledCandidateModalWrapper,
  StyledCandidateName,
  StyledCandidateNameAndRoleWrapper,
  StyledCandidateRole,
  StyledCandidateRoleWrapper,
  StyledCandidatesWrapper
} from './StyledSelectCandidateModal.js';

const applicantStatus = [
  {
    name: 'all',
    value: 'all'
  },
  {
    name: 'saved',
    value: 'saved'
  },
  {
    name: 'contacted',
    value: 'contacted'
  }
];

const SelectCandidateModal = ({ closePortal, data, name }) => {
  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;
  const { jobId } = data;
  const [chosenCandidate, setChosenCandidate] = useState(false);
  const [status, setStatus] = React.useState('all');
  const [loading, setIsLoading] = React.useState(false);

  const { applicants, applicantsPage: page, getApplicants, hasMoreApplicants: hasMore } = useApplicantService(jobId);

  const handleCandidateChose = candidate => {
    setChosenCandidate(candidate);
  };

  const ApplicantsList = applicants.filter(applicant => {
    if (status !== 'all') {
      const key = `${status}Applicant`;
      return applicant[key];
    }
    return !applicant.hiredApplicant;
  });

  React.useEffect(() => {
    setIsLoading(true);
    getApplicants(jobId, { 'applicant-status': { value: status } }, 1, false, 8, () => {
      setIsLoading(false);
    });
  }, [status]);

  return (
    <TxplModal
      title="Select a candidate to staff this job"
      name={name}
      appElement={document.getElementById('root-modal')}
      size="large"
      padding="56px 0 82px 0"
      addPaddingTop
      titleMB="16"
      renderFooter={
        <ScopeFooterWrapper>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            CANCEL
          </ContainedButton>
          <ContainedButton
            opens="approve-candidate-modal"
            icon="thumbs-up"
            backgroundColor="#7266EE"
            forceDisplayText
            disabled={!chosenCandidate}
            data={{
              chosenCandidate,
              setChosenCandidate,
              jobId
            }}
          >
            approve
          </ContainedButton>
        </ScopeFooterWrapper>
      }
    >
      <StyledCandidateModalWrapper>
        <FormSwitcher
          className="with-border"
          backgroundColor="transparent"
          initialValue={status}
          options={applicantStatus}
          onChange={setStatus}
        />
        <InfiniteScroll
          loadMore={() => !loading && getApplicants(jobId, { 'applicant-status': { value: status } }, page)}
          loader={<Loader key={0} />}
          hasMore={hasMore}
          useWindow
        >
          <StyledCandidatesWrapper>
            {ApplicantsList &&
              ApplicantsList.map(applicant => (
                <StyledCandidate
                  key={applicant.id}
                  onClick={() => handleCandidateChose(applicant)}
                  className={chosenCandidate.name === applicant.name && 'chosenCandidate'}
                >
                  <StyledCandidateImage src={applicant.img ?? defaultAvatar} alt="" />
                  <StyledCandidateNameAndRoleWrapper>
                    <StyledCandidateName>{applicant.name}</StyledCandidateName>
                    <StyledCandidateRoleWrapper>
                      {applicant &&
                        applicant?.role?.map(role => (
                          <StyledCandidateRole key={role.name}>{role.name}</StyledCandidateRole>
                        ))}
                    </StyledCandidateRoleWrapper>
                  </StyledCandidateNameAndRoleWrapper>
                </StyledCandidate>
              ))}
          </StyledCandidatesWrapper>
        </InfiniteScroll>
      </StyledCandidateModalWrapper>
    </TxplModal>
  );
};

export default withRenderPortal('select-candidate-modal')(SelectCandidateModal);
