import styled from '@emotion/styled';
import PageLoader from 'common/PageLoader';
import AcceptInvitationModal from 'components/modals/AcceptInvitationModal';
import useToken from 'hooks/api/useToken';
import logoIcon from 'images/page-header-thexplace-logo.svg';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { portalActions } from 'store/portals';
import { pageSize } from 'utils/devices';
import Page404 from './service-page/Page404';

const StyledContainer = styled.div`
  margin: auto;
  margin-top: 30px;

  @media ${pageSize.XS} {
    content: 'XS';
    width: auto;
    margin-top: 80px;
    margin-left: 16px;
    margin-right: 16px;
  }

  @media ${pageSize.S} {
    content: 'S';
    width: 560px;
  }

  @media ${pageSize.M} {
    content: 'M';
    width: 585px;
  }

  @media ${pageSize.L} {
    content: 'L';
    width: 700px;
    margin-top: 50px;
  }
`;

const StyledBackgroundImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #6655cc 0%, rgba(74, 41, 204, 0) 100%);

  @media ${pageSize.XS} {
    width: 389px;
    height: 389px;
    top: -198px;
  }

  @media ${pageSize.S} {
    width: 971px;
    height: 971px;
    top: -650px;
  }

  @media ${pageSize.M} {
    width: 1341px;
    height: 1341px;
    top: -1023px;
  }

  @media ${pageSize.L} {
    width: 1572px;
    height: 1572px;
    top: -1200px;
  }
`;

const StyledImage = styled.img`
  margin: auto;
  display: block;
`;

const AcceptInvitation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { checkActivationToken, isValidToken, loading } = useToken();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    checkActivationToken(params.get('email'), params.get('token')).then(() => {
      dispatch(
        portalActions.openPortal({
          name: 'accept-invitation-modal',
          data: {
            email: params.get('email'),
            invitationToken: params.get('token')
          }
        })
      );
    });
  }, [dispatch, location.search]);

  if (loading) {
    return <PageLoader />;
  }

  if (!isValidToken && !loading) {
    return <Page404 />;
  }

  return (
    <>
      <StyledBackgroundImage />

      <StyledContainer>
        <StyledImage src={logoIcon} />
      </StyledContainer>

      <AcceptInvitationModal />
    </>
  );
};

export default AcceptInvitation;
