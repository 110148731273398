import styled from '@emotion/styled';
import { companyProfileService, tagService, talentService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormAvatar from 'common/form/FormAvatar';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import FormSelectLocation from 'common/form/FormSelectLocation';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import CompanyLocations from 'components/CompanyLocations';
import withRenderPortal from 'hocs/withRenderPortal';
import useTagService from 'hooks/api/useTagService';
import useForm from 'hooks/useForm';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'store/auth';
import { profileActions, profileSelectors } from 'store/profile';
import { tagActions, tagSelectors } from 'store/tags';
import { selectOptionsToArrayOfStrings } from 'utils';
import { dataURItoBlob } from 'utils/assets';
import { getLanguagesOptions } from 'utils/languages';
import { experienceLevelUserOptions } from 'utils/selectOptions';
import { getArrayFromSelectTypeArray, getFormSelectTypeArray } from 'utils/tagHelpers';
import { tagKeys } from '../../constants';
import EditTalentNameFromFields from './EditTalentNameFromFields';
import chatUserService from '../../chatApi/chatUserService';

const StyledGridWrapper = styled.div`
  display: grid;
`;
const StyledFormInput = styled(FormInput)`
  width: 115px;
`;

const companySizeOptions = [
  { value: '0-1', label: '0-1 Employees' },
  { value: '2-10', label: '2-10 Employees' },
  { value: '10-50', label: '10-50 Employees' },
  { value: '50-200', label: '50-200 Employees' },
  { value: '200-500', label: '200-500 Employees' },
  { value: '500+', label: '500+ Employees' }
];

const generateExperienceLevelToSelect = value => {
  let initialExperienceLevel = '';

  if (value && value?.isIntern) {
    initialExperienceLevel = { value: 'intern', label: 'Student/Intern' };
  }

  if (value && !value.from && value.to) {
    const { to } = value;
    const years = `0-${to} years`;
    initialExperienceLevel = {
      value: `0-${to}`,
      label: `${to === 2 ? `Entry level (${years})` : years}`
    };
  }

  if (value && value.from && value.to) {
    const { from, to } = value;
    initialExperienceLevel = {
      value: `${from}-${to}`,
      label: `${from}-${to} years`
    };
  }

  if (value && value.from && !value.to) {
    const { from } = value;
    initialExperienceLevel = {
      value: `${from}-`,
      label: `${from}+ years`
    };
  }

  return initialExperienceLevel;
};

const generateExperienceLevelFromSelect = value => {
  let experienceLevel = null;

  if (value && value === 'intern') {
    experienceLevel = { from: null, to: null, isIntern: true };
  }

  if (value && value !== 'intern') {
    const from = parseInt(value.split('-')[0]) || null;
    const to = parseInt(value.split('-')[1]) || null;
    experienceLevel = {
      from,
      to,
      isIntern: false
    };
  }

  return experienceLevel;
};

const fields = profile => {
  const basicInfo = profile.basicInfo ?? {};
  let formFields = {};

  const avatarImage = { avatarImage: { initialValue: basicInfo?.imageUri?.url ?? '', validate: ['isRequired'] } };
  const yourXField = { yourX: { initialValue: basicInfo?.yourX ?? '', trim: true } };
  const disciplines = {
    disciplines: { initialValue: getFormSelectTypeArray({ arr: basicInfo?.disciplines }), validate: ['isRequired'] }
  };

  const languages = {
    languages: {
      initialValue:
        basicInfo?.languages?.map(lang =>
          getLanguagesOptions().find(item => {
            return item.label === lang.name;
          })
        ) || [],
      validate: ['isRequired']
    }
  };

  if (profile.type === 'talent') {
    formFields = {
      ...avatarImage,
      firstName: { initialValue: profile.firstName, validate: ['isRequired'], trim: true },
      lastName: { initialValue: profile.lastName, validate: ['isRequired'], trim: true },
      additionalName: { initialValue: profile.additionalName ?? '', trim: true },
      useAdditionalName: { initialValue: profile.useAdditionalName },
      pronouns: { initialValue: basicInfo.pronouns },
      customPronoun: {
        initialValue: basicInfo.customPronoun ?? '',
        validate: ['isCustomPronounRequired'],
        dependOn: 'pronouns'
      },
      ...yourXField,
      ...disciplines,
      roles: { initialValue: getFormSelectTypeArray({ arr: basicInfo.roles }), validate: ['isRequired'] },
      location: { initialValue: basicInfo.location, validate: ['isRequired'] },
      ...languages,
      experienceLevel: {
        initialValue: generateExperienceLevelToSelect(basicInfo.experienceLevel),
        validate: ['isRequired']
      }
    };
  } else {
    formFields = {
      ...avatarImage,
      ...yourXField,
      ...disciplines,
      companyType: { initialValue: getFormSelectTypeArray({ arr: basicInfo?.companyType }) },
      suggestedCompanyType: { initialValue: basicInfo.suggestedCompanyType ?? '' },
      locations: {
        initialValue: basicInfo?.locations ?? []
      },
      companySize: { initialValue: basicInfo.companySize ?? '' },
      yearFounded: { initialValue: basicInfo.yearFounded ?? '', validate: ['isRequired', 'isLessThanNowYear'] },
      ...languages
    };
  }

  return formFields;
};

const transforTalentFields = (formFields, dirtyLocation) => ({
  ...formFields,
  disciplines: selectOptionsToArrayOfStrings(formFields.disciplines),
  roles: selectOptionsToArrayOfStrings(formFields.roles),
  languages: getArrayFromSelectTypeArray({ arr: formFields.languages, valueField: 'id' }),
  experienceLevel: generateExperienceLevelFromSelect(formFields.experienceLevel?.value),
  location: dirtyLocation ? formFields.location : undefined
});

const transformCompanyFileds = (formFields, dirtyLocation) => ({
  ...formFields,
  disciplines: selectOptionsToArrayOfStrings(formFields.disciplines),
  companyType: selectOptionsToArrayOfStrings(formFields.companyType),
  languages: getArrayFromSelectTypeArray({ arr: formFields.languages, valueField: 'id' }),
  location: dirtyLocation ? formFields.location : undefined
});

function UserCardModal({ closePortal, name }) {
  const dispatch = useDispatch();
  const { getTags } = useTagService();

  const allTags = useSelector(tagSelectors.selectAllTags);

  const tagDisciplines = useSelector(tagSelectors.selectTagsByType('disciplines'));
  const disciplines = getFormSelectTypeArray({ arr: tagDisciplines.data });

  const tagRoles = useSelector(tagSelectors.selectTagsByType('roles'));
  const roles = getFormSelectTypeArray({ arr: tagRoles.data });
  const [loading, setLoading] = useState(false);

  const tagCompanyTypes = useSelector(tagSelectors.selectTagsByType('companyTypes'));
  const companyTypes = getFormSelectTypeArray({ arr: tagCompanyTypes.data });

  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const profile = useSelector(profileSelectors.selectProfile);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);

  const [dirtyLocation, setDirtyLocation] = useState(false);

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(profile),
    callApi: async formFields => {
      const { avatarImage, ...rest } = formFields;
      const transform = !isCompanyView ? transforTalentFields : transformCompanyFileds;

      const basicInfoData = transform(rest, dirtyLocation);

      // build pronouns object when custom pronouns exists
      if (basicInfoData?.customPronoun) {
        basicInfoData.pronouns = {
          value: basicInfoData.pronouns.value,
          label: basicInfoData.customPronoun
        };
      }

      const formData = new FormData();

      if (profile?.basicInfo?.imageUri?.url !== avatarImage) {
        formData.append('file', dataURItoBlob(avatarImage));
      }

      formData.append('basicInfoData', JSON.stringify(basicInfoData));

      const service = isCompanyView
        ? companyProfileService.setCompanyProfileBasicInfo
        : talentService.setTalentBasicInfo;

      return service(profileId, formData).then(async response => {
        const { data } = response.data;

        await chatUserService.createUser(profileId, {
          id: profileId,
          name: `${formFields.firstName} ${formFields.lastName}`,
          photoUrl: data.imageUri.url
        });

        dispatch(
          profileActions.updateProfileProperty({
            property: 'basicInfo',
            value: data,
            profileId
          })
        );

        if (!isCompanyView) {
          dispatch(
            authActions.updateCurrentUserProperty({
              property: 'firstName',
              value: formFields.firstName
            })
          );
          dispatch(
            authActions.updateCurrentUserProperty({
              property: 'lastName',
              value: formFields.lastName
            })
          );

          dispatch(
            profileActions.updateProfileProperty({
              property: 'firstName',
              value: formFields.firstName,
              profileId
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'lastName',
              value: formFields.lastName,
              profileId
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'additionalName',
              value: formFields.additionalName,
              profileId
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'name',
              value:
                formFields.useAdditionalName && formFields.additionalName?.length
                  ? `${formFields.firstName} ${formFields.lastName} (${formFields.additionalName})`
                  : `${formFields.firstName} ${formFields.lastName}`,
              profileId
            })
          );

          dispatch(
            profileActions.updateProfileProperty({
              property: 'useAdditionalName',
              value: formFields.useAdditionalName,
              profileId
            })
          );
        }

        closePortal();
      });
    }
  });

  const companyTypeLabels = (values.companyType ?? []).map(type => type.label.toLowerCase());
  const companyPrimaryLocation = (values.locations ?? []).find(location => location.isHeadOffice);

  const handleOptionsOpen = type => {
    const fetchTags = async () => {
      await getTags(type);
    };

    const key = tagKeys[type];
    if (!allTags[key].isLoaded) {
      fetchTags();
    }
  };

  const handleCreate = async inputVal => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await tagService.requestTag({ name: inputVal, type: 'role' });
      const tag = { value: data._id, label: data.name };
      dispatch(tagActions.addTag('roles', data));
      onFieldChange('roles')([...values.roles, tag]);
    } finally {
      setLoading(false);
    }
  };

  const setPrimaryLocation = value => {
    onFieldChange('locations')(
      values.locations.map(location => {
        const field = location.id ? 'id' : '_id';
        location.isHeadOffice = location[field] === value;
        return location;
      })
    );
  };

  return (
    <TxplModal
      name={name}
      padding="56px 0 80px 0"
      testTitle
      allowLine
      titleMB="16"
      addPaddingTopBig
      XSpadding="48px 0 80px 0"
      title={isCompanyView ? 'Your company, in a nutshell' : 'You, in a nutshell'}
      appElement={document.getElementById('talent-modal-placeholder')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <ContainedButton mr={6} ml={6} onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton
            form="user-card-modal-form"
            type="submit"
            backgroundColor="#7266EE"
            disabled={submitting}
            data-testid="save-usercard"
          >
            Save
          </ContainedButton>
        </>
      }
    >
      <StyledGridWrapper>
        <FormAvatar
          value={values.avatarImage}
          onChange={onFieldChange('avatarImage')}
          label={isCompanyView ? 'Company logo' : 'Your game face'}
          hintText={
            isCompanyView
              ? 'Your company logo, at least 300x300 pixels. PNG, JPG, GIF, 500Kb or less.'
              : 'A headshot, avatar or cat pic at least 300x300 pixels. PNG, JPG, GIF, 500Kb or less.'
          }
          required
          errors={errors.avatarImage}
          className="avatarImage-required"
          data-testid="avatarImage-field"
        />
        <form onSubmit={onSubmit} id="user-card-modal-form">
          {!isCompanyView && (
            <>
              <EditTalentNameFromFields values={values} errors={errors} onFieldChange={onFieldChange} />
            </>
          )}

          <FormInput
            mb={56}
            value={values.yourX}
            onChange={e => {
              onFieldChange('yourX')(e.target.value);
            }}
            label="Your X"
            maxLength={50}
            placeholder="e.g. Funny sounds for funny games"
            hintText="Tight tagline, best describing what you do, your experience and style."
            data-testid="yourx-field"
          />

          <FormSelect
            mb={56}
            value={values.disciplines}
            options={disciplines}
            isMulti
            maxOptions={2}
            onMenuOpen={() => handleOptionsOpen('discipline')}
            onChange={onFieldChange('disciplines')}
            label="Your disciplines"
            customNoOptMessage="2 will do here"
            hintText="Your craft is likely legendary. Your top 1-2 disciplines will do here."
            isLoading={tagDisciplines.loading}
            error={errors.disciplines}
            required
            className="disciplines-required"
            data-testid="disciplines-field"
          />

          {isCompanyView && (
            <>
              <FormSelect
                mb={56}
                isMulti
                maxOptions={3}
                label="Your company type"
                hintText="Specify the type that best describes your business"
                value={values.companyType}
                error={errors.companyType}
                options={companyTypes}
                onMenuOpen={() => handleOptionsOpen('company')}
                onChange={onFieldChange('companyType')}
                isLoading={tagCompanyTypes.loading}
                data-testid="companyType-field"
              />
              {companyTypeLabels && companyTypeLabels.includes('other') && (
                <FormInput
                  mb={56}
                  value={values.suggestedCompanyType}
                  onChange={onFieldChange('suggestedCompanyType')}
                  error={errors.suggestedCompanyType}
                  hintText="Let us know what company types we are missing and we'll consider adding them to the list"
                  data-testid="suggestedCompanyType-field"
                />
              )}

              <CompanyLocations
                locations={values.locations}
                updateLocations={e => {
                  onFieldChange('locations')(e);
                  setDirtyLocation(true);
                }}
                required
                primaryLocation={companyPrimaryLocation?._id}
                setPrimaryLocation={setPrimaryLocation}
              />

              <FormSelect
                mb={56}
                inputGap={'3px'}
                label="Company size"
                value={companySizeOptions.find(size => values.companySize === size.value)}
                options={companySizeOptions}
                onChange={option => onFieldChange('companySize')(option.value)}
                data-testid="companySize-field"
              />

              <StyledFormInput
                required
                className="yearFounded-required"
                mb={56}
                value={values.yearFounded}
                onChange={event => {
                  if (Number(event.target.value) > 0 || event.target.value === '') {
                    onFieldChange('yearFounded')(event.target.value);
                  }
                }}
                label="Year founded"
                error={errors.yearFounded}
                data-testid="yearFounded-field"
              />
            </>
          )}

          {!isCompanyView && (
            <>
              <FormSelect
                mb={56}
                value={values.roles}
                options={roles}
                handleCreate={handleCreate}
                isLoading={loading || tagRoles.loading}
                isMulti
                maxOptions={3}
                onMenuOpen={() => handleOptionsOpen('role')}
                onChange={onFieldChange('roles')}
                label="Your Roles"
                isCreatable={roles.value?.length !== 3}
                hintText="Give us 1-3 favorite roles that best represent your work."
                customNoOptMessage="3 will do here"
                error={errors.roles}
                required
                className="roles-required"
                data-testid="roles-field"
              />

              <FormSelectLocation
                mb={56}
                isClearable
                value={values?.location?.name || values?.location}
                onChange={e => {
                  onFieldChange('location')(e ?? '');
                  setDirtyLocation(true);
                }}
                label="Your location"
                error={errors.location}
                required
                className="location-required"
                hintText="Where do you currently live? Game worlds not accepted."
                data-testid="location-field"
              />
            </>
          )}

          <FormSelect
            mb={isCompanyView ? 0 : 56}
            value={values.languages}
            options={getLanguagesOptions()}
            isMulti
            onChange={onFieldChange('languages')}
            label="Your languages"
            hintText="The ones you can talk business with..."
            error={errors.languages}
            required
            className="language-required"
            data-testid="languages-field"
          />

          {!isCompanyView && (
            <FormSelect
              value={values.experienceLevel}
              options={experienceLevelUserOptions}
              onChange={onFieldChange('experienceLevel')}
              label="Experience level"
              placeholder="Select an option"
              isClearable
              error={errors.experienceLevel}
              required
              className="experienceLevel-required"
              data-testid="experienceLevel-field"
            />
          )}
        </form>
      </StyledGridWrapper>
    </TxplModal>
  );
}

export default withRenderPortal('edit-talent-modal')(UserCardModal);
