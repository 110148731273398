import Loader from 'common/Loader';
import useJobsIPosted from 'hooks/api/useJobsIPosted';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import JobList from '../commons/JobList';
import size from 'lodash/fp/size';

const mapMyJobs = jobs => (Object.values(jobs) || []).map(job => ({ ...job, isMine: true }));

const JobsIpostedList = ({ status }) => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const [loading, setLoading] = React.useState(true);

  const { getJobsIPosted, hasMoreJobsIPosted, jobsIPosted } = useJobsIPosted(status);

  const jobLength = size(jobsIPosted);

  React.useEffect(() => {
    getJobsIPosted(profileId, status, 0, () => {
      setLoading(false);
    });
  }, [status]);

  return (
    <InfiniteScroll
      loadMore={() => !loading && getJobsIPosted(profileId, status, jobLength)}
      hasMore={hasMoreJobsIPosted && !loading}
      loader={<Loader key={0} style={{ marginTop: '43px' }} />}
      useWindow
    >
      {loading && !jobLength && <Loader key={0} style={{ marginTop: '43px' }} />}
      <JobList jobs={mapMyJobs(jobsIPosted)} hasMore={hasMoreJobsIPosted} showDropdown showPostJobCard status={status}/>
    </InfiniteScroll>
  );
};

export default JobsIpostedList;
