import { notificationActions } from 'store/notifications';
import { authActions } from 'store/auth';
import { collaborationsActions } from 'store/collaborations';
import { profileActions } from 'store/profile';
import store from '../store';
import socketEvents from './socketEvents';

const handleSocketEvents = (event, data) => {
  switch (event) {
    case socketEvents.NOTIFICATION:
      return store.dispatch(
        notificationActions.addNotification({ profileId: data.profileId, notification: data.notification })
      );

    case socketEvents.DELETE_NOTIFICATION:
      return store.dispatch(
        notificationActions.deleteNotification({ profileId: data.profileId, notification: data.notification })
      );

    case socketEvents.WELCOME_MESSAGE:
      return store.dispatch(
        authActions.updateCurrentUserProperty({ property: 'settings.welcomeMessage', value: data.welcomeMessage })
      );

    case socketEvents.NEW_COLLABORATION:
      return store.dispatch(collaborationsActions.addCollaboration({ collaboration: data.newCollaboration }));

    case socketEvents.USER_TYPING_MESSAGE: {
      return store.dispatch(
        profileActions.updateProfileProperty({
          updateType: 'update',
          property: 'conversations',
          profileId: data.profileId,
          updateKey: 'id',
          value: {
            id: data.conversationId,
            typing: data.typing
          }
        })
      );
    }

    case socketEvents.MARK_CONVERSATION_AS_READ: {
      return store.dispatch(
        profileActions.markConversationAsRead({
          conversationId: data.conversationId,
          profileId: data.profileId,
          messageIds: data.messageIds,
          readBy: data.readBy
        })
      );
    }

    case socketEvents.UPDATE_FUNNEL_STATUS: {
      return store.dispatch(
        authActions.updateCurrentUserProperty({
          property: 'funnelStatus.status',
          value: data.statusLog.status,
          profileId: data.statusLog.profile
        })
      );
    }
  }
};

export { handleSocketEvents };
