import React from 'react';
import { useSelector } from 'react-redux';

import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import { profileSelectors } from 'store/profile';

import CollaboratorCard from '../CollaboratorCard/CollaboratorCard';
import { StyledGridContainer } from './CollaboratorsFrameStyles';

const AllCollaboratorsModal = ({ data, name }) => {
  const fullName = useSelector(profileSelectors.selectProfileProperty('name'));
  const { collaborators, gameName = '' } = data;

  const roleList = React.useMemo(
    () =>
      collaborators.reduce(
        (acc, curr) => ({ ...acc, [curr.role ? curr.role._id : curr._id]: curr.role ? curr.role.name : curr.name }),
        {}
      ),
    [collaborators]
  );

  return (
    <TxplModal
      title={`${fullName}'s collaborators on ${gameName}`}
      name={name}
      appElement={document.getElementById('root-modal')}
    >
      <StyledGridContainer>
        {collaborators.map(curr => (
          <CollaboratorCard key={curr.email} roleList={roleList} collaborator={curr} />
        ))}
      </StyledGridContainer>
    </TxplModal>
  );
};

export default withRenderPortal('collaborators-list')(AllCollaboratorsModal);
