import React from 'react';

import TxplModal from 'common/TxplModal';
import styled from '@emotion/styled';
import withRenderPortal from '../../hocs/withRenderPortal';
import Icon from '../../common/Icon';
import ContainedButton from '../../common/ContainedButton';

const EmailText = styled.div`
  display: flex;
`;
const SuccessLabel = styled.div`
  margin-left: 16px;
  color: #ffffff;
`;

const ContactSupportSent = ({ closePortal, name }) => (
  <TxplModal
    title="Contact support"
    name={name}
    appElement={document.getElementById('root-modal')}
    size="small"
    renderFooter={
      <>
        <ContainedButton backgroundColor="primary" onClick={closePortal}>
          Done
        </ContainedButton>
      </>
    }
  >
    <EmailText>
      <Icon icon="checkmark" color="#00FF00" />
      <SuccessLabel>Thank you! Your submission has been received.</SuccessLabel>
    </EmailText>
  </TxplModal>
);

export default withRenderPortal('contact-support-sent')(ContactSupportSent);
