import React from 'react';
import { pageSize } from 'utils/devices';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  position: fixed;
  top: 1px;
  left: 1px;
  color: black;

  &::before {
    padding: 5px;

    @media ${pageSize.XS} {
      content: 'XS';
      background-color: yellow;
    }

    @media ${pageSize.S} {
      content: 'S';
      background-color: orange;
    }

    @media ${pageSize.M} {
      content: 'M';
      background-color: red;
    }

    @media ${pageSize.L} {
      content: 'L';
      background-color: purple;
    }
  }
`;

const DisplayPageSize = () => <StyledDiv />;

export default DisplayPageSize;
