import styled from '@emotion/styled';
import Tooltip from 'components/Tooltip/Tooltip';

export const IconBackgorund = styled.div`
  background: rgba(204, 213, 255, 0.11);
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => (props.marginRight ? props.marginRight : '15px')};
  width: ${props => (props.iconWidth ? props.iconWidth : '40px')};
  min-width: ${props => (props.iconMinWidth ? props.iconMinWidth : '40px')};
  height: ${props => (props.iconHeight ? props.iconHeight : '40px')};
`;
export const StyledTooltip = styled(Tooltip)`
  .__react_component_tooltip.show {
    text-transform: none;
    width: 189px;
    background: #25262c;
    border: none;
    padding: 8px 16px;
  }
  .__react_component_tooltip.place-left::after {
    border-left: 7px solid #25262c !important;
  }

  .__react_component_tooltip.place-right::after {
    border-right: 7px solid #25262c !important;
  }

  .__react_component_tooltip.place-top::after {
    border-top: 7px solid #25262c !important;
  }

  .__react_component_tooltip.place-bottom::after {
    border-bottom: 7px solid #25262c !important;
  }
`;
