import React, { useState } from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import { compose, pathOr, pick } from 'ramda';
import {useSelector } from 'react-redux';
import { Avatar, IconBox, JobBy, MessageBox, ModalBox, TextBox } from './CompanyContractModal.styled';
import useApplicantService from 'hooks/api/useApplicantService';
import { currentUserIdSelector ,currentUserNameSelector } from 'store/selectors/currentUser';
import useJobPath from 'hooks/useJobPath';
import Loader from 'common/Loader';

const AdminContactModal = ({ closePortal, data }) => {
  const { notifyOwnerContractCreation } = useApplicantService();
  const [buttonText, setButtonText] = useState('Notify owner');
  const [iconMesage, setIconMessage] = useState('lock');
  const [showCompany, setShowCompany] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const currentUserName = useSelector(currentUserNameSelector);
  const currentUserId = useSelector(currentUserIdSelector);
  const { jobDescriptionPage } = useJobPath(data.job);
  const { firstName, lastName } = compose(
    pick(['firstName', 'lastName']),
    pathOr({}, ['activeProfile', 'userOwner'])
  )(data);
  const [title, setTitle] = useState('Please, contact the account owner');
  const [message, setMessage] = useState(
    `To create a contract please, contact the account owner of your company ${firstName} ${lastName}.`
  );

  const companyPicture = data?.activeProfile?.basicInfo?.imageUri?.url;
  const companyName = data?.activeProfile?.name;
  const ownerEmail = data?.activeProfile?.userOwner?.email;
  const ownerName = data?.activeProfile?.userOwner?.firstName;
  const applicantId = data?.applicant?._id;
  const talentName = data?.applicant?.name;
  const jobId = data?.jobId;
  const jobName = data?.job ? data?.job?.title : `Proposal with ${talentName}`;

  const isCompanyMember = (companyMembers, currentUserId) => {
    return companyMembers.some(member => member.user === currentUserId);
  };

  const isCurrentUserCompanyMember = isCompanyMember(data?.job ? data?.job?.companyProfile?.members : data?.activeProfile?.members, currentUserId);

  const notifyOwner = async () => {
    setIsLoading(true);
    const payload = {
      ownerEmail,
      adminName: currentUserName,
      ownerName,
      companyName,
      talentName,
      jobName,
      jobUrl:  jobId ? `${process.env.REACT_APP_BASE_URL}${jobDescriptionPage}` : `${process.env.REACT_APP_BASE_URL}/talent/${applicantId}/about`,
    };

    if (!isCurrentUserCompanyMember) {
      return;
    }

    // send job service
    await notifyOwnerContractCreation(applicantId, jobId, payload, () => {
      setIsLoading(false);
      setButtonText('Done');
      setIconMessage('message');
      setShowCompany(false);
      setTitle('An email was sent to the account owner');
      setMessage(
        `An email was sent to ${firstName} ${lastName}, owner of ${companyName} account. We’ll notify them that you are trying to initiate the contract creation process for the specified candidate. Additionally, it is worth noting that you have the option to send a message to ${firstName} through TheXPlace platform. `
      );
    });
  };

  return (
    <TxplModal
      name={'admin-contract-modal'}
      appElement={document.getElementById('root-modal')}
      title={title}
      addPaddingTop
      allowLine={true}
      headerPaddingBottom={0}
      hideCloseIcon={false}
      size="small"
      renderFooter={
        <>
          {showCompany && !isLoading && (
            <ContainedButton onClick={closePortal} mr={6}>
              Cancel
            </ContainedButton>
          )}
          {!isLoading && <ContainedButton onClick={showCompany ? notifyOwner : closePortal} disabled={!isCurrentUserCompanyMember} >{buttonText}</ContainedButton>}
          {isLoading && <Loader isLoading />}
        </>
      }
    >
      <ModalBox>
        {showCompany && (
          <JobBy>
            This job is by <Avatar src={companyPicture} /> {companyName}
          </JobBy>
        )}
        <MessageBox>
          {' '}
          <IconBox>
            <Icon icon={iconMesage} />
          </IconBox>
          <TextBox>{message}</TextBox>
        </MessageBox>
      </ModalBox>
    </TxplModal>
  );
};

export default withRenderPortal('admin-contract-modal')(AdminContactModal);
