import Typography from 'components/shared/Typography';
import withRenderPortal from 'hocs/withRenderPortal';
import useAppliedJobService from 'hooks/api/useAppliedJobService';
import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { DEFAULT_AVATAR } from 'utils/assets';
import ContainedButton from './ContainedButton';
import Spinner from './Spinner';
import Flex from './styles/Flex';
import Tag from './Tag/Tag';
import TxplModal from './TxplModal';

function ApplyAsSoloistModal({ closePortal, data, name }) {
  const {
    applyToJob,
    loading: { loadingApply }
  } = useAppliedJobService();

  const myTalentProfile = useSelector(profileSelectors.selectMyTalentProfile);
  const avatar = myTalentProfile?.avatar || DEFAULT_AVATAR;

  const handleApply = async () => {
    await applyToJob(myTalentProfile._id, data.jobId, data.applyToJobCallback);
    closePortal();
  };

  return (
    <TxplModal
      title="Apply as a soloist"
      titleMB={24}
      name={name}
      size="small"
      maxWidth={520}
      padding="40px 0 40px"
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loadingApply && <Spinner />}
          <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal} ml={6} mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleApply} disabled={loadingApply}>
            Apply as a soloist
          </ContainedButton>
        </>
      }
    >
      <Typography fontSize="17px" mb={24} color="rgba(242, 244, 255, 0.9)">
        Oops! To apply for this job, please go to your soloist account.
      </Typography>
      <Flex alignCenter>
        <Typography as="span" fontSize="17px" color="rgba(242, 244, 255, 0.9)" mr={16}>
          Your soloist account is:
        </Typography>
        <Tag fontSize="16px" img={avatar}>
          {myTalentProfile.name}
        </Tag>
      </Flex>
    </TxplModal>
  );
}

export default withRenderPortal('apply-as-a-soloist')(ApplyAsSoloistModal);
