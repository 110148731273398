import Api from './api';

const api = new Api('benefits');

const getBenefits = data => api.post('', data);

const addBenefit = data => api.post('/add', data);

const updateBenefit = (benefitId, data) => api.put(`${benefitId}`, data);

const deleteBenefit = benefitId => api.delete(`${benefitId}`);

export { getBenefits, addBenefit, updateBenefit, deleteBenefit };
