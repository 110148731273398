import { isNotNilOrEmpty } from '@flybondi/ramda-land';
import jwtDecode from 'jwt-decode';
import { applySpec, compose, equals, propOr, propSatisfies, split, when } from 'ramda';

export function getCookie(name) {
  function escape(s) {
    return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
  }
  var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
  return match ? match[1] : null;
}

export const domainByEnv = {
  develop: 'integration',
  staging: 'staging',
  production: 'production'
};

export const cookieAuthAsName = `thexplace.${domainByEnv[process.env.REACT_APP_ENVIRONMENT]}.on-behalf-of`;
export const cookieAuthName = `thexplace.${domainByEnv[process.env.REACT_APP_ENVIRONMENT]}`;

export const getDecodedIdToken = () => {
  const token = getCookie(`${cookieAuthName}.it`);
  if (token) {
    const decoded = jwtDecode(token);

    if (decoded) {
      const user = applySpec({
        id: propOr('', 'given_name'),
        basicInfoComplete: propSatisfies(equals('true'), 'basicInfoComplete'),
        firstName: propOr('', 'custom:firstName'),
        lastName: propOr('', 'custom:lastName'),
        email: propOr('', 'email'),
        emailVerified: propOr(false, 'email_verified'),
        imageUrl: propOr('', 'custom:imageUrl'),
        preferedName: propOr('', 'custom:preferedName'),
        disciplines: compose(when(isNotNilOrEmpty, split(';')), propOr([], 'custom:disciplines')),
        jobRoles: compose(when(isNotNilOrEmpty, split(';')), propOr([], 'custom:jobRoles')),
        roles: propOr(['standard'], 'cognito:groups'),
        languages: compose(when(isNotNilOrEmpty, split(';')), propOr([], 'custom:languages')),
        location: propOr('', 'custom:location'),
        experienceLevel: propOr('', 'custom:experienceLevel'),
        identityVerification: propOr('', 'custom:identityVerification'),
        timeZone: propOr('', 'custom:timeZone'),
        customAlias: propOr('', 'custom:customAlias'),
        userParameters: compose(params => JSON.parse(params), propOr('{}', 'parameters')),
        featureFlags: compose(params => JSON.parse(params), propOr('{}', 'featureFlags'))
      })(decoded);
      return user;
    }
  }

  return undefined;
};
