import React from 'react';

import Frame from 'common/Frame';
import Tags from 'common/Tags';
import Typography from 'components/shared/Typography';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

const GenreTags = ({
  isEditMode = true,
  error,
  data = [],
  onAction,
  hintText,
  required,
  hasDragPermission = false,
  service = null,
  hideEmpty = false,
  afterDrag,
  portfolio = null,
  project = null,
  callService,
  titlePB = 16
}) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const mode = data.length ? 'edit' : 'add';

  return (
    <>
      {(!hasPermission || hideEmpty) && (data.length === 0 || data[0] === null) ? (
        <></>
      ) : (
        <Frame
          error={error}
          project={project}
          required={required}
          color="#2BFF26"
          text="Genres"
          titlePB={titlePB}
          canEdit={isEditMode}
          editMode={mode}
          onEdit={onAction}
          filled={!!data.length}
          noDataText={hintText || 'Please select genres'}
          id="genres"
          className="genres-required"
        >
          <Tags
            tags={data}
            project={project}
            shouldToggle
            type="genres"
            canEdit={isEditMode}
            hasDragPermission={hasDragPermission}
            service={service}
            afterDrag={afterDrag}
            portfolio={portfolio}
            callService={callService}
          />
          {error && (
            <Typography mt={30} error variant="small">
              {error || 'There must be at least 1'}
            </Typography>
          )}
        </Frame>
      )}
    </>
  );
};

export default GenreTags;
