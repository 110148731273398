import React from 'react';

import GameSearch from 'components/GameSearch';

const AddCreditFirstStep = ({ error, name, onFieldChange }) => (
  <div>
    <GameSearch
      label="Official game title"
      required
      autoFocus
      value={name}
      onChange={option => {
        onFieldChange('game')({
          id: option._id,
          name: option.label,
          credits: option.credits
        });
      }}
      error={error}
    />
  </div>
);

export default AddCreditFirstStep;
