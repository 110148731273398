import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import React from 'react';

const JobTitleModal = ({ closePortal, data, name, onSave }) => {
  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields: {
      jobTitle: { initialValue: data.title ?? '', validate: ['isRequired'], trim: true }
    },
    callApi: async formFields => {
      onSave(formFields.jobTitle);
    },
    closePortal
  });

  return (
    <TxplModal
      size="large"
      padding="0 0 58px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      addPaddingTopBig
      renderFooter={
        <>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={onSubmit} backgroundColor="#7266EE" forceDisplayText>
            Save
          </ContainedButton>
        </>
      }
    >
      <FormInput
        value={values.jobTitle}
        onChange={e => onFieldChange('jobTitle')(e.target.value)}
        error={errors.jobTitle}
        label="Job title"
        maxLength={100}
        placeholder="e.g. Advanced economy game designer"
        required
      />
    </TxplModal>
  );
};

export default withRenderPortal('job-title-modal')(JobTitleModal);
