import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  &.hidden {
    display: none;
  }

  .video-js {
    width: 100%;
    height: ${({ height }) => height || 'calc(100% - 40px)'};
  }
  .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 96px;
    width: 96px;
    border-radius: 50%;
    border: none;
    background-color: #fff;
  }
  .video-js .vjs-control-bar {
    display: flex;
  }
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    display: flex;
    flex-flow: column;
    justify-content: center;
    color: rgba(114, 102, 238, 1);
    font-size: 60px;
  }
  .video-js .vjs-slider {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .video-js .vjs-control-bar {
    background-color: transparent;
  }
  .video-js .vjs-progress-holder {
    height: 4px;
  }
  .video-js .vjs-play-progress:before {
    font-size: 12px;
  }
  .video-js .vjs-picture-in-picture-control {
    display: none;
  }
  .video-js .vjs-tech {
    position: relative;
  }
`;

export const VideoJS = ({ height, isHidden, options }) => {
  const videoRef = React.useRef(null);
  const player = React.useRef(null);

  if (player.current) {
    player.current.pause();
  }

  React.useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      player.current = videojs(videoElement, options);
    }
    return () => {
      if (player) {
        player.current.dispose();
      }
    };
  }, []);

  return (
    <Wrapper className={`wrapper ${isHidden ? 'hidden' : ''}`} height={height}>
      <div data-vjs-player className="video-wrapper">
        <video height="100%" disablePictureInPicture ref={videoRef} className="video-js" />
      </div>
    </Wrapper>
  );
};

export default VideoJS;
