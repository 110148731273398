import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TxplModal from 'common/TxplModal';
import useForm from 'hooks/useForm';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import { companyProfileService, talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';
import { portalActions } from 'store/portals';
import Spinner from 'common/Spinner';

import AddCreditThirdStep from './steps/AddCreditThirdStep';
import AddCreditSecondStep from './steps/AddCreditSecondStep';
import AddCreditFirstStep from './steps/AddCreditFirstStep';
import { StyledStepTitle } from './AddCreditWizardStyles';

const transform = (values, fullName) => ({
  ...values,
  name: values.game.name,
  gameId: values.game.id,
  role: values.role.value,
  vetted: !!values.game?.credits?.find(({ name }) => name.toLowerCase().includes(fullName.toLowerCase()))
});

const fields = {
  game: { initialValue: {}, validate: ['isRequired'] },
  role: { initialValue: '', validate: ['isRequired'] },
  collaborators: { initialValue: [] }
};

const steps = {
  game: {
    name: 'game',
    nextStep: 'role',
    title: 'Credits',
    subTitle: 'Specify the game title',
    index: 1,
    modalSize: 'small'
  },
  role: {
    name: 'role',
    nextStep: 'collaborators',
    title: 'Credits',
    subTitle: 'Specify your role',
    index: 2,
    modalSize: 'small'
  },
  collaborators: {
    name: 'collaborators',
    title: 'Credits',
    subTitle: 'Add collaborators',
    index: 3,
    modalSize: 'large'
  },
  add: {
    name: 'add',
    title: 'Add new collaborator',
    index: 4,
    nextStep: 'message',
    modalSize: 'large',
    titleMB: 32
  },
  message: {
    title: 'Collaborator added',
    nextStep: 'back',
    index: 5,
    name: 'message',
    modalSize: 'large',
    titleMB: 24
  }
};

const AddCreditWizard = ({ name, closePortal, onChoose, onSave, loading, selectedCredits = [] }) => {
  const [step, setStep] = React.useState(steps.game.name);
  const [addOpen, setAddOpen] = useState(false);
  const dispatch = useDispatch();
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const credits = useSelector(profileSelectors.selectProfileProperty('credits')) || [];
  const fullName = useSelector(profileSelectors.selectProfileProperty('name'));

  const collaboratorsRef = useRef(null);

  const onValidate = formFields => {
    const customErrors = {};

    const gameTitle = formFields.getIn(['game', 'value', 'name']);
    if (!gameTitle) {
      customErrors.game = 'Game is required';
    } else if (credits.some(credit => credit?.game?.title === gameTitle)) {
      customErrors.game = 'Game with same name already exists';
    }

    if (!formFields.getIn(['role', 'value'])) {
      customErrors.role = 'Role is required';
    }

    return customErrors;
  };

  const onSubmitSuccess = () => {
    closePortal();
    dispatch(
      portalActions.openPortal({
        name: 'claimed-credit-modal',
        data: {
          gameName: values.game.name
        }
      })
    );
  };

  const callApi = params => {
    const { ...data } = params;
    const service = isCompanyView ? companyProfileService.createCredit : talentService.createCredit;
    return service(profileId, data).then(response => {
      dispatch(
        profileActions.updateProfileProperty({
          property: 'credits',
          updateType: 'insert',
          value: response.data.data,
          profileId
        })
      );
      if (onChoose) {
        onChoose([...selectedCredits, response.data.data._id]);
      }
      return response;
    });
  };

  const { clearErrors, errors, onFieldChange, onSubmit, submitting, triggerErrors, values } = useForm({
    fields,
    onValidate,
    callApi: params => callApi(transform(params, fullName)),
    onSuccess: onSubmitSuccess
  });

  const handleNextStep = e => {
    e.preventDefault();

    if (steps[step].index === 4) {
      collaboratorsRef.current && collaboratorsRef.current.click();
      return;
    }
    if (steps[step].index === 5) {
      handleDone();
      return;
    }

    const { errors } = triggerErrors()(onValidate);
    if (errors?.[step]) return;

    clearErrors();

    if (steps[step].nextStep) return setStep(steps[step].nextStep);
    if (onSave) {
      const data = transform(values, fullName);
      onSave(data);
    } else {
      onSubmit();
    }
  };

  const setAddStep = () => {
    if (steps[step].index === 3) {
      setStep('add');
    } else {
      setStep('collaborators');
    }
  };

  const setDoneStep = () => {
    if (steps[step].index === 4) setStep('message');
  };

  const handleDone = () => {
    setStep('collaborators');
  };

  const handleCancel = () => {
    switch (steps[step].index) {
      case 1:
        closePortal();
        break;
      case 2:
        setStep('game');
        break;
      case 3:
        setStep('role');
        break;
      case 4:
        setAddOpen(false);
        setStep('collaborators');
        break;
    }
  };

  const collaborator = React.useMemo(() => {
    const found = values.game?.credits?.find(({ name }) => name.toLowerCase().includes(fullName.toLowerCase()));
    return found;
  }, [values.game, fullName]);

  const currentStepOptions = React.useMemo(() => steps[step], [step]);

  return (
    <>
      <TxplModal
        name={name}
        title={currentStepOptions.title}
        titleMB={currentStepOptions.titleMB ?? currentStepOptions.subTitle ? '24' : '32'}
        addPaddingTop
        XSaddPaddingTop={'14px'}
        allowLine={true}
        headerPaddingBottom={0}
        padding={step === steps.message.name ? '0px 0 60px' : '56px 0 80px'}
        subTitle={
          currentStepOptions.subTitle && (
            <>
              <Typography mb={16}>
                <StyledStepTitle opacity={1}>
                  Step {currentStepOptions.index > 3 ? 3 : currentStepOptions.index}/3{' '}
                </StyledStepTitle>
                {currentStepOptions.subTitle}
              </Typography>
            </>
          )
        }
        size={currentStepOptions.modalSize}
        appElement={document.getElementById('root')}
        noScroll={step !== steps.collaborators.name && step !== steps.add.name}
        renderFooter={
          <>
            {(submitting || loading) && <Spinner mr={6} />}
            {step !== 'message' && (
              <ContainedButton
                mr={6}
                backgroundColor="secondary"
                onClick={handleCancel}
                disabled={submitting || loading}
                data-testid={steps[step].index === 1 ? 'cancel-changes' : 'back-changes'}
              >
                {steps[step].index === 1 ? 'Cancel' : 'Back'}
              </ContainedButton>
            )}
            <ContainedButton
              form="addCreditWizard"
              type="submit"
              disabled={submitting || loading}
              data-testid="add-credit-wizard"
            >
              {currentStepOptions.nextStep === 'role' && 'Next'}
              {currentStepOptions.nextStep === 'collaborators' && 'Next'}
              {currentStepOptions.nextStep === 'message' && 'ADD COLLABORATOR'}
              {currentStepOptions.nextStep === 'back' && 'DONE'}
              {!currentStepOptions.nextStep && 'FINISH'}
            </ContainedButton>
          </>
        }
      >
        <form onSubmit={handleNextStep} id="addCreditWizard">
          {step === steps.game.name && (
            <AddCreditFirstStep onFieldChange={onFieldChange} name={values.game?.name} error={errors.game} />
          )}
          {step === steps.role.name && (
            <AddCreditSecondStep
              game={values.game}
              role={values.role}
              handleChange={onFieldChange('role')}
              error={errors.role}
              collaborator={collaborator}
            />
          )}
          {steps[step].index > 2 && (
            <AddCreditThirdStep
              values={values}
              ref={collaboratorsRef}
              collaborators={values.collaborators}
              collaborator={collaborator}
              error={errors.collaborators}
              step={steps[step].index}
              addOpen={addOpen}
              setAddOpen={setAddOpen}
              setAddStep={setAddStep}
              setDoneStep={setDoneStep}
              handleChange={onFieldChange('collaborators')}
            />
          )}
        </form>
      </TxplModal>
    </>
  );
};

export default withRenderPortal('add-credit')(AddCreditWizard);
