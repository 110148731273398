import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const FormContainer = styled.form``;

export const FormFieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

export const FormField = styled.div`
  height: 100%;

  > div {
    height: 100%;
  }

  ${props => props.fullWidth && 'grid-column:  1/-1'};

  @media ${pageSize.XS} {
    grid-column: 1/-1;
  }
`;
