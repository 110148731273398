import React from 'react';
import styled from '@emotion/styled';
import FormSwitcher from './form/FormSwitcher';

const Wrapper = styled.div`
  display: flex;
`;

const StyledFormSwitcher = styled(FormSwitcher)`
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  display: flex;
  padding: 2px;
  button.switcher-button {
    padding: 8px 14px;
  }
`;

const viewSwitcher = [
  {
    icon: 'grid-list',
    value: 'grid',
    tooltip: 'Grid view',
    tooltipId: 'tooltip-for-grid-view'
  },
  {
    icon: 'list',
    value: 'list',
    tooltip: 'List view',
    tooltipId: 'tooltip-for-list-view'
  },
  {
    icon: 'cards-list',
    value: 'cards-list',
    tooltip: 'Cards list view',
    tooltipId: 'tooltip-for-cards-list'
  }
];

export const DisplayContext = React.createContext({
  display: 'grid',
  setDisplay: () => {}
});

const DisplaySwitcher = ({ order = ['grid', 'list'] }) => {
  const { display, setDisplay } = React.useContext(DisplayContext);
  return (
    <Wrapper>
      <StyledFormSwitcher
        className="grid-view-selector"
        backgroundColor="transparent"
        initialValue={display}
        options={order.map(it => viewSwitcher.find(view => view.value === it))}
        onChange={setDisplay}
      />
    </Wrapper>
  );
};

export default DisplaySwitcher;
