import Typography from 'components/shared/Typography';
import withRenderPortal from 'hocs/withRenderPortal';
import useProfilePath from 'hooks/useProfilePath';
import useProfileSwitcher from 'hooks/useProfileSwitcher';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { profileSelectors } from 'store/profile';
import ContainedButton from './ContainedButton';
import Spinner from './Spinner';
import TxplModal from './TxplModal';

function CompleteProfileToApplyModal({ closePortal, name }) {
  const history = useHistory();
  const { switchProfile, switchingProfile } = useProfileSwitcher();
  const myTalentProfile = useSelector(profileSelectors.selectMyTalentProfile);
  const { myProfilePath } = useProfilePath();

  const handleNextStepClick = async () => {
    await switchProfile(myTalentProfile, () => history.push(`${myProfilePath}/about?open-progress-funnel=true`));
  };

  return (
    <TxplModal
      title="Complete your profile to apply"
      titleMB={24}
      name={name}
      size="small"
      maxWidth={520}
      padding="40px 0 40px"
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {switchingProfile && <Spinner />}
          <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal} ml={6} mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleNextStepClick} disabled={switchingProfile}>
            check next steps
          </ContainedButton>
        </>
      }
    >
      <Typography as="span" fontSize="17px" color="rgba(242, 244, 255, 0.9)">
        We are happy you are interested in this job! Let’s get your profile completed so that you can apply.
      </Typography>
    </TxplModal>
  );
}

export default withRenderPortal('complete-your-profile-to-apply')(CompleteProfileToApplyModal);
