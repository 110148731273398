import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { backOfficeActions } from 'store/backOffice';
import useAPICall from 'hooks/useAPICall';
import ContainedButton from 'common/ContainedButton';
import { Column, Table } from 'components/Table';
import FormSwitcher from 'common/form/FormSwitcher';
import Typography from 'components/shared/Typography';

import ExportCsv from 'common/ExportCsv';
import BackOfficeMissingItemModal from './BackOfficeMissingItemModal';
import BackOfficeMissingGameModal from './BackOfficeMissingGameModal';
import BackOfficeMissingCompanyModal from './BackOfficeMissingCompanyModal';

const StyledAddButton = styled(ContainedButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledToggleButton = styled(ContainedButton)`
  height: 100%;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  padding-bottom: 30px;
  height: 40px;
`;

const StyledRequestedByList = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledIgnoreButton = styled(ContainedButton)`
  & svg path {
    fill-opacity: 1;
  }
`;

const StyledExportCsvContainer = styled.div`
  margin-left: auto;
`;

const BackOfficeMissingItemIgnore = ({ activeTab, activeTabSettings, tableRef, tag }) => {
  const ignoreId = React.useMemo(() => (activeTab === 'companies' ? tag.id : tag._id), [activeTab]);

  const { callApi, loading } = useAPICall({
    name: `update-${activeTab}-ignore`,
    transformData: response => response.data,
    url: `${activeTabSettings.ignoreUrl}${ignoreId}/ignore`,
    onSuccess: () => {
      tableRef.current.refetch();
    },
    method: 'post'
  });

  return (
    <StyledIgnoreButton
      icon="ignore"
      backgroundColor="transparent"
      loading={loading}
      iconColor={tag.ignore ? '#FF5151' : 'rgba(230, 234, 255, 0.35);'}
      onClick={() => callApi({ ignore: !tag.ignore })}
    />
  );
};

const BackOfficeMissingItemRequestedBy = ({ requestedBy }) => {
  if (!requestedBy?.length) return;

  const renderItems = requestedBy.slice(0, 5);

  return (
    <StyledRequestedByList>
      {renderItems.map((user, index) => (
        <Link key={user._id} to={`/backoffice/operations/users/${user._id}`}>
          {user.firstName} {user.lastName} {index !== requestedBy.length - 1 && ','}
        </Link>
      ))}
      {requestedBy.length > 5 && (
        <Typography variant="medium" underline>
          [+{requestedBy.length - 5} MORE]
        </Typography>
      )}
    </StyledRequestedByList>
  );
};

const tabs = {
  tags: {
    dataKey: 'tags',
    paginateUrl: '/tags/missing-items',
    csvUrl: 'tags/missing-items/export-csv',
    ignoreUrl: '/tags/',
    modal: 'backOffice-missing-item-modal',
    title: 'Descriptors',
    titleKey: 'name'
  },
  companies: {
    title: 'Companies',
    dataKey: 'companies',
    paginateUrl: '/companies/missing-items',
    csvUrl: 'companies/missing-items/export-csv',
    ignoreUrl: '/companies/',
    modal: 'backOffice-missing-company-modal',
    type: 'Company',
    titleKey: 'name'
  },
  gameTitles: {
    dataKey: 'games',
    paginateUrl: '/games',
    csvUrl: 'games/missing-items/export-csv',
    ignoreUrl: '/games/',
    modal: 'backOffice-missing-game-title-modal',
    title: 'Game Titles',
    type: 'Game Title',
    titleKey: 'title'
  }
};

const switchOptions = [
  { name: tabs.tags.title, value: 'tags' },
  { name: tabs.companies.title, value: 'companies' },
  { name: tabs.gameTitles.title, value: 'gameTitles' }
];

const BackOfficeMissingItems = () => {
  const [displayIgnored, setDisplayIgnored] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState('tags');
  const tableRef = React.useRef();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Knowledge base' }, { label: 'Missing items' }]));
  }, [dispatch]);

  const resetPage = () => {
    const newQuery = queryString.stringify(
      { ...queryString.parse(location.search, { arrayFormat: 'bracket' }), page: 0 },
      { arrayFormat: 'bracket' }
    );

    history.push({
      pathname: location.pathname,
      search: newQuery
    });
  };

  const handleIgnoredChange = () => {
    setDisplayIgnored(state => !state);
    resetPage();
  };

  const activeTabSettings = React.useMemo(() => tabs[activeTab], [activeTab]);

  return (
    <div>
      <BackOfficeMissingItemModal />
      <BackOfficeMissingGameModal />
      <BackOfficeMissingCompanyModal />
      <StyledButtonsContainer>
        <FormSwitcher options={switchOptions} onChange={value => setActiveTab(value)} initialValue={activeTab} />
        <StyledToggleButton
          onClick={handleIgnoredChange}
          backgroundColor="rgba(204, 213, 255, 0.11);"
          icon={displayIgnored ? 'hidden' : 'visible'}
        >
          {displayIgnored ? 'HIDE' : 'SHOW'} IGNORED ITEMS
        </StyledToggleButton>

        <StyledExportCsvContainer>
          <ExportCsv path={activeTabSettings.csvUrl} />
        </StyledExportCsvContainer>
      </StyledButtonsContainer>
      <Table
        keyId="_id"
        paginate
        ref={tableRef}
        endpoint={`${activeTabSettings.paginateUrl}/paginate`}
        dataKey={activeTabSettings.dataKey}
        queryParams={{ ignored: !!displayIgnored }}
      >
        <Column
          title="Type"
          keyName="type"
          render={tag => {
            if (activeTabSettings.type) return activeTabSettings.type;
            if (tag.type) return tag.type[0].toUpperCase() + tag.type.slice(1);
            return '';
          }}
          search
        />
        <Column title="Name" keyName={activeTabSettings.titleKey} maxWidth={'150px'} search />
        <Column title="Count" keyName="count" render={tag => tag.requestedBy?.length} width={85} search />
        <Column
          title="Comes From Disciplines"
          keyName="fromDisciplines"
          render={tag => tag.fromDisciplines?.reduce((acc, curr) => `${acc}${acc && ', '}${curr.name}`, '')}
          search
        />
        <Column title="Requested By" keyName="requestedBy" render={BackOfficeMissingItemRequestedBy} search />
        <Column
          type="action"
          keyName="add"
          render={tag => (
            <StyledAddButton
              icon="add"
              data={{
                type: tag.type,
                tagId: tag._id,
                gameId: tag._id,
                companyId: tag._id,
                tableRef,
                initialName: tag.name || tag.title
              }}
              opens={activeTabSettings.modal}
              backgroundColor="rgba(204, 213, 255, 0.11);"
            >
              ADD
            </StyledAddButton>
          )}
        />
        <Column
          type="action"
          width="1%"
          keyName="ignore"
          render={tag => (
            <BackOfficeMissingItemIgnore
              tag={tag}
              tableRef={tableRef}
              activeTab={activeTab}
              activeTabSettings={activeTabSettings}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default BackOfficeMissingItems;
