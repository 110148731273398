import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const StyledBenefitFramesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const StyledBenefitContainer = styled.div`
  width: 100%;
  border: 2px dashed rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${pageSize.XS} {
    height: 474px;
  }
`;
export const StyledLifeFrameContentContainer = styled.div`
  width: 100%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledDescription = styled.div`
  max-width: 572px;
  width: 100%;
  height: fit-content;
  margin: 34px 0 32px 0;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  @media ${pageSize.XS} {
    max-width: 294px;
    width: 100%;
  }
`;
