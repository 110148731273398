import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';
import jobService from 'api/jobService';
import { profileSelectors } from 'store/profile';

import { ReactComponent as IconPurpleGradient } from 'images/assets/icons_16x16/purple-gradient.svg';
import Spinner from 'common/Spinner';
import Footer from 'pages/Footer';
import AddJob from 'pages/talent/Jobs/AddJob';
import useProfilePath from 'hooks/useProfilePath';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from '../../common/Icon';
import { F, T, cond, pathEq, propEq } from 'ramda';
import { isNilOrEmpty } from '@flybondi/ramda-land';
import SupplyPostJobModal from 'pages/jobs/card/JobCard/SupplyPostJobModal';
import DemandApplyJobModal from 'pages/jobs/card/JobCard/DemandApplyJobModal';
import CopyClipboard from 'common/CopyClipboard';

const StyledPurpleGradient = styled(IconPurpleGradient)`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: -1;
`;

const Container = styled.div`
  position: relative;
  max-width: 1242px;
  margin: 120px auto 0 auto;
  min-height: 100vh;
  @media ${pageSize.M}, ${pageSize.L} {
    margin-top: 144px;
  }
  @media ${pageSize.M} {
    max-width: 1035px;
  }

  @media ${pageSize.S} {
    max-width: 672px;
  }

  @media ${pageSize.XS} {
    max-width: 100%;
    padding: 0 16px;
  }
`;

const GoBackWrapper = styled.div`
  position: absolute;
  top: -80px;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;

  color: #ffffff;

  @media ${pageSize.XS}, ${pageSize.S} {
    top: -68px;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

/**
 * This function is used to check if the current user has permission to edit the job
 * If the job is null (means the job is being created), it will return true
 * If the job is created by the current user, it will return true
 * Otherwise it will return false
 *
 * @param {Object} job
 * @param {string} profileId
 * @returns {boolean}
 */
function hasJobEditPermission(job, profileId) {
  return cond([
    [isNilOrEmpty, T],
    [propEq('talentModel', 'CompanyProfile'), pathEq(['companyProfile', '_id'], profileId)],
    [propEq('talentModel', 'Talent'), pathEq(['talent', '_id'], profileId)],
    [T, F]
  ])(job);
}

const TalentJobPageLayout = ({ jobId, loadingUser, userId }) => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const currentProfile = useSelector(profileSelectors.selectMyProfile);
  const [job, setJob] = useState(null);
  const hasEditPermission = hasJobEditPermission(job, profileId);
  const isProfileLoaded = useSelector(profileSelectors.selectIsProfileLoaded);
  const viewMode = !hasEditPermission;
  const [loading, setLoading] = useState(false);
  const { myProfilePath } = useProfilePath();
  const { pathname } = useLocation();
  const isApplicantsRoute = pathname.includes('applicants');
  const [backPage, setBackPage] = useState({});

  const currentJob = currentProfile?.jobs[jobId];

  const getBackPage = () => {
    let back = { url: `${myProfilePath}/all-jobs`, title: 'All jobs' };

    if ((pathname.includes('candidates') || pathname.includes('description')) && hasEditPermission) {
      back = { url: `${myProfilePath}/jobs-i-posted`, title: 'Jobs i posted' };
    }

    const applicant = job?.appliedBy.some(j => j.talentId.includes(profileId));

    const jobs = currentProfile?.favorites?.jobs;

    let favorite = false;

    for (const j in jobs) {
      if (j.includes(job?._id)) {
        favorite = true;
      }
    }

    if (applicant || favorite) {
      back = { url: `${myProfilePath}/my-jobs`, title: 'My jobs' };
    }
    return back;
  };

  useEffect(() => {
    if (job !== null) setBackPage(getBackPage());
  }, [job]);

  useEffect(() => {
    if (jobId && jobId !== 'add') {
      const fetchJob = async () => {
        setLoading(true);
        try {
          const { data } = await jobService.getJob(userId, jobId);
          setJob(data.data);
        } catch (error) {
          console.log('error', error);
        } finally {
          setLoading(false);
        }
      };

      fetchJob();
    }
  }, [userId, currentJob]);

  return (
    <>
      <StyledPurpleGradient />
      <Container>
        {isProfileLoaded && backPage?.url && (
          <GoBackWrapper>
            <Link to={backPage?.url}>
              <Icon icon="back" cursorPointer />
            </Link>
            &nbsp; &nbsp;
            <Link to={backPage?.url}>{backPage?.title}</Link>
            &nbsp;
            <Icon icon="arrow-right" />
            &nbsp;
            {job?.title || ' New job'}{' '}
            {job?.code && <CopyClipboard successText="Job ID copied!">{job.code && ` (${job?.code}) `}</CopyClipboard>}
          </GoBackWrapper>
        )}

        {(!isProfileLoaded || loading || loadingUser) && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!!isProfileLoaded && !loading && !loadingUser && (
          <AddJob
            userId={userId}
            profileId={profileId}
            job={job}
            jobId={jobId}
            viewMode={viewMode}
            hasEditPermission={hasEditPermission}
            loading={loading}
          />
        )}
        <SupplyPostJobModal />
        <DemandApplyJobModal />
      </Container>
      {isProfileLoaded && (viewMode || isApplicantsRoute) && <Footer />}
    </>
  );
};

export default TalentJobPageLayout;
