import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import talentService from 'api/talentService';
import companyProfileService from 'api/companyProfileService';
import { profileActions, profileSelectors } from 'store/profile';
import { useErrorSnackbar, useSuccessSnackbar } from 'hooks/useReactSnackbar';
import { jobActions } from 'store/jobs';
import isString from 'lodash/isString';
import jobService from 'api/jobService';

const initialLoading = {
  loadingSave: false,
  loadingChangeStatus: false
};

const useJobServices = () => {
  const dispatch = useDispatch();
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { openErrorSnackbar } = useErrorSnackbar();
  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);

  const [loading, setLoading] = React.useState(initialLoading);

  const saveJob = async (profileId, jobId, action, callback) => {
    if (!loading.loadingSave) {
      const save = isString(action) ? action === 'save' : !action;
      try {
        setLoading({ ...loading, loadingSave: true });
        const service = isCompanyView ? companyProfileService : talentService;
        const res = await service.addRemoveFavorites(profileId, { favoriteId: jobId, favoriteModel: 'Job' });
        const { data } = res.data;

        if (save) {
          dispatch(
            jobActions.updateJob({
              jobType: 'savedJobs',
              profileId,
              jobs: data
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'favorites.jobs',
              updateType: 'insert',
              value: data,
              profileId
            })
          );
        } else {
          dispatch(
            jobActions.deleteJob({
              jobType: 'savedJobs',
              profileId,
              job: data
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'favorites.jobs',
              updateType: 'delete',
              value: data._id,
              profileId
            })
          );
        }

        callback && callback();

        openSuccessSnackbar(save ? 'Job saved' : 'Removed from saved');
      } catch (err) {
        openErrorSnackbar(save ? 'Failed to save' : 'Failed to remove');
      } finally {
        setLoading({ ...loading, loadingSave: false });
      }
    }
  };

  const changeJobStatus = React.useCallback(
    async (profileId, jobId, status = 'closed', callback = () => ({})) => {
      if (!loading.loadingChangeStatus) {
        try {
          setLoading({ ...loading, loadingSave: true });
          await jobService.changeJobStatus(profileId, jobId, status === 'reopened' ? 'published' : status);

          dispatch(
            jobActions.updateJobProperty({
              profileId,
              jobId,
              property: 'status',
              value: status
            })
          );

          if (status === 'closed') {
            dispatch(
              jobActions.deleteJob({
                profileId,
                job: jobId
              })
            );
          }

          dispatch(
            profileActions.updateProfileProperty({
              property: `jobs.${jobId}.status`,
              updateType: 'update',
              value: status,
              profileId
            })
          );

          openSuccessSnackbar(`Job ${status}`);
          callback && callback();
        } catch (err) {
          openErrorSnackbar('Oops Something went wrong');
        } finally {
          setLoading({ ...loading, loadingSave: false });
        }
      }
    },
    [loading.loadingChangeStatus, setLoading]
  );

  const getUnreadJobsCounters = async talentId => {
    try {
      const res = await jobService.getUnreadJobsCounters(talentId, { talentId });
      const payload = res.data;

      dispatch(jobActions.updateJobsCounters(payload));
    } catch (err) {
      console.error(err);
    }
  };

  return {
    saveJob,
    changeJobStatus,
    getUnreadJobsCounters,
    loading
  };
};

export default useJobServices;
