import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import Icon from './Icon';
import Tooltip from 'components/Tooltip';

const Wrapper = styled.div`
  cursor: pointer;
  display: inline-block;
`;

const CopyClipboard = ({ children, successText }) => {
  const { openSuccessSnackbar } = useSuccessSnackbar();

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(children);
    openSuccessSnackbar(successText, 4000);
  }, []);

  return (
    <Wrapper onClick={handleCopyClick} data-tip data-for="job-code">
      {children}
      <Icon icon="copy" />
      <Tooltip className="secondary" tooltipId="job-code">
        Copy to Clipboard
      </Tooltip>
    </Wrapper>
  );
};

export default CopyClipboard;
