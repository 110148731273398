import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
  border: 2px solid rgba(204, 212, 255, 0.11);
  border-radius: 8px;
  padding: 18px;
`;

export const Input = styled.input`
  margin-left: 16px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: rgba(229, 234, 255, 0.35);
  font-size: 16px;
  &:placeholder {
    color: rgba(229, 234, 255, 0.35);
  }
`;
