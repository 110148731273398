import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import isString from 'lodash/fp/isString';
import MediaCoverageCredits from 'pages/talent/MediaCoverageCredits';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import MediaGameCard from './MediaGameCard';
import generateGameUrl from '../../../utils/generateGameUrl';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'side main'
    'side main';
  @media ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    grid-template-areas:
      'side side'
      'main main';
    grid-row-gap: 40px;
  }
  ${props =>
    props.compact &&
    `
    grid-template-areas:
      "main main"
      "side side";
    grid-row-gap: 24px;
  `}
  grid-template-columns: 215px auto;
  margin-bottom: 16px;
  ${props =>
    !props.compact &&
    `
    padding: 32px 40px 32px 24px;
    border: 2px solid rgba(204, 213, 255, 0.11);
    @media ${pageSize.S}{
      padding: 32px 34px 32px 24px;
    }
    @media ${pageSize.XS}{
      padding: 32px 24px 32px 24px;
    }
  `}
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  align-items: start;
  ${props =>
    props.compact &&
    `
    padding-bottom: 24px;
    border-radius: 0px;
  `}
`;
const Text = styled.div`
  white-space: pre-line;
  ${commonStyles.paragraph}
  margin-bottom: ${props => (props.compact ? 0 : 40)}px;
  word-break: break-word;
  font-size: 22px !important;
  font-weight: 400;
  line-height: 36px !important;
  @media ${pageSize.M}, ${pageSize.S} {
    font-size: 20px !important;
    line-height: 32px !important;
  }
  @media ${pageSize.XS} {
    font-size: 17px !important;
    line-height: 28px !important;
  }
`;
const LinkWrapper = styled.div`
  ${commonStyles.paragraph}
  display: inline-block;
  color: rgba(230, 234, 255, 0.35);
  font-size: 22px !important;
  font-weight: 400;
  line-height: 36px !important;
  a {
    color: rgba(230, 234, 255, 0.35);
  }
  @media ${pageSize.M}, ${pageSize.S} {
    font-size: 20px !important;
    line-height: 32px !important;
  }
  @media ${pageSize.XS} {
    font-size: 17px !important;
    line-height: 28px !important;
  }
`;
const ContentWrapper = styled.div`
  border-left: 1px solid rgba(204, 213, 255, 0.11);
  padding: 0 0 0 33px;
  grid-area: main;
  ${props =>
    props.compact &&
    `
    border-left: none;
    padding-left: 0px;
  `}
  @media ${pageSize.M} , ${pageSize.S} , ${pageSize.XS} {
    border-left: none;
    padding-left: 0px;
  }
`;
const EditButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;
const StyledContainedButton = styled(ContainedButton)``;

const addQuotation = text => {
  if (!isString(text)) {
    return text;
  }

  const quotations = ['"', '“', '”'];
  const startsWithQuotation = quotations.includes(text[0]);
  const endsWithQuotation = quotations.includes(text[text.length - 1]);

  if (startsWithQuotation && endsWithQuotation) {
    return `“${text.substring(1, text.length - 1)}”`;
  }
  return `“${text}”`;
};

const TalentMediaCoverageItem = ({ compact, mediaCoverage = {} }) => {
  const { _id, articleLink, articlePublishDate, coverImageUrl, game, mediaTitle, officialGameName, quote, vetted } =
    mediaCoverage;

  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const formattedLink = useMemo(() => {
    if (articleLink.startsWith('http')) return articleLink;
    return `//${articleLink}`;
  }, [articleLink]);

  return (
    <Wrapper compact={compact}>
      {hasEditPermission && !compact && (
        <EditButtonWrapper>
          <StyledContainedButton
            backgroundColor="rgba(204, 213, 255, 0.11)"
            opens="media-coverage-modal"
            data={{ mediaId: _id }}
            icon="edit"
          />
        </EditButtonWrapper>
      )}

      <MediaCoverageCredits
        mediaTitle={mediaTitle}
        date={articlePublishDate}
        link={articleLink}
        vetted={vetted}
        formattedLink={formattedLink}
      />

      <ContentWrapper compact={compact}>
        <Text compact={compact}>
          {addQuotation(quote)}&nbsp;
          <LinkWrapper>
            <a href={formattedLink} target="blank" rel="noopener noreferrer">
              Read more
            </a>
            &nbsp;&rarr;
          </LinkWrapper>
        </Text>

        {!compact && !!game && (
          <MediaGameCard
            imageUrl={(game?.cover && generateGameUrl(game?._id)) || game?.image?.url || coverImageUrl}
            title="Game Highlighted"
            description={game?.title || officialGameName}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default TalentMediaCoverageItem;
