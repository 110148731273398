import { useState } from 'react';

import chatFileService from 'chatApi/chatFileService';

const useChatFileService = () => {
  const [loading, setLoading] = useState(false);

  const uploadFile = async formData => {
    try {
      setLoading(true);
      const res = await chatFileService.uploadFile(formData);
      const { attachmentToken } = res.data;
      return attachmentToken;
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadFile,
    loading
  };
};

export default useChatFileService;
