import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import Frame from 'common/Frame';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import LockButton from 'common/lockUnlockButtons/LockButton';
import UnlockPendingButton from 'common/lockUnlockButtons/UnlockPendingButton';
import useProfileUnlock from 'hooks/api/useProfileUnlockService';
import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';

import ProjectHandiworkModal from './ProjectHandiworkModal';
import ProjectOverviewInstructionsModal from './ProjectOverviewInstructionsModal';

const StyledProjectHandiworkContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding-right: 10px;
  justify-content: space-between;
  margin-bottom: 80px;
`;
const StyledProjectHandiwork = styled.h1`
  white-space: pre-line;
  margin: 0px;
  ${commonStyles.paragraph}
  word-break: break-word;
`;
const StyledButton = styled(ContainedButton)`
  position: relative;
  top: 4px;
  margin-left: 10px;
`;
const StyledIcon = styled(Icon)`
  padding: 0 5px;
  margin-top: 5px;
  cursor: pointer;
`;
const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const ProjectHandiwork = ({
  addMode,
  className,
  error,
  handiwork,
  hasProfileEditPermission,
  id,
  loading,
  onSave,
  project
}) => {
  const dispatch = useDispatch();
  const { addProfileUnlockRequest } = useProfileUnlock();
  const talentId = useSelector(profileSelectors.selectMyProfileId);

  const openInstructions = () => {
    dispatch(portalActions.openPortal({ name: 'project-overview-instructions-modal' }));
  };

  const handleLockBtnClick = e => {
    e.stopPropagation();
    addProfileUnlockRequest({ entityId: project._id, entityType: 'project', talentId });
  };

  if (handiwork) {
    return (
      <>
        {(handiwork?.length > 0 || hasProfileEditPermission) && (
          <StyledProjectHandiworkContainer>
            <StyledProjectHandiwork>{handiwork}</StyledProjectHandiwork>
            {hasProfileEditPermission && (
              <>
                {project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'LOCKED' ? (
                  <LockButton onClick={handleLockBtnClick} tooltip="To request edit permissions, click here" />
                ) : project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'UNLOCK_REQUESTED' ? (
                  <UnlockPendingButton tooltip="Edit request pending" />
                ) : (
                  <StyledButton
                    backgroundColor="rgba(204, 213, 255, 0.11)"
                    icon="edit"
                    data={{ handiwork, notClosePortal: !addMode }}
                    opens="project-handiwork-modal"
                    canEdit={hasProfileEditPermission}
                  />
                )}
              </>
            )}

            <ProjectOverviewInstructionsModal />
            <ProjectHandiworkModal onSave={onSave} loading={loading} />
          </StyledProjectHandiworkContainer>
        )}
      </>
    );
  }

  return (
    <>
      <Frame
        text="Project Overview"
        required
        transparent
        noDataText={
          <>
            <Typography>
              What was your specific contribution? What magic touch did you bring to the project?
              <StyledIcon onClick={openInstructions} icon="question" color="rgba(230, 234, 255, 0.35);" size={16} />
              <StyledSpan onClick={openInstructions}>See instructions</StyledSpan>
            </Typography>
          </>
        }
        data={{ notClosePortal: !addMode }}
        editMode="add"
        canEdit={hasProfileEditPermission}
        error={error}
        opens="project-handiwork-modal"
        id={id}
        className={className}
        project={project}
      />

      <ProjectHandiworkModal onSave={onSave} />
      <ProjectOverviewInstructionsModal />
    </>
  );
};

export default ProjectHandiwork;
