import styled from '@emotion/styled';
import React from 'react';

const StatusText = styled.span`
  text-transform: capitalize;
`;

const SoloistSupplyDemandStatus = ({ status }) => <StatusText>{status}</StatusText>;

export default SoloistSupplyDemandStatus;
