import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';

const StyledSpacer = styled.div`
  height: 2px;
  background-color: rgba(204, 213, 255, 0.11);
`;

const PortfolioMobileActionsModal = ({ data, name }) => {
  const { openProjectPublish, status } = data;

  return (
    <TxplModal
      size="small"
      name={name}
      nonFullScreenOnMobile
      noPadding
      padding="0 !important"
      appElement={document.getElementById('root-modal')}
      hideCloseIcon
    >
      <Typography pl={24} pb={17} pt={21} onClick={openProjectPublish} closes={name} variant="medium" data={data}>
        {status === 'draft' ? 'Publish' : 'Unpublish'}
      </Typography>

      <StyledSpacer />

      <Typography pl={24} pt={19} pb={19} opens="project-publish-modal" closes={name} variant="medium" data={data}>
        Edit settings
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('portfolio-mobile-actions')(PortfolioMobileActionsModal);
