import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { authService } from 'api';
import { authActions } from 'store/auth';
import { profileActions } from 'store/profile';
import { pendoService } from 'services/pendoService';

import useForm from 'hooks/useForm';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import * as Styled from './StyledSplashForm';
import { getDecodedIdToken } from 'utils/cookies';
import { apiCallActions } from 'store/apiCall';

const fields = {
  email: { initialValue: '', validate: ['isRequired', 'isEmail'] },
  password: { initialValue: '', validate: ['isRequired'] }
};

const SplashSignInForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const callApi = formValues =>
    authService
      .login(formValues)
      .then(({ data: { user, talent, company } }) => {
        const allowedLoginStatuses = ['created', 'active'];
        if (!allowedLoginStatuses.includes(user.status)) {
          const fullName = `${user.firstName} ${user.lastName}`;
          return history.push(`/account-suspended?email=${user.email}&fullName=${fullName}&reason=${user.status}`);
        }

        localStorage.removeItem('activeProfileType');

        dispatch(profileActions.clearProfiles());
        dispatch(authActions.login(user));
        dispatch(
          profileActions.updateProfileProperty({
            property: 'quickOnboardingCompleted',
            value: talent?.quickOnboardingCompleted,
            profileId: talent?._id
          })
        );
        dispatch(
          profileActions.updateProfileProperty({
            property: 'quickOnboardingStep',
            value: talent?.quickOnboardingStep,
            profileId: talent?._id
          })
        );

        // Service for analytics
        pendoService.initializePendoService({ user, talent, company });
        const decoded = getDecodedIdToken();
        const isSuperAdmin = decoded.roles.includes('super-admin');
        const backoffice = '/backoffice';
        if (!decoded.basicInfoComplete) {
          window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}/onboarding`;
          return;
        }
        if (isSuperAdmin) {
          history.push(backoffice);
        } else {
          window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}`;
        }
      })
      .finally(() => {
        dispatch(apiCallActions.sendRequestSuccess({ name: 'currentUser' }));
      });

  const { errors, onFieldChange, onSubmit, values, submitting } = useForm({
    callApi,
    fields
  });

  const redirectSignUp = () => {
    const url = `${process.env.REACT_APP_NEXT_BASE_URL}/onboarding`;
    window.location.href = url;
  };

  const handleForgotPassword = () => {
    const url = `${process.env.REACT_APP_NEXT_BASE_URL}/auth/forgot-password`;
    window.location.href = url;
  };

  const errorMessage = errors?.response?.data?.message;
  const showErrorMessage = errorMessage !== 'ACCOUNT_BLOCKED' && errorMessage;

  return (
    <>
      <Styled.SplashFormWrapper>
        <Styled.SplashHeader>
          <Typography
            color="white"
            transform="uppercase"
            fontFamily="Space Grotesk"
            fontStyle="normal"
            fontSize="36px"
            lineHeight="40px"
            bold
          >
            Sign In
          </Typography>
          <Typography
            color="rgba(230, 234, 255, 0.35)"
            transform="none"
            fontFamily="Space Grotesk"
            fontStyle="normal"
            fontSize="16px"
            lineHeight="22px"
            fontWeight="normal"
          >
            Don’t have an account?{' '}
            <Typography color="white" underline display="inline" cursor="pointer" onClick={redirectSignUp}>
              Join
            </Typography>
          </Typography>
        </Styled.SplashHeader>
        <Styled.SplashForm
          onSubmit={() => {
            dispatch(apiCallActions.sendRequest({ name: 'currentUser' }));
            onSubmit();
          }}
          autoComplete="on"
        >
          {errors?.response?.data?.message === 'ACCOUNT_BLOCKED' && (
            <Typography variant="small" color="rgba(255, 81, 81, 1)" pb={24}>
              For security reasons, your account has been locked. You can try again in 5 minutes or&nbsp;
              <Styled.NavigateTo onClick={handleForgotPassword}>reset your password</Styled.NavigateTo>.
            </Typography>
          )}
          <FormInput
            inputGap="6px"
            label="Email"
            title="Email"
            type="email"
            hintText=""
            value={values?.email}
            error={errors?.email || !!errors.message}
            autoFocus
            onChange={onFieldChange('email')}
            mb={24}
          />

          <FormInput
            inputGap="6px"
            label="Password"
            title="Password"
            type="password"
            hintText=""
            value={values?.password}
            error={errors?.password || showErrorMessage || !!errors.message}
            onChange={onFieldChange('password')}
          />

          <Styled.SplashFormFooter>
            <Typography
              color="rgba(230, 234, 255, 0.35)"
              underline
              transform="none"
              fontFamily="Space Grotesk"
              fontStyle="normal"
              fontSize="16px"
              lineHeight="22px"
              fontWeight="normal"
              cursor="pointer"
              onClick={handleForgotPassword}
            >
              Forgot password?
            </Typography>
            <ContainedButton
              data-testid={'splash-login-button'}
              backgroundColor="#7266EE"
              noBorder
              forceDisplayText
              disabled={submitting}
              onClick={onSubmit}
            >
              Sign In
            </ContainedButton>
          </Styled.SplashFormFooter>
        </Styled.SplashForm>
      </Styled.SplashFormWrapper>
    </>
  );
};

export default SplashSignInForm;
