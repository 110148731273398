import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 16px;
  cursor: pointer;
  background: #1a1b1e;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(204, 212, 255, 0.05);
  }

  @media ${pageSize.M} {
    padding: 20px 16px;
  }
  @media ${pageSize.XS} {
    padding: 17px 16px;
  }

  ${({ active }) =>
    active &&
    `
        background: rgba(204, 212, 255, 0.11);
        &:hover {
        background: rgba(204, 212, 255, 0.11);
        }
    `}
`;
export const AvatarWrapper = styled.div`
  position: relative;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const Details = styled.div`
  width: calc(100% - 56px);
  margin-left: 16px;
  color: rgba(229, 234, 255, 0.6);
  font-size: 16px;
`;

export const Participant = styled.div`
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const UnreadContainer = styled.div`
  background: #7266ee;
  border-radius: 12px;
  font-size: 13px;
  color: #fff;
  padding: 2px 9.5px;
  box-sizing: border-box;
  white-space: nowrap;
`;

export const LastMessage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LastMessageText = styled.div`
  color: rgba(229, 234, 255, 0.6);
  width: 100%;
  overflow: hidden;

  ${({ isUnread }) => isUnread && 'color: #fff'}
`;

export const LastMessageDate = styled.div`
  min-width: 70px;
  text-align: end;
`;
