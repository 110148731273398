import Api from './api';

const api = new Api('project');

const getProjects = () => api.get('/');

const getProjectTags = projectId => api.get(`/${projectId}/tags`);

export default {
  getProjects,
  getProjectTags
};
