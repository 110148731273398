import React, { useState } from 'react';
import { get } from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { authActions } from 'store/auth';
import { authService } from 'api';

import ContainedButton from 'common/ContainedButton';
import BackofficeActionButtons from 'pages/backoffice/BackofficeActionButtons';
import BackofficeActionContent from 'pages/backoffice/BackofficeActionContent';
import BackofficeActionHeader from 'pages/backoffice/BackofficeActionHeader';
import BackofficeActionPanel from 'pages/backoffice/BackofficeActionPanel';
import { profileActions } from 'store/profile';
import { collaborationsActions } from 'store/collaborations';

const ActionLoginAs = ({ userId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleLoginAs = () => {
    setLoading(true);
    authService
      .loginAs({ userId })
      .then(response => {
        dispatch(profileActions.clearProfiles());
        dispatch(collaborationsActions.clearCollaborations());
        const user = get('data.user', response);
        dispatch(authActions.login(user));

        history.push('/profile');
      })
      .catch(err => {
        console.log('Login As - error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BackofficeActionPanel>
      <BackofficeActionHeader>Log in as this user</BackofficeActionHeader>

      <BackofficeActionContent>
        Use this function to edit profile data in case a user needs some help. Never use it without an explicit user
        permission.
      </BackofficeActionContent>

      <BackofficeActionButtons>
        <ContainedButton onClick={handleLoginAs} disabled={loading}>
          Log in
        </ContainedButton>
      </BackofficeActionButtons>
    </BackofficeActionPanel>
  );
};

export default ActionLoginAs;
