import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import React from 'react';
import TagItem from 'common/Tag/Tag';
import styled from '@emotion/styled';

const AtAGlanceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const UserAtAGlance = ({ aaaGames, experience, gamesShipped, gap, indieGames }) => {
  if (!experience && !gamesShipped && !aaaGames && !indieGames) {
    return null;
  }
  return (
    <Flex column gap={gap ?? 8} maxWidth={406}>
      <Typography variant="caption" color="rgba(230, 234, 255, 0.35)">
        At a glance
      </Typography>

      <AtAGlanceWrapper>
        {!!experience && (
          <TagItem icon="years-of-experience" backgroundColor="linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)">
            {experience} years of experience
          </TagItem>
        )}
        {!!gamesShipped && (
          <TagItem icon="years-of-experience" backgroundColor="linear-gradient(0deg, #0066FF 0%, #0400C9 100%)">
            {gamesShipped} games shipped
          </TagItem>
        )}
        {!!aaaGames && (
          <TagItem icon="years-of-experience" backgroundColor="linear-gradient(0deg, #FF7A00 0%, #C40000 100%)">
            {aaaGames} AAA shipped
          </TagItem>
        )}
        {!!indieGames && (
          <TagItem
            icon="years-of-experience"
            backgroundColor="linear-gradient(359.43deg, #00BC35 0.44%, #027100 99.47%)"
          >
            {indieGames} indies shipped
          </TagItem>
        )}
      </AtAGlanceWrapper>
    </Flex>
  );
};

export default UserAtAGlance;
