import styled from '@emotion/styled';
import React from 'react';

const StatusText = styled.span`
  text-transform: capitalize;
`;

const SupplyDemandStatus = ({ isSupply }) => <StatusText>{isSupply}</StatusText>;

export default SupplyDemandStatus;
