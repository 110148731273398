import { useState } from 'react';

import conversationService from 'api/conversationService';

const useConversationService = () => {
  const [loading, setLoading] = useState(false);

  const fetchContacts = async (profileId, contactName) => {
    try {
      setLoading(true);
      const res = await conversationService.searchContacts(profileId, contactName);
      return res.data.data;
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const createConversation = async (currentProfileId, contactProfileId, data) => {
    try {
      await conversationService.createConversation(currentProfileId, contactProfileId, data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const getConversationByParticipants = async data => {
    try {
      const res = await conversationService.getConversationByParticipants(data);
      return { conversation: res.data.data };
    } catch (err) {
      return { error: err.response.message };
    }
  };

  return {
    fetchContacts,
    createConversation,
    getConversationByParticipants,
    loading
  };
};

export default useConversationService;
