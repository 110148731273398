import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';
import useIDVerification from 'hooks/useIDVerification';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import VideoJS from 'common/Video';
import * as Styled from './StyledIDVerificationVideoModal';
import { explanataionVideosUrls } from '../../../constants';
import { missingBasicInfo } from 'utils/validateProfileData';
import Spinner from 'common/Spinner';

const IDVerificationVideoModal = ({ closePortal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openStripeModal, getCurrentVerification } = useIDVerification();
  const profile = useSelector(profileSelectors.selectProfile);
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const isFirstStep = step === 1;

  const handleNextStep = async () => {
    if (isFirstStep) {
      setStep(2);
      return;
    }

    await openStripeModal();
    setIsLoading(true);
    const { data } = await getCurrentVerification();
    closePortal();

    // validate that talent has all basic profile info completed.
    const isMissingBasicProfileInfo = missingBasicInfo(profile);

    // ID verification process has been submitted and completed succesfully (verified).
    // Basic profile info is completed.
    if (data && data.status === 'verified' && !isMissingBasicProfileInfo) {
      dispatch(portalActions.openPortal({ name: 'join-events-now-modal' }));
    }
    setIsLoading(false);
  };

  return (
    <TxplModal
      title={isFirstStep ? 'Why do you need to verify my identity?' : 'So how does it work?'}
      name="id-verification-video-modal"
      appElement={document.getElementById('root-modal')}
      titleMB="24"
      padding="32px 0"
      renderFooter={
        <Styled.FooterWrapper>
          <Styled.FooterStep>{`Video ${step}/2`}</Styled.FooterStep>
          {isLoading && !isFirstStep ? <Spinner /> : null}
          {!isLoading && (
            <ContainedButton form="helpModal" type="submit" backgroundColor="primary" onClick={handleNextStep}>
              {isFirstStep ? 'NEXT' : 'GOT IT'}
            </ContainedButton>
          )}
        </Styled.FooterWrapper>
      }
    >
      <VideoJS
        height="100%"
        options={{
          sources: [{ src: explanataionVideosUrls.id_verification_first, type: 'video/mp4' }],
          controls: true
        }}
        isHidden={!isFirstStep}
      />
      <VideoJS
        height="100%"
        options={{
          sources: [{ src: explanataionVideosUrls.id_verification_second, type: 'video/mp4' }],
          controls: true
        }}
        isHidden={isFirstStep}
      />
    </TxplModal>
  );
};

export default withRenderPortal('id-verification-video-modal')(IDVerificationVideoModal);
