import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { portalActions, portalSelectors } from 'store/portals';

const withRenderPortal = getPortalName => Component => {
  const EnhancedComponent = props => {
    const portalName = typeof getPortalName === 'string' ? getPortalName : getPortalName(props);

    const showPortal = useSelector(portalSelectors.isPortalOpenedSelector(portalName));
    const portalData = useSelector(portalSelectors.portalDataSelector(portalName));
    const dispatch = useDispatch();

    const closePortal = data => dispatch(portalActions.closePortal({ name: portalName, data }));
    const openPortal = ({ data, name }) => dispatch(portalActions.openPortal({ name, data }));

    return showPortal ? (
      <Component {...props} name={portalName} openPortal={openPortal} closePortal={closePortal} data={portalData} />
    ) : null;
  };

  return EnhancedComponent;
};

export default withRenderPortal;
