import React from 'react';
import styled from '@emotion/styled';
import Icon from 'common/Icon';

const StyledMessage = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 401px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #00ff00;
  border-radius: 8px;
  padding: 0 12px;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMessageText = styled.span`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #111112;
  text-transform: none;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const Message = ({ setShowMessage }) => (
  <StyledMessage>
    <StyledWrapper>
      <Icon icon="mask" color="black" width="18px" height="16px" mr="9" noFill />
      <StyledMessageText>You have edit permission for 24 hours</StyledMessageText>
    </StyledWrapper>
    <StyledIcon icon="close" color="#111112" size={10} onClick={() => setShowMessage(false)} noFill />
  </StyledMessage>
);

export default Message;
