import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { profileSelectors } from 'store/profile';
import { DEFAULT_AVATAR } from 'utils/assets';
import { transformNotificationData } from 'utils/notificationUtils';
import useNotificationsServices from 'hooks/api/useNotificationsServices';
import { notificationActionStatusConfig, notificationsConfig } from 'components/Notifications/notificationsConfig';
import ContainedButton from 'common/ContainedButton';
import Flex from 'common/styles/Flex';
import Icon from 'common/Icon';
import isEmpty from 'lodash/fp/isEmpty';
import * as Styled from './StyledNotification';
import Typography from 'components/shared/Typography';
import { formatDistanceToNow } from 'date-fns';

const tagColor = {
  'Action needed': '#FF9C40',
  Highlights: '#26CBFF',
  Opportunity: '#FFE040',
  type: 'rgba(204, 213, 255, 0.11)'
};

const Notification = ({ closeNotifications, currentTab, isFirst, notification }) => {
  const history = useHistory();
  const { setNotificationsAsRead } = useNotificationsServices();
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const currentPublicUrl = useSelector(profileSelectors.selectMyProfilePublicUrl);

  const { _id, notificationData, notificationType, readAt, sender, type } = notification;
  const { actionStatus } = notificationData;
  const { actionBtn, icon, message, tag, url } = notificationsConfig[notificationType] ?? {};
  const { statusIcon, statusText } = notificationActionStatusConfig[`${notificationType}-${actionStatus}`] ?? {};

  const avatar = sender?.profile?.basicInfo?.imageUri?.url;

  const showActionBtn = isEmpty(actionStatus) || actionStatus === 'pending';

  const handleUrlClick = () => {
    if (!readAt) {
      setNotificationsAsRead(profileId, type, [_id]);
    }
    const path = transformNotificationData({
      notificationData: { publicUrl: currentPublicUrl, ...notificationData },
      url
    });

    if (path.includes('http')) {
      window.open(path, '_blank');
      return;
    }

    history.push(`${path}`);
  };

  const relativeTime = formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true });

  if (!notificationsConfig[notificationType]) {
    return null;
  }

  return (
    <Styled.NotificationsList onClick={closeNotifications}>
      <Styled.NotificationListItem isFirst={isFirst} onClick={handleUrlClick}>
        <Flex className="nowrap relative zZz">
          <Flex gap={16} pr={20}>
            <Flex gap={6} ml={56}>
              {tag && (
                <Styled.TagItem color="#111112" backgroundColor={tagColor[tag]}>
                  {tag}
                </Styled.TagItem>
              )}
              {type && currentTab === 'all' && (
                <Styled.TagItem backgroundColor={tagColor.type} textTransform="capitalize">
                  {type}
                </Styled.TagItem>
              )}
            </Flex>
            <Flex className="nowrap" gap={16}>
              {sender.senderType === 'system' ? (
                <Styled.Circle>
                  {' '}
                  <Icon icon={icon} />{' '}
                </Styled.Circle>
              ) : (
                <Styled.Avatar src={avatar || DEFAULT_AVATAR} alt="" />
              )}
              <Styled.NotificationListContent>
                <Styled.NotificationMessage>
                  {transformNotificationData({ notificationData, message })}
                </Styled.NotificationMessage>
                {notification.createdAt ? <Styled.Timestamp>{relativeTime}</Styled.Timestamp> : null}
              </Styled.NotificationListContent>
            </Flex>
            <Flex column justifyEnd flexGrow mt={-20} style={{ width: '100%' }}>
              {!showActionBtn && (
                <Flex mt={16} gap={8} justifyEnd alignCenter>
                  <Icon icon={statusIcon} color="rgba(230, 234, 255, 0.6)" />
                  <Typography variant="medium" transform="none" color="rgba(230, 234, 255, 0.6)">
                    {statusText}
                  </Typography>
                </Flex>
              )}
              {actionBtn && showActionBtn && (
                <Styled.ActionButton onClick={handleUrlClick}>
                  <ContainedButton backgroundColor="secondary">{actionBtn}</ContainedButton>
                </Styled.ActionButton>
              )}
            </Flex>
          </Flex>
          {!readAt && (
            <Styled.ReadIndicatorWrapper>
              <Icon size={12} icon="new-indicator" />
            </Styled.ReadIndicatorWrapper>
          )}
        </Flex>
      </Styled.NotificationListItem>
    </Styled.NotificationsList>
  );
};

export default Notification;
