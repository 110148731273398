import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import { collaborationsActions } from 'store/collaborations';
import { profileSelectors } from 'store/profile';
import PageLoader from '../../common/PageLoader';
import CollaborationItem from './collaborationItem/CollaborationItem';
import CollaborationsEmptyState from './CollaborationsEmptyState';
import * as Styled from './StyledCollaborations';

const Collaborations = ({
  activeButton,
  collaborationsList,
  fetchCollaborations,
  hasMore,
  hasVetted,
  hideEmptyState,
  isFirstVouch,
  limit,
  loadMore,
  loading,
  totalCollabs
}) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const verificationStatus = useSelector(selectCurrentUserProperty('verificationStatus'));

  const handleSeeAll = () => {
    history.push(`/talent/${profileId}/community`);
  };

  useEffect(() => {
    return () => {
      dispatch(collaborationsActions.clearCollaborations());
    };
  }, []);

  if (collaborationsList?.length === 0 && hideEmptyState && !loading.getCollaborators) {
    return null;
  }

  // * We have to refactor complex logic of render

  return (
    <Styled.CollaborationsList
      className={(collaborationsList?.length === 0 || activeButton === 'requests sent') && 'empty'}
    >
      {limit && loading.getCollaborators && <PageLoader height="fit-content" width="100%" margin="24px 0" />}
      {!loading.getCollaborators && (collaborationsList?.length === 0 || activeButton === 'requests sent') ? (
        <CollaborationsEmptyState activeButton={activeButton} />
      ) : (
        <Styled.Wrapper>
          {limit && !loading.getCollaborators && (
            <Styled.CollaborationListHeader>
              <Styled.CollabTitle>Collaborations requiring your input</Styled.CollabTitle>
              {totalCollabs > limit && (
                <Styled.AllCollabsButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleSeeAll}>
                  SEE ALL ({totalCollabs})
                </Styled.AllCollabsButton>
              )}
            </Styled.CollaborationListHeader>
          )}
          <InfiniteScroll
            hasMore={limit ? false : hasMore}
            loadMore={() => loadMore()}
            loader={<PageLoader key={0} height="fit-content" width="100%" margin="24px 0" />}
            threshold={100}
          >
            {collaborationsList?.map(item => {
              return (
                <CollaborationItem
                  key={item._id}
                  id={item._id}
                  item={item}
                  verificationStatus={verificationStatus}
                  disableItems={hasVetted}
                  onSuccess={() => fetchCollaborations(0, true)}
                  isFirstVouch={isFirstVouch}
                />
              );
            })}
          </InfiniteScroll>
        </Styled.Wrapper>
      )}
    </Styled.CollaborationsList>
  );
};

export default Collaborations;
