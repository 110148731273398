import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';

import useIDVerification from 'hooks/useIDVerification';

import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';

import * as Styled from './StyledVerificationStatusesModal';

const statusText = {
  pending: 'Please hold. We’re reviewing your application and will reach out shortly. Questions? Feel free to',
  retry: 'Hmmm... something went wrong during your previous attempt. Please try again. Questions? Feel free to',
  'failed-retry':
    'Hmmm... something went wrong during your previous attempt. Please try again. Questions? Feel free to',
  problem: 'Please',
  failed: 'Please'
};

const VerificationStatusesModal = ({ closePortal, helpPopup, name, status, statusTitle }) => {
  const { openStripeModal } = useIDVerification();

  return (
    <TxplModal
      name={name}
      title="Id verification status"
      appElement={document.getElementById('root-modal')}
      size="small"
      allowLine
      maxWidth={680}
      titlePadding="24px"
      padding="56px 0 82px"
      XSpadding="56px 0 24px"
      renderFooter={
        <>
          {status === 'retry' || status === 'failed-retry' ? (
            <>
              <Styled.CancelButton forceDisplayText backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal}>
                CANCEL
              </Styled.CancelButton>
              <ContainedButton forceDisplayText onClick={openStripeModal}>
                TRY AGAIN
              </ContainedButton>
            </>
          ) : (
            <ContainedButton forceDisplayText onClick={closePortal}>
              DONE
            </ContainedButton>
          )}
        </>
      }
    >
      {statusTitle()}
      <Styled.contentText variant="medium" color="rgba(230, 234, 255, 0.6)">
        {statusText[status]}
        <Styled.questionsText variant="medium" color="#7266EE" onClick={() => helpPopup()}>
          &nbsp;contact our friendly support team{status === 'failed' && ' to get help'}.
        </Styled.questionsText>
      </Styled.contentText>
    </TxplModal>
  );
};

export default withRenderPortal('verification-statuses-modal')(VerificationStatusesModal);
