import React, { useState } from 'react';
import styled from '@emotion/styled';
import { excludeMissingItemByType } from 'utils/tagUtils';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import FormUpload from 'common/form/FormUpload';
import { tagService } from 'api';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledForm = styled.form`
  max-width: 600px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
  margin-left: auto;
`;

const BackOfficeMissingItemModal = ({
  closePortal,
  name: modalName,
  data: { tagId, type, tableRef, initialName, initialList, initialAssociates = [], initialImage, isRequestMode }
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [list, setList] = useState(initialList);
  const [associates, setAssociates] = useState(initialAssociates);
  const [image, setImage] = useState(initialImage);
  const [error, setError] = useState({});

  const addMissingItem = async data => {
    if (isRequestMode) {
      await tagService.requestTag(data);
    } else {
      await tagService.approveMissingItem(tagId, data);
    }
  };

  const handleSubmit = async e => {
    setIsLoading(true);
    try {
      e.preventDefault();

      if ((!name?.length && !exclude.name) || (!list?.length && !exclude.lists)) {
        let error = {};
        if (!name?.length && !exclude.name) error = { ...error, name: 'Name is required' };
        if (!list?.length && !exclude.lists) error = { ...error, list: 'List is required' };

        return setError(error);
      }

      setError({});

      const body = {
        name,
        lists: [list],
        associates,
        type
      };

      const formData = new FormData();

      if (image) formData.append('file', image);
      formData.append('tag', JSON.stringify(body));

      await addMissingItem(formData);

      if (tableRef) {
        tableRef.current.refetch();
      }
      closePortal();
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isLoading || !tagId) return;

    setIsLoading(true);
    tagService
      .getTag(tagId)
      .then(({ data: { data } }) => {
        setName(data.name);
        setList(data.lists[0]);
        setAssociates(data.associates);
        setImage(data.imageUrl);
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line
  }, [tagId])

  const exclude = React.useMemo(() => excludeMissingItemByType[type], [type]);

  return (
    <TxplModal
      name={modalName}
      appElement={document.getElementById('root')}
      title="Add missing Item"
      renderFooter={
        <SubmitRow>
          <StyledButtonsContainer>
            {isLoading && <Spinner />}
            <ContainedButton
              type="button"
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              disabled={isLoading}
            >
              Cancel
            </ContainedButton>
            <ContainedButton type="submit" form="back-office-missing-items" forceDisplayText disabled={isLoading}>
              Add
            </ContainedButton>
          </StyledButtonsContainer>
        </SubmitRow>
      }
    >
      <StyledForm onSubmit={handleSubmit} id="back-office-missing-items">
        {!exclude.name && (
          <FormInput
            autoFocus
            label="Name"
            error={error.name}
            value={name}
            required
            onChange={e => setName(e.target.value)}
          />
        )}
        {!exclude.lists && (
          <FormInput label="List" error={error.list} value={list} required onChange={e => setList(e.target.value)} />
        )}
        {!exclude.logo && (
          <FormUpload
            wrapperStyles={{ flexDirection: 'column' }}
            disabled
            label="Logo"
            preview
            accept="image/svg+xml"
            mw="100px"
            aspectRatio={208 / 300}
            noCrop
            onChange={setImage}
            value={image}
            hintText="Should be an SVG image"
            mt={20}
            padding
          />
        )}
        {!exclude.associates && (
          <FormInput
            label="Associations"
            value={associates.join(', ')}
            onChange={e => setAssociates(e.target.value.split(', '))}
          />
        )}
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-missing-item-modal')(BackOfficeMissingItemModal);
