import _ from 'lodash';
import map from 'lodash/fp/map';
import {
  compensationOptions,
  compensationOptionsContractor,
  jobTypeOptions,
  reqAvailabilityOptions,
  teamRoleOptions,
  workplaceTypeOptions
} from '../../../../utils/selectOptions';

export const transformData = data => {
  const generateExperienceLevel = value => {
    let experienceLevel = null;

    if (value && value === 'intern') {
      experienceLevel = { isIntern: true };
    }
    if (value && value === 'all') {
      experienceLevel = { all: true };
    }

    if (value && value !== 'intern' && value !== 'all') {
      const from = parseInt(value.split('-')[0]) || null;
      const to = parseInt(value.split('-')[1]) || null;
      experienceLevel = {
        from,
        to
      };
    }

    return experienceLevel;
  };

  const transformedData = {
    ...data,
    thumbnail: data.thumbnail || null,
    details: {
      ...data.details,
      disciplines: data.details?.disciplines ? data.details?.disciplines?.map(discipline => discipline.value) : [],
      jobType: data.details?.jobType?.value,
      workplaceType: data.details?.workplaceType?.value,
      requiredAvailability: data.details?.requiredAvailability?.value,
      teamRole: data.details?.teamRole?.value,
      compensation: data.details?.compensation?.value,
      compensationRange: data.details?.compensationRange
        ? {
            from: parseInt(data.details?.compensationRange?.from),
            to: parseInt(data.details?.compensationRange?.to),
            hideFromProfile: data.details?.hideRemuneration
          }
        : null,
      language: data.details?.language
        ? { id: data.details?.language?.value, name: data.details?.language?.label }
        : null,
      experienceLevel: generateExperienceLevel(data.details?.experienceLevel?.value),
      hideExperience: data.details?.hideExperience,
      anythingElse: data.details?.anythingElse
    },
    projects: data.projects,
    role: {
      youWill: JSON.stringify(data.youWill),
      youHave: JSON.stringify(data.youHave),
      bonus: JSON.stringify(data.bonus),
      platforms: map(tag => tag._id ?? tag, data.platforms),
      tools: map(tag => tag._id ?? tag, data.tools),
      genres: map(tag => tag._id ?? tag, data.genres),
      skills: map(tag => tag._id ?? tag, data.skills),
      tags: map(tag => tag._id ?? tag, data.tags)
    }
  };

  return transformedData;
};

export const normalizeData = data => {
  const generateExperienceLevel = value => {
    let experienceLevel = null;

    if (value && value.isIntern) {
      experienceLevel = { value: 'intern', label: 'Intern' };
    }

    if (value && value.all) {
      experienceLevel = { value: 'all', label: 'Open for all' };
    }

    if (value && !value.from && value.to) {
      const { to } = value;
      experienceLevel = {
        value: `0-${to}`,
        label: `0-${to} years`
      };
    }

    if (value && value.from && value.to) {
      const { from, to } = value;
      experienceLevel = {
        value: `${from}-${to}`,
        label: `${from}-${to} years`
      };
    }

    if (value && value.from && !value.to) {
      const { from } = value;
      experienceLevel = {
        value: `${from}-`,
        label: `${from}+ years`
      };
    }

    return experienceLevel;
  };

  const { _id, role, ...formData } = data;

  const getCompensationValue = jobType => {
    const options = jobType && jobType === 'contractor' ? compensationOptionsContractor : compensationOptions;
    return options.find(item => item.value === data.details.compensation);
  };

  const normalizedData = {
    ...formData,
    details: _.values(data.details).every(_.isEmpty)
      ? null
      : {
          ...data.details,
          disciplines: data.details.disciplines
            ? data.details.disciplines.map(discipline => ({ value: discipline._id, label: discipline?.name }))
            : null,
          jobType: jobTypeOptions.find(item => item.value === data.details.jobType),
          workplaceType: workplaceTypeOptions.find(item => item.value === data.details.workplaceType),
          compensation: getCompensationValue(data.details.jobType),
          compensationRange: data.details.compensationRange,
          language: data.details.language
            ? { value: data.details.language.id, label: data.details.language.name }
            : null,
          experienceLevel: generateExperienceLevel(data.details.experienceLevel),
          requiredAvailability: reqAvailabilityOptions.find(item => item.value === data.details.requiredAvailability),
          hideSalary: data.details.salary ? data.details.salary.hideFromProfile : null,
          hideHourlyRate: data.details.hourlyRate ? data.details.hourlyRate.hideFromProfile : null,
          hideRemuneration: data.details.hideRemuneration,
          teamRole: teamRoleOptions.find(item => item.value === data.details.teamRole)
        },
    projects: data.projects.reduce((curr, project) => {
      if (project.name) curr.push(project);
      return curr;
    }, []),
    youWill: role?.youWill ? JSON.parse(role.youWill) : '',
    youHave: role?.youHave ? JSON.parse(role.youHave) : '',
    bonus: role?.bonus ? JSON.parse(role.bonus) : '',
    platforms: role?.platforms || [],
    tools: role?.tools || [],
    genres: role?.genres || [],
    skills: role?.skills || [],
    tags: role?.tags || []
  };

  return normalizedData;
};
