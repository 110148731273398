import styled from '@emotion/styled';
import Icon from 'common/Icon';
import Tooltip from 'components/Tooltip';
import React from 'react';
import commonStyles from 'utils/common-styles';

const StyledVettingStatusContainer = styled.div`
  display: inline-block;
  vertical-align: text-top;
`;

const StyledVettingLabel = styled.span`
  ${commonStyles.caption}
  text-transform: uppercase;
  margin-left: 6px;
`;
const IconWrapper = styled.span`
  position: relative;
  display: inline-flex;
`;

const VettingStatus = ({ className, hideText, id, size = 21, tooltipPlace = 'top', vetted }) => (
  <IconWrapper>
    <StyledVettingStatusContainer className={className}>
      <Icon
        data-tip
        data-for={`tooltip-for-${id}`}
        icon={vetted ? 'vetted-i' : 'vetting-i'}
        color={vetted ? '#00FF00' : '#fff'}
        size={size}
      />
      {!hideText && <StyledVettingLabel>{vetted ? 'vetted' : 'vetting in progress'}</StyledVettingLabel>}
      {id && (
        <Tooltip tooltipId={`tooltip-for-${id}`} place={tooltipPlace}>
          {vetted ? 'Vetted  ' : 'Vetting in progress'}
        </Tooltip>
      )}
    </StyledVettingStatusContainer>
  </IconWrapper>
);

export default VettingStatus;
