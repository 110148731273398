import React from 'react';
import * as Styled from './StyledUserCard';
import MoreLanguages from 'common/MoreLanguages';

const Languages = ({ allLanguages, languages, renderLocation, isCompanyView }) => {
  return (
    <>
      <Styled.FlexRowLanguages isCompanyView={isCompanyView} locationRendered={renderLocation}>
        <Styled.StyledIcon icon="languages" color="rgba(230, 234, 255, 0.6)" />
        <Styled.Languages title={allLanguages}>
          <MoreLanguages languages={languages} initialLength={2} />
        </Styled.Languages>
      </Styled.FlexRowLanguages>
    </>
  );
};

export default Languages;
