import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';

export const Container = styled.div`
  border: 2px solid rgba(204, 212, 255, 0.11);
  border-radius: 12px;
  padding: 12px;
`;
export const MaxCharacterError = styled.div`
  color: red;
  margin-bottom: 5px;
  font-size: 13px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItem = styled.div`
  margin-right: 8px;
  width: 48px;
  height: 40px;
  background: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media ${pageSize.XS} {
    margin-right: 4px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-height: 160px;
`;

export const Input = styled.textarea`
  width: 100%;
  max-height: 160px;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  resize: none;
  overflow: auto;
  ${({ isDisabled }) => isDisabled && 'pointer-events: none; opacity: .5;'}
  ${({ noValue }) => noValue && 'height: 36px; line-height: 36px;'}
`;
export const SendMessageButton = styled(ContainedButton)`
  height: 40px;

  ${({ isDisabled }) =>
    isDisabled &&
    `
        pointer-events: none; 
        button {
            background: grey;
        };
    `}

  @media ${pageSize.XS} {
    width: 48px;
    display: flex;
    justify-content: center;
  }
`;
