import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';

const Wrapper = styled.div`
  padding: 16px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  background: rgba(204, 213, 255, 0.11);
  transition: all 0.5s ease-in;
  cursor: pointer;

  &:hover {
    background: white;
    & path {
      fill: black;
    }
  }
  & svg {
    position: absolute;
  }
`;

const CircleButton = ({ className, iconName, onClick }) => (
  <Wrapper onClick={onClick} className={className}>
    <Icon icon={iconName} />
  </Wrapper>
);

export default CircleButton;
