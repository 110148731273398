import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const portalsDomain = state => get('ui.portals', state);

export const isPortalOpenedSelector = getPortalName =>
  createSelector([portalsDomain, (_, props) => props], (portals, props) => {
    const portalName = getPortalName
      ? typeof getPortalName === 'string'
        ? getPortalName
        : getPortalName(props)
      : props.name;

    return Boolean(portals[portalName]);
  });

export const portalDataSelector = getPortalName =>
  createSelector([portalsDomain, (_, props) => props], (portals, props) => {
    const portalName = getPortalName
      ? typeof getPortalName === 'string'
        ? getPortalName
        : getPortalName(props)
      : props.name;

    return get(`${portalName}.data`, portals);
  });
