import React, { useEffect, useState } from 'react';
import kebabCase from 'lodash/fp/kebabCase';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import Icon from 'common/Icon';
import { addCategory, getCategories } from '../../../../api/categoryServices';
import {
  DragIcon,
  StyledAddPageButton,
  StyledCancelButton,
  StyledPageButtonsContainer,
  StyledPageNameContainer,
  StyledPageNameInput,
  StyledPageNameInputContainer,
  StyledSinglePageEditContainer
} from './StyledLifePagesEditModal';
import LifePageDeleteConfirmationModal from '../LifePageDeleteConfirmationModal/LifePageDeleteConfirmationModal';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../../../store/profile';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { lifeServices } from '../../../../api';

const LifePagesEditModal = ({ closePortal, listOfCategories, name, setActiveButton, setListOfCategories }) => {
  const [pageId, setPageId] = useState();
  const [pageName, setPageName] = useState();
  const [newPageName, setNewPageName] = useState('');
  const [showField, setShowField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(listOfCategories);
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));

  useEffect(() => {
    if (listOfCategories && listOfCategories.length === 0) setShowField(true);
    setCategories(listOfCategories);
  }, [listOfCategories]);

  const handleDelete = (id, name) => {
    setPageId(id);
    setPageName(name);
  };

  const handleAdd = () => {
    if (newPageName !== '' && showField) {
      setLoading(true);
      const data = {
        name: `${newPageName}`,
        page: 'life',
        companyId: companyId
      };
      addCategory(data).then(() => {
        getCategories(companyId, 'life').then(res => {
          setListOfCategories(res.data.data);
          res.data.data && setActiveButton(kebabCase(res.data.data[0]?.name));
          setLoading(false);
          closePortal();
        });
      });
    } else {
      closePortal();
    }
  };

  const handleShowInput = () => {
    setShowField(true);
  };

  function handleOnDragEnd(result) {
    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCategories(items);

    lifeServices
      .changeOrder({
        companyId: companyId,
        categories: items.map(item => item._id)
      })
      .then(() => {
        setListOfCategories(items);
      });
  }

  return (
    <TxplModal
      name={name}
      testTitle
      title="Edit pages"
      padding="56px 0 82px 0"
      titlePadding="16px"
      addPaddingTop
      XSpadding
      boldWeight
      allowLine
      XSaddPaddingTop="14px"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loading && <Spinner mr={6} />}
          <StyledCancelButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </StyledCancelButton>
          <ContainedButton onClick={handleAdd}>Save</ContainedButton>
        </>
      }
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => {
                return (
                  <div id={category._id} key={index}>
                    <Draggable key={category._id} draggableId={category._id} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <div
                            style={{
                              position: 'relative'
                            }}
                          >
                            {categories.length > 1 && (
                              <DragIcon {...provided.dragHandleProps} className={`drag-icon-${category._id}`}>
                                <Icon icon="draggable_touch" size={32} noFill />
                              </DragIcon>
                            )}
                            <StyledSinglePageEditContainer key={category._id}>
                              <StyledPageNameContainer>{category.name}</StyledPageNameContainer>
                              <StyledPageButtonsContainer>
                                <ContainedButton
                                  opens="life-page-delete-confirmation-modal"
                                  icon="delete"
                                  type="button"
                                  iconColor="#FF5151;"
                                  backgroundColor="transparent"
                                  color="rgba(204, 213, 255, 0.11);"
                                  ml="6"
                                  onClick={() => handleDelete(category._id, category.name)}
                                />
                              </StyledPageButtonsContainer>
                            </StyledSinglePageEditContainer>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </div>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showField && (
        <StyledSinglePageEditContainer>
          <StyledPageNameInputContainer>
            <StyledPageNameInput
              placeholder="Name of the page"
              value={newPageName}
              onChange={e => setNewPageName(e.target.value)}
            />
          </StyledPageNameInputContainer>
          <StyledPageButtonsContainer>
            <ContainedButton
              opens="life-page-delete-confirmation-modal"
              icon="delete"
              type="button"
              iconColor="#FF5151;"
              backgroundColor="transparent"
              color="rgba(204, 213, 255, 0.11);"
              ml="6"
              onClick={() => handleDelete(0, newPageName)}
            />
          </StyledPageButtonsContainer>
        </StyledSinglePageEditContainer>
      )}
      <StyledAddPageButton
        onClick={handleShowInput}
        icon="plus"
        backgroundColor="transparent"
        border="2px solid rgba(204, 213, 255, 0.11);"
        forceDisplayText
      >
        Add Page
      </StyledAddPageButton>
      <LifePageDeleteConfirmationModal
        setListOfCategories={setListOfCategories}
        setActiveButton={setActiveButton}
        setShowField={setShowField}
        pageId={pageId}
        pageName={pageName}
      />
    </TxplModal>
  );
};

export default withRenderPortal('life-pages-edit-modal')(LifePagesEditModal);
