import useChatConversationService from 'hooks/chatApi/useChatConversation';
import useChatFileService from 'hooks/chatApi/useChatFile';
import useViewport from 'hooks/useViewport';
import get from 'lodash/fp/get';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import { v4 as uuidv4 } from 'uuid';
import EmojiSelector from '../EmojiSelector/EmojiSelector';
import Upload from '../Upload';
import UploadList from '../Upload/UploadList';
import DropZone from 'components/Dropzone';
import * as Styled from './StyledNewMessage';

const LENGT_LIMIT = 5000;

const NewMessage = ({ selectedConversation, setNewMessageMode }) => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const { isXS } = useViewport();
  const conversationId = typeof selectedConversation === 'string' ? selectedConversation : null;
  const conversations = useSelector(profileSelectors.selectProfileProperty('conversations'));
  const { lastMessageInput = '', lastAttachments = [] } = conversations[conversationId] ?? {};

  const { addMessage, createConversation, loading, typingMessage } = useChatConversationService({ conversationId });
  const { uploadFile } = useChatFileService();
  const [value, setValue] = useState(lastMessageInput);
  const [attachments, setAttachments] = useState([]);
  const [messageSendInProgress, setMessageSendInProgress] = useState(false);

  const handleInputChange = e => {
    const eValue = e.target.value;
    setValue(eValue);
    if (conversationId) {
      dispatch(
        profileActions.updateProfileProperty({
          property: `conversations.${conversationId}.lastMessageInput`,
          value: eValue,
          profileId
        })
      );
    }
    typingMessage({ conversationId: conversationId || selectedConversation.id, isTyping: true });
  };

  const handleValueKeyUp = e => {
    const hasValue = value.replace(/\s/g, '').length !== 0;
    const notSent = (noValue && attachments.length === 0) || value.length > LENGT_LIMIT || loading.addMessage;
    if (hasValue && e.keyCode === 13 && !e.shiftKey && !notSent && !loading.createConversation && !loading.addMessage) {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (value.length <= LENGT_LIMIT) {
      dispatch(
        profileActions.updateProfileProperty({
          property: `conversations.${conversationId}.lastMessageInput`,
          value: '',
          profileId
        })
      );
      const messageId = uuidv4();
      let id = typeof selectedConversation === 'string' ? conversationId : null;
      if (!conversationId && typeof selectedConversation === 'object') {
        const { conversationId: createdConversationId } = await createConversation(null, {
          contact: selectedConversation
        });
        id = createdConversationId;
      }
      setMessageSendInProgress(true);
      await addMessage(id, [{ idempotencyKey: messageId, text: value, sender: profileId, type: 'UserMessage' }]);
      setValue('');
      if (attachments.length > 0) {
        const promises = attachments.map(async attachment => {
          const formData = new FormData();
          formData.append('file', attachment.file);
          const attachmentToken = await uploadFile(formData);
          if (attachmentToken) {
            await addMessage(id, [
              {
                attachmentToken,
                sender: profileId,
                type: 'UserMessage',
                custom: {
                  file: JSON.stringify({
                    fileName: attachment.fileName,
                    fileType: attachment.fileType
                  })
                }
              }
            ]);
          }
        });

        await Promise.all(promises);

        setAttachments([]);

        dispatch(
          profileActions.updateProfileProperty({
            property: `conversations.${conversationId}.lastAttachments`,
            value: [],
            profileId
          })
        );
      }
      setMessageSendInProgress(false);
      setNewMessageMode(false);
    }
  };

  const handleFileUpload = e => {
    const files = Array.from(e?.target?.files ?? e);
    const newAttachments = files.map(file => {
      const { name, type } = file;
      const id = uuidv4();
      return {
        id,
        file,
        fileName: name,
        fileType: type
      };
    });

    if (conversationId) {
      dispatch(
        profileActions.updateProfileProperty({
          property: `conversations.${conversationId}.lastAttachments`,
          value: [...attachments, ...newAttachments],
          profileId
        })
      );
    }
    setAttachments([...attachments, ...newAttachments]);
  };

  const handleFileRemove = id => {
    const updatedAttachments = attachments.filter(attachment => attachment.id !== id);
    dispatch(
      profileActions.updateProfileProperty({
        property: `conversations.${conversationId}.lastAttachments`,
        value: updatedAttachments,
        profileId
      })
    );
    setAttachments(updatedAttachments);
  };
  const noValue = !value || value.replace(/\s/g, '').length === 0;

  const selectEmoji = emoji => {
    const emojis = JSON.parse(localStorage.getItem('emojis')) ?? {};
    const emojisOject = !Array.isArray(emojis) ? emojis : {};
    const frequentEmojis = get(profileId, emojisOject) ?? [];
    const frequent = !Array.isArray(frequentEmojis)
      ? frequentEmojis
      : frequentEmojis.reduce(
          (curr, key) => ({
            ...curr,
            [key]: { id: key, count: 1 }
          }),
          {}
        );

    const newFrequent = Object.assign(frequent, {
      [emoji.id]: {
        id: emoji.id,
        count: (frequent[emoji.id]?.count ?? 0) + 1
      }
    });

    localStorage.setItem('emojis', JSON.stringify(Object.assign(emojisOject, { [profileId]: newFrequent })));

    setValue(message => message + emoji.native);
    typingMessage({ conversationId: conversationId || selectedConversation.id, isTyping: true });
    inputRef.current.focus();
  };

  useEffect(() => {
    if (conversationId) {
      setValue(lastMessageInput);
      setAttachments(lastAttachments);
    }
  }, [conversationId]);

  return (
    <Styled.Container>
      {value.length > LENGT_LIMIT && (
        <Styled.MaxCharacterError>
          {`Max Characters Limit exceeded - ${value.length}/${LENGT_LIMIT}`}
        </Styled.MaxCharacterError>
      )}
      <Styled.Wrapper>
        <DropZone onUpload={handleFileUpload}>
          <Styled.Input
            ref={inputRef}
            placeholder="Write a message"
            value={value}
            onChange={handleInputChange}
            onKeyUp={handleValueKeyUp}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
              }
            }}
            isDisabled={!selectedConversation}
            noValue={value === ''}
          />
        </DropZone>
        <Styled.ActionsWrapper>
          <EmojiSelector onSelect={selectEmoji} isDisabled={!selectedConversation} />
          <Upload handleNewFile={handleFileUpload} isDisabled={!selectedConversation} />
          <Styled.SendMessageButton
            icon="message"
            size={16}
            onClick={handleSendMessage}
            isDisabled={
              messageSendInProgress ||
              (noValue && attachments.length === 0) ||
              value.length > LENGT_LIMIT ||
              loading.addMessage
            }
          >
            {!isXS && 'send'}
          </Styled.SendMessageButton>
        </Styled.ActionsWrapper>
      </Styled.Wrapper>
      {attachments.length > 0 && <UploadList attachments={attachments} handleFileRemove={handleFileRemove} />}
    </Styled.Container>
  );
};

export default NewMessage;
