import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import FormCheckbox from 'common/form/FormCheckbox';
import FormSelect from 'common/form/FormSelect';
import Typography from 'components/shared/Typography';
import capitalize from 'lodash/fp/capitalize';
import React from 'react';

const GridColumnView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 16px 0;
`;

const FilterCheckBoxes = ({ checked, disabled, name, onChange, onSelect, options, title }) => {
  const limit = 8;
  const first8 = options.slice(0, limit);
  const rest = options.slice(limit);

  const selectInitialValues = [];
  if (rest.length > 0 && checked) {
    for (const it of rest) {
      if (checked.find(({ value }) => value == it.value)) {
        selectInitialValues.push(it);
      }
    }
  }

  const [select, setSelected] = React.useState(selectInitialValues);
  const [show, setShow] = React.useState(!!selectInitialValues.length);

  return (
    <div>
      <Typography variant="h3" mb={16} disabled={disabled}>
        {title}
      </Typography>
      <GridColumnView>
        {first8.map(({ label, value }, key) => (
          <FormCheckbox
            key={key}
            name={name}
            value={value}
            text={label}
            iconBorder="rgba(204, 213, 255, 0.11)"
            checked={!!checked?.find(it => it.value.toString() === value.toString())}
            onChange={e => onChange && onChange(e, label)}
            disabled={disabled}
          />
        ))}
      </GridColumnView>
      {(show || !!select.length) && (
        <FormSelect
          isMulti
          mt={24}
          borderRadius={8}
          inputGap="0px"
          padding="13px 18px"
          name={name}
          placeholder={capitalize(name)}
          value={select}
          options={rest}
          onChange={(values, e) => {
            onSelect(values, e);
            setSelected(values);
          }}
        />
      )}
      {!!rest.length && (
        <ContainedButton
          mt={24}
          padding="7px 10px"
          icon="add"
          backgroundColor="secondary"
          onClick={() => setShow(true)}
          disabled={disabled}
        >
          Add {title}
        </ContainedButton>
      )}
    </div>
  );
};

export default FilterCheckBoxes;
