import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logoIcon from 'images/page-header-thexplace-logo.svg';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import userService from 'api/userService';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import useViewport from 'hooks/useViewport';
import Typography from 'components/shared/Typography';
import { authActions } from 'store/auth';
import { pendoService } from 'services/pendoService';

const StyledForm = styled.form`
  display: grid;
  grid-row-gap: 40px;
`;

const StyledImage = styled.img`
  margin-right: auto;
  margin-bottom: 50px;
  display: block;
`;

const fields = data => ({
  email: { initialValue: data.email, validate: ['isRequired'] },
  invitationToken: { initialValue: data.invitationToken, validate: ['isRequired'] },
  password: { initialValue: '', validate: ['isRequired', 'strongPassword'] },
  passwordAgain: { initialValue: '', validate: ['isRequired'] }
});

const onValidate = fields =>
  fields.getIn(['password', 'value']) !== fields.getIn(['passwordAgain', 'value'])
    ? { passwordAgain: 'Passwords does not match' }
    : {};

const AcceptInvitationModal = ({ data, name }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const { apiResponse, errors, onFieldChange, onSubmit, submitted, submitting, values } = useForm({
    fields: fields(data),
    onValidate,
    callApi: params =>
      userService.acceptInvitation(params).then(({ data: { data, ...rest } }) => {
        dispatch(authActions.login(data.user));

        return Promise.resolve({ data: { data, ...rest } });
      })
  });

  const onNext = () => {
    const { user, talent, company } = apiResponse.data.data;
    pendoService.initializePendoService({ user, talent, company });

    if (user?.roles?.includes('super-admin')) {
      history.push('/backoffice/dashboard');
    }
    history.push('/profile');
  };

  const hasError = submitted && !get('data.success', apiResponse);

  return (
    <TxplModal
      title="Set the password"
      subTitle={hasError ? '' : 'Welcome to TheXPlace! To start, please enter and confirm your new password'}
      name={name}
      hideCloseIcon
      size="small"
      transparentBackground
      renderAdditionalHeader={width <= 759 && <StyledImage src={logoIcon} />}
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          {submitted && !hasError && (
            <ContainedButton forceDisplayText onClick={onNext}>
              Next
            </ContainedButton>
          )}
          {!submitted && (
            <ContainedButton forceDisplayText onClick={onSubmit}>
              SET PASSWORD
            </ContainedButton>
          )}
        </>
      }
    >
      {!submitted && (
        <StyledForm onSubmit={onSubmit}>
          <FormInput
            value={values.password}
            label="Set the password"
            error={errors.password}
            onChange={onFieldChange('password')}
            type="password"
          />

          <FormInput
            value={values.passwordAgain}
            label="Type it again"
            error={errors.passwordAgain}
            onChange={onFieldChange('passwordAgain')}
            type="password"
          />
        </StyledForm>
      )}

      {submitted && (
        <Typography variant="medium" success>
          Cool! Your password has been set!
        </Typography>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('accept-invitation-modal')(AcceptInvitationModal);
