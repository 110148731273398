import React from 'react';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';

const UnlockPendingButton = ({ mr, tooltip }) => (
  <ContainedButton backgroundColor="#FFFF" mr={mr} tooltip={tooltip} tooltipId={tooltip}>
    <Icon icon="unlock-pending" noFill size={16} color="black" />
  </ContainedButton>
);

export default UnlockPendingButton;
