import * as React from 'react';

const FilterChildren = () => {
  return (
    <>
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={9} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_215_21908" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={7.2} />
      <feGaussianBlur stdDeviation={10.8} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.320833 0 0 0 0 0.077 0 0 0 0 0 0 0 0 0.3 0" />
      <feBlend in2="effect1_backgroundBlur_215_21908" result="effect2_dropShadow_215_21908" />
      <feBlend in="SourceGraphic" in2="effect2_dropShadow_215_21908" result="shape" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={1.8} />
      <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
      <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0" />
      <feBlend in2="shape" result="effect3_innerShadow_215_21908" />
    </>
  );
};

const ToolkitBoxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144} height={120} fill="none" viewBox="0 0 144 120">
    <g clipPath="url(#a)">
      <path
        d="M20.605 54.191a3.6 3.6 0 0 1 3.22-1.99h96.35a3.6 3.6 0 0 1 3.22 1.99l4.025 8.05c.25.5.38 1.051.38 1.61v62.15a3.6 3.6 0 0 1-3.6 3.6H19.8a3.6 3.6 0 0 1-3.6-3.6v-62.15c0-.559.13-1.11.38-1.61l4.025-8.05Z"
        fill="#5706B7"
      />
      <g filter="url(#b)">
        <path
          d="M10.068 12.79a5.05 5.05 0 0 1 8.16-4.91c1.087.899 26.566 22.423 28.868 59.8a5.045 5.045 0 1 1-10.065.62c-2.067-33.017-25.014-52.457-25.243-52.647a5.04 5.04 0 0 1-1.72-2.864Z"
          fill="#BFCAFF"
          fillOpacity={0.1}
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M16.2 66.6a3.6 3.6 0 0 1 3.6-3.6h104.4a3.6 3.6 0 0 1 3.6 3.6v63a7.2 7.2 0 0 1-7.2 7.2H23.4a7.2 7.2 0 0 1-7.2-7.2v-63Z"
        fill="url(#c)"
      />
      <rect
        x={55.8}
        y={73.799}
        width={32.4}
        height={9}
        rx={3.6}
        fill="#9A7DB7"
        style={{
          mixBlendMode: 'multiply'
        }}
      />
      <path
        d="M9.364 43.66a7.2 7.2 0 0 1 4.703-9.032L117.072 2.164a7.2 7.2 0 0 1 9.032 4.703l1.082 3.433a3.6 3.6 0 0 1-2.352 4.516L14.962 49.444a3.6 3.6 0 0 1-4.515-2.35L9.364 43.66Z"
        fill="url(#d)"
      />
      <g filter="url(#e)">
        <path
          d="M72.3 58.822a4.053 4.053 0 0 1-3.01.86c-3.55-.409-6.53-1.665-8.609-3.631-2.2-2.082-3.274-4.865-2.957-7.642.557-4.875 5.482-9.32 13.821-8.364 3.244.37 4.7-.664 4.752-1.163.06-.495-1.121-1.833-4.365-2.207-3.55-.408-6.53-1.664-8.612-3.63-2.2-2.083-3.279-4.865-2.957-7.642.565-4.876 5.486-9.32 13.817-8.36 2.364.27 3.616-.21 4.146-.52.423-.256.593-.5.61-.643.052-.495-1.113-1.833-4.365-2.207a4.087 4.087 0 0 1-3.596-4.535 4.082 4.082 0 0 1 4.53-3.596c8.332.952 12.127 6.393 11.566 11.272-.565 4.884-5.486 9.32-13.825 8.368-2.364-.274-3.604.21-4.138.52-.423.252-.597.5-.614.64-.057.498 1.117 1.832 4.369 2.206 8.331.957 12.126 6.393 11.565 11.273-.56 4.875-5.482 9.32-13.817 8.36-2.364-.27-3.612.213-4.146.52-.427.26-.593.504-.61.643-.057.495 1.117 1.833 4.365 2.207a4.093 4.093 0 0 1 2.08 7.27Z"
          fill="#BFCAFF"
          fillOpacity={0.1}
          shapeRendering="crispEdges"
        />
      </g>
      <path d="M94.2 44.44a6 6 0 1 0 .08-12 6 6 0 0 0-.08 12Z" fill="#FF4D4D" />
      <g filter="url(#f)">
        <path d="M113.64 24.751a8 8 0 1 0 .107-16.001 8 8 0 0 0-.107 16.001Z" fill="#F0C800" />
      </g>
      <path
        d="M104.719 56.547a6 6 0 1 0 .08-12 6 6 0 0 0-.08 12ZM47.4 28.241a6 6 0 1 0 .08-12 6 6 0 0 0-.08 12Z"
        fill="#F0C800"
      />
    </g>
    <defs>
      <filter
        id="b"
        x={-11.635}
        y={-11.274}
        width={80.352}
        height={113.257}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <filter
        id="e"
        x={36.069}
        y={-12.486}
        width={72.656}
        height={100.996}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <filter
        id="f"
        x={84.094}
        y={-9.249}
        width={59.2}
        height={62.8}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <linearGradient id="c" x1={72} y1={63} x2={72} y2={136.8} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <linearGradient id="d" x1={65.7} y1={18.9} x2={69.3} y2={32.4} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h144v144H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ToolkitBoxIcon;
