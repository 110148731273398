import { notificationTypes } from 'utils/notificationUtils';

export const notificationsConfig = {
  [notificationTypes.soloistAddedCredit]: {
    message: 'Congrats on adding a new credit! Send a note to your collaborators so we can get you vetted.',
    url: '/talent/{publicUrl}/about?credit={creditId}',
    icon: 'smile',
    actionBtn: '',
    tag: ''
  },
  [notificationTypes.soloistAddedProject]: {
    message: 'Congrats on adding a new portfolio project! Send a note to your collaborators so we can get you vetted.',
    url: '/talent/{publicUrl}/portfolio/{projectId}',
    icon: 'smile',
    actionBtn: '',
    tag: ''
  },
  [notificationTypes.collaboratorVetted]: {
    message: 'Woohoo! Your collaborator {userName}, has joined TheXPlace! 👋',
    url: '/talent/{publicUrl}/about',
    icon: '',
    actionBtn: '',
    tag: 'Highlights'
  },
  [notificationTypes.collaboratorUploadedProject]: {
    message: '{userName} just created a new Portfolio Project for {projectTitle}. Check it out! 👀',
    url: '/talent/{publicUrl}/portfolio/{projectId}',
    icon: '',
    actionBtn: '',
    tag: 'Highlights'
  },
  [notificationTypes.collaboratorListedOnCredit]: {
    message: '{userName} has listed you as a collaborator on the {gameTitle}. Help a colleague get their credits! 🙌',
    url: '/talent/{publicUrl}/community',
    icon: '',
    actionBtn: 'See details',
    tag: 'Action needed'
  },
  [notificationTypes.collaboratorListedOnProject]: {
    message:
      '{userName} has listed you as a collaborator on the {projectTitle}. Thanks in advance for helping a colleague! 🙌',
    url: '/talent/{publicUrl}/community',
    icon: '',
    actionBtn: 'See details',
    tag: 'Action needed'
  },
  [notificationTypes.invitedInCompany]: {
    message: 'Nice! You were added as {role} of {companyName}.',
    url: '/company/{publicUrl}/about',
    icon: '',
    actionBtn: '',
    tag: ''
  },
  [notificationTypes.vettingConfirmed]: {
    message: 'Congrats! Your Experience at {companyName} was vetted successfully!',
    url: '',
    icon: 'vetted-i',
    actionBtn: '',
    tag: 'Highlights'
  },
  [notificationTypes.creditVetted]: {
    message: 'Booyah! Your Credit for {creditTitle} was vetted successfully!',
    url: '/talent/{publicUrl}/about?credit={creditId}',
    icon: 'vetted-i',
    actionBtn: '',
    tag: 'Highlights'
  },
  [notificationTypes.projectVetted]: {
    message: 'Amazeballs! Your {projectTitle} project was vetted successfully!',
    url: '/talent/{publicUrl}/portfolio/{projectId}',
    icon: 'vetted-i',
    actionBtn: '',
    tag: 'Highlights'
  },
  [notificationTypes.creditUnlockApproved]: {
    message: 'You can now edit your credits from {creditTitle}. You have 24hrs to make the changes you need.',
    url: '/talent/{publicUrl}/about?credit={creditId}',
    icon: 'unlock',
    actionBtn: 'Edit credits',
    tag: ''
  },
  [notificationTypes.creditUnlockDenied]: {
    message:
      "We're sorry, we couldn't approve your request to unlock {creditTitle}. You can contact support for more details.",
    url: '/talent/{publicUrl}/about?credit={creditId}',
    icon: 'lock',
    actionBtn: '',
    tag: ''
  },
  [notificationTypes.projectUnlockApproved]: {
    message: 'You can now edit your assets from {projectTitle}. You have 24hrs to make the changes you need.',
    url: '/talent/{publicUrl}/portfolio/{projectId}',
    icon: 'unlock',
    actionBtn: 'Edit project',
    tag: ''
  },
  [notificationTypes.projectUnlockDenied]: {
    message:
      "We're sorry, we couldn't approve your request to unlock {projectTitle}. You can contact support for more details.",
    url: '/talent/{publicUrl}/portfolio/{projectId}',
    icon: 'lock',
    actionBtn: '',
    tag: ''
  },
  [notificationTypes.profileVetted]: {
    message: "Congrats! You're in 🎊. Start matching new jobs now.",
    url: '/talent/{publicUrl}/all-jobs',
    icon: 'vetted-i',
    actionBtn: 'See jobs',
    tag: 'Highlights'
  },
  [notificationTypes.collaboratorDeclinedCredit]: {
    message:
      "{collaboratorName} wasn't able to complete the vouch on your {gameTitle}. Let's list another collaborator.",
    url: '/talent/{publicUrl}/about?credit={creditId}',
    icon: 'alert',
    actionBtn: 'Add more',
    tag: 'Action needed'
  },
  [notificationTypes.collaboratorDeclinedProject]: {
    message:
      "{collaboratorName} wasn't able to complete the vouch on your {projectTitle}. Let's list another collaborator.",
    url: '/talent/{publicUrl}/portfolio/{projectId}',
    icon: 'alert',
    actionBtn: 'Add more',
    tag: 'Action needed'
  },
  [notificationTypes.collaboratorNotAnsweredCredit]: {
    message:
      "Dang! We haven't heard back from {collaboratorName}. It's best to list another collaborator on your {gameTitle} credits to move things along.",
    url: '/{profileType}/{publicUrl}/about?credit={creditId}',
    icon: 'alert',
    actionBtn: 'Show me more',
    tag: 'Action needed'
  },
  [notificationTypes.collaboratorNotAnsweredProject]: {
    message:
      "Dang! We haven't heard back from {collaboratorName}. It's best to list another collaborator on your {projectTitle} project to move things along.",
    url: '/{profileType}/{publicUrl}/portfolio/{projectId}',
    icon: 'alert',
    actionBtn: 'Show me more',
    tag: 'Action needed'
  },
  [notificationTypes.jobSaveAsDraft]: {
    message: 'Have you finished editing {jobTitle}? Publish your draft now to start getting qualified applicants!',
    url: '/{profileType}/{publicUrl}/jobs/${jobId}',
    icon: 'edit',
    actionBtn: 'See details',
    tag: ''
  },
  [notificationTypes.jobNewApplicant]: {
    message: 'You have new candidates to review for the {jobTitle} position you posted!',
    url: '/{profileType}/{publicUrl}/jobs/{jobId}/candidates',
    icon: 'community',
    actionBtn: 'See details',
    tag: 'Highlights'
  },
  [notificationTypes.jobUserHired]: {
    message: 'Congrats! {companyName} hired you for the {jobTitle} position. We knew you could do it!',
    url: '/{profileType}/{publicUrl}/jobs/${jobId}',
    icon: '',
    actionBtn: '',
    tag: 'Highlights'
  },
  [notificationTypes.jobCompanyMessaged]: {
    message: '{companyName} messaged you about the {jobTitle} position you applied to! Good luck!',
    url: '/talent/{publicUrl}/conversations',
    icon: '',
    actionBtn: 'See details',
    tag: 'Opportunity'
  },
  [notificationTypes.talentRequestedChanges]: {
    message: '{userName} asked for some changes in your contract for {jobName}.',
    url: `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/{contractId}/edit`,
    icon: '',
    actionBtn: '',
    tag: 'Action needed'
  },
  [notificationTypes.contractSendForApproval]: {
    message: 'Congratulations! 🎊 {userName} has sent you a contract, review it to start working together!',
    url: `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/{contractId}`,
    icon: '',
    actionBtn: '',
    tag: 'Action needed'
  },
  [notificationTypes.contractAccept]: {
    message: '{userName} has signed the contract for {jobName} starting on {startDate}',
    url: `${process.env.REACT_APP_NEXT_BASE_URL}/contracts`,
    icon: '',
    actionBtn: '',
    tag: ''
  }
};

const collaborationAcceptedStatus = {
  statusIcon: 'checkmark-circle',
  statusText: 'Collaboration accepted'
};

const collaborationDeclinedStatus = {
  statusIcon: 'ignore',
  statusText: 'Collaboration declined'
};

export const notificationActionStatusConfig = {
  [`${notificationTypes.collaboratorListedOnCredit}-approved`]: collaborationAcceptedStatus,
  [`${notificationTypes.collaboratorListedOnCredit}-declined`]: collaborationDeclinedStatus,
  [`${notificationTypes.collaboratorListedOnProject}-approved`]: collaborationAcceptedStatus,
  [`${notificationTypes.collaboratorListedOnProject}-declined`]: collaborationDeclinedStatus
};
