import styled from '@emotion/styled';
import Flex from 'common/styles/Flex';
import LimitedTypography from 'components/shared/LimitedTypography';
import Typography from 'components/shared/Typography';
import React from 'react';
import NA from 'images/NA.png';

const ProjectImage = styled.img`
  width: 156px;
  height: 90px;
  border-radius: 8px;
`;

const UserTopProjects = ({ limit, projects }) => {
  if (!projects?.length) {
    return null;
  }

  const limited = projects.slice(0, limit ?? projects.length);


  return (
    <Flex column gap={16}>
      <Typography variant="caption" color="rgba(230, 234, 255, 0.35)">
        top matching portfolio projects
      </Typography>
      <Flex className="wrap" gap={16}>
        {limited.map(({ coverImage, cover, id, _id, name }) => (
          <Flex column key={id ?? _id} gap={8} width={156}>
            <ProjectImage src={cover ?? coverImage?.url ?? NA} />
            <LimitedTypography
              variant="tiny"
              color="rgba(230, 234, 255, 0.6)"
              tooltipId={`tooltip-for-projects-${id}`}
              limit={30}
            >
              {name}
            </LimitedTypography>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default UserTopProjects;
