import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import useAPICall from 'hooks/useAPICall';

import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import Spinner from 'common/Spinner';
import FormSelect from 'common/form/FormSelect';
import Typography from 'components/shared/Typography';

const StyledSubmitRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  align-items: center;
`;

const fields = {
  firstName: { initialValue: '', validate: ['isRequired'] },
  lastName: { initialValue: '', validate: ['isRequired'] },
  email: { initialValue: '', validate: ['isRequired', 'isEmail'] },
  role: { initialValue: '', validate: ['isRequired'] }
};

const permissionOptions = ['super-admin', 'super-editor', 'analyst'].map(permission => ({
  value: permission,
  label: permission
}));

const BackOfficeMemberAdd = ({ closePortal, data: { tableRef }, name }) => {
  const [success, setSuccess] = React.useState(false);

  const { callApi, loading } = useAPICall({
    name: 'invite-backoffice-member',
    url: '/backoffice/backoffice-members/invite',
    method: 'post',
    onSuccess: () => {
      if (tableRef) {
        tableRef.current.refetch();
      }
      setSuccess(true);
    }
  });

  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields,
    callApi: data => Promise.resolve(callApi({ ...data, role: data.role.value }))
  });

  return (
    <TxplModal
      name={name}
      title="Invite new member"
      size={success ? 'small' : 'large'}
      appElement={document.getElementById('root')}
      renderFooter={
        <StyledSubmitRow>
          {loading && <Spinner />}
          {!success && (
            <ContainedButton
              type="button"
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
            >
              CANCEL
            </ContainedButton>
          )}
          {!success && (
            <ContainedButton type="submit" onClick={onSubmit} forceDisplayText disabled={loading}>
              SEND INVITE
            </ContainedButton>
          )}
          {success && (
            <ContainedButton type="button" onClick={closePortal} forceDisplayText>
              DONE
            </ContainedButton>
          )}
        </StyledSubmitRow>
      }
    >
      {!success && (
        <form onSubmit={onSubmit}>
          <FormInput
            label="First name"
            value={values.firstName}
            onChange={onFieldChange('firstName')}
            error={errors.firstName}
          />
          <FormInput
            label="Last name"
            value={values.lastName}
            onChange={onFieldChange('lastName')}
            error={errors.lastName}
          />
          <FormInput label="Email" value={values.email} onChange={onFieldChange('email')} error={errors.email} />
          <FormSelect
            label="Permission"
            value={values.role}
            options={permissionOptions}
            placeholder={'Select an option'}
            onChange={onFieldChange('role')}
            error={errors.role}
          />
        </form>
      )}
      {success && (
        <Typography variant="medium" success>
          The invite has been sent. A new member needs to accept the invite to be added to the team
        </Typography>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('bacOffice-add-team-member')(BackOfficeMemberAdd);
