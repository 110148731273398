import { useState } from 'react';

import chatUserService from 'chatApi/chatUserService';

const useChatUserService = () => {
  const [loading, setLoading] = useState({ createUser: false, getUserConversations: false });
  const createUser = async (userId, data) => {
    try {
      setLoading({ ...loading, createUser: true });
      await chatUserService.createUser(userId, data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading({ ...loading, createUser: false });
    }
  };

  const getUserConversations = async ({ limit, onEnd, startingAfter = '', userId }) => {
    try {
      setLoading({ ...loading, getUserConversations: true });
      let fetchedData = null;
      let allData = [];

      while (fetchedData === null || fetchedData.length !== 0) {
        const { data } = await chatUserService.getUserConversations(
          userId,
          allData[allData.length - 1]?.id || startingAfter,
          limit
        );
        fetchedData = data.data;
        allData = [...allData, ...data.data];
      }

      if (fetchedData && fetchedData.length === 0 && onEnd) {
        onEnd();
      }

      return allData.sort((a, b) => b.lastMessage?.createdAt - a.lastMessage?.createdAt);
    } finally {
      setLoading({ ...loading, getUserConversations: false });
    }
  };

  return {
    createUser,
    getUserConversations,
    loading
  };
};

export default useChatUserService;
