import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background-color: rgba(204, 213, 255, 0.04);
  padding: 24px;
  border-radius: 16px;
`;
const StyledTitle = styled.h3`
  ${commonStyles.ui_heading_3}
  margin-top: 0px;
  ${props => props.marginBottom && `margin-bottom:${props.marginBottom}px;`}
`;
const EditIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(204, 213, 255, 0.11);
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
`;
const StyledContainer = styled.div`
  ${commonStyles.ui_text_medium}
  height: 100%;
  ${props => (props.display ? `display: ${props.display};` : 'display: grid;')}
  ${props => props.display && props.flexDirection && `flex-direction:${props.flexDirection};`}
  ${props => props.display && props.justifyContent && `justify-content: ${props.justifyContent};`}
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 24px;
`;

const BackofficePanel = ({
  children,
  className,
  display,
  flexDirection,
  justifyContent,
  marginBottom,
  onEdit,
  title
}) => (
  <Wrapper className={className}>
    {title && <StyledTitle marginBottom={marginBottom}>{title}</StyledTitle>}
    {onEdit && <EditIcon icon="edit" onClick={onEdit} />}

    <StyledContainer display={display} flexDirection={flexDirection} justifyContent={justifyContent}>
      {children}
    </StyledContainer>
  </Wrapper>
);

export default BackofficePanel;
