import styled from '@emotion/styled';
import { lifeServices } from 'api';
import Frame from 'common/Frame';
import useDragEnd from 'hooks/useDragEnd';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import LifeAddBlockDropdown from '../LifeAddBlockDropdown';
import LifeBlockWrapper from './LifeBlockWrapper';
import LifePhotoGallery from './LifePhotoGallery';
import LifeTextBlock from './LifeTextBlock';
import LifeTextMediaBlock from './LifeTextMediaBlock';
import { LifeBlocksWrapper } from './styled.components';

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  min-width: 0;
`;

const LifeBlocks = ({ categoryId, jobsPage, lives }) => {
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const dispatch = useDispatch();

  const { list, onDragEnd } = useDragEnd({
    items: lives,
    order: 'asc',
    callApi: (blocks, items) => {
      lifeServices
        .changeBlockOrder({
          categoryId,
          companyId,
          blocks
        })
        .then(() => {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'lives',
              update: 'update',
              value: items,
              profileId: companyId
            })
          );
        });
    }
  });

  return (
    <LifeBlocksWrapper className={jobsPage ? 'jobs-page' : ''}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="life-blocks-dnd">
          {provided => (
            <Wrapper {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((life, index) => (
                <Draggable key={life._id} draggableId={life._id} index={index}>
                  {({ dragHandleProps, draggableProps, innerRef }) => (
                    <LifeBlockWrapper
                      innerRef={innerRef}
                      draggableProps={draggableProps}
                      dragHandleProps={dragHandleProps}
                      life={life}
                      jobsPage={jobsPage}
                    >
                      {life.type === 'text' && <LifeTextBlock life={life} />}
                      {life.type === 'text_media' && <LifeTextMediaBlock life={life} />}
                      {life.type === 'image_gallery' && <LifePhotoGallery life={life} />}
                    </LifeBlockWrapper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Wrapper>
          )}
        </Droppable>
      </DragDropContext>

      {hasEditPermission && !jobsPage && (
        <Frame className="center">
          <LifeAddBlockDropdown categoryId={categoryId} />
        </Frame>
      )}
    </LifeBlocksWrapper>
  );
};

export default LifeBlocks;
