import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { pageSize } from 'utils/devices';
import getDisciplinesIconAndColor from 'utils/disciplines';
import AllAdditionalTags from '../AllAdditionalTags';
import useCompanyService from '../hooks/useCompanyService';
import CompanyServiceForm from './CompanyServiceForm';
import { authSelectors } from 'store/auth';
import { selectMyProfile } from 'store/profile/profileSelectors';
import { pluck } from 'ramda';
import useApplicantService from 'hooks/api/useApplicantService';

const StyledContainer = styled.div`
  display: grid;
  grid-row-gap: 40px;
  padding-top: 32px;
  margin: 40px 0px;
  ${props => props.index !== 0 && 'border-top: 2px solid rgba(204, 213, 255, 0.11);'}
  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 2px solid rgba(204, 213, 255, 0.09);
  }
`;
const StyledTitleContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 32px auto 36px;
  grid-column-gap: 16px;
`;
const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 0px !important;
`;
const StyledIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => `background: ${props.backgroundColor}`}
`;
const ArrowIconWrapper = styled.div`
  button {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
  &.collapsed button {
    transform: rotate(0);
  }
`;
const MoreButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  gap: 6px;
`;
const StyledTypography = styled(Typography)`
  width: 85%;
  @media ${pageSize.XS} {
    width: 65%;
  }
`;

const CompanyService = ({
  dragHandleProps,
  index,
  onDelete,
  page = 'services',
  service,
  setCurrentService,
  showDragIcon
}) => {
  const serviceTag = service?.service;
  const hasProfileEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const [showInformation, toggleInformation] = useState(true);
  const { hireApplicant } = useApplicantService();

  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profile = useSelector(profileSelectors.selectProfile);
  const isAuthed = useSelector(authSelectors.isAuthed);
  const activeProfile = useSelector(selectMyProfile);
  const companyOwner = activeProfile?.owner;
  const isCompany = activeProfile?.type === 'company';
  const isDemandAndVetted = isCompany || (activeProfile?.isSupply !== 'supply' && activeProfile?.vetted);
  const shouldShowCreateProposal =
    !hasProfileEditPermission && !isCompanyView && profile.vetted && isAuthed && isDemandAndVetted;
  const myUserProfileList = pluck('userId', useSelector(profileSelectors.selectMyProfileList));
  const isCompanyOwner = myUserProfileList.includes(companyOwner);
  const openCompanyModal = isCompany ? true : false;
  const modalToOpen = isCompanyOwner ? 'company-contract-modal' : 'admin-contract-modal';

  const { errors, initialize, mode, onFieldChange, onSubmit, submitting, updateMode, values } = useCompanyService();

  const { color, icon } = getDisciplinesIconAndColor(serviceTag?.name);

  const handleEdit = () => {
    initialize(service);
    updateMode('edit');
  };

  const handleDelete = () => {
    setCurrentService(service._id);
    onDelete(service);
  };

  const handleCancel = () => {
    updateMode('view');
  };

  const handleCreateProposal = async () => {
    await hireApplicant(profile._id, '', `${service._id}`, response => {
      const url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${response.contractId}/edit`;
      window.location.href = url;
    });
  };

  return (
    <>
      {mode === 'view' ? (
        <StyledContainer index={index}>
          <StyledTitleContainer>
            <StyledIcon backgroundColor={color}>
              <Icon size={16} icon={icon} />
            </StyledIcon>
            <StyledTypography wordBreak="break-all" variant="h2">
              {service.customName ?? serviceTag?.name} Services
            </StyledTypography>
            <MoreButtonWrapper>
              {dragHandleProps && showDragIcon && (
                <ContainedButton {...dragHandleProps} backgroundColor="secondary" icon={'drag-i'} />
              )}
              {hasProfileEditPermission && (
                <DropDown noBorder backgroundColor="rgba(204, 213, 255, 0.11)" width="36px" height="32px">
                  <DropDownItem icon="edit" onClick={handleEdit}>
                    Edit
                  </DropDownItem>
                  <DropDownItem icon="delete" onClick={handleDelete}>
                    Delete
                  </DropDownItem>
                </DropDown>
              )}
              <ArrowIconWrapper className={`${showInformation ? 'collapsed' : ''}`}>
                <ContainedButton
                  backgroundColor="transparent"
                  icon="arrow-up"
                  onClick={() => toggleInformation(toggled => !toggled)}
                />
              </ArrowIconWrapper>
            </MoreButtonWrapper>
          </StyledTitleContainer>
          {showInformation && (
            <>
              <Typography wordBreak="break-all" variant="paragraph">
                {service.serviceOverview}
              </Typography>
              {shouldShowCreateProposal && (
                <ContainedButton
                  data={{
                    job: null,
                    jobId: null,
                    serviceId: `${service._id}`,
                    isCompany,
                    isCompanyOwner,
                    activeProfile,
                    applicant: profile
                  }}
                  onClick={event => !isCompany && handleCreateProposal(event)}
                  opens={openCompanyModal && modalToOpen}
                  icon="jobs"
                >
                  Make me a proposal
                </ContainedButton>
              )}

              <StyledAllAdditionalTags
                page={page}
                data={service}
                isEditMode={false}
                hasDragPermission={hasProfileEditPermission}
                order={['skills', 'platforms', 'tools', 'genres', 'tags']}
                hideEmpty
              />
            </>
          )}
        </StyledContainer>
      ) : (
        <CompanyServiceForm
          showDragIcon={showDragIcon}
          dragHandleProps={dragHandleProps}
          values={values}
          errors={errors}
          onFieldChange={onFieldChange}
          submitting={submitting}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          mode={mode}
        />
      )}
    </>
  );
};

export default CompanyService;
