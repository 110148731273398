import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { creditVettingOutcomes, vettingOutcomes, vettingStatuses } from '../../constants';
import { vettingService } from '../../api';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = (initialStatus, initialOutcome, additionalComment, element) => ({
  status: { initialValue: vettingStatuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  outcome: { initialValue: vettingOutcomes.find(curr => curr.value === initialOutcome), validate: ['isRequired'] },
  additionalComment: { initialValue: additionalComment },
  element: { initialValue: element }
});

const transformParams = params => ({
  ...params,
  outcome: params.outcome.value,
  status: params.status.value
});

const VettingOutcomeModal = ({ closePortal, data, name }) => {
  const { additionalComment, element, id, outcome, status } = data;

  const callApi = params => {
    const apiParams = transformParams(params);

    const requestPromise = vettingService.updateVettingStatus(id, { ...apiParams });

    return requestPromise.then(({ data: { success } }) => {
      (success === true && data.onSuccess()) || closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(status, outcome, additionalComment, element),
    callApi
  });

  return (
    <TxplModal
      title="Outcome"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="vettingOutcomeModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="vettingOutcomeModal">
        <FormSelect
          label="Vetting status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={vettingStatuses}
          autoFocus
        />

        <br />
        <br />

        <FormSelect
          label="Outcome"
          value={values.outcome}
          onChange={onFieldChange('outcome')}
          options={creditVettingOutcomes}
        />

        <br />
        <br />

        <FormTextarea
          label="Additional comments"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('vetting-outcome-modal')(VettingOutcomeModal);
