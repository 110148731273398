import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { pageSize } from 'utils/devices';
import logoIcon from 'images/page-header-thexplace-logo.svg';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';

const StyledContainer = styled.div`
  margin: auto;
  margin-top: 105px;

  @media ${pageSize.XS} {
    content: 'XS';
    width: auto;
    margin-top: 80px;
    margin-left: 16px;
    margin-right: 16px;
  }

  @media ${pageSize.S} {
    content: 'S';
    width: 560px;
  }

  @media ${pageSize.M} {
    content: 'M';
    width: 585px;
  }

  @media ${pageSize.L} {
    content: 'L';
    width: 710px;
    margin-top: 105px;
  }
`;

const StyledLogo = styled.img`
  display: block;
  margin: auto;
  margin-top: 56px;
  margin-bottom: 146px;

  @media ${pageSize.XS} {
    margin-bottom: 104px;
  }

  @media ${pageSize.S} {
    margin-bottom: 140px;
  }

  @media ${pageSize.M} {
    margin-bottom: 140px;
  }
`;

const StyledBackgroundImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #6655cc 0%, rgba(74, 41, 204, 0) 100%);

  @media ${pageSize.XS} {
    width: 389px;
    height: 389px;
    top: -198px;
  }

  @media ${pageSize.S} {
    width: 971px;
    height: 971px;
    top: -650px;
  }

  @media ${pageSize.M} {
    width: 1341px;
    height: 1341px;
    top: -1023px;
  }

  @media ${pageSize.L} {
    width: 1572px;
    height: 1572px;
    top: -1200px;
  }
`;

const StyledHighlightedText = styled.span`
  color: rgb(74, 41, 204);
  margin-left: 5px;
  cursor: pointer;
`;

const DeleteAccountSuccess = () => {
  const history = useHistory();

  const onStartFromBeginning = () => history.push('/login');

  return (
    <>
      <StyledBackgroundImage />

      <StyledLogo src={logoIcon} />

      <StyledContainer>
        <Icon icon="like-colored" size={40} color="linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)" />

        <Typography variant="h2" mt={24} mb={24}>
          WE WILL BE HAPPY TO SEE YOU AGAIN!
        </Typography>

        <Typography variant="paragraph">
          We are sad to see you leaving but we will reserve your special place in our hearts in case you come back.
          Anyway, we did what you asked and your account is now deleted from TheXPlace. In case if there can be another
          chance,{' '}
          <StyledHighlightedText onClick={onStartFromBeginning}>start from the beginning.</StyledHighlightedText>
        </Typography>
      </StyledContainer>
    </>
  );
};

export default DeleteAccountSuccess;
