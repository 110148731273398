import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';
import { pageSize } from 'utils/devices';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 20px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;
export const StyledImageContainer = styled.img`
  border-radius: 8px;
  height: 100%;
  max-height: 192px;
  max-width: 134px;
  object-fit: cover;
  @media ${pageSize.XS} {
    margin-bottom: 25px;
    max-height: 90px;
    max-width: 63px;
  }
`;
export const StyledGameCardImage = styled.div`
  width: 134px;
  height: 192px;
  background-color: #1a1b1e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media ${pageSize.XS} {
    width: 63px;
    height: 90px;
    margin-bottom: 16px;
  }
`;
export const StyledCredit = styled.div`
  display: grid;
  grid-template-rows: 1fr 5fr;
  gap: 15px;
  @media ${pageSize.XS} {
    grid-template-rows: 1fr;
  }
`;
export const StyledCreditTitleContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: row;
  gap: 10px;
  margin-right: 75px;
  align-items: baseline;
  @media ${pageSize.XS} {
    display: none;
  }
`;
export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  color: rgba(230, 234, 255, 0.35);
  letter-spacing: 0.04em;
  font-style: normal;
  @media ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    margin-left: 0;
  }
`;
export const StyledTypographyText = styled(Typography)`
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;
export const ReStyledTypography = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.35);
  width: 100px;
`;
export const CreditHeader = styled.div`
  display: flex;
  margin-right: 36px;
  @media ${pageSize.XS} {
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    margin-bottom: 16px;
  }
`;
export const StyledCreditTitleContainerShow = styled.div`
  display: none;
  margin-left: 16px;
  @media ${pageSize.XS} {
    display: flex;
    align-items: baseline;
    gap: 10px;
    width: 80%;
    word-break: break-all;
  }
`;
export const StyledCreditBody = styled.div`
  display: grid;
  gap: 16px;
  ${props => (props.mediumView ? 'grid-template-rows: 2fr 1fr;' : 'grid-template-columns: 1fr 1fr;')}
  @media ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;
export const TypographyWrapper = styled.div`
  margin-right: 16px;
  width: calc(30vw - 122px);
  @media ${pageSize.M} {
    width: calc(30vw - 185px);
  }
  @media ${pageSize.XS} {
    width: calc(70vw - 140px);
  }
  @media ${pageSize.L} {
    width: calc(20vw - 175px);
  }
  @media ${pageSize.XL} {
    width: calc(20vw - 225px);
  }
`;
export const TypographyWrapperTile = styled.div``;
export const StyledCreditInfoContainer = styled.div`
  display: flex;
`;
export const StyledCollaboratorsContainer = styled.div`
  ${props =>
    props.mediumView
      ? 'border-top: 2px solid rgba(204, 213, 255, 0.11);'
      : 'border-left: 2px solid rgba(204, 213, 255, 0.11);'}
  ${props => props.mediumView && 'padding-top: 10px;'}
  @media ${pageSize.M} ,${pageSize.S},${pageSize.XS} {
    border-top: 2px solid rgba(204, 213, 255, 0.11);
    border-left: none;
  }
`;
export const StyledCollaboratorsView = styled.div`
  display: flex;
  flex-flow: column;
  @media ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    margin-top: 16px;
  }
`;
