import React from 'react';

import { StyledColumn, StyledInnerColumn } from 'components/Table/TableStyles';

const Column = ({ value }) => (
  <StyledColumn>
    <StyledInnerColumn>{value}</StyledInnerColumn>
  </StyledColumn>
);

export default Column;
