import styled from '@emotion/styled';
import FormRadiobutton from 'common/form/FormRadiobutton';

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ScopeFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ScopeDescription = styled.h2`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
  margin: 0 0 24px 0;
`;

export const ScopeRadioButtons = styled.div``;

export const ModalActionsButtons = styled.div`
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 10px;
  }
`;
export const StyledRadioButton = styled(FormRadiobutton)`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: black;
  margin-bottom: 16px !important;
`;
