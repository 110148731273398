import React, { useState } from 'react';
import withRenderPortal from 'hocs/withRenderPortal';

import useViewport from 'hooks/useViewport';
import useCollaborationsService from 'hooks/useCollaboration';

import styled from '@emotion/styled';
import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';

const CancelButton = styled(ContainedButton)`
  margin: 0 3px;
`;

const Modal = styled(TxplModal)``;

const reasons = [
  { value: 'unknown', label: "I don't know this talent" },
  { value: 'wrong info', label: 'This talent is providing wrong information about their role/project' },
  { value: 'other', label: 'Other' }
];

const DeclineCollaboration = ({ closePortal, data, name }) => {
  const { acceptDeclineCollaboration } = useCollaborationsService();

  const { id, onSuccess } = data;
  const { isXS } = useViewport();
  const [reason, setReason] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    if (reason === '') return setError('Please, provide us with a reason for your choice');
    setLoading(true);
    await acceptDeclineCollaboration(id, {
      action: 'declined',
      reason: reason.label,
      comment: additionalInfo
    });
    if (onSuccess) onSuccess();
    setLoading(false);
    setTimeout(() => {
      closePortal();
    }, 600);
  };

  const handleReasonChange = e => {
    setError(false);
    setReason(e);
  };

  return (
    <Modal
      name={name}
      title="Share your comments"
      appElement={document.getElementById('root-modal')}
      allowLine
      titleMB={16}
      addPaddingTopBig
      padding="56px 0 82px"
      renderFooter={
        <>
          {loading && <Spinner />}
          <CancelButton forceDisplayText backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal}>
            CANCEL
          </CancelButton>
          <ContainedButton forceDisplayText form="declineCollaboration" type="submit">
            SUBMIT
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={handleSubmit} id="declineCollaboration">
        <FormSelect
          label="Can you share more details to help us understand your choice?"
          placeholder="Select your reason"
          required
          noHintText
          error={error}
          value={reason}
          options={reasons}
          onChange={handleReasonChange}
          mt={isXS && 56}
          indicatorStyles={{ color: '#FFFFFF' }}
        />
        <FormTextarea
          label="Please provide additional information"
          maxLength="600"
          mt={56}
          noFlexRow
          noHintText
          value={additionalInfo}
          onChange={e => setAdditionalInfo(e.target.value)}
        />
      </form>
    </Modal>
  );
};

export default withRenderPortal('decline-collaboration')(DeclineCollaboration);
