import React, { useState } from 'react';
import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';

const MoreButton = styled.button`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.35);
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    color: white;
  }
`;

const MoreLanguages = ({ languages = [], initialLength = 0 }) => {
  const [userLanguages, setUserLanguages] = useState(
    languages
      ?.slice(0, initialLength)
      .map(lang => lang.name)
      .join(', ')
  );
  const [more, setMore] = useState(true);

  const allLanguages = languages && languages.map(lang => lang.name).join(', ');

  const handleMoreClick = () => {
    setMore(false);
    setUserLanguages(allLanguages);
  };

  return (
    <>
      Speaks&nbsp;
      {languages?.length <= initialLength ? (
        allLanguages
      ) : (
        <>
          {userLanguages}
          {more && ', '}
          <MoreButton onClick={handleMoreClick}>{more && `+${languages?.length - initialLength} more`}</MoreButton>
        </>
      )}
    </>
  );
};

export default MoreLanguages;
