import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

import Typography from 'components/shared/Typography';
import { pageSize } from 'utils/devices';
import ContainedButton from './ContainedButton';

const StyledContainedButton = styled(ContainedButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const StyledTooltipContainer = styled.div`
  background-color: black;
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  width: 204px;
  z-index: 300;
  @media ${pageSize.XS} {
    margin-left: 20px;
  }
`;

const StyledIndicator = styled.div`
  width: 17px;
  height: 17px;
  transform: translate(-50%, 0px) rotate(45deg);
  background-color: black;
  position: absolute;
  bottom: -9px;
  left: 50%;
`;

const StyledTypography = styled(Typography)`
  color: rgba(230, 234, 255, 0.35);
`;

const getCoords = triggerRef => {
  if (!triggerRef.current) return {};

  const triggerCoords = triggerRef.current.getBoundingClientRect();

  return {
    top: triggerCoords.top + window.scrollY - 100,
    left: triggerCoords.left - 90
  };
};

const GameNotFoundTooltip = ({ className }) => {
  const [showTooltip, toggleTooltip] = React.useState(false);
  const buttonRef = React.useRef();
  const contentRef = React.useRef();

  const toggle = () => toggleTooltip(!showTooltip);

  return (
    <>
      <StyledContainedButton
        className={className}
        icon="question"
        backgroundColor="transparent"
        iconColor="rgba(204, 213, 255, 0.11)"
        onMouseEnter={toggle}
        onMouseLeave={toggle}
        buttonRef={buttonRef}
      />

      {showTooltip &&
        ReactDOM.createPortal(
          <StyledTooltipContainer ref={contentRef} style={getCoords(buttonRef, contentRef)}>
            <StyledIndicator />
            <StyledTypography variant="small">
              The official cover art will appear once the game is in the public video game database.
            </StyledTypography>
          </StyledTooltipContainer>,
          document.getElementById('root-modal')
        )}
    </>
  );
};

export default GameNotFoundTooltip;
