import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const ConfirmModal = ({ closePortal, data, name }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const onDelete = async () => {
    if (data.onConfirm) {
      setIsLoading(true);
      try {
        await data.onConfirm();
      } finally {
        setIsLoading(false);
        closePortal();
      }
    } else {
      closePortal();
    }
  };

  return (
    <TxplModal
      title={data.title}
      name={name}
      appElement={document.getElementById('root')}
      hideCloseIcon
      size="small"
      nonFullScreenOnMobile
      padding="55px 0 40px"
      XSpadding="0 0 24px"
      renderFooter={
        <StyledButtonsContainer>
          {isLoading && <Spinner />}
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton backgroundColor="#FF5151" onClick={onDelete}>
            Delete
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <Typography variant="medium">{data.description}</Typography>
    </TxplModal>
  );
};

export default withRenderPortal('confirm-modal')(ConfirmModal);
