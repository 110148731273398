import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';

import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';

import BackofficeSubMenuItem from './BackofficeSubMenuItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 240px;
  max-width: 240px;
  height: 48px;
  border-top: 1px solid rgba(204, 213, 255, 0.11);
  color: rgba(230, 234, 255, 0.35);
  ${props => props.isSelected && 'background: rgba(204, 213, 255, 0.11);'}

  &:hover {
    cursor: pointer;
    background: rgba(204, 213, 255, 0.04);
  }
`;

const StyledIcon = styled(Icon)`
  color: rgba(230, 234, 255, 0.35);
  margin: 16px;
  margin-right: 12px;
`;

const Text = styled.div`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.35);
  ${props => props.isSelected && 'color: white'};
  flex: 1;
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled.div`
  margin: 16px;
`;

const SubMenuWrapper = styled.div`
  ${props => (props && props.isOpen ? '' : 'display: none;')}
`;

const BackofficeMenuItem = ({ menuItem }) => {
  const { icon, label, subMenuItems, url } = menuItem || {};
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isSelected = location.pathname === url;

  useEffect(() => {
    if (location.pathname.includes(url)) {
      setIsOpen(true);
    }
  }, [location, url]);

  const hasSubItems = subMenuItems && subMenuItems.length > 0;

  const onMenuItemClick = () => {
    setIsOpen(!isOpen);
    if (url) history.push(url);
  };

  return (
    <>
      <Wrapper isSelected={isSelected} onClick={onMenuItemClick}>
        <StyledIcon icon={icon} color={isSelected ? 'white' : 'rgba(230, 234, 255, 0.35)'} />
        <Text isSelected={isSelected}>{label}</Text>
        {hasSubItems && (
          <ArrowIcon>
            <Icon icon={isOpen ? 'arrow-up' : 'arrow-down'} color="rgba(230, 234, 255, 0.35)" />
          </ArrowIcon>
        )}
      </Wrapper>
      <SubMenuWrapper isOpen={isOpen}>
        {/* {children} */}
        {subMenuItems &&
          subMenuItems.map(subMenu => (
            <BackofficeSubMenuItem
              key={subMenu.url}
              style={subMenu.style}
              icon={subMenu.icon}
              label={subMenu.label}
              url={subMenu.url}
            />
          ))}
      </SubMenuWrapper>
    </>
  );
};

export default BackofficeMenuItem;
