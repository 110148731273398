import React, { useState } from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import FormTextarea from 'common/form/FormTextarea';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const ServiceOverviewModal = ({ closePortal, data, name, onAdd }) => {
  const [serviceOverview, updateServiceOverview] = useState(data.serviceOverview);

  const handleAddService = () => {
    onAdd(serviceOverview);
    closePortal();
  };

  return (
    <TxplModal
      name={name}
      appElement={document.getElementById('root-modal')}
      size="large"
      padding="0 0 80px"
      addPaddingTop
      XSaddPaddingTop="14px"
      renderFooter={
        <StyledActionsContainer>
          <ContainedButton onClick={closePortal} backgroundColor="secondary">
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleAddService}>Save</ContainedButton>
        </StyledActionsContainer>
      }
    >
      <FormTextarea
        label="Service overview"
        hintText="Describe your workflow and uniqueness"
        maxLength={600}
        value={serviceOverview}
        onChange={event => updateServiceOverview(event.target.value)}
      />
    </TxplModal>
  );
};

export default withRenderPortal('service-overview-modal')(ServiceOverviewModal);
