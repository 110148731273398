import {
  CLEAR_JOBS,
  DELETE_JOBS,
  SET_JOBS,
  UPDATE_JOBS,
  UPDATE_JOBS_PAGINATION,
  UPDATE_JOB_PROPERTY,
  UPDATE_UNREAD_JOBS_COUNTERS,
} from './jobsActionTypes';

export const updateJob = ({
  jobType = 'unstaffedJobs', // unstaffedJobs || savedJobs || appliedJobs || recommendedJobs
  jobs,
  profileId // Array || Object
}) => ({
  type: UPDATE_JOBS,
  payload: {
    jobType,
    profileId,
    jobs
  }
});

export const setJobs = ({
  jobType = 'unstaffedJobs', // unstaffedJobs || savedJobs || appliedJobs || recommendedJobs
  jobs,
  profileId // Array || Object
}) => ({
  type: SET_JOBS,
  payload: {
    jobType,
    profileId,
    jobs
  }
});

export const updateJobProperty = ({ jobId, jobType, profileId, property, value }) => ({
  type: UPDATE_JOB_PROPERTY,
  payload: {
    profileId,
    jobId,
    property,
    value,
    jobType
  }
});

export const deleteJob = ({
  job, // unstaffedJobs || savedJobs || appliedJobs || recommendedJobs
  jobType = 'unstaffedJobs',
  profileId // Object || JobId
}) => ({
  type: DELETE_JOBS,
  payload: {
    jobType,
    profileId,
    job
  }
});

export const clearJobs = () => ({
  type: CLEAR_JOBS
});

export const updateJobPagination = ({
  jobType = 'unstaffedJobs', // unstaffedJobs || savedJobs || appliedJobs || recommendedJobs
  pagination,
  profileId
}) => ({
  type: UPDATE_JOBS_PAGINATION,
  payload: {
    jobType,
    profileId,
    pagination
  }
});

export const updateJobsCounters = (payload) => ({
  type: UPDATE_UNREAD_JOBS_COUNTERS,
  payload
});
