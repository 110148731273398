import ContainedButton from 'common/ContainedButton';
import styled from '@emotion/styled';

export const Title = styled.p`
  color: #e5e8ffa6;
  text-align: center;
`;
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 0px 30px;
  justify-content: center;
  align-items: center;
`;
export const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 57px;
`;

export const StyledContainedButton = styled(ContainedButton)`
  button {
    padding: 9px 16px;

    & .button-text {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
`;
