import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${props =>
    props.activeTab === 0
      ? 'linear-gradient(83.66deg, #8a00ac 0%, #cc00ff 100%)'
      : 'linear-gradient(83.66deg, #483CCE 0%, #5B4DFF 100%)'};
  padding: 0px 20px 0px 20px;
`;
export const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Body = styled.div`
  display: flex;
  height: 300px;
`;

export const Title = styled.h3`
  color: white;
`;

export const Button = styled.button`
  background: linear-gradient(90deg, #493dd0 -107.69%, #594bfa -54.12%, #493dd0 0%);
  height: 38px;
  width: 100%;
  cursor: pointer;
  color: white;
  left: 0px;
  top: 0px;
  border: 0px;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    cursor: auto;
  `}
`;

export const Tabs = styled.div`
  display: flex;
  gap: 20px;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${props => (props.active ? 'white' : '#E5E8FFA6')};
  border-bottom: 2px solid;
  border-color: ${props => (props.active ? 'white' : 'transparent')};
  cursor: pointer;
  padding: 0px 0px 20px 0px;
`;
export const Content = styled.div`
  padding: 10px 20px 10px 20px;
  width: 100%;
  ${props => (props.active ? '' : 'display:none')}
`;
