import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import Typography from 'components/shared/Typography';
import withRenderPortal from 'hocs/withRenderPortal';
import useJobServices from 'hooks/api/useJobServices';
import React from 'react';
import { useDispatch } from 'react-redux';
import { profileActions } from 'store/profile';

function JobPublishModal({ closePortal, data, name }) {
  const { jobId, profileId } = data;
  const dispatch = useDispatch();
  const {
    changeJobStatus,
    loading: { loadingChangeStatus }
  } = useJobServices();

  const callback = () => {
    dispatch(
      profileActions.updateProfileProperty({
        property: 'jobs',
        updateType: 'update',
        value: { _id: jobId, status: 'published' },
        profileId
      })
    );
    closePortal();
  };

  const handleHide = () => {
    changeJobStatus(profileId, jobId, 'published', callback);
  };

  return (
    <TxplModal
      title="Publish this job?"
      titleMB="24"
      name={name}
      size="small"
      padding="56px 0 56px"
      noPaddingTop
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loadingChangeStatus && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal}>
            cancel
          </ContainedButton>
          <ContainedButton onClick={handleHide}>Publish</ContainedButton>
        </>
      }
    >
      <Typography variant="medium">Are you sure you want to publish this job?</Typography>
    </TxplModal>
  );
}

export default withRenderPortal('job-publish-modal')(JobPublishModal);
