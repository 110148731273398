import React from 'react';
import PropTypes from 'prop-types';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import Spinner from 'common/Spinner';
import withRenderPortal from 'hocs/withRenderPortal';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const LoadingModal = ({ data, name }) => {
  return (
    <TxplModal
      title={data.title}
      name={name}
      appElement={document.getElementById('root')}
      hideCloseIcon={true}
      size="small"
    >
      <Wrapper>
        <Spinner />
        <Typography variant="medium">{data.description}</Typography>
      </Wrapper>
    </TxplModal>
  );
};

LoadingModal.propTypes = {
  closePortal: PropTypes.func,
  data: PropTypes.object,
  name: PropTypes.string
};

export default withRenderPortal('loading-modal')(LoadingModal);
