import { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

import { updateCurrentUserProperty } from 'store/auth/authActions';
import userService from '../api/userService';
import useCollaborationsService from 'hooks/useCollaboration';

const useIDVerification = () => {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({ openStripeModal: false, getCurrentVerification: false });

  const { fetchCollaborations } = useCollaborationsService();

  const openStripeModal = async () => {
    try {
      setLoading({ ...loading, openStripeModal: true });
      const { data } = await userService.requestVerification();
      setLoading({ ...loading, openStripeModal: false });
      const res = await stripe.verifyIdentity(data.data?.client_secret);
      if (!res.error) {
        dispatch(updateCurrentUserProperty({ property: 'verificationStatus', value: 'pending' }));
      }
    } catch (err) {
      console.log('err', err);
      setLoading({ ...loading, openStripeModal: false });
    }
  };

  const getCurrentVerification = async () => {
    try {
      setLoading({ ...loading, getCurrentVerification: true });
      const { data } = await userService.currentVerification();

      let status = 'pending';
      if (data.data.status === 'requires_input') {
        status = data.data.isThirdAttempt ? 'failed' : 'failed-retry';
      }

      if (data.data.status === 'verified') {
        status = 'verified';
        const { vouchedAsUnverified, vouchedCollaborators } = await fetchCollaborations(0, true);

        if (vouchedAsUnverified && vouchedCollaborators === 1) {
          localStorage.setItem('showIDVerifiedModal', true);
        }
      }

      dispatch(updateCurrentUserProperty({ property: 'verificationStatus', value: status }));
      return data;
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading({ ...loading, getCurrentVerification: false });
    }
  };

  return {
    openStripeModal,
    getCurrentVerification,
    loading
  };
};

export default useIDVerification;
