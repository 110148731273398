import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

const selectDomain = state => state.tags;

export const selectTags = createSelector([selectDomain], state => state.data);

export const selectAllTags = createSelector([selectDomain], state => state);

export const selectTagsByType = type => createSelector([selectDomain], state => state[type]);

export const selectTagsLoading = createSelector([selectDomain], state => state.loading);

export const selectTagName = tagId => createSelector([selectDomain], state => get(`${tagId}.name`, state.data));
