import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import { isBackOffice, isFullPage } from 'utils';
import { portalActions } from 'store/portals';
import { isLoggedInSelector } from 'store/selectors/currentUser';
import LogoImage from 'images/page-header-thexplace-logo.svg';
import useViewport from 'hooks/useViewport';
import ContainedButton from './ContainedButton';
import PageHeaderUser from './PageHeaderUser';
import FormSearch from './form/FormSearch';
import HeaderMobile from './HeaderMobile/HeaderMobile';
import PageHeaderGuest from './PageHeaderGuest';
import Flex from './styles/Flex';
import PageHeaderLinks from './PageHeaderLinks';
import redirectToApp2 from 'utils/redirectToApp2';

const HeaderGuestWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledHeaderContainer = styled.div`
  max-width: 1920px;
  background-color: #111112;
`;
const StyledContainedButton = styled(ContainedButton)`
  margin-right: 8px;
`;
const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled.div`
  ${commonStyles.ui_text_small}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1350px;
  margin: 0px auto;
  height: 80px;
  font-weight: 600;
  text-transform: uppercase;
  box-sizing: border-box;
  @media ${pageSize.XL} {
    position: relative;
  }
  @media ${pageSize.L} {
    padding: 0 54px;
  }
  @media ${pageSize.M} {
    padding: 0 54px;
  }
  @media ${pageSize.S} {
    padding: 0 58px;
  }
  @media ${pageSize.XS} {
    padding: 0 28px;
  }
`;
const Logo = styled.img`
  width: 154px;
  cursor: pointer;
  @media ${pageSize.XS} {
    width: 130px;
  }
`;

const PageHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { isS, isXS } = useViewport();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('login')) {
      dispatch(portalActions.openPortal({ name: 'login' }));
    }
  }, [location, dispatch]);

  useEffect(() => {
    const { pathname } = location;

    switch (true) {
      case pathname === '/':
        document.body.setAttribute('style', 'max-width: 100%');
        break;

      case pathname.includes('backoffice'):
        document.body.setAttribute('style', 'max-width: 100%');
        break;

      case new RegExp(/portfolio$/).test(pathname):
      case pathname.includes('talent'):
      case pathname.includes('talents'):
      case pathname.includes('company'):
      case pathname.includes('home'):
      case pathname.includes('jobs/search'):
      case pathname.includes('peoples/search'):
        document.body.setAttribute('style', 'max-width: 1920px;');
        break;

      default:
        document.body.removeAttribute('style');
    }
  }, [location]);

  if (isFullPage(location.pathname)) {
    return null;
  }

  if (isBackOffice(location.pathname)) {
    return null;
  }

  if (isXS) {
    return (
      <HeaderMobile
        page={location.pathname}
        value={searchValue}
        icon="search"
        onChange={e => {
          setSearchValue(e.target.value);
        }}
      />
    );
  }

  const shouldShowSearch = isLoggedIn && !isS && !isXS;

  return (
    <StyledHeaderContainer>
      <Header>
        <HeaderInnerWrapper>
          <Flex
            mr={24}
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}`;
            }}
          >
            <Logo src={LogoImage} alt="#" data-testid="logo-header" />
          </Flex>
          {shouldShowSearch && (
            <FormSearch
              value={searchValue}
              icon="search"
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              data-testid="search-header"
            />
          )}
        </HeaderInnerWrapper>

        {!isLoggedIn && (
          <HeaderGuestWrapper>
            <PageHeaderGuest />
            <StyledContainedButton
              onClick={() =>
                redirectToApp2(
                  `/auth/login${
                    location.pathname ? `?redirect=${process.env.REACT_APP_BASE_URL}${location.pathname}` : ''
                  }`
                )
              }
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              data-testid="sign-in-header"
            >
              Sign in
            </StyledContainedButton>
            <ContainedButton
              onClick={() => redirectToApp2('/onboarding')}
              color="#111112"
              backgroundColor="#00FF00"
              forceDisplayText
              data-testid="join-header"
            >
              Join
            </ContainedButton>
          </HeaderGuestWrapper>
        )}

        {isLoggedIn && (
          <>
            {!isS && <PageHeaderLinks searchValue={searchValue} setSearchValue={setSearchValue} />}
            <PageHeaderUser searchValue={searchValue} setSearchValue={setSearchValue} />
          </>
        )}
      </Header>
    </StyledHeaderContainer>
  );
};

export default PageHeader;
