import React from 'react';
import Icon from 'common/Icon';
import * as Styled from './StyledStartConversation';

const StartConversation = ({ setNewMessageMode }) => (
  <Styled.Container>
    <Icon icon="conversation_group" size={64} color="rgba(221, 226, 255, 0.2)" hoverColor="rgba(221, 226, 255, 0.2)" />
    <Styled.Text>Start a new conversation</Styled.Text>
    <Styled.NewMessageButton icon="plus" onClick={() => setNewMessageMode(true)}>
      new message
    </Styled.NewMessageButton>
  </Styled.Container>
);

export default StartConversation;
