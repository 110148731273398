import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

const selectDomain = state => state.applicants;

export const selectApplicants = createSelector([selectDomain], state => Object.values(get(`applicants`, state) ?? {}));

export const selectRejectedApplicants = createSelector([selectDomain], state =>
  Object.values(get(`applicants`, state) ?? {}).filter(it => it.rejectedApplicant)
);

export const selectNonRejectedUnsavedApplicants = createSelector([selectDomain], state =>
  Object.values(get(`applicants`, state) ?? {}).filter(
    it => !it.rejectedApplicant && !it.savedApplicant && !it.hiredApplicant
  )
);

export const selectHiredApplicants = createSelector([selectDomain], state =>
  Object.values(get(`applicants`, state) ?? {}).filter(it => it.hiredApplicant)
);

export const selectSavedApplicants = createSelector([selectDomain], state =>
  Object.values(get(`applicants`, state) ?? {}).filter(it => it.savedApplicant)
);

export const selectNonSavedHiredApplicants = createSelector([selectDomain], state =>
  Object.values(get(`applicants`, state) ?? {}).filter(it => !it.savedApplicant && !it.hiredApplicant)
);
