import React from 'react';
import Icon from 'common/Icon';
import * as Styled from './StyledSplashForm';

const MailSentMessage = ({ email }) => (
  <Styled.SuccessMessage>
    <Icon icon="checkmark-circle" size={40} color="#00FF00" noFill />
    <Styled.SuccessMessageText>
      Password reset link has been sent to&nbsp;
      <Styled.EmailAdress>{email}</Styled.EmailAdress>. Check your email for instructions (be sure to check your spam
      folder!)
    </Styled.SuccessMessageText>
  </Styled.SuccessMessage>
);

export default MailSentMessage;
