import styled from '@emotion/styled';

export const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
export const StyledText = styled.span`
  width: 440px;
  height: fit-content;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
`;
