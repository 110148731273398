import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import TxplModal from 'common/TxplModal';
import Typography from 'components/shared/Typography';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${pageSize.XS} {
    padding-bottom: 104px;
  }
`;
export const RightSide = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
export const ForgotPasswordLabel = styled.span`
  display: inline-block;
  cursor: pointer;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.35);
  text-decoration: underline;
`;
export const StyledTypography = styled(Typography)`
  margin-bottom: 18px;
  @media ${pageSize.XS} {
    margin-bottom: 16px;
    margin-top: -22px;
    padding-top: 0px;
  }
`;
export const StyleTypography = styled(Typography)`
  margin-bottom: 19px;
  @media ${pageSize.XS} {
    margin-bottom: 16px;
  }
`;
export const Spacer = styled.div`
  border: 1px solid rgba(204, 213, 255, 0.11);
  margin: 32px auto 16px auto;
  @media ${pageSize.XS} {
    margin: 32px auto;
  }
`;
export const Modal = styled(TxplModal)`
  width: 520px !important;
  max-width: 520px !important;
`;

export const NavigateTo = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
