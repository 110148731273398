import { authService } from 'api';
import { get } from 'lodash/fp';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiCallActions } from 'store/apiCall';
import { authActions } from 'store/auth';
import { pendoService } from 'services/pendoService';
import { cookieAuthName, getCookie, getDecodedIdToken } from 'utils/cookies';

const AutoLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const autoLogin = useCallback(() => {
    const refreshToken = getCookie(`${cookieAuthName}.rt`);
    if (!refreshToken) {
      dispatch(apiCallActions.sendRequestSuccess({ name: 'currentUser' }));
      return;
    }
    dispatch(apiCallActions.sendRequest({ name: 'currentUser' }));
    authService
      .autoLogin({})
      .then(response => {
        const user = get('data.user', response);
        const talent = get('data.talent', response);
        const company = get('data.company', response);

        if (user) {
          dispatch(authActions.login(user));

          const decoded = getDecodedIdToken();
          if (!decoded.basicInfoComplete) {
            window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}/onboarding`;
            return;
          }

          // Service for analytics
          pendoService.initializePendoService({ user, talent, company });
        }
      })
      .catch(() => {
        history.push('/login');
      })
      .finally(() => {
        dispatch(apiCallActions.sendRequestSuccess({ name: 'currentUser' }));
      });
  }, [dispatch, history]);

  useEffect(() => {
    // the first time that app loads
    autoLogin();
    const interval = setInterval(() => {
      autoLogin();
      // 5 minutes before the token expires
    }, 1000 * 55 * 60);

    return () => clearInterval(interval);
  }, []);

  return null;
};

export default AutoLogin;
