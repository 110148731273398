import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import Icon from 'common/Icon';

const slideUp = keyframes`
    from {
      height: 0px;
    }
  
    to {      
      height: 400px;      
    }
  `;

export const Card = styled.div`
  display: ${props => (props.open === false ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background-color: #18191d;
  animation: ${props => (props.animation && props.open ? slideUp : 'none')} 0.3s linear;
`;

export const Footer = styled.div`
  display: flex;
  padding: 0px 20px 0px 20px;
  gap: 10px;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid rgba(230, 234, 255, 0.15);
`;

export const FooterDescription = styled.p`
  color: rgba(230, 234, 255, 0.35);
  font-size: 13px;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 16px;
`;

export const Link = styled.a`
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;
`;
