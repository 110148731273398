import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Icon from 'common/Icon';
import commonStyles from 'utils/common-styles';
import isNumber from 'lodash/isNumber';
import { pageSize } from 'utils/devices';

const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;

  ${({ stretchOnXs }) =>
    stretchOnXs &&
    `
        @media ${pageSize.XS} {
            width: 100%;
        }
    `}
`;
const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
    background: ${props => (props.isOpen && !props.backgroundColor ? '#1A1B1E' : '#111112')};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`};
  border: 2px solid ${({ isOpen }) => (isOpen ? '#383a43' : '#26272c')};
  ${props => props.noBorder && 'border: none;'}
  border-radius: 8px;
  padding: ${({ noBorder, padding }) => padding || (noBorder ? '8px 10px' : '6px 8px')};
  cursor: pointer;
  box-sizing: border-box;

  &:hover,
  &.active {
    background: #1a1b1e;
    border-color: #383a43;
    ${({ backgroundColor, noBorder }) => noBorder && `box-shadow: 0px 2px 16px ${backgroundColor}`}
  }

  &:disabled .icon {
    opacity: 0.3;
  }

  &.secondary {
    background: rgba(204, 213, 255, 0.11);
    border: none;
  }
  &.secondary:hover {
    box-shadow: 0px 2px 16px rgba(204, 213, 255, 0.11);
  }

  &.more-notifications {
    background: transparent;
    border: none;
    padding: 0;
    .text {
      color: ${({ color }) => color || 'rgba(230, 234, 255, 0.6)'};
    }
    .icon {
      path {
        fill: rgba(230, 234, 255, 0.6);
      }
    }
  }

  &.more-notifications.active {
    .text {
      color: #ffffff;
    }
    .icon {
      transform: matrix(1, 0, 0, -1, 0, 0);
      path {
        fill: #ffffff;
      }
    }
  }

  &.transparent {
    background: transparent;
    border: none;
    padding: 8px 14px;
    .text {
      color: rgba(230, 234, 255, 0.35);
    }
    .icon {
      path {
        fill: rgba(230, 234, 255, 0.35);
      }
    }
  }
  &.transparent.active {
    background: rgba(204, 213, 255, 0.11);
    .text {
      color: #fff;
    }
    .icon {
      path {
        fill: #fff;
      }
    }
  }

  &.text-uppercase {
    .text {
      text-transform: uppercase;
    }
  }
  &.position-left {
    width: 128px;
    height: 100%;
    padding: 9px 12px;
    justify-content: space-between;
    .text {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
    }
  }

  @media ${pageSize.XS} {
    &.position-left {
      padding: 12px 22px 12px 12px;
    }
    &.center-on-xs {
      width: 100%;
      justify-content: center;
    }
  }
`;
const DropDownItems = styled.div`
  display: none;
  &.active {
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 252px;
    right: ${({ right }) => (isNumber(right) ? right : '-8')}px;
    padding: 6px;
    margin-top: 4px;
    background: #1a1b1e;
    border: 2px solid rgba(204, 213, 255, 0.05);
    border-radius: 8px;
    z-index: 6;
    &.more-notifications {
      width: 168px;
      padding: 8px;
    }
  }
  &.reverse.active {
    display: flex;
    flex-flow: column-reverse;
  }
  &.position-left {
    left: 0;
    right: unset;
    width: auto;
    min-width: 128px;
  }
  @media ${pageSize.XS} {
    &.dropDown {
      right: unset;
    }
  }
  &.text-uppercase {
    .button-text {
      text-transform: uppercase;
    }
  }
`;
const Text = styled.span`
  ${commonStyles.caption}
  padding-right: 8px;
`;

export const DropDownContext = React.createContext({
  isOpen: false,
  setIsOpen: () => ({})
});

const DropDown = ({
  backgroundColor,
  children,
  className = '',
  height,
  icon,
  noBorder,
  noRight,
  padding,
  right,
  stretchOnXs,
  text,
  width,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();

  const outsideClick = useCallback(
    e => {
      if (isOpen && !node.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    },
    [isOpen, node]
  );

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('mousedown', outsideClick, false);
    }
    return () => {
      document.body.removeEventListener('mousedown', outsideClick, false);
    };
  }, [isOpen, outsideClick]);

  return (
    <StyledDropdown stretchOnXs={stretchOnXs} ref={node} onClick={e => e.stopPropagation()}>
      <StyledButton
        noBorder={noBorder}
        backgroundColor={backgroundColor}
        width={width}
        height={height}
        type="button"
        className={`${className}${isOpen ? ' active' : ''}`}
        isOpen={isOpen}
        padding={padding}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        {...rest}
      >
        {text && <Text className="text">{text}</Text>}
        <Icon className="icon" icon={icon ?? 'dots'} />
      </StyledButton>
      <DropDownItems className={`${className} ${isOpen ? 'active' : ''}${noRight ? ' dropDown' : ''}`} right={right}>
        <DropDownContext.Provider value={{ isOpen, setIsOpen }}>{children}</DropDownContext.Provider>
      </DropDownItems>
    </StyledDropdown>
  );
};

export default DropDown;
