import React from 'react';
import { useDispatch } from 'react-redux';

import { portalActions } from 'store/portals';

const withOpenPortal = Component => {
  const EnhancedComponent = ({ closes, data, onClick, opens, ...rest }) => {
    const dispatch = useDispatch();

    const openPortal = event => {
      event.stopPropagation();

      dispatch(portalActions.openPortal({ name: opens, data }));
      if (closes) dispatch(portalActions.closePortal({ name: closes }));
      if (onClick) onClick(event);
    };

    return <Component onClick={opens ? openPortal : onClick} {...rest} />;
  };

  return EnhancedComponent;
};

export default withOpenPortal;
