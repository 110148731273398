import React from 'react';
import styled from '@emotion/styled';

import { gameService } from 'api';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import useForm from 'hooks/useForm';
import FormUpload from 'common/form/FormUpload';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledYearInput = styled(FormInput)`
  width: 114px;
`;

const StyledForm = styled.form`
  max-width: 600px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
  margin-left: auto;
`;

const fields = data => ({
  name: { initialValue: data.name, validate: ['isRequired'] },
  developer: { initialValue: data.developer, validate: ['isRequired'] },
  publisher: { initialValue: data.publisher, validate: ['isRequired'] },
  year: { initialValue: data.year, validate: ['isRequired'] },
  gameEngines: { initialValue: data.gameEngines, validate: ['isRequired'] },
  platforms: { initialValue: data.platforms, validate: ['isRequired'] },
  image: { initialValue: data.image, validate: ['isRequired'] }
});

const BackOfficeMissingGameModal = ({ closePortal, data: { gameId, initialName, tableRef }, name: modalName }) => {

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields({ name: initialName }),
    callApi: async params => {
      const body = {
        ...params,
        game_engines: params.gameEngines.split(', ').map(name => ({ name })),
        platforms: params.platforms.split(', ').map(name => ({ name }))
      };

      const formData = new FormData();

      if (values.image) formData.append('file', values.image);
      formData.append('game', JSON.stringify(body));

      const {
        data: { data, ...rest }
      } = await gameService.approveGameTitle(gameId, formData);

      if (tableRef) tableRef.current.refetch();
      closePortal();

      return Promise.resolve({ data: { data, ...rest } });
    }
  });

  return (
    <TxplModal
      name={modalName}
      appElement={document.getElementById('root')}
      title="Add missing Item"
      renderFooter={
        <SubmitRow>
          <StyledButtonsContainer>
            {submitting && <Spinner />}
            <ContainedButton
              type="button"
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              disabled={submitting}
            >
              Cancel
            </ContainedButton>
            <ContainedButton type="submit" onClick={onSubmit} forceDisplayText disabled={submitting}>
              Add
            </ContainedButton>
          </StyledButtonsContainer>
        </SubmitRow>
      }
    >
      <StyledForm onSubmit={onSubmit}>
        <FormInput
          label="Official game title"
          value={values.name}
          onChange={onFieldChange('name')}
          error={errors.name}
          required
        />
        <FormInput
          label="Developer"
          value={values.developer}
          onChange={onFieldChange('developer')}
          error={errors.developer}
          required
        />
        <FormInput
          label="Publisher"
          value={values.publisher}
          onChange={onFieldChange('publisher')}
          error={errors.publisher}
          required
        />
        <StyledYearInput
          label="Year"
          value={values.year}
          onChange={onFieldChange('year')}
          error={errors.year}
          required
        />
        <FormUpload
          wrapperStyles={{ flexDirection: 'column' }}
          disabled
          label="Game cover"
          onChange={onFieldChange('image')}
          value={values.image}
          error={errors.image}
          hintText="Should be an PNG image, at least 208x300px"
          mt={20}
          preview
          mw="100px"
          accept="image/png"
          padding
          required
          noCrop
        />
        <FormInput
          label="Game engines"
          value={values.gameEngines}
          onChange={onFieldChange('gameEngines')}
          error={errors.gameEngines}
          required
        />
        <FormInput
          label="Platforms"
          value={values.platforms}
          onChange={onFieldChange('platforms')}
          error={errors.platforms}
          required
        />
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-missing-game-title-modal')(BackOfficeMissingGameModal);
