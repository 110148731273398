import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profileSelectors } from 'store/profile';
import { selectCollaborations, selectCollaborationsProperty } from 'store/collaborations/collaborationsSelector';
import { collaborationsActions } from 'store/collaborations';
import collaborationService from 'api/collaborationService';

const useCollaborationsService = (staticLength = false, limit = null) => {
  const LIMIT = limit ?? 8;

  const dispatch = useDispatch();

  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const collaborations = useSelector(selectCollaborations) || {};
  const hasMore = useSelector(selectCollaborationsProperty('hasMore'));
  const hasVetted = useSelector(selectCollaborationsProperty('hasVetted'));
  const totalCollabs = useSelector(selectCollaborationsProperty('totalCollabs'));

  const collaborationsList = staticLength
    ? Object.values(collaborations).slice(0, limit)
    : Object.values(collaborations);

  const [loading, setLoading] = useState({
    getCollaborators: true,
    acceptDeclineCollaboration: true,
    getCollaboratorByExternalId: false
  });

  const getCollaboratorByExternalId = async externalId => {
    try {
      setLoading(state => ({ ...state, getCollaboratorByExternalId: true }));
      const res = await collaborationService.getCollaboratorByExternalId(externalId);
      const { collaborator } = res.data;
      return collaborator;
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(state => ({ ...state, getCollaboratorByExternalId: false }));
    }
  };

  const getCollaborators = async (skip, refetch = false) => {
    try {
      setLoading(state => ({ ...state, getCollaborators: true }));
      const res = await collaborationService.getCollaborations(skip, LIMIT);
      const {
        data,
        hasMore: hasMoreRes,
        hasVetted: hasVettedRes,
        isFirstVouch,
        totalCollaborators,
        vouchedAsUnverified,
        vouchedCollaborators
      } = res.data;

      dispatch(
        collaborationsActions.fetchCollaborations({
          collaborations: data,
          hasMore: hasMoreRes,
          hasVetted: hasVettedRes,
          isFirstVouch,
          totalCollabs: totalCollaborators,
          vouchedAsUnverified,
          vouchedCollaborators,
          refetch
        })
      );
      return { data, hasMore, totalCollaborators, hasVetted, isFirstVouch, vouchedAsUnverified, vouchedCollaborators };
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(state => ({ ...state, getCollaborators: false }));
    }
  };

  const acceptDeclineCollaboration = async (id, collabData) => {
    try {
      setLoading(state => ({ ...state, acceptDeclineCollaboration: true }));
      const res = await collaborationService.acceptDeclineCollaboration(id, collabData);
      const { data } = res.data;
      if (collabData.action === 'declined') {
        dispatch(
          collaborationsActions.deleteCollaboration({
            key: data._id
          })
        );
      } else {
        dispatch(
          collaborationsActions.updateCollaborationProperty({
            key: data._id,
            property: 'status',
            value: data.status
          })
        );
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(state => ({ ...state, acceptDeclineCollaboration: false }));
    }
  };

  const fetchCollaborations = async (skip, refetch = false) => {
    return getCollaborators(skip, refetch);
  };

  const loadMore = useCallback(() => {
    if (hasMore && !loading.getCollaborators) {
      fetchCollaborations(collaborationsList.length);
    }
  }, [collaborationsList]);

  useEffect(() => {
    if (profileId) {
      fetchCollaborations(0);
    }
  }, [profileId]);

  return {
    collaborations: collaborationsList,
    hasMore,
    hasVetted,
    totalCollabs,
    fetchCollaborations,
    getCollaboratorByExternalId,
    acceptDeclineCollaboration,
    loading,
    loadMore
  };
};

export default useCollaborationsService;
