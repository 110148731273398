import React, { useState } from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Loader from 'common/Loader';
import { MessageBox, ModalBox, TextBox } from 'pages/talent/Jobs/Applicants/CompanyContractModal.styled';
import { talentService } from 'api';

const DemandApplyJobModal = ({ closePortal, data }) => {
  const [buttonText, setButtonText] = useState('SEND A REQUEST');
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('Want to apply?');
  const [message, setMessage] = useState(
    `Your profile is setup as demand, so you can post jobs and hire talents. If you want to change your permissions to supply and apply for jobs, please, send a request to our friendly support team.`
  );

  const requestChange = async () => {
    if (buttonText === 'Done') {
      closePortal();
    } else {
      setIsLoading(true);
      await talentService.requestDemandRoleChange(data?.talentId);
      setIsLoading(false);
      setButtonText('Done');
      setTitle('Request sent');
      setMessage(`Thank you! We will get back to you shortly.`);
    }
  };

  return (
    <TxplModal
      name={'demand-apply-job-modal'}
      appElement={document.getElementById('root-modal')}
      title={title}
      addPaddingTop
      allowLine={true}
      onClose={closePortal}
      headerPaddingBottom={0}
      hideCloseIcon={false}
      size="small"
      renderFooter={
        <>
          {buttonText === 'SEND A REQUEST' && !isLoading  && (
            <ContainedButton onClick={closePortal} mr={6}>
              Cancel
            </ContainedButton>
          )}
          {!isLoading && <ContainedButton onClick={requestChange}>{buttonText}</ContainedButton>}
          {isLoading && <Loader isLoading />}
        </>
      }
    >
      <ModalBox>
        <MessageBox>
          <TextBox>{message}</TextBox>
        </MessageBox>
      </ModalBox>
    </TxplModal>
  );
};

export default withRenderPortal('demand-apply-job-modal')(DemandApplyJobModal);
