import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import { backOfficeActions } from '../../../store/backOffice';
import { backOfficeService, vettingService } from '../../../api';
import { pageSize } from '../../../utils/devices';
import Spinner from '../../../common/Spinner';
import FormRadiobutton from '../../../common/form/FormRadiobutton';
import Icon from '../../../common/Icon';
import VettingStatus from '../VettingStatus';
import { portalActions } from '../../../store/portals';
import EditButton from '../../../common/EditButton';
import VettingOutcome from '../VettingOutcome';
import isNumber from 'lodash/isNumber';
import generateGameUrl from '../../../utils/generateGameUrl';

const GameVettingContainer = styled.div`
  width: 70%;
`;
const PagerTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
`;
const PagerSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
`;
const GameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  left: 280px;
  top: 200px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 12px;
`;
const GameImage = styled.img`
  border-radius: 8px;
  height: 100%;
  max-height: 192px;
  max-width: 134px;
  object-fit: cover;
  @media ${pageSize.XS} {
    margin-bottom: 25px;
    max-height: 90px;
    max-width: 63px;
  }
`;
const GameContent = styled.div`
  margin-left: 24px;
  width: 100%;
`;
const GameTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 16px;
`;
const GameDescContainer = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: rgba(230, 234, 255, 0.35);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 10px;
  ${({ mb }) => isNumber(mb) && `margin-bottom: ${mb}px;`}
  width:100%;
`;
const DescLabel = styled.div`
  font-weight: bold;
  width: 50%;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: rgba(230, 234, 255, 0.35);
  order: 0;
  display: flex;
  align-items: center;
`;
const GameDescLabel = styled(DescLabel)`
  min-width: 160px;
`;
const DescValue = styled.div`
  font-weight: 600;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff;
  order: 0;
  display: flex;
  word-break: break-word;
  font-family: Space Grotesk;
`;
const GameDescValue = styled(DescValue)``;
const Loader = styled(Spinner)`
  margin: 15px 0 5px 0;
`;
const GameCollaboratorContainer = styled.div`
  display: block;
`;
const GameCollaborator = styled.div`
  display: inline-block;
  background: rgba(204, 212, 255, 0.04);
  border-radius: 16px;
  width: calc(50% - 60px);
  padding: 24px;
  margin-bottom: 24px;
  :nth-child(odd) {
    margin-right: 24px;
  }
`;
const GameCollaboratorTitle = styled.div`
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #ffffff;
  padding-bottom: 24px;
`;
const GameCollaboratorDescContainer = styled.div`
  display: flex;
  padding-bottom: 28px;
`;
const Vouch = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 35px;
  color: rgba(230, 234, 255, 0.35);
  margin-right: 18px;
  margin-left: 4px;
`;
const GameVettingSidebar = styled.div`
  position: absolute;
  width: 296px;
  height: 900px;
  right: 0%;
  top: 0%;
  background: linear-gradient(0deg, rgba(204, 213, 255, 0.04), rgba(204, 213, 255, 0.04)), #111112;
`;
const GameVettingHeader = styled.div`
  display: flex;
`;
const GameVettingSidebarLabel = styled.div`
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.35);
`;
const GameVettingSidebarValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 4px;
  width: 85%;
`;
const GameVettingSidebarContent = styled.div`
  padding: 16px 0 0 24px;
`;
const GameVettingHeaderTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 37px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
`;
const GameVettingSidebarBottomInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 80%;
`;
const GameVettingSidebarComments = styled.div`
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  flex-grow: 0;
  padding: 13px 0;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 30px;
  cursor: pointer;
`;
const GameCollaboratorResult = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 2px;
`;
const GameMarkAsCompleted = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 170px;
  border-radius: 6px;
  ${props => props.count >= 2 && 'cursor: pointer'};
  background: ${props => (props.count >= 2 ? 'rgba(114, 102, 238, 1)' : 'rgba(204, 213, 255, 0.11)')};
`;
const GameMarkAsCompletedContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;
const GameCompleted = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #00ff00;
  padding-top: 8px;
  margin-right: 20px;
`;
const GameCompletedCheckMark = styled.div`
  display: inline;
  margin-left: 14px;
`;
const StyledEditButton = styled(EditButton)`
  position: absolute;
  margin-top: -16px;
  right: 0;
`;
const GameVettingSidebarDelimiter = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 24px;
  background-color: rgba(204, 213, 255, 0.11);
`;
const GameVettingViewAccount = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const BackOfficeVettingInner = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [game, setGame] = useState('');
  const [role, setRole] = useState('');
  const [gameImage, setGameImage] = useState(game?.cover);
  const [collaborators, setCollaborators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [vetting, setVetting] = useState('');
  const [selectedAnalyst, setSelectedAnalyst] = useState({});
  const [vouchList, setVouchList] = useState({});
  const [completedCollaborators, setCompletedCollaborators] = useState(0);
  const [collaboratorsChecked, setCollaboratorsChecked] = useState(false);
  const [outcomeGenerated, setOutcomeGenerated] = useState(false);
  const [analystList, setAnalystList] = useState([]);
  const [dueDate, setDueDate] = useState('');

  dispatch(
    backOfficeActions.updateBackOfficeTitle([
      { label: 'Operations' },
      { label: 'vetting', url: '/backoffice/operations/vetting' },
      { label: `Vetting Credits ${vetting ? vetting.vetting_id : ''}` }
    ])
  );

  const calculateDueDate = date => {
    const dueDate = moment(date).add(2, 'days');
    const hours = dueDate.diff(moment.utc(), 'hours');
    return Math.ceil(hours / 24);
  };

  const loadVetting = (hideLoader = false) => {
    !hideLoader && setIsLoading(true);
    vettingService
      .getVetting(params.id)
      .then(response => {
        setRole(response.data.vetting.credit?.role);
        setGame(response.data.vetting.credit?.game);
        setVetting(response.data.vetting);
        setCollaborators(response.data.vetting.credit?.collaborators);
        setSelectedAnalyst({
          value: response.data.vetting.user._id,
          name: `${response.data.vetting?.user?.firstName} ${response.data.vetting?.user?.lastName}`
        });

        if (response.data.vetting.vouch) {
          setVouchList(response.data.vetting.vouch);
          calculateCompleted(response.data.vetting.vouch);
        } else {
          const vList = {};

          if (response.data.vetting.credit) {
            response.data.vetting.credit.collaborators.map(collaborator => (vList[collaborator._id] = 'No'));
          }
          setVouchList(vList);
        }

        const dueDate = moment(response.data.vetting?.createdAt).add(2, 'days').isAfter(moment())
          ? 'Due in ' + calculateDueDate(response.data.vetting?.createdAt) + ' days'
          : 'Overdue';
        setDueDate(dueDate);
        response.data.vetting.vouch !== undefined &&
          Object.values(response.data.vetting.vouch).length >= 2 &&
          setOutcomeGenerated(true);
        !hideLoader && setIsLoading(false);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  const loadAnalyst = () => {
    backOfficeService
      .getUsersByRole('analyst')
      .then(response => {
        const list = [];
        response.data.data.map(data => {
          list.push({
            value: data._id,
            label: `${data.firstName} ${data.lastName}`
          });
        });
        setAnalystList(list);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  const updateVouch = id => e => {
    const newArr = { ...vouchList };
    newArr[id] = e.target.value;
    setVouchList(newArr);
    calculateCompleted(newArr);
    vettingService.updateVettingStatus(params.id, {
      vouch: newArr
    });
  };

  const calculateCompleted = list => {
    let i = 0;
    Object.values(list).map(v => {
      v === 'Yes' && i++;
    });
    setCompletedCollaborators(i);
  };

  const handleCollaboratorsCompleted = () => {
    setCollaboratorsChecked(true);
  };

  const generateOutcome = (openModal = true) => {
    vettingService
      .updateVettingStatus(params.id, {
        vouch: vouchList
      })
      .then(() => {
        setCollaboratorsChecked(false);
        openModal && openVettingOutcomeModal();
        setOutcomeGenerated(true);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  const openCommentModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'vetting-comment-modal',
        data: {
          on: 'user',
          id: vetting._id,
          comments: vetting.comments.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0)),
          onSuccess: () => loadVetting(true)
        }
      })
    );
  };

  const openVettingAssigneeModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'vetting-assignee-modal',
        data: {
          on: 'user',
          id: vetting._id,
          setSelectedAnalyst,
          selectedAnalyst,
          analystList,
          user: vetting.user,
          onSuccess: () => loadVetting(true)
        }
      })
    );
  };

  const openVettingStatusModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'vetting-only-status-modal',
        data: {
          on: 'user',
          id: vetting._id,
          status: vetting.status,
          onSuccess: () => loadVetting(true)
        }
      })
    );
  };

  const openVettingOutcomeModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'vetting-outcome-modal',
        data: {
          on: 'user',
          id: vetting._id,
          outcome: vetting.outcome,
          status: vetting.status,
          additionalComment: '',
          onSuccess: () => loadVetting(true)
        }
      })
    );
  };

  const calculateSelect = (collaborator, value) => vouchList[collaborator] === value && value;

  useEffect(() => {
    loadVetting();
    loadAnalyst();
    setGameImage(generateGameUrl(game?._id));
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader centered />
      ) : (
        <>
          <GameVettingContainer>
            <PagerTitle>Review and Vet Credits</PagerTitle>
            <PagerSubTitle>Review the information provided by the user</PagerSubTitle>
            <GameContainer>
              <GameImage src={gameImage} alt="test" />
              <GameContent>
                <GameTitle>{game?.title}</GameTitle>
                <GameDescContainer>
                  <GameDescLabel>Project status </GameDescLabel>
                  <GameDescValue>{moment(game?.year).year() <= moment().year() && 'Released'}</GameDescValue>
                </GameDescContainer>
                <GameDescContainer>
                  <GameDescLabel>Release year</GameDescLabel>
                  <GameDescValue>{` ${moment(game?.year).year()}`}</GameDescValue>
                </GameDescContainer>
                <GameDescContainer>
                  <GameDescLabel>Role </GameDescLabel>
                  <GameDescValue>{role?.name}</GameDescValue>
                </GameDescContainer>
                <GameDescContainer>
                  <GameDescLabel>Developer </GameDescLabel>
                  <GameDescValue>{game?.developed_by}</GameDescValue>
                </GameDescContainer>
                <GameDescContainer mb={0}>
                  <GameDescLabel>Publisher </GameDescLabel>
                  <GameDescValue>{game?.published_by}</GameDescValue>
                </GameDescContainer>
              </GameContent>
            </GameContainer>
            <br />
            <PagerSubTitle>Validate Collaborators. We require at least 2 vouchings</PagerSubTitle>
            <GameCollaboratorContainer>
              {collaborators &&
                collaborators.map((collaborator, index) => (
                  <GameCollaborator key={index}>
                    <GameCollaboratorTitle>Collaborator #{index + 1}</GameCollaboratorTitle>
                    <GameCollaboratorDescContainer>
                      <DescLabel>Full Name </DescLabel>
                      <DescValue>{collaborator?.name}</DescValue>
                    </GameCollaboratorDescContainer>
                    <GameCollaboratorDescContainer>
                      <DescLabel>Email </DescLabel>
                      <DescValue>{collaborator?.email}</DescValue>
                    </GameCollaboratorDescContainer>
                    <GameCollaboratorDescContainer>
                      <DescLabel>Did they vouch?</DescLabel>
                      <DescValue>
                        <FormRadiobutton
                          name={collaborator?._id}
                          value="Yes"
                          selected={calculateSelect(collaborator._id, 'Yes')}
                          onChange={updateVouch(collaborator._id)}
                        />
                        <Vouch>Yes</Vouch>
                        <FormRadiobutton
                          name={collaborator?._id}
                          value="No"
                          selected={calculateSelect(collaborator._id, 'No')}
                          onChange={updateVouch(collaborator._id)}
                        />
                        <Vouch>No</Vouch>
                      </DescValue>
                    </GameCollaboratorDescContainer>
                  </GameCollaborator>
                ))}
              <GameCollaboratorResult>
                {completedCollaborators > 3 ? 3 : completedCollaborators}/3 Positive Results
              </GameCollaboratorResult>
              <GameMarkAsCompletedContainer>
                {collaboratorsChecked && completedCollaborators >= 2 && (
                  <GameCompleted>
                    Completed{' '}
                    <GameCompletedCheckMark>
                      <Icon icon="checkmark" color="rgba(0, 255, 0, 1)" />
                    </GameCompletedCheckMark>
                  </GameCompleted>
                )}
                {!collaboratorsChecked && !outcomeGenerated && (
                  <GameMarkAsCompleted
                    count={completedCollaborators}
                    onClick={() => completedCollaborators >= 2 && handleCollaboratorsCompleted()}
                  >
                    Mark as completed
                  </GameMarkAsCompleted>
                )}
                {collaboratorsChecked && (
                  <GameMarkAsCompleted
                    count={completedCollaborators}
                    onClick={() => completedCollaborators >= 2 && generateOutcome()}
                  >
                    Generate Outcome
                  </GameMarkAsCompleted>
                )}
              </GameMarkAsCompletedContainer>
            </GameCollaboratorContainer>
          </GameVettingContainer>
          <GameVettingSidebar>
            <GameVettingSidebarContent>
              <GameVettingHeader>
                <Icon icon="vetting_due" width="40" height="40" />
                <GameVettingHeaderTitle>{dueDate}</GameVettingHeaderTitle>
              </GameVettingHeader>

              <GameVettingSidebarBottomInfo>
                <GameVettingViewAccount
                  onClick={() => history.push(`/backoffice/operations/soloists/${vetting.credit?.talent}`)}
                >
                  View user account ↗
                </GameVettingViewAccount>

                <GameVettingSidebarDelimiter />

                <GameVettingSidebarLabel>Assignee :</GameVettingSidebarLabel>
                <GameVettingSidebarValue>{selectedAnalyst?.name}</GameVettingSidebarValue>
                <StyledEditButton iconName="edit" onClick={openVettingAssigneeModal} />

                <GameVettingSidebarLabel>Vetting Status :</GameVettingSidebarLabel>
                <GameVettingSidebarValue>
                  <VettingStatus status={vetting.status} icon={false} />
                </GameVettingSidebarValue>
                <StyledEditButton iconName="edit" onClick={openVettingStatusModal} />

                <GameVettingSidebarLabel>Outcome :</GameVettingSidebarLabel>
                <GameVettingSidebarValue>
                  {vetting.outcome ? <VettingOutcome status={vetting.outcome} icon={false} /> : 'Null'}
                </GameVettingSidebarValue>
                <StyledEditButton iconName="edit" onClick={openVettingOutcomeModal} />

                <GameVettingSidebarDelimiter />

                <GameVettingSidebarComments onClick={openCommentModal}>
                  Comments ({vetting.comments?.length})
                </GameVettingSidebarComments>
              </GameVettingSidebarBottomInfo>
            </GameVettingSidebarContent>
          </GameVettingSidebar>
        </>
      )}
    </>
  );
};

export default BackOfficeVettingInner;
