import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { pageSize } from 'utils/devices';

export const Text = styled.span`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${({ color }) => color || '#fff'};
  @media ${pageSize.XS} {
    font-size: 17px;
    line-height: 28px;
  }
`;

export const ClickableText = styled(Text)`
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  color: #7266ee;
  text-decoration: none;
`;
