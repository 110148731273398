import React from 'react';
import { StyledContainedButton, StyledContent, VerifyIdWrapper } from './Steps.styled';
import Spinner from 'common/Spinner';

const VerifyYourId = ({ handleVerifyId, isLoading }) => {
  return (
    <VerifyIdWrapper>
      <StyledContent>One last step, verify your ID to unlock more opportunities (takes 3 minutes tops).</StyledContent>
      {isLoading ? <Spinner /> : <StyledContainedButton onClick={handleVerifyId}>Verify my ID</StyledContainedButton>}
    </VerifyIdWrapper>
  );
};

export default VerifyYourId;
