import styled from '@emotion/styled';
import isNumber from 'lodash/fp/isNumber';
import { pageSize } from 'utils/devices';

const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  &.column-reverse {
    flex-flow: column-reverse;
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.w-100 {
    width: 100%;
  }
  &.relative {
    position: relative;
  }
  &.row {
    flex-flow: row;
  }

  ${({ flexGrow }) => flexGrow && `flex-grow : ${flexGrow};`}
  ${({ column }) => column && 'flex-flow: column;'}
    ${({ gap }) => gap && `gap: ${gap}px;`}
    ${({ justifySpaceBetween }) => justifySpaceBetween && 'justify-content: space-between;'}
    ${({ justifyEnd }) => justifyEnd && 'justify-content: flex-end;'}
    ${({ justifyStart }) => justifyStart && 'justify-content: flex-start;'}
    ${({ alignCenter }) => alignCenter && 'align-items: center;'}
    ${({ center }) => center && 'justify-content: center; align-items: center;'}
    ${({ width }) => width && `width: ${isNumber(width) ? `${width}px;` : `${width};`}`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
    ${({ pt }) => pt && `padding-top: ${pt}px;`}
    ${({ pr }) => pr && `padding-right: ${isNumber(pr) ? `${pr}px` : pr};`}
    ${({ mr }) => mr && `margin-right: ${isNumber(mr) ? `${mr}px` : mr};`}
    ${({ ml }) => ml && `margin-left: ${isNumber(ml) ? `${ml}px` : ml};`}
    ${({ mt }) => mt && `margin-top: ${isNumber(mt) ? `${mt}px` : mt};`}
    ${({ mb }) => mb && `margin-bottom: ${isNumber(mb) ? `${mb}px` : mb};`}
    ${({ order }) => order && `order: ${order};`}
    @media ${pageSize.XS} {
    ${({ columnOnXs }) => columnOnXs && 'flex-flow: column;'}
    ${({ gapOnXS }) => gapOnXS && `gap: ${gapOnXS}px;`}
        ${({ spaceBetweenOnXS }) => spaceBetweenOnXS && 'justify-content: space-between'}
  }
`;

export default Flex;
