import Api from './api';

const api = new Api('companies');

const getCompanies = data => api.get('/', data);

const deleteCompany = companyId => api.delete(`/${companyId}`);

const searchCompany = company => api.get('/search', { params: { name: company } });

const createCompany = formData =>
  api.post('/', formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const getCompany = companyId => api.get(`/${companyId}`);

const updateCompany = (companyId, data) => api.put(`/${companyId}`, data);

const updateCreditSort = (companyId, data) => api.put(`/${companyId}/credit`, data);

const updatePortfolioSort = (companyId, data) => api.put(`/${companyId}/portfolio`, data);

const approveMissingItem = (companyId, data) =>
  api.post(`/${companyId}/approve-missing-item`, data, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const setTags = (companyId, data) => api.post(`/${companyId}/tags`, data);

const updateRoleSort = (companyId, data) => api.post(`/${companyId}/role/sort`, data);

const requestCompanyTitle = data => api.post('/request', data);

const updateMediaCoverageSort = (companyId, data) => api.put(`/${companyId}/media-coverage`, data);

const updateProjectSort = (companyId, data) => api.put(`/${companyId}/projects/sort`, data);

const checkCompany = query => api.get(`/check?${query}`);

export default {
  getCompanies,
  setTags,
  deleteCompany,
  createCompany,
  searchCompany,
  requestCompanyTitle,
  getCompany,
  updateCompany,
  updateCreditSort,
  updatePortfolioSort,
  updateMediaCoverageSort,
  updateProjectSort,
  approveMissingItem,
  updateRoleSort,
  checkCompany
};
