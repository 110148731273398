import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profileActions, profileSelectors } from 'store/profile';

import uploadsService from 'api/uploadsService';
import talentService from 'api/talentService';
import withRenderPortal from 'hocs/withRenderPortal';
import isBoolean from 'lodash/fp/isBoolean';
import { ScopeDescription, ScopeFooterWrapper, ScopeRadioButtons, StyledRadioButton } from './styled.components';
import TxplModal from 'common/TxplModal';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';

const EditProjectScopeModal = ({ closePortal, data }) => {
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const { openErrorSnackbar } = useErrorSnackbar();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { project, projectId, talentId } = data;
  const callUpdateProject = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { coverImage, ...updatedData } = project;
      formData.append(
        'data',
        JSON.stringify({
          ...updatedData,
          platforms: project.platforms.map(platform => platform.value),
          genres: project.genres.map(genre => genre.value),
          tags: project.tags.map(tag => tag.value)
        })
      );
      if (typeof project.coverImage !== 'string') {
        formData.append('file', project.coverImage);
      }
      const res = await talentService.updateProject(talentId, projectId, formData);
      dispatch(
        profileActions.updateProfileProperty({
          property: 'generalProjects',
          updateType: 'update',
          value: res.data.data,
          profileId
        })
      );
      dispatch(
        profileActions.updateProfileProperty({
          property: 'projects',
          updateType: 'update',
          value: res.data.data,
          profileId
        })
      );
      if (data.afterSave) {
        data.afterSave(res.data.data);
      }
      setLoading(false);
      closePortal();
      data.closeProjectModal();
    } catch (err) {
      const errData = err?.response?.data;
      setLoading(false);
      if (errData) {
        const { error, success } = errData;
        if (isBoolean(success) && !success) {
          openErrorSnackbar(error);
        }
      }
    }
  };

  const callImageUpload = async () => {
    try {
      setLoading(true);
      let createdImageId = null;
      let preview = null;
      if (typeof project.coverImage !== 'string') {
        const formData = new FormData();
        formData.append('file', project.coverImage);
        preview = URL.createObjectURL(project.coverImage);
        const res = await uploadsService.uploadFile(formData);
        createdImageId = res.data.data.id;
      }
      data.afterSave({ ...project, _id: projectId, coverImage: createdImageId ?? project.coverImage, preview });
      setLoading(false);
      closePortal();
      data.closeProjectModal();
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const onSubmit = () => {
    if (value === 'all') {
      callUpdateProject();
    } else {
      callImageUpload();
    }
  };

  return (
    <TxplModal
      title="Choose the update scope"
      name="edit-project-scope-modal"
      appElement={document.getElementById('root-modal')}
      size="small"
      renderFooter={
        <ScopeFooterWrapper>
          {loading && <Spinner />}
          <ContainedButton
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
            mr={6}
            disabled={loading}
          >
            CANCEL
          </ContainedButton>

          <ContainedButton onClick={onSubmit} backgroundColor="#7266EE" forceDisplayText disabled={!value || loading}>
            PROCEED
          </ContainedButton>
        </ScopeFooterWrapper>
      }
    >
      <ScopeDescription>Choose where you want the udates to take effect.</ScopeDescription>
      <ScopeRadioButtons>
        <StyledRadioButton
          value="current"
          name="scope"
          selected={value}
          onChange={() => setValue('current')}
          text="Update this project only on current page"
        />
        <StyledRadioButton
          value="all"
          name="scope"
          selected={value}
          onChange={() => setValue('all')}
          text="Update this project across all my profile"
        />
      </ScopeRadioButtons>
    </TxplModal>
  );
};

export default withRenderPortal('edit-project-scope-modal')(EditProjectScopeModal);
