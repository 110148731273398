import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  ${props => !props.mediumView && 'margin-left: 16px;'}
  position: relative;
  ${props => !props.mediumView && 'margin-top: 8px;'}
  ${props => props.mediumView && 'margin-top: 7px;'}
  ${({ hasChild }) => hasChild && 'height: 36px;'}
  ${({ hasChild }) => !hasChild && 'margin-top: 0;'}
  @media  ${pageSize.M}, ${pageSize.S} , ${pageSize.XS} {
    margin-left: 0;
  }
`;

export const StyledAvatar = styled.img`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  ${props => props.left && `left: ${props.left}px;`}
  ${props => props.faded && 'opacity: 0.5;'}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`;

export const StyledExpandContainer = styled.div`
  position: absolute;
  z-index: 5;
  ${props => props.left && `left: ${props.left}px;`}
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  background-color: #111112;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
