import Api from './api';

const api = new Api('collaborators');

const getCollaborations = (skip = 0, limit = 5) => api.get(`/?skip=${skip}&limit=${limit}`);
const acceptDeclineCollaboration = (collaborationId, data) => api.put(`${collaborationId}/accept-decline`, data);
const getCollaboratorByExternalId = (externalId) => api.get(`/externalId/${externalId}`);

export default {
  getCollaborations,
  acceptDeclineCollaboration,
  getCollaboratorByExternalId
};
