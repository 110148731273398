import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

const PageWrapper = styled.div`
  max-width: 1350px;
  margin: 0 auto;
  box-sizing: border-box;
  @media ${pageSize.L} {
    padding: 0 54px;
  }
  @media ${pageSize.M} {
    padding: 0 58px;
  }
  @media ${pageSize.S} {
    padding: 0 81px;
  }
  @media ${pageSize.XS} {
    padding: 0 16px;
  }
`;
export default PageWrapper;
