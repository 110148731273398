import styled from '@emotion/styled';
import { Modal as TagsModal } from 'common/TagsModal';
import get from 'lodash/fp/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import React from 'react';
import { pageSize } from 'utils/devices';
import GeneralTags from './GeneralTags';
import GenreTags from './GenreTags';
import PlatformTags from './PlatformTags';
import SkillTags from './SkillTags';
import ToolTags from './ToolTags';

const StyledGridCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-top: 72px;
  @media ${pageSize.S} {
    margin-top: 80px;
  }
  @media ${pageSize.M} {
    margin-top: 104px;
  }
  @media ${pageSize.L} {
    margin-top: 80px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const defaultOrder = ['platforms', 'tools', 'genres', 'skills', 'tags'];

const AllAdditionalTags = ({
  page,
  project,
  cols,
  className,
  errors,
  maxWidth,
  maxWidthXs,
  onAdd,
  isEditMode = true,
  hasDragPermission = false,
  data,
  order,
  hintTexts = {},
  required,
  hideEmpty,
  afterDrag,
  notClosePortal,
  portfolio = null,
  saveLoading,
  callService,
  largeGap = false,
  titlePB = 16,
  tagsModalFooterPaddingTop,
  fullWidth,
  ...rest
}) => {
  const [show, setShow] = React.useState(false);
  const [modelData, setData] = React.useState({
    type: 'skills',
    key: 'skill',
    title: ''
  });

  const openTagPortals = (name, key) => () => {
    setData({
      type: name,
      key,
      title: ''
    });
    setShow(true);
  };

  const removeEmptyTags = data => {
    if (page === 'projects') {
      return data;
    }

    return data?.reduce((arr, item) => {
      if (isString(item) || isObject(item)) {
        arr.push(item);
      }
      return arr;
    }, []);
  };

  const generateProps = (name, key) => ({
    error: get(name, errors),
    isEditMode,
    hasDragPermission,
    page,
    portfolio,
    maxWidth,
    maxWidthXs,
    cols,
    data: removeEmptyTags(data[name]),
    service: data?._id,
    required,
    onAction: openTagPortals(name, key),
    hintText: hintTexts[name],
    hideEmpty,
    afterDrag,
    callService,
    titlePB
  });

  return (
    <StyledGridCardsContainer fullWidth={fullWidth} className={className} largeGap={largeGap} {...rest}>
      {(order || defaultOrder).map(tagName => {
        switch (tagName) {
          case 'platforms':
            return (
              <PlatformTags
                project={project}
                key={`${tagName}-${project?._id}`}
                {...generateProps(tagName, 'platform')}
              />
            );
          case 'tools':
            return (
              <ToolTags project={project} key={`${tagName}-${project?._id}`} {...generateProps(tagName, 'tool')} />
            );
          case 'genres':
            return (
              <GenreTags project={project} key={`${tagName}-${project?._id}`} {...generateProps(tagName, 'genre')} />
            );
          case 'skills':
            return (
              <SkillTags project={project} key={`${tagName}-${project?._id}`} {...generateProps(tagName, 'skill')} />
            );
          case 'tags':
            return (
              <GeneralTags project={project} key={`${tagName}-${project?._id}`} {...generateProps(tagName, 'tag')} />
            );
          default:
            return null;
        }
      })}

      {show && (
        <TagsModal
          closePortal={() => setShow(false)}
          data={modelData}
          tags={data}
          onAdd={onAdd}
          notClosePortal={notClosePortal}
          saveLoading={saveLoading}
          required={required}
          tagsModalFooterPaddingTop={tagsModalFooterPaddingTop}
        />
      )}
    </StyledGridCardsContainer>
  );
};

export default AllAdditionalTags;
