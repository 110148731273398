import * as React from 'react';

const VettedBadgeIcon = ({ active, ...svgProps }) =>
  active ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M4 3C4 1.89543 4.89543 1 6 1H26C27.1046 1 28 1.89543 28 3V28.2251C28 29.5903 26.6626 30.5542 25.3675 30.1225L16.6325 27.2108C16.2219 27.074 15.7781 27.074 15.3675 27.2108L6.63246 30.1225C5.33739 30.5542 4 29.5903 4 28.2251V3Z"
        fill="url(#paint0_radial_93_7734)"
      />
      <g filter="url(#filter0_d_93_7734)">
        <path
          d="M19.9995 11C19.9995 13.2091 18.2087 15 15.9995 15C13.7904 15 11.9995 13.2091 11.9995 11C11.9995 8.79086 13.7904 7 15.9995 7C18.2087 7 19.9995 8.79086 19.9995 11Z"
          fill="white"
        />
        <path
          d="M14.9995 17.1944C14.9995 16.593 14.4693 16.1231 13.8892 16.2813C11.1672 17.0239 9.02343 19.1677 8.28098 21.8897C8.12272 22.4699 8.59266 23 9.19404 23H13.9995C14.5518 23 14.9995 22.5523 14.9995 22V17.1944Z"
          fill="white"
        />
        <path
          d="M23.7066 17.7071C24.0971 17.3166 24.0971 16.6834 23.7066 16.2929C23.3161 15.9024 22.6829 15.9024 22.2924 16.2929L18.9995 19.5858L17.7066 18.2929C17.3161 17.9024 16.6829 17.9024 16.2924 18.2929C15.9019 18.6834 15.9019 19.3166 16.2924 19.7071L18.9995 22.4142L23.7066 17.7071Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_93_7734"
          x="6"
          y="6"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.148568 0 0 0 0 0.0247613 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_93_7734" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_93_7734" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_93_7734"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4 31) rotate(-90) scale(30 36)"
        >
          <stop stopColor="#2B883D" />
          <stop offset="1" stopColor="#00FF40" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M4 3C4 1.89543 4.89543 1 6 1H26C27.1046 1 28 1.89543 28 3V28.2251C28 29.5903 26.6626 30.5542 25.3675 30.1225L16.6325 27.2108C16.2219 27.074 15.7781 27.074 15.3675 27.2108L6.63246 30.1225C5.33739 30.5542 4 29.5903 4 28.2251V3Z"
        fill="#C7C7FF"
        fillOpacity="0.21"
      />
      <path
        d="M19.9995 11C19.9995 13.2091 18.2087 15 15.9995 15C13.7904 15 11.9995 13.2091 11.9995 11C11.9995 8.79086 13.7904 7 15.9995 7C18.2087 7 19.9995 8.79086 19.9995 11Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        d="M14.9995 17.1944C14.9995 16.593 14.4693 16.1231 13.8892 16.2813C11.1672 17.0239 9.02343 19.1677 8.28098 21.8897C8.12272 22.4699 8.59266 23 9.19404 23H13.9995C14.5518 23 14.9995 22.5523 14.9995 22V17.1944Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        d="M23.7066 17.7071C24.0971 17.3166 24.0971 16.6834 23.7066 16.2929C23.3161 15.9024 22.6829 15.9024 22.2924 16.2929L18.9995 19.5858L17.7066 18.2929C17.3161 17.9024 16.6829 17.9024 16.2924 18.2929C15.9019 18.6834 15.9019 19.3166 16.2924 19.7071L18.9995 22.4142L23.7066 17.7071Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
    </svg>
  );

export default VettedBadgeIcon;
