import { userService } from 'api';
import { useDispatch } from 'react-redux';
import { profileActions } from 'store/profile';
import isEmpty from 'lodash/fp/isEmpty';
import { useErrorSnackbar } from './useReactSnackbar';
import { authActions } from 'store/auth';
import { useState } from 'react';

function useProfileSwitcher() {
  const { openErrorSnackbar } = useErrorSnackbar();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const switchProfile = async (profile, callback, setActiveProfileId = true) => {
    if (!isEmpty(profile)) {
      try {
        setLoading(true);
        const profileId = profile?._id ?? profile;
        const {
          data: {
            user: { activeProfile }
          }
        } = await userService.switchProfile(profileId);

        if (setActiveProfileId) {
          dispatch(profileActions.setActiveProfileId(profileId));
        }

        dispatch(
          authActions.updateCurrentUserProperty({
            property: 'activeProfile',
            value: activeProfile ?? {
              profile: profileId,
              profileModel: profile?.type !== 'talent' ? 'CompanyProfile' : 'Talent'
            }
          })
        );
        callback && callback();
      } catch (e) {
        openErrorSnackbar('Opps! something went wrong.');
      } finally {
        setLoading(false);
      }
    }
  };
  return { switchingProfile: loading, switchProfile };
}

export default useProfileSwitcher;
