import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';

export const Spacer = styled.div`
  height: 0px;
  border: 1px solid rgba(204, 213, 255, 0.11);
  margin: ${({ margin }) => margin || '20px 0px'};
`;

export const StyledEditCreditCancel = styled(ContainedButton)`
  margin-right: 6px;
`;

export const StyledDeleteButton = styled(ContainedButton)`
  margin-right: auto;
`;
