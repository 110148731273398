const languages = {
  aa: {
    alpha2: 'aa',
    'alpha3-b': 'aar',
    English: 'Afar'
  },
  ab: {
    alpha2: 'ab',
    'alpha3-b': 'abk',
    English: 'Abkhazian'
  },
  af: {
    alpha2: 'af',
    'alpha3-b': 'afr',
    English: 'Afrikaans'
  },
  ak: {
    alpha2: 'ak',
    'alpha3-b': 'aka',
    English: 'Akan'
  },
  sq: {
    alpha2: 'sq',
    'alpha3-b': 'alb',
    English: 'Albanian'
  },
  am: {
    alpha2: 'am',
    'alpha3-b': 'amh',
    English: 'Amharic'
  },
  ar: {
    alpha2: 'ar',
    'alpha3-b': 'ara',
    English: 'Arabic'
  },
  an: {
    alpha2: 'an',
    'alpha3-b': 'arg',
    English: 'Aragonese'
  },
  hy: {
    alpha2: 'hy',
    'alpha3-b': 'arm',
    English: 'Armenian'
  },
  as: {
    alpha2: 'as',
    'alpha3-b': 'asm',
    English: 'Assamese'
  },
  av: {
    alpha2: 'av',
    'alpha3-b': 'ava',
    English: 'Avaric'
  },
  ae: {
    alpha2: 'ae',
    'alpha3-b': 'ave',
    English: 'Avestan'
  },
  ay: {
    alpha2: 'ay',
    'alpha3-b': 'aym',
    English: 'Aymara'
  },
  az: {
    alpha2: 'az',
    'alpha3-b': 'aze',
    English: 'Azerbaijani'
  },
  ba: {
    alpha2: 'ba',
    'alpha3-b': 'bak',
    English: 'Bashkir'
  },
  bm: {
    alpha2: 'bm',
    'alpha3-b': 'bam',
    English: 'Bambara'
  },
  eu: {
    alpha2: 'eu',
    'alpha3-b': 'baq',
    English: 'Basque'
  },
  be: {
    alpha2: 'be',
    'alpha3-b': 'bel',
    English: 'Belarusian'
  },
  bn: {
    alpha2: 'bn',
    'alpha3-b': 'ben',
    English: 'Bengali'
  },
  bh: {
    alpha2: 'bh',
    'alpha3-b': 'bih',
    English: 'Bihari languages'
  },
  bi: {
    alpha2: 'bi',
    'alpha3-b': 'bis',
    English: 'Bislama'
  },
  bs: {
    alpha2: 'bs',
    'alpha3-b': 'bos',
    English: 'Bosnian'
  },
  br: {
    alpha2: 'br',
    'alpha3-b': 'bre',
    English: 'Breton'
  },
  bg: {
    alpha2: 'bg',
    'alpha3-b': 'bul',
    English: 'Bulgarian'
  },
  my: {
    alpha2: 'my',
    'alpha3-b': 'bur',
    English: 'Burmese'
  },
  ca: {
    alpha2: 'ca',
    'alpha3-b': 'cat',
    English: 'Catalan; Valencian'
  },
  ch: {
    alpha2: 'ch',
    'alpha3-b': 'cha',
    English: 'Chamorro'
  },
  ce: {
    alpha2: 'ce',
    'alpha3-b': 'che',
    English: 'Chechen'
  },
  zh: {
    alpha2: 'zh',
    'alpha3-b': 'chi',
    English: 'Chinese'
  },
  cu: {
    alpha2: 'cu',
    'alpha3-b': 'chu',
    English: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic'
  },
  cv: {
    alpha2: 'cv',
    'alpha3-b': 'chv',
    English: 'Chuvash'
  },
  kw: {
    alpha2: 'kw',
    'alpha3-b': 'cor',
    English: 'Cornish'
  },
  co: {
    alpha2: 'co',
    'alpha3-b': 'cos',
    English: 'Corsican'
  },
  cr: {
    alpha2: 'cr',
    'alpha3-b': 'cre',
    English: 'Cree'
  },
  cs: {
    alpha2: 'cs',
    'alpha3-b': 'cze',
    English: 'Czech'
  },
  da: {
    alpha2: 'da',
    'alpha3-b': 'dan',
    English: 'Danish'
  },
  dv: {
    alpha2: 'dv',
    'alpha3-b': 'div',
    English: 'Divehi; Dhivehi; Maldivian'
  },
  nl: {
    alpha2: 'nl',
    'alpha3-b': 'dut',
    English: 'Dutch; Flemish'
  },
  dz: {
    alpha2: 'dz',
    'alpha3-b': 'dzo',
    English: 'Dzongkha'
  },
  en: {
    alpha2: 'en',
    'alpha3-b': 'eng',
    English: 'English'
  },
  eo: {
    alpha2: 'eo',
    'alpha3-b': 'epo',
    English: 'Esperanto'
  },
  et: {
    alpha2: 'et',
    'alpha3-b': 'est',
    English: 'Estonian'
  },
  ee: {
    alpha2: 'ee',
    'alpha3-b': 'ewe',
    English: 'Ewe'
  },
  fo: {
    alpha2: 'fo',
    'alpha3-b': 'fao',
    English: 'Faroese'
  },
  fj: {
    alpha2: 'fj',
    'alpha3-b': 'fij',
    English: 'Fijian'
  },
  fi: {
    alpha2: 'fi',
    'alpha3-b': 'fin',
    English: 'Finnish'
  },
  fr: {
    alpha2: 'fr',
    'alpha3-b': 'fre',
    English: 'French'
  },
  fy: {
    alpha2: 'fy',
    'alpha3-b': 'fry',
    English: 'Western Frisian'
  },
  ff: {
    alpha2: 'ff',
    'alpha3-b': 'ful',
    English: 'Fulah'
  },
  ka: {
    alpha2: 'ka',
    'alpha3-b': 'geo',
    English: 'Georgian'
  },
  de: {
    alpha2: 'de',
    'alpha3-b': 'ger',
    English: 'German'
  },
  gd: {
    alpha2: 'gd',
    'alpha3-b': 'gla',
    English: 'Gaelic; Scottish Gaelic'
  },
  ga: {
    alpha2: 'ga',
    'alpha3-b': 'gle',
    English: 'Irish'
  },
  gl: {
    alpha2: 'gl',
    'alpha3-b': 'glg',
    English: 'Galician'
  },
  gv: {
    alpha2: 'gv',
    'alpha3-b': 'glv',
    English: 'Manx'
  },
  el: {
    alpha2: 'el',
    'alpha3-b': 'gre',
    English: 'Greek, Modern (1453-)'
  },
  gn: {
    alpha2: 'gn',
    'alpha3-b': 'grn',
    English: 'Guarani'
  },
  gu: {
    alpha2: 'gu',
    'alpha3-b': 'guj',
    English: 'Gujarati'
  },
  ht: {
    alpha2: 'ht',
    'alpha3-b': 'hat',
    English: 'Haitian; Haitian Creole'
  },
  ha: {
    alpha2: 'ha',
    'alpha3-b': 'hau',
    English: 'Hausa'
  },
  he: {
    alpha2: 'he',
    'alpha3-b': 'heb',
    English: 'Hebrew'
  },
  hz: {
    alpha2: 'hz',
    'alpha3-b': 'her',
    English: 'Herero'
  },
  hi: {
    alpha2: 'hi',
    'alpha3-b': 'hin',
    English: 'Hindi'
  },
  ho: {
    alpha2: 'ho',
    'alpha3-b': 'hmo',
    English: 'Hiri Motu'
  },
  hr: {
    alpha2: 'hr',
    'alpha3-b': 'hrv',
    English: 'Croatian'
  },
  hu: {
    alpha2: 'hu',
    'alpha3-b': 'hun',
    English: 'Hungarian'
  },
  ig: {
    alpha2: 'ig',
    'alpha3-b': 'ibo',
    English: 'Igbo'
  },
  is: {
    alpha2: 'is',
    'alpha3-b': 'ice',
    English: 'Icelandic'
  },
  io: {
    alpha2: 'io',
    'alpha3-b': 'ido',
    English: 'Ido'
  },
  ii: {
    alpha2: 'ii',
    'alpha3-b': 'iii',
    English: 'Sichuan Yi; Nuosu'
  },
  iu: {
    alpha2: 'iu',
    'alpha3-b': 'iku',
    English: 'Inuktitut'
  },
  ie: {
    alpha2: 'ie',
    'alpha3-b': 'ile',
    English: 'Interlingue; Occidental'
  },
  ia: {
    alpha2: 'ia',
    'alpha3-b': 'ina',
    English: 'Interlingua (International Auxiliary Language Association)'
  },
  id: {
    alpha2: 'id',
    'alpha3-b': 'ind',
    English: 'Indonesian'
  },
  ik: {
    alpha2: 'ik',
    'alpha3-b': 'ipk',
    English: 'Inupiaq'
  },
  it: {
    alpha2: 'it',
    'alpha3-b': 'ita',
    English: 'Italian'
  },
  jv: {
    alpha2: 'jv',
    'alpha3-b': 'jav',
    English: 'Javanese'
  },
  ja: {
    alpha2: 'ja',
    'alpha3-b': 'jpn',
    English: 'Japanese'
  },
  kl: {
    alpha2: 'kl',
    'alpha3-b': 'kal',
    English: 'Kalaallisut; Greenlandic'
  },
  kn: {
    alpha2: 'kn',
    'alpha3-b': 'kan',
    English: 'Kannada'
  },
  ks: {
    alpha2: 'ks',
    'alpha3-b': 'kas',
    English: 'Kashmiri'
  },
  kr: {
    alpha2: 'kr',
    'alpha3-b': 'kau',
    English: 'Kanuri'
  },
  kk: {
    alpha2: 'kk',
    'alpha3-b': 'kaz',
    English: 'Kazakh'
  },
  km: {
    alpha2: 'km',
    'alpha3-b': 'khm',
    English: 'Central Khmer'
  },
  ki: {
    alpha2: 'ki',
    'alpha3-b': 'kik',
    English: 'Kikuyu; Gikuyu'
  },
  rw: {
    alpha2: 'rw',
    'alpha3-b': 'kin',
    English: 'Kinyarwanda'
  },
  ky: {
    alpha2: 'ky',
    'alpha3-b': 'kir',
    English: 'Kirghiz; Kyrgyz'
  },
  kv: {
    alpha2: 'kv',
    'alpha3-b': 'kom',
    English: 'Komi'
  },
  kg: {
    alpha2: 'kg',
    'alpha3-b': 'kon',
    English: 'Kongo'
  },
  ko: {
    alpha2: 'ko',
    'alpha3-b': 'kor',
    English: 'Korean'
  },
  kj: {
    alpha2: 'kj',
    'alpha3-b': 'kua',
    English: 'Kuanyama; Kwanyama'
  },
  ku: {
    alpha2: 'ku',
    'alpha3-b': 'kur',
    English: 'Kurdish'
  },
  lo: {
    alpha2: 'lo',
    'alpha3-b': 'lao',
    English: 'Lao'
  },
  la: {
    alpha2: 'la',
    'alpha3-b': 'lat',
    English: 'Latin'
  },
  lv: {
    alpha2: 'lv',
    'alpha3-b': 'lav',
    English: 'Latvian'
  },
  li: {
    alpha2: 'li',
    'alpha3-b': 'lim',
    English: 'Limburgan; Limburger; Limburgish'
  },
  ln: {
    alpha2: 'ln',
    'alpha3-b': 'lin',
    English: 'Lingala'
  },
  lt: {
    alpha2: 'lt',
    'alpha3-b': 'lit',
    English: 'Lithuanian'
  },
  lb: {
    alpha2: 'lb',
    'alpha3-b': 'ltz',
    English: 'Luxembourgish; Letzeburgesch'
  },
  lu: {
    alpha2: 'lu',
    'alpha3-b': 'lub',
    English: 'Luba-Katanga'
  },
  lg: {
    alpha2: 'lg',
    'alpha3-b': 'lug',
    English: 'Ganda'
  },
  mk: {
    alpha2: 'mk',
    'alpha3-b': 'mac',
    English: 'Macedonian'
  },
  mh: {
    alpha2: 'mh',
    'alpha3-b': 'mah',
    English: 'Marshallese'
  },
  ml: {
    alpha2: 'ml',
    'alpha3-b': 'mal',
    English: 'Malayalam'
  },
  mi: {
    alpha2: 'mi',
    'alpha3-b': 'mao',
    English: 'Maori'
  },
  mr: {
    alpha2: 'mr',
    'alpha3-b': 'mar',
    English: 'Marathi'
  },
  ms: {
    alpha2: 'ms',
    'alpha3-b': 'may',
    English: 'Malay'
  },
  mg: {
    alpha2: 'mg',
    'alpha3-b': 'mlg',
    English: 'Malagasy'
  },
  mt: {
    alpha2: 'mt',
    'alpha3-b': 'mlt',
    English: 'Maltese'
  },
  mn: {
    alpha2: 'mn',
    'alpha3-b': 'mon',
    English: 'Mongolian'
  },
  na: {
    alpha2: 'na',
    'alpha3-b': 'nau',
    English: 'Nauru'
  },
  nv: {
    alpha2: 'nv',
    'alpha3-b': 'nav',
    English: 'Navajo; Navaho'
  },
  nr: {
    alpha2: 'nr',
    'alpha3-b': 'nbl',
    English: 'Ndebele, South; South Ndebele'
  },
  nd: {
    alpha2: 'nd',
    'alpha3-b': 'nde',
    English: 'Ndebele, North; North Ndebele'
  },
  ng: {
    alpha2: 'ng',
    'alpha3-b': 'ndo',
    English: 'Ndonga'
  },
  ne: {
    alpha2: 'ne',
    'alpha3-b': 'nep',
    English: 'Nepali'
  },
  nn: {
    alpha2: 'nn',
    'alpha3-b': 'nno',
    English: 'Norwegian Nynorsk; Nynorsk, Norwegian'
  },
  nb: {
    alpha2: 'nb',
    'alpha3-b': 'nob',
    English: 'Bokmål, Norwegian; Norwegian Bokmål'
  },
  no: {
    alpha2: 'no',
    'alpha3-b': 'nor',
    English: 'Norwegian'
  },
  ny: {
    alpha2: 'ny',
    'alpha3-b': 'nya',
    English: 'Chichewa; Chewa; Nyanja'
  },
  oc: {
    alpha2: 'oc',
    'alpha3-b': 'oci',
    English: 'Occitan (post 1500)'
  },
  oj: {
    alpha2: 'oj',
    'alpha3-b': 'oji',
    English: 'Ojibwa'
  },
  or: {
    alpha2: 'or',
    'alpha3-b': 'ori',
    English: 'Oriya'
  },
  om: {
    alpha2: 'om',
    'alpha3-b': 'orm',
    English: 'Oromo'
  },
  os: {
    alpha2: 'os',
    'alpha3-b': 'oss',
    English: 'Ossetian; Ossetic'
  },
  pa: {
    alpha2: 'pa',
    'alpha3-b': 'pan',
    English: 'Panjabi; Punjabi'
  },
  fa: {
    alpha2: 'fa',
    'alpha3-b': 'per',
    English: 'Persian'
  },
  pi: {
    alpha2: 'pi',
    'alpha3-b': 'pli',
    English: 'Pali'
  },
  pl: {
    alpha2: 'pl',
    'alpha3-b': 'pol',
    English: 'Polish'
  },
  pt: {
    alpha2: 'pt',
    'alpha3-b': 'por',
    English: 'Portuguese'
  },
  ps: {
    alpha2: 'ps',
    'alpha3-b': 'pus',
    English: 'Pushto; Pashto'
  },
  qu: {
    alpha2: 'qu',
    'alpha3-b': 'que',
    English: 'Quechua'
  },
  rm: {
    alpha2: 'rm',
    'alpha3-b': 'roh',
    English: 'Romansh'
  },
  ro: {
    alpha2: 'ro',
    'alpha3-b': 'rum',
    English: 'Romanian; Moldavian; Moldovan'
  },
  rn: {
    alpha2: 'rn',
    'alpha3-b': 'run',
    English: 'Rundi'
  },
  ru: {
    alpha2: 'ru',
    'alpha3-b': 'rus',
    English: 'Russian'
  },
  sg: {
    alpha2: 'sg',
    'alpha3-b': 'sag',
    English: 'Sango'
  },
  sa: {
    alpha2: 'sa',
    'alpha3-b': 'san',
    English: 'Sanskrit'
  },
  si: {
    alpha2: 'si',
    'alpha3-b': 'sin',
    English: 'Sinhala; Sinhalese'
  },
  sk: {
    alpha2: 'sk',
    'alpha3-b': 'slo',
    English: 'Slovak'
  },
  sl: {
    alpha2: 'sl',
    'alpha3-b': 'slv',
    English: 'Slovenian'
  },
  se: {
    alpha2: 'se',
    'alpha3-b': 'sme',
    English: 'Northern Sami'
  },
  sm: {
    alpha2: 'sm',
    'alpha3-b': 'smo',
    English: 'Samoan'
  },
  sn: {
    alpha2: 'sn',
    'alpha3-b': 'sna',
    English: 'Shona'
  },
  sd: {
    alpha2: 'sd',
    'alpha3-b': 'snd',
    English: 'Sindhi'
  },
  so: {
    alpha2: 'so',
    'alpha3-b': 'som',
    English: 'Somali'
  },
  st: {
    alpha2: 'st',
    'alpha3-b': 'sot',
    English: 'Sotho, Southern'
  },
  es: {
    alpha2: 'es',
    'alpha3-b': 'spa',
    English: 'Spanish; Castilian'
  },
  sc: {
    alpha2: 'sc',
    'alpha3-b': 'srd',
    English: 'Sardinian'
  },
  sr: {
    alpha2: 'sr',
    'alpha3-b': 'srp',
    English: 'Serbian'
  },
  ss: {
    alpha2: 'ss',
    'alpha3-b': 'ssw',
    English: 'Swati'
  },
  su: {
    alpha2: 'su',
    'alpha3-b': 'sun',
    English: 'Sundanese'
  },
  sw: {
    alpha2: 'sw',
    'alpha3-b': 'swa',
    English: 'Swahili'
  },
  sv: {
    alpha2: 'sv',
    'alpha3-b': 'swe',
    English: 'Swedish'
  },
  ty: {
    alpha2: 'ty',
    'alpha3-b': 'tah',
    English: 'Tahitian'
  },
  ta: {
    alpha2: 'ta',
    'alpha3-b': 'tam',
    English: 'Tamil'
  },
  tt: {
    alpha2: 'tt',
    'alpha3-b': 'tat',
    English: 'Tatar'
  },
  te: {
    alpha2: 'te',
    'alpha3-b': 'tel',
    English: 'Telugu'
  },
  tg: {
    alpha2: 'tg',
    'alpha3-b': 'tgk',
    English: 'Tajik'
  },
  tl: {
    alpha2: 'tl',
    'alpha3-b': 'tgl',
    English: 'Tagalog'
  },
  th: {
    alpha2: 'th',
    'alpha3-b': 'tha',
    English: 'Thai'
  },
  bo: {
    alpha2: 'bo',
    'alpha3-b': 'tib',
    English: 'Tibetan'
  },
  ti: {
    alpha2: 'ti',
    'alpha3-b': 'tir',
    English: 'Tigrinya'
  },
  to: {
    alpha2: 'to',
    'alpha3-b': 'ton',
    English: 'Tonga (Tonga Islands)'
  },
  tn: {
    alpha2: 'tn',
    'alpha3-b': 'tsn',
    English: 'Tswana'
  },
  ts: {
    alpha2: 'ts',
    'alpha3-b': 'tso',
    English: 'Tsonga'
  },
  tk: {
    alpha2: 'tk',
    'alpha3-b': 'tuk',
    English: 'Turkmen'
  },
  tr: {
    alpha2: 'tr',
    'alpha3-b': 'tur',
    English: 'Turkish'
  },
  tw: {
    alpha2: 'tw',
    'alpha3-b': 'twi',
    English: 'Twi'
  },
  ug: {
    alpha2: 'ug',
    'alpha3-b': 'uig',
    English: 'Uighur; Uyghur'
  },
  uk: {
    alpha2: 'uk',
    'alpha3-b': 'ukr',
    English: 'Ukrainian'
  },
  ur: {
    alpha2: 'ur',
    'alpha3-b': 'urd',
    English: 'Urdu'
  },
  uz: {
    alpha2: 'uz',
    'alpha3-b': 'uzb',
    English: 'Uzbek'
  },
  ve: {
    alpha2: 've',
    'alpha3-b': 'ven',
    English: 'Venda'
  },
  vi: {
    alpha2: 'vi',
    'alpha3-b': 'vie',
    English: 'Vietnamese'
  },
  vo: {
    alpha2: 'vo',
    'alpha3-b': 'vol',
    English: 'Volapük'
  },
  cy: {
    alpha2: 'cy',
    'alpha3-b': 'wel',
    English: 'Welsh'
  },
  wa: {
    alpha2: 'wa',
    'alpha3-b': 'wln',
    English: 'Walloon'
  },
  wo: {
    alpha2: 'wo',
    'alpha3-b': 'wol',
    English: 'Wolof'
  },
  xh: {
    alpha2: 'xh',
    'alpha3-b': 'xho',
    English: 'Xhosa'
  },
  yi: {
    alpha2: 'yi',
    'alpha3-b': 'yid',
    English: 'Yiddish'
  },
  yo: {
    alpha2: 'yo',
    'alpha3-b': 'yor',
    English: 'Yoruba'
  },
  za: {
    alpha2: 'za',
    'alpha3-b': 'zha',
    English: 'Zhuang; Chuang'
  },
  zu: {
    alpha2: 'zu',
    'alpha3-b': 'zul',
    English: 'Zulu'
  }
};

export const getLanguagesOptions = () =>
  Object.values(languages).map(obj => ({ value: obj.alpha2, label: obj.English }));

export default languages;
