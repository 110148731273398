import styled from '@emotion/styled';
import Icon from 'common/Icon';
import React from 'react';
import { NavLink } from 'react-router-dom';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

const StyledSidebarNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
  box-sizing: border-box;
  background: transparent;
  border: none;
  border-radius: 8px;
  padding: 12px;
  text-decoration: none;
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.6);
  text-transform: initial;
  white-space: nowrap;

  &.active {
    color: #fff;
    background: rgba(204, 213, 255, 0.11);
    .link-icon {
      path {
        fill: #fff;
      }
    }
  }

  & path {
    fill: rgba(230, 234, 255, 0.6);
  }
  &:not(.active):hover {
    background: rgba(204, 213, 255, 0.05);
  }
  &:hover {
    box-shadow: none;
    & path {
      fill: rgba(230, 234, 255, 0.6);
    }
  }
  &:disabled {
    background: transparent;
  }

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    width: 100%;
  }

  @media ${pageSize.XS}, ${pageSize.S} {
    padding: 9px 12px;
    & .link-icon {
      display: none;
    }
  }
  @media ${pageSize.XS} {
    width: max-content;
    justify-content: center;
  }
`;

const SidebarNavLink = ({ children, icon, to = '#', ...rest }) => (
  <StyledSidebarNavLink to={to} {...rest}>
    {icon && <Icon className="link-icon" icon={icon} />}
    {children}
  </StyledSidebarNavLink>
);
export default SidebarNavLink;
