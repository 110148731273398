import React, { useEffect, useState } from 'react';

import { Wrapper } from './RichTextEditor.styled';

import QuillEditor from 'react-quill';
import useHtmlToText from 'hooks/useHtmlToText';

const removeBackslashesAndQuotes = str => {
  return str?.replace(/^\\*"|\\*"$/g, '') || '';
};

const RichTextEditor = ({ containerStyle, hasError = false, readOnly, maxLength = 100, value, onChange, ...rest }) => {
  const [length, setLength] = useState(0);

  const { extractTextFromHTML } = useHtmlToText();

  const handleKeyDown = e => {
    if (maxLength) {
      const text = extractTextFromHTML(value ?? '');
      if (text.length >= maxLength && e.key !== 'Backspace') {
        e.preventDefault();
      }
    }
  };

  const handleChange = value => {
    const text = extractTextFromHTML(value);
    if (!text.length) {
      onChange?.('');
    } else {
      onChange?.(value);
    }
  };

  useEffect(() => {
    if (maxLength) {
      const text = extractTextFromHTML(value ?? '');
      setLength(text.length);
    }
  }, [maxLength, value, extractTextFromHTML]);

  console.log('readOnly', readOnly);
  if (readOnly) {
    return (
      <div style={containerStyle}>
        <QuillEditor value={removeBackslashesAndQuotes(value)} {...rest} readOnly theme="bubble" />
      </div>
    );
  }

  return (
    <Wrapper style={containerStyle ?? {}} className={hasError ? 'quill-error' : ''}>
      <QuillEditor
        onKeyDown={handleKeyDown}
        value={removeBackslashesAndQuotes(value)}
        onChange={handleChange}
        {...rest}
      />
      {maxLength && <p className="limit">{`${length}/${maxLength}`}</p>}
    </Wrapper>
  );
};

export default RichTextEditor;
