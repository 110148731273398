import styled from '@emotion/styled';

export const AnchorOutline = styled.a`
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid var(--white-white-35, rgba(229, 234, 255, 0.35));
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
`;
