import * as notificationActionTypes from './notificationActionTypes';
import update from 'lodash/fp/update';
import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';
import unset from 'lodash/fp/unset';
import get from 'lodash/fp/get';
import normalize from 'utils/normalize';

const initialState = {};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActionTypes.SET_NOTIFICATIONS: {
      const { notificationType, notifications, profileId } = action.payload;
      return update(
        `${profileId}.${notificationType}.data`,
        values => assign(values ?? {}, normalize(notifications)),
        state
      );
    }

    case notificationActionTypes.SET_NOTIFICATIONS_PAGINATION: {
      const { notificationType, pagination, profileId } = action.payload;
      return update(`${profileId}.${notificationType}.pagination`, values => assign(values ?? {}, pagination), state);
    }

    case notificationActionTypes.SET_UNSEEN_NOTIFICATIONS_COUNT: {
      const { profileId, unseenCommunityCount, unseenJobsCount, unseenProfileCount } = action.payload;
      return update(
        `${profileId}`,
        values => assign(values, { unseenCommunityCount, unseenJobsCount, unseenProfileCount }),
        state
      );
    }

    case notificationActionTypes.UPDATE_NOTIFICATIONS: {
      const { notificationId, notificationType, profileId, property, value } = action.payload;
      return update(
        `${profileId}.${notificationType}.data.${notificationId}`,
        values => set(`${property}`, value, values),
        state
      );
    }

    case notificationActionTypes.ADD_NOTIFICATION: {
      const { notification, profileId } = action.payload;
      const { type } = notification;

      if (!state[profileId]) {
        return state;
      }

      const tabDataLoaded = state[profileId][type] && Object.values(state[profileId][type].data).length > 0;
      const unseenKey = `unseen${type.charAt(0).toUpperCase()}${type.substring(1)}Count`;

      const allData = !get(`${profileId}.all.data.${notification._id}`, state)
        ? {
            [notification._id]: notification,
            ...state[profileId].all.data
          }
        : set(`${notification._id}`, notification, state[profileId].all.data);

      const typeData = !get(`${profileId}.${type}.data.${notification._id}`, state)
        ? {
            [notification._id]: notification,
            ...state[profileId].all.data
          }
        : set(`${notification._id}`, notification, state[profileId][type].data);

      let updateQuery = {
        all: {
          ...state[profileId].all,
          data: allData
        },
        [unseenKey]: ++state[profileId][unseenKey]
      };

      if (tabDataLoaded) {
        updateQuery = {
          ...updateQuery,
          [type]: {
            ...state[profileId][type],
            data: typeData
          }
        };
      }

      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          ...updateQuery
        }
      };
    }

    case notificationActionTypes.SET_NOTIFICATION_AS_READ: {
      const { notificationId, profileId, type } = action.payload;
      const allIncludes = state[profileId].all.data[notificationId];
      const tabIncludes = state[profileId][type] && state[profileId][type].data[notificationId];

      let updateQuery = {};

      if (allIncludes) {
        updateQuery = {
          all: {
            ...state[profileId].all,
            data: {
              ...state[profileId].all.data,
              [notificationId]: {
                ...state[profileId].all.data[notificationId],
                readAt: new Date()
              }
            }
          }
        };
      }

      if (tabIncludes) {
        updateQuery = {
          ...updateQuery,
          [type]: {
            ...state[profileId][type],
            data: {
              ...state[profileId][type].data,
              [notificationId]: {
                ...state[profileId][type].data[notificationId],
                readAt: new Date()
              }
            }
          }
        };
      }

      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          ...updateQuery
        }
      };
    }

    case notificationActionTypes.SET_NOTIFICATIONS_AS_SEEN: {
      const { profileId } = action.payload;

      const updateQuery = {
        unseenCommunityCount: 0,
        unseenJobsCount: 0,
        unseenProfileCount: 0
      };
      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          ...updateQuery
        }
      };
    }

    case notificationActionTypes.DELETE_NOTIFICATION: {
      const { notification, profileId } = action.payload;
      const { _id: notificationId, type } = notification;

      const unseenKey = `unseen${type.charAt(0).toUpperCase()}${type.substring(1)}Count`;
      const unseenCount = --state[profileId][unseenKey];

      const allData = get(`${profileId}.all.data`, state);
      const tabData = get(`${profileId}.${type}.data`, state);

      const allIncludes = get(`${notificationId}`, allData);
      const tabIncludes = get(`${notificationId}`, tabData);

      let updateQuery = {
        [unseenKey]: unseenCount > 0 ? unseenCount : 0
      };

      if (allIncludes) {
        updateQuery = {
          ...updateQuery,
          all: {
            ...state[profileId].all,
            data: unset(`${notificationId}`, allData)
          }
        };
      }

      if (tabIncludes) {
        updateQuery = {
          ...updateQuery,
          [type]: {
            ...state[profileId][type],
            data: unset(`${notificationId}`, tabData)
          }
        };
      }

      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          ...updateQuery
        }
      };
    }

    case notificationActionTypes.CLEAR_NOTIFICATIONS:
      return initialState;

    default:
      return state;
  }
};

export default notificationReducer;
