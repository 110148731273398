import styled from '@emotion/styled';
import FormCheckbox from 'common/form/FormCheckbox';
import FormSelect from 'common/form/FormSelect';
import commonStyles from '../../../../utils/common-styles';
import { pageSize } from '../../../../utils/devices';

export const StyledFormCheckbox = styled(FormCheckbox)`
  display: flex;

  .checkbox {
    border-radius: 4px;
  }
`;

export const StyledHelperFormCheckbox = styled(FormCheckbox)`
  .checkbox {
    border-radius: 4px;
  }
`;

export const CompensationBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  flex-wrap: nowrap;
  margin-top: 6px;

  @media ${pageSize.XS} {
    flex-wrap: wrap;
  }
`;

export const CompensationNumbers = styled.div`
  display: flex;
  gap: 8px;
  width: 66%;
  align-items: flex-start;

  @media ${pageSize.XS} {
    width: 100%;
  }
`;

export const CompensationSelect = styled(FormSelect)`
  @media ${pageSize.XS} {
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  display: flex;
  flex-direction: row;
  color: white;
  ${props => (props.disabled ? 'color: rgba(230, 234, 255, 0.35);' : '')}
  font-family: ${props => (props.familyInter ? 'inter' : 'Space Grotesk')};
  font-size: ${props => (props.fontSize ? '16px' : '')};
  font-weight: ${props => (props.fontWeight ? '400' : '')};
  line-height: ${props => (props.lineHeight ? '16px' : '')};
  ${props => props.boldWeight && 'font-weight: 700;'}
`;

export const StyledHintText = styled.div`
  ${commonStyles.ui_text_small};
  color: ${({ hintError }) => (hintError ? '#FF5151' : 'rgba(230, 234, 255, 0.35)')};
  ${props => props.lightWeight && 'font-weight: 400;'}
  clear: both;
`;

export const RequiredInput = styled.span`
  color: rgba(229, 234, 255, 0.35);
  padding-left: 3px !important;
  &::after {
    content: '*';
  }
  ${props => (props.errorRequired ? 'color: #FF5151' : 'color: rgba(229,234,255,0.35)')}
`;
