import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { portalActions } from 'store/portals';
import { HeaderSubtitle, SpacedRow, StyledTextContent, VerifyIdWrapper } from './Steps.styled';
import ContainedButton from 'common/ContainedButton';

const VerifyYourId = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(portalActions.closePortal({ name: 'quick-onboarding-modal' }));
  }, []);

  return (
    <VerifyIdWrapper>
      <SpacedRow>
        <HeaderSubtitle>Skip ID verification?</HeaderSubtitle>
        <ContainedButton icon="close" backgroundColor="transparent" onClick={handleClick} />
      </SpacedRow>
      <StyledTextContent>
        Completing ID verification will let you join events. Are you sure you want to skip?.
      </StyledTextContent>
    </VerifyIdWrapper>
  );
};

export default VerifyYourId;
