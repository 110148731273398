import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Talk from 'talkjs';
import get from 'lodash/fp/get';
import { profileActions, profileSelectors } from 'store/profile';
import useChatUserService from 'hooks/chatApi/useChatUser';
import useChatConversationService from 'hooks/chatApi/useChatConversation';
import chatUserService from '../chatApi/chatUserService';
import { compose, find, flatten, keys, map, pluck, prop, propEq, uniq } from 'ramda';

const LIMIT = 15;

const defaultProps = {
  newMessageMode: false
};

const useTalkSession = ({ newMessageMode } = defaultProps) => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const isConversationsPage = pathname.includes('conversations');

  const { createUser, getUserConversations } = useChatUserService();
  const { getMessages, updateConversation } = useChatConversationService();

  const [newMessage, setNewMessage] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const myProfile = useSelector(profileSelectors.selectMyProfile);
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const conversations = get('conversations', myProfile) ?? [];
  const conversationsArray = Object.values(conversations);
  const currentProfileSignature = get('talk_signature', myProfile);
  const currentUserName = get('name', myProfile);
  const currentUserAvatar = get('basicInfo', myProfile);

  const fetchConversations = async () => {
    const data = await getUserConversations({ userId: profileId, startingAfter: '', limit: LIMIT });
    const query = new URLSearchParams(search);

    const getTalentIds = compose(uniq, flatten, map(compose(keys, prop('participants'))));

    const talentIds = getTalentIds(data);

    const promises = map(chatUserService.getUser)(talentIds);
    const result = await Promise.all(promises);
    const users = pluck('data', result);

    const normalized = await data.reduce((curr, item) => {
      const participants = JSON.parse(item.custom.participants);

      const meId = participants.me.id;
      const recipientId = participants.recipient.id;

      return {
        ...curr,
        [item.id]: {
          ...item,
          messages: [],
          custom: {
            participants: {
              me: find(propEq('id', meId))(users),
              recipient: find(propEq('id', recipientId))(users)
            },
            unreadMessages: item.unreadMessageCount,
            job: item.custom.job ? JSON.parse(item.custom.job) : null
          },
          typing: []
        }
      };
    }, {});
    dispatch(
      profileActions.updateProfileProperty({
        property: 'conversations',
        value: normalized,
        profileId
      })
    );

    if (query && query.get('id')) {
      setSelectedConversation(query.get('id'));
    } else if (isConversationsPage && data.length > 0) {
      setSelectedConversation(data[0].id);
    }
  };

  const startSession = () => {
    if (profileId) {
      createUser(profileId, { name: currentUserName, photoUrl: currentUserAvatar?.imageUri?.url });

      Talk.ready.then(() => {
        const currentUserData = {
          id: profileId,
          name: currentUserName,
          photoUrl: currentUserAvatar?.imageUri?.url,
          role: 'default'
        };
        const me = new Talk.User(currentUserData);
        window.talkSession = new Talk.Session({
          appId: process.env.REACT_APP_TALK_JS_APP_ID,
          me,
          signature: currentProfileSignature
        });
        window.talkSession.on('message', async data => {
          const messages = await getMessages(data.conversation.id, '', 1);

          handleNewMessage({ ...data, id: messages[0].id });
        });
        if (Object.values(conversations).length === 0) {
          fetchConversations();
        }
      });
    }
  };

  const endSession = () => {
    window.talkSession && window.talkSession.destroy();
  };

  const handleNewMessage = message => {
    dispatch(profileActions.newMessage({ profileId, message }));
    setNewMessage(message);
  };

  useEffect(() => {
    if (newMessage) {
      if (isConversationsPage) {
        const targetedConversationId = newMessage.conversation.id;
        const targetedConversation = conversations[targetedConversationId];
        if (targetedConversation) {
          const { ...others } = conversations;
          const updated = {
            [targetedConversationId]: targetedConversation,
            ...others
          };
          dispatch(
            profileActions.updateProfileProperty({
              property: `conversations`,
              value: updated,
              profileId
            })
          );
        }
        setSelectedConversation(targetedConversationId);
      }
      if (!newMessage.isByMe) {
        const targetedConversation = conversations[newMessage.conversation.id];
        if (targetedConversation) {
          updateConversation(newMessage.conversation.id, {
            custom: {
              unreadMessages: JSON.stringify(targetedConversation.custom.unreadMessages + 1)
            }
          });
          dispatch(
            profileActions.updateProfileProperty({
              property: `conversations.${newMessage.conversation.id}.custom.unreadMessages`,
              value: targetedConversation.custom.unreadMessages + 1,
              profileId: profileId
            })
          );
        }
      }
    }
  }, [newMessage]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const searchId = query.get('id');

    if (searchId) {
      setSelectedConversation(searchId);
    }
    if (
      !searchId &&
      (!selectedConversation || typeof selectedConversation === 'object') &&
      conversationsArray.length > 0 &&
      !newMessageMode
    ) {
      setSelectedConversation(conversationsArray[0].id);
    }
  }, [pathname, search, conversationsArray]);

  return {
    selectedConversation,
    startSession,
    endSession,
    setSelectedConversation,
    fetchConversations
  };
};

export default useTalkSession;
