const commercialEntities = [
  {
    value: 'LLC',
    label: 'LLC'
  },
  {
    value: 'CCORP',
    label: 'C-corp'
  },
  {
    value: 'ETC',
    label: 'Etc'
  }
];

export default commercialEntities;
