import Tooltip from 'components/Tooltip';
import isString from 'lodash/isString';
import React, { useEffect, useRef, useState } from 'react';
import { isOverflowing } from 'utils';
import Typography from './Typography';

const LimitedTypography = ({ children, limit, text, tooltip = true, tooltipId, ...rest }) => {
  const ref = useRef();

  const [showTooltip, setShowTooltip] = useState(false);

  let fullText = text;
  if (!text) {
    fullText = isString(children) ? children : '';
  }

  const limitedText = limit ? `${fullText.substr(0, limit)}...` : fullText;

  useEffect(() => {
    if (ref.current && !limit) {
      const el = ref.current;
      if (isOverflowing(el)) {
        !showTooltip && setShowTooltip(true);
      } else {
        showTooltip && setShowTooltip(false);
      }
    }
  }, [ref.current, window.innerWidth]);

  return (
    <>
      <Typography data-tip data-for={tooltipId} textRef={ref} className="limited-text" {...rest}>
        {limitedText}
      </Typography>
      {tooltip && (showTooltip || limit) && (
        <Tooltip tooltipId={tooltipId} className="secondary">
          {fullText}
        </Tooltip>
      )}
    </>
  );
};

export default LimitedTypography;
