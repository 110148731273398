import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Icon from '../../../../common/Icon';

const StyledEmptyContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  min-height: 483px;
`;

const CandidateIcon = styled(Icon)`
  width: 64px;
  height: 56px;
`;

const StyledText = styled.div`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 32px;
  color: rgba(230, 234, 255, 0.6);
  width: 296px;
  text-align: center;
`;

const ApplicantsEmpty = ({ state }) => {
  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState('');

  useEffect(() => {
    switch (state) {
      case 'saved':
        setMessage('You have no shortlisted candidates. Use the shortlist feature to add your preferred candidates');
        setIcon('candidatesShortlist');
        break;
      case 'hired':
        setMessage('You have not sent a proposal to any candidates yet');
        setIcon('candidatesSelected');
        break;
      default:
        setMessage('Candidates for this job will appear here');
        setIcon('candidates');
    }
  }, [state]);

  return (
    <StyledEmptyContainer>
      <CandidateIcon icon={icon} />
      <StyledText>{message}</StyledText>
    </StyledEmptyContainer>
  );
};

export default ApplicantsEmpty;
