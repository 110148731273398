import Api from './api';

const api = new Api('backoffice/vetting');

const getVetting = id => api.get(`/${id}`);

const addCommentToVetting = (id, params = {}) => api.post(`/${id}/comment`, { params });

const updateVettingStatus = (id, params = {}) => api.put(`/${id}`, { params });

const updateVettingLog = (id, data) => api.put(`/vetting-log/${id}`, data);

export default {
  getVetting,
  addCommentToVetting,
  updateVettingStatus,
  updateVettingLog
};
