import TextEditorModal from 'common/TextEditorModal';
import unionBy from 'lodash/fp/unionBy';
import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { isObjectEmpty } from 'utils/isObjectEmpty';
import JobAtGlance from '../JobAtGlance';
import JobAtGlanceModal from '../JobAtGlance/JobAtGlanceModal';
import JobLife from '../JobLife/JobLife';
import JobLifeModal from '../JobLife/JobLifeModal';
import JobOverview from '../JobOverview';
import JobProject from '../JobProject';
import JobProjectModal from '../JobProject/JobProjectModal';
import JobRole from '../JobRole';
import JobThumbnail from '../JobThumbnail';
import JobThumbnailModal from '../JobThumbnail/JobThumbnailModal';
import { FormContainer, FormField, FormFieldsWrapper } from './styled.components';
import { FixedFooter } from '../AddJob/styled.components';
import StickyJoinBanner from 'components/StickyJoinBanner/StickyJoinBanner.component';
import { authSelectors } from 'store/auth';

const JobForm = ({
  alreadyApplied,
  errors,
  handleApplyClick,
  handleWithdrawClick,
  hasEditPermission,
  handleReview,
  isClosed,
  isContractActive = false,
  jobId,
  loading,
  onFieldChange,
  profile,
  talentId,
  values,
  viewMode
}) => {
  const isAuthed = useSelector(authSelectors.isAuthed);
  const isCompany = useSelector(profileSelectors.selectIsCompanyView);

  const role = {
    youWill: values.youWill,
    youHave: values.youHave,
    bonus: values.bonus,
    genres: values.genres,
    skills: values.skills,
    tags: values.tags,
    tools: values.tools
  };

  const roleErrors = {
    youWill: errors.youWill,
    youHave: errors.youHave
  };

  const setTags = projects => {
    const tags = projects.reduce(
      (tagsObj, project) => {
        tagsObj.tools = [...tagsObj.tools, ...project.tools];
        tagsObj.skills = [...tagsObj.skills, ...project.skills];
        tagsObj.tags = [...tagsObj.tags, ...project.tags];

        return tagsObj;
      },
      { tools: [], skills: [], tags: [] }
    );

    onFieldChange('tools')(unionBy('_id', values.tools, tags.tools));
    onFieldChange('skills')(unionBy('_id', values.skills, tags.skills));
    onFieldChange('tags')(unionBy('_id', values.tags, tags.tags));
  };

  const onProjectsSave = projects => {
    setTags(projects);
    onFieldChange('projects')(projects);
  };

  const afterProjectEdit = project => {
    const updatedProjects = [...values.projects];
    const projectToUpdateIndex = updatedProjects.findIndex(item => item._id === project._id);
    updatedProjects[projectToUpdateIndex] = {
      ...updatedProjects[projectToUpdateIndex],
      ...project,
      platforms: project.platforms.map(item => item.item ?? item),
      genres: project.genres.map(item => item.item ?? item),
      tags: project.tags.map(item => item.item ?? item)
    };
    setTags(updatedProjects);
    onFieldChange('projects')(updatedProjects);
  };

  const afterProjectAdd = project => {
    setTags([...values.projects, project]);
    onFieldChange('projects')([...values.projects, project]);
  };

  const afterProjectDelete = projectId => {
    const updatedProjects = values.projects.filter(item => item._id !== projectId);
    onFieldChange('projects')(updatedProjects);
  };

  return (
    <FormContainer>
      <FormFieldsWrapper>
        <FormField fullWidth>
          <JobThumbnail
            thumbnail={values.thumbnail}
            title={values.title}
            errorTitle={errors.title}
            hasEditPermission={hasEditPermission}
            profile={profile}
            onFieldChange={onFieldChange}
          />
        </FormField>
        <FormField fullWidth>
          {(!viewMode || !isObjectEmpty(values.details)) && (
            <JobAtGlance hasEditPermission={hasEditPermission} details={values.details} error={errors.details} />
          )}
        </FormField>
        <FormField id="job_overview" className="overview-required" fullWidth>
          {(!viewMode || values.overview) && (
            <JobOverview hasEditPermission={hasEditPermission} overview={values.overview} error={errors.overview} />
          )}
        </FormField>
        <FormField id="project" className="project-required" fullWidth>
          {(!viewMode || (!!values.projects.length && !!values.projects[0].name?.length)) && (
            <JobProject
              hasEditPermission={hasEditPermission}
              projects={values.projects}
              afterProjectEdit={afterProjectEdit}
              afterProjectAdd={afterProjectAdd}
              afterProjectDelete={afterProjectDelete}
              jobId={jobId}
              viewMode={viewMode}
            />
          )}
        </FormField>
        <FormField id="role" className="role-required" fullWidth>
          {(!viewMode || !isObjectEmpty(role)) && (
            <JobRole
              hasEditPermission={hasEditPermission}
              role={role}
              errors={roleErrors}
              onFieldChange={onFieldChange}
              viewMode={viewMode}
              alreadyApplied={alreadyApplied}
              handleWithdrawClick={handleWithdrawClick}
              handleReview={handleReview}
              handleApplyClick={handleApplyClick}
              isClosed={isClosed}
              isContractActive={isContractActive}
              loading={loading}
            />
          )}
        </FormField>
        {isCompany && (
          <FormField id="categories" className="categories-required" fullWidth>
            <JobLife onFieldChange={onFieldChange} JobCategories={values.categories} />
          </FormField>
        )}
        {!isAuthed && (
          <FixedFooter>
            <StickyJoinBanner
              title="Get your own profile to apply"
              buttonTitle="Join early access"
              url={`${process.env.REACT_APP_NEXT_BASE_URL}/onboarding`}
            />
          </FixedFooter>
        )}
      </FormFieldsWrapper>
      <JobThumbnailModal talentId={talentId} onSave={thumbnail => onFieldChange('thumbnail')(thumbnail)} />
      <JobAtGlanceModal talentId={talentId} onSave={details => onFieldChange('details')(details)} />
      <JobProjectModal onSave={projects => onProjectsSave(projects)} />
      <TextEditorModal onSave={(field, text) => onFieldChange(field)(text)} />
      {isCompany && <JobLifeModal />}
    </FormContainer>
  );
};

export default JobForm;
