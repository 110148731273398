import React from 'react';
import { userService } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import { authActions } from 'store/auth';
import get from 'lodash/fp/get';

const initialLoading = {
  loadingLockProfile: false
};

function useProfileOnBoarding() {
  const dispatch = useDispatch();
  const myProfile = useSelector(profileSelectors.selectMyProfile);
  const profileId = get('_id', myProfile);
  const [loading, setLoading] = React.useState(initialLoading);

  const lockProfile = React.useCallback(async () => {
    if (profileId && !loading.loadingLockProfile) {
      try {
        setLoading({ ...loading, loadingLockProfile: true });
        const {
          data: { funnelStatus, profile }
        } = await userService.lockProfile(profileId);

        dispatch(profileActions.setProfile(profile));

        dispatch(
          authActions.updateCurrentUserProperty({
            property: 'funnelStatus',
            value: funnelStatus,
            profileId,
            updateType: 'update'
          })
        );

        handleOnboardingCompleted();
      } finally {
        setLoading({ ...loading, loadingLockProfile: false });
      }
    }
  }, [profileId]);

  const handleOnboardingCompleted = () => {
    dispatch(
      profileActions.updateProfileProperty({
        property: 'onboardingCompleted',
        value: true,
        profileId,
        updateType: 'update'
      })
    );
  };

  return {
    lockProfile,
    handleOnboardingCompleted
  };
}

export default useProfileOnBoarding;
