import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';

export const StyledCollaborators = styled.div``;

export const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex-wrap: wrap;
  gap: 56px;
  width: 100%;
`;

export const StyledShowMoreContainer = styled.div`
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 12px;
  padding: 18px;
  cursor: pointer;
`;
export const StyledCandidateId = styled.div`
  width: 600px;
  height: 66px;
  background: rgba(204, 213, 255, 0.04);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-family: 'Space Grotesk';
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    color: '#fff';
    margin-left: 24px;
  }

  span {
    font-family: 'Space Grotesk';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(230, 234, 255, 0.6);
    margin-right: 24px;
  }
`;

export const StyledCancelButton = styled(ContainedButton)`
  margin-right: 6px;
  margin-left: 6px;
`;
