import css from '@emotion/css';
import { pageSize } from './devices';

const line_limit = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const line_limit_1 = css`
  ${line_limit}
  -webkit-line-clamp: 1;
`;

export const line_limit_2 = css`
  ${line_limit}
  -webkit-line-clamp: 2;
`;

export const line_limit_3 = css`
  ${line_limit}
  -webkit-line-clamp: 3;
`;

export const heading_1 = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  color: #ffffff;
  word-break: break-word;

  @media ${pageSize.S} {
    font-size: 44px;
    line-height: 48px;
  }
  @media ${pageSize.M} {
    font-size: 44px;
    line-height: 48px;
  }
  @media ${pageSize.L} {
    font-size: 52px;
    line-height: 56px;
  }
`;

export const heading_2 = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;

  @media ${pageSize.S}, ${pageSize.M} {
    font-size: 30px;
    line-height: 32px;
  }
  @media ${pageSize.L} {
    font-size: 36px;
    line-height: 40px;
  }
`;

export const paragraph = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(242, 244, 255, 0.9);

  @media ${pageSize.S} {
    font-size: 20px;
    line-height: 32px;
  }
  @media ${pageSize.M} {
    font-size: 20px;
    line-height: 32px;
  }
  @media ${pageSize.L} {
    font-size: 22px;
    line-height: 36px;
  }
`;

export const card_label = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  word-break: break-word;
`;

export const caption = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_heading_1 = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_heading_2 = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_heading_3 = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_text_medium = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_text_small = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_text_tiny = css`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  word-break: break-word;
`;

export const ui_error_text = css`
  ${ui_text_small}
  color: #FF5151;
`;

export default {
  line_limit_1,
  line_limit_2,
  line_limit_3,
  heading_1,
  heading_2,
  paragraph,
  card_label,
  caption,
  ui_heading_1,
  ui_heading_2,
  ui_heading_3,
  ui_text_medium,
  ui_text_small,
  ui_text_tiny,
  ui_error_text
};
