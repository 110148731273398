import React from 'react';
import FormSelect from './FormSelect';

const FormAsyncSelect = ({ apiCall, error, onChange, value, ...rest }) => {
  const [search, updateSearch] = React.useState(value);

  const onInputChange = newSearch => {
    updateSearch(newSearch);
  };

  const handleChange = (value, opt) => {
    updateSearch('');
    onChange(value, opt);
  };

  const loadOptions = inputValue => apiCall(inputValue);

  return (
    <FormSelect
      isAsync
      loadOptions={loadOptions}
      value={value ? { label: value, value } : null}
      onInputChange={onInputChange}
      onChange={handleChange}
      inputValue={search}
      error={error}
      {...rest}
    />
  );
};

export default FormAsyncSelect;
