import styled from '@emotion/styled';
import FormRadiobutton from 'common/form/FormRadiobutton';

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ScopeFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ScopeDescription = styled.h2`
  font-size: 17px;
  color: rgba(229, 234, 255, 0.6);
  font-style: normal;
`;

export const ScopeRadioButtons = styled.div``;

export const ModalActionsButtons = styled.div`
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 10px;
  }
`;
export const StyledRadioButton = styled(FormRadiobutton)``;
