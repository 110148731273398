import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { profileSelectors } from 'store/profile';
import { pageSize } from 'utils/devices';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import commonStyles from 'utils/common-styles';
import Typography from 'components/shared/Typography';
import LockButton from 'common/lockUnlockButtons/LockButton';
import UnlockPendingButton from 'common/lockUnlockButtons/UnlockPendingButton';
import useProfileUnlock from 'hooks/api/useProfileUnlockService';

const StyledAssetContainer = styled.div`
  max-width: 600px;
  min-width: 200px;
  ${props => props.border && 'border: 5px solid #7266EE'};
  ${({ autoHeight }) => !autoHeight && 'max-height: 672px;'}
  border-radius: 10px;
  position: relative;
  min-height: 337px;

  .bottom-shadow {
    position: absolute;
    bottom: -120px;
    width: 100%;
    min-height: 120px;
    background: linear-gradient(0deg, rgba(14, 14, 15, 0.85) 0%, rgba(14, 14, 15, 0) 100%);
    transition-duration: 0.2s;
  }

  &.video-playing:hover,
  &.video-paused:hover {
    .play-button.hidden {
      display: flex;
    }
  }

  &:hover {
    cursor: pointer;
    .header {
      display: flex;
    }
    .hover-container {
      z-index: 5;
      background: rgba(14, 14, 15, 0.2);
    }
    .top-shadow {
      top: 0px;
    }
    .bottom-shadow {
      bottom: 0px;
    }
    .play {
      transform: scale(1.2);
    }
  }

  @media ${pageSize.XS} {
    max-height: 343px;
  }
`;
const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  ${({ minHeight }) => minHeight && 'min-height: 337px;'}
`;
const StyledIconsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  padding: 10px;
  grid-gap: 10px;
  justify-content: space-between;
  z-index: 6;
  @media ${pageSize.XS}, ${pageSize.S} {
    display: flex;
  }
`;
const StyledLeftIcon = styled(ContainedButton)`
  margin-right: auto;
  & svg path {
    fill-opacity: 1;
  }
`;
const StyledButtonContainer = styled.div`
  display: inline-flex;
  gap: 10px;
  margin-left: auto;
`;
const StyledDeleteIcon = styled(ContainedButton)``;
const StyledExtraIcon = styled(ContainedButton)``;
const HoverContainer = styled.div`
  position: absolute;
  z-index: 5;
  pointer-events: none;
  top: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &:hover {
  }
`;
const HoverTitle = styled.div`
  ${commonStyles.ui_heading_3}
  padding: 48px 24px 8px;
`;
const HoverDescription = styled(Typography)`
  ${commonStyles.ui_text_small}
  padding: 0 24px 24px;
`;

export const AssetContainer = ({
  asset,
  autoHeight,
  border,
  children,
  className,
  dragHandleProps,
  draggableProps,
  extraIconName,
  extraIconOpens,
  extraIconText,
  innerRef,
  loading,
  lockUnlockStatus,
  notClosePortal,
  onClick,
  onRemove,
  project,
  rightIcon
}) => {
  const talentId = useSelector(profileSelectors.selectMyProfileId);

  const { addProfileUnlockRequest } = useProfileUnlock();

  const handleLockBtnClick = e => {
    e.stopPropagation();
    addProfileUnlockRequest({ entityId: project._id, entityType: 'project', talentId });
  };

  return (
    <StyledAssetContainer
      ref={innerRef}
      {...draggableProps}
      border={border}
      onClick={onClick}
      autoHeight={autoHeight}
      minHeight={loading}
      className={className}
    >
      {loading ? (
        <LoadingContainer>
          <Spinner />
          Please Wait...
        </LoadingContainer>
      ) : (
        <>
          <StyledIconsContainer className={'header'}>
            {dragHandleProps && <StyledLeftIcon {...dragHandleProps} icon={rightIcon} backgroundColor="#1A1B1E" />}
            <StyledButtonContainer>
              {extraIconText && (project ? lockUnlockStatus : true) && (
                <StyledExtraIcon
                  backgroundColor="#1A1B1E"
                  opens={extraIconOpens}
                  data={{ ...asset, notClosePortal }}
                  icon={extraIconName}
                >
                  {extraIconText}
                </StyledExtraIcon>
              )}
              {project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'LOCKED' ? (
                <LockButton onClick={handleLockBtnClick} tooltip="To request edit permissions, click here" />
              ) : project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'UNLOCK_REQUESTED' ? (
                <UnlockPendingButton tooltip="Edit request pending" />
              ) : (
                <StyledDeleteIcon
                  onClick={e => {
                    e.stopPropagation();
                    onRemove && onRemove();
                  }}
                  icon="delete"
                  backgroundColor="#1A1B1E"
                />
              )}
            </StyledButtonContainer>
          </StyledIconsContainer>

          {children}

          <HoverContainer>
            <div className="bottom-shadow">
              <HoverTitle>{asset.name}</HoverTitle>
              {asset?.description && asset?.description?.length > 80 ? (
                <HoverDescription>{asset.description.substr(0, 80)}...</HoverDescription>
              ) : (
                <HoverDescription>{asset.description}</HoverDescription>
              )}
            </div>
          </HoverContainer>
        </>
      )}
    </StyledAssetContainer>
  );
};
