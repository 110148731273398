import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

const HiddenAvatarContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(204, 213, 255, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: border 100ms;

  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    width: 64px;
    height: 64px;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid rgba(230, 234, 255, 0.6);
  }
`;

const HiddenAvatarLogoContainer = styled.div`
  width: 36px;
  height: 32px;
  background-color: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { HiddenAvatarContainer, HiddenAvatarLogoContainer };
