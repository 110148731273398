import React from 'react';
import { useSelector } from 'react-redux';

import { profileSelectors } from 'store/profile';

import Projects from '../Projects';
import Credits from '../Credits';

const CompanyProjects = () => {
  const profile = useSelector(profileSelectors.selectProfile);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  return (
    <div>
      <Projects />
      {isCompanyView && (
        <Credits
          margin="16px 0 0"
          chooseFromExisting
          items={profile.credits}
          hintText="Claim your credits for the games that you helped to ship"
          isEditMode={hasEditPermission}
          hideDropDown={isCompanyView}
        />
      )}
    </div>
  );
};

export default CompanyProjects;
