import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';

import TxplModal from 'common/TxplModal';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import { StyledButtonsContainer, StyledCopyButton, StyledFormInput, StyledShareButton } from './StyledJobShareModal';

const JobShareModal = ({ data, name }) => {
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { clipboard } = data;
  const url = clipboard || `${window.location.href}`;

  const handleLinkClick = () => {
    navigator.clipboard.writeText(url);
    openSuccessSnackbar('Link copied');
  };

  return (
    <TxplModal
      name={name}
      size="small"
      title="Share on social media"
      padding="24px 0 0 0"
      titlePadding="16px"
      boldWeight
      allowLine
      XSpadding="16px 0 0 0"
      nonFullScreenOnMobile
      contentPadding="24px 16px 16px 16px"
      appElement={document.getElementById('root-modal')}
    >
      <StyledButtonsContainer>
        <StyledShareButton icon="facebook" backgroundColor="#4267B2" disabled>
          SHARE ON FACEBOOK
        </StyledShareButton>
        <StyledShareButton icon="twitter" backgroundColor="#3F81ED" disabled>
          SHARE ON TWITTER
        </StyledShareButton>
        <StyledShareButton icon="linkedin" backgroundColor="#0277B5" disabled>
          SHARE ON LINKEDIN
        </StyledShareButton>
      </StyledButtonsContainer>
      <StyledFormInput mb={16} pt={40} label="Or copy link" placeholder={url}>
        <StyledCopyButton
          backgroundColor="transparent"
          border="3px solid rgba(221, 226, 255, 0.2)"
          onClick={handleLinkClick}
        >
          COPY
        </StyledCopyButton>
      </StyledFormInput>
    </TxplModal>
  );
};
export default withRenderPortal('job-share-modal')(JobShareModal);
