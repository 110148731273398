import React from 'react';
import { Container, Title } from './ProgressFunnelLoading.styled';
import Loader from 'common/Loader';
import withWidgetCardLayout from '../hocs/WidgetCardLayout';

const ProgressFunnelLoading = ({ loadingText, dataTestId }) => {
  return (
    <Container data-testid={dataTestId}>
      <Loader size={40} />
      <Title>{loadingText}</Title>
    </Container>
  );
};

export default withWidgetCardLayout({ withFooter: true, closable: false })(ProgressFunnelLoading);
