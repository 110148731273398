import styled from '@emotion/styled-base';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import React from 'react';
import { useLocation } from 'react-router';
import { pageSize } from 'utils/devices';

const StyledDropdown = styled(DropDown)`
  &.secondary {
    width: 128px;
  }

  @media ${pageSize.XS} {
    &.secondary {
      width: 100%;
    }
  }
`;

function SearchSwitcher() {
  const { pathname } = useLocation();

  const isJobs = pathname.includes('/jobs/search');
  const isPeoples = pathname.includes('/peoples/search');
  const isCompanies = pathname.includes('/companies/search');

  const text = isJobs ? 'Jobs' : isPeoples ? 'Talent' : 'Companies';

  return (
    <StyledDropdown stretchOnXs className="secondary position-left" text={text} icon="arrow-down">
      {!isJobs && <DropDownItem to="/jobs/search">Jobs</DropDownItem>}
      {!isPeoples && <DropDownItem to="/peoples/search">Talent</DropDownItem>}
      {!isCompanies && <DropDownItem disabled>Companies</DropDownItem>}
    </StyledDropdown>
  );
}

export default SearchSwitcher;
