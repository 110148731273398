import React from 'react';
import PropTypes from 'prop-types';
import TxplModal from 'common/TxplModal';
import Typography from 'components/shared/Typography';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import useProfilePath from 'hooks/useProfilePath';

const CompleteProfileModal = ({ closePortal, data, name }) => {
  const { myProfilePath } = useProfilePath();
  const { saveDraft } = data;

  const handleSaveDraft = () => {
    saveDraft();
    closePortal();
  };

  const handleCheckNextSteps = () => {
    saveDraft(`${myProfilePath}/about?open-progress-funnel=true`);
    closePortal();
  };

  return (
    <TxplModal
      title="Complete your profile to post"
      titleMB="24"
      name={name}
      size="small"
      padding="56px 0 56px"
      noPaddingTop
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <ContainedButton ml={6} mr={6} backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleSaveDraft}>
            keep it as draft
          </ContainedButton>
          <ContainedButton onClick={handleCheckNextSteps}>check next steps</ContainedButton>
        </>
      }
    >
      <Typography variant="medium">
        We are happy you are interested in posting a job! Let&apos;s get your profile completed so you can publish.
      </Typography>
    </TxplModal>
  );
};

CompleteProfileModal.propTypes = {
  closePortal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default withRenderPortal('complete-profile-modal')(CompleteProfileModal);
