import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import DisplaySwitcher from 'common/DisplaySwitcher';
import FormSwitcher from 'common/form/FormSwitcher';
import { Card } from 'common/styles/Card';
import Flex from 'common/styles/Flex';
import useViewport from 'hooks/useViewport';
import React from 'react';
import unset from 'lodash/fp/unset';
import isEmpty from 'lodash/fp/isEmpty';
import useForm from 'hooks/useForm';
import commonStyles from 'utils/common-styles';
import isPropertyPopulated from 'utils/isPropertyPopulated';

const MoreFiltersCount = styled.span`
  ${commonStyles.ui_text_tiny}
  font-weight: 600;
  background: #ff5151;
  border-radius: 8px;
  padding: 0px 4px;
  margin-left: 8px;
`;

const applicantStatus = [
  {
    name: 'Candidates',
    value: 'all'
  },
  {
    name: 'shortlist',
    value: 'saved'
  },
  {
    name: 'Offered',
    value: 'hired'
  }
];

const fields = filters => ({
  discipline: { initialValue: filters.discipline ?? [] },
  location: { initialValue: filters.location ?? [] },
  timezone: { initialValue: filters.timezone ?? {} }
});

const ApplicantsFilter = ({ clearFilters, filters, initialFilters, loading, setFilters }) => {
  const { isL, isXS } = useViewport();
  const [show, setShow] = React.useState(false);
  const { onFieldChange, values } = useForm({
    fields: fields(initialFilters)
  });

  const hasInitialFilter = isPropertyPopulated(unset('applicant-status', initialFilters));
  const isFiltersSelected = isPropertyPopulated(values) || hasInitialFilter;

  const moreFiltersCount = React.useMemo(
    () =>
      Object.keys(initialFilters).reduce((curr, key) => {
        if (key === 'applicant-status') {
          return curr;
        }

        let add = 0;

        if ((key === 'credit' || key === 'projects') && !isEmpty(initialFilters[key])) {
          add = 1;
        }

        if (key === 'tool' || key === 'tag' || key === 'genre' || key === 'skill') {
          add = initialFilters[key].length;
        }

        curr += add;

        return curr;
      }, 0),
    [initialFilters]
  );

  const handleReset = () => {
    Object.keys(values).forEach(key => {
      onFieldChange(key)([]);
    });
    clearFilters && clearFilters();
    setShow(true);
  };

  React.useEffect(() => {
    Object.keys(values).forEach(key => {
      onFieldChange(key)(initialFilters[key]);
    });
  }, [initialFilters]);

  return (
    <Card>
      <Flex gap={8} justifySpaceBetween columnOnXs>
        <div>
          <FormSwitcher
            className="with-border"
            backgroundColor="transparent"
            initialValue={initialFilters['applicant-status'].value}
            options={applicantStatus}
            limit={!isL ? 2 : 4}
            onChange={value =>
              initialFilters['applicant-status'].value !== value &&
              setFilters(items => ({
                ...items,
                'applicant-status': { value }
              }))
            }
          />
        </div>
        <Flex gap={6} flexGrow={'auto'}>
          <ContainedButton
            className="btn-secondary"
            backgroundColor="rgba(204, 213, 255, 0.11)"
            height="40px"
            padding="12px 24px"
            icon="filter"
            opens="applicant-filter-modal"
            data={{
              filters,
              initialFilters,
              setFilters,
              clearFilters: handleReset,
              setShow
            }}
            disabled={!isPropertyPopulated(filters) || loading}
          >
            Filters
            {!show && isFiltersSelected && !!moreFiltersCount && (
              <MoreFiltersCount>{moreFiltersCount}</MoreFiltersCount>
            )}
          </ContainedButton>

          {!isXS && <DisplaySwitcher />}
        </Flex>
      </Flex>
    </Card>
  );
};

export default ApplicantsFilter;
