import * as React from 'react';

const FilterChildren = () => {
  return (
    <>
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={14.4} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_215_21788" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={7.2} />
      <feGaussianBlur stdDeviation={10.8} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.320833 0 0 0 0 0.077 0 0 0 0 0 0 0 0 0.3 0" />
      <feBlend in2="effect1_backgroundBlur_215_21788" result="effect2_dropShadow_215_21788" />
      <feBlend in="SourceGraphic" in2="effect2_dropShadow_215_21788" result="shape" />
      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={1.8} />
      <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
      <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0" />
      <feBlend in2="shape" result="effect3_innerShadow_215_21788" />
    </>
  );
};

const DuckSunglassesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144} height={120} fill="none" viewBox="0 0 144 120">
    <g clipPath="url(#a)">
      <path
        d="M101.43 140.946c11.697-2.658 17.97-13.75 17.97-25.745 0-26.51-21.49-48-48-48s-48 21.49-48 48c0 11.995 6.273 23.087 17.97 25.745 7.462 1.695 17.386 3.055 30.03 3.055 12.643 0 22.568-1.36 30.03-3.055Z"
        fill="url(#b)"
      />
      <path
        d="M100.2 28.8c0 15.906-12.895 28.8-28.8 28.8-15.906 0-28.8-12.894-28.8-28.8S55.494 0 71.4 0c15.905 0 28.8 12.894 28.8 28.8Z"
        fill="url(#c)"
      />
      <path
        d="M37.8 12.6A7.2 7.2 0 0 1 45 5.4h18a5.4 5.4 0 0 1 5.4 5.4v10.8c0 5.964-4.836 10.8-10.8 10.8h-9c-5.965 0-10.8-4.836-10.8-10.8v-9ZM104.4 12.6a7.2 7.2 0 0 0-7.2-7.2h-18a5.4 5.4 0 0 0-5.4 5.4v10.8c0 5.964 4.836 10.8 10.8 10.8h9c5.965 0 10.8-4.836 10.8-10.8v-9Z"
        fill="#111112"
      />
      <path
        d="M83.571 37.799a17.053 17.053 0 0 1-12.47 5.4 17.053 17.053 0 0 1-12.472-5.4"
        stroke="#111112"
        strokeWidth={3.6}
        strokeLinecap="round"
      />
      <path fill="#111112" d="M66.6 10.799h9v7.2h-9z" />
      <g filter="url(#d)">
        <rect y={63} width={70.2} height={36} rx={10.8} fill="#BFCAFF" fillOpacity={0.1} shapeRendering="crispEdges" />
      </g>
      <g filter="url(#e)">
        <rect
          x={73.8}
          y={93.601}
          width={70.2}
          height={36}
          rx={10.8}
          fill="#BFCAFF"
          fillOpacity={0.1}
          shapeRendering="crispEdges"
        />
      </g>
      <rect x={7.2} y={72} width={55.8} height={7.2} rx={3.6} fill="#fff" />
      <rect x={81} y={102.601} width={55.8} height={7.2} rx={3.6} fill="#fff" />
      <rect x={7.2} y={84.601} width={39.6} height={7.2} rx={3.6} fill="#fff" />
      <rect x={81} y={115.201} width={39.6} height={7.2} rx={3.6} fill="#fff" />
    </g>
    <defs>
      <radialGradient
        id="b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 118.8 -79.2 0 71.4 25.2)"
      >
        <stop stopColor="#FFC480" />
        <stop offset={0.755} stopColor="#FFBF1A" />
        <stop offset={1} stopColor="#FF8A01" />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 89.1 -47.52 0 71.4 -31.5)"
      >
        <stop stopColor="#FFC480" />
        <stop offset={0.896} stopColor="#FFBF1A" />
        <stop offset={1} stopColor="#FF8A01" />
      </radialGradient>
      <filter
        id="d"
        x={-28.8}
        y={34.2}
        width={127.8}
        height={93.6}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <filter
        id="e"
        x={45}
        y={64.801}
        width={127.8}
        height={93.6}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <FilterChildren />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h144v144H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default DuckSunglassesIcon;
