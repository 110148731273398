import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const Container = styled.div`
  background-color: #1a1b1e;
  border-radius: 16px;
  height: 834px;
  @media ${pageSize.S}, ${pageSize.XS} {
    position: absolute;
    inset: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 2px solid rgba(204, 212, 255, 0.11);
  height: 72px;
  @media ${pageSize.XS} {
    padding: 0 16px;
  }
`;

export const HeaderTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
`;

export const HeaderIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 32px;
  background-color: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
`;
