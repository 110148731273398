/* eslint-disable react/display-name */
import React, { forwardRef, useMemo, useState } from 'react';
import Typography from 'components/shared/Typography';
import { useSelector } from 'react-redux';
import { currentUserEmailSelector } from '../../../../store/selectors/currentUser';
import CollaboratorItem from '../../Collaborators/CollaboratorsForm/CollaboratorItem';
import AddCollaborator from '../../Collaborators/CollaboratorsForm/AddCollaborator';
import ContainedButton from '../../../../common/ContainedButton';
import CollaboratorsMessage from '../../Collaborators/CollaboratorsForm/CollaboratorsMessage';

const AddCreditThirdStep = forwardRef(
  (
    {
      addOpen,
      collaborator,
      collaborators,
      error,
      handleChange,
      label,
      mb,
      setAddOpen,
      setAddStep,
      setDoneStep,
      step,
      values
    },
    ref
  ) => {
    const [collaborate, setCollaborate] = useState();
    const currentUserEmail = useSelector(currentUserEmailSelector);

    const headerText = () => {
      if (step === 4) {
        return 'Add collaborators to provide feedback about your shared working experience';
      }
      if (collaborator && values.role.label === collaborator.role.replace(/ /g, ' ').trim()) {
        return 'Consider adding collaborators on this credits to extend your network. (You will be able to do it later in your Credits section.)';
      }
      return 'Add collaborators you’ve worked with to extend your network and increase your chances of getting discovered';
    };

    const handleAddCollab = () => {
      setAddStep();
      setAddOpen(true);
    };

    const onCollaboratorCustomValidate = fields => {
      const errors = {};
      const email = fields.getIn(['email', 'value']);
      const alreadyExists = collaborators.find(item => item.email === email);

      if (alreadyExists) errors.email = `Oops! You’ve already listed ${email} as a collaborator.`;

      const isCurrentUserEmail = currentUserEmail === email;

      const collaborationType = fields.getIn(['collaborationType', 'value', 'value']);
      const collaborationTypeOther = fields.getIn(['collaborationTypeOther', 'value']);

      if (isCurrentUserEmail) errors.email = `${email} matches your email. Please try another email`;

      if (collaborationType === 'Other' && !collaborationTypeOther)
        errors.collaborationTypeOther = 'Collaboration type is required';

      return errors;
    };

    const onCollaboratorAdd = collab => {
      handleChange([...collaborators, collab]);
      setCollaborate(collab);
      setAddOpen(false);
    };

    const handleDelete = collab => {
      handleChange(collaborators.filter(coll => coll.email !== collab.email));
    };

    const collabText = useMemo(() => label, [collaborators.length, label]);

    return (
      <div>
        <Typography mb={24} error={!!error} color="rgba(230, 234, 255, 0.6);">
          {step !== 5 && headerText()}
        </Typography>
        <>
          {step === 5 ? (
            <>
              {' '}
              <CollaboratorsMessage
                name={collaborate.name}
                email={collaborate.email}
                project={values.game}
                isActive={!!collaborate.collaborator}
                externalId={collaborate.externalId}
              />
            </>
          ) : (
            <>
              <Typography opacity="0.4" mb={24}>
                {collabText}
              </Typography>
              {!addOpen &&
                collaborators.map((collab, key) => (
                  <CollaboratorItem key={key} collaborator={collab} handleDelete={handleDelete} />
                ))}
              {addOpen && (
                <AddCollaborator
                  onValidate={onCollaboratorCustomValidate}
                  onAdd={onCollaboratorAdd}
                  step={step}
                  setDoneStep={setDoneStep}
                  ref={ref}
                />
              )}
              {collaborators.length < 20 && !addOpen && (
                <ContainedButton
                  mb={mb}
                  mt={16}
                  backgroundColor="rgba(204, 213, 255, 0.11)"
                  onClick={handleAddCollab}
                  icon="add"
                  type="button"
                >
                  Add Collaborator
                </ContainedButton>
              )}
              {collaborators.length === 20 && (
                <Typography mt={30} error variant="small">
                  Reached the maximum number of collaborators
                </Typography>
              )}
            </>
          )}
        </>
      </div>
    );
  }
);

export default AddCreditThirdStep;
