import React from 'react';
import { useDispatch } from 'react-redux';

import Seo from 'common/Seo';
import ServicePage from 'common/ServicePage';
import useProfiles from 'hooks/useProfiles';
import useViewport from 'hooks/useViewport';
import FeedbackModal from 'pages/ContactSupport/FeedbackModal';
import { portalActions } from 'store/portals';
import { ClickableText, Text } from './styled.components';

const Page404 = () => {
  const dispatch = useDispatch();
  const { isL } = useViewport();

  const toggleFeedbackModal = () => {
    dispatch(portalActions.openPortal({ name: 'feedback-modal' }));
  };

  useProfiles();

  return (
    <>
      <Seo />
      <ServicePage
        icon="404"
        iconColor="linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%)"
        title="404"
        infoText={
          <Text>
            {`
            Um, yeah. This is awkward. We tried really hard, but we couldn’t find the page you were looking for. If
            you're sure this page is missing by mistake,`}{' '}
            {isL && <br />}
            <ClickableText color="#7266ee" onClick={toggleFeedbackModal}>
              contact our friendly support team.
            </ClickableText>
          </Text>
        }
      />
      <FeedbackModal />
    </>
  );
};

export default Page404;
