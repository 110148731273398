export const FACEBOOK_STATE = 'random_string';
const FACEBOOK_SCOPE = 'email';
const FACEBOOK_RIDERECT = `${window.location.origin}/auth/fb`;
const FACEBOOK_CLIENT_ID = '466678041206028';

const query = new URLSearchParams({
  response_type: 'code',
  display: 'page',
  client_id: FACEBOOK_CLIENT_ID,
  redirect_uri: FACEBOOK_RIDERECT,
  state: FACEBOOK_STATE,
  scope: FACEBOOK_SCOPE
});

export const FACEBOOK_URL = `https://www.facebook.com/v8.0/dialog/oauth?${query}`;
