import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';
import LinkWrapper from 'common/LinkWrapper';
import ReactTooltip from 'react-tooltip';
import { pageSize } from 'utils/devices';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 28px;
  white-space: nowrap;
  @media ${pageSize.M} {
    margin-right: 18px;
  }
  @media ${pageSize.S} {
    margin-right: 20px;
  }
  ${props => (props.isSelected ? 'border-bottom: 2px solid #00FF00;' : '')}
`;
const StyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  flex-direction: row;
`;
const MenuIcon = styled(Icon)`
  margin-right: 8px;
`;
const MenuText = styled.div`
  ${commonStyles.caption}
  @media ${pageSize.M} {
    font-size: 13px;
  }
  @media ${pageSize.S} {
    font-size: 12px;
  }
  ${props => (props.color ? `color: ${props.color};` : '')}
`;
const StyledTooltip = styled(ReactTooltip)`
  border-radius: 8px !important;
  padding: 7px 14px !important;
`;

const GalleryMenuItem = ({ disabled, icon, isSelected, pathname, tabName, text, tooltip }) => {
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [basePath, setBasePath] = useState();

  let color = isSelected ? '#00FF00' : 'rgba(230, 234, 255, 0.6)';
  if (isMouseHover) {
    color = 'white';
  }

  if (disabled) {
    color = 'rgba(221, 226, 255, 0.2);';
  }

  useEffect(() => {
    const pathArray = (pathname && pathname.split('/')) || [];
    pathArray.pop();
    setBasePath(pathArray.join('/'));
  }, [pathname]);

  return (
    <Wrapper isSelected={isSelected}>
      <StyledLinkWrapper
        {...(disabled ? {} : { to: `${basePath}/${tabName}` })}
        onMouseEnter={() => setIsMouseHover(true)}
        onMouseLeave={() => setIsMouseHover(false)}
        onMouseUp={() => setIsMouseHover(false)}
      >
        <MenuIcon icon={icon} color={tooltip ? 'rgba(230,234,255,0.2)' : color} />
        <MenuText
          color={color}
          data-tip={tooltip || ''}
          data-for={tooltip || ''}
          data-border
          data-border-color="rgba(204, 213, 255, 0.15)"
          data-text-color="rgba(230, 234, 255, 0.6);"
          data-background-color="#000000"
        >
          {text}
        </MenuText>
        {tooltip && (
          <div>
            <StyledTooltip effect="solid" id={tooltip} />
          </div>
        )}
      </StyledLinkWrapper>
    </Wrapper>
  );
};

export default GalleryMenuItem;
