import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import Icon from 'common/Icon';
import Frame from 'common/Frame';

import { profileSelectors } from 'store/profile';
import TalentExperienceContext from './TalentExperienceContext';
import AccomplishmentsModal from './AccomplishmentsModal';

const StyledGridContainer = styled.div`
  display: grid;
  grid-row-gap: 24px;
`;
const StyledAccomplishment = styled.div`
  white-space: pre-line;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 16px auto;
  grid-column-gap: 16px;
  align-items: baseline;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 36px;
`;
const StyledIcon = styled(Icon)`
  width: 16px;
  height: 12px;
`;

const Accomplishments = ({ error, experienceId, isEditMode = true, mb }) => {
  const { values } = React.useContext(TalentExperienceContext);
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const savedAccomplishments = useSelector(
    profileSelectors.selectProfileProperty(`experience.${experienceId}.achievements`)
  );

  const accomplishments = experienceId ? savedAccomplishments : values.accomplishments;

  return (
    <>
      {(accomplishments?.length > 0 || hasPermission) && (
        <Frame
          color="#FFE040"
          required
          mb={mb}
          error={error}
          text="Accomplishments"
          canEdit={hasPermission && isEditMode}
          editMode={accomplishments?.length ? 'edit' : 'add'}
          opens="accomplishments-modal"
          filled={!!accomplishments?.length}
          noDataText="There’s no need to be shy here—share your hero moments!"
          id="accomplishments"
          className="accomplishments-required"
        >
          <StyledGridContainer>
            {accomplishments &&
              accomplishments.map((curr, key) => (
                <StyledAccomplishment key={key}>
                  <StyledIcon icon="checkmark" />
                  {curr}
                </StyledAccomplishment>
              ))}
          </StyledGridContainer>

          <AccomplishmentsModal />
        </Frame>
      )}
    </>
  );
};

export default Accomplishments;
