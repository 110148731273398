import ChatApi from './chatApi';

const chatApi = new ChatApi('conversations');

const createConversation = (conversationId, data) => chatApi.put(`/${conversationId}`, data);

const getConversation = conversationId => chatApi.get(`/${conversationId}`);

const updateConversation = (conversationId, data) => chatApi.put(`/${conversationId}`, data);

const addMessage = (conversationId, data) => chatApi.post(`/${conversationId}/messages`, data);

const getMessages = (conversationId, startingAfter = '', limit = 20) => {
  let apiUrl = '';
  if (startingAfter) {
    apiUrl = `/${conversationId}/messages?startingAfter=${startingAfter}&limit=${limit}`;
  } else {
    apiUrl = `/${conversationId}/messages?&limit=${limit}`;
  }
  return chatApi.get(apiUrl);
};

const getAttachments = conversationId => chatApi.get(`/${conversationId}/messages?limit=100`);

const markConversationAsRead = (conversationId, userId) =>
  chatApi.post(`/${conversationId}/readBy/${userId}`, { Headers: { 'Content-Type': 'application/json' } });

export default {
  createConversation,
  getConversation,
  updateConversation,
  addMessage,
  getMessages,
  getAttachments,
  markConversationAsRead
};
