import React from 'react';
import { Document, Page } from 'react-pdf';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 672px;
  overflow: scroll;

  @media ${pageSize.XS} {
    height: 343px;
    max-height: 343px;
    padding-top: 50px;
  }

  .react-pdf__Document {
    max-height: 672px;
    margin-left: ${props => (props.noMargin ? 0 : 10)}px;
    width: 100%;
    @media ${pageSize.XS} {
      height: 343px;
    }
  }
  .react-pdf__Page {
    max-height: 672px;
    ${props => !props.noMargin && 'margin-top: 20px;'}
    @media ${pageSize.XS} {
      height: 343px;
    }
  }
`;

const AssetPdf = ({ noMargin = true, url }) => {
  const [numPages, setNumPages] = React.useState(null);
  const containerRef = React.useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pages = React.useMemo(() => {
    const { height, width } = containerRef.current?.getBoundingClientRect() || {};
    const arr = Array.from(new Array(numPages));

    return arr.map((_, index) => (
      <Page
        scale={1}
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        height={height}
        width={noMargin ? width : width - 20}
      />
    ));
  }, [numPages, containerRef.current]);

  return (
    <StyledContainer noMargin={noMargin} ref={containerRef}>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {pages}
      </Document>
    </StyledContainer>
  );
};

export default AssetPdf;
