
import {StyledButton} from '../styled.components';
import styled from '@emotion/styled';

export const ContractStatusOptions = {
    'in-draft': 'Proposal draft',
    'in-review': 'Waiting for changes',
    'pending-for-approval': 'Proposal sent',
    active: 'Starts on',
    rejected: 'Declined',
    'in-progress': 'Ongoing project',
    'on-hold': 'On hold',
    finished: 'Ended',
    cancelled: 'Canceled'
  };
  
export const ContractStatusIcon = {
    'in-draft': 'purpleDot',
    'pending-for-approval': 'orangeDot',
    'in-progress': 'orangeDot',
    'in-review': 'time',
    'on-hold': 'orangeDot',
    active: 'checkmark-circle',
    finished: 'checkmark-circle',
    cancelled: 'ignore',
    rejected: 'ignore'
  };

  export const ContractStatusIconColor = {
    'in-draft': 'purple',
    'pending-for-approval': 'orange',
    'in-progress': 'orange',
    'in-review': 'orange',
    'on-hold': 'orange',
    active: 'green',
    finished: '#A4BFD8',
    cancelled: 'red',
    rejected: 'red'
  };
  
export const AppliedInfo = styled.span`
    display: inline-block;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(230, 234, 255, 0.6);
  `;
  
export const SeeMoreButton = styled(StyledButton)`
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    gap: 8px;
  `;
  
export const ReviewBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: rgba(204, 213, 255, 0.05);
    border-radius: 8px;
    margin-top: 32px;
  `;
  
export const ReviewBoxText = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
  `;
  
export const ReviewBoxTextArea = styled.span`
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */
  
    /* White/White 60% */
  
    color: rgba(230, 234, 255, 0.6);
  `;
  
export const ReviewBoxDateArea = styled.span`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
  
    letter-spacing: 0.04em;
    text-transform: uppercase;
  
    /* White/White 35% */
  
    color: rgba(230, 234, 255, 0.35);
  `;
  
export const ReviewBoxFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
  `;
  
export const ReviewButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
    padding: 7px 10px;
    gap: 8px;
  `;
  
export const ReviewButton = styled(StyledButton)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    gap: 8px;
  `;