import { compose, equals, filter, length, multiply } from 'ramda';

const MINIMUM_PERCENTAGE_STEP = 20;
const getPercentage = compose(multiply(MINIMUM_PERCENTAGE_STEP), length, filter(equals(true)));

export const getProgressFunnelItems = ({
  isUserCardCompleted,
  isIdentityVerified,
  hasAtLeastOneCredit,
  hasAtLeastOneProject,
  hasExperience
}) => {
  const items = [
    {
      id: 'you-in-a-nutshell',
      icon: 'id',
      title: 'You, in a nutshell',
      isCompleted: isUserCardCompleted
    },
    {
      id: 'verify-identity',
      icon: 'personal_info',
      title: 'Verify that you are a real deal',
      isCompleted: isIdentityVerified
    },
    {
      id: 'claim-your-game-credits',
      icon: 'achievement',
      title: 'Claim your game credits',
      isCompleted: hasAtLeastOneCredit
    },
    {
      id: 'showcase-portfolio',
      icon: 'portfolio',
      title: 'Showcase your remarkable portfolio',
      isCompleted: hasAtLeastOneProject
    },
    {
      id: 'experience',
      icon: 'experience',
      title: 'Outline your experience',
      isCompleted: hasExperience
    }
  ];

  const percentage = getPercentage([
    isUserCardCompleted,
    isIdentityVerified,
    hasAtLeastOneCredit,
    hasAtLeastOneProject,
    hasExperience
  ]);

  return { items, percentage };
};
