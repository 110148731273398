import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AvatarWrapper = styled.div`
  position: relative;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Details = styled.div`
  margin-left: 16px;
  @media ${pageSize.XS} {
    margin-left: 8px;
  }
`;

export const Participant = styled.div`
  color: #fff;
  font-size: 16px;
`;
export const JobTitle = styled.div`
  color: rgba(229, 234, 255, 0.6);
  font-size: 13px;
  cursor: pointer;
`;

export const SearchIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  background-color: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
`;

export const GoBackIcon = styled(SearchIcon)`
  margin-right: 24px;
  @media ${pageSize.XS} {
    margin-right: 8px;
  }
`;
