import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ margin }) => `margin: ${margin};`}
`;

const Loader = ({ margin, size }) => (
  <StyledLoader margin={margin}>
    <Icon size={size ?? 34} icon="loader" />
  </StyledLoader>
);

export default Loader;
