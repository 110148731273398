import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import { userActions } from 'store/user';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import backOfficeService from 'api/backOfficeService';
import { profileActions } from 'store/profile';
import { soloistVettingOutcomes, vettingStatuses } from '../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = (initialStatus, initialOutcome, additionalComment, element) => ({
  status: { initialValue: vettingStatuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  outcome: {
    initialValue: soloistVettingOutcomes.find(curr => curr.value === initialOutcome),
    validate: ['isRequired']
  },
  additionalComment: { initialValue: additionalComment },
  element: { initialValue: element }
});

const transformParams = params => ({
  ...params,
  outcome: params.outcome.value,
  status: params.status.value
});

const VettingStatusModal = ({ closePortal, data, name }) => {
  const { additionalComment, element, outcome, status, talentId, userId } = data;
  const dispatch = useDispatch();

  const callApi = params => {
    const apiParams = transformParams(params);
    const accountId = userId || talentId;
    const accountType = userId ? 'user' : 'talent';

    const requestPromise = backOfficeService.setAccountVettingStatus(accountId, { ...apiParams, accountType });

    return requestPromise.then(({ data: { accounts, statusLog } }) => {
      const action = userId ? userActions.updateUserProperty : profileActions.updateProfileProperty;

      dispatch(action({ property: 'vettingStatus', value: apiParams.status, profileId: accountId }));
      dispatch(action({ property: 'vettingStatusLogs', value: statusLog, updateType: 'insert', profileId: accountId }));

      if (userId) dispatch(action({ property: 'accounts', value: accounts }));

      if (data.onSuccess) data.onSuccess();

      closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(status, outcome, additionalComment, element),
    callApi
  });

  return (
    <TxplModal
      title="Vetting status"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="vettingStatusModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="vettingStatusModal">
        <FormSelect
          label="Vetting status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={vettingStatuses}
          autoFocus
        />

        <FormSelect
          label="Outcome"
          value={values.outcome}
          onChange={onFieldChange('outcome')}
          options={soloistVettingOutcomes}
        />

        <FormTextarea
          label="Additional comments"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
          maxLength={200}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('vetting-status-modal')(VettingStatusModal);
