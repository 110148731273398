import React from 'react';
import { useSelector } from 'react-redux';

import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import { tagSelectors } from 'store/tags';

import styled from '@emotion/styled';
import { StyledCollaboratorInfoContainer, StyledContainer } from './CollaboratorItemStyles';
import { getFullName } from '../../../../../utils/collaboratorUtils';

const CollaboratorName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CollaboratorItem = ({ collaborator, handleDelete }) => {
  const roleName = useSelector(tagSelectors.selectTagName(collaborator.role?._id || collaborator.role));

  return (
    <StyledContainer>
      <StyledCollaboratorInfoContainer>
        <CollaboratorName variant="h3">{getFullName(collaborator)}</CollaboratorName>
        <Typography variant="small" color="rgba(230,234,255,0.6)">
          {roleName}
        </Typography>
      </StyledCollaboratorInfoContainer>
      <ContainedButton
        icon="delete"
        onClick={() => handleDelete(collaborator)}
        type="button"
        iconColor="#FF5151;"
        backgroundColor="transparent"
        color="rgba(204, 213, 255, 0.11);"
      />
    </StyledContainer>
  );
};

export default CollaboratorItem;
