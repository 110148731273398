import React from 'react';
import jobService from 'api/jobService';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions, jobsSelectors } from 'store/jobs';

const useSelectedJobs = () => {
  const jobs = useSelector(jobsSelectors.selectMyJobs('selectedJobs'));
  const { hasMore } = useSelector(jobsSelectors.selectMyJobsPagination('selectedJobs'));
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const getSelectedJobs = React.useCallback(
    async (profileId, skip, limit = 4) => {
      if (!isLoading && hasMore && profileId !== undefined) {
        try {
          setIsLoading(true);
          const {
            data: { data, hasMore }
          } = await jobService.getSelectedJobs(profileId, skip, limit);

          dispatch(
            jobActions.updateJob({
              jobType: 'selectedJobs',
              profileId,
              jobs: data
            })
          );

          dispatch(
            jobActions.updateJobPagination({
              jobType: 'selectedJobs',
              profileId,
              pagination: {
                hasMore,
                limit
              }
            })
          );
        } catch (err) {
          dispatch(
            jobActions.updateJobPagination({
              jobType: 'selectedJobs',
              profileId,
              pagination: {
                hasMore: false,
                limit
              }
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isLoading, hasMore, dispatch]
  );

  return {
    selectedJobs: jobs,
    loadingSelectedJobs: isLoading,
    hasMoreSelectedJobs: hasMore,
    getSelectedJobs
  };
};

export default useSelectedJobs;
