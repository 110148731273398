import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';

import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';

const RequestSentText = styled.div`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.6);
`;

const UnlockRequestModal = ({ closePortal, name }) => (
  <TxplModal
    name={name}
    title="Your request has been sent"
    appElement={document.getElementById('root-modal')}
    size="small"
    hideBorder
    noPaddingTop
    maxWidth={520}
    padding="32px 0 40px"
    renderFooter={
      <ContainedButton forceDisplayText onClick={closePortal}>
        Close
      </ContainedButton>
    }
  >
    <RequestSentText>
      Thank you, we have received your request! We’ll drop you an email once your portfolio is editable
    </RequestSentText>
  </TxplModal>
);

export default withRenderPortal('unlock-request-modal')(UnlockRequestModal);
