import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import backOfficeService from 'api/backOfficeService';
import { userActions } from 'store/user';
import Typography from 'components/shared/Typography';
import FormInput from 'common/form/FormInput';
import { idVerificationStatuses } from '../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const StyledOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 56px;
`;

const StyledSpacer = styled.div`
  height: 2px;
  background-color: rgba(204, 213, 255, 0.11);
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
`;

const fields = initialValues => ({
  status: {
    initialValue: idVerificationStatuses.find(curr => curr.value === initialValues.status),
    validate: ['isRequired']
  },
  reason: { initialValue: 'Manually overriden' || '', validate: ['isRequired'] },
  additionalComment: { initialValue: initialValues.additionalComment || '' }
});

const transformParams = params => ({
  ...params,
  reason: params.reason,
  status: params.status.value
});

const IdVerificationStatusModal = ({ closePortal, data, name }) => {
  const { status, talentId, userId } = data;
  const dispatch = useDispatch();
  const callApi = async params => {
    const apiParams = transformParams(params);
    const accountId = userId || talentId;
    const accountType = userId ? 'user' : 'talent';

    await backOfficeService.setAccountVerificationStatus(accountId, { ...apiParams, accountType });
    dispatch(
      userActions.updateUserProperty({ property: 'verifications', value: apiParams.status, profileId: accountId })
    );

    dispatch(
      userActions.updateUserProperty({
        property: 'verificationStatusLogs',
        value: apiParams,
        updateType: 'unshift',
        profileId: accountId
      })
    );
    closePortal();
  };

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({ fields: fields(status), callApi });

  return (
    <TxplModal
      title="ID Verification Status"
      titleMB={16}
      padding={'56px 0 80px'}
      addPaddingTopBig
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="idVerificationStatusModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="idVerificationStatusModal">
        <FormSelect
          autoFocus
          label="Current status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={idVerificationStatuses}
        />

        <StyledSpacer mt={26} mb={22} />

        <StyledOptionContainer>
          <Typography variant="medium">To keep user updated, you can re-send a status email.</Typography>
          <ContainedButton backgroundColor="secondary" disabled>
            RE-SEND THE STATUS EMAIL
          </ContainedButton>
        </StyledOptionContainer>

        <FormInput
          required
          label="Reason"
          mb={56}
          value="Manually overriden"
          onChange={onFieldChange('reason')}
          error={errors.reason}
          maxLength={200}
          disabled
        />

        <FormTextarea
          maxLength={200}
          label="Additional comments"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('id-verification-status-modal')(IdVerificationStatusModal);
