import styled from '@emotion/styled';

export const ScopeFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const StyledCandidateModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const StyledCandidatesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 0 0;
`;
export const StyledCandidate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  height: 56px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 12px;
  margin: 6px 0 0;
  &.chosenCandidate {
    background-color: #7266ee;
    border: 2px solid rgba(204, 213, 255, 0.11);
  }
`;
export const StyledCandidateImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
`;
export const StyledCandidateNameAndRoleWrapper = styled.div`
  height: 100%;
`;
export const StyledCandidateName = styled.span`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
`;
export const StyledCandidateRoleWrapper = styled.div`
  display: flex;
`;
export const StyledCandidateRole = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: rgba(230, 234, 255, 0.35);
  margin-right: 10px;
`;
