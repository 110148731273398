import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardWrapper, StepContainer, Subtitle } from './Steps.styled';
import { closePortal } from 'store/portals/portalActions';
import StepCard from './StepCard';
import { useDispatch } from 'react-redux';

const OnYourWay = ({ profile }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cards = [
    {
      icon: 'jobs-gradient',
      label: 'Explore job opportunities',
      legend: '',
      color: 'linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%);',
      disabled: false,
      path: `/talent/${profile.talentId}/all-jobs`
    },
    {
      icon: 'profile-gradient',
      label: 'Continue building your profile',
      legend: 'to join events and apply for jobs',
      color: 'linear-gradient(180deg, #0057FF 0%, #00C1DB 100%);',
      disabled: false,
      path: `/talent/${profile.talentId}/about?open-progress-funnel=true`
    },
    {
      icon: 'events-gradient',
      label: 'Check out networking events',
      legend: '',
      color: 'linear-gradient(359.43deg, #00BC35 0.44%, #027100 99.47%);',
      disabled: false,
      path: `${process.env.REACT_APP_NEXT_BASE_URL}/events`
    }
  ];

  const handleClick = card => {
    if (card.path.startsWith('/talent')) {
      history.push(card.path);
    } else {
      window.location.href = card.path;
    }

    dispatch(closePortal({ name: 'quick-onboarding-modal' }));
  };

  return (
    <StepContainer>
      <Subtitle>What do you want to do next?</Subtitle>
      <CardWrapper>
        {cards.map(card => (
          <StepCard key={card.icon} card={card} handleClick={handleClick} />
        ))}
      </CardWrapper>
    </StepContainer>
  );
};

export default OnYourWay;
