import styled from '@emotion/styled';
import React from 'react';

const FunnelStatusWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusIcon = styled.div`
  display: inline-flex;
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: white;
  ${props => (props.color ? `background-color: ${props.color};` : '')}
`;

const StatusText = styled.span`
  text-transform: capitalize;
`;

const getStatusColor = status => {
  switch (status) {
    case 'ACCOUNT_CREATED':
    case 'Account Created':
      return 'rgba(230, 234, 255, 0.35)';
    case 'VETTING_IN_PROGRESS':
      return '#FFE040';
    case 'VETTING_PENDING':
      return '#FFE040';
    case 'VETTING_COMPLETE':
      return '#2BFF26';
    case 'APPLICATION_INCOMPLETE':
      return '#FF5151';
    case 'ID_VERIFICATION_COMPLETE':
      return '#FF5151';

    default:
      return 'white';
  }
};

const FunnelStatus = ({ status, value }) => (
  <FunnelStatusWrapper>
    <StatusIcon color={getStatusColor(value || status)} />
    <StatusText>{status && status.replace('_', ' ').toLowerCase().replace('_', ' ')}</StatusText>
  </FunnelStatusWrapper>
);

export default FunnelStatus;
