import styled from '@emotion/styled';
import { tagService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import FormUpload from 'common/form/FormUpload';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import React, { useState } from 'react';
import { excludeByType } from 'utils/tagUtils';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
  margin-left: auto;
`;

const BackOfficeTagModal = ({ closePortal, data: { initialName, isEdit, tableRef, tagId, type }, name: modalName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [list, setList] = useState('');
  const [associates, setAssociates] = useState([]);
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState();
  const [errors, setErrors] = useState(null);

  const title = React.useMemo(() => (isEdit ? `Edit ${type}` : `Add new ${type}`), [isEdit, type]);

  const exclude = React.useMemo(() => excludeByType[type], [type]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    if ((!list && !exclude.lists) || !name) {
      const tempErrors = {};
      if (!list) {
        tempErrors.list = 'List is required';
      }
      if (!name) {
        tempErrors.name = 'Name is required';
      }
      setErrors(tempErrors);
      return setIsLoading(false);
    }
    try {
      setErrors({});
      const body = {
        name,
        lists: [list],
        associates,
        type
      };

      const data = new FormData();

      if (isEdit) {
        data.append('tag', JSON.stringify(body));
        if (image) {
          data.append('file', image);
        }
        await tagService.updateTag(tagId, data);
      } else {
        if (image) {
          data.append('file', image);
        }
        data.append('tag', JSON.stringify(body));
        await tagService.createTag(data);
      }

      if (tableRef) {
        tableRef.current.refetch();
      }
      closePortal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await tagService.deleteTag(tagId);
      if (tableRef) {
        tableRef.current.refetch();
      }
      closePortal();
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isEdit || isLoading) return;

    setIsLoading(true);
    tagService
      .getTag(tagId)
      .then(({ data: { data } }) => {
        setName(data.name);
        setList(data.lists[0]);
        setAssociates(data.associates);
        setInitialImage(data.image?.url);
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line
  }, [tagId])

  return (
    <TxplModal
      name={modalName}
      size="small"
      appElement={document.getElementById('root')}
      title={title}
      renderFooter={
        <SubmitRow>
          {isEdit && (
            <ContainedButton
              icon="delete"
              disabled={isLoading}
              onClick={handleDelete}
              backgroundColor="rgba(204, 213, 255, 0.11)"
            />
          )}

          <StyledButtonsContainer>
            {isLoading && <Spinner />}
            <ContainedButton
              type="button"
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              disabled={isLoading}
            >
              Cancel
            </ContainedButton>
            <ContainedButton type="submit" form="BackOfficeTagModal" forceDisplayText disabled={isLoading}>
              Add
            </ContainedButton>
          </StyledButtonsContainer>
        </SubmitRow>
      }
    >
      <form onSubmit={handleSubmit} id="BackOfficeTagModal" style={{ maxWidth: '600px' }}>
        {!exclude.lists && (
          <FormInput
            autoFocus
            label="List"
            value={list}
            required
            error={errors?.list}
            onChange={e => setList(e.target.value)}
          />
        )}
        {!exclude.name && (
          <FormInput
            autoFocus
            label="Name"
            value={name}
            required
            error={errors?.name}
            onChange={e => setName(e.target.value)}
          />
        )}
        {!exclude.logo && (
          <FormUpload
            wrapperStyles={{ flexDirection: 'column' }}
            disabled
            label="Logo"
            onChange={setImage}
            value={initialImage}
            hintText="Should be an SVG image"
            mt={20}
            preview
            mw="100px"
            aspectRatio={208 / 300}
            accept="image/svg+xml"
            padding
            noCrop
          />
        )}
        {!exclude.associates && (
          <FormInput
            label="Associations"
            value={associates.join(', ')}
            onChange={e => setAssociates(e.target.value.split(', '))}
          />
        )}
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-tag-modal')(BackOfficeTagModal);
