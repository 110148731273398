import ChatApi from './chatApi';

const chatApi = new ChatApi('users');

const createUser = (userId, data) => chatApi.put(`/${userId}`, data);

const getUser = userId => chatApi.get(`/${userId}`);

const getUserConversations = (userId, startingAfter = '', limit = 15) => {
  let apiUrl = '';
  if (startingAfter) {
    apiUrl = `/${userId}/conversations?startingAfter=${startingAfter.toString()}&limit=${limit}`;
  } else {
    apiUrl = `/${userId}/conversations?limit=${limit}`;
  }
  return chatApi.get(apiUrl);
};
export default {
  createUser,
  getUser,
  getUserConversations
};
