import React from 'react';
import styled from '@emotion/styled';

const InputRangeWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .slider {
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) ${({ fillTo }) => (fillTo ? `${fillTo}%` : '0')},
      rgba(255, 255, 255, 0.4) ${({ fillTo }) => (fillTo ? `${fillTo}%` : '0')}
    );
  }

  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const RangeSlider = ({ fillTo, max, min, onChange, value }) => (
  <InputRangeWrapper fillTo={fillTo}>
    <input className="slider" type="range" min={min || 0} max={max} step={0.02} value={value} onChange={onChange} />
  </InputRangeWrapper>
);

export default RangeSlider;
