import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { talentService } from 'api';

import { profileActions, profileSelectors } from 'store/profile';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import { fields } from '../formFields';

import AddProjectForm from './AddProjectForm';

import { StyledContainedButton } from './styled.components';

const AddProjectModal = ({ closePortal, onSuccess }) => {
  const dispatch = useDispatch();
  const talent = useSelector(profileSelectors.selectProfile);
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const [loading, setLoading] = useState(false);

  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields,
    callApi: params => {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', values.coverImage);
      formData.append(
        'addData',
        JSON.stringify({
          ...params,
          platforms: params.platforms.map(platform => platform.value),
          genres: params.genres.map(genre => genre.value),
          tags: params.tags.map(tag => tag.value),
          isGeneral: true
        })
      );
      return talentService
        .addProject(talent._id, formData)
        .then(res => {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'generalProjects',
              updateType: 'insert',
              value: res.data.data.project,
              profileId
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'projects',
              updateType: 'insert',
              value: res.data.data.project,
              profileId
            })
          );
          if (onSuccess) {
            onSuccess(res.data.data.project);
          }
          closePortal();
          setLoading(false);
        })
        .catch(error => {
          console.log('error', error);
          setLoading(false);
        });
    }
  });

  return (
    <TxplModal
      title="Project"
      name="add-project-modal"
      appElement={document.getElementById('root-modal')}
      titlePadding="24px"
      renderFooter={
        <>
          {loading && <Spinner />}
          <StyledContainedButton
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
            mr={10}
            disabled={loading}
          >
            Cancel
          </StyledContainedButton>

          <StyledContainedButton
            onClick={onSubmit}
            backgroundColor="#7266EE"
            forceDisplayText
            disabled={loading}
            form="addProjectForm"
            type="submit"
          >
            Add
          </StyledContainedButton>
        </>
      }
    >
      <AddProjectForm values={values} errors={errors} onSubmit={onSubmit} onFieldChange={onFieldChange} />
    </TxplModal>
  );
};

export default withRenderPortal('add-project-modal')(AddProjectModal);
