import styled from '@emotion/styled';
import { ReactComponent as IconPurpleGradient } from 'images/assets/icons_16x16/purple-gradient.svg';
import { pageSize } from 'utils/devices';

export const PurpleGradient = styled(IconPurpleGradient)`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: -1;
`;
export const Container = styled.div`
  max-width: 1242px;
  height: 834px;
  margin: 120px auto 54px auto;
  font-family: 'Space Grotesk';
  padding: 0 54px;
  @media ${pageSize.M} {
    margin: 120px auto 56px auto;
    padding: 0 59px;
  }
  @media ${pageSize.S} {
    margin: 120px auto 76px auto;
    padding: 0 59px;
  }
  @media ${pageSize.XS} {
    padding: 0;
    margin: 0;
    height: 100vh;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-gap: 54px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 834px;
  height: 100%;
  @media ${pageSize.M} {
    grid-gap: 45px;
  }
  @media ${pageSize.S}, ${pageSize.XS} {
    grid-template-columns: 1fr;
    position: relative;
    overflow: hidden;
  }
  @media ${pageSize.XS} {
    grid-template-rows: 100%;
  }
`;
