import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { vettingService } from '../../api';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = (analystList, initialAnalyst, element) => ({
  selectedAnalyst: {
    initialValue: analystList.find(curr => curr.value === initialAnalyst.value),
    validate: ['isRequired']
  },
  element: { initialValue: element }
});

const transformParams = params => ({
  ...params,
  user: params.selectedAnalyst.value
});

const VettingAssigneeModal = ({ closePortal, data, name }) => {
  const { analystList, id, selectedAnalyst } = data;

  const callApi = params => {
    const apiParams = transformParams(params);

    const requestPromise = vettingService.updateVettingStatus(id, { ...apiParams });

    return requestPromise.then(({ data: { success } }) => {
      (success === true && data.onSuccess()) || closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(analystList, selectedAnalyst),
    callApi
  });

  return (
    <TxplModal
      size="small"
      title="Assigned to:"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="vettingAssigneeModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="vettingAssigneeModal">
        <FormSelect
          label="Assignee"
          autoFocus
          value={values.selectedAnalyst}
          onChange={onFieldChange('selectedAnalyst')}
          options={analystList}
          menuPosition="absolute"
          menuPortalTarget={document.body}
          menuPlacement="bottom"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
        <br />
        <br />
        <br />
        <br />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('vetting-assignee-modal')(VettingAssigneeModal);
