import React from 'react';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';

const ReachedMaxLimitModal = ({ closePortal, name }) => (
  <TxplModal
    title="You’ve reached the maximum of published projects"
    size="small"
    name={name}
    renderFooter={<ContainedButton onClick={closePortal}>Got it</ContainedButton>}
    appElement={document.getElementById('root-modal')}
  >
    <Typography variant="medium">
      You’ve already reached a maximum of published projects. We appreciate that you have more but we honor showing off
      only the best work. You can unpublish or delete some of current projects to publish the new ones.
    </Typography>
  </TxplModal>
);

export default withRenderPortal('reached-max-projects-modal')(ReachedMaxLimitModal);
