import styled from '@emotion/styled';
import React from 'react';
import commonStyles from 'utils/common-styles';
import NoMatchingJobs from 'images/assets/no-matching-jobs.svg';
import ContainedButton from 'common/ContainedButton';

const StyledEmptyState = styled.div`
  display: flex;
  justify-content: center;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  padding: 86px 0 80px;
  margin-top: 24px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  gap: 32px;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Text = styled.span`
  ${commonStyles.ui_text_medium}
  color: rgba(229, 234, 255, 0.6);
  text-align: center;
  u {
    cursor: pointer;
  }
`;

const SearchEmptyState = ({ emptyStateFor = 'talents', url = null }) => {
  return (
    <StyledEmptyState>
      <Wrapper>
        <Image src={NoMatchingJobs} alt="No matching jobs" />
        <Text>
          {emptyStateFor === 'jobs' ? (
            <>
              {`
              No recommendations right now, but stay tuned! Keep checking back for job opportunities that match your skills. We're always updating! `}
            </>
          ) : (
            <>
              {' '}
              {`We haven't found any matching `}
              {emptyStateFor}
              {`. Please try another request or see your `}
            </>
          )}
        </Text>
        <ContainedButton
          form="user-card-modal-form"
          backgroundColor="#7266EE"
          data-testid="save-usercard"
          onClick={() => (window.location.href = url)}
        >
          See all jobs
        </ContainedButton>
      </Wrapper>
    </StyledEmptyState>
  );
};

export default SearchEmptyState;
