import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ContainedButton from '../../common/ContainedButton';
import Spinner from '../../common/Spinner';
import { Wrapper } from './TermsServiceModal.styled';
import { userService } from 'api';
import { authActions } from 'store/auth';
import Typography from 'components/shared/Typography';
import LogoImage from 'images/page-header-thexplace-logo.svg';
import styled from '@emotion/styled';

const Logo = styled.img`
  width: 154px;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  width: 100%
  align-items: center;
  justify-content: flex-end;
`;

const TermsServiceModal = ({ closePortal }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [reachBottom, setReachBottom] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleAgree = async () => {
    if (firstTime && !reachBottom) {
      setFirstTime(false);
      return;
    }
    setSubmitting(true);
    await userService.agreeTermsOfService();
    dispatch(
      authActions.updateCurrentUserProperty({
        property: 'settings.termsOfService',
        value: true
      })
    );
    setSubmitting(false);
    closePortal();
  };

  const logout = () => {
    window.location.href = '/app/logout';
  };

  const handleCancel = () => {
    setShowConfirm(true);
  };

  useEffect(() => {
    window.addEventListener('message', e => {
      if (e.data?.reachBottom) {
        setReachBottom(true);
      }
    });

    return () => {
      window.removeEventListener('message', null);
    };
  }, []);

  return (
    <>
      <TxplModal
        onClose={() => undefined}
        hideCloseIcon
        title="We have updated our Terms of Service and Privacy Policy"
        name="terms-of-service-modal"
        appElement={document.getElementById('root-modal')}
        renderHeader={() => <Logo src={LogoImage} alt="#" data-testid="logo-header" />}
        renderFooter={
          <Footer>
            {!firstTime && !reachBottom && (
              <Typography style={{ flex: 1 }}>
                You need to scroll to the bottom of the document before you can “Agree”
              </Typography>
            )}
            {submitting && <Spinner />}
            <ContainedButton ml="6" mr="6" backgroundColor="secondary" onClick={handleCancel}>
              Cancel
            </ContainedButton>
            <ContainedButton
              disabled={!reachBottom && !firstTime}
              form="termsServiceModal"
              type="submit"
              backgroundColor="primary"
              onClick={handleAgree}
            >
              Agree
            </ContainedButton>
          </Footer>
        }
      >
        <Wrapper>
          <Typography>
            Hi there!👋 <br />
            We&apos;ve updated our Terms of Service and Privacy Policy.🌟 <br />
            Just simply scroll, read and click &quot;Agree&quot; to continue enjoying our platform.
            <br />
            Thanks for being a member of our community!
          </Typography>
          {loading && (
            <div className="loader">
              <Spinner />
            </div>
          )}
          <iframe
            style={{ display: loading ? 'none' : 'inherit' }}
            onLoad={() => {
              setTimeout(() => {
                setLoading(false);
              }, 100);
            }}
            src={`${process.env.REACT_APP_LANDING_BASE_URL}/terms-of-service/`}
            width={'100%'}
            height={500}
          />
        </Wrapper>
      </TxplModal>
      {showConfirm && (
        <TxplModal
          title="Are you sure?"
          name={'confirmation-modal'}
          size="small"
          hideBorder
          onClose={() => setShowConfirm(false)}
          appElement={document.getElementById('root-modal')}
          renderFooter={
            <>
              <ContainedButton
                mr="6"
                form="termsServiceModal"
                type="submit"
                backgroundColor="secondary"
                onClick={logout}
              >
                I don&apos;t want to agree
              </ContainedButton>
              <ContainedButton backgroundColor="primary" onClick={() => setShowConfirm(false)}>
                Take me back!
              </ContainedButton>
            </>
          }
        >
          If you don&apos;t click &apos;Agree&apos;, you won&apos;t be able to access TheXPlace and take part in our
          community.
          <br />
          Are you sure you want to pass this up?
        </TxplModal>
      )}
    </>
  );
};

export default withRenderPortal('terms-of-service-modal')(TermsServiceModal);
