import React, { useEffect, useState } from 'react';
import VideoAskFormService from 'api/videoaskFormService';

import Spinner from 'common/Spinner';
import * as Styled from './StyledVideoaskModal';

const VideoaskModal = ({ data }) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchVideoaskForm = async () => {
    try {
      setLoading(true);
      await VideoAskFormService.getVideoaskForm(data?.collaborationId).then(res => {
        setUrl(res.data.data.url);
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.videoask.loadModal({ url, options: { modalType: 'FullScreen' } });
  }, [url]);

  useEffect(() => {
    fetchVideoaskForm(data);
  }, []);

  return (
    <div>
      {loading && (
        <Styled.Loader>
          <Spinner />
        </Styled.Loader>
      )}
    </div>
  );
};

export default VideoaskModal;
