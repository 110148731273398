import styled from '@emotion/styled';
import { lifeServices } from 'api';
import ContainedButton from 'common/ContainedButton';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';

const Wrapper = styled.div`
  position: relative;
  min-width: 0;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
  gap: 6px;
`;

const LifeBlockWrapper = ({ children, dragHandleProps, draggableProps, innerRef, jobsPage, life }) => {
  const { _id, companyProfile, type } = life;

  const opens =
    type === 'text' ? 'life-text-modal' : type === 'text_media' ? 'life-text-media-modal' : 'life-photo-gallery-modal';

  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(
      portalActions.openPortal({
        name: 'confirm-modal',
        data: {
          title: 'Delete this Block ?',
          description: 'Are you sure you want to delete this Block? This cannot be undone.',
          notClosePortal: true,
          onConfirm: async () => {
            try {
              const res = await lifeServices.deleteLife(companyProfile, _id);
              const { data } = res.data;

              dispatch(
                profileActions.updateProfileProperty({
                  property: 'lives',
                  updateType: 'delete',
                  value: data,
                  profileId: companyProfile
                })
              );
            } catch (err) {
              console.log(err);
            }
          }
        }
      })
    );
  };

  return (
    <Wrapper ref={innerRef} {...draggableProps}>
      {hasPermission && !jobsPage && (
        <ButtonWrapper>
          <ContainedButton {...dragHandleProps} backgroundColor="secondary" icon="drag-i" />
          <DropDown className="secondary" padding="8px 10px" right={0}>
            <DropDownItem icon="delete" onClick={onDelete}>
              Delete Block
            </DropDownItem>
            <DropDownItem icon="edit" opens={opens} data={{ life }}>
              Edit Block
            </DropDownItem>
          </DropDown>
        </ButtonWrapper>
      )}
      {children}
    </Wrapper>
  );
};

export default LifeBlockWrapper;
