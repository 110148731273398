import { experienceSearchOptions } from './selectOptions';

const options = (values, key) => {
  if (key === 'experience') {
    return getExperienceOptions(values);
  }

  return (values ?? []).map(item => {
    const value = item?.value ?? item;

    if (key === 'gamesShipped' || key === 'hoursPerWeek') {
      if (value && value.toString() === '40-+') {
        return {
          label: '40+',
          value
        };
      }
      if (value && value.toString() === '8-+') {
        return {
          label: '8+',
          value
        };
      }
    }

    return {
      label: item?.label ?? capitalize(item),
      value
    };
  });
};

const getExperienceOptions = options =>
  (options ?? []).reduce((arr, item) => {
    const value = item?.value ?? item;
    const option = experienceSearchOptions.find(it => it.value === value);
    if (option) {
      arr.push(option);
    }
    if (item === 'Intern') {
      arr.push({ value: '0-0', label: 'Intern' });
    }

    if (item === 'all') {
      arr.push({ value: '1-0', label: 'Open for all' });
    }

    return arr;
  }, []);

const capitalize = value => {
  if (value?.length) {
    return value.length === 1 ? value : value[0].toUpperCase() + value.slice(1);
  }
  return value;
};
export default options;
