import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'store/selectors/currentUser';
import notificationSelectors from 'store/notifications/notificationSelectors';
import Logo from 'images/page-header-thexplace-logo.svg';
import Icon from 'common/Icon';
import Notifications from 'components/Notifications';
import Badge from 'components/Badge';
import HeaderMobileAuthorized from './HeaderMobileAuthorized';
import HeaderMobileUnauthorized from './HeaderMobileUnauthorized';

import {
  StyledContainer,
  StyledHeaderMenuBadge,
  StyledHeaderMenuItem,
  StyledHeaderMenuItemOpen,
  StyledHeaderMenuLink,
  StyledItemsWrapper,
  StyledLogo,
  StyledNavItemsContainer
} from './HeaderMobileStyles';

const HeaderMobile = ({ icon, onChange, value }) => {
  const [updateMenuIsOpen, setUpdateMenuIsOpen] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const unseenNotificationsCount = useSelector(notificationSelectors.selectAllUnreadNotificationsCount);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const StyledHeaderMenu = updateMenuIsOpen ? StyledHeaderMenuItemOpen : StyledHeaderMenuItem;

  const handleDropdownClick = () => {
    setNavbarOpen(false);
  };

  useEffect(() => {
    if (updateMenuIsOpen || navbarOpen) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, [100]);
    }
  }, [updateMenuIsOpen, navbarOpen]);

  return (
    <>
      <StyledContainer>
        <div
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}`;
          }}
        >
          <StyledLogo src={Logo} alt="TheXPlace" />
        </div>
        <div />
        <StyledNavItemsContainer>
          {isLoggedIn && (
            <StyledHeaderMenu>
              <StyledHeaderMenuLink
                to="#"
                onClick={() => {
                  setUpdateMenuIsOpen(!updateMenuIsOpen);
                  setNavbarOpen(false);
                }}
              >
                <div>
                  <Icon
                    className="header-Icon"
                    color={updateMenuIsOpen ? '#fff' : 'rgba(230, 234, 255, 0.35);'}
                    icon="notification_ring"
                    size={16}
                  />
                </div>
                <StyledHeaderMenuBadge>
                  {unseenNotificationsCount > 0 && (
                    <Badge
                      text={unseenNotificationsCount}
                      color="#5448DB"
                      textColor="#fff"
                      borderRadius={8}
                      paddingBottom={1}
                    />
                  )}
                </StyledHeaderMenuBadge>
              </StyledHeaderMenuLink>
            </StyledHeaderMenu>
          )}
          <StyledHeaderMenuItem style={{ margin: '0 12px' }}>
            <StyledHeaderMenuLink
              to="#"
              onClick={() => {
                setNavbarOpen(state => !state);
                setUpdateMenuIsOpen(false);
              }}
            >
              <div>
                <Icon className="header-Icon" color="rgba(230, 234, 255, 0.35);" icon="burger" size={16} />
              </div>
            </StyledHeaderMenuLink>
          </StyledHeaderMenuItem>
        </StyledNavItemsContainer>
        <StyledItemsWrapper isOpen={updateMenuIsOpen}>
          <Notifications closeNotifications={() => setUpdateMenuIsOpen(false)} />
        </StyledItemsWrapper>
      </StyledContainer>
      {navbarOpen && isLoggedIn && (
        <HeaderMobileAuthorized
          onClick={handleDropdownClick}
          onUpdatesClick={() => setUpdateMenuIsOpen(!updateMenuIsOpen)}
          value={value}
          icon={icon}
          onChange={onChange}
        />
      )}
      {navbarOpen && !isLoggedIn && <HeaderMobileUnauthorized onClick={handleDropdownClick} />}
    </>
  );
};

export default HeaderMobile;
