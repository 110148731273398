import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userActions, userSelectors } from 'store/user';
import { portalActions } from 'store/portals';
import useAPICall from 'hooks/useAPICall';
import ContainedButton from 'common/ContainedButton';
import BackofficeActionButtons from 'pages/backoffice/BackofficeActionButtons';
import BackofficeActionContent from 'pages/backoffice/BackofficeActionContent';
import BackofficeActionHeader from 'pages/backoffice/BackofficeActionHeader';
import BackofficeActionPanel from 'pages/backoffice/BackofficeActionPanel';
import Spinner from 'common/Spinner';
import AccountActivatedManuallyModal from './AccountActivatedManuallyModal';
import ActivationLinkSentModal from './ActivationLinkSentModal';

const ActionProfileActivation = () => {
  const userId = useSelector(userSelectors.selectUserProperty('_id'));
  const userAccounts = useSelector(userSelectors.selectUserProperty('accounts'));
  const dispatch = useDispatch();

  const { callApi: activateManually, loading: activating } = useAPICall({
    name: 'activate-manually',
    method: 'post',
    onSuccess: () => {
      dispatch(userActions.updateUserProperty({ property: 'status', value: 'active' }));
      dispatch(
        userActions.updateUserProperty({
          property: 'accounts',
          value: userAccounts.map(curr => ({ ...curr, status: 'active' }))
        })
      );
      dispatch(portalActions.openPortal({ name: 'account-manually-activated' }));
    },
    url: `/backoffice/users/${userId}/activate-manually`
  });

  const { callApi: sendActivationLink, loading: sending } = useAPICall({
    name: 'send-activation-link',
    method: 'post',
    url: `/backoffice/users/${userId}/send-activation-link`,
    onSuccess: () => {
      dispatch(portalActions.openPortal({ name: 'activation-link-sent-modal' }));
    }
  });

  return (
    <BackofficeActionPanel>
      <BackofficeActionHeader>User activation</BackofficeActionHeader>

      <BackofficeActionContent>
        Once account is created, a user will get a link to activate the profile. If the link is missing, it can be
        re-sent. In an edge case a profile can be activated manually.
      </BackofficeActionContent>

      <BackofficeActionButtons>
        {sending ? <Spinner /> : <ContainedButton onClick={() => sendActivationLink()}>Send Link</ContainedButton>}

        {activating ? (
          <Spinner />
        ) : (
          <ContainedButton onClick={() => activateManually()}>Activate Manually</ContainedButton>
        )}
      </BackofficeActionButtons>

      <AccountActivatedManuallyModal />
      <ActivationLinkSentModal />
    </BackofficeActionPanel>
  );
};

export default ActionProfileActivation;
