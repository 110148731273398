import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const AddJobContainer = styled.div`
  padding-bottom: 216px;
`;

export const FixedFooter = styled.div`
  position: fixed;
  bottom: 16px;
  z-index: 99;
  width: calc(100vw - 32px);

  @media ${pageSize.XS} {
    width: calc(100vw - 32px);
  }
  @media ${pageSize.S} {
    width: 672px;
  }
  @media (min-width: 1150px) and (max-width: 1250px) {
    width: 651px;
  }
  @media (min-width: 1251px) and (max-width: 1349px) {
    width: 651px;
  }
  @media ${pageSize.L} {
    width: 849px;
  }
`;

export const JobContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 54px;
  margin-top: 30px;

  @media ${pageSize.M} {
    grid-gap: 45px;
  }

  @media ${pageSize.S} {
    grid-template-columns: 1fr;
  }

  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

export const StyledAddJobFooter = styled.footer`
  padding: 24px 0;
  background-color: #111112;
  position: fixed;
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 2px solid rgba(204, 212, 255, 0.11);
`;

export const AddJobFooterContainer = styled.div`
  max-width: 1241px;
  margin: 0 auto;

  @media ${pageSize.M} {
    max-width: 1035px;
  }

  @media ${pageSize.S} {
    max-width: 672px;
  }

  @media ${pageSize.XS} {
    max-width: 100%;
    padding: 0 16px;
  }
`;

export const AddJobFooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    @media ${pageSize.XS} {
      padding: 8px;
    }
  }
`;
export const AddJobFooterRightButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
