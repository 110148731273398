import React from 'react';
import * as Styled from './StyledUserCard';
import BadgeIcon from 'common/IconComponents/BadgeIcon';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import VettedBadgeIcon from 'common/IconComponents/Badges/VettedBadgeIcon';
import Tooltip from 'components/Tooltip';
import VerifiedBadgeIcon from 'common/IconComponents/Badges/VerifiedBadgeIcon';

const DEFAULT_COLOR = '#D1D7FF66';

const VERIFIED = 'verified';
const VETTING_COMPLETE = 'VETTING_COMPLETE';

const UserCardBadges = () => {
  const currentUserId = useSelector(selectCurrentUserProperty('_id'));
  const currentProfileUserId = useSelector(profileSelectors.selectProfileProperty('userId'));
  const profleVettingStatus = useSelector(profileSelectors.selectProfileProperty('vettingStatus'));

  const viewMode = currentUserId !== currentProfileUserId;
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const isCompanyVerified = profleVettingStatus?.status === VETTING_COMPLETE;

  const profileStatus = useSelector(profileSelectors.selectProfileProperty('verificationStatus'));
  const currentUserStatus = useSelector(selectCurrentUserProperty('verificationStatus'));

  const isTalentVetted = useSelector(profileSelectors.selectProfileProperty('vetted'));

  const isTalentVerified = viewMode ? profileStatus === VERIFIED : currentUserStatus === VERIFIED;

  return (
    <Styled.UserCardBadgesWrapper>
      {isCompanyView ? (
        <BadgeIcon
          iconName="verified"
          tooltip={isCompanyVerified ? 'Business verified' : 'Verification pending'}
          tooltipId="company-info-tooltip"
          iconColor={isCompanyVerified ? null : DEFAULT_COLOR}
          iconSize={28}
        />
      ) : (
        <>
          <div data-tip data-for={'vetted-tooltip'}>
            <VettedBadgeIcon active={isTalentVetted} />
            <Tooltip
              className="secondary"
              tooltipId={'vetted-tooltip'}
              overrideTooltipPosition
              isClickable
              delayHide={0}
            >
              {isTalentVetted ? 'Vetted' : 'Profile vetting pending'}
            </Tooltip>
          </div>
          <div data-tip data-for={'verified-tooltip'}>
            <VerifiedBadgeIcon active={isTalentVerified} />
            <Tooltip
              className="secondary"
              tooltipId={'verified-tooltip'}
              overrideTooltipPosition
              isClickable
              delayHide={0}
            >
              {isTalentVerified ? 'ID verified' : 'ID verification pending'}
            </Tooltip>
          </div>
        </>
      )}
    </Styled.UserCardBadgesWrapper>
  );
};

export default UserCardBadges;
