import Api from './api';

const api = new Api('uploads');

const uploadFile = formData =>
  api.post('/upload-file', formData, { headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' } });

export default {
  uploadFile
};
