import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal';
import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import ContractInReviewIcon from 'common/IconComponents/DocumentIcon';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  span {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #ffffff;
  }
`;

const StyledBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 40px;
  background-image: url('${process.env.PUBLIC_URL}/assets/backgrounds/bg-02.png');
  background-repeat: no-repeat;
  background-size: cover;
`;

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const StyledDescription = styled.div`
  display: flex;
  text-align: center;
  padding: 0 18px;

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <span>Ready to submit?</span>
      <StyledBackground>
        <ContractInReviewIcon />
      </StyledBackground>
    </StyledHeader>
  );
};

const ReadyToSubmitModal = ({ name, data }) => {
  const { onConfirm, onCancel } = data;
  return (
    <TxplModal
      title="submit modal"
      name={name}
      appElement={document.getElementById('root-modal')}
      renderHeader={Header}
      hideBorder
      renderFooter={
        <StyledFooter>
          <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText onClick={onCancel}>
            NEVERMIND
          </ContainedButton>
          <ContainedButton color="#111112" backgroundColor="#00FF00" forceDisplayText onClick={onConfirm}>
            SUBMIT
          </ContainedButton>
        </StyledFooter>
      }
    >
      <StyledDescription>
        <span>
          To increase your chances of getting approved, ensure that you have completed all your profile. Once you
          submit, your profile will be temporarily locked while we review it.
        </span>
      </StyledDescription>
    </TxplModal>
  );
};

export default withRenderPortal('ready-submit-modal')(ReadyToSubmitModal);
