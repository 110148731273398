import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { api } from 'api';
import { selectMyProfile, selectMyProfileId } from 'store/profile/profileSelectors';
import { selectIsAuthLoading } from 'store/auth/authSelectors';
import { apiCallActions } from 'store/apiCall';
import useProfileSwitcher from './useProfileSwitcher';

const useProfiles = talentPublicUrl => {
  const isMounted = React.useRef(true);
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const profile = useSelector(selectMyProfile);
  const activeProfileId = useSelector(selectMyProfileId);
  const { switchProfile } = useProfileSwitcher();

  const dispatch = useDispatch();
  const [isAllowed, setIsAllowed] = React.useState(true);
  const isLoading = useSelector(profileSelectors.selectIsProfileLoading);

  const getAvailableProfiles = React.useCallback(
    async callback => {
      try {
        const res = await api.get('talents/available-profiles');
        const { profiles } = res.data;

        dispatch(profileActions.initializeProfiles(profiles));

        const validProfile = profiles.find(item => item._id === activeProfileId);

        if (activeProfileId && validProfile) {
          isMounted.current && setIsAllowed(talentPublicUrl === validProfile.publicUrl);
          dispatch(profileActions.setActiveProfileId(activeProfileId));
          return;
        }

        const defaultProfilePublicUrl = get('0.publicUrl', profiles);
        const defaultProfile = get('0', profiles);

        isMounted.current && setIsAllowed(talentPublicUrl === defaultProfilePublicUrl);
        await switchProfile(defaultProfile);
        callback && callback();
      } finally {
        if (isMounted.current) {
          dispatch(apiCallActions.sendRequestSuccess({ name: 'profiles' }));
        }
      }
    },
    [dispatch, activeProfileId, talentPublicUrl, switchProfile]
  );

  React.useEffect(() => {
    isMounted.current = true;
    if (!isAuthLoading) {
      if (isEmpty(profile)) {
        getAvailableProfiles();
      } else if (isMounted.current) {
        dispatch(apiCallActions.sendRequestSuccess({ name: 'profiles' }));
        setIsAllowed(false);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [talentPublicUrl, isAuthLoading, dispatch]);

  return {
    isLoading,
    isAuthLoading,
    isAllowed,
    profile,
    getAvailableProfiles
  };
};

export default useProfiles;
