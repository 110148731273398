import styled from '@emotion/styled';
import React from 'react';
import { pageSize } from 'utils/devices';
import JobCard from 'pages/jobs/card/JobCard';
import SearchEmptyState from '../../../components/SearchEmptyState';
import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import FormSelect from 'common/form/FormSelect';
import useProfilePath from 'hooks/useProfilePath';

const JobGridList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  @media ${pageSize.S} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

const sortOptions = [
  { label: 'Sort by latest', value: 'desc' },
  { label: 'Sort by oldest', value: 'asc' }
];
const JobSearchResult = ({ hasMore, initialSort, jobs, jobsFound, loading, onSort }) => {
  const [sortBy, setSortBy] = React.useState(sortOptions.find(it => it.value === initialSort));
  const { myProfilePath } = useProfilePath();
  const handleSort = e => {
    setSortBy(e);
    onSort && onSort(e);
  };
  return (
    <>
      <Flex justifySpaceBetween alignCenter mb={16}>
        <Typography color="rgba(230, 234, 255, 0.6)">
          {!loading && (jobsFound ? `${jobsFound} ${jobsFound > 1 ? 'jobs' : 'job'} found` : 'No matching jobs found')}
        </Typography>
        <FormSelect
          width="220px"
          inputGap={'0px'}
          borderRadius={8}
          padding={'4px 12px'}
          placeholderColor="rgba(255, 255, 255, 1)"
          placeholder="Sort by date posted"
          value={sortBy}
          isSearchable={false}
          onChange={e => handleSort(e)}
          options={sortOptions}
        />
      </Flex>
      {hasMore && !jobs.length ? (
        <></>
      ) : !jobs?.length && !loading ? (
        <SearchEmptyState url={myProfilePath + '/all-jobs'} emptyStateFor={'jobs'} />
      ) : (
        <>
          {!!jobs?.length && !loading && (
            <JobGridList>
              {jobs.map(job => (
                <JobCard key={job.id} job={job} hideApply />
              ))}
            </JobGridList>
          )}
        </>
      )}
    </>
  );
};

export default JobSearchResult;
