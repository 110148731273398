import React, { useRef } from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';

const InputWrapper = styled.div`
  ${({ p }) => p && `padding: ${p};`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
	${({ pb }) => pb && `padding-bottom: ${pb}px;`}
	${({ mt }) => mt && `margin-top: ${mt}px;`}
	${({ mb }) => mb && `margin-bottom: ${mb}px;`}
	display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  gap: ${({ inputGap }) => inputGap || '6px'};
  position: relative;
  ${({ inputRight }) => inputRight && 'flex-direction: row'}
  ${({ fullWidth }) => fullWidth && 'width: 100%; height: max-content;'}
	${({ width }) => (width ? `width: ${width}` : 'width: 100%')};
  ${({ height }) => (height ? `height: ${height}px` : 'height: auto')};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight}` : '')};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}` : '')};
`;
const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.iconTop && `top: ${props.iconTop}px`};
  ${props => props.iconBottom && `bottom: ${props.iconBottom}px`};
  ${props => props.iconRight && `right: ${props.iconRight}px`};
  ${props => props.onClick && 'cursor: pointer'};
`;
export const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  display: flex;
  flex-direction: row;
  color: white;
  ${props => (props.disabled ? 'color: rgba(230, 234, 255, 0.35);' : '')}
  font-family: ${props => (props.familyInter ? 'inter' : 'Space Grotesk')};
  font-size: ${props => (props.fontSize ? '16px' : '')};
  font-weight: ${props => (props.fontWeight ? '400' : '')};
  line-height: ${props => (props.lineHeight ? '16px' : '')};
  ${props => props.boldWeight && 'font-weight: 700;'}
`;
const StyledHintText = styled.label`
  ${commonStyles.ui_text_small};
  color: ${({ hintError }) => (hintError ? '#FF5151' : 'rgba(230, 234, 255, 0.35)')};
  ${props => props.lightWeight && 'font-weight: 400;'}
`;
const StyledInput = styled.input`
  ${commonStyles.ui_text_medium};
  padding: ${({ padding }) => padding || '15px 18px 15px 18px'};
  outline: none;
  background: transparent;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  ${props => (props.error ? 'border: 2px solid #FF5151;' : '')}
  width: ${({ width }) => width || '-webkit-fill-available'};
  ${props => (props.disabled ? 'border: 2px solid rgba(230, 234, 255, 0.35);' : '')}
  ${props => (props.disabled ? 'color: rgba(230, 234, 255, 0.35);' : '')}
	${props => props.lightWeight && 'font-weight: 400;'}
	&:hover {
    border: 2px solid rgba(230, 234, 255, 0.6);
    ${props => (props.disabled ? 'border: 2px solid rgba(230, 234, 255, 0.35);' : '')}
  }
  &:focus {
    border: 2px solid #7266ee;
    ${props => (props.disabled ? 'border: 2px solid rgba(230, 234, 255, 0.35);' : '')}
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledError = styled.div`
  ${commonStyles.ui_text_small}
  color: #FF5151;
`;
const MaxLength = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(230, 234, 255, 0.35);
  margin-left: auto;
`;
export const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);
  padding-left: 3px;
  &::after {
    content: '*';
  }
  ${props => (props.errorRequired ? 'color: #FF5151' : 'color: rgba(229,234,255,0.35)')}
`;
const StyledInputContainer = styled.div`
  position: relative;
  ${({ flexDirection }) => !flexDirection && 'width: 100%;'}
  ${props => props.fitContent && 'width: fit-content;'}
	${props => props.inputWidth && `width: ${props.inputWidth}px`}
`;

const FormInput = (
  {
    boldWeight,
    children,
    disableInput,
    disabled,
    error,
    errorRequired,
    familyInter = false,
    fitContent,
    flexDirection,
    fontSize,
    fontWeight,
    fullWidth,
    height,
    hideMaxLength,
    hintError,
    hintText,
    icon,
    iconBottom,
    iconRight,
    iconTop,
    inputGap,
    inputRight = false,
    inputWidth = '',
    justifyContent,
    label,
    lightWeight,
    lineHeight = false,
    marginLeft,
    marginRight,
    maxLength,
    mb,
    mt,
    noErrorMessage = false,
    onChange,
    onIconClick,
    p,
    pb,
    pt,
    required,
    value = '',
    width,
    ...rest
  },
  ref
) => {
  const inputRef = useRef();

  return (
    <InputWrapper
      p={p}
      pt={pt}
      mb={mb}
      pb={pb}
      mt={mt}
      ref={ref}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      inputRight={inputRight}
      inputGap={inputGap}
      fullWidth={fullWidth}
      width={width}
      height={height}
      marginRight={marginRight}
      marginLeft={marginLeft}
    >
      {inputRight ? (
        <div>
          <StyledLabel
            fontSize={fontSize}
            lineHeight={lineHeight}
            fontWeight={fontWeight}
            familyInter={familyInter}
            boldWeight={boldWeight}
            disabled={disabled}
          >
            {label}
            {required && <Required errorRequired={errorRequired} />}
          </StyledLabel>
          {hintText && <StyledHintText lightWeight={lightWeight}>{hintText}</StyledHintText>}
        </div>
      ) : (
        <>
          <StyledLabel
            fontSize={fontSize}
            lineHeight={lineHeight}
            fontWeight={fontWeight}
            familyInter={familyInter}
            boldWeight={boldWeight}
            disabled={disabled}
          >
            {label}
            {required && <Required errorRequired={errorRequired} />}
          </StyledLabel>
          {hintText && (
            <StyledHintText hintError={hintError} lightWeight={lightWeight}>
              {hintText}
            </StyledHintText>
          )}
        </>
      )}
      <StyledInputContainer fitContent={fitContent} inputWidth={inputWidth} flexDirection={flexDirection}>
        <StyledInput
          lightWeight={lightWeight}
          value={value ?? ''}
          onChange={onChange}
          error={error}
          disabled={disabled || disableInput}
          ref={inputRef}
          maxLength={maxLength}
          hideMaxLength={hideMaxLength}
          {...rest}
        />
        {icon && (
          <IconWrapper
            iconBottom={iconBottom}
            iconTop={iconTop}
            iconRight={iconRight}
            onClick={e => onIconClick && onIconClick(e)}
          >
            <Icon icon={icon} color="rgba(230, 234, 255, 0.35);" size={16} />
          </IconWrapper>
        )}
        {children}
      </StyledInputContainer>
      {(!!error || !!maxLength) && !noErrorMessage && (
        <FlexRow>
          {error && <StyledError>{error}&nbsp;</StyledError>}
          {maxLength && !hideMaxLength && (
            <MaxLength>
              {value?.length ?? '0'}/{maxLength}
            </MaxLength>
          )}
        </FlexRow>
      )}
    </InputWrapper>
  );
};

export default React.forwardRef(FormInput);
