import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';
import { portalActions } from 'store/portals';
import FooterLogo from 'images/thexplace-logo.png';
import ContactSupportSent from './backoffice/ContactSupportSent';

const StyledFooter = styled.div`
  width: 100%;
  background: #1a1b1e;
`;
const FooterContainer = styled.div`
  padding: 0 81px 0 81px;
  margin: 0 auto;
  max-width: 1240px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-top: 240px;
  justify-content: space-between;
  @media ${pageSize.XS} {
    flex-direction: column;
    align-items: start;
    height: max-content;
    justify-content: center;
    padding: 24px 0;
    margin-top: 160px;
  }
  @media ${pageSize.M}, ${pageSize.S} {
    margin-top: 200px;
  }
`;
const FooterInnerContainer = styled.div`
  z-index: 10;
  display: flex;
  @media ${pageSize.XS} {
    flex-direction: column;
  }
`;
const FooterLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  @media ${pageSize.XS} {
    padding: 0 0 16px 0px;
    padding-left: 0px;
  }
`;
const Logo = styled.img`
  width: 104px;
  @media ${pageSize.XS} {
    margin-left: 16px;
  }
`;
const FooterItem = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: rgba(230, 234, 255, 0.35);
  margin-left: 24px;
  cursor: pointer;
  @media ${pageSize.XS} {
    margin-left: 16px;
    padding-top: 15px;
  }
`;
const FooterRightCopyright = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: rgba(230, 234, 255, 0.35);
  @media ${pageSize.XS} {
    margin: 0 0 0 16px;
  }
`;
const FooterItemHelp = styled(FooterItem)`
  cursor: pointer;
`;

const Footer = () => {
  const dispatch = useDispatch();

  const helpPopup = () => {
    dispatch(
      portalActions.openPortal({
        name: 'help-modal'
      })
    );
  };

  return (
    <StyledFooter>
      <ContactSupportSent />
      <FooterContainer>
        <FooterInnerContainer>
          <Link to="/profile">
            <Logo src={FooterLogo} />
          </Link>
          <FooterLinks>
            <FooterItem>
              <Link to="/terms-of-service" style={{ textDecoration: 'none', color: 'inherit' }}>
                {' '}
                Terms of service
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
                Privacy Policy
              </Link>
            </FooterItem>
            <FooterItemHelp onClick={() => helpPopup()}>Help</FooterItemHelp>
            <FooterItem>
              <Link to="/website" style={{ textDecoration: 'none', color: 'inherit' }}>
                {' '}
                Website
              </Link>
            </FooterItem>
          </FooterLinks>
        </FooterInnerContainer>
        <FooterRightCopyright>TheXPlace © 2024</FooterRightCopyright>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
