import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';

import LockPageEditModal from '../lockPage/LockPageEditModal';

const BackOfficeUnlockRequests = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Operations' }, { label: 'Unlock requests' }]));
  }, [dispatch]);

  const contentText = content => (content?.length > 30 ? `${content.substr(0, 30)}...` : content);

  return (
    <>
      <Table keyId="_id" paginate endpoint="/profile-unlock" dataKey="data">
        <Column
          title="account id"
          keyName="accountId"
          sort
          search
          maxWidth="280px"
          render={item => <div>{item.accountId}</div>}
        />
        <Column
          title="requested by"
          keyName="requestedBy"
          sort
          search
          maxWidth="445px"
          render={item => <div>{contentText(item.requestedBy)}</div>}
        />
        <Column
          title="number of requests"
          keyName="numberOfRequests"
          sort
          search
          maxWidth="211px"
          render={item => <div>{item.numberOfRequests}</div>}
        />
        <Column
          title="request date"
          keyName="requestedDate"
          sort
          maxWidth="363px"
          render={item => <div>{moment(item.requestedDate).format('DD MMMM YYYY')}</div>}
        />
        <Column title="" width={46} type="action" onClick={item => history.push(`soloists/${item.profile}`)} />
      </Table>

      <LockPageEditModal />
    </>
  );
};

export default BackOfficeUnlockRequests;
