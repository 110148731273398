import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import { StyledTextContent } from './VerificationSubmitted.styled';
import Footer from './Footer';
import Header from './Header';

const VerificationSubmittedModal = ({ name }) => {
  return (
    <TxplModal
      name={name}
      appElement={document.getElementById('root')}
      hideBorder
      renderHeader={Header}
      renderFooter={<Footer />}
    >
      <StyledTextContent>
        Stripe may take a few minutes to process your ID, but don`t worry — the result will be waiting for you on your
        profile in no time!
      </StyledTextContent>
    </TxplModal>
  );
};

export default withRenderPortal('verification-submitted-modal')(VerificationSubmittedModal);
