import styled from '@emotion/styled';
import FormInput from 'common/form/FormInput';
import { Link } from 'react-router-dom';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 6px;
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(204, 213, 255, 0.09);
`;

export const CreateAccount = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.6);
  margin: 0;
`;

export const Form = styled.form`
  padding-top: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
  @media ${pageSize.XS} {
    margin-bottom: 24px;
  }
`;

export const Input = styled(FormInput)`
  border-radius: 8px;
`;

export const ServiceLink = styled(Link)`
  color: #7266ee;
`;

export const CheckboxText = styled.div`
  display: inline-flex;
  gap: 5px;
  color: rgba(230, 234, 255, 0.6);
`;

export const ErrorMessage = styled.div`
  ${commonStyles.ui_error_text}
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
  justify-content: end;
  @media ${pageSize.XS} {
    margin-top: 0;
  }
`;
