import * as React from 'react';

const DocumentIcon = props => (
  <svg width={190} height={190} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.083 22.167a9.5 9.5 0 0 1 9.5-9.5h80.75l39.583 39.583v115.583a9.5 9.5 0 0 1-9.5 9.5H39.583a9.5 9.5 0 0 1-9.5-9.5V22.167Z"
      fill="url(#a)"
    />
    <path d="m120.333 12.667 39.583 39.583h-30.083a9.5 9.5 0 0 1-9.5-9.5V12.667Z" fill="#0180F7" />
    <path
      stroke="#fff"
      strokeWidth={6.333}
      strokeLinecap="round"
      d="M71.25 68.083h49.083M71.25 87.083h49.083M71.25 106.083h49.083M71.25 125.083h49.083"
    />
    <g filter="url(#b)">
      <circle cx={158.334} cy={98.167} r={31.667} fill="#C7C7FF" fillOpacity={0.21} />
    </g>
    <path
      d="M146.86 90.98v1.335h4.652v-1.133c0-3.114 2.467-4.691 5.662-4.691 3.074 0 5.258 1.577 5.258 4.125 0 2.346-1.376 3.357-3.964 4.49l-.849.363c-2.548 1.092-3.357 2.75-3.357 5.379v1.74h4.651v-1.456c0-1.214.323-1.78 1.335-2.225l.889-.364c2.993-1.294 5.986-3.316 5.986-7.967v-.203c0-4.853-4.206-8.25-9.949-8.25-5.824 0-10.314 3.316-10.314 8.857Zm6.229 17.068c0 2.062 1.496 3.518 3.478 3.518 2.022 0 3.519-1.456 3.519-3.518 0-2.023-1.497-3.479-3.519-3.479-1.982 0-3.478 1.456-3.478 3.479Z"
      fill="#fff"
    />
    <defs>
      <linearGradient id="a" x1={95} y1={19} x2={95} y2={171} gradientUnits="userSpaceOnUse">
        <stop stopColor="#00417E" />
        <stop offset={1} stopColor="#0081FA" />
      </linearGradient>
      <filter
        id="b"
        x={102.917}
        y={42.75}
        width={110.833}
        height={110.833}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={11.875} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_528_19423" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_528_19423" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.583} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_528_19423" />
      </filter>
    </defs>
  </svg>
);

export default DocumentIcon;
