import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
export const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  z-index: 50;

  @media ${pageSize.XS} {
    width: calc(100vw - 32px);
    margin: 0px 16px;
  }
  @media ${pageSize.S} {
    padding-left: 81px;
  }

  @media ${pageSize.M1} {
    padding-left: 54px;
    width: 324px;
  }

  @media ${pageSize.M2} {
    padding-left: calc((100vw - 1135px) / 2);
    width: 324px;
  }

  @media ${pageSize.L} {
    padding-left: calc((100vw - 1245px) / 2);
    width: 324px;
  }

  @media ${pageSize.XL} {
    padding-left: 338px;
    width: 324px;
  }
`;
