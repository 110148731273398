import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';
import ContainedButton from 'common/ContainedButton';

const StyledAddMediaCoverageContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  margin-bottom: 54px;
`;

const StyledDescription = styled.p`
  ${commonStyles.ui_text_medium}
  margin: 32px 0px;
  width: 295px;
  text-align: center;
`;

const AddFirstMediaCoverage = () => (
  <StyledAddMediaCoverageContainer>
    <Icon icon="thumb-up" size={64} />

    <StyledDescription>Did any reviews brighten your day? Share away.</StyledDescription>

    <ContainedButton opens="media-coverage-modal" icon="plus" forceDisplayText>
      Add media coverage
    </ContainedButton>
  </StyledAddMediaCoverageContainer>
);

export default AddFirstMediaCoverage;
