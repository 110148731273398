import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';

export const CancelButton = styled(ContainedButton)`
  margin-right: 3px;
`;
export const InformationBoard = styled.div`
  width: 100%;
  min-height: 120px;
  height: fit-content;
  background: rgba(204, 212, 255, 0.04);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 24px;
`;
export const Section = styled.div`
  display: flex;
  height: fit-content;
  &:nth-child(2) {
    margin-top: 28px;
  }
`;
export const SectionText = styled.span`
  width: 50%;
  padding-right: 10px;
  height: fit-content;
  white-space: pre-wrap;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  ${props => props.color && `color: ${props.color};`};
`;
export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const SwitcherState = styled.span`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 16px;
`;
