import React, { useEffect, useState } from 'react';
import validator from 'validator';
import lodash from 'lodash';
import { Map, fromJS } from 'immutable';
import useScrollToMandatoryField from 'hooks/useScrollToMandatoryField';
import moment, { isDate, isMoment } from 'moment';
import { isStrongPassword, processValidationErrors } from '../utils';
import trim from 'lodash/fp/trim';

const transformFields = fields =>
  fromJS(fields) && fromJS(fields).map(field => field.set('value', field.get('initialValue')).delete('initialValue'));

const getErrorMessage = (name, validator, messages) => {
  const upperName = lodash.capitalize(lodash.startCase(lodash.camelCase(name)));

  switch (validator) {
    case 'isRequired':
    case 'isCustomPronounRequired':
      return messages?.isRequired ?? `This field is required.`;

    case 'typeItAgain':
      return 'Confirm your password';

    case 'isLessThanMillion':
      return `${upperName} must be less than 1,000,000`;

    case 'isEmail':
      return `${upperName} should be a valid email`;

    case 'strongPassword':
      return 'Password must contain at least 8 characters, one number and one uppercase.';

    case 'isNotEmpty':
      return `${upperName} is empty.`;

    case 'confirmPassword':
      return "Passwords don't match";

    case 'isDateBefore':
      return `${upperName} can't be in future.`;

    default:
      return `${upperName} is invalid`;
  }
};

const checkThroughValidator = (rule, value, dependOn) => {
  if (rule === 'isRequired' || rule === 'typeItAgain') {
    if (typeof value === 'string') {
      return !validator.equals(value, '');
    }
    if (typeof value === 'object' && !(isDate(value) || isMoment(value))) {
      return !!value?.size;
    }

    return Boolean(value);
  }

  if (rule === 'isDate' && value) {
    return validator.isDate(new Date(value));
  }

  if (rule === 'isDateBefore' && value) {
    return moment(value).isBefore(moment());
  }

  if (rule === 'isLessThanMillion') return value < 1000000;

  if (rule === 'isLessThanNowYear') return value < new Date().getFullYear();

  if (rule === 'strongPassword') return isStrongPassword(value);

  if (rule === 'isNotEmpty') {
    return value.size !== 0;
  }

  if (rule === 'confirmPassword') {
    return value === dependOn;
  }

  // make custom pronoun required if pronoun selected is 'other'
  if (rule === 'isCustomPronounRequired' && dependOn) {
    const pronounsFieldValue = dependOn.toJS();
    if (pronounsFieldValue?.value === 'other' && pronounsFieldValue.label === 'Other') {
      return !validator.equals(value, '');
    }
    return true;
  }

  return !value ? true : validator[rule](value);
};

const useForm = ({
  fields,
  callApi,
  onValidate,
  onSubmitSuccess,
  onSuccess = (data = {}) => data,
  onSuccessRedirect,
  closePortal,
  doNotScroll = false
}) => {
  const [formFields, updateFormFields] = useState(transformFields(fields));
  const [errors, updateErrors] = useState({});
  const [submitting, updateSubmitting] = useState(false);
  const [submitted, updateSubmitted] = useState(false);
  const [apiResponse, updateApiResponse] = useState({});
  const [dirty, updateDirty] = useState(false);
  const { handleScroll } = useScrollToMandatoryField();

  const clearErrors = field => {
    if (field) {
      const newErrors = errors;
      delete newErrors[field];
      updateErrors(newErrors);
      return;
    }
    updateErrors({});
  };

  const initialize = initialValues => {
    updateDirty(false);

    updateFormFields(currFormFields =>
      currFormFields.map((formField, fieldName) =>
        formField.update('value', value =>
          initialValues.hasOwnProperty(fieldName) ? fromJS(initialValues[fieldName]) : value
        )
      )
    );
  };

  const onFieldChange = field => event => {
    if (Object.keys(errors).length && !onValidate) clearErrors(field);
    if (!dirty) updateDirty(true);
    const value = event?.target ? event.target.value : event;

    let val = value;
    if (field === 'email') {
      val = value.replace(/[\s]+/g, '');
    }
    if (field === 'publicUrl') {
      val = value.replace(/[^A-Za-z0-9._-]+/g, '');
    }

    updateFormFields(currFormFields =>
      currFormFields?.setIn([field, 'value'], typeof val === 'object' ? fromJS(val) : val)
    );
  };

  const onFieldInsert = field => data => {
    updateFormFields(currFormFields => currFormFields?.updateIn([field, 'value'], items => items.push(data)));
  };

  const onFiledSet = field => data => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    updateFormFields(currFormFields => currFormFields?.updateIn([field, 'value'], items => (items = data)));
  };

  const onFieldDelete =
    field =>
    (value, key = '_id') => {
      updateFormFields(currFormFields =>
        currFormFields?.updateIn([field, 'value'], items =>
          items.filter(
            curr => (typeof curr !== 'object' ? curr : curr[key]) !== (typeof value !== 'object' ? value : value[key])
          )
        )
      );
    };

  const values = React.useMemo(() => formFields?.map(curr => curr.get('value')).toJS(), [formFields]);

  const onSubmit = (event, additionalValues, submitOptions = { redirect: true }) => {
    if (event) event.preventDefault();
    updateErrors({});
    let customErrors = {};

    if (onValidate) {
      const customFailedFields = onValidate(formFields);
      if (Object.keys(customFailedFields).length) {
        customErrors = customFailedFields;
      }
    }

    const failedFields = formFields
      .filter(field => field.get('validate'))
      .reduce((result, field, name) => {
        let newResult = result;

        field.get('validate').forEach(rule => {
          const value = field.get('trim') ? trim(field.get('value')) : field.get('value');
          const isValid =
            typeof rule === 'string'
              ? checkThroughValidator(
                  rule,
                  value,
                  field.get('dependOn') && formFields.get(`${field.get('dependOn')}`).get('value')
                )
              : rule(value);

          console.log(
            checkThroughValidator(
              rule,
              value,
              field.get('dependOn') && formFields.get(`${field.get('dependOn')}`).get('value')
            )
          );

          if (!isValid && !newResult.get(name)) {
            newResult = newResult.set(
              name,
              getErrorMessage(field.get('title') || name, rule, field.get('messages')?.toJS())
            );
          }
        });

        return newResult;
      }, new Map());

    if (failedFields.size || !lodash.isEmpty(customErrors)) {
      const error = { ...failedFields.toJS(), ...customErrors };
      if (!doNotScroll) {
        handleScroll(error);
      }
      updateErrors(error);
      return;
    }

    updateSubmitting(true);

    if (callApi) {
      if (!submitting) {
        const options = {
          ...formFields?.map(curr => (curr.get('trim') ? trim(curr.get('value')) : curr.get('value'))).toJS(),
          ...additionalValues
        };
        callApi(options)
          .then(response => {
            const data = response?.data ?? response ?? {};

            if (data?.success !== undefined && !data?.success) {
              throw new Error(data.message);
            }

            updateApiResponse(response);
            updateSubmitting(false);
            updateSubmitted(true);
            updateDirty(false);

            return data;
          })
          .then(onSuccess)
          .then(onSubmitSuccess)
          .then(() => {
            if (submitOptions.redirect && onSuccessRedirect) {
              onSuccessRedirect();
            }
          })
          .catch(error => {
            updateErrors(processValidationErrors(error));
          })
          .finally(() => {
            updateSubmitting(false);
            updateSubmitted(true);
            closePortal && closePortal();
          });
      }
    } else {
      updateSubmitting(false);
      updateSubmitted(true);
      onSubmitSuccess(values);
    }
  };

  const triggerErrors = event => customOnValidate => {
    if (event) event.preventDefault();
    updateErrors({});

    const validationCheck = customOnValidate || onValidate;

    if (validationCheck) {
      const customFailedFields = validationCheck(formFields);
      if (Object.keys(customFailedFields).length) {
        if (!doNotScroll) {
          setTimeout(() => {
            handleScroll(customFailedFields);
          }, [100]);
        }
        updateErrors(customFailedFields);
        return { success: false, errors: customFailedFields };
      }
    }

    const failedFields = formFields
      .filter(field => field.get('validate'))
      .reduce((result, field, name) => {
        let newResult = result;

        field.get('validate').forEach(rule => {
          const isValid =
            typeof rule === 'string'
              ? checkThroughValidator(
                  rule,
                  field.get('value'),
                  field.get('dependOn') && formFields.get(`${field.get('dependOn')}`).get('value')
                )
              : rule(field.get('value'));

          if (!isValid && !newResult.get(name)) {
            newResult = newResult.set(name, getErrorMessage(field.get('title') || name, rule));
          }
        });

        return newResult;
      }, new Map());

    if (failedFields.size) {
      updateErrors(failedFields.toJS());
      return { success: false, errors: failedFields.toJS() };
    }

    return { success: true };
  };

  useEffect(() => {
    if (Object.keys(errors).length && onValidate) triggerErrors()(onValidate);
  }, [formFields]);

  return {
    values,
    initialize,
    submitting,
    submitted,
    apiResponse,
    errors,
    onFieldChange,
    onFieldInsert,
    onFiledSet,
    onFieldDelete,
    onSubmit,
    dirty,
    updateDirty,
    triggerErrors,
    clearErrors
  };
};

export default useForm;
