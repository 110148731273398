import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  ${props => props.small && 'text-decoration: none;'}
  color: rgba(230, 234, 255, 0.35);
`;
const Label = styled.span`
  color: rgba(230, 234, 255, 0.35);
  ${props => props.small && 'font-family: Space Grotesk;'}
  ${props => props.small && 'font-size: 16px;'}
  ${props => props.small && 'line-height: 22px;'}
  ${props => (props.isLast ? 'color: white;' : '')}
`;
const StyledArrowRight = styled.span`
  padding-bottom: ${props => (props.small ? '0px' : '10px')};
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.small && 'font-family: Space Grotesk;'}
  ${props => props.small && 'font-size: 16px;'}
  ${props => props.small && 'line-height: 22px;'}
`;

const BackofficeBreadCrumbsItem = ({ isLast, label, specificPage, url }) => (
  <>
    {url ? (
      <StyledLink to={url} small={specificPage}>
        <Label isLast={isLast} small={specificPage}>
          {label}
        </Label>
      </StyledLink>
    ) : (
      <Label isLast={isLast} small={specificPage}>
        {label}
      </Label>
    )}
    {!isLast && <StyledArrowRight small={specificPage}>{specificPage ? '/' : '→'}</StyledArrowRight>}
  </>
);

export default BackofficeBreadCrumbsItem;
