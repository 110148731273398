import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { profileSelectors } from 'store/profile';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';
import Typography from 'components/shared/Typography';
import useViewport from 'hooks/useViewport';
import useStartConversation from 'hooks/chatApi/useStartConversation';
import NA from 'images/NA.png';
import moment from 'moment';
import getDisciplinesNameIconAndColor from 'utils/disciplines';
import JobCardDropDown from '../JobCardDropDown';
import {
  JobButtonWrapper,
  JobCompanyContainer,
  JobCompanyImage,
  JobCompanyName,
  JobImage,
  JobImageGradient,
  JobImageWrapper,
  JobInfoWrapper,
  JobStatus,
  JobStatusTopology,
  JobTagList,
  JobTagListWrapper,
  JobTitle,
  StyledButton,
  StyledJobCard,
  StyledTooltip
} from '../styled.components';
import Tag from 'common/Tag/Tag';
import BookmarkButton from 'common/BookmarkButton';
import useJobServices from 'hooks/api/useJobServices';
import Flex from 'common/styles/Flex';
import { authSelectors } from 'store/auth';
import getProfileInfoFromJob from './getProfileInfoFromJob';
import useApplyToJob from 'hooks/useApplyToJob';
import * as Styled from '../../../../components/UserCard/StyledUserCard';
import {
  AppliedInfo,
  ContractStatusIcon,
  ContractStatusIconColor,
  ContractStatusOptions,
  ReviewBox,
  ReviewBoxDateArea,
  ReviewBoxFooter,
  ReviewBoxText,
  ReviewBoxTextArea,
  ReviewButtonArea,
  SeeMoreButton
} from './JobCardStyles';
import { compose, find, ifElse, prop, propEq, propOr } from 'ramda';
import { format, parseISO } from 'date-fns';
import { portalActions } from 'store/portals';
import useCompanyRole from 'hooks/useCompanyRole';

const getAppliedBy = profileId => compose(find(propEq('talentId', profileId)), propOr([], 'appliedBy'));
const isTalentProfile = propEq('talentModel', 'Talent');
const getCreatorProfile = job => ifElse(isTalentProfile, prop('talent'), prop('companyProfile'))(job);

const JobCard = ({ dragIcon = null, hideApply, isListView, job, showDropdown, staffedStatus }) => {
  const {
    _id,
    applied,
    appliedAt,
    appliedStatus,
    createdAt,
    details,
    favorite,
    id,
    isMine,
    isSaved,
    profileType,
    status,
    talentModel,
    thumbnail,
    title,
    contractedSupply,
    code
  } = job;
  let appliedEntity;

  const jobId = _id ?? id;
  const { basicInfo, img, name, publicUrl, talentId, type, url } = getProfileInfoFromJob(job);
  const creatorProfile = getCreatorProfile(job);
  const { coverImage, imageUri } = basicInfo ?? {};
  const { discipline, disciplines, jobType, location, workplaceType } = details ?? job ?? {};
  const [jobStatus, setJobStatus] = useState(status);
  const [contacted, setContacted] = useState(false);
  const jobDisciplines = disciplines?.length ? disciplines : discipline;
  const { pathname } = useLocation();
  const isAuthed = useSelector(authSelectors.isAuthed);
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const [isFavored, setIsFavored] = React.useState(!!favorite);
  const isApplied = !!applied?.all?.find(({ name }) => name === profileId) || appliedStatus === 'in-progress';
  const isJobIPostedPage = pathname.includes('/jobs-i-posted');
  const { applyProfileToJob, loadingApply } = useApplyToJob();
  const { saveJob } = useJobServices();
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const { isXS } = useViewport();
  const { redirectToStartConversation } = useStartConversation();

  const { isMember } = useCompanyRole();

  useEffect(() => {
    appliedEntity = getAppliedBy(profileId)(job);
    setContacted(!!appliedEntity?.contacted);
  }, [job]);

  const { contractDate, startDate, contractStatus, contractId } = job;
  const selectedContractStatus = ['pending-for-approval', 'on-hold'];
  const selectedNoContractStatus = ['in-review', 'rejected'];
  const hiredContractStatus = ['in-progress', 'cancelled', 'finished', 'active'];

  const selectedContract = contractStatus && selectedContractStatus.includes(contractStatus);
  const selectedNoContract = contractStatus && selectedNoContractStatus.includes(contractStatus);
  const isContractActive = contractStatus && contractStatus !== 'in-draft';

  useEffect(() => {
    appliedEntity = getAppliedBy(profileId)(job);
    setContacted(!!appliedEntity?.contacted);
  }, [job]);

  const profilePath =
    (type !== undefined && type === 'talent') ||
    (profileType !== undefined && profileType === 'talent') ||
    (talentModel !== undefined && talentModel === 'Talent')
      ? 'talent'
      : 'company';

  const jobPagePath = `/${profilePath}/${publicUrl ?? url ?? talentId}/jobs`;
  const jobPath = `${jobPagePath}/${jobId}`;
  const jobUrl = `${window.location.origin}/app${jobPath}`;
  const isJobPage = jobPagePath === pathname;
  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;

  const isUnpublished = status === 'unpublished';
  const isHidden = status === 'hidden';
  const isClosed = status === 'closed';
  const isPublished = status === 'published';
  const isDraft = status === 'draft';

  const hiredContract = !isClosed && contractStatus && hiredContractStatus.includes(contractStatus);
  const contactedJob = !isClosed && contacted && !selectedContract && !selectedNoContract;
  const appliedJob = !isClosed && appliedStatus && appliedStatus === 'in-progress' && !contacted;
  const applyOption = !isClosed && !isMine && !appliedStatus && !hideApply && isAuthed;

  const dispatch = useDispatch();

  const handleApply = e => {
    e.stopPropagation();
    if (profileJobType === 'demand') {
      dispatch(portalActions.openPortal({ name: 'supply-post-job-modal', data: { talentId } }));
    }

    applyProfileToJob(jobId);
  };

  const handleReview = e => {
    e.stopPropagation();
    const url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${contractId}/review`;
    window.location.href = url;
  };

  const handleSave = e => {
    e.stopPropagation();
    if (profileJobType === 'demand') {
      dispatch(portalActions.openPortal({ name: 'demand-apply-job-modal', data: { talentId } }));
    }
    if (!isApplied && profileId) {
      saveJob(profileId, jobId, isFavored, () => {
        setIsFavored(!isFavored);
      });
    }
  };

  const handleClick = () => {
    let path = '';

    if (isMine && isJobPage) {
      path = '/description';
    }
    if (isJobIPostedPage) {
      path = '/candidates';
    }

    const urlToOpen = `${jobUrl}${path}`;
    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
  };

  const handleClickMessage = useCallback(
    e => {
      e.stopPropagation();
      redirectToStartConversation(creatorProfile);
    },
    [creatorProfile]
  );

  const profileJobType = useSelector(profileSelectors.selectMyProfileProperty('isSupply'));
  const showBookmarkButton = ((isJobPage && !hasPermission) || !showDropdown) && profileJobType !== 'demand';

  const bookmarkTooltip = isApplied ? "Can't be saved" : isFavored ? 'Remove from saved' : 'Save';
  const dateContract = contractDate && parseISO(contractDate);
  const startDateContract = startDate && parseISO(startDate);

  return (
    <StyledJobCard onClick={handleClick} isListView={isListView}>
      <JobImageWrapper className="job-image-wrapper">
        <JobImage className="job-image" src={thumbnail?.url ?? thumbnail ?? coverImage?.url ?? NA} />
        <JobImageGradient className="job-company-grad" />
        {!isJobPage && (
          <JobCompanyContainer className="job-company-info">
            <JobCompanyImage className="job-company-img" src={imageUri?.url ?? img ?? defaultAvatar} />
            {!isListView && <JobCompanyName>{name}</JobCompanyName>}
          </JobCompanyContainer>
        )}
        <JobButtonWrapper>
          {dragIcon}
          {(isUnpublished || isHidden || isDraft) && (
            <Flex padding="8px 10px" backgroundColor="#fff" borderRadius={8} center>
              <Typography variant="caption" color="#7266EE">
                {isUnpublished && 'UNPUBLISHED'}
                {isHidden && 'HIDDEN'}
                {isDraft && 'DRAFT'}
              </Typography>
            </Flex>
          )}
          {showBookmarkButton && (
            <BookmarkButton
              className={(isApplied || !profileId) && 'disabled'}
              tooltipId={`job-tooltip-${jobId}`}
              tooltip={bookmarkTooltip}
              isSaved={isFavored && !isApplied}
              onClick={handleSave}
              disabled={isMember}
            />
          )}
          {(showDropdown || isJobPage) && (
            <JobCardDropDown
              clipboard={jobUrl}
              jobPath={jobPath}
              isApplied={appliedStatus === 'in-progress'}
              isSaved={isSaved}
              isClosed={isClosed}
              isPublished={isPublished}
              isMine={isMine}
              profileId={profileId}
              jobId={jobId}
              jobStatus={jobStatus}
              setStatus={setJobStatus}
              isJobIPostedPage={isJobIPostedPage}
              isJobPage={isJobPage}
              contacted={contacted}
              creatorProfile={creatorProfile}
              isContractActive={isContractActive}
            />
          )}
        </JobButtonWrapper>
      </JobImageWrapper>

      <JobInfoWrapper className="job-info">
        <JobTitle className="job-title">
          {title} {code && `(${code})`}
        </JobTitle>
        <Flex row mb={'20px'}>
          <>
            <AppliedInfo>Posted {moment(createdAt).fromNow()}</AppliedInfo>
          </>
          {location && (
            <>
              <Styled.StyledIcon
                icon="separator-dot"
                color="rgba(230, 234, 255, 0.6)"
                marginLeft={'8px'}
                marginRight={'8px'}
              />
              <div>
                <Styled.StyledIcon icon="location" color="rgba(230, 234, 255, 0.6)" marginRight={'8px'} />
                <Styled.LocationContainer color="rgba(230, 234, 255, 0.6)">{location}</Styled.LocationContainer>
              </div>
            </>
          )}
        </Flex>
        <JobTagListWrapper>
          <JobTagList>
            {Array.isArray(jobDisciplines) &&
              jobDisciplines?.length > 0 &&
              jobDisciplines.map((item, key) => {
                const { color, icon } = getDisciplinesNameIconAndColor(item.name);
                return (
                  <Tag key={key} icon={icon} backgroundColor={color} gap="8" color="rgba(242, 244, 255, 0.9)">
                    {item.name}
                  </Tag>
                );
              })}
            {jobType && (
              <Tag icon={jobType === 'internship' && 'internship'}>
                {jobType[0].toUpperCase() + jobType.substring(1)}
              </Tag>
            )}
            {job?.type && (
              <Tag icon={job.type === 'internship' && 'internship'}>
                {job.type[0].toUpperCase() + job.type.substring(1)}
              </Tag>
            )}
            {(workplaceType === 'remote' || job?.preference?.remote) && <Tag>Remote friendly</Tag>}
          </JobTagList>
          <JobTagList>
            {(isXS || isListView) && (
              <>
                <Tag icon="company" gap={8}>
                  {name?.length > 14 ? (
                    <>
                      <Typography data-tip data-for={`jobCreatorName-${jobId}`}>
                        {name.substr(0, 9)}...
                      </Typography>
                      <StyledTooltip tooltipId={`jobCreatorName-${jobId}`} place="top">
                        {name}
                      </StyledTooltip>
                    </>
                  ) : (
                    <Typography>{name}</Typography>
                  )}
                </Tag>
              </>
            )}
          </JobTagList>
        </JobTagListWrapper>
        {isClosed && (
          <JobStatus className="job-status">
            <JobStatusTopology variant="medium">
              <Icon mr={8} icon="ignore" color="#FF5151" hoverColor="#FF5151" />
              Job is no longer available
            </JobStatusTopology>
          </JobStatus>
        )}
        {appliedJob && (
          <JobStatus className="job-status">
            <JobStatusTopology variant="medium" color="rgba(230, 234, 255, 0.35)">
              <Icon mr={8} icon="vetting-i" color="rgba(230, 234, 255, 0.35)" hoverColor="rgba(230, 234, 255, 0.35)" />
              Applied {moment(appliedAt).fromNow()}
            </JobStatusTopology>
          </JobStatus>
        )}
        {contactedJob && (
          <JobStatus className="job-status">
            <JobStatusTopology variant="medium" color="rgba(255, 255, 255, 1)">
              <Icon
                mr={8}
                icon="checkmark-circle"
                color="rgba(00, 255, 00, 1)"
                hoverColor="rgba(230, 234, 255, 0.35)"
              />
              Contacted
            </JobStatusTopology>
            <StyledButton
              icon="message"
              padding="8px 10px"
              height="auto"
              onClick={handleClickMessage}
              data-testid="message-button"
            >
              Message
            </StyledButton>
          </JobStatus>
        )}

        {hiredContract && (
          <JobStatus className="job-status">
            <JobStatusTopology variant="medium" color="rgba(255, 255, 255, 1)">
              <Icon
                mr={8}
                icon={ContractStatusIcon[contractStatus]}
                color={ContractStatusIconColor[contractStatus]}
                marginLeft={'10px'}
                hoverColor="rgba(230, 234, 255, 0.35)"
              />
              {`${ContractStatusOptions[contractStatus]}${
                contractStatus === 'active' ? ' ' + moment(startDateContract).format('MMM DD') : ''
              }`}
            </JobStatusTopology>
            {contractStatus === 'active' && <SeeMoreButton onClick={handleReview}>See More</SeeMoreButton>}
          </JobStatus>
        )}

        {!isClosed && selectedContract && (
          <>
            <ReviewBox>
              <ReviewBoxText>
                <ReviewBoxDateArea>{dateContract && format(dateContract, 'MMM dd')}</ReviewBoxDateArea>
                <ReviewBoxTextArea>
                  🎉 Congratulations! You were selected for this role. To proceed, review the contract.
                </ReviewBoxTextArea>
              </ReviewBoxText>
              <ReviewBoxFooter>
                <ReviewButtonArea>
                  <StyledButton ml={6} icon="info" onClick={handleReview} tooltipId="tooltip-for-apply-button">
                    Review Proposal
                  </StyledButton>
                </ReviewButtonArea>
              </ReviewBoxFooter>
            </ReviewBox>
          </>
        )}
        {!isClosed && selectedNoContract && (
          <JobStatus className="job-status">
            <JobStatusTopology variant="medium" color="rgba(255, 255, 255, 1)">
              <Icon
                mr={8}
                icon={ContractStatusIcon[contractStatus]}
                color={ContractStatusIconColor[contractStatus]}
                marginLeft={'10px'}
                hoverColor="rgba(230, 234, 255, 0.35)"
              />
              {ContractStatusOptions[contractStatus]}
            </JobStatusTopology>
            <SeeMoreButton onClick={handleReview}>See More</SeeMoreButton>
          </JobStatus>
        )}
        {applyOption && (
          <JobStatus className="job-status" justifyEnd>
            {loadingApply && <Spinner />}
            <StyledButton ml={6} icon="info" onClick={handleApply} tooltipId="tooltip-for-apply-button">
              Apply
            </StyledButton>
          </JobStatus>
        )}
        {!isClosed && !!isMine && isJobIPostedPage && (
          <>
            {staffedStatus === 'staffed' ? (
              <JobStatus className="job-status stuffed" mt={18} pt={24}>
                <JobStatusTopology color="rgba(230, 234, 255, 0.35)">Job staffed with</JobStatusTopology>
                <JobTagList>
                  {contractedSupply.map(contracted => (
                    <Tag
                      img={contracted?.profile?.img ?? contracted?.profile?.basicInfo?.imageUri?.url ?? defaultAvatar}
                      key={contracted?.profile?.id}
                    >
                      {contracted?.profile?.name}
                    </Tag>
                  ))}
                </JobTagList>
              </JobStatus>
            ) : (
              <JobStatus className="job-status applicants" mt={18} pt={24}>
                <JobStatusTopology color="rgba(230, 234, 255, 0.35)">
                  {job?.appliedBy?.length === 0
                    ? `no candidates`
                    : job?.appliedBy?.length === 1
                    ? `1 candidate`
                    : `${job?.appliedBy?.length} candidates`}
                </JobStatusTopology>
                {job?.newAppliedBy?.length > 0 && (
                  <JobStatusTopology
                    backgroundColor="#FF5151"
                    borderRadius="10px"
                    fontSize={'13px'}
                    lineHeight={'16px'}
                  >
                    {job?.newAppliedBy?.length}
                  </JobStatusTopology>
                )}
              </JobStatus>
            )}
          </>
        )}
      </JobInfoWrapper>
    </StyledJobCard>
  );
};

export default JobCard;
