import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import size from 'lodash/fp/size';
import { profileActions, profileSelectors } from 'store/profile';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import AddFirstMediaCoverage from './AddFirstMediaCoverage';
import TalentMediaCoverageItem from './TalentMediaCoverageItem';
import AddMediaCoverage from './AddMediaCoverage';
import MediaCoverageModal from './MediaCoverageModal';
import MediaCoverageDeleteModal from './MediaCoverageDeleteModal';
import ClaimedMediaCoverageModal from './ClaimedMediaCoverageModal';
import Icon from '../../../common/Icon';
import talentService from '../../../api/talentService';
import { pageSize } from '../../../utils/devices';
import companyService from '../../../api/companyService';

const DragIcon = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 1;
  right: 52px;
  top: 12px;
`;
const Wrapper = styled.div`
  &:hover .drag-icon-${props => props.id} {
    display: block;
  }
  @media ${pageSize.XS} {
    .drag-icon-${props => props.id} {
      display: block;
    }
  }
`;

const TalentMediaCoverage = () => {
  const mediaCoverage = useSelector(profileSelectors.selectProfileProperty('mediaCoverage'));
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const talentId = useSelector(profileSelectors.selectProfileProperty('id'));
  const [coverageList, setCoverageList] = useState([]);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const dispatch = useDispatch();

  useEffect(() => {
    setCoverageList([...Object.values(mediaCoverage)]);
  }, [mediaCoverage]);

  function handleOnDragEnd(result) {
    const items = Array.from(coverageList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCoverageList(items);

    const sortedIdList = items.map(item => item._id);

    const serviceType = isCompanyView ? companyService : talentService;
    const id = isCompanyView ? myProfileId : talentId;

    serviceType
      .updateMediaCoverageSort(id, sortedIdList)
      .then(response => {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'mediaCoverage',
            value: response.data.data,
            profileId: id
          })
        );
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }

  return (
    <>
      {!size(mediaCoverage) && hasEditPermission ? (
        <AddFirstMediaCoverage />
      ) : (
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {coverageList.map(
                    (tag, index) =>
                      tag && (
                        <Wrapper id={tag._id} key={index}>
                          <Draggable
                            key={tag._id}
                            draggableId={tag._id}
                            index={index}
                            isDragDisabled={!hasEditPermission && true}
                          >
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  {hasEditPermission && coverageList.length > 1 && (
                                    <DragIcon {...provided.dragHandleProps} className={`drag-icon-${tag._id}`}>
                                      <Icon icon="draggable_touch" size={32} noFill />
                                    </DragIcon>
                                  )}
                                  <TalentMediaCoverageItem key={tag} mediaCoverage={tag} />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </Wrapper>
                      )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {hasEditPermission && <AddMediaCoverage />}
        </>
      )}
      <MediaCoverageModal />
      <MediaCoverageDeleteModal />
      <ClaimedMediaCoverageModal />
    </>
  );
};

export default TalentMediaCoverage;
