import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled.components';
import Icon from 'common/Icon';
import Tooltip from 'components/Tooltip';

const BadgeIcon = ({iconName, iconColor, iconSize, tooltip, tooltipId}) => {
  return (
    <Container data-tip data-for={tooltipId}>
      <Icon icon={iconName} color={iconColor} size={iconSize} noFill />
      {tooltip && <Tooltip className="secondary" tooltipId={tooltipId} overrideTooltipPosition isClickable delayHide={100}>
        {tooltip}
      </Tooltip>}
    </Container>
  );
};

BadgeIcon.propTypes = {
    iconName: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    tooltip: PropTypes.any,
    tooltipId: PropTypes.string,
};

BadgeIcon.defaultProps = {
    iconColor: '#D1D7FF66',
    iconSize: 24,
    tooltip: null,
    tooltipId: null,
};

export default BadgeIcon;
