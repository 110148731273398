import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';

export const StyledLifePageBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  margin-bottom: 32px;
  width: fit-content;
  height: 40px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  &.lifePageBar {
    display: none;
  }
  @media ${pageSize.XS} {
    width: 100%;
  }
`;
export const StyledLifeBarMoreButton = styled(ContainedButton)`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  height: 32px;
  border-radius: 4px;
  background-color: transparent;
`;
