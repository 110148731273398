export const jobTypeOptions = [
  { value: 'employee', label: 'Employee', new: true },
  { value: 'contractor', label: 'Contractor' },
  { value: 'internship', label: 'Internship' }
];

export const workplaceTypeOptions = [
  { value: 'onsite', label: 'On-site', new: true },
  { value: 'remote', label: 'Remote' },
  { value: 'hybrid', label: 'Hybrid' }
];

export const reqAvailabilityOptions = [
  { value: 'full time', label: 'Full-time (30+ hours/week)' },
  { value: 'part time', label: 'Part-time (10-30 hours/week)' },
  { value: 'small project', label: 'Small project (<10 hours/week)' }
];

export const experienceLevelOptions = [
  { value: 'all', label: 'Open for all' },
  { value: 'intern', label: 'Student/Intern' },
  { value: '0-2', label: 'Entry level (0-2 years)' },
  { value: '3-4', label: '3-4 years' },
  { value: '5-9', label: '5-9 years' },
  { value: '10-0', label: '10+ years' },
  { value: '15-0', label: '15+ years' },
  { value: '20-0', label: '20+ years' }
];

export const pronounOptions = [
  { value: 'she', label: 'She/Her' },
  { value: 'he', label: 'He/Him' },
  { value: 'they', label: 'They/Them' },
  { value: 'en', label: 'En/En' },
  { value: 'xe', label: 'Xe/Xem/Xyr' },
  { value: 'other', label: 'Other' }
];

export const experienceSearchOptions = [
  { value: '0-0', label: 'Intern' },
  { value: '1-0', label: 'Open for all' },
  { value: '0-2', label: '0–2 years' },
  { value: '3-4', label: '3-4 years' },
  { value: '5-9', label: '5-9 years' },
  { value: '10-0', label: '10+ years' },
  { value: '15-0', label: '15+ years' },
  { value: '20-0', label: '20+ years' }
];

export const experienceLevelUserOptions = [
  { value: 'intern', label: 'Student/Intern' },
  { value: '0-2', label: 'Entry level (0-2 years)' },
  { value: '3-4', label: '3-4 years' },
  { value: '5-9', label: '5-9 years' },
  { value: '10-0', label: '10+ years' },
  { value: '15-0', label: '15+ years' },
  { value: '20-0', label: '20+ years' }
];

export const compensationOptions = [
  { value: 'monthly', label: 'per month' },
  { value: 'yearly', label: 'per year' }
];

export const compensationOptionsContractor = [
  { value: 'hourly', label: 'per hour' },
  { value: 'weekly', label: 'per week' }
];

export const compensationRangeOptions = {
  hourly: [
    { value: '0-40', label: 'Up to $40' },
    { value: '40-60', label: '$40-$60' },
    { value: '60-80', label: '$60-$80' },
    { value: '80-100', label: '$80-$100' },
    { value: '100-130', label: '$100-$130' },
    { value: '130-160', label: '$130-$160' },
    { value: '160-200', label: '$160-$200' },
    { value: '200-0', label: '$200+' }
  ],
  monthly: [
    { value: '0-5', label: 'Up to $5k' },
    { value: '5-7', label: '$5K-$7K' },
    { value: '7-9', label: '$7K-$9K' },
    { value: '9-11', label: '$9K-$11K' },
    { value: '11-12', label: '$11K-$12K' },
    { value: '12-14', label: '$12K-$14K' },
    { value: '14-16', label: '$14K-$16K' },
    { value: '16-0', label: '$16K+' }
  ],
  yearly: [
    { value: '0-60', label: 'Up to $60K' },
    { value: '60-80', label: '$60K-$80K' },
    { value: '80-100', label: '$80K-$100K' },
    { value: '100-120', label: '$100K-$120K' },
    { value: '120-140', label: '$120K-$140K' },
    { value: '140-160', label: '$140-$160K' },
    { value: '160-200', label: '$160-$200K' },
    { value: '200-0', label: '$200K+' }
  ]
};

export const teamRoleOptions = [
  { value: 'individual', label: 'Individual contributor ' },
  { value: 'manager', label: 'Team manager' },
  { value: 'multiManager', label: 'Multiple team manager' }
];
