import * as React from 'react';

const FirstContractIcon = ({ active, ...svgProps }) =>
  active ? (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M15.0302 1.20463C15.6085 0.784472 16.3915 0.784472 16.9698 1.20463L30.7226 11.1966C31.3009 11.6167 31.5429 12.3615 31.322 13.0413L26.0689 29.2087C25.848 29.8885 25.2145 30.3488 24.4997 30.3488H7.50034C6.78551 30.3488 6.15199 29.8885 5.9311 29.2087L0.678016 13.0413C0.457123 12.3615 0.699109 11.6167 1.27741 11.1966L15.0302 1.20463Z"
        fill="url(#paint0_radial_92_5035)"
      />
      <g filter="url(#filter0_d_92_5035)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 11.5C11 9.84315 12.3431 8.5 14 8.5H18C19.6569 8.5 21 9.84315 21 11.5V12.5H22C23.1046 12.5 24 13.3954 24 14.5V18.5H8V14.5C8 13.3954 8.89543 12.5 10 12.5H11V11.5ZM19 11.5V12.5H13V11.5C13 10.9477 13.4477 10.5 14 10.5H18C18.5523 10.5 19 10.9477 19 11.5Z"
          fill="white"
        />
        <path
          d="M8 20.5V22.5C8 23.6046 8.89543 24.5 10 24.5H22C23.1046 24.5 24 23.6046 24 22.5V20.5H19C18.4477 20.5 18 20.9477 18 21.5C18 22.0523 17.5523 22.5 17 22.5H15C14.4477 22.5 14 22.0523 14 21.5C14 20.9477 13.5523 20.5 13 20.5H8Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_92_5035"
          x="6"
          y="7.5"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.148568 0 0 0 0 0.0247613 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_92_5035" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_92_5035" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_92_5035"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-0.499997 33.5) rotate(-90) scale(33 49.5)"
        >
          <stop stopColor="#5448DB" />
          <stop offset="1" stopColor="#8A80FF" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M15.0302 1.20463C15.6085 0.784472 16.3915 0.784472 16.9698 1.20463L30.7226 11.1966C31.3009 11.6167 31.5429 12.3615 31.322 13.0413L26.0689 29.2087C25.848 29.8885 25.2145 30.3488 24.4997 30.3488H7.50034C6.78551 30.3488 6.15199 29.8885 5.9311 29.2087L0.678016 13.0413C0.457123 12.3615 0.699109 11.6167 1.27741 11.1966L15.0302 1.20463Z"
        fill="#C7C7FF"
        fillOpacity="0.21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11.5C11 9.84315 12.3431 8.5 14 8.5H18C19.6569 8.5 21 9.84315 21 11.5V12.5H22C23.1046 12.5 24 13.3954 24 14.5V18.5H8V14.5C8 13.3954 8.89543 12.5 10 12.5H11V11.5ZM19 11.5V12.5H13V11.5C13 10.9477 13.4477 10.5 14 10.5H18C18.5523 10.5 19 10.9477 19 11.5Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        d="M8 20.5V22.5C8 23.6046 8.89543 24.5 10 24.5H22C23.1046 24.5 24 23.6046 24 22.5V20.5H19C18.4477 20.5 18 20.9477 18 21.5C18 22.0523 17.5523 22.5 17 22.5H15C14.4477 22.5 14 22.0523 14 21.5C14 20.9477 13.5523 20.5 13 20.5H8Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
    </svg>
  );

export default FirstContractIcon;
