import Frame from 'common/Frame';
import MoreButton from 'common/MoreButton';
import GeneralTags from 'pages/talent/AllAdditionalTags/GeneralTags';
import GenresTags from 'pages/talent/AllAdditionalTags/GenreTags';
import PlatformTags from 'pages/talent/AllAdditionalTags/PlatformTags';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { portalActions } from 'store/portals';
import sanitizeAndParseHTML from 'utils/sanitizeAndParseHTML';
import NA from '../../../images/NA.png';
import { ProjectContent, ProjectImage, ProjectOverview, ProjectTags, ProjectTitle } from './styled.components';

const removeEmptyTags = data => data.filter(it => it !== null);

const ProjectItem = ({
  _id,
  afterProjectDelete,
  afterProjectEdit,
  cover,
  coverImage,
  fromAllJobs = false,
  genres,
  hasEditPermission = false,
  hideTagsEditIcon = false,
  jobId,
  name,
  openScopeModal = false,
  overview,
  platforms,
  preview,
  project,
  setCollapsedItem,
  showCollapse = true,
  tags
}) => {
  const dispatch = useDispatch();
  const isEditable = fromAllJobs || hideTagsEditIcon ? false : hasEditPermission;

  const [detailsCollapsed, setDetailsCollapsed] = useState(!showCollapse);
  const openTagsModal = (type, key) => {
    setCollapsedItem({ _id, name, overview, platforms, genres, tags });
    dispatch(
      portalActions.openPortal({
        name: 'tags-modal',
        data: { type, key, title: '' }
      })
    );
  };

  const handleCollapseClick = e => {
    e.preventDefault();
    setDetailsCollapsed(!detailsCollapsed);
  };

  let imgUrl = coverImage ? coverImage.url : cover || NA;
  if (typeof coverImage === 'string') {
    imgUrl = coverImage;
  }

  if (coverImage?.size) {
    imgUrl = URL.createObjectURL(coverImage);
  }

  return (
    <Frame
      filled
      withMarginBottom
      canEdit={hasEditPermission}
      editMode="edit"
      opens="edit-project-modal"
      data={{
        projectId: _id,
        project,
        fromAllJobs,
        afterSave: afterProjectEdit,
        afterDelete: afterProjectDelete,
        openScopeModal,
        jobId
      }}
    >
      <ProjectTitle>{name}</ProjectTitle>
      <ProjectContent>
        <ProjectImage>
          <img src={preview ?? imgUrl} alt="" />
        </ProjectImage>
        <ProjectOverview detailsCollapsed={detailsCollapsed}>{sanitizeAndParseHTML(overview)}</ProjectOverview>
      </ProjectContent>
      {detailsCollapsed && (
        <ProjectTags visible={detailsCollapsed}>
          <PlatformTags
            project={_id}
            hasDragPermission={hasEditPermission}
            isEditMode={isEditable}
            cols={6}
            data={removeEmptyTags(platforms)}
            onAction={() => openTagsModal('platforms', 'platform')}
            hideEmpty
          />
          <GenresTags
            project={_id}
            hasDragPermission={hasEditPermission}
            isEditMode={isEditable}
            data={removeEmptyTags(genres)}
            onAction={() => openTagsModal('genres', 'genre')}
            hideEmpty
          />
          <GeneralTags
            project={_id}
            hasDragPermission={hasEditPermission}
            isEditMode={isEditable}
            data={removeEmptyTags(tags)}
            onAction={() => openTagsModal('tags', 'tag')}
            hideEmpty
          />
        </ProjectTags>
      )}
      {showCollapse && (
        <MoreButton onClick={handleCollapseClick}>{detailsCollapsed ? 'SEE LESS' : 'SEE MORE'}</MoreButton>
      )}
    </Frame>
  );
};

export default ProjectItem;
