import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { pageSize } from 'utils/devices';

import isNumber from 'lodash/isNumber';

import Frame from 'common/Frame';
import { portalActions } from 'store/portals';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';

import Asset from './Asset';
import Spinner from 'common/Spinner';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.small {
    flex-direction: row;
    gap: 16px;
  }
`;
const StyledMiniContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
`;
const StyledIcon = styled(Icon)`
  height: auto;
  min-height: auto;
  @media ${pageSize.XS} {
    display: none;
  }
  @media ${pageSize.S} {
    display: none;
  }
`;
const StyledDropText = styled(Typography)`
  @media ${pageSize.XS} {
    display: none;
  }
  @media ${pageSize.S} {
    display: none;
  }
`;
const StyledOrText = styled(Typography)`
  color: rgba(229, 234, 255, 0.35);
  margin: 16px 0;
  @media ${pageSize.XS} {
    display: none;
  }
  @media ${pageSize.S} {
    display: none;
  }
  line-height: 16px;
`;
const StyledButton = styled(ContainedButton)`
  @media ${pageSize.XS} {
    margin-top: 20px;
  }
  @media ${pageSize.S} {
    margin-top: 20px;
  }
`;
const StyledMiniButton = styled(ContainedButton)``;
const StyledTypography = styled(Typography)`
  max-width: 410px;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  &.text {
    color: rgba(230, 234, 255, 0.35);
  }
`;
const StyledDroppableContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;
const LoadingContainer = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.8);
`;

const FormDragAndDrop = ({
  className,
  project,
  lockUnlockStatus,
  hintText,
  iconSize,
  cloudIconMarginTop,
  cloudIconMarginBottom,
  onDrop,
  style,
  noDataText,
  title,
  required,
  titlePB,
  titleIcon,
  titleIconSize,
  backgroundColor,
  padding,
  instruction,
  onInstructionClick,
  orders,
  single,
  multi,
  files = [],
  reorderAssets,
  onRemove,
  rightIcon,
  extraIconText,
  extraIconName,
  onExtraIconClick,
  accept,
  sizeError,
  extraIconOpens,
  error,
  aspectRatio,
  requireCrop,
  loading,
  notClosePortal,
  setShowLightbox,
  setAssetIndex,
  openLightBoxWithZeroIndex = true,
  id
}) => {
  const dispatch = useDispatch();

  const handleDrop = (droppedFiles, _rejected, event) => {
    if (droppedFiles.length) {
      if (requireCrop && droppedFiles[0].type.includes('image')) {
        event.target.value = '';
        return dispatch(
          portalActions.openPortal({
            name: 'cropper-modal',
            data: {
              onCrop: (image, { overlay }) => onDrop([{ asset: image, overlay }]),
              initialImage: droppedFiles[0],
              aspectRatio
            }
          })
        );
      }

      return onDrop(droppedFiles.map(file => ({ asset: file })));
    }
  };

  const { getInputProps, getRootProps } = useDropzone({ onDrop: handleDrop, noClick: true, accept, multiple: false });

  const { ref, ...rest } = getInputProps();

  const onDragEnd = ({ destination, source }) => {
    const { index: newIndex } = destination;
    const { index: oldIndex } = source;

    reorderAssets({ oldIndex, newIndex });
  };

  const openLightbox = assetIndex => {
    setAssetIndex(assetIndex);
    setShowLightbox(true);
  };

  if (files[0] && single) {
    return (
      <Asset
        project={project}
        lockUnlockStatus={lockUnlockStatus}
        rightIcon={rightIcon}
        extraIconText={extraIconText}
        file={files[0]}
        id={files[0].id || files[0]._id}
        onExtraIconClick={onExtraIconClick}
        extraIconName={extraIconName}
        onRemove={onRemove}
        border
        single
        extraIconOpens={extraIconOpens}
        loading={loading}
        onClick={() => openLightbox(0)}
      />
    );
  }
  if (files.length && multi) {
    return (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="Assets">
            {({ droppableProps, innerRef, placeholder }) => (
              <StyledDroppableContainer {...droppableProps} ref={innerRef}>
                {orders
                  .map(fileId => files.find(file => (file._id || file.id) === fileId))
                  .map(
                    (file, index) =>
                      file && (
                        <Asset
                          project={project}
                          lockUnlockStatus={lockUnlockStatus}
                          rightIcon={rightIcon}
                          extraIconText={file.description ? 'EDIT DESCRIPTION' : 'ADD DESCRIPTION'}
                          orderIndex={index}
                          file={file}
                          id={file.id || file._id}
                          key={file.id || file._id}
                          onExtraIconClick={onExtraIconClick}
                          extraIconName={file.description ? 'edit' : 'add'}
                          extraIconOpens={extraIconOpens}
                          onRemove={onRemove}
                          loading={loading === file.id}
                          notClosePortal={notClosePortal}
                          onClick={() => openLightbox(openLightBoxWithZeroIndex ? index : index + 1)}
                        />
                      )
                  )}

                {placeholder}
              </StyledDroppableContainer>
            )}
          </Droppable>
        </DragDropContext>

        {(project ? lockUnlockStatus : true) && (
          <Frame
            noDataText={noDataText}
            canEdit
            noIcon
            outline="none"
            backgroundColor={backgroundColor}
            padding={padding}
            instruction={instruction}
            onInstructionClick={onInstructionClick}
            error={error}
            {...getRootProps()}
            id={id}
            className={className}
          >
            <StyledMiniContainer>
              {loading && (
                <LoadingContainer>
                  <Spinner />
                  Please wait...
                </LoadingContainer>
              )}
              <StyledIcon size={32} icon="upload-alt" />
              <StyledDropText variant="medium">Drag and drop files here</StyledDropText>
              <StyledOrText color="rgba(230, 234, 255, 0.35)" variant="caption">
                OR
              </StyledOrText>
              <input
                type="file"
                {...rest}
                id="upload"
                ref={ref}
                style={{ display: 'none' }}
                accept={accept}
                multiple={false}
              />
              <StyledMiniButton
                type="button"
                backgroundColor="rgba(204, 213, 255, 0.11)"
                forceDisplayText
                icon="search"
                onClick={() => ref.current.click()}
              >
                Browse Files
              </StyledMiniButton>
            </StyledMiniContainer>

            <StyledTypography color="rgba(230, 234, 255, 0.35)" error={sizeError} variant="small">
              Images 5Mb or less. Video files, Audio files, and PDFs. 50Mb or less
            </StyledTypography>
          </Frame>
        )}
      </>
    );
  }

  return (
    <>
      {(project ? lockUnlockStatus : true) && (
        <Frame
          noDataText={noDataText}
          canEdit
          editMode="add"
          style={style}
          noIcon
          text={title}
          required={required}
          titlePB={titlePB}
          titleIcon={titleIcon}
          titleIconSize={titleIconSize}
          backgroundColor={backgroundColor}
          padding={padding}
          instruction={instruction}
          onInstructionClick={onInstructionClick}
          outline="none"
          error={error}
          {...getRootProps()}
        >
          <StyledContainer className={className}>
            <StyledIcon
              mt={isNumber(cloudIconMarginTop) ? cloudIconMarginTop : 40}
              mb={isNumber(cloudIconMarginBottom) ? cloudIconMarginBottom : 9}
              size={iconSize ?? 80}
              icon="upload-alt"
            />
            <StyledDropText variant="medium">Drag and drop files here</StyledDropText>
            <StyledOrText mt={20} mb={20} variant="caption">
              OR
            </StyledOrText>
            <input
              type="file"
              id="upload"
              style={{ display: 'none' }}
              {...rest}
              ref={ref}
              accept={accept}
              multiple={false}
            />
            <StyledButton
              type="button"
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              onClick={() => ref.current.click()}
            >
              Browse Files
            </StyledButton>
          </StyledContainer>
          {hintText && (
            <StyledTypography variant="small" className="text">
              {hintText}
            </StyledTypography>
          )}
        </Frame>
      )}
    </>
  );
};

export default FormDragAndDrop;
