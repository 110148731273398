import styled from '@emotion/styled';
import Flex from 'common/styles/Flex';
import React from 'react';

const ActivityStatusWrapper = styled.div``;

const StatusIcon = styled.div`
  display: inline-flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: white;
  ${props => (props.color ? `background-color: ${props.color};` : '')}
`;
const StatusText = styled.span`
  text-transform: capitalize;
`;

const getStatusColor = status => {
  switch (status) {
    case 'active':
      return '#2BFF26';
    case 'created':
      return '#ffe040';
    case 'suspended':
    case 'deleted':
      return '#FF5151';
    default:
      return 'white';
  }
};

const ActivityStatus = ({ status }) => (
  <ActivityStatusWrapper>
    <Flex className="nowrap" alignCenter>
      <StatusIcon color={getStatusColor(status)} />
      <StatusText>{status}</StatusText>
    </Flex>
  </ActivityStatusWrapper>
);

export default ActivityStatus;
