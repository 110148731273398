import * as tagActionTypes from './tagActionTypes';

const initialState = {
  platforms: {
    data: [],
    loading: false,
    isLoaded: false
  },
  genres: {
    data: [],
    loading: false,
    isLoaded: false
  },
  skills: {
    data: [],
    loading: false,
    isLoaded: false
  },
  tools: {
    data: [],
    loading: false,
    isLoaded: false
  },
  tags: {
    data: [],
    loading: false,
    isLoaded: false
  },
  disciplines: {
    data: [],
    loading: false,
    isLoaded: false
  },
  roles: {
    data: [],
    loading: false,
    isLoaded: false
  },
  companyTypes: {
    data: [],
    loading: false,
    isLoaded: false
  },
  keywords: {
    data: [],
    loading: false,
    isLoaded: false
  }
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case tagActionTypes.INITIALIZE_TAGS: {
      const { tags, type } = action.payload;
      return {
        ...state,
        [type]: {
          data: tags,
          loading: false
        }
      };
    }

    case tagActionTypes.DELETE_TAG: {
      const { tagId, type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          data: state[type].data.filter(tag => tag._id !== tagId)
        }
      };
    }

    case tagActionTypes.ADD_TAG: {
      const { tag, type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          data: [tag, ...state[type].data]
        }
      };
    }

    case tagActionTypes.UPDATE_TAG: {
      const { _id, type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          data: state.data.map(tag => (tag._id === _id ? { ...tag, ...action.payload } : tag))
        }
      };
    }

    case tagActionTypes.SET_TAGS_LOADED: {
      const { type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          loading: false,
          isLoaded: true
        }
      };
    }

    case tagActionTypes.SET_TAGS_LOADING: {
      const { type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          loading: true
        }
      };
    }

    case tagActionTypes.CLEAR_TAGS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default tagsReducer;
