import React from 'react';
import useSavedJobs from 'hooks/useSavedJobs';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'common/Loader';
import CompleteProfileToApplyModal from 'common/CompleteProfileToApplyModal';
import ApplyAsSoloistModal from 'common/ApplyAsSoloistModal';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import JobList from '../commons/JobList';
import { mapJobs } from '../helpers/mapJobs';
import { isEmpty } from 'ramda';
import JobsEmptyList from '../commons/JobsEmptyList';

const SavedJobs = () => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const { getSavedJobs, hasMoreSavedJobs, loadingSavedJobs, savedJobs } = useSavedJobs();

  const jobs = mapJobs(savedJobs, profileId, true);

  if (isEmpty(jobs) && loadingSavedJobs) {
    return <Loader />;
  }

  return (
    <>
      <CompleteProfileToApplyModal />
      <ApplyAsSoloistModal />
      <InfiniteScroll
        loadMore={() => getSavedJobs(profileId, jobs.length)}
        hasMore={hasMoreSavedJobs}
        loader={<Loader key={0} style={{ marginTop: '43px' }} />}
        useWindow
      >
        {isEmpty(jobs) ? (
          <JobsEmptyList iconName="saved" text="Your saved jobs" />
        ) : (
          <JobList jobs={jobs} showDropdown />
        )}
      </InfiniteScroll>
    </>
  );
};

export default SavedJobs;
