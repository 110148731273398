import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import RadiobuttonIcon from './RadiobuttonIcon';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  ${props => (props.margin ? `margin: ${props.margin}` : '')}
`;

const Label = styled.label`
  ${commonStyles.ui_heading_3}
  color: rgba(242, 244, 255, 0.9);
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const RadiobuttonIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  align-self: flex-start;
`;

const Text = styled.div`
  ${commonStyles.ui_text_small}
  font-size: 16px;
  line-height: 22px;
  ${props => (props.color ? props.color : '')}
  color: rgba(242, 244, 255, 0.9);
  &.disabled {
    color: rgba(230, 234, 255, 0.35);
  }
  ${({ color }) => color && `color: ${color};`}
  ${({ disabled }) => disabled && 'color: rgb(84, 86, 91);'}
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledError = styled.div`
  ${commonStyles.ui_text_small}
  color: #FF5151;
`;

const FormRadiobutton = ({
  circleColor,
  disabled,
  error,
  label,
  labelDisabled,
  margin,
  name,
  onChange,
  selected,
  text,
  textColor,
  value,
  ...rest
}) => {
  const defaultCircle = value === selected ? 'white' : 'rgba(204, 213, 255, 0.11)';

  return (
    <InputWrapper margin={margin}>
      <Label>{label}</Label>
      <StyledLabel>
        <input
          type="radio"
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          checked={value === selected}
          {...rest}
        />
        <RadiobuttonIconWrapper>
          <RadiobuttonIcon color={circleColor || defaultCircle} checked={value === selected} disabled={disabled} />
        </RadiobuttonIconWrapper>
        <Text disabled={disabled} color={textColor} className={`${labelDisabled ? 'disabled' : ''}`}>
          {text}
        </Text>
      </StyledLabel>
      {error && (
        <FlexRow>
          <StyledError>{error}&nbsp;</StyledError>
        </FlexRow>
      )}
    </InputWrapper>
  );
};

export default FormRadiobutton;
