import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';

export const Description = styled.div`
  margin-bottom: 24px;
`;
export const StyledContainedButton = styled(ContainedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ProjectTitle = styled.h2`
  font-size: 19px;
  margin-bottom: 24px;
  @media ${pageSize.XS} {
    width: calc(100% - 43px);
  }
`;
export const ProjectContent = styled.div`
  display: flex;
  gap: 24px;
  @media ${pageSize.XS} {
    flex-direction: column;
  }
`;
export const ProjectImage = styled.div`
  width: 268px;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
  @media ${pageSize.L}, ${pageSize.XL} {
    flex: 1;
  }
  @media ${pageSize.XS} {
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
export const ProjectOverview = styled.div`
  color: rgba(230, 234, 255, 0.6);
  line-height: 22px;
  flex: 1;
  max-width: 468px;
  word-wrap: break-word;
  white-space: pre-wrap;
  @media ${pageSize.L}, ${pageSize.XL} {
    flex: 2;
  }
  @media ${pageSize.XS} {
    display: ${props => (props.detailsCollapsed ? 'inline-block' : 'none')};
  }
`;
export const ProjectTags = styled.div`
  margin-top: 40px;
  > div:not(:last-child) {
    margin-bottom: 24px;
  }
  @media ${pageSize.XS} {
    margin-top: 24px;
  }
  ${props => (props.visible ? 'display: block' : 'display: none')}
`;
export const CollapseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(204, 213, 255, 0.11);
  color: white;
  font-size: 14px;
  padding: 8px;
  margin-top: 24px;
`;
