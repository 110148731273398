import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import EditButton from 'common/EditButton';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import Tooltip from 'components/Tooltip/Tooltip';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

export const StyledTooltip = styled(Tooltip)`
  .__react_component_tooltip.show {
    border: 1px solid rgba(204, 213, 255, 0.22) !important;
  }
`;
export const Container = styled.div`
  width: 324px;
  @media ${pageSize.S}, ${pageSize.XS} {
    width: 100%;
    flex: 1;
  }
`;
export const Wrapper = styled.div`
  background-color: cyan;
  box-sizing: border-box;
  padding: 24px;
  background: rgba(17, 17, 18, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.09);
  backdrop-filter: blur(100px);
  border-radius: 16px;
  ${props => props.disabled && 'opacity: 0.3;cursor: auto;'}
`;
export const Spacer = styled.div`
  height: 0px;
  margin: 16px 0;
  border: 1px solid rgba(204, 213, 255, 0.11);
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;

  @media ${pageSize.S} {
    flex-direction: row;
  }
`;
export const Avatar = styled.img`
  width: 96px;
  min-width: 96px;
  height: 96px;
  border-radius: 50%;
  background: rgba(204, 213, 255, 0.11);

  &:hover {
    cursor: pointer;
  }
`;
export const TitleTexts = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin-top: 16px;

  @media ${pageSize.S}, ${pageSize.XS} {
    padding-left: 24px;
  }

  @media ${pageSize.XS} {
    padding-left: 0px;
  }
`;
export const TitleName = styled.div`
  ${commonStyles.ui_heading_1}
  font-size: ${p => (p.len > 13 ? (p.len > 70 ? '14px' : '22px') : '36px')};
  line-height: ${p => (p.len > 40 ? (p.len > 60 ? (p.len > 100 ? '25px' : '30px') : '35px') : '40px')};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: ${p => (p.len > 100 ? 'break-all' : 'break-word')};

  @media ${pageSize.XS} {
    font-size: 14px;
    line-height: 20px;
  }
  @media ${pageSize.S} {
    font-size: 18px;
    line-height: 24px;
  }
  @media ${pageSize.M} {
    font-size: 22px;
    line-height: 30px;
  }
  @media ${pageSize.L} {
    font-size: 28px;
    line-height: 36px;
  }
`;
export const TitleDesc = styled.div`
  ${commonStyles.caption}
  color: #00FF00;
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  ${props => props.isCompanyView && ' margin-bottom: 0px;'};
`;
export const LocationAndLanguagesSection = styled.div`
  display: grid;
  color: rgba(242, 244, 255, 0.9);

  @media ${pageSize.S} {
    align-items: center;
    ${props => props.isCompanyView && 'grid-template-columns: 1fr 1fr 1fr'};
  }
`;

export const IconWrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  @media ${pageSize.S} {
    ${props => !props.isCompanyView && 'display: flex'};
  }
`;

export const CompanyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${pageSize.S} {
    flex-direction: row;
  }
`;

export const CompanyDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${pageSize.S} {
    ${props =>
      (props.languages || props.location) &&
      `
      border-left: 2px solid rgba(204, 213, 255, 0.11);
      padding-left: 16px;
    `}
  }
`;

export const IconWrapperRev = styled.div`
  display: grid;
  grid-row-gap: 8px;
  @media ${pageSize.S} {
    display: flex;
    flex-direction: column-reverse;
    ${props =>
      props.location &&
      `
      border-left: 2px solid rgba(204, 213, 255, 0.11);
      padding-left: 16px;
    `}
  }
`;
export const Languages = styled.span`
  @media ${pageSize.S} {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    word-wrap: break-word;
  }
`;
export const StyledEditButton = styled(EditButton)`
  margin-left: auto;

  @media ${pageSize.M}, ${pageSize.L} {
    display: none;
  }
`;
export const EditButtonWide = styled(EditButton)`
  margin-left: auto;

  @media ${pageSize.S}, ${pageSize.XS} {
    display: none;
  }
`;
export const StyledIcon = styled(Icon)`
  margin-left: ${({ marginLeft }) => marginLeft ?? '0px'};
  margin-right: ${({ marginRight }) => marginRight ?? '6px'};
  margin-top: 2px;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const FlexRowLocation = styled.div`
  flex: 1;
  align-items: flex-start;
  flex-wrap: wrap !important;
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  display: inline;
  flex-flow: row;
  align-content: center;
  @media ${pageSize.S} {
    ${props => !props.isCompanyView && 'margin-bottom:0px'};
    ${props =>
      !props.isCompanyView &&
      props.pronouns &&
      `
      border-left: 2px solid rgba(204, 213, 255, 0.11);
      padding-left: 16px;
    `}
  }
`;

export const StyledFlexRowLocation = styled(FlexRow)`
  margin-bottom: 0;
  @media ${pageSize.S} {
    margin-bottom: 8px;
  }
`;
export const LocationContainer = styled.span`
  white-space: pre-wrap;
  word-break: break-word;
  color: ${props => props.color} !important;
`;
export const FlexRowLanguages = styled(FlexRow)`
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-start;

  @media ${pageSize.S} {
    max-width: 50%;
    ${props =>
      !props.isCompanyView &&
      props.locationRendered &&
      `
      border-left: 2px solid rgba(204, 213, 255, 0.11);
      padding-left: 16px;
    `}
  }
`;
export const FlexRowPronouns = styled(FlexRow)`
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-start;
  @media ${pageSize.S} {
    max-width: 50%;
  }
`;
export const ShareButtonWrapper = styled.div`
  position: relative;
`;

export const Button = styled(ContainedButton)`
  position: relative;
  margin-right: 3px;

  ${props =>
    props.noPadding &&
    `
        .button-text {
            padding: 0;
        }
    `}
`;
export const Text = styled.span`
  display: inline-block;
`;
export const CompanyTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;
export const MoreLocations = styled.div`
  font-size: 16px;
  color: rgba(230, 234, 255, 0.35);
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;
export const StyledTypography = styled(Typography)`
  width: max-content;
  line-height: 10px;
`;

export const UserCardBadgesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
