import React from 'react';

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      // Set the height in state as well as the width
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isXS = React.useMemo(() => width <= 759, [width]);
  const isS = React.useMemo(() => width > 759 && width <= 1149, [width]);
  const isM = React.useMemo(() => width > 1149 && width <= 1349, [width]);
  const isL = React.useMemo(() => width > 1349, [width]);

  return {
    width,
    height,
    isXS,
    isS,
    isM,
    isL
  };
};

export default useViewport;
