import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  box-sizing: border-box;
  display: inline-block;
  height: 56px;
  max-height: 56px;
  width: 128px;
  max-width: 128px;
  padding: 12px;

  background: rgba(204, 213, 255, 0.11);
  border-radius: 12px;

  & .img {
    height: 32px;
  }
`;

const CompanyName = styled.div`
  height: 40px;
  line-height: 40px;

  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;

  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  margin-top: -4px;
`;

const CompanyLogo = ({ companyName, logoUrl }) => (
  <Wrapper>
    {logoUrl ? <img src={logoUrl} alt="Company Logo" /> : <CompanyName title={companyName}>{companyName}</CompanyName>}
  </Wrapper>
);

export default CompanyLogo;
