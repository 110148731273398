import AssetPlayIcon from 'images/assets/assets_icons/play-icon.svg';
import AssetPauseIcon from 'images/assets/assets_icons/pause-icon.svg';
import AssetZoomIcon from 'images/assets/assets_icons/zoom-icon.svg';
import AssetPdfIcon from 'images/assets/assets_icons/pdf-icon.svg';
import AssetTimer from 'images/assets/assets_icons/timer.svg';
import AssetBackgroundLight from 'images/assets/assets_icons/background-light.svg';
import AssetScreenCast from 'images/assets/assets_icons/screen-casts.svg';
import AssetDimensions from 'images/assets/assets_icons/dimensions.svg';
import AssetVerificationNotStarted from 'images/assets/assets_icons/verification-not-started.svg';
import AssetVerificationVerified from 'images/assets/assets_icons/verification-verified.svg';
import AssetVerificationProgress from 'images/assets/assets_icons/verification-progress.svg';
import AssetTermsOfservice from 'images/assets/assets_icons/terms-of-service.svg';

const CLOUDINARY_BASE_IMAGE = 'https://res.cloudinary.com/thexplace-develop';
const CLOUDINARY_BASE_VIDEO = 'https://res.cloudinary.com/thexplace-develop';

const getPreviewUrl = (assetType = 'image', assetId) => {
  switch (assetType.toUpperCase()) {
    case 'VIDEO':
      return `${CLOUDINARY_BASE_VIDEO}/${assetId}.mp4`;
    case 'VIDEO_POSTER':
      return `${CLOUDINARY_BASE_VIDEO}/${assetId}.png`;
    case 'AUDIO':
      return `${CLOUDINARY_BASE_VIDEO}/h_200,du_10,fl_waveform,co_rgb:A678F6,b_transparent/${assetId}.png`;
    case 'PDF':
      // return `${CLOUDINARY_BASE_IMAGE}/${assetId}.jpg`;
      return `${CLOUDINARY_BASE_IMAGE}/ar_1.77,c_crop,g_north,co_rgb:7266EE,e_colorize:70/${assetId}.jpg`;

    default:
      return `${CLOUDINARY_BASE_IMAGE}/${assetId}`;
  }
};

const getAssetIcon = assetType => {
  switch (assetType) {
    case 'video':
    case 'audio':
    case 'VIDEO':
    case 'AUDIO':
      return AssetPlayIcon;
    case 'pause':
    case 'PAUSE':
      return AssetPauseIcon;
    case 'pdf':
    case 'PDF':
      return AssetPdfIcon;
    case 'TIMER':
      return AssetTimer;
    case 'BACKGROUND_LIGHT':
      return AssetBackgroundLight;
    case 'SCREEN_CAST':
      return AssetScreenCast;
    case 'DIMENSIONS':
      return AssetDimensions;
    case 'VERIFICATION_NOT_STARTED':
      return AssetVerificationNotStarted;
    case 'VERIFICATION_VERIFIED':
      return AssetVerificationVerified;
    case 'VERIFICATION_PROGRESS':
      return AssetVerificationProgress;
    case 'TERMS_OF_SERVICE':
      return AssetTermsOfservice;
    default:
      return AssetZoomIcon;
  }
};

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

const DEFAULT_AVATAR = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;

const avatars = [
  `${process.env.PUBLIC_URL}/assets/avatars/violet.png`,
  `${process.env.PUBLIC_URL}/assets/avatars/green.png`,
  `${process.env.PUBLIC_URL}/assets/avatars/lightblue.png`,
  `${process.env.PUBLIC_URL}/assets/avatars/red.png`,
  `${process.env.PUBLIC_URL}/assets/avatars/blue.png`,
  `${process.env.PUBLIC_URL}/assets/avatars/pink.png`
];

const getRandomAvatar = () => {
  const idx = Math.floor(Math.random() * 4 + 1);

  return avatars[idx];
};

const fileSizeInMb = file => file.size / 1024 / 1024;

const getImageDimensions = image =>
  new Promise((resolve, reject) => {
    const link = URL.createObjectURL(image);
    const img = new Image();

    img.onload = function () {
      img.remove();
      resolve({ width: this.width, height: this.height });
    };
    img.onerror = () => reject('Failed to load image');
    img.src = link;
  });

export {
  getPreviewUrl,
  getAssetIcon,
  dataURItoBlob,
  DEFAULT_AVATAR,
  fileSizeInMb,
  getImageDimensions,
  getRandomAvatar
};
