import { useEffect } from 'react';

const RedirectApp2Route = ({ path }) => {
  useEffect(() => {
    window.location.replace(`${process.env.REACT_APP_NEXT_BASE_URL}/${path}`);
  }, []);
  return null;
};

export default RedirectApp2Route;
