import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Flex from 'common/styles/Flex';
import Filters from 'components/filters/Filters';
import SearchSwitcher from 'components/SearchSwitcher';
import useJobSearch from 'hooks/api/useJobSearch';
import useForm from 'hooks/useForm';
import useViewport from 'hooks/useViewport';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'store/auth';
import { portalActions } from 'store/portals';
import { selectIsMyProfileCompany, selectMyProfileId } from 'store/profile/profileSelectors';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import filtersQueryString from 'utils/filtersQueryString';
import isPropertyPopulated from 'utils/isPropertyPopulated';
import assign from 'lodash/fp/assign';
import isEmpty from 'lodash/fp/isEmpty';
import { useLocation } from 'react-router';
import PageWrapper from 'common/styles/PageWrapper';

const StyledJobSearchFilters = styled.div`
  background-color: rgba(26, 27, 30, 1);
  padding: 16px 0;

  @media ${pageSize.XS} {
    padding: 16px 0;
  }
`;

const Wrapper = styled(PageWrapper)`
  @media ${pageSize.XS}, ${pageSize.S} {
    gap: 32px;
  }
`;
const MoreFiltersCount = styled.span`
  ${commonStyles.ui_text_tiny}
  font-weight: 600;
  background: #ff5151;
  border-radius: 8px;
  padding: 0px 4px;
  margin-left: 8px;
`;

const fields = filters => ({
  discipline: { initialValue: filters?.discipline ?? [] },
  title: {initialValue: filters?.title ?? []},
  type: { initialValue: filters?.type ?? [] },
  genre: { initialValue: filters?.genre ?? [] },
  platform: { initialValue: filters?.platform ?? [] },
  tool: { initialValue: filters?.tool ?? [] },
  location: { initialValue: filters?.location ?? [] },
  preference: { initialValue: filters?.preference ?? [] }
  // teamRole: { initialValue: filters?.teamRole ?? [] },
  // requiredAvailability: { initialValue: filters?.requiredAvailability ?? [] },
});

const order = [
  {
    placeholder: 'Discipline',
    key: 'discipline'
  },
  {
    placeholder: 'Job title',
    key: 'title'
  },
  {
    placeholder: 'Job Type',
    key: 'type'
  },
  {
    placeholder: 'Genres',
    key: 'genre'
  },
  {
    placeholder: 'Platforms',
    key: 'platform'
  },
  {
    placeholder: 'Tools',
    key: 'tool'
  },
  {
    placeholder: 'Location',
    key: 'location'
  },
  {
    placeholder: 'Remote and on-site',
    key: 'preference'
  }
];

function JobSearchFilters({ clearFilters, filters, found, initialFilters, setFilters, setLocalFilters }) {
  const { isXS } = useViewport();
  const { search } = useLocation();

  const profileId = useSelector(selectMyProfileId);
  const isCompany = useSelector(selectIsMyProfileCompany);
  const isAuthed = useSelector(authSelectors.isAuthed);
  const dispatch = useDispatch();
  const { saveJobSearch } = useJobSearch();

  const [show, setShow] = React.useState(false);

  const { onFieldChange, values } = useForm({
    fields: fields(initialFilters)
  });

  const {
    jobsFound,
    loading: { loadingJobs },
    searchJobs,
    setJobsFound
  } = useJobSearch();

  React.useEffect(() => {
    if (show) {
      searchJobs(profileId, search.substring(1), 1, 'desc');
      setShow(false);
    }
  }, [search]);

  const hasInitialFilter = isPropertyPopulated(initialFilters);

  const moreFiltersCount = React.useMemo(
    () =>
      Object.keys(initialFilters).reduce((curr, key) => {
        if (key === 'applicant-status') {
          return curr;
        }

        const shouldAddCount =
          key === 'tag' ||
          key === 'experience' ||
          key === 'salary' ||
          key === 'tag' ||
          key === 'company.type' ||
          key === 'company.size' ||
          key === 'remote' ||
          key === 'relocation';

        const shouldAddOne = key === 'createdAt' && !isEmpty(initialFilters[key]);

        let add = 0;
        if (shouldAddCount) {
          add = initialFilters[key].length;
        }
        if (shouldAddOne) {
          add = 1;
        }

        curr += add;

        return curr;
      }, 0),
    [initialFilters]
  );

  useEffect(() => {
    setLocalFilters(values);
  }, [values]);

  const handleChange = (val, { name }) => {
    onFieldChange(name)(val ?? []);
    setShow(true);
    const query = assign({ ...initialFilters, ...values }, { [name]: val });
    searchJobs(profileId, filtersQueryString(query), 1, 'desc', { update: false, isCountSearch: true });
  };

  const handleReset = () => {
    Object.keys(values).forEach(key => {
      onFieldChange(key)([]);
    });
    clearFilters && clearFilters();
    setJobsFound(found);
    setShow(false);
  };

  const handleSubmit = () => {
    setFilters({ ...initialFilters, ...values });
    setShow(false);
  };

  const handleSave = () => {
    saveJobSearch(profileId, isCompany);
  };

  const openFilterModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'job-filter-modal',
        data: {
          initialFilters,
          filters,
          setFilters,
          clearFilters,
          setShow,
          found: jobsFound || found
        }
      })
    );
  };

  React.useEffect(() => {
    Object.keys(values).forEach(key => {
      onFieldChange(key)(initialFilters[key] ?? []);
    });
  }, [initialFilters]);

  return (
    <StyledJobSearchFilters>
      <Wrapper>
        <Flex justifySpaceBetween>
          <SearchSwitcher />
          {!isXS && (
            <Flex gap={6}>
              {hasInitialFilter && isAuthed && (
                <ContainedButton
                  className="transparent"
                  backgroundColor="transparent"
                  height={'100%'}
                  padding="12px 16px"
                  onClick={handleSave}
                >
                  Save this search
                </ContainedButton>
              )}

              <ContainedButton
                icon="filter"
                backgroundColor="rgba(204, 213, 255, 0.11)"
                height={'100%'}
                padding="12px 16px"
                onClick={openFilterModal}
                disabled={!isPropertyPopulated(filters)}
              >
                more Filters
                {hasInitialFilter && !!moreFiltersCount && <MoreFiltersCount>{moreFiltersCount}</MoreFiltersCount>}
              </ContainedButton>
            </Flex>
          )}
        </Flex>

        <Filters
          order={order}
          xsLimit={4}
          values={values}
          initialFilters={initialFilters}
          filters={filters}
          show={show}
          moreFiltersCount={moreFiltersCount}
          found={jobsFound}
          notFoundText={'No matching jobs found'}
          loading={loadingJobs}
          onChange={handleChange}
          onReset={handleReset}
          onSubmit={handleSubmit}
          onSave={handleSave}
          openFilterModal={openFilterModal}
        />
      </Wrapper>
    </StyledJobSearchFilters>
  );
}

export default JobSearchFilters;
