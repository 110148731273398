import React from 'react';

import FormAsyncSelect from 'common/form/FormAsyncSelect';
import { gameService } from 'api';

const getCoverUrl = cover => {
  if (!cover.url) return undefined;
  if (cover.fromIGDB) return cover.url;
  return `${process.env.REACT_APP_CLOUDINARY_BASE_IMAGE_URL}/${cover.url}`;
};

const GameSearch = ({ error, hintText, isClearable, label, noOptionsMessage, onChange, required, value, ...rest }) => {
  const [loading, setLoading] = React.useState();

  const searchOfficialGameName = React.useCallback(
    search =>
      gameService.searchGame(search, { searchLocal: true }).then(({ data }) =>
        data.map(game => ({
          _id: game._id,
          label: game.title,
          title: game.title,
          value: game.title,
          credits: game.credits,
          overview: game.desc,
          platforms: game.platforms.map(platform => platform.platform_name),
          genres: game.genres.map(genre => genre.genre_name),
          first_release_date: game.first_release_date,
          cover: game.cover,
          coverUrl: game.cover && getCoverUrl(game.cover)
        }))
      ),
    []
  );

  const handleCreate = async inputVal => {
    try {
      setLoading(true);

      const {
        data: { data }
      } = await gameService.requestGameTitle({ name: inputVal });

      onChange({ ...data, label: data.title, value: data.title });

      return data;
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormAsyncSelect
      isClearable={isClearable}
      label={label || 'Official game title'}
      required={required}
      apiCall={searchOfficialGameName}
      hintText={hintText}
      menuPosition="absolute"
      isLoading={loading}
      noOptionsMessage={noOptionsMessage}
      handleCreate={handleCreate}
      menuPlacement="bottom"
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      value={value}
      isCreatable
      onChange={onChange}
      id="name"
      className="name-required"
      error={error}
      {...rest}
    />
  );
};

export default GameSearch;
