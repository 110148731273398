import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import commonStyles from 'utils/common-styles';
import { portalActions } from 'store/portals';

import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import TxplModal from 'common/TxplModal';
import GameSearch from 'components/GameSearch';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;

const StyledFoundRoleLabel = styled.p`
  ${commonStyles.ui_text_medium}
  margin: 0px;
  margin-bottom: 24px;
  margin-top: 56px;
`;

const fields = {
  gameId: { initialValue: '' },
  name: { initialValue: '', validate: ['isRequired'] }
};

const StartAddCreditModal = ({ closePortal, name }) => {
  const [role] = React.useState(); // temporary it will be always undefined
  const dispatch = useDispatch();
  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields,
    onSubmitSuccess: () => {
      closePortal();
      dispatch(
        portalActions.openPortal({
          name: 'edit-credit-modal',
          data: values
        })
      );
    },
    callApi: () => Promise.resolve({ data: { success: true } })
  });

  return (
    <TxplModal
      title="Credits"
      subTitle="Claim credits for games you helped ship, then give yourself a standing ovation."
      name={name}
      size="small"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <StyledContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </StyledContainedButton>

          <StyledContainedButton onClick={onSubmit} backgroundColor="#7266EE" forceDisplayText>
            Next
          </StyledContainedButton>
        </>
      }
    >
      <GameSearch
        label="Official game title"
        required
        value={values.name}
        onChange={option => {
          onFieldChange('gameId')(option._id);
          onFieldChange('name')(option.label);
        }}
        error={errors.name}
      />

      {role && (
        <>
          <StyledFoundRoleLabel>
            We found a record confirming you were an Audio Engineer for this project. If you’d like to claim a different
            role, we’ll need to vet it first.
          </StyledFoundRoleLabel>

          <FormInput disabled label="Your role in this project" required value={role} />

          <ContainedButton
            backgroundColor="rgba(204, 213, 255, 0.11)"
            opens="edit-credit-modal"
            closes="start-add-credit-modal"
            icon="edit"
          >
            Edit role
          </ContainedButton>
        </>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('start-add-credit-modal')(StartAddCreditModal);
