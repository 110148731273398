import ContainedButton from 'common/ContainedButton';
import FormCheckbox from 'common/form/FormCheckbox';
import Flex from 'common/styles/Flex';
import TxplModal from 'common/TxplModal';
import Typography from 'components/shared/Typography';
import withRenderPortal from 'hocs/withRenderPortal';
import useDragEnd from 'hooks/useDragEnd';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import uniqBy from 'lodash/fp/uniqBy';
import concat from 'lodash/fp/concat';

function JobLifeModal({ closePortal, data, name }) {
  const { categories, selectedCategories = [], onAdd } = data;
  const [checkedCategories, setCheckedCategories] = useState(selectedCategories?.map(({ name }) => name) ?? []);

  const { list, onDragEnd } = useDragEnd({
    items: uniqBy('_id', concat(selectedCategories, categories)),
    update: false
  });

  const handleCheck = e => {
    const { checked, value } = e.target;

    let items = [...checkedCategories];

    if (checked) {
      items.push(value);
    } else {
      items = items.filter(it => it != value);
    }

    setCheckedCategories(items);
  };

  const getCheckedCategories = () =>
    list.reduce((curr, item) => {
      if (checkedCategories.find(name => name === item.name)) {
        curr.push(item);
      }
      return curr;
    }, []);

  const handleApply = () => {
    onAdd(getCheckedCategories());
    closePortal();
  };

  return (
    <TxplModal
      size="small"
      title="Life"
      titleMB="24"
      padding="56px 0 80px"
      name={name}
      addPaddingTop
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <ContainedButton mr={6} backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleApply}>Apply</ContainedButton>
        </>
      }
    >
      <Flex colum gap="32">
        <Typography variant="medium" color="rgba(230, 234, 255, 0.6)">
          Click the checkbox to add or remove sections, or drag and drop to rearrange the order.
        </Typography>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="life-blocks-dnd">
            {provided => (
              <Flex className="w-100" column gap={6} {...provided.droppableProps} ref={provided.innerRef}>
                {list.map((category, index) => (
                  <Draggable key={category._id} draggableId={category._id} index={index} isDragDisabled={false}>
                    {({ dragHandleProps, draggableProps, innerRef }) => (
                      <div ref={innerRef} {...dragHandleProps} {...draggableProps}>
                        <FormCheckbox
                          className={`btn reverse ${checkedCategories?.includes(category.name) ? 'checked' : ''}`}
                          name="category"
                          text={category.name}
                          value={category.name}
                          icon={'drag'}
                          iconBorder={'rgba(204, 213, 255, 0.11)'}
                          checkBoxRadius={4}
                          checked={!!checkedCategories?.includes(category.name)}
                          onChange={handleCheck}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </TxplModal>
  );
}

export default withRenderPortal('job-life-modal')(JobLifeModal);
