import styled from '@emotion/styled';
import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import React from 'react';
import TagItem from 'common/Tag/Tag';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import { pageSize } from 'utils/devices';
import moment from 'moment';

const CommentCard = styled(Flex)`
  flex-flow: column;
  gap: 11px;
  padding: 19px 16px 16px;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background: rgba(204, 213, 255, 0.05);
    .comment-dropdown {
      display: flex;
    }
  }

  @media ${pageSize.XS} {
    padding: 11px 0 8px;
  }
`;
const CommentHeader = styled(Flex)`
  position: relative;
`;
const ButtonWrapper = styled.div`
  display: none;
  position: absolute;
  top: -3px;
  right: 0px;
  @media ${pageSize.XS} {
    display: flex;
  }
`;

function ApplicantComments({ comments, jobId, onDelete, onEdit }) {
  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;

  if (!comments?.length) {
    return null;
  }

  return (
    <>
      {comments.map(comment => (
        <CommentCard key={comment._id}>
          <CommentHeader gap={8} alignCenter>
            <TagItem img={comment?.img ?? defaultAvatar}>{comment?.name}</TagItem>
            <Typography varient="small" color="rgba(230, 234, 255, 0.35)">
              {moment(comment?.date).fromNow()}
            </Typography>
            <ButtonWrapper className="comment-dropdown">
              <DropDown>
                <DropDownItem icon="edit" onClick={() => onEdit(comment)}>
                  Edit
                </DropDownItem>
                <DropDownItem icon="delete" onClick={() => onDelete(jobId, comment?._id)}>
                  Delete
                </DropDownItem>
              </DropDown>
            </ButtonWrapper>
          </CommentHeader>
          {comment.text && (
            <Typography variant="medium" color="rgba(230, 234, 255, 0.6)">
              {comment.text}
            </Typography>
          )}
        </CommentCard>
      ))}
    </>
  );
}

export default ApplicantComments;
