import styled from '@emotion/styled';
import TxplModal from 'common/TxplModal';

export const StyledTpxlModal = styled(TxplModal)`
  width: 680px;
  max-heigth: 542px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IDVerifiedImage = styled.img`
  width: 176px;
`;

export const IDVerifiedTitle = styled.h1`
  font-size: 24px;
  margin: 32px 0;
`;

export const IDVerifiedDescription = styled.p`
  margin: 0;
  font-size: 16px;
  color: rgba(230, 234, 255, 0.6);
`;
