import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/fp/sortBy';

import { getFormSelectTypeArray } from 'utils/tagHelpers';
import useTagService from 'hooks/api/useTagService';

import FormSelect from 'common/form/FormSelect';
import Typography from 'components/shared/Typography';
import { tagService } from 'api';
import { tagActions, tagSelectors } from 'store/tags';

import { StyledGameLabel } from './AddCreditSecondStepStyles';

const AddCreditSecondStep = ({ collaborator, error, game, handleChange, role }) => {
  const { getTags } = useTagService();

  const tagRoles = useSelector(tagSelectors.selectTagsByType('roles'));

  const [roles, setRoles] = useState({
    options: sortBy(getFormSelectTypeArray({ arr: tagRoles.data })),
    value: []
  });

  const [loading, setLoading] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState(role);

  const dispatch = useDispatch();

  const handleCreate = async inputVal => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await tagService.requestTag({ name: inputVal, type: 'role' });
      const tag = { value: data._id, label: data.name };
      dispatch(tagActions.addTag('roles', data));
      setSelectedRole(tag);
      handleChange(tag);
    } finally {
      setLoading(false);
    }
  };

  React.useMemo(() => {
    if (collaborator) {
      const role = collaborator.role.replace(/ /g, ' ').trim();

      const selected = tagRoles.data
        .map(curr => ({ label: curr.name, value: curr._id }))
        .find(tag => tag.label === role);

      if (collaborator && selected === undefined) {
        handleCreate(role);
      } else {
        setSelectedRole(selected ?? '');
        handleChange(selected ?? '');
      }
    }
  }, []);

  const handleRoleChange = e => {
    handleChange(e);
    setSelectedRole(e);
  };

  const handleOptionsOpen = () => {
    const fetchTags = async () => {
      await getTags('role');
    };
    if (!tagRoles.isLoaded) {
      fetchTags();
    }
  };

  useEffect(() => {
    const rolesOptions = tagRoles.data.map(discipline => ({ value: discipline._id, label: discipline.name }));
    setRoles(currentRoles => ({ value: currentRoles.value, options: rolesOptions }));
  }, [tagRoles]);

  return (
    <div>
      <Typography mb={25} color="rgba(230, 234, 255, 0.6);" variant="medium">
        {collaborator && (
          <>
            We found a record confirming you were an <StyledGameLabel color="#FFF">{collaborator.role}</StyledGameLabel>{' '}
            for <StyledGameLabel color="#FFF">{game.name}</StyledGameLabel>. If you’d like to claim a different role,
            {`we’ll need to vet it first.`}
          </>
        )}
        {!collaborator && (
          <>
            {`We haven’t found `}
            <StyledGameLabel color="#FFF">{game.name}</StyledGameLabel>
            {` in our database. Databases are
            not perfect. You can claim your credits and we'll take care of vetting it.`}
          </>
        )}
      </Typography>

      <FormSelect
        label="Your Role"
        required
        autoFocus
        error={error}
        handleCreate={handleCreate}
        loading={loading}
        isCreatable
        value={selectedRole}
        options={roles.options}
        onChange={handleRoleChange}
        onMenuOpen={handleOptionsOpen}
      />
    </div>
  );
};

export default AddCreditSecondStep;
