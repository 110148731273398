import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import FormSelect from 'common/form/FormSelect';
import FormSelectLocation from 'common/form/FormSelectLocation';
import useForm from 'hooks/useForm';
import React from 'react';
import shortid from 'shortid';
import specificWorkingHoursList from 'utils/specificWorkingHoursList';
import { locations } from '../constants';
import Typography from './shared/Typography';

const fields = {
  _id: { initialValue: '' },
  officeAddress: { initialValue: '', validate: ['isRequired'] },
  isHeadOffice: { initialValue: false },
  timezone: { initialValue: '', validate: [] },
  workDayStart: { initialValue: '', validate: [] },
  workDayEnd: { initialValue: '', validate: [] }
};

const StyledForm = styled.form`
  padding: 16px;
  padding-bottom: 32px;
  background-color: rgba(204, 213, 255, 0.05);
  border-radius: 8px;
  display: grid;
  grid-row-gap: 24px;
`;

const StyledGridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const AddLocationForm = ({ companyLocations, handleLocationAdd, handleLocationUpdate, locationId, onCancel }) => {
  const { initialize, onFieldChange, onSubmit, values } = useForm({
    fields,
    callApi: params => {
      if (locationId) {
        handleLocationUpdate({ ...params, id: locationId });
        return Promise.resolve(params);
      }

      const data = { ...params, _id: shortid.generate() };

      handleLocationAdd(data);
      return Promise.resolve(data);
    }
  });

  console.log('values', values);

  React.useEffect(() => {
    if (locationId) {
      const location = companyLocations.find(curr => (curr.id || curr._id) === locationId);
      initialize({
        ...location,
        workDayStart: parseInt(location.workDayStart, 10),
        workDayEnd: parseInt(location.workDayEnd, 10)
      });
    }
  }, [locationId]);

  return (
    <StyledForm>
      <FormSelectLocation
        locations={companyLocations}
        value={values.officeAddress}
        onChange={({ value }) => onFieldChange('officeAddress')(value)}
        label="Office address"
        inputGap={'3px'}
        required
      />

      <FormSelect
        options={locations}
        value={locations.find(location => location.label === values.timezone)}
        onChange={option => onFieldChange('timezone')(option.label)}
        label="Your time zone"
        hintText="So we can match you with time-zone relevant opportunities"
      />

      <div>
        <Typography variant="h3">Working hours</Typography>
        <StyledGridContainer>
          <FormSelect
            options={specificWorkingHoursList}
            value={specificWorkingHoursList.find(workingHour => workingHour.value === values.workDayStart)}
            onChange={option => onFieldChange('workDayStart')(option.value)}
            hintText="From"
          />

          <FormSelect
            options={specificWorkingHoursList}
            value={specificWorkingHoursList.find(workingHour => workingHour.value === values.workDayEnd)}
            onChange={option => onFieldChange('workDayEnd')(option.value)}
            hintText="To"
          />
        </StyledGridContainer>
      </div>

      <ButtonsWrapper>
        <ContainedButton backgroundColor="secondary" onClick={onCancel} mr={6}>
          Cancel
        </ContainedButton>
        <ContainedButton backgroundColor="#7266EE" onClick={onSubmit}>{`${
          locationId ? 'Save Changes' : 'Save Location'
        }`}</ContainedButton>
      </ButtonsWrapper>
    </StyledForm>
  );
};

export default AddLocationForm;
