import React from 'react';

import Icon from 'common/Icon';
import Badge from 'components/Badge';
import Typography from 'components/shared/Typography';

import styled from '@emotion/styled';
import {
  StyledAvatar,
  StyledHeaderDropdownItem,
  StyledHeaderDropdownItemBadge,
  StyledHeaderDropdownItemContainer
} from './HeaderMobileStyles';

const MenuTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HeaderMobileDropdownItem = ({ avatar, badge, icon, onClick, rightIcon, text, to }) => (
  <StyledHeaderDropdownItemContainer className="header-mobile-dropdown-item" to={to || '#'} onClick={onClick}>
    <StyledHeaderDropdownItem>
      {!avatar && <Icon color="rgba(230, 234, 255, 0.35);" icon={icon} />}
      {avatar && <StyledAvatar src={avatar} />}
      <MenuTypography variant="medium" color="#E5EAFF99">
        {text}
      </MenuTypography>
    </StyledHeaderDropdownItem>
    <StyledHeaderDropdownItemBadge>
      {badge && <Badge text={badge} color="red" textColor="#fff" borderRadius={4} paddingTop={1} paddingBottom={1} />}
      {rightIcon && <StyledAvatar src={rightIcon} />}
    </StyledHeaderDropdownItemBadge>
  </StyledHeaderDropdownItemContainer>
);

export default HeaderMobileDropdownItem;
