import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';
import { userActions } from 'store/user';

import useQuery from 'hooks/useQuery';
import VerificationStatus from './BackOfficeUsers/VerificationStatus';

const format = date => moment(date).format('MMM DD YYYY');

const BackOfficeUserVerificationStatusLog = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useQuery({
    url: `/backoffice/user/${params.userId}`,
    onSuccess: ({ user }) => {
      dispatch(userActions.initializeUser(user));

      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'OPERATIONS' },
          { label: 'Users', url: '/backoffice/operations/users' },
          { label: `${user.firstName} ${user.lastName}`, url: `/backoffice/operations/users/${user._id}` },
          { label: 'Verification Status Log' }
        ])
      );
    }
  });

  const renderActionUser = user => (user ? `${user.firstName} ${user.lastName}` : 'Stripe');

  return (
    <div>
      <Table keyId="_id" endpoint={`/backoffice/accounts/${params.userId}/verification-status-log`} dataKey="data">
        <Column
          title="CHANGED TO"
          keyName="status"
          render={statusLog => <VerificationStatus verificationStatus={statusLog.status} />}
          width="108px"
        />
        <Column title="CHANGED ON" keyName="createdAt" render={statusLog => format(statusLog.createdAt)} />
        <Column
          title="CHANGED BY"
          keyName="actionUserId"
          render={statusLog => renderActionUser(statusLog.actionUserId)}
        />
        <Column title="REASON" keyName="reason" />
        <Column preWrap title="ADDITIONAL COMMENTS" keyName="additionalComment" />
      </Table>
    </div>
  );
};

export default BackOfficeUserVerificationStatusLog;
