import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Loader from 'common/Loader';
import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import usePostedJobs from 'hooks/api/usePostedJobs';
import useProfilePath from 'hooks/useProfilePath';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import map from 'lodash/fp/map';
import values from 'lodash/fp/values';
import JobCloseModal from 'pages/jobs/details/JobCloseModal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions } from 'store/jobs';
import { profileSelectors } from 'store/profile';
import AddFirstJob from './AddFirstJob';
import DraggableJobCard from './DraggableJobCard';
import JobHideModal from './JobModals/JobHideModal/JobHideModal';
import JobPublishModal from './JobModals/JobPublishModal/JobPublishModal';
import JobShareModal from './JobSidebar/JobShareModal';
import { JobsContainer, JobsGrid } from './styled.components';
import UnpublishJobModal from './JobModals/UnpublishJobModal/UnpublishJobModal';

const StyledButton = styled(ContainedButton)`
  width: 100%;
  justify-content: center;
`;

const Jobs = () => {
  const myProfile = useSelector(profileSelectors.selectMyProfile);
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const {
    getPostedJobs,
    hasMorePostedJobs: hasMore,
    loadingPostedJobs,
    postedJobs,
    postedJobsPagination,
    showFirstEightJob,
    totalJobs,
    updatePostedJobsOrder
  } = usePostedJobs();
  const { myProfilePath } = useProfilePath();
  const jobs = values(postedJobs);
  const [items, setItems] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const savedJobs = keys(get('favorites.jobs', myProfile));
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const sortedArray = arrayMove(items, oldIndex, newIndex);

        const newJobList = [];
        const newOrder = [];

        sortedArray.forEach((id, index) => {
          newJobList.push({ ...postedJobs[id], order: totalJobs - index });
          newOrder.push({
            _id: id,
            order: totalJobs - index
          });
        });

        setJobList(newJobList);

        updatePostedJobsOrder({ profileId, jobs: newOrder })
          .then(() => {
            dispatch(
              jobActions.updateJob({
                jobType: 'postedJobs',
                profileId,
                jobs: newJobList
              })
            );
          })
          .catch(err => {
            console.log(err);
          });

        return sortedArray;
      });
    }
  }

  const loadMore = () => {
    getPostedJobs({
      profileId,
      skip: jobs.length
    });
  };

  const loadLess = () => {
    showFirstEightJob({
      profileId
    });
  };

  useEffect(() => {
    if (!jobs.length) {
      setLoading(true);
      getPostedJobs({
        profileId
      }).finally(() => setLoading(false));
    }
  }, [jobs.length === 0]);

  useEffect(() => {
    const sortedJobs = jobs.sort((a, b) => b.order - a.order);
    setItems(map('_id', sortedJobs));

    setJobList(sortedJobs);
  }, [jobs.length]);

  return (
    <>
      <JobsContainer>
        {hasPermission && (
          <Flex className="no-wrap row" gap={16} spaceBetweenOnXS alignCenter justifyEnd>
            <Typography variant="small" color="rgba(230, 234, 255, 0.6)">
              Looking for drafts and closed jobs?
            </Typography>
            <ContainedButton to={`${myProfilePath}/jobs-i-posted`} className="transparent">
              Manage all jobs
            </ContainedButton>
          </Flex>
        )}
        {hasPermission && !jobList.length && !loading && <AddFirstJob />}
        <Flex gap={16} column>
          <JobsGrid>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={items} strategy={rectSortingStrategy}>
                {items.map((jobId, index) => {
                  return (
                    <DraggableJobCard
                      job={{
                        ...jobList[index],
                        isMine: myProfile?._id === (jobList[index]?.talent?._id ?? jobList[index]?.companyProfile?._id),
                        favorite: savedJobs.includes(jobId)
                      }}
                      key={jobId}
                      id={jobId}
                      profileId={profileId}
                      items={items}
                      hasPermission={hasPermission}
                    />
                  );
                })}
              </SortableContext>
            </DndContext>
          </JobsGrid>
          {(loading || loadingPostedJobs) && <Loader />}
          {!hasMore && jobs.length > postedJobsPagination.limit && (
            <StyledButton backgroundColor="secondary" onClick={loadLess}>
              See Less
            </StyledButton>
          )}
          {hasMore && !loading && (
            <StyledButton backgroundColor="secondary" onClick={loadMore}>
              See more
            </StyledButton>
          )}
        </Flex>
      </JobsContainer>
      <JobShareModal />
      <JobCloseModal />
      <JobHideModal />
      <JobPublishModal />
      <UnpublishJobModal />
    </>
  );
};

export default Jobs;
