import styled from '@emotion/styled';
import { companyProfileService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import Spinner from 'common/Spinner';
import Flex from 'common/styles/Flex';
import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import React from 'react';
import { roles } from '../../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  margin-left: auto;
`;

const fields = member => ({
  email: { initialValue: member.email, validate: ['isRequired', 'isEmail'] },
  permission: { initialValue: member.permission, validate: ['isRequired'] }
});

const CompanyMemberPermissionsModal = ({ callback, closePortal, data, name }) => {
  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(data),
    callApi: params =>
      data.memberId
        ? companyProfileService.editMember(data.companyId, data.memberId, params)
        : companyProfileService.addMember(data.companyId, params),
    onSuccess: ({ members }) => {
      callback && callback(members);
      closePortal();
    }
  });

  return (
    <TxplModal
      title={`${data.memberId ? 'Edit' : 'Add'} company member`}
      appElement={document.getElementById('root-modal')}
      size="large"
      name={name}
      padding="56px 0 120px"
      headerPaddingBottom={16}
      addPaddingTop
      renderFooter={
        <Flex justifySpaceBetween width="100%">
          {data.memberId && (
            <ContainedButton
              icon="delete"
              color="#FF5151"
              className="transparent"
              backgroundColor="transparent"
              data={data}
              opens="delete-company-member-modal"
              closes={name}
            >
              Delete member
            </ContainedButton>
          )}
          <StyledActionsContainer>
            {submitting && <Spinner />}
            <ContainedButton onClick={closePortal} backgroundColor="secondary" disabled={submitting}>
              Cancel
            </ContainedButton>
            <ContainedButton form="company-member-permissions" type="submit" disabled={submitting}>
              {data.memberId ? 'Save' : 'Add user'}
            </ContainedButton>
          </StyledActionsContainer>
        </Flex>
      }
    >
      <form onSubmit={onSubmit} id="company-member-permissions">
        <FormInput
          mb={56}
          label="Email"
          value={values.email}
          onChange={onFieldChange('email')}
          error={errors.email}
          autoFocus
          required
          disabled={!!data.memberId}
        />

        <FormSelect
          label="Permission"
          value={roles.find(role => role.value === values.permission)}
          onChange={option => onFieldChange('permission')(option.value)}
          error={errors.permission}
          options={roles}
          required
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('company-member-permissions-modal')(CompanyMemberPermissionsModal);
