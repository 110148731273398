import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto 3fr 1fr;
  height: 20px;
  padding: 24px 10px 24px 16px;
  background: #111112;
  position: sticky;
  top: 0;
  z-index: 99;
`;

export const StyledLogo = styled.img`
  width: 130px;
  cursor: pointer;
`;

export const StyledNavItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledNavigation = styled.div`
  display: none;
  ${props => props.open && 'display: flex;'}
`;

export const StyledHeaderMenuItem = styled.div`
  font-family: Space Grotesk;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  width: 40px;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 2px solid transparent;
`;

export const StyledHeaderMenuItemOpen = styled(StyledHeaderMenuItem)`
  border-bottom: 2px solid #fff;
`;

export const StyledHeaderMenuLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover,
  &.active {
    .header-MenuLabel {
      color: white;
    }
    .header-Icon path {
      fill: white;
    }
  }
`;
export const StyledItemsWrapper = styled.div`
  position: absolute;
  top: 68px;
  left: 0px;
  box-sizing: border-box;
  background-color: #1a1b1e;
  overflow: scroll;
  z-index: 100;
  width: 100%;
  height: calc(100vh - 68px);
  > .react-tabs__tab {
    width: 100%;
  }
  .react-tabs__tab-panel {
    max-width: unset;
  }
  ${props => (props.isOpen ? '' : 'display: none;')}
`;

export const StyledHeaderMenuBadge = styled.div`
  position: absolute;
  top: -9px;
  right: -9%;
`;

export const StyledHeaderDropdownItems = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 68px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background: #1a1b1e;
`;

export const StyledAvatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: contain;
  /* margin: 12px; */
`;

export const StyledHeaderDropdownItemContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 10px 16px;
`;

export const StyledHeaderDropdownItem = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledHeaderDropdownItemBadge = styled.div``;

export const StyledSpacer = styled.div`
  border: 1px solid rgba(204, 213, 255, 0.11);
  width: 100%;
`;

export const StyledAuthContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-left: 15px;
`;
