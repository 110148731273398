import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';

const StyledVettingStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledVettingLabel = styled.span`
  ${commonStyles.caption}
  text-transform: uppercase;
  margin-left: 6px;
`;

const VettingStatus = ({ className, hideText, vetted }) => (
  <StyledVettingStatusContainer className={className}>
    <Icon icon={vetted ? 'vetted' : 'vetting'} color="black" />
    {!hideText && <StyledVettingLabel>{vetted ? 'vetted' : 'vetting in progress'}</StyledVettingLabel>}
  </StyledVettingStatusContainer>
);

export default VettingStatus;
