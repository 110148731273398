import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

export const MobileSearchWrapper = styled.div`
  width: 100vw;
  padding-top: 16px;
  position: absolute;
  background-color: #1a1b1e;
`;
export const MobileSearch = styled.input`
  ${commonStyles.ui_text_medium};
  padding: 16px 16px 16px 48px;
  font-size: 13px;
  outline: none;
  background: transparent;
  border: none;
  min-width: 300px;
  ${props => (props.error ? 'border: 2px solid #FF5151;' : '')}
  width: -webkit-fill-available;
  ${props => (props.disabled ? 'border: 2px solid rgba(230, 234, 255, 0.35);' : '')}
  ${props => (props.disabled ? 'color: rgba(230, 234, 255, 0.35);' : '')}
  background: rgba(204, 213, 255, 0.05);
  &:focus {
    border: 1px solid #8a80ff;
  }
  @media ${pageSize.M} {
    min-width: 200px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: 42px;
  left: 16px;
  transform: translateY(-50%);

  ${props => props.iconTop && `top: ${props.iconTop}px;`}
  ${props => props.iconBottom && `bottom: ${props.iconBottom}px;`}
  ${props => props.iconRight && `right: ${props.iconRight}px;`}
`;
export const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;
export const ClearSearchWrapper = styled.div`
  position: absolute;
  right: 7%;
  top: 50%;
  transform: translateY(-50%);
`;
export const ClearSearch = styled.div`
  padding: 6px 10px;
  border-radius: 4px;
  background: rgba(204, 213, 255, 0.11);
  cursor: pointer;
  display: flex;
`;
export const SearchBarDropDown = styled.div`
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);
  width: 95%;
  padding: 4px 0;
  z-index: 20;
`;
export const SearchBarDropDownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: rgba(204, 213, 255, 0.05);
  }

  .search-dropdown-icon path {
    fill: rgba(229, 234, 255, 0.6);
  }
  .search-dropdown-icon path:hover {
    fill: rgba(229, 234, 255, 0.6);
  }
`;
export const SearchBarDropDownItemDisabled = styled(SearchBarDropDownItem)`
  opacity: 0.4;
  cursor: auto;
`;
export const SearchBarDropDownHeader = styled.div`
  padding: 12px 12px 12px 0;
  margin: 0 16px;
  ${({ topBorder }) => topBorder && 'border-top: 2px solid rgba(204, 213, 255, 0.11);'}
`;
export const SearchTextWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const SearchText = styled.span`
  ${commonStyles.ui_text_medium}
  text-transform: initial;
  color: ${({ color }) => color || 'rgba(229, 234, 255, 0.6)'};
  overflow: hidden;
`;
export const SearchInText = styled.span`
  ${commonStyles.ui_text_tiny}
  text-transform: initial;
  color: rgba(229, 234, 255, 0.6);
`;
