import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import * as Styled from './StyledConversationItem';

import { profileSelectors } from 'store/profile';

import NA from 'images/NA.png';
import Icon from 'common/Icon';
import MessageStatus from 'pages/conversations/ConversationBox/messages/MessageStatus';

const ConversationItem = ({ custom, id, lastMessage, selectedConversation, setSelectedConversation, setVisible }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const participants = custom.participants;
  const unreadMessages = custom?.unreadMessages || 0;
  const sender = participants.me;
  const recipient = participants.recipient;
  const contact = sender?.id === profileId ? recipient : sender;
  const { name = '', photoUrl = '' } = contact;

  const lastMessageDate = lastMessage && moment(lastMessage.createdAt).format('LT').toLowerCase();
  let isUnread = false;
  if (lastMessage) {
    isUnread = lastMessage?.senderId !== profileId && !lastMessage?.readBy?.includes(profileId) && unreadMessages;
  }

  const lastMessageText = lastMessage?.text || lastMessage?.body;

  const handleSelectedConversation = () => {
    const query = new URLSearchParams(search);
    const searchId = query.get('id');

    setSelectedConversation(id);
    setVisible(true);

    if (searchId) {
      history.push(`${pathname}`);
    }
  };

  return (
    <Styled.Container active={selectedConversation === id} onClick={handleSelectedConversation}>
      <Styled.AvatarWrapper>
        <Styled.Avatar>
          <img src={photoUrl || NA} alt="" />
        </Styled.Avatar>
      </Styled.AvatarWrapper>
      <Styled.Details>
        <Styled.Participant>
          {name}
          {unreadMessages > 0 && (
            <div>
              <Styled.UnreadContainer>{unreadMessages} new</Styled.UnreadContainer>
            </div>
          )}
          {lastMessage && unreadMessages === 0 && (
            <MessageStatus conversationId={id} {...lastMessage} contact={contact} />
          )}
        </Styled.Participant>
        {lastMessage ? (
          <Styled.LastMessage>
            <Styled.LastMessageText isUnread={isUnread}>
              {lastMessage.attachment && (
                <>
                  <Icon icon="attachment" color="rgba(229, 234, 255, 0.6)" hoverColor="rgba(229, 234, 255, 0.6)" />
                  Attachment sent
                </>
              )}
              {!lastMessage.attachment && lastMessageText?.length > 18
                ? `${lastMessageText.substr(0, 18)}...`
                : lastMessageText}
            </Styled.LastMessageText>
            <Styled.LastMessageDate>{lastMessageDate}</Styled.LastMessageDate>
          </Styled.LastMessage>
        ) : (
          'No messages yet'
        )}
      </Styled.Details>
    </Styled.Container>
  );
};

export default ConversationItem;
