const benefitColors = [
  '#FF5151',
  '#FF9C40',
  '#FFE040',
  '#CAFF33',
  '#26FF3C',
  '#26FFB1',
  '#A4BFD8',
  '#26CBFF',
  '#608CFF',
  '#968DFF',
  '#9C4FFF',
  '#FF32F7',
  '#FF7CBB'
];

export default benefitColors;
