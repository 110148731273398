import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DisplayContext } from 'common/DisplaySwitcher';
import Flex from 'common/styles/Flex';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';
import { selectMyProfileProperty } from 'store/profile/profileSelectors';
import useConversationService from 'hooks/api/useConversation';
import SendMessageModal from 'components/modals/SendMessageModal';
import ApplicantCard from './ApplicantCard';
import ApplicantsEmpty from './ApplicantsEmpty';
import { compose, filter, map, pluck, prop, propEq, propOr } from 'ramda';
import { userSelectors } from 'store/user';
import { selectMyProfile } from 'store/profile/profileSelectors';

const ApplicantsList = ({
  applicants,
  biasReducedMode,
  countFilter,
  hasMore,
  job,
  jobId,
  jobTitle,
  loading,
  status
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const conversations = useSelector(profileSelectors.selectMyProfileProperty('conversations'));
  const jobs = useSelector(selectMyProfileProperty('jobs'));

  const [applicantsFinal, setApplicantsFinal] = useState([]);

  useEffect(() => {
    const newApplicantsId = job?.newAppliedBy && pluck('talentId', job?.newAppliedBy);

    if (status === 'all') {
      if (countFilter === 'visible') {
        setApplicantsFinal(
          applicants
            .filter(app => !app?.savedApplicant && !app?.hiredApplicant)
            .reduce((acc, elem) => {
              if (newApplicantsId?.includes(elem.id)) {
                return [elem, ...acc];
              }
              return [...acc, elem];
            }, [])
        );
      }
      if (countFilter === 'hidden') {
        setApplicantsFinal(
          applicants
            .filter(app => !app?.savedApplicant && !app?.hiredApplicant && !app?.rejectedApplicant)
            .reduce((acc, elem) => {
              if (newApplicantsId?.includes(elem.id)) {
                return [elem, ...acc];
              }
              return [...acc, elem];
            }, [])
        );
      }
    }
    if (status === 'saved') {
      setApplicantsFinal(applicants?.filter(app => !app?.rejectedApplicant && app?.savedApplicant));
    }
    if (status === 'hired') {
      setApplicantsFinal(applicants?.filter(app => !app?.rejectedApplicant && app?.hiredApplicant));
    }
  }, [applicants, countFilter]);

  const { getConversationByParticipants } = useConversationService();

  const { display } = React.useContext(DisplayContext);

  const handleMessageClick = async talent => {
    const { conversation } = await getConversationByParticipants({ participants: [profileId, talent.id] });
    let conversationId = '';
    for (const property in conversations) {
      const participants = keys(get('participants', conversations[property]));
      participants.forEach(participant => {
        if (participant === talent.id) {
          conversationId = property;
        }
      });
    }
    if (talent?.contactedApplicant || conversation) {
      const profileType = talent.type === 'talent' ? 'talent' : 'company';
      history.push(`/${profileType}/${profileId}/conversations?id=${conversationId}`);
    } else {
      dispatch(
        portalActions.openPortal({
          name: 'send-message-modal',
          data: {
            talent,
            jobId,
            jobTitle,
            jobCreatorProfileType: job?.talentModel === 'Talent' ? 'talent' : 'company'
          }
        })
      );
    }
  };

  const isCompany = useSelector(profileSelectors.selectIsCompanyView);
  const activeProfile = useSelector(selectMyProfile);
  const currentUser = useSelector(userSelectors.selectCurrentUserProperty('_id'));
  const companyOwner = activeProfile?.owner;

  const isAdmin = propEq('role', 'admin');
  const adminIds = compose(map(prop('user')), filter(isAdmin), propOr([], 'members'))(activeProfile);
  const myUserProfileList = pluck('userId', useSelector(profileSelectors.selectMyProfileList));

  const enableCompanyCreateProposal = () => {
    if (isCompany) {
      if (myUserProfileList.includes(companyOwner)) {
        return true;
      } else {
        if (adminIds.includes(currentUser)) {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
  };

  const allowCreateProposalForCompany = enableCompanyCreateProposal();
  const isCompanyOwner = myUserProfileList.includes(companyOwner);

  return (
    <Flex column gap={16}>
      {!loading && !applicantsFinal?.length && <ApplicantsEmpty state={status} />}
      {!hasMore &&
        applicantsFinal?.length > 0 &&
        !loading &&
        applicantsFinal?.map(applicant => (
          <ApplicantCard
            job={job}
            key={applicant._id}
            jobId={jobId}
            applicant={applicant}
            isListView={display === 'list'}
            handleMessageClick={handleMessageClick}
            biasReducedMode={biasReducedMode}
            enableCompanyProposal={allowCreateProposalForCompany}
            isCompany={isCompany}
            activeProfile={activeProfile}
            isCompanyOwner={isCompanyOwner}
          />
        ))}
      <SendMessageModal />
    </Flex>
  );
};

export default ApplicantsList;
