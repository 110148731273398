import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { portalActions } from 'store/portals';
import Icon from 'common/Icon';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import ShareURL from 'common/ShareURL';
import createSharingUrl from 'utils/create-sharing-url';
import { authSelectors } from 'store/auth';

const CreditDropdown = ({ credit, hasPermission }) => {
  const dispatch = useDispatch();
  const [shareURLVisible, setShareURLVisible] = useState(false);
  const isAuthed = useSelector(authSelectors.isAuthed);

  const handleEditClick = e => {
    e.stopPropagation();
    dispatch(
      portalActions.openPortal({
        name: 'edit-credit',
        data: { credit }
      })
    );
  };

  const handleShareClick = e => {
    e.stopPropagation();
    setShareURLVisible(true);
  };
  const canShareCredit = isAuthed && credit.vetted;

  return (
    <DropDown icon="dots" noBorder backgroundColor="#1A1B1E">
      {hasPermission && (
        <DropDownItem data-testid="edit-credit" onClick={handleEditClick}>
          <Icon icon="edit" color="rgba(230, 234, 255, 0.6)" mr={16} />
          Edit
        </DropDownItem>
      )}
      <DropDownItem onClick={handleShareClick} disabled={!canShareCredit}>
        <Icon icon="share" color="rgba(230, 234, 255, 0.6)" mr={16} />
        Share
        {shareURLVisible && (
          <ShareURL
            styles={{ container: { top: 35, withBorder: true } }}
            visible={shareURLVisible}
            setVisible={setShareURLVisible}
            url={createSharingUrl({ type: 'credit', id: credit._id })}
            successText="The credit link has been copied"
          />
        )}
      </DropDownItem>
    </DropDown>
  );
};

export default CreditDropdown;
