import { isNilOrEmpty } from '@flybondi/ramda-land';
import { any } from 'ramda';

export const missingBasicInfo = profile => {
  const { basicInfo, about, firstName, lastName } = profile;

  return any(isNilOrEmpty, [
    basicInfo?.imageUri?.url,
    firstName,
    lastName,
    basicInfo?.disciplines,
    basicInfo?.roles,
    basicInfo?.location,
    basicInfo?.languages,
    basicInfo?.experienceLevel,
    about?.tools,
    about?.skills
  ]);
};

// validate verification status and basic info to decide if show join events modal
export const showJoinEventsModal = ({ profile, isCollaborator, verificationStatus, hasJoinEventMessageRead }) => {
  return (
    profile &&
    profile.type === 'talent' &&
    profile?.basicInfo &&
    !isCollaborator &&
    !hasJoinEventMessageRead &&
    !missingBasicInfo(profile) &&
    verificationStatus === 'verified'
  );
};
