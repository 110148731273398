import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';
import ContainedButton from 'common/ContainedButton';

const StyledAddFirstExperienceContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  margin-bottom: 54px;
`;

const StyledDescription = styled.p`
  ${commonStyles.ui_text_medium}
  margin: 32px 0px;
  width: 295px;
  text-align: center;
`;

const AddFirstExperience = ({ toggleAddMode }) => (
  <StyledAddFirstExperienceContainer>
    <Icon icon="experience" color="rgba(221, 226, 255, 0.2)" size={64} />
    <StyledDescription>Build your Xfolio, showcase your glorious skills, and prepare to launch</StyledDescription>

    <ContainedButton onClick={toggleAddMode} icon="plus" forceDisplayText>
      Add experience
    </ContainedButton>
  </StyledAddFirstExperienceContainer>
);

export default AddFirstExperience;
