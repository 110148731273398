import React from 'react';
import {
  JobButtonWrapper,
  JobCompanyContainer,
  JobCompanyImage,
  JobCompanyName,
  JobImage,
  JobImageGradient,
  JobImageWrapper,
  JobInfoWrapper,
  JobTagList,
  JobTagListWrapper,
  JobTitle,
  StyledJobCard
} from '../styled.components';
import Flex from 'common/styles/Flex';
import { AppliedInfo } from '../JobCard/JobCardStyles';
import * as Styled from '../../../../components/UserCard/StyledUserCard';
import moment from 'moment';
import getDisciplinesIconAndColor from 'utils/disciplines';
import Tag from 'common/Tag/Tag';
import NA from 'images/NA.png';
import BookmarkButton from 'common/BookmarkButton';
import useJobServices from 'hooks/api/useJobServices';
import { profileSelectors } from 'store/profile';
import { useSelector } from 'react-redux';
import { compose, keys, path } from 'ramda';

const extractMatchingKeys = compose(keys, path(['favorites', 'jobs']));

const JobCardSearch = ({ isListView, job }) => {
  const { saveJob } = useJobServices();
  const currentProfile = useSelector(profileSelectors.selectMyProfileId);
  const currentProfileData = useSelector(profileSelectors.selectMyProfile);
  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;

  const {
    code,
    title,
    locationName,
    disciplines,
    jobType,
    workplaceType,
    thumbnail,
    companyId,
    profileId,
    jobId,
    updated,
    imageUri,
    profileName
  } = job;

  const favorites = extractMatchingKeys(currentProfileData);
  const isSaved = favorites.includes(jobId);

  const handleClick = () => {
    const profilePath = companyId ? 'company' : 'talent';
    const jobPagePath = `/${profilePath}/${companyId ?? profileId}/jobs`;
    const jobPath = `${jobPagePath}/${jobId}`;
    const jobUrl = `${window.location.origin}/app${jobPath}`;
    window.open(jobUrl, '_blank');
  };

  const handleSave = e => {
    e.stopPropagation();
    if (isSaved) {
      saveJob(currentProfile, jobId, 'remove');
    } else {
      saveJob(currentProfile, jobId, 'save');
    }
  };

  return (
    <StyledJobCard onClick={handleClick} isListView={isListView}>
      <JobImageWrapper className="job-image-wrapper">
        <JobImage className="job-image" src={thumbnail ?? NA} />
        <JobImageGradient className="job-company-grad" />
        <JobCompanyContainer className="job-company-info">
          <JobCompanyImage className="job-company-img" src={imageUri || defaultAvatar} />
          {!isListView && <JobCompanyName>{profileName}</JobCompanyName>}
        </JobCompanyContainer>
        <JobButtonWrapper>
          <BookmarkButton tooltipId={`job-tooltip-${jobId}`} tooltip={'Save'} onClick={handleSave} isSaved={isSaved} />
        </JobButtonWrapper>
      </JobImageWrapper>

      <JobInfoWrapper className="job-info">
        <JobTitle className="job-title">
          {title} {code && `(${code})`}
        </JobTitle>

        <Flex row mb={'20px'}>
          <>
            <AppliedInfo>Posted {moment(updated).fromNow()}</AppliedInfo>
          </>
          {locationName && (
            <>
              <Styled.StyledIcon
                icon="separator-dot"
                color="rgba(230, 234, 255, 0.6)"
                marginLeft={'8px'}
                marginRight={'8px'}
              />
              <div>
                <Styled.StyledIcon icon="location" color="rgba(230, 234, 255, 0.6)" marginRight={'8px'} />
                <Styled.LocationContainer color="rgba(230, 234, 255, 0.6)">{locationName}</Styled.LocationContainer>
              </div>
            </>
          )}
        </Flex>

        <JobTagListWrapper>
          <JobTagList>
            {disciplines &&
              disciplines.map((discipline, key) => {
                const { name, color, icon } = getDisciplinesIconAndColor(discipline);
                return (
                  <Tag key={key} icon={icon} backgroundColor={color} gap="8" color="rgba(242, 244, 255, 0.9)">
                    {name}
                  </Tag>
                );
              })}
            {jobType && <Tag icon={jobType === 'internship' && 'internship'}>{jobType}</Tag>}
            {workplaceType === 'remote' && <Tag>Remote friendly</Tag>}
          </JobTagList>
        </JobTagListWrapper>
      </JobInfoWrapper>
    </StyledJobCard>
  );
};

export default JobCardSearch;
