import React from 'react';
import * as Styled from './StyledUserCard';

const Location = ({ renderLocation, dataHidden, pronouns, isCompanyView }) => {
  return (
    <>
      {renderLocation && !dataHidden && (
        <Styled.FlexRowLocation isCompanyView={isCompanyView} pronouns={pronouns}>
          <Styled.StyledIcon icon="location" color="rgba(230, 234, 255, 0.6)" />
          <Styled.LocationContainer>{renderLocation}</Styled.LocationContainer>
        </Styled.FlexRowLocation>
      )}
      {renderLocation && dataHidden && (
        <Styled.FlexRowLocation isCompanyView={isCompanyView}>
          <Styled.StyledIcon icon="location" color="rgba(230, 234, 255, 0.6)" />
          <Styled.LocationContainer color="rgba(229, 234, 255, .35)">Location hidden</Styled.LocationContainer>
        </Styled.FlexRowLocation>
      )}
    </>
  );
};

export default Location;
