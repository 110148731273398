import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import DeleteProjectModal from 'components/modals/DeleteProjectModal';
import PortfolioMobileActionsModal from 'components/modals/PortfolioMobileActionsModal';
import UnpublishProjectModal from 'components/modals/UnpublishProjectModal';
import useProfilePath from 'hooks/useProfilePath';
import useViewport from 'hooks/useViewport';
import isEmpty from 'lodash/fp/isEmpty';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { portalActions } from 'store/portals';
import { pageSize } from 'utils/devices';

const StyledViewPortfolioFooterContainer = styled.div`
  background: rgba(204, 213, 255, 0.05);
  left: 0;
  bottom: 0;
  background-color: #111112;
  right: 0;
  border-top: 2px solid rgba(204, 213, 255, 0.11);
  padding: 16px 60px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 6;

  @media ${pageSize.XS} {
    padding: 16px;
  }
`;

const StyledLeftContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const PublishTooltip = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 3;
  width: 230px;
  top: -90px;
  left: -90px;
  border: 1px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  padding: 8px;
  padding-left: 14px;
  opacity: 1 !important;
  background: #000;
  text-transform: initial;
  color: rgba(230, 234, 255, 0.6);
  border: 1px solid rgba(204, 213, 255, 0.22) !important;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #000;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid rgba(204, 213, 255, 0.22);
  }
`;

const PublishButtonWrapper = styled.div`
  position: relative;

  &:hover .publish-tooltip {
    opacity: 1;
    visibility: visible;
  }
`;
const StyledLink = styled.div`
  text-decoration: none;
`;

const EditPortfolioFooter = ({
  assets,
  handleSave,
  lockUnlockStatus,
  onDraftSave,
  onDraftValidate,
  onPublishValidate,
  projectId,
  status,
  submitting,
  triggerErrors
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const { myProfilePath } = useProfilePath();

  const openProjectPublish = async () => {
    const { success: isSuccess } = triggerErrors()(values => onPublishValidate(values, assets));

    if (status === 'published') {
      dispatch(
        portalActions.openPortal({
          name: 'unpublish-project-modal',
          data: { projectId, status, forcePublishProject: true }
        })
      );
    }
    if (status === 'draft' && isSuccess) {
      dispatch(
        portalActions.openPortal({
          name: 'project-publish-modal',
          data: { projectId, status, forcePublishProject: true }
        })
      );
    }
  };

  const handleDoneClick = () => {
    if (status === 'publish') {
      const { errors } = triggerErrors()(values => onPublishValidate(values, assets));
      if (isEmpty(errors)) {
        handleSave();
      }
    }
    if (status === 'draft') {
      const { errors } = triggerErrors()(values => onDraftValidate(values));
      if (isEmpty(errors)) {
        onDraftSave();
      }
    }
    history.push(`${myProfilePath}/portfolio`);
  };

  return (
    <>
      <StyledViewPortfolioFooterContainer>
        <StyledLeftContainer>
          {lockUnlockStatus && (
            <ContainedButton
              icon="delete"
              style={{ border: '2px solid rgba(204, 213, 255, 0.11)' }}
              backgroundColor="transparent"
              iconColor="#FF5151"
              opens="delete-project-modal"
              data={{ projectId }}
              type="button"
            />
          )}

          {width <= 759 ? (
            <ContainedButton
              data={{ projectId, openProjectPublish, status, forcePublishProject: true }}
              backgroundColor="secondary"
              opens="portfolio-mobile-actions"
              icon="kebab"
            />
          ) : (
            <>
              {lockUnlockStatus && (
                <>
                  <PublishButtonWrapper>
                    <ContainedButton
                      backgroundColor="secondary"
                      data-tip
                      data-for="projectPublishBtn"
                      onClick={openProjectPublish}
                      type="button"
                    >
                      {status === 'draft' ? 'Publish' : 'Unpublish'}
                    </ContainedButton>
                    {status === 'published' && (
                      <PublishTooltip className="publish-tooltip">
                        This will move the project to your drafts and make it private.
                      </PublishTooltip>
                    )}
                  </PublishButtonWrapper>
                  <ContainedButton
                    data={{ projectId }}
                    backgroundColor="secondary"
                    opens="project-publish-modal"
                    type="button"
                  >
                    Edit settings
                  </ContainedButton>
                </>
              )}
            </>
          )}
        </StyledLeftContainer>

        {submitting ? (
          <Spinner />
        ) : (
          <ContainedButton onClick={handleDoneClick} type="button">
            <StyledLink>Done</StyledLink>
          </ContainedButton>
        )}
      </StyledViewPortfolioFooterContainer>

      <PortfolioMobileActionsModal />
      <UnpublishProjectModal />
      <DeleteProjectModal />
    </>
  );
};

export default EditPortfolioFooter;
