import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import { portalActions } from 'store/portals';
import { pageSize } from 'utils/devices';

const StyledViewPortfolioFooterContainer = styled.div`
  background: rgba(204, 213, 255, 0.05);
  left: 0;
  bottom: 0;
  background-color: #111112;
  right: 0;
  border-top: 2px solid rgba(204, 213, 255, 0.11);
  padding: 16px 60px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 6;

  @media ${pageSize.XS} {
    padding: 16px;
  }
`;

const StyledRightSideContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const AddPortfolioFooter = ({
  assets,
  handleCancel,
  onDraftValidate,
  onPublishValidate,
  projectId,
  saveDraft,
  submitting,
  triggerErrors
}) => {
  const dispatch = useDispatch();

  const onSaveDraft = () => {
    const { success: isSuccess } = triggerErrors()(onDraftValidate);

    if (isSuccess) {
      saveDraft();
    }
  };

  const openProjectPublish = async () => {
    const { success: isSuccess } = triggerErrors()(values => onPublishValidate(values, assets));

    if (isSuccess) {
      dispatch(
        portalActions.openPortal({
          name: 'project-publish-modal',
          data: { projectId, forcePublishProject: true }
        })
      );
    }
  };

  return (
    <StyledViewPortfolioFooterContainer>
      <ContainedButton type="button" backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleCancel}>
        Cancel
      </ContainedButton>

      <StyledRightSideContainer>
        {submitting && <Spinner />}
        <ContainedButton
          data-testid="save-draft-button"
          type="button"
          backgroundColor="rgba(204, 213, 255, 0.11)"
          onClick={onSaveDraft}
          disabled={submitting}
          icon="save"
        >
          Save Draft
        </ContainedButton>
        <ContainedButton type="button" onClick={openProjectPublish}>
          Continue
        </ContainedButton>
      </StyledRightSideContainer>
    </StyledViewPortfolioFooterContainer>
  );
};

export default AddPortfolioFooter;
