import React from 'react';
import { deleteBenefit, getBenefits } from 'api/benefitsService';

import Icon from 'common/Icon';
import styled from '@emotion/styled';
import FormCheckbox from 'common/form/FormCheckbox';
import ContainedButton from 'common/ContainedButton';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../store/profile';

const StyledBenefitSingleItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const BenefitItem = ({
  benefitIds,
  companyBenefits,
  handleCheck,
  icon,
  iconColor,
  id,
  isCustom,
  name,
  setBenefitIds,
  setBenefitList,
  setCompanyBenefits,
  setEditBenefit,
  setLoading
}) => {
  const checkboxChange = () => {
    handleCheck(id);
  };
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const displayBenefits = async benefitId =>
    getBenefits({
      companyId
    }).then(res => {
      const benefitList = res.data.data;
      setBenefitList(benefitList);
      setCompanyBenefits(companyBenefits.filter(it => it._id !== benefitId));
      setLoading(false);
    });

  const handleDelete = () => {
    setLoading(true);
    deleteBenefit(id).then(res => {
      displayBenefits(res.data.data).then(() => setBenefitIds(ids => ids.filter(it => it !== id)));
    });
  };

  const handleUpdate = () => {
    setEditBenefit({
      id: `${id}`,
      name: `${name}`,
      icon: `${icon}`,
      iconColor: `${iconColor}`
    });
  };

  return (
    <>
      <StyledBenefitSingleItem>
        <Icon icon={icon} color={iconColor} size={24} />
        <div>{name}</div>
      </StyledBenefitSingleItem>
      {isCustom === false && (
        <FormCheckbox
          id={id}
          checked={benefitIds && benefitIds.includes(id)}
          onChange={handleCheck && checkboxChange}
        />
      )}
      {isCustom === true && (
        <StyledBenefitSingleItem>
          <ContainedButton
            icon="edit"
            opens="add-benefit-modal"
            type="button"
            iconColor="#FFFFFF;"
            backgroundColor="#CCD4FF1C"
            color="rgba(204, 213, 255, 0.11);"
            onClick={handleUpdate}
          />
          <ContainedButton
            icon="delete"
            type="button"
            iconColor="#FF5151;"
            backgroundColor="transparent"
            color="rgba(204, 213, 255, 0.11);"
            onClick={handleDelete}
          />
        </StyledBenefitSingleItem>
      )}
    </>
  );
};

export default BenefitItem;
