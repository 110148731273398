import React from 'react';

import Icon from 'common/Icon';
import ContainedButton from 'common/ContainedButton';
import {
  StyledBenefitContainer,
  StyledBenefitFramesContainer,
  StyledDescription,
  StyledLifeFrameContentContainer
} from './StyledAddFirstBenefitFrame';

const AddFirstBenefitFrame = () => (
  <StyledBenefitFramesContainer>
    <StyledBenefitContainer>
      <StyledLifeFrameContentContainer>
        <Icon icon="benefit" size={64} />
        <StyledDescription>
          {`“What we offer” lists all the benefits that an employee gets in your company such as visa sponsorship,
          wellness & volunteer programs, happy office hours, etc. `}
          <a href="#"> See examples</a>
          {` to get inspired. Don’t
          feel you need it now? No worries, you can fill it out later.`}
        </StyledDescription>
        <ContainedButton
          size={12}
          opens="benefit-modal"
          icon="plus"
          radius="6px"
          backgroundColor="hsla(229.41176470588238, 100%, 90%, 0.11)"
          padding="8px 10px"
          forceDisplayText
        >
          Add Benefits
        </ContainedButton>
      </StyledLifeFrameContentContainer>
    </StyledBenefitContainer>
  </StyledBenefitFramesContainer>
);

export default AddFirstBenefitFrame;
