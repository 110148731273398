import React, { useState } from 'react';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import useProfileOnBoarding from 'hooks/useProfileOnBoarding';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';
import Spinner from 'common/Spinner';
import get from 'lodash/fp/get';

const SubmitProfileModal = ({ closePortal, name }) => {
  const { lockProfile } = useProfileOnBoarding();
  const { openErrorSnackbar } = useErrorSnackbar();
  const [loading, setLoading] = useState(false);

  const handleSubmitButton = async () => {
    try {
      setLoading(true);
      await lockProfile();
      setLoading(false);
      closePortal();
    } catch (err) {
      const message = get('response.data.error', err);
      openErrorSnackbar(message ?? 'Oops! Something went wrong.');
      setLoading(false);
    }
  };

  return (
    <TxplModal
      name={name}
      title="Submit profile"
      appElement={document.getElementById('root-modal')}
      hideBorder
      titleMB={24}
      maxWidth={520}
      size="small"
      padding="40px 0"
      renderFooter={
        <>
          {loading && <Spinner />}
          <ContainedButton mr="6" ml="6" backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton form="submitProfileModal" type="submit" onClick={handleSubmitButton} disabled={loading}>
            Yes, let’s go!
          </ContainedButton>
        </>
      }
    >
      <Typography fontSize="17px" lineHeight="28px" color="rgba(230, 234, 255, 0.6)">
        Is everything looking good? Ready to submit?
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('submit-profile-modal')(SubmitProfileModal);
