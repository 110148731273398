import Api from './api';

const api = new Api('applied-jobs');

const applyToJob = (talentId, jobId) => api.post(`/${talentId}/${jobId}`);
const withdrawJob = (talentId, jobId) => api.post(`/${talentId}/${jobId}/withdraw`);

export default {
  applyToJob,
  withdrawJob
};
