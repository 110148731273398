import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { api } from 'api';
import { apiCallActions, apiCallSelectors } from 'store/apiCall';

const useAPICall = ({
  name,
  url,
  getCompoundUrl,
  method = 'get',
  transformData = () => {},
  persist = false,
  selectors = {},
  optimisticUpdater,
  onSuccess,
  onFailure
}) => {
  const requestInfo = useSelector(apiCallSelectors.selectApiCall(name)) || {};
  const apiParams = useSelector(createStructuredSelector(selectors));
  const dispatch = useDispatch();

  const callApi = React.useCallback(
    (additionalParams = {}) => {
      dispatch(apiCallActions.sendRequest({ name }));

      api[method](url || getCompoundUrl(), { ...apiParams, ...additionalParams })
        .then(response => {
          if (!response.data.success) throw new Error(response.data.message);
          const data = transformData(response.data);
          dispatch(apiCallActions.sendRequestSuccess({ name, data, persist }));

          if (optimisticUpdater) dispatch(optimisticUpdater(data));
          if (onSuccess) onSuccess(data);
        })
        .catch(error => {
          dispatch(apiCallActions.sendRequestFailure({ name, error, persist }));
          if (onFailure) onFailure(error);
        });
    },
    [
      apiParams,
      dispatch,
      getCompoundUrl,
      method,
      name,
      onFailure,
      onSuccess,
      optimisticUpdater,
      persist,
      transformData,
      url
    ]
  );

  return {
    loading: requestInfo.loading,
    data: requestInfo.data,
    callApi
  };
};

export default useAPICall;
