import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import useAPICall from 'hooks/useAPICall';
import commonStyles from 'utils/common-styles';

import TxplModal from 'common/TxplModal';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { profileActions, profileSelectors } from 'store/profile';

const StyledFooterContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const StyledDescription = styled.p`
  ${commonStyles.ui_text_medium}
  margin: 0px;
`;

const DeleteExperienceModal = ({ closePortal, data, name }) => {
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const { callApi, loading } = useAPICall({
    name: 'delete-experience',
    transformData: response => response.data,
    optimisticUpdater: experienceId =>
      profileActions.updateProfileProperty({
        updateType: 'delete',
        value: experienceId,
        property: 'experience',
        profileId
      }),
    url: `/experience/${profileId}/experience/${data.experienceId}`,
    onSuccess: () => {
      closePortal();
      data.onSuccess();
    },
    method: 'delete'
  });

  return (
    <TxplModal
      title="Delete this experience?"
      name={name}
      appElement={document.getElementById('root-modal')}
      size="small"
      padding="55px 0 40px 0"
      hideBorder
      renderFooter={
        <StyledFooterContainer>
          {loading && <Spinner />}
          <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton backgroundColor="#FF5151" onClick={() => callApi()}>
            Delete
          </ContainedButton>
        </StyledFooterContainer>
      }
    >
      <StyledDescription>Are you sure you want to delete this experience? This cannot be undone.</StyledDescription>
    </TxplModal>
  );
};

export default withRenderPortal('delete-experience-modal')(DeleteExperienceModal);
