import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import utils from 'utils';

import Icon from 'common/Icon';
import EditButton from 'common/EditButton';

const Wrapper = styled.div`
  position: relative;
  border: none;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.background};
  ${props => (props.background === 'transparent' ? 'border: 2px solid rgba(204, 213, 255, 0.11);' : '')}
  ${props => props.isPlaceHolder && 'background: transparent;'}
  ${props => props.isPlaceHolder && 'border: 2px dashed rgba(204, 213, 255, 0.11);'}
`;
const ExperienceIcon = styled(Icon)``;
const ExperianceValue = styled.div`
  ${commonStyles.ui_heading_1}
  min-height: 40px;
  margin-top: 4px;
  font-weight: 700;
  font-size: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ExperienceText = styled.div`
  ${commonStyles.ui_text_small}
  ${commonStyles.line_limit_3}
  margin-top: 4px;
  font-weight: 400;
`;
const PerDeliverableButton = styled.button`
  ${commonStyles.caption}
  margin: 12px 0 5px 0;
  padding: 8px 10px;
  white-space: nowrap;
  cursor: pointer;
  background: rgba(204, 213, 255, 0.11);
  border: none;
  border-radius: 6px;
`;
const experienceData = {
  years: {
    icon: 'years-of-experience',
    background: 'linear-gradient(180deg, #C600B2 0%, #FF007A 100%)',
    text: 'years of experience',
    addText: 'Add years of experience'
  },
  games: {
    icon: 'games-shipped',
    background: 'linear-gradient(180deg, #5700C6 0%, #0094FF 100%)',
    text: 'games shipped',
    addText: 'Add games shipped'
  },
  aaaGames: {
    icon: 'triple-a',
    background: 'linear-gradient(0deg, #FF7A00 0%, #C40000 100%)',
    text: 'AAA games shipped',
    addText: 'Add AAA games shipped'
  },
  aaGames: {
    icon: 'double-a',
    background: 'linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%);',
    text: 'AA games shipped',
    addText: 'Add AA games shipped'
  },
  indieGames: {
    icon: 'triple-i',
    background: 'linear-gradient(0deg, #00E941 0%, #027800 100%)',
    text: 'Indies shipped',
    addText: 'Add Indies shipped'
  },
  mobileGames: {
    icon: 'mobile',
    background: 'linear-gradient(180deg, #DB0035 0%, #FF4869 100%);',
    text: 'Mobile shipped',
    addText: 'Add Mobile shipped'
  },
  perHour: {
    icon: 'per-hour',
    background: 'transparent',
    text: 'per hour',
    addText: 'Add rate per hour'
  },
  perDeliverable: {
    icon: 'per-deliverable',
    background: 'transparent',
    text: 'per deliverable',
    addText: 'Add rate per deliverable'
  }
};

const getExperienceData = type => experienceData[type];

const StyledEditButton = styled(EditButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const AboutExperienceItem = ({ canEdit, hideValue, isAddMode, onEdit, text, type, value }) => {
  const experience = getExperienceData(type);

  const typeIsPrice = ['perHour', 'perDeliverable'].includes(type);
  const isPlaceHolder = canEdit && (!value || value === '0');
  const roundedValue = utils.roundToK(value);
  const valueToDisplay = canEdit && roundedValue === '0' ? '' : roundedValue;

  const displayText = React.useMemo(() => {
    if (isAddMode) return experience.addText;

    if (!text) return experience.text;

    const startsWithPer = text.trim().toLowerCase().startsWith('per ');

    if (!startsWithPer) return `per ${text}`;

    return text;
  }, [experience.text, text, isAddMode, experience.addText]);

  if (!experience) return;

  if (valueToDisplay === 0 && !canEdit) return null;

  return (
    <Wrapper background={experience.background} isPlaceHolder={isPlaceHolder}>
      {isPlaceHolder && <StyledEditButton iconName="plus" onClick={onEdit} />}
      <ExperienceIcon icon={experience.icon} size="40px" />
      {hideValue && <PerDeliverableButton>Talk to me</PerDeliverableButton>}
      {!hideValue && (
        <ExperianceValue>
          {typeIsPrice && !isAddMode ? '$' : ''}
          {valueToDisplay}
        </ExperianceValue>
      )}
      <ExperienceText>{displayText}</ExperienceText>
    </Wrapper>
  );
};

export default AboutExperienceItem;
