import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { currentUserIdSelector } from 'store/selectors/currentUser';
import { companyProfileService, talentService } from 'api';
import PageLoader from 'common/PageLoader';
import useProfilePath from 'hooks/useProfilePath';
import { profileActions, profileSelectors } from 'store/profile';
import { authActions, authSelectors } from 'store/auth';

const Profile = props => {
  const {
    match: {
      params: { userId }
    }
  } = props;
  const [publicUrlNotExists, SetPublicUrlNotExists] = useState(false);
  const currentUserId = useSelector(currentUserIdSelector);
  const { getProfilePath } = useProfilePath();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthLoading = useSelector(authSelectors.selectIsAuthLoading);
  const isAuth = useSelector(authSelectors.isAuthed);
  const activeProfileId = useSelector(profileSelectors.selectMyProfileId);
  const isCompany = useSelector(profileSelectors.selectIsMyProfileCompany);

  useEffect(() => {
    if (!isAuthLoading && (userId || currentUserId)) {
      const companyProfile = activeProfileId ? isCompany : false;
      const response = companyProfile
        ? companyProfileService.getCompany(activeProfileId)
        : talentService.getTalentByUserId(userId || currentUserId);

      response.then(({ data }) => {
        const publicUrl = data?.publicUrl ?? data?.data?.publicUrl;
        const profileId = data?._id ?? data?.data?._id;

        if (publicUrl) {
          dispatch(profileActions.setActiveProfileId(profileId));
          if (!activeProfileId) {
            dispatch(
              authActions.updateCurrentUserProperty({
                property: 'activeProfile',
                value: {
                  profile: profileId,
                  profileModel: 'Talent'
                }
              })
            );
          }
          history.push(getProfilePath(publicUrl, companyProfile));
        } else {
          SetPublicUrlNotExists(true);
        }
      });
    }
    if (!isAuthLoading && !isAuth) {
      localStorage.removeItem('token');
      history.push('/login');
    }
  }, [userId, isAuthLoading, currentUserId, history]);

  return <PageLoader message={publicUrlNotExists && `Public URL not found for ${userId || currentUserId}`} />;
};

export default Profile;
