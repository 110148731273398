import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { compose, filter, head, map, prop, propEq, propOr } from 'ramda';

const useCompanyRole = () => {
  const currentProfile = useSelector(profileSelectors.selectMyProfile);
  const myProfileList = useSelector(profileSelectors.selectMyProfileList);
  const profileEditorId = compose(prop('userId'), head)(myProfileList);

  const checkMember = propEq('role', 'member');
  const memberIds = compose(map(prop('user')), filter(checkMember), propOr([], 'members'))(currentProfile);
  const isMember = memberIds.includes(profileEditorId);

  return {
    isMember,
    memberIds,
    profileEditorId
  };
};

export default useCompanyRole;
