import CropModal from 'common/CropModal/CropModal';
import PageHeader from 'common/PageHeader';
import AutoLogin from 'components/AutoLogin';
import AutoLogout from 'components/AutoLogout';
import ConfirmModal from 'components/modals/ConfirmModal';
import TalkSession from 'components/TalkSession';
import TalentPageLayout from 'layouts/TalentPageLayout';
import AcceptInvitation from 'pages/AcceptInvitation';
import Backoffice from 'pages/backoffice/Backoffice';
import HelpModal from 'pages/backoffice/HelpModal';
import CommunityLayout from 'pages/community/CommunityLayout';
import ContactSupport from 'pages/ContactSupport';
import { ConversationsLayout } from 'pages/conversations';
import DeleteAccountSuccess from 'pages/DeleteAccountSuccess';
import Home from 'pages/home/Home';
import JobsPage from 'pages/jobs/details/JobsPage';
import JobSearch from 'pages/jobs/search/JobSearch';
import LoginModal from 'pages/LoginModal';
import Logout from 'pages/Logout';
import Oauth from 'pages/Oauth';
import OnBoarding from 'pages/OnBoarding/OnBoarding';
import Profile from 'pages/Profile';
import AccountSuspended from 'pages/service-page/AccountSuspended';
import Page404 from 'pages/service-page/Page404';
import ServerOutage from 'pages/service-page/ServerOutage';
import Settings from 'pages/settings/settings';
import SplashPage from 'pages/splash-page/SplashPage';
import TalentsSearch from 'pages/talent/search/TalentsSearch';
import TeamUsers from 'pages/TeamUsers';
import TOS from 'pages/TOS';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import Socket from 'socket';
import { selectFailedServerCalls } from 'store/apiCall/apiCallSelectors';
import { portalActions, portalSelectors } from 'store/portals';
import { isJSON } from 'utils';
import ProtectedRoute from './ProtectedRoute';
import Route from './Route';
import LoadingModal from 'components/modals/LoadingModal';
import TermsServiceModal from 'common/TermsServiceModal';
import AutoCheckTermsService from 'components/AutoCheckTermsService';
import { cookieAuthName, getCookie } from 'utils/cookies';
import RedirectApp2Route from './RedirectApp2Route';

function Routes() {
  const dispatch = useDispatch();
  const isTermsOfServiceModalOpened = useSelector(portalSelectors.isPortalOpenedSelector('terms-of-service-modal'));

  const token = getCookie(cookieAuthName);

  const serverErrors = useSelector(selectFailedServerCalls);
  const urls = Object.keys(serverErrors);

  const shouldRedirectAuth = !process.env.REACT_APP_BASE_URL.includes('localhost');

  const getUserConfirmation = (message, callback) => {
    let data = {
      onOk: () => callback(true),
      onCancel: () => callback(false)
    };

    if (isJSON(message)) {
      const content = JSON.parse(message);

      data = {
        ...data,
        ...content
      };
    } else {
      data = {
        ...data,
        message
      };
    }

    dispatch(
      portalActions.openPortal({
        name: 'leave-page-modal',
        data
      })
    );
  };

  useEffect(
    () => () => {
      if (!isTermsOfServiceModalOpened) {
        dispatch(portalActions.clearPortal());
      }
    },
    [window.location.pathname]
  );

  useEffect(() => {
    Socket.disconnect();
    if (token) {
      Socket.connect();
    }
  }, [token]);

  return (
    <Router basename="/app" getUserConfirmation={getUserConfirmation}>
      <AutoLogin />
      <AutoLogout />
      <AutoCheckTermsService />
      <PageHeader />
      <TalkSession />
      <div>
        <Switch>
          <Route path={urls} component={ServerOutage} />
          <Route exact path="/" component={SplashPage} />
          {shouldRedirectAuth && (
            <Route exact path="/login" component={() => <RedirectApp2Route path="auth/login" />} />
          )}
          {shouldRedirectAuth && (
            <Route exact path="/forgot-password" component={() => <RedirectApp2Route path="auth/forgot-password" />} />
          )}
          {shouldRedirectAuth && (
            <Route exact path="/reset-password" component={() => <RedirectApp2Route path="auth/reset-password" />} />
          )}

          {!shouldRedirectAuth && <Route exact path="/login" component={SplashPage} />}
          {!shouldRedirectAuth && <Route exact path="/forgot-password" component={SplashPage} />}
          {!shouldRedirectAuth && <Route exact path="/reset-password" component={SplashPage} />}

          <Route exact path="/logout" component={Logout} />
          <Route exact path="/home" component={Home} />
          <Route padLayout exact path="/profile" component={Profile} />
          <Route padLayout exact path="/profile/:userId" component={Profile} />
          <ProtectedRoute roles={['super-admin']} path="/backoffice" component={Backoffice} />
          <Route padLayout path="/settings" component={Settings} />
          <Route exact path="/privacy" component={TOS} />
          <Route exact path="/terms" component={TOS} />
          <Route exact path="/tos" component={TOS} />
          <Route path="/activate-account" component={AcceptInvitation} />
          <Route path="/accept-invitation" component={AcceptInvitation} />
          <Route exact path="/auth/:type" component={Oauth} />
          <Route exact path="/jobs/search" component={JobSearch} />
          <Route exact path="/peoples/search" component={TalentsSearch} />
          <Redirect exact from="/talent/:talentId" to="/talent/:talentId/about" />
          <Redirect exact from="/talent/:talentId/life" to="/talent/:talentId/about" />
          <Route padLayout path="/talent/:talentId/portfolio/:portfolioId" component={TalentPageLayout} />
          <Route exact path="/talent/:talentId/all-jobs" component={JobsPage} />
          <Route exact path="/talent/:talentId/my-jobs" component={JobsPage} />
          <Route exact path="/talent/:talentId/jobs-i-posted" component={JobsPage} />
          <Route exact path="/talent/:talentId/jobs/details" component={JobsPage} />
          <Route padLayout path="/talent/:talentId/jobs/:jobId" component={TalentPageLayout} />
          <Route exact path="/talent/:talentId/conversations" component={ConversationsLayout} />
          <Route
            exact
            path={['/talent/:talentId/community', '/company/:talentId/community']}
            component={CommunityLayout}
          />
          <Route padLayout path="/talent/:talentId" component={TalentPageLayout} />
          <Redirect exact from="/company/:talentId" to="/company/:talentId/about" />
          <Route padLayout path="/company/:talentId/portfolio/:portfolioId" component={TalentPageLayout} />
          <Route exact path="/company/:talentId/all-jobs" component={JobsPage} />
          <Route exact path="/company/:talentId/my-jobs" component={JobsPage} />
          <Route exact path="/company/:talentId/jobs-i-posted" component={JobsPage} />
          <Route exact path="/company/:talentId/jobs/details" component={JobsPage} />
          <Route padLayout path="/company/:talentId/jobs/:jobId" component={TalentPageLayout} />
          <Route exact path="/company/:talentId/conversations" component={ConversationsLayout} />
          <Route padLayout path="/company/:talentId" component={TalentPageLayout} />
          <Route path="/team/users" component={TeamUsers} />
          <Route path="/contact-support" component={ContactSupport} />
          <Route path="/account-deleted" component={DeleteAccountSuccess} />
          <Route path="/account-suspended" component={AccountSuspended} />
          <Route exact path="/splash-page" component={SplashPage} />
          <Route path="/onboarding" component={OnBoarding} />
          <Route
            path="/terms-of-service"
            component={() => {
              window.location.href = `${process.env.REACT_APP_LANDING_BASE_URL}/terms-of-service/`;
            }}
          />
          <Route
            path="/privacy-policy"
            component={() => {
              window.location.href = `${process.env.REACT_APP_LANDING_BASE_URL}/privacy-policy/`;
            }}
          />
          <Route
            path="/website"
            component={() => {
              window.location.href = `${process.env.REACT_APP_LANDING_BASE_URL}`;
            }}
          />
          <Route path="*" component={Page404} />
        </Switch>
      </div>

      <Route path="/" component={LoginModal} />
      <ConfirmModal />
      <LoadingModal />
      <CropModal />
      <HelpModal />
      <TermsServiceModal />
    </Router>
  );
}

export default Routes;
