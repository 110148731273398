import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import IndustryIcons from 'common/IndustryIcon/IndustryIcon';
import LockButton from 'common/lockUnlockButtons/LockButton';
import UnlockPendingButton from 'common/lockUnlockButtons/UnlockPendingButton';
import VettingStatus from 'common/VettingStatus';
import useProfileUnlock from 'hooks/api/useProfileUnlockService';
import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import commonStyles from 'utils/common-styles';
import ProjectNameModal from './ProjectNameModal';

const StyledProjectNameContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding-right: 10px;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const StyledProjectName = styled.h1`
  ${commonStyles.heading_1}
  margin: 0px;
`;
const StyledButton = styled(ContainedButton)`
  position: relative;
  top: 4px;
`;
const StyledSpacer = styled.div`
  height: 2px;
  margin-bottom: 40px;
  background-color: rgba(204, 213, 255, 0.11);
  width: 100%;
`;
const TitleWrapper = styled.div`
  display: flex;
`;
const IndustryIconsWrapper = styled.span`
  display: inline-flex;
`;

const ProjectName = ({
  addMode,
  className,
  error,
  hasProfileEditPermission,
  hideSpacer,
  id,
  industryType,
  loading,
  name,
  onSave,
  project,
  status
}) => {
  const talentId = useSelector(profileSelectors.selectMyProfileId);

  const { addProfileUnlockRequest } = useProfileUnlock();

  const handleLockBtnClick = e => {
    e.stopPropagation();
    addProfileUnlockRequest({ entityId: project._id, entityType: 'project', talentId });
  };

  if (name) {
    return (
      <>
        <StyledProjectNameContainer>
          <TitleWrapper>
            <StyledProjectName>
              {!addMode && (
                <IndustryIconsWrapper>
                  <IndustryIcons
                    iconHeight={'44px'}
                    iconWidth={'44px'}
                    iconMinWidth={'44px'}
                    name={industryType?.name}
                  />
                </IndustryIconsWrapper>
              )}
              <span>{name}</span>
              {!!status && status === 'published' && (
                <VettingStatus id={project._id} vetted={project.vetted} size={30} hideText />
              )}
            </StyledProjectName>
          </TitleWrapper>
          {hasProfileEditPermission && (
            <>
              {project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'LOCKED' ? (
                <LockButton onClick={handleLockBtnClick} tooltip="To request edit permissions, click here" />
              ) : project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'UNLOCK_REQUESTED' ? (
                <UnlockPendingButton tooltip="Edit request pending" />
              ) : (
                <StyledButton
                  backgroundColor="rgba(204, 213, 255, 0.11)"
                  icon="edit"
                  data={{ name, notClosePortal: !addMode }}
                  opens="project-name-modal"
                  type="button"
                />
              )}
            </>
          )}
        </StyledProjectNameContainer>

        {!hideSpacer && <StyledSpacer />}

        <ProjectNameModal onSave={onSave} loading={loading} />
      </>
    );
  }

  return (
    <>
      <Frame
        text="PROJECT NAME"
        transparent
        error={error}
        required
        noDataText="What makes this project stand out? Give it a good ol` Hollywood headline."
        editMode="add"
        canEdit={hasProfileEditPermission}
        data={{ name, notClosePortal: !addMode }}
        opens="project-name-modal"
        id={id}
        className={className}
      />

      <ProjectNameModal onSave={onSave} />
    </>
  );
};

export default ProjectName;
