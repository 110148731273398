import React, { useState } from 'react';
import styled from '@emotion/styled';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import Typography from 'components/shared/Typography';
import useForm from 'hooks/useForm';
import jobService from '../../../../../api/jobService';
import FormTextarea from '../../../../../common/form/FormTextarea';
import { applicantsActions } from '../../../../../store/applicants';
import { useDispatch } from 'react-redux';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;

const CancelContractModal = ({ closePortal, data, name }) => {
  const [description, setDescription] = useState(false);
  const dispatch = useDispatch();

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: {
      description: { initialValue: '', validate: ['isRequired'] }
    },
    callApi: async formFields => jobService.cancelContract(data?.contractId, formFields.description),
    onSuccess: async () => {
      dispatch(
        applicantsActions.updateApplicantContractStatus({
          jobId: data?.jobId,
          applicantId: data?.applicantId,
          contractStatus: 'cancelled'
        })
      );
      setDescription(true);
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <TxplModal
      title="Cancel Proposal"
      name={name}
      size="small"
      renderFooter={
        <>
          {submitting && <Spinner />}

          {!description && (
            <>
              <StyledContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
                Cancel
              </StyledContainedButton>
              <StyledContainedButton
                form="cancelContractModal"
                type="submit"
                backgroundColor="#7266EE"
                disabled={submitting}
              >
                OK
              </StyledContainedButton>
            </>
          )}

          {description && (
            <>
              <StyledContainedButton onClick={closePortal} backgroundColor="#7266EE" forceDisplayText>
                OK
              </StyledContainedButton>
            </>
          )}
        </>
      }
    >
      {description && (
        <Typography success variant="medium">
          Proposal canceled
        </Typography>
      )}

      {!description && (
        <form onSubmit={handleSubmit} id="cancelContractModal" disabled>
          <FormTextarea
            label="Description"
            required
            hintText="Include the reason for canceling the proposal"
            maxLength={600}
            value={values.description}
            onChange={e => onFieldChange('description')(e.target.value)}
            error={errors.description}
            mb={56}
            boldWeight
            lightWeight
            id="description"
          />
        </form>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('cancel-contract-modal')(CancelContractModal);
