import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { portalActions } from 'store/portals';
import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';
import useViewport from 'hooks/useViewport';
import useProfilePath from 'hooks/useProfilePath';
import Typography from 'components/shared/Typography';
import HiddenAvatar from 'components/HiddenAvatar/HiddenAvatar';

import { Card } from 'common/styles/Card';
import TagItem from 'common/Tag/Tag';
import Flex from 'common/styles/Flex';
import Icon from 'common/Icon';
import ContainedButton from 'common/ContainedButton';
import TalentCardDropDown from './TalentCardDropDown';
import UserTopProjects from 'common/User/UserTopProjects';
import UserTopCredits from 'common/User/UserTopCredits';
import UserAtAGlance from 'common/User/UserAtAGlance';
import UserTags from 'common/User/UserTags';
import UserDisciplines from 'common/User/UserDisciplines';
import MoreLanguages from 'common/MoreLanguages';

import { Spacer } from '../Credits/EditCreditModalStyles';
import useStartConversation from 'hooks/chatApi/useStartConversation';

import { selectMyProfile } from 'store/profile/profileSelectors';

const TalentInfo = styled(Flex)`
  position: relative;
  @media ${pageSize.XS}, ${pageSize.S} {
    flex-flow: column;
  }
`;
const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    width: 64px;
    height: 64px;
  }
`;
const TalentName = styled(Link)`
  ${commonStyles.ui_heading_2}
  text-decoration: none;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
`;
const TalentDescription = styled.div`
  display: flex;
  align-items: center;
  ${commonStyles.caption}
  color: rgba(230, 234, 255, 0.35);
`;
const AbsoluteButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({ gap }) => gap && `gap: ${gap}px;`}

  @media ${pageSize.XS}, ${pageSize.S} {
    grid-template-columns: 1fr;
  }
`;
const FlexSpacer = styled(Flex)`
  @media ${pageSize.M}, ${pageSize.L} {
    border-right: 2px solid rgba(204, 213, 255, 0.11);
    padding-right: 32px;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    padding-bottom: 32px;
  }
`;
const TagsWrapper = styled(Flex)`
  @media ${pageSize.M}, ${pageSize.L} {
    padding-left: 32px;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    padding-top: 32px;
  }
`;

function TalentCard({ talent = {}, isListView, isExtended = false, biasReducedMode = false }) {
  const {
    aaaGames,
    additionalName,
    atGlanceYears,
    credit,
    discipline,
    firstName,
    gamesShipped,
    genre,
    img,
    indieGames,
    language,
    lastName,
    location,
    name,
    projects,
    role,
    skill,
    tag,
    theX,
    topOverall,
    topPortfolio,
    type,
    url,
    useAdditionalName
  } = talent;

  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;
  const { quickOnboardingCompleted, onboardingCompleted } = useSelector(selectMyProfile);

  const isProfileCompleted = quickOnboardingCompleted || onboardingCompleted;

  const [dataHidden, setDataHidden] = useState(type === 'talent' ? biasReducedMode : false);
  useEffect(() => {
    setDataHidden(biasReducedMode);
  }, [setDataHidden, biasReducedMode]);

  const { getProfilePath } = useProfilePath();
  const { redirectToStartConversation } = useStartConversation();
  const dispatch = useDispatch();

  const { isXS } = useViewport();
  const [isOpen, setIsOpen] = useState(isExtended);

  const extendedView = isOpen || (!isListView && !isXS);

  const openTalentCardModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'talent-card-modal',
        data: {
          talent,
          biasReducedMode: dataHidden
        }
      })
    );
  };

  const closeTalentCardModal = () => {
    dispatch(
      portalActions.closePortal({
        name: 'talent-card-modal'
      })
    );
  };

  const getTalentName = () => {
    let sname = firstName && lastName ? `${firstName} ${lastName}` : name;
    if (useAdditionalName && additionalName?.length && !biasReducedMode) {
      sname += ` (${additionalName})`;
    }

    if (dataHidden) {
      const words = sname.split(' ');
      let result = '';
      words.forEach(word => (result += word[0] + '. '));
      return result;
    }

    return sname;
  };

  const changeMode = React.useCallback(() => setDataHidden(latest => !latest), [setDataHidden]);

  return (
    <Card>
      {(topOverall || topOverall) && (
        <>
          <Flex gap={6}>
            {topOverall && (
              <TagItem icon="about" backgroundColor="#00FF00" color="#111112">
                Top overall match
              </TagItem>
            )}
            {topPortfolio && (
              <TagItem icon="portfolio" backgroundColor="#00FF00" color="#111112">
                Top portfolio match
              </TagItem>
            )}
          </Flex>
          <Spacer margin="24px 0" />
        </>
      )}
      <TalentInfo gap={24}>
        <AbsoluteButtonWrapper>
          <Flex gap={6}>
            {isListView && !isOpen && (
              <ContainedButton className="transparent" icon="zoom-in" onClick={openTalentCardModal} />
            )}

            <TalentCardDropDown talent={talent} biasReducedMode={biasReducedMode} />
            <ContainedButton
              icon="message"
              disabled={!isProfileCompleted}
              backgroundColor="secondary"
              onClick={() => redirectToStartConversation(talent)}
            >
              Message
            </ContainedButton>
            {isListView && isOpen && (
              <ContainedButton className="transparent" icon="close" onClick={closeTalentCardModal} />
            )}
          </Flex>
        </AbsoluteButtonWrapper>
        <div>
          {!dataHidden && <Avatar src={img ?? defaultAvatar} onClick={changeMode} />}
          {dataHidden && <HiddenAvatar changeMode={changeMode} credit={credit} />}
        </div>
        <Flex column gap={16}>
          <Flex column gap={10}>
            <TalentName
              to={`${getProfilePath(url, type !== 'talent')}/about?bios-reduced=${biasReducedMode}`}
              target="_blank"
            >
              {getTalentName()}
            </TalentName>
            {theX && (
              <TalentDescription>
                <Icon mr={8} icon="the-x" color="rgba(230, 234, 255, 0.35)" hoverColor="rgba(230, 234, 255, 0.35)" />
                {theX}
              </TalentDescription>
            )}
          </Flex>

          {(!extendedView || isXS) && !!discipline?.length && (
            <>
              <UserDisciplines disciplines={discipline} />

              <UserTags tags={role} />
            </>
          )}

          {!!location && !!language && (
            <Flex className="wrap" gap={6}>
              {location && !dataHidden && <TagItem icon="location">{location}</TagItem>}
              {location && dataHidden && (
                <TagItem icon="location">
                  <span style={{ color: 'rgba(229, 234, 255, .35)' }}>Location hidden</span>
                </TagItem>
              )}
              {!!language.length && (
                <TagItem icon="language">
                  <MoreLanguages languages={language} initialLength={2} />
                </TagItem>
              )}
            </Flex>
          )}
        </Flex>
      </TalentInfo>

      {extendedView && (
        <>
          <Spacer />
          <Grid>
            <FlexSpacer column gap={24}>
              <UserTopProjects projects={projects} limit={2} />

              <Grid gap={24}>
                <UserTopCredits credits={credit} limit={3} />
                <UserAtAGlance
                  gap={16}
                  experience={atGlanceYears}
                  gamesShipped={gamesShipped}
                  aaaGames={aaaGames}
                  indieGames={indieGames}
                />
              </Grid>
            </FlexSpacer>
            <TagsWrapper column gap={32}>
              <Flex column gap={16}>
                <Typography variant="caption" color="rgba(230, 234, 255, 0.35)">
                  Disciplines and roles
                </Typography>
                <Flex column gap={6}>
                  <UserDisciplines disciplines={discipline} />
                  <UserTags tags={role} />
                </Flex>
              </Flex>
              <UserTags title="Genres" tags={genre} />
              <UserTags title="Skills" tags={skill} />
              <UserTags title="Tags" tags={tag} />
            </TagsWrapper>
          </Grid>
        </>
      )}

      {isXS && !isListView && (
        <Flex pt={24}>
          <ContainedButton
            className="stretched"
            height="auto"
            padding="8px 12px"
            backgroundColor="rgba(204, 213, 255, 0.11)"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? 'See less' : 'See more'}
          </ContainedButton>
        </Flex>
      )}
    </Card>
  );
}

export default TalentCard;
