import conversationService from 'api/conversationService';
import chatConversationsService from 'chatApi/chatConversationsService';
import keys from 'lodash/fp/keys';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import useChatUserService from 'hooks/chatApi/useChatUser';
import { generateConversationId } from 'utils/generateConversationId';

const useChatConversationService = () => {
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const activeProfile = useSelector(profileSelectors.selectMyProfile);
  const conversations = useSelector(profileSelectors.selectMyProfileProperty('conversations'));

  const { createUser } = useChatUserService();

  const [loading, setLoading] = useState({ addMessage: false, getMessages: false, createConversation: false });

  const addMessage = async (conversationId, data) => {
    try {
      setLoading({ ...loading, addMessage: true });
      await chatConversationsService.addMessage(conversationId, data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading({ ...loading, addMessage: false });
    }
  };

  const getMessages = async (conversationId, startingAfter = '', limit = 20, onEnd = () => ({})) => {
    try {
      setLoading({ ...loading, getMessages: true });
      const res = await chatConversationsService.getMessages(conversationId, startingAfter, limit);
      if (res.data.data.length === 0) {
        onEnd();
      }
      return res.data.data ?? [];
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading({ ...loading, getMessages: false });
    }
  };

  const getConversation = async conversationId => {
    try {
      const res = await chatConversationsService.getConversation(conversationId);
      return { conversation: res.data };
    } catch (err) {
      return { error: err.response.data.errorCode };
    }
  };

  const createConversation = async (conversationId, { contact, job = null }) => {
    try {
      setLoading({ ...loading, createConversation: true });
      const id = conversationId ?? generateConversationId('private', [profileId, contact.id || contact._id]);
      const { conversation, error } = await getConversation(id);

      if (error === 'CONVERSATION_NOT_FOUND') {
        const newConversation = {
          id,
          participants: [profileId, contact.id || contact._id],
          unreadMessages: 0,
          custom: {
            unreadMessages: 0,
            participants: {
              recipient: {
                id: contact.id || contact._id,
                name: contact.name,
                photoUrl: contact.photoUrl
              },
              me: {
                id: profileId,
                name: activeProfile.name,
                photoUrl: activeProfile.basicInfo?.imageUri?.url
              }
            },
            job
          },
          messages: [],
          typing: [],
          lastMessage: null
        };

        await createUser(contact.id || contact._id, { name: contact.name, photoUrl: contact.photoUrl });
        const data = {
          ...newConversation,
          custom: {
            unreadMessages: JSON.stringify(newConversation.custom.unreadMessages),
            job: JSON.stringify(newConversation.custom.job),
            participants: JSON.stringify(newConversation.custom.participants)
          }
        };
        await chatConversationsService.createConversation(id, data);

        const updated = {
          [id]: {
            ...newConversation,
            participants: {
              [newConversation.custom.participants.recipient.id]: newConversation.custom.participants.recipient,
              [newConversation.custom.participants.me.id]: newConversation.custom.participants.me
            }
          },
          ...conversations
        };

        dispatch(
          profileActions.updateProfileProperty({
            property: 'conversations',
            value: updated,
            profileId
          })
        );

        return { conversationId: id, conversation: newConversation };
      }

      return { conversationId: id, conversation };
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading({ ...loading, createConversation: false });
    }
  };

  const updateConversation = async (conversationId, contact, cb) => {
    try {
      await chatConversationsService.createConversation(conversationId, contact);

      if (cb) {
        cb();
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAttachments = async conversationId => {
    try {
      setLoading(true);
      const res = await chatConversationsService.getAttachments(conversationId);
      return res.data.data
        .filter(item => item.attachment)
        .map(item => ({
          ...item,
          custom: {
            file: JSON.parse(item.custom.file)
          }
        }));
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const markConversationAsRead = async (conversationId, userId) => {
    try {
      setLoading(true);
      const res = await chatConversationsService.markConversationAsRead(conversationId, userId);
      if (res.status === 200) {
        const conversation = conversations[conversationId];
        if (conversation?.lastMessage?.id) {
          dispatch(
            profileActions.updateProfileProperty({
              property: `conversations.${conversationId}.lastMessage.readBy`,
              value: [...conversation.lastMessage.readBy, userId],
              profileId
            })
          );
        } else {
          dispatch(
            profileActions.updateProfileProperty({
              property: `conversations.${conversationId}.lastMessage.readBy`,
              value: [...conversation.lastMessage.readBy, userId],
              profileId
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: `conversations.${conversationId}.lastMessage.read`,
              value: true,
              profileId
            })
          );
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const markConversation = useCallback(
    async ({ conversationId, messageIds, participantsProfileIds }) => {
      if (conversationId && participantsProfileIds && messageIds) {
        try {
          await markConversationAsRead(conversationId, profileId);
          await conversationService.markConversationAsRead(profileId, {
            conversationId,
            participantsProfileIds,
            messageIds
          });

          await updateConversation(conversationId, {
            custom: {
              unreadMessages: '0'
            }
          });
          dispatch(
            profileActions.updateProfileProperty({
              property: `conversations.${conversationId}.custom.unreadMessages`,
              value: 0,
              profileId
            })
          );
        } catch (err) {
          console.log('err', err);
        }
      }
    },
    [dispatch, profileId]
  );

  const typingMessage = async ({ conversationId, isTyping }) => {
    try {
      if (profileId && conversationId) {
        const conversation = conversations[conversationId];
        const typing = conversation.typing ?? [];
        const participantsProfileIds = keys(conversation.participants);

        await conversationService.typingMessage(profileId, {
          conversationId,
          participantsProfileIds,
          typing,
          isTyping
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    createConversation,
    updateConversation,
    getMessages,
    addMessage,
    getAttachments,
    markConversationAsRead,
    typingMessage,
    markConversation,
    loading
  };
};

export default useChatConversationService;
