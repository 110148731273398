import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import jobService from 'api/jobService';
import { uploadsService } from 'api';
import { portalActions, portalSelectors } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';
import useForm from 'hooks/useForm';
import useAppliedJobService from 'hooks/api/useAppliedJobService';
import useJobServices from 'hooks/api/useJobServices';
import { normalizeData, transformData } from './transformData';
import LeavePageModal from 'common/LeavePageModal/LeavePageModal';
import { AddJobContainer, JobContentGrid } from './styled.components';
import JobSidebar from '../JobSidebar';
import JobForm from '../JobForm';
import AddJobFooter from '../AddJob/AddJobFooter';
import JobApplicantsSidebar from '../JobSidebar/JobApplicantsSidebar';
import JobShareModal from '../JobSidebar/JobShareModal';
import Applicants from '../Applicants/Applicants';
import JobCloseModal from 'pages/jobs/details/JobCloseModal';
import TextModal from 'common/TextModal';
import RejectApplicationModal from '../JobModals/RejectApplicationModal/RejectApplicationModal';
import isString from 'lodash/fp/isString';
import get from 'lodash/fp/get';
import useProfilePath from 'hooks/useProfilePath';
import CompleteWithdrawJobModal from '../JobModals/jobConfirmationModal/JobWithdrawComplication/CompleteWithdrawJobModal';
import JobSuccessModal from '../JobModals/jobConfirmationModal/JobSuccessModal';
import JobWithDrawModal from 'pages/jobs/details/JobWithDrawModal';
import Prompt from 'common/Prompt';
import isEmpty from 'lodash/fp/isEmpty';
import size from 'lodash/fp/size';
import trim from 'lodash/fp/trim';
import CompleteProfileToApplyModal from 'common/CompleteProfileToApplyModal';
import ApplyAsSoloistModal from 'common/ApplyAsSoloistModal';
import useApplyToJob from 'hooks/useApplyToJob';
import useProfileSwitcher from 'hooks/useProfileSwitcher';
import UnpublishJobModal from '../JobModals/UnpublishJobModal';
import CancelContractModal from '../Applicants/modal/CancelContractModal';
import CompleteProfileModal from '../JobModals/CompleteProfileModal';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import { compose, pluck, propOr } from 'ramda';
import { authSelectors } from 'store/auth';
import useCompanyRole from 'hooks/useCompanyRole';

const fields = {
  title: { initialValue: '' },
  thumbnail: { initialValue: null },
  details: { initialValue: {} },
  overview: { initialValue: '' },
  projects: { initialValue: [] },
  youWill: { initialValue: '' },
  youHave: { initialValue: '' },
  bonus: { initialValue: '' },
  tools: { initialValue: [] },
  skills: { initialValue: [] },
  tags: { initialValue: [] },
  categories: { initialValue: [] }
};

const onPublishValidate = formFields => {
  const errors = {};
  const details = Object.fromEntries(formFields.getIn(['details', 'value']));

  if (!formFields.getIn(['title', 'value'])) errors.title = 'title is required';
  if (
    !details ||
    !details.disciplines?.size ||
    (details.jobType?.value === 'full time' && !details.salary) ||
    (details.jobType?.value === 'part time' && !details.hourlyRate) ||
    !details.jobType ||
    !details.language
  )
    errors.details = 'details is required';
  if (!formFields.getIn(['overview', 'value'])) errors.overview = 'overview is required';
  if (!formFields.getIn(['youWill', 'value'])) errors.youWill = 'youWill is required';
  if (!formFields.getIn(['youHave', 'value'])) errors.youHave = 'youHave is required';

  return errors;
};

const onDraftValidate = formFields => {
  const errors = {};

  if (!formFields.getIn(['title', 'value'])) errors.title = 'title is required';

  return errors;
};

const reduceProjects = (jobProjects, projects) =>
  (jobProjects ?? []).reduce((curr, project) => {
    let newProject = project;
    if (isString(project.coverImage)) {
      const { coverImage } = projects.find(it => it._id === project._id);

      newProject = {
        ...project,
        coverImage
      };
    }
    curr.push(newProject);
    return curr;
  }, []);

/**
 * Returns true if the job can be published based on the user funnel status, vetting status,
 * and type of profile (company or soloist)
 *
 * @param {string} funnelStatus
 * @param {string} vettingStatus
 * @param {string} vettingOutcome
 * @param {boolean} isCompany
 * @returns {boolean}
 */
const canBePublished = (funnelStatus, vettingStatus, isCompany) => {
  return (
    isCompany ||
    (funnelStatus?.status === 'VETTING_COMPLETE' &&
      vettingStatus?.status === 'COMPLETE' &&
      vettingStatus?.outcome === 'APPROVED')
  );
};

const shoudTriggerSaving = (dirty, redirectTo, errors, submitting) => {
  return dirty && !!size(trim(redirectTo)) && isEmpty(errors) && !submitting;
};

const getContractedTalents = compose(pluck('profile'), propOr([], 'contractedSupply'));

const AddJob = ({ hasEditPermission, job, jobId, loading, profileId, userId, viewMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isApplicantsRoute = pathname.includes('candidates');
  const isDescriptionsRoute = pathname.includes('description');
  const isJobIPosted = isApplicantsRoute || isDescriptionsRoute;
  const {
    loading: { loadingWithdraw }
  } = useAppliedJobService();
  const {
    loading: { loadingSave },
    saveJob
  } = useJobServices();
  const { switchProfile } = useProfileSwitcher();
  const favorites = get('favorites', useSelector(profileSelectors.selectMyProfile));
  const basicInfo = useSelector(profileSelectors.selectProfileProperty('basicInfo'));
  const currentUser = useSelector(authSelectors.selectCurrUser);
  const currentUserProfileId = currentUser?.activeProfile?.profile;
  const profileImageId = basicInfo?.imageUri?._id || '';
  const { myProfilePath } = useProfilePath();
  const [redirectTo, setRedirectTo] = useState('');
  const jobsIPostedPath = `${myProfilePath}/jobs-i-posted`;

  const { isMember, profileEditorId } = useCompanyRole();

  const [currentJob, setCurrentJob] = useState(null);
  const isClosed = job?.status === 'closed' || job?.status === 'draft';

  const isMyProfileComapny = useSelector(profileSelectors.selectIsMyProfileCompany);
  const myTalentProfile = useSelector(profileSelectors.selectMyTalentProfile);

  const funnelStatus = useSelector(currentUserPropertySelector('funnelStatus'));
  const vettingStatus = useSelector(profileSelectors.selectProfileProperty('vettingStatus'));

  const { applyProfileToJob, loadingApply } = useApplyToJob({
    applyToJobCallback: updatedJob => {
      setCurrentJob(updatedJob);
      if (!isMyProfileComapny) {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'favorites.jobs',
            updateType: 'delete',
            value: updatedJob._id,
            profileId: myTalentProfile._id
          })
        );
      }
      dispatch(
        portalActions.openPortal({
          name: 'job-success-modal',
          data: {
            text: 'Great! Your application was sent. Good luck!',
            onDoneClick: async () => {
              if (isMyProfileComapny) {
                await switchProfile(myTalentProfile, () => ({}), false);
              }
            }
          }
        })
      );
    }
  });

  const { dirty, errors, initialize, onFieldChange, onSubmit, submitting, triggerErrors, updateDirty, values } =
    useForm({
      fields,
      callApi: async params => {
        const transformedParams = {
          ...params,
          thumbnail: params.thumbnail || profileImageId
        };
        const jobData = transformData(transformedParams);
        if (jobData.thumbnail && typeof jobData.thumbnail !== 'string' && !jobData.thumbnail.url) {
          const formData = new FormData();
          formData.append('file', jobData.thumbnail);
          const { data } = await uploadsService.uploadFile(formData);
          const createdImageId = data.data.id;
          jobData.thumbnail = createdImageId;
        }
        if (job) {
          return jobService.updateJob(profileEditorId, job._id, {
            ...jobData,
            projects: reduceProjects(jobData.projects, job.projects)
          });
        }
        return jobService.addJob(profileId, jobData);
      },
      onSuccess: ({ data }) => {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'jobs',
            updateType: job ? 'update' : 'insert',
            value: data,
            profileId: profileId
          })
        );
      },
      onSuccessRedirect: () => history.push(size(trim(redirectTo)) ? redirectTo : jobsIPostedPath)
    });

  const leavingPageData = {
    title: 'Save this job for later?',
    message: "Hey! It looks like you are in the middle of writing something and you haven't saved all of your changes.",
    messageLineTwo: 'Save before you go!',
    okBtnText: 'Save changes',
    cancelBtnText: 'Leave page',
    padding: '55px 0 39px',
    maxWidth: 520,
    headerPaddingBottom: 16
  };
  const userLeavingPage = useSelector(portalSelectors.isPortalOpenedSelector('leave-page-modal'));
  const leavingPagePromptData = useSelector(portalSelectors.portalDataSelector('leave-page-modal'));
  const profileJobType = useSelector(profileSelectors.selectMyProfileProperty('isSupply'));

  const handleApplyClick = async () => {
    if (profileJobType === 'demand') {
      dispatch(portalActions.openPortal({ name: 'demand-apply-job-modal', data: { talentId: currentUserProfileId } }));
    } else {
      await applyProfileToJob(job?._id);
    }
  };

  const handleWithdrawClick = () => {
    dispatch(
      portalActions.openPortal({
        name: 'job-withdraw',
        data: {
          profileId,
          jobId: job._id,
          setCurrentJob: setCurrentJob
        }
      })
    );
  };

  const handleSaveClick = action => {
    saveJob(profileId, job._id, action);
  };

  const onSave = async (e, additionalInfo, options) => {
    const validate = additionalInfo.status === 'published' ? onPublishValidate : onDraftValidate;

    const validation = triggerErrors()(validate);

    if (!validation.success) {
      options?.validationFailedCallback && options.validationFailedCallback();
      return;
    }

    await onSubmit(e, additionalInfo, options);
  };

  const handleCancel = () => {
    if (isMember) {
      updateDirty(false);
      history.push(jobsIPostedPath);
    } else {
      dispatch(
        portalActions.openPortal({
          name: 'leave-page-modal',
          data: {
            ...leavingPageData,
            clickedOnCancel: true,
            onOk: async () => {
              try {
                const status = job ? job.status : 'draft';
                await onSave(null, { status }, { validationFailedCallback: () => setRedirectTo(jobsIPostedPath) });
              } catch {}
            },
            onCancel: () => {
              updateDirty(false);
              setTimeout(() => history.push(jobsIPostedPath), 100);
            }
          }
        })
      );
    }
  };

  const openCompleteProfileModal = useCallback(() => {
    dispatch(
      portalActions.openPortal({
        name: 'complete-profile-modal',
        data: {
          saveDraft: path => {
            updateDirty(true);
            triggerErrors()(onDraftValidate);
            return path ? setRedirectTo(path) : onSave(null, { status: 'draft' });
          }
        }
      })
    );
  }, [dispatch, onSave, triggerErrors, updateDirty]);

  const handleSaveJob = useCallback(
    (e, status) => {
      if (status === 'published' && !canBePublished(funnelStatus, vettingStatus, isMyProfileComapny)) {
        return openCompleteProfileModal();
      }
      onSave(e, { status });
    },
    [funnelStatus, vettingStatus, isMyProfileComapny, openCompleteProfileModal]
  );

  useEffect(() => {
    if (!isMember && userLeavingPage && !leavingPagePromptData.clickedOnCancel) {
      dispatch(
        portalActions.openPortal({
          name: 'leave-page-modal',
          data: {
            ...leavingPagePromptData,
            onOk: async path => {
              try {
                const status = job ? job.status : 'draft';

                const validate = status === 'draft' ? onDraftValidate : onPublishValidate;

                const { success } = triggerErrors()(validate);

                if (!success) {
                  setRedirectTo(path);
                  return leavingPagePromptData.onCancel();
                }

                await onSubmit(null, { status }, { redirect: false });
                return leavingPagePromptData.onOk();
              } catch {}
            },
            onCancel: () => {
              return leavingPagePromptData.onOk();
            }
          }
        })
      );
    }
  }, [userLeavingPage]);

  useEffect(() => {
    if (job) {
      const normilizedJob = normalizeData(job);
      initialize(normilizedJob);
      setCurrentJob(job);
    }
  }, [job]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const appliedTalentIds = currentJob?.appliedBy ? currentJob.appliedBy.map(item => item.talentId) : [];
  const contractedTalentIds = getContractedTalents(currentJob);
  const savedJobsIds = Object.keys(favorites?.jobs ?? {});

  const alreadyApplied = appliedTalentIds.includes(profileId);
  const alreadyContracted = contractedTalentIds.includes(profileId);
  const contractedSupply = job?.contractedSupply;
  const alreadySaved = savedJobsIds.includes(job?._id);

  const [rejectedApplicants, setRejectedApplicants] = useState([]);

  useEffect(() => {
    if (shoudTriggerSaving(dirty, redirectTo, errors, submitting)) {
      onSave(null, { status: job ? job.status : 'draft' });
    }
  }, [dirty, values, errors, redirectTo]);

  const hasContractActive = useCallback(() => {
    if (!contractedSupply) {
      return false;
    }

    return contractedSupply.some(
      contract => contract.profile === currentUserProfileId && contract.contractStatus !== 'in-draft'
    );
  }, [contractedSupply, currentUserProfileId]);

  const findContractId = useCallback(() => {
    if (!contractedSupply) {
      return null;
    }

    const contract = contractedSupply.find(contract => contract.profile === currentUserProfileId);

    return contract ? contract.contractId : null;
  }, [contractedSupply, currentUserProfileId]);

  const contractId = findContractId();
  const isContractActive = hasContractActive();

  const handleReview = e => {
    e.stopPropagation();
    const url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${contractId}/review`;
    window.location.href = url;
  };

  return (
    <>
      {!isMember && <Prompt when={dirty} message={JSON.stringify(leavingPageData)} />}
      <JobShareModal />
      <JobCloseModal />
      <TextModal />
      <RejectApplicationModal rejectedApplicants={rejectedApplicants} setRejectedApplicants={setRejectedApplicants} />
      <JobWithDrawModal />
      <CompleteProfileToApplyModal />
      <ApplyAsSoloistModal />
      {isJobIPosted && hasEditPermission && (
        <>
          <UnpublishJobModal />
        </>
      )}

      <AddJobContainer>
        <LeavePageModal />
        <JobContentGrid>
          <div>
            {isJobIPosted && hasEditPermission ? (
              <JobApplicantsSidebar
                job={currentJob}
                jobId={jobId}
                jobStatus={job?.status}
                userId={userId}
                dirty={dirty}
                validatePublish={() => triggerErrors()(onPublishValidate)}
                onSave={handleSaveJob}
              />
            ) : (
              <JobSidebar
                alreadyApplied={alreadyApplied}
                alreadyContracted={alreadyContracted}
                isContractActive={isContractActive}
                isSaved={!!alreadySaved}
                handleWithdrawClick={handleWithdrawClick}
                handleApplyClick={handleApplyClick}
                handleSaveClick={handleSaveClick}
                viewMode={viewMode}
                isClosed={isClosed}
                loading={loadingApply || loadingWithdraw}
                job={currentJob}
                categories={values.categories}
                handleReview={handleReview}
              />
            )}
          </div>
          {isApplicantsRoute && hasEditPermission ? (
            <Applicants job={job} jobId={job?._id} jobTitle={job?.title} status={job?.status} loading={loading} />
          ) : (
            <JobForm
              talentId={profileId}
              values={values}
              errors={errors}
              onFieldChange={onFieldChange}
              hasEditPermission={hasEditPermission}
              viewMode={viewMode}
              profile={currentJob?.talent || currentJob?.companyProfile}
              alreadyApplied={alreadyApplied}
              handleWithdrawClick={handleWithdrawClick}
              handleApplyClick={handleApplyClick}
              loading={loadingSave || loadingApply || loadingWithdraw}
              jobId={job?._id}
              isClosed={isClosed}
              isContractActive={isContractActive}
              handleReview={handleReview}
            />
          )}
        </JobContentGrid>
        {hasEditPermission && !isApplicantsRoute && (
          <AddJobFooter
            onSave={handleSaveJob}
            submitting={submitting}
            editMode={jobId !== 'add'}
            dirty={dirty}
            handleCancel={handleCancel}
            jobStatus={job?.status}
            isMember={isMember}
          />
        )}
      </AddJobContainer>

      <JobSuccessModal />
      <CompleteWithdrawJobModal />
      <CancelContractModal />
      <CompleteProfileModal />
    </>
  );
};
export default AddJob;
