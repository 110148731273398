import React from 'react';
import Icon from 'common/Icon';
import * as Styled from './StyledConversationsSearch';

const ConversationSearch = ({ searchValue, setSearchValue }) => (
  <Styled.Container>
    <Icon icon="search" />
    <Styled.Input placeholder="Search contact" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
  </Styled.Container>
);

export default ConversationSearch;
