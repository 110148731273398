import React from 'react';
import useProfiles from 'hooks/useProfiles';
import PageLoader from 'common/PageLoader';

const withProfiles = Component => {
  const EnhancedComponent = props => {
    const { isLoading } = useProfiles();
    if (isLoading) {
      return <PageLoader />;
    }

    return <Component {...props} />;
  };
  return EnhancedComponent;
};

export default withProfiles;
