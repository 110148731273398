import React from 'react';
import styled from '@emotion/styled';

import AssetVoice from './AssetVoice';
import AssetPdf from './AssetPdf';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
const ImageAsset = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

const Asset = ({ asset, className, onClick, height }) => {
  const url = asset?.file?.url ? asset.file.url : asset?.url ? asset.url : window.URL.createObjectURL(asset.file);
  const type = asset.file?.type || asset.type;

  return (
    <Wrapper onClick={onClick} className={className}>
      {type.includes('image') && <ImageAsset src={url} />}

      {type.includes('pdf') && <AssetPdf url={url} />}

      {type.includes('video') && (
        <video height={height} width="100%" controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
          <source src={url} />
        </video>
      )}

      {type.includes('audio') && <AssetVoice type={type} url={url} />}
    </Wrapper>
  );
};

export default Asset;
