export const salaryFields = {
  '0-140000': {
    label: 'up to $140K',
    value: '0-140000'
  },
  '0-40000': {
    label: 'up to $40K',
    value: '0-40000'
  },
  '40000-60000': {
    label: '$40K–$60K',
    value: '40000-60000'
  },
  '60000-80000': {
    label: '$60K–$80K',
    value: '60000-80000'
  },
  '80000-100000': {
    label: '$80K–$100K',
    value: '80000-100000'
  },
  '100000-120000': {
    label: '$100K–$120K',
    value: '100000-120000'
  }
};

export const hourlyRateFields = {
  '0-40': {
    label: 'up to $40',
    value: '0-40'
  },
  '40-60': {
    label: '$40–$60',
    value: '40-60'
  },
  '60-80': {
    label: '$60–$80',
    value: '60-80'
  },
  '80-100': {
    label: '$80–$100',
    value: '80-100'
  },
  '100-130': {
    label: '$100–$130',
    value: '100-130'
  }
};

export const datePostedFields = {
  older_than_past_month: {
    label: 'Anytime',
    value: 'older_than_past_month'
  },
  '1_week_ago': {
    label: '1 week ago',
    value: '1_week_ago'
  },
  '24_hours_ago': {
    label: '24 Hours ago',
    value: '24_hours_ago'
  },
  past_month: {
    label: 'Past Month',
    value: 'past_month'
  }
};
