import React from 'react';

import withOpenPortal from 'hocs/withOpenPortal';
import ContainedButton from './ContainedButton';

const EditButton = ({ className, iconName = 'edit', onClick, tooltip, type }) => (
  <ContainedButton
    type={type}
    icon={iconName}
    onClick={onClick}
    className={className}
    backgroundColor="secondary"
    tooltip={tooltip}
    tooltipId={tooltip}
    data-testid="edit-button"
  />
);

export default withOpenPortal(EditButton);
