import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { portalActions } from 'store/portals';
import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';

import useQuery from 'hooks/useQuery';
import VettingStatus from './VettingStatus';
import VettingOutcome from './VettingOutcome';

const format = date => moment(date).format('LLL');
const contentLength = content => (content?.length > 30 ? `${content.substr(0, 30)}...` : content);

const BackOfficeSoloistVettingLog = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useQuery({
    url: `/backoffice/talents/${params.userId}`,
    onSuccess: talent => {
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'OPERATIONS' },
          { label: 'SOLOIST ACCOUNTS', url: '/backoffice/operations/soloists' },
          {
            label: `${talent.talent.userId.firstName} ${talent.talent.userId.lastName}`,
            url: `/backoffice/operations/soloists/${talent.talent._id}`
          },
          { label: 'Vetting Inputs' }
        ])
      );
    }
  });

  const handleEditClick = e => {
    dispatch(
      portalActions.openPortal({
        name: 'update-vetting-log-modal',
        data: {
          status: e.status,
          outcome: e.outcome,
          additionalComment: e.additionalComment,
          updateVettingLog: true,
          vettingLogId: e._id,
          vettingLogData: { status: e.status }
        }
      })
    );
  };

  return (
    <div>
      <Table keyId="_id" endpoint={`/backoffice/accounts/${params.userId}/vetting-log`} dataKey="data">
        <Column
          title="VETTED ELEMENT"
          keyName="element"
          render={vettingLog =>
            vettingLog.element
              ? vettingLog.element.charAt(0).toUpperCase() + vettingLog.element.slice(1)
              : vettingLog.entity.entityType.charAt(0).toUpperCase() + vettingLog.entity.entityType.slice(1)
          }
          width="108px"
        />
        <Column
          title="INPUT NAME"
          keyName="entity.title"
          render={vettingLog => vettingLog.entity?.title}
          width="108px"
        />
        <Column
          title="STATUS CHANGED TO"
          keyName="status"
          render={vettingLog => <VettingStatus status={vettingLog.status} />}
          width="130px"
        />
        <Column
          title="OUTCOME CHANGED TO"
          keyName="status"
          render={vettingLog => <VettingOutcome status={vettingLog.outcome} />}
          width="108px"
        />
        <Column title="CHANGED ON" keyName="createdAt" render={vettingLog => format(vettingLog.updatedAt)} />
        <Column
          title="CHANGED BY"
          keyName="actionUserId"
          render={vettingLog => `${vettingLog.actionUserId.firstName} ${vettingLog.actionUserId.lastName}`}
        />
        <Column
          title="ADDITIONAL COMMENTS"
          keyName="additionalComment"
          render={vettingLog => <div>{contentLength(vettingLog.additionalComment)}</div>}
        />
        <Column title="" width={46} type="action" onClick={handleEditClick} />
      </Table>
    </div>
  );
};

export default BackOfficeSoloistVettingLog;
