import styled from '@emotion/styled';
import { companyProfileService, talentService } from 'api';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import { pageSize } from 'utils/devices';
import AboutExperienceInput from './AboutExperienceInput';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;
const GridWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 32px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const fields = initialValues => ({
  years: { initialValue: initialValues.years ? initialValues.years : null, validate: ['isLessThanMillion'] },
  games: { initialValue: initialValues.games ? initialValues.games : null, validate: ['isLessThanMillion'] },
  aaaGames: { initialValue: initialValues.aaaGames ? initialValues.aaaGames : null, validate: ['isLessThanMillion'] },
  aaGames: { initialValue: initialValues.aaGames ? initialValues.aaGames : null, validate: ['isLessThanMillion'] },
  indieGames: {
    initialValue: initialValues.indieGames ? initialValues.indieGames : null,
    validate: ['isLessThanMillion']
  },
  mobileGames: {
    initialValue: initialValues.mobileGames ? initialValues.mobileGames : null,
    validate: ['isLessThanMillion']
  }
});

const AboutExperienceModal = ({ closePortal, data: { focusField }, name }) => {
  const about = useSelector(profileSelectors.selectProfileProperty('about'));
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const dispatch = useDispatch();

  const service = isCompanyView ? companyProfileService.setAboutExperience : talentService.setAboutExperience;

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(about),
    callApi: params =>
      service(profileId, params).then(() => {
        dispatch(
          profileActions.updateProfileProperty({ property: 'about.years', value: parseInt(params.years), profileId })
        );
        dispatch(
          profileActions.updateProfileProperty({ property: 'about.games', value: parseInt(params.games), profileId })
        );
        dispatch(
          profileActions.updateProfileProperty({
            property: 'about.aaaGames',
            value: parseInt(params.aaaGames),
            profileId
          })
        );
        dispatch(
          profileActions.updateProfileProperty({
            property: 'about.aaGames',
            value: parseInt(params.aaGames),
            profileId
          })
        );
        dispatch(
          profileActions.updateProfileProperty({
            property: 'about.indieGames',
            value: parseInt(params.indieGames),
            profileId
          })
        );
        dispatch(
          profileActions.updateProfileProperty({
            property: 'about.mobileGames',
            value: parseInt(params.mobileGames),
            profileId
          })
        );
        closePortal();
      })
  });

  return (
    <TxplModal
      name={name}
      title="Experience"
      appElement={document.getElementById('talent-modal-placeholder')}
      size="large"
      addPaddingTop
      titleMB="16"
      allowLine
      XSpadding="54px 0 79px 0"
      renderFooter={
        <>
          {submitting && <Spinner />}
          <StyledContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </StyledContainedButton>
          <StyledContainedButton form="aboutExperienceModal" type="submit" backgroundColor="#7266EE" forceDisplayText>
            Save
          </StyledContainedButton>
        </>
      }
    >
      <GridWrapper onSubmit={onSubmit} id="aboutExperienceModal">
        <AboutExperienceInput
          title="Years of experience"
          hintText="How many years have you worked in the industry?"
          value={values.years}
          autofocus={focusField === 'years'}
          onChange={onFieldChange('years')}
          error={errors.years}
        />

        <AboutExperienceInput
          title="Games shipped"
          hintText="How many games have you helped ship?"
          value={values.games}
          autofocus={focusField === 'games'}
          onChange={onFieldChange('games')}
          error={errors.games}
        />

        <AboutExperienceInput
          title="AAA shipped"
          hintText="How many AAA games have you helped ship?"
          value={values.aaaGames}
          autofocus={focusField === 'aaaGames'}
          onChange={onFieldChange('aaaGames')}
          error={errors.aaaGames}
        />

        <AboutExperienceInput
          title="AA shipped"
          hintText="How many AA games have you helped ship?"
          value={values.aaGames}
          autofocus={focusField === 'aaGames'}
          onChange={onFieldChange('aaGames')}
          error={errors.aaGames}
        />

        <AboutExperienceInput
          title="Indies shipped"
          hintText="How many Indie games have you helped ship?"
          value={values.indieGames}
          autofocus={focusField === 'indieGames'}
          onChange={onFieldChange('indieGames')}
          error={errors.indieGames}
        />

        <AboutExperienceInput
          title="Mobile shipped"
          hintText="How many mobile games have you helped ship?"
          value={values.mobileGames}
          autofocus={focusField === 'mobileGames'}
          onChange={onFieldChange('mobileGames')}
          error={errors.mobileGames}
        />
      </GridWrapper>
    </TxplModal>
  );
};

export default withRenderPortal('about-experience-modal')(AboutExperienceModal);
