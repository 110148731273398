import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import isEmpty from 'lodash/fp/isEmpty';
import { api, companyProfileService, talentService } from 'api';
import get from 'lodash/fp/get';
import useProfileSwitcher from './useProfileSwitcher';
import { useLocation } from 'react-router';
import { authSelectors } from 'store/auth';
import { apiCallActions } from 'store/apiCall';

function useProfileView(profilePublicUrl) {
  const isMounted = useRef(true);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const selectedProfile = useSelector(profileSelectors.selectProfileByPublicUrl(profilePublicUrl));
  const isProfileInitialized = !isEmpty(selectedProfile);
  const isProfileLoaded = !isEmpty(useSelector(profileSelectors.selectIsProfileLoaded));
  const isMyProfilesInitialized = !!useSelector(profileSelectors.selectMyProfileList).length;
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const isAuthed = useSelector(authSelectors.isAuthed);
  const isAuthLoading = useSelector(authSelectors.selectIsAuthLoading);
  const { switchProfile } = useProfileSwitcher();

  const [notFound, setNotFound] = useState(false);
  const loading = useSelector(profileSelectors.selectIsProfileLoading);

  const isCompany = useMemo(() => pathname.includes('company'), [pathname.includes('company')]);

  const getProfile = useCallback(
    async callback => {
      try {
        const callApi = pathname.includes('company')
          ? companyProfileService.getCompanyPreview
          : talentService.getTalentPreview;

        const {
          data: { data }
        } = await callApi(profilePublicUrl);

        if (!data) {
          setNotFound(true);
          return;
        }

        dispatch(profileActions.setProfile(data));
        dispatch(profileActions.setActiveProfileId(data?._id));

        callback && callback();
      } catch (err) {
        setNotFound(true);
      }
    },
    [dispatch, pathname, profilePublicUrl]
  );

  const getAvailableProfiles = useCallback(
    async callback => {
      try {
        const res = await api.get('talents/available-profiles');
        const profiles = res.data.profiles;

        dispatch(profileActions.initializeProfiles(profiles));

        const validProfile = profiles.find(item => item._id === myProfileId);
        const validProfileId = validProfile?.publicUrl;
        if (validProfileId !== profilePublicUrl) {
          return await getProfile();
        }

        if (!myProfileId && isAuthed) {
          const defaultProfile = get('0', profiles);

          switchProfile(defaultProfile);
        }

        if (myProfileId && validProfile) {
          dispatch(profileActions.setActiveProfileId(myProfileId));
          return;
        }

        callback && callback();
      } finally {
        isMounted.current && dispatch(apiCallActions.sendRequestSuccess({ name: 'profiles' }));
      }
    },
    [dispatch, getProfile, isAuthed, myProfileId, profilePublicUrl]
  );

  useEffect(() => {
    if (profilePublicUrl !== undefined && !isAuthLoading) {
      if (!isMyProfilesInitialized && isAuthed) {
        getAvailableProfiles();
      } else if ((isMyProfilesInitialized || !isAuthed) && !isProfileInitialized) {
        dispatch(apiCallActions.sendRequest({ name: 'profiles' }));
        getProfile(() => dispatch(apiCallActions.sendRequestSuccess({ name: 'profiles' })));
      } else {
        if (isProfileInitialized) {
          dispatch(profileActions.setActiveProfileId(selectedProfile._id));
          notFound && setNotFound(false);
        }
        dispatch(apiCallActions.sendRequestSuccess({ name: 'profiles' }));
      }
    }
  }, [dispatch, getAvailableProfiles, getProfile, profilePublicUrl, isAuthLoading, isCompany]);

  return {
    loading,
    isProfileLoaded: !loading && isProfileLoaded,
    notFound
  };
}

export default useProfileView;
