import React from 'react';
import styled from '@emotion/styled';

import Frame from 'common/Frame';
import Location from 'components/Location';

const StyledLocationsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

const AboutLocations = ({ locations = [] }) => (
  <Frame id="locations" color="#A4BFD8" filled text="Locations">
    <StyledLocationsContainer>
      {locations.map(location => (
        <Location key={location._id} location={location} />
      ))}
    </StyledLocationsContainer>
  </Frame>
);

export default AboutLocations;
