import React from 'react';
import { useDispatch } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import { portalActions } from 'store/portals';

import ContainedButton from 'common/ContainedButton';
import IDVerifiedIcon from 'images/assets/assets_icons/id-verified.svg';
import * as Styled from './StyledIDVerifiedModal';

const IDVerifiedModal = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    localStorage.removeItem('showIDVerifiedModal');
    dispatch(portalActions.closePortal({ name: 'id-verified-modal' }));
  };

  return (
    <Styled.StyledTpxlModal
      title="Your ID has been verified."
      name="id-verified-modal"
      appElement={document.getElementById('root-modal')}
      titleMB="24"
      padding="56px 0 80px 0"
      renderFooter={
        <>
          <ContainedButton onClick={handleCloseModal} backgroundColor="primary">
            DONE
          </ContainedButton>
        </>
      }
      onClose={handleCloseModal}
    >
      <Styled.Container>
        <Styled.IDVerifiedImage src={IDVerifiedIcon} />
        <Styled.IDVerifiedTitle>Your feedback has been submitted</Styled.IDVerifiedTitle>
        <Styled.IDVerifiedDescription>Thank you!</Styled.IDVerifiedDescription>
      </Styled.Container>
    </Styled.StyledTpxlModal>
  );
};

export default withRenderPortal('id-verified-modal')(IDVerifiedModal);
