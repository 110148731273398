import React, { useCallback, useEffect } from 'react';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import { portalActions } from 'store/portals';
import { authActions } from 'store/auth';
import { useDispatch } from 'react-redux';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import { ModalBody, Title } from './JoinEventsNowModal.styled';
import { userService } from 'api';

const JoinEventsNowModal = ({ name }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    userService
      .setSettings({ hasJoinEventMessageRead: true })
      .then(() => {
        dispatch(
          authActions.updateCurrentUserProperty({
            property: 'settings.hasJoinEventMessageRead',
            value: true
          })
        );
      })
      .catch(error => {
        console.error('Error updating settings:', error);
      });
  }, []);

  const onClose = useCallback(() => {
    dispatch(portalActions.closePortal({ name: 'join-events-now-modal' }));
  }, []);

  const header = () => {
    return <ModalHeader title="Congrats, you can join events!" onClose={onClose} />;
  };

  return (
    <TxplModal
      name={name}
      noPadding={true}
      appElement={document.getElementById('root')}
      hideCloseIcon
      size="small"
      renderHeader={header}
      renderFooter={<ModalFooter />}
      hideBorder
      padding="25px"
    >
      <ModalBody>
        <Title>Explore events or keep building your profile to unlock more opportunities.</Title>
      </ModalBody>
    </TxplModal>
  );
};

export default withRenderPortal('join-events-now-modal')(JoinEventsNowModal);
