import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const DateSeperatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 16px;
  margin-top: 24px;

  ${({ isFirstMessage }) => isFirstMessage && 'margin-top: 24px;'}
`;

export const DateSeperatorContent = styled.div`
  display: inline-block;
  font-size: 13px;
  color: rgba(229, 234, 255, 0.35);

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 38%;
    height: 2px;
    background: rgba(204, 212, 255, 0.11);
  }

  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;

  ${({ isByMe }) => isByMe && 'justify-content: flex-end;'}
  ${({ hasDate, isByMe, isSameSender }) => isSameSender && !hasDate && isByMe && 'margin-top: 4px;'}
    ${({ hasDate, isByMe, isSameSender }) => isSameSender && !hasDate && !isByMe && 'margin-top: 16px;'}
    ${({ isLastMessage }) => isLastMessage && 'margin-bottom: 16px;'}
    ${({ hasDate, isAttachment, isSameSender }) => isAttachment && isSameSender && !hasDate && 'margin-top: 10px;'}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media ${pageSize.XS} {
    gap: 4px;
  }
`;

export const TextContainer = styled.div`
  ${({ pointer }) => pointer && 'cursor: pointer;'}
  padding: 12px 16px;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  background: rgba(204, 212, 255, 0.11);
  max-width: 345px;
  order: 1;
  word-break: break-all;
  white-space: pre-wrap;

  ${({ isByMe }) => isByMe && 'background: #7266EE; order: 2; margin-right: 0;'}
  ${({ isDocument }) =>
    isDocument &&
    `
            background: rgba(204, 212, 255, 0.05); 
            border-radius: 12px;
            &:hover {
                background: rgba(204, 212, 255, 0.11);
            }
        `}
    ${({ isImage }) =>
    isImage &&
    `
        background: transparent;
        padding:  0; 
    `}
`;
export const MessageDate = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: rgba(229, 234, 255, 0.35);
  @media ${pageSize.XS} {
    display: none;
  }
`;
export const AttachmentWrapper = styled.div`
  position: relative;
`;
export const DocumentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const DocumentName = styled.div`
  font-size: 14px;
  margin-left: 8px;
  color: #fff;
`;

export const ImageContainer = styled.div`
  border-radius: 12px;
  width: 103px;
  height: 103px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  &:hover {
    &:before,
    .download-button {
      opacity: 1;
      visibility: visible;
    }
  }
`;
export const ImageDownloadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 32px;
  background: #111112;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  opacity: 0;
  visibility: hidden;
`;
