import React from 'react';
import Icon from 'common/Icon';
import * as Styled from './StyledConversationsEmpty';

const ConversationEmpty = () => (
  <Styled.Container>
    <Icon icon="group" size={64} color="rgba(221, 226, 255, 0.2)" />
    <Styled.Text>Your conversations will appear here</Styled.Text>
  </Styled.Container>
);

export default ConversationEmpty;
