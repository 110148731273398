import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';

export const StyledBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCompanyBenefits = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(160px, auto);
  margin-top: 16px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(128px, auto);
  }
`;
export const StyledCompanyBenefitItem = styled.div`
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid rgba(204, 213, 255, 0.11);
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding: 24px;
`;

export const StyledEditButton = styled(ContainedButton)`
  display: flex;
  justify-content: flex-end;
  color: white;
  @media ${pageSize.XS} {
    width: 100%;
    justify-content: center;
  }
`;
