import React, { useState } from 'react';
import Icon from 'common/Icon';

import useChatUserService from 'hooks/chatApi/useChatUser';

import Loader from 'common/Loader';
import * as Styled from './StyledConversationsSidebar';
import ConversationSearch from './ConversationsSearch';
import ConversationList from './ConversationList';
import ConversationEmpty from './ConversationsEmpty';

const ConversationsSidebar = ({
  conversations = [],
  newMessageMode,
  setNewMessageMode,
  selectedConversation,
  setSelectedConversation,
  fetchConversations,
  setVisible
}) => {
  const { loading } = useChatUserService();

  const [searchValue, setSearchValue] = useState('');

  const handleHeaderIconClick = () => {
    setNewMessageMode(conversations.length === 0 ? !newMessageMode : true);
    setSelectedConversation(null);
    setVisible(true);
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.HeaderTitle>conversations</Styled.HeaderTitle>
        <Styled.HeaderIcon onClick={handleHeaderIconClick}>
          <Icon icon="edit_underline" color="#fff" />
        </Styled.HeaderIcon>
      </Styled.Header>
      <ConversationSearch
        fetchConversations={fetchConversations}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {loading.getUserConversations && <Loader />}
      {conversations.length === 0 && !loading.getUserConversations && <ConversationEmpty />}
      {!loading.getUserConversations && (
        <ConversationList
          setVisible={setVisible}
          conversations={conversations}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          searchTerm={searchValue}
        />
      )}
    </Styled.Container>
  );
};

export default ConversationsSidebar;
