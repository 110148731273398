import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';

const StyledFooterContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-areas:
    'icon title'
    'description description';
  grid-template-columns: 20px auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  align-items: center;
  border: 2px dashed rgba(221, 226, 255, 0.2);
  border-radius: 16px;
  padding: 32px 0px;
  justify-content: center;
  @media ${pageSize.XS} {
    padding: 32px 10px 32px 10px;
  }
`;

const StyledTitle = styled.p`
  grid-area: title;
  ${commonStyles.ui_text_medium}
  margin: 0px;
`;

const StyledDescription = styled.p`
  ${commonStyles.ui_text_small}
  grid-area: description;
  margin: 0px;
  color: rgba(230, 234, 255, 0.35);
  max-width: 425px;
  text-align: center;
`;

const ReachedMaxCreditLimit = () => (
  <StyledFooterContainer>
    <Icon color="#FF7CBB" icon="thumbs-up" />

    <StyledTitle>Wow, you’ve reached the maximum of published projects!</StyledTitle>
    <StyledDescription>
      We appreciate that you have more but we honor showing off only the best work. You can unpublish or delete some of
      current projects to publish the new ones.
    </StyledDescription>
  </StyledFooterContainer>
);

export default ReachedMaxCreditLimit;
