import * as categoryServices from 'api/categoryServices';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

const initialLoading = {
  loadingCategories: false
};

function useCategories() {
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(initialLoading);

  const getCategories = useCallback(
    async profileId => {
      if (profileId !== undefined && !loading.loadingCategories) {
        try {
          setLoading({ ...loading, loadingCategories: true });

          const {
            data: { data }
          } = await categoryServices.getCategories(profileId, 'life');

          setCategories(data);
        } catch {
        } finally {
          setLoading({ ...loading, loadingCategories: false });
        }
      }
    },
    [loading]
  );

  useEffect(() => {
    getCategories(profileId);
  }, []);

  return {
    loading,
    categories,
    getCategories
  };
}

export default useCategories;
