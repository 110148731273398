import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import LinkWrapper from 'common/LinkWrapper';
import { Column, Table } from 'components/Table';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { backOfficeActions } from 'store/backOffice';
import ActivityStatus from '../ActivityStatus';
import VerificationStatus from './VerificationStatus';

const StyledLinkContainer = styled.div`
  margin-bottom: 20px;

  & button {
    margin-left: auto;
  }
`;

const StyledIdContainer = styled.p`
  width: 108px;
  overflow-x: scroll;
`;

const BackOfficeUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Operations' }, { label: 'Users' }]));
  }, [dispatch]);

  return (
    <>
      <StyledLinkContainer>
        <LinkWrapper to="/backoffice/operations/users/new">
          <ContainedButton>Create New User</ContainedButton>
        </LinkWrapper>
      </StyledLinkContainer>

      <Table keyId="_id" paginate endpoint="/backoffice/users" dataKey="users">
        <Column
          title="USER ID"
          keyName="_id"
          render={user => <StyledIdContainer>{user._id}</StyledIdContainer>}
          width="108px"
          search
        />
        <Column title="First Name" keyName="firstName" search sort />
        <Column title="Last Name" keyName="lastName" search />
        <Column title="email" keyName="email" search />
        <Column
          title="Id Verification status"
          keyName="verificationStatus"
          render={user => {
            const verificationStatus = user?.verificationStatusLogs?.[0] || {};
            return (
              <VerificationStatus verification={verificationStatus} verificationStatus={verificationStatus.status} />
            );
          }}
          pr={60}
          search
        />
        <Column
          title="Activity Status"
          keyName="status"
          render={user => <ActivityStatus status={user.status} />}
          width="1%"
          pr={60}
          search
        />
        <Column
          title=""
          type="action"
          width={46}
          onClick={user => history.push(`users/${user._id}`)}
          opens="some-modal"
        />
      </Table>
    </>
  );
};

export default BackOfficeUsers;
