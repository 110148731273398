import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import { vettingOutcomes, vettingStatuses } from '../../../constants';
import vettingService from 'api/vettingService';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = (initialStatus, initialOutcome, additionalComment, element) => ({
  status: { initialValue: vettingStatuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  outcome: { initialValue: vettingOutcomes.find(curr => curr.value === initialOutcome), validate: [] },
  additionalComment: { initialValue: additionalComment },
  element: { initialValue: element }
});

const transformParams = params => ({
  ...params,
  outcome: params.outcome?.value,
  status: params.status.value
});

const UpdateVettingLogModal = ({ closePortal, data, name, reload, setReload }) => {
  const { status, outcome, additionalComment, element, vettingLogId, vettingLogData = {} } = data;

  const callApi = params => {
    const apiParams = transformParams(params);
    const requestPromise = vettingService.updateVettingLog(vettingLogId, {
      ...vettingLogData,
      status: apiParams.status,
      outcome: apiParams.outcome,
      additionalComment: apiParams.additionalComment
    });

    return requestPromise.then(() => {
      setReload(!reload);
      closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(status, outcome, additionalComment, element),
    callApi
  });

  return (
    <TxplModal
      title="Vetting status"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="updateVettingLogModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="updateVettingLogModal">
        <FormSelect
          label="Vetting status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={vettingStatuses}
          autoFocus
          mb={40}
        />

        <FormSelect
          label="Outcome"
          value={values.outcome}
          onChange={onFieldChange('outcome')}
          options={vettingOutcomes}
          isDisabled={values.status?.value !== 'COMPLETE' && values.status?.value !== 'IN_PROGRESS'}
          mb={40}
        />

        <FormTextarea
          label="Additional comments"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
          maxLength={200}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('update-vetting-log-modal')(UpdateVettingLogModal);
