import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import Credits from 'pages/talent/Credits';
import AllAdditionalTags from 'pages/talent/AllAdditionalTags/AllAdditionalTags';
import useForm from 'hooks/useForm';
import Prompt from 'common/Prompt';
import { talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';
import CompanyAndRole from './CompanyAndRole';
import AddExperienceFooter from './AddExperienceFooter';
import Accomplishments from './Accomplishments';
import EditExperienceFooter from './EditExperienceFooter';
import TalentExperienceContext from './TalentExperienceContext';
import MediaCoverageFrame from '../MediaCoverage/MediaCoverageFrame';
import CollaboratorsFrame from '../Collaborators/CollaboratorsFrame/CollaboratorsFrame';
import useScrollToMandatoryField from 'hooks/useScrollToMandatoryField';

const StyledAddNewExperienceContainer = styled.form`
  display: grid;
  grid-row-gap: 16px;
`;

const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 16px !important;
`;

const fields = {
  experienceId: { initialValue: undefined },
  companyAndRole: { initialValue: {} },
  accomplishments: { initialValue: [] },
  collaborators: { initialValue: [] },
  credits: { initialValue: [] },
  mediaCoverage: { initialValue: [] },
  platforms: { initialValue: [] },
  genres: { initialValue: [] },
  tools: { initialValue: [] },
  skills: { initialValue: [] },
  tags: { initialValue: [] }
};

const hintTexts = {
  platforms: 'What platforms was this work made for?',
  genres: 'What types of gameplay genres did you focus on?',
  tools: 'What tools did you use to create and collaborate?',
  skills: 'Which of your (many) skills came into play here?',
  tags: 'What tags would you add to this work?'
};

const transform = values => ({
  ...values,
  platforms: values.platforms.map(tag => tag._id),
  genres: values.genres.map(tag => tag._id),
  tools: values.tools.map(tag => tag._id),
  skills: values.skills.map(tag => tag._id),
  tags: values.tags.map(tag => tag._id),
  companyAndRole: {
    ...values.companyAndRole,
    role: values?.companyAndRole?.role,
    company: values?.companyAndRole?.company?._id,
    industryType: values?.companyAndRole?.industryType?.value
  }
});

const onValidate = values => {
  const errors = {};

  if (!values.getIn(['companyAndRole', 'value']).size) errors.companyAndRole = 'Required';
  if (!values.getIn(['accomplishments', 'value']).size) errors.accomplishments = 'Required';
  if (!values.getIn(['platforms', 'value']).size) errors.platforms = 'There must be at least 1';
  if (!values.getIn(['genres', 'value']).size) errors.genres = 'There must be at least 1';
  if (!values.getIn(['tools', 'value']).size) errors.tools = 'There must be at least 1';
  if (!values.getIn(['tags', 'value']).size) errors.tags = 'There must be at least 1';
  if (!values.getIn(['skills', 'value']).size) errors.skills = 'There must be at least 1';

  return errors;
};

const AddNewExperience = ({ experienceId, mode, toggleMode, updateExperienceId }) => {
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const experience = useSelector(profileSelectors.selectProfileProperty(`experience.${experienceId}`));
  const isEditMode = ['edit', 'add'].includes(mode);
  const dispatch = useDispatch();
  const { handleScroll } = useScrollToMandatoryField();

  const {
    dirty,
    errors,
    initialize,
    onFieldChange,
    onFieldDelete,
    onFieldInsert,
    onFiledSet,
    onSubmit,
    submitting,
    triggerErrors,
    values
  } = useForm({
    doNotScroll: true,
    fields,
    onValidate,
    onSubmitSuccess: () => toggleMode(),
    callApi: values =>
      talentService.createExperience(profileId, transform(values)).then(
        ({
          data: {
            data: { credits, experience, mediaCoverage }
          }
        }) => {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'experience',
              updateType: values.experienceId ? 'update' : 'insert',
              value: experience,
              profileId
            })
          );

          credits.forEach(credit => {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'credits',
                updateType: 'insert',
                value: credit,
                profileId
              })
            );
          });
          mediaCoverage.forEach(coverage => {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'mediaCoverage',
                updateType: 'insert',
                value: coverage,
                profileId
              })
            );
          });
        }
      )
  });

  const onCollaboratorCustomValidate = formFields => {
    const email = formFields.getIn(['email', 'value']);
    const alreadyExists = values.collaborators.find(item => item.email === email);

    return alreadyExists ? { email: `${email} already exists` } : {};
  };

  React.useEffect(() => {
    if (experienceId) {
      initialize({
        experienceId: experience._id,
        companyAndRole: experience.companyAndRole,
        currentPosition: !experience.companyAndRole.endDate,
        accomplishments: experience.achievements,
        collaborators: experience.collaborators,
        credits: experience.credits,
        mediaCoverage: experience.mediaCoverage,
        platforms: experience.platforms,
        genres: experience.genres,
        tools: experience.tools,
        skills: experience.skills,
        tags: experience.tags
      });
    }

    return () => updateExperienceId();
  }, []);

  const onCreditAdd = credit => {
    const changeHandler = onFieldInsert('credits');
    return Promise.resolve(changeHandler({ ...credit, localId: Date.now() }));
  };

  const onCreditDelete = ({ creditId, localCreditId }) => {
    const changeHandler = onFieldDelete('credits');
    const value = creditId || localCreditId;
    const key = creditId ? '_id' : 'localId';

    changeHandler(value, key);
  };

  const onMediaCoverageAdd = mediaCoverage => {
    const changeHandler = onFieldInsert('mediaCoverage');
    return Promise.resolve(changeHandler({ ...mediaCoverage, localId: Date.now() }));
  };

  const onMediaCoverageDelete = ({ localMediaId, mediaId }) => {
    const changeHandler = onFieldDelete('mediaCoverage');
    const value = mediaId || localMediaId;
    const key = mediaId ? '_id' : 'localId';
    changeHandler(value, key);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { errors } = triggerErrors()(onValidate);
    handleScroll(errors);
    if (errors) return;
    onSubmit();
  };

  return (
    <TalentExperienceContext.Provider value={{ values, onFieldChange }}>
      <Prompt when={dirty} message="Are you sure you want to leave?" />
      <StyledAddNewExperienceContainer>
        <CompanyAndRole error={errors.companyAndRole} isEditMode={isEditMode} />
        <Accomplishments error={errors.accomplishments} isEditMode={isEditMode} />

        <CollaboratorsFrame
          error={errors.collaborators}
          onValidate={onCollaboratorCustomValidate}
          isEditMode={isEditMode}
          collaborators={values.collaborators}
          onFieldChange={onFieldChange('collaborators')}
          emptyLabel="Add collaborators you’ve worked with to extend your network and increase your chances of getting discovered."
          fullLabel="Add collaborators you’ve worked with to extend your network and increase your chances of getting discovered."
        />

        <Credits
          error={errors.credits}
          items={values.credits}
          isEditMode={isEditMode}
          hideDropDown
          chooseFromExisting
          required
          hintText="Did you get any game credits at this place? Put ‘em here"
          onDelete={onCreditDelete}
          onChoose={creditIds => onFiledSet('credits')(creditIds)}
          onAdd={onCreditAdd}
          formExperience
        />

        <MediaCoverageFrame
          error={errors.mediaCoverage}
          items={values.mediaCoverage}
          onAdd={onMediaCoverageAdd}
          required
          onDelete={onMediaCoverageDelete}
          onChoose={mediaIds => onFiledSet('mediaCoverage')(mediaIds)}
          isEditMode={isEditMode}
          hintText="Did you get any positive reviews for the work you did?"
        />

        <StyledAllAdditionalTags
          page="experiense"
          order={['platforms', 'genres', 'tools', 'skills', 'tags']}
          errors={errors}
          isEditMode={isEditMode}
          hintTexts={hintTexts}
          required
          data={values}
          hasDragPermission={hasEditPermission}
          afterDrag={(type, data) => onFieldChange(type)(data)}
          onAdd={({ data, type }) => onFieldChange(type)(data)}
          callService={false}
        />

        {mode === 'edit' && (
          <EditExperienceFooter
            save={handleSubmit}
            submitting={submitting}
            experienceId={experienceId}
            toggleMode={toggleMode}
            dirty={dirty}
          />
        )}
        {mode === 'add' && (
          <AddExperienceFooter save={handleSubmit} submitting={submitting} toggleMode={toggleMode} dirty={dirty} />
        )}
      </StyledAddNewExperienceContainer>
    </TalentExperienceContext.Provider>
  );
};

export default AddNewExperience;
