import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import { useSelector } from 'react-redux';
import { selectMyProfileId } from 'store/profile/profileSelectors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 240px;
  max-width: 240px;
  height: auto;
  word-break: break-word;
  ${props => props.index === 0 && 'border-top:  2px solid rgba(204, 213, 255, 0.11)'};
  ${props => props.length - 1 === props.index && 'border-bottom:  2px solid rgba(204, 213, 255, 0.11)'};
  color: white;

  &:hover {
    cursor: pointer;
    background: rgba(204, 213, 255, 0.04);
  }
  &:hover {
    .header-icon path {
      fill: white;
    }
    .header-Text {
      color: white;
    }
  }
`;

const StyledIcon = styled(Icon)`
  margin: 16px;
  margin-right: 12px;
`;

const Text = styled.div`
  ${commonStyles.ui_text_medium}
  ${({ textWidth }) => (textWidth ? `width: ${textWidth};` : 'flex: 1;')}
  padding: 13px 0;
  color: rgba(230, 234, 255, 0.6);
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledAvatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 13px;
  background-image: url('${props => props.src}');
  background-size: contain;
  margin: 12px;
`;

const BackofficeUserMenuItem = ({
  avatar,
  className,
  icon,
  index,
  length,
  onClick,
  onMouseEnter,
  onMouseLeave,
  profileId,
  secondaryIcon,
  text,
  textWidth,
  url = '#'
}) => {
  const myProfileId = useSelector(selectMyProfileId);
  return (
    <LinkWrapper to={url} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Wrapper className={className} index={index} length={length}>
        {avatar ? (
          <StyledAvatar src={avatar} />
        ) : (
          <StyledIcon icon={icon} className="header-icon" color=" rgba(230, 234, 255, 0.6)" />
        )}
        <Text className="header-Text" textWidth={textWidth}>
          {text}
        </Text>
        {secondaryIcon && <StyledIcon icon={secondaryIcon} className="header-icon" color=" rgba(230, 234, 255, 0.6)" />}
        {profileId && myProfileId === profileId && <StyledIcon icon="checkmark" />}
      </Wrapper>
    </LinkWrapper>
  );
};

export default BackofficeUserMenuItem;
