import React from 'react';

import Frame from 'common/Frame';
import Tags from 'common/Tags';
import Typography from 'components/shared/Typography';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

const GeneralTags = ({
  isEditMode = true,
  error,
  data = [],
  onAction,
  hintText,
  required,
  sort,
  hasDragPermission = false,
  service = false,
  hideEmpty = false,
  afterDrag,
  project = false,
  callService,
  titlePB = 16
}) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const mode = data.length ? 'edit' : 'add';

  return (
    <>
      {(!hasPermission || hideEmpty) && (data.length === 0 || data[0] === null) ? (
        <></>
      ) : (
        <Frame
          project={project}
          error={error}
          required={required}
          color="#968DFF"
          text="Tags"
          titlePB={titlePB}
          canEdit={isEditMode}
          editMode={mode}
          onEdit={onAction}
          filled={!!data.length}
          noDataText={hintText || 'Please select tags'}
          id="tags"
          className="tags-required"
        >
          <Tags
            tags={data}
            project={project}
            shouldToggle
            type="tags"
            canEdit={isEditMode}
            sort={sort}
            hasDragPermission={hasDragPermission}
            service={service}
            afterDrag={afterDrag}
            callService={callService}
          />
          {error && (
            <Typography mt={30} error variant="small">
              {error || 'There must be at least 1'}
            </Typography>
          )}
        </Frame>
      )}
    </>
  );
};

export default GeneralTags;
