export default {
  '/': {
    title: 'Top Vetted Video Games Talent, On Demand',
    description:
      'GREAT GAMES START WITH GREAT PEOPLE. Request early access to join TheXPlace - the top vetted video games professional community.',
    keywords: 'TheXPlace, Video Games, Talent, Marketplace, jobs',
    image: 'https://www.thex.place/assets/splash-page-og.png'
  },
  '/splash-page': {
    keywords:
      'Job(s), Audio Design(er), Sound Design(er), Video Game(s), Marketplace, Freelancer, Audio Director.Splash-Page Keyword, Home Keywords2, Home Keywords3...'
  }
};
