import unset from 'lodash/fp/unset';
import set from 'lodash/fp/set';

import * as portalActionTypes from './portalActionTypes';

const initialValue = {};

const portalsReducer = (state = initialValue, { payload, type }) => {
  switch (type) {
    case portalActionTypes.OPEN_PORTAL:
      return set(payload.name, { open: true, data: payload.data || {} }, state);

    case portalActionTypes.CLOSE_PORTAL:
      return unset(payload.name, state);

    case portalActionTypes.CLEAR_PORTAL:
      return initialValue;

    default:
      return state;
  }
};

export default portalsReducer;
