import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { pageSize } from 'utils/devices';
import useViewport from 'hooks/useViewport';
import useOutsideClick from 'hooks/useOutsideClick';
import Icon from 'common/Icon';

import { companyItems, userItems } from './GalleryMenuConfig';
import GalleryMenuItem from './GalleryMenuItem';
import { profileSelectors } from '../../../store/profile';

const Wrapper = styled.div`
  overflow-x: scroll;
  max-width: 100vw;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const WrapAll = styled.div`
  position: sticky !important;
  top: 0px;
  background-color: #111112;
  height: 64px;
  margin-bottom: 80px;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  display: flex;
  flex-direction: row;
  position: relative;

  background-color: #111112;
  max-width: 100vw;
  z-index: 10;

  @media ${pageSize.S} {
    height: 64px;
    top: 0;
    margin-top: 101px;
    &.lifePage {
      margin-bottom: 32px;
    }
  }
  @media ${pageSize.XS} {
    height: 56px;
    top: 56px;
    margin-top: 101px;
    &.lifePage {
      margin-bottom: 32px;
    }
  }
`;
const MyDivLeft = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;
  background-color: #111112;
  width: 40px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid rgba(204, 213, 255, 0.11);
  ${props => (props.hide ? 'display: none;' : '')}
`;
const MyDivRight = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  background-color: #111112;
  width: 40px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgba(204, 213, 255, 0.11);
  ${props => (props.hide ? 'display: none;' : '')}
`;
const MoreWrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgba(230, 234, 255, 0.6);
  cursor: pointer;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
  &.selected {
    color: #00ff00;
    border-bottom: 2px solid #00ff00;
    svg path {
      fill: #00ff00;
    }
  }
  @media ${pageSize.M} {
    font-size: 13px;
    height: 64px;
  }
  @media ${pageSize.S} {
    font-size: 12px;
  }
  svg {
    margin-left: 10px;
    margin-top: 1px;
  }

  &:hover,
  &:hover svg {
    color: white;
  }

  &:hover svg path {
    fill: white;
  }

  ${props => (props.hide ? 'display: none' : '')}
`;
const MoreItemsWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  box-sizing: border-box;
  background-color: #1a1b1e;
  border: 2px solid rgba(204, 213, 255, 0.11);
  text-transform: initial;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 30px;
  z-index: 100;
  padding: 8px 0;

  ${props => (props.hide ? 'display: none' : '')}
`;
const MoreItemWrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 240px;
  max-width: 240px;
  padding: 8px 16px;
  color: rgba(230, 234, 255, 0.6);

  &:hover {
    cursor: pointer;
    background: rgba(204, 213, 255, 0.04);
  }
  &:hover {
    svg path {
      fill: white;
    }
    color: white;
  }

  svg {
    margin-right: 16px;
  }

  &.selected {
    color: #00ff00;

    svg path {
      fill: #00ff00;
    }
  }

  ${props => props.disabled && 'color: rgba(221, 226, 255, 0.2); pointer-events:none'}
`;

const GalleryMenu = ({ pathname, selectedTab }) => {
  const refMenu = useRef();
  const refMoreItems = useRef();
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profileMarketType = useSelector(profileSelectors.selectMyProfileProperty('isSupply'));
  const items = isCompanyView ? companyItems : userItems;
  const { isL, isM, isS, isXS, width } = useViewport();

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [moreItemsOpen, setMoreItemsOpen] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [moreItems, setMoreItems] = useState([]);

  const filteredItems = useMemo(() => {
    return profileMarketType === 'supply' ? items.filter(item => item?.tabName !== 'jobs') : items;
  }, [items]);

  useEffect(() => {
    const showHideArrows = ({ offsetWidth, scrollLeft, scrollWidth }) => {
      if (scrollLeft === 0) {
        setShowRightArrow(true);
      } else {
        setShowRightArrow(false);
      }

      if (offsetWidth + scrollLeft === scrollWidth) {
        setShowLeftArrow(true);
      } else {
        setShowLeftArrow(false);
      }
    };

    const handleScroll = e => {
      showHideArrows(e.target);
    };

    showHideArrows(refMenu.current);
    if (refMenu.current) refMenu.current.addEventListener('scroll', handleScroll);

    return () => refMenu?.current?.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLeftClick = () => {
    refMenu.current.scrollLeft = 0;
  };

  const handleRightClick = () => {
    refMenu.current.scrollLeft = 2000;
  };

  useOutsideClick(refMoreItems, () => {
    if (moreItemsOpen) setMoreItemsOpen(false);
  });

  useEffect(() => {
    if (isXS) {
      setVisibleItems(filteredItems);
      setMoreItems([]);
    } else if ((isS && width < 880) || (isM && width < 1178)) {
      setVisibleItems(filteredItems.slice(0, 4));
      setMoreItems(filteredItems.slice(4));
    } else if (isS) {
      setVisibleItems(filteredItems.filter(item => item.views.includes('isS')));
      setMoreItems(filteredItems.filter(item => !item.views.includes('isS')));
    } else if (isM) {
      setVisibleItems(filteredItems.filter(item => item.views.includes('isM')));
      setMoreItems(filteredItems.filter(item => !item.views.includes('isM')));
    } else if (isL) {
      setVisibleItems(filteredItems.filter(item => item.views.includes('isL')));
      setMoreItems(filteredItems.filter(item => !item.views.includes('isL')));
    }
  }, [isXS, isS, isM, isL, width, isCompanyView]);

  const showMoreBtn = moreItems.length > 0;

  const pathArary = pathname.split('/') || [];
  pathArary.pop();
  const basePath = pathArary.join('/');

  return (
    <WrapAll className={window.location.pathname.includes('/life') ? 'lifePage' : ''}>
      {isXS && (
        <MyDivLeft onClick={handleLeftClick} hide={!showLeftArrow}>
          <Icon icon="back" color="rgba(230, 234, 255, 0.6)" hoverColor="white" />
        </MyDivLeft>
      )}

      <Wrapper ref={refMenu}>
        {visibleItems.map(item => (
          <GalleryMenuItem
            key={item.id}
            id={item.id}
            icon={item.icon}
            tabName={item.tabName}
            text={item.text}
            pathname={pathname}
            isSelected={selectedTab === item.tabName}
            size={item.size}
            color={item.color}
            disabled={item.disabled}
            tooltip={item.tooltip}
          />
        ))}
      </Wrapper>
      <MoreWrapper
        className={`${moreItems.find(item => item.tabName === selectedTab) ? 'selected' : ''}`}
        hide={!showMoreBtn}
        onClick={() => setMoreItemsOpen(!moreItemsOpen)}
      >
        <div>MORE</div>
        <Icon icon="arrow-down" color="rgba(230, 234, 255, 0.6)" hoverColor="white" />
      </MoreWrapper>

      <MoreItemsWrapper ref={refMoreItems} hide={!moreItemsOpen}>
        {moreItems.map(item => (
          <MoreItemWrapper
            key={item.id}
            isSelected={selectedTab === item.tabName}
            className={`${selectedTab === item.tabName ? 'selected' : ''}`}
            to={`${basePath}/${item.tabName}`}
            onClick={() => setMoreItemsOpen(false)}
            disabled={item.disabled}
          >
            <Icon
              icon={item.icon}
              color={`${item.disabled ? 'rgba(221, 226, 255, 0.2)' : 'rgba(230, 234, 255, 0.6)'}`}
              hoverColor="white"
            />
            {item.text}
          </MoreItemWrapper>
        ))}
      </MoreItemsWrapper>

      {isXS && (
        <MyDivRight onClick={handleRightClick} hide={!showRightArrow}>
          <Icon icon="next" color="rgba(230, 234, 255, 0.6)" hoverColor="white" />
        </MyDivRight>
      )}
    </WrapAll>
  );
};

export default GalleryMenu;
