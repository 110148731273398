import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import ReactAvatarEditor from 'react-avatar-editor';
import { useDebouncedCallback } from 'use-debounce';

import ContainedButton from 'common/ContainedButton';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 30px;
`;

const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  color: white;
`;

const EmptyImage = styled.div`
  min-width: 165px;
  min-height: 165px;
  border: 2px dashed rgba(230, 234, 255, 0.35);
  border-radius: 50%;
  margin: 20px;

  @media ${pageSize.XS} {
    margin-left: 0px;
    margin-right: 12px;
  }
`;

const StyledHintText = styled.label`
  ${commonStyles.ui_text_small};
  color: rgba(230, 234, 255, 0.35);
  ${props => props.error && 'color: #FF5151;'}
`;

const AvatarUploader = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  @media ${pageSize.XS} {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
`;

const UploadWrapper = styled.div`
  width: 165px;
  margin: 24px;

  @media ${pageSize.XS} {
    margin-left: 12px;
    width: auto;
  }
`;

const StyledError = styled.div`
  font-size: 13px;
  color: #ff5151;
  margin-bottom: 16px;
`;

const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);
  padding-left: 3px;
  &::after {
    content: '*';
  }
`;

const FormAvatar = ({ errors, hintText, label, onChange, required, value, ...rest }) => {
  const [scale, setScale] = useState(1.0);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [image, setImage] = useState(value);
  const [error, setError] = useState();
  const [showZoom, setShowZoom] = useState(false);
  const [, setCanvasImage] = useState();

  const editorRef = useRef();
  const inputTypeFileRef = useRef();

  const { callback: debouncedOnChange } = useDebouncedCallback(() => {
    if (editorRef.current) {
      const val = editorRef.current.getImage()?.toDataURL();
      setCanvasImage(val);
      onChange(val);
    }
  }, 200);

  const fileSizeIsValid = file => {
    const fileSizeInMb = file.size / 1024 / 1024;

    return fileSizeInMb <= 0.5;
  };

  const fileExtensionIsValid = file => {
    const validExtensions = ['png', 'jpg', 'jpeg', 'gif'];
    const fileExtension = file.type.split('/').reverse()[0];
    return validExtensions.includes(fileExtension);
  };

  const fileDimensionsAreValid = file => {
    const img = document.createElement('img');

    return new Promise(resolve => {
      img.onload = function () {
        // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
        // The natural size is the actual image size regardless of rendering.
        // The 'normal' width/height are for the **rendered** size.

        const width = img.naturalWidth || img.width;
        const height = img.naturalHeight || img.height;

        if (height < 300 || width < 300) resolve(false);
        return resolve(true);
      };
      img.src = window.URL.createObjectURL(file);
    });
  };

  const handleInputTypeFileClicked = () => {
    inputTypeFileRef.current.click();
    setError('');
  };

  const handlePositionChange = position => {
    setPosition(position);
    debouncedOnChange();
  };

  const handleNewImage = async e => {
    e.persist();

    const extensionIsValid = fileExtensionIsValid(e.target.files[0]);
    const dimensionIsValid = await fileDimensionsAreValid(e.target.files[0]);
    const sizeIsValid = fileSizeIsValid(e.target.files[0]);

    if (sizeIsValid && dimensionIsValid) {
      setImage(e.target.files[0]);
      setShowZoom(true);
      debouncedOnChange();
    } else {
      e.target.value = null;

      if (!extensionIsValid) {
        setError('Unsupported format');
        return;
      }

      if (!sizeIsValid) {
        setError('Heavy weight');
        return;
      }

      if (!dimensionIsValid) {
        setError('insufficient dimensions');
      }
    }
  };

  const handleScale = e => {
    setScale(parseFloat(e.target.value));
    debouncedOnChange();
  };

  useEffect(() => {
    setError(errors);
  }, [errors]);

  // useEffect(
  // 	() => setImage(value),
  // 	[value, setImage]
  // )

  return (
    <InputWrapper {...rest}>
      <StyledLabel>
        {label}
        {required && <Required />}
      </StyledLabel>
      <AvatarUploader>
        {image && (
          <div>
            <ReactAvatarEditor
              scale={scale}
              width={165}
              height={165}
              position={position}
              onPositionChange={handlePositionChange}
              rotate={0}
              color={[26, 27, 30]}
              borderRadius={165 / 2}
              image={image}
              ref={editorRef}
              className="editor-canvas"
            />
            {showZoom && (
              <div>
                Zoom:
                <input name="scale" type="range" onChange={handleScale} min="1" max="10" step="0.01" defaultValue="1" />
              </div>
            )}
          </div>
        )}
        {!image && <EmptyImage />}
        <UploadWrapper>
          <ContainedButton
            type="button"
            icon="upload"
            onClick={handleInputTypeFileClicked}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
          >
            Upload
          </ContainedButton>
          <StyledError>{error && `* ${error}`}</StyledError>
          <StyledHintText error={error}>{hintText}</StyledHintText>

          <input
            name="newImage"
            type="file"
            id="upload"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={handleNewImage}
            ref={inputTypeFileRef}
            style={{ display: 'none' }}
          />
        </UploadWrapper>
      </AvatarUploader>
    </InputWrapper>
  );
};

export default FormAvatar;
