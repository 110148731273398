/* eslint-disable react/display-name */
import { tagService } from 'api';
import { nanoid } from 'nanoid';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import useTagService from 'hooks/api/useTagService';
import useUserService from 'hooks/api/useUserService';
import useForm from 'hooks/useForm';
import sortBy from 'lodash/fp/sortBy';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tagActions, tagSelectors } from 'store/tags';
import collaboratorUtils from 'utils/collaboratorUtils';
import { getFormSelectTypeArray } from 'utils/tagHelpers';
import { StyledContainer } from './AddCollaboratorStyles';

const fields = initialValues => ({
  name: {
    initialValue: initialValues.name ?? '',
    validate: ['isRequired'],
    trim: true
  },
  email: {
    initialValue: initialValues.email ?? '',
    validate: ['isRequired', 'isEmail']
  },
  collaborator: {
    initialValue: initialValues.collaborator ?? '',
    validate: []
  },
  profile: { initialValue: initialValues.profile, validate: [] },
  collaborationType: {
    initialValue: initialValues.collaborationType ?? '',
    validate: ['isRequired']
  },
  role: { initialValue: initialValues.role ?? '', validate: ['isRequired'] },
  collaborationTypeOther: {
    initialValue: initialValues.collaborationTypeOther ?? '',
    validate: []
  },
  collaboratorProfile: { initialValue: null }
});

const AddCollaborator = forwardRef(({ onAdd, onValidate, setDoneStep }, ref) => {
  const dispatch = useDispatch();

  const { getTags } = useTagService();
  const { searchUserByEmail } = useUserService();

  const tagRoles = useSelector(tagSelectors.selectTagsByType('roles'));

  const [roles, setRoles] = useState({
    options: sortBy(getFormSelectTypeArray({ arr: tagRoles.data })),
    value: getFormSelectTypeArray({ arr: [] })
  });

  const [loading, setLoading] = useState();
  const timerRef = useRef();

  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields: fields({}),
    callApi: async params => {
      setDoneStep();
      onAdd({
        ...params,
        collaborationType: params.collaborationTypeOther || params.collaborationType.value,
        collaborator: params.collaborator || undefined,
        role: params.role.value,
        externalId: nanoid(10)
      });
    },
    onValidate
  });

  const handleCreate = async inputVal => {
    try {
      setLoading(true);

      const {
        data: { data }
      } = await tagService.requestTag({ name: inputVal, type: 'role' });
      const tag = { value: data._id, label: data.name };
      dispatch(tagActions.addTag('roles', data));

      setRoles(currentRoles => ({
        value: currentRoles.value,
        options: [...currentRoles.options, tag]
      }));
      onFieldChange('role')(tag);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = async e => {
    clearTimeout(timerRef.current);

    const email = e.target.value.replace(/[\n\r\s\t]+/g, '');

    onFieldChange('email')(email);
    if (values.collaborator) {
      onFieldChange('collaborator')({ target: { value: undefined } });
      onFieldChange('name')({ target: { value: undefined } });
    }

    timerRef.current = setTimeout(async () => {
      const { data, success } = await searchUserByEmail({ email });

      if (success) {
        const { _id, collaboratorProfile, firstName, lastName, profileId } = data;

        onFieldChange('name')(`${firstName} ${lastName}`);
        onFieldChange('collaborator')(_id);
        onFieldChange('profile')(profileId);
        onFieldChange('collaboratorProfile')(collaboratorProfile || null);
      } else {
        onFieldChange('collaborator')(null);
        onFieldChange('profile')(null);
        onFieldChange('collaboratorProfile')(null);
      }
    }, 1000);
  };

  const handleOptionsOpen = () => {
    const fetchTags = async () => {
      await getTags('role');
    };
    if (!tagRoles.isLoaded) {
      fetchTags();
    }
  };

  useEffect(() => {
    const rolesOptions = tagRoles.data.map(discipline => ({ value: discipline._id, label: discipline.name }));
    setRoles(currentRoles => ({
      value: currentRoles.value,
      options: rolesOptions
    }));
  }, [tagRoles]);

  return (
    <StyledContainer>
      <FormInput
        mb={56}
        label="Collaborator's name"
        required
        value={values.name}
        error={errors.name}
        disabled={!!values.collaborator}
        onChange={onFieldChange('name')}
        className="name-required"
      />
      <FormInput
        mb={56}
        label="Collaborator's email"
        required
        hintText="The automated message we send is polite and tactful—we promise"
        value={values.email}
        error={errors.email}
        onChange={handleEmailChange}
        className="email-required"
      />
      <FormSelect
        pb={56}
        options={collaboratorUtils.collaborationTypeOptions}
        value={values.collaborationType}
        required
        error={errors.collaborationType}
        onChange={onFieldChange('collaborationType')}
        placeholder="Select an option"
        label="Collaboration type"
        hintText="This person was your:"
        className="collaborationType-required"
      />
      {values.collaborationType?.value === 'Other' && (
        <FormInput
          mb={56}
          value={values.collaborationTypeOther}
          error={errors.collaborationTypeOther}
          onChange={onFieldChange('collaborationTypeOther')}
          label="Specify the type of collaboration"
          required
          className="collaborationTypeOther-required"
        />
      )}
      <FormSelect
        value={values.role}
        options={roles.options}
        handleCreate={handleCreate}
        error={errors.role}
        isLoading={tagRoles.loading || loading}
        onChange={onFieldChange('role')}
        onMenuOpen={handleOptionsOpen}
        label="Collaborator’s role"
        isCreatable
        required
        placeholder="Select an option"
        className="role-required"
      />
      <input type={'button'} hidden onClick={onSubmit} ref={ref} />
    </StyledContainer>
  );
});

export default AddCollaborator;
