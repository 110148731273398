import isString from 'lodash/fp/isString';
import options from './options';
import queryString from 'query-string';

const decodeQuery = search => {
  const query = isString(search) ? queryString.parse(search) : search ?? {};
  let filters = {};
  for (const key in query) {
    const newKey = key.replace('filters[', '').replace(']', '');
    const items = isString(query[key]) ? [query[key]] : query[key];

    const asObject =
      newKey === 'search' ||
      newKey === 'firstName' ||
      newKey === 'lastName' ||
      newKey === 'recommended' ||
      newKey === 'createdAt';
    filters = Object.assign(filters, {
      [newKey]: asObject ? { value: query[key] } : options(items, newKey)
    });
  }

  return filters;
};

export default decodeQuery;
