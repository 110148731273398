import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { talentService } from 'api';

import useForm from 'hooks/useForm';
import withRenderPortal from 'hocs/withRenderPortal';

import { profileActions, profileSelectors } from 'store/profile';

import Spinner from 'common/Spinner';
import DeleteProjectModal from 'components/modals/DeleteProjectModal';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import { FooterWrapper, ModalActionsButtons } from './styled.components';
import EditProjectForm from './EditProjectForm';
import EditProjectScopeModal from './EditProjectScopeModal';
import { portalActions } from 'store/portals';
import { getFormSelectTypeArray } from 'utils/tagHelpers';

const reduceTags = tags =>
  (tags ?? []).reduce((curr, tag) => {
    if (tag !== null) {
      curr.push(tag._id ?? tag);
    }
    return curr;
  }, []);

const EditProjectModal = ({ closePortal, data }) => {
  const dispatch = useDispatch();
  const talent = useSelector(profileSelectors.selectProfile);
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const { afterDelete, fromAllJobs, jobId, openScopeModal = false, project, projectId } = data;
  const projectFromSelector = useSelector(
    profileSelectors.selectProfileProperty(`${fromAllJobs ? 'projects' : 'generalProjects'}.${projectId}`)
  );
  const currentProjct = project || projectFromSelector;
  const { cover, coverImage, genres, name, overview, platforms, tags } = currentProjct;

  const platformsIds = reduceTags(platforms);
  const genresIds = reduceTags(genres);
  const tagsIds = reduceTags(tags);

  const [loading, setLoading] = useState(false);

  const platformOptions = getFormSelectTypeArray({ arr: platforms });
  const genresOptions = getFormSelectTypeArray({ arr: genres });
  const tagsOptions = getFormSelectTypeArray({ arr: tags });

  const platformsInitialValues = useMemo(
    () => platformOptions.filter(option => platformsIds.includes(option.value)),
    []
  );
  const genresInitialValues = useMemo(() => genresOptions.filter(option => genresIds.includes(option.value)), []);
  const tagsInitialValues = useMemo(() => tagsOptions.filter(option => tagsIds.includes(option.value)), []);
  const fields = {
    name: { initialValue: name, validate: ['isRequired'] },
    coverImage: {
      initialValue: cover || (typeof coverImage === 'string' ? coverImage : coverImage?.url),
      validate: ['isRequired']
    },
    overview: { initialValue: overview, validate: ['isRequired'] },
    platforms: { initialValue: platformsInitialValues },
    genres: { initialValue: genresInitialValues },
    tags: { initialValue: tagsInitialValues }
  };

  const onValidate = formFields => {
    const customErrors = {};

    if (!formFields.getIn(['name', 'value'])) {
      customErrors.name = 'Name is requird';
    }

    if (!formFields.getIn(['coverImage', 'value'])) {
      customErrors.coverImage = 'Cover imagee is required';
    }

    if (!formFields.getIn(['overview', 'value'])) {
      customErrors.overview = 'Overview is required';
    }

    if (formFields.getIn(['platforms', 'value']).size === 0) {
      customErrors.platforms = 'Platforms is required';
    }

    return customErrors;
  };

  const { errors, onFieldChange, onSubmit, triggerErrors, values } = useForm({
    fields,
    onValidate,
    callApi: params => {
      setLoading(true);
      const formData = new FormData();
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { coverImage, ...updatedData } = params;
      formData.append(
        'data',
        JSON.stringify({
          ...updatedData,
          platforms: params.platforms.map(platform => platform.value),
          genres: params.genres.map(genre => genre.value),
          tags: params.tags.map(tag => tag.value)
        })
      );
      if (typeof values.coverImage !== 'string') {
        formData.append('file', values.coverImage);
      }
      return talentService
        .updateProject(talent._id, projectId, formData)
        .then(res => {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'generalProjects',
              updateType: 'update',
              value: res.data.data,
              profileId
            })
          );
          dispatch(
            profileActions.updateProfileProperty({
              property: 'projects',
              updateType: 'update',
              value: res.data.data,
              profileId
            })
          );
          if (data.afterSave) {
            data.afterSave(res.data.data);
          }
          closePortal();
          setLoading(false);
        })
        .catch(error => {
          console.log('error', error);
          setLoading(false);
        });
    }
  });

  const openEditProjectScopeModal = () => {
    const { errors } = triggerErrors()(onValidate);
    if (errors) return;
    dispatch(
      portalActions.openPortal({
        name: 'edit-project-scope-modal',
        data: {
          talentId: talent._id,
          projectId,
          jobId,
          project: values,
          afterSave: data.afterSave,
          closeProjectModal: closePortal
        }
      })
    );
  };

  return (
    <TxplModal
      title="Project"
      name="edit-project-modal"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <FooterWrapper>
          <ContainedButton
            icon="delete"
            style={{ border: '2px solid rgba(204, 213, 255, 0.11)' }}
            backgroundColor="transparent"
            color="#FF5151"
            opens="delete-project-modal"
            data={{ projectId, isGeneral: true, closeParentModal: closePortal, afterDelete }}
            disabled={loading}
          >
            Delete
          </ContainedButton>
          <ModalActionsButtons>
            {loading && <Spinner />}
            <ContainedButton
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              mr={6}
              disabled={loading}
            >
              Cancel
            </ContainedButton>

            <ContainedButton
              onClick={openScopeModal ? openEditProjectScopeModal : onSubmit}
              backgroundColor="#7266EE"
              forceDisplayText
              disabled={loading}
            >
              Save
            </ContainedButton>
          </ModalActionsButtons>
          <DeleteProjectModal />
        </FooterWrapper>
      }
    >
      <EditProjectForm values={values} onFieldChange={onFieldChange} preview={data?.project?.preview} errors={errors} />
      <EditProjectScopeModal />
    </TxplModal>
  );
};

export default withRenderPortal('edit-project-modal')(EditProjectModal);
