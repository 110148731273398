import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import FormTextarea from 'common/form/FormTextarea';
import TxplModal from 'common/TxplModal';
import FormDateInput from 'common/form/FormDateInput';
import Typography from 'components/shared/Typography';
import GameSearch from 'components/GameSearch';

import useMediaCoverage from '../hooks/useMediaCoverage';

const StyledCancelButton = styled(ContainedButton)`
  margin-right: 3px;
`;

const MediaCoverageModal = ({ closePortal, data: { mediaId }, loading, name, onAdd }) => {
  const { errors, onFieldChange, onSubmit, submitting, values } = useMediaCoverage({ mediaId, onAdd });

  const handleSetTitle = option => (option ? onFieldChange('game')(option) : onFieldChange('game')(null));

  return (
    <TxplModal
      name={name}
      testTitle
      title="Media Coverage"
      padding="56px 0 82px 0"
      titlePadding="16px"
      addPaddingTop
      XSpadding
      boldWeight
      allowLine
      XSaddPaddingTop={'14px'}
      appElement={document.getElementById('root-modal')}
      contentLeft={
        mediaId ? (
          <ContainedButton
            icon="delete"
            opens="delete-media-coverage-modal"
            closes="media-coverage-modal"
            data={{ mediaId }}
            iconColor="#FF5151;"
            backgroundColor="transparent"
            color="rgba(204, 213, 255, 0.11);"
          />
        ) : null
      }
      renderFooter={
        <>
          {(submitting || loading) && <Spinner />}
          <StyledCancelButton disabled={submitting} onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </StyledCancelButton>
          <ContainedButton
            disabled={submitting}
            type="submit"
            form="mediaCoverageModal"
            data-testid="save-portfolio-mediacoverage"
          >
            {mediaId ? 'save' : 'add'}
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={onSubmit} id="mediaCoverageModal">
        <FormTextarea
          label="The quote"
          required
          hintText="Include the part that highlights your achievements here. Copy. Paste. Shine"
          maxLength={600}
          value={values.quote}
          onChange={onFieldChange('quote')}
          error={errors.quote}
          mb={56}
          boldWeight
          lightWeight
          id="quote"
          className="quote-required"
        />

        <FormInput
          label="The source"
          hintText="What’s the name of the media outlet?"
          required
          value={values.mediaTitle}
          onChange={onFieldChange('mediaTitle')}
          error={errors.mediaTitle}
          mb={56}
          boldWeight
          lightWeight
          id="mediaTitle"
          className="mediaTitle-required"
        />

        <FormDateInput
          label="The date"
          hintText="When was the article published?"
          placeholder="MM/DD/YYYY"
          required
          value={values.articlePublishDate}
          error={errors.articlePublishDate}
          onChange={onFieldChange('articlePublishDate')}
          mb={56}
          boldWeight
          lightWeight
          id="articlePublishDate"
          className="articlePublishDate-required"
        />

        <FormInput
          label="The link"
          required
          value={values.articleLink}
          onChange={onFieldChange('articleLink')}
          error={errors.articleLink}
          hintText="You know, to the article"
          mb={56}
          boldWeight
          lightWeight
          id="articleLink"
          className="articleLink-required"
        />

        <GameSearch
          isClearable
          label="Official game title"
          hintText="If cited in the article, add the name of the game you helped ship"
          boldWeight
          lightWeight
          noOptionsMessage={({ inputValue }) => (
            <Typography variant="medium">
              {!inputValue ? (
                'Please search game.'
              ) : (
                <span>
                  No games found for: <u>{inputValue}</u>
                </span>
              )}
            </Typography>
          )}
          value={values.game?.title}
          onChange={option => {
            handleSetTitle(option);
          }}
          error={errors.game}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('media-coverage-modal')(MediaCoverageModal);
