import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import Icon from 'common/Icon';
import Message from 'common/form/Message';
import useProfilePath from 'hooks/useProfilePath';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import { getArray } from 'utils/convertObjToArray';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { profileSelectors } from 'store/profile';

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  height: 96px;
  z-index: 10;
  display: flex;
  align-items: center;
  ${props => (props.hide ? 'display: none;' : '')}
  @media ${pageSize.M} {
    height: 144px;
  }
  @media ${pageSize.L} {
    height: 144px;
  }
`;
const Line = styled.div`
  ${commonStyles.caption}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & .text {
    padding: 0 8px;
    white-space: nowrap;
  }
`;
const MiddlePart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & .text {
    padding: 0 8px;
    white-space: nowrap;
  }
  & .text-spacer {
    display: inline-block;
    width: 8px;
  }
  @media ${pageSize.XS} {
    & .text {
      display: none;
    }
    & .text-spacer {
      width: 24px;
    }
  }
`;
const Avatar = styled.img`
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;
const Underline = styled.span`
  text-decoration: underline;
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    color: rgba(229, 234, 255, 0.6);
  }
`;

const PortfolioMenu = ({ hide, hideNav, portfolioId }) => {
  const cardRef = useRef();
  const { getProfilePath } = useProfilePath();

  const talent = useSelector(profileSelectors.selectProfile);
  const portfolioList = useSelector(profileSelectors.selectProfileProperty('projects')) || {};
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const { name, publicUrl: talentId, type } = talent;

  const profilePath = getProfilePath(talentId, type !== 'talent');

  const projects = getArray(talent.projects).filter(project =>
    hasEditPermission ? !project.isGeneral : project.status === 'published' && !project.isGeneral
  );
  const firstName = useMemo(() => name?.split(' ')[0], [name]);
  const portfolioItem = useMemo(() => portfolioList[portfolioId], [portfolioList, portfolioId]);

  const [forwardColor, setForwardColor] = useState();
  const [backColor, setBackColor] = useState();
  const [showMessage, setShowMessage] = useState(
    portfolioItem?.vetting?.status === 'IN_PROGRESS' &&
      portfolioItem?.lockData?.status === 'UNLOCKED' &&
      portfolioItem?.lockData?.unlockedDate
  );

  const hoverColor = 'rgba(229, 234, 255, 0.6)';

  const handleForwardMouseEnter = () => setForwardColor(hoverColor);
  const handleForwardMouseLeave = () => setForwardColor(null);
  const handleBackMouseEnter = () => setBackColor(hoverColor);
  const handleBackMouseLeave = () => setBackColor(null);

  const { next, prev } = useMemo(() => {
    const curr = projects.findIndex(item => item._id === portfolioId);

    return {
      next: projects[curr + 1] ? projects[curr + 1]?._id : projects[0]?._id,
      prev: projects[curr - 1] ? projects[curr - 1]?._id : projects[projects.length - 1]?._id
    };
  }, [portfolioId, projects]);

  useScrollPosition(
    ({ currPos }) => {
      if (Math.abs(currPos.y) > 200) {
        cardRef.current.setAttribute(
          'style',
          'background: linear-gradient(#111112 0%, rgba(17, 17, 18, 0.85) 35%, rgba(17, 17, 18, 0) 100%);'
        );
      } else {
        cardRef.current.removeAttribute('style');
      }
    },
    [],
    false,
    false,
    100
  );

  return (
    <Wrapper ref={cardRef} hide={hide}>
      <Line>
        <LeftSide>
          <StyledLink to={`${profilePath}/portfolio`}>
            <Avatar
              src={talent?.basicInfo?.imageUri?.url || `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`}
            />
            <span className="text">Back to</span>
            <Underline>{`${firstName}'s portfolio`}</Underline>
          </StyledLink>
        </LeftSide>
        {hasEditPermission && <MiddlePart>{showMessage && <Message setShowMessage={setShowMessage} />}</MiddlePart>}

        {!hideNav && !!projects.length && (
          <RightSide>
            <StyledLink
              to={`${profilePath}/portfolio/${prev}`}
              onMouseEnter={handleBackMouseEnter}
              onMouseLeave={handleBackMouseLeave}
            >
              <Icon icon="back" color={backColor} />
              <span className="text">Previous project</span>
            </StyledLink>
            <span className="text-spacer">&nbsp;</span>
            <StyledLink
              to={`${profilePath}/portfolio/${next}`}
              onMouseEnter={handleForwardMouseEnter}
              onMouseLeave={handleForwardMouseLeave}
            >
              <span className="text">Next project</span>
              <Icon icon="forward" color={forwardColor} />
            </StyledLink>
          </RightSide>
        )}
      </Line>
    </Wrapper>
  );
};

export default PortfolioMenu;
