import React from 'react';
import { useDispatch } from 'react-redux';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import styled from '@emotion/styled';
import Spinner from 'common/Spinner';
import useAppliedJobService from 'hooks/api/useAppliedJobService';
import { portalActions } from 'store/portals';

const Text = styled.form`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(242, 244, 255, 0.9);
`;

const JobWithDrawModal = ({ closePortal, data, name }) => {
  const dispatch = useDispatch();
  const { jobId, profileId, setCurrentJob } = data;

  const {
    loading: { loadingWithdraw },
    withdrawFromJob
  } = useAppliedJobService();

  const handleWithdraw = () => {
    withdrawFromJob(profileId, jobId, job => {
      setCurrentJob && setCurrentJob(job);
      closePortal();
      setCurrentJob &&
        dispatch(
          portalActions.openPortal({
            name: 'job-success-modal',
            data: {
              text: 'Your application was withdrawn'
            }
          })
        );
    });
  };

  return (
    <TxplModal
      title="Withdraw application"
      titleMB="24"
      name={name}
      size="small"
      padding="40px 0 56px"
      noPaddingTop
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loadingWithdraw && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton
            icon="ignore"
            backgroundColor="#FF5151"
            form="jobWithdrawModal"
            type="button"
            disabled={loadingWithdraw}
            onClick={handleWithdraw}
          >
            Withdraw
          </ContainedButton>
        </>
      }
    >
      <Text id="jobWithdrawModal" autoFocus>
        Are you sure you want to withdraw this application?
      </Text>
    </TxplModal>
  );
};

export default withRenderPortal('job-withdraw')(JobWithDrawModal);
