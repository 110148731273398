import isEmpty from 'lodash/fp/isEmpty';

const isPropertyPopulated = object => {
  if (isEmpty(object)) {
    return false;
  }
  for (const item of Object.values(object)) {
    const isPopulated = Array.isArray(item) ? !!item.length : !isEmpty(item);
    if (isPopulated) {
      return true;
    }
  }
  return false;
};

export default isPropertyPopulated;
