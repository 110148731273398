import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { backOfficeSelectors } from 'store/backOffice';

import BackofficeBreadCrumbsItem from './BackofficeBreadCrumbsItem';

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BackofficeBreadCrumbs = ({ specificPage }) => {
  const title = useSelector(backOfficeSelectors.selectBackOfficeTitle);

  return (
    <StyledTitleWrapper>
      {title.map((t, index) => {
        const isLast = title.length - 1 === index;

        return (
          <BackofficeBreadCrumbsItem
            key={t.label}
            isLast={isLast}
            url={t.url}
            label={t.label}
            specificPage={specificPage}
          />
        );
      })}
    </StyledTitleWrapper>
  );
};

export default BackofficeBreadCrumbs;
