import React from 'react';
import FormSelect from 'common/form/FormSelect';
import { Container } from './Steps.styled';

const ToolkitInfo = ({ formik, handleOptionsOpen, handleSelect, tagsOptions }) => {
  const { values, setFieldValue, errors, touched } = formik;
  const { tools, skills, platforms, genres } = tagsOptions;

  const handleChange = (field, event) => {
    handleSelect(field, event, setFieldValue);
  };

  return (
    <Container>
      <FormSelect
        mb={40}
        name="tools"
        value={values.tools}
        options={tools.options}
        isMulti
        onChange={e => handleChange('tools', e)}
        onMenuOpen={() => handleOptionsOpen('tool')}
        error={touched.tools ? errors.tools : ''}
        isLoading={tools.tagTools.loading}
        label="Tools"
        required
        hintText="What's in your arsenal? Include any game engines, software and collaboration tools."
        placeholder="e.g. Unreal Engine, Maya, Python, Jira"
      />
      <FormSelect
        mb={40}
        name="skills"
        value={values.skills}
        options={skills.options}
        isMulti
        onChange={e => handleChange('skills', e)}
        onMenuOpen={() => handleOptionsOpen('skill')}
        error={touched.skills ? errors.skills : ''}
        isLoading={skills.tagSkills.loading}
        label="Skills"
        required
        hintText="Let your top skills standout by listing them here."
        placeholder="e.g. Polygonal texturing, Presentation skills"
      />
      <FormSelect
        mb={40}
        name="platforms"
        value={values.platforms}
        options={platforms.options}
        isMulti
        onChange={e => handleChange('platforms', e)}
        onMenuOpen={() => handleOptionsOpen('platform')}
        error={touched.platforms ? errors.platforms : ''}
        isLoading={platforms.tagPlatforms.loading}
        label="Platforms"
        hintText="Tell us the platforms you specialize in or would like to focus on."
        placeholder="e.g. Xbox One, PS Vita"
      />
      <FormSelect
        mb={40}
        name="genre"
        value={values.genre}
        options={genres.options}
        isMulti
        onChange={e => handleChange('genre', e)}
        onMenuOpen={() => handleOptionsOpen('genre')}
        error={touched.genre ? errors.genre : ''}
        isLoading={genres.tagGenres.loading}
        label="Genre"
        hintText="Include themes, player perspectives, subgenres—whatever tickles your fancy."
        placeholder="e.g. First Person Shooter, Open world, Puzzle"
      />
    </Container>
  );
};

export default ToolkitInfo;
