import { useState } from 'react';
import notificationService from 'api/notificationService';
import queryString from 'query-string';
import { notificationActions } from 'store/notifications';
import { useDispatch } from 'react-redux';

const useNotificationsServices = () => {
  const dispatch = useDispatch();

  const [notificationLoading, setNotificationLoading] = useState(false);

  const getNotifications = async (profileId, skip, type, limit = 10) => {
    if (!notificationLoading) {
      try {
        setNotificationLoading(true);
        let queryObject = {
          skip,
          limit
        };
        if (type !== 'all') {
          queryObject = {
            ...queryObject,
            type
          };
        }
        const res = await notificationService.getNotifications(profileId, queryString.stringify(queryObject));

        const { data, pagination, unseenCommunityCount, unseenJobsCount, unseenProfileCount } = res.data;

        dispatch(notificationActions.setNotifications({ profileId, notifications: data, notificationType: type }));
        dispatch(notificationActions.setNotificationPagination({ profileId, pagination, notificationType: type }));
        dispatch(
          notificationActions.setUnseenNotificationsCount({
            profileId,
            unseenCommunityCount,
            unseenJobsCount,
            unseenProfileCount
          })
        );

        return res.data;
      } catch (err) {
      } finally {
        setNotificationLoading(false);
      }
    }
  };

  const setNotificationsAsRead = async (profileId, type, notificationIds = []) => {
    try {
      const bodyData = { notificationIds };
      const res = await notificationService.setNotificationsAsRead(profileId, bodyData);
      if (res.status === 200) {
        notificationIds.forEach(notificationId =>
          dispatch(notificationActions.setNotificationAsRead({ profileId, notificationId, type }))
        );
      }
    } catch (err) {}
  };

  const setNotificationsAsSeen = async (profileId, type = 'all') => {
    try {
      const bodyData = type !== 'all' ? { type } : {};
      const res = await notificationService.setNotificationsAsSeen(profileId, bodyData);
      if (res.status === 200) {
        dispatch(notificationActions.setNotificationsAsSeen({ profileId }));
      }
    } catch (err) {}
  };

  return {
    getNotifications,
    setNotificationsAsRead,
    setNotificationsAsSeen,
    notificationLoading
  };
};

export default useNotificationsServices;
