import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import FormSelect from 'common/form/FormSelect';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import { getFormSelectTypeArray, getFormSelectValue } from 'utils/tagHelpers';
import { tagSelectors } from 'store/tags';
import useTagService from 'hooks/api/useTagService';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  gap: 56px;
`;

const CreateServiceModal = ({ closePortal, data, name, onAdd }) => {
  const { getTags } = useTagService();
  const tagDisciplines = useSelector(tagSelectors.selectTagsByType('disciplines'));

  const options = getFormSelectTypeArray({ arr: tagDisciplines.data });

  const [customName, setCustomName] = React.useState(data.customName ?? null);
  const [error, setError] = React.useState(false);
  const [disciplines, setDisciplines] = useState({
    options,
    value: getFormSelectValue(data.service)
  });

  const isCustom = disciplines.value?.label && disciplines.value?.label.toLowerCase() === 'other';

  const handleAddService = e => {
    e.preventDefault();
    if (!disciplines.value) {
      setError('Service is required');
    } else {
      const save = {
        service: { _id: disciplines.value.value, name: disciplines.value.label },
        customName: isCustom && customName?.length ? customName : null
      };

      if (data.onEdit) {
        data.onEdit(save);
      } else {
        onAdd(save);
      }
      closePortal();
    }
  };

  const handleOptionsOpen = () => {
    const fetchTags = async () => {
      await getTags('discipline');
    };

    if (!tagDisciplines.isLoaded) {
      fetchTags();
    }
  };

  useEffect(() => {
    setError();
  }, [disciplines]);

  useEffect(() => {
    const disciplinesOptions = tagDisciplines.data.map(discipline => ({
      value: discipline._id,
      label: discipline.name
    }));
    setDisciplines(currentDisciplines => ({ value: currentDisciplines.value, options: disciplinesOptions }));
  }, [tagDisciplines]);

  return (
    <TxplModal
      title="Add service"
      name={name}
      appElement={document.getElementById('root-modal')}
      size="large"
      renderFooter={
        <StyledActionsContainer>
          <ContainedButton onClick={closePortal} backgroundColor="secondary">
            Cancel
          </ContainedButton>
          <ContainedButton form="createServiceModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <StyledForm onSubmit={handleAddService} id="createServiceModal">
        <FormSelect
          label="Select service"
          autoFocus
          value={disciplines.value}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPlacement="bottom"
          options={disciplines.options}
          onChange={option =>
            setDisciplines(currentDisciplines => ({ options: currentDisciplines.options, value: option }))
          }
          onMenuOpen={handleOptionsOpen}
          error={error}
          isLoading={tagDisciplines.loading}
        />
        {isCustom && (
          <FormInput
            label="Specify service name"
            hintText="Please specify the type of service you provide"
            maxLength={50}
            value={customName}
            onChange={e => setCustomName(e.target.value)}
          />
        )}
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('create-service-modal')(CreateServiceModal);
