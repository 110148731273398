import React from 'react';
import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import BackofficeLogo from 'images/assets/backoffice-logo.svg';
import BackofficeMenuItem from './BackofficeMenuItem';
import menuItems from './menu';
import analystMenuItems from './analystMenu';
import BackofficeUserMenu from './BackofficeUserMenu';
import { getDecodedIdToken } from 'utils/cookies';

const Wrapper = styled.div`
  position: fixed;
  ${commonStyles.ui_heading_3}
  display: flex;
  height: 100%;
  width: 240px;
  max-width: 240px;
  background: #18191b;
  flex-direction: column;
  z-index: 100;
`;

const Logo = styled.img`
  margin: 24px 100px 40px 16px;
  width: 124px;
  height: 40px;
`;
const MenuWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

const UserMenuWrapper = styled.div`
  margin-top: auto;
`;

const BackofficeMenu = () => {
  const decoded = getDecodedIdToken();

  const isAnalyst = decoded.roles.includes('analyst');
  const isSuperUser = decoded.roles.includes('super-admin');

  return (
    <Wrapper>
      <Logo src={BackofficeLogo} alt="Backoffice" />

      <MenuWrapper>
        {isSuperUser &&
          menuItems?.map(menuItem => (
            <BackofficeMenuItem key={menuItem.label} menuItem={menuItem} icon="portfolio-grid" text="Dashboard" />
          ))}
        {isAnalyst &&
          analystMenuItems?.map(menuItem => (
            <BackofficeMenuItem key={menuItem.label} menuItem={menuItem} icon="portfolio-grid" text="Dashboard" />
          ))}
      </MenuWrapper>

      <UserMenuWrapper>
        <BackofficeUserMenu />
      </UserMenuWrapper>
    </Wrapper>
  );
};

export default BackofficeMenu;
