import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import withRenderPortal from 'hocs/withRenderPortal';
import { profileActions } from 'store/profile';
import { authActions } from 'store/auth';
import { authService } from 'api';
import useForm from 'hooks/useForm';

import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import FormInput from 'common/form/FormInput';
import Typography from 'components/shared/Typography';
import LoginModalOauth from './LoginModalOauth';
import * as Styled from './StyledLoginModal';
import { getDecodedIdToken } from 'utils/cookies';
import { apiCallActions } from 'store/apiCall';

const fields = {
  email: { initialValue: '', validate: ['isRequired'] },
  password: { initialValue: '', validate: ['isRequired'] }
};

const LoginModal = ({ closePortal, name }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const callApi = formValues =>
    authService
      .login(formValues)
      .then(({ data: { user, talent } }) => {
        const allowedLoginStatuses = ['created', 'active'];
        if (!allowedLoginStatuses.includes(user.status)) {
          const fullName = `${user.firstName} ${user.lastName}`;
          closePortal();
          return history.push(`/account-suspended?email=${user.email}&fullName=${fullName}&reason=${user.status}`);
        }

        localStorage.removeItem('activeProfileType');

        dispatch(profileActions.clearProfiles());
        dispatch(authActions.login(user));
        const decoded = getDecodedIdToken();
        const isSuperAdmin = decoded.roles.includes('super-admin');

        const redirectPath = `/talent/${talent.talentId}/about`;
        const backofficePath = '/backoffice';

        history.push(isSuperAdmin ? backofficePath : redirectPath);
        closePortal();
      })
      .finally(() => {
        dispatch(apiCallActions.sendRequestSuccess({ name: 'currentUser' }));
      });

  const { errors, loading, onFieldChange, onSubmit, values } = useForm({
    callApi,
    onSuccess: closePortal,
    fields
  });

  const handleForgotPassword = () => {
    const url = `${process.env.REACT_APP_NEXT_BASE_URL}/auth/forgot-password`;
    window.location.href = url;
    closePortal();
  };

  const errorMessage = errors?.response?.data?.message;
  const showErrorMessage = errorMessage !== 'ACCOUNT_BLOCKED' && errorMessage;

  return (
    <Styled.Modal
      padding="24px 0"
      titlePadding="24px"
      name={name}
      hideBorder
      title="Sign in"
      size="small"
      showSignUp
      appElement={document.getElementById('root-modal')}
      noPaddingTop
      renderFooter={
        <Styled.ActionsContainer>
          <Styled.ForgotPasswordLabel onClick={handleForgotPassword}>Forgot password?</Styled.ForgotPasswordLabel>
          <Styled.RightSide>
            {loading && <Spinner />}
            <ContainedButton form="loginModal" type="submit" backgroundColor="#7266EE" forceDisplayText>
              Sign in
            </ContainedButton>
          </Styled.RightSide>
        </Styled.ActionsContainer>
      }
    >
      <Styled.StyledTypography variant="medium" color="rgba(230, 234, 255, 0.35)">
        Proceed with social account
      </Styled.StyledTypography>
      <LoginModalOauth />
      <Styled.Spacer />
      <Styled.StyleTypography variant="medium" color="rgba(230, 234, 255, 0.35)">
        Or use your email
      </Styled.StyleTypography>
      <form
        onSubmit={e => {
          dispatch(apiCallActions.sendRequest({ name: 'currentUser' }));
          onSubmit(e);
        }}
        autoComplete="off"
        id="loginModal"
      >
        <FormInput
          label="Email"
          title="Email"
          type="email"
          hintText=""
          value={values.email}
          error={errors?.email || !!errors.message}
          autoFocus
          onChange={onFieldChange('email')}
          mb={18}
        />

        <FormInput
          label="Password"
          title="Password"
          type="password"
          hintText=""
          value={values.password}
          error={errors?.password || showErrorMessage || !!errors.message}
          onChange={onFieldChange('password')}
        />

        {errorMessage === 'ACCOUNT_BLOCKED' && (
          <Typography variant="small" color="rgba(255, 81, 81, 1)">
            For security reasons, your account has been locked. You can try again in 5 minutes or&nbsp;
            <Styled.NavigateTo onClick={handleForgotPassword}>reset your password</Styled.NavigateTo>.
          </Typography>
        )}
      </form>
    </Styled.Modal>
  );
};

export default withRenderPortal('login')(LoginModal);
