import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';

export const Container = styled.div`
  max-height: 128px;
  min-width: 324px;
  box-sizing: border-box;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  padding: 24px;
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${pageSize.XS} {
    min-width: 0;
  }
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
`;
export const Icon32 = styled(Icon)``;
export const Icon16 = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 8px;
`;
export const Button = styled(ContainedButton)`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 0;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  background-color: rgba(204, 213, 255, 0.11);
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const StatusLoader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
