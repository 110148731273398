import React from 'react';
import styled from '@emotion/styled';
import Icon from 'common/Icon';
import ProfileVettedIcon from 'common/IconComponents/ProfileVettedIcon';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 0px 30px;
  background-image: url('${process.env.PUBLIC_URL}/assets/backgrounds/bg-01.png');
  background-repeat: no-repeat;
`;

const Title = styled.h3`
  color: white;
  font-size: 36px;
  max-width: 280px;
  margin: -5px 0px 16px 0px;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
`;

export default function ModalHeader({ title, onClose }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <ProfileVettedIcon width="145" height="120" />
      <CloseIcon icon="close" size={10} onClick={onClose} />
    </Wrapper>
  );
}
