import * as React from 'react';

const WalletBadgeIcon = ({ active, ...svgProps }) =>
  active ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M15.1467 0.492672C15.6747 0.187806 16.3253 0.187806 16.8533 0.492672L29.0031 7.50733C29.5311 7.81219 29.8564 8.37561 29.8564 8.98534V23.0147C29.8564 23.6244 29.5311 24.1878 29.0031 24.4927L16.8533 31.5073C16.3253 31.8122 15.6747 31.8122 15.1467 31.5073L2.99693 24.4927C2.46888 24.1878 2.14359 23.6244 2.14359 23.0147V8.98534C2.14359 8.37561 2.46888 7.81219 2.99693 7.50733L15.1467 0.492672Z"
        fill="url(#paint0_radial_133_17840)"
      />
      <g filter="url(#filter0_d_133_17840)">
        <path
          d="M7.89062 10C7.89062 8.89543 8.78606 8 9.89062 8H19.8906C20.9952 8 21.8906 8.89543 21.8906 10H7.89062Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.89062 12H21.8906C22.9952 12 23.8906 12.8954 23.8906 14V22C23.8906 23.1046 22.9952 24 21.8906 24H9.89062C8.78606 24 7.89062 23.1046 7.89062 22V12ZM20.361 19.5959C21.2423 19.5959 21.9568 18.8814 21.9568 18C21.9568 17.1187 21.2423 16.4042 20.361 16.4042C19.4796 16.4042 18.7651 17.1187 18.7651 18C18.7651 18.8814 19.4796 19.5959 20.361 19.5959Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_133_17840"
          x="5.89062"
          y="7"
          width="20.0078"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.148568 0 0 0 0 0.0247613 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_133_17840" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_133_17840" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_133_17840"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2.86102e-06 32) rotate(-90) scale(32 48)"
        >
          <stop stopColor="#9B36B5" />
          <stop offset="1" stopColor="#DB4DFF" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M15.1467 0.492672C15.6747 0.187806 16.3253 0.187806 16.8533 0.492672L29.0031 7.50733C29.5311 7.81219 29.8564 8.37561 29.8564 8.98534V23.0147C29.8564 23.6244 29.5311 24.1878 29.0031 24.4927L16.8533 31.5073C16.3253 31.8122 15.6747 31.8122 15.1467 31.5073L2.99693 24.4927C2.46888 24.1878 2.14359 23.6244 2.14359 23.0147V8.98534C2.14359 8.37561 2.46888 7.81219 2.99693 7.50733L15.1467 0.492672Z"
        fill="#C7C7FF"
        fillOpacity="0.21"
      />
      <path
        d="M7.89062 10C7.89062 8.89543 8.78606 8 9.89062 8H19.8906C20.9952 8 21.8906 8.89543 21.8906 10H7.89062Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89062 12H21.8906C22.9952 12 23.8906 12.8954 23.8906 14V22C23.8906 23.1046 22.9952 24 21.8906 24H9.89062C8.78606 24 7.89062 23.1046 7.89062 22V12ZM20.361 19.5959C21.2423 19.5959 21.9568 18.8814 21.9568 18C21.9568 17.1187 21.2423 16.4042 20.361 16.4042C19.4796 16.4042 18.7651 17.1187 18.7651 18C18.7651 18.8814 19.4796 19.5959 20.361 19.5959Z"
        fill="#E5E8FF"
        fillOpacity="0.65"
      />
    </svg>
  );

export default WalletBadgeIcon;
