import Api from './api';

const api = new Api('conversations');

const searchContacts = (profileId, contactName) => api.get(`/${profileId}/contacts?name=${contactName}`);

const getConversationByParticipants = data => api.post('/participants', data);

const createConversation = (profileId, contactProfileId, data) =>
  api.post(`/${profileId}`, { contactProfileId, conversationData: data });

const typingMessage = (profileId, data) => api.put(`/${profileId}/typing`, data);

const markConversationAsRead = (profileId, data) => api.put(`/${profileId}/read`, data);

export default {
  getConversationByParticipants,
  createConversation,
  searchContacts,
  typingMessage,
  markConversationAsRead
};
