import React from 'react';
import styled from '@emotion/styled';

import SpinnerOnBlack from 'images/spinner-on-black@2x.gif';
import SpinnerOnGreen from 'images/spinner-on-green@2x.gif';

const CenteredWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Image = styled.img`
  width: ${({ size }) => (size ? `${size}` : '32')}px;
  height: ${({ size }) => (size ? `${size}` : '32')}px;
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  ${({ mr }) => mr && `margin-right: ${mr}px;`}
`;

const TheSpinner = ({ className, isOnGreen = false, ...rest }) => (
  <Image className={className} src={isOnGreen ? SpinnerOnGreen : SpinnerOnBlack} alt="Spinner" {...rest} />
);

const Spinner = ({ centered, className, isOnGreen, ...rest }) => {
  if (centered) {
    return (
      <CenteredWrapper className={className}>
        <TheSpinner isOnGreen={isOnGreen} {...rest} />
      </CenteredWrapper>
    );
  }

  return <TheSpinner className={className} isOnGreen={isOnGreen} {...rest} />;
};

export default Spinner;
