import React from 'react';

import { ImageWrapper } from './styled.components';

const JobThumbnailGalleryItem = ({ image, selected, setSelected }) => (
  <ImageWrapper
    onClick={() => setSelected(image)}
    className={`${selected && selected._id === image._id && 'selected'}`}
  >
    <img src={image.url} alt="" />
  </ImageWrapper>
);

export default JobThumbnailGalleryItem;
