import React from 'react';
import Frame from 'common/Frame';

const style = {
  wordWrap: 'break-word',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '32px',
  textAlign: 'left',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap'
};

const AboutText = ({ aboutText = '', canEdit }) => (
  <Frame
    className=""
    style={style}
    canEdit={canEdit}
    editMode={aboutText ? 'edit' : 'add'}
    opens="about-text-modal"
    filled={!!aboutText?.length}
    text="Overview Summary"
    noDataText="Highlight past accomplishments, current projects, and future goals. Show us your personality."
    holderFontSize="16px"
    textFontSize="22px"
  >
    {aboutText}
  </Frame>
);

export default AboutText;
