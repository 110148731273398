import React from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1
};

function DropZone({ children, onUpload }) {
  const { getInputProps, getRootProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: async acceptedFiles => {
      onUpload && onUpload(acceptedFiles);
    }
  });

  return (
    <div {...getRootProps({ className: 'dropzone', style: baseStyle })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

export default DropZone;
