import Api from './api';

const api = new Api('games');

const getGames = () => api.get('/');

const getTopGamesByYear = year => api.get(`/top/${year}`);

const requestGameTitle = data => api.post('/request', data);

const getGameById = gameId => api.get(`/${gameId}`);

const getGameCover = title => api.get(`/cover/${title}`);

const searchGame = (game, params = {}) => api.get(`/search/${game}`, { params });

const approveGameTitle = (gameId, data) =>
  api.post(`/${gameId}/approve`, data, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const getGameTags = gameId => api.get(`/${gameId}/tags`);

export default {
  getGames,
  getGameCover,
  requestGameTitle,
  searchGame,
  approveGameTitle,
  getTopGamesByYear,
  getGameById,
  getGameTags
};
