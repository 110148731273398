import { companyProfileService } from 'api';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';

const useCompanyServices = () => {
  const { openErrorSnackbar } = useErrorSnackbar();

  const updateCompanyService = async (profileId, items) => {
    try {
      companyProfileService.updateCompanyServices(profileId, items);
    } catch {
      openErrorSnackbar('Oops! something went wrong');
    }
  };

  return { updateCompanyService };
};

export default useCompanyServices;
