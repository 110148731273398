import { useState } from 'react';
import { userService } from 'api';

const useUserService = () => {
  const [loading, setLoading] = useState({ searchUserByEmail: false });

  const searchUserByEmail = async ({ email }) => {
    try {
      setLoading(currentLoading => ({ ...currentLoading, searchUserByEmail: true }));
      const res = await userService.searchUserByEmail(email);

      return res.data;
    } catch (err) {
      return err.response.data;
    } finally {
      setLoading(currentLoading => ({ ...currentLoading, searchUserByEmail: false }));
    }
  };

  return {
    loading,
    searchUserByEmail
  };
};

export default useUserService;
