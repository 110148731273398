import React, { useCallback } from 'react';
import { portalActions } from 'store/portals';
import { useDispatch } from 'react-redux';
import { HeaderContainer, HeaderTitle, HeaderWrapper, StyledIcon, TitleWrapper } from './VerificationSubmitted.styled';
import IdVerificationSubmittedIcon from 'common/IconComponents/IdVerificationIcons/IdVerificationSubmittedIcon';

const Header = () => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(portalActions.closePortal({ name: 'verification-submitted-modal' }));
  }, []);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <TitleWrapper>
          <HeaderTitle>ID verification submitted</HeaderTitle>
        </TitleWrapper>
        <IdVerificationSubmittedIcon />
        <StyledIcon icon="close" color="rgba(229, 232, 255, 0.65)" onClick={handleClose} />
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;
