import React from 'react';
import { useDispatch } from 'react-redux';

import { portalActions } from 'store/portals';
import ContainedButton from 'common/ContainedButton';
import BackofficeActionButtons from 'pages/backoffice/BackofficeActionButtons';
import BackofficeActionContent from 'pages/backoffice/BackofficeActionContent';
import BackofficeActionHeader from 'pages/backoffice/BackofficeActionHeader';
import BackofficeActionPanel from 'pages/backoffice/BackofficeActionPanel';
import useAPICall from 'hooks/useAPICall';
import Spinner from 'common/Spinner';

import ActionResetPasswordModal from './ActionResetPasswordModal';
import PasswordResetLinkSentModal from './PasswordResetLinkSentModal';

const ActionResetPassword = ({ userId }) => {
  const dispatch = useDispatch();

  const { callApi, loading } = useAPICall({
    name: 'send-reset-password-link',
    method: 'post',
    url: `/backoffice/users/${userId}/send-password-reset-link`,
    onSuccess: () => {
      dispatch(portalActions.openPortal({ name: 'password-reset-link-sent-modal' }));
    }
  });

  return (
    <>
      <BackofficeActionPanel>
        <BackofficeActionHeader>Reset password</BackofficeActionHeader>

        <BackofficeActionContent>
          Users are able to reset their passwords through the front end. If a user is stuck for some reason, can send a
          reset link or reset a password manually.
        </BackofficeActionContent>

        <BackofficeActionButtons>
          {loading ? <Spinner /> : <ContainedButton onClick={() => callApi()}>Send a link</ContainedButton>}
          <ContainedButton opens="backOffice-reset-user-password-modal" data={{ userId }}>
            Reset Manually
          </ContainedButton>
        </BackofficeActionButtons>
      </BackofficeActionPanel>

      <ActionResetPasswordModal />
      <PasswordResetLinkSentModal />
    </>
  );
};

export default ActionResetPassword;
