import companyProfileService from 'api/companyProfileService';
import useForm from 'hooks/useForm';
import { List } from 'immutable';
import map from 'lodash/fp/map';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import { talentService } from '../../../api';

const fields = {
  _id: { initialValue: '' },
  service: { initialValue: '', validate: ['isRequired'] },
  customName: { initialValue: null },
  serviceOverview: { initialValue: '' },
  platforms: { initialValue: new List() },
  tools: { initialValue: new List() },
  genres: { initialValue: new List() },
  skills: { initialValue: new List() },
  tags: { initialValue: new List() }
};

const useCompanyService = () => {
  const [mode, updateMode] = useState('view');
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);

  let saveService = companyProfileService.saveCompanyService;
  let updateService = companyProfileService.updateCompanyService;
  let profileKey = 'companyProfileId';
  if (!isCompanyView) {
    saveService = talentService.saveTalentService;
    updateService = talentService.updateTalentService;
    profileKey = 'talentProfileId';
  }

  const { errors, initialize, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: params => {
      const data = {
        ...params,
        service: params.service._id,
        platforms: map('_id', params.platforms),
        tools: map('_id', params.tools),
        genres: map('_id', params.genres),
        skills: map('_id', params.skills),
        tags: map('_id', params.tags),
        [profileKey]: profileId
      };

      const service = params._id ? updateService(data) : saveService(data);

      return service.then(({ data: { service: savedService, services } }) => {
        const update = params._id
          ? {
              property: 'services',
              updateType: 'replace',
              value: savedService,
              profileId: profileId
            }
          : {
              property: 'services',
              value: services,
              profileId: profileId
            };
        dispatch(profileActions.updateProfileProperty(update));
      });
    },
    onSubmitSuccess: () => {
      updateMode('view');
      initialize({
        service: '',
        customName: null,
        serviceOverview: '',
        platforms: [],
        tools: [],
        genres: [],
        skills: [],
        tags: []
      });
    }
  });

  return {
    mode,
    updateMode,
    values,
    submitting,
    onSubmit,
    onFieldChange,
    errors,
    initialize
  };
};

export default useCompanyService;
