import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';

export const CancelButton = styled(ContainedButton)`
  margin: 0 3px;
`;

export const contentText = styled(Typography)`
  margin-top: 32px;
  font-size: 16px;
  font-weigh: 400;
`;

export const questionsText = styled(contentText)`
  display: inline;
  cursor: pointer;
`;
