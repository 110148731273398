import React from 'react';
import styled from '@emotion/styled';

import withOpenPortal from 'hocs/withOpenPortal';
import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';

const variants = {
  h1: 'heading_1',
  h2: 'heading_2',
  h3: 'ui_heading_3',
  paragraph: 'paragraph',
  caption: 'caption',
  medium: 'ui_text_medium',
  small: 'ui_text_small',
  tiny: 'ui_text_tiny'
};

const getTag = variant => {
  switch (variant) {
    case 'h1':
      return 'h1';
    case 'h2':
      return 'h2';
    case 'h3':
      return 'h3';
    case 'caption':
      return 'span';
    case 'medium':
      return 'p';
    case 'small':
      return 'p';
    case 'tiny':
      return 'tiny';
    default:
      return 'p';
  }
};

const StyledTypographyContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  align-items: center;
`;
const StyledTypography = styled.div`
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  white-space: pre-wrap;
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props => props.display && `display: ${props.display};`}
  ${props => props.underline && 'text-decoration: underline;'}
  ${props => commonStyles[props.variant]}
  ${props => props.transform && `text-transform: ${props.transform};`}
  ${props => props.opacity && `opacity: ${props.opacity};`}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.fontFamily && `font-family: ${props.fontFamily};`}
  ${props => props.fontStyle && `font-style: ${props.fontStyle};`}
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  margin: 0px;

  ${props => props.success && 'color: #00FF00;'}
  ${props => props.error && 'color: #FF5151;'}
  ${props => props.disabled && 'opacity: .35;'}

  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  ${({ mr }) => mr && `margin-right: ${mr}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
  ${({ pl }) => pl && `padding-left: ${pl}px;`}
  ${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ mediumText }) => mediumText && 'font-weight: 400;'}
  ${({ smediumText }) => smediumText && 'font-weight: 700;'}

  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak};`}

  &.limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    word-break: break-all;
  }

  @media ${pageSize.XS} {
    ${({ mtXS }) => mtXS && `margin-top: ${mtXS}px;`}
    ${({ mbXS }) => mbXS && `margin-bottom: ${mbXS}px;`}
    ${({ pbXS }) => pbXS && `padding-bottom: ${pbXS}px;`}
  }
`;

const Typography = ({ children, success, textRef, transform, underline, variant, ...rest }) => {
  const component = (
    <StyledTypography
      variant={variants[variant]}
      as={getTag(variant)}
      transform={transform}
      underline={underline}
      success={success}
      ref={textRef}
      {...rest}
    >
      {children}
    </StyledTypography>
  );

  return !success ? (
    component
  ) : (
    <StyledTypographyContainer>
      <Icon icon="checkmark" color="#00FF00" />
      {component}
    </StyledTypographyContainer>
  );
};

export default withOpenPortal(Typography);
