import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import VerifyAccount from './VerifyAccount';

const OnBoarding = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/verify-account`} component={VerifyAccount} />
    </Switch>
  );
};

export default OnBoarding;
