import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';
import ContainedButton from 'common/ContainedButton';
import { funnelStatusesEnum } from '../../constants';

import ActivityStatus from './ActivityStatus';
import FunnelStatus from './FunnelStatus';

const StyledIdContainer = styled.p`
  width: 108px;
  overflow-x: scroll;
  margin-bottom: 0px;
`;
const StyledButton = styled(ContainedButton)`
  margin-left: auto;
  margin-bottom: 16px;
`;
const CompanyPublicUrl = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const BackofficeCompanyAccounts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Operations' }, { label: 'Company Accounts' }]));
  }, [dispatch]);

  return (
    <>
      <StyledButton onClick={() => history.push('/backoffice/operations/company-accounts/new')}>
        Create new company account
      </StyledButton>

      <Table keyId="_id" paginate endpoint="/backoffice/companies-profiles" dataKey="companies">
        <Column
          title="Account ID"
          keyName="_id"
          render={user => <StyledIdContainer>{user._id}</StyledIdContainer>}
          width="108px"
          search
        />

        <Column title="Company name" keyName="name" search sort />
        <Column title="Associated user" keyName="owner.email" search />
        <Column
          title="Public profile url"
          keyName="publicUrl"
          render={company => (
            <CompanyPublicUrl onClick={() => history.push(`/company/${company.publicUrl}/about`)}>
              /{company.publicUrl}
            </CompanyPublicUrl>
          )}
          search
        />
        <Column title="Supply/Demand flags" keyName="supplyDemand" search />

        <Column
          title="Funnel Status"
          keyName="status"
          render={company => (
            <FunnelStatus
              value={company.funnelStatusLogs?.[company.funnelStatusLogs.length - 1]?.status}
              status={
                funnelStatusesEnum[company.funnelStatusLogs?.[company.funnelStatusLogs.length - 1]?.status] ||
                'Account Created'
              }
            />
          )}
          search
        />
        <Column
          title="Activity status"
          keyName="status"
          render={user => <ActivityStatus status={user.status} />}
          width="1%"
          pr={60}
          search
        />

        <Column
          title=""
          type="action"
          width={46}
          onClick={company => history.push(`company/${company._id}`)}
          opens="some-modal"
        />
      </Table>
    </>
  );
};

export default BackofficeCompanyAccounts;
