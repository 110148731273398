import styled from '@emotion/styled';

export const Avatar = styled.img`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(204, 213, 255, 0.11);

  &:hover {
    cursor: pointer;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const IconBox = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--padding-gap-panel-level-1, 24px);
  background: var(--white-white-5, rgba(204, 212, 255, 0.05));
`;

export const TextBox = styled.div`
  color: var(--white-white-60, rgba(229, 234, 255, 0.6));
  /* UI Text Medium */
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

export const JobBy = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  color: var(--white-white-100, #fff);
  /* UI Heading 3 */
  font-family: Space Grotesk;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 136.842% */
`;

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;