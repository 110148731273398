import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import { userActions } from 'store/user';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import backOfficeService from 'api/backOfficeService';
import { profileActions } from 'store/profile';
import { funnelStatuses, reasons } from '../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = (initialStatus, additionalComment) => ({
  status: { initialValue: funnelStatuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  reason: { initialValue: '', validate: ['isRequired'] },
  additionalComment: { initialValue: additionalComment }
});

const transformParams = params => ({
  ...params,
  reason: params.reason.value,
  status: params.status.value
});

const CompanyFunnelStatusModal = ({ closePortal, data, name }) => {
  const { additionalComment, on, status, talentId, userId } = data;
  const dispatch = useDispatch();

  const callApi = params => {
    const apiParams = transformParams(params);
    const accountId = userId || talentId;
    const accountType = 'company';

    const requestPromise = backOfficeService.setAccountFunnelStatus(accountId, { ...apiParams, accountType });

    return requestPromise.then(({ data: { accounts, statusLog } }) => {
      const action = userId ? userActions.updateUserProperty : profileActions.updateProfileProperty;

      dispatch(action({ property: 'funnelStatus', value: apiParams.status, profileId: accountId }));
      dispatch(action({ property: 'funnelStatusLogs', value: statusLog, updateType: 'insert', profileId: accountId }));

      if (userId) dispatch(action({ property: 'accounts', value: accounts }));

      if (data.onSuccess) data.onSuccess();

      closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields(status, additionalComment),
    callApi
  });

  React.useEffect(
    () => {
      const [newReasons] = reasons[values?.status?.value] || [];
      onFieldChange('reason')(newReasons || { target: null });
    },
    [values?.status?.value] // eslint-disable-line
  );

  return (
    <TxplModal
      title={on === 'user' ? 'Funnel status' : 'Funnel status'}
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="companyFunnelStatusModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="companyFunnelStatusModal">
        <FormSelect
          autoFocus
          label="Current status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={funnelStatuses}
        />

        <FormSelect
          label="Reason"
          value={values.reason}
          onChange={onFieldChange('reason')}
          options={reasons[values?.status?.value]}
        />

        <FormTextarea
          label="Additional comments"
          maxLength="200"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('company-funnel-status-modal')(CompanyFunnelStatusModal);
