import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';
import useAudioWaveSurfer from 'hooks/useAudioWaveSurfer';
import React from 'react';
import { getAssetIcon } from 'utils/assets';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import AssetPdf from './form/Asset/AssetPdf';
import Icon from './Icon';

const Wrapper = styled.div`
  ${({ autoHeight }) => !autoHeight && 'min-height: 380px;'}
  position: relative;
  width: 100%;
  max-width: 600px;
  background: #1a1b1e;
  border-radius: 8px;
  overflow: hidden;
  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-shadow {
    position: absolute;
    bottom: -120px;
    width: 100%;
    min-height: 120px;
    background: linear-gradient(0deg, rgba(14, 14, 15, 0.85) 0%, rgba(14, 14, 15, 0) 100%);
    transition-duration: 0.2s;
  }

  &.video-playing:hover,
  &.video-paused:hover {
    .play-button.hidden {
      display: flex;
    }
  }

  &:hover {
    cursor: pointer;
    .hover-container {
      background: rgba(14, 14, 15, 0.2);
    }

    .bottom-shadow {
      bottom: 0px;
    }
    .zoom-in {
      display: flex;
    }
  }
`;

const ImageContainer = styled.img`
  position: relative;
  display: block;
  width: 100%;
`;

const VideoContainer = styled.video`
  position: relative;
  display: block;
  width: 100%;
`;

const StyledWaveSurfer = styled.div`
  width: 100%;
  height: 380px;
  object-fit: cover;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #7d61f0;

  & wave {
    width: 100%;
  }

  @media ${pageSize.XS} {
    height: 343px;
  }
`;

const HoverContainer = styled.div`
  position: absolute;
  z-index: 5;
  pointer-events: none;
  top: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &:hover {
  }
  
  &.pink {
    background-color: rgba(114, 102, 238, 0.7);
  }
`;

const HoverTitle = styled.div`
  ${commonStyles.ui_heading_3}
  padding: 48px 24px 8px;
`;
const HoverDescription = styled(Typography)`
  ${commonStyles.ui_text_small}
  padding: 0 24px 24px;
`;

const ZoomIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &.zoom-in {
    display: none;
  }
  &.play-button.hidden {
    display: none;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const AssetViewer = ({ asset, className, noMargin, onClick }) => {
  const { description, name } = asset || {};
  const normalizedAsset = asset.file || asset;
  const url = normalizedAsset.url;
  const type = normalizedAsset.type;
  const id = normalizedAsset._id;

  const lastIndexOfDot = url.lastIndexOf('.');
  const videoType = url.slice(lastIndexOfDot);
  const videoPoster = url.replace(videoType, '.png');

  const isProjectVideo = !!normalizedAsset.isProjectVideo;

  const isVideo = type === 'video';
  const isAudio = type === 'audio';
  const isPDF = type === 'pdf';

  const videoRef = React.useRef();
  const audioRef = React.useRef();
  const surferRef = React.useRef();

  const { loadingAudio, pauseAudio, playingAudio, toggleAudio } = useAudioWaveSurfer({ audioRef, surferRef, url });

  const assetIcon = isAudio && playingAudio ? 'pause' : type;

  const wrapperClassName = className ?? '';

  const pauseVideo = React.useCallback(() => {
    videoRef.current.pause();
  }, [videoRef]);

  const handleIconClick = e => {
    if (isAudio) {
      toggleAudio(e);
    }
  };

  const handleClick = () => {
    if (isVideo) {
      pauseVideo();
    }
    if (isAudio) {
      pauseAudio();
    }
    onClick();
  };

  return (
    <Wrapper
      onClick={handleClick}
      autoHeight={type === 'image'}
      className={`${wrapperClassName}${isVideo ? ' center' : ''}`}
    >
      {isVideo && (
        <VideoContainer
          ref={videoRef}
          poster={videoPoster}
          key={url}
          autoPlay={isProjectVideo}
          muted={isProjectVideo}
          loop={isProjectVideo}
          playsInline={isProjectVideo}
          controls
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          onClickCapture={e => e.preventDefault()}
        >
          <source src={url} />
        </VideoContainer>
      )}
      {isPDF && <AssetPdf noMargin={noMargin} url={url} />}
      {type === 'image' && <ImageContainer src={url} alt={'portfolio image'} />}
      {isAudio && <StyledWaveSurfer ref={surferRef} id={`audio_${id}`} />}

      <HoverContainer className={isPDF ? 'pink' : ''}>
        {!isVideo && (
          <ZoomIconWrapper className={`${assetIcon} play-button`}>
            <IconWrapper onClick={handleIconClick}>
              {loadingAudio && isAudio ? (
                <Icon icon="loader" size={30} />
              ) : (
                <img className={type} src={getAssetIcon(assetIcon)} width="80px" alt={type} />
              )}
            </IconWrapper>
          </ZoomIconWrapper>
        )}
        <div className="bottom-shadow">
          <HoverTitle>{name}</HoverTitle>
          {description && description?.length > 80 ? (
            <HoverDescription>{description.substr(0, 80)}...</HoverDescription>
          ) : (
            <HoverDescription>{description}</HoverDescription>
          )}
        </div>
      </HoverContainer>
    </Wrapper>
  );
};

export default AssetViewer;
