import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import Icon from 'common/Icon';
import Label from 'common/Label';
import ShareURL from 'common/ShareURL';
import Tags from 'common/Tags';
import HiddenAvatar from 'components/HiddenAvatar/HiddenAvatar';
import VerificationStatusCard from 'components/VerificationStatusCard';
import useStartConversation from 'hooks/chatApi/useStartConversation';
import useViewport from 'hooks/useViewport';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { profileSelectors } from 'store/profile';
import { authSelectors } from 'store/auth';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import getDisciplinesIconAndColor from 'utils/disciplines';
import getBiosReducedName from 'utils/getBiosReducedName';
import companyService from '../../api/companyService';
import { userSelectors } from '../../store/user';
import CompanyRoles from './CompanyRoles';
import * as Styled from './StyledUserCard';
import IDVerificationVideoModal from '../modals/IDVerificationVideoModal/IDVerificationVideoModal';
import createSharingUrl from 'utils/create-sharing-url';
import UserCardBadges from './UserCardBadges';
import Location from './Location';
import Languages from './Languages';
import useApplicantService from 'hooks/api/useApplicantService';
import { pluck } from 'ramda';
import { selectMyProfile } from 'store/profile/profileSelectors';
import UserCardAchievements from './UserCardAchievements';

const UserCardDisciplines = ({ disciplines = [] }) => {
  const formattedTags = React.useMemo(
    () =>
      disciplines.map(discipline => {
        const { color, icon } = getDisciplinesIconAndColor(discipline.name);

        return { ...discipline, icon, color };
      }),
    [disciplines]
  );

  return formattedTags.map(({ _id, color, icon, name }) => (
    <Label key={_id} icon={icon} background={color} forceDisplayText>
      {name}
    </Label>
  ));
};

const UserCard = ({ basicInfo = {}, profile = {}, canEdit }) => {
  const { pathname, search } = useLocation();
  const { isS, isXS } = useViewport();
  const [companyRoles, setCompanyRoles] = useState(
    basicInfo.companyType && basicInfo.companyType.map(role => role._id)
  );
  const [showAllLocations, setShowAllLocations] = useState(false);

  const {
    yourX = '',
    location,
    languages = [],
    disciplines,
    roles,
    imageUri,
    companySize,
    yearFounded,
    experienceLevel,
    pronouns
  } = basicInfo;
  const isAuthed = useSelector(authSelectors.isAuthed);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const currentUserProfile = useSelector(profileSelectors.selectProfileProperty('_id'));
  const userId = useSelector(currentUserPropertySelector('_id'));
  const publicUrl = useSelector(currentUserPropertySelector('publicUrl'));
  const unownedProfile = useSelector(profileSelectors.selectProfile);
  const authUser = useSelector(authSelectors.selectCurrUser);
  const authedProfile = useSelector(profileSelectors.selectMyProfile);

  const userFirstName = useSelector(userSelectors.selectCurrentUserProperty('firstName'));
  const userLastName = useSelector(userSelectors.selectCurrentUserProperty('lastName'));

  const [biasReducedMode, setBiasReducedMode] = useState(
    !!useSelector(profileSelectors.selectMyProfileProperty('biasReducedMode'))
  );
  const [dataHidden, setDataHidden] = useState(false);
  const [shareURLVisible, setShareURLVisible] = useState(false);

  const { additionalName, firstName, lastName, name, useAdditionalName } = profile;

  const [finalName, setFinalName] = useState('');

  const allLanguages = languages && languages.map(lang => lang.name).join(', ');

  const { redirectToStartConversation } = useStartConversation();
  const { hireApplicant } = useApplicantService();

  const userVerified = authUser.verificationStatus === 'verified';
  const isAuthedVettedAndVerified = isAuthed && authedProfile.vetted && userVerified;
  const areAuthUserAndProfileEqual = isAuthed && authUser._id === profile.userId;

  const sendMessageTooltip =
    areAuthUserAndProfileEqual && !profile.vetted ? 'You will be able to send message once your profile is vetted' : '';

  const shareButtonTooltip =
    areAuthUserAndProfileEqual && !profile.vetted ? 'You will be able to share once your profile is vetted' : '';

  useEffect(() => {
    const checkId = unownedProfile.type === 'company' ? unownedProfile.owner : unownedProfile.talentId;
    if (
      !isCompanyView &&
      (Object.keys(unownedProfile).length === 0 || checkId === userId || checkId === publicUrl
        ? false
        : !unownedProfile?.members?.find(item => item.user === userId))
    ) {
      setDataHidden(biasReducedMode);
    } else {
      setDataHidden(false);
    }
  }, [setDataHidden, biasReducedMode]);

  useEffect(() => {
    if (profile.type === 'talent') {
      if (firstName && lastName) {
        if (useAdditionalName && additionalName && additionalName !== '') {
          setFinalName(`${firstName} ${lastName} (${additionalName})`);
        } else {
          setFinalName(`${firstName} ${lastName}`);
        }
      } else {
        setFinalName(`${userFirstName} ${userLastName}`);
      }
    } else {
      setFinalName(name);
    }
  }, [profile]);

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (params.get('bios-reduced')) {
      if (profile.type === 'talent') {
        setBiasReducedMode(params.get('bios-reduced') === 'true');
      }
    }
  }, []);

  const changeMode = useCallback(() => setDataHidden(latest => !latest), [setDataHidden]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    setCompanyRoles([]);
    basicInfo.companyType && setCompanyRoles(basicInfo.companyType.map(role => role._id));
  }, [basicInfo.companyType]);

  function handleDragEnd(event) {
    if (hasEditPermission) {
      const { active, over } = event;

      if (active.id !== over.id) {
        setCompanyRoles(items => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          const sortedArray = arrayMove(items, oldIndex, newIndex);

          companyService.updateRoleSort(profile._id, {
            roles: sortedArray
          });

          return sortedArray;
        });
      }
    }
  }

  const renderLocation = React.useMemo(() => {
    if (isCompanyView) {
      const primaryLocation = basicInfo.locations.find(item => item.isHeadOffice);
      const allLocations = basicInfo.locations.filter(item => !item.isHeadOffice);
      const allOtherLocationsCount = allLocations.length;

      if (!primaryLocation && !allOtherLocationsCount) {
        return null;
      }

      return (
        <span>
          {primaryLocation && <span>{primaryLocation.officeAddress}</span>}
          {allOtherLocationsCount > 0 && !showAllLocations && (
            <Styled.MoreLocations onClick={() => setShowAllLocations(true)}>
              +{allOtherLocationsCount} {allOtherLocationsCount > 1 ? 'locations' : 'location'}
            </Styled.MoreLocations>
          )}
          {showAllLocations &&
            allLocations?.map(location => {
              return <div key={location.officeAddress}>{location.officeAddress}</div>;
            })}
        </span>
      );
    }
    return location;
  }, [isCompanyView, location, basicInfo.locations, showAllLocations]);

  const handleMessagesClick = () => {
    redirectToStartConversation(profile);
  };

  const handleCreateProposal = async () => {
    await hireApplicant(profile._id, '', '', response => {
      const url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${response.contractId}/edit`;
      window.location.href = url;
    });
  };

  const profilePath = pathname.split('/').slice(0, -1).join('/');

  const myUserProfileList = pluck('userId', useSelector(profileSelectors.selectMyProfileList));
  const activeProfile = useSelector(selectMyProfile);
  const companyOwner = activeProfile?.owner;
  const isCompany = activeProfile?.type === 'company';
  const isDemandAndVetted = isCompany || (activeProfile?.isSupply !== 'supply' && activeProfile?.vetted);
  const isCompanyOwner = myUserProfileList.includes(companyOwner);
  const openCompanyModal = isCompany ? true : false;
  const modalToOpen = isCompanyOwner ? 'company-contract-modal' : 'admin-contract-modal';

  const shouldShowCreateProposal = !canEdit && !isCompanyView && profile.vetted && isAuthed && isDemandAndVetted;

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.FlexRow>
          <UserCardDisciplines disciplines={disciplines} />
        </Styled.FlexRow>
        <Styled.Spacer />
        <Styled.TitleSection>
          <Styled.FlexRow>
            {!dataHidden && (
              <Styled.Avatar
                onClick={
                  !isCompanyView && !unownedProfile?.members?.find(item => item.user === userId) ? changeMode : null
                }
                src={imageUri?.url || `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`}
                alt=""
              />
            )}
            {dataHidden && <HiddenAvatar changeMode={changeMode} credit={profileId} overrideTooltipPosition />}
            {!isS && <UserCardBadges />}
          </Styled.FlexRow>
          <Styled.TitleTexts isCompanyView={isCompanyView}>
            {!isS && !isXS && !dataHidden ? (
              <Styled.TitleName len={finalName?.length} data-tip data-for="yourName">{`${finalName}`}</Styled.TitleName>
            ) : (
              <Styled.TitleName>
                {getBiosReducedName({
                  name: finalName,
                  isBiosReduced: dataHidden
                })}
              </Styled.TitleName>
            )}
            <Styled.TitleDesc isCompanyView={isCompanyView}>
              {yourX && <Icon icon="the-x" size={13} color="#00FF00"></Icon>}
              {yourX?.length > 58 && !isS && !isXS ? (
                <Styled.Text data-tip data-for="yourXTooltip">{`${yourX.substr(0, 58)}...`}</Styled.Text>
              ) : (
                <Styled.Text>{yourX}</Styled.Text>
              )}
            </Styled.TitleDesc>
            <Styled.CompanyTypeWrapper>
              {companyRoles && (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={companyRoles} strategy={rectSortingStrategy}>
                    {companyRoles.map(role => (
                      <CompanyRoles
                        key={role}
                        roleList={basicInfo.companyType}
                        hasDragPermission={hasEditPermission}
                        id={role}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              )}
            </Styled.CompanyTypeWrapper>
            <Styled.CompanyTypeWrapper>
              {!isCompanyView && (experienceLevel?.isIntern || experienceLevel?.to === 2) && (
                <Label
                  icon={experienceLevel?.isIntern ? 'internship' : 'entryLevel'}
                  background={'rgba(204, 213, 255, 0.11)'}
                >
                  {experienceLevel?.isIntern ? 'Student/Intern' : 'Entry level'}
                </Label>
              )}
            </Styled.CompanyTypeWrapper>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Tags
                hasDragPermission={hasEditPermission}
                type="roles"
                style={{ display: 'flex' }}
                styledWidth
                tags={roles}
              />
            </div>
          </Styled.TitleTexts>
          {isS && <UserCardBadges />}
        </Styled.TitleSection>
        <Styled.Spacer />
        <Styled.LocationAndLanguagesSection isCompanyView={isCompanyView}>
          {!isCompanyView && (
            <Styled.IconWrapper isCompanyView={isCompanyView}>
              {pronouns && (
                <Styled.FlexRowPronouns isCompanyView={isCompanyView}>
                  <Styled.StyledIcon icon="profile" color="rgba(230, 234, 255, 0.6)" />
                  <Styled.LocationContainer>{pronouns.label}</Styled.LocationContainer>
                </Styled.FlexRowPronouns>
              )}
              <Location
                renderLocation={renderLocation}
                dataHidden={dataHidden}
                pronouns={pronouns}
                isCompanyView={isCompanyView}
              />
              {languages?.length !== 0 && (
                <Languages
                  languages={languages}
                  allLanguages={allLanguages}
                  renderLocation={renderLocation}
                  isCompanyView={isCompanyView}
                />
              )}
            </Styled.IconWrapper>
          )}
        </Styled.LocationAndLanguagesSection>
        {isCompanyView && (
          <Styled.CompanyDetailsWrapper>
            <Styled.CompanyDetailSection>
              <Location
                renderLocation={renderLocation}
                dataHidden={dataHidden}
                pronouns={pronouns}
                isCompanyView={isCompanyView}
              />
              {languages?.length !== 0 && (
                <Languages
                  languages={languages}
                  allLanguages={allLanguages}
                  renderLocation={renderLocation}
                  isCompanyView={isCompanyView}
                />
              )}
            </Styled.CompanyDetailSection>
            <Styled.CompanyDetailSection location={renderLocation} languages={languages}>
              <Styled.FlexRowLocation isCompanyView={isCompanyView}>
                <Styled.StyledIcon icon="foundation" color="rgba(230, 234, 255, 0.6)" />
                <Styled.LocationContainer>{`Founded in ${yearFounded}`}</Styled.LocationContainer>
              </Styled.FlexRowLocation>
              <Styled.FlexRowLocation isCompanyView={isCompanyView}>
                <Styled.StyledIcon icon="company-size" color="rgba(230, 234, 255, 0.6)" />
                <Styled.LocationContainer>{`${companySize} Employees`}</Styled.LocationContainer>
              </Styled.FlexRowLocation>
            </Styled.CompanyDetailSection>
          </Styled.CompanyDetailsWrapper>
        )}
        <Styled.Spacer />
        {!isCompanyView && <UserCardAchievements profile={profile} />}
        <Styled.FlexRow>
          {shouldShowCreateProposal && (
            <Styled.Button
              data={{ job: null, jobId: null, isCompany, isCompanyOwner, activeProfile, applicant: profile }}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              onClick={event => !isCompany && handleCreateProposal(event)}
              opens={openCompanyModal && modalToOpen}
              icon="jobs"
            >
              Hire me
            </Styled.Button>
          )}
          {canEdit && (
            <Styled.Button
              padding="0px 24px"
              backgroundColor="rgba(204, 213, 255, 0.11)"
              icon="edit"
              opens="edit-talent-modal"
            >
              Edit
            </Styled.Button>
          )}
          {!canEdit && (
            <Styled.Button
              tooltipId="f5"
              overrideTooltipPosition
              icon="sendAMessage"
              backgroundColor="rgba(204, 213, 255, 0.11)"
              onClick={handleMessagesClick}
              tooltip={sendMessageTooltip}
              disabled={!isAuthedVettedAndVerified}
            >
              {shouldShowCreateProposal ? '' : 'Message'}
            </Styled.Button>
          )}
          {isCompanyView && profilePath.includes('company') && (
            <Styled.Button
              tooltipId="f3"
              tooltip="Coming Soon 😉"
              overrideTooltipPosition
              icon="share"
              backgroundColor="rgba(204, 213, 255, 0.11)"
              disabled
            />
          )}
          {!isCompanyView && profilePath.includes('talent') && (
            <Styled.Button
              tooltipId="f3"
              tooltip={shareButtonTooltip}
              overrideTooltipPosition
              icon="share"
              backgroundColor="rgba(204, 213, 255, 0.11)"
              onClick={() => setShareURLVisible(true)}
              noPadding
              disabled={!isAuthedVettedAndVerified}
            >
              {shareURLVisible && (
                <ShareURL
                  styles={{ container: { top: 40 } }}
                  visible={shareURLVisible}
                  setVisible={setShareURLVisible}
                  url={createSharingUrl({ type: 'profile', id: currentUserProfile })}
                  successText="The profile link has been copied"
                />
              )}
            </Styled.Button>
          )}
        </Styled.FlexRow>
      </Styled.Wrapper>

      {finalName?.length > 22 && !isS && !isXS && !dataHidden && (
        <Styled.StyledTooltip tooltipId="yourName" place="right">
          {finalName}
        </Styled.StyledTooltip>
      )}
      {yourX?.length > 58 && !isS && !isXS && (
        <Styled.StyledTooltip tooltipId="yourXTooltip" place="right">
          {yourX}
        </Styled.StyledTooltip>
      )}

      {!isCompanyView && <VerificationStatusCard />}
      {!isCompanyView && <IDVerificationVideoModal />}
    </Styled.Container>
  );
};

export default UserCard;
