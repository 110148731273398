import jobService from 'api/jobService';
import { useErrorSnackbar, useSuccessSnackbar } from 'hooks/useReactSnackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicantsActions, applicantsSelectors } from 'store/applicants';
import filtersQueryString from 'utils/filtersQueryString';
import { compose, flatten, values } from 'ramda';
import { portalActions } from 'store/portals';

const initialLoading = {
  loadingHireApplicant: false,
  loadingApplicants: false,
  loadingRejectApplicant: false,
  loadingChangeApplicantStatus: false,
  loadingCommentOnApplicant: false,
  loadingEditComment: false,
  loadingDeleteComment: false,
  loadingNotifyOwner: false
};

const useApplicantService = () => {
  const [loading, setLoading] = React.useState(initialLoading);

  const applicants = useSelector(applicantsSelectors.selectApplicants);
  const rejectedApplicants = useSelector(applicantsSelectors.selectRejectedApplicants);
  const savedApplicants = useSelector(applicantsSelectors.selectSavedApplicants);
  const hiredApplicants = useSelector(applicantsSelectors.selectHiredApplicants);
  const nonSavedHiredApplicants = useSelector(applicantsSelectors.selectNonSavedHiredApplicants);
  const nonRejectedUnsavedApplicants = useSelector(applicantsSelectors.selectNonRejectedUnsavedApplicants);

  const dispatch = useDispatch();
  const [hasMoreApplicants, setHasMore] = React.useState(true);
  const [applicantsPage, setPage] = React.useState(1);
  const [applicantFilters, setApplicantFilters] = React.useState({});

  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { openErrorSnackbar } = useErrorSnackbar();

  const hireApplicant = async (applicantId, jobId, serviceId, callback) => {
    if (!loading.loadingHireApplicant) {
      try {
        setLoading({ ...loading, loadingHireApplicant: true });
        dispatch(
          portalActions.openPortal({
            name: 'loading-modal',
            data: {
              description: 'Please wait while we create your proposal.'
            }
          })
        );
        let response;
        if (jobId) {
          response = await jobService.hireApplicant(applicantId, jobId);
        } else if (serviceId) {
          response = await jobService.createServiceProposal(applicantId, serviceId);
        } else {
          response = await jobService.createProposal(applicantId);
        }

        dispatch(
          applicantsActions.updateApplicantStatus({
            applicantId,
            status: 'hired'
          })
        );

        callback && callback(response.data);
      } catch (e) {
        dispatch(
          portalActions.closePortal({
            name: 'loading-modal'
          })
        );
        openErrorSnackbar('Failed to create proposal');
      } finally {
        setLoading({ ...loading, loadingHireApplicant: false });
      }
    }
  };

  const notifyOwnerContractCreation = async (applicantId, jobId, data, callback) => {
    try {
      setLoading({ ...loading, loadingNotifyOwner: true });
      const response = await jobService.notifyOwnerContractCreation(applicantId, jobId, data);
      callback && callback(response.data);
    } catch (e) {
      openErrorSnackbar('Failed to notify');
    } finally {
      setLoading({ ...loading, loadingNotifyOwner: false });
    }
  };

  const rejectApplicant = async (applicantId, jobId, callback) => {
    if (!loading.loadingRejectApplicant) {
      try {
        setLoading({ ...loading, loadingRejectApplicant: true });
        await jobService.rejectApplicant(applicantId, jobId);

        dispatch(
          applicantsActions.updateApplicantStatus({
            applicantId,
            status: 'rejected',
            rejectedApplicant: true
          })
        );

        openSuccessSnackbar('Application rejected');
        callback && callback();
      } catch (e) {
        openErrorSnackbar('Failed to reject');
      } finally {
        setLoading({ ...loading, loadingRejectApplicant: false });
      }
    }
  };

  const unrejectApplicant = async (applicantId, jobId, callback) => {
    if (!loading.loadingRejectApplicant) {
      try {
        setLoading({ ...loading, loadingRejectApplicant: true });
        await jobService.rejectApplicant(applicantId, jobId);

        dispatch(
          applicantsActions.updateApplicantStatus({
            applicantId,
            status: 'unsave',
            rejectedApplicant: false
          })
        );

        openSuccessSnackbar('Application restored');
        callback && callback();
      } catch (e) {
        openErrorSnackbar('Failed to restore');
      } finally {
        setLoading({ ...loading, loadingRejectApplicant: false });
      }
    }
  };

  const getApplicantsByStatus = (applicants, status = 'all') => {
    if (status === 'saved' || status === 'contacted' || status === 'hired') {
      const key = `${status}Applicant`;
      return applicants.filter(applicant => applicant[key] && !applicant.rejectedApplicant);
    }
    if (status === 'withRejected') {
      return applicants.filter(applicant => !applicant.savedApplicant && !applicant.hiredApplicant);
    }
    return applicants.filter(
      applicant => !applicant.savedApplicant && !applicant.hiredApplicant && !applicant.rejectedApplicant
    );
  };

  const getApplicants = React.useCallback(
    async (jobId, filters, page = 1, _, limit = 8, callback = () => ({})) => {
      if (jobId !== undefined && !loading.loadingApplicants) {
        try {
          setLoading({ ...loading, loadingApplicants: true });
          const {
            data: { relevant }
          } = await jobService.getApplicants(jobId, filtersQueryString(filters), page, limit);
          const { data, filters: resFilters, pagination } = relevant;

          const applicantsToArray = compose(flatten, values);
          const finalApplicants = applicantsToArray(data);

          dispatch(
            applicantsActions.updateApplicants({
              jobId,
              applicants: finalApplicants
            })
          );

          setApplicantFilters(resFilters);
          setHasMore(
            getApplicantsByStatus(applicants, filters['applicant-status']?.value).length + limit < pagination.total
          );
          setPage(page + 1);
        } catch {
          setHasMore(false);
        } finally {
          setLoading({ ...loading, loadingApplicants: false });
          callback && callback();
        }
      }
    },
    [applicants, loading.loadingApplicants, hasMoreApplicants, applicantsPage]
  );

  const changeApplicantStatus = async (profileId, jobId, status, callback) => {
    if (jobId !== undefined && profileId !== undefined && !loading.loadingChangeApplicantStatus) {
      try {
        setLoading({ ...loading, loadingChangeApplicantStatus: true });
        await jobService.changeApplicantStatus(profileId, jobId, status);

        dispatch(applicantsActions.updateApplicantStatus({ jobId, applicantId: profileId, status }));

        status === 'saved' && openSuccessSnackbar('Candidate shortlisted');
        status === 'unsave' && openSuccessSnackbar('Removed from shortlisted');

        callback && callback();
      } catch {
        status === 'saved' && openErrorSnackbar('Failed to shortlist');
        status === 'unsave' && openErrorSnackbar('Failed to remove');
      } finally {
        setLoading({ ...loading, loadingChangeApplicantStatus: false });
      }
    }
  };

  const commentOnApplicant = async (creatorId, talentId, img, name, jobId, text, callback) => {
    if (
      creatorId !== undefined &&
      talentId !== undefined &&
      img !== undefined &&
      name !== undefined &&
      jobId !== undefined &&
      !loading.loadingCommentOnApplicant
    ) {
      try {
        setLoading({ ...loading, loadingCommentOnApplicant: true });
        const { data } = await jobService.commentOnApplicant(creatorId, talentId, img, name, jobId, text);

        callback && callback(data);
      } catch {
        openErrorSnackbar('Oops! something went wrong');
      } finally {
        setLoading({ ...loading, loadingCommentOnApplicant: false });
      }
    }
  };

  const editComment = async (jobId, commentId, text, callback) => {
    if (jobId !== undefined && commentId !== undefined && !!text.length && !loading.loadingEditComment) {
      try {
        setLoading({ ...loading, loadingEditComment: true });
        await jobService.editComment(jobId, commentId, text);

        callback && callback();
      } catch {
        openErrorSnackbar('Oops! something went wrong');
      } finally {
        setLoading({ ...loading, loadingEditComment: false });
      }
    }
  };

  const deleteComment = async (jobId, commentId, callback) => {
    if (jobId !== undefined && commentId !== undefined && !loading.loadingDeleteComment) {
      try {
        setLoading({ ...loading, loadingDeleteComment: true });
        await jobService.deleteComment(jobId, commentId);

        callback && callback();
      } finally {
        setLoading({ ...loading, loadingDeleteComment: false });
      }
    }
  };

  return {
    loading,
    applicants: Object.values(applicants),
    rejectedApplicants: Object.values(rejectedApplicants),
    savedApplicants: Object.values(savedApplicants),
    hiredApplicants: Object.values(hiredApplicants),
    nonSavedHiredApplicants: Object.values(nonSavedHiredApplicants),
    nonRejectedUnsavedApplicants: Object.values(nonRejectedUnsavedApplicants),
    hasMoreApplicants: hasMoreApplicants,
    applicantsPage,
    applicantFilters,
    hireApplicant,
    rejectApplicant,
    unrejectApplicant,
    changeApplicantStatus,
    getApplicants,
    getApplicantsByStatus,
    commentOnApplicant,
    editComment,
    deleteComment,
    notifyOwnerContractCreation
  };
};

export default useApplicantService;
