import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as Styled from './StyledConversationLIst';
import ConversationItem from '../ConversationItem';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

const ConversationList = ({
  conversations,
  loading,
  searchTerm,
  selectedConversation,
  setSelectedConversation,
  setVisible
}) => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);

  const filteredConversations =
    conversations?.filter(conversation => {
      const participants = conversation?.custom?.participants;
      const sender = participants?.me;
      const recipient = participants?.recipient;
      const contact = sender?.id === profileId ? recipient : sender;

      return contact?.name.toLowerCase().includes(searchTerm.toLowerCase());
    }) ?? [];

  return (
    <Styled.Container>
      <InfiniteScroll
        pageStart={0}
        hasMore={false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
        useWindow={false}
        threshold={100}
      >
        {!loading &&
          filteredConversations.map(conversation => (
            <ConversationItem
              setVisible={setVisible}
              key={conversation.id}
              {...conversation}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
            />
          ))}
      </InfiniteScroll>
    </Styled.Container>
  );
};

export default ConversationList;
