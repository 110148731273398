import Icon from 'common/Icon';
import MD5 from 'crypto-js/md5';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import union from 'lodash/fp/union';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'store/auth';
import { selectCurrentUserProperty, selectIsAuthLoading } from 'store/auth/authSelectors';
import { profileActions, profileSelectors } from 'store/profile';
import { selectIsMyProfileCompany, selectMyProfileId } from 'store/profile/profileSelectors';
import { currentUserIdSelector } from 'store/selectors/currentUser';
import { companyProfileService } from '../../../api';
import searchService from '../../../api/searchService';
import talentService from '../../../api/talentService';
import ContainedButton from '../../../common/ContainedButton';
import FormCheckbox from '../../../common/form/FormCheckbox';
import FormSelect from '../../../common/form/FormSelect';
import Typography from '../../../components/shared/Typography';
import { Column, Table } from '../../../components/Table';
import { portalActions } from '../../../store/portals';
import commercialEntities from '../../../utils/commercial-entities';
import countries from '../../../utils/countries';
import jobTypes from '../../../utils/job-types';
import specificWorkingHoursList from '../../../utils/specificWorkingHoursList';
import SettingsSaved from '../../backoffice/SettingsSaved';
import BiasReducedMode from '../BiasReducedMode/BiasReducedMode';
import CompanyMemberPermissionsModal from '../ProfileSettingModals/CompanyMemberPermissionsModal';
import DeleteCompanyMemberModal from '../ProfileSettingModals/DeleteCompanyMemberModal';
import {
  ActionButtons,
  CommercialEntityFormCheckbox,
  CompanyContent,
  ContainedButtonIcon,
  Content,
  CopyUrlSuccess,
  CopyUrlSuccessText,
  CopyUrlWrapper,
  GridFiller,
  GridWrapper,
  HoursFormInput,
  Loader,
  SettingsFooter,
  Spacer,
  SpecificDate,
  SpecificDateContainer,
  StyledButton,
  StyledButtonsContainer,
  StyledContainedButton,
  StyledFormInput,
  StyledLabel,
  StyledOrigin,
  WorkingHoursFormInput,
  WorkingHoursFormInputContainer
} from './StyledProfileSettings';

const initialWorkingHours = [
  {
    from: 9,
    to: 17
  },
  {
    from: 9,
    to: 17
  },
  {
    from: 9,
    to: 17
  },
  {
    from: 9,
    to: 17
  },
  {
    from: 9,
    to: 17
  },
  {
    from: 9,
    to: 17
  },
  {
    from: 9,
    to: 17
  }
];

const commonSettingKeys = [
  'jobType',
  'eligibleWork',
  'timezone',
  'hoursPerWeek',
  'workingHoursFrom',
  'workingHoursTo',
  'weekendWorking',
  'biasReducedMode'
];

const talentSettingKeys = ['relocation', 'commercial', 'commercialEntity'];

const ProfileSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [publicUrl, setPublicUrl] = useState('');
  const [publicUrlError, setPublicUrlError] = useState('');
  const [jobType, setJobType] = useState('');
  const [commercialEntity, setCommercialEntity] = useState('');
  const [eligibleWork, setEligibleWork] = useState([]);
  const [relocation, setRelocation] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [hours, setHours] = useState('');
  const [workingHoursFrom, setWorkingHoursFrom] = useState('');
  const [workingHoursTo, setWorkingHoursTo] = useState('');
  const userId = useSelector(currentUserIdSelector);
  const [weekendWorking, setWeekendWorking] = useState(false);
  const [maxHoursError, setMaxHoursError] = useState('');
  const [workingHoursLimitError, setWorkingHoursLimitError] = useState('');
  const [commercial, setCommercial] = useState(false);
  const [specificWorkingHours, setSpecificWorkingHours] = useState(false);
  const [companyMembers, setCompanyMembers] = useState([]);
  const [copyUrlSuccessVisible, setCopyUrlSuccessVisible] = useState(false);
  const [changeHash, setChangeHash] = useState(false);
  const currentUserBiosReducedMode = !!useSelector(profileSelectors.selectMyProfileProperty('biasReducedMode'));
  const [biasReducedMode, setBiasReducedMode] = useState(currentUserBiosReducedMode);
  const isAutLoading = useSelector(selectIsAuthLoading);
  const myProfileId = useSelector(selectMyProfileId);
  const isCompany = useSelector(selectIsMyProfileCompany);
  const dispatch = useDispatch();
  const activeUserId = useSelector(selectCurrentUserProperty('_id'));

  const [specificWorkingHoursValues, setSpecificWorkingHoursValues] = useState(initialWorkingHours);

  const specificDateList = [
    {
      label: 'Monday'
    },
    {
      label: 'Tuesday'
    },
    {
      label: 'Wednesday'
    },
    {
      label: 'Thursday'
    },
    {
      label: 'Friday'
    },
    {
      label: 'Saturday'
    },
    {
      label: 'Sunday'
    }
  ];

  const prefixRef = useRef();
  const formInputRef = useRef();

  const formInputPaddingLeft = prefixRef.current?.getBoundingClientRect().width + 17;

  useEffect(() => {
    if (!isAutLoading) {
      loadTimezones();
      loadProfile(isCompany ? myProfileId : userId);
    }
  }, [isAutLoading]);

  const loadTimezones = () => {
    searchService.getTimezones().then(response => setTimezones(response.data));
  };

  const loadProfile = async profileId => {
    const service = isCompany ? companyProfileService.getCompany : talentService.getTalentByUserId;

    const { data } = await service(profileId);
    const profile = isCompany ? data.data : data;

    const settingKeys = union(!isCompany ? talentSettingKeys : [], commonSettingKeys);

    const str = profile.publicUrl.concat(
      settingKeys.reduce((curr, key) => {
        if (key === 'eligibleWork') {
          curr = curr.concat(profile[key] ? JSON.stringify(profile[key]) : []);
        } else {
          const defaultValueFalse = ['weekendWorking', 'biasReducedMode', 'relocation', 'commercial'];
          const defaultValueNull = ['timezone'];
          let defaultValue = '';
          if (defaultValueFalse.includes(key)) {
            defaultValue = false;
          }
          if (defaultValueNull.includes(key)) {
            defaultValue = null;
          }

          curr = curr.concat(profile[key] ?? defaultValue);
        }

        return curr;
      }, '')
    );

    setChangeHash(MD5(str).toString());

    if (isCompany) {
      setCompany(profile);
      setCompanyMembers(profile.members);
    } else {
      setUser(profile);
      setCommercial(profile.commercial);
      setCommercialEntity(profile.commercialEntity);
    }
    setPublicUrl(profile.publicUrl);
    setRelocation(profile.relocation);
    setTimezone(profile.timezone);
    setJobType(profile.jobType);
    setHours(profile.hoursPerWeek);
    setBiasReducedMode(profile.biasReducedMode || false);
    setWorkingHoursFrom(profile.workingHoursFrom);
    setWorkingHoursTo(profile.workingHoursTo);
    setWeekendWorking(profile.weekendWorking);
    setWorkingHoursFrom(profile.workingHoursFrom);
    setWorkingHoursTo(profile.workingHoursTo);
    setWeekendWorking(profile.weekendWorking);

    profile.specificWorkingHoursValues.length > 0 && setSpecificWorkingHoursValues(profile.specificWorkingHoursValues);
    profile.specificWorkingHoursValues.length > 0 && setSpecificWorkingHours(true);

    setEligibleWork(
      profile.eligibleWork?.reduce((curr, item) => {
        const country = countries.find(it => it.value === item);
        if (country) {
          curr.push(country);
        }
        return curr;
      }, []) ?? null
    );

    setIsLoading(false);
  };

  const handleHours = value => {
    setMaxHoursError('');
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      value > 168 && setMaxHoursError('Value must be less or equal to 168');
      setHours(value);
    }
  };

  const updateSpecificHourFrom = index => e => {
    const newArr = [...specificWorkingHoursValues];
    newArr[index].from = e.value;
    setSpecificWorkingHoursValues(newArr);
    setSaving(false);
  };

  const updateSpecificHourTo = index => e => {
    const newArr = [...specificWorkingHoursValues];
    newArr[index].to = e.value;
    setSpecificWorkingHoursValues(newArr);
    setSaving(false);
  };

  const handleSubmit = profileId => {
    setPublicUrlError('');

    if (maxHoursError.length > 0) {
      return;
    }

    if (workingHoursLimitError.length > 0) {
      return;
    }

    setSaving(true);

    const eligibleWorkList = [];

    eligibleWork &&
      eligibleWork.map(work => {
        eligibleWorkList.push(work.value);
      });

    const service = isCompany ? companyProfileService : talentService;

    const payload = {
      publicUrl,
      jobType,
      eligibleWork: eligibleWorkList,
      relocation,
      timezone,
      hoursPerWeek: hours,
      workingHoursFrom,
      workingHoursTo,
      weekendWorking,
      commercial,
      specificWorkingHours,
      specificWorkingHoursValues,
      commercialEntity: commercialEntity?.value,
      biasReducedMode,
      currentUserId: activeUserId
    };

    service
      .updateProfile(profileId, payload)
      .then(({ data: { profile } }) => {
        if (profile && (profile.talentId || profile.publicUrl)) {
          setPublicUrl(profile.talentId ?? profile.publicUrl);
        }
        setSaving(true);
        dispatch(
          authActions.updateCurrentUserProperty({ property: 'activeProfile.biasReducedMode', value: biasReducedMode })
        );
        dispatch(
          profileActions.updateProfileProperty({
            profileId,
            property: 'biasReducedMode',
            value: biasReducedMode,
            updateType: 'update'
          })
        );
        dispatch(
          portalActions.openPortal({
            name: 'settings-saved-modal',
            data: {
              text: "The changes you've made to the settings have been saved"
            }
          })
        );
      })
      .catch(error => {
        setSaving(false);
        const errorData = get('response.data', error);
        const validationError = find(['param', 'publicUrl'], get('response.data.errors', error));

        if (errorData?.field === 'publicUrl' || validationError) {
          setPublicUrlError(errorData.message ?? validationError.msg);
        }
      });
  };

  const handleWorkingHoursFrom = e => {
    setWorkingHoursLimitError('');
    const to = workingHoursTo?.value ? workingHoursTo.value : workingHoursTo;
    if (to === e.value) {
      setWorkingHoursLimitError('From and To are same');
    }
    setWorkingHoursFrom(e.value);
  };

  const handleWorkingHoursTo = e => {
    setWorkingHoursLimitError('');
    const from = workingHoursFrom?.value ? workingHoursFrom.value : workingHoursFrom;
    if (from === e.value) {
      setWorkingHoursLimitError('From and To are same');
    }
    setWorkingHoursTo(e.value);
  };

  const handleUrlClick = () => {
    if (prefixRef && prefixRef.current) {
      const url = `${prefixRef.current.innerText.replace('www.', '')}${publicUrl}`;
      navigator.clipboard.writeText(url);
      setCopyUrlSuccessVisible(true);
      setTimeout(() => {
        setCopyUrlSuccessVisible(false);
      }, 1000);
    }
  };

  const handleResetClick = () => {
    const profile = company ?? user;
    const profilePublicUrl = profile.publicUrl ?? profile._id;

    setPublicUrl(profilePublicUrl);
    setRelocation(profile.relocation ?? false);
    setJobType(profile.jobType);
    setEligibleWork(
      profile.eligibleWork.reduce((curr, item) => {
        const country = countries.find(it => it.value === item);
        if (country) {
          curr.push(country);
        }
        return curr;
      }, []) ?? null
    );
    setTimezone(profile.timezone ?? null);
    setHours(profile.hoursPerWeek ?? '');
    setWorkingHoursFrom(profile.workingHoursFrom ?? '');
    setWorkingHoursTo(profile.workingHoursTo ?? '');
    setWeekendWorking(profile.weekendWorking ?? false);
    setSpecificWorkingHoursValues(initialWorkingHours);
    setCommercial(profile.commercial ?? false);
    setCommercialEntity(profile.commercialEntity ?? '');
    setBiasReducedMode(currentUserBiosReducedMode ?? false);
  };

  const calculateChangeHash = useCallback(() => {
    if (isCompany) {
      const ew = [];
      if (eligibleWork) {
        eligibleWork.map(e => {
          ew.push(e.value);
        });
      }
      const str =
        publicUrl &&
        publicUrl.concat(
          jobType ?? '',
          ew ? JSON.stringify(ew) : [],
          timezone ?? null,
          hours ?? '',
          workingHoursFrom ?? '',
          workingHoursTo ?? '',
          weekendWorking ?? false,
          biasReducedMode ?? false
        );

      return MD5(str).toString();
    } else {
      const ew = [];
      if (eligibleWork) {
        eligibleWork.map(e => {
          ew.push(e.value);
        });
      }
      const str =
        publicUrl &&
        publicUrl.concat(
          jobType ?? '',
          ew ? JSON.stringify(ew) : [],
          timezone ?? null,
          hours ?? '',
          workingHoursFrom ?? '',
          workingHoursTo ?? '',
          weekendWorking ?? false,
          biasReducedMode ?? false,
          relocation ?? false,
          commercial ?? false,
          commercialEntity ?? ''
        );

      return MD5(str).toString();
    }
  }, [
    publicUrl,
    jobType,
    eligibleWork,
    timezone,
    hours,
    workingHoursFrom,
    workingHoursTo,
    weekendWorking,
    relocation,
    commercial,
    commercialEntity,
    biasReducedMode
  ]);

  useEffect(() => {
    calculateChangeHash(changeHash) === changeHash ? setSaving(true) : setSaving(false);
  }, [calculateChangeHash]);

  return (
    <>
      {isLoading ? (
        <Content>
          <Loader centered />
        </Content>
      ) : (
        <>
          <div>
            {isCompany && (
              <CompanyContent>
                <Typography mb={32} variant="h3">
                  Company members
                </Typography>
                <StyledButton data={{ companyId: company?._id }} opens="company-member-permissions-modal">
                  Add New
                </StyledButton>
                <Table keyId="_id" staticData={companyMembers} noSort adjustHeight>
                  <Column width="25%" title="First name" keyName="user.firstName" />
                  <Column width="25%" title="Last name" keyName="user.lastName" />
                  <Column width="30%" title="Email" keyName="user.email" />
                  <Column width="12%" title="Permission" keyName="role" />
                  <Column
                    title=""
                    width="8%"
                    type="action"
                    render={member => (
                      <StyledButtonsContainer>
                        <ContainedButtonIcon
                          icon="edit"
                          color="rgba(230, 234, 255, 0.35)"
                          size={13}
                          backgroundColor="transparent"
                          onClick={() =>
                            dispatch(
                              portalActions.openPortal({
                                name: 'company-member-permissions-modal',
                                data: {
                                  companyId: company?._id,
                                  memberId: member.user._id,
                                  email: member.user.email,
                                  permission: member.role
                                }
                              })
                            )
                          }
                        />
                      </StyledButtonsContainer>
                    )}
                  />
                </Table>

                <CompanyMemberPermissionsModal callback={setCompanyMembers} />
                <DeleteCompanyMemberModal
                  callback={memberId =>
                    setCompanyMembers(members => members.filter(member => member.user._id !== memberId))
                  }
                />
              </CompanyContent>
            )}
            <Content>
              <GridWrapper>
                <StyledFormInput
                  ref={formInputRef}
                  value={publicUrl}
                  width={`100%`}
                  paddingLeft={formInputPaddingLeft}
                  hintText="Your custom URL must contain 3-100 letters or numbers. Please do not use spaces, symbols, or special characters."
                  onChange={e => setPublicUrl(e.target.value)}
                  label="Public profile URL"
                  error={publicUrlError}
                >
                  <StyledOrigin ref={prefixRef} error={publicUrlError}>
                    www.{window.location.host}/{isCompany ? 'company' : 'talent'}/
                  </StyledOrigin>
                </StyledFormInput>
                <GridFiller />
              </GridWrapper>
              <CopyUrlWrapper>
                <ContainedButton onClick={handleUrlClick}>Copy Url</ContainedButton>
                {copyUrlSuccessVisible && (
                  <CopyUrlSuccess>
                    <Icon icon="checkmark" color="#00FF00" />
                    <CopyUrlSuccessText>Url has been copied</CopyUrlSuccessText>
                  </CopyUrlSuccess>
                )}
              </CopyUrlWrapper>
              <br />
              <br />
              <FormSelect
                value={jobTypes.find(jt => jt.value === jobType) || null}
                options={jobTypes}
                onChange={e => setJobType(e.value)}
                label="What jobs are you looking for?"
              />
              <br />
              <br />
              <FormSelect
                value={eligibleWork}
                options={countries}
                isMulti
                onChange={setEligibleWork}
                label="Where you are eligible to work"
                hintText="We'll hide this from your public profile."
              />
              <br />
              {!isCompany && (
                <div>
                  <FormCheckbox
                    checked={relocation}
                    onChange={() => setRelocation(!relocation)}
                    text="I'm open to relocation"
                  />
                  <br />
                  <br />
                </div>
              )}
              <FormSelect
                value={timezones.find(tz => tz?.value === timezone) || null}
                options={timezones}
                onChange={e => setTimezone(e.value)}
                hintText="Please verify your time zone so we could better understand your schedule"
                label="Your time zone"
              />
              <br />
              <br />
              <HoursFormInput
                value={hours}
                hintText="The total per week you’d like to work with TheXPlace Clients"
                onChange={e => handleHours(e.target.value)}
                label="Hours"
                error={maxHoursError}
              />
              <br />
              <br />
              {!specificWorkingHours ? (
                <div>
                  <WorkingHoursFormInputContainer>
                    <WorkingHoursFormInput>
                      <FormSelect
                        value={specificWorkingHoursList.find(wh => wh.value === workingHoursFrom)}
                        options={specificWorkingHoursList}
                        onChange={handleWorkingHoursFrom}
                        hintText="From"
                        error={workingHoursLimitError}
                        label="Preferable working hours"
                      />
                    </WorkingHoursFormInput>
                    <WorkingHoursFormInput>
                      <StyledLabel>
                        <div>&nbsp;</div>
                      </StyledLabel>
                      <FormSelect
                        value={specificWorkingHoursList.find(wh => wh.value === workingHoursTo)}
                        options={specificWorkingHoursList}
                        onChange={handleWorkingHoursTo}
                        hintText="To"
                      />
                    </WorkingHoursFormInput>
                  </WorkingHoursFormInputContainer>
                  <br />
                  <FormCheckbox
                    checked={weekendWorking}
                    onChange={() => setWeekendWorking(!weekendWorking)}
                    text={!isCompany ? 'I will work on Saturdays and Sundays' : 'We work on Saturdays and Sundays'}
                  />
                  <br />
                  <br />
                  <SpecificDate onClick={() => setSpecificWorkingHours(true)}>
                    Set a specific time for different days
                  </SpecificDate>
                </div>
              ) : (
                <div>
                  {specificDateList.map((date, index) => (
                    <div key={index}>
                      <SpecificDateContainer>
                        <WorkingHoursFormInput>
                          <FormSelect
                            value={specificWorkingHoursList.filter(
                              option => option.value === specificWorkingHoursValues[index].from
                            )}
                            options={specificWorkingHoursList}
                            onChange={updateSpecificHourFrom(index)}
                            hintText="From"
                            label={date.label}
                          />
                        </WorkingHoursFormInput>
                        <WorkingHoursFormInput>
                          <StyledLabel>
                            <div>&nbsp;</div>
                          </StyledLabel>
                          <FormSelect
                            value={specificWorkingHoursList.filter(
                              option => option.value === specificWorkingHoursValues[index].to
                            )}
                            options={specificWorkingHoursList}
                            onChange={updateSpecificHourTo(index)}
                            hintText="To"
                          />
                        </WorkingHoursFormInput>
                      </SpecificDateContainer>
                      {index === 4 && (
                        <div>
                          <br />
                          <FormCheckbox
                            checked={weekendWorking}
                            onChange={() => setWeekendWorking(!weekendWorking)}
                            text={
                              !isCompany ? 'I will work on Saturdays and Sundays' : 'We work on Saturdays and Sundays'
                            }
                          />
                          <br />
                          <br />
                        </div>
                      )}
                    </div>
                  ))}

                  <SpecificDate onClick={() => setSpecificWorkingHours(false)}>Set one time for all days</SpecificDate>
                </div>
              )}

              <BiasReducedMode checked={biasReducedMode} onChangeHandler={setBiasReducedMode} />

              {!isCompany && (
                <>
                  <Spacer />
                  <CommercialEntityFormCheckbox
                    checked={commercial}
                    onChange={() => setCommercial(!commercial)}
                    text="There’s a commercial entity through which I operate as a freelancer"
                  />
                </>
              )}
              {!isCompany && commercial && (
                <>
                  <WorkingHoursFormInput>
                    <FormSelect
                      value={commercialEntities.find(jt => jt.value === (commercialEntity?.value ?? commercialEntity))}
                      options={commercialEntities}
                      onChange={setCommercialEntity}
                      label="Type of commercial entity"
                    />
                  </WorkingHoursFormInput>
                  <br />
                </>
              )}
              <SettingsSaved />
            </Content>
          </div>
          <SettingsFooter>
            <ActionButtons>
              <ContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleResetClick}>
                Reset
              </ContainedButton>
              <StyledContainedButton type="submit" disabled={saving} onClick={() => handleSubmit(myProfileId)}>
                Save Changes
              </StyledContainedButton>
            </ActionButtons>
          </SettingsFooter>
        </>
      )}
    </>
  );
};

export default ProfileSettings;
