import styled from '@emotion/styled';

export const ProgressContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  background: ${props =>
    props.isCompleted
      ? 'linear-gradient(225deg, #00FF40 0%, #0A731F 100%)'
      : 'linear-gradient(83.66deg, #8a00ac 0%, #5b4cfd 100%)'};
  width: 60px;
  height: 60px;
  z-index: 99;
`;
