import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import filter from 'lodash/fp/filter';

import { currentUserPropertySelector } from '../selectors/currentUser';

const selectDomain = state => state.talent;

export const selectTalent = createSelector([selectDomain], talent => talent);

export const selectIsTalentLoaded = createSelector([selectDomain], talent => get('_id', talent));

export const selectTalentProperty = property => createSelector([selectDomain], talent => get(property, talent));

export const hasTalentEditPermission = createSelector(
  [currentUserPropertySelector('_id'), selectTalentProperty('userId')],
  (currentUserId, talentUserId) => currentUserId === talentUserId
);

export const canPublishProject = createSelector(
  [selectTalentProperty('projects')],
  projects => size(filter(project => project.status === 'published', projects)) < 8
);

export const selectIsCompanyView = createSelector([selectTalent], talent => talent.isCompanyView);
