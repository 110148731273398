import styled from '@emotion/styled';
import { backOfficeService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import React from 'react';
import { roles } from '../../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const fields = {
  user: { initialValue: '', validate: ['isRequired'] },
  permission: { initialValue: '', validate: ['isRequired'] }
};

const BackOfficeAddCompanyMemberModal = ({ closePortal, data, name, refetch }) => {
  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: params => backOfficeService.addMemberInCompany(data.companyId, params),
    onSubmitSuccess: () => {
      refetch();
      closePortal();
    }
  });

  return (
    <TxplModal
      title="Add company member"
      appElement={document.getElementById('root-modal')}
      size="large"
      name={name}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="secondary">
            Cancel
          </ContainedButton>
          <ContainedButton form="backOfficeAddCompanyMemberModal" type="submit">
            Add user
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="backOfficeAddCompanyMemberModal">
        <FormInput
          mb={40}
          label="User"
          hintText="Search by user ID or email"
          value={values.user}
          onChange={onFieldChange('user')}
          error={errors.user}
          autoFocus
        />

        <FormSelect
          label="Permission"
          value={roles.find(role => role.value === values.permission)}
          onChange={option => onFieldChange('permission')(option.value)}
          error={errors.permission}
          options={roles}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('add-company-member-modal')(BackOfficeAddCompanyMemberModal);
