import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions, authSelectors } from 'store/auth';
import { profileActions } from 'store/profile';
import { collaborationsActions } from 'store/collaborations';
import useTalkSession from 'hooks/useTalkSession';
import { cookieAuthName, getCookie } from 'utils/cookies';

function AutoLogout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthed = useSelector(authSelectors.isAuthed);

  const { endSession } = useTalkSession();

  const listenToLocalStorage = useCallback(() => {
    const token = getCookie(`${cookieAuthName}.it`);
    if (token === null && isAuthed) {
      dispatch(authActions.logout());
      dispatch(profileActions.clearProfiles());
      dispatch(collaborationsActions.clearCollaborations());
      endSession();
      history.push('/login');
    }
  }, [dispatch, history, isAuthed]);

  useEffect(() => {
    window.addEventListener('storage', listenToLocalStorage);

    return () => {
      window.removeEventListener('storage', listenToLocalStorage);
    };
  }, [listenToLocalStorage]);

  return null;
}

export default AutoLogout;
