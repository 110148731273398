export const excludeByType = {
  platform: { igdbLogo: true, theXPlaceLogo: true, lists: true },
  tool: { igdbLogo: true, theXPlaceLogo: true, lists: true },
  skill: { igdbLogo: true, theXPlaceLogo: true, lists: true, logo: true },
  genre: { igdbLogo: true, theXPlaceLogo: true, lists: true, logo: true },
  tag: { igdbLogo: true, theXPlaceLogo: true, logo: true },
  entry: { igdbLogo: true, theXPlaceLogo: true, logo: true },
  role: { igdbLogo: true, theXPlaceLogo: true, logo: true },
  discipline: { igdbLogo: true, theXPlaceLogo: true, logo: true },
  company: { lists: true, logo: true },
  keyword: { igdbLogo: true, theXPlaceLogo: true, lists: true, logo: true }
};

export const excludeMissingItemByType = {
  role: { logo: true },
  skill: { logo: true, lists: true },
  genre: { logo: true, lists: true },
  tag: { logo: true, lists: true },
  platform: { lists: true }, // todo upload
  tool: { lists: true } // todo upload
};
