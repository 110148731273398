import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import FormTextarea from 'common/form/FormTextarea';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import { portalActions } from 'store/portals';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';
import useForm from 'hooks/useForm';

const StyledIcon = styled(Icon)`
  padding: 0 5px;
  margin-top: 5px;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
const StyledCancelButton = styled(ContainedButton)`
  margin: 0 3px;
`;

const ProjectHandiworkModal = ({ closePortal, data, loading, name, onSave }) => {
  const dispatch = useDispatch();

  const openInstructions = () => {
    dispatch(portalActions.openPortal({ name: 'project-overview-instructions-modal' }));
  };

  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields: {
      projectOverview: {
        initialValue: data.handiwork ?? '',
        validate: ['isRequired'],
        trim: true
      }
    },
    callApi: async fields => onSave(fields.projectOverview),
    closePortal: () => {
      if (!data.notClosePortal) {
        closePortal();
      }
    }
  });

  return (
    <TxplModal
      size="large"
      padding="0 0 82px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      addPaddingTop
      XSaddPaddingTop
      renderFooter={
        <>
          {loading && <Spinner />}
          <StyledCancelButton
            mr={6}
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
            disabled={loading}
          >
            Cancel
          </StyledCancelButton>
          <ContainedButton
            onClick={onSubmit}
            backgroundColor="#7266EE"
            forceDisplayText
            disabled={loading}
            data-testid="save-portfolio-overview"
          >
            Save
          </ContainedButton>
        </>
      }
    >
      <FormTextarea
        value={values.projectOverview}
        error={errors.projectOverview}
        hintText={
          <>
            <Typography>
              What was your specific contribution? What magic touch did you bring to the project?
              <StyledIcon onClick={openInstructions} icon="question" color="rgba(230, 234, 255, 0.35);" size={10} />
              <StyledSpan onClick={openInstructions}>See instructions</StyledSpan>
            </Typography>
          </>
        }
        label="Project Overview"
        onChange={e => onFieldChange('projectOverview')(e.target.value)}
        maxLength={1200}
        required
      />
    </TxplModal>
  );
};

export default withRenderPortal('project-handiwork-modal')(ProjectHandiworkModal);
