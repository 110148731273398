import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import { portalActions } from 'store/portals';
import Icon from './Icon';

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderMenuItem = styled.div`
  font-family: Space Grotesk;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  width: 80px;
  position: relative;
  border-bottom: 2px solid transparent;
  position: relative;
  cursor: pointer;
  @media ${pageSize.M} {
    width: 65px;
  }
  @media ${pageSize.S} {
    width: 55px;
  }
  @media ${pageSize.XS} {
    width: 50px;
  }

  .header-Icon path {
    fill: rgba(230, 234, 255, 0.35) !important;
  }

  &:hover,
  &.opened {
    .header-MenuLabel {
      color: white;
    }
    .header-Icon path {
      fill: white !important;
    }
  }

  &.disabled {
    cursor: default;
    .header-Icon path {
      fill: rgba(230, 234, 255, 0.35);
    }
    .header-MenuLabel {
      color: rgba(230, 234, 255, 0.35);
    }
    &:hover,
    &.opened {
      .header-Icon path {
        fill: rgba(230, 234, 255, 0.35) !important;
      }
    }
  }

  ${props => (props.active ? 'border-bottom: 2px solid #fff' : '')}
`;

const PageHeaderGuest = () => {
  const dispatch = useDispatch();

  const helpPopup = () => {
    dispatch(
      portalActions.openPortal({
        name: 'help-modal'
      })
    );
  };

  return (
    <Header>
      <HeaderMenuItem onClick={() => helpPopup()}>
        <div>
          <Icon className="header-Icon" icon="question" size={16} />
        </div>
      </HeaderMenuItem>
    </Header>
  );
};
export default PageHeaderGuest;
