import React from 'react';
import styled from '@emotion/styled';

import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';

const StyledContainer = styled.div`
  height: 32px;
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(204, 213, 255, 0.05);
  margin: 16px 0;
`;

const CreditReferenceCard = ({ handleDelete, reference }) => (
  <StyledContainer>
    <Typography variant="normal">{reference.name}</Typography>
    <ContainedButton
      icon="delete"
      onClick={() => handleDelete(reference)}
      type="button"
      iconColor="#FF5151;"
      backgroundColor="transparent"
      color="rgba(204, 213, 255, 0.11);"
    />
  </StyledContainer>
);

export default CreditReferenceCard;
