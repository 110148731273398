import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { portalActions } from 'store/portals';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';

const StyledAddExperienceFooterContainer = styled.div`
  background: rgba(204, 213, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const StyledRightSideContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const AddExperienceFooter = ({ dirty, save, submitting, toggleMode }) => {
  const dispatch = useDispatch();

  const onCancel = () => {
    if (!dirty) {
      return toggleMode();
    }
    dispatch(
      portalActions.openPortal({
        name: 'leave-page-modal',
        data: {
          onOk: () => {
            toggleMode();
          },
          onCancel: () => {}
        }
      })
    );
  };

  return (
    <StyledAddExperienceFooterContainer>
      {submitting && <Spinner />}
      <ContainedButton type="button" backgroundColor="secondary" mr={6} ml={6} onClick={onCancel}>
        Cancel
      </ContainedButton>

      <StyledRightSideContainer>
        <ContainedButton onClick={save}>Save</ContainedButton>
      </StyledRightSideContainer>
    </StyledAddExperienceFooterContainer>
  );
};

export default AddExperienceFooter;
