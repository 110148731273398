import { api } from 'api';
import React from 'react';

function useToken() {
  const [isValidToken, setIsValidToken] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const checkActivationToken = async (email, token) => {
    try {
      setLoading(true);
      const {
        data: { data, success }
      } = await api.put('/backoffice/users/check-activation-token', { email, token });
      if (success && data.isValidToken) {
        setIsValidToken(true);
      }
    } catch (err) {
      setIsValidToken(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    isValidToken,
    loading,
    checkActivationToken
  };
}

export default useToken;
