import React from 'react';
import Icon from 'common/Icon';
import * as Styled from './StyledCollaborations';

function CollaborationsEmptyState({ activeButton = 'input needed' }) {
  return (
    <>
      <Styled.CollaborationsIconContainer>
        <Icon icon={activeButton === 'input needed' ? 'input_needed_icon' : 'request_sent_icon'} size="64" />
      </Styled.CollaborationsIconContainer>
      <Styled.CollaborationsText>
        {activeButton === 'input needed'
          ? 'The collaboration requests requiring your input will appear here'
          : 'The collaboration requests you’ve sent to others will appear here'}
      </Styled.CollaborationsText>
    </>
  );
}

export default CollaborationsEmptyState;
