const initialState = null;

function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_COMPANIES':
      return [...action.payload.companies];

    case 'DELETE_COMPANY':
      return [...state.filter(company => company._id !== action.payload.id)];

    case 'ADD_COMPANY':
      return [...state, action.payload];

    case 'UPDATE_COMPANY':
      return [
        ...state.map(company => (company._id === action.payload._id ? { ...company, ...action.payload } : company))
      ];

    default:
      return state;
  }
}

export default companiesReducer;
