import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import withRenderPortal from 'hocs/withRenderPortal';

import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';

import BenefitItem from 'common/Benefits/BenefitItem';
import lifeServices from '../../../api/lifeServices';
import { profileSelectors } from 'store/profile';
import { StyledAddMoreButton, StyledBenefitItem, StyledBenefits, StyledCancelButton } from './StyledBenefitModal';

const BenefitModal = ({
  benefitIds,
  benefitList,
  categoryId,
  closePortal,
  companyBenefits,
  loading,
  name,
  setBenefitList,
  setCompanyBenefits,
  setEditBenefit,
  setLoading
}) => {
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const [selectedBenefitIds, seSelectedBenefitIds] = useState(benefitIds);

  const saveBenefits = () => {
    setLoading(true);

    lifeServices
      .addBenefit(companyId, {
        benefits: selectedBenefitIds,
        categoryId
      })
      .then(res => {
        setCompanyBenefits(res.data.data);
        setLoading(false);
        closePortal();
      });
  };

  const handleCheck = id => {
    selectedBenefitIds.includes(id)
      ? seSelectedBenefitIds(selectedBenefitIds.filter(benefitId => benefitId !== id))
      : seSelectedBenefitIds([...selectedBenefitIds, id]);
  };

  const handleClose = () => {
    lifeServices.getSavedBenefits(companyId, categoryId).then(res => {
      setCompanyBenefits(res.data.data);
    });
    const ids = companyBenefits?.map(benefit => benefit._id);
    seSelectedBenefitIds(ids);
    closePortal();
  };

  return (
    <TxplModal
      name={name}
      testTitle
      title="Choose the benefits to add"
      padding="56px 0 82px 0"
      titlePadding="16px"
      XSpadding
      boldWeight
      allowLine
      addPaddingTop
      XSaddPaddingTop="14px"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loading && <Spinner />}
          <StyledCancelButton forceDisplayText onClick={handleClose} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </StyledCancelButton>
          <ContainedButton forceDisplayText onClick={() => saveBenefits()}>
            save
          </ContainedButton>
        </>
      }
    >
      <StyledBenefits>
        {benefitList.map(benefit => (
          <StyledBenefitItem key={benefit._id}>
            <BenefitItem
              id={benefit._id}
              name={benefit.name}
              icon={benefit.icon}
              iconColor={benefit.iconColor}
              isCustom={benefit.isCustom}
              setLoading={setLoading}
              benefitIds={selectedBenefitIds}
              setBenefitIds={seSelectedBenefitIds}
              handleCheck={handleCheck}
              setBenefitList={setBenefitList}
              setEditBenefit={setEditBenefit}
              companyBenefits={companyBenefits}
              setCompanyBenefits={setCompanyBenefits}
            />
          </StyledBenefitItem>
        ))}
      </StyledBenefits>
      <StyledAddMoreButton
        opens="add-benefit-modal"
        data={{ setBenefitList }}
        backgroundColor="transparent"
        icon="add"
        border="3px solid rgba(221, 226, 255, 0.2)"
      >
        Add More
      </StyledAddMoreButton>
    </TxplModal>
  );
};
export default withRenderPortal('benefit-modal')(BenefitModal);
