import React, { useState } from 'react';

import TxplModal from 'common/TxplModal/TxplModal';

import { StyledCancelButton, StyledCandidateId, StyledGridContainer } from './StyledBackOfficeCandidatesModal';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import { validateEmail } from '../BackOfficeCandidatesUtils';
import useAPICall from 'hooks/useAPICall';
import Spinner from 'common/Spinner';
import { isStrongPassword } from '../../../../utils';

const BackOfficeCandidatesModal = ({ onCloseHandler, onSaveHandler, passedCandidate }) => {
  const [candidate, setCandidate] = useState(passedCandidate);
  const [errorMessages, setErrorMessages] = useState({
    firstName: { message: '', isValid: false },
    lastName: { message: '', isValid: false },
    email: { message: '', isValid: false },
    password: { message: '', isValid: false }
  });
  const { callApi, loading } = useAPICall({
    url: '/candidates/transform',
    method: 'post',
    name: 'transform-candidate',
    onSuccess: () => {
      onSaveHandler();
    },
    onFailure: error => {
      const errorResponse = error.response.data.errors;
      const errorObject = errorResponse.reduce(
        (acc, curr) => ({ ...acc, [curr.param]: { isValid: false, message: curr.msg } }),
        {}
      );
      setErrorMessages({ ...errorMessages, ...errorObject });
    }
  });
  const handleDataChange = (name, value) => {
    if (errorMessages[name] && errorMessages[name].message.trim().length !== 0) {
      setErrorMessages({ ...errorMessages, [name]: { message: '', isValid: null } });
    }
    setCandidate({ ...candidate, [name]: value });
  };

  const formIsValid = () => {
    const errorObject = {
      firstName: { message: '', isValid: false },
      lastName: { message: '', isValid: false },
      email: { message: '', isValid: false },
      password: { message: '', isValid: false }
    };
    if (candidate.firstName.trim().length === 0) {
      errorObject.firstName = { message: 'First name is required', isValid: false };
    } else {
      errorObject.firstName.isValid = true;
    }
    if (candidate.lastName.trim().length === 0) {
      errorObject.lastName = { message: 'Last name is required', isValid: false };
    } else {
      errorObject.lastName.isValid = true;
    }
    if (candidate.email.trim().length === 0 || !validateEmail(candidate.email)) {
      errorObject.email = { message: 'Email is missing or invalid', isValid: false };
    } else {
      errorObject.email.isValid = true;
    }
    if (!candidate.password || candidate.password.trim().length === 0 || !isStrongPassword(candidate.password)) {
      errorObject.password = {
        message: 'Password must contain at least 8 characters, one number and one uppercase.',
        isValid: false
      };
    } else {
      errorObject.password.isValid = true;
    }
    if (Object.values(errorObject).find(o => !o.isValid)) {
      setErrorMessages(errorObject);
    } else {
      //   onSaveHandler(candidate)
      callApi({
        candidateId: candidate._id,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        password: candidate.password,
        email: candidate.email
      });
    }
  };

  return (
    <TxplModal
      title={'User Info'}
      name={'test'}
      appElement={document.getElementById('root-modal')}
      onClose={onCloseHandler}
      renderFooter={
        <>
          {loading && <Spinner />}
          <StyledCancelButton
            mr={6}
            onClick={onCloseHandler}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
          >
            Cancel
          </StyledCancelButton>
          <ContainedButton onClick={formIsValid} backgroundColor="#7266EE" forceDisplayText>
            Save
          </ContainedButton>
        </>
      }
    >
      <StyledGridContainer>
        <StyledCandidateId>
          <h1>Candidate ID</h1>
          <span>{candidate._id}</span>
        </StyledCandidateId>
        <FormInput
          error={errorMessages.firstName.message}
          required
          label={'First Name'}
          value={candidate.firstName}
          onChange={e => handleDataChange('firstName', e.target.value)}
        />
        <FormInput
          error={errorMessages.lastName.message}
          required
          label={'Last Name'}
          value={candidate.lastName}
          onChange={e => handleDataChange('lastName', e.target.value)}
        />
        <FormInput
          error={errorMessages.email.message}
          required
          label={'Email'}
          value={candidate.email}
          onChange={e => handleDataChange('email', e.target.value)}
        />
        <FormInput
          error={errorMessages.password.message}
          required
          label={'Password'}
          value={candidate.password}
          onChange={e => handleDataChange('password', e.target.value)}
          type="password"
        />
      </StyledGridContainer>
    </TxplModal>
  );
};

export default BackOfficeCandidatesModal;
