import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { profileSelectors } from 'store/profile';
import useViewport from 'hooks/useViewport';
import useOutsideClick from 'hooks/useOutsideClick';
import useConversationService from 'hooks/api/useConversation';
import Icon from 'common/Icon';

import * as Styled from './StyledConversationMessageTo';
import ContactList from './ContactList';

const ConversationMessageTo = ({ setSelectedConversation, setVisible }) => {
  const searchContactInputRef = useRef();
  const contactListRef = useRef();
  const { isS, isXS } = useViewport();

  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const { fetchContacts, loading } = useConversationService();

  const [contactListVisible, setContactListVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [contactList, setContactList] = useState(null);

  useOutsideClick(contactListRef, e => {
    if (contactListVisible && !searchContactInputRef.current.contains(e.target)) {
      setContactListVisible(false);
    }
  });

  const handleSearchValueChange = e => {
    setSearchValue(e.target.value);
    if (e.target.value && !contactListVisible) setContactListVisible(true);
  };

  useEffect(() => {
    let timeout = null;
    if (searchValue) {
      timeout = setTimeout(async () => {
        const contacts = await fetchContacts(profileId, searchValue);
        const data = [...contacts.talents, ...contacts.companyProfiles];
        setContactList(data);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);

  return (
    <>
      <Styled.TitleContainer>
        {(isXS || isS) && (
          <Styled.GoBackIcon
            onClick={() => {
              setVisible(false);
            }}
          >
            <Icon icon="back" color="#fff" />
          </Styled.GoBackIcon>
        )}
        <Styled.Title>message to:</Styled.Title>
      </Styled.TitleContainer>
      <Styled.Input
        ref={searchContactInputRef}
        placeholder="Type a name or add multiple names"
        onClick={() => setContactListVisible(true)}
        value={searchValue}
        onChange={handleSearchValueChange}
      />
      {contactListVisible && searchValue && (
        <ContactList
          contactListRef={contactListRef}
          contactList={contactList}
          loading={loading}
          setSelectedConversation={setSelectedConversation}
        />
      )}
    </>
  );
};

export default ConversationMessageTo;
