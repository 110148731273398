import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import { useHistory } from 'react-router';
import useChatConversationService from './useChatConversation';
import { authSelectors } from 'store/auth';

function useStartConversation() {
  const history = useHistory();
  const myProfile = useSelector(profileSelectors.selectMyProfile);
  const conversations = get('conversations', myProfile) ?? [];

  const { createConversation } = useChatConversationService();
  const isAuthed = useSelector(authSelectors.isAuthed);

  const redirectToStartConversation = async profile => {
    const profileId = profile._id ?? profile.id;
    if (!isAuthed) {
      return;
    }
    if (myProfile._id === profileId) {
      return redirectToMessages();
    }
    let conversationId = '';
    for (const property in conversations) {
      const participants = keys(get('participants', conversations[property]));
      participants.forEach(participant => {
        if (participant === profileId) {
          conversationId = property;
        }
      });
    }
    if (conversationId) {
      redirectToMessages(conversationId);
    } else {
      const { conversationId: newConversationId } = await createConversation(null, {
        contact: {
          id: profileId,
          name: profile.firstName + '' + profile.lastName,
          photoUrl: profile?.basicInfo?.imageUri?.url ?? profile.img
        }
      });

      redirectToMessages(newConversationId);
    }
  };

  const redirectToMessages = conversationId => {
    const profileType = myProfile.type === 'talent' ? 'talent' : 'company';
    const query = conversationId ? `?id=${conversationId}` : '';
    history.push(`/${profileType}/${myProfile._id}/conversations${query}`);
  };

  return {
    redirectToStartConversation
  };
}

export default useStartConversation;
