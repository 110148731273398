import styled from '@emotion/styled';
import React from 'react';
import ContainedButton from './ContainedButton';

export const StyledBookmarkButton = styled(ContainedButton)`
  button.transparent.disabled {
    opacity: 0.6;
    box-shadow: none;
  }
`;

function BookmarkButton({ className, isSaved, ...rest }) {
  return (
    <StyledBookmarkButton
      icon={isSaved ? 'bookmark' : 'bookmark-empty'}
      className={`${className} ${!isSaved && 'transparent'}`}
      tooltipSecondary
      {...rest}
    />
  );
}

export default BookmarkButton;
