import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import React from 'react';

const LifeAddBlockDropdown = ({ categoryId }) => (
  <DropDown className="secondary text-uppercase" padding="8px 10px" icon="arrow-down" text="add block">
    <DropDownItem icon="text" opens="life-text-modal" textPaddingLeft={16} data={{ categoryId }}>
      Text
    </DropDownItem>
    <DropDownItem icon="text-image" opens="life-text-media-modal" textPaddingLeft={16} data={{ categoryId }}>
      Text + Media
    </DropDownItem>
    <DropDownItem icon="photo-gallery" opens="life-photo-gallery-modal" textPaddingLeft={16} data={{ categoryId }}>
      Photo gallery
    </DropDownItem>
  </DropDown>
);

export default LifeAddBlockDropdown;
