import ContainedButton from 'common/ContainedButton';
import styled from '@emotion/styled';
import FormInput from 'common/form/FormInput';
import { pageSize } from 'utils/devices';

export const Title = styled.p`
  color: #e5e8ffa6;
  text-align: center;
`;
export const Subtitle = styled.p`
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 10px 0px 0px 0px;
`;
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 0px 30px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
`;
export const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const StyledShareButton = styled(ContainedButton)`
  width: 100%;
  height: 40px;
  justify-content: center;
`;
export const StyledFormInput = styled(FormInput)`
  position: relative;
  padding-right: 75px;
`;

export const StyledCopyButton = styled(ContainedButton)`
  position: absolute;
  top: 6px;
  right: 6px;
`;
export const StyledButton = styled(ContainedButton)`
  position: relative;
  margin-right: 3px;
  padding: 10px;

  ${props =>
    props.noPadding &&
    `
        .button-text {
            padding: 0;            
        }
    `}
`;

export const Content = styled.div`
  display: flex;
  width: 90%;
  margin: 0px 30px;
  @media ${pageSize.XS} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
