import React, { useEffect } from 'react';
import withRenderPortal from 'hocs/withRenderPortal';
import { addBenefit, getBenefits, updateBenefit } from 'api/benefitsService';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import Icon from 'common/Icon';
import { useSelector } from 'react-redux';
import benefitIcons from './icons';
import benefitColors from './colors';
import {
  Required,
  StyledCancelButton,
  StyledColorButton,
  StyledColorContainer,
  StyledFormInput,
  StyledIconContainer,
  StyledTitle
} from './StyledAddBenefitModal';
import { profileSelectors } from '../../../store/profile';
import useForm from 'hooks/useForm';

const AddBenefitModal = ({
  closePortal,
  companyBenefits,
  editBenefit,
  loading,
  name,
  setBenefitList,
  setCompanyBenefits,
  setEditBenefit,
  setLoading
}) => {
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));

  const { errors, initialize, onFieldChange, onSubmit, values } = useForm({
    fields: {
      name: { initialValue: editBenefit.name, validate: ['isRequired'], trim: true },
      icon: { initialValue: editBenefit.icon, validate: ['isRequired'] },
      iconColor: { initialValue: editBenefit.iconColor, validate: ['isRequired'] }
    },
    callApi: async formFields => {
      const data = {
        ...formFields,
        companyId
      };

      const displayBenefits = () => {
        getBenefits({
          companyId: companyId
        }).then(res => {
          setBenefitList(res.data.data);
          setEditBenefit({
            id: '',
            name: '',
            icon: '',
            iconColor: ''
          });
          setLoading(false);
          closePortal();
        });
      };

      if (editBenefit.name !== '') {
        updateBenefit(editBenefit.id, data).then(() => {
          displayBenefits();
        });
      } else {
        addBenefit(data).then(res => {
          setCompanyBenefits([...companyBenefits, res.data.data]);
          displayBenefits();
        });
      }
    }
  });

  useEffect(() => {
    initialize(editBenefit);
  }, [editBenefit]);

  return (
    <TxplModal
      name={name}
      testTitle
      title={editBenefit.name !== '' ? 'Edit benefit' : 'Add benefit'}
      padding="56px 0 82px 0"
      titlePadding="16px"
      addPaddingTop
      XSpadding
      boldWeight
      allowLine
      XSaddPaddingTop="14px"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loading && <Spinner />}
          <StyledCancelButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" disabled={loading}>
            Cancel
          </StyledCancelButton>
          <ContainedButton form="addBenefitModal" type="submit" disabled={loading}>
            save
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={onSubmit} id="addBenefitModal">
        <StyledFormInput
          mb={24}
          label="Name of the benefit"
          placeholder="E.g Free lunches"
          noErrorMessage
          required
          autoFocus
          errorRequired={errors.name}
          value={values.name}
          error={errors.name}
          onChange={e => onFieldChange('name')(e.target.value)}
        />
        <StyledTitle>Choose icon</StyledTitle>
        <Required error={errors.icon} />
        <StyledIconContainer>
          {benefitIcons.map((icon, index) => (
            <Icon
              key={index}
              required
              icon={icon}
              size={24}
              color={values.icon === icon ? '#FFFFFF' : '#E5EAFF59'}
              noBorder
              noPadding
              backgroundColor="transparent"
              value={values.icon}
              onClick={() => onFieldChange('icon')(icon)}
            />
          ))}
        </StyledIconContainer>
        <StyledTitle>Choose icon color</StyledTitle>
        <Required error={errors.iconColor} />
        <StyledColorContainer>
          {benefitColors.map((color, index) => (
            <StyledColorButton
              key={index}
              type="button"
              className={values.iconColor === color ? 'benefit_color-selected' : ''}
              color={color}
              value={values.iconColor}
              onClick={() => onFieldChange('iconColor')(color)}
            />
          ))}
        </StyledColorContainer>
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('add-benefit-modal')(AddBenefitModal);
