import { useState } from 'react';

import { gameService } from 'api';

const useGameService = () => {
  const [loading, setLoading] = useState({ getGameTags: false });

  const getGameTags = async gameId => {
    try {
      setLoading({ ...loading, getGameTags: true });
      const res = await gameService.getGameTags(gameId);
      return res.data.data;
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading({ ...loading, getGameTags: false });
    }
  };

  return {
    loading,
    getGameTags
  };
};

export default useGameService;
