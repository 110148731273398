import React, { useState } from 'react';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

import { profileSelectors } from 'store/profile';
import { pageSize } from 'utils/devices';
import AssetViewer from 'common/AssetViewer';
import AssetsLightbox from 'common/AssetsLightbox';
import PostedByCredits from 'pages/talent/PostedByCredits';
import PortfolioActions from 'pages/talent/PortfolioActions';
import AllAdditionalTags from 'pages/talent/AllAdditionalTags';
import MediaCoverageFrame from '../MediaCoverage/MediaCoverageFrame';

import Credits from '../Credits';
import ProjectName from './ProjectName';
import ProjectHandiwork from './ProjectHandiwork';
import ProjectProduction from './ProjectProduction';
import CollaboratorsFrame from '../Collaborators/CollaboratorsFrame/CollaboratorsFrame';

const StyledAssetViewer = styled(AssetViewer)`
  margin-bottom: 16px;

  @media ${pageSize.XS} {
    margin-bottom: 8px;
  }
`;
const Spacer = styled.div`
  height: 0px;
  margin: 40px 0;
  border: 1px solid rgba(204, 213, 255, 0.11);
`;
const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  padding-top: 0px !important;
`;

const TalentPortfolioView = ({ portfolioId }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [assetIndex, setAssetIndex] = useState();
  const portfolios = useSelector(profileSelectors.selectProfileProperty('projects')) || {};

  const portfolio = React.useMemo(() => portfolios[portfolioId], [portfolios, portfolioId]);
  const hasProfileEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const openLightbox = assetIndex => {
    setAssetIndex(assetIndex);
    setShowLightbox(true);
  };

  // TODO: move this logic to backend
  const verifiedCollaborators = React.useMemo(
    () => portfolio?.collaborators?.filter(collaborator => collaborator.status === 'approved'),
    [portfolio?.collaborators]
  );

  if (!portfolio) return null;

  const sortAssets = (a, b) => {
    if (a.orderIndex > b.orderIndex) {
      return 1;
    }

    if (a.orderIndex < b.orderIndex) {
      return -1;
    }

    if (a.orderIndex === b.orderIndex) {
      return 0;
    }
  };

  const filteredAssets = portfolio && portfolio.assets?.filter(asset => asset.file && asset.file.type).sort(sortAssets);
  const assetList =
    portfolio && portfolio.video ? [{ ...portfolio.video, isProjectVideo: true }, ...filteredAssets] : filteredAssets;

  return (
    <>
      <Modal
        isOpen={showLightbox}
        appElement={document.getElementById('root')}
        onRequestClose={() => setShowLightbox(false)}
        closeModal={() => setShowLightbox(false)}
        style={{ overlay: { zIndex: 10 }, content: { top: '0', left: '0', right: '0', bottom: '0', border: 'none' } }}
      >
        <AssetsLightbox
          oneLineHeading={portfolio && portfolio.oneLineHeading}
          assets={assetList}
          assetIndex={assetIndex}
          closeModal={() => setShowLightbox(false)}
        />
      </Modal>

      <div className="grid-intro">
        <PortfolioActions portfolio={portfolio} />

        {(portfolio?.name.length > 0 || hasProfileEditPermission) && <ProjectName hideSpacer name={portfolio.name} />}

        {portfolio.updatedAt && <PostedByCredits updatedAt={portfolio.updatedAt} />}

        <Spacer />
      </div>

      <div className="grid-assets">
        {assetList?.map((asset, assetIndex) => (
          <StyledAssetViewer
            noMargin={false}
            key={asset.assetId}
            onClick={() => openLightbox(assetIndex)}
            onPlay={() => {}}
            asset={asset}
          />
        ))}
      </div>

      <div className="grid-intro2" id="artistDescriptionAncor">
        {(portfolio?.roleAndAccomplishments.length > 0 || hasProfileEditPermission) && (
          <ProjectHandiwork
            mb={40}
            handiwork={portfolio.roleAndAccomplishments}
            hasProfileEditPermission={hasProfileEditPermission}
          />
        )}

        {(portfolio?.production?.length > 0 || hasProfileEditPermission) && (
          <ProjectProduction mb={48} production={portfolio.production} />
        )}

        {(verifiedCollaborators?.length > 0 || hasProfileEditPermission) && (
          <CollaboratorsFrame mb={48} isEditMode={false} collaborators={verifiedCollaborators} />
        )}

        {(portfolio.credits.length > 0 || hasProfileEditPermission) && (
          <Credits
            items={portfolio.credits}
            isEditMode={false}
            chooseFromExisting
            hintText="Did you get any game credits at this place? Put ‘em here"
            mediumView
          />
        )}

        {(portfolio?.mediaCoverage?.length > 0 || hasProfileEditPermission) && (
          <MediaCoverageFrame mb={48} isEditMode={false} items={portfolio.mediaCoverage} />
        )}

        <StyledAllAdditionalTags
          isEditMode={false}
          cols={3}
          project={portfolio}
          data={{
            tags: portfolio.tags,
            platforms: portfolio.platforms,
            tools: portfolio.tools,
            skills: portfolio.skills,
            genres: portfolio.genres
          }}
        />
      </div>
    </>
  );
};

export default TalentPortfolioView;
