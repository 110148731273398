import React from 'react';
import styled from '@emotion/styled';

import useViewport from 'hooks/useViewport';
import AssetsLightboxSmall from './AssetsLightboxSmall';
import AssetsLightboxLarge from './AssetsLightboxLarge';

const Wrapper = styled.div`
  background-color: black;
`;

const AssetsLightbox = props => {
  const { width } = useViewport();

  return (
    <Wrapper className={props.className}>
      {width > 1149 ? <AssetsLightboxLarge {...props} /> : <AssetsLightboxSmall {...props} />}
    </Wrapper>
  );
};

export default AssetsLightbox;
