import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';

const Wrapper = styled.div`
  ${commonStyles.ui_text_medium}
  background-color: rgba(204, 213, 255, 0.04);
  /* min-width: 360px;  */
  min-height: 443px;
  padding: 24px;
`;

const MenuIcon = styled(Icon)`
  background: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  margin-bottom: 19px;
  padding: 16px;
`;

const MenuTitle = styled.div`
  ${commonStyles.ui_heading_2}
  padding-bottom: 8px;
`;

const MenuItems = styled.div``;

const GreyLink = styled(Link)`
  display: block;
  line-height: 32px;
  color: rgba(230, 234, 255, 0.6);
`;

const DashboardBox = props => {
  const { icon, iconColor, items, title } = props;

  return (
    <Wrapper>
      <MenuIcon color={iconColor} icon={icon} size={32} />
      <MenuTitle>{title}</MenuTitle>
      <MenuItems>
        {items &&
          items.map(item => (
            <GreyLink key={item.url} to={item.url}>
              {item.label}
            </GreyLink>
          ))}
      </MenuItems>
    </Wrapper>
  );
};

export default DashboardBox;
