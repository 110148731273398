import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import Frame from 'common/Frame';
import { profileActions, profileSelectors } from 'store/profile';
import ContainedButton from 'common/ContainedButton';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ViewCreditsModal from './ViewCreditsModal';
import Credit from './Credit';
import DeleteCreditModal from './DeleteCreditModal';
import StartAddCreditModal from './StartAddCreditModal';
import EditCreditRoleModal from './EditCreditRoleModal';
import ClaimedCreditModal from './ClaimedCreditModal';
import AddCreditWizard from './AddCreditWizard';
import EditCreditModal from './EditCreditModal';
import ReachedMaxCreditLimit from './ReachedMaxCreditLimit';
import Icon from '../../../common/Icon';
import talentService from '../../../api/talentService';
import { pageSize } from '../../../utils/devices';
import companyService from '../../../api/companyService';
import useViewport from 'hooks/useViewport';
import UnlockRequestModal from 'components/modals/UnlockRequestModal';
import { useLocation } from 'react-router';

const StyledFrameContainer = styled(Frame)`
  margin: ${({ margin }) => margin || '16px 0px'};
  overflow: visible;
`;
const StyledCreditsContainer = styled.div``;
const StyledAddFrame = styled(Frame)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 16px 24px;
`;
const DragIcon = styled.div`
  width: 36px;
  height: 32px;
  position: absolute;
  z-index: 1;
  right: 58px;
  top: 22px;
  @media ${pageSize.XS} {
    top: 62px;
    right: 20px;
  }
`;
const Wrapper = styled.div`
  &:hover .drag-icon-${props => props.id} {
    display: block;
  }
  @media ${pageSize.XS} {
    .drag-icon-${props => props.id} {
      display: block;
    }
  }
`;

const Credits = ({
  chooseFromExisting,
  creditsRef,
  formExperience = false,
  fromEdit = false,
  fromPortfolio = false,
  height,
  hideDropDown = false,
  hintText,
  isEditMode = false,
  items,
  loading,
  margin,
  mediumView,
  noAdd = false,
  notClosePortal,
  onAdd,
  onChoose,
  onDelete,
  onSave,
  project,
  single
}) => {
  const { search } = useLocation();
  const creditId = new URLSearchParams(search).get('credit');
  const credits = useSelector(profileSelectors.selectProfileProperty('credits')) || [];
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const talentId = useSelector(profileSelectors.selectProfileProperty('id'));
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const { isXS } = useViewport();
  const dispatch = useDispatch();
  const displayedCredits = items
    ? items
        .map(credit => (typeof credit !== 'object' ? credits.find(curr => curr._id === credit) : credit))
        .filter(Boolean)
        .filter(credit => (chooseFromExisting ? true : credit.display))
    : [];
  const [creditList, setCreditList] = React.useState(displayedCredits);
  const editModeLabel = displayedCredits.length ? 'edit' : 'add';
  const maxCreditVisible = 8;
  const hasMaxCredits = displayedCredits.length >= maxCreditVisible;
  const opens = chooseFromExisting ? 'credits-modal' : 'add-credit';

  const allowAdd = React.useMemo(() => {
    if (noAdd) return false;
    if (single && items.length >= 1) return false;
    if (!hasMaxCredits && hasPermission) return true;

    return false;
  }, [hasMaxCredits, hasPermission, single, items]);

  useEffect(() => {
    setCreditList(displayedCredits);
  }, [items, credits]);

  useEffect(() => {
    if (creditId) {
      (document.getElementById(creditId) ?? document.getElementById('credits')).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }, [creditId]);

  function handleOnDragEnd(result) {
    const items = Array.from(creditList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCreditList(items);

    const sortedIdList = items.map(item => item._id);

    const serviceType = isCompanyView ? companyService : talentService;
    const id = isCompanyView ? myProfileId : talentId;

    serviceType
      .updateCreditSort(id, sortedIdList)
      .then(() => {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'credits',
            value: items,
            profileId: id
          })
        );
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }

  return (
    <>
      {((hasPermission && allowAdd) || creditList?.length > 0) && (
        <StyledFrameContainer
          project={project}
          ref={creditsRef}
          id="credits"
          color="#FF7CBB"
          text="Credits"
          titlePB={24}
          editMode={editModeLabel}
          canEdit={hasPermission}
          noIcon
          data={{ chooseFromExisting, notClosePortal, fromPortfolio, formExperience }}
          noDataText={hintText || 'Claim credits for games you helped ship, then give yourself a standing ovation.'}
          renderFooter={hasMaxCredits && hasPermission && <ReachedMaxCreditLimit />}
          filled={!!displayedCredits?.length}
          opens={opens}
          margin={margin}
        >
          <StyledCreditsContainer>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {creditList.map(
                      (credit, index) =>
                        index < maxCreditVisible && (
                          <Wrapper key={credit._id} id={credit._id}>
                            <Draggable
                              key={credit._id}
                              draggableId={credit._id}
                              index={index}
                              isDragDisabled={!hasPermission && true}
                            >
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <div
                                    style={{
                                      position: 'relative',
                                      marginBottom: '24px'
                                    }}
                                  >
                                    {hasPermission && creditList.length > 1 && !fromPortfolio && !formExperience && (
                                      <DragIcon {...provided.dragHandleProps} className={`drag-icon-${credit._id}`}>
                                        <Icon icon="draggable_touch" size={isXS ? 35 : 32} noFill />
                                      </DragIcon>
                                    )}
                                    <Credit
                                      id={`credit-${credit._id || credit.localId}`}
                                      mediumView={mediumView}
                                      key={credit._id || credit.localId}
                                      credit={credit}
                                      project={project}
                                      height={height}
                                      isEditMode={isEditMode}
                                      hideDropDown={hideDropDown}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </Wrapper>
                        )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </StyledCreditsContainer>
          {allowAdd && (
            <StyledAddFrame filled={false}>
              <ContainedButton
                data-testid={'add-credits-button'}
                icon="add"
                type="button"
                opens={opens}
                data={{ chooseFromExisting, notClosePortal, fromPortfolio }}
                backgroundColor="secondary"
              >
                Add Credits
              </ContainedButton>
            </StyledAddFrame>
          )}
        </StyledFrameContainer>
      )}
      <ViewCreditsModal
        items={items}
        onChoose={onChoose}
        single={single}
        data={{ notClosePortal, fromPortfolio }}
        loading={loading}
      />
      <DeleteCreditModal onDelete={onDelete} fromPortfolio={fromPortfolio} />
      <StartAddCreditModal />
      <EditCreditRoleModal onAdd={onAdd} />
      <AddCreditWizard
        onChoose={onChoose}
        onSave={onSave}
        loading={loading}
        fromEdit={fromEdit}
        selectedCredits={items}
        fromPortfolio={fromPortfolio}
      />
      <EditCreditModal />
      <ClaimedCreditModal />
      <UnlockRequestModal />
    </>
  );
};

export default React.memo(Credits);
