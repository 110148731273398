import Frame from 'common/Frame';
import useViewport from 'hooks/useViewport';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/auth';
import { ApplyNowButton, RoleGrid, StyledAllAdditionalTags } from './styled.components';
import RichTextEditor from 'common/RichTextEditor';

const hintTexts = {
  platforms: 'Add platforms an applicant should be able to work for.',
  tools: 'Add tools that an applicant should be able to use.',
  genres: 'Add genres an applicant should be comfortable with.',
  skills: 'List the primary skills an applicant should have. Consider disciplines, management related skills.',
  tags: 'Add tags that further describe this job. Consider themes, style, play-mode.'
};

const JobRole = ({
  alreadyApplied,
  errors,
  handleApplyClick,
  handleWithdrawClick,
  handleReview,
  hasEditPermission,
  isClosed,
  isContractActive,
  loading,
  onFieldChange,
  role,
  viewMode
}) => {
  const { isL } = useViewport();
  const { tools = [], skills = [], tags = [], youWill = '', youHave = '', bonus = '' } = role;
  const { _id, ...valuesToCheck } = role;
  const isAuthed = useSelector(authSelectors.isAuthed);

  return (
    <Frame
      transparent
      text="role"
      titlePB={32}
      color="#FFE040"
      noDataText=""
      data={{ role }}
      opens="job-role-modal"
      filled={!_.values(valuesToCheck).every(_.isEmpty)}
    >
      <RoleGrid>
        {(!viewMode || youWill) && (
          <Frame
            className={`youWill-required${youHave && bonus && ' large'} job-section-rich-text`}
            transparent
            text="You will…"
            canEdit={hasEditPermission}
            editMode={youWill ? 'edit' : 'add'}
            noDataText="Describe what an applicant will do in this role."
            data={{
              field: 'youWill',
              title: 'You will...',
              subTitle: 'Describe what an applicant will do in this role.',
              text: youWill,
              limit: 1200,
              required: true
            }}
            opens="text-editor-modal"
            smallHeader
            filled={youWill}
            error={errors.youWill}
            required
          >
            {youWill && <RichTextEditor value={youWill} readOnly />}
          </Frame>
        )}
        {(!viewMode || youHave) && (
          <Frame
            className="youHave-required job-section-rich-text"
            transparent
            text="You have…"
            canEdit={hasEditPermission}
            editMode={youHave ? 'edit' : 'add'}
            noDataText="Describe the capabilities and experience an applicant should have."
            data={{
              field: 'youHave',
              title: 'You have...',
              subTitle: 'Describe the capabilities and experience an applicant should have.',
              text: youHave,
              limit: 1200,
              required: true
            }}
            opens="text-editor-modal"
            smallHeader
            filled={youHave}
            error={errors.youHave}
            required
          >
            {youHave && <RichTextEditor value={youHave} readOnly />}
          </Frame>
        )}
      </RoleGrid>
      <StyledAllAdditionalTags
        isEditMode={hasEditPermission}
        hasDragPermission={hasEditPermission}
        cols={isL ? 4 : 6}
        hintTexts={hintTexts}
        order={['tools', 'skills', 'tags']}
        data={{ tools, skills, tags }}
        onAdd={({ data, type }) => onFieldChange(type)(data)}
        afterDrag={(type, ids) => onFieldChange(type)(ids)}
        titlePB={24}
        tagsModalFooterPaddingTop={24}
        fullWidth
      />
      {viewMode && !isContractActive && (
        <ApplyNowButton
          backgroundColor="#7266EE"
          icon="info"
          fullWidth
          type="button"
          mt={16}
          onClick={alreadyApplied ? handleWithdrawClick : handleApplyClick}
          disabled={loading || !isAuthed || isClosed}
        >
          {alreadyApplied ? 'WITHDRAW' : 'APPLY NOW'}
        </ApplyNowButton>
      )}
      {viewMode && isContractActive && (
        <ApplyNowButton backgroundColor="#7266EE" icon="info" fullWidth type="button" mt={16} onClick={handleReview}>
          REVIEW PROPOSAL
        </ApplyNowButton>
      )}
    </Frame>
  );
};

export default JobRole;
