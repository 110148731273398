import Api from './api';

const api = new Api('proxy');

const searchPlace = input =>
  api.get('/places-api', {
    params: {
      types: '(regions)',
      input,
      key: process.env.REACT_APP_GOOGLE_API_KEY
    }
  });

export default {
  searchPlace
};
