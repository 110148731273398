import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import ContainedButton from 'common/ContainedButton';

export const CollaborationItem = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  @media ${pageSize.XS} {
    flex-direction: column;
    gap: 24px;
  }
  &:last-child {
    border-bottom: none;
  }
`;
export const WrapperForCollaborationItem = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  width: 100%;
`;
export const CollaborationItemImageWrapper = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
`;
export const CollaborationItemImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
`;
export const CollaborationItemTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
export const CollaborationItemSource = styled.div`
  width: 100%;
  height: 16px;
  display: flex;
  margin-bottom: 13px;
`;
export const CollaborationItemSourceLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  width: fit-content;
  height: 100%;
  background: rgba(204, 213, 255, 0.11);
  border-radius: 4px;
  text-transform: capitalize;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
`;
export const CollaborationItemText = styled.div`
  width: 100%;
  height: fit-content;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.6);
  @media ${pageSize.S} {
    height: fit-content;
  }
  @media ${pageSize.XS} {
    height: fit-content;
  }
`;
export const CollaborationItemInnerTextLink = styled.a`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: white;
  cursor: pointer;
  text-decoration: none;
`;
export const CollaborationItemButtonsWrapper = styled.div`
  max-width: 206px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  @media ${pageSize.XS} {
    align-self: flex-end;
  }
`;
export const CollaborationItemDeclineButton = styled(ContainedButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 32px;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
`;
export const CollaborationItemShowDetailsButton = styled(ContainedButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 32px;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CollaborationItemVerificationWarnningText = styled.div`
  width: 198px;
  height: 32px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffe040;
`;
