import styled from '@emotion/styled';
import React from 'react';
import { pageSize } from 'utils/devices';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useViewport from 'hooks/useViewport';
import SliderArrow from 'common/SliderArrow';
import { LifeBlockHeading, LifeTextBlockWrapper } from './styled.components';

const LifeImage = styled.img`
  width: 520px !important;
  height: 300px !important;
  margin-right: 16px;
  border-radius: 8px;
  @media ${pageSize.XS} {
    width: 248px !important;
    height: 145px !important;
    margin-right: 7.39px;
  }
`;
const StyledSlider = styled(Slider)`
  padding-left: 24px;
  .slick-list {
  }
  .slick-track {
    display: flex;
    margin: 0;
  }
  @media ${pageSize.XS} {
    padding-left: 16px;
  }
`;

const LifePhotoGallery = ({ life }) => {
  const { images, title } = life;
  const { width } = useViewport();

  return (
    <LifeTextBlockWrapper className="life-block gallery" maxWidth={width}>
      <LifeBlockHeading className="gallery-heading">{title}</LifeBlockHeading>
      <StyledSlider
        className="slider variable-width"
        infinite={false}
        slidesToShow={1}
        adaptiveHeight
        variableWidth
        swipeToSlide
        nextArrow={<SliderArrow icon="forward" />}
        prevArrow={<SliderArrow icon="back" />}
        rows={1}
      >
        {images.map(img => (
          <LifeImage key={img._id} src={img.url} />
        ))}
      </StyledSlider>
    </LifeTextBlockWrapper>
  );
};

export default LifePhotoGallery;
