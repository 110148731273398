import withRenderPortal from 'hocs/withRenderPortal';
import React from 'react';
import ContainedButton from './ContainedButton';
import FormTextarea from './form/FormTextarea';
import Spinner from './Spinner';
import TxplModal from './TxplModal';

const TextModal = ({ closePortal, data, name }) => {
  const { callApi, initialText, label, limit = 300, onSuccess, placeholder } = data;

  const [text, setText] = React.useState(initialText ?? '');
  const [loading, setLoading] = React.useState(false);

  const handleDone = async () => {
    if (!loading && callApi) {
      try {
        setLoading(true);
        await callApi(text);
        onSuccess && (await onSuccess(text));
        closePortal();
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <TxplModal
      name={name}
      size="large"
      titleMB="0"
      padding="0 0 80px"
      addPaddingTop
      nonFullScreenOnMobileCenter
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loading && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal}>
            cancel
          </ContainedButton>
          <ContainedButton onClick={handleDone} disabled={loading}>
            done
          </ContainedButton>
        </>
      }
    >
      <FormTextarea
        label={label ?? 'Leave a note'}
        placeholder={placeholder ?? 'Note text'}
        bordered
        value={text}
        onChange={e => setText(e.target.value)}
        innerPadding="padding: 18px;"
        maxLength={limit}
      />
    </TxplModal>
  );
};

export default withRenderPortal('text-modal')(TextModal);
