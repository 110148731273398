import React from 'react';

import Frame from 'common/Frame';
import IconTags from 'common/IconTags';
import Typography from 'components/shared/Typography';
import { profileSelectors } from 'store/profile';
import { useSelector } from 'react-redux';

const PlatformTags = ({
  isEditMode = true,
  data = [],
  service = null,
  cols,
  error,
  onAction,
  hintText,
  required,
  page = 'about',
  hasDragPermission = false,
  hideEmpty = false,
  afterDrag,
  portfolio = null,
  project = null,
  callService,
  titlePB = 16
}) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const mode = data.length ? 'edit' : 'add';

  return (
    <>
      {(!hasPermission || hideEmpty) && data.length === 0 ? (
        <></>
      ) : (
        <Frame
          error={error}
          color="#FF5151"
          text="Platforms"
          project={project}
          titlePB={titlePB}
          canEdit={isEditMode}
          required={required}
          editMode={mode}
          onEdit={onAction}
          filled={!!data.length}
          noDataText={hintText || 'Tell us the platforms you specialize in or would like to focus on.'}
          id="platforms"
          className="platforms-required"
        >
          <IconTags
            tags={data}
            project={project}
            service={service}
            type="platforms"
            cols={cols}
            canEdit={isEditMode}
            page={page}
            hasDragPermission={hasDragPermission}
            afterDrag={afterDrag}
            portfolio={portfolio}
            callService={callService}
          />
          {error && (
            <Typography mt={30} error variant="small">
              {error || 'There must be at least 1'}
            </Typography>
          )}
        </Frame>
      )}
    </>
  );
};

export default PlatformTags;
