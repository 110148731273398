import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import { device } from 'utils/devices';

export const StyledSinglePageEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 8px;
  width: 100%;
  height: 88px;
  box-sizing: border-box;
  background: rgba(204, 213, 255, 0.05);
  border-radius: 8px;
`;
export const StyledPageNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 18px 16px 18px;
  position: static;
  width: 100%;
  height: 56px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  @media ${device.mobile} {
    margin-right: 20px;
  }
`;
export const StyledPageButtonsContainer = styled.div`
  width: 78px;
  height: 56px;
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
export const StyledAddPageButton = styled(ContainedButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
`;
export const StyledPageNameInputContainer = styled.div`
  width: 100%;
`;
export const StyledPageNameInput = styled(FormInput)`
  display: flex;
  align-items: center;
  padding: 18px 18px 16px 18px;
  position: static;
  width: 100%;
  height: 56px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
`;
export const StyledCancelButton = styled(ContainedButton)`
  margin-right: 3px;
`;
export const DragIcon = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 55px;
  top: 16px;
`;
