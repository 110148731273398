import styled from '@emotion/styled';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import Tooltip from 'components/Tooltip';

export const StyledContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  max-width: 250px;
  padding: 10px 16px;
  border-radius: 12px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  width: fit-content;

  ${props => props.pending && 'opacity: 0.5;'}
  &.border-dashed {
    border: 2px dashed rgba(204, 213, 255, 0.11);
  }
`;

export const StyledAvatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
`;

export const StyledTooltip = styled(Tooltip)`
  .__react_component_tooltip.show {
    border: 1px solid rgba(204, 213, 255, 0.22) !important;
  }
`;

export const TypographyTitle = styled(Typography)`
  width: 100%;
`;

export const StyledIcon = styled(Icon)``;
