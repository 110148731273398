import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';
import unset from 'lodash/fp/unset';
import update from 'lodash/fp/update';
import normalize from 'utils/normalize';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import {
  CLEAR_JOBS,
  DELETE_JOBS,
  SET_JOBS,
  UPDATE_JOBS,
  UPDATE_JOBS_PAGINATION,
  UPDATE_JOB_PROPERTY,
  UPDATE_UNREAD_JOBS_COUNTERS
} from './jobsActionTypes';

const getJobType = (profileId, jobId, state) => {
  return get(`unstaffedJobs.jobs.${profileId}.${jobId}`, state)
    ? 'unstaffedJobs'
    : get(`staffedJobs.jobs.${profileId}.${jobId}`, state)
    ? 'staffedJobs'
    : get(`closedJobs.jobs.${profileId}.${jobId}`, state)
    ? 'closedJobs'
    : get(`appliedJobs.jobs.${profileId}.${jobId}`, state)
    ? 'appliedJobs'
    : get(`savedJobs.jobs.${profileId}.${jobId}`, state)
    ? 'savedJobs'
    : get(`selectedJobs.jobs.${profileId}.${jobId}`, state)
    ? 'savedJobs'
    : get(`hiredJobs.jobs.${profileId}.${jobId}`, state)
    ? 'savedJobs'
    : false;
};

const jobsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_JOBS: {
      const { jobType, jobs, profileId } = action.payload;
      const key = jobs[0]?._id || jobs?._id ? '_id' : 'id';

      return update(
        `${jobType}.jobs.${profileId}`,
        values => (Array.isArray(jobs) ? assign(values, normalize(jobs, key)) : assign(values, { [jobs[key]]: jobs })),
        state
      );
    }

    case UPDATE_UNREAD_JOBS_COUNTERS: {
      return set(
        `unreadCounters`,
        action.payload,
        state
      );
    }

    case SET_JOBS: {
      const { jobType, jobs, profileId } = action.payload;
      const key = jobs[0]?._id || jobs?._id ? '_id' : 'id';

      return set(
        `${jobType}.jobs.${profileId}`,
        Array.isArray(jobs) ? normalize(jobs, key) : { [jobs[key]]: jobs },
        state
      );
    }

    case UPDATE_JOB_PROPERTY: {
      const { jobId, profileId, property, value } = action.payload;

      const jobType =
        action.payload?.jobType !== undefined ? action.payload.jobType : getJobType(profileId, jobId, state);

      if (isEmpty(get(`${jobType}.jobs.${profileId}.${jobId}`, state))) {
        return state;
      }

      return jobType ? set(`${jobType}.jobs.${profileId}.${jobId}.${property}`, value, state) : state;
    }

    case DELETE_JOBS: {
      const { job, profileId } = action.payload;
      const key = job?._id ? '_id' : 'id';
      const id = job[key] ?? job;

      const jobType = getJobType(profileId, id, state);

      return update(`${jobType}.jobs.${profileId}`, values => unset(id, values), state);
    }

    case UPDATE_JOBS_PAGINATION: {
      const { jobType, pagination, profileId } = action.payload;
      const { hasMore, limit, page, total } = pagination;
      const jobs = get(`${jobType}.jobs.${profileId}`, state);

      let pageObj = {};
      if (page) {
        pageObj = {
          page: parseInt(page) + 1
        };
      }

      const newPagination = {
        ...pagination,
        hasMore: hasMore ?? Object.values(jobs).length < total,
        limit: parseInt(limit),
        ...pageObj
      };

      return set(`${jobType}.pagination.${profileId}`, newPagination, state);
    }

    case CLEAR_JOBS: {
      return {};
    }

    default:
      return state;
  }
};

export default jobsReducer;
