import React from 'react';
import Loader from 'common/Loader';
import useAppliedJobs from 'hooks/useAppliedJobs';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { mapJobs } from '../helpers/mapJobs';
import JobList from '../commons/JobList';
import JobsEmptyList from '../commons/JobsEmptyList';
import { isEmpty } from 'ramda';

const AppliedJobs = () => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const { appliedJobs, getAppliedJobs, hasMoreAppliedJobs, loadingAppliedJobs } = useAppliedJobs();

  const jobs = mapJobs(appliedJobs, profileId);

  if (isEmpty(jobs) && loadingAppliedJobs) {
    return <Loader />;
  }

  return (
    <InfiniteScroll
      loadMore={() => getAppliedJobs(profileId, jobs.length)}
      hasMore={hasMoreAppliedJobs}
      loader={<Loader key={0} style={{ marginTop: '43px' }} />}
      useWindow
    >
      {isEmpty(jobs) ? (
        <JobsEmptyList iconName="applied" text="Your applications" />
      ) : (
        <JobList jobs={jobs} showDropdown />
      )}
    </InfiniteScroll>
  );
};

export default AppliedJobs;
