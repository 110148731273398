import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

const Wrapper = styled.div`
  ${commonStyles.ui_text_small}
  color: rgba(230, 234, 255, 0.35);
  padding: 16px 0;
  flex: 1;
`;

const BackofficeActionContent = ({ children }) => <Wrapper>{children}</Wrapper>;

export default BackofficeActionContent;
