import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';

import useIDVerification from 'hooks/useIDVerification';
import useCurrentVerification from 'hooks/useCurrentVerification';
import Typography from 'components/shared/Typography';

import Spinner from 'common/Spinner';
import VerificationStatusesModal from '../modals/VerificationStatusesModal/VerificationStatusesModal';
import * as Styled from './StyledVerificationStatusCard';

const verificationStatus = {
  not_started: {
    color: '#A4BFD8',
    title: 'ID verification needed'
  },
  created: {
    color: '#A4BFD8',
    title: 'ID verification needed'
  },
  verified: {
    color: '#00FF00',
    title: 'ID verified'
  },
  pending: {
    color: '#FFE040',
    title: 'ID verification in progress'
  },
  processing: {
    color: '#FFE040',
    title: 'ID verification in progress'
  },
  retry: {
    color: '#FF9C40',
    title: 'ID verification problem'
  },
  'failed-retry': {
    color: '#FF9C40',
    title: 'ID verification problem'
  },
  problem: {
    color: '#FF5151',
    title: 'ID verification failed'
  },
  failed: {
    color: '#FF5151',
    title: 'ID verification failed'
  },
  requires_input: {
    color: '#FF5151',
    title: 'ID verification failed'
  }
};

const VerificationStatusCard = () => {
  const dispatch = useDispatch();
  const { loading, openStripeModal } = useIDVerification();
  const [iconColor, setIconColor] = useState();
  const [title, setTitle] = useState();

  const status = useSelector(selectCurrentUserProperty('verificationStatus'));
  const profileStatus = useSelector(profileSelectors.selectProfileProperty('verificationStatus'));

  const currentUserId = useSelector(selectCurrentUserProperty('_id'));
  const currentProfileUserId = useSelector(profileSelectors.selectProfileProperty('userId'));
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const viewMode = currentUserId !== currentProfileUserId;

  const currentStatus = viewMode ? profileStatus : status;

  useCurrentVerification();

  const helpPopup = () => {
    dispatch(
      portalActions.openPortal({
        name: 'help-modal'
      })
    );
  };

  const handleVerifyClick = () => {
    dispatch(
      portalActions.openPortal({
        name: 'id-verification-video-modal'
      })
    );
  };

  const handleHelperBtnClick = () => {
    if (currentStatus === 'failed' || currentStatus === 'problem' || currentStatus === 'requires_input') {
      helpPopup();
    }

    if (currentStatus === 'retry' || currentStatus === 'failed-retry') {
      openStripeModal();
    }
  };

  useEffect(() => {
    if (currentStatus) {
      setIconColor(verificationStatus[currentStatus].color);
      setTitle(verificationStatus[currentStatus].title);
    }
  }, [currentStatus]);

  const renderLoader = () => (
    <Styled.StatusLoader>
      Please wait...
      <Spinner />
    </Styled.StatusLoader>
  );

  const statusTitle = () => (
    <Styled.Title>
      <Styled.Icon32 icon="id_verification" color={iconColor} size={32} noFill />
      <Styled.Icon16 icon="id" color="#111112" size={16} noFill />
      <Typography variant="medium">{title}</Typography>
    </Styled.Title>
  );

  const statusButton = () => {
    const statusModal = 'verification-statuses-modal';
    switch (currentStatus) {
      case 'not_started':
      case 'created':
        return <Styled.Button onClick={handleVerifyClick}>VERIFY</Styled.Button>;
      case 'pending':
        return (
          <Styled.Button className="transparent" opens={statusModal}>
            SEE DETAILS
          </Styled.Button>
        );
      case 'retry':
      case 'problem':
      case 'failed-retry':
      case 'failed':
      case 'requires_input':
        return (
          <Styled.ButtonsContainer>
            <Styled.Button className="transparent" opens={statusModal}>
              SEE DETAILS
            </Styled.Button>
            <Styled.Button onClick={handleHelperBtnClick}>
              {currentStatus === 'retry' || currentStatus === 'failed-retry' ? 'TRY AGAIN' : 'ASK HELP'}
            </Styled.Button>
          </Styled.ButtonsContainer>
        );
    }
  };

  if (!hasEditPermission || currentStatus === 'verified') {
    return null;
  }

  return (
    <>
      <Styled.Container>
        {statusTitle()}
        {hasEditPermission && loading.openStripeModal && renderLoader()}
        {hasEditPermission && !loading.openStripeModal && statusButton()}
      </Styled.Container>
      <VerificationStatusesModal status={currentStatus} statusTitle={statusTitle} helpPopup={helpPopup} />
    </>
  );
};

export default VerificationStatusCard;
