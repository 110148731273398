import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: #18191d;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  max-width: 324px;
`;

export const TextContainer = styled.div`
  padding: 20px;
`;

export const Title = styled.h3`
  color: white;
`;

export const Subtitle = styled.p`
  color: rgba(230, 234, 255, 0.35);
  text-align: center;
  font-size: 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;
