import React from 'react';
import styled from '@emotion/styled';

import { getDate } from 'utils';
import commonStyles from 'utils/common-styles';

import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  align-items: start;
  max-width: 98px;
  grid-area: side;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 12px;
  /* @media ${pageSize.S},  ${pageSize.XS}{
    max-width: 98px;
  } */
`;

const MediaCoverageLink = styled.div`
  ${commonStyles.ui_text_small}
  top: 10px;
  left: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledLink = styled.a`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const MediaCoverageDate = styled.div`
  ${commonStyles.ui_text_tiny}
  top: 32px;
  left: 16px;
  color: rgba(230, 234, 255, 0.35);
  font-weight: 400;
  font-family: 'inter';
`;

const MediaCoverageCredits = ({ date, formattedLink, mediaTitle, vetted }) => (
  <Wrapper>
    <MediaCoverageLink>
      <StyledLink href={formattedLink} target="_blank" rel="noopener noreferrer">
        {mediaTitle}
      </StyledLink>
      <Icon color="black" icon={vetted ? 'vetted' : 'vetting'} />
    </MediaCoverageLink>

    <MediaCoverageDate>{getDate(date)}</MediaCoverageDate>
  </Wrapper>
);

export default MediaCoverageCredits;
