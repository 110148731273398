import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import Frame from 'common/Frame';
import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';
import GameNotFoundTooltip from 'common/GameNotFoundTooltip';
import ProjectProductionModal from './ProjectProductionModal';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 150px;
  grid-auto-flow: column;

  @media ${pageSize.XS}, ${pageSize.M} {
    grid-auto-flow: row;
    grid-template-columns: initial;
    height: auto;
  }
`;

const StyledGame = styled.div`
  display: flex;
  border-right: 2px solid rgba(204, 213, 255, 0.11);

  @media ${pageSize.XS}, ${pageSize.M} {
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    border-right: none;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInfoRow = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  padding-left: 24px;
  align-items: start;
`;

const StyledImage = styled.img`
  min-width: 105px;
  height: 150px;
  background-color: gray;
  border-radius: 8px;
  margin-right: 16px;
`;

const StyledGameCardImage = styled.div`
  min-width: 105px;
  height: 150px;
  background-color: #1a1b1e;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const StyledGameTitle = styled.h3`
  ${commonStyles.ui_heading_3}
  color: ${props => (props.unknown ? 'rgba(230, 234, 255, 0.35)' : 'white')};
  margin: 0px;
`;

const StyledCaption = styled.span`
  ${commonStyles.caption}
  color: rgba(230, 234, 255, 0.35);
  line-height: 22px;
`;

const StyledValue = styled.span`
  ${commonStyles.ui_text_medium}
`;

const getGameImage = ({ game, status }) => {
  const gameName = game?.id?.name;
  const coverImage = game?.id?.coverUrl || game?.id?.cover?.url;
  const gameTitlePermitted = game?.permitted;

  if (gameName && gameTitlePermitted && !coverImage) {
    return (
      <StyledGameCardImage>
        <GameNotFoundTooltip />
        <Icon size={64} icon="blank-image" />
      </StyledGameCardImage>
    );
  }

  if (!gameName && status === 'cancelled') {
    return (
      <StyledGameCardImage>
        <Icon size={64} icon="cancelled-project" />
      </StyledGameCardImage>
    );
  }

  if (status === 'in-progress' && !gameName) {
    return (
      <StyledGameCardImage>
        <GameNotFoundTooltip />
        <Icon size={64} icon="coming-soon" />
      </StyledGameCardImage>
    );
  }

  if (!gameTitlePermitted) {
    return (
      <StyledGameCardImage>
        <Icon size={64} icon="lock" />
      </StyledGameCardImage>
    );
  }

  return <StyledImage src={coverImage} />;
};

const ProjectProduction = ({ error, mb, onSave, production }) => (
  <>
    <Frame
      color="#FF32F7"
      style={{ marginBottom: mb }}
      transparent
      error={error}
      text={
        <span>
          Production <Icon icon="vetting" />
        </span>
      }
      noDataText="Let us know if this project was part of commercial production."
      editMode={production.status ? 'edit' : 'add'}
      canEdit
      data={{ production }}
      opens="project-production-modal"
      filled={!!Object.keys(production).length}
    >
      {production.status && (
        <StyledContainer>
          <StyledGame>
            {getGameImage(production)}

            <StyledFlexContainer>
              <StyledGameTitle unknown={!production.game?.id?.name}>
                {production.game?.id?.name
                  ? production.game.permitted
                    ? production.game.id?.name
                    : 'Game title can’t be disclosed'
                  : 'Game title unknown'}
              </StyledGameTitle>
              <StyledCaption>{production.year}</StyledCaption>
            </StyledFlexContainer>
          </StyledGame>

          <StyledInfo>
            {production.role.name && (
              <StyledInfoRow>
                <StyledCaption>Role</StyledCaption>
                <StyledValue>{production.role.permitted ? production.role.name : <Icon icon="lock" />}</StyledValue>
              </StyledInfoRow>
            )}

            {production.developer?.company && (
              <StyledInfoRow>
                <StyledCaption>Developer</StyledCaption>
                <StyledValue>
                  {production.developer.permitted ? production.developer?.company?.name : <Icon icon="lock" />}
                </StyledValue>
              </StyledInfoRow>
            )}

            {production.publisher?.company && (
              <StyledInfoRow>
                <StyledCaption>Publisher</StyledCaption>
                <StyledValue>
                  {production.publisher.permitted ? production.publisher?.company?.name : <Icon icon="lock" />}
                </StyledValue>
              </StyledInfoRow>
            )}
            <StyledInfoRow>
              <StyledCaption>Status</StyledCaption> <StyledValue>{production.status}</StyledValue>
            </StyledInfoRow>
          </StyledInfo>
        </StyledContainer>
      )}
    </Frame>

    <ProjectProductionModal onSave={onSave} />
  </>
);

export default ProjectProduction;
