import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Flex from 'common/styles/Flex';
import useForm from 'hooks/useForm';
import useViewport from 'hooks/useViewport';
import React from 'react';
import { pageSize } from 'utils/devices';
import isEmpty from 'lodash/fp/isEmpty';
import commonStyles from 'utils/common-styles';
import Filters from 'components/filters/Filters';
import { portalActions } from 'store/portals';
import { useDispatch } from 'react-redux';
import isPropertyPopulated from 'utils/isPropertyPopulated';
import SearchSwitcher from 'components/SearchSwitcher';

const StyledTalentsFilter = styled.div`
  background-color: rgba(26, 27, 30, 1);
  padding: 16px 0;

  @media ${pageSize.XS} {
    padding: 16px 0;
  }
`;
const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  @media ${pageSize.L} {
    padding: 0 58px;
  }
  @media ${pageSize.M} {
    padding: 0 54px;
  }
  @media ${pageSize.S} {
    padding: 0 81px;
  }
  @media ${pageSize.XS} {
    padding: 0 16px;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    gap: 32px;
  }
`;
const MoreFiltersCount = styled.span`
  ${commonStyles.ui_text_tiny}
  font-weight: 600;
  background: #ff5151;
  border-radius: 8px;
  padding: 0px 4px;
  margin-left: 8px;
`;

const fields = filters => ({
  discipline: { initialValue: filters?.discipline ?? [] },
  tool: { initialValue: filters?.tool ?? [] },
  platform: { initialValue: filters?.platform ?? [] },
  genre: { initialValue: filters?.genre ?? [] },
  skill: { initialValue: filters?.skill ?? [] },
  experience: { initialValue: filters?.experience ?? [] },
  location: { initialValue: filters?.location ?? [] },
  gamesShipped: { initialValue: filters?.gamesShipped ?? [] }
});

function TalentsFilter({ clearFilters, filters, initialFilters, setFilters }) {
  const { isXS } = useViewport();

  const [show, setShow] = React.useState(false);

  const dispatch = useDispatch();

  const { onFieldChange, values } = useForm({
    fields: fields(initialFilters)
  });

  const isFiltersSelected = isPropertyPopulated(values) || isPropertyPopulated(initialFilters);

  const handleChange = (values, { name }) => {
    onFieldChange(name)(values ?? []);
    setShow(true);
  };

  const handleReset = () => {
    Object.keys(values).forEach(key => {
      onFieldChange(key)([]);
    });
    clearFilters && clearFilters();
    setShow(true);
  };

  const handleSubmit = () => {
    setFilters({ ...initialFilters, ...values });
    setShow(false);
  };

  const openFilterModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'talents-filter-modal',
        data: {
          initialFilters,
          filters,
          setFilters,
          clearFilters,
          setShow
        }
      })
    );
  };

  const moreFiltersCount = React.useMemo(
    () =>
      Object.keys(initialFilters).reduce((curr, key) => {
        if (key === 'applicant-status') {
          return curr;
        }

        const shouldAddOne = (key === 'firstName' || key === 'lastName') && !isEmpty(initialFilters[key].value);
        const shouldAddCount =
          key === 'tag' || key === 'role' || key === 'hoursPerWeek' || key === 'jobType' || key === 'language';

        const add = shouldAddOne ? 1 : shouldAddCount ? initialFilters[key].length : 0;

        return (curr += add);
      }, 0),
    [initialFilters]
  );

  React.useEffect(() => {
    Object.keys(values).forEach(key => {
      onFieldChange(key)(initialFilters[key] ?? []);
    });
  }, [initialFilters]);

  return (
    <StyledTalentsFilter>
      <Wrapper>
        <Flex justifySpaceBetween>
          <SearchSwitcher />

          {!isXS && (
            <ContainedButton
              icon="filter"
              backgroundColor="rgba(204, 213, 255, 0.11)"
              height={'100%'}
              opens="talents-filter-modal"
              data={{
                initialFilters,
                filters,
                setFilters,
                clearFilters,
                setShow
              }}
              disabled={!isPropertyPopulated(filters)}
            >
              more Filters
              {!show && isFiltersSelected && !!moreFiltersCount && (
                <MoreFiltersCount>{moreFiltersCount}</MoreFiltersCount>
              )}
            </ContainedButton>
          )}
        </Flex>
        <Filters
          order={[
            'discipline',
            { key: 'tool', placeholder: 'Tools' },
            { key: 'platform', placeholder: 'Platforms' },
            'genre',
            { key: 'skill', placeholder: 'Skills' },
            { key: 'experience', placeholder: 'Experience Level' },
            { key: 'gamesShipped', placeholder: 'Games Shipped' },
            'location'
          ]}
          xsLimit={4}
          values={values}
          initialFilters={initialFilters}
          filters={filters}
          show={show}
          moreFiltersCount={moreFiltersCount}
          onChange={handleChange}
          onReset={handleReset}
          onSubmit={handleSubmit}
          openFilterModal={openFilterModal}
        />
      </Wrapper>
    </StyledTalentsFilter>
  );
}

export default TalentsFilter;
