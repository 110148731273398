import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { backOfficeActions } from 'store/backOffice';
import Typography from 'components/shared/Typography';
import Spinner from 'common/Spinner';
import useQuery from 'hooks/useQuery';
import LinkWithIcon from 'common/LinkWithIcon';
import ContainedButton from 'common/ContainedButton';
import { Column, Table } from 'components/Table';
import { portalActions } from 'store/portals';
import BackofficePanel from './BackofficePanel';
import { funnelStatusesEnum, reasons } from '../../constants';
import ActivityStatus from './ActivityStatus';
import BackOfficeAddCompanyMemberModal from './modals/BackOfficeAddCompanyMemberModal';
import BackofficeAddCompanyUserModal from './modals/BackofficeAddCompanyUserModal';
import BackofficeEditCompanyMemberRoleModal from './modals/BackofficeEditCompanyMemberRoleModal';
import BackofficeDeleteCompanyMemberModal from './modals/BackofficeDeleteCompanyMemberModal';
import FunnelStatus from './FunnelStatus';
import Label from '../../common/Label';
import UserInvitedSuccess from './UserInvitedSuccess';

const StyledBackOfficeDetailsContainer = styled.div`
  display: grid;
  grid-row-gap: 80px;
  margin-bottom: 80px;
`;
const StyledSectionContainer = styled.div`
  border-top: 2px solid rgba(204, 213, 255, 0.11);
  padding-top: 40px;
`;
const StyledGridContainer = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
`;
const StyledTypography = styled(Typography)`
  color: rgba(230, 234, 255, 0.35);
  self-align: center;
`;
const PublicUrl = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;
const StyledButton = styled(ContainedButton)`
  margin-left: auto;
  margin-bottom: 16px;
`;
const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
const StyledDivFunnel = styled.div`
  word-break: break-all;
`;

const BackofficeCompanyDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, refetch } = useQuery({
    url: `/backoffice/company/${params.companyId}`,
    transformData: responseData => responseData.company,
    onSuccess: company => {
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'Operations' },
          { label: 'Companies', url: '/backoffice/operations/company-accounts' },
          { label: company.name }
        ])
      );
    }
  });

  const { data: funnelStatus, refetch: refetchFunnelStatus } = useQuery({
    url: `/users/funnel-status-logs/${params.companyId}/latest`,
    transformData: data => data.data
  });

  const latestStatusLog = useMemo(() => {
    if (!data) return null;

    const { statusLogs = [] } = data;

    if (!statusLogs.length) return null;

    const lastLog = statusLogs[statusLogs.length - 1];
    const reason = data.status && lastLog && reasons[data.status].find(curr => curr.value === lastLog.reason);

    return !reason ? null : (
      <>
        <StyledTypography>Reason</StyledTypography> <div>{reason.label}</div>
        <StyledTypography>Additional comments</StyledTypography> <div>{lastLog.additionalComment || ''}</div>
      </>
    );
  }, [data]);

  const openFlagModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'company-flag-modal',
        data: {
          accountId: data._id,
          status: data.isSupply === true,
          onSuccess: () => refetch()
        }
      })
    );
  };

  const openFunnelStatusModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'company-funnel-status-modal',
        data: {
          on: 'user',
          userId: params.companyId,
          status: funnelStatus?.status,
          additionalComment: funnelStatus?.additionalComment,
          onSuccess: () => refetchFunnelStatus()
        }
      })
    );
  };

  const openEditCompanyModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'backOffice-company-info-modal',
        data: {
          userId: params.companyId,
          data,
          onSuccess: () => refetch()
        }
      })
    );
  };

  const openStatusModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'company-activity-status-modal',
        data: {
          userId: params.companyId,
          status: data.status,
          onSuccess: () => refetch()
        }
      })
    );
  };

  if (!data) {
    return <Spinner />;
  }

  return (
    <>
      <StyledBackOfficeDetailsContainer>
        <StyledSectionContainer>
          <Typography mb={32} variant="h2">
            Account
          </Typography>

          <StyledGridContainer>
            <BackofficePanel title="Account Info" onEdit={openEditCompanyModal}>
              <StyledTypography variant="medium">Account ID</StyledTypography>
              <div>{data._id}</div>
              <StyledTypography variant="medium">Company name</StyledTypography>
              <div>{data.name}</div>
              <StyledTypography variant="medium">Associated User</StyledTypography>
              <div>{data.owner?.email}</div>
              <StyledTypography variant="medium">Public profile url</StyledTypography>
              <PublicUrl onClick={() => history.push(`/company/${data.publicUrl}/about`)}>/{data.publicUrl}</PublicUrl>
            </BackofficePanel>

            <BackofficePanel title="Supply/demand flags" onEdit={openFlagModal}>
              <StyledTypography variant="medium">Current flag</StyledTypography>
              <div>{data.supplyDemand}</div>
            </BackofficePanel>
          </StyledGridContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Typography mb={32} variant="h2">
            Statuses
          </Typography>

          <StyledGridContainer>
            <BackofficePanel title="Activity status" onEdit={openStatusModal}>
              <StyledTypography>Activity status</StyledTypography> <ActivityStatus status={data.status} />
              {latestStatusLog}
              <div />
              <LinkWithIcon icon="info">
                <Link to={`/backoffice/operations/users/${data._id}/status-log`}>Show status log ↗︎</Link>
              </LinkWithIcon>
            </BackofficePanel>
            <BackofficePanel title="Funnel Status" onEdit={openFunnelStatusModal}>
              <Label>Funnel status</Label>{' '}
              <FunnelStatus status={funnelStatusesEnum[funnelStatus?.status]} value={funnelStatus?.status} />
              <Label>Additional comments</Label> <StyledDivFunnel>{funnelStatus?.additionalComment}</StyledDivFunnel>
              <div />{' '}
              <LinkWithIcon icon="info">
                <Link to={`/backoffice/operations/company/${data._id}/funnel-log`}>Show funnel log ↗︎</Link>
              </LinkWithIcon>
            </BackofficePanel>
          </StyledGridContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Typography mb={32} variant="h2">
            Company members
          </Typography>

          <StyledButton data={{ companyId: data._id }} opens="add-company-member-modal">
            Add members
          </StyledButton>

          <Table keyId="_id" staticData={data.members} noSort adjustHeight>
            <Column title="User ID" keyName="user._id" />
            <Column title="First name" keyName="user.firstName" />
            <Column title="Last name" keyName="user.lastName" />
            <Column title="Role" keyName="role" />
            <Column title="Email" keyName="user.email" />
            <Column
              title="Activity Status"
              keyName="user.status"
              render={member => <ActivityStatus key={member._id} status={member.user?.status} />}
            />

            <Column
              title=""
              width={50}
              type="action"
              render={member => (
                <StyledButtonsContainer>
                  <ContainedButton
                    icon="edit"
                    size={13}
                    backgroundColor="transparent"
                    opens="edit-company-member-modal"
                    data={{
                      memberId: member._id,
                      companyId: data._id,
                      role: member.role
                    }}
                  />

                  <ContainedButton
                    icon="visit"
                    size={13}
                    backgroundColor="transparent"
                    onClick={() => history.push(`/backoffice/operations/users/${member.user._id}`)}
                  />
                </StyledButtonsContainer>
              )}
            />
          </Table>
        </StyledSectionContainer>
      </StyledBackOfficeDetailsContainer>

      <BackOfficeAddCompanyMemberModal refetch={refetch} />
      <BackofficeAddCompanyUserModal refetch={refetch} />
      <UserInvitedSuccess />
      <BackofficeEditCompanyMemberRoleModal refetch={refetch} />
      <BackofficeDeleteCompanyMemberModal refetch={refetch} />
    </>
  );
};

export default BackofficeCompanyDetails;
