import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import Icon from 'common/Icon';
import JobCard from 'pages/jobs/card/JobCard/JobCard';
import React, { useRef } from 'react';
import { pageSize } from 'utils/devices';

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  @media ${pageSize.XS} {
    flex-wrap: wrap;
  }
`;

const ButtonsWrapper = styled.div`
  align-items: center;

  .action-btn {
    width: 36px;
    height: 32px;
    background: #111112;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid rgba(204, 213, 255, 0.11);
  }
  .action-btn:hover {
    background: #1a1b1e;
    border-color: #383a43;
  }
`;
const DragIcon = styled.div`
  cursor: pointer;
`;

const DraggableJobCard = ({ hasPermission = false, id, items, job }) => {
  const dragIconRef = useRef();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const draggableIconElement =
    items.length > 1 && hasPermission ? (
      <StyledButtonsContainer onClick={e => e.stopPropagation()}>
        <ButtonsWrapper className="buttons-wrapper">
          <DragIcon ref={dragIconRef} {...attributes} {...listeners} className={`drag-icon-${job._id} action-btn`}>
            <Icon icon="drag-i" />
          </DragIcon>
        </ButtonsWrapper>
      </StyledButtonsContainer>
    ) : null;

  return (
    <div ref={setNodeRef} style={{ ...style, height: '100%' }}>
      <div id={job._id} style={{ height: '100%' }}>
        <JobCard job={job} isListView={false} showDropdown={hasPermission} hideApply dragIcon={draggableIconElement} />
      </div>
    </div>
  );
};

export default DraggableJobCard;
