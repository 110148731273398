import React from 'react';
import { ReactComponent as IconSeparator } from 'images/assets/icons_16x16/separator_dot.svg';
import { ReactComponent as GridIconList } from 'images/assets/icons_16x16/grid-list.svg';
import { ReactComponent as GridIconEmpty } from 'images/assets/icons_16x16/grid-empty.svg';
import { ReactComponent as IconHide } from 'images/assets/icons_16x16/hide.svg';
import { ReactComponent as IconThumbsDown } from 'images/assets/icons_16x16/thumbs-down.svg';
import { ReactComponent as IconCandidates } from 'images/assets/icons_large/candidates.svg';
import { ReactComponent as IconCandidatesShortlist } from 'images/assets/icons_large/candidatesShortlist.svg';
import { ReactComponent as IconCandidatesSelected } from 'images/assets/icons_large/candidatesSelected.svg';
import { ReactComponent as IconAddToCollection } from 'images/assets/icons_16x16/add_to_collecton.svg';
import { ReactComponent as IconAdminPanel } from 'images/assets/icons_16x16/admin-panel.svg';
import { ReactComponent as IconArrowDown } from 'images/assets/icons_16x16/arrow-down.svg';
import { ReactComponent as IconArrowForward } from 'images/assets/icons_16x16/arrow-forward.svg';
import { ReactComponent as IconArrowLeft } from 'images/assets/icons_16x16/arrow-left.svg';
import { ReactComponent as IconArrowRight } from 'images/assets/icons_16x16/arrow-right.svg';
import { ReactComponent as IconArrowUp } from 'images/assets/icons_16x16/arrow-up.svg';
import { ReactComponent as IconArrowSwitch } from 'images/assets/icons_16x16/arrow-switch.svg';
import { ReactComponent as IconApplied } from 'images/assets/icons_large/applied.svg';
import { ReactComponent as IconSaved } from 'images/assets/icons_large/saved.svg';
import { ReactComponent as IconSelected } from 'images/assets/icons_large/selected.svg';
import { ReactComponent as IconHired } from 'images/assets/icons_large/hired.svg';
import { ReactComponent as IconBack } from 'images/assets/icons_16x16/back.svg';
import { ReactComponent as IconIgnore } from 'images/assets/icons_16x16/ignore.svg';
import { ReactComponent as IconBackoffice } from 'images/assets/icons_16x16/backoffice.svg';
import { ReactComponent as IconForward } from 'images/assets/icons_16x16/forward.svg';
import { ReactComponent as IconVisit } from 'images/assets/icons_16x16/visit.svg';
import { ReactComponent as IconClose } from 'images/assets/icons_16x16/close.svg';
import { ReactComponent as IconSave } from 'images/assets/icons_16x16/save.svg';
import { ReactComponent as IconExport } from 'images/assets/icons_16x16/export.svg';
import { ReactComponent as IconEvents } from 'images/assets/icons_16x16/events.svg';
import { ReactComponent as IconEventsGradient } from 'images/assets/icons_16x16/events-gradient.svg';
import { ReactComponent as IconImport } from 'images/assets/icons_16x16/import.svg';
import { ReactComponent as IconInfo } from 'images/assets/icons_16x16/info.svg';
import { ReactComponent as InconInformation } from 'images/assets/icons_16x16/information.svg';
import { ReactComponent as IconLike } from 'images/assets/icons_16x16/like.svg';
import { ReactComponent as IconLikeColored } from 'images/assets/icons_16x16/like-colored.svg';
import { ReactComponent as IconPortfolioGrid } from 'images/assets/icons_16x16/portfolio-grid.svg';
import { ReactComponent as IconPortfolio } from 'images/assets/icons_16x16/portfolio.svg';
import { ReactComponent as IconShare } from 'images/assets/icons_16x16/share.svg';
import { ReactComponent as IconTop } from 'images/assets/icons_16x16/top.svg';
import { ReactComponent as IconNA } from 'images/assets/icons_16x16/NA.svg';
import { ReactComponent as IconFollow } from 'images/assets/icons_16x16/follow.svg';
import { ReactComponent as IconKebab } from 'images/assets/icons_16x16/kebab.svg';
import { ReactComponent as IconKnowledgeBase } from 'images/assets/icons_16x16/knowledge-base.svg';
import { ReactComponent as IconLanguage } from 'images/assets/icons_16x16/language.svg';
import { ReactComponent as IconLocation } from 'images/assets/icons_16x16/location.svg';
import { ReactComponent as IconOperations } from 'images/assets/icons_16x16/operations.svg';
import { ReactComponent as IconSpeech } from 'images/assets/icons_16x16/speech-icon.svg';
import { ReactComponent as IconExperience } from 'images/assets/icons_16x16/experience.svg';
import { ReactComponent as IconTestimonials } from 'images/assets/icons_16x16/testimonials.svg';
import { ReactComponent as IconAbout } from 'images/assets/icons_16x16/about.svg';
import { ReactComponent as IconAboutGradient } from 'images/assets/icons_16x16/about-gradient.svg';
import { ReactComponent as CommentViolet } from 'images/assets/icons_16x16/comment_violet.svg';
import { ReactComponent as CommentGreen } from 'images/assets/icons_16x16/comment_green.svg';
import { ReactComponent as IconAudio } from 'images/assets/icons_16x16/audio.svg';
import { ReactComponent as IconMusic } from 'images/assets/icons_16x16/music.svg';
import { ReactComponent as Icon3D } from 'images/assets/icons_16x16/3D.svg';
import { ReactComponent as IconMovie } from 'images/assets/icons_16x16/movie.svg';
import { ReactComponent as IconVideoGames } from 'images/assets/icons_16x16/videoGames.svg';
import { ReactComponent as IconTheX } from 'images/assets/assets_icons/thex.svg';
import { ReactComponent as IconDownload } from 'images/assets/icons_16x16/download.svg';
import { ReactComponent as IconBell } from 'images/assets/bellIcon.svg';
import { ReactComponent as IconCopy } from 'images/assets/icons_16x16/copy.svg';

import { ReactComponent as IconSeasonedTalent } from 'images/assets/icons_16x16/seasoned_talent.svg';
import { ReactComponent as IconCheckmark } from 'images/assets/icons_16x16/checkmark.svg';
import { ReactComponent as IconMagnifier } from 'images/assets/icons_16x16/magnifier.svg';
import { ReactComponent as IconHorizontalDash } from 'images/assets/icons_16x16/horizontal-dash.svg';
import { ReactComponent as IconEdit } from 'images/assets/icons_16x16/edit.svg';
import { ReactComponent as IconEditUnderline } from 'images/assets/icons_16x16/edit_underline.svg';
import { ReactComponent as IconPlus } from 'images/assets/icons_16x16/plus.svg';
import { ReactComponent as IconLogout } from 'images/assets/icons_16x16/logout.svg';
import { ReactComponent as IconUpload } from 'images/assets/icons_16x16/upload.svg';
import { ReactComponent as IconUploadAlt } from 'images/assets/icons_16x16/upload-alt.svg';
import { ReactComponent as IconLink } from 'images/assets/icons_16x16/link.svg';
import { ReactComponent as IconLife } from 'images/assets/icons_16x16/life.svg';
import { ReactComponent as IconDots } from 'images/assets/icons_16x16/dots.svg';
import { ReactComponent as IconBookmark } from 'images/assets/icons_16x16/bookmark.svg';
import { ReactComponent as IconBookmarkEmpty } from 'images/assets/icons_16x16/bookmark-empty.svg';
import { ReactComponent as IconBenefit } from 'images/assets/icons_16x16/benefit.svg';
import { ReactComponent as RedDot } from 'images/assets/icons_16x16/red_dot.svg';
import { ReactComponent as GreenDot } from 'images/assets/icons_16x16/green_dot.svg';
import { ReactComponent as OrangeDot } from 'images/assets/icons_16x16/orange_dot.svg';
import { ReactComponent as CyanDot } from 'images/assets/icons_16x16/cyan_dot.svg';
import { ReactComponent as PurpleDot } from 'images/assets/icons_16x16/purple_dot.svg';

import { ReactComponent as IconAchievement } from 'images/assets/icons_16x16/achievement.svg';
import { ReactComponent as IconCalendar } from 'images/assets/icons_16x16/calendar.svg';
import { ReactComponent as IconDelete } from 'images/assets/icons_16x16/delete.svg';
import { ReactComponent as IconDrag } from 'images/assets/icons_16x16/drag.svg';
import { ReactComponent as IconDraggable } from 'images/assets/icons_16x16/draggable.svg';
import { ReactComponent as IconDraggableTouch } from 'images/assets/icons_16x16/draggable_touch.svg';
import { ReactComponent as IconDraggableTouchTransparent } from 'images/assets/icons_16x16/draggable_touch_transparent.svg';
import { ReactComponent as IconHidden } from 'images/assets/icons_16x16/hidden.svg';
import { ReactComponent as IconQuestion } from 'images/assets/icons_16x16/question.svg';
import { ReactComponent as IconSearch } from 'images/assets/icons_16x16/search.svg';
import { ReactComponent as IconZoomIn } from 'images/assets/icons_16x16/zoom-in.svg';
import { ReactComponent as IconSettings } from 'images/assets/icons_16x16/settings.svg';
import { ReactComponent as IconVisible } from 'images/assets/icons_16x16/visible.svg';
import { ReactComponent as IconTime } from 'images/assets/icons_16x16/time.svg';
import { ReactComponent as Union } from 'images/assets/icons_16x16/union.svg';
import { ReactComponent as MultiUsers } from 'images/assets/icons_16x16/multi-users.svg';
import { ReactComponent as IconVolume100 } from 'images/assets/icons_16x16/volume-100.svg';
import { ReactComponent as IconVolume50 } from 'images/assets/icons_16x16/volume-50.svg';
import { ReactComponent as IconVolumeMute } from 'images/assets/icons_16x16/volume-mute.svg';
import { ReactComponent as IconText } from 'images/assets/icons_16x16/text.svg';
import { ReactComponent as IconTextImage } from 'images/assets/icons_16x16/text-image.svg';
import { ReactComponent as IconPhotoGallery } from 'images/assets/icons_16x16/photo-gallery.svg';
import { ReactComponent as IconId } from 'images/assets/icons_16x16/id_icon.svg';
import { ReactComponent as IconIdVerification } from 'images/assets/id_verification_icon.svg';
import { ReactComponent as IconBetaProgress } from 'images/assets/assets_icons/betaProgress.svg';
import { ReactComponent as IconRealDeal } from 'images/assets/icons_16x16/real_deal.svg';
import { ReactComponent as IconTick } from 'images/assets/tick.svg';
import { ReactComponent as IconCheckmarkCircle } from 'images/assets/assets_icons/checkmark-circle.svg';
import { ReactComponent as IconCheckmarkBig } from 'images/assets/assets_icons/checkmark-big.svg';
import { ReactComponent as IconNewIndicator } from 'images/assets/new-indicator.svg';

// Social Logins
import { ReactComponent as IconFacebook } from 'images/assets/icons_16x16/facebook.svg';
import { ReactComponent as IconGoogle } from 'images/assets/icons_16x16/google.svg';
import { ReactComponent as IconLinkedin } from 'images/assets/icons_16x16/linkedin.svg';
import { ReactComponent as IconTwitter } from 'images/assets/icons_16x16/twitter.svg';
// About Icons
import { ReactComponent as IconGamesShipped } from 'images/assets/icons_16x16/games-shipped.svg';
import { ReactComponent as IconPerDeliverable } from 'images/assets/icons_16x16/per-deliverable.svg';
import { ReactComponent as IconPerHour } from 'images/assets/icons_16x16/per-hour.svg';
import { ReactComponent as IconTripleA } from 'images/assets/icons_16x16/triple-a.svg';
import { ReactComponent as IconDoubleA } from 'images/assets/icons_16x16/double-a.svg';
import { ReactComponent as IconTripleI } from 'images/assets/icons_16x16/triple-i.svg';
import { ReactComponent as IconMobile } from 'images/assets/icons_16x16/mobile.svg';
import { ReactComponent as IconYearsOfExperience } from 'images/assets/icons_16x16/years-of-experience.svg';
import { ReactComponent as Skills } from 'images/assets/icons_16x16/skills.svg';
import { ReactComponent as Goal } from 'images/assets/icons_16x16/goal.svg';
import { ReactComponent as CompanyImg } from 'images/assets/icons_16x16/companyImg.svg';
// Disciplines Icons
import { ReactComponent as IconDisVisualEffectsAndLighting } from 'images/assets/icons_16x16/dis_visual_effects_and_lighting.svg';
import { ReactComponent as IconDisPeopleOperations } from 'images/assets/icons_16x16/dis_people_operations.svg';
import { ReactComponent as IconDisDevOperations } from 'images/assets/icons_16x16/dis_dev_operations.svg';
import { ReactComponent as IconDisProduction } from 'images/assets/icons_16x16/dis_production.svg';
import { ReactComponent as IconCommunityManagement } from 'images/assets/icons_16x16/dis_community_management.svg';
import { ReactComponent as IconDisFinance } from 'images/assets/icons_16x16/dis_finance.svg';
import { ReactComponent as IconDisAnalytics } from 'images/assets/icons_16x16/dis_analytics.svg';
import { ReactComponent as IconDisAnimation } from 'images/assets/icons_16x16/dis_animation.svg';
import { ReactComponent as IconDisArt } from 'images/assets/icons_16x16/dis_art.svg';
import { ReactComponent as IconHome } from 'images/assets/icons_16x16/home.svg';
import { ReactComponent as IconDisAudio } from 'images/assets/icons_16x16/dis_audio.svg';
import { ReactComponent as IconDisBusinessDevelopment } from 'images/assets/icons_16x16/dis_business_development.svg';
import { ReactComponent as IconDisCinematics } from 'images/assets/icons_16x16/dis_cinematics.svg';
import { ReactComponent as IconDisEngineering } from 'images/assets/icons_16x16/dis_engineering.svg';
import { ReactComponent as IconDisGameDesign } from 'images/assets/icons_16x16/dis_game_design.svg';
import { ReactComponent as IconDisGameSecurity } from 'images/assets/icons_16x16/dis_game_security.svg';
import { ReactComponent as IconDisLegal } from 'images/assets/icons_16x16/dis_legal.svg';
import { ReactComponent as IconDisLocalization } from 'images/assets/icons_16x16/dis_localization.svg';
import { ReactComponent as IconDisMarketing } from 'images/assets/icons_16x16/dis_marketing.svg';
import { ReactComponent as IconDisOther } from 'images/assets/icons_16x16/dis_other.svg';
import { ReactComponent as IconDisPlayerSupport } from 'images/assets/icons_16x16/dis_player_support.svg';
import { ReactComponent as IconPlay } from 'images/assets/icons_16x16/play.svg';
import { ReactComponent as IconPause } from 'images/assets/icons_16x16/pause.svg';
import { ReactComponent as IconPeople } from 'images/assets/icons_16x16/people.svg';
import { ReactComponent as IconDisProductManagement } from 'images/assets/icons_16x16/dis_product_management.svg';
import { ReactComponent as AccountManagement } from 'images/assets/icons_16x16/account_management.svg';
import { ReactComponent as IconDisPublishing } from 'images/assets/icons_16x16/dis_publishing.svg';
import { ReactComponent as IconDisQaAndCertification } from 'images/assets/icons_16x16/dis_qa_and_certification.svg';
import { ReactComponent as IconDisStory } from 'images/assets/icons_16x16/dis_story.svg';
import { ReactComponent as IconDisUiUxDesign } from 'images/assets/icons_16x16/dis_ui_ux_design.svg';
import { ReactComponent as IconDisVisualEffectsAndLightning } from 'images/assets/icons_16x16/dis_visual_effects_and_lightning.svg';
import { ReactComponent as IconDisInvestmentFunding } from 'images/assets/icons_16x16/dis_investment_funding.svg';
import { ReactComponent as IconDisLeadership } from 'images/assets/icons_16x16/dis_leadership.svg';
import { ReactComponent as IconDisBoardMember } from 'images/assets/icons_16x16/dis_board_member.svg';
import { ReactComponent as IconDisAdvisoryBoard } from 'images/assets/icons_16x16/dis_advisory_board.svg';
import { ReactComponent as IconDisEntrepreneurship } from 'images/assets/icons_16x16/dis_entrepreneurship.svg';
import { ReactComponent as IconDisCustomerExperience } from 'images/assets/icons_16x16/dis_customer_experience.svg';
import { ReactComponent as IconDisEconomyMonetization } from 'images/assets/icons_16x16/dis_economy_monetization.svg';
import { ReactComponent as IconSuspended } from 'images/assets/icons_16x16/suspended-icon.svg';
import { ReactComponent as Page404 } from 'images/assets/icons_16x16/404.svg';
import { ReactComponent as ServerOutage } from 'images/assets/icons_16x16/server-outage.svg';
import { ReactComponent as IconThumbUp } from 'images/assets/icons_16x16/thumb-up.svg';
import { ReactComponent as IconThumbsUpOutlined } from 'images/assets/icons_16x16/thumb-up-outlined.svg';
import { ReactComponent as IconAlert } from 'images/assets/icons_16x16/alert.svg';
import { ReactComponent as IconLock } from 'images/assets/icons_16x16/lock.svg';
import { ReactComponent as IconUnlock } from 'images/assets/icons_16x16/unlock.svg';
import { ReactComponent as IconUnlockPending } from 'images/assets/icons_16x16/unlockPending.svg';
import { ReactComponent as IconComingSoon } from 'images/assets/icons_16x16/coming-soon.svg';
import { ReactComponent as IconBlankImage } from 'images/assets/icons_16x16/blank-image.svg';
import { ReactComponent as IconCancelledProject } from 'images/assets/icons_16x16/cancelled-project.svg';
import { ReactComponent as IconChatHead } from 'images/assets/icons_16x16/chat-head.svg';
import { ReactComponent as IconVideo } from 'images/assets/icons_16x16/video.svg';
import { ReactComponent as IconNotification } from 'images/assets/icons_16x16/notification.svg';
import { ReactComponent as IconNotificationRing } from 'images/assets/icons_16x16/notification_ring.svg';
import { ReactComponent as IconNoGame } from 'images/assets/icons_16x16/no-game.svg';
import { ReactComponent as IconUserChecked } from 'images/assets/icons_16x16/user-checked.svg';
import { ReactComponent as IconCommunity } from 'images/assets/icons_16x16/community.svg';
import { ReactComponent as CompanySize } from 'images/assets/icons_16x16/company_size.svg';
import { ReactComponent as IconJobs } from 'images/assets/icons_16x16/jobs.svg';
import { ReactComponent as IconJobsGradient } from 'images/assets/icons_16x16/jobs-gradient.svg';
import { ReactComponent as IconServices } from 'images/assets/icons_16x16/services.svg';
import { ReactComponent as IconPublishingServices } from 'images/assets/icons_16x16/publishing-services.svg';
import { ReactComponent as IconArtServices } from 'images/assets/icons_16x16/art-services.svg';
import { ReactComponent as IconFoundation } from 'images/assets/icons_16x16/foundation.svg';
import { ReactComponent as IconVerified } from 'images/assets/icons_16x16/verified.svg';
import { ReactComponent as IconBurger } from 'images/assets/icons_16x16/burger.svg';
import { ReactComponent as IconVetting } from 'images/assets/icons_16x16/vetting.svg';
import { ReactComponent as IconVetted } from 'images/assets/icons_16x16/vettedIcon.svg';
import { ReactComponent as VettingDue } from 'images/assets/icons_16x16/vetting_due.svg';
import { ReactComponent as IconProjects } from 'images/assets/icons_16x16/projects.svg';
import { ReactComponent as IconHammer } from 'images/assets/icons_16x16/hammer.svg';
import { ReactComponent as IconCrossedHammer } from 'images/assets/icons_16x16/crossedHammer.svg';
import { ReactComponent as IconSuitcase } from 'images/assets/icons_16x16/suitcase.svg';
import { ReactComponent as IconFilter } from 'images/assets/icons_16x16/filter.svg';
import { ReactComponent as IconJobsIPosted } from 'images/assets/icons_16x16/jobsIPosted.svg';
import { ReactComponent as IconGrid } from 'images/assets/icons_16x16/grid.svg';
import { ReactComponent as IconList } from 'images/assets/icons_16x16/list.svg';
import { ReactComponent as IconCardsList } from 'images/assets/icons_16x16/cards-list.svg';
import { ReactComponent as IconRefer } from 'images/assets/icons_16x16/refer.svg';
import { ReactComponent as IconCompany } from 'images/assets/icons_16x16/company.svg';
import { ReactComponent as IconInternship } from 'images/assets/icons_16x16/internship.svg';
import { ReactComponent as IconEntryLevel } from 'images/assets/icons_16x16/entryLevel.svg';
import { ReactComponent as IconLifeFrameWhatWeAre } from 'images/assets/icons_16x16/lifeFrameWhoWeAre.svg';
import { ReactComponent as IconLifeFrameWhatWeStandFor } from 'images/assets/icons_16x16/lifeFrameWhatWeStandFor.svg';
import { ReactComponent as IconLifeFrameWhatWeOffer } from 'images/assets/icons_16x16/lifeFrameWhatWeOffer.svg';
import { ReactComponent as IconLifeFrameNewPage } from 'images/assets/icons_16x16/lifeFrameNewPage.svg';
import { ReactComponent as IconWallet } from 'images/assets/icons_16x16/wallet.svg';

// benefit icons
import { ReactComponent as IconThumbsUp } from 'images/assets/benefits_icons/thumbs-up.svg';
import { ReactComponent as IconBusinessDevelopment } from 'images/assets/benefits_icons/business_development.svg';
import { ReactComponent as IconPublishing } from 'images/assets/benefits_icons/publishing.svg';
import { ReactComponent as IconJobIPosted } from 'images/assets/benefits_icons/jobs_i_posted.svg';
import { ReactComponent as IconPayments } from 'images/assets/benefits_icons/payments.svg';
import { ReactComponent as IconRocket } from 'images/assets/benefits_icons/rocket.svg';
import { ReactComponent as IconAudioMusic } from 'images/assets/benefits_icons/audio_music.svg';
import { ReactComponent as IconLegal } from 'images/assets/benefits_icons/legal.svg';
import { ReactComponent as IconVisualEffects } from 'images/assets/benefits_icons/visual_effects.svg';
import { ReactComponent as IconRelocation } from 'images/assets/benefits_icons/relocation.svg';
import { ReactComponent as IconAnalytics } from 'images/assets/benefits_icons/analytics.svg';
import { ReactComponent as IconPersonalInfo } from 'images/assets/benefits_icons/personal_info.svg';
import { ReactComponent as IconProductManagement } from 'images/assets/benefits_icons/product_management.svg';
import { ReactComponent as IconPlayerSupport } from 'images/assets/benefits_icons/player_support.svg';
import { ReactComponent as IconTv } from 'images/assets/benefits_icons/tv.svg';
import { ReactComponent as IconSubtract } from 'images/assets/benefits_icons/subtract.svg';
import { ReactComponent as IconVideoGamesBenefit } from 'images/assets/benefits_icons/video_games.svg';

// Files Icons
import { ReactComponent as IconPDF } from 'images/assets/files_icons/pdf.svg';
import { ReactComponent as IconSvg } from 'images/assets/files_icons/svg.svg';
import { ReactComponent as IconImage } from 'images/assets/files_icons/image.svg';
import { ReactComponent as IconVideoFile } from 'images/assets/files_icons/video.svg';
import { ReactComponent as IconAudioFile } from 'images/assets/files_icons/audio.svg';
import { ReactComponent as IconTextFile } from 'images/assets/files_icons/text.svg';
import { ReactComponent as IconWord } from 'images/assets/files_icons/word.svg';
import { ReactComponent as IconExcel } from 'images/assets/files_icons/excel.svg';
import { ReactComponent as IconPPT } from 'images/assets/files_icons/ppt.svg';
import { ReactComponent as IconDefaultFile } from 'images/assets/files_icons/default.svg';

import { ReactComponent as IconLoader } from 'images/assets/loader.svg';
import { ReactComponent as IconGroup } from 'images/assets/group.svg';
import { ReactComponent as IconConversationGroup } from 'images/assets/conversation_group.svg';
import { ReactComponent as IconWarning } from 'images/assets/warning.svg';
import { ReactComponent as IconMask } from 'images/assets/mask.svg';
import { ReactComponent as IconAttachment } from 'images/assets/attachment.svg';
import { ReactComponent as IconSmile } from 'images/assets/smile.svg';
import { ReactComponent as IconReverse } from 'images/assets/assets_icons/reverse.svg';
import { ReactComponent as IconEclipse } from 'images/assets/assets_icons/eclipse.svg';

// Conversation Icons
import { ReactComponent as IconSent } from 'images/assets/conversation_icons/sent.svg';
import { ReactComponent as IconNotSent } from 'images/assets/conversation_icons/not_sent.svg';
import { ReactComponent as IconSeen } from 'images/assets/conversation_icons/seen.svg';

// community Icons
import { ReactComponent as InputNeededIcon } from 'images/assets/communityIcons/inputNeeded.svg';
import { ReactComponent as RequestSentIcon } from 'images/assets/communityIcons/requestSent.svg';
import { ReactComponent as IconVouch } from 'images/assets/communityIcons/vouch.svg';
import { ReactComponent as IconMyCommunity } from 'images/assets/communityIcons/my_community.svg';
import { ReactComponent as IconSubmitAlert } from 'images/assets/communityIcons/alert.svg';
import { ReactComponent as IconApplicationCheckbox } from 'images/assets/communityIcons/application_checkbox.svg';

// Welcome Messages Icons
import { ReactComponent as IconBasicProfileInfo } from 'images/assets/welcomeMessage_icons/basic-profile-info.svg';
import { ReactComponent as IconWriteUp } from 'images/assets/welcomeMessage_icons/write-up.svg';
import { ReactComponent as IconReviewProjects } from 'images/assets/welcomeMessage_icons/projects.svg';
import { ReactComponent as IconAssets } from 'images/assets/welcomeMessage_icons/assets.svg';
import { ReactComponent as IconCredits } from 'images/assets/welcomeMessage_icons/credits.svg';
import { ReactComponent as IconFinish } from 'images/assets/welcomeMessage_icons/finish.svg';

import withOpenPortal from 'hocs/withOpenPortal';

import styled from '@emotion/styled';

const getIcon = iconName => {
  switch (iconName) {
    case 'achievement':
      return IconAchievement;
    case 'add-to-collection':
    case 'addToCollection':
      return IconAddToCollection;
    case 'admin-panel':
      return IconAdminPanel;
    case 'arrow-down':
      return IconArrowDown;
    case 'arrow-forward':
      return IconArrowForward;
    case 'arrow-left':
      return IconArrowLeft;
    case 'arrow-right':
      return IconArrowRight;
    case 'arrow-up':
      return IconArrowUp;
    case 'arrow-switch':
      return IconArrowSwitch;
    case 'back':
      return IconBack;
    case 'backoffice':
      return IconBackoffice;
    case 'calendar':
      return IconCalendar;
    case 'forward':
    case 'next':
      return IconForward;
    case 'checkmark':
      return IconCheckmark;
    case 'checkmark-some':
    case 'horizontal-dash':
      return IconHorizontalDash;
    case 'the-x':
      return IconTheX;
    case 'download':
      return IconDownload;
    case 'bell':
      return IconBell;
    case 'copy':
      return IconCopy;
    case 'close':
      return IconClose;
    case 'edit':
      return IconEdit;
    case 'edit_underline':
      return IconEditUnderline;
    case 'delete':
      return IconDelete;
    case 'drag':
      return IconDrag;
    case 'draggable':
      return IconDraggable;
    case 'draggable_touch':
      return IconDraggableTouch;
    case 'drag-i':
      return IconDraggableTouchTransparent;
    case 'hidden':
      return IconHidden;
    case 'export':
      return IconExport;
    case 'events':
      return IconEvents;
    case 'events-gradient':
      return IconEventsGradient;
    case 'import':
      return IconImport;
    case 'info':
      return IconInfo;
    case 'information':
      return InconInformation;
    case 'like':
      return IconLike;
    case 'like-colored':
      return IconLikeColored;
    case 'portfolio-grid':
      return IconPortfolioGrid;
    case 'save':
      return IconSave;
    case 'portfolio':
      return IconPortfolio;
    case 'share':
      return IconShare;
    case 'top':
      return IconTop;
    case 'follow':
      return IconFollow;
    case 'visit':
      return IconVisit;
    case 'kebab':
      return IconKebab;
    case 'knowledge-base':
      return IconKnowledgeBase;
    case 'language':
    case 'languages':
      return IconLanguage;
    case 'location':
      return IconLocation;
    case 'logout':
      return IconLogout;
    case 'send_a_message':
    case 'sendAMessage':
    case 'message':
      return IconSpeech;
    case 'operations':
      return IconOperations;
    case 'experience':
      return IconExperience;
    case 'testimonials':
    case 'media-coverage':
      return IconTestimonials;
    case 'audio':
      return IconAudio;
    case 'music':
      return IconMusic;
    case 'movie':
      return IconMovie;
    case '3D':
      return Icon3D;
    case 'videoGames':
      return IconVideoGames;
    case 'seasoned-talent':
    case 'seasoned_talent':
    case 'seasonedTalent':
      return IconSeasonedTalent;
    case 'about':
    case 'profile':
      return IconAbout;
    case 'about-gradient':
    case 'profile-gradient':
      return IconAboutGradient;
    case 'games-shipped':
      return IconGamesShipped;
    case 'comment-avatar-violet':
      return CommentViolet;
    case 'comment-avatar-green':
      return CommentGreen;
    case 'per-deliverable':
      return IconPerDeliverable;
    case 'per-hour':
      return IconPerHour;
    case 'add':
    case 'plus':
      return IconPlus;
    case 'question':
      return IconQuestion;
    case 'search':
      return IconSearch;
    case 'zoom-in':
      return IconZoomIn;
    case 'settings':
      return IconSettings;
    case 'time':
      return IconTime;
    case 'union':
      return Union;
    case 'multi-users':
      return MultiUsers;
    case 'triple-a':
      return IconTripleA;
    case 'double-a':
      return IconDoubleA;
    case 'mobile':
      return IconMobile;
    case 'triple-i':
      return IconTripleI;
    case 'upload':
      return IconUpload;
    case 'skills':
      return Skills;
    case 'goal':
      return Goal;
    case 'companyImg':
      return CompanyImg;
    case 'upload-alt':
      return IconUploadAlt;
    case 'link':
      return IconLink;
    case 'life':
      return IconLife;
    case 'benefit':
      return IconBenefit;
    case 'vetted':
      // eslint-disable-next-line react/display-name
      return ({ className }) => (
        <span className={`${className} icon-thexplace`} style={{ color: '#00FF00' }}>
          vetted
        </span>
      );
    case 'vetting':
      // eslint-disable-next-line react/display-name
      return ({ className }) => <span className={`${className} icon-thexplace`}>vettinginprogress</span>;
    case 'vetted-i':
      return IconVetted;
    case 'vetting-i':
      return IconVetting;
    case 'vetting_due':
      return VettingDue;
    case 'visible':
      return IconVisible;
    case 'years-of-experience':
      return IconYearsOfExperience;
    case 'id':
      return IconId;
    case 'id_verification':
      return IconIdVerification;
    case 'vouch':
      return IconVouch;
    case 'my_community':
      return IconMyCommunity;
    case 'submit_alert':
      return IconSubmitAlert;
    case 'application_checkbox':
      return IconApplicationCheckbox;
    case 'betaProgress':
      return IconBetaProgress;
    case 'real_deal':
      return IconRealDeal;
    case 'tick':
      return IconTick;
    case 'checkmark-circle':
      return IconCheckmarkCircle;
    case 'checkmark-big':
      return IconCheckmarkBig;
    // Social Logins :
    case 'facebook':
      return IconFacebook;
    case 'google':
      return IconGoogle;
    case 'linkedin':
      return IconLinkedin;
    case 'twitter':
      return IconTwitter;
    // Disciplines Icons:
    case 'dis_visual_effects_and_lighting':
      return IconDisVisualEffectsAndLighting;
    case 'dis_people_operations':
      return IconDisPeopleOperations;
    case 'dis_dev_operations':
      return IconDisDevOperations;
    case 'dis_production':
      return IconDisProduction;
    case 'dis_finance':
      return IconDisFinance;
    case 'dis_community_management':
      return IconCommunityManagement;
    case 'dis_analytics':
      return IconDisAnalytics;
    case 'dis_animation':
      return IconDisAnimation;
    case 'dis_art':
      return IconDisArt;
    case 'home':
      return IconHome;
    case 'dis_audio':
      return IconDisAudio;
    case 'dis_business_development':
      return IconDisBusinessDevelopment;
    case 'dis_cinematics':
      return IconDisCinematics;
    case 'dis_engineering':
      return IconDisEngineering;
    case 'dis_game_design':
      return IconDisGameDesign;
    case 'dis_game_security':
      return IconDisGameSecurity;
    case 'dis_legal':
      return IconDisLegal;
    case 'dis_localization':
      return IconDisLocalization;
    case 'dis_marketing':
      return IconDisMarketing;
    case 'dis_other':
      return IconDisOther;
    case 'dis_player_support':
      return IconDisPlayerSupport;
    case 'dis_product_management':
      return IconDisProductManagement;
    case 'account_management':
      return AccountManagement;
    case 'dis_publishing':
      return IconDisPublishing;
    case 'dis_qa_and_certification':
      return IconDisQaAndCertification;
    case 'dis_story':
      return IconDisStory;
    case 'dis_ui_ux_design':
      return IconDisUiUxDesign;
    case 'dis_visual_effects_and_lightning':
      return IconDisVisualEffectsAndLightning;
    case 'dis_entrepreneurship':
      return IconDisEntrepreneurship;
    case 'dis_customer_experience':
      return IconDisCustomerExperience;
    case 'dis_economy_monetization':
      return IconDisEconomyMonetization;
    case 'dis_economymonetization':
      return IconDisEconomyMonetization;
    case 'suspended':
      return IconSuspended;
    case 'thumb-up':
      return IconThumbUp;
    case 'thumb-up-outlined':
      return IconThumbsUpOutlined;
    case 'alert':
      return IconAlert;
    case 'lock':
      return IconLock;
    case 'unlock':
      return IconUnlock;
    case 'unlock-pending':
      return IconUnlockPending;
    case 'coming-soon':
      return IconComingSoon;
    case 'blank-image':
      return IconBlankImage;
    case 'cancelled-project':
      return IconCancelledProject;
    case 'ignore':
      return IconIgnore;
    case 'chat-head':
      return IconChatHead;
    case 'na':
      return IconNA;
    case 'video':
      return IconVideo;
    case 'notification':
      return IconNotification;
    case 'notification_ring':
      return IconNotificationRing;
    case 'no-game':
      return IconNoGame;
    case 'magnifier':
      return IconMagnifier;
    case 'play':
      return IconPlay;
    case 'pause':
      return IconPause;
    case 'people':
      return IconPeople;
    case 'user-checked':
      return IconUserChecked;
    case 'community':
      return IconCommunity;
    case 'company-size':
      return CompanySize;
    case 'jobs':
      return IconJobs;
    case 'jobs-gradient':
      return IconJobsGradient;
    case 'applied':
      return IconApplied;
    case 'saved':
      return IconSaved;
    case 'selected':
      return IconSelected;
    case 'hired':
      return IconHired;
    case 'candidates':
      return IconCandidates;
    case 'candidatesShortlist':
      return IconCandidatesShortlist;
    case 'candidatesSelected':
      return IconCandidatesSelected;
    case 'services':
      return IconServices;
    case 'publishing-services':
      return IconPublishingServices;
    case 'art-services':
      return IconArtServices;
    case 'foundation':
      return IconFoundation;
    case 'verified':
      return IconVerified;
    case 'burger':
      return IconBurger;
    case 'projects':
      return IconProjects;
    case 'dots':
      return IconDots;
    case 'greenDot':
      return GreenDot;
    case 'redDot':
      return RedDot;
    case 'cyanDot':
      return CyanDot;
    case 'orangeDot':
      return OrangeDot;
    case 'purpleDot':
      return PurpleDot;
    case 'bookmark':
      return IconBookmark;
    case 'bookmark-empty':
      return IconBookmarkEmpty;
    case 'hammer':
      return IconHammer;
    case 'crossed-hammer':
      return IconCrossedHammer;
    case 'suitcase':
      return IconSuitcase;
    case 'volume-100':
      return IconVolume100;
    case 'volume-50':
      return IconVolume50;
    case 'volume-mute':
      return IconVolumeMute;
    case 'text':
      return IconText;
    case 'text-image':
      return IconTextImage;
    case 'photo-gallery':
      return IconPhotoGallery;
    case 'filter':
      return IconFilter;
    case 'jobs_i_posted':
    case 'jobsIPosted':
      return IconJobsIPosted;
    case 'separator-dot':
      return IconSeparator;
    case 'grid':
      return IconGrid;
    case 'grid-empty':
      return GridIconEmpty;
    case 'grid-list':
      return GridIconList;
    case 'list':
      return IconList;
    case 'cards-list':
      return IconCardsList;
    case 'refer':
      return IconRefer;
    case 'company':
      return IconCompany;
    case 'loader':
      return IconLoader;
    case 'internship':
      return IconInternship;
    case 'entryLevel':
      return IconEntryLevel;
    case 'dis_leadership':
      return IconDisLeadership;
    case 'dis_board_member':
      return IconDisBoardMember;
    case 'dis_advisory_board':
      return IconDisAdvisoryBoard;
    case 'dis_investment_funding':
      return IconDisInvestmentFunding;
    case 'lifeFrame_whatWeAre':
      return IconLifeFrameWhatWeAre;
    case 'lifeFrame_newPage':
      return IconLifeFrameNewPage;
    case 'lifeFrame_whatWeStandFor':
      return IconLifeFrameWhatWeStandFor;
    case 'lifeFrame_whatWeOffer':
      return IconLifeFrameWhatWeOffer;
    case 'new-indicator':
      return IconNewIndicator;
    case 'wallet':
      return IconWallet;
    // benefit icons
    case 'thumbs-up':
      return IconThumbsUp;
    case 'thumbs-down':
      return IconThumbsDown;
    case 'hide':
      return IconHide;
    case 'business_development':
      return IconBusinessDevelopment;
    case 'publishing':
      return IconPublishing;
    case 'job_i_posted':
      return IconJobIPosted;
    case 'payments':
      return IconPayments;
    case 'rocket':
      return IconRocket;
    case 'audio_music':
      return IconAudioMusic;
    case 'visual_effects':
      return IconVisualEffects;
    case 'legal':
      return IconLegal;
    case 'relocation':
      return IconRelocation;
    case 'analytics':
      return IconAnalytics;
    case 'personal_info':
      return IconPersonalInfo;
    case 'product_management':
      return IconProductManagement;
    case 'player_support':
      return IconPlayerSupport;
    case 'tv':
      return IconTv;
    case 'subtract':
      return IconSubtract;
    case 'video_games':
      return IconVideoGamesBenefit;
    case 'group':
      return IconGroup;
    case 'conversation_group':
      return IconConversationGroup;
    case 'warning':
      return IconWarning;
    case 'mask':
      return IconMask;
    case 'attachment':
      return IconAttachment;
    case 'smile':
      return IconSmile;
    case 'pdf':
      return IconPDF;
    case 'word':
      return IconWord;
    case 'excel':
      return IconExcel;

    // Conversation Icons
    case 'sent':
      return IconSent;
    case 'not_sent':
      return IconNotSent;
    case 'seen':
      return IconSeen;
    case 'reverse':
      return IconReverse;
    case 'eclipse':
      return IconEclipse;

    // files icons
    case 'file_pdf':
      return IconPDF;
    case 'file_svg':
      return IconSvg;
    case 'file_image':
      return IconImage;
    case 'file_video':
      return IconVideoFile;
    case 'file_audio':
      return IconAudioFile;
    case 'file_text':
      return IconTextFile;
    case 'file_word':
      return IconWord;
    case 'file_excel':
      return IconExcel;

    case 'file_ppt':
      return IconPPT;
    case 'file_default':
      return IconDefaultFile;
    case 'input_needed_icon':
      return InputNeededIcon;
    case 'request_sent_icon':
      return RequestSentIcon;
    case '404':
      return Page404;
    case 'server-outage':
      return ServerOutage;

    // Welcome Messages Icons
    case 'basic-profile-info':
      return IconBasicProfileInfo;
    case 'write-up':
      return IconWriteUp;
    case 'review-projects':
      return IconReviewProjects;
    case 'assets':
      return IconAssets;
    case 'credits':
      return IconCredits;
    case 'finish':
      return IconFinish;

    default:
      return null;
  }
};

const IconByName = ({ className, icon, onClick, ...rest }) => {
  const SelectedIcon = getIcon(icon);
  if (!SelectedIcon) {
    return null;
  }

  return <SelectedIcon className={className} onClick={onClick} {...rest} />;
};

const StyledIcon = styled(IconByName)`
  width: ${props => props.width || props.size || 16}px;
  height: ${props => props.height || props.size || 16}px;
  min-width: ${props => props.size || 16}px;
  min-height: ${props => props.size || 16}px;
  ${props => (props.cursorPointer ? 'cursor: pointer;' : '')}

  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  ${({ mr }) => mr && `margin-right: ${mr}px;`}

  & path {
    fill: ${props => props.color || (!props.noFill && 'white')};
    ${props => props.icon === 'publishing' && `stroke: ${props.color};`}
  }

  &:hover {
    & path {
      fill: ${props => props.hoverColor || props.color || (!props.noFill && 'white')};
      ${props => props.icon === 'publishing' && `stroke: ${props.hoverColor || props.color || 'white'};`}
    }
  }

  ${({ color, icon }) =>
    icon === 'loader' &&
    `
    animation: spin 1s linear infinite;

    & path {
      fill: ${color || '#7266EE'};
    }
  
    &:hover {
      & path {
        fill: ${color || '#7266EE'};
      }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
  `}
`;

const Icon = props => <StyledIcon {...props} />;

export default withOpenPortal(Icon);
