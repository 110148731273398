import styled from '@emotion/styled';

export const Wrapper = styled.div`
  & .quill-container {
    min-height: 100px;
  }

  & .limit {
    text-align: right;
    color: rgba(229, 234, 255, 0.35);
    font-size: 13px;
  }
`;
