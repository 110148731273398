export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const SET_NOTIFICATIONS_PAGINATION = 'SET_NOTIFICATIONS_PAGINATION';

export const SET_UNSEEN_NOTIFICATIONS_COUNT = 'SET_UNSEEN_NOTIFICATIONS_COUNT';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ';

export const SET_NOTIFICATIONS_AS_SEEN = 'SET_NOTIFICATIONS_AS_SEEN';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
