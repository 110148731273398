import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useAPICall from 'hooks/useAPICall';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';

import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import { profileActions, profileSelectors } from 'store/profile';
import useProfilePath from 'hooks/useProfilePath';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const UnpublishProjectModal = ({ closePortal, data, name }) => {
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const history = useHistory();
  const { myProfilePath } = useProfilePath();
  const { openSuccessSnackbar } = useSuccessSnackbar();

  const { callApi, loading } = useAPICall({
    url: `/project/${profileId}/project/${data.projectId}`,
    method: 'put',
    name: 'unpublish-project',
    transformData: response => response.data,
    optimisticUpdater: updatedProject =>
      profileActions.updateProfileProperty({
        property: 'projects',
        updateType: 'update',
        value: updatedProject,
        profileId
      }),
    onSuccess: updatedProject => {
      if (updatedProject.status === 'draft') {
        openSuccessSnackbar('The draft has been saved');
      }
      closePortal();
      history.push(`${myProfilePath}/portfolio`);
    }
  });

  const togglePublish = () => {
    callApi(data.status === 'draft' ? { published: true, status: 'published' } : { published: false, status: 'draft' });
  };

  return (
    <TxplModal
      title={data.status === 'draft' ? 'Publish this project?' : 'Unpublish this project?'}
      name={name}
      appElement={document.getElementById('root')}
      hideCloseIcon
      size="small"
      nonFullScreenOnMobile
      renderFooter={
        <StyledButtonsContainer>
          {loading && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="secondary">
            Cancel
          </ContainedButton>
          <ContainedButton onClick={togglePublish}>{data.status === 'draft' ? 'Publish' : 'Unpublish'}</ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <Typography variant="medium">
        {data.status === 'draft'
          ? 'Are you sure you want to publish the project?'
          : 'Are you sure you want to unpublish the project? It will be kept as a draft.'}
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('unpublish-project-modal')(UnpublishProjectModal);
