import jobService from 'api/jobService';
import { mapJobs } from 'pages/jobs/details/helpers/mapJobs';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions, jobsSelectors } from 'store/jobs';
import { profileSelectors } from 'store/profile';

const useJobsIPosted = status => {
  const jobs = useSelector(jobsSelectors.selectMyJobs(`${status}Jobs`));
  const { hasMore } = useSelector(jobsSelectors.selectMyJobsPagination(`${status}Jobs`));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);

  const getJobsIPosted = useCallback(
    async (profileId, jobStatus, skip = 0, callback = () => ({}), limit = 6) => {
      if (!isLoading && profileId !== undefined && hasMore) {
        try {
          const res = await jobService.getMyJobs(profileId, jobStatus, skip, limit);
          const { data, pagination } = res.data;

          dispatch(
            jobActions.updateJob({
              jobType: `${jobStatus}Jobs`,
              profileId,
              jobs: mapJobs(data, myProfileId)
            })
          );
          dispatch(
            jobActions.updateJobPagination({
              jobType: `${jobStatus}Jobs`,
              profileId,
              pagination
            })
          );
        } catch {
          dispatch(
            jobActions.updateJobPagination({
              jobType: `${jobStatus}Jobs`,
              profileId,
              pagination: {
                hasMore: false,
                skip,
                limit
              }
            })
          );
        } finally {
          callback && callback();
          setIsLoading(false);
        }
      }
    },
    [isLoading, jobs, dispatch]
  );

  return {
    jobsIPosted: jobs,
    isLoadingJobsIPosted: isLoading,
    hasMoreJobsIPosted: hasMore,
    getJobsIPosted
  };
};

export default useJobsIPosted;
