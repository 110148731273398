import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';

import useQuery from 'hooks/useQuery';
import VettingStatus from './VettingStatus';

const format = date => moment(date).format('MMM DD YYYY');

const BackOfficeSoloistFunnelLog = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useQuery({
    url: `/backoffice/talents/${params.userId}`,
    onSuccess: talent => {
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'OPERATIONS' },
          { label: 'SOLOIST ACCOUNTS', url: '/backoffice/operations/soloists' },
          {
            label: `${talent.talent.userId.firstName} ${talent.talent.userId.lastName}`,
            url: `/backoffice/operations/soloists/${talent.talent._id}`
          },
          { label: 'Funnel Log' }
        ])
      );
    }
  });

  return (
    <div>
      <Table keyId="_id" endpoint={`/backoffice/accounts/${params.userId}/funnel-log`} dataKey="data">
        <Column
          title="STATUS CHANGED TO"
          keyName="status"
          render={vettingLog => <VettingStatus status={vettingLog.status} />}
          width="130px"
        />
        <Column title="CHANGED ON" keyName="createdAt" render={vettingLog => format(vettingLog.createdAt)} />
        <Column
          title="CHANGED BY"
          keyName="actionUserId"
          render={vettingLog => `${vettingLog.actionUserId.firstName} ${vettingLog.actionUserId.lastName}`}
        />
        <Column title="Reason" keyName="reason" />
        <Column title="ADDITIONAL COMMENTS" keyName="additionalComment" />
      </Table>
    </div>
  );
};

export default BackOfficeSoloistFunnelLog;
