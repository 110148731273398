import SliderArrow from 'common/SliderArrow';
import React, { useState } from 'react';
import * as Styled from './StyledImageGallery';
import useViewport from 'hooks/useViewport';

const ImageGallery = ({ images, selectedImage, setSelectedImage }) => {
  const [slider, setSlider] = useState();
  const [navSlider, setNavSlider] = useState();
  const { isXS } = useViewport();

  const handleImageClick = i => {
    const newSelectedImage = {
      ...images[i].attachment,
      fileName: images[i].custom.file.fileName,
      fileType: images[i].custom.file.fileType
    };
    setSelectedImage(newSelectedImage);
  };

  const selectedImageIndex = images.indexOf(
    images.find(image => image.custom.file.fileName === selectedImage.fileName)
  );

  const sliderSettings = {
    initialSlide: selectedImageIndex,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: navSlider,
    ref: slider => setSlider(slider),
    afterChange: i => handleImageClick(i),
    nextArrow: <SliderArrow icon="arrow-right" isXS={isXS} modified />,
    prevArrow: <SliderArrow icon="arrow-left" isXS={isXS} modified />
  };

  const navSliderSettings = {
    initialSlide: selectedImageIndex,
    swipeToSlide: true,
    infinite: images.length > 8,
    focusOnSelect: true,
    slidesToShow: 8,
    responsive: [
      {
        breakpoint: 1149,
        settings: {
          slidesToShow: 7
        }
      }
    ],
    slidesToScroll: 1,
    asNavFor: slider,
    ref: navSlider => setNavSlider(navSlider)
  };

  const renderImage = image => (
    <Styled.ImageContainer notXS={!isXS} key={image.id}>
      <img src={image.attachment.url} alt="" />
    </Styled.ImageContainer>
  );

  const renderNavImage = image => (
    <Styled.ImageItem notXS={!isXS} key={image.id}>
      <img src={image.attachment.url} alt="" />
    </Styled.ImageItem>
  );

  return (
    <Styled.ImageListWrapper>
      <Styled.StyledSlider {...sliderSettings} isXS={isXS}>
        {images.map(image => renderImage(image))}
      </Styled.StyledSlider>
      {!isXS && (
        <Styled.StyledNavSlider {...navSliderSettings}>
          {images.map(image => renderNavImage(image))}
        </Styled.StyledNavSlider>
      )}
    </Styled.ImageListWrapper>
  );
};

export default ImageGallery;
