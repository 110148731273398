import React from 'react';
import styled from '@emotion/styled';

import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import useForm from 'hooks/useForm';
import { backOfficeService } from 'api';
import Spinner from 'common/Spinner';
import { roles } from '../../../constants';
import { portalActions } from '../../../store/portals';
import { useDispatch } from 'react-redux';

const StyledForm = styled.div`
  display: grid;
  grid-row-gap: 40px;
`;

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const fields = {
  firstName: { initialValue: '', validate: ['isRequired'] },
  lastName: { initialValue: '', validate: ['isRequired'] },
  email: { initialValue: '', validate: ['isRequired', 'isEmail'] },
  permission: { initialValue: '', validate: ['isRequired'] }
};

const BackofficeAddCompanyUserModal = ({ closePortal, data, name, refetch }) => {
  const dispatch = useDispatch();

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: params =>
      backOfficeService
        .inviteNewMember({ ...params, role: 'member' })
        .then(({ data: { user } }) =>
          backOfficeService.addMemberInCompany(data.data.companyId, { user: user.email, permission: params.permission })
        ),
    onSubmitSuccess: () => {
      refetch();
      closePortal();

      dispatch(
        portalActions.openPortal({
          name: 'user-invited-success'
        })
      );
    }
  });

  const close = () => {
    closePortal();
    dispatch(
      portalActions.openPortal({
        name: 'add-company-member-modal'
      })
    );
  };

  return (
    <TxplModal
      title="Add company member"
      name={name}
      onClose={close}
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}

          <ContainedButton backgroundColor="secondary" onClick={close} opens="add-company-member-modal">
            Cancel
          </ContainedButton>

          <ContainedButton onClick={onSubmit}>Add User</ContainedButton>
        </StyledActionsContainer>
      }
    >
      <StyledForm>
        <FormInput
          label="First Name"
          value={values.firstName}
          onChange={onFieldChange('firstName')}
          error={errors.firstName}
        />

        <FormInput
          label="Last Name"
          value={values.lastName}
          onChange={onFieldChange('lastName')}
          error={errors.lastName}
        />

        <FormInput label="Email" value={values.email} onChange={onFieldChange('email')} error={errors.email} />

        <FormSelect
          label="Permission"
          value={roles.find(role => role.value === values.permission)}
          options={roles}
          onChange={option => onFieldChange('permission')(option.value)}
          error={errors.permission}
        />
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('add-company-user-modal')(BackofficeAddCompanyUserModal);
