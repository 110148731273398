import React from 'react';
import styled from '@emotion/styled';

const StyledVerificationStatus = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledStatusIcon = styled.div`
  display: inline-flex;
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: white;
  ${props => (props.color ? `background-color: ${props.color};` : '')}
`;

const StyledStatusText = styled.span`
  text-transform: capitalize;
`;

const colorAndStatusByVerification = {
  verified: {
    color: '#26FFB1',
    status: 'Verified'
  },
  failed: {
    color: '#FF5151;',
    status: 'Failed'
  },
  requires_input: {
    color: '#FF5151;',
    status: 'Failed'
  },
  processing: {
    color: '#FFE040;',
    status: 'Processing'
  },
  pending: {
    color: '#FFE040;',
    status: 'Processing'
  },
  'stripe-response': {
    color: '#FFE040;',
    status: 'In Progress, waiting Stripe response'
  },
  'support-needed': {
    color: '#FFE040;',
    status: 'In Progress, Support needed'
  },
  reset: {
    color: '#FFE040;',
    status: 'Verification Reset'
  },
  compromised: {
    color: '#FFE040;',
    status: 'Compromised'
  },
  'not-started': {
    color: '#FFE040;',
    status: 'Not Started'
  },
  created: {
    color: 'rgba(230, 234, 255, 0.35)',
    status: 'Created'
  },
  'failed-retry': {
    color: '#FF5151;',
    status: 'Failed, waiting for User to retry'
  }
};

const VerificationStatus = ({ verification, verificationStatus }) => {
  const { color, status } = React.useMemo(
    () =>
      colorAndStatusByVerification[verificationStatus || verification?.status] || {
        color: 'rgba(230, 234, 255, 0.35);',
        status: 'Not Started'
      },
    [verification, verificationStatus]
  );

  return (
    <StyledVerificationStatus>
      <StyledStatusIcon color={color} />
      <StyledStatusText>{status}</StyledStatusText>
    </StyledVerificationStatus>
  );
};

export default VerificationStatus;
