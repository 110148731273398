import styled from '@emotion/styled';
import { pageSize } from '../../../../utils/devices';

export const DetailsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 6px;
  row-gap: 6px;
`;

export const DisciplinesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const DetailsListItem = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(204, 212, 255, 0.11);
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: rgba(242, 244, 255, 0.9);
  font-size: 16px;
  justify-content: flex-start;
  flex: 0 0 49%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.discipline {
    margin-bottom: 0;
  }

  @media ${pageSize.XS} {
    flex: 0 0 100%;
  }

  ${props => props.bg && `border: none; background: ${props.bg}`}
`;

export const DetailsListItemText = styled.div`
  margin-left: 14px;
`;

export const DisDetailsListItemText = styled.div`
  margin-left: 8px;
  color: #ffffff;
`;

export const FormWrapper = styled.form`
  margin-top: 40px;
`;
