import * as authActionTypes from './authActionTypes';

export const login = user => ({
  type: authActionTypes.LOGIN,
  payload: {
    user
  }
});

export const logout = () => ({
  type: authActionTypes.LOGOUT
});

export const updateCurrentUserProperty = ({ property, value }) => ({
  type: authActionTypes.UPDATE_CURRENT_USER_PROPERTY,
  payload: {
    property,
    value
  }
});

export const signup = user => ({
  type: authActionTypes.SIGNUP,
  payload: {
    user
  }
});
