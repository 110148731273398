import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import some from 'lodash/fp/some';
import filter from 'lodash/fp/filter';

import { portalActions } from 'store/portals';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import commonStyles from 'utils/common-styles';

import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import FormInput from 'common/form/FormInput';
import Spinner from 'common/Spinner';
import { companyProfileService, talentService } from 'api';

import Typography from 'components/shared/Typography';
import FormSelect from 'common/form/FormSelect';
import { profileActions, profileSelectors } from 'store/profile';
import { selectTags } from 'store/tags/tagSelectors';
import CreditReferenceCard from './CreditReferenceCard';
import AddCreditReference from './AddCreditReference';

const getTags = type => createSelector([selectTags], tags => filter(tag => tag.type === type)(tags));

const EmailsText = styled.div`
  ${commonStyles.ui_text_medium}
  margin-bottom: 40px;
`;

const Spacer = styled.div`
  height: 0px;
  border: 1px solid rgba(204, 213, 255, 0.11);
  margin: 56px 0px;
`;

const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const fields = ({ gameId, name, role }) => ({
  gameId: { initialValue: gameId },
  name: { initialValue: name, validate: ['isRequired'] },
  role: { initialValue: role, validate: ['isRequired'] },
  references: { initialValue: [] }
});

const onCustomValidate = (formFields, existingCredits) => {
  // const errors = formFields.getIn(['references', 'value']).size < 3 ?
  //   { 'references': 'Please provide at least 3 references' } :
  //   {}
  const errors = {};

  const gameId = formFields.getIn(['gameId', 'value']);
  const role = formFields.getIn(['role', 'value']);

  const exists = some(
    credit =>
      parseInt(credit.game?._id, 10) === parseInt(gameId, 10) && credit.role.toLowerCase() === role.toLowerCase(),
    existingCredits
  );

  if (exists) errors.role = 'You have already claimed this credit.';

  return errors;
};

const EditCreditRoleModal = ({ closePortal, data, name, onAdd }) => {
  const [referenceOpen, setReferenceOpen] = React.useState(false);
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const existingCredits = useSelector(profileSelectors.selectProfileProperty('credits'));
  const rolesTags = useSelector(getTags('role'));
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);

  const callApi = params => {
    const service = isCompanyView ? companyProfileService.createCredit : talentService.createCredit;

    return service(profileId, params).then(response => {
      dispatch(
        profileActions.updateProfileProperty({
          property: 'credits',
          updateType: 'insert',
          value: response.data.data,
          profileId
        })
      );

      return response;
    });
  };

  const onSubmitSuccess = params => {
    closePortal();
    dispatch(
      portalActions.openPortal({
        name: 'claimed-credit-modal',
        data: {
          gameName: params.name
        }
      })
    );
  };

  const { errors, onFieldChange, onFieldInsert, onSubmit, submitting, values } = useForm({
    fields: fields(data),
    onSubmitSuccess,
    onValidate: formFields => onCustomValidate(formFields, existingCredits),
    callApi: onAdd || callApi
  });

  const handleAddCreditReference = reference => {
    setReferenceOpen(false);
    onFieldInsert('references')(reference);
  };

  const onReferenceCustomValidate = formFields => {
    const email = formFields.getIn(['email', 'value']);
    const alreadyExists = values.references.find(item => item.email === email);

    return alreadyExists ? { email: `${email} already exists` } : {};
  };

  return (
    <TxplModal
      title="Credits"
      subTitle="Claim credits for games you helped ship, then give yourself a standing ovation."
      appElement={document.getElementById('root-modal')}
      name={name}
      renderFooter={
        <StyledButtonsContainer>
          {submitting && <Spinner />}
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={onSubmit}>Claim Credits</ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <form onSubmit={onSubmit}>
        <FormInput
          label="Official game title"
          required
          error={errors.name}
          value={values.name}
          onChange={onFieldChange('name')}
          mb={24}
        />

        <FormSelect
          label="Your role in this project"
          required
          error={errors.role}
          value={rolesTags.map(curr => ({ label: curr.name, value: curr._id })).find(tag => tag.value === values.role)}
          options={rolesTags.map(curr => ({ label: curr.name, value: curr._id }))}
          onChange={option => onFieldChange('role')(option.value)}
        />

        <Spacer />

        <EmailsText>
          We use industry databases to vet credits. As a fallback, you can give us a list of 3-5 people who can confirm
          your role for this project.
        </EmailsText>

        {errors.references && (
          <Typography variant="small" error>
            {errors.references}
          </Typography>
        )}

        {values.references.map(reference => (
          <CreditReferenceCard
            key={reference.email}
            reference={reference}
            handleDelete={currRef =>
              onFieldChange('references')(values.references.filter(item => item.email !== currRef.email))
            }
          />
        ))}

        {!referenceOpen && (
          <ContainedButton
            type="button"
            onClick={() => setReferenceOpen(true)}
            icon="plus"
            backgroundColor="rgba(204, 213, 255, 0.11);"
          >
            ADD A REFERENCE
          </ContainedButton>
        )}

        {referenceOpen && (
          <AddCreditReference onValidate={onReferenceCustomValidate} onAdd={handleAddCreditReference} />
        )}
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('edit-credit-modal')(EditCreditRoleModal);
