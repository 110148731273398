import React from 'react';

import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';

const StyledAddFirstExperienceContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px 40px;
  margin-bottom: 54px;
`;

const StyledDescription = styled.p`
  ${commonStyles.ui_text_medium}
  margin: 32px 0 0;
  width: 295px;
  text-align: center;
`;

const CreateFirstArtService = () => (
  <StyledAddFirstExperienceContainer>
    <Icon icon="services" color="rgba(221, 226, 255, 0.2)" size={64} />
    <StyledDescription>Add services to share details on what you do and what your specialties are</StyledDescription>
    <ContainedButton opens="create-service-modal" icon="plus" mt={24} forceDisplayText>
      Add Service
    </ContainedButton>
  </StyledAddFirstExperienceContainer>
);

export default CreateFirstArtService;
