import Api from './api';

const api = new Api('talents');
const projectApi = new Api('project');
const experienceApi = new Api('experience');

const setTags = (talentId, data) => api.post(`/${talentId}/tags`, data);
const setToolkit = (talentId, data) => api.post(`/${talentId}/toolkit`, data);
const beginQuickOnboarding = talentId => api.post(`/${talentId}/quick-onboarding`);

const getTalentByUserId = userId => api.get(`/by-user/${userId}`);

const getTalent = talentId => api.get(`/${talentId}`);

const getTalentPreview = talentId => api.get(`/${talentId}/preview`);
const getTalentAchievements = talentId => api.get(`/${talentId}/achievements`);

const search = (talentId, query, page, limit) =>
  api({
    url: `/${talentId}/search?${query}`,
    method: 'GET',
    params: {
      page,
      limit
    }
  });

const setAboutExperience = (talentId, data) => api.post(`/${talentId}/about-experience`, data);

const setAboutRates = (talentId, data) => api.post(`/${talentId}/about-rates`, data);

const setAboutText = (talentId, data) => api.post(`/${talentId}/about-text`, data);

const setAboutKeywords = (talentId, data) => api.post(`/${talentId}/about-text`, data);

const deleteTalentCredit = (talentId, creditId) => api.delete(`/${talentId}/credit/${creditId}`);

const saveTalentService = params => api.post('/services', params);

const updateTalentService = ({ _id, ...rest }) => api.put(`/services/${_id}`, rest);

const deleteTalentService = (talentId, serviceId) => api.delete(`/${talentId}/${serviceId}`);

const createCredit = (talentId, data) => api.post(`/${talentId}/credit`, data);

const updateCredit = (talentId, creditId, data) => api.put(`/${talentId}/credit/${creditId}`, data);

const updateCreditSort = (talentId, data) => api.put(`/${talentId}/credit`, data);

const updateProfile = (talentId, data) => api.put(`/${talentId}/profile`, data);

const createMediaCoverage = (talentId, data) => api.post(`/${talentId}/media-coverage`, data);

const updateMediaCoverage = (talentId, data) => api.put(`/${talentId}/media-coverage/${data._id}`, data);

const updateMediaCoverageSort = (talentId, data) => api.put(`/${talentId}/media-coverage`, data);

const updatePortfolioSort = (talentId, data) => api.put(`/${talentId}/portfolio`, data);

const updateExperienceSort = (talentId, data) => api.put(`/${talentId}/experience`, data);

const createExperience = (talentId, data) => experienceApi.post(`/${talentId}/experience`, data);

const setTalentDisplay = (talentId, creditId, data) => api.post(`/${talentId}/credit/${creditId}/display`, data);

const addProject = (talentId, formData) =>
  projectApi.post(`/${talentId}/add`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const updateProject = (talentId, projectId, formData) =>
  projectApi.put(`/${talentId}/${projectId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const publishProject = (talentId, formData) =>
  projectApi.post(`/${talentId}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const setTalentBasicInfo = (talentId, formData) =>
  api.post(`/${talentId}/basic-info`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const setCoverImage = (talentId, formData) =>
  api.post(`/${talentId}/cover-image`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' }
  });

const deleteCoverImage = talentId => api.delete(`/${talentId}/cover-image`);

const deleteMediaCoverage = (talentId, mediaId) => api.delete(`/${talentId}/media-coverage/${mediaId}`);

const addRemoveFavorites = (talentId, data) => api.put(`/${talentId}/favorites`, data);

const getSavedSearches = (talentId, page, limit) =>
  api.get(`/${talentId}/saved-search/jobs?limit=${limit}&page=${page}`);

const deleteSavedSearch = (talentId, searchId) => api.delete(`/${talentId}/saved-search/jobs/${searchId}`);

const getRecentSearches = (talentId, page, limit) =>
  api.get(`/${talentId}/recent-search/jobs?limit=${limit}&page=${page}`);

const deleteRecentSearch = (talentId, searchId) => api.delete(`/${talentId}/recent-search/jobs/${searchId}`);

const getRecommendedJobs = (talentId, page, limit, sort) =>
  api.get(`/${talentId}/recommended-search/jobs?limit=${limit}&page=${page}&sort=${sort}`);

const updateTalentServices = (talentId, data) => api.post(`/${talentId}/set-services`, { services: data });

const checkTalent = query => api.get(`/check?${query}`);

const requestSupplyRoleChange = talentId => api.post(`/${talentId}/supply-role-request`);
const requestDemandRoleChange = talentId => api.post(`/${talentId}/demand-role-request`);

export default {
  addProject,
  addRemoveFavorites,
  beginQuickOnboarding,
  checkTalent,
  createCredit,
  createExperience,
  createMediaCoverage,
  deleteCoverImage,
  deleteMediaCoverage,
  deleteRecentSearch,
  deleteSavedSearch,
  deleteTalentCredit,
  deleteTalentService,
  getRecentSearches,
  getRecommendedJobs,
  getSavedSearches,
  getTalent,
  getTalentAchievements,
  getTalentByUserId,
  getTalentPreview,
  publishProject,
  requestDemandRoleChange,
  requestSupplyRoleChange,
  saveTalentService,
  search,
  setAboutExperience,
  setAboutKeywords,
  setAboutRates,
  setAboutText,
  setCoverImage,
  setTags,
  setTalentBasicInfo,
  setTalentDisplay,
  setToolkit,
  updateCredit,
  updateCreditSort,
  updateExperienceSort,
  updateMediaCoverage,
  updateMediaCoverageSort,
  updatePortfolioSort,
  updateProfile,
  updateProject,
  updateTalentService,
  updateTalentServices
};
