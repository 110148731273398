import styled from '@emotion/styled';

export const StyledCollaborators = styled.div``;

export const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
`;

export const StyledShowMoreContainer = styled.div`
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 12px;
  padding: 18px;
  cursor: pointer;
`;
