import * as notificationActionTypes from './notificationActionTypes';

export const addNotification = ({ notification, profileId }) => ({
  type: notificationActionTypes.ADD_NOTIFICATION,
  payload: {
    profileId,
    notification
  }
});

export const setNotifications = ({ notificationType, notifications, profileId }) => ({
  type: notificationActionTypes.SET_NOTIFICATIONS,
  payload: {
    profileId,
    notifications,
    notificationType
  }
});

export const setNotificationPagination = ({ notificationType, pagination, profileId }) => ({
  type: notificationActionTypes.SET_NOTIFICATIONS_PAGINATION,
  payload: {
    profileId,
    pagination,
    notificationType
  }
});

export const setUnseenNotificationsCount = ({
  profileId,
  unseenCommunityCount,
  unseenJobsCount,
  unseenProfileCount
}) => ({
  type: notificationActionTypes.SET_UNSEEN_NOTIFICATIONS_COUNT,
  payload: {
    profileId,
    unseenCommunityCount,
    unseenJobsCount,
    unseenProfileCount
  }
});

export const setNotificationAsRead = ({ notificationId, profileId, type }) => ({
  type: notificationActionTypes.SET_NOTIFICATION_AS_READ,
  payload: {
    profileId,
    notificationId,
    type
  }
});

export const setNotificationsAsSeen = ({ profileId }) => ({
  type: notificationActionTypes.SET_NOTIFICATIONS_AS_SEEN,
  payload: {
    profileId
  }
});

export const deleteNotification = ({ notification, profileId }) => ({
  type: notificationActionTypes.DELETE_NOTIFICATION,
  payload: {
    profileId,
    notification
  }
});

export const clearNotifications = () => ({
  type: notificationActionTypes.CLEAR_NOTIFICATIONS
});
