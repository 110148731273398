import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';

import { isValidEmail } from 'utils';
import { backOfficeActions } from 'store/backOffice';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import { backOfficeService } from 'api';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;

const permissionOptions = [
  { value: 'super-editor', label: 'super-editor' },
  { value: 'super-admin', label: 'super-admin' },
  { value: 'analyst', label: 'analyst' }
];

const BackOfficeMembersNew = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [permission, setPermission] = useState('');

  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [permissionError, setPermissionError] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      backOfficeActions.updateBackOfficeTitle([
        { label: 'Admin Panel' },
        { label: 'Back Office Members', url: '/backoffice/admin-panel/members' },
        { label: 'Create new member' }
      ])
    );
  }, [dispatch]);

  const clearErrors = () => {
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
    setPermissionError('');
    setPasswordError('');
  };

  const formIsValid = () => {
    if (!email) {
      setEmailError('Please enter email');
      return false;
    }
    if (!isValidEmail(email)) {
      setEmailError('Email is not valid');
      return false;
    }
    if (!firstName) {
      setFirstNameError('Please enter first name');
      return false;
    }
    if (!lastName) {
      setLastNameError('Please enter last name');
      return false;
    }
    if (!permission) {
      setPermissionError('Please select permission');
      return false;
    }
    if (!password) {
      setPassword('Please input password');
      return false;
    }

    // Form is OK
    return true;
  };

  const handleSubmit = e => {
    clearErrors();
    e.preventDefault();
    if (!formIsValid()) return false;

    backOfficeService
      .createBackOfficeMember({ email, firstName, lastName, password, roles: [{ role: permission.value }] })
      .then(response => {
        const responseStatus = get('data.status', response);
        if (responseStatus === 'OK') {
          history.push('/backoffice/admin-panel/members');
        }

        if (responseStatus === 'ERROR') {
          const errorMessage = get('data.message', response);
          const errorField = get('data.field', response);
          switch (errorField) {
            case 'email':
              setEmailError(errorMessage);
              break;
            case 'firstName':
              setFirstNameError(errorMessage);
              break;
            case 'lastName':
              setLastNameError(errorMessage);
              break;
            case 'permission':
              setPermissionError(errorMessage);
              break;
            case 'password':
              setPasswordError(errorMessage);
              break;

            default:
              setFirstNameError(errorMessage);
              break;
          }
        }
      })
      .catch(err => {
        console.log('error: ', err);
        const errorMessage = get('response.data.message', err);
        if (errorMessage === 'Email already exists.') {
          setEmailError(errorMessage);
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px' }}>
        <FormInput label="Email" value={email} onChange={e => setEmail(e.target.value)} error={emailError} />
        <FormInput
          label="First Name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          error={firstNameError}
        />
        <FormInput
          label="Last Name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          error={lastNameError}
        />
        <FormInput
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={passwordError}
        />
        <FormSelect
          label="Permission"
          value={permission}
          options={permissionOptions}
          error={permissionError}
          onChange={val => setPermission(val)}
        />
        <SubmitRow>
          <ContainedButton type="submit" forceDisplayText>
            Create Account
          </ContainedButton>
        </SubmitRow>
      </form>
    </>
  );
};

export default BackOfficeMembersNew;
