import { createSelector } from 'reselect';

const selectDomain = state => state.user;
const selectCurrentUserDomain = state => state.currentUser;

export const selectUser = createSelector([selectDomain], user => user);

export const selectUserProperty = property => createSelector([selectDomain], user => user[property]);

export const selectCurrentUserProperty = property => createSelector([selectCurrentUserDomain], user => user[property]);
