import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { currentUserNameSelector, isLoggedInSelector } from 'store/selectors/currentUser';
import { pageSize } from 'utils/devices';

import SettingsMenuItem, { StyledAnchor, StyledIcon, Text } from './SettingsMenuItem';
import { selectCurrentUserProperty, selectIsAuthLoading } from '../../store/auth/authSelectors';
import { companyProfileService } from '../../api';
import talentService from '../../api/talentService';
import { selectIsMyProfileCompany, selectMyProfileId } from 'store/profile/profileSelectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: #1a1b1e;
  border-radius: 16px;
  padding: 12px;

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    width: 240px;
  }

  @media ${pageSize.XS}, ${pageSize.S} {
    flex-direction: row;
    margin-bottom: 12px;
    overflow: auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const WideScreen = styled.div``;

const SettingsMenu = () => {
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const isCompany = useSelector(selectIsMyProfileCompany);
  const myProfileId = useSelector(selectMyProfileId);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const [userName, setUserName] = useState(useSelector(currentUserNameSelector));
  const [imageUri, setImageUri] = useState(useSelector(selectCurrentUserProperty('imageUri')));

  useEffect(() => {
    if (!isAuthLoading && myProfileId !== undefined) {
      isCompany ? loadCompany(myProfileId) : loadUser(myProfileId);
    }
  }, [isAuthLoading, myProfileId]);

  const loadCompany = profileId => {
    companyProfileService
      .getCompany(profileId)
      .then(response => {
        setUserName(response.data.data.name);
        setImageUri(
          response.data.data.imageUri?.url || `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`
        );
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  const loadUser = profileId => {
    talentService
      .getTalent(profileId)
      .then(response => {
        setUserName(response.data.data.name);
        setImageUri(
          response.data.data.basicInfo?.imageUri?.url || `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`
        );
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  return (
    <>
      <WideScreen>
        <Wrapper>
          {isLoggedIn && (
            <>
              <SettingsMenuItem
                key="/settings/profile"
                menuItem={{
                  key: 'profile',
                  label: userName,
                  url: '/settings/profile',
                  img: imageUri
                }}
              />
              {isCompany ? (
                <SettingsMenuItem
                  key="/settings/billing-information"
                  menuItem={{
                    key: 'settings',
                    label: 'Billing information',
                    url: '/settings/billing-information',
                    icon: 'settings'
                  }}
                />
              ) : (
                <StyledAnchor href={`${process.env.REACT_APP_NEXT_BASE_URL}/settings`}>
                  <StyledIcon
                    className="icon"
                    color="rgba(230, 234, 255, 0.6)"
                    hoverColor="rgba(230, 234, 255, 0.6)"
                    icon="settings"
                  />
                  <Text>Billing information</Text>
                </StyledAnchor>
              )}
              <SettingsMenuItem
                key="/settings/account-settings"
                menuItem={{
                  key: 'settings',
                  label: 'User settings',
                  url: '/settings/account-settings',
                  icon: 'about'
                }}
              />
            </>
          )}
        </Wrapper>
      </WideScreen>
    </>
  );
};

export default SettingsMenu;
