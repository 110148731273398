import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment-timezone';

import Icon from 'common/Icon';
import Typography from './shared/Typography';

const StyledLocationContainer = styled.div`
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeadOfficeContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 16px auto;
  grid-column-gap: 6px;
  background-color: #a4bfd8;
  border-radius: 8px;
  max-width: 155px;
  align-items: center;
  padding: 2px 6px;
  margin-bottom: 16px;
`;

const StyledTypography = styled(Typography)`
  color: #111112;
`;
const ReStyledTypography = styled(Typography)`
  font-weight: 700;
`;

const StyledWorkingHoursLabel = styled(Typography)`
  color: rgba(230, 234, 255, 0.35);
  display: block;
`;
const LocationMainInfoWrapper = styled.div`
  flex: 2;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const WorkingHoursWrapper = styled.div`
  p {
    ${props => props.empty && 'opacity: .5; font-style: italic'}
  }
`;

const Location = ({ location }) => {
  const renderWorkingHours = ({ timezone, workDayEnd, workDayStart }) => (
    <>
      {moment(workDayStart || '', 'HH').format('h a')}-{moment(workDayEnd || '', 'HH').format('h a')}{' '}
      {timezone?.split('(').reverse()[0].slice(0, -1)}
    </>
  );

  return (
    <StyledLocationContainer>
      <LocationMainInfoWrapper>
        {location.isHeadOffice && (
          <StyledHeadOfficeContainer>
            <Icon color="#111112" icon="location" />
            <StyledTypography variant="medium">Primary location</StyledTypography>
          </StyledHeadOfficeContainer>
        )}

        <ReStyledTypography variant="h3">{location.officeAddress}</ReStyledTypography>
      </LocationMainInfoWrapper>

      <WorkingHoursWrapper empty={!location.workDayStart || !location.workDayEnd}>
        <StyledWorkingHoursLabel variant="caption" mb={8}>
          Working Hours
        </StyledWorkingHoursLabel>
        <Typography variant="medium">
          {location.workDayStart && location.workDayEnd ? renderWorkingHours(location) : 'N/A'}
        </Typography>
      </WorkingHoursWrapper>
    </StyledLocationContainer>
  );
};

export default Location;
