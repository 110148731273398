import Api from './api';

const api = new Api('backoffice');

const setAccountStatus = (accountId, data) => api.post(`/accounts/${accountId}/status`, data);

const setAccountFunnelStatus = (accountId, data) => api.post(`/accounts/${accountId}/funnel-status`, data);

const setAccountVerificationStatus = (accountId, data) =>
  api.post(`/accounts/${accountId}/verification-status-log`, data);

const setAccountVettingStatus = (accountId, data) => api.post(`/accounts/${accountId}/vetting-status`, data);

const getUsersByRole = role => api.get(`/users/${role}`);

const setAccountFlagStatus = (accountId, data) => api.post(`/accounts/${accountId}/flag-status`, data);

const setCompanyAccountFlagStatus = (accountId, data) => api.post(`/company/${accountId}/flag-status`, data);

const updateUserInfo = (userId, data) => api.post(`/users/${userId}/user-info`, data);

const updateCompanyActivityStatus = (userId, data) => api.post(`/users/${userId}/company-status`, data);

const updateCompanyInfo = (userId, data) => api.post(`/users/${userId}/company-info`, data);

const createNewUser = data => api.post('/newUser', data);

const resetUserPassword = (userId, data) => api.post(`/users/${userId}/reset-password`, data);

const setBackOfficeMemberPermission = (userId, data) => api.post(`/backoffice-members/${userId}/permissions`, data);

const getBackOfficeUser = userId => api.get(`/user/${userId}`);

const createBackOfficeMember = data => api.post('/backoffice-memebrs', data);

const deleteUserAccount = userId => api.delete(`/users/${userId}`);
const inviteNewMember = params => api.post('/backoffice-members/invite', params);

const createCompany = data => api.post('/company-profile', data);
const createSoloist = data => api.post('/soloist', data);

const addMemberInCompany = (companyId, data) => api.post(`/company-profile/${companyId}/members`, data);
const updateMemberRoleInCompany = (companyId, data) => api.put(`/company-profile/${companyId}/members`, data);
const deleteMemberFromCompany = (companyId, memberId) =>
  api.delete(`/company-profile/${companyId}/members/${memberId}`);

const getProfileEntities = userId => api.get(`/accounts/${userId}/profile-entities`);
const lockUnlock = (userId, data) => api.put(`/accounts/${userId}/lock-unlock`, data);

const fetchCandidateById = candidateId => {
  const candidatesApi = new Api('candidates');
  return candidatesApi.get(`/${candidateId}`);
};
const backOfficeCandidates = state => api.get(`/candidates${state ? '?rejected=hide' : ''}`);

export default {
  setAccountStatus,
  updateUserInfo,
  updateCompanyActivityStatus,
  updateCompanyInfo,
  createNewUser,
  resetUserPassword,
  setBackOfficeMemberPermission,
  updateMemberRoleInCompany,
  addMemberInCompany,
  getBackOfficeUser,
  inviteNewMember,
  deleteMemberFromCompany,
  createBackOfficeMember,
  createCompany,
  createSoloist,
  deleteUserAccount,
  setAccountFunnelStatus,
  getUsersByRole,
  setAccountVettingStatus,
  setAccountFlagStatus,
  setCompanyAccountFlagStatus,
  setAccountVerificationStatus,
  getProfileEntities,
  lockUnlock,
  fetchCandidateById,
  backOfficeCandidates
};
