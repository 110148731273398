import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const TitleContainer = styled.div`
  margin-right: 13.5px;
  display: flex;
  align-items: center;
`;
export const GoBackIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  background-color: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
  margin-right: 24px;
  @media ${pageSize.XS} {
    margin-right: 8px;
  }
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  flex: 1;
`;
export const Input = styled.input`
  flex: 4;
  background: none;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 16px;
  &:placeholder {
    color: rgba(229, 234, 255, 0.6);
  }
`;

export const ContactListContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 24px;
  top: 57px;
  width: 360px;
  max-height: 192px;
  min-height: 64px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: auto;
  background: #1a1b1e;
  border: 2px solid rgba(204, 212, 255, 0.11);
  padding: 8px 0;

  ${({ noResult }) => noResult && 'justify-content: center;'}
`;

export const ContactListLoader = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #1a1b1e;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(204, 212, 255, 0.05);
  }
`;
export const ContactItemImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ContactItemName = styled.div`
  color: #ffffff;
  font-size: 16px;
  margin-left: 16px;
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
export const NoResultsIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 32px;
  border-radius: 8px;
  background-color: rgba(204, 212, 255, 0.11);
`;
export const NoResultsText = styled.div`
  font-size: 16px;
  margin-left: 16px;
  color: #fff;
`;
