import * as React from 'react';

const AirplaneGreenCheckIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="190" height="190" fill="none" viewBox="0 0 190 190" {...props}>
    <path
      fill="url(#paint0_linear_282_7181)"
      d="M40.329 66.03a8.662 8.662 0 10.116-17.323 8.662 8.662 0 10-.116 17.323z"
    ></path>
    <path
      fill="url(#paint1_linear_282_7181)"
      d="M81.495 66.465a8.662 8.662 0 10.116-17.324 8.662 8.662 0 00-.116 17.324z"
    ></path>
    <g filter="url(#filter0_bi_282_7181)">
      <path
        fill="#BFCAFF"
        fillOpacity="0.1"
        d="M3.366 63.34a5.206 5.206 0 013.43-9.2c1.454.027 35.828 1.027 61.688 29.697a5.2 5.2 0 11-7.72 6.964C37.894 65.496 6.9 64.551 6.593 64.546a5.195 5.195 0 01-3.227-1.206z"
      ></path>
    </g>
    <path fill="#00410D" d="M81.23 145.081l-22.116 30.624 5.011-37.955 17.105 7.331z"></path>
    <path
      fill="url(#paint2_linear_282_7181)"
      d="M134.294 167.145L8.92 115.338c-3.643-1.505-3.973-6.537-.558-8.505l149.262-86.028c3.462-1.995 7.69.915 7.063 4.861l-21.719 136.62c-.643 4.041-4.892 6.422-8.674 4.859z"
    ></path>
    <path fill="#006614" d="M59.141 175.75l-14.807-45.62 105.291-99.255-85.5 106.875-4.984 38z"></path>
    <g filter="url(#filter1_bi_282_7181)">
      <circle cx="158.333" cy="95" r="31.667" fill="#C7C7FF" fillOpacity="0.21"></circle>
    </g>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="6.333"
      d="M144.372 93.706l10.795 10.795 18.711-18.712"
    ></path>
    <g filter="url(#filter2_bi_282_7181)">
      <path
        fill="#BFCAFF"
        fillOpacity="0.1"
        d="M126.609 36.019a4.416 4.416 0 01.09 3.41c-1.396 3.636-3.531 6.439-6.17 8.098-2.795 1.757-6.023 2.134-8.868 1.045-4.994-1.912-8.346-8.317-5.072-16.858 1.272-3.322.576-5.14.064-5.33-.506-.2-2.24.685-3.515 4.006-1.396 3.636-3.53 6.439-6.172 8.102-2.794 1.757-6.024 2.14-8.867 1.045-4.992-1.92-8.346-8.32-5.069-16.852.927-2.422.762-3.873.577-4.517-.154-.516-.366-.761-.512-.818-.508-.19-2.237.677-3.515 4.007a4.451 4.451 0 01-5.763 2.563 4.444 4.444 0 01-2.861-4.044 4.447 4.447 0 01.297-1.715c3.268-8.533 10.04-11.06 15.038-9.143 5 1.923 8.345 8.322 5.075 16.864-.931 2.42-.758 3.86-.575 4.508.15.515.365.766.507.822.512.195 2.238-.682 3.516-4.011 3.273-8.533 10.041-11.06 15.038-9.143 4.993 1.916 8.347 8.317 5.069 16.852-.927 2.422-.756 3.87-.577 4.517.157.521.37.762.512.818.507.194 2.238-.681 3.515-4.007a4.462 4.462 0 016.543-2.176 4.459 4.459 0 011.695 1.957z"
      ></path>
    </g>
    <path
      fill="url(#paint3_linear_282_7181)"
      d="M114.528 26.424a5.277 5.277 0 10.072-10.554 5.277 5.277 0 00-.072 10.554z"
    ></path>
    <path
      fill="url(#paint4_linear_282_7181)"
      d="M25.861 47.007a5.278 5.278 0 10.071-10.555 5.278 5.278 0 00-.07 10.555z"
    ></path>
    <path
      fill="url(#paint5_linear_282_7181)"
      d="M176.453 58.454a7.037 7.037 0 10-6.989-7.084 7.036 7.036 0 006.989 7.084z"
    ></path>
    <defs>
      <filter
        id="filter0_bi_282_7181"
        width="100.095"
        height="70.161"
        x="-14.334"
        y="38.306"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.917"></feGaussianBlur>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_282_7181"></feComposite>
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_282_7181" result="shape"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1.583"></feOffset>
        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
        <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_282_7181"></feBlend>
      </filter>
      <filter
        id="filter1_bi_282_7181"
        width="110.833"
        height="110.833"
        x="102.917"
        y="39.583"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.875"></feGaussianBlur>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_282_7181"></feComposite>
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_282_7181" result="shape"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1.583"></feOffset>
        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_282_7181"></feBlend>
      </filter>
      <filter
        id="filter2_bi_282_7181"
        width="87.711"
        height="72.437"
        x="55.121"
        y="-7.42"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.917"></feGaussianBlur>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_282_7181"></feComposite>
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_282_7181" result="shape"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1.583"></feOffset>
        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
        <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_282_7181"></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_282_7181"
        x1="40.329"
        x2="40.445"
        y1="66.03"
        y2="48.707"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3C8E"></stop>
        <stop offset="1" stopColor="#A400F1"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_282_7181"
        x1="81.495"
        x2="81.611"
        y1="66.465"
        y2="49.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3C8E"></stop>
        <stop offset="1" stopColor="#A400F1"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_282_7181"
        x1="83.125"
        x2="83.125"
        y1="15.833"
        y2="129.833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF40"></stop>
        <stop offset="1" stopColor="#0A731F"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_282_7181"
        x1="114.528"
        x2="114.599"
        y1="26.424"
        y2="15.869"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3C8E"></stop>
        <stop offset="1" stopColor="#A400F1"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_282_7181"
        x1="25.861"
        x2="25.932"
        y1="47.007"
        y2="36.452"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3C8E"></stop>
        <stop offset="1" stopColor="#A400F1"></stop>
      </linearGradient>
      <linearGradient
        id="paint5_linear_282_7181"
        x1="176.453"
        x2="176.548"
        y1="58.454"
        y2="44.381"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3C8E"></stop>
        <stop offset="1" stopColor="#A400F1"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default AirplaneGreenCheckIcon;
