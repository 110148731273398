import styled from '@emotion/styled';
import useForm from 'hooks/useForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageSize } from 'utils/devices';
import userService from '../../api/userService';
import ContainedButton from '../../common/ContainedButton';
import FormInput from '../../common/form/FormInput';
import { selectCurrentUserProperty } from '../../store/auth/authSelectors';
import { portalActions } from '../../store/portals';
import EmailChangeModal from '../backoffice/EmailChange';
import EmailChangeResend from '../backoffice/EmailChangeResend';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

const Spacer = styled.div`
  margin: 56px 0;
  height: 0px;
  border: 1px solid rgba(204, 213, 255, 0.11);
`;

const Content = styled.div`
  background: #1a1b1e;
  border-radius: 16px;
  padding: 56px 56px 64px;
  @media ${pageSize.L}, ${pageSize.XL} {
    margin-bottom: 240px;
  }
  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    margin-bottom: 200px;
  }
  @media ${pageSize.XS} {
    border-radius: 0;
    margin-bottom: 160px;
  }
`;

const AccountSettings = () => {
  const dispatch = useDispatch();

  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields: {
      email: { initialValue: useSelector(selectCurrentUserProperty('email')), validate: ['isRequired', 'isEmail'] }
    },
    callApi: fields => userService.changeEmail(fields),
    onSuccess: () => {
      dispatch(
        portalActions.openPortal({
          name: 'change-email-modal',
          data: {
            sendMail: onSubmit,
            email: values.email
          }
        })
      );
    }
  });

  return (
    <>
      <Content>
        <ChangePassword />
        <Spacer />
        <FormInput
          value={values.email}
          onChange={e => onFieldChange('email')(e.target.value)}
          label="Email"
          error={errors.email}
        />
        <br />
        <ContainedButton type="submit" onClick={onSubmit}>
          Save changes
        </ContainedButton>
        <Spacer />
        <DeleteAccount />
        <EmailChangeModal />
        <EmailChangeResend />
      </Content>
    </>
  );
};

export default AccountSettings;
