import React, { useEffect } from 'react';
import values from 'lodash/fp/values';

const reorder = (list, startIndex, endIndex, order) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, key) => ({
    ...item,
    order: order === 'asc' ? key + 1 : result.length - key
  }));
};

const useDragEnd = ({ callApi, items, order = 'desc', update = true }) => {
  const [list, setList] = React.useState(values(items));

  useEffect(() => {
    if (update) {
      setList(values(items));
    }
  }, [update && items]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(list, result.source.index, result.destination.index, order);

    setList(reorderedItems);

    callApi &&
      callApi(
        reorderedItems.map(item => item._id),
        reorderedItems
      );
  };

  return {
    list,
    onDragEnd
  };
};

export default useDragEnd;
