import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import React from 'react';
import TagItem from 'common/Tag/Tag';
import ContainedButton from 'common/ContainedButton';

function UserTags({ limit, tags, title }) {
  const [show, setShow] = React.useState(false);
  if (!tags?.length) {
    return null;
  }

  const maxLength = limit ?? 8;
  const limited = tags.slice(0, show ? tags.length : maxLength).filter(tag => tag?.name?.length !== 0);

  return (
    <Flex column gap={16}>
      {title && (
        <Typography variant="caption" color="rgba(230, 234, 255, 0.35)">
          {title}
        </Typography>
      )}
      <Flex className="wrap" gap={6}>
        {limited.map(({ name }, key) => (
          <TagItem key={key}>{name}</TagItem>
        ))}
        {tags.length > maxLength && (
          <ContainedButton
            className="transparent"
            radius="4px"
            height="auto"
            padding={!show ? '3px 4px' : '7px 8px'}
            iconSize={!show ? 16 : 8}
            icon={!show ? 'dots' : 'close'}
            onClick={() => setShow(!show)}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default UserTags;
