import React from 'react';
import { useLocation } from 'react-router';
import isEmpty from 'lodash/fp/isEmpty';
import merge from 'lodash/fp/merge';
import capitalize from 'lodash/fp/capitalize';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';
import decodeQuery from 'utils/decodeQuery';
import options from 'utils/options';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Flex from 'common/styles/Flex';
import TxplModal from 'common/TxplModal';
import FormInput from 'common/form/FormInput';
import Typography from 'components/shared/Typography';
import FilterCheckBoxes from 'components/filters/FilterCheckBoxes';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 16px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;
const order = [
  'discipline',
  'tool',
  'platform',
  'skill',
  'tag',
  'genre',
  'experience',
  'role',
  'hoursPerWeek',
  'gamesShipped',
  'language',
  'location'
];

const initialState = state => ({
  discipline: state?.discipline ?? [],
  tool: state?.tool ?? [],
  platform: state?.platform ?? [],
  skill: state?.skill ?? [],
  tag: state?.tag ?? [],
  genre: state?.genre ?? [],
  experience: state?.experience ?? [],
  role: state?.role ?? [],
  hoursPerWeek: state?.hoursPerWeek ?? [],
  jobType: state?.jobType ?? [],
  gamesShipped: state?.gamesShipped ?? [],
  language: state?.language ?? [],
  location: state?.location ?? []
});

function TalentsFilterModal({ closePortal, data, name }) {
  const { clearFilters, filters, setFilters, setShow } = data ?? {};
  const { search } = useLocation();
  const initialFilters = decodeQuery(search);

  const [checkedFilters, setCheckedFilters] = React.useState(initialState(initialFilters));
  const [selectedFilters, setSelectedFilters] = React.useState({});

  const [firstName, setFirstName] = React.useState(initialFilters?.firstName?.value ?? '');
  const [lastName, setLastName] = React.useState(initialFilters?.lastName?.value ?? '');

  const handleCheck = (e, label) => {
    const { checked, name, value } = e.target;
    let items = checkedFilters[name];

    if (checked) {
      items.push({ value, label });
    } else {
      items = items.filter(it => it.value !== value);
    }

    const newFilters = {
      [name]: items
    };

    setCheckedFilters({
      ...checkedFilters,
      ...newFilters
    });
  };

  const handleSelect = (values, { name }) => {
    setSelectedFilters({ ...selectedFilters, [name]: values });
  };

  const handleReset = () => {
    setCheckedFilters(initialState());
    setSelectedFilters({});
    setFirstName('');
    setLastName('');
    clearFilters && clearFilters();
  };

  const handleSubmit = () => {
    const modalFilters = merge(checkedFilters, selectedFilters);
    const mergeToName = merge(modalFilters, {
      firstName: firstName.length ? { value: firstName } : {},
      lastName: lastName.length ? { value: lastName } : {}
    });

    setFilters(mergeToName);
    setShow(false);
    closePortal();
  };

  return (
    <TxplModal
      title="Filter talents"
      titleMB="24"
      addPaddingTop
      XSaddPaddingTop="14px"
      padding={'56px 0'}
      name={name}
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <ContainedButton padding="8px 10px" mr={6} backgroundColor="secondary" onClick={handleReset}>
            Reset
          </ContainedButton>
          <ContainedButton padding="8px 10px" onClick={handleSubmit}>
            Show Results
          </ContainedButton>
        </>
      }
    >
      <Flex column gap={56}>
        {!isEmpty(filters) &&
          order.map(key => {
            if (!filters[key]?.length) {
              return null;
            } else {
              const title =
                key === 'gamesShipped'
                  ? 'Games shipped'
                  : key === 'jobType'
                  ? 'Job type preferences'
                  : key === 'hoursPerWeek'
                  ? 'Work hours (week)'
                  : key === 'experience'
                  ? 'Experience level'
                  : capitalize(key);
              return (
                <FilterCheckBoxes
                  key={key}
                  title={title}
                  name={key}
                  options={options(filters[key], key)}
                  checked={checkedFilters[key]}
                  onChange={handleCheck}
                  onSelect={handleSelect}
                />
              );
            }
          })}
        <Flex>
          <Typography variant="h3" mb={16}>
            First name + Last Name specific
          </Typography>

          <Grid>
            <FormInput
              inputGap={'0px'}
              padding={'10px 12px'}
              borderRadius={8}
              placeholder="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value.replace(/[!0-9\s]/gi, ''))}
            />
            <FormInput
              inputGap={'0px'}
              padding={'10px 12px'}
              borderRadius={8}
              placeholder="Last Name"
              value={lastName}
              onChange={e => setLastName(e.target.value.replace(/[!0-9\s]/gi, ''))}
            />
          </Grid>
        </Flex>
      </Flex>
    </TxplModal>
  );
}

export default withRenderPortal('talents-filter-modal')(TalentsFilterModal);
