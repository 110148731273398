import React from 'react';
import styled from '@emotion/styled';

const StyledRadiobutton = styled.div`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 50%;
  ${props => (props.color ? `background-color: ${props.color};` : '')};
  ${props => (props.circleColor ? `border: 2px solid ${props.circleColor};` : '')};
  ${({ disabled }) => disabled && 'border-color: rgb(84, 86, 91);'}
  padding: 4px;
`;

const Checked = styled.div`
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 50%;
`;

const RadiobuttonIcon = ({ checked, color, disabled }) => (
  <StyledRadiobutton circleColor={color} checked={checked} disabled={disabled}>
    {checked && <Checked />}
  </StyledRadiobutton>
);

export default RadiobuttonIcon;
