import PageLoader from 'common/PageLoader';
import useProfiles from 'hooks/useProfiles';
import useTalkSession from 'hooks/useTalkSession';
import get from 'lodash/fp/get';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import ConversationBox from './ConversationBox';
import ConversationsSidebar from './ConversationsSidebar';
import * as Styled from './StyledConversationsLayout';
import { authSelectors } from 'store/auth';
import { useHistory } from 'react-router-dom';

const ConversationsLayout = () => {
  const isProfileLoaded = useSelector(profileSelectors.selectIsProfileLoaded);

  const myProfile = useSelector(profileSelectors.selectMyProfile);
  const conversations = get('conversations', myProfile) ?? [];

  const [newMessageMode, setNewMessageMode] = useState(false);
  const [visible, setVisible] = useState(false);

  const isAuthLoading = useSelector(authSelectors.selectIsAuthLoading);
  const isAuth = useSelector(authSelectors.isAuthed);
  const history = useHistory();

  useProfiles();
  const { fetchConversations, selectedConversation, setSelectedConversation } = useTalkSession({ newMessageMode });

  useEffect(() => {
    if (!isAuthLoading && !isAuth) {
      history.push('/login');
    }
  }, [isAuth, isAuthLoading]);

  return (
    <>
      {!isProfileLoaded && <PageLoader />}
      <Styled.PurpleGradient />
      {isProfileLoaded && (
        <Styled.Container>
          <Styled.Grid>
            <ConversationsSidebar
              newMessageMode={newMessageMode}
              setNewMessageMode={setNewMessageMode}
              conversations={Object.values(conversations)}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
              fetchConversations={fetchConversations}
              setVisible={setVisible}
            />
            <ConversationBox
              visible={visible}
              setVisible={setVisible}
              newMessageMode={newMessageMode}
              setNewMessageMode={setNewMessageMode}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
              conversationsLength={Object.values(conversations).length}
            />
          </Styled.Grid>
        </Styled.Container>
      )}
    </>
  );
};

export default ConversationsLayout;
