import React, { useState } from 'react';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import ShareURL from 'common/ShareURL';
import useJobServices from 'hooks/api/useJobServices';
import useStartConversation from 'hooks/chatApi/useStartConversation';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import useCompanyRole from 'hooks/useCompanyRole';
import { getDecodedIdToken } from 'utils/cookies';

const JobCardDropDown = ({
  className,
  clipboard,
  closeJobCallback,
  isApplied,
  isClosed,
  isJobIPostedPage,
  isJobPage,
  isMine,
  isPublished,
  isSaved,
  isContractActive,
  jobId,
  jobPath,
  jobStatus,
  noRight,
  padding,
  profileId,
  setStatus,
  contacted,
  creatorProfile
}) => {
  const {
    changeJobStatus,
    loading: { loadingSave },
    saveJob
  } = useJobServices();

  const { isMember } = useCompanyRole();

  const decoded = getDecodedIdToken();
  const isSuperAdmin = decoded?.roles.includes('super-admin');

  const { redirectToStartConversation } = useStartConversation();

  const [shareURLVisible, setShareURLVisible] = useState(false);

  const handleStatusChange = status => {
    changeJobStatus(profileId, jobId, status, () => setStatus('published'));
  };

  const canEdit = useSelector(profileSelectors.hasProfileEditPermission);

  const removeSave = () => {
    saveJob(profileId, jobId, 'remove');
  };

  const handleShareClick = e => {
    e.stopPropagation();
    setShareURLVisible(true);
  };

  return (
    <DropDown
      icon="dots"
      className={`${className} ${isMine && !isJobPage && 'reverse'}`}
      padding={padding}
      noRight={noRight}
    >
      <DropDownItem disabled icon="refer">
        Refer a person
      </DropDownItem>
      {isApplied && contacted && (
        <DropDownItem icon="message" onClick={() => redirectToStartConversation(creatorProfile)}>
          Message
        </DropDownItem>
      )}
      {isApplied && (
        <DropDownItem icon="share" disabled={!isSuperAdmin} onClick={handleShareClick}>
          Share
          {shareURLVisible && (
            <ShareURL
              styles={{ container: { width: 300, top: 35, left: -74, withBorder: true } }}
              visible={shareURLVisible}
              setVisible={setShareURLVisible}
              url={clipboard}
              successText="The job link has been copied"
            />
          )}
        </DropDownItem>
      )}
      {isApplied && !isSaved && !isContractActive && (
        <DropDownItem opens="job-withdraw" data={{ profileId, jobId }} icon="ignore" disabled={isMember}>
          Withdraw application
        </DropDownItem>
      )}
      {isSaved && (
        <DropDownItem icon={loadingSave ? 'spinner' : 'delete'} onClick={removeSave} disabled={isMember}>
          Remove from saved
        </DropDownItem>
      )}
      {!isClosed && isMine && isJobIPostedPage && canEdit && (
        <DropDownItem
          icon="ignore"
          opens="job-close-modal"
          data={{ profileId, jobId, closeJobCallback }}
          disabled={isMember}
        >
          Close job
        </DropDownItem>
      )}
      {isMine && isJobPage && canEdit && (
        <DropDownItem icon="edit" to={`${jobPath}/description`}>
          Edit
        </DropDownItem>
      )}
      {isMine && !isJobIPostedPage && (
        <DropDownItem
          icon={isPublished ? 'visible' : 'hidden'}
          opens={isPublished ? 'job-hide-modal' : 'job-publish-modal'}
          data={{ profileId, jobId }}
          disabled={isMember}
        >
          {isPublished ? 'Hide from my profile' : 'Publish this job'}
        </DropDownItem>
      )}

      {isMine && jobStatus === 'published' && (
        <DropDownItem
          icon="hidden"
          opens="unpublish-job-modal"
          data={{ clipboard, jobId, jobStatus, setStatus }}
          data-testid="unpublish-job"
          disabled={isMember}
        >
          Unpublish
        </DropDownItem>
      )}
      {isMine && jobStatus === 'unpublished' && (
        <DropDownItem icon="visible" onClick={() => handleStatusChange('published')} disabled={isMember}>
          Publish
        </DropDownItem>
      )}
    </DropDown>
  );
};

export default JobCardDropDown;
