import styled from '@emotion/styled';
import React from 'react';

const ActivityStatusWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusIcon = styled.div`
  display: inline-flex;
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: white;
  ${props => (props.color ? `background-color: ${props.color};` : '')}
`;

const StatusText = styled.span`
  text-transform: capitalize;
`;

const getStatusColor = status => {
  switch (status) {
    case 'COMPLETE':
      return '#2BFF26';
    case 'PENDING':
      return '#FF9C40';
    case 'IN_PROGRESS':
      return '#7266EE';
    default:
      return 'white';
  }
};

const VettingStatus = ({ icon = true, status }) => (
  <ActivityStatusWrapper>
    {icon && <StatusIcon color={getStatusColor(status)} />}
    <StatusText>{status && status.replace('_', ' ').toLowerCase()}</StatusText>
  </ActivityStatusWrapper>
);

export default VettingStatus;
