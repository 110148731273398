import React from 'react';

import { getFileType } from 'utils/getFileType';

import Icon from 'common/Icon';
import * as Styled from './StyledUpload';

const UploadList = ({ attachments, handleFileRemove }) => {
  const renderAttachment = attachment => {
    const typeOfFile = getFileType(attachment.fileType);
    const ext = attachment.fileName.split('.').reverse()[0];
    const nameLength = attachment.fileName.length - ext.length;
    return (
      <Styled.AttachmentWrapper key={attachment.id}>
        <Styled.AttachmentClose className="remove_attachment" onClick={() => handleFileRemove(attachment.id)}>
          <Icon icon="close" size={8} />
        </Styled.AttachmentClose>
        {typeOfFile === 'image' ? (
          <Styled.ImageContainer>
            <img src={URL.createObjectURL(attachment.file)} alt="" />
          </Styled.ImageContainer>
        ) : (
          <Styled.DocumentContainer>
            <Styled.DocumentWrapper>
              <Icon icon={`file_${typeOfFile}`} noFill size={24} />
              <Styled.DocumentName>
                {nameLength > 30 ? `${attachment.fileName.substr(0, 30)}...${ext}` : attachment.fileName}
              </Styled.DocumentName>
            </Styled.DocumentWrapper>
          </Styled.DocumentContainer>
        )}
      </Styled.AttachmentWrapper>
    );
  };

  return (
    <Styled.UploadListContainer>
      {attachments.map(attachment => renderAttachment(attachment))}
    </Styled.UploadListContainer>
  );
};

export default UploadList;
