import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import { profileSelectors } from 'store/profile';
import Spinner from 'common/Spinner';
import ViewModalCreditItem from './ViewModalCreditItem';
import ReachedMaxCreditLimit from './ReachedMaxCreditLimit';

const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const ViewCreditsModal = ({
  closePortal,
  data: { chooseFromExisting, notClosePortal },
  items,
  loading,
  name,
  onChoose,
  single
}) => {
  const [showExistingCredits, toggleShowExistingCredits] = React.useState(false);
  const credits = useSelector(profileSelectors.selectProfileProperty('credits'));
  const [selectedCredits, setSelectedCredits] = useState(items);

  const handleChoose = creditId => {
    let updatedCredits = [...selectedCredits];
    if (selectedCredits.includes(creditId)) {
      const index = selectedCredits.findIndex(credit => credit === creditId);
      if (single) {
        updatedCredits = [];
      } else {
        updatedCredits.splice(index, 1);
      }
    } else {
      updatedCredits = single ? [creditId] : [...updatedCredits, creditId];
    }
    setSelectedCredits(updatedCredits);
  };

  const data = notClosePortal ? credits.filter(credit => selectedCredits.includes(credit._id)) : credits;
  const btnDisabled = !notClosePortal ? single && items.length > 0 : single && selectedCredits.length > 0;

  const handleAdd = () => {
    onChoose(selectedCredits);

    closePortal();
  };

  return (
    <TxplModal
      title="Credits"
      name={name}
      subTitle={
        chooseFromExisting ? '' : 'Claim credits for games you helped ship, then give yourself a standing ovation.'
      }
      appElement={document.getElementById('talent-modal-placeholder')}
      addPaddingTop
      XSaddPaddingTop="14px"
      renderFooter={
        <>
          {loading && <Spinner />}
          <ContainedButton
            onClick={closePortal}
            ml={6}
            mr={6}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
            disabled={loading}
          >
            Cancel
          </ContainedButton>

          {!notClosePortal && showExistingCredits && (
            <ContainedButton onClick={handleAdd} backgroundColor="#7266EE" forceDisplayText>
              Add
            </ContainedButton>
          )}

          {notClosePortal && (
            <ContainedButton
              onClick={notClosePortal ? () => onChoose(selectedCredits) : () => closePortal()}
              backgroundColor="#7266EE"
              forceDisplayText
              disabled={loading}
            >
              Save
            </ContainedButton>
          )}
        </>
      }
    >
      {!showExistingCredits && chooseFromExisting && (
        <Typography variant="medium" mb={32}>
          Did you receive any game credits for your work here?
        </Typography>
      )}

      {showExistingCredits && (
        <>
          <Typography variant="h3" mb={24}>
            Select from your existing credits
          </Typography>

          {credits.map(credit => (
            <ViewModalCreditItem
              selectedCredits={selectedCredits}
              checked={selectedCredits.includes(credit._id)}
              key={credit._id}
              onChoose={() => handleChoose(credit._id)}
              chooseFromExisting={chooseFromExisting}
              hideDelete
              credit={credit}
              single={single}
              setSelectedCredits={setSelectedCredits}
              notClosePortal={notClosePortal}
            />
          ))}
        </>
      )}

      {!showExistingCredits &&
        items
          .map(credit => (typeof credit !== 'object' ? data.find(curr => curr._id === credit) : credit))
          .filter(Boolean)
          .map(credit => (
            <ViewModalCreditItem
              selectedCredits={selectedCredits}
              hideChoose={chooseFromExisting}
              onChoose={onChoose}
              key={credit._id}
              credit={credit}
              single={single}
              setSelectedCredits={setSelectedCredits}
              notClosePortal={notClosePortal}
            />
          ))}

      {items.length >= 6 ? (
        <ReachedMaxCreditLimit />
      ) : (
        <StyledButtonsContainer>
          {!showExistingCredits && (
            <>
              {chooseFromExisting && !!credits.length && (
                <ContainedButton
                  backgroundColor="secondary"
                  onClick={() => toggleShowExistingCredits(!showExistingCredits)}
                  disabled={btnDisabled}
                >
                  Add from your list
                </ContainedButton>
              )}

              <ContainedButton
                backgroundColor="rgba(204, 213, 255, 0.11)"
                opens="add-credit"
                closes="credits-modal"
                forceDisplayText
                disabled={btnDisabled}
              >
                Add new
              </ContainedButton>
            </>
          )}
        </StyledButtonsContainer>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('credits-modal')(ViewCreditsModal);
