import React from 'react';
import styled from '@emotion/styled';

export const StyledButton = styled.button`
  cursor: pointer;
  background: transparent;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(204, 213, 255, 0.11);
  color: white;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
  margin-top: 16px;
  &.button {
    margin-top: 16px;
  }
`;

const MoreButton = ({ children, onClick, roleIWill }) => (
  <StyledButton className={roleIWill ? 'button' : ''} onClick={onClick}>
    {children}
  </StyledButton>
);

export default MoreButton;
