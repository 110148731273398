import React from 'react';
import * as Styled from './StyledMessageStatus';

import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

import NA from 'images/NA.png';
import Icon from 'common/Icon';
import { getMessageStatus } from './getMessageStatus';

const MessageStatus = ({ conversationId, id, readBy, senderId }) => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const conversation = useSelector(profileSelectors.selectMyProfileProperty(`conversations.${conversationId}`));

  const participants = conversation?.custom?.participants;
  const sender = participants?.me;
  const recipient = participants?.recipient;
  const contact = sender?.id === profileId ? recipient : sender;

  const status = getMessageStatus(profileId, id, readBy, senderId, contact);

  return (
    <>
      {status && status !== 'seen_by_contact' && (
        <Icon icon={status} size={13} color="rgba(229, 234, 255, 0.6)" hoverColor="rgba(229, 234, 255, 0.6)" />
      )}
      {status === 'seen_by_contact' && (
        <Styled.ImageContainer>
          <img src={contact?.photoUrl || NA} />
        </Styled.ImageContainer>
      )}
    </>
  );
};

export default MessageStatus;
