import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import React from 'react';
import ApplicantCard from '../ApplicantCard';

function ApplicantCardModal({ data, name}) {
  const { applicant, biasReducedMode, job } = data;

  return (
    <TxplModal
      name={name}
      appElement={document.getElementById('root-modal')}
      titleMB="0"
      size="custom"
      padding="0"
      hideCloseIcon
    >
      <ApplicantCard applicant={applicant} isListView isExtended biasReducedMode={biasReducedMode} job={job} />
    </TxplModal>
  );
}

export default withRenderPortal('applicant-card-modal')(ApplicantCardModal);
