import Frame from 'common/Frame';
import useProfilePath from 'hooks/useProfilePath';
import NA from 'images/NA.png';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectProfileProperty } from 'store/profile/profileSelectors';
import {
  CompanyIdentifier,
  CompanyLogo,
  CompanyLogoWrapper,
  CompanyTitle,
  ImageOpacity,
  JobTitle,
  JobTitleEmpty,
  StyledEditTitleButton,
  ThumbnailContainer,
  ThumbnailContent,
  ThumbnailContentTitle,
  ThumbnailCoverContent
} from './styled.components';
import JobTitleModal from '../JobTitle/JobTitleModal';

const JobThumbnail = ({ errorTitle, hasEditPermission, onFieldChange, profile, thumbnail, title }) => {
  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;
  const companyNameFromSelector = useSelector(selectProfileProperty('name'));
  const basicInfoFromSelector = useSelector(selectProfileProperty('basicInfo'));
  const companyName = profile?.name || companyNameFromSelector;
  const basicInfo = profile?.basicInfo || basicInfoFromSelector;
  const profileLogoUrl = basicInfo?.cover || basicInfo?.imageUri?.url || defaultAvatar;
  const profileCoverUrl = basicInfo?.imageUri?.url || '';
  const styles = {
    padding: 0
  };
  let imageUrl = profileCoverUrl || NA;
  if (thumbnail) {
    imageUrl = thumbnail.url ? thumbnail.url : URL.createObjectURL(thumbnail);
  }

  const { getProfilePath } = useProfilePath();
  const profilePath = `${getProfilePath(profile?.publicUrl, profile?.type !== 'talent')}/about`;

  return (
    <Frame
      transparent
      text=""
      noBorder
      canEdit={hasEditPermission}
      editMode={'edit'}
      error={errorTitle}
      noDataText=""
      data={{ thumbnail: thumbnail?.url ? thumbnail : { url: thumbnail } }}
      opens="job-thumbnail-modal"
      style={styles}
      filled={thumbnail}
      darkEditBtn={thumbnail}
      fullHeight
    >
      <ThumbnailContainer title={title}>
        <ThumbnailContent>
          <ThumbnailContentTitle>
            {title ? <JobTitle len={title?.length}>{title}</JobTitle> : <JobTitleEmpty>job title*</JobTitleEmpty>}
          </ThumbnailContentTitle>
          {hasEditPermission && <StyledEditTitleButton opens="job-title-modal" type="button" data={{ title }} />}
          <CompanyIdentifier>
            <CompanyLogo>
              <CompanyLogoWrapper to={profilePath} target="_blank">
                <ImageOpacity />
                <img src={profileLogoUrl} alt="" />
              </CompanyLogoWrapper>
            </CompanyLogo>
            <CompanyTitle to={profilePath} target="_blank">
              {companyName}
            </CompanyTitle>
          </CompanyIdentifier>
        </ThumbnailContent>
        <ThumbnailCoverContent>
          <img src={imageUrl} alt="" />
        </ThumbnailCoverContent>
      </ThumbnailContainer>
      <JobTitleModal onSave={onFieldChange('title')} />
    </Frame>
  );
};

export default JobThumbnail;
