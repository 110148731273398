import styled from '@emotion/styled';
import React from 'react';
import Icon from 'common/Icon';

const Label = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => (props.icon ? '60px;' : '56px;')};
  height: ${props => (props.icon ? '36px;' : '32px;')};
  opacity: ${props => (props.disabled ? '.6' : '1')};

  .slider {
    position: absolute;
    cursor: ${props => !props.noCursor && 'pointer'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    ${props =>
      props.icon
        ? `background-color: ${props.checked ? '#7266EE;' : 'transparent'}`
        : 'background-color: rgba(204, 213, 255, 0.11);'};
    border: ${props =>
      props.icon ? (props.checked ? '2px solid #7266EE;' : '2px solid rgba(204, 213, 255, 0.11);') : 'none;'};
    transition: 0.4s;
    :before {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      left: ${props => (props.checked ? '2px' : '4px')};
      bottom: 4px;
      border-radius: 50%;
      background-color: ${props => {
        if (props.icon && props.checked) {
          return '#FFF;';
        }
        if (props.icon && !props.checked) {
          return 'rgba(204, 213, 255, 0.11);';
        }
        if (!props.icon && props.checked) {
          return '#7266EE;';
        }
        return '#3a3b41;';
      }};
      transition: ${props => (props.icon ? '.0s;' : '.4s;')};
    }
  }

  .switcherIcon {
    position: absolute;
    left: ${props => (props.checked ? '32px;' : '7px;')};
    top: 7px;
  }

  .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .checkbox:checked + .slider {
    ${props =>
      props.icon
        ? `background-color: ${props.checked ? '#7266EE;' : 'transparent'}`
        : 'background-color: rgba(204, 213, 255, 0.11);'};
  }

  .checkbox:focus + .slider {
    box-shadow: 0 0 1px rgba(204, 213, 255, 0.11);
  }

  .checkbox:checked + .slider:before {
    transform: translateX(26px);
  }
`;

const Switcher = ({ checked, disabled, icon, noCursor, noFill, onClick, setChecked }) => (
  <Label
    className="switch"
    onClick={() => (onClick ? !disabled && onClick() : !disabled && setChecked && setChecked(!checked))}
    checked={checked}
    icon={icon}
    disabled={disabled}
    noCursor={noCursor}
  >
    <input className="checkbox" type="checkbox" checked={checked} disabled={disabled} />
    <span className="slider" icon={icon}>
      {icon && (
        <Icon
          className="switcherIcon"
          icon={checked ? 'unlock' : 'lock'}
          color={checked ? '#7266EE' : '#FFF'}
          noFill={noFill}
        />
      )}
    </span>
  </Label>
);

export default Switcher;
