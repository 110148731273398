import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: 216px;
  background-color: rgba(204, 213, 255, 0.04);
  padding: 24px;
  border-radius: 16px;
`;

const BackofficeActionPanel = ({ children }) => <Wrapper>{children}</Wrapper>;

export default BackofficeActionPanel;
