import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';

import Icon from './Icon';

const StyledLinkContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 14px auto;
  align-items: center;
  grid-column-gap: 9px;
  cursor: pointer;
  ${props => props.pt && `padding-top: ${props.pt}`}
`;

const StyledText = styled.span`
  ${commonStyles.ui_text_medium}
  text-decoration: underline;
`;

const LinkWithIcon = ({ children, icon, pt }) => (
  <StyledLinkContainer pt={pt}>
    <Icon icon={icon} />
    <StyledText>{children}</StyledText>
  </StyledLinkContainer>
);

export default LinkWithIcon;
