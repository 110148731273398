import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 136px;
  font-size: 16px;
  color: rgba(229, 234, 255, 0.6);
`;

export const Text = styled.div`
  margin-top: 16px;
`;
