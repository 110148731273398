/* eslint-disable unused-imports/no-unused-vars */
import styled from '@emotion/styled';
import Tooltip from 'components/Tooltip';
import withOpenPortal from 'hocs/withOpenPortal';
import kebabCase from 'lodash/fp/kebabCase';
import React from 'react';
import { Link } from 'react-router-dom';
import commonStyles from 'utils/common-styles';
import Icon from './Icon';
import Spinner from './Spinner';

const getBackgroundColor = background => (background === 'secondary' ? 'rgba(204, 213, 255, 0.11)' : background);

const Wrapper = styled.span`
  &.stretched {
    width: 100%;
  }
  ${props => props.paginationStyles && 'margin-right: -26px;'}
`;
const StyledButton = styled.button`
  ${commonStyles.caption}
  position: relative;
  display: flex;
  flex-direction: row;
  padding: ${({ padding }) => (padding ? padding : '0 10px')};
  align-items: center;
  outline: none;
  border: 1px;
  gap: 8px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  ${({ mr }) => mr && `margin-right: ${mr}px;`}

  background: #7266EE;
  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    cursor: auto;
  `}
  ${props => (props.backgroundColor ? `background-color: ${getBackgroundColor(props.backgroundColor)};` : '')}
  ${props => (props.color && !props.noBorder ? `border: 1px solid ${props.color};` : '')}
  ${props => (props.border ? `border: ${props.border};` : '')}
  ${props => (props.radius ? `border-radius: ${props.radius};` : `border-radius: 8px;`)}
  height: ${({ height }) => (height ? height : '32px')};
  &:focus {
    border: ${({ enableFocus }) => enableFocus && '4px solid rgba(114, 102, 238, 0.5)'};
  }
  &:hover {
    box-shadow: 0px 2px 16px rgba(114, 102, 238, 0.5);
    box-shadow: 0px 2px 16px
      ${props => (props.backgroundColor ? getBackgroundColor(props.backgroundColor) : 'rgba(114, 102, 238, 0.5);')};
  }
  & .button-text {
    ${commonStyles.caption}
    white-space: nowrap;
    ${props => (props.color ? `color: ${props.color};` : '')}
    ${props => (props.icon ? 'padding-left: 8px;' : '')}
      ${props => (props.icon && props.left ? 'padding-left: 8px;' : '')}
  }
  &.transparent {
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'rgba(17, 17, 18, 1)')};
    border: 2px solid rgba(204, 213, 255, 0.11);
    border-radius: ${({ radius }) => (radius ? radius : '8px')};
    padding: ${({ padding }) => (padding ? padding : '4px 8px')};
  }
  &.red {
    background-color: #ff5151;
  }
  &.transparent:hover {
    background-color: #1a1b1e;
    border-color: #383a43;
    box-shadow: none;
  }
  &.stretched {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
const StyledIcon = styled(Icon)``;

const StyledIndicator = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 4px;
  gap: 10px;

  /* Secondary/Platforms */

  background: #ff5151;
  border-radius: 8px;
`;

const StyledIndicatorText = styled.span`
  width: 7px;
  height: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
`;

const ContainedButton = props => {
  const {
    icon,
    iconSize,
    color,
    children,
    buttonRef,
    forceDisplayText = true,
    iconColor,
    indicator,
    disabled,
    tooltip,
    tooltipId,
    onClick,
    style,
    padding,
    height,
    tooltipSecondary,
    overrideTooltipPosition,
    paginationStyles,
    type,
    form,
    delayTooltipHide,
    tooltipIsClickable,
    to,
    ...rest
  } = props;

  const as = to ? Link : 'button';

  return (
    <Wrapper {...rest} data-tip data-for={tooltipId} data-tip-disable={false} paginationStyles={paginationStyles}>
      <StyledButton as={as} style={style} ref={buttonRef} {...props}>
        {icon == 'spinner' ? (
          <Spinner size="16" />
        ) : (
          <StyledIcon className="button-icon" icon={icon} size={iconSize || 16} color={iconColor || color} />
        )}
        {children && <span className="button-text">{children}</span>}
        {indicator && (
          <StyledIndicator backgroundColor="#FF5151" borderRadius="10px" fontSize={'13px'} lineHeight={'16px'}>
            <StyledIndicatorText>{indicator}</StyledIndicatorText>
          </StyledIndicator>
        )}
      </StyledButton>

      {tooltip && (
        <Tooltip
          key={kebabCase(tooltip)}
          className="secondary"
          tooltipId={tooltipId}
          delayHide={delayTooltipHide}
          isClickable={tooltipIsClickable}
          overrideTooltipPosition={overrideTooltipPosition}
        >
          {tooltip}
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default withOpenPortal(ContainedButton);
