import set from 'lodash/fp/set';
import { talentActionTypes } from 'store/talent';
import * as authActionTypes from './authActionTypes';

const initialState = {};

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.LOGIN:
      return action.payload.user;

    case authActionTypes.LOGOUT:
      return initialState;

    case authActionTypes.UPDATE_CURRENT_USER_PROPERTY:
      return set(action.payload.property, action.payload.value, state);

    case talentActionTypes.UPDATE_TALENT_PROPERTY: {
      const { payload } = action;

      return payload.property !== 'basicInfo.imageUri' ? state : { ...state, imageUri: payload.value };
    }

    case authActionTypes.SIGNUP:
      return action.payload.user;

    default:
      return state;
  }
};

export default currentUserReducer;
