import styled from '@emotion/styled';
import TagsModal from 'common/TagsModal';
import { get } from 'lodash/fp';
import AllAdditionalTags from 'pages/talent/AllAdditionalTags';
import { equals } from 'ramda';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { pageSize } from 'utils/devices';
import Credits from '../Credits';
import AboutExperience from './AboutExperience';
import AboutExperienceModal from './AboutExperienceModal';
import AboutLocations from './AboutLocations';
import AboutText from './AboutText';
import AboutTextEditor from './AboutTextEditor';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  min-width: 0;
  margin-top: -32px;
  @media ${pageSize.L} {
    margin-top: -16px;
  }
`;

const GridExperienceAndRate = styled.div`
  display: grid;
  ${props => !props.singleFrame && 'grid-template-columns: repeat(2, 1fr);'}
  grid-gap: 16px;
  @media ${pageSize.XS} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 0 !important;
`;

const hintTexts = {
  platforms: 'Add Platforms you work for',
  tools: 'Add tools that you use',
  genres: 'Add genres you are comfortable with',
  skills: 'List your primary skills',
  tags: 'Add tags that describe your work best'
};

const TalentAbout = ({ location: { hash, key } }) => {
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profile = useSelector(profileSelectors.selectProfile);

  const {
    aaaGames,
    aaGames,
    mobileGames,
    aboutText,
    games,
    genres,
    indieGames,
    platforms,
    skills,
    tags,
    tools,
    years
  } = get('about', profile) || {};
  const locations = get('basicInfo.locations', profile) ?? [];
  const creditsRef = useRef();
  const hasCredits = equals(hash, '#credits');

  useEffect(() => {
    if (hasCredits) {
      creditsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [key]);

  const keywords = get('keywords', profile);

  return (
    <Wrapper>
      <AboutTextEditor />
      <AboutExperienceModal />

      {hasEditPermission || aboutText?.length > 0 ? (
        <AboutText aboutText={aboutText} canEdit={hasEditPermission} />
      ) : (
        <></>
      )}

      {hasEditPermission || years || games || aaaGames || indieGames || aaGames || mobileGames ? (
        <GridExperienceAndRate singleFrame>
          <AboutExperience
            {...{ years, games, aaaGames, indieGames, aaGames, mobileGames }}
            canEdit={hasEditPermission}
          />
        </GridExperienceAndRate>
      ) : (
        <></>
      )}

      {!isCompanyView && (
        <StyledAllAdditionalTags
          maxWidth="250px"
          maxWidthXs="245px"
          hintTexts={hintTexts}
          isEditMode={hasEditPermission}
          hasDragPermission={hasEditPermission}
          data={{ keywords, platforms, tags, skills, genres, tools }}
        />
      )}

      <TagsModal tags={{ keywords, platforms, tags, skills, genres, tools }} />

      {(profile.credits?.length > 0 || hasEditPermission) && (
        <Credits
          isEditMode={hasEditPermission}
          hideDropDown={isCompanyView}
          margin="0"
          creditsRef={creditsRef}
          items={profile.credits}
          hintText="Claim your credits for the games that you helped to ship"
        />
      )}

      {isCompanyView && (!!locations.length || hasEditPermission) && (
        <>
          <AboutLocations locations={locations} />
        </>
      )}
    </Wrapper>
  );
};

export default TalentAbout;
