import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const StyledCompanyType = styled.div`
  font-family: Space Grotesk;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(242, 244, 255, 0.9);
  height: 26px;
  min-width: 40px;
  font-weight: 400;
  padding: 0 6px;
  margin: 0 6px 6px 0;
  text-align: center;
  border: 1px solid rgba(221, 226, 255, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  max-width: 250px;
  cursor: ${props => (props.hasDragPermission ? 'grab' : 'unset')};
`;

const CompanyRoles = ({ hasDragPermission, id, roleList }) => {
  const [role, setRole] = useState({});
  const disabledRef = useRef();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  useEffect(() => {
    roleList &&
      roleList.map(role => {
        role._id === id && setRole(role);
      });
  }, []);

  return (
    <StyledCompanyType
      hasDragPermission={hasDragPermission}
      ref={hasDragPermission ? setNodeRef : disabledRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {role.name}
    </StyledCompanyType>
  );
};

export default CompanyRoles;
