import React from 'react';
import styled from '@emotion/styled';

import { companyService } from 'api';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import useForm from 'hooks/useForm';
import FormUpload from 'common/form/FormUpload';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledForm = styled.form`
  max-width: 600px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
  margin-left: auto;
`;

const fields = data => ({
  name: { initialValue: data.name, validate: ['isRequired'] },
  associates: { initialValue: data.associates, validate: [] }
});

const BackOfficeMissingCompanyModal = ({
  closePortal,
  data: { companyId, initialName, tableRef },
  name: modalName
}) => {
  const [image, setImage] = React.useState();

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields({ name: initialName }),
    callApi: async body => {
      const formData = new FormData();

      if (image) formData.append('file', image);
      formData.append('company', JSON.stringify(body));

      const {
        data: { data, ...rest }
      } = await companyService.approveMissingItem(companyId, formData);

      if (tableRef) tableRef.current.refetch();
      closePortal();

      return Promise.resolve({ data: { data, ...rest } });
    }
  });

  return (
    <TxplModal
      name={modalName}
      appElement={document.getElementById('root')}
      title="Add missing Item"
      renderFooter={
        <SubmitRow>
          <StyledButtonsContainer>
            {submitting && <Spinner />}
            <ContainedButton
              type="button"
              onClick={closePortal}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              forceDisplayText
              disabled={submitting}
            >
              Cancel
            </ContainedButton>
            <ContainedButton type="submit" onClick={onSubmit} forceDisplayText disabled={submitting}>
              Add
            </ContainedButton>
          </StyledButtonsContainer>
        </SubmitRow>
      }
    >
      <StyledForm onSubmit={onSubmit}>
        <FormInput label="Name" value={values.name} onChange={onFieldChange('name')} error={errors.name} />
        <FormUpload
          wrapperStyles={{ flexDirection: 'column' }}
          disabled
          label="Logo"
          noCrop
          onChange={setImage}
          value={image}
          hintText="Should be an SVG image"
          mt={20}
          preview
          aspectRatio={208 / 300}
          mw="100px"
          accept="image/svg+xml"
          padding
        />
        <FormInput
          label="Associations"
          value={values.associates?.join(', ')}
          onChange={e => onFieldChange('associates')(e.target.value.split(', '))}
        />
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-missing-company-modal')(BackOfficeMissingCompanyModal);
