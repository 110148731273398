import React from 'react';
import { useDispatch } from 'react-redux';

import { getRandomAvatar } from 'utils/assets';
import { portalActions } from 'store/portals';
import AllCollaboratorsModal from 'pages/talent/Collaborators/CollaboratorsFrame/AllCollaboratorsModal';
import { ReactComponent as IconKebab } from 'images/assets/icons_16x16/kebab.svg';

import { StyledAvatar, StyledContainer, StyledExpandContainer } from './CollaboratorsBadgeStyles';

const CollaboratorsBadge = ({ collaborators = [], mediumView, gameName }) => {
  const dispatch = useDispatch();

  const slicedCollaborators = React.useMemo(() => collaborators.slice(0, 3), [collaborators]);

  const renderExpand = React.useMemo(() => collaborators.length > 0, [collaborators.length]);

  const handleOpenCollaborators = () => {
    dispatch(
      portalActions.openPortal({
        name: 'collaborators-list',
        data: { collaborators, gameName }
      })
    );
  };

  return (
    <StyledContainer mediumView={mediumView} hasChild={slicedCollaborators.length > 0}>
      {slicedCollaborators.map((collaborator, i) => {
        return (
          <StyledAvatar
            src={collaborator?.collaboratorProfile?.basicInfo?.imageUri?.url || getRandomAvatar()}
            alt="Avatar"
            key={collaborator._id}
            faded={collaborator.status === 'pending'}
            left={!!i && i * 26}
            zIndex={i + 1}
          />
        );
      })}
      {renderExpand && (
        <StyledExpandContainer onClick={handleOpenCollaborators} left={slicedCollaborators.length * 26}>
          <IconKebab />
        </StyledExpandContainer>
      )}
      <AllCollaboratorsModal />
    </StyledContainer>
  );
};

export default CollaboratorsBadge;
