import set from 'lodash/fp/set';
import flowRight from 'lodash/fp/flowRight';
import unset from 'lodash/fp/unset';
import * as apiCallActionTypes from './apiCallActionTypes';

const initialState = {
  currentUser: {
    loading: true
  },
  profiles: {
    loading: true
  }
};

const apiCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case apiCallActionTypes.SEND_REQUEST: {
      const { name } = action.payload;
      return set(name, { loading: true, data: {} }, state);
    }

    case apiCallActionTypes.SEND_REQUEST_SUCCESS: {
      const { data, name, persist } = action.payload;

      if (!persist) return unset(name, state);

      return flowRight([set(`${name}.loading`, false), set(`${name}.data`, data)])(state);
    }

    case apiCallActionTypes.SEND_REQUEST_FAILURE: {
      const { error, name, persist } = action.payload;

      if (!persist) return unset(name, state);

      return flowRight([set(`${name}.loading`, false), set(`${name}.error`, error)])(state);
    }

    case apiCallActionTypes.CLEAR_REQUEST: {
      return unset(action.payload.name, state);
    }

    default:
      return state;
  }
};

export default apiCallReducer;
