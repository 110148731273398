import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import FormCheckbox from 'common/form/FormCheckbox';
import FormInput from 'common/form/FormInput';
import SaveContainedButton from 'common/SaveContainedButton';
import Spinner from 'common/Spinner';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

export const StyledFormInput = styled(FormInput)`
  padding-left: ${props => props.paddingLeft}px;
  box-sizing: border-box;
`;
export const HoursFormInput = styled(StyledFormInput)`
  width: 10%;
`;
export const WorkingHoursFormInputContainer = styled.div`
  display: flex;
  width: 100%;
`;
export const WorkingHoursFormInput = styled.div`
  width: 48%;
  :first-child {
    margin-right: 2%;
  }
  :last-child {
    margin-left: 2%;
  }
`;
export const Spacer = styled.div`
  margin: 56px 0;
  height: 0px;
  border: 1px solid rgba(204, 213, 255, 0.11);
`;
export const SpecificDate = styled.div`
  background: rgba(221, 226, 255, 0.2);
  border-radius: 6px;
  flex: none;
  order: 16;
  flex-grow: 0;
  margin: 0px 0px 56px 0;
  padding: 8px 10px;
  width: fit-content;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
`;
export const SpecificDateContainer = styled.div`
  clear: both;
  display: flex;
  width: 50%;
  margin-bottom: 20px;
`;
export const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  display: flex;
  flex-direction: row;
  color: white;
  ${props => props.isDisabled && 'opacity: 0.35;'}
`;
export const Loader = styled(Spinner)`
  margin: 15px 0 5px 0;
`;
export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
  max-width: 1500px;
  margin: 0 auto;
`;
export const Content = styled.div`
  background: #1a1b1e;
  border-radius: 16px;
  padding: 64px 56px;
  margin-bottom: 240px;
  @media ${pageSize.XS} {
    padding: 64px 16px 56px;
  }
`;
export const CompanyContent = styled.div`
  background: #1a1b1e;
  border-radius: 16px;
  padding: 64px 56px;
  margin-bottom: 20px;
`;
export const GridWrapper = styled.div`
  display: grid;
  position: relative;
`;
export const StyledContainedButton = styled(SaveContainedButton)`
  margin-left: 6px;
  margin-right: 56px;
`;
export const GridFiller = styled.div`
  @media ${pageSize.XS}, ${pageSize.S} {
    display: none;
  }
`;
export const StyledOrigin = styled.span`
  position: absolute;
  z-index: 100;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-family: Space Grotesk;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.35);
`;
export const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
export const StyledButton = styled(ContainedButton)`
  margin-left: auto;
  margin-bottom: 35px;
  margin-top: -60px;
`;
export const ContainedButtonIcon = styled(ContainedButton)`
  border: 1px solid transparent;
`;
export const CopyUrlWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CopyUrlSuccess = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
`;
export const CopyUrlSuccessText = styled.span`
  color: rgba(230, 234, 255, 0.35);
  margin-left: 5px;
`;
export const CommercialEntityFormCheckbox = styled(FormCheckbox)`
  margin-bottom: 30px;
`;
export const SettingsFooter = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 1px solid rgba(204, 213, 255, 0.11);
  background-color: #111112;
`;
