import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';

export const SplashFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 64px;
  @media ${pageSize.S} {
    max-width: 568px;
    width: 100%;
    background-color: #111112;
    border-radius: 16px;
  }
`;
export const SplashHeader = styled.div`
  max-width: 440px;
  width: 100%;
  height: 40px;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${pageSize.XS} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    height: max-content;
  }
`;
export const SplashForm = styled.form`
  max-width: 440px;
  width: 100%;
  margin-top: 40px;
`;
export const SplashFormFooter = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.buttonToEnd ? 'flex-end' : 'space-between')};
`;

export const ErrorMessage = styled.div`
  ${commonStyles.ui_error_text}
`;

export const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 440px;
  padding: ${props => props.padding ?? '36px 32px'};
  box-sizing: border-box;
  background: rgba(204, 213, 255, 0.05);
  border-radius: 24px;
`;

export const SuccessMessageText = styled.div`
  color: rgba(230, 234, 255, 0.6);
  text-align: center;
`;

export const RequirementsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RequirementsListItem = styled.div`
  display: flex;
  color: ${props => props.color ?? 'rgba(230, 234, 255, 0.6)'};
`;

export const RequirementTitle = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const EmailAdress = styled.span`
  color: white;
`;

export const NavigateTo = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
