import React from 'react';
import styled from '@emotion/styled';

import Spinner from 'common/Spinner';

const StyledTableColumn = styled.td`
  text-align: center;
`;

const StyledTableRow = styled.tr`
  background: transparent !important;
`;

const TableLoader = () => (
  <StyledTableRow>
    <StyledTableColumn>
      <Spinner />
    </StyledTableColumn>
  </StyledTableRow>
);

export default TableLoader;
