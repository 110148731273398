import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';

export const StyledCancelButton = styled(ContainedButton)`
  margin: 0 3px;
`;
export const StyledAddMoreButton = styled(ContainedButton)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledBenefitItem = styled.div`
  max-width: 600px;
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 21px 24px;
  background: rgba(204, 213, 255, 0.05);
  border-radius: 16px;
`;
export const StyledBenefits = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 8px;
`;
