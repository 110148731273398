import * as ActionTypes from './collaborationsActionsTypes';

export const fetchCollaborations = ({
  collaborations,
  hasMore,
  hasVetted,
  isFirstVouch,
  refetch = false,
  totalCollabs,
  vouchedAsUnverified,
  vouchedCollaborators
}) => ({
  type: ActionTypes.FETCH_COLLABORATIONS,
  payload: {
    collaborations,
    hasMore,
    hasVetted,
    isFirstVouch,
    totalCollabs,
    vouchedAsUnverified,
    vouchedCollaborators,
    refetch
  }
});

export const addCollaboration = ({ collaboration }) => ({
  type: ActionTypes.ADD_COLLABORATION,
  payload: { collaboration }
});

export const updateCollaborationProperty = ({ key, property, value }) => ({
  type: ActionTypes.UPDATE_COLLABORATION_PROPERTY,
  payload: {
    key,
    property,
    value
  }
});

export const deleteCollaboration = ({ key }) => ({
  type: ActionTypes.DELETE_COLLABORATION,
  payload: {
    key
  }
});

export const clearCollaborations = () => ({
  type: ActionTypes.CLEAR_COLLABORATIONS
});
