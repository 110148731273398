import * as React from 'react';

const RocketCompleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144} height={120} fill="none" viewBox="0 0 144 120">
    <g clipPath="url(#a)">
      <path
        d="M47.617 61.731c5.4-1.989 10.652 3.263 8.663 8.667l-11.776 32.013a4.498 4.498 0 0 1-7.407 1.629L13.974 80.92a4.5 4.5 0 0 1 1.63-7.403l32.012-11.79v.005Z"
        fill="url(#b)"
      />
      <path
        d="M47.617 61.731c5.4-1.989 10.652 3.263 8.663 8.667l-11.776 32.013a4.498 4.498 0 0 1-7.407 1.629L13.974 80.92a4.5 4.5 0 0 1 1.63-7.403l32.012-11.79v.005Z"
        fill="url(#c)"
      />
      <path
        d="M47.617 61.731c5.4-1.989 10.652 3.263 8.663 8.667l-11.776 32.013a4.498 4.498 0 0 1-7.407 1.629L13.974 80.92a4.5 4.5 0 0 1 1.63-7.403l32.012-11.79v.005Z"
        fill="url(#d)"
      />
      <path
        d="M74.614 88.731c5.404-1.988 10.656 3.263 8.667 8.667l-11.786 32.013a4.495 4.495 0 0 1-3.255 2.84 4.498 4.498 0 0 1-4.147-1.211l-23.121-23.121a4.5 4.5 0 0 1 1.629-7.402l32.013-11.79v.004Z"
        fill="url(#e)"
      />
      <path
        d="M74.614 88.731c5.404-1.988 10.656 3.263 8.667 8.667l-11.786 32.013a4.495 4.495 0 0 1-3.255 2.84 4.498 4.498 0 0 1-4.147-1.211l-23.121-23.121a4.5 4.5 0 0 1 1.629-7.402l32.013-11.79v.004Z"
        fill="url(#f)"
      />
      <path
        d="M74.614 88.731c5.404-1.988 10.656 3.263 8.667 8.667l-11.786 32.013a4.495 4.495 0 0 1-3.255 2.84 4.498 4.498 0 0 1-4.147-1.211l-23.121-23.121a4.5 4.5 0 0 1 1.629-7.402l32.013-11.79v.004Z"
        fill="url(#g)"
      />
      <g filter="url(#h)">
        <path
          d="M100.799 15.975a.676.676 0 0 1 .729.153l26.483 26.685a.674.674 0 0 1 .148.729c-6.345 15.57-15.088 32.13-26.793 43.839-11.403 11.403-30.69 21.118-48.105 28.264l-24.71-24.714c7.147-17.415 16.862-36.697 28.265-48.1 11.745-11.745 28.368-20.507 43.979-26.852l.004-.004Z"
          fill="url(#i)"
        />
        <path
          d="M100.799 15.975a.676.676 0 0 1 .729.153l26.483 26.685a.674.674 0 0 1 .148.729c-6.345 15.57-15.088 32.13-26.793 43.839-11.403 11.403-30.69 21.118-48.105 28.264l-24.71-24.714c7.147-17.415 16.862-36.697 28.265-48.1 11.745-11.745 28.368-20.507 43.979-26.852l.004-.004Z"
          fill="url(#j)"
        />
        <path
          d="M100.799 15.975a.676.676 0 0 1 .729.153l26.483 26.685a.674.674 0 0 1 .148.729c-6.345 15.57-15.088 32.13-26.793 43.839-11.403 11.403-30.69 21.118-48.105 28.264l-24.71-24.714c7.147-17.415 16.862-36.697 28.265-48.1 11.745-11.745 28.368-20.507 43.979-26.852l.004-.004Z"
          fill="url(#k)"
        />
        <path
          d="M100.799 15.975a.676.676 0 0 1 .729.153l26.483 26.685a.674.674 0 0 1 .148.729c-6.345 15.57-15.088 32.13-26.793 43.839-11.403 11.403-30.69 21.118-48.105 28.264l-24.71-24.714c7.147-17.415 16.862-36.697 28.265-48.1 11.745-11.745 28.368-20.507 43.979-26.852l.004-.004Z"
          fill="url(#l)"
        />
        <path
          d="M100.799 15.975a.676.676 0 0 1 .729.153l26.483 26.685a.674.674 0 0 1 .148.729c-6.345 15.57-15.088 32.13-26.793 43.839-11.403 11.403-30.69 21.118-48.105 28.264l-24.71-24.714c7.147-17.415 16.862-36.697 28.265-48.1 11.745-11.745 28.368-20.507 43.979-26.852l.004-.004Z"
          fill="url(#m)"
        />
      </g>
      <g filter="url(#n)">
        <path
          d="M10.903 132.898C7.722 129.717 9 107.649 20.25 103.149c0 0 11.25-4.5 18.499 2.7 7.25 7.2 4 15.3 4 15.3-3.181 9.544-16.726 13.338-18.319 11.745-.878-.878.702-2.475 0-3.177-.702-.707-1.791.099-4.77 1.588-2.124 1.062-7.483 2.867-8.757 1.593Z"
          fill="url(#o)"
        />
      </g>
      <path
        d="M27.27 94.42a6.75 6.75 0 0 1 9.549 0l15.907 15.912a6.75 6.75 0 0 1-9.54 9.544L27.27 103.969a6.746 6.746 0 0 1-1.465-7.359 6.75 6.75 0 0 1 1.465-2.19Z"
        fill="url(#p)"
      />
      <path
        d="M27.27 94.42a6.75 6.75 0 0 1 9.549 0l15.907 15.912a6.75 6.75 0 0 1-9.54 9.544L27.27 103.969a6.746 6.746 0 0 1-1.465-7.359 6.75 6.75 0 0 1 1.465-2.19Z"
        fill="url(#q)"
      />
      <path
        d="M27.27 94.42a6.75 6.75 0 0 1 9.549 0l15.907 15.912a6.75 6.75 0 0 1-9.54 9.544L27.27 103.969a6.746 6.746 0 0 1-1.465-7.359 6.75 6.75 0 0 1 1.465-2.19Z"
        fill="url(#r)"
      />
      <path
        d="M131.273 12.92c-4.262-4.262-11.736-3.78-17.181-1.909a258.456 258.456 0 0 0-12.213 4.532.453.453 0 0 0-.265.544c.021.072.06.136.112.19l26.145 26.32a.442.442 0 0 0 .403.118.446.446 0 0 0 .326-.267 258.154 258.154 0 0 0 4.585-12.343c1.845-5.373 3.33-11.943-1.912-17.186Z"
        fill="url(#s)"
      />
      <path
        d="M131.273 12.92c-4.262-4.262-11.736-3.78-17.181-1.909a258.456 258.456 0 0 0-12.213 4.532.453.453 0 0 0-.265.544c.021.072.06.136.112.19l26.145 26.32a.442.442 0 0 0 .403.118.446.446 0 0 0 .326-.267 258.154 258.154 0 0 0 4.585-12.343c1.845-5.373 3.33-11.943-1.912-17.186Z"
        fill="url(#t)"
      />
      <path
        d="M131.273 12.92c-4.262-4.262-11.736-3.78-17.181-1.909a258.456 258.456 0 0 0-12.213 4.532.453.453 0 0 0-.265.544c.021.072.06.136.112.19l26.145 26.32a.442.442 0 0 0 .403.118.446.446 0 0 0 .326-.267 258.154 258.154 0 0 0 4.585-12.343c1.845-5.373 3.33-11.943-1.912-17.186Z"
        fill="url(#u)"
      />
      <path
        d="M61.118 75.231c5.4-1.988 10.652 3.263 8.663 8.667L58 115.911a4.498 4.498 0 0 1-7.407 1.629L27.472 94.42a4.499 4.499 0 0 1 1.629-7.403l32.017-11.79v.004Z"
        fill="url(#v)"
      />
      <path
        d="M61.118 75.231c5.4-1.988 10.652 3.263 8.663 8.667L58 115.911a4.498 4.498 0 0 1-7.407 1.629L27.472 94.42a4.499 4.499 0 0 1 1.629-7.403l32.017-11.79v.004Z"
        fill="url(#w)"
      />
      <path
        d="M61.118 75.231c5.4-1.988 10.652 3.263 8.663 8.667L58 115.911a4.498 4.498 0 0 1-7.407 1.629L27.472 94.42a4.499 4.499 0 0 1 1.629-7.403l32.017-11.79v.004Z"
        fill="url(#x)"
      />
      <path
        d="M61.118 75.231c5.4-1.988 10.652 3.263 8.663 8.667L58 115.911a4.498 4.498 0 0 1-7.407 1.629L27.472 94.42a4.499 4.499 0 0 1 1.629-7.403l32.017-11.79v.004Z"
        fill="url(#y)"
      />
      <circle cx={89.1} cy={53.101} r={13.5} fill="url(#z)" />
      <g filter="url(#A)">
        <circle cx={110.7} cy={90.901} r={24.3} fill="#BFCAFF" fillOpacity={0.1} shapeRendering="crispEdges" />
      </g>
      <path
        d="M100.8 91.8 108 99l14.4-14.4"
        stroke="#fff"
        strokeWidth={5.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient id="b" x1={36.498} y1={77.886} x2={13.998} y2={74.178} gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2452" />
        <stop offset={1} stopColor="#F63E7A" />
      </linearGradient>
      <linearGradient id="d" x1={34.685} y1={61.301} x2={34.685} y2={105.36} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <linearGradient id="e" x1={80.513} y1={97.525} x2={52.532} y2={119.48} gradientUnits="userSpaceOnUse">
        <stop stopColor="#F83267" />
        <stop offset={1} stopColor="#FF3190" />
      </linearGradient>
      <linearGradient id="g" x1={61.683} y1={88.301} x2={61.683} y2={132.356} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <linearGradient id="i" x1={60.686} y1={39.024} x2={100.34} y2={78.961} gradientUnits="userSpaceOnUse">
        <stop stopColor="#CCBBC0" />
        <stop offset={1} stopColor="#EAD2EC" />
      </linearGradient>
      <linearGradient id="o" x1={27.854} y1={133.336} x2={27.854} y2={101.76} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7A00" />
        <stop offset={1} stopColor="#C40000" />
      </linearGradient>
      <linearGradient id="p" x1={26.091} y1={98.789} x2={50.841} y2={123.116} gradientUnits="userSpaceOnUse">
        <stop stopColor="#452860" />
        <stop offset={1} stopColor="#51509F" />
      </linearGradient>
      <linearGradient id="r" x1={39.932} y1={92.441} x2={39.932} y2={121.721} gradientUnits="userSpaceOnUse">
        <stop stopColor="#303543" />
        <stop offset={1} stopColor="#7B7C9A" />
      </linearGradient>
      <linearGradient id="s" x1={131.295} y1={12.942} x2={114.753} y2={29.484} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF3745" />
        <stop offset={1} stopColor="#ED224B" />
      </linearGradient>
      <linearGradient id="u" x1={118.243} y1={9.633} x2={118.243} y2={42.724} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <linearGradient id="v" x1={68.219} y1={76.793} x2={39.032} y2={105.98} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF2644" />
        <stop offset={1} stopColor="#FF2982" />
      </linearGradient>
      <linearGradient id="y" x1={48.183} y1={74.801} x2={48.183} y2={118.86} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <linearGradient id="z" x1={89.1} y1={39.601} x2={89.1} y2={66.601} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8000FF" />
        <stop offset={1} stopColor="#CC3DFF" />
      </linearGradient>
      <radialGradient
        id="c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-19.958 223.598 7.73) scale(36.0075 6.12204)"
      >
        <stop offset={0.164} stopColor="#FF8DB0" />
        <stop offset={1} stopColor="#FF8DB0" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="f"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(86.482 -20.318 84.838) scale(34.3758 29.6373)"
      >
        <stop offset={0.757} stopColor="#E76CBE" stopOpacity={0} />
        <stop offset={0.951} stopColor="#E76CBE" />
      </radialGradient>
      <radialGradient
        id="j"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-59.49579 -45.74023 85.97964 -111.83649 107.864 97.096)"
      >
        <stop offset={0.811} stopColor="#E7E5E5" stopOpacity={0} />
        <stop offset={1} stopColor="#E7E5E5" />
      </radialGradient>
      <radialGradient
        id="k"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-56.929 109.096 9.846) scale(44.1171 20.7346)"
      >
        <stop offset={0.281} stopColor="#B5A3A5" />
        <stop offset={1} stopColor="#B5A3A5" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="l"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(11.02612 -37.52397 6.35833 1.86835 28.549 90.408)"
      >
        <stop offset={0.208} stopColor="#B28F96" />
        <stop offset={1} stopColor="#B28F96" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="m"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-43.56002 40.21856 -12.4874 -13.5249 121.994 31.995)"
      >
        <stop stopColor="#FAECF1" />
        <stop offset={1} stopColor="#FAECF1" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="q"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(77.829 -44.423 67.305) scale(7.3368 6.48225)"
      >
        <stop stopColor="#8E839A" />
        <stop offset={1} stopColor="#8E839A" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="t"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(135 60.61 36.567) scale(37.1105 8.90865)"
      >
        <stop offset={0.189} stopColor="#FF5971" />
        <stop offset={1} stopColor="#FF5971" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="w"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(31.3594 -11.95304 3.1292 8.20962 28.061 87.4)"
      >
        <stop stopColor="#FF95AF" />
        <stop offset={1} stopColor="#FF95AF" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="x"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(14.74513 14.59376 -4.31085 4.35556 24.29 91.386)"
      >
        <stop offset={0.382} stopColor="#E14678" />
        <stop offset={1} stopColor="#E14678" stopOpacity={0} />
      </radialGradient>
      <filter
        id="h"
        x={28.102}
        y={15.027}
        width={100.106}
        height={100.618}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx={-0.45} dy={-0.9} />
        <feGaussianBlur stdDeviation={1.35} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.745098 0 0 0 0 0.772549 0 0 0 0 0.952941 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_215_22011" />
      </filter>
      <filter
        id="n"
        x={9.393}
        y={101.4}
        width={34.083}
        height={31.936}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={-0.36} />
        <feGaussianBlur stdDeviation={0.36} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.505882 0 0 0 0 0.360784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_215_22011" />
      </filter>
      <filter
        id="A"
        x={64.8}
        y={48.601}
        width={91.8}
        height={95.4}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={9} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_215_22011" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={7.2} />
        <feGaussianBlur stdDeviation={10.8} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.320833 0 0 0 0 0.077 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend in2="effect1_backgroundBlur_215_22011" result="effect2_dropShadow_215_22011" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_215_22011" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.8} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.9168 0 0 0 0 0.74 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect3_innerShadow_215_22011" />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h144v144H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default RocketCompleteIcon;
