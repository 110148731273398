import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import Icon from 'common/Icon';
import first from 'lodash/fp/first';
import isEmpty from 'lodash/fp/isEmpty';
import isObject from 'lodash/fp/isObject';
import map from 'lodash/fp/map';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import normalize from 'utils/normalize';
import companyService from '../api/companyService';
import talentService from '../api/talentService';
import { profileActions, profileSelectors } from '../store/profile';
import Tag, { Wrapper } from './Tag';

const StyledWrapper = styled(Wrapper)`
  cursor: pointer;
  ${props => (props.expanded ? 'line-height: 30px' : 'line-height: 20px')}
`;

const Tags = ({
  afterDrag,
  callService = true,
  hasDragPermission = false,
  maxWidth,
  page = 'about',
  portfolio = null,
  project = null,
  service = null,
  tags,
  type,
  useLimit = true
}) => {
  const profile = useSelector(profileSelectors.selectProfile);
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const [activeId, setActiveId] = useState(undefined);
  const dispatch = useDispatch();
  const [sliceTo, setSliceTo] = useState(
    localStorage.getItem(`slice_${type}`) ? parseInt(localStorage.getItem(`slice_${type}`)) : 11
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const [tagIds, setTagIds] = React.useState(map(tag => tag._id ?? tag, tags));
  const [normalizedTags, setNormalizedTags] = React.useState(normalize(tags));

  useEffect(() => {
    window.addEventListener('beforeunload', clearSlice);
    return () => {
      window.removeEventListener('beforeunload', clearSlice);
    };
  }, []);

  const clearSlice = () => {
    localStorage.setItem(`slice_${type}`, 11);
  };

  useEffect(() => {
    setTagIds(map(tag => tag._id ?? tag, tags));
    isObject(first(tags)) && setNormalizedTags(normalize(tags));
  }, [tags]);

  const updateTags = (newTagList, sortedArray) => {
    const serviceType = isCompanyView ? companyService : talentService;
    const id = isCompanyView ? myProfileId : profile.id;
    serviceType
      .setTags(id, {
        [type.toString()]: sortedArray,
        type,
        service,
        portfolio,
        project
      })
      .then(response => {
        if (isCompanyView) {
          if (project) {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'generalProjects',
                updateType: 'update',
                value: response.data.data,
                profileId: id
              })
            );
          } else {
            const services = {};
            response.data.data.map(service => (services[service._id] = service));
            dispatch(
              profileActions.updateProfileProperty({
                property: 'services',
                value: services,
                profileId: id
              })
            );
          }
        } else if (page === 'services') {
          const services = {};
          response.data.data.map(service => {
            services[service._id] = service;
          });
          dispatch(
            profileActions.updateProfileProperty({
              property: 'services',
              value: services,
              profileId: id
            })
          );
        } else if (portfolio) {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'projects',
              updateType: 'update',
              value: response.data.data,
              profileId: id
            })
          );
        } else {
          dispatch(
            profileActions.updateProfileProperty({
              property: `about.${type}`,
              value: response.data.data.about[type],
              profileId: id
            })
          );
        }

        if (type === 'keywords') {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'keywords',
              value: newTagList,
              profileId: id
            })
          );
        }
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd() {
    setActiveId(null);
  }

  function handleDragOver(event) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setTagIds(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const handleMoreClick = () => {
    if (sliceTo === 11) {
      setSliceTo(24);
      localStorage.setItem(`slice_${type}`, '24');
    } else {
      setSliceTo(11);
      localStorage.setItem(`slice_${type}`, '11');
    }
  };

  useEffect(() => {
    if (activeId === null) {
      const newTagList = tagIds.reduce((curr, id) => {
        if (normalizedTags[id]) {
          curr.push(normalizedTags[id]);
        }
        return curr;
      }, []);

      if (afterDrag) {
        afterDrag(type, newTagList);
      }

      if (callService) {
        updateTags(newTagList, tagIds);
      }
    }
  }, [activeId]);

  if (!tags || isEmpty(tags)) return null;

  const slicedTagList = tagIds.slice(0, sliceTo);
  const moreThanTwelve = tagIds.length > 12;
  const expanded = !!(moreThanTwelve && sliceTo > 11);

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragEnd}
      >
        <SortableContext items={tagIds} strategy={() => {}}>
          {slicedTagList.map(tag => (
            <Tag
              key={tag}
              hasDragPermission={hasDragPermission}
              id={hasDragPermission && tag}
              tag={normalizedTags[tag]}
              maxWidth={maxWidth}
              useLimit={useLimit}
            />
          ))}
          {moreThanTwelve && (
            <StyledWrapper onClick={handleMoreClick} expanded key="more">
              <Icon icon={`${expanded ? 'close' : 'dots'}`} width={10} height={10} />
            </StyledWrapper>
          )}
        </SortableContext>
        <DragOverlay>{activeId ? <Tag tag={normalizedTags[activeId]} /> : null}</DragOverlay>
      </DndContext>
    </>
  );
};

export default Tags;
