import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import FormTextarea from 'common/form/FormTextarea';
import TxplModal from 'common/TxplModal';

import TalentExperienceContext from './TalentExperienceContext';

const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const initialAccomplishments = ({ accomplishments }) =>
  accomplishments.length
    ? accomplishments.map((curr, index) => ({ id: index + 1, text: curr }))
    : [
        { id: 1, text: '' },
        { id: 2, text: '' },
        { id: 3, text: '' }
      ];

const AccomplishmentsModal = ({ closePortal, name }) => {
  const { onFieldChange, values } = useContext(TalentExperienceContext);
  const [error, updateError] = useState();
  const [update, setUpdate] = useState(true);
  const [accomplishments, updateAccomplishments] = useState(initialAccomplishments(values));

  const updateAccomplishment = accomplishmentId => event => {
    const {
      target: { value }
    } = event;

    updateAccomplishments(items => items.map(item => (item.id === accomplishmentId ? { ...item, text: value } : item)));
  };

  const addAccomplishment = () => {
    updateAccomplishments(items => items.concat([{ id: items.length + 1, text: '' }]));
  };

  useEffect(() => {
    const arr = accomplishments.filter(curr => curr.text.length >= 3);
    if (arr.length === 3) {
      onFieldChange('accomplishments')(accomplishments.filter(curr => curr.text).map(curr => curr.text));
    }
  }, [update]);

  const onSubmit = () => {
    setUpdate(!update);
    updateError();
    if (accomplishments.filter(curr => curr.text.trim()).length < 3) {
      updateError('Required');
      const firstError = document.getElementsByClassName(`required-required`);
      firstError?.length && firstError[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      return;
    }

    onFieldChange('accomplishments')(accomplishments.filter(curr => curr.text).map(curr => curr.text));
    setTimeout(() => closePortal(), 500);
  };

  return (
    <TxplModal
      title="Accomplishments"
      subTitle="List 3–5 wins that, let’s just say, got you to the next level"
      appElement={document.getElementById('root-modal')}
      name={name}
      renderFooter={
        <StyledButtonsContainer>
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={onSubmit}>Save</ContainedButton>
        </StyledButtonsContainer>
      }
    >
      {accomplishments.map((accomplishment, index) => (
        <FormTextarea
          key={index}
          error={!accomplishment.text.trim() && error && index <= 2}
          value={accomplishment.text}
          label={`Accomplishment #${index + 1}`}
          required={index <= 2}
          onChange={updateAccomplishment(accomplishment.id)}
          maxLength={300}
          className={accomplishment.text.length === 0 && 'required-required'}
        />
      ))}

      {accomplishments.length < 5 && (
        <ContainedButton onClick={addAccomplishment} icon="plus">
          Add another
        </ContainedButton>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('accomplishments-modal')(AccomplishmentsModal);
