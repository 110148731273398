import React from 'react';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';

const StyledTxplModalContent = styled.div`
  flex-grow: 3;
  overflow: auto;
  ${props => props.noPaddingTop && 'padding-top: 0px;'}
  ${props => props.noScroll && 'overflow: visible;'}
  padding: ${props => (props.padding ? props.padding : '55px 0px 56px 0')};

  @media ${pageSize.XS} {
    padding: ${props => (props.XSpadding ? props.XSpadding : '56px 0 82px')};
    ${props => props.centerFlex && 'display: flex;'}
  }
`;

const TxplModalContent = ({ XSpadding, centerFlex, children, noPaddingTop, noScroll, padding }) => (
  <StyledTxplModalContent
    noPaddingTop={noPaddingTop}
    noScroll={noScroll}
    XSpadding={XSpadding}
    centerFlex={centerFlex}
    padding={padding}
  >
    {children}
  </StyledTxplModalContent>
);

export default TxplModalContent;
