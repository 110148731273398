import isEmpty from 'lodash/fp/isEmpty';
/**
 *
 * @param {arr} - array of data object
 * @param {valueField} -  key of field, which value should be used as 'value' key in return data
 * @param {labelField} -  key of field, which value should be used as 'label' key in return data
 * @returns {arr} - array of selectable data object with { value, label } structure
 */

export const getFormSelectTypeArray = ({ arr = [], valueField = '_id', labelField = 'name' }) =>
  (arr ?? []).reduce((curr, item) => {
    if (item !== null && item?.value !== null) {
      curr.push({
        value: item[valueField],
        label: item[labelField],
        item
      });
    }
    return curr;
  }, []);

export const getArrayFromSelectTypeArray = ({ arr = [], valueField = '_id', labelField = 'name' }) => {
  const result = (arr ?? [])
    .filter(item => item !== null)
    .map(item => ({
      [valueField]: item.value,
      [labelField]: item.label
    }));

  return result.filter(item => item.value !== null);
};

export const getFormSelectValue = (item, formLabel = 'name', formValue = '_id') => {
  if (isEmpty(item)) {
    return null;
  }

  return {
    label: item[formLabel],
    value: item[formValue]
  };
};
