import React, { useState } from 'react';

import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import Switcher from 'common/form/Switcher';
import Tooltip from 'components/Tooltip';
import { backOfficeService } from 'api';
import * as Styled from './StyledLockPageEditModal';
import RichTextEditor from 'common/RichTextEditor';

const LockPageEditModal = ({ closePortal, data, name, reload, setReload }) => {
  const { comment, entity, id, locked, title } = data;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(comment);
  const [checked, setChecked] = useState(!locked);

  const handleSave = async () => {
    let entityType = '';
    switch (entity) {
      case 'portfolio':
        entityType = 'project';
        break;

      case 'credit':
        entityType = 'credit';
        break;

      case 'Experience':
        entityType = 'experience';
        break;

      case 'Media coverage':
        entityType = 'mediaCoverage';
    }

    setLoading(true);
    await backOfficeService.lockUnlock(id, {
      type: entityType,
      action: checked ? 'unlock' : 'lock',
      comment: value ? value.replace(/(<([^>]+)>)/gi, '') : ''
    });
    setReload(!reload);
    setLoading(false);
    closePortal();
  };

  return (
    <TxplModal
      name={name}
      title="Lock/unlock user input"
      padding="56px 0 82px 0"
      addPaddingTopBig
      titleMB="16"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loading && <Spinner mr={6} />}
          <Styled.CancelButton forceDisplayText onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </Styled.CancelButton>
          <div data-tip data-for={`tooltip-for-${data.id}`}>
            <ContainedButton onClick={handleSave} forceDisplayText disabled={data.vettingStatus !== 'IN_PROGRESS'}>
              Save
            </ContainedButton>
            {data.vettingStatus !== 'IN_PROGRESS' && (
              <Tooltip tooltipId={`tooltip-for-${data.id}`}>
                {`Can't lock/unlock Entity with vetting statu ${data.vettingStatus}`}
              </Tooltip>
            )}
          </div>
        </>
      }
    >
      <Styled.InformationBoard>
        <Styled.Section>
          <Styled.SectionText color="rgba(230, 234, 255, 0.35)">Section</Styled.SectionText>
          <Styled.SectionText color="white">{entity}</Styled.SectionText>
        </Styled.Section>
        <Styled.Section>
          <Styled.SectionText color="rgba(230, 234, 255, 0.35)">Input Title</Styled.SectionText>
          <Styled.SectionText color="white">{title}</Styled.SectionText>
        </Styled.Section>
      </Styled.InformationBoard>
      <Typography
        mt="56"
        mb="8"
        color="white"
        fontFamily="Space Grotesk"
        fontWeight="bold"
        fontSize="19px"
        lineHeight="26px"
      >
        Status
      </Typography>
      <Styled.SwitcherWrapper>
        <div data-tip data-for={`tooltip-for-${data.id}`}>
          <Switcher
            checked={checked}
            setChecked={setChecked}
            icon
            noFill
            disabled={data.vettingStatus !== 'IN_PROGRESS'}
          />
          <Styled.SwitcherState>{checked ? 'Unlocked' : 'Locked'}</Styled.SwitcherState>
          {data.vettingStatus !== 'IN_PROGRESS' && (
            <Tooltip tooltipId={`tooltip-for-${data.id}`}>
              {`Can't lock/unlock Entity with vetting statu ${data.vettingStatus}`}
            </Tooltip>
          )}
        </div>
      </Styled.SwitcherWrapper>
      <Typography
        mt="56"
        mb="6"
        color="white"
        fontFamily="Space Grotesk"
        fontWeight="bold"
        fontSize="19px"
        lineHeight="26px"
      >
        Add a comment
      </Typography>
      <RichTextEditor
        value={value}
        maxLength={400}
        onChange={text => setValue(text)}
        placeholder="Add any comments here"
        modules={{
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']]
        }}
      />
    </TxplModal>
  );
};

export default withRenderPortal('lock-page-edit-modal')(LockPageEditModal);
