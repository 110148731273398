import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { portalActions } from 'store/portals';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';

const StyledViewExperienceFooterContainer = styled.div`
  background: rgba(204, 213, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledRightSideContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const EditExperienceFooter = ({ dirty, experienceId, save, submitting, toggleMode }) => {
  const dispatch = useDispatch();

  const onCancel = () => {
    if (!dirty) {
      return toggleMode();
    }
    dispatch(
      portalActions.openPortal({
        name: 'leave-page-modal',
        data: {
          onOk: () => {
            toggleMode();
          },
          onCancel: () => {}
        }
      })
    );
  };

  return (
    <StyledViewExperienceFooterContainer>
      <ContainedButton
        opens="delete-experience-modal"
        data={{ experienceId, onSuccess: () => toggleMode() }}
        color="#FF5151"
        backgroundColor="transparent"
        type="button"
      >
        Delete
      </ContainedButton>

      <StyledRightSideContainer>
        {submitting && <Spinner />}
        <ContainedButton type="button" backgroundColor="rgba(204, 213, 255, 0.11)" onClick={onCancel}>
          Cancel
        </ContainedButton>
        <ContainedButton onClick={save}>Save</ContainedButton>
      </StyledRightSideContainer>
    </StyledViewExperienceFooterContainer>
  );
};

export default EditExperienceFooter;
