import * as portalActionTypes from './portalActionTypes';

export const openPortal = ({ data, name }) => ({
  type: portalActionTypes.OPEN_PORTAL,
  payload: {
    name,
    data
  }
});

export const closePortal = ({ name }) => ({
  type: portalActionTypes.CLOSE_PORTAL,
  payload: {
    name
  }
});

export const clearPortal = () => ({
  type: portalActionTypes.CLEAR_PORTAL
});
