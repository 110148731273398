import React from 'react';
import styled from '@emotion/styled';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const StyledCropper = styled(Cropper)`
  object-fit: contain;
  width: 100%;
`;

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const FormCropper = ({ aspect, image, isResizable = true, minHeight = '', minWidth = '', viewMode = 0 }, ref) => {
  const cropperRef = React.useRef(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    return dataURLtoBlob(cropper?.getCroppedCanvas().toDataURL());
  };

  React.useEffect(() => {
    ref.current = { getImage: onCrop };
  }, [ref, onCrop]);
  return (
    <StyledCropper
      src={image}
      aspectRatio={aspect}
      guides={false}
      viewMode={viewMode}
      ref={cropperRef}
      cropBoxResizable={isResizable}
      minCropBoxWidth={minWidth}
      minCropBoxHeight={minHeight}
    />
  );
};

export default React.forwardRef(FormCropper);
