import React from 'react';
import { Document, Page } from 'react-pdf';
import styled from '@emotion/styled';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  max-height: 100vh;
  height: 100%;
  width: 100%;

  .react-pdf__Document {
    width: 100%;
    height: 100%;
    ${props => `max-height: ${props.maxHeight}px;`}
  }
`;

const AssetPdf = ({ url }) => {
  const [numPages, setNumPages] = React.useState(null);
  const containerRef = React.useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pages = React.useMemo(() => {
    const { height, width } = containerRef.current?.getBoundingClientRect() || {};
    const arr = Array.from(new Array(numPages));

    return arr.map((_, index) => (
      <Page scale={1} key={`page_${index + 1}`} pageNumber={index + 1} width={width - 20} height={height} />
    ));
  }, [numPages, containerRef]);

  return (
    <StyledContainer ref={containerRef}>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {pages}
      </Document>
    </StyledContainer>
  );
};

export default AssetPdf;
