import { useSnackbar } from 'react-simple-snackbar';

const initialOptions = {
  position: 'top-right',
  style: {
    backgroundColor: '#00FF00',
    borderRadius: '5px',
    color: '#000000',
    fontFamily: 'inherit',
    fontSize: '16px',
    textAlign: 'center',
    minWidth: 'auto'
  },
  closeStyle: {
    display: 'none'
  }
};

const useInfoSnackbar = () => {
  const options = { ...initialOptions, style: { ...initialOptions.style, backgroundColor: 'skyblue' } };

  const [openSnackbar] = useSnackbar(options);

  const openInfoSnackbar = title => {
    openSnackbar(title, [1500]);
  };

  return {
    openInfoSnackbar
  };
};

const useErrorSnackbar = () => {
  const options = { ...initialOptions, style: { ...initialOptions.style, backgroundColor: 'red' } };

  const [openSnackbar] = useSnackbar(options);

  const openErrorSnackbar = title => {
    openSnackbar(title, [1500]);
  };

  return {
    openErrorSnackbar
  };
};

const useSuccessSnackbar = customOptions => {
  const { showClose = false } = customOptions ?? {};

  const closeStyle = showClose
    ? {
        display: 'initial',
        color: '#000000'
      }
    : {};

  const options = {
    ...initialOptions,
    style: {
      ...initialOptions.style,
      backgroundColor: '#00FF00'
    },
    closeStyle: {
      ...initialOptions.closeStyle,
      ...closeStyle
    }
  };

  const [openSnackbar] = useSnackbar(options);

  const openSuccessSnackbar = (title, delay = 1500) => {
    openSnackbar(title, [delay]);
  };

  return {
    openSuccessSnackbar
  };
};

const useWarningSnackbar = () => {
  const options = { ...initialOptions, style: { ...initialOptions.style, backgroundColor: 'orange' } };

  const [openSnackbar] = useSnackbar(options);

  const openWarningSnackbar = title => {
    openSnackbar(title, [1500]);
  };

  return {
    openWarningSnackbar
  };
};

export { useSuccessSnackbar, useErrorSnackbar, useWarningSnackbar, useInfoSnackbar };
