import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { userService } from 'api';
import useForm from 'hooks/useForm';
import Spinner from 'common/Spinner';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';

import MailSentMessage from './MailSentMessage';
import * as Styled from './StyledSplashForm';

const fields = { email: { initialValue: '', validate: ['isRequired', 'isEmail'] } };

const ForgotPassword = ({ linkExpired, subTitle, title }) => {
  const history = useHistory();
  const [mailSent, setMailSent] = useState(false);
  const [expired, setExpired] = useState(true);

  useEffect(() => {
    if (linkExpired && !mailSent) setExpired(false);
  }, []);

  const { clearErrors, errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: params => userService.forgotPassword(params),
    onSuccess: () => setMailSent(true)
  });

  const handleChange = e => {
    onFieldChange('email')(e.target.value);
    if (errors) clearErrors();
  };

  const handleBackTo = () => history.push('/login');

  return (
    <Styled.SplashFormWrapper>
      <Styled.SplashHeader>
        <Typography variant="h2" fontSize="36px" lineHeight="40px">
          {mailSent ? 'Check your email' : title ?? 'Forgot password?'}
        </Typography>
      </Styled.SplashHeader>
      <Styled.SplashForm onSubmit={onSubmit} id="forgotPassword" autoComplete="on">
        {!mailSent && (
          <>
            <Typography pb={40} variant="medium" color="rgba(230, 234, 255, 0.35)">
              {subTitle ??
                "Enter the email associated with your account and we'll send you a link to reset you password."}
            </Typography>
            <FormInput
              mb={24}
              autoFocus
              hintText=""
              type="email"
              label="Email"
              title="Email"
              inputGap="6px"
              value={values.email}
              onChange={handleChange}
              error={errors.email || errors.message}
            />
          </>
        )}
        {mailSent && <MailSentMessage email={values.email} />}
        <Styled.SplashFormFooter buttonToEnd={linkExpired && !mailSent}>
          {(expired || (!expired && mailSent)) && (
            <ContainedButton className="transparent" type="button" padding="12px 16px" onClick={handleBackTo}>
              back to sign in
            </ContainedButton>
          )}
          {!mailSent && (
            <Styled.Wrapper>
              {submitting && <Spinner />}
              <ContainedButton type="submit" form="forgotPassword" padding="12px 16px">
                send link
              </ContainedButton>
            </Styled.Wrapper>
          )}
        </Styled.SplashFormFooter>
      </Styled.SplashForm>
    </Styled.SplashFormWrapper>
  );
};

export default ForgotPassword;
