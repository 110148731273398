/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { fromJS } from 'immutable';
import { api } from 'api';
import { ActionColumn, HeaderColumn } from './column';
import Pagination from './Pagination';
import TableLoader from './TableLoader';
import SearchRow from './SearchRow';

import {
  StyledBody,
  StyledColumn,
  StyledColumnItem,
  StyledHeader,
  StyledHeaderColumn,
  StyledTable,
  StyledTableContainer,
  StyledTableWrapper
} from './TableStyles';
import LockPageEditModal from 'pages/backoffice/lockPage/LockPageEditModal';
import UpdateVettingLogModal from 'components/modals/UpdateVettingLogModal/UpdateVettingLogModal';

const findSortDir = (sortDirs, keyName) => {
  const foundDir = sortDirs.find(sortName => sortName.startsWith(keyName));
  if (foundDir) return foundDir.split(':')[1];
  return 'desc';
};

const HeaderColumns = React.memo(({ children, handleSort, sort: sortDirs }) =>
  children.map(({ props }) => (
    <HeaderColumn
      title={props.title}
      sort={props.sort}
      sortDir={findSortDir(sortDirs, props.keyName)}
      handleSort={() => handleSort(props.keyName)}
      key={props.title}
      type={props.type}
    />
  ))
);

const TableColumn = React.memo(({ item, props, setData, tableRef }) => {
  const { data, keyName, maxWidth, onClick, opens, padding, pl, pr, preWrap, render, type, width } = props;

  if (type === 'action' && !render) {
    return (
      <ActionColumn
        onClick={() => onClick && onClick(item)}
        opens={opens}
        data={data && data({ ...item, tableRef }, setData)}
        width={width}
        pl={pl}
        pr={pr}
        maxWidth={maxWidth}
      />
    );
  }

  if (render) {
    return (
      <StyledColumnItem preWrap={preWrap} padding={padding} width={width} maxWidth={maxWidth} pl={pl} pr={pr}>
        {render(item)}
      </StyledColumnItem>
    );
  }

  const renderName = fromJS(item).getIn(keyName.split('.'));

  return (
    <StyledColumnItem preWrap={preWrap} padding={padding} width={width} maxWidth={maxWidth} pl={pl} pr={pr}>
      {renderName}
    </StyledColumnItem>
  );
});

const TableRows = React.memo(({ children, data, keyId, padding, preWrap, setData, tableRef }) =>
  data.map(item => (
    <StyledColumn key={item[keyId]}>
      {children.map(({ props }) => {
        return (
          <TableColumn
            preWrap={preWrap}
            padding={padding}
            key={`${item[keyId || '_id']}-${props.keyName}`}
            item={item}
            setData={setData}
            keyId={keyId}
            props={props}
            tableRef={tableRef}
          />
        );
      })}
    </StyledColumn>
  ))
);

const Table = (
  {
    adjustHeight,
    children,
    dataKey,
    delayFetch,
    endpoint,
    forceReload = false,
    keyId,
    padding,
    paginate,
    preWrap,
    queryParams,
    setForceReload,
    staticData
  },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(staticData || []);
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(forceReload);

  const location = useLocation();
  const history = useHistory();
  const timeoutRef = useRef();

  const query = useMemo(() => queryString.parse(location.search, { arrayFormat: 'bracket' }), [location.search]);

  const { sort = [], page = 0, rowsPerPage = 10, ...search } = query;

  const getData = useCallback(
    (queryObject = {}) => {
      api
        .get(`${endpoint}`, { params: { ...queryObject, ...(queryParams || {}), page, rowsPerPage } })
        .then(res => {
          setData(res.data[dataKey]);
          setCount(res.data.count);
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setLoading(false);
          if (forceReload) {
            setForceReload(false);
          }
        });
    },
    [endpoint, queryParams, page, rowsPerPage, dataKey, forceReload]
  );

  useEffect(() => setData(staticData || []), [staticData]);

  useEffect(() => {
    if (staticData || delayFetch) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setLoading(true);
    timeoutRef.current = setTimeout(() => getData(query), 100);
  }, [timeoutRef, getData, query, setLoading, staticData, delayFetch, reload, forceReload]);

  useEffect(() => {
    if (ref) {
      ref.current = { refetch: () => getData(queryString.parse(window.location.search, { arrayFormat: 'bracket' })) };
    }
  }, [getData, ref]);

  const handleSort = keyName => {
    const sortIndex = sort.findIndex(sortName => sortName.startsWith(keyName));

    const jsonQuery = { ...query };

    if (sortIndex !== -1) {
      const [name, dir] = sort[sortIndex].split(':');

      const newDir = dir === 'asc' ? 'desc' : 'asc';

      jsonQuery.sort = [...sort.slice(0, sortIndex), `${name}:${newDir}`, ...sort.slice(sortIndex + 1)];
    } else {
      jsonQuery.sort = sort.concat(`${keyName}:asc`);
    }

    const formattedQuery = queryString.stringify(jsonQuery, { arrayFormat: 'bracket' });

    history.push({
      pathname: location.pathname,
      search: formattedQuery
    });
  };

  const handleSearch = React.useCallback(
    (keyName, value) => {
      const newQuery = queryString.stringify({ ...query, [keyName]: value, page: 0 }, { arrayFormat: 'bracket' });

      history.push({
        pathname: location.pathname,
        search: newQuery
      });
    },
    [query, location.pathname, history]
  );

  const handlePageChange = value => {
    const newQuery = queryString.stringify({ ...query, page: value }, { arrayFormat: 'bracket' });

    history.push({
      pathname: location.pathname,
      search: newQuery
    });
  };

  const handleRowsPerPageChange = value => {
    const newQuery = queryString.stringify({ ...query, rowsPerPage: value, page: 0 }, { arrayFormat: 'bracket' });

    history.push({
      pathname: location.pathname,
      search: newQuery
    });
  };

  const normalizedChildren = React.useMemo(() => children.filter(child => child), [children]);

  const renderSearch = React.useMemo(() => children.some(({ props }) => props?.search), [children]);

  return (
    <StyledTableContainer>
      <StyledTableWrapper adjustHeight={adjustHeight}>
        <StyledTable>
          <StyledHeader>
            <StyledHeaderColumn>
              <HeaderColumns handleSort={handleSort} sort={sort}>
                {normalizedChildren}
              </HeaderColumns>
            </StyledHeaderColumn>
          </StyledHeader>
          <StyledBody renderSearch={renderSearch}>
            {renderSearch && (
              <SearchRow search={search} handleSearch={handleSearch}>
                {normalizedChildren}
              </SearchRow>
            )}
            {loading && <TableLoader />}
            <TableRows
              data={loading ? [] : data}
              setData={setData}
              keyId={keyId}
              tableRef={ref?.current}
              preWrap={preWrap}
              padding={padding}
            >
              {normalizedChildren}
            </TableRows>
          </StyledBody>
        </StyledTable>
      </StyledTableWrapper>

      {paginate && (
        <Pagination
          rowsPerPage={+rowsPerPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          page={+page}
          handlePageChange={handlePageChange}
          dataLen={count}
        />
      )}

      <LockPageEditModal reload={reload} setReload={setReload} />
      <UpdateVettingLogModal reload={reload} setReload={setReload} />
    </StyledTableContainer>
  );
};

export default React.forwardRef(Table);
