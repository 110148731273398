import Icon from 'common/Icon';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { profileSelectors } from 'store/profile';
import { DeleteButton, SearchedText, SmallText, StyledSearchedBox, TextWrapper } from './styled.components';

const SearchBox = ({ color, icon, id, isCompany, label, newJobs, onDelete, search, url }) => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const history = useHistory();
  const location = useLocation();

  return (
    <StyledSearchedBox
      key={id}
      onClick={() =>
        history.push(url ? url.slice(url.indexOf('/jobs')) : search ? { pathname: location.pathname, search } : '')
      }
      color={color}
    >
      <TextWrapper>
        <SearchedText>
          {icon && <Icon icon={icon} />}
          <span>{label}</span>
        </SearchedText>
        <SmallText>
          {newJobs === 0 ? 'no new jobs' : newJobs === 1 ? `${newJobs} new job` : newJobs >= 0 && `${newJobs} new jobs`}
        </SmallText>
      </TextWrapper>
      {onDelete && (
        <DeleteButton
          className="delete-btn"
          icon="delete"
          backgroundColor="transparent"
          onClick={e => {
            e.stopPropagation();
            onDelete && onDelete(profileId, id, isCompany);
          }}
        />
      )}
    </StyledSearchedBox>
  );
};

export default SearchBox;
