import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profileSelectors } from 'store/profile';
import { portalActions } from 'store/portals';

import Frame from 'common/Frame';
import Typography from 'components/shared/Typography';
import { ReactComponent as IconKebab } from 'images/assets/icons_16x16/kebab.svg';

import CollaboratorsModal from '../CollaboratorsModal/CollaboratorsModal';
import CollaboratorCard from '../CollaboratorCard';

import { StyledGridContainer, StyledShowMoreContainer } from './CollaboratorsFrameStyles';
import AllCollaboratorsModal from './AllCollaboratorsModal';

const CollaboratorsFrame = ({
  collaborators = [],
  project,
  error,
  isEditMode,
  onFieldChange,
  onSave,
  emptyLabel,
  fullLabel,
  mb,
  loading,
  notClosePortal,
  ...rest
}) => {
  const dispatch = useDispatch();
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const renderCollaborators = React.useMemo(() => collaborators.slice(0, 3), [collaborators]);

  const shouldRenderToggle = React.useMemo(() => collaborators.length > 0, [collaborators]);

  const handleOpenCollaborators = () => {
    dispatch(
      portalActions.openPortal({
        name: 'collaborators-list',
        data: { collaborators, notClosePortal }
      })
    );
  };

  const displayLabel = React.useMemo(() => (collaborators?.length ? fullLabel : emptyLabel), [collaborators?.length]);

  const roleList = React.useMemo(
    () =>
      renderCollaborators.reduce(
        (acc, curr) => ({ ...acc, [curr.role ? curr.role._id : curr._id]: curr.role ? curr.role.name : curr.name }),
        {}
      ),
    [renderCollaborators]
  );

  return (
    <Frame
      error={error}
      style={{ marginBottom: mb }}
      color="#B6E5FF"
      text="Collaborators"
      titlePB={24}
      canEdit={hasPermission && isEditMode}
      project={project}
      editMode={collaborators.length ? 'edit' : 'add'}
      opens="collaborators-modal"
      data={{ notClosePortal }}
      filled={!!collaborators.length}
      noDataText="Expand your network — add collaborators"
      {...rest}
      id="collaborators"
      className="collaborators-required"
    >
      <AllCollaboratorsModal />
      <StyledGridContainer>
        {renderCollaborators.map(curr => (
          <CollaboratorCard key={curr.email} collaborator={curr} roleList={roleList} />
        ))}
        {shouldRenderToggle && (
          <StyledShowMoreContainer onClick={handleOpenCollaborators}>
            <IconKebab />
          </StyledShowMoreContainer>
        )}
      </StyledGridContainer>

      <CollaboratorsModal
        label={displayLabel}
        collaborators={collaborators}
        onFieldChange={onFieldChange}
        onSave={onSave}
        loading={loading}
        project={project}
      />
      {error && (
        <Typography mt={30} error variant="small">
          {error}
        </Typography>
      )}
    </Frame>
  );
};

export default CollaboratorsFrame;
