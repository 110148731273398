import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import tagService from 'api/tagService';
import useTagService from 'hooks/api/useTagService';

import { selectAllTags } from 'store/tags/tagSelectors';
import { tagActions } from 'store/tags';

import GameSearch from 'components/GameSearch';
import FormUpload from 'common/form/FormUpload';
import FormTextArea from 'common/form/FormTextarea';
import FormSelect from 'common/form/FormSelect';
import { tagKeys } from '../../../../constants';
import { getFormSelectTypeArray } from 'utils/tagHelpers';

const loadingDefaultValues = { platform: false, genre: false, tag: false };

const EditProjectForm = ({ errors, onFieldChange, preview, values }) => {
  const dispatch = useDispatch();
  const tags = useSelector(selectAllTags);

  const { getTags } = useTagService();

  const [loading, setLoading] = useState(loadingDefaultValues);

  const handleTagCreate = async (value, type) => {
    try {
      setLoading({ ...loading, [type]: true });
      const {
        data: { data }
      } = await tagService.requestTag({ name: value, type });
      const newTag = { value: data._id, label: data.name };

      let field = '';
      switch (type) {
        case 'platform':
          field = 'platforms';
          break;
        case 'genre':
          field = 'genres';
          break;
        case 'tag':
          field = 'tags';
          break;
        default:
          field = '';
      }

      const modifiedTags = [...values[field], newTag];
      onFieldChange(field)(modifiedTags);
      dispatch(tagActions.addTag(field, data));
    } finally {
      setLoading({ ...loading, [type]: false });
    }
  };

  const handleGameSearchChange = option => {
    if (option.requestedBy) {
      onFieldChange('name')(option.title);
      onFieldChange('overview')('');
      onFieldChange('coverImage')('');
      onFieldChange('cover')('');
      onFieldChange('platforms')([]);
      onFieldChange('genres')([]);
    } else {
      onFieldChange('name')(option.title);
      onFieldChange('overview')(option.overview || '');
      onFieldChange('coverImage')(option.cover || '');
      onFieldChange('cover')(option.cover || '');
      const platforms = tags.platforms
        .filter(item => option.platforms.includes(item.name))
        .map(item => ({ value: item._id, label: item.name, item: item }));
      const genres = tags.genres
        .filter(item => option.platforms.includes(item.name))
        .map(item => ({ value: item._id, label: item.name, item: item }));
      onFieldChange('platforms')(platforms);
      onFieldChange('genres')(genres);
    }
  };

  const handleOptionsOpen = type => {
    const key = tagKeys[type];
    const fetchTags = async () => {
      await getTags(type);
    };
    if (!tags[key].isLoaded) {
      fetchTags();
    }
  };

  return (
    <form style={{ padding: '0px 0px 24px 0' }}>
      <GameSearch
        label="Project Title"
        required
        value={values.name}
        onChange={option => handleGameSearchChange(option)}
        error={errors.name}
      />
      <FormUpload
        mt={56}
        mb={56}
        required
        label="Project cover Image"
        hintText="Should be at least 700x400 pixels. PNG, JPG, GIF, 500Kb or less."
        error={errors.coverImage}
        value={values.coverImage}
        onChange={val => onFieldChange('coverImage')(val)}
        previewUrl={preview}
        preview
        image={values.coverImage}
        wrapperStyles={{ flexDirection: 'column', width: '100%' }}
        labelRight
        padding
        onRemoveClick={() => {
          onFieldChange('coverImage')('');
        }}
        aspectRatio={7 / 4}
      />
      <FormTextArea
        mb={56}
        required
        label="Overview"
        hintText="Provide some background on the project from a birds-eye view."
        value={values.overview?.replace(/<(.|\n)*?>/g, '')}
        onChange={onFieldChange('overview')}
        error={errors.overview}
        name="overview"
        maxLength={600}
      />
      <FormSelect
        mb={56}
        required
        label="Platforms"
        hintText="What platforms is this project made for?"
        menuShouldScrollIntoView={false}
        menuPosition="absolute"
        menuPlacement="bottom"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        maxOptions={23}
        customNoOptMessage="23 will do here"
        value={values.platforms}
        onChange={e => onFieldChange('platforms')(e || [])}
        onMenuOpen={() => handleOptionsOpen('platform')}
        error={errors.platforms}
        options={getFormSelectTypeArray({ arr: tags.platforms.data })}
        placeholder=".eg. Xbox1, PS Vista"
        isCreatable
        handleCreate={e => handleTagCreate(e, 'platform')}
        isLoading={tags.platforms.loading}
      />
      <FormSelect
        mb={56}
        label="Genres"
        hintText="What genres can this project be associated with?"
        menuShouldScrollIntoView={false}
        menuPosition="absolute"
        menuPlacement="bottom"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        maxOptions={23}
        customNoOptMessage="23 will do here"
        value={values.genres}
        onChange={e => onFieldChange('genres')(e || [])}
        onMenuOpen={() => handleOptionsOpen('genre')}
        options={getFormSelectTypeArray({ arr: tags.genres.data })}
        placeholder="e.g. first-person shooter, open-world, puzzle"
        isCreatable
        handleCreate={e => handleTagCreate(e, 'genre')}
        isLoading={tags.genres.loading}
      />
      <FormSelect
        label="Tags"
        hintText="What tags would you add to this project?"
        menuShouldScrollIntoView={false}
        menuPosition="absolute"
        menuPlacement="top"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        maxOptions={23}
        customNoOptMessage="23 will do here"
        value={values.tags}
        onChange={e => onFieldChange('tags')(e || [])}
        onMenuOpen={() => handleOptionsOpen('tag')}
        options={getFormSelectTypeArray({ arr: tags.tags.data })}
        placeholder="e.g. AAA, fantasy, Co-Op,  Power-Ups"
        isCreatable
        handleCreate={e => handleTagCreate(e, 'tag')}
        isLoading={tags.tags.loading}
      />
    </form>
  );
};

export default EditProjectForm;
