import styled from '@emotion/styled';
import React from 'react';
import { pageSize } from 'utils/devices';

const StyledSidebarFooter = styled.div`
  display: flex;
  flex-flow: column;
  gap: 6px;
  button.btn-primary,
  button.btn-secondary {
    justify-content: center;
  }
  @media ${pageSize.M}, ${pageSize.L} {
    .btn-primary,
    .btn-secondary {
      width: 100%;
    }
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    flex-flow: row;
    .btn-primary {
      width: 100%;
    }
  }
`;

function SidebarFooter({ children }) {
  return <StyledSidebarFooter>{children}</StyledSidebarFooter>;
}

export default SidebarFooter;
