import { isNotEmpty } from '@flybondi/ramda-land';

/**
 * Sends logged in user data to Pendo for tracking
 *
 * @param {object} user - user object
 * @param {object} talent - talent object
 * @param {object} company - company object
 * @returns {void}
 */

export const initializePendoService = ({ user, talent, company }) => {
  const { _id, email, funnelStatus, isCollaborator, status } = user;
  const { _id: talent_Id, name, type, isSupply, basicInfo, quickOnboardingCompleted, quickOnboardingStep } = talent;

  // build obj data to pass to Pendo
  const visitorData = {
    id: _id,
    email,
    funnelStatus: funnelStatus?.status,
    isCollaborator,
    status: status
  };

  if (isNotEmpty(talent)) {
    visitorData.talent_id = talent_Id;
    visitorData.name = name;
    visitorData.type = type;
    visitorData.isSupply = isSupply;
    visitorData.disciplines = basicInfo?.disciplines;
    visitorData.quickOnboardingCompleted = quickOnboardingCompleted;
    visitorData.quickOnboardingStep = quickOnboardingStep;
  }

  const accountData = {
    id: 'ACCOUNT-UNIQUE-ID'
  };

  // when logged in with a company add the information of it.
  if (isNotEmpty(company)) {
    const { _id, name, type, supplyDemand, basicInfo } = company;
    accountData.id = _id;
    accountData.companyName = name;
    accountData.companyType = type;
    accountData.companySupplyDemand = supplyDemand;
    accountData.companyDisciplines = basicInfo?.disciplines;
  }

  window.pendo.initialize({
    visitor: visitorData,
    account: accountData
  });
};
