import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';
import Typography from 'components/shared/Typography';
import BenefitModal from 'components/modals/BenefitModals/BenefitModal';
import AddBenefitModal from 'components/modals/BenefitModals/AddBenefitModal';
import AddFirstBenefitFrame from './AddFirstBenefitFrame/AddFirstBenefitFrame';
import lifeServices from '../../api/lifeServices';
import { getBenefits } from 'api/benefitsService';
import { profileSelectors } from '../../store/profile';
import {
  StyledBenefitsContainer,
  StyledCompanyBenefitItem,
  StyledCompanyBenefits,
  StyledEditButton
} from './StyledBenefits';

const Benefits = ({ categoryId, jobsPage }) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const [benefitList, setBenefitList] = useState([]);
  const [companyBenefits, setCompanyBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const [editBenefit, setEditBenefit] = useState({
    id: '',
    name: '',
    icon: '',
    iconColor: ''
  });

  useEffect(() => {
    getBenefits({
      companyId: companyId
    }).then(res => {
      setBenefitList(res.data.data);
    });
    if (categoryId) {
      lifeServices.getSavedBenefits(companyId, categoryId).then(res => {
        const benefits = res.data.data;
        setCompanyBenefits(benefits);
        setLoading(false);
      });
    }
  }, [categoryId]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {!companyBenefits?.length && !jobsPage && hasPermission && <AddFirstBenefitFrame />}
          {!!companyBenefits?.length && (
            <StyledBenefitsContainer>
              {hasPermission && !jobsPage && (
                <StyledEditButton
                  icon="edit"
                  opens="benefit-modal"
                  type="button"
                  iconColor="#FFFFFF;"
                  backgroundColor="#CCD4FF1C"
                  border="rgba(204, 213, 255, 0.11);"
                >
                  Edit
                </StyledEditButton>
              )}
              <StyledCompanyBenefits>
                {companyBenefits.map(myBenefit => (
                  <StyledCompanyBenefitItem key={myBenefit._id}>
                    <Icon icon={myBenefit.icon} color={myBenefit.iconColor} size={24} />
                    <Typography variant="h3">{myBenefit.name}</Typography>
                  </StyledCompanyBenefitItem>
                ))}
              </StyledCompanyBenefits>
            </StyledBenefitsContainer>
          )}
        </>
      )}
      <BenefitModal
        companyBenefits={companyBenefits}
        setCompanyBenefits={setCompanyBenefits}
        categoryId={categoryId}
        loading={loading}
        setLoading={setLoading}
        benefitIds={companyBenefits.reduce((curr, b) => {
          if (!b.isCustom) {
            curr.push(b._id);
          }
          return curr;
        }, [])}
        benefitList={benefitList}
        setBenefitList={setBenefitList}
        setEditBenefit={setEditBenefit}
      />
      <AddBenefitModal
        loading={loading}
        setLoading={setLoading}
        setBenefitList={setBenefitList}
        editBenefit={editBenefit}
        setEditBenefit={setEditBenefit}
        companyBenefits={companyBenefits}
        setCompanyBenefits={setCompanyBenefits}
      />
    </>
  );
};

export default Benefits;
