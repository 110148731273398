import React from 'react';
import { useDispatch } from 'react-redux';

import { profileActions } from 'store/profile';

import VideoAskFormService from 'api/videoaskFormService';
import useVideoAsk from 'hooks/useVideoask';
import NA from 'images/NA.png';
import * as Styled from './StyledCollaborationItem';
import Flex from 'common/styles/Flex';
import { getCreatorName } from '../../../utils/collaboratorUtils';
import { portalActions } from '../../../store/portals';

const CollaborationItem = ({ id, isFirstVouch, item, onSuccess }) => {
  const dispatch = useDispatch();

  const { openVideoAskModal } = useVideoAsk();

  const onClose = () => {
    document.body.style.overflow = 'initial';
  };

  const onMessage = data => {
    console.log('message', data);
  };

  const fetchVideoAskForm = async () => {
    try {
      const { data } = await VideoAskFormService.getVideoaskForm(id);
      return data.data.url;
    } catch (err) {
      console.log(err);
    }
  };

  const openLearnAboutCollaborationModal = async () => {
    dispatch(
      portalActions.openPortal({
        name: 'collaborators-video-modal',
        data: { url: await fetchVideoAskForm() }
      })
    );
  };

  const showVideoAskModal = async () => {
    const url = await fetchVideoAskForm();
    openVideoAskModal(`${url}&redirect_target=self`, onClose, onMessage);
  };

  const handleShowDetailsClick = async () => {
    if (isFirstVouch) {
      openLearnAboutCollaborationModal();
    } else {
      await showVideoAskModal();
    }
  };

  const handleRedirectClick = url => {
    dispatch(profileActions.setActiveProfileId(null));
    window.location.replace(url);
  };

  const finalName = getCreatorName(item);

  return (
    <Styled.CollaborationItem>
      <Styled.WrapperForCollaborationItem>
        <Styled.CollaborationItemImageWrapper>
          <Styled.CollaborationItemImage src={item.profile?.basicInfo?.imageUri?.url || NA} alt="" />
        </Styled.CollaborationItemImageWrapper>
        <Flex className="nowrap" ml={24} gap={24} columnOnXs width="100%">
          <Styled.CollaborationItemTextWrapper>
            <Styled.CollaborationItemSource>
              <Styled.CollaborationItemSourceLabel>
                {item.entity.entityModel === 'projects' ? 'portfolio' : 'credit'}
              </Styled.CollaborationItemSourceLabel>
            </Styled.CollaborationItemSource>
            <Styled.CollaborationItemText>
              Would you vouch that{' '}
              <Styled.CollaborationItemInnerTextLink
                onClick={() =>
                  item.profile &&
                  handleRedirectClick(
                    item.profileModel === 'talents'
                      ? `/app/talent/${item.profile?.talentId}/about`
                      : `/app/company/${item.profile?.publicUrl}/about`
                  )
                }
              >
                {finalName}
              </Styled.CollaborationItemInnerTextLink>{' '}
              worked on the
              {item.entity.entityModel === 'projects' ? (
                <>
                  <Styled.CollaborationItemInnerTextLink
                    onClick={() =>
                      handleRedirectClick(
                        item.profileModel === 'talents'
                          ? `/app/talent/${item.profile?.publicUrl}/portfolio/${item.entity.entityData._id}`
                          : `/app/company/${item.profile?.publicUrl}/portfolio/${item.entity.entityData._id}`
                      )
                    }
                  >
                    &nbsp; {item.entity.entityData?.name}
                  </Styled.CollaborationItemInnerTextLink>
                  ?
                </>
              ) : (
                <>
                  <Styled.CollaborationItemInnerTextLink
                    onClick={() =>
                      handleRedirectClick(
                        item.profileModel === 'talents'
                          ? `/app/talent/${item.profile?.publicUrl}/about?credit=${item.entity.entityData._id}`
                          : `/app/company/${item.profile?.publicUrl}/about?credit=${item.entity.entityData._id}`
                      )
                    }
                  >
                    &nbsp;{item.entity.entityData?.game?.title}
                  </Styled.CollaborationItemInnerTextLink>{' '}
                  as a <span>{item.entity.entityData?.role?.name}?</span>
                </>
              )}
            </Styled.CollaborationItemText>
          </Styled.CollaborationItemTextWrapper>
          <>
            {item.status === 'pending' && (
              <Styled.CollaborationItemButtonsWrapper>
                <Styled.CollaborationItemDeclineButton
                  data={{ id, onSuccess }}
                  opens="decline-collaboration"
                  backgroundColor="rgba(204, 213, 255, 0.11)"
                  padding="0px"
                  mr="8"
                >
                  Decline
                </Styled.CollaborationItemDeclineButton>
                <Styled.CollaborationItemShowDetailsButton
                  onClick={handleShowDetailsClick}
                  backgroundColor="#7266EE"
                  padding="0px"
                >
                  Show Details
                </Styled.CollaborationItemShowDetailsButton>
              </Styled.CollaborationItemButtonsWrapper>
            )}
          </>
        </Flex>
      </Styled.WrapperForCollaborationItem>
    </Styled.CollaborationItem>
  );
};

export default CollaborationItem;
