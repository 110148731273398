import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import ContainedButton from 'common/ContainedButton';
import FormRadiobutton from 'common/form/FormRadiobutton';
import AddLocationForm from './AddLocationForm';
import Typography from './shared/Typography';

const StyledAddLocationContainer = styled.div`
  display: grid;
  grid-row-gap: 6px;
  margin-bottom: 36px;
`;

const StyledCaption = styled(Typography)`
  color: rgba(230, 234, 255, 0.35);
  margin-bottom: 10px;
`;

const StyledLocationContainer = styled.div`
  background-color: rgba(204, 213, 255, 0.05);
  padding: 16px;
  display: grid;
  grid-row-gap: 14px;
  border-radius: 8px;
`;

const StyledSeparator = styled.div`
  border: 2px solid rgba(204, 213, 255, 0.11);
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledGridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
`;

const StyledButton = styled(ContainedButton)`
  border: 1px solid rgba(204, 213, 255, 0.11);
  background-color: transparent;
  border-radius: 6px;
`;

const StyledContainedButton = styled(ContainedButton)`
  margin-top: 10px;
  width: 142px;
`;

const CompanyLocations = ({ locations, primaryLocation, setPrimaryLocation, updateLocations }) => {
  const isMounted = React.useRef(true);
  const [mode, updateMode] = useState('view');
  const [locationId, updateLocationId] = useState();

  const onAddLocation = () => {
    updateLocationId('');
    updateMode('add');
  };

  const handleLocationAdd = params => {
    updateLocations(locations.concat([params]));
    updateMode('view');
  };

  const handleDeleteLocation = location => () => {
    updateLocations(locations.filter(curr => (curr._id || curr.id) !== (location._id || location.id)));
  };

  const handleLocationUpdate = location => {
    updateLocations(locations.map(curr => ((curr._id || curr.id) === (location._id || location.id) ? location : curr)));
    updateMode('view');
  };

  const handleEdit = location => {
    updateLocationId(location._id || location.id);
    updateMode('add');
  };

  const handleChange = id => setPrimaryLocation(id);

  useEffect(() => {
    isMounted.current = true;
    if (locations.length === 1 && isMounted.current) {
      setPrimaryLocation(locations[0].id || locations[0]._id);
    }
    return () => {
      isMounted.current = false;
    };
  }, [locations.length]);

  return (
    <StyledAddLocationContainer>
      <Typography variant="h3">Your location</Typography>
      <StyledCaption variant="small">
        Please specify your primary location. You can edit, remove, or add more locations later.
      </StyledCaption>

      {locations.map(location => (
        <StyledLocationContainer key={location._id || location.id}>
          <FormRadiobutton
            labelDisabled={primaryLocation !== (location._id || location.id)}
            name="location"
            value={location._id || location.id}
            selected={primaryLocation}
            onChange={() => handleChange(location._id || location.id)}
            text="This is my primary location"
          />

          <StyledSeparator />

          <StyledFlexContainer>
            <Typography variant="h3">{location.officeAddress}</Typography>

            <StyledGridContainer>
              <StyledButton type="button" onClick={() => handleEdit(location)} icon="edit" />
              <StyledButton type="button" onClick={handleDeleteLocation(location)} icon="delete" color="#FF5151" />
            </StyledGridContainer>
          </StyledFlexContainer>
        </StyledLocationContainer>
      ))}

      {mode === 'view' && (
        <StyledAddLocationContainer>
          <StyledContainedButton icon="plus" backgroundColor="secondary" onClick={onAddLocation}>
            Add Location
          </StyledContainedButton>
        </StyledAddLocationContainer>
      )}

      {mode === 'add' && (
        <AddLocationForm
          locationId={locationId}
          companyLocations={locations}
          handleLocationAdd={handleLocationAdd}
          handleLocationUpdate={handleLocationUpdate}
          onCancel={() => updateMode('view')}
        />
      )}
    </StyledAddLocationContainer>
  );
};

export default CompanyLocations;
