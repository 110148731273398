import React from 'react';
import { Prompt as RouterPrompt } from 'react-router-dom';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { isJSON } from 'utils';

const Prompt = ({ message, when }) => {
  useBeforeUnload({ when, message });

  return (
    <RouterPrompt
      when={when}
      message={location => {
        const data = isJSON(message) ? JSON.parse(message) : { message };

        return JSON.stringify({ ...data, pathname: location.pathname });
      }}
    />
  );
};

export default Prompt;
