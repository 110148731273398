import React from 'react';

import FormAsyncSelect from 'common/form/FormAsyncSelect';
import { companyService } from 'api';

const CompanySearch = ({ error, hintText, label, noOptionsMessage, onChange, required, value, ...rest }) => {
  const [loading, setLoading] = React.useState();

  const searchCompany = React.useCallback(
    search =>
      companyService.searchCompany(search).then(({ data }) =>
        data.data.map(company => ({
          _id: company._id,
          label: company.name,
          name: company.name,
          value: company.name
        }))
      ),
    []
  );

  const handleCreate = async inputVal => {
    try {
      setLoading(true);

      const {
        data: { data }
      } = await companyService.requestCompanyTitle({ name: inputVal });

      onChange({ label: data.name, value: data.name, _id: data._id, name: data.name });

      return data;
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormAsyncSelect
      label={label || 'Official Company title'}
      required={required}
      apiCall={searchCompany}
      menuShouldScrollIntoView={false}
      hintText={hintText}
      menuPosition="absolute"
      isLoading={loading}
      noOptionsMessage={noOptionsMessage}
      handleCreate={handleCreate}
      menuPlacement="bottom"
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      value={value}
      isCreatable
      onChange={onChange}
      error={error}
      {...rest}
    />
  );
};

export default CompanySearch;
