import get from 'lodash/fp/get';

export default function getProfileInfoFromJob(job) {
  if (get('talent.name', job)) {
    return job.talent;
  }
  if (get('talentInfo.name', job)) {
    return job.talentInfo;
  }
  if (get('companyProfile.name', job)) {
    return job.companyProfile;
  }
  if (get('company.name', job)) {
    return job.company;
  }
  return {};
}
