import styled from '@emotion/styled';
import { uploadsService } from 'api';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import Icon from 'common/Icon';
import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import useViewport from 'hooks/useViewport';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import commonStyles from 'utils/common-styles';

const FormDropZoneWrapper = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
`;

const StyledError = styled.div`
  ${commonStyles.ui_text_small}
  color: #FF5151;
`;

const AssetList = styled.div`
  display: flex;
  gap: 16px;
  &.multi {
    margin-bottom: 16px;
    .asset-viewer,
    .asset-view-loader {
      width: 189px;
      height: 106px;
    }
  }
  flex-wrap: wrap;
`;

const AssetViewerWrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

const AssetImageViewer = styled.img`
  display: block;
  width: 268px;
  height: 154px;
  border-radius: 8px;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 5;
`;

// const DropZoneStyle

const UploadTextWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  &.size-error {
    color: red;
  }
`;
const HintTextWrapper = styled(UploadTextWrapper)`
  margin-top: 18px;
`;
const StyledTypography = styled(Typography)`
  &.size-error {
    color: #ff5151;
  }
`;

const ImageLoader = styled.div`
  display: inline-flex;
  width: 268px;
  height: 154px;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  display: flex;
  flex-direction: row;
  color: white;
  margin-bottom: 8px;
`;

const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);
  padding-left: 3px;
  &::after {
    content: '*';
  }
`;

const getListOfIds = newFiles => newFiles.map(file => file.id);
const getVideoMimeTypeFromUrl = file =>
  file.type.includes('video') ? `video/${file.url.slice(file.url.lastIndexOf('.') + 1)}` : file.type;
const transform = assets => assets?.map(file => ({ id: file._id, type: getVideoMimeTypeFromUrl(file), url: file.url }));

const FormDropZone = ({
  accept,
  assets,
  deleteIconColor,
  error,
  hintText,
  label,
  required,
  setAssetIds,
  setAssets,
  single,
  ...rest
}) => {
  const [files, setFiles] = React.useState(transform(assets) ?? []);
  const [isLoading, setIsLoading] = React.useState(false);
  const { isXS } = useViewport();
  const [sizeError, setSizeError] = React.useState();

  const updateAssets = newFiles => {
    setAssets && setAssets(single ? newFiles[0] ?? {} : newFiles);

    setAssetIds && setAssetIds(single ? getListOfIds(newFiles)[0] ?? '' : getListOfIds(newFiles));
  };

  const { getInputProps, getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled: isLoading,
    accept: accept ?? 'image/*',
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length == 0) {
        return;
      }

      const file = acceptedFiles[0];
      const isImageValidSize = file?.type.includes('image') ? file.size / 1024 / 1024 > 5 : false;
      const isVideoValidSize = file?.type.includes('video') ? file.size / 1024 / 1024 > 50 : false;

      if (isImageValidSize || isVideoValidSize) {
        setSizeError(true);
        return;
      }

      setSizeError(false);
      setIsLoading(true);

      // upload file
      const formData = new FormData();
      formData.append('file', file);

      const res = await uploadsService.uploadFile(formData);
      const { data } = res.data;
      const id = data?.id;

      let newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          url: URL.createObjectURL(file),
          id
        })
      );

      newFiles = [...files, ...newFiles];

      setFiles(newFiles);
      updateAssets(newFiles);
      setIsLoading(false);
    }
  });
  const showUploader = single ? single && !files.length : true;

  const handleDelete = id => {
    const newFiles = files.filter(file => file.id !== id);
    setFiles(newFiles);
    updateAssets(newFiles);
  };

  const thumbs = files.map(file => (
    <AssetViewerWrapper key={file.id}>
      <DeleteButtonWrapper>
        <ContainedButton
          type="button"
          icon="delete"
          color="rgba(204, 213, 255, 0.11)"
          iconColor={deleteIconColor ?? '#FF5151'}
          backgroundColor="#111112"
          onClick={() => handleDelete(file.id)}
        />
      </DeleteButtonWrapper>
      {file.type.includes('image') && <AssetImageViewer className="asset-viewer" src={file.url} />}
      {file.type.includes('video') && <video width={268} height={154} controls src={file.url} type={file.type} />}
    </AssetViewerWrapper>
  ));

  return (
    <FormDropZoneWrapper className="container" {...rest}>
      {label && (
        <StyledLabel>
          {label}
          {required && <Required />}
        </StyledLabel>
      )}

      <AssetList className={!single && !!files.length && 'multi'}>
        {thumbs}
        {isLoading && (
          <ImageLoader className="asset-view-loader">
            <Icon icon="loader" size="40" />
          </ImageLoader>
        )}
      </AssetList>

      {files.length < 8 && showUploader && (
        <Frame backgroundColor="transparent" error={error || sizeError} {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          <UploadTextWrapper>
            {!isXS && (
              <UploadTextWrapper>
                <Icon icon="upload-alt" size="34" />
                <Typography variant="medium">Drag and drop files here</Typography>
                <Typography variant="medium" color="rgba(230, 234, 255, 0.35)" fontSize="14px">
                  OR
                </Typography>
              </UploadTextWrapper>
            )}
            <ContainedButton
              type="button"
              icon={isXS ? 'upload' : 'search'}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              onClick={open}
              disabled={isLoading}
            >
              Browse files
            </ContainedButton>
          </UploadTextWrapper>
          {hintText && (
            <HintTextWrapper>
              <StyledTypography
                variant="medium"
                color="rgba(230, 234, 255, 0.35)"
                className={sizeError ? 'size-error' : ''}
              >
                {hintText}
              </StyledTypography>
            </HintTextWrapper>
          )}
        </Frame>
      )}
      {!!error && !!files.length && <Flex>{error && <StyledError>{error}&nbsp;</StyledError>}</Flex>}
    </FormDropZoneWrapper>
  );
};

export default FormDropZone;
