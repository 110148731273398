import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import withRenderPortal from 'hocs/withRenderPortal';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import useAppliedJobService from 'hooks/api/useAppliedJobService';
import Spinner from 'common/Spinner';
import { portalActions } from 'store/portals';
import * as Styled from './StyledCompleteWithdrawJobModal';

const CompleteWithdrawJobModal = ({ closePortal, data, name }) => {
  const { withdrawFromJob } = useAppliedJobService();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { jobId, setCurrentJob, talentId } = data;

  const handleWithdraw = async () => {
    setLoading(true);
    await withdrawFromJob(talentId, jobId, job => {
      setCurrentJob(job);
    });
    setLoading(false);
    closePortal();
    dispatch(
      portalActions.openPortal({
        name: 'job-success-modal',
        data: {
          text: 'Your application was withdrawn'
        }
      })
    );
  };

  return (
    <TxplModal
      name={name}
      padding="56px 0 48px 0"
      testTitle
      allowLine
      size="large"
      titleMB="16"
      noPaddingTop
      hideBorder
      title="Withdraw your application?"
      appElement={document.getElementById('talent-modal-placeholder')}
      renderFooter={
        <>
          {loading && <Spinner />}
          <ContainedButton backgroundColor="#7266EE" forceDisplayText onClick={handleWithdraw} ml="6">
            PROCEED
          </ContainedButton>
        </>
      }
    >
      <Styled.IconWrapper>
        <Icon icon="tick" width="51px" height="48px" color="#00FF00" noFill />
      </Styled.IconWrapper>
      <Typography
        fontFamily="Space Grotesk"
        fontStyle="normal"
        fontWeight="bold"
        fontSize="19px"
        lineHeight="26px"
        color="rgba(242, 244, 255, 0.9)"
        textAlign="center"
        mb="54"
      >
        Are you sure you want to withdraw your application?
      </Typography>
      <Typography
        fontFamily="Space Grotesk"
        fontStyle="normal"
        fontWeight="bold"
        fontSize="19px"
        lineHeight="26px"
        color="rgba(242, 244, 255, 0.9)"
        textAlign="center"
      >
        No / Yes
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('complete-withdraw-job-modal')(CompleteWithdrawJobModal);
