import React from 'react';
import { useLocation } from 'react-router';
import ContainedButton from 'common/ContainedButton';
import { StyledAuthContainer, StyledHeaderDropdownItems } from './HeaderMobileStyles';
import HeaderMobileDropdownItem from './HeaderMobileDropdownItem';
import redirectToApp2 from 'utils/redirectToApp2';

const HeaderMobileUnauthorized = ({ onClick }) => {
  const location = useLocation();

  return (
    <StyledHeaderDropdownItems onClick={onClick}>
      <HeaderMobileDropdownItem text="Help" icon="question" />
      <StyledAuthContainer>
        <ContainedButton
          onClick={() =>
            redirectToApp2(
              `/auth/login${location.pathname ? `?redirect=${process.env.REACT_APP_BASE_URL}${location.pathname}` : ''}`
            )
          }
          backgroundColor="rgba(204, 213, 255, 0.11)"
          forceDisplayText
        >
          Sign in
        </ContainedButton>
        <ContainedButton
          onClick={() => redirectToApp2('/onboarding')}
          color="#111112"
          backgroundColor="#00FF00"
          forceDisplayText
        >
          Join
        </ContainedButton>
      </StyledAuthContainer>
    </StyledHeaderDropdownItems>
  );
};

export default HeaderMobileUnauthorized;
