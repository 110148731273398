import Api from './api';

const api = new Api('life');

const addLife = (companyId, categoryId, data) => api.post(`${companyId}/${categoryId}`, data);
const getLives = companyId => api.get(`${companyId}`);
const editLife = (companyId, categoryId, data) => api.put(`${companyId}/${categoryId}`, data);
const deleteLife = (companyId, lifeId) => api.delete(`${companyId}/${lifeId}`);
const changeOrder = data => api.put('change-order', data);
const changeBlockOrder = data => api.put('change-block-order', data);

const addBenefit = (companyId, data) => api.post(`/${companyId}/benefit`, data);
const getSavedBenefits = (companyId, categoryId) => api.get(`/${companyId}/benefits/${categoryId}`);

export default {
  addLife,
  getLives,
  editLife,
  deleteLife,
  changeOrder,
  changeBlockOrder,
  addBenefit,
  getSavedBenefits
};
