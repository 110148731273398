import React from 'react';
import { parseJson } from 'utils';
import sanitizeAndParseHTML from 'utils/sanitizeAndParseHTML';
import { LifeBlockHeading, LifeBlockParagraph, LifeTextBlockWrapper } from './styled.components';

const LifeTextBlock = ({ life }) => {
  const { description, title } = life;

  return (
    <LifeTextBlockWrapper className="life-block">
      <LifeBlockHeading className="life-block-heading">{title}</LifeBlockHeading>
      <LifeBlockParagraph>{sanitizeAndParseHTML(parseJson(description))}</LifeBlockParagraph>
    </LifeTextBlockWrapper>
  );
};

export default LifeTextBlock;
