import React from 'react';
import WaveSurfer from 'wavesurfer.js';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';
import RangeSlider from './form/RangeSlider';

import ContainedButton from './ContainedButton';
import Icon from './Icon';
import { playerTimeFormat } from 'utils/playerTimeFormat';
import { useDispatch, useSelector } from 'react-redux';
import { audioSelectors } from 'store/audio';
import { setAudioVolume } from 'store/audio/audioActions';

const StyledPlayButton = styled(ContainedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  backdrop-filter: blur(25px);

  z-index: 3;
  position: absolute;
  border: none;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
`;

const StyledWaveSurfer = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  object-fit: cover;
  border-radius: 16px;
  display: flex;
  align-items: center;
  background-color: #7d61f0;
  position: relative;
  padding: 0 0 30px;
  & wave {
    width: 100%;
    height: 100% !important;
  }
  @media ${pageSize.L}, ${pageSize.XL} {
    padding: 100px 0;
  }
  @media ${pageSize.XS} {
    height: 343px;
  }
`;

const AudioControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  padding: 14px 17px;
`;

const AudioControlButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const PlayPauseButtonWrapper = styled(AudioControlButton)`
  margin-right: 8px;
`;

const SoundControllerWrapper = styled.div`
  display: flex;
  margin-right: 16px;
`;
const SoundControllerButtonWrapper = styled(AudioControlButton)`
  margin-right: 2px;
`;

const PlayedSecondsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  width: 40px;
`;

const PlayerBar = styled.div`
  flex: 1;
  margin-left: 6px;
  margin-right: 8px;
  input {
    width: 100%;
  }
`;

const AssetVoice = ({ url }) => {
  const surferRef = React.useRef();
  const audioRef = React.useRef();
  const [playing, setPlaying] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [muted, setMuted] = React.useState(false);

  const [currentTime, setCurrentTime] = React.useState(0);
  const duration = audioRef.current?.getDuration() || 0;

  const { volume } = useSelector(audioSelectors.selectAudio);
  const dispatch = useDispatch();

  if (audioRef.current && !surferRef.current?.closest('.slide')?.classList.contains('selected')) {
    audioRef.current.pause();
  }

  React.useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: surferRef.current,
      waveColor: '#A678F6',
      progressColor: '#B678F6',
      cursorColor: 'transparent'
    });

    wavesurfer.load(url);

    wavesurfer.on('audioprocess', () => {
      setCurrentTime(Math.floor(wavesurfer.getCurrentTime() * 100) / 100);
    });

    wavesurfer.on('ready', () => {
      setIsLoading(false);
    });

    audioRef.current = wavesurfer;

    audioRef.current.setVolume(volume);
    playing && audioRef.current.play();

    return () => {
      audioRef.current.destroy();
    };
  }, [url]);

  const toggleAudio = () => {
    if (!isLoading) {
      if (audioRef.current.isPlaying()) {
        audioRef.current.pause();
        setPlaying(false);
      } else {
        audioRef.current.play();
        setPlaying(true);
      }
    }
  };

  const toggleVolume = () => {
    setMuted(!muted);
    audioRef.current.setVolume(muted ? volume : 0);
  };

  const handleVolumeChange = ({ currentTarget }) => {
    dispatch(setAudioVolume(currentTarget.valueAsNumber));
    setMuted(false);
    audioRef.current.setVolume(currentTarget.valueAsNumber);
  };

  const handleAudioChange = ({ currentTarget }) => {
    audioRef.current.play(currentTarget.valueAsNumber);
  };

  return (
    <>
      <StyledWaveSurfer ref={surferRef}>
        <AudioControlsWrapper className="AudioControlsWrapper">
          <PlayPauseButtonWrapper onClick={toggleAudio}>
            <Icon icon={playing ? 'pause' : 'play'} size={16} color="#FFFFFF" hoverColor="#FFFFFF" />
          </PlayPauseButtonWrapper>

          <SoundControllerWrapper>
            <SoundControllerButtonWrapper onClick={toggleVolume}>
              <Icon
                icon={muted || volume === 0 ? 'volume-mute' : volume > 0 && volume < 0.75 ? 'volume-50' : 'volume-100'}
                size={16}
                color="#FFFFFF"
                hoverColor="#FFFFFF"
              />
            </SoundControllerButtonWrapper>
            <RangeSlider fillTo={volume * 100} max={1} value={volume} onChange={handleVolumeChange} />
          </SoundControllerWrapper>

          <PlayedSecondsWrapper>{playerTimeFormat(currentTime)}</PlayedSecondsWrapper>

          <PlayerBar>
            <RangeSlider
              fillTo={(currentTime / duration) * 100}
              max={duration}
              value={currentTime}
              onChange={handleAudioChange}
            />
          </PlayerBar>

          <PlayedSecondsWrapper>{playerTimeFormat(audioRef.current?.getDuration())}</PlayedSecondsWrapper>
        </AudioControlsWrapper>
      </StyledWaveSurfer>
      <StyledPlayButton onClick={toggleAudio}>
        <Icon icon={isLoading ? 'loader' : playing ? 'pause' : 'play'} size={30} color="#7D61f0" hoverColor="#7D61f0" />
      </StyledPlayButton>
    </>
  );
};

export default AssetVoice;
