import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';

export const Container = styled.div`
  margin: 40px 0 0 0;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const ProjectList = styled.div`
  max-height: 500px;
  overflow: auto;
`;

export const CreateNewProjectButton = styled.button`
  margin-top: 16px;
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: white;
  text-transform: uppercase;
  font-family: Space Grotesk;
  font-size: 14px;
  cursor: pointer;
  ${props => props.disabled && 'opacity: .5; pointer-events: none'}
`;

export const StyledContainedButton = styled(ContainedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFrame = styled(Frame)`
  margin-top: 24px;
  padding: 16px;
`;
