import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash/fp';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import commonStyles from 'utils/common-styles';
import CircleButton from 'common/CircleButton';

import Typography from 'components/shared/Typography';
import Asset from './Asset';
import LightBoxUser from './LightBoxUser';

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  background-color: black;
  display: grid;
  grid-template-columns: 96px auto 96px;
  grid-template-rows: 96px auto 120px;

  & > div {
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: row;
  }

  & > div:nth-of-type(2),
  > div:nth-of-type(8) {
    border-left: 2px solid rgba(204, 213, 255, 0.11);
    border-right: 2px solid rgba(204, 213, 255, 0.11);
  }

  & > div:nth-of-type(4),
  > div:nth-of-type(6) {
    border-top: 2px solid rgba(204, 213, 255, 0.11);
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    align-items: center;
    justify-content: center;
  }

  & > div:nth-of-type(5) {
    border: 2px solid rgba(204, 213, 255, 0.11);
  }

  & .close-icon {
    cursor: pointer;
    color: rgba(230, 234, 255, 0.35);
  }

  & .carousel .slide iframe {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;

  & > div, .carousel.carousel-slider, .slider-wrapper, ul.slider {
    height: 100%;
    width: 100%;
  }
`;

const CarouselItem = styled.div`
  width: 100%;
  // space for the controls dots of the carousel
  height: calc(100% - 20px);
`;

const Title = styled.div`
  ${commonStyles.ui_heading_3}
  align-self: center;
`;

const StyledTypography = styled(Typography)`
  color: rgba(242, 244, 255, 0.9);
  max-width: 582px;
  font-size: 16px;
`;

const AssetsLightboxLarge = ({ assetIndex, assets, className, closeModal }) => {
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleBack = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else {
      setSelectedIndex(assets.length - 1);
    }
  };

  const handleNext = () => {
    if (selectedIndex < assets.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else {
      setSelectedIndex(0);
    }
  };

  const onChange = slideIndex => {
    setSelectedIndex(slideIndex);
  };

  useEffect(() => {
    setSelectedAsset(assets[selectedIndex]);
  }, [selectedIndex, setSelectedAsset, assets]);

  useEffect(() => {
    setSelectedIndex(assetIndex);
  }, [assetIndex, setSelectedAsset]);

  if (!assets || isEmpty(assets)) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <div />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>{selectedAsset?.name}</Title>
        <LightBoxUser />
      </div>

      <div>
        <CircleButton iconName="close" onClick={closeModal} alt="Close" />
      </div>
      <div>
        <CircleButton iconName="back" onClick={handleBack} alt="Previous" />
      </div>

      <CarouselContainer id="carousel-container">
        <Carousel
          onChange={onChange}
          selectedItem={selectedIndex}
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          width="100%"
        >
          {assets.map(asset => (
            <CarouselItem key={asset._id}>
              <Asset asset={asset} height="100%" />
            </CarouselItem>
          ))}
        </Carousel>
      </CarouselContainer>

      <div>
        <CircleButton iconName="next" onClick={handleNext} alt="Next" />
      </div>

      <div />
      <div>
        <StyledTypography variant="small">{(selectedAsset?.description || '').slice(0, 250)}</StyledTypography>
      </div>
      <div />
    </Wrapper>
  );
};

export default AssetsLightboxLarge;
