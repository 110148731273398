import { useCallback } from 'react';

const useHtmlToText = () => {
  const extractTextFromHTML = useCallback(htmlString => {
    const tempDiv = document.createElement('div');
    tempDiv.setAttribute('id', 'temp-div-html-to-text');
    tempDiv.innerHTML = htmlString;
    const text = tempDiv.textContent || '';
    document.getElementById('temp-div-html-to-text')?.remove();
    return text;
  }, []);

  return {
    extractTextFromHTML
  };
};

export default useHtmlToText;
