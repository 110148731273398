import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { backOfficeActions } from 'store/backOffice';
import ContainedButton from 'common/ContainedButton';
import { Column, Table } from 'components/Table';
import Typography from 'components/shared/Typography';
import BackofficeMemberModal from './BackOfficeMemberModal';
import BackOfficeMemberAdd from './BackOfficeMemberAdd';

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const getSuperPermissions = roles => {
  const superUserRoles = roles && roles.filter(r => r.role.startsWith('super-'));
  const analystUserRoles = roles && roles.filter(r => r.role.startsWith('analyst'));
  return superUserRoles
    .map(r => r.role)
    .sort()
    .join(', ')
    .concat(
      analystUserRoles
        .map(r => r.role)
        .sort()
        .join(', ')
    );
};

const BackOfficeMembers = () => {
  const tableRef = React.useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Admin panel' }, { label: 'Back office members' }]));
  }, [dispatch]);

  return (
    <>
      <BackofficeMemberModal tableRef={tableRef} />
      <BackOfficeMemberAdd />

      <ActionsRow>
        <ContainedButton data={tableRef} opens="bacOffice-add-team-member">
          Invite new member
        </ContainedButton>
      </ActionsRow>

      <Table keyId="_id" endpoint="/backoffice/backoffice-members" dataKey="users" ref={tableRef} paginate>
        <Column title="First Name" keyName="firstName" search />
        <Column title="Last Name" keyName="lastName" search />
        <Column title="email" keyName="email" search />
        <Column
          title="Permission"
          keyName="roles.role"
          render={user =>
            user.status === 'invite-pending' ? (
              <Typography variant="small" opacity={0.35}>
                Invite pending...
              </Typography>
            ) : (
              getSuperPermissions(user.roles)
            )
          }
          search
        />
        <Column
          title=""
          width={46}
          type="action"
          opens="backOffice-edit-team-member"
          data={(user, setUsers) => ({ userId: user._id, setUsers })}
        />
      </Table>
    </>
  );
};

export default BackOfficeMembers;
