import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const Container = styled.div`
  width: 315px;
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  border-radius: 16px;
  background-color: #1a1b1e;
  padding: 12px;
  ${props => props.hided && 'display: none'};
  @media ${pageSize.S} {
    width: 100% !important;
    position: relative;
    background-color: transparent !important;
    padding: 0;
  }
  @media ${pageSize.XS} {
    width: 100%;
    position: relative;
    background-color: transparent;
    padding: 0;
  }
`;
export const SidebarList = styled.div``;
export const SidebarItem = styled.a`
  display: block;
  color: rgba(230, 234, 255, 0.6);
  padding: 12px 13px;
  position: relative;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  border-radius: 8px;
  &:hover {
    background: rgba(204, 213, 255, 0.05);
  }
  &.active {
    color: #ffffff;
    background: rgba(204, 213, 255, 0.11);
  }
`;
export const SidebarFooter = styled.div`
  border-top: 2px solid rgba(204, 212, 255, 0.11);
  margin-top: 12px;
  padding-top: 12px;
  box-sizing: border-box;
  @media ${pageSize.M}, ${pageSize.L} {
    .apply-btn,
    .save-btn {
      width: 100%;
    }
  }
  button.apply-btn,
  button.save-btn {
    justify-content: center;
  }
  @media ${pageSize.S} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-top: none;
  }
  @media ${pageSize.XS} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-top: none;
    .apply-btn {
      width: 100%;
    }
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    background: #1a1b1e;
    justify-content: flex-end;
    padding: 8px;
    border-radius: 16px;
    gap: 6px;
  }
`;
export const SidebarFooterTopButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  gap: 6px;
  @media ${pageSize.M} {
    width: 100%;
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    margin-bottom: 0;
  }
`;
export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const StyledSidebarClosedJobFooter = styled.div`
  width: 291px;
  height: 102px;
  padding: 8px 8px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const StyledText = styled.span`
  width: 275px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
`;
export const StyledReopenButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 40px;
  background-color: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  cursor: pointer;
`;
export const StyledReopenButtonText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 9px;
`;
