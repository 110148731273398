import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUserProperty } from 'store/auth/authSelectors';

import useIDVerification from 'hooks/useIDVerification';

const useCurrentVerification = () => {
  const status = useSelector(selectCurrentUserProperty('verificationStatus'));

  const { getCurrentVerification } = useIDVerification();

  useEffect(() => {
    let interval = null;
    if (status === 'pending') {
      interval = setInterval(async () => {
        await getCurrentVerification();
      }, [3000]);
    }

    return () => clearInterval(interval);
  }, [status]);
};

export default useCurrentVerification;
