import Api from './api';

const api = new Api('auth');

const autoLogin = data => api.post('/auto-login', data);

const authWithFB = data => api.post('/fb', data);

const authWithGoogle = data => api.post('/google', data);

const authWithLinkedIn = data => api.post('/linkedin', data);

const fetchOauthUser = params => api.get('/oauth/current', { params });

const login = data => api.post('/login2', data);

const signUp = data => api.post('/signup', data);

const logout = data => api.post('/logout', data);

const loginAs = data => api.post('/login-as', data);

export default {
  autoLogin,
  authWithFB,
  authWithGoogle,
  authWithLinkedIn,
  login,
  logout,
  signUp,
  loginAs,
  fetchOauthUser
};
