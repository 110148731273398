import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { pageSize } from 'utils/devices';
import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';
import { map, size, values } from 'lodash/fp';
import PublishProjectModal from 'pages/talent/portfolio/PublishProjectModal';
import UnpublishProjectModal from 'components/modals/UnpublishProjectModal';
import DeleteProjectModal from 'components/modals/DeleteProjectModal';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import UnlockRequestModal from 'components/modals/UnlockRequestModal';
import normalize from 'utils/normalize';
import AddProjectCard from './portfolio/AddProjectButton';
import GalleryCard from './GalleryCard';
import ReachedMaxLimit from './portfolio/ReachedMaxLimit';
import CollaboratorsModal from './Collaborators/CollaboratorsModal/CollaboratorsModal';
import talentService from '../../api/talentService';
import companyService from '../../api/companyService';

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 24px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

const TalentGallery = () => {
  const { talentId: profileId } = useParams();
  const profileIdB = useSelector(profileSelectors.selectProfileProperty('_id'));

  const talentId = useSelector(profileSelectors.selectActiveProfileId);
  const canPublishProject = useSelector(profileSelectors.canPublishProject);
  const projects = useSelector(profileSelectors.selectProfileProperty('projects')) || {};
  const portfolios = values(projects).filter(project => !project.isGeneral);
  const [items, setItems] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [currentProject, setCurrentProject] = useState({ _id: null, isAccurate: null, coverImage: '', keywords: [] });
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const hasProfileEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setItems(map('_id', portfolios));

    setProjectList(portfolios);
  }, [projects]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const sortedArray = arrayMove(items, oldIndex, newIndex);

        const newProjectList = [];
        sortedArray.map(id => projectList.map(project => project._id === id && newProjectList.push(project)));
        setProjectList(newProjectList);

        const serviceType = isCompanyView ? companyService : talentService;
        const id = isCompanyView ? myProfileId : talentId;

        serviceType
          .updatePortfolioSort(id, sortedArray)
          .then(() => {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'projects',
                value: normalize(newProjectList),
                profileId: id
              })
            );
          })
          .catch(error => {
            console.log('error: ', error);
          });

        return sortedArray;
      });
    }
  }

  const handleSave = async params => {
    try {
      setSubmitting(true);
      const formData = new FormData();
      formData.append('file', params.file);
      formData.append(
        'data',
        JSON.stringify({
          keywords: params.keywords,
          industryType: params.industryType,
          viaThexplace: params.viaThexplace
        })
      );
      const res = await talentService.updateProject(talentId, currentProject._id, formData);
      dispatch(
        profileActions.updateProfileProperty({
          property: 'projects',
          updateType: 'update',
          value: res.data.data,
          profileId: profileIdB
        })
      );
      dispatch(
        portalActions.closePortal({
          name: 'project-publish-modal'
        })
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (currentProject) {
      setCurrentProject(currentProject);
    }
  }, [currentProject]);

  const shouldShowReachedLimit = hasProfileEditPermission && !canPublishProject;
  return (
    <>
      <CardsWrapper>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={rectSortingStrategy}>
            {items.map((portfolio, index) => {
              if (!hasProfileEditPermission && projectList[index].status === 'draft') {
                return null;
              }
              return (
                <GalleryCard
                  portfolio={projectList[index]}
                  key={portfolio}
                  id={portfolio}
                  profileId={profileId}
                  items={items}
                  hasProfileEditPermission={hasProfileEditPermission}
                  setCurrentProject={setCurrentProject}
                />
              );
            })}
          </SortableContext>
        </DndContext>

        {!!size(portfolios) && canPublishProject && <AddProjectCard talentId={talentId} />}
      </CardsWrapper>
      <PublishProjectModal
        submitting={submitting}
        isAccurate={currentProject.isAccurate || null}
        image={currentProject.coverImage || ''}
        keywords={currentProject.keywords || []}
        initialIndustryType={currentProject.industryType || ''}
        onSave={handleSave}
      />
      <UnpublishProjectModal />
      <DeleteProjectModal />
      <CollaboratorsModal />
      {!size(portfolios) && <AddProjectCard talentId={talentId} fullScreen />}
      {shouldShowReachedLimit && <ReachedMaxLimit />}
      <UnlockRequestModal />
    </>
  );
};

export default TalentGallery;
