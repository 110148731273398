import Flex from 'common/styles/Flex';
import Typography from 'components/shared/Typography';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import React, { useRef, useState } from 'react';
import FormTextarea from 'common/form/FormTextarea';
import * as Styled from 'components/UserCard/StyledUserCard';

const CollaboratorsMessage = ({ email, isActive, name, project, externalId}) => {
  const { openSuccessSnackbar } = useSuccessSnackbar({ showClose: true });
  const textRef = useRef();
  const proj = (project && `“${project.name}”`) || '';

  const messageUrl = isActive
    ? `${process.env.REACT_APP_BASE_URL}/login`
    : `${process.env.REACT_APP_BASE_URL}/home?collaborator=${externalId}`;
  const initialMessage = `Hi ${name} I have joined TheXPlace. It’s the new way to network and find work in the interactive gaming industry. Would you answer a few questions about our shared project ${proj}?\nPlease, follow this link: \n${messageUrl}`;

  const [message, setMessage] = useState(initialMessage);

  const printText = () => {
    navigator.clipboard.writeText(textRef.current.value);
    openSuccessSnackbar('The message has been copied');
  };

  return (
    <Flex column gap={32}>
      <Typography color="rgba(230, 234, 255, 0.6);" variant="medium">
        In addition to the invitation we will send by email, feel free to connect directly. Copy and share the unique
        link with your collaborator.
      </Typography>

      <Flex column gap={16} gapOnXs={24}>
        <FormTextarea
          ref={textRef}
          label={`Unique link for ${email}`}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <Styled.Button icon="copy" type="button" backgroundColor="rgba(204, 213, 255, 0.11)" onClick={printText}>
          COPY MESSAGE
        </Styled.Button>
      </Flex>
    </Flex>
  );
};

export default CollaboratorsMessage;
