import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

import Frame from 'common/Frame';
import Tags from 'common/Tags';
import Typography from 'components/shared/Typography';

const SkillTags = ({
  maxWidth,
  project,
  portfolio = null,
  maxWidthXs,
  isEditMode = true,
  data = [],
  error,
  onAction,
  hintText,
  required,
  hasDragPermission = false,
  service = null,
  hideEmpty = false,
  afterDrag,
  callService,
  page,
  titlePB = 16
}) => {
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const mode = data.length ? 'edit' : 'add';

  return (
    <>
      {(!hasPermission || hideEmpty) && data.length === 0 ? (
        <></>
      ) : (
        <Frame
          error={error}
          project={project}
          required={required}
          color="#26CBFF"
          text="Skills"
          titlePB={titlePB}
          canEdit={isEditMode}
          editMode={mode}
          onEdit={onAction}
          filled={!!data.length}
          noDataText={hintText || 'Please select skills'}
          id="skills"
          className="skills-required"
        >
          <Tags
            page={page}
            maxWidth={maxWidth}
            maxWidthXs={maxWidthXs}
            tags={data}
            shouldToggle
            type="skills"
            canEdit={isEditMode}
            hasDragPermission={hasDragPermission}
            service={service}
            portfolio={portfolio}
            afterDrag={afterDrag}
            callService={callService}
          />
          {error && (
            <Typography mt={30} error variant="small">
              {error || 'There must be at least 1'}
            </Typography>
          )}
        </Frame>
      )}
    </>
  );
};

export default SkillTags;
