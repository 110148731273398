import styled from '@emotion/styled';
import { backOfficeService, userService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import get from 'lodash/fp/get';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { backOfficeActions } from 'store/backOffice';
import { isValidEmail } from 'utils';

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const UsersNew = () => {
  const [formValues, setFormValues] = useState({
    candidateId: { value: null, error: null },
    candidate: { value: null, error: null },
    email: { value: null, error: null },
    firstName: { value: null, error: null },
    lastName: { value: null, error: null }
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      backOfficeActions.updateBackOfficeTitle([
        { label: 'Operations' },
        { label: 'Users', url: '/backoffice/operations/users' },
        { label: 'Create new user' }
      ])
    );
  }, [dispatch]);

  const clearErrors = () => {
    const formValuesNoErrors = { ...formValues };
    Object.keys(formValues).forEach(key => {
      formValuesNoErrors[key].error = null;
    });
    setFormValues({ ...formValues, ...formValuesNoErrors });
  };

  const handleInputChange = async (field, value) => {
    switch (field) {
      case 'email':
        try {
          setFormValues({ ...formValues, [field]: { value, error: null } });
          if (value.length && isValidEmail(value)) {
            userService.checkUserEmail(value).then(({ data: { exists } }) => {
              setFormValues({ ...formValues, [field]: { value, error: exists ? 'Email already exists' : null } });
            });
          } else {
            setFormValues({ ...formValues, [field]: { value, error: null } });
          }
        } catch {
          if (!value.length && formValues[field].error.length) {
            setFormValues({ ...formValues, [field]: { value, error: null } });
          }
        }
        break;
      case 'candidateId':
        if (value && value.length === 24) {
          await backOfficeService
            .fetchCandidateById(value)
            .then(response => {
              const candidateResponse = response.data;
              setFormValues({
                ...formValues,
                email: { value: candidateResponse.email, error: null },
                firstName: { value: candidateResponse.firstName, error: null },
                lastName: { value: candidateResponse.lastName, error: null },
                candidateId: { value: candidateResponse._id, error: null },
                candidate: { value: candidateResponse, error: null }
              });
            })
            .catch(error =>
              setFormValues({ ...formValues, candidateId: { value, error: error.response?.data?.error } })
            );
        } else {
          if (formValues.candidate.value) {
            setFormValues({
              ...formValues,
              candidateId: { value, error: value.length === 24 ? null : 'Candidate Id is invalid' },
              candidate: { value: null, error: null },
              email: { value: '', error: null },
              firstName: { value: '', error: null },
              lastName: { value: '', error: null }
            });
          } else {
            setFormValues({
              ...formValues,
              candidateId: { value, error: value.length > 0 && value.length < 24 ? 'Candidate Id is invalid' : '' }
            });
          }
        }
        break;
      default:
        setFormValues({ ...formValues, [field]: { value, error: '' } });
        break;
    }
  };

  const formIsValid = () => {
    const errorObject = { email: null, firstName: null, lastName: null };
    if (formValues.email.error === 'Email already exists') {
      errorObject.email = formValues.email.error;
    } else if (!formValues.email.value) {
      errorObject.email = 'Please enter email';
    } else {
      if (!isValidEmail(formValues.email.value)) {
        errorObject.email = 'Email is not valid';
      }
    }
    if (!formValues.firstName.value) {
      errorObject.firstName = 'Please enter first name';
    }
    if (!formValues.lastName.value) {
      errorObject.lastName = 'Please enter last name';
    }

    if (errorObject.email || errorObject.firstName || errorObject.lastName) {
      const formValuesWithErrors = { ...formValues };
      Object.keys(errorObject).forEach(key => {
        if (errorObject[key]) {
          formValuesWithErrors[key].error = errorObject[key];
        }
      });
      setFormValues({ ...formValues, formValuesWithErrors });
      return false;
    }

    // Form is OK
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!formIsValid()) {
      return false;
    } else {
      clearErrors();
    }
    const body = {
      email: formValues.email.value,
      firstName: formValues.firstName.value,
      lastName: formValues.lastName.value
    };
    if (formValues.candidate.value) {
      body.candidateId = formValues.candidate.value._id;
    }
    backOfficeService
      .createNewUser(body)
      .then(response => {
        const responseStatus = get('data.status', response);
        const responseUserId = get('data.user._id', response);

        if (responseStatus === 'OK') {
          history.push(`/backoffice/operations/users/${responseUserId}`);
        }

        if (responseStatus === 'ERROR') {
          const errorMessage = get('data.message', response);
          const errorField = get('data.field', response);
          switch (errorField) {
            case 'email':
              setFormValues({ ...formValues, email: { value: formValues.email.value, error: errorMessage } });
              break;
            default:
              setFormValues({
                ...formValues,
                [errorField]: { value: formValues.errorField.value, error: errorMessage }
              });
              break;
          }
        }
      })
      .catch(err => {
        const errorMessage = get('response.data.message', err);
        if (errorMessage === 'Email already exists.') {
          setFormValues({ ...formValues, email: { value: formValues.email.value, error: errorMessage } });
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px' }}>
        <FormInput
          label="Candidate ID"
          value={formValues.candidateId.value}
          onChange={e => handleInputChange('candidateId', e.target.value)}
          error={formValues.candidateId.error}
        />
        <FormInput
          mt={40}
          label="Email"
          value={formValues.email.value}
          onChange={e => handleInputChange('email', e.target.value)}
          error={formValues.email.error}
        />
        <FormInput
          mt={40}
          label="First Name"
          value={formValues.firstName.value}
          onChange={e => handleInputChange('firstName', e.target.value)}
          error={formValues.firstName.error}
        />
        <FormInput
          mt={40}
          label="Last Name"
          value={formValues.lastName.value}
          onChange={e => handleInputChange('lastName', e.target.value)}
          error={formValues.lastName.error}
        />
        <SubmitRow>
          <ContainedButton mt={40} type="submit" forceDisplayText>
            Create User
          </ContainedButton>
        </SubmitRow>
      </form>
    </>
  );
};

export default UsersNew;
