import React, { useCallback, useState } from 'react';
import { pronounOptions } from 'utils/selectOptions';
import FormSelect from 'common/form/FormSelect';
import FormInput from 'common/form/FormInput';

const FormPronounSelect = ({ errors, onFieldChange, values }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const option = 'other';
  const showCustomInput = selectedOption && selectedOption.value === option;
  const mb = showCustomInput ? 36 : 56;

  const handleDropdownChange = useCallback(value => {
    setSelectedOption(value);
    onFieldChange('pronouns')(value);
  }, []);

  const handleInputChange = useCallback(event => {
    onFieldChange('customPronoun')(event.target.value);
  }, []);
  return (
    <>
      <FormSelect
        mb={mb}
        value={values.pronouns}
        options={pronounOptions}
        onChange={handleDropdownChange}
        label="Your pronouns"
        placeholder="Select an option"
        isClearable
        error={errors.pronouns}
        data-testid="pronouns-field"
      />
      {showCustomInput && (
        <FormInput
          mb={56}
          value={values.customPronoun}
          onChange={handleInputChange}
          label="Enter custom pronouns"
          error={errors.customPronoun}
          maxLength={25}
          required
          data-testid="customPronouns-field"
        />
      )}
    </>
  );
};

export default FormPronounSelect;
