import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import { selectMyProfile, selectProfile } from '../profile/profileSelectors';

const selectDomain = state => state.jobs;

export const selectMyJobs = jobType =>
  createSelector(
    [selectDomain, selectMyProfile],
    (jobs, profile) => get(`${jobType}.jobs.${profile?._id}`, jobs) ?? {}
  );

export const selectUnreadCounters = state => get('jobs.unreadCounters', state);

export const selectJobs = jobType =>
  createSelector([selectDomain, selectProfile], (jobs, profile) => get(`${jobType}.jobs.${profile?._id}`, jobs) ?? {});

export const selectMyJobsPagination = jobType =>
  createSelector(
    [selectDomain, selectMyProfile],
    (jobs, profile) =>
      get(`${jobType}.pagination.${profile?._id}`, jobs) ?? {
        hasMore: true,
        limit: 6,
        skip: 0,
        page: 1
      }
  );

export const selectJobsPagination = jobType =>
  createSelector(
    [selectDomain, selectProfile],
    (jobs, profile) =>
      get(`${jobType}.pagination.${profile?._id}`, jobs) ?? {
        hasMore: true,
        limit: 6,
        skip: 0,
        page: 1
      }
  );
