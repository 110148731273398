import styled from '@emotion/styled';
import Icon from 'common/Icon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserNameSelector, isLoggedInSelector } from 'store/selectors/currentUser';
import commonStyles from 'utils/common-styles';
import BackofficeUserMenuItem from './BackofficeUserMenuItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 240px;
  max-width: 240px;
  height: 48px;
  border-top: 1px solid rgba(204, 213, 255, 0.11);
  color: rgba(230, 234, 255, 0.35);
  background: rgba(204, 213, 255, 0.11);

  &:hover {
    cursor: pointer;
    background: rgba(204, 213, 255, 0.04);
  }
`;

const Text = styled.div`
  ${commonStyles.ui_text_medium}
  flex: 1;
  height: 44px;
  padding: 13px 0 13px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ArrowIcon = styled.div`
  margin: 16px;
`;

const ItemsWrapper = styled.div`
  box-sizing: border-box;
  background-color: #111112;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  overflow: hidden;
  margin: 8px;
  margin-top: 30px;
`;

const BackofficeUserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userName = useSelector(currentUserNameSelector);

  if (!isLoggedIn) return null;

  return (
    <>
      {isOpen && (
        <ItemsWrapper>
          <BackofficeUserMenuItem icon="profile" text="View Profile" url="/profile" />
          <BackofficeUserMenuItem icon="logout" text="Log out" url="/logout" />
        </ItemsWrapper>
      )}

      <Wrapper onClick={() => setIsOpen(!isOpen)}>
        <Text>{userName}</Text>
        <ArrowIcon>
          <Icon icon="kebab" />
        </ArrowIcon>
      </Wrapper>
    </>
  );
};

export default BackofficeUserMenu;
