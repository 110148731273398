import React from 'react';
import FormRadioButton from 'common/form/FormRadiobutton';
import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';

const GridColumnView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 16px 0;
`;

const FilterRadioBoxes = ({ checked, disabled, name, onChange, options, title }) => (
  <div>
    <Typography variant="h3" disabled={disabled} mb={16}>
      {title}
    </Typography>
    <GridColumnView>
      {options?.map((it, key) => {
        return (
          <FormRadioButton
            key={key}
            name={name}
            value={it.value}
            selected={checked.value}
            onChange={e => onChange(e, it.label)}
            text={it.label}
            disabled={disabled}
          />
        );
      })}
    </GridColumnView>
  </div>
);

export default FilterRadioBoxes;
