import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Global } from '@emotion/core';
import kebabCase from 'lodash/fp/kebabCase';
import map from 'lodash/fp/map';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import isNumber from 'lodash/fp/isNumber';
import isEmpty from 'lodash/fp/isEmpty';
import LimitedTypography from 'components/shared/LimitedTypography';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
	${({ mt }) => mt && `margin-top: ${mt}px;`}
	${({ mb }) => mb && `margin-bottom: ${mb}px;`}
	gap: ${props => (props.inputGap ? props.inputGap : '6px')};
  ${({ width }) => width && `width: ${width};`}
  @media ${pageSize.XS} {
    ${({ mtXS }) => mtXS && `margin-top: ${mtXS}px;`}
  }
  .class-container .react-select__control {
    ${({ borderRadius }) => (isNumber(borderRadius) ? `border-radius: ${borderRadius}px;` : `border-radius: 8px;`)}
    ${({ padding }) => padding && `padding: ${padding};`}
  }
  .class-container .react-select__placeholder {
    ${({ placeholderColor }) =>
      placeholderColor ? `color: ${placeholderColor}!important;` : `color: rgba(230, 234, 255, 0.35);`}
  }
`;
const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  display: flex;
  flex-direction: row;
  color: white;
  ${props => props.isDisabled && 'opacity: 0.35;'}
  ${props => props.boldWeight && 'font-weight: 700;'}
`;
const StyledHintText = styled.label`
  ${commonStyles.ui_text_small};
  color: rgba(230, 234, 255, 0.35);
  ${props => props.lightWeight && 'font-weight: 400;'}
  ${props => props.noHintText && 'display: none;'}
`;
const indicatorSeparatorCss = css`
  display: 'none';
`;
const clearIndicatorCss = css`
  border-radius: 8px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
  }
`;
const selectGlobalStyles = css`
  .class-container .react-select__container {
    cursor: pointer;
    outline: none;
  }

  .class-container .react-select__value-container {
    padding: 0 16px 0 0;
  }

  .class-container .react-select__value-container--is-multi {
    gap: 6px;
  }

  .class-container .react-select--is-disabled {
    opacity: 0.35;
  }

  .class-container .react-select__placeholder {
    ${commonStyles.ui_text_medium}
    color: rgba(230, 234, 255, 0.35) !important;
  }

  .class-container .react-select__control {
    padding: 15px 18px;
    color: white;
    background-color: transparent !important;
    outline: none;
    border: 2px solid rgba(204, 213, 255, 0.11);

    &:hover {
      border: 2px solid rgba(230, 234, 255, 0.6);
    }

    &:focus {
      outline: none !important;
      border: 2px solid #7266ee;
    }
  }
  .class-container-error .react-select__control {
    border: 2px solid #ff5151;
  }

  .class-container .react-select__control--is-focused {
    border: 2px solid #7266ee;
    outline: none;
    box-shadow: none;

    &:hover {
      border: 2px solid #7266ee;
    }
  }

  .class-container .react-select__singleValue {
    color: white;
    background-color: red;
  }

  .class-container .react-select__multi-value {
    display: flex;
    align-items: center;
    background: transparent !important;
    border: 2px solid rgba(204, 213, 255, 0.11);
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
  }

  .class-container .react-select__input {
    color: white;
  }

  .class-container .react-select__single-value {
    color: white;
  }

  .class-container .react-select__multi-value__label {
    color: rgba(242, 244, 255, 0.9);
    font-size: 16px;
    padding: 0 6px;
  }

  .class-container .react-select__multi-value__remove {
    color: rgba(242, 244, 255, 0.9);
    background-color: transparent;
    height: 22px;
    border-radius: 8px;
    margin-right: 2px;
    padding-top: 2px;
    &:hover {
      color: rgba(242, 244, 255, 0.9);
      background-color: rgba(204, 213, 255, 0.11);
    }
  }

  .class-container .react-select__indicators {
    align-self: flex-start;
  }

  .class-container .react-select__indicator-separator {
    display: none;
  }

  .class-container .react-select__clear-indicator {
    width: 26px;
    height: 26px;
    background-color: rgba(204, 213, 255, 0.11);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: inherit;
    }
  }

  .class-container .react-select__dropdown-indicator {
    padding:  1px;
  }

  body .react-select__menu {
    border: 2px solid rgba(204, 213, 255, 0.11);
    background-color: #1a1b1e;
    border-radius: 8px;
  }
  body .react-select__menu-list {
    /* background-color: rgba(204, 213, 255, 0.11); */
    background-color: #1a1b1e;
    border-radius: 8px;
  }

  body .react-select__option {
    color: white;
    background-color: #1a1b1e;
    padding: 9px 18px;

    &:hover {
      color: white;
      background-color: rgba(204, 213, 255, 0.05);
      padding: 9px 18px;
    }
  }

  body .react-select__option--is-focused {
    background-color: #7266ee;
    padding: 9px 18px;
  }
`;
const inputCss = css`
  color: white;
`;
const StyledError = styled.div`
  ${commonStyles.ui_text_small}
  color: #FF5151;
`;
const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);
  padding-left: 3px;
  &::after {
    content: '*';
  }
`;

export const SelectComponent = ({
  value,
  maxOptions,
  options,
  onInputChange,
  placeholder,
  onChange,
  isMulti,
  isAsync,
  isCreatable,
  handleCreate,
  isLoading,
  customNoOptMessage,
  containerStyle,
  lightWeight,
  dropdownStyles = {},
  indicatorStyles = {},
  error,
  inputGap,
  ...rest
}) => {
  const SelectComp = React.useMemo(() => {
    if (isCreatable && isAsync) return AsyncCreatableSelect;

    if (isCreatable && !isAsync) return CreatableSelect;

    if (!isCreatable && isAsync) return AsyncSelect;

    if (!isCreatable && !isAsync) return Select;
  }, [isCreatable, isAsync]);

  const transformValue = option => {
    return {
      ...option,
      label: <LimitedTypography tooltipId={kebabCase(option.value)} text={option.label} />
    };
  };

  const transformOption = option => {
    if (option === null) {
      return option;
    }
    return {
      ...option,
      label: option.label?.props?.text ?? option.label
    };
  };

  return (
    <div style={containerStyle} className={`class-container ${error && 'class-container-error'}`}>
      <SelectComp
        value={Array.isArray(value) ? map(transformValue, value) : value}
        lightWeight={lightWeight}
        options={value?.length >= maxOptions ? [] : options}
        noOptionsMessage={() =>
          value?.length >= maxOptions
            ? customNoOptMessage || `You can't select more than ${maxOptions} items`
            : 'No options available'
        }
        onInputChange={onInputChange}
        menuShouldScrollIntoView
        onChange={(option, action) => {
          const transformedOption = Array.isArray(option) ? map(transformOption, option) : transformOption(option);
          onChange(transformedOption, action);
        }}
        maxMenuHeight={200}
        isSearchable
        menuPlacement="auto"
        isLoading={isLoading}
        placeholder={placeholder}
        isMulti={isMulti || (maxOptions && maxOptions > 1)}
        stylesXXX={commonStyles.select}
        onCreateOption={handleCreate}
        inputGap={inputGap}
        styles={{
          indicatorSeparator: () => indicatorSeparatorCss,
          clearIndicator: () => clearIndicatorCss,
          singleValue: () => inputCss,
          input: () => inputCss,
          dropdownIndicator: baseStyles => ({ ...baseStyles, ...indicatorStyles }),
          ...dropdownStyles
        }}
        classNamePrefix="react-select"
        {...rest}
        {...(isAsync && !rest.inputValue ? { menuIsOpen: false } : {})}
      />
      <Global styles={{ ...selectGlobalStyles }} />
    </div>
  );
};

const FormSelect = ({
  boldWeight,
  borderRadius,
  error,
  handleCreate,
  hintText,
  inputGap,
  isAsync,
  isDisabled,
  isLoading,
  isMulti,
  label,
  lightWeight,
  maxOptions,
  mb,
  mt,
  mtXS,
  name,
  noHintText,
  onChange,
  onInputChange,
  options,
  padding,
  pb,
  placeholder,
  placeholderColor,
  pt,
  required,
  value,
  width,
  ...rest
}) => {
  return (
    <InputWrapper
      pt={pt}
      pb={pb}
      mt={mt}
      mb={mb}
      mtXS={mtXS}
      inputGap={inputGap}
      borderRadius={borderRadius}
      padding={padding}
      width={width}
      placeholderColor={placeholderColor}
      name={name}
    >
      <StyledLabel boldWeight={boldWeight} isDisabled={isDisabled}>
        {label}
        {required && <Required />}
      </StyledLabel>
      {hintText && (
        <StyledHintText lightWeight={lightWeight} noHintText={noHintText}>
          {hintText}
        </StyledHintText>
      )}
      <SelectComponent
        lightWeight={lightWeight}
        value={isEmpty(value) ? null : value}
        maxOptions={maxOptions}
        options={options}
        onInputChange={onInputChange}
        placeholder={placeholder}
        onChange={onChange}
        isMulti={isMulti}
        isAsync={isAsync}
        handleCreate={handleCreate}
        error={error}
        isLoading={isLoading}
        isDisabled={isDisabled}
        name={name}
        {...rest}
      />
      {error && <StyledError>{error}&nbsp;</StyledError>}
    </InputWrapper>
  );
};

export default FormSelect;
