import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import CollaboratorsForm from 'pages/talent/Collaborators/CollaboratorsForm';
import React, { useRef, useState } from 'react';

const steps = {
  collaborators: {
    name: 'collaborators',
    title: 'Collaborators',
    subTitle: 'Add new collaborator',
    index: 1,
    modalSize: 'large'
  },
  add: {
    name: 'add',
    title: 'Add new collaborator',
    index: 2,
    nextStep: 'message',
    modalSize: 'large'
  },
  message: {
    title: 'Collaborator Added',
    nextStep: 'collaborators',
    index: 3,
    name: 'message',
    modalSize: 'large',
    padding: '32px 0 80px'
  }
};

const CollaboratorsModal = ({
  name,
  closePortal,
  data,
  collaborators = [],
  onSave,
  onFieldChange,
  label,
  loading,
  project
}) => {
  const [selectedCollaborators, setSelectedCollaborators] = useState(collaborators);
  const [showError] = useState(false);
  const [step, setStep] = React.useState(steps.collaborators.name);
  const [addOpen, setAddOpen] = useState(false);

  const collaboratorsRef = useRef(null);

  const handleSave = () => {
    if (!data.notClosePortal) {
      onFieldChange(selectedCollaborators);
      setTimeout(() => closePortal(), 500);
    } else {
      onSave(selectedCollaborators);
    }
  };

  const handleNextStep = e => {
    e.preventDefault();

    if (steps[step].index === 2) {
      collaboratorsRef.current && collaboratorsRef.current.click();
      return;
    }
    if (steps[step].index === 3) {
      handleDone();
      return;
    }

    if (steps[step].nextStep) return setStep(steps[step].nextStep);

    handleSave();
  };

  const setAddStep = () => {
    if (steps[step].index === 1) {
      setStep('add');
    }
  };

  const setDoneStep = () => {
    if (steps[step].index === 2) setStep('message');
  };

  const handleDone = () => {
    setStep('collaborators');
  };

  const handleCancel = () => {
    switch (steps[step].index) {
      case 1:
        closePortal();
        break;
      case 2:
        setAddOpen(false);
        setStep('collaborators');
        break;
      default:
        break;
    }
  };

  return (
    <TxplModal
      title={steps[step].title}
      titleMB={24}
      name={name}
      appElement={document.getElementById('root')}
      addPaddingTop
      allowLine
      size={steps[step].modalSize}
      padding={steps[step].padding}
      XSaddPaddingTop="14px"
      renderFooter={
        <>
          {loading && <Spinner />}
          {step !== 'message' && (
            <ContainedButton
              onClick={handleCancel}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              disabled={loading}
              mr={6}
            >
              {steps[step].index === 1 ? 'Cancel' : 'Back'}
            </ContainedButton>
          )}
          <ContainedButton
            form="addCreditProject"
            type="submit"
            backgroundColor="#7266EE"
            disabled={loading}
            data-testid="save-collaborators-modal"
          >
            {steps[step].nextStep === 'message' && 'ADD COLLABORATOR'}
            {steps[step].nextStep === 'collaborators' && 'DONE'}
            {!steps[step].nextStep && 'SAVE'}
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={handleNextStep} id="addCreditProject">
        <CollaboratorsForm
          handleChange={setSelectedCollaborators}
          label={label}
          collaborators={selectedCollaborators}
          showError={showError}
          step={steps[step].index}
          setAddStep={setAddStep}
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          setDoneStep={setDoneStep}
          handleDone={handleDone}
          ref={collaboratorsRef}
          project={project}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('collaborators-modal')(CollaboratorsModal);
