import React, { useState } from 'react';
import Sidebar from 'common/Sidebar/Sidebar';
import SidebarList from 'common/Sidebar/SidebarList';
import SidebarNavLink from 'common/Sidebar/SidebarNavLink';
import useProfilePath from 'hooks/useProfilePath';
import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import useViewport from 'hooks/useViewport';
import JobCardDropDown from 'pages/jobs/card/JobCardDropDown';
import SidebarFooter from 'common/Sidebar/SidebarFooter';
import Icon from 'common/Icon';

import useJobServices from 'hooks/api/useJobServices';
import { useSelector } from 'react-redux';
import { selectMyProfileId } from 'store/profile/profileSelectors';
import { useHistory } from 'react-router';
import useJobPath from 'hooks/useJobPath';
import {
  StyledReopenButton,
  StyledReopenButtonText,
  StyledSidebarClosedJobFooter,
  StyledText
} from './styled.components';
import ShareURL from '../../../../common/ShareURL';
import createSharingUrl from 'utils/create-sharing-url';
import { getDecodedIdToken } from 'utils/cookies';

const FlexRow = styled.div`
  display: flex;
  gap: 6px;
`;

const JobApplicantsSidebar = ({ job, jobId, jobStatus, validatePublish, onSave }) => {
  const { isS, isXS } = useViewport();
  const { myProfilePath } = useProfilePath();
  const publishIcon = jobStatus === 'published' ? 'share' : 'visible';
  const [status, setStatus] = useState(jobStatus);
  const history = useHistory();
  const decoded = getDecodedIdToken();
  const isSuperAdmin = decoded?.roles.includes('super-admin');
  const { jobDescriptionPage } = useJobPath(job);

  const [shareURLVisible, setShareURLVisible] = useState(false);

  const profileId = useSelector(selectMyProfileId);

  const { changeJobStatus } = useJobServices();

  const handleStatusChange = status => {
    const { success } = validatePublish();
    if (status === 'published' && !success) {
      return history.push(jobDescriptionPage);
    }
    changeJobStatus(profileId, jobId, status, () => setStatus('published'));
  };

  const handlePublishClick = e => {
    if (status === 'published') {
      setShareURLVisible(true);
    } else {
      onSave(e, 'published');
    }
  };

  return (
    <Sidebar>
      <SidebarList>
        <SidebarNavLink to={`${myProfilePath}/jobs/${jobId}/candidates`} icon="multi-users">
          Candidates
        </SidebarNavLink>
        <SidebarNavLink to={`${myProfilePath}/jobs/${jobId}/description`} icon="info">
          Job description
        </SidebarNavLink>
      </SidebarList>
      {status === 'closed' ? (
        <StyledSidebarClosedJobFooter>
          <StyledText>This job is closed</StyledText>
          <StyledReopenButton onClick={() => handleStatusChange('reopened')}>
            <Icon icon="reverse" width={14.3} height={13} />
            <StyledReopenButtonText>REOPEN</StyledReopenButtonText>
          </StyledReopenButton>
        </StyledSidebarClosedJobFooter>
      ) : (
        <SidebarFooter>
          <FlexRow>
            {!isXS && !isS && (
              <ContainedButton
                className="btn-secondary"
                padding="12px"
                height="auto"
                backgroundColor="rgba(204, 213, 255, 0.11)"
                icon={publishIcon}
                disabled={!isSuperAdmin}
                data={{ setStatus, jobId }}
                onClick={handlePublishClick}
              >
                {status === 'published' && shareURLVisible && (
                  <ShareURL
                    styles={{ container: { top: 40 } }}
                    visible={shareURLVisible}
                    setVisible={setShareURLVisible}
                    url={createSharingUrl({ type: 'job', id: job._id })}
                    successText="The job link has been copied"
                  />
                )}
                {status === 'published' ? 'Share Job' : status === 'closed' ? '' : 'Publish'}
              </ContainedButton>
            )}
            {status === 'published' && (
              <JobCardDropDown
                className="secondary"
                padding="11px 14px"
                jobId={jobId}
                jobStatus={jobStatus}
                setStatus={setStatus}
                profileId={profileId}
                isJobIPostedPage
                closeJobCallback={() => setStatus('closed')}
                noRight
                isMine
              />
            )}
          </FlexRow>
        </SidebarFooter>
      )}
    </Sidebar>
  );
};

export default JobApplicantsSidebar;
