import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import Loader from 'common/Loader';
import Badge from 'components/Badge';
import NotificationsEmpty from 'components/Notifications/NotificationsEmpty';
import useNotificationsServices from 'hooks/api/useNotificationsServices';
import useViewport from 'hooks/useViewport';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import notificationSelectors from 'store/notifications/notificationSelectors';
import { profileSelectors } from 'store/profile';
import { notificationTabsList } from 'utils/notificationUtils';
import Notification from './Notification';
import * as Styled from './NotificationsStyles';

const Notifications = ({ closeNotifications, large, page = 'about', updateMenuIsOpen }) => {
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const notifications = useSelector(notificationSelectors.selectNotifications(currentTab));
  const notificationsPagination = useSelector(notificationSelectors.selectNotificationsPagination(currentTab));
  const unseenNotificationsCount = useSelector(notificationSelectors.selectAllUnreadNotificationsCount);
  const unseenCommunityCount = useSelector(
    notificationSelectors.selectUnreadNotificationsCountByType('unseenCommunityCount')
  );
  const unseenJobsCount = useSelector(notificationSelectors.selectUnreadNotificationsCountByType('unseenJobsCount'));
  const unseenProfileCount = useSelector(
    notificationSelectors.selectUnreadNotificationsCountByType('unseenProfileCount')
  );
  const profileId = useSelector(profileSelectors.selectMyProfileId);

  const { getNotifications, setNotificationsAsSeen } = useNotificationsServices();
  const { isM } = useViewport();
  const firstNotificationId = notifications[0]?._id;
  const tabList = isM ? notificationTabsList.slice(0, 2) : notificationTabsList;

  const setAsSeen = page === 'home' ? unseenNotificationsCount : 0;
  useEffect(() => {
    if (unseenNotificationsCount > 0 && updateMenuIsOpen) {
      setNotificationsAsSeen(profileId, currentTab);
    }
  }, [updateMenuIsOpen, setAsSeen]);

  useEffect(() => {
    profileId && notifications.length === 0 && getNotifications(profileId, 0, currentTab);
  }, [profileId, currentTab]);

  const renderBadge = tabType => {
    let count = 0;
    switch (tabType) {
      case 'community':
        count = unseenCommunityCount;
        break;
      case 'jobs':
        count = unseenJobsCount;
        break;
      case 'profile':
        count = unseenProfileCount;
        break;
      case 'all':
        count = unseenNotificationsCount;
        break;
    }
    if (count) {
      return (
        <Badge text={count} color={tabType === currentTab ? 'white' : 'rgba(230, 234, 255, 0.6)'} textColor="#000" />
      );
    }
  };

  const handleLoadMore = () => {
    if (profileId) {
      getNotifications(profileId, notifications?.length, currentTab);
    }
  };

  const handleDropdownClick = tab => {
    setCurrentTab(tab.type);
    setSelectedIndex(2);
  };

  const handleTabClick = (tab, key) => {
    setCurrentTab(tab.type);
    setSelectedIndex(key);
  };

  return (
    <Styled.TabContainer large={large}>
      <Tabs selectedIndex={selectedIndex}>
        <TabList>
          {tabList.map((tab, key) => (
            <Tab key={key} onClick={() => handleTabClick(tab, key)}>
              <Styled.TabItem>
                <Styled.TabLabel>{tab.name}</Styled.TabLabel>
                {renderBadge(tab.type)}
              </Styled.TabItem>
            </Tab>
          ))}
          {isM && (
            <Tab key={2}>
              <DropDown
                className="more-notifications"
                text="more"
                icon="arrow-down"
                color={selectedIndex && '#fff'}
                right={0}
              >
                {notificationTabsList.slice(2).map((tab, key) => (
                  <DropDownItem key={key} onClick={() => handleDropdownClick(tab)} padding="9px 12px">
                    {tab.name}
                  </DropDownItem>
                ))}
              </DropDown>
            </Tab>
          )}
        </TabList>
        {notificationTabsList.map((tab, key) => (
          <TabPanel key={key}>
            <InfiniteScroll
              hasMore={notificationsPagination.hasMore}
              loadMore={handleLoadMore}
              loader={<Loader key={0} margin="56px auto 32px" />}
              useWindow={false}
              initialLoad={false}
            >
              {notificationsPagination.hasMore && !notifications?.length ? (
                <></>
              ) : (
                <>
                  {!!notifications.length &&
                    notifications.map(notification => (
                      <Notification
                        key={notification._id}
                        notification={notification}
                        currentTab={currentTab}
                        isFirst={notification._id === firstNotificationId}
                        closeNotifications={closeNotifications}
                      />
                    ))}
                  {!notifications.length && <NotificationsEmpty />}
                </>
              )}
            </InfiniteScroll>
          </TabPanel>
        ))}
      </Tabs>
    </Styled.TabContainer>
  );
};

export default Notifications;
