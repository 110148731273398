import React from 'react';

import Typography from 'components/shared/Typography';
import useViewport from 'hooks/useViewport';
import { getRandomAvatar } from 'utils/assets';

import {
  StyledAvatar,
  StyledContainer,
  StyledIcon,
  StyledTextContainer,
  StyledTooltip,
  TypographyTitle
} from './CollaboratorCardStyles';
import { getFullName } from '../../../../utils/collaboratorUtils';

const iconByStatus = {
  approved: 'thumb-up-outlined',
  approved_unverified: 'thumb-up-outlined',
  pending: 'time'
};

const CollaboratorCard = ({ collaborator, roleList }) => {
  const icon = iconByStatus[collaborator.status];
  const { isS, isXS } = useViewport();

  const renderRole = React.useMemo(() => {
    if (collaborator.role?._id) return roleList[collaborator.role._id];
  }, [collaborator.role, roleList]);

  const finalName = getFullName(collaborator);

  return (
    <StyledContainer
      className={!collaborator.collaborator ? 'border-dashed' : ''}
      pending={collaborator.status === 'pending'}
    >
      <StyledAvatar
        src={collaborator.collaboratorProfile?.basicInfo?.imageUri?.url || getRandomAvatar()}
        alt="Avatar"
      />
      <StyledTextContainer>
        {finalName?.length > 10 && !isS && !isXS ? (
          <TypographyTitle variant="medium" data-tip data-for={`colaboratorName${collaborator._id}`}>
            {`${finalName.substr(0, 10)}...`}
          </TypographyTitle>
        ) : (
          <TypographyTitle> {finalName} </TypographyTitle>
        )}
        <Typography opacity={0.35} variant="tiny">
          {renderRole}
        </Typography>
      </StyledTextContainer>
      {icon && <StyledIcon icon={icon} />}
      {finalName?.length > 10 && !isS && !isXS && (
        <StyledTooltip tooltipId={`colaboratorName${collaborator._id}`} place="top">
          {finalName}
        </StyledTooltip>
      )}
    </StyledContainer>
  );
};

export default CollaboratorCard;
