import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import FormSelect from 'common/form/FormSelect';
import FormSelectLocation from 'common/form/FormSelectLocation';
import Required from 'common/styles/Required';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useTagService from 'hooks/api/useTagService';
import _ from 'lodash';
import size from 'lodash/fp/size';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { tagSelectors } from 'store/tags';
import { getLanguagesOptions } from 'utils/languages';
import {
  compensationOptions,
  compensationOptionsContractor,
  experienceLevelOptions,
  jobTypeOptions,
  reqAvailabilityOptions,
  teamRoleOptions,
  workplaceTypeOptions
} from 'utils/selectOptions';
import { getFormSelectTypeArray } from 'utils/tagHelpers';
import { FormWrapper } from './styled.components';
import {
  CompensationBox,
  CompensationNumbers,
  CompensationSelect,
  RequiredInput,
  StyledFormCheckbox,
  StyledHelperFormCheckbox,
  StyledHintText,
  StyledLabel
} from './JobAtGlanceStyles';
import { isNotEmpty } from '@flybondi/ramda-land';

const JobAtGlanceModal = ({ closePortal, data, name, onSave }) => {
  const { details } = data;
  const { getTags } = useTagService();

  const tagDisciplines = useSelector(tagSelectors.selectTagsByType('disciplines'));

  const [disciplines, setDisciplines] = useState({
    options: getFormSelectTypeArray({ arr: tagDisciplines.data }),
    value: getFormSelectTypeArray({ arr: details?.disciplines, valueField: 'value', labelField: 'label' })
  });

  const [errors, setErrors] = useState('');
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [experienceLevel, setExperienceLevel] = useState(details?.experienceLevel || '');
  const [jobType, setJobType] = useState(details?.jobType || '');
  const [workplaceType, setWorkplaceType] = useState(details?.workplaceType || '');
  const [reqAvailability, setReqAvailability] = useState(details?.requiredAvailability || '');
  const [compensation, setCompensation] = useState(details?.compensation || '');
  const [compensationFrom, setCompensationFrom] = useState(details?.compensationRange?.from || '');
  const [compensationTo, setCompensationTo] = useState(details?.compensationRange?.to || '');
  const [location, setLocation] = useState(details?.location || null);
  const [minHoursOfOverlap, setMinHoursOfOverlap] = useState(details?.minHoursOfOverlap || null);
  const [language, setLanguage] = useState(details?.language || '');
  const [relocationAssistance, setRelocationAssistance] = useState(details?.relocationAssistance ?? false);
  const [anythingElse, setAnythingElse] = useState(details?.anythingElse || '');
  const [teamRole, setTeamRole] = useState(details?.teamRole || '');
  const [hideExperience, setHideExperience] = useState(details?.hideExperience ?? false);
  const [hideRemuneration, setHideRemuneration] = useState(details?.hideRemuneration ?? false);

  const addMoneySign = val => `$ ${val || ''}`;

  const removeMoneySign = val => val.replace('$ ', '');

  const isPositiveInteger = str => parseInt(str).toString() === str && parseInt(str) !== 0;

  const isEmptyInput = str => str === '';

  const isValidIntegerInput = str => isPositiveInteger(str) || isEmptyInput(str);

  const [locationRequired, setLocationRequired] = useState(true);
  const [showLocation, setShowLocation] = useState(true);

  const formIsValid = () => {
    setErrors('');
    let updatedErrors = { ...errors };
    if (!size(disciplines.value)) {
      updatedErrors = { ...updatedErrors, disciplines: 'Discipline is required' };
    }

    if (!jobType) {
      updatedErrors = { ...updatedErrors, jobType: 'Job Type is required' };
    }

    if (!experienceLevel) {
      updatedErrors = { ...updatedErrors, experienceLevel: 'Experience level is required' };
    }

    if (!workplaceType) {
      updatedErrors = { ...updatedErrors, workplaceType: 'Workplace Type is required' };
    }

    if (!reqAvailability) {
      updatedErrors = { ...updatedErrors, reqAvailability: 'Required Availability is required' };
    }

    if (!compensation && jobType?.value !== 'internship') {
      updatedErrors = { ...updatedErrors, compensation: 'This field is required' };
    }

    if (!compensationFrom && jobType?.value !== 'internship') {
      console.log('compensation req');
      updatedErrors = { ...updatedErrors, compensationFrom: 'This field is required' };
    }

    if (isNotEmpty(compensationTo) && +compensationTo < +compensationFrom) {
      updatedErrors = { ...updatedErrors, compensationTo: `Should be greater than ${addMoneySign(compensationFrom)}` };
    }

    if (!location && locationRequired) {
      updatedErrors = { ...updatedErrors, location: 'Location is required' };
    }

    if (!language) {
      updatedErrors = { ...updatedErrors, language: 'Language is required' };
    }

    const error = Object.entries(updatedErrors).find(key => {
      return key[1] !== '';
    });
    if (updatedErrors) {
      if (error?.length > 0) {
        const firstError = document.getElementsByClassName(`${error[0]}-required`);
        firstError?.length > 0 &&
          firstError[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
    setErrors(updatedErrors);
    if (!_.values(updatedErrors).every(_.isEmpty)) {
      return false;
    }

    return true;
  };

  const handleSave = e => {
    e.preventDefault();
    const isValid = formIsValid();

    if (isValid) {
      const details = {
        disciplines: disciplines.value,
        experienceLevel: experienceLevel,
        requiredAvailability: reqAvailability,
        jobType: jobType,
        compensation: compensation,
        compensationRange: {
          from: compensationFrom,
          to: compensationTo
        },
        location,
        minHoursOfOverlap,
        language,
        relocationAssistance,
        hideRemuneration,
        hideExperience,
        anythingElse,
        teamRole: teamRole,
        workplaceType
      };
      onSave(details);
      closePortal();
    }
  };

  const handleDisciplinesOptionsOpen = () => {
    const fetchTags = async () => {
      await getTags('discipline');
    };
    if (!tagDisciplines.isLoaded) {
      fetchTags();
    }
  };

  const handleWorkplaceChange = e => {
    setErrors({ ...errors, workplaceType: '' });
    setWorkplaceType(e);
    if (e.value === 'remote') {
      setLocationRequired(false);
      setShowLocation(false);
    } else {
      setLocationRequired(true);
      setShowLocation(true);
    }
  };

  useEffect(() => {
    setLanguagesOptions(getLanguagesOptions());
  }, []);

  useEffect(() => {
    const disciplinesOptions = tagDisciplines.data.map(discipline => ({
      value: discipline._id,
      label: discipline.name
    }));
    setDisciplines(currentDisciplines => ({ value: currentDisciplines.value, options: disciplinesOptions }));
  }, [tagDisciplines]);

  const compensationSelectOptions = useMemo(() => {
    return jobType?.value === 'contractor' ? compensationOptionsContractor : compensationOptions;
  }, [jobType?.value, compensationOptions, compensationOptionsContractor]);

  return (
    <TxplModal
      title={
        <>
          Job at a glance
          <Required ml={5} />
        </>
      }
      titleMB={8}
      subTitle="List the parameters and perks of this job."
      padding="0 0 58px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      width
      size="large"
      fromJob
      renderFooter={
        <>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton form="jobAtGlanceModal" type="submit" backgroundColor="#7266EE" forceDisplayText>
            save
          </ContainedButton>
        </>
      }
    >
      <FormWrapper onSubmit={handleSave} id="jobAtGlanceModal">
        <FormSelect
          required
          autoFocus
          mb={56}
          isMulti
          maxOptions={2}
          value={disciplines.value}
          options={disciplines.options}
          onChange={e => {
            setErrors({ ...errors, disciplines: '' });
            setDisciplines(currentDisciplines => ({ value: e, options: currentDisciplines.options }));
          }}
          onMenuOpen={handleDisciplinesOptionsOpen}
          label="Discipline"
          placeholder="Select an option"
          isClearable
          error={errors && errors.disciplines}
          id="disciplines"
          className="disciplines-required"
          isLoading={tagDisciplines.loading}
        />

        <FormSelect
          required
          mb={16}
          value={experienceLevel}
          options={experienceLevelOptions}
          onChange={e => {
            setErrors({ ...errors, experienceLevel: '' });
            setExperienceLevel(e);
          }}
          error={errors && errors.experienceLevel}
          label="Experience level"
          placeholder="Select an option"
          className="experience-required"
          isClearable
        />
        <StyledFormCheckbox
          mb={56}
          checked={hideExperience}
          value={hideExperience}
          onChange={() => {
            setHideExperience(!hideExperience);
          }}
          text="Hide experience level from the job description"
          iconBorder="rgba(204, 212, 255, 0.11)"
          textColor
        />

        <FormSelect
          mb={56}
          value={teamRole}
          options={teamRoleOptions}
          onChange={e => setTeamRole(e)}
          label="Team Role"
          placeholder="Select an option"
          isClearable
          id="teamRole"
        />
        <FormSelect
          required
          mb={56}
          value={reqAvailability}
          options={reqAvailabilityOptions}
          onChange={e => {
            setErrors({ ...errors, reqAvailability: '' });
            setReqAvailability(e);
          }}
          label="Required availability"
          placeholder="Select an option"
          isClearable
          error={errors && errors.reqAvailability}
          id="requiredAvailability"
          className="requiredAvailability-required"
          hintText="What level of time commitment will you require?"
        />
        <FormSelect
          required
          mb={56}
          value={jobType}
          options={jobTypeOptions}
          onChange={e => {
            setErrors({ ...errors, jobType: '', compensation: '', compensationFrom: '' });
            setJobType(e);
            // reset compensation value as its value depends on job type
            setCompensation('');
          }}
          label="Job type"
          placeholder="Select an option"
          isClearable
          error={errors && errors.jobType}
          id="jobType"
          className="jobType-required"
          hintText="What type of employment is this?"
        />
        <div style={{ marginBottom: '6px' }}>
          <StyledLabel line-height={26} font-size={19} mb={6}>
            <div>Compensation</div> {jobType?.value !== 'internship' && <RequiredInput />}
          </StyledLabel>
        </div>
        <StyledLabel mb={6}>
          <StyledHintText>We use this information to improve the quality of our matchmaking</StyledHintText>
        </StyledLabel>
        <CompensationBox>
          <CompensationNumbers>
            <FormInput
              mb={16}
              width="50%"
              value={compensationFrom && addMoneySign(compensationFrom)}
              error={errors && errors.compensationFrom}
              onChange={e => {
                isValidIntegerInput(removeMoneySign(e.target.value)) &&
                  setCompensationFrom(removeMoneySign(e.target.value));
                setErrors({ ...errors, compensationFrom: '' });
              }}
              hintText="From"
              placeholder="$"
            />
            <FormInput
              mb={16}
              width="48%"
              value={compensationTo && addMoneySign(compensationTo)}
              error={errors && errors.compensationTo}
              onChange={e => {
                isValidIntegerInput(removeMoneySign(e.target.value)) &&
                  setCompensationTo(removeMoneySign(e.target.value));
              }}
              onBlur={e => {
                if (isNotEmpty(e.target.value) && removeMoneySign(e.target.value) < +compensationFrom) {
                  setErrors({ ...errors, compensationTo: `Should be greater than ${addMoneySign(compensationFrom)}` });
                } else {
                  setErrors({ ...errors, compensationTo: '' });
                }
              }}
              hintText="To (Optional)"
              placeholder="$"
            />
          </CompensationNumbers>
          <CompensationSelect
            mb={16}
            width="34%"
            value={compensation}
            options={compensationSelectOptions}
            onChange={e => {
              setErrors({ ...errors, compensation: '' });
              setCompensation(e);
            }}
            error={errors && errors.compensation}
            hintText="Per"
            placeholder={jobType?.value === 'contractor' ? 'per hour' : 'per month'}
          />
        </CompensationBox>
        <StyledHelperFormCheckbox
          mb={56}
          checked={hideRemuneration}
          value={hideRemuneration}
          onChange={() => setHideRemuneration(!hideRemuneration)}
          text="Hide remuneration from this job description"
          iconBorder="rgba(204, 212, 255, 0.11)"
          textColor
        />

        <FormSelect
          required
          mb={workplaceType?.value === 'remote' ? ' 16' : '56'}
          value={workplaceType}
          options={workplaceTypeOptions}
          onChange={handleWorkplaceChange}
          label="Workplace type"
          placeholder="Select an option"
          isClearable
          error={errors && errors.workplaceType}
          id="workspaceType"
          className="workspaceType-required"
        />

        {workplaceType?.value === 'remote' && (
          <StyledFormCheckbox
            mb={56}
            checked={showLocation}
            value={showLocation}
            onChange={() => {
              setLocationRequired(!locationRequired);
              setShowLocation(!showLocation);
            }}
            text="Specify employee location"
            iconBorder="rgba(204, 212, 255, 0.11)"
            textColor
          />
        )}

        {showLocation && (
          <FormSelectLocation
            required={locationRequired}
            isClearable
            mb={16}
            value={location || ''}
            onChange={e => {
              setErrors({ ...errors, location: '' });
              setLocation(e?.value ?? '');
            }}
            label="Location"
            error={errors && errors.location}
            id="location"
            className="location-required"
          />
        )}

        {showLocation && workplaceType?.value !== 'remote' && (
          <StyledFormCheckbox
            mb={56}
            checked={relocationAssistance}
            value={relocationAssistance}
            onChange={() => setRelocationAssistance(!relocationAssistance)}
            text="Relocation assistance"
            iconBorder="rgba(204, 212, 255, 0.11)"
            textColor
          />
        )}

        {workplaceType?.value === 'remote' && (
          <FormInput
            mb={56}
            value={minHoursOfOverlap}
            onChange={e => setMinHoursOfOverlap(e.target.value)}
            label="Minimal hours of overlap"
            hintText="What’s the minimal number of hours applicant’s schedule should have with yours?"
            inputRight
            inputWidth={114}
          />
        )}
        <FormSelect
          mb={56}
          required
          value={language}
          options={languagesOptions}
          onChange={e => {
            setErrors({ ...errors, language: '' });
            setLanguage(e);
          }}
          label="Languages"
          placeholder="Select an option"
          error={errors && errors.language}
          id="language"
          className="language-required"
        />

        <FormInput
          mb={56}
          // height={250}
          value={anythingElse}
          onChange={e => setAnythingElse(e.target.value)}
          label="Anything else you want to highlight?"
          placeholder="E.g. AAA, New IP, Newly-formed team"
          inputRight
          maxLength={24}
        />
      </FormWrapper>
    </TxplModal>
  );
};

export default withRenderPortal('job-at-glance-modal')(JobAtGlanceModal);
