import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import commonStyles from 'utils/common-styles';
import useViewport from 'hooks/useViewport';
import { talentService } from 'api';

import FormCheckbox from 'common/form/FormCheckbox';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';

import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';
import VettingStatus from './VettingStatus';

const StyledItemContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: rgba(204, 213, 255, 0.05);
  display: grid;
  grid-row-gap: 14px;
`;

const StyledGameLabel = styled.h3`
  ${commonStyles.ui_heading_3}
  margin: 0px;
`;

const StyledDivider = styled.div`
  height: 2px;
  background-color: rgba(204, 213, 255, 0.11);
`;

const StyledSpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainedButton = styled(ContainedButton)`
  border: 1px solid rgba(204, 213, 255, 0.11);
`;

const ViewModalCreditItem = ({
  checked,
  chooseFromExisting,
  credit,
  hideChoose,
  hideDelete,
  notClosePortal,
  onChoose,
  selectedCredits,
  setSelectedCredits,
  single
}) => {
  const { display, game, name, role, vetted } = credit;

  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const dispatch = useDispatch();

  const { isXS } = useViewport();

  const handleVisibilityChange = event => {
    if (onChoose) {
      onChoose(credit._id);
      return;
    }

    dispatch(
      profileActions.updateProfileProperty({
        property: 'credits',
        updateType: 'update',
        value: { ...credit, display: event.target.checked },
        profileId
      })
    );
    talentService.setTalentDisplay(profileId, credit._id, { display: event.target.checked });
  };

  const handleDeleteClick = () => {
    if (notClosePortal) {
      const updatedSelectedCredits = [...selectedCredits];
      const index = selectedCredits.findIndex(item => item === credit._id);
      updatedSelectedCredits.splice(index, 1);
      setSelectedCredits(updatedSelectedCredits);
    } else {
      dispatch(
        portalActions.openPortal({
          name: 'delete-credit-modal',
          data: {
            talentId: profileId,
            credit
          }
        })
      );
    }
  };

  const gameName = game?.title ?? name;

  return (
    <StyledItemContainer>
      {!hideChoose && (
        <>
          <FormCheckbox
            value={display}
            checked={chooseFromExisting ? checked : display}
            onChange={handleVisibilityChange}
            {...(chooseFromExisting ? {} : { text: 'Show on the "About" page' })}
          />

          <StyledDivider />
        </>
      )}

      <StyledGameLabel>{gameName}</StyledGameLabel>
      <Typography opacity={0.35} variant="medium">
        {role?.name}
      </Typography>

      <StyledDivider />

      <StyledSpaceBetweenContainer>
        <VettingStatus vetted={vetted} />

        {!hideDelete && !isXS && (
          <StyledContainedButton
            backgroundColor="transparent"
            icon="delete"
            onClick={handleDeleteClick}
            closes={single ? '' : 'credits-modal'}
            forceDisplayText
          >
            DELETE
          </StyledContainedButton>
        )}
        {!hideDelete && isXS && (
          <ContainedButton
            icon="delete"
            opens="delete-credit-modal"
            closes="credits-modal"
            iconColor="#FF5151;"
            backgroundColor="transparent"
            color="rgba(204, 213, 255, 0.11);"
          />
        )}
      </StyledSpaceBetweenContainer>
    </StyledItemContainer>
  );
};

export default ViewModalCreditItem;
