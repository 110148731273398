import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';
import React from 'react';
import { pageSize } from 'utils/devices';
import Icon from './Icon';
import PurpleGradient from './PurpleGradient';
import Flex from './styles/Flex';

const PageWrapper = styled.div`
  max-width: 1240px;
  padding-top: 192px;
  min-height: calc(100vh - 272px);
  @media ${pageSize.L} {
    padding-left: 58px;
    padding-right: 58px;
  }
  @media ${pageSize.M} {
    padding-left: 54px;
    padding-right: 54px;
  }
  @media ${pageSize.S} {
    padding-left: 81px;
    padding-right: 81px;
  }
  @media ${pageSize.XS} {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${pageSize.S}, ${pageSize.M} {
    padding-top: 186px;
    min-height: calc(100vh - 266px);
  }
  @media ${pageSize.XS} {
    padding-top: 146px;
    min-height: calc(100vh - 226px);
  }
`;
const TextWrapper = styled.div`
  max-width: 702px;
  @media ${pageSize.M} {
    max-width: 585px;
  }
  @media ${pageSize.S} {
    max-width: 560px;
  }
  @media ${pageSize.XS} {
    max-width: 343px;
  }
`;

function ServicePage({ icon, iconColor, infoText, title }) {
  return (
    <>
      <PurpleGradient />
      <Flex width="100%" center>
        <PageWrapper>
          <Flex center>
            <Flex column gap={24}>
              <div>
                <Icon size={40} icon={icon} color={iconColor} hoverColor={iconColor} />
              </div>

              <Flex column gap={24} gapOnXS={16}>
                <Typography variant="h2">{title}</Typography>
                <TextWrapper>{infoText}</TextWrapper>
              </Flex>
            </Flex>
          </Flex>
        </PageWrapper>
      </Flex>
    </>
  );
}

export default ServicePage;
