import React, { useState } from 'react';

import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';
import { useDispatch } from 'react-redux';
import withRenderPortal from '../../hocs/withRenderPortal';
import FormInput from '../../common/form/FormInput';
import userService from '../../api/userService';
import { portalActions } from '../../store/portals';

const EmailText = styled.div`
  color: #8b8d9b;
`;

const EmailTo = styled.div`
  color: #fff;
  display: inline;
`;

const ResendWrapper = styled.div`
  color: #7266ee;
  cursor: pointer;
  display: inline;
`;

const StyledFormInput = styled(FormInput)`
  width: 20%;
`;

const StyledContainedButton = styled(ContainedButton)`
  margin-right: 5px;
`;

const EmailChangeModal = ({ closePortal, data, name }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleCode = code => {
    const regx = /^[0-9\b]+$/;
    regx.test(code) && code.length < 5 && setCode(code);
  };

  const handleConfirm = () => {
    setError('');
    userService
      .validateChangeEmail({
        email: data.email,
        code
      })
      .then(response => {
        const success = get('data.success', response);
        const message = get('data.message', response);
        if (success === false) {
          setError(message);
        }

        if (success === true) {
          closePortal();
        }
      });
  };

  const resend = () => {
    data.sendMail();

    dispatch(
      portalActions.openPortal({
        name: 'change-email-resend-modal'
      })
    );
  };

  return (
    <TxplModal
      title="Change Email Request"
      name={name}
      appElement={document.getElementById('root-modal')}
      size="small"
      hideBorder
      renderFooter={
        <>
          <StyledContainedButton backgroundColor="secondary" onClick={() => closePortal()}>
            Revert to previous email
          </StyledContainedButton>

          <ContainedButton backgroundColor="primary" onClick={() => handleConfirm()}>
            Confirm
          </ContainedButton>
        </>
      }
    >
      <EmailText>
        {`We've sent a verification link to `}
        <EmailTo>{data.email}</EmailTo>. Please follow it to reset the email. Give it 2 minutes, check your spam folder
        or <ResendWrapper onClick={resend}>click to resend</ResendWrapper>
      </EmailText>
      <br />
      <br />
      <StyledFormInput
        value={code}
        onChange={e => handleCode(e.target.value)}
        label="Verification code"
        error={error}
      />
    </TxplModal>
  );
};

export default withRenderPortal('change-email-modal')(EmailChangeModal);
