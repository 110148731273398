import styled from '@emotion/styled';

export const Container = styled.div`
  ${({ width }) => `width: ${width ? `${width}px` : '310px'}`};
  ${({ top }) => `top: ${top ? `${top}px` : '0'}`};
  ${({ bottom }) => `bottom: ${bottom && `${bottom}px`}`};
  ${({ left }) => `left: ${left ? `${left}px` : '0'}`};
  ${({ right }) => `right: ${right && `${right}px`}`};
  ${({ withBorder }) => `border: ${withBorder && '1px solid rgba(204, 213, 255, 0.11)'}`};
  padding: 8px;
  background: #1a1b1e;
  border-radius: 8px;
  position: absolute;
  z-index: 21;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Input = styled.input`
  ${({ width }) => `width: ${width ? `${width}px` : '240px'}`};
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  outline: none;
  background: transparent;
  padding: 8px 12px;
  cursor: pointer;

  &::placeholder {
    color: rgba(230, 234, 255, 0.35);
    font-size: 16px;
    line-height: 22px;
  }
`;
export const CopyIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  background: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  margin-left: 6px;
`;
