import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const JobsContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-flow: column;
  margin-top: -48px;
`;

export const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

export const StyledSeparator = styled.span`
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
`;

export const RejectedCount = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

export const JobItemCard = styled.div`
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid rgba(204, 213, 255, 0.11);
  min-height: 487px;
  box-sizing: border-box;
`;

export const JobItemImage = styled.div`
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const JobContent = styled.section`
  padding: 20px;
`;

export const JobTitle = styled.h3`
  color: #fff;
`;
