import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: linear-gradient(90deg, #7f1bce, #a20bff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  padding: 0px 16px 0px 24px;
  border-radius: 16px;
  @media (min-width: 769px) {
    height: 84px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px;
  }
`;
const Title = styled.h1`
  font-family: Space Grotesk;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 425px) {
    font-size: 24px;
    text-align: center;
  }
`;
const Button = styled.a`
  font-family: 'Space Grotesk';
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  border-radius: 8px;
  color: #7f1bce;
  background: white;
  height: 28px;
  padding: 12px 20px 12px 20px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

function StickyJoinBanner({ buttonTitle, title, url }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Button href={url}>{buttonTitle}</Button>
    </Wrapper>
  );
}

export default StickyJoinBanner;
