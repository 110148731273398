import isEmpty from 'lodash/fp/isEmpty';

const isEmptyFilters = filters => {
  if (isEmpty(filters)) {
    return true;
  }
  for (const key in filters) {
    if (!!filters[key].length || !isEmpty(filters[key])) {
      return false;
    }
  }

  return true;
};

export default isEmptyFilters;
