import ServicePage from 'common/ServicePage';
import Flex from 'common/styles/Flex';
import FeedbackModal from 'pages/ContactSupport/FeedbackModal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { portalActions } from 'store/portals';
import { ClickableText, StyledLink, Text } from './styled.components';

function AccountSuspended() {
  const dispatch = useDispatch();

  const toggleFeedbackModal = () => {
    dispatch(portalActions.openPortal({ name: 'feedback-modal' }));
  };

  return (
    <>
      <ServicePage
        icon="suspended"
        iconColor="linear-gradient(0deg, #FF7A00 0%, #C40000 100%)"
        title="Your account is suspended"
        infoText={
          <Flex gap={24}>
            <div>
              <Text>
                We typically suspend accounts for violations of <StyledLink to="/terms">Terms of Service.</StyledLink>
              </Text>
            </div>
            <div>
              <Text>
                For further details, please contact our&nbsp;
                <ClickableText color="#7266ee" onClick={toggleFeedbackModal}>
                  support team.
                </ClickableText>
              </Text>
            </div>
          </Flex>
        }
      />
      <FeedbackModal />
    </>
  );
}

export default AccountSuspended;
