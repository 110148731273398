import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const StyledTextContent = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: rgba(229, 232, 255, 0.65);
  text-align: center;
  padding: 0 44px;
`;

export const HeaderContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  margin-top: -32px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 24px 40px 0px 40px;

  background: radial-gradient(circle at 105% -58%, #bd00ff 14.06%, rgba(170, 0, 173, 0) 30%),
    radial-gradient(circle at 0% -58%, #5941ed 0%, rgba(66, 43, 189, 0.541667) 36.21%, rgba(38, 17, 131, 0) 200%);
  mixer-blend-mode: color-dodge;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media ${pageSize.S} {
    svg:nth-of-type(1) {
      margin-left: 120px;
      margin-right: 18px;
    }
  }

  @media ${pageSize.M} {
    svg:nth-of-type(1) {
      margin-left: 120px;
      margin-right: 18px;
    }
  }

  @media ${pageSize.L} {
    svg:nth-of-type(1) {
      margin-left: 120px;
      margin-right: 18px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  width: 272px;
  word-wrap: break-word;
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledContainedButton = styled(ContainedButton)`
  button {
    padding: 9px 16px;

    & .button-text {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
`;
