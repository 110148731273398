import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import talentService from 'api/talentService';
import tagService from 'api/tagService';
import { tagActions, tagSelectors } from 'store/tags';
import { portalActions } from 'store/portals';
import useTagService from 'hooks/api/useTagService';
import { getFormSelectTypeArray } from 'utils/tagHelpers';
import { selectOptionsToArrayOfStrings } from 'utils';
import BackOfficeMissingItemModal from 'pages/backoffice/BackOfficeMissingItems/BackOfficeMissingItemModal';
import { companyProfileService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import FormSelect from './form/FormSelect';
import Spinner from './Spinner';
import TxplModal from './TxplModal/TxplModal';
import ContainedButton from './ContainedButton';
import map from 'lodash/fp/map';
import withRenderPortal from 'hocs/withRenderPortal';
import isEmpty from 'lodash/fp/isEmpty';
import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import capitalize from 'lodash/fp/capitalize';
import { showJoinEventsModal } from 'utils/validateProfileData';

const tagsConfigSettings = () => ({
  keywords: {
    type: 'keyword',
    lists: null,
    label: 'Key(word)s',
    hintText:
      "Your Keys “open doors” to potential projects by showing Clients what you're capable of “unlocking”. Add up to 23 Key(word)s among the following.",
    placeholder: 'e.g. Shooter, 3D art, Xbox1, Comedy',
    required: false
  },
  platforms: {
    lists: ['Platforms'],
    type: 'platform',
    label: 'Platforms',
    hintText: 'Tell us the platforms you specialize in or would  like to focus on.',
    placeholder: 'e.g. Xbox1, PS Vita',
    required: false
  },
  tools: {
    lists: ['Tools'],
    label: 'Tools',
    type: 'tool',
    hintText:
      "What's in your arsenal? Be sure to include any game engines, discipline-specific software and collaboration tools under your belt.",
    placeholder: 'e.g. Unreal Engine, Maya, Python, Jira',
    required: true
  },
  genres: {
    lists: ['Genres', 'Themes', 'Player Perspectives', 'Game Play'],
    label: 'Genres',
    type: 'genre',
    hintText: 'Include themes, player perspectives, subgenres—whatever tickles your fancy.',
    placeholder: 'e.g. first-person shooter, open-world, puzzle',
    required: false
  },
  skills: {
    lists: ['Skills'],
    label: 'Skills',
    type: 'skill',
    hintText:
      "Let your top skills standout by listing them here. (It's best to leave out any nunchuck skills, bow-hunting skills...)",
    placeholder: 'e.g. Polygonal texturing, Presentation skills',
    required: true
  },
  tags: {
    lists: ['Tags'],
    label: 'Tags',
    type: 'tag',
    hintText: 'These guys will help prospective clients fine-tune their search to your niche. Tag away.',
    placeholder: 'e.g. AAA, fantasy, Co-Op,  Power-Ups',
    required: false
  }
});

const TagsModal = ({
  closePortal,
  data,
  name,
  notClosePortal,
  onAdd,
  required,
  saveLoading,
  tags: tagItems,
  tagsModalFooterPaddingTop
}) => {
  const dispatch = useDispatch();
  const { getTags } = useTagService();
  const tagsList = useSelector(tagSelectors.selectTagsByType(data.type));

  const tagsConfig = tagsConfigSettings()[data.type];

  const [tags, setTags] = useState({
    value: getFormSelectTypeArray({ arr: tagItems ? tagItems[data.type] : [] }),
    options: getFormSelectTypeArray({ arr: tagsList ? tagsList.data : [] })
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);
  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const profile = useSelector(profileSelectors.selectProfile);
  const verificationStatus = useSelector(currentUserPropertySelector('verificationStatus'));
  const isCollaborator = useSelector(currentUserPropertySelector('isCollaborator'));
  const hasJoinEventMessageRead = useSelector(selectCurrentUserProperty('settings'))?.hasJoinEventMessageRead;
  const shouldShowJoinEventsModal = useMemo(
    () => showJoinEventsModal({ profile, isCollaborator, verificationStatus, hasJoinEventMessageRead }),
    [profile, isCollaborator, verificationStatus]
  );

  const callTalentsTagService = postData => {
    const service = isCompanyView ? companyProfileService.setTalentTags : talentService.setTags;
    setIsLoading(true);
    service(profileId, postData)
      .then(res => {
        const property = data.type === 'keywords' ? 'keywords' : `about.${data.type}`;
        dispatch(
          profileActions.updateProfileProperty({
            property,
            value: property === 'keywords' ? res.data.data.keywords : res.data.data.about[data.type],
            profileId
          })
        );
        closePortal();

        // trigger join events modal in case id verification is already done
        // and tools are added for the first time
        if (shouldShowJoinEventsModal) {
          dispatch(portalActions.openPortal({ name: 'join-events-now-modal' }));
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleSave = async e => {
    e.preventDefault();
    setTouched(true);
    if (tagsConfig.required && (isEmpty(tags.value) || tags.value?.length === 0)) {
      setError(`${capitalize(data.type)} is required`);
      return;
    }

    if (!error) {
      const postData = {
        type: data.type,
        [data.type]: selectOptionsToArrayOfStrings(tags.value)
      };

      if (onAdd) {
        const tagData = map(({ item }) => item, tags.value);
        onAdd({ type: data.type, data: tagData }, () => closePortal());
        if (!notClosePortal) {
          setTimeout(() => closePortal(), 500);
        }
        return;
      }

      callTalentsTagService(postData);
    }
  };

  const handleChange = e => {
    let error = '';
    if (!e && touched) {
      error = `${data.type} is required`;
    }
    setError(error);
    setTags(currentTags => ({ value: e, options: currentTags.options }));
  };

  const handleCreate = async inputVal => {
    try {
      setLoading(true);

      const type = tagsConfig.type === 'keyword' ? 'tag' : tagsConfig.type;
      const tagRes = await tagService.requestTag({ name: inputVal, type });
      const tag = { value: tagRes.data.data._id, label: tagRes.data.data.name, item: tagRes.data.data };
      dispatch(tagActions.addTag(data.type, tagRes.data.data));
      setTags(currentTags => ({
        value: filter(item => !isEmpty(item), concat(currentTags.value, tag)),
        options: currentTags.options
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleOptionsOpen = async () => {
    if (!tagsList.isLoaded) {
      await getTags(data.key);
    }
  };

  useEffect(() => {
    const tagsOptions = getFormSelectTypeArray({ arr: tagsList ? tagsList.data : [] });
    setTags(currentTags => ({ value: currentTags.value, options: tagsOptions }));
  }, [tagsList]);

  return (
    <TxplModal
      title={data.hasOwnProperty('title') ? data.title : 'Tags'}
      name={name}
      onClose={closePortal}
      size="large"
      padding="0 0 58px 0"
      contentPadding="40px 40px 24px 40px"
      addPaddingTop
      XSaddPaddingTop="16px"
      titlePadding="24px"
      titleMB="8"
      addPaddingTopBig={tagsModalFooterPaddingTop}
      renderFooter={
        <>
          {(isLoading || saveLoading) && <Spinner />}
          <ContainedButton
            ml={6}
            mr={6}
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
            disabled={saveLoading}
          >
            Cancel
          </ContainedButton>
          <ContainedButton
            form="tagsModal"
            type="submit"
            backgroundColor="#7266EE"
            forceDisplayText
            disabled={saveLoading}
            data-testid="save-tags-modal"
          >
            Save
          </ContainedButton>
        </>
      }
    >
      <BackOfficeMissingItemModal />
      <form onSubmit={handleSave} id="tagsModal">
        <FormSelect
          padding="16px"
          required={tagsConfig.required}
          label={tagsConfig.label}
          value={tags.value}
          menuShouldScrollIntoView={false}
          menuPosition="absolute"
          menuPortalTarget={document.body}
          menuPlacement="bottom"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isMulti
          isCreatable
          options={tags.options.sort((a, b) => a.label.localeCompare(b.label)).filter(item => item.label.length > 0)}
          onMenuOpen={handleOptionsOpen}
          onChange={handleChange}
          hintText={tagsConfig.hintText}
          placeholder={tagsConfig.placeholder}
          handleCreate={handleCreate}
          isLoading={tagsList.loading || loading}
          maxOptions={23}
          error={tagsConfig.required && error && error}
          customNoOptMessage="23 will do here"
        />
      </form>
    </TxplModal>
  );
};

export const Modal = TagsModal;

export default withRenderPortal('tags-modal')(TagsModal);
