import React from 'react';
import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';

const Text = styled.div`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(242, 244, 255, 0.9);
  ${({ mt }) => mt && `margin-top: ${mt}px`}
`;

const LeavePageModal = ({ closePortal, data, name }) => {
  const handleStay = () => {
    data.onCancel();
    closePortal();
  };

  const handleLeave = () => {
    data.onOk(data.pathname);
    closePortal();
  };

  return (
    <TxplModal
      name={name}
      size="small"
      maxWidth={data.maxWidth}
      headerPaddingBottom={data.headerPaddingBottom}
      title={data.title ?? 'Leave page?'}
      padding={data.padding ?? '40px 0'}
      nonFullScreenOnMobile
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <ContainedButton mr={6} onClick={handleStay} backgroundColor="rgba(204, 213, 255, 0.11)">
            {data.cancelBtnText ?? 'Stay On Page'}
          </ContainedButton>
          <ContainedButton onClick={handleLeave}>{data.okBtnText ?? 'Leave page'}</ContainedButton>
        </>
      }
    >
      <Text>{data.message || 'You have unsaved changes to your profile.'}</Text>
      {data.messageLineTwo && <Text mt={16}>{data.messageLineTwo}</Text>}
    </TxplModal>
  );
};

export default withRenderPortal('leave-page-modal')(LeavePageModal);
