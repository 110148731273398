const benefitIcons = [
  'info',
  'thumbs-up',
  'portfolio',
  'business_development',
  'achievement',
  'publishing',
  'company',
  'jobs_i_posted',
  'community',
  'video_games',
  'jobs',
  'internship',
  'about',
  'time',
  'payments',
  'location',
  'rocket',
  'audio_music',
  'legal',
  'search',
  'visual_effects',
  'relocation',
  'analytics',
  'personal_info',
  'product_management',
  'seasoned_talent',
  'player_support',
  'calendar',
  'language',
  'tv'
];

export default benefitIcons;
