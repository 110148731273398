import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import useProfilePath from 'hooks/useProfilePath';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import useViewport from 'hooks/useViewport';
import React from 'react';
import createSharingUrl from 'utils/create-sharing-url';

function TalentCardDropDown({ biasReducedMode, talent }) {
  const { getProfilePath } = useProfilePath();
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { isXS } = useViewport();

  const profilePath = `${getProfilePath(talent?.url, talent?.type !== 'talent')}/about?bios-reduced=${biasReducedMode}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(createSharingUrl({ id: talent.id, type: 'profile' }));
    openSuccessSnackbar('Link copied');
  };

  return (
    <DropDown right={isXS && -126}>
      <DropDownItem icon="about" to={profilePath} target="_blank">
        See full profile
      </DropDownItem>
      <DropDownItem icon="link" onClick={handleCopy}>
        Copy profile link
      </DropDownItem>
    </DropDown>
  );
}

export default TalentCardDropDown;
