import React from 'react';
import styled from '@emotion/styled';

import Frame from 'common/Frame';
import commonStyles from 'utils/common-styles';
import ContainedButton from 'common/ContainedButton';
import ServiceOverviewModal from './ServiceOverviewModal';

const StyledFrame = styled(Frame)`
  border-radius: 0px;
  margin-top: 32px;
`;
const StyledServiceOverviewContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding-right: 10px;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 32px;
`;
const StyledServiceOverview = styled.h1`
  margin: 0px;
  ${commonStyles.paragraph}
  word-break: break-word;
  white-space: pre-wrap;
`;
const StyledButton = styled(ContainedButton)`
  position: relative;
  top: 4px;
  margin-left: 10px;
`;

const ServiceOverview = ({ onInputChange, serviceOverview }) => (
  <>
    {!serviceOverview && (
      <StyledFrame
        canEdit
        opens="service-overview-modal"
        data={{ serviceOverview }}
        text="Service Overview"
        noDataText="Current projects and services, bragging rights accomplishment, and future goals are all fair game here."
        editMode={serviceOverview ? 'edit' : 'add'}
      />
    )}

    {serviceOverview && (
      <StyledServiceOverviewContainer>
        <StyledServiceOverview>{serviceOverview}</StyledServiceOverview>
        <StyledButton
          backgroundColor="rgba(204, 213, 255, 0.11)"
          icon="edit"
          data={{ serviceOverview }}
          opens="service-overview-modal"
        />
      </StyledServiceOverviewContainer>
    )}

    <ServiceOverviewModal onAdd={value => onInputChange(value)} />
  </>
);

export default ServiceOverview;
