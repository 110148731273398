import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';
import ContainedButton from 'common/ContainedButton';
import useAPICall from 'hooks/useAPICall';
import Typography from 'components/shared/Typography';
import { backOfficeService } from 'api';
import Icon from '../../../common/Icon';
import BackOfficeCandidatesDate from './BackOfficeCandidatesDate';
import BackOfficeCandidatesModal from './modal/BackOfficeCandidatesModal';

const StyledTableTop = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StatusFilterText = styled.div`
  padding-top: 5px;
`;
const StyledIcon = styled(Icon)`
  padding-top: 8px;
  padding-right: 8px;
`;
const StatusFilter = styled.div`
  width: fit-content;
  background: rgba(221, 226, 255, 0.2);
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 12px 18px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
`;
const StyledDivider = styled.div`
  width: 1px;
  height: 48px;

  background-color: rgba(204, 213, 255, 0.11);
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
`;
const BackOfficeCandidates = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const { callApi } = useAPICall({
    url: '/candidates/change-status',
    method: 'put',
    name: 'change-candidate-status',
    transformData: response => response.data,
    onSuccess: () => {
      setForceReload(true);
    }
  });

  const [showRejectedStatus, setShowRejectedStatus] = useState(false);
  const [candidatesCount, setCandidatesCount] = useState();

  useEffect(() => {
    dispatch(backOfficeActions.updateBackOfficeTitle([{ label: 'Operations' }, { label: 'Candidates' }]));
  }, [dispatch]);

  const handleStatusFilter = () => {
    setShowRejectedStatus(!showRejectedStatus);
    window.location.href.includes('rejected') ? history.push('candidates') : history.push('candidates?rejected=hide');
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const onCloseHandler = () => setModalIsOpen(false);

  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    setModalIsOpen(!!selectedCandidate);
  }, [selectedCandidate]);

  useEffect(() => {
    if (modalIsOpen === false) {
      setSelectedCandidate(null);
    }
  }, [modalIsOpen]);
  const onSaveHandler = () => {
    setModalIsOpen(false);
    setForceReload(true);
  };
  const changeCandidateContactType = candidate => {
    callApi({
      candidateId: candidate._id,
      contactType: candidate.contactType === 'candidate' ? 'not_a_match_for_marketplace' : 'candidate'
    });
  };

  useEffect(() => {
    backOfficeService.backOfficeCandidates(window.location.href.includes('rejected')).then(res => {
      setCandidatesCount(res.data.count);
    });
  }, [window.location.href]);

  return (
    <>
      <StyledTableTop>
        <StatusFilter onClick={() => handleStatusFilter()}>
          <StyledIcon icon={showRejectedStatus ? 'visible' : 'hidden'} />
          <StatusFilterText>
            {window.location.href.includes('rejected') ? 'Show' : 'Hide'} Rejected Candidates
          </StatusFilterText>
        </StatusFilter>
        <Typography
          fontFamily="Space Grotesk"
          fontWeight="bold"
          fontSize="19px"
          lineHeight="26px"
          textAlign="right"
          color="rgba(230, 234, 255, 0.35)"
        >
          {candidatesCount || 0} total candidates
        </Typography>
      </StyledTableTop>
      <br />
      <Table
        keyId="_id"
        paginate
        endpoint="/backoffice/candidates"
        dataKey="candidates"
        forceReload={forceReload}
        setForceReload={setForceReload}
      >
        <Column
          title="Date Created"
          keyName="creationDate"
          render={candidate => <BackOfficeCandidatesDate date={candidate.creationDate} />}
          search
          sort
        />
        <Column title="Flag" keyName="flag" search sort />
        <Column title="Candidate Id" keyName="_id" search />
        <Column title="First Name" keyName="firstName" search sort />
        <Column title="Last Name" keyName="lastName" search sort />
        <Column title="Email" keyName="email" search sort />
        <Column title="Company Name" keyName="companyName" search sort />
        <Column title="Jobs Applied" keyName="jobsApplied" search sort />
        <Column title="Vip Code" keyName="vipCode" search sort />
        <Column
          title="Actions"
          padding="0"
          //   type="action"
          render={candidate => (
            <ActionsContainer>
              <ContainedButton
                style={{ margin: '4px 5px 4px 4px', backgroundColor: '#CCD4FF1C' }}
                icon="add"
                opens="candidate-modal"
                onClick={() => setSelectedCandidate(candidate)}
              >
                ADD
              </ContainedButton>
              <StyledDivider />
              <Icon
                margin="16px 18px 16px 12px"
                cursorPointer
                style={{}}
                icon="ignore"
                color={
                  candidate.contactType !== 'not_a_match_for_marketplace' ? 'rgba(230, 234, 255, 0.35);' : '#FF5151;'
                }
                hoverColor={candidate.contactType !== 'not_a_match_for_marketplace' ? '#fff' : '#FF5151;'}
                onClick={() => changeCandidateContactType(candidate)}
              />
            </ActionsContainer>
          )}
        />
      </Table>
      {modalIsOpen && (
        <BackOfficeCandidatesModal
          passedCandidate={selectedCandidate}
          onCloseHandler={onCloseHandler}
          onSaveHandler={onSaveHandler}
          relod={reload}
          setReload={setReload}
        />
      )}
    </>
  );
};

export default BackOfficeCandidates;
