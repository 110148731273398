import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import AllAdditionalTags from 'pages/talent/AllAdditionalTags';
import Toast from 'common/Toast';
import FormDragAndDrop from 'common/form/FormDragAndDrop';
import AssetsLightbox from 'common/AssetsLightbox';
import Typography from 'components/shared/Typography';
import useViewport from 'hooks/useViewport';
import { pageSize } from 'utils/devices';
import Icon from 'common/Icon';
import { useSelector } from 'react-redux';
import ProjectName from './ProjectName';
import ProjectHandiwork from './ProjectHandiwork';
import PublishProjectModal from './PublishProjectModal';
import MediaCoverageFrame from '../MediaCoverage/MediaCoverageFrame';
import AddPortfolioFooter from './AddPortfolioFooter';
import EditPortfolioFooter from './EditPortfolioFooter';
import usePortfolio from '../hooks/usePortfolio';
import AssetDescriptionModal from './AssetDescriptionModal';
import ProjectVideoInstructionModal from './ProjectVideoInstructionModal';
import ReachedMaxLimitModal from './ReachedMaxLimitModal';
import CollaboratorsFrame from '../Collaborators/CollaboratorsFrame/CollaboratorsFrame';
import Credits from '../Credits';
import { profileSelectors } from '../../../store/profile';
import { useLocation } from 'react-router';

const StyledBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;
const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 0px !important;
`;
const StyledTypography = styled(Typography)`
  color: rgba(230, 234, 255, 0.35);
`;
const StyledInstructionsText = styled(Typography)`
  color: rgba(229, 234, 255, 0.6);
`;
const StyledInstructions = styled(Typography)`
  display: inline;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  grid-gap: 10px;
  margin-left: auto;
  margin-left: 5px;

  @media ${pageSize.XS} {
    margin-top: 10px;
    margin-left: 0;
  }
  @media ${pageSize.S} {
    margin-top: 10px;
    margin-left: 0;
  }
`;
const StyledIcon = styled(Icon)`
  position: relative;
  top: 3px;
`;
const StyledInstructionSpan = styled.span`
  text-decoration: underline;
  margin-left: 5px;
`;

const hintTexts = {
  platforms: 'What platforms was this work made for?',
  tools: 'What tools did you use to create this work?',
  genres: 'What genres can this work be associated with?',
  skills: 'What skills were required to create this work?',
  tags: 'What tags would you add to this work?'
};

const onPublishValidate = (values, assets) => {
  const errors = {};

  if (!values.getIn(['name', 'value'])) errors.name = 'Required';
  if (!values.getIn(['roleAndAccomplishments', 'value'])) errors.roleAndAccomplishments = 'Required';
  if (!values.getIn(['platforms', 'value']).size) errors.platforms = 'There must be at least 1';
  if (!values.getIn(['genres', 'value']).size) errors.genres = 'There must be at least 1';
  if (!values.getIn(['tools', 'value']).size) errors.tools = 'There must be at least 1';
  if (!values.getIn(['tags', 'value']).size) errors.tags = 'There must be at least 1';
  if (!values.getIn(['skills', 'value']).size) errors.skills = 'There must be at least 1';
  if (!assets.length) errors.assets = 'There must be at least 1';
  return errors;
};

const onDraftValidate = values => {
  const errors = {};

  if (!values.getIn(['name', 'value'])) errors.name = 'Required';

  return errors;
};

const AddTalentProject = ({ projectId, setProject }) => {
  const [toastList, setToastList] = useState([]);

  const setToast = toast => {
    setToastList(items => [...items, toast]);
  };

  const {
    assetSizeError,
    assets,
    assetsOrder,
    errors,
    handleCancel,
    handleOpenVideoInstruction,
    isEditMode,
    loading,
    onAssetRemove,
    onAssetsDrop,
    onCollaboratorCustomValidate,
    onCreditAdd,
    onCreditDelete,
    onDraftSave,
    onFieldChange,
    onMediaCoverageAdd,
    onMediaCoverageDelete,
    onPortfolioSave,
    onPublishDataSave,
    onVideoDrop,
    onVideoRemove,
    project,
    projectVideo,
    projectVideoSizeError,
    reorderAssets,
    saveAssetDescription,
    submitting,
    triggerErrors,
    updateProjectProperty,
    values
  } = usePortfolio({ projectId, setToast });
  const { search } = useLocation();
  const addMode = projectId === 'add';
  const { width } = useViewport();
  const [showLightbox, setShowLightbox] = useState(false);
  const [assetIndex, setAssetIndex] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProject(project);
  }, [project]);

  const hasProfileEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const handleSave = (value, property, portalName, publish = false, onSuccess = () => ({})) => {
    updateProjectProperty(project.profile, property, value, {
      portalName,
      publish,
      onSuccess
    });
  };

  const sortAssets = (a, b) => {
    if (a.orderIndex > b.orderIndex) {
      return 1;
    }

    if (a.orderIndex < b.orderIndex) {
      return -1;
    }

    if (a.orderIndex === b.orderIndex) {
      return 0;
    }
  };

  const filteredAssets = assets.filter(asset => asset.file && asset.file.type).sort(sortAssets);
  const assetList = projectVideo ? [projectVideo, ...filteredAssets] : filteredAssets;

  useEffect(() => {
    const status = project?.status;
    const params = new URLSearchParams(search);
    if (params.get('validate-publish') === 'true') {
      if (status === 'draft') {
        triggerErrors()(items => onPublishValidate(items, assets));
      }
    }
  }, [project?._id, values]);

  const lockUnlockStatus = useMemo(
    () =>
      !(
        project?.vetting?.status === 'IN_PROGRESS' &&
        (project?.lockData?.status === 'LOCKED' || project?.lockData?.status === 'UNLOCK_REQUESTED')
      ),
    [project?.vetting, project?.lockData]
  );

  return (
    <>
      <Modal
        isOpen={showLightbox}
        appElement={document.getElementById('root')}
        onRequestClose={() => setShowLightbox(false)}
        closeModal={() => setShowLightbox(false)}
        style={{ overlay: { zIndex: 10 }, content: { top: '0', left: '0', right: '0', bottom: '0', border: 'none' } }}
      >
        <AssetsLightbox
          oneLineHeading="title"
          assets={assetList}
          assetIndex={assetIndex}
          closeModal={() => setShowLightbox(false)}
        />
      </Modal>
      <StyledBlockContainer className="grid-intro">
        {(values?.name.length > 0 || hasProfileEditPermission) && (
          <ProjectName
            project={project}
            industryType={project?.industryType}
            name={values.name}
            error={errors.name}
            status={project?.status}
            onSave={addMode ? onFieldChange('name') : value => handleSave(value, 'name', 'project-name-modal')}
            loading={loading.name}
            hasProfileEditPermission={hasProfileEditPermission}
            addMode={addMode}
            id="name"
            className="name-required"
          />
        )}
      </StyledBlockContainer>
      <ProjectVideoInstructionModal />
      <StyledBlockContainer className="grid-assets">
        <FormDragAndDrop
          project={project}
          lockUnlockStatus={addMode || lockUnlockStatus}
          noDataText={
            <>
              <StyledInstructionsText variant="medium">
                Recording a short video is a great way to create a sharable professional identity that highlights what
                you do best and gives clients a glimpse into your personality and communication skills.
                <StyledInstructions onClick={handleOpenVideoInstruction}>
                  <StyledIcon icon="question" color="#fff" size={16} />
                  <StyledInstructionSpan>See Instruction</StyledInstructionSpan>
                </StyledInstructions>
              </StyledInstructionsText>
              {projectVideoSizeError && (
                <StyledTypography error color="#f00" variant="medium">
                  Video must be less than 100mb
                </StyledTypography>
              )}
            </>
          }
          title="Project video"
          onDrop={onVideoDrop}
          titleIcon="video"
          titleIconSize={40}
          cloudIconMarginTop={62}
          backgroundColor="#7266EE"
          padding="40px 24px 48px"
          single
          files={[projectVideo]}
          onRemove={onVideoRemove}
          accept="video/mp4,video/x-m4v,video/*"
          loading={loading.video}
          setShowLightbox={setShowLightbox}
          setAssetIndex={setAssetIndex}
          openLightBoxWithZeroIndex={!projectVideo}
        />
        <FormDragAndDrop
          project={project}
          lockUnlockStatus={addMode || lockUnlockStatus}
          noDataText={
            <>
              <StyledTypography mb={16} variant="medium">
                Recommend featuring the best asset that represent your work at its prime, as the top item in this
                column. Below it, we recommend including design documents and earlier assets to help illustrate the
                evolution of the deliverables and your work.
              </StyledTypography>

              <StyledTypography error={assetSizeError} mb={width <= 1149 ? 40 : undefined} variant="medium">
                Images 5Mb or less. Video files, audio files, and PDFs. 50Mb or less
              </StyledTypography>
            </>
          }
          title="Project Assets"
          required
          titlePB={11}
          onDrop={onAssetsDrop}
          reorderAssets={reorderAssets}
          padding={'32px 24px 48px'}
          files={assetList}
          orders={assetsOrder}
          extraIconOpens="asset-description-modal"
          multi
          onRemove={onAssetRemove}
          error={errors.assets}
          rightIcon="draggable"
          accept="image/*,video/*,audio/*,application/pdf"
          onExtraIconClick={console.log}
          aspectRatio={16 / 9}
          sizeError={assetSizeError}
          loading={loading.asset}
          notClosePortal={!addMode}
          setShowLightbox={setShowLightbox}
          setAssetIndex={setAssetIndex}
          openLightBoxWithZeroIndex={!projectVideo}
          id="assets"
          className="assets-required"
        />
      </StyledBlockContainer>
      <StyledBlockContainer className="grid-intro2">
        {(values?.roleAndAccomplishments.length > 0 || hasProfileEditPermission) && (
          <ProjectHandiwork
            project={project}
            addMode={addMode}
            handiwork={values.roleAndAccomplishments}
            error={errors.roleAndAccomplishments}
            onSave={
              addMode
                ? onFieldChange('roleAndAccomplishments')
                : value => handleSave(value, 'roleAndAccomplishments', 'project-handiwork-modal')
            }
            loading={loading.roleAndAccomplishments}
            hasProfileEditPermission={hasProfileEditPermission}
            id="roleAndAccomplishments"
            className="roleAndAccomplishments-required"
          />
        )}
        {(values?.collaborators.length > 0 || hasProfileEditPermission) && (
          <CollaboratorsFrame
            project={project}
            error={errors.collaborators}
            onValidate={onCollaboratorCustomValidate}
            isEditMode={isEditMode}
            collaborators={values.collaborators}
            onFieldChange={onFieldChange('collaborators')}
            emptyLabel="Add collaborators you’ve worked with to extend your network and increase your chances of getting discovered."
            fullLabel="Add collaborators you’ve worked with to extend your network and increase your chances of getting discovered."
            onSave={value =>
              addMode
                ? onFieldChange('collaborators')(value)
                : handleSave(value, 'collaborators', 'collaborators-modal', true)
            }
            loading={loading.collaborators}
            notClosePortal={!addMode}
            id="collaborators"
            className="collaborators-required"
          />
        )}
        <Credits
          project={project}
          lockUnlockStatus={lockUnlockStatus}
          error={errors.credits}
          items={values.credits}
          isEditMode={isEditMode}
          chooseFromExisting
          hintText="Let us know if this project was part of commercial production."
          height={'270px'}
          onDelete={onCreditDelete}
          onChoose={credits =>
            addMode ? onFieldChange('credits')(credits) : handleSave(credits, 'credits', 'credits-modal', true)
          }
          onSave={addMode ? null : onCreditAdd}
          canEdit={hasProfileEditPermission}
          mediumView
          notClosePortal={!addMode}
          loading={loading.credits}
          fromPortfolio
        />
        <MediaCoverageFrame
          project={project}
          lockUnlockStatus={lockUnlockStatus}
          items={values.mediaCoverage}
          chooseFromExisting
          error={errors.mediaCoverage}
          hasProfileEditPermission={hasProfileEditPermission}
          onAdd={onMediaCoverageAdd}
          onChoose={media =>
            addMode
              ? onFieldChange('mediaCoverage')(media)
              : handleSave(media, 'mediaCoverage', 'choose-media-coverage-modal', true)
          }
          onDelete={onMediaCoverageDelete}
          isEditMode={isEditMode}
          skipDeleteConfirm
          loading={loading.mediaCoverage}
          notClosePortal={!addMode}
          single
          fromPortfolio
        />
        <StyledAllAdditionalTags
          project={project}
          maxWidth="250px"
          required
          hasDragPermission={hasProfileEditPermission}
          cols={3}
          data={values}
          errors={errors}
          isEditMode={isEditMode}
          portfolio={projectId}
          afterDrag={(type, ids) => onFieldChange(type)(ids)}
          hintTexts={hintTexts}
          order={['platforms', 'genres', 'tools', 'skills', 'tags']}
          page="projects"
          onAdd={({ data, type }, callback) =>
            addMode
              ? onFieldChange(type)(data)
              : handleSave(
                  data.map(item => item._id),
                  type,
                  'tags-modal',
                  true,
                  callback
                )
          }
          notClosePortal={!addMode}
          saveLoading={loading.platforms || loading.tools || loading.genres || loading.skills || loading.tags}
        />
      </StyledBlockContainer>

      {project ? (
        <EditPortfolioFooter
          lockUnlockStatus={lockUnlockStatus}
          project={project}
          handleSave={onPortfolioSave}
          onDraftSave={onDraftSave}
          coverImage={values.coverImage}
          projectId={projectId}
          status={project.status}
          submitting={submitting}
          assets={assets}
          triggerErrors={triggerErrors}
          onPublishValidate={onPublishValidate}
          onDraftValidate={onDraftValidate}
        />
      ) : (
        <AddPortfolioFooter
          assets={assets}
          triggerErrors={triggerErrors}
          handleCancel={handleCancel}
          saveDraft={onDraftSave}
          onDraftValidate={onDraftValidate}
          onPublishValidate={onPublishValidate}
          submitting={submitting}
        />
      )}

      <PublishProjectModal
        submitting={submitting}
        isAccurate={values.isAccurate}
        image={values.coverImage}
        keywords={values.keywords}
        allTags={[...values.platforms, ...values.tools, ...values.genres, ...values.skills, ...values.tags]}
        onSave={onPublishDataSave}
        saveDraft={onDraftSave}
        initialIndustryType={values.industryType}
      />
      <AssetDescriptionModal handleSave={saveAssetDescription} loading={loading.assetDescription} />
      <ReachedMaxLimitModal />
      {toastList.length > 0 && <Toast data={toastList} setToastList={setToastList} />}
    </>
  );
};

export default AddTalentProject;
