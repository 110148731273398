import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import profileUnlockService from 'api/profileUnlockService';
import get from 'lodash/fp/get';
import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';

const useProfileUnlock = () => {
  const dispatch = useDispatch();

  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const credits = useSelector(profileSelectors.selectProfileProperty('credits'));

  const [loading, setLoading] = useState({ addProfileUnlockRequest: false });

  const updateCreditLockDataStatus = creditId => {
    const updatedCredits = [...credits];

    const creditToUpdateIndex = updatedCredits.findIndex(credit => credit._id === creditId);
    updatedCredits[creditToUpdateIndex].lockData.status = 'UNLOCK_REQUESTED';

    dispatch(
      profileActions.updateProfileProperty({
        property: 'credits',
        value: updatedCredits,
        profileId
      })
    );
  };

  const addProfileUnlockRequest = async data => {
    try {
      setLoading({ addProfileUnlockRequest: true });
      const res = await profileUnlockService.addProfileUnlockRequest(data);
      if (res.status === 200) {
        dispatch(portalActions.openPortal({ name: 'unlock-request-modal' }));
        if (data.entityType === 'project') {
          const creditIds = get('data.entity.credits', res) || [];

          dispatch(
            profileActions.updateProfileProperty({
              property: `projects.${data.entityId}.lockData.status`,
              value: 'UNLOCK_REQUESTED',
              profileId
            })
          );
          creditIds.forEach(creditId => {
            updateCreditLockDataStatus(creditId);
          });
        }

        if (data.entityType === 'credit') {
          updateCreditLockDataStatus(data.entityId);
        }
      }
    } finally {
      setLoading({ addProfileUnlockRequest: false });
    }
  };

  return {
    addProfileUnlockRequest,
    loading
  };
};

export default useProfileUnlock;
