const specificWorkingHoursList = [
  {
    value: 0,
    label: '12 a.m.'
  },
  {
    value: 1,
    label: '1 a.m.'
  },
  {
    value: 2,
    label: '2 a.m.'
  },
  {
    value: 3,
    label: '3 a.m.'
  },
  {
    value: 4,
    label: '4 a.m.'
  },
  {
    value: 5,
    label: '5 a.m.'
  },
  {
    value: 6,
    label: '6 a.m.'
  },
  {
    value: 7,
    label: '7 a.m.'
  },
  {
    value: 8,
    label: '8 a.m.'
  },
  {
    value: 9,
    label: '9 a.m.'
  },
  {
    value: 10,
    label: '10 a.m.'
  },
  {
    value: 11,
    label: '11 a.m.'
  },
  {
    value: 12,
    label: '12 p.m.'
  },
  {
    value: 13,
    label: '1 p.m.'
  },
  {
    value: 14,
    label: '2 p.m.'
  },
  {
    value: 15,
    label: '3 p.m.'
  },
  {
    value: 16,
    label: '4 p.m.'
  },
  {
    value: 17,
    label: '5 p.m.'
  },
  {
    value: 18,
    label: '6 p.m.'
  },
  {
    value: 19,
    label: '7 p.m.'
  },
  {
    value: 20,
    label: '8 p.m.'
  },
  {
    value: 21,
    label: '9 p.m.'
  },
  {
    value: 22,
    label: '10 p.m.'
  },
  {
    value: 23,
    label: '11 p.m.'
  }
];

export default specificWorkingHoursList;
