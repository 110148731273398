import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { isNilOrEmpty } from '@flybondi/ramda-land';
import { any } from 'ramda';
import { talentService } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { tagSelectors } from 'store/tags';
import { authActions } from 'store/auth';
import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import { getArrayFromSelectTypeArray, getFormSelectTypeArray } from 'utils/tagHelpers';
import useTagService from 'hooks/api/useTagService';
import { tagKeys } from '../../../constants';
import useViewport from 'hooks/useViewport';
import useIDVerification from 'hooks/useIDVerification';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import {
  BasicProfileInfoFooter,
  SkipVerifyIdFooter,
  ToolkitFooter,
  VerifyIdFooter,
  WelcomeFooter
} from './Common/StepFooters';
import WelcomeToXplace from './Common/WelcomeToXplace';
import BasicProfileInfo from './Common/BasicProfileInfo';
import ToolkitInfo from './Common/ToolkitInfo';
import OnYourWay from './Common/OnYourWay';
import { dataURItoBlob } from 'utils/assets';
import validation from './FormModel/ValidationSchema';
import { selectOptionsToArrayOfStrings } from 'utils';
import { getLanguagesOptions } from 'utils/languages';
import chatUserService from '../../../chatApi/chatUserService';
import StepHeader from './Common/StepHeader';
import PartyConeIcon from 'common/IconComponents/PartyConeIcon';
import DuckSunglassesIcon from 'common/IconComponents/DuckSunglassesIcon';
import ToolkitBoxIcon from 'common/IconComponents/ToolkitBoxIcon';
import RocketCompleteIcon from 'common/IconComponents/RocketCompleteIcon';
import VerifyYourId from './Common/VerifyYourId';
import SkipVerifyYourId from './Common/SkipVerifyYourId';
import IdVerificationIcon from 'common/IconComponents/IdVerificationIcons/IdVerificationIcon';

const stepsConfig = [
  {
    key: '01',
    label: 'Welcome to TheXPlace!',
    component: WelcomeToXplace,
    footer: WelcomeFooter,
    icon: PartyConeIcon,
    closeIcon: true,
    step: null
  },
  {
    key: '02',
    label: 'You, in a nutshell',
    component: BasicProfileInfo,
    footer: BasicProfileInfoFooter,
    icon: DuckSunglassesIcon,
    closeIcon: true,
    step: 1
  },
  {
    key: '03',
    label: 'What’s in your toolkit?',
    component: ToolkitInfo,
    footer: ToolkitFooter,
    icon: ToolkitBoxIcon,
    closeIcon: true,
    step: 2
  },
  {
    key: '04',
    label: 'Verify your ID',
    component: VerifyYourId,
    footer: VerifyIdFooter,
    icon: IdVerificationIcon,
    closeIcon: false,
    step: 3
  },
  {
    key: '05',
    label: null,
    component: SkipVerifyYourId,
    footer: SkipVerifyIdFooter,
    icon: null,
    closeIcon: false,
    step: null
  },
  {
    key: '06',
    label: 'You’re on your way!',
    component: OnYourWay,
    footer: null,
    icon: RocketCompleteIcon,
    closeIcon: true,
    step: null
  }
];

const getExperienceLevelFromSelect = value => {
  let experienceLevel = null;

  if (value && value === 'intern') {
    experienceLevel = { from: null, to: null, isIntern: true };
  }

  if (value && value !== 'intern') {
    const from = parseInt(value.split('-')[0]) || null;
    const to = parseInt(value.split('-')[1]) || null;
    experienceLevel = {
      from,
      to,
      isIntern: false
    };
  }

  return experienceLevel;
};

const transformTalentFields = (formFields, dirtyLocation) => ({
  ...formFields,
  disciplines: selectOptionsToArrayOfStrings(formFields.disciplines),
  roles: selectOptionsToArrayOfStrings(formFields.roles),
  languages: getArrayFromSelectTypeArray({ arr: formFields.languages, valueField: 'id' }),
  experienceLevel: getExperienceLevelFromSelect(formFields.experienceLevel?.value),
  location: dirtyLocation ? formFields.location : undefined
});

const getExperienceLevelToSelect = value => {
  let initialExperienceLevel = '';

  if (value?.isIntern) {
    initialExperienceLevel = { value: 'intern', label: 'Student/Intern' };
  }

  if (!value?.from && value?.to) {
    const { to } = value;
    const years = `0-${to} years`;
    initialExperienceLevel = {
      value: `0-${to}`,
      label: `${to === 2 ? `Entry level (${years})` : years}`
    };
  }

  if (value?.from && value?.to) {
    const { from, to } = value;
    initialExperienceLevel = {
      value: `${from}-${to}`,
      label: `${from}-${to} years`
    };
  }

  if (value?.from && !value?.to) {
    const { from } = value;
    initialExperienceLevel = {
      value: `${from}-`,
      label: `${from}+ years`
    };
  }

  return initialExperienceLevel;
};

const getinitialValues = profile => {
  const { basicInfo, about, firstName, lastName, additionalName, useAdditionalName } = profile;
  return {
    avatarImage: basicInfo?.imageUri?.url ?? '',
    firstName: firstName,
    lastName: lastName,
    additionalName: additionalName,
    useAdditionalName: useAdditionalName,
    yourX: basicInfo?.yourX ?? '',
    disciplines: getFormSelectTypeArray({ arr: basicInfo?.disciplines }),
    roles: getFormSelectTypeArray({ arr: basicInfo?.roles }),
    location: basicInfo?.location ?? '',
    languages:
      basicInfo?.languages?.map(lang =>
        getLanguagesOptions().find(item => {
          return item.label === lang.name;
        })
      ) || [],
    experienceLevel: getExperienceLevelToSelect(basicInfo?.experienceLevel),
    tools: getFormSelectTypeArray({ arr: about?.tools }),
    skills: getFormSelectTypeArray({ arr: about?.skills }),
    platforms: getFormSelectTypeArray({ arr: about?.platforms }),
    genre: getFormSelectTypeArray({ arr: about?.genre })
  };
};

// set step depending on fields completed
const getInitialStep = profile => {
  const { basicInfo, about, firstName, lastName } = profile;
  const requiredBasicFields = [
    firstName,
    lastName,
    basicInfo?.disciplines,
    basicInfo?.roles,
    basicInfo?.location,
    basicInfo?.languages,
    basicInfo?.experienceLevel
  ];
  const requiredAboutFields = [about?.tools, about?.skills];

  return any(isNilOrEmpty, requiredBasicFields) ? 0 : any(isNilOrEmpty, requiredAboutFields) ? 2 : 3;
};

const QuickOnboardingModal = ({ name }) => {
  const [steps, setSteps] = useState(stepsConfig);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [dirtyLocation, setDirtyLocation] = useState(false);

  const { openStripeModal, getCurrentVerification } = useIDVerification();
  const { isXS } = useViewport();
  const dispatch = useDispatch();
  const { getTags } = useTagService();

  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const profile = useSelector(profileSelectors.selectProfile);
  const verificationStatus = useSelector(currentUserPropertySelector('verificationStatus'));
  const allTags = useSelector(tagSelectors.selectAllTags);
  const tagDisciplines = useSelector(tagSelectors.selectTagsByType('disciplines'));
  const disciplines = getFormSelectTypeArray({ arr: tagDisciplines.data });
  const tagRoles = useSelector(tagSelectors.selectTagsByType('roles'));
  const roles = getFormSelectTypeArray({ arr: tagRoles.data });
  const tagTools = useSelector(tagSelectors.selectTagsByType('tools'));
  const tools = getFormSelectTypeArray({ arr: tagTools.data });
  const tagSkills = useSelector(tagSelectors.selectTagsByType('skills'));
  const skills = getFormSelectTypeArray({ arr: tagSkills.data });
  const tagPlatforms = useSelector(tagSelectors.selectTagsByType('platforms'));
  const platforms = getFormSelectTypeArray({ arr: tagPlatforms.data });
  const tagGenres = useSelector(tagSelectors.selectTagsByType('genres'));
  const genres = getFormSelectTypeArray({ arr: tagGenres.data });

  useEffect(() => {
    setActiveStep(steps[getInitialStep(profile)]);
  }, [profile]);

  const tagsOptions = {
    disciplines: {
      options: disciplines,
      tagDisciplines
    },
    roles: {
      options: roles,
      tagRoles
    },
    tools: {
      options: tools,
      tagTools
    },
    skills: {
      options: skills,
      tagSkills
    },
    platforms: {
      options: platforms,
      tagPlatforms
    },
    genres: {
      options: genres,
      tagGenres
    }
  };

  const stepHeader = useCallback(() => {
    const { label, icon, closeIcon } = activeStep;

    // This means there is no header for this step
    if (!label && !icon && !closeIcon) {
      return null;
    }

    return <StepHeader activeStep={activeStep} />;
  }, [activeStep]);

  const formatTagsData = values => {
    const { tools, skills, platforms, genre } = values;

    const formData = {
      toolkit: [
        {
          type: 'tools',
          tools: selectOptionsToArrayOfStrings(tools)
        },
        {
          type: 'skills',
          skills: selectOptionsToArrayOfStrings(skills)
        }
      ]
    };

    if (platforms) {
      formData.toolkit.push({
        type: 'platforms',
        platforms: selectOptionsToArrayOfStrings(platforms)
      });
    }

    if (genre) {
      formData.toolkit.push({
        type: 'genres',
        genres: selectOptionsToArrayOfStrings(genre)
      });
    }

    return formData;
  };

  const setFieldsTouched = (setFieldTouched, step) => {
    switch (step) {
      case '02':
        setFieldTouched('avatarImage', true);
        setFieldTouched('firstName', true);
        setFieldTouched('lastName', true);
        setFieldTouched('yourX', true);
        setFieldTouched('disciplines', true);
        setFieldTouched('roles', true);
        setFieldTouched('location', true);
        setFieldTouched('languages', true);
        setFieldTouched('experienceLevel', true);
        break;
      case '03':
        setFieldTouched('tools', true);
        setFieldTouched('skills', true);
        setFieldTouched('platforms', true);
        setFieldTouched('genres', true);
        break;
    }
  };

  const updateStoreTalentBasicInfo = (values, data) => {
    dispatch(
      profileActions.updateProfileProperty({
        property: 'basicInfo',
        value: data.data,
        profileId
      })
    );

    dispatch(
      authActions.updateCurrentUserProperty({
        property: 'firstName',
        value: values.firstName
      })
    );
    dispatch(
      authActions.updateCurrentUserProperty({
        property: 'lastName',
        value: values.lastName
      })
    );

    dispatch(
      profileActions.updateProfileProperty({
        property: 'firstName',
        value: values.firstName,
        profileId
      })
    );
    dispatch(
      profileActions.updateProfileProperty({
        property: 'lastName',
        value: values.lastName,
        profileId
      })
    );
    dispatch(
      profileActions.updateProfileProperty({
        property: 'additionalName',
        value: values.additionalName,
        profileId
      })
    );
    dispatch(
      profileActions.updateProfileProperty({
        property: 'name',
        value:
          values.useAdditionalName && values.additionalName?.length
            ? `${values.firstName} ${values.lastName} (${values.additionalName})`
            : `${values.firstName} ${values.lastName}`,
        profileId
      })
    );

    dispatch(
      profileActions.updateProfileProperty({
        property: 'useAdditionalName',
        value: values.useAdditionalName,
        profileId
      })
    );

    dispatch(
      profileActions.updateProfileProperty({
        property: 'quickOnboardingStep',
        value: 2,
        profileId
      })
    );
  };

  const updateStoreToolkitInfo = data => {
    // Updates store toolkit data and quickOnboarding process.
    dispatch(
      profileActions.updateProfileProperty({
        property: 'about',
        value: data?.response?.about,
        profileId
      })
    );
    dispatch(
      profileActions.updateProfileProperty({
        property: 'quickOnboardingStep',
        value: 3,
        profileId
      })
    );
    dispatch(
      profileActions.updateProfileProperty({
        property: 'quickOnboardingCompleted',
        value: true,
        profileId
      })
    );
  };

  const handleOptionsOpen = async type => {
    const fetchTags = async () => {
      await getTags(type);
    };

    const key = tagKeys[type];
    if (!allTags[key].isLoaded) {
      await fetchTags();
    }
  };

  const scrollToErrorField = errors => {
    // Get all keys of the error messages.
    const keys = Object.keys(errors);

    // Get the first input/div element error by its name.
    const elementToQuery =
      keys[0] === 'firstName' || keys[0] === 'lastName' ? `input[name="${keys[0]}"]` : `div[name="${keys[0]}"]`;

    const errorElement = document.querySelector(elementToQuery);
    if (errorElement) {
      // When there is an input/div, scroll it into view.
      errorElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNext = async (formik, dirtyLocation) => {
    const { validateForm, setFieldTouched, values } = formik;

    if (activeStep.key === stepsConfig[0].key) {
      const service = talentService.beginQuickOnboarding;
      await service(profileId);
    }

    if (activeStep.key === stepsConfig[1].key) {
      setIsLoading(true);
      setFieldsTouched(setFieldTouched, stepsConfig[1].key);

      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setIsLoading(false);
        scrollToErrorField(errors);
        return;
      }

      const { avatarImage, ...rest } = values;
      const basicInfoData = transformTalentFields(rest, dirtyLocation);
      const formData = new FormData();

      if (profile?.basicInfo?.imageUri?.url !== avatarImage) {
        formData.append('file', dataURItoBlob(avatarImage));
      }
      formData.append('basicInfoData', JSON.stringify(basicInfoData));

      const service = talentService.setTalentBasicInfo;
      const { data } = await service(profileId, formData);

      await chatUserService.createUser(profileId, {
        id: profileId,
        name: `${values.firstName} ${values.lastName}`,
        photoUrl: data.data.imageUri.url
      });

      updateStoreTalentBasicInfo(values, data);
      setIsLoading(false);
    }

    if (activeStep.key === stepsConfig[2].key) {
      setIsLoading(true);
      setFieldsTouched(setFieldTouched, stepsConfig[2].key);
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setIsLoading(false);
        scrollToErrorField(errors);
        return;
      }

      const formData = formatTagsData(values);
      const service = talentService.setToolkit;

      const { data } = await service(profileId, formData);
      updateStoreToolkitInfo(data);

      setIsLoading(false);
    }

    if (steps[steps.length - 1].key === activeStep.key) {
      return;
    }

    const index = steps.findIndex(x => x.key === activeStep.key);
    setSteps(prevStep =>
      prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = true;
        return x;
      })
    );

    // Skip the verification step if the user is already verified.
    if (activeStep.key === stepsConfig[2].key && verificationStatus === 'verified') {
      setActiveStep(steps[index + 3]);
      dispatch(portalActions.closePortal({ name: 'quick-onboarding-modal' }));
      return;
    }

    setActiveStep(steps[index + 1]);
  };

  const handleBack = useCallback(() => {
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;

    setSteps(prevStep =>
      prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  }, [activeStep, steps]);

  const handleSelect = (field, value, setFieldValue) => {
    setFieldValue(field, value);
  };

  const handleVerifyId = async () => {
    setIsLoading(true);
    await openStripeModal();
    const { data } = await getCurrentVerification();

    // ID verification process has been submitted.
    if (data && data.status === 'pending') {
      dispatch(portalActions.closePortal({ name: 'quick-onboarding-modal' }));
      dispatch(portalActions.openPortal({ name: 'verification-submitted-modal' }));
    }

    if (data && data.status === 'verified') {
      dispatch(portalActions.closePortal({ name: 'quick-onboarding-modal' }));
      dispatch(portalActions.openPortal({ name: 'join-events-now-modal' }));
    }

    setIsLoading(false);
  };

  const isSecondStep = steps[1].key === activeStep.key;
  const isThirdStep = steps[2].key === activeStep.key;
  const hideBorder = !isXS && !isSecondStep && !isThirdStep;

  return (
    <Formik initialValues={getinitialValues(profile)} validationSchema={validation[activeStep.key]}>
      {formik => {
        return (
          <TxplModal
            name={name}
            renderHeader={stepHeader}
            hideBorder={hideBorder}
            renderFooter={
              activeStep.footer && (
                <activeStep.footer
                  formik={formik}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleVerifyId={handleVerifyId}
                  isLoading={isLoading}
                  dirtyLocation={dirtyLocation}
                />
              )
            }
            appElement={document.getElementById('root-modal')}
          >
            <Form>
              {
                <activeStep.component
                  formik={formik}
                  handleOptionsOpen={handleOptionsOpen}
                  handleSelect={handleSelect}
                  handleVerifyId={handleVerifyId}
                  tagsOptions={tagsOptions}
                  setDirtyLocation={setDirtyLocation}
                  isLoading={isLoading}
                  profile={profile}
                />
              }
            </Form>
          </TxplModal>
        );
      }}
    </Formik>
  );
};

export default withRenderPortal('quick-onboarding-modal')(QuickOnboardingModal);
