import React from 'react';

import Frame from 'common/Frame';
import ProjectItem from 'pages/talent/Projects/ProjectItem';
import AddProjectModal from 'pages/talent/Projects/AddProject/AddProjectModal';
import EditProjectModal from 'pages/talent/Projects/EditProject/EditProjectModal';
import { StyledContainedButton, StyledFrame } from './styled.components';

const JobProject = ({
  afterProjectAdd,
  afterProjectDelete,
  afterProjectEdit,
  hasEditPermission,
  jobId,
  projects,
  viewMode
}) => (
  <Frame
    transparent
    titlePB={24}
    text="project"
    color="#FF7CBB"
    canEdit={hasEditPermission}
    noIcon
    editMode={projects.length > 0 ? 'edit' : 'add'}
    noDataText="Add one or multiple projects related to this job from your ongoing projects or create a new one. Leave this blank if this job is not related to any particular project."
    filled={projects.length > 0}
  >
    {projects[0]?.name?.length &&
      projects?.map(project => (
        <ProjectItem
          hasEditPermission={hasEditPermission}
          key={project._id}
          project={project}
          {...project}
          showCollapse={false}
          setCollapsedItem={() => {}}
          fromAllJobs
          afterProjectEdit={afterProjectEdit}
          afterProjectDelete={afterProjectDelete}
          openScopeModal
          jobId={jobId}
          viewMode={viewMode}
        />
      ))}
    {hasEditPermission && (
      <>
        <StyledFrame filled={false}>
          <StyledContainedButton
            icon="add"
            type="button"
            data={{ projects, fromAllJobs: true }}
            opens="job-project-modal"
            backgroundColor="secondary"
          >
            Add Project
          </StyledContainedButton>
        </StyledFrame>
        <AddProjectModal onSuccess={afterProjectAdd} />
      </>
    )}
    <EditProjectModal />
  </Frame>
);

export default JobProject;
