import {
  CLEAR_APPLICANTS,
  DELETE_APPLICANTS,
  SET_APPLICANTS,
  UPDATE_APPLICANTS,
  UPDATE_APPLICANT_CONTRACT_STATUS,
  UPDATE_APPLICANT_STATUS
} from './applicantsActionTypes';

export const setApplicants = ({ applicants, jobId }) => ({
  type: SET_APPLICANTS,
  payload: {
    jobId,
    applicants
  }
});
export const updateApplicants = ({ applicants, jobId }) => ({
  type: UPDATE_APPLICANTS,
  payload: {
    jobId,
    applicants
  }
});

export const updateApplicantStatus = ({ applicantId, jobId, status }) => ({
  type: UPDATE_APPLICANT_STATUS,
  payload: {
    jobId,
    applicantId,
    status
  }
});

export const updateApplicantContractStatus = ({ applicantId, contractStatus, jobId }) => ({
  type: UPDATE_APPLICANT_CONTRACT_STATUS,
  payload: {
    jobId,
    applicantId,
    contractStatus
  }
});

export const deleteApplicants = ({ applicant, jobId }) => ({
  type: DELETE_APPLICANTS,
  payload: {
    jobId,
    applicant
  }
});

export const clearApplicants = () => ({
  type: CLEAR_APPLICANTS
});
