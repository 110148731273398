import React from 'react';
import JobsEmptyList from '../commons/JobsEmptyList';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { profileSelectors } from 'store/profile';
import { mapJobs } from '../helpers/mapJobs';
import JobList from '../commons/JobList';
import { isEmpty } from 'ramda';
import Loader from 'common/Loader';
import useHiredJobs from 'hooks/useHiredJobs';

const HiredJobs = () => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const { hiredJobs, getHiredJobs, hasMoreHiredJobs, loadingHiredJobs } = useHiredJobs();

  const jobs = mapJobs(hiredJobs, profileId);

  if (isEmpty(jobs) && loadingHiredJobs) {
    return <Loader />;
  }

  return (
    <InfiniteScroll
      loadMore={() => getHiredJobs(profileId, jobs.length)}
      hasMore={hasMoreHiredJobs}
      loader={<Loader key={0} style={{ marginTop: '43px' }} />}
      useWindow
    >
      {isEmpty(jobs) ? (
        <JobsEmptyList iconName="hired" text="Your started projects" />
      ) : (
        <JobList jobs={jobs} showDropdown hideApply={true}/>
      )}
    </InfiniteScroll>
  );
};

export default HiredJobs;
