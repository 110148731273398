import React from 'react';
import styled from '@emotion/styled';

import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';

import CompanyLogo from 'common/CompanyLogo';

import TestimonialCredits from './TestimonialCredits';

const TalentTestimonial = ({ testimonial }) => {
  if (!testimonial) {
    return null;
  }

  const { companyLogo, date, image: avatar, name, text } = testimonial;

  const TestimonialText = styled.div`
    ${commonStyles.paragraph}
    padding-right: 24px;
  `;

  const Spacer = styled.div`
    height: 0px;
    margin: 32px 0;
    border: 1px solid rgba(204, 213, 255, 0.11);
  `;

  const BottomFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${pageSize.XS} {
      flex-direction: column;
      height: 120px;
    }
  `;

  return (
    <>
      <TestimonialText>{`"${text}"`}</TestimonialText>
      <Spacer />
      <BottomFlex>
        <CompanyLogo logoUrl={companyLogo || '/assets/temp/temp_logo.svg'} />
        <TestimonialCredits date={date} name={name} avatar={avatar} />
      </BottomFlex>
    </>
  );
};

export default TalentTestimonial;
