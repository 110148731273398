import React from 'react';
import styled from '@emotion/styled';
import TxplModal from 'common/TxplModal/TxplModal';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput from 'common/form/FormInput';
import FormTextarea from 'common/form/FormTextarea';
import useForm from 'hooks/useForm';

const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const StyledTxplModal = styled(TxplModal)`
  .modal-title {
    margin-bottom: 16px !important;
  }
`;

const AddAssetDescription = ({ closePortal, data, handleSave, name }) => {
  const { onFieldChange, onSubmit, submitting, values } = useForm({
    fields: {
      name: { initialValue: data.name ?? '', trim: true },
      description: { initialValue: data.description ?? '', trim: true },
      assetId: { initialValue: data._id || data.id }
    },
    callApi: async fields => {
      if (data.name || data.description || fields.name || fields.description) {
        return handleSave(fields);
      }
      return false;
    },
    closePortal
  });

  return (
    <StyledTxplModal
      name={name}
      title="Asset description"
      titleMB={16}
      titleFontSize={24}
      padding="56px 0 82px"
      allowLine
      size="large"
      appElement={document.getElementById('root-modal')}
      addPaddingTop
      XSaddPaddingTop="14px"
      renderFooter={
        <StyledButtonsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="secondary" disabled={submitting}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={onSubmit} disabled={submitting}>
            Save
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <FormInput
        label="Asset name"
        hintText="Give this baby a name why don’t ya?!"
        value={values.name}
        onChange={({ target: { value } }) => onFieldChange('name')(value)}
        maxLength={50}
        placeholder="e.g. Environment concept art"
      />

      <FormTextarea
        label="Asset description"
        hintText="Describe how it applies to the project and why it rocks."
        value={values.description}
        onChange={({ target: { value } }) => onFieldChange('description')(value)}
        maxLength={250}
        mt={56}
      />
    </StyledTxplModal>
  );
};

export default withRenderPortal('asset-description-modal')(AddAssetDescription);
