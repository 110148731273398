import { createSelector } from 'reselect';
import { selectMyProfileProperty } from 'store/profile/profileSelectors';

const selectDomain = state => state.notifications;

const selectNotificationsDomain = createSelector(
  [selectDomain, selectMyProfileProperty('_id')],
  (state, profileId) => state[profileId] || []
);

const selectNotifications = type =>
  createSelector([selectNotificationsDomain], notifications =>
    notifications[type]?.data ? Object.values(notifications[type]?.data) : []
  );

const selectAllUnreadNotificationsCount = createSelector(
  [selectNotificationsDomain],
  notifications =>
    notifications?.unseenCommunityCount + notifications?.unseenJobsCount + notifications?.unseenProfileCount
);

const selectUnreadNotificationsCountByType = type =>
  createSelector([selectNotificationsDomain], notifications => notifications[type] ?? 0);

const selectNotificationsPagination = type =>
  createSelector(
    [selectNotificationsDomain],
    notifications => notifications[type]?.pagination ?? { hasMore: true, skip: 0, limit: 10 }
  );

export default {
  selectNotificationsDomain,
  selectNotifications,
  selectAllUnreadNotificationsCount,
  selectNotificationsPagination,
  selectUnreadNotificationsCountByType
};
