import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const UploadInputWrapper = styled.div`
  margin-right: 8px;
  width: 48px;
  height: 40px;
  background: rgba(204, 212, 255, 0.11);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media ${pageSize.XS} {
    margin-right: 4px;
  }
  ${({ isDisabled }) => isDisabled && 'opacity: .3;'}
`;

export const UploadListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const AttachmentWrapper = styled.div`
  position: relative;
  margin-top: 15px;
  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    .remove_attachment {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media ${pageSize.XS} {
    .remove_attachment {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
export const AttachmentClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(204, 213, 255, 0.11);
  position: absolute;
  top: -12px;
  right: -12px;
  opacity: 0;
  pointer-events: none;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const DocumentContainer = styled.div`
  height: 52px;
  padding: 0 10px;
  background: rgba(204, 212, 255, 0.05);
  border-radius: 12px;
  display: flex;
  align-items: center;
`;
export const DocumentName = styled.div`
  font-size: 14px;
  margin-left: 8px;
  color: #fff;
`;

export const ImageContainer = styled.div`
  border-radius: 12px;
  width: 52px;
  height: 52px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
