import React from 'react';
import * as Styled from './StyledConversationBox';

import ConversationMessageTo from './ConversationMessageTo';
import ConversationParticipant from './ConversationParticipant';
import StartConversation from './StartConversation';
import MessageList from './messages/MessageList';
import NewMessage from './messages/NewMessage';

const ConversationBox = ({
  conversationsLength,
  newMessageMode,
  selectedConversation,
  setNewMessageMode,
  setSelectedConversation,
  setVisible,
  visible
}) => (
  <Styled.Container visible={visible}>
    <Styled.Header isEmpty={!selectedConversation && !newMessageMode}>
      {!selectedConversation && newMessageMode && (
        <ConversationMessageTo setSelectedConversation={setSelectedConversation} setVisible={setVisible} />
      )}
      {selectedConversation && (
        <ConversationParticipant selectedConversation={selectedConversation} setVisible={setVisible} />
      )}
    </Styled.Header>
    <Styled.Content>
      {!selectedConversation && !newMessageMode && conversationsLength === 0 && (
        <StartConversation setNewMessageMode={setNewMessageMode} />
      )}
      {(selectedConversation || newMessageMode) && <MessageList selectedConversation={selectedConversation} />}
      {(newMessageMode || selectedConversation) && (
        <NewMessage
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          setNewMessageMode={setNewMessageMode}
        />
      )}
    </Styled.Content>
  </Styled.Container>
);

export default ConversationBox;
