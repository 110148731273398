import axios from 'axios';

function ChatApi(baseURL) {
  const chatApi = axios.create({
    baseURL: `${process.env.REACT_APP_TALK_JS_API_URL}/${process.env.REACT_APP_TALK_JS_APP_ID}/${baseURL}`
  });

  chatApi.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${process.env.REACT_APP_TALK_JS_API_KEY}`;

      return config;
    },
    error => Promise.reject(error)
  );

  return chatApi;
}

export default ChatApi;
