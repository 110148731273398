import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import axios from 'axios';

import './index.css';
import SnackbarProvider from 'react-simple-snackbar';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { cookieAuthAsName, cookieAuthName, getCookie } from 'utils/cookies';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

axios.interceptors.request.use(
  config => {
    const token = getCookie(cookieAuthAsName) || getCookie(cookieAuthName);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

const PageWrapper = styled.div`
  .Snackbar_snackbar-wrapper__ocbPJ {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    top: 80px;
    z-index: 999;
    @media ${pageSize.L} {
      padding: 0 58px;
    }
    @media ${pageSize.M} {
      padding: 0 54px;
    }
    @media ${pageSize.S} {
      padding: 0 81px;
    }
    @media ${pageSize.XS} {
      padding: 0 16px;
    }
  }
`;

ReactDOM.render(
  <PageWrapper>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </PageWrapper>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
