const CONNECTION = 'connection';
const DISCONNECT = 'disconnect';
const NOTIFICATION = 'notification';
const DELETE_NOTIFICATION = 'deleteNotification';
const WELCOME_MESSAGE = 'welcomeMessage';
const ID_VEIFICATION_RESPONSE = 'IDVerificationResponse';
const NEW_COLLABORATION = 'newCollaboration';
const NEW_CONVERSATION = 'newConversation';
const USER_TYPING_MESSAGE = 'userTypingMessage';
const MARK_CONVERSATION_AS_READ = 'markConversationAsRead';
const UPDATE_FUNNEL_STATUS = 'updateFunnelStatus';

export default {
  CONNECTION,
  DISCONNECT,
  NOTIFICATION,
  DELETE_NOTIFICATION,
  WELCOME_MESSAGE,
  ID_VEIFICATION_RESPONSE,
  NEW_COLLABORATION,
  NEW_CONVERSATION,
  USER_TYPING_MESSAGE,
  MARK_CONVERSATION_AS_READ,
  UPDATE_FUNNEL_STATUS
};
