import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import GameNotFoundTooltip from 'common/GameNotFoundTooltip';

const StyledMediaGameCardContainer = styled.div`
  display: grid;
  grid-template-areas:
    'side title'
    'side description';
  grid-template-columns: 50px auto;
  grid-column-gap: 16px;
  grid-template-rows: 20px auto;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 12px;
  padding: 16px;
  width: 267px;
  box-sizing: border-box;
`;

const StyledGameImage = styled.div`
  width: 47px;
  height: 68px;
  ${props => props.bg && 'background-color: #1A1B1E;'}
  grid-area: side;
  border-radius: 8px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.span`
  ${commonStyles.caption}
  grid-area: title;
  align-self: center;
  color: rgba(230, 234, 255, 0.35);
`;

const StyledDescription = styled.span`
  ${commonStyles.ui_text_medium}
  grid-area: description;
  align-self: start;
  font-weight: 400;
  margin-top: 8px;
`;

const StyledGameNotFoundTooltip = styled(GameNotFoundTooltip)`
  top: -4px;
  right: -4px;
`;

const MediaGameCard = ({ description, imageUrl, title }) => (
  <StyledMediaGameCardContainer>
    {imageUrl && <StyledGameImage bg src={imageUrl} />}
    {!imageUrl && (
      <StyledGameImage bg>
        <Icon icon="blank-image" size={22} />
        <StyledGameNotFoundTooltip />
      </StyledGameImage>
    )}

    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </StyledMediaGameCardContainer>
);

export default MediaGameCard;
