/* eslint-disable react/display-name */
import React, { forwardRef, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { currentUserEmailSelector } from 'store/selectors/currentUser';

import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import AddCollaborator from './AddCollaborator/AddCollaborator';
import CollaboratorItem from './CollaboratorItem/CollaboratorItem';
import CollaboratorsMessage from './CollaboratorsMessage';

const CollaboratorsForm = forwardRef(
  (
    { collaborators = [], handleChange, label, mb, mt, step, setAddStep, addOpen, setAddOpen, setDoneStep, project },
    ref
  ) => {
    const currentUserEmail = useSelector(currentUserEmailSelector);
    const [collaborate, setCollaborate] = useState();

    const handleAddCollaborator = () => {
      setAddStep();
      setAddOpen(true);
    };

    const onCollaboratorCustomValidate = formFields => {
      const errors = {};
      const email = formFields.getIn(['email', 'value']);
      const alreadyExists = collaborators.find(item => item.email === email);
      const isCurrentUserEmail = currentUserEmail === email;

      if (alreadyExists) errors.email = `Oops! You’ve already listed ${email} as a collaborator.`;
      if (isCurrentUserEmail) errors.email = `${email} matches your email. Please try another email`;

      const collaborationType = formFields.getIn(['collaborationType', 'value', 'value']);
      const collaborationTypeOther = formFields.getIn(['collaborationTypeOther', 'value']);

      if (collaborationType === 'Other' && !collaborationTypeOther)
        errors.collaborationTypeOther = 'Collaboration type is required';

      return errors;
    };

    const onCollaboratorAdd = collaborator => {
      handleChange([...collaborators, collaborator]);
      setCollaborate(collaborator);
      setAddOpen(false);
    };

    const handleDelete = collaborator => {
      handleChange(collaborators.filter(coll => coll.email !== collaborator.email));
    };

    const collabText = useMemo(() => label, [collaborators.length, label]);

    const headerText = () => {
      if (step === 1) {
        return collabText;
      }
      return 'Add collaborators to provide feedback about your shared working experience';
    };

    return (
      <>
        {step === 3 ? (
          <CollaboratorsMessage
            name={collaborate.name}
            email={collaborate.email}
            project={project}
            isActive={!!collaborate.collaborator}
            externalId={collaborate.externalId}
          />
        ) : (
          <>
            <Typography opacity="0.4" mb={24}>
              {headerText()}
            </Typography>
            {!addOpen &&
              collaborators.map((collaborator, key) => (
                <CollaboratorItem key={key} collaborator={collaborator} handleDelete={handleDelete} />
              ))}
            {addOpen && (
              <AddCollaborator
                onValidate={onCollaboratorCustomValidate}
                onAdd={onCollaboratorAdd}
                step={step}
                setDoneStep={setDoneStep}
                ref={ref}
              />
            )}
            {step === 1 && collaborators.length < 20 && (
              <ContainedButton
                mb={mb}
                mt={mt}
                backgroundColor="rgba(204, 213, 255, 0.11)"
                onClick={handleAddCollaborator}
                icon="add"
                type="button"
              >
                Add Collaborator
              </ContainedButton>
            )}
            {collaborators.length === 20 && (
              <Typography mt={30} error variant="small">
                Reached the maximum number of collaborators
              </Typography>
            )}
          </>
        )}
      </>
    );
  }
);

export default CollaboratorsForm;
