import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import withRenderPortal from 'hocs/withRenderPortal';

import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import Icon from 'common/Icon';

const StyledText = styled.p`
  ${commonStyles.ui_text_medium}
  width: 255px;
  text-align: center;
  margin: auto;
  color: rgba(230, 234, 255, 0.6);
`;
const StyledIcon = styled(Icon)`
  color: #fff;
`;
const ClaimedMediaCoverageModal = ({ closePortal, name }) => (
  <TxplModal
    title="Media Coverage"
    name={name}
    XSaddPaddingTop="14px"
    addPaddingTop
    allowLine
    centerFlex
    padding="67px 0px 93px 0"
    appElement={document.getElementById('root-modal')}
    size="small"
    renderFooter={
      <ContainedButton forceDisplayText onClick={closePortal}>
        Done
      </ContainedButton>
    }
  >
    <StyledText>
      You have claimed this media coverage. While we vet it, you will see this icon{' '}
      <StyledIcon color="black" icon="vetting" /> next to the entry. Once vetted, the icon will change to{' '}
      <Icon icon="vetted" />.
    </StyledText>
  </TxplModal>
);

export default withRenderPortal('claim-media-coverage-modal')(ClaimedMediaCoverageModal);
