import React, { useRef } from 'react';

import useOutsideClick from 'hooks/useOutsideClick';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import Icon from 'common/Icon';
import * as Styled from './StyledShareURL';

const ShareURL = ({ setVisible, styles, successText = '', url = '', visible }) => {
  const shareURLRef = useRef();
  useOutsideClick(shareURLRef, () => {
    if (visible) {
      setVisible(false);
    }
  });

  const { openSuccessSnackbar } = useSuccessSnackbar();

  const handleShareClick = () => {
    navigator.clipboard.writeText(url);
    openSuccessSnackbar(successText, 6000);
  };

  return (
    <Styled.Container ref={shareURLRef} onClick={handleShareClick} {...(styles.container || {})}>
      <Styled.Wrapper>
        <Styled.Input placeholder={url} {...(styles.input || {})} readOnly />
        <Styled.CopyIcon>
          <Icon icon="copy" />
        </Styled.CopyIcon>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default ShareURL;
