import axios from 'axios';
import { apiCallActions } from 'store/apiCall';
import store from '../store';

function Api(baseURL) {
  const api = axios.create({
    withCredentials: true,
    baseURL: `${process.env.REACT_APP_BASE_API_URL}api/${baseURL}`
  });

  api.interceptors.response.use(
    res => res,
    error => {
      if (error.response?.status === 401) {
        window.location.ref = '/login';
      }

      if (error.response?.status === 502) {
        store.dispatch(
          apiCallActions.sendRequestFailure({
            name: window.location.pathname,
            error: error.response,
            persist: true
          })
        );
      }
      return Promise.reject(error);
    }
  );

  return api;
}

export default Api;
