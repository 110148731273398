import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';

import { profileSelectors } from 'store/profile';
import useChatConversationService from 'hooks/chatApi/useChatConversation';

import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import FormTextarea from 'common/form/FormTextarea';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import useApplicantService from 'hooks/api/useApplicantService';

const SendMessageModal = ({ closePortal, data, name }) => {
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { changeApplicantStatus } = useApplicantService();

  const { jobCreatorProfileType = '', jobId, jobTitle, talent } = data;

  const { addMessage, createConversation, loading } = useChatConversationService();

  const [message, setMessage] = useState('');

  const handleDoneClick = async () => {
    const hasValue = message.replace(/\s/g, '').length !== 0;
    if (hasValue && !loading.createConversation && !loading.addMessage) {
      const { conversationId } = await createConversation(null, {
        contact: {
          id: talent.id,
          name: talent.name,
          photoUrl: talent.img
        },
        job: {
          id: jobId,
          title: jobTitle,
          profileType: jobCreatorProfileType
        }
      });
      await addMessage(conversationId, [{ text: message, sender: profileId, type: 'UserMessage' }]);
      if (!talent.hiredApplicant) {
        await changeApplicantStatus(talent.id, jobId, 'contacted');
      }

      closePortal();
      openSuccessSnackbar('Message Sent');
    }
  };

  return (
    <TxplModal
      title="Send a message"
      titleMB={6}
      padding="0 0 56px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      size="large"
      titleFontSize="19"
      hideHeaderBorder
      headerBorderSize="0px"
      addPaddingTop
      renderFooter={
        <>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton
            disabled={loading.createConversation || loading.addMessage}
            onClick={handleDoneClick}
            backgroundColor="#7266EE"
            forceDisplayText
          >
            done
          </ContainedButton>
        </>
      }
    >
      <FormTextarea
        placeholder="Message Text"
        onChange={e => setMessage(e.target.value)}
        value={message}
        gap="gap: 0px"
      />
    </TxplModal>
  );
};

export default withRenderPortal('send-message-modal')(SendMessageModal);
