export const mapJobs = (jobs, profileId, isSaved) =>
  Object.values(jobs).map(job => ({
    ...job,
    ...appliedData(job.appliedBy, profileId),
    isMine: profileId === (job?.talent?._id ?? job?.companyProfile?._id),
    isSaved: !!isSaved
  }));

export const appliedData = (appliedBy, id) => {
  const applied = appliedBy?.find(({ talentId }) => talentId === id);

  if (applied) {
    return {
      appliedStatus: applied.appliedData.status,
      appliedAt: applied.appliedData.createdAt
    };
  }
  return {};
};
