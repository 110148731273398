import { tagService } from 'api';
import jobService from 'api/jobService';
import { useErrorSnackbar, useSuccessSnackbar } from 'hooks/useReactSnackbar';
import { map } from 'ramda';
import React from 'react';
import { useLocation } from 'react-router';

const initialLoading = {
  loadingJobs: false,
  loadingSaveJobSearch: false
};

const getText = value => {
  return value.replace(/\([^)]*\)/g, '').trim();
};
const getTitles = map(getText);

function useJobSearch() {
  const { search } = useLocation();

  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { openErrorSnackbar } = useErrorSnackbar();

  const [loading, setLoading] = React.useState(initialLoading);
  const [jobs, setJobs] = React.useState([]);
  const [jobFilters, setFilters] = React.useState({});
  const [jobsPage, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);

  const searchJobs = React.useCallback(
    async (
      profileId,
      query,
      page = 1,
      sort = 'desc',
      opt = { isCountSearch: false },
      callback = () => ({}),
      limit = 9
    ) => {
      const { clear, update } = Object.assign({ clear: false, update: true }, opt);

      if (!loading.loadingJobs || !update) {
        try {
          setLoading({ ...loading, loadingJobs: true });
          const {
            data: { data, filters, pagination }
          } = await jobService.search(profileId, query, page, sort, limit, opt.isCountSearch);

          console.log('jobService', { data, filters, pagination });
          const {
            data: { role: roles }
          } = await tagService.getTagsByType({ types: ['role'] });

          console.log('tagService', { roles });

          const titles = { title: getTitles(filters.title) };

          console.log('titles', { titles });

          const preferenceAndRole = {
            role: roles.map(role => role.value),
            preference: [
              {
                label: 'Remote',
                value: 'remote'
              },
              {
                label: 'Relocation',
                value: 'relocation'
              }
            ]
          };

          setJobs(items => (clear ? data : [...items, ...data]));
          update && setFilters(Object.assign(filters, preferenceAndRole, titles));

          const jobsLength = clear ? 0 : jobs.length;

          setHasMore(jobsLength + limit < pagination.total);
          setTotal(pagination.total);
          setPage(page + 1);
        } catch (e) {
          console.log(e);
          setHasMore(false);
        } finally {
          setLoading({ ...loading, loadingJobs: false });
          callback && callback();
        }
      }
    },
    [loading, jobs.length]
  );

  const saveJobSearch = async (profileId, isCompany, callback) => {
    if (profileId !== undefined && !loading.loadingSaveJobSearch) {
      try {
        setLoading({ ...loading, loadingSaveJobSearch: false });
        await jobService.saveJobSearch(profileId, {
          type: isCompany ? 'company' : 'talent',
          selectedFilter: search
        });

        openSuccessSnackbar('Search has been saved');
        callback && callback();
      } catch (err) {
        const message = err.response.data.error ?? 'Oops! Something went Wrong';
        openErrorSnackbar(message);
      } finally {
        setLoading({ ...loading, loadingSaveJobSearch: false });
      }
    }
  };

  return {
    loading,
    jobs,
    jobFilters,
    hasMoreJobs: hasMore,
    jobsPage,
    jobsFound: total,
    searchJobs,
    saveJobSearch,
    setJobsFound: setTotal
  };
}

export default useJobSearch;
