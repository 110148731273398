import React from 'react';

import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import styled from '@emotion/styled';
import Typography from '../../components/shared/Typography';
import withRenderPortal from '../../hocs/withRenderPortal';

const StyledTypography = styled(Typography)`
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
`;

const SettingsSaved = ({ closePortal, data, name }) => (
  <TxplModal
    title="Changes Saved"
    name={name}
    appElement={document.getElementById('root-modal')}
    size="small"
    hideBorder
    renderFooter={
      <ContainedButton backgroundColor="primary" onClick={() => closePortal()}>
        Done
      </ContainedButton>
    }
  >
    <StyledTypography variant="medium">{data.text}</StyledTypography>
  </TxplModal>
);

export default withRenderPortal('settings-saved-modal')(SettingsSaved);
