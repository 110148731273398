import React from 'react';
import styled from '@emotion/styled';

import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import FormSelect from 'common/form/FormSelect';
import useForm from 'hooks/useForm';
import withRenderPortal from 'hocs/withRenderPortal';
import { backOfficeService } from 'api';
import { roles } from '../../../constants';

const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const fields = {
  permission: { initialValue: '', validate: ['isRequired'] }
};

const BackofficeEditCompanyMemberRoleModal = ({ closePortal, data, name, refetch }) => {
  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: params =>
      backOfficeService.updateMemberRoleInCompany(data.companyId, {
        ...params,
        memberId: data.memberId
      }),
    onSubmitSuccess: () => {
      refetch();
      closePortal();
    }
  });

  React.useEffect(() => {
    if (data.role) onFieldChange('permission')(data.role);
  }, []);

  return (
    <TxplModal
      name={name}
      title="Edit company member"
      size="small"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <StyledActionsContainer>
          <ContainedButton
            color="#FF5151"
            backgroundColor="transparent"
            icon="delete"
            opens="delete-company-member-bo-modal"
            closes={name}
            data={data}
          >
            Remove member
          </ContainedButton>

          <StyledButtonsContainer>
            {submitting && <Spinner />}

            <ContainedButton backgroundColor="secondary" onClick={closePortal}>
              Cancel
            </ContainedButton>

            <ContainedButton onClick={onSubmit}>Save</ContainedButton>
          </StyledButtonsContainer>
        </StyledActionsContainer>
      }
    >
      <FormSelect
        options={roles}
        label="Permission"
        value={roles.find(role => role.value === values.permission)}
        onChange={option => onFieldChange('permission')(option.value)}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        error={errors.permission}
      />
    </TxplModal>
  );
};

export default withRenderPortal('edit-company-member-modal')(BackofficeEditCompanyMemberRoleModal);
