import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import kebabCase from 'lodash/fp/kebabCase';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import { StyledButtonsContainer, StyledText } from './StyledLifePageDeleteConfirmationModal';
import { deleteCategory, getCategories } from '../../../../api/categoryServices';
import { profileActions, profileSelectors } from '../../../../store/profile';

const LifePageDeleteConfirmationModal = ({
  closePortal,
  name,
  pageId,
  pageName,
  setActiveButton,
  setListOfCategories,
  setShowField
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const companyId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const lives = useSelector(profileSelectors.selectProfileProperty('lives'));

  const handlePageDelete = () => {
    if (pageId === 0) {
      setShowField(false);
      closePortal();
    } else {
      setLoading(true);
      deleteCategory(companyId, 'life', pageId).then(() => {
        getCategories(companyId, 'life').then(res => {
          setListOfCategories(res.data.data);
          res.data.data && setActiveButton(kebabCase(res.data.data[0]?.name ?? ''));
          const updatedLives = lives.filter(live => live.categoryId !== pageId);
          dispatch(
            profileActions.updateProfileProperty({ property: 'lives', value: updatedLives, profileId: companyId })
          );
          setLoading(false);
          closePortal();
        });
      });
    }
  };

  return (
    <TxplModal
      title="Delete page?"
      name={name}
      headerBorderColor="rgba(221, 226, 255, 0.2)"
      size="small"
      hideBorder
      padding="40px 0"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <StyledButtonsContainer>
          {loading && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handlePageDelete} backgroundColor="#FF5151" border="none" forceDisplayText>
            Delete Page
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <StyledText>
        Are you sure you want to delete the page “{pageName}”? You will lose all the content included in it.
      </StyledText>
    </TxplModal>
  );
};

export default withRenderPortal('life-page-delete-confirmation-modal')(LifePageDeleteConfirmationModal);
