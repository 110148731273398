import get from 'lodash/fp/get';

export const currUserNamespaceSelector = state => state.currentUser;

export const currUserFFNamespaceSelector = state => state.currentUser.featureFlags;

export const isSuperUserSelector = state => state?.currentUser?.roles?.some(el => el.role.startsWith('super-'));

export const isAnalystUserSelector = state => state?.currentUser?.roles?.some(el => el.role.startsWith('analyst'));

export const isLoggedInSelector = state => !!state?.currentUser?._id;

export const currentUserIdSelector = state => state?.currentUser?._id;

export const currentUserRoles = state => state?.currentUser?.roles || [];

export const currentUserNameSelector = state => {
  const firstName = state?.currentUser?.firstName || '';
  const lastName = state?.currentUser?.lastName || '';
  return `${firstName} ${lastName}`;
};

export const currentUserEmailSelector = state => {
  return state?.currentUser?.email;
};

export const currentUserFirstNameSelector = state => {
  const firstName = state?.currentUser?.firstName || '';
  return `${firstName}`;
};

export const currentUserAvatarSelector = state => state?.currentUser?.imageUri;

export const currentUserPropertySelector = property => state => get(property, currUserNamespaceSelector(state));

export const currentUserHasFFSelector = feature => state => get(feature, currUserFFNamespaceSelector(state)) === true;
