import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  height: 40px;
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(204, 213, 255, 0.05);
  margin: 8px 0;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const StyledCollaboratorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
