import React from 'react';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';

const LockButton = ({ mr, onClick, tooltip }) => (
  <ContainedButton onClick={onClick} backgroundColor="#1A1B1E" mr={mr} tooltip={tooltip} tooltipId={tooltip}>
    <Icon icon="lock" height="16px" width="12px" />
  </ContainedButton>
);

export default LockButton;
