import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import NA from 'images/NA.png';
import useProfileUnlock from 'hooks/api/useProfileUnlockService';
import Tags from 'common/Tags';
import { pageSize } from 'utils/devices';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Icon from '../../common/Icon';
import GalleryCardDropdown from './GalleryCardDropdown';
import IndustryIcons from 'common/IndustryIcon/IndustryIcon';
import useProfilePath from 'hooks/useProfilePath';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import LockButton from 'common/lockUnlockButtons/LockButton';
import UnlockPendingButton from 'common/lockUnlockButtons/UnlockPendingButton';
import UnlockButton from 'common/lockUnlockButtons/UnlockButton';
import VettingStatus from 'common/VettingStatus';
import ViaTheXPlace from '../../images/via-thexplace-logo.svg';

const ImageWrapper = styled.div`
  position: relative;
`;
const GalleryImage = styled.div`
  width: 100%;
  height: 239px;
  background-image: ${props => `url("${props.src}")`};
  background-position: center center;
  background-size: cover;
  border-radius: 16px 16px 0 0;

  @media ${pageSize.XS} {
    height: 185px;
  }
`;
const CompanyLogos = styled.div`
  position: absolute;
  display: flex;
  left: 16px;
  bottom: 16px;
`;
const CompanyLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 81px;
  height: 36px;
  background: rgba(17, 17, 18, 0.7);
  border-radius: 8px;
  margin-right: 6px;
`;
const CompanyLogo = styled.img`
  max-width: 80%;
  max-height: 80%;
`;
const TextWrapper = styled.div`
  padding: 32px 24px;
`;
const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 55.6px;
  min-height: 64px;
`;
const NameWrapper = styled.div`
  ${commonStyles.ui_heading_2}
  color: white;
  font-weight: 700;
  div {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
  }
`;
const TagsWrapper = styled.div``;
const YearWrapper = styled.div`
  ${commonStyles.caption}
  margin-top: 18px;
  color: rgba(230, 234, 255, 0.35);
`;
const StyledButtonsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  @media ${pageSize.XS} {
    flex-wrap: wrap;
  }
`;
const StyledDraftText = styled.span`
  ${commonStyles.caption}
  color: #7266EE;
  background-color: white;
  border-radius: 8px;
  padding: 9px;
`;
const Wrapper = styled.div`
  background: #111112;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 16px;
  min-height: 487px;
  height: 100%;
  z-index: 100;
  cursor: pointer;

  &:hover .buttons-wrapper {
    display: flex;
  }

  @media ${pageSize.XS} {
    height: 434px;
    .drag-icon-${props => props.id} {
      display: flex;
    }
  }

  &:hover .edit-settings {
    display: flex;
  }
  @media ${pageSize.XS} {
    .edit-settings {
      display: flex;
    }
  }
  @media ${pageSize.XS}, ${pageSize.S} {
    .buttons-wrapper {
      display: flex;
    }
  }
`;
const ButtonsWrapper = styled.div`
  align-items: center;
  margin-left: 4px;
  display: none;

  .action-btn {
    width: 36px;
    height: 32px;
    background-color: #1a1b1e;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;
const DragIcon = styled.div`
  margin-right: 4px;
  cursor: pointer;
`;

const GalleryCard = ({ hasProfileEditPermission = false, id, items, portfolio, profileId, setCurrentProject }) => {
  const history = useHistory();
  const dragIconRef = useRef();
  const { _id, industryType, keywords, name, production, status, viaThexplace } = portfolio;
  const talentId = useSelector(profileSelectors.selectMyProfileId);
  const developer = production?.developer;
  const publisher = production?.publisher;
  const [hideDeveloper, setHideDeveloper] = useState(false);
  const [hidePublisher, setHidePublisher] = useState(false);

  const lockUnlockStatus = useMemo(
    () =>
      !(
        portfolio?.vetting?.status === 'IN_PROGRESS' &&
        (portfolio?.lockData?.status === 'LOCKED' || portfolio?.lockData?.status === 'UNLOCK_REQUESTED')
      ),
    [portfolio.vetting, portfolio.lockData]
  );

  const isCompany = useSelector(profileSelectors.selectIsCompanyView);
  const { getProfilePath } = useProfilePath();

  const { addProfileUnlockRequest } = useProfileUnlock();

  const year = production?.year;

  const imgUrl = portfolio.coverImage ? portfolio.coverImage.url : NA;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleLockBtnClick = e => {
    e.stopPropagation();
    addProfileUnlockRequest({ entityId: portfolio._id, entityType: 'project', talentId });
  };

  const pushToProject = (validatePublish = false) => {
    const query = validatePublish ? '?validate-publish=true' : '';
    history.push(`${getProfilePath(profileId, isCompany)}/portfolio/${portfolio._id}${query}`);
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Wrapper
        id={portfolio._id}
        onClick={e => {
          if ((dragIconRef.current && !dragIconRef.current.contains(e.target)) || !dragIconRef.current) {
            pushToProject();
          }
        }}
      >
        <ImageWrapper>
          <StyledButtonsContainer>
            {portfolio.status === 'draft' && hasProfileEditPermission && <StyledDraftText>Draft</StyledDraftText>}
            <ButtonsWrapper className="buttons-wrapper">
              {items.length > 1 && hasProfileEditPermission && (
                <DragIcon
                  ref={dragIconRef}
                  {...attributes}
                  {...listeners}
                  className={`drag-icon-${portfolio._id} action-btn`}
                >
                  <Icon icon="drag-i" />
                </DragIcon>
              )}
              {hasProfileEditPermission &&
                portfolio?.vetting?.status === 'IN_PROGRESS' &&
                portfolio?.lockData?.status === 'LOCKED' && (
                  <LockButton mr="6" tooltip="To request edit permissions, click here" onClick={handleLockBtnClick} />
                )}
              {hasProfileEditPermission &&
                portfolio?.vetting?.status === 'IN_PROGRESS' &&
                portfolio?.lockData?.status === 'UNLOCK_REQUESTED' && (
                  <UnlockPendingButton mr="6" tooltip="Edit request pending" />
                )}
              {hasProfileEditPermission &&
                portfolio?.vetting?.status === 'IN_PROGRESS' &&
                portfolio?.lockData?.status === 'UNLOCKED' &&
                portfolio?.lockData?.unlockedDate && <UnlockButton mr="6" tooltip="Unlocked for 24h" />}
              {hasProfileEditPermission && (
                <GalleryCardDropdown
                  project={portfolio}
                  setCurrentProject={setCurrentProject}
                  pushToProject={pushToProject}
                  lockUnlockStatus={lockUnlockStatus}
                />
              )}
            </ButtonsWrapper>
          </StyledButtonsContainer>

          <GalleryImage src={imgUrl} alt="Gallery Image" />
          <CompanyLogos>
            {developer?.company?.image?.url && hideDeveloper && (
              <CompanyLogoContainer>
                <CompanyLogo
                  onError={() => setHideDeveloper(true)}
                  src={developer?.company?.image?.url}
                  alt={developer.company?.name}
                />
              </CompanyLogoContainer>
            )}
            {publisher?.company?.image?.url && hidePublisher && (
              <CompanyLogoContainer>
                <CompanyLogo
                  onError={() => setHidePublisher(true)}
                  src={publisher?.company?.image?.url}
                  alt={publisher.company?.name}
                />
              </CompanyLogoContainer>
            )}
            {viaThexplace && <img src={ViaTheXPlace}></img>}
          </CompanyLogos>
        </ImageWrapper>
        <TextWrapper>
          <TitleWrapper>
            <IndustryIcons
              marginRight={'8px'}
              iconHeight={'32px'}
              iconWidth={'32px'}
              iconMinWidth={'32px'}
              name={industryType?.name}
            />
            <NameWrapper>
              {name}&nbsp;
              {status === 'published' && <VettingStatus id={_id} vetted={portfolio.vetted} hideText />}
            </NameWrapper>
          </TitleWrapper>
          {keywords && (
            <TagsWrapper>
              <Tags tags={keywords} />
            </TagsWrapper>
          )}
          {year && <YearWrapper>{year}</YearWrapper>}
        </TextWrapper>
      </Wrapper>
    </div>
  );
};

export default GalleryCard;
