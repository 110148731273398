import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import FormCheckbox from 'common/form/FormCheckbox';
import FormSelect from 'common/form/FormSelect';
import FormUpload from 'common/form/FormUpload';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useProjectService from 'hooks/api/useProjectService';
import useScrollToMandatoryField from 'hooks/useScrollToMandatoryField';
import { isEmpty, sortBy, values } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPortal } from 'store/portals/portalActions';
import { talentSelectors } from 'store/talent';
import { selectOptionsToArrayOfStrings } from 'utils';
import { getFormSelectTypeArray } from 'utils/tagHelpers';

const StyledForm = styled.form`
  display: grid;
  grid-row-gap: 56px;
`;
const StyledFormUpload = styled(FormUpload)`
  width: auto;
  .upload-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    .hint-text {
      width: 215px;
    }
  }
`;
const StyledSeparator = styled.div`
  border: 2px solid rgba(204, 213, 255, 0.11);
  margin-top: 26px;
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;
const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
const StyledCancelButton = styled(ContainedButton)`
  margin: 0 3px;
`;

const initErrors = {
  coverImage: '',
  keywords: '',
  industryType: '',
  isAccurate: ''
};

const PublishProjectModal = ({
  closePortal,
  data,
  submitting,
  keywords: existingKeywords,
  allTags = [],
  image,
  isAccurate: formIsAccurate,
  onSave,
  saveDraft,
  initialIndustryType,
  name
}) => {
  const dispatch = useDispatch();

  const { getProjectTags, loading: projectLoading } = useProjectService();

  const canPublishProject = useSelector(talentSelectors.canPublishProject);
  const industrytypes = useSelector(state => state.industry.data);

  const videoGames = industrytypes.find(item => item.name === 'Video Games');
  const industryTypeInitialValue = { label: videoGames?.name, value: videoGames?._id };

  const [tags, setTags] = React.useState([]);
  const [coverImage, setCoverImage] = React.useState(image || null);
  const [isAccurate, setIsAccurate] = React.useState(formIsAccurate);
  const [viaThexplace, setViaThexplace] = React.useState(data?.viaTheXplace || false);
  const [keywords, setKeywords] = React.useState([]);
  const [errors, updateErrors] = React.useState(initErrors);
  const [industryType, setIndustryType] = React.useState(
    initialIndustryType
      ? { label: initialIndustryType?.name, value: initialIndustryType?._id }
      : industryTypeInitialValue
  );
  const { handleScroll } = useScrollToMandatoryField();

  const onValidate = status => {
    const errors = {};

    if (status === 'draft') {
      return {};
    }

    if (!coverImage) errors.coverImage = 'Cover image is required';
    if (!keywords) errors.keywords = 'Keywords is required';
    if (keywords?.length < 2 || keywords?.length > 10) errors.keywords = 'Choose 2-10 keywords';
    if (!industryType) errors.industryType = 'industry type required';
    errors.isAccurate = !isAccurate ? 'Required' : null;

    return errors;
  };

  const handleSubmit = (e, additionalParams) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    const errorsObj = onValidate(additionalParams?.status);
    const errorsObjIsEmpty = values(errorsObj).every(isEmpty);
    updateErrors(errorsObj);

    if (!canPublishProject) {
      dispatch(openPortal({ name: 'reached-max-projects-modal' }));
    }

    if (errorsObjIsEmpty) {
      const save = additionalParams?.status === 'draft' ? saveDraft : onSave;

      save({
        forcePublishProject: data.forcePublishProject,
        keywords: selectOptionsToArrayOfStrings(keywords),
        isAccurate,
        viaThexplace,
        file: coverImage,
        industryType: industryType?.value || null,
        ...additionalParams
      });
    } else {
      formIsAccurate && delete errorsObj.isAccurate;
      handleScroll(errorsObj);
    }
  };

  const handleChange = (e, fieldName) => {
    switch (fieldName) {
      case 'isAccurate':
        setIsAccurate(e.target.checked);
        break;
      case 'coverImage':
        setCoverImage(e);
        break;
      case 'keywords':
        setKeywords(e);
        break;
      case 'industryType':
        setIndustryType(e);
        break;
      case 'viaThexplace':
        setViaThexplace(e.target.checked);
        break;
    }

    updateErrors({ ...errors, [fieldName]: '' });
  };

  const handleOptionOpen = async () => {
    if (tags.length === 0 || !data.projectId) {
      const projectTags = data.projectId
        ? await getProjectTags(data.projectId)
        : sortBy(item => item.name.toLowerCase(), allTags);
      setTags(getFormSelectTypeArray({ arr: projectTags }));
    }
  };

  useEffect(() => {
    if (!keywords.length) {
      setKeywords(existingKeywords.map(keyword => ({ value: keyword._id, label: keyword.name })));
    }
  }, [existingKeywords]);

  return (
    <TxplModal
      title="Project settings"
      name={name}
      testTitle
      boldWeight
      padding="56px 0 56px 0"
      addPaddingTop
      XSaddPaddingTop
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {data.projectId && (
            <>
              {submitting && <Spinner />}
              <StyledCancelButton
                onClick={closePortal}
                backgroundColor="rgba(204, 213, 255, 0.11)"
                forceDisplayText
                disabled={submitting}
                mr={6}
              >
                Cancel
              </StyledCancelButton>

              <ContainedButton
                form="publishProjectModal"
                type="submit"
                backgroundColor="#7266EE"
                forceDisplayText
                disabled={submitting}
              >
                Save
              </ContainedButton>
            </>
          )}

          {!data.projectId && (
            <StyledButtonsContainer>
              <div style={{ flex: 1 }}>
                <ContainedButton onClick={closePortal} backgroundColor="secondary">
                  Cancel
                </ContainedButton>
              </div>

              <StyledActionsContainer>
                {submitting && <Spinner />}
                <ContainedButton
                  data-testid={'save-draft-button'}
                  onClick={e => handleSubmit(e, { status: 'draft', published: false })}
                  backgroundColor="secondary"
                  icon="save"
                  disabled={submitting}
                >
                  Save draft
                </ContainedButton>
                <ContainedButton disabled={submitting} onClick={e => handleSubmit(e)} data-testid={'publish-button'}>
                  Publish
                </ContainedButton>
              </StyledActionsContainer>
            </StyledButtonsContainer>
          )}
        </>
      }
    >
      <StyledForm onSubmit={e => handleSubmit(e)} id="publishProjectModal">
        <StyledFormUpload
          name="coverImage"
          error={errors.coverImage}
          required
          value={coverImage?.from ? coverImage.url : coverImage}
          wrapperStyles={{ display: 'block' }}
          preview
          Switchpadding
          boldWeight
          onChange={e => handleChange(e, 'coverImage')}
          label="Project cover image"
          aspectRatio={7 / 4}
          viewMode={3}
          hintText="Should be at least 700x400 pixels. PNG, JPG, GIF, 500Kb or less."
          lightWeight
          onRemoveClick={() => setCoverImage(null)}
          className="coverImage-required"
        />

        <FormSelect
          name="keywords"
          label="Keywords"
          hintText="Keywords help your project to be more discoverable. Choose 2-10 keywords. You can use platforms, tools, genres, skills, and tags you specified for this project."
          required
          autoFocus
          placeholder="e.g. Shooter, 3D art, Xbox1, Comedy"
          value={keywords}
          error={errors.keywords}
          maxOptions={10}
          options={tags}
          isMulti
          boldWeight
          lightWeight
          customNoOptMessage="10 will do here"
          onMenuOpen={handleOptionOpen}
          onChange={e => handleChange(e, 'keywords')}
          isLoading={projectLoading.getProjectTags}
          className="keywords-required"
        />
        <FormSelect
          name="industryType"
          label="Type of industry"
          hintText="Select the type of industry"
          required
          error={errors.industryType}
          value={industryType || industryTypeInitialValue}
          onChange={e => handleChange(e, 'industryType')}
          options={industrytypes.map(item => ({ label: item.name, value: item._id }))}
          className="industryType-required"
        />

        <FormCheckbox
          name="viaThexplace"
          gap={0}
          checked={viaThexplace}
          onChange={e => handleChange(e, 'viaThexplace')}
          text="TheXPlace helped me find this opportunity"
        />

        {!formIsAccurate && (
          <>
            <StyledSeparator />

            <FormCheckbox
              name="isAccurate"
              checked={isAccurate}
              onChange={e => handleChange(e, 'isAccurate')}
              text="By clicking this checkbox I confirm that the descriptions of my contributions to the uploaded assets and work are true and reasonably accurate."
              error={Boolean(errors.isAccurate)}
              className="isAccurate-required"
            />
          </>
        )}
      </StyledForm>
    </TxplModal>
  );
};

export default withRenderPortal('project-publish-modal')(PublishProjectModal);
