import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import get from 'lodash/fp/get';

import { profileSelectors } from 'store/profile';

import NA from 'images/NA.png';
import * as Styled from './StyledConversationParticipant';
import Icon from 'common/Icon';
import useViewport from 'hooks/useViewport';

const ConversationParticipant = ({ selectedConversation, setVisible }) => {
  const history = useHistory();
  const { isS, isXS } = useViewport();
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const myProfile = useSelector(profileSelectors.selectMyProfile);
  const conversations = get('conversations', myProfile) ?? [];
  const conversation =
    typeof selectedConversation === 'string' ? conversations[selectedConversation] : selectedConversation;

  const participants = conversation ? conversation.custom?.participants : null;

  const job = conversation ? conversation.custom?.job : null;
  const sender = participants?.me;
  const recipient = participants?.recipient;
  const contact = sender?.id === profileId ? recipient : sender;
  const profile = typeof selectedConversation === 'string' ? contact : conversation;

  const handleJobTitleClick = () => {
    if (job?.profileType) {
      history.push(`/${job.profileType}/${sender?.id}/jobs/${job?.id}`);
    }
  };

  return (
    <Styled.Container>
      <Styled.Wrapper>
        {(isXS || isS) && (
          <Styled.GoBackIcon
            onClick={() => {
              setVisible(false);
            }}
          >
            <Icon icon="back" color="#fff" />
          </Styled.GoBackIcon>
        )}
        <Styled.AvatarWrapper>
          <Styled.Avatar>
            <img src={profile?.photoUrl || NA} alt="" />
          </Styled.Avatar>
        </Styled.AvatarWrapper>
        <Styled.Details>
          <Styled.Participant>{profile?.name}</Styled.Participant>
          {job && <Styled.JobTitle onClick={handleJobTitleClick}>{job?.title} ↗</Styled.JobTitle>}
        </Styled.Details>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default ConversationParticipant;
