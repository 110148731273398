import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageSize } from 'utils/devices';
import ContainedButton from '../../common/ContainedButton';
import FormInput from '../../common/form/FormInput';
import { ActionButtons, SettingsFooter, StyledContainedButton } from './ProfileSettings/StyledProfileSettings';
import { selectIsMyProfileCompany, selectMyProfileId } from 'store/profile/profileSelectors';
import Loader from 'common/Loader';
import { companyProfileService } from 'api';
import { useErrorSnackbar, useSuccessSnackbar } from 'hooks/useReactSnackbar';
import FormSelect from 'common/form/FormSelect';
import countries from 'utils/countries';
import 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Content = styled.div`
  background: #1a1b1e;
  border-radius: 16px;
  padding: 56px 56px 64px;
  @media ${pageSize.L}, ${pageSize.XL} {
    margin-bottom: 240px;
  }
  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    margin-bottom: 200px;
  }
  @media ${pageSize.XS} {
    border-radius: 0;
    margin-bottom: 160px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    gap: 1.5rem;
    flex-direction: row;
  }
`;

const CompanyBillingInformation = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);

  const validationSchema = Yup.object().shape({
    legalName: Yup.string().label('Legal name').required(),
    phoneNumber: Yup.string(),
    street: Yup.string(),
    zipCode: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    taxId: Yup.string()
  });

  const { values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, handleBlur } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      legalName: company?.billingInformation?.legalName,
      phoneNumber: company?.billingInformation?.phoneNumber,
      street: company?.billingInformation?.address?.street,
      zipCode: company?.billingInformation?.address?.zipCode,
      city: company?.billingInformation?.address?.city,
      state: company?.billingInformation?.address?.state,
      country: company?.billingInformation?.address?.country,
      taxId: company?.billingInformation?.taxId
    },
    onSubmit: async values => {
      const { street, zipCode, city, state, country, ...rest } = values;
      await companyProfileService.updateCompanyBillingInformation(`${company._id}`, {
        ...rest,
        address: { street, zipCode, city, state, country }
      });
      openSuccessSnackbar('Billing information updated successfully');
    }
  });
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { openErrorSnackbar } = useErrorSnackbar();
  const isCompany = useSelector(selectIsMyProfileCompany);
  const myProfileId = useSelector(selectMyProfileId);

  const countriesOptions = useMemo(() => countries.map(({ label }) => ({ label, value: label })), []);

  const handleResetClick = () => {
    setFieldValue('legalName', company?.billingInformation?.legalName);
    setFieldValue('phoneNumber', company?.billingInformation?.phoneNumber);
    setFieldValue('street', company?.billingInformation?.address?.street);
    setFieldValue('zipCode', company?.billingInformation?.address?.zipCode);
    setFieldValue('city', company?.billingInformation?.address?.city);
    setFieldValue('state', company?.billingInformation?.address?.state);
    setFieldValue('country', company?.billingInformation?.address?.country);
    setFieldValue('taxId', company?.billingInformation?.taxId);
  };

  useEffect(() => {
    if (!isCompany) {
      window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}/settings`;
    }
  }, [isCompany]);

  useEffect(() => {
    if (isCompany) {
      setLoading(true);
      companyProfileService
        .getCompany(myProfileId)
        .then(response => {
          setCompany(response.data.data);
          setLoading(false);
        })
        .catch(_error => {
          openErrorSnackbar('Failed to fetch company data');
          setLoading(false);
        });
    }
  }, [myProfileId, isCompany]);

  if (loading) {
    return (
      <Content>
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </Content>
    );
  }

  return (
    <>
      <Content>
        <Col>
          <FormInput
            name="legalName"
            value={values.legalName}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Legal name"
            error={touched.legalName && errors.legalName}
          />
          <FormInput
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            label="Phone number"
            error={errors.phoneNumber}
          />
          <Row>
            <FormInput
              name="street"
              value={values.street}
              onChange={handleChange}
              label="Street name"
              error={errors.street}
            />
            <FormInput
              name="zipCode"
              value={values.zipCode}
              onChange={handleChange}
              label="Zip code"
              error={errors.zipCode}
            />
          </Row>
          <Row>
            <FormInput name="city" value={values.city} onChange={handleChange} label="City" error={errors.city} />
            <FormInput value={values.state} name="state" onChange={handleChange} label="State" error={errors.state} />
          </Row>
          <FormSelect
            key="country-select"
            options={countriesOptions}
            value={values.country ? { label: values.country, value: values.country } : null}
            onChange={value => setFieldValue('country', value ? value.label : null)}
            label="Country"
            isSearchable
            isClearable
          />
          <FormInput
            name="taxId"
            value={values.taxId}
            onChange={handleChange}
            label="VAT number/Tax ID number"
            error={errors.taxId}
          />
        </Col>
      </Content>
      <SettingsFooter>
        <ActionButtons>
          {isSubmitting && (
            <div style={{ marginRight: 4 }}>
              <Loader />
            </div>
          )}
          <ContainedButton
            type="button"
            backgroundColor="rgba(204, 213, 255, 0.11)"
            disabled={isSubmitting}
            onClick={() => handleResetClick()}
          >
            Reset
          </ContainedButton>
          <StyledContainedButton disabled={isSubmitting} type="button" onClick={() => handleSubmit()}>
            Save Changes
          </StyledContainedButton>
        </ActionButtons>
      </SettingsFooter>
    </>
  );
};

export default CompanyBillingInformation;
