import React from 'react';
import { format } from 'date-fns';

export const notificationTabsList = [
  {
    name: 'All',
    count: '12',
    color: '#fff',
    type: 'all'
  },
  {
    name: 'Community',
    count: '6',
    color: '#fff',
    type: 'community'
  },
  {
    name: 'Jobs',
    count: '4',
    color: '#fff',
    type: 'jobs'
  },
  {
    name: 'Profile',
    count: '2',
    color: '#fff',
    type: 'profile'
  }
];

export const notificationTypes = {
  soloistAddedCredit: 'soloist-added-credit',
  soloistAddedProject: 'soloist-added-project',
  collaboratorVetted: 'collaborator-vetted',
  collaboratorUploadedProject: 'collaborator-uploaded-project',
  collaboratorListedOnCredit: 'collaborator-listed-on-credit',
  collaboratorListedOnProject: 'collaborator-listed-on-project',
  invitedInCompany: 'invited-in-company',
  vettingConfirmed: 'vetting-confirmed',
  creditVetted: 'credit-vetted',
  projectVetted: 'project-vetted',
  creditUnlockApproved: 'credit-unlock-approved',
  creditUnlockDenied: 'credit-unlock-denied',
  projectUnlockApproved: 'project-unlock-approved',
  projectUnlockDenied: 'project-unlock-denied',
  profileVetted: 'profile-vetted',
  collaboratorDeclinedCredit: 'collaborator-declined-credit',
  collaboratorDeclinedProject: 'collaborator-declined-project',
  collaboratorNotAnsweredCredit: 'collaborator-not-answered-credit',
  collaboratorNotAnsweredProject: 'collaborator-not-answered-project',
  jobAddedToFavorites: 'job-added-to-favorites',
  jobNewApplicant: 'job-new-applicant',
  jobUserHired: 'job-user-hired',
  jobCompanyMessaged: 'job-company-messaged',
  jobSaveAsDraft: 'job-save-as-draft',
  talentRequestedChanges: 'talent-requested-changes',
  contractSendForApproval: 'contract-send-for-approval',
  contractAccept: 'contract-accept',
};

export const transformNotificationData = ({ message, notificationData, url }) => {
  const keys = Object.keys(notificationData).map(key => `{${key}}`);
  const regex = new RegExp(keys.join('|'), 'gi');
  let transformedTarget = message || url;

  if (url) {
    transformedTarget = url.replace(regex, matched => {
      return notificationData[matched.substring(1, matched.length - 1)];
    });
  } else {
    transformedTarget = message.split(' ').map((item, index) => {
      const matched = item?.match(regex);
      if (matched) {
        const text = matched[0];
        const value = notificationData[text.substring(1, text.length - 1)];
        if (text === '{role}') {
          const article = value === 'admin' ? 'an' : 'a';
          return ` ${article} ${value} `;
        }

        if (text === '{startDate}') {
          return (
            <span key={index} style={{ color: 'white' }}>
              {format(new Date(value), 'MMMM do yyyy')}
            </span>
          );
        }
        return (
          <span key={index} style={{ color: 'white' }}>
            {value}&nbsp;
          </span>
        );
      }
      return `${item} `;
    });
  }
  return transformedTarget;
};
