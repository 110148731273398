import * as Yup from 'yup';

const secondStepValidation = Yup.object().shape({
  avatarImage: Yup.string().required('Avatar image is required'),
  firstName: Yup.string()
    .required('First name is required')
    .min(2, 'First name is too short')
    .max(50, 'First name is too long'),
  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last name is too short')
    .max(50, 'Last name is too long'),
  yourX: Yup.string().max(100, 'Your X is too long'),
  disciplines: Yup.array()
    .min(1, 'At least one discipline is required')
    .required('At least one discipline is required')
    .nullable(),
  roles: Yup.array().min(1, 'At least one role is required').required('At least one role is required').nullable(),
  location: Yup.lazy(value => {
    return typeof value === 'string'
      ? Yup.string().required('Location is required').nullable()
      : Yup.object().required('Location is required').nullable();
  }),
  languages: Yup.array()
    .min(1, 'At least one language is required')
    .required('At least one language is required')
    .nullable(),
  experienceLevel: Yup.object().required('Experience level is required').nullable()
});

const thirdStepValidation = Yup.object().shape({
  tools: Yup.array().min(1, 'At least one tool is required').required('At least one tool is required').nullable(),
  skills: Yup.array().min(1, 'At least one skill is required').required('At least one skill is required').nullable(),
  platforms: Yup.array().nullable(),
  genres: Yup.array()
});

const validation = {
  '02': secondStepValidation,
  '03': secondStepValidation.concat(thirdStepValidation)
};

export default validation;
