import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'common/styles/Flex';
import Switcher from 'common/form/Switcher';
import { StyledTypography } from 'pages/talent/Credits/Credit/CreditStyles';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import { Link } from 'react-router-dom';
import { BiasReducedModeSwitchContainer } from './styled-components';

const BiasReducedModeSwitch = ({ checked, setChecked }) => (
  <BiasReducedModeSwitchContainer>
    <Flex alignCenter gap={8}>
      <Switcher checked={checked} setChecked={setChecked} />
      <StyledTypography
        variant="medium"
        color="rgba(230, 234, 255, 0.6)"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        Bias-Reduced mode
        <ContainedButton
          icon="information"
          iconColor="rgba(230, 234, 255, 0.35)"
          backgroundColor="transparent"
          tooltipId="bias-reduces-mode-tooltip-info"
          padding="0 0 0 4px"
          tooltip={
            <>
              <Typography color="rgba(230, 234, 255, 0.6)">
                Bias-Reduced mode removes <br />
                pictures and names in order <br />
                to focus on candidates skills. <br />
                To change this everywhere, <br />
                go into your&nbsp;
                <Typography
                  fontSize="13px"
                  fontWeight="300"
                  color="#fff"
                  underline
                  variant="caption"
                  transform="lowercase"
                >
                  <Link to="/settings">settings.</Link>
                </Typography>
              </Typography>
            </>
          }
          delayTooltipHide={1000}
          tooltipIsClickable
        />
      </StyledTypography>
    </Flex>
  </BiasReducedModeSwitchContainer>
);

BiasReducedModeSwitch.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func
};

export default BiasReducedModeSwitch;
