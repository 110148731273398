import styled from '@emotion/styled';
import { Tabs } from 'react-tabs';
import { pageSize } from 'utils/devices';

export const TabContainer = styled(Tabs)`
  .react-tabs {
    width: 100%px;
    max-height: 584px;
    min-height: 300px;
    padding: 8px 16px 16px 16px;
    box-sizing: border-box;
    @media ${pageSize.M} {
    }
    @media ${pageSize.XS} {
      max-height: 100%;
    }
  }
  .react-tabs__tab-panel {
    max-height: 540px;
    overflow-y: scroll;
    padding-right: 4px;
    margin-top: 5px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      height: 157px;
      border-radius: 4px;
      background: rgba(204, 213, 255, 0.11);
    }
    @media ${pageSize.XS} {
      max-height: 100%;
    }
  }
  .react-tabs__tab--selected {
    background: transparent;
    color: #fff !important;
    border: none;
    border-bottom: 1px solid #fff;
    ${props => props.deselected && 'color: rgba(230, 234, 255, 0.6) !important; border-bottom: none'}
  }
  .react-tabs__tab-list {
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    margin: 0;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .react-tabs__tab {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: rgba(230, 234, 255, 0.6);
    padding: 0 0 18px;
    padding-right: 24px;
  }
  .react-tabs__tab:not(:last-child) {
    margin-right: 24px;
  }
  .react-tabs__tab:focus {
    box-shadow: none;
  }
  .react-tabs__tab:focus:after {
    background: none;
  }
  .react-tabs {
    ${props => props.large && 'width: 400px;'}
  }
`;
export const TabItem = styled.div`
  display: flex;
  gap: 8px;
`;
export const TabLabel = styled.div`
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 66px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
