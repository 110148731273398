import styled from '@emotion/styled';
import { ReactComponent as IconPurpleGradient } from 'images/assets/icons_16x16/purple-gradient.svg';
import { pageSize } from 'utils/devices';

export const PurpleGradient = styled(IconPurpleGradient)`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: -1;
`;
export const Container = styled.div`
  max-width: 864px;
  min-height: calc(100vh - 80px - 64px - 536px);
  height: fit-content;
  margin: 64px auto 240px auto;
  padding: 0 54px;
  @media ${pageSize.M} {
    padding: 0 58px 0 59px;
    min-height: calc(100vh - 80px - 64px - 496px);
  }
  @media ${pageSize.S} {
    padding: 0 81px;
    margin-bottom: 200px;
    min-height: calc(100vh - 80px - 64px - 456px);
  }
  @media ${pageSize.XS} {
    padding: 0 16px;
    margin-top: 64px;
  }
`;
export const CommunityPageWrapper = styled.div`
  display: flex;
  gap: 54px;
  @media ${pageSize.M} {
    gap: 45px;
  }
  @media ${pageSize.S}, ${pageSize.XS} {
    flex-direction: column;
    gap: 16px;
  }
`;
export const CommunityMainContent = styled.div`
  width: 100%;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  position: absolute;
`;
