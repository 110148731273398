import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import React from 'react';
import capitalize from 'lodash/fp/capitalize';
import isEmpty from 'lodash/fp/isEmpty';
import merge from 'lodash/fp/merge';
import Flex from 'common/styles/Flex';
import withRenderPortal from 'hocs/withRenderPortal';
import options from 'utils/options';
import FilterRadioBoxes from 'components/filters/FilterRadioBoxes';
import FilterCheckBoxes from 'components/filters/FilterCheckBoxes';

const order = ['discipline', 'location', 'tag', 'genre', 'skill', 'timezone', 'tool', 'projects', 'credit'];

const initialState = state => ({
  discipline: state?.discipline ?? [],
  location: state?.location ?? [],
  timezone: state?.timezone ?? [],
  tag: state?.tag ?? [],
  genre: state?.genre ?? [],
  skill: state?.skill ?? [],
  tool: state?.tool ?? [],
  projects: state?.projects ?? {},
  credit: state?.credit ?? {}
});

const radioOptions = [
  {
    label: 'at least 1',
    value: '1'
  },
  {
    label: 'at least 2',
    value: '2'
  },
  {
    label: 'at least 3',
    value: '3'
  },
  {
    label: 'at least 4',
    value: '4'
  },
  {
    label: '5 or more',
    value: '5'
  }
];

const includeLocationOptions = [
  {
    label: 'Exact match',
    value: '0'
  },
  {
    label: 'Up to 10 miles from selected',
    value: '10'
  },
  {
    label: 'Up to 20 miles from selected',
    value: '20'
  },
  {
    label: 'Up to 30 miles from selected',
    value: '30'
  },
  {
    label: 'Up to 40 miles from selected',
    value: '40'
  },
  {
    label: 'Up to 50 miles from selected',
    value: '50'
  }
];

const ApplicantsFilterModal = ({ closePortal, data, name }) => {
  const { clearFilters, filters, initialFilters, setFilters, setShow } = data;

  const [checkedFilters, setCheckedFilters] = React.useState(initialState(initialFilters));
  const [selectedFilters, setSelectedFilters] = React.useState({});

  const handleCheck = (e, label) => {
    const { checked, name: targetName, value } = e.target;
    let items = checkedFilters[targetName];

    if (checked) {
      items.push({ value, label });
    } else {
      items = items.filter(it => it.value !== value);
    }

    let newFilters = {
      [targetName]: items
    };

    if (
      targetName === 'location' &&
      !checked &&
      checkedFilters.location.length === 1 &&
      !isEmpty(checkedFilters.includelocation)
    ) {
      newFilters = Object.assign(newFilters, { includelocation: {} });
    }

    setCheckedFilters({
      ...checkedFilters,
      ...newFilters
    });
  };

  const handleSelect = (values, { name: targetName }) => {
    setSelectedFilters({ ...selectedFilters, [targetName]: values });
  };

  const handleRadio = (e, label) => {
    const { name: targetName, value } = e.target;

    setCheckedFilters({
      ...checkedFilters,
      [targetName]: { value, label }
    });
  };

  const handleReset = () => {
    setCheckedFilters(initialState());
    clearFilters && clearFilters();
  };

  const handleSubmit = () => {
    setFilters(items => ({ ...items, ...merge(checkedFilters, selectedFilters) }));
    setShow(false);
    closePortal();
  };
  return (
    <TxplModal
      title="Filter applicants"
      titleMB="24"
      name={name}
      appElement={document.getElementById('root-modal')}
      padding={'56px 0 82px'}
      renderFooter={
        <>
          <ContainedButton padding="8px 10px" mr={6} backgroundColor="secondary" onClick={handleReset}>
            Reset
          </ContainedButton>
          <ContainedButton padding="8px 10px" onClick={handleSubmit}>
            Show Results
          </ContainedButton>
        </>
      }
    >
      <Flex column gap={56}>
        {!isEmpty(filters) &&
          order.map(key => {
            if (!filters[key]?.length) {
              return null;
            }
            if (key === 'credit' || key === 'projects') {
              return (
                <FilterRadioBoxes
                  key={key}
                  title={key === 'credit' ? 'Matching credits' : 'Matching portfolio projects'}
                  name={key}
                  options={radioOptions.slice(0, filters[key][0])}
                  checked={checkedFilters[key]}
                  onChange={handleRadio}
                />
              );
            } else if (key === 'timezone') {
              return (
                <FilterRadioBoxes
                  key={key}
                  title={capitalize(key)}
                  name={key}
                  options={options(filters[key])}
                  checked={checkedFilters[key]}
                  onChange={handleRadio}
                  disabled={key === 'timezone' && !isEmpty(checkedFilters.location)}
                />
              );
            } else {
              return (
                <React.Fragment key={key}>
                  <FilterCheckBoxes
                    key={key}
                    title={capitalize(key)}
                    name={key}
                    options={options(filters[key])}
                    checked={checkedFilters[key]}
                    onChange={handleCheck}
                    onSelect={handleSelect}
                    disabled={key === 'location' && !isEmpty(checkedFilters.timezone)}
                  />
                  {key === 'location' && !!checkedFilters[key].length && false && (
                    <>
                      <FilterRadioBoxes
                        title="Include locations"
                        name="includelocation"
                        options={includeLocationOptions}
                        checked={checkedFilters.includelocation}
                        onChange={handleRadio}
                      />
                    </>
                  )}
                </React.Fragment>
              );
            }
          })}
      </Flex>
    </TxplModal>
  );
};

export default withRenderPortal('applicant-filter-modal')(ApplicantsFilterModal);
