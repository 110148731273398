import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import appliedJobService from 'api/appliedJobService';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';
import { jobActions } from 'store/jobs';
import { profileActions, profileSelectors } from 'store/profile';
import { portalActions } from 'store/portals';
import useProfileSwitcher from 'hooks/useProfileSwitcher';

const initialLoading = {
  loadingApply: false,
  loadingWithdraw: false
};

const useAppliedJobService = () => {
  const dispatch = useDispatch();
  const { openErrorSnackbar } = useErrorSnackbar();
  const activeProfileId = useSelector(profileSelectors.selectActiveProfileId);
  const myTalent = useSelector(profileSelectors.selectMyTalentProfile);
  const { switchProfile } = useProfileSwitcher();

  const [loading, setLoading] = useState(initialLoading);

  const applyToJob = async (profileId, jobId, onSuccess) => {
    try {
      setLoading({ ...loading, loadingApply: true });
      const {
        data: { data }
      } = await appliedJobService.applyToJob(profileId, jobId);

      if (activeProfileId !== profileId) {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'jobs',
            updateType: 'update',
            value: data,
            profileId: activeProfileId
          })
        );
      }

      dispatch(
        jobActions.deleteJob({
          jobType: 'savedJobs',
          profileId,
          job: data
        })
      );
      dispatch(
        jobActions.updateJob({
          jobType: 'appliedJobs',
          profileId,
          jobs: data
        })
      );

      if (onSuccess) {
        onSuccess(data);
      }
    } catch (err) {
      const error = err?.response?.data?.error;
      if (error === 'CANNOT_APPLY') {
        dispatch(
          portalActions.openPortal({
            name: 'complete-your-profile-to-apply'
          })
        );
      } else if (error === 'ALLREADY_APPLIED') {
        await switchProfile(myTalent, () => ({}), false);
      } else {
        openErrorSnackbar('Failed to apply job');
      }
    } finally {
      setLoading({ ...loading, loadingApply: false });
    }
  };

  const withdrawFromJob = async (profileId, jobId, onSuccess) => {
    if (!loading.loadingWithdraw) {
      try {
        setLoading({ ...loading, loadingWithdraw: true });
        const {
          data: { data }
        } = await appliedJobService.withdrawJob(profileId, jobId);

        if (activeProfileId !== profileId) {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'jobs',
              updateType: 'update',
              value: data,
              profileId: activeProfileId
            })
          );
        }

        dispatch(
          jobActions.deleteJob({
            jobType: 'appliedJobs',
            profileId,
            job: data
          })
        );

        if (onSuccess) {
          onSuccess(data);
        }
      } catch (err) {
        openErrorSnackbar('Failed to remove from applied');
      } finally {
        setLoading({ ...loading, loadingWithdraw: false });
      }
    }
  };

  return {
    applyToJob,
    withdrawFromJob,
    loading
  };
};

export default useAppliedJobService;
