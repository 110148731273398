import Flex from 'common/styles/Flex';
import React from 'react';
import getDisciplinesIconAndColor from 'utils/disciplines';
import TagItem from 'common/Tag/Tag';

function UserDisciplines({ disciplines }) {
  if (!disciplines?.length) {
    return null;
  }

  return (
    <Flex className="wrap" gap={6}>
      {disciplines.map((discipline, key) => {
        const name = discipline.name;
        const { color, icon } = getDisciplinesIconAndColor(name);
        return (
          <TagItem icon={icon} backgroundColor={color} key={key}>
            {name}
          </TagItem>
        );
      })}
    </Flex>
  );
}

export default UserDisciplines;
