import { talentService, uploadsService } from 'api';
import useForm from 'hooks/useForm';
import useProfilePath from 'hooks/useProfilePath';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';
import { isObjectEmpty } from 'utils/isObjectEmpty';

const fields = {
  name: { initialValue: '' },
  roleAndAccomplishments: { initialValue: '' },
  isAccurate: { initialValue: false },
  projectId: { initialValue: '' },
  published: { initialValue: false },
  coverImage: { initialValue: '' },
  keywords: { initialValue: [] },
  collaborators: { initialValue: [] },
  mediaCoverage: { initialValue: [] },
  platforms: { initialValue: [] },
  genres: { initialValue: [] },
  tools: { initialValue: [] },
  skills: { initialValue: [] },
  tags: { initialValue: [] },
  credits: { initialValue: [] },
  industryType: { initialValue: null },
  viaThexplace: { initialValue: false }
};

const loadingInitialValues = {
  name: false,
  roleAndAccomplishments: false,
  collaborators: false,
  credits: false,
  mediaCoverage: false,
  platforms: false,
  tools: false,
  skills: false,
  genres: false,
  tags: false,
  asset: null,
  video: false,
  assetDescription: false
};

const usePortfolio = ({ projectId }) => {
  const [projectVideo, setProjectVideo] = useState();
  const [assets, setAssets] = useState([]);
  const [assetSizeError, setAssetSizeError] = useState(false);
  const [projectVideoSizeError, setProjectVideoSizeError] = useState(false);
  const [assetsOrder, setAssetsOrder] = useState([]);
  const [loading, setLoading] = useState(loadingInitialValues);
  const { openSuccessSnackbar } = useSuccessSnackbar();

  const history = useHistory();
  const profile = useSelector(profileSelectors.selectProfile);
  const isCompany = useSelector(profileSelectors.selectIsCompanyView);
  const projects = useSelector(profileSelectors.selectProfileProperty('projects')) || {};
  const project = useMemo(
    () => projects[projectId] ?? Object.values(projects)?.find(({ _id }) => _id === projectId),
    [projects, projectId]
  );
  const dispatch = useDispatch();
  const isEditMode = true;
  const { getProfilePath } = useProfilePath();

  const onVideoDrop = useCallback(
    async acceptedFiles => {
      setProjectVideoSizeError(false);

      const file = acceptedFiles[0];
      if (file.asset.size / 1024 / 1024 > 100) return setProjectVideoSizeError(true);
      setProjectVideo({ id: shortid.generate(), file: file.asset });

      if (projectId !== 'add') {
        setLoading({ ...loading, video: true });
        const formData = new FormData();
        formData.append('file', file.asset);
        const res = await uploadsService.uploadFile(formData);
        const { data } = res.data;
        await updateProjectProperty(profile._id, 'video', data.id, {});
      }
    },
    [setProjectVideo, projectId]
  );

  const onCollaboratorCustomValidate = formFields => {
    const email = formFields.getIn(['email', 'value']);
    const alreadyExists = values.collaborators.find(item => item.email === email);

    return alreadyExists ? { email: `${email} already exists` } : {};
  };

  const onAssetsDrop = useCallback(
    async acceptedFiles => {
      const isError = acceptedFiles.find(({ asset: file }) => {
        const fileSize = file.size / 1024 / 1024;
        return (file.type.includes('image') && fileSize > 5) || fileSize > 50;
      });

      if (isError) return setAssetSizeError(true);
      setAssetSizeError(false);
      const newFiles = acceptedFiles.map(({ asset: file, overlay }, index) => ({
        id: shortid.generate(),
        file,
        orderIndex: assets.length + index,
        overlay
      }));
      setAssetsOrder(orders => orders.concat(newFiles.map(curr => curr.id)));
      if (projectId === 'add') {
        setAssets(state => [...state, ...newFiles]);
        clearErrors('assets');
      }
      if (projectId !== 'add') {
        setLoading({ ...loading, asset: newFiles[0]?.id });
        const formData = new FormData();
        formData.append('file', newFiles[0]?.file);
        const res = await uploadsService.uploadFile(formData);
        const { data } = res.data;
        const existedAssets = assets.map(asset => {
          return {
            file: asset.file._id,
            orderIndex: asset.orderIndex,
            name: asset?.name,
            description: asset?.description
          };
        });
        const updatedAssets = [...existedAssets, { file: data.id, orderIndex: assetsOrder.length + 1 }];
        await updateProjectProperty(profile._id, 'assets', updatedAssets, {});
      }
    },
    [assets, assets.length]
  );

  const {
    clearErrors,
    dirty,
    errors,
    initialize,
    onFieldChange,
    onFieldDelete,
    onFieldInsert,
    onSubmit,
    submitted,
    submitting,
    triggerErrors,
    values
  } = useForm({
    fields,
    callApi: ({ file, ...rest }) => {
      const formData = new FormData();

      formData.append('file', file || values.coverImage);
      if (projectVideo && projectVideo.file) formData.append('video', projectVideo.file);
      if (assets.length) {
        assets
          .filter(item => Boolean(item.id))
          .map(({ file: asset }) => asset)
          .forEach(asset => formData.append('assets', asset));
      }
      formData.append(
        'publishData',
        JSON.stringify({
          ...rest,
          remainingAssets: assets
            .filter(asset => asset._id)
            .map(asset => ({ ...asset, orderIndex: assetsOrder.findIndex(assetId => assetId === asset._id) })),
          newAssets: assets
            .filter(asset => Boolean(asset.id))
            .map(({ ...restObj }) => ({
              ...restObj,
              orderIndex: assetsOrder.findIndex(assetId => assetId === restObj.id)
            })),
          projectVideo
        })
      );

      return talentService.publishProject(profile._id, formData).then(
        ({
          data: {
            data: { mediaCoverage, project: publishedProject }
          }
        }) => {
          dispatch(
            profileActions.updateProfileProperty({
              property: 'projects',
              value: publishedProject,
              updateType: rest.projectId ? 'update' : 'insert',
              profileId: profile._id
            })
          );
          mediaCoverage.forEach(coverage => {
            dispatch(
              profileActions.updateProfileProperty({
                property: 'mediaCoverage',
                value: coverage,
                updateType: 'insert',
                profileId: profile._id
              })
            );
          });

          return { project: publishedProject, mediaCoverage };
        }
      );
    },
    onSubmitSuccess: data => {
      if (!projectId || projectId === 'add') {
        if (data.project.status === 'draft') {
          openSuccessSnackbar('The draft has been saved');
        }

        history.push(`${getProfilePath(profile.publicUrl, isCompany)}/portfolio`);
      }
      dispatch(portalActions.closePortal({ name: 'project-publish-modal' }));
    }
  });

  useEffect(() => {
    if (project) {
      initialize({ ...project, published: project.status === 'published', projectId: project._id });
      setAssets(project.assets ? project.assets.filter(item => Boolean(item.file)) : []);
      setAssetsOrder(
        flow(
          sortBy(['orderIndex']),
          map(asset => asset._id)
        )(project.assets ? project.assets.filter(item => Boolean(item.file)) : [])
      );
      setProjectVideo(project.video);
    }
  }, [project]);

  const updateProjectProperty = async (talentId, property, value, { onSuccess, portalName, publish = false }) => {
    try {
      const formData = new FormData();
      const updateData = {
        [property]: value
      };

      formData.append(
        publish ? 'publishData' : 'data',
        publish
          ? JSON.stringify({
              ...values,
              projectId: project._id,
              ...updateData,
              remainingAssets: assets
                .filter(asset => asset._id)
                .map(asset => ({ ...asset, orderIndex: assetsOrder.findIndex(assetId => assetId === asset._id) }))
            })
          : JSON.stringify(updateData)
      );

      console.log('talentId', talentId);

      const service = publish
        ? talentService.publishProject(talentId, formData)
        : talentService.updateProject(talentId, projectId, formData);

      setLoading({ ...loading, [property]: true });
      const { data } = await service;
      const updatedProperty = publish ? data.data.project[property] : data.data[property];
      if (property === 'assets') {
        setAssets(updatedProperty);
        setAssetsOrder(
          flow(
            sortBy(['orderIndex']),
            map(asset => asset._id)
          )(updatedProperty)
        );
      } else if (updatedProperty) {
        onFieldChange(property)(updatedProperty);
      }
      dispatch(
        profileActions.updateProfileProperty({
          property: `projects`,
          value: publish ? data.data.project : data.data,
          updateType: 'update',
          updateKey: '_id',
          profileId: talentId
        })
      );
      if (portalName) {
        dispatch(portalActions.closePortal({ name: portalName }));
      }
      if (onSuccess) {
        onSuccess();
      }
      openSuccessSnackbar('The project has been updated');
    } finally {
      if (property === 'assets') {
        setLoading({ ...loading, asset: null });
      } else {
        setLoading({ ...loading, [property]: false });
      }
    }
  };

  const saveAssetDescription = async ({ assetId, description, name }) => {
    const updatedAssets = [
      ...assets.map(asset => ((asset._id || asset.id) === assetId ? { ...asset, name, description } : asset))
    ];

    if (projectId !== 'add') {
      setLoading({ ...loading, assetDescription: true });
      await updateProjectProperty(profile._id, 'assets', updatedAssets, { portalName: 'asset-description-modal' });
    }
    setAssets(updatedAssets);
  };

  const reorderAssets = async ({ newIndex, oldIndex }) => {
    const removedAssetId = assetsOrder[oldIndex];
    const newArray = Object.assign([], assetsOrder);

    newArray.splice(oldIndex, 1);
    newArray.splice(newIndex, 0, removedAssetId);
    setAssetsOrder(newArray);

    if (projectId !== 'add') {
      const updatedAssets = newArray.map((id, index) => {
        const asset = assets.find(item => item.id || item._id === id);

        return {
          _id: asset._id,
          file: asset.file._id,
          orderIndex: index,
          description: asset.description,
          name: asset.name
        };
      });

      await updateProjectProperty(profile._id, 'assets', updatedAssets, {});
    }
  };

  const onMediaCoverageAdd = async mediaCoverage => {
    const changeHandler = projectId === 'add' ? onFieldInsert('mediaCoverage') : null;
    const { data } = await talentService.createMediaCoverage(profile._id, mediaCoverage);
    dispatch(
      profileActions.updateProfileProperty({
        property: 'mediaCoverage',
        value: data.data,
        updateType: 'insert',
        profileId: profile._id
      })
    );

    if (projectId !== 'add') {
      await updateProjectProperty(profile._id, 'mediaCoverage', [data.data._id], {});
    }
    if (changeHandler) return changeHandler(data.data._id);
  };

  const onMediaCoverageDelete = ({ localMediaId, mediaId }) => {
    const changeHandler = onFieldDelete('mediaCoverage');
    const value = mediaId || localMediaId;
    const key = mediaId ? '_id' : 'localId';

    changeHandler(value, key);
  };

  const onPublishDataSave = ({
    file,
    forcePublishProject,
    industryType,
    isAccurate,
    keywords,
    viaThexplace,
    published = true,
    status
  }) => {
    if (projectId && projectId !== 'add' && !forcePublishProject) {
      onFieldChange('coverImage')(file);
      onFieldChange('isAccurate')(isAccurate);
      onFieldChange('keywords')(keywords);
      onFieldChange('viaThexplace')(viaThexplace);
      onSubmit(null, { viaThexplace, keywords, file, isAccurate, industryType });
    } else {
      onSubmit(null, { viaThexplace, keywords, file, isAccurate, status, published, industryType });
    }
  };

  const onDraftSave = options => {
    if (isObjectEmpty(options)) {
      return onSubmit(null);
    }
    onSubmit(null, { ...options, published: false });
  };

  const onPortfolioSave = () => {
    onSubmit(null);
  };

  const handleCancel = () => history.push(`${getProfilePath(profile.publicUrl, isCompany)}/portfolio`);

  const onAssetRemove = (_file, id) => () => {
    if (projectId !== 'add') {
      const updatedAssets = assets.filter(file => (file.id || file._id) !== id);
      dispatch(
        portalActions.openPortal({
          name: 'confirm-modal',
          data: {
            onConfirm: () => {
              if (projectId !== 'add') {
                updateProjectProperty(profile._id, 'assets', updatedAssets, {});
              }
            },
            title: 'Delete project asset?',
            description: 'Are you sure you want to delete this project asset? This cannot be undone.'
          }
        })
      );
    } else {
      setAssets(state => state.filter(file => (file.id || file._id) !== id));
      setAssetsOrder(state => state.filter(assetId => assetId !== id));
    }
  };

  const onVideoRemove = () => () => {
    dispatch(
      portalActions.openPortal({
        name: 'confirm-modal',
        data: {
          onConfirm: () => {
            if (projectId !== 'add') {
              setLoading(true);
              updateProjectProperty(profile._id, 'video', null, {
                portalName: 'confir-modal',
                onSuccess: () => setProjectVideo()
              });
            } else {
              setProjectVideo();
            }
          },
          title: 'Delete project video?',
          description: 'Are you sure you want to delete the project video? This cannot be undone.',
          notClosePortal: true,
          loading: loading.video
        }
      })
    );
  };

  const handleOpenVideoInstruction = e => {
    e.stopPropagation();
    dispatch(portalActions.openPortal({ name: 'project-video-instruction-modal' }));
  };

  const onCreditAdd = async credit => {
    const changeHandler = onFieldInsert('credits');
    const { data } = await talentService.createCredit(profile._id, credit);
    if (projectId !== 'add') {
      dispatch(
        profileActions.updateProfileProperty({
          property: 'credits',
          updateType: 'insert',
          value: data.data,
          profileId: profile._id
        })
      );
      return updateProjectProperty(profile._id, 'credits', [...values.credits, data.data._id], {
        portalName: 'add-credit'
      });
    }
    return Promise.resolve(changeHandler({ ...credit, localId: Date.now() }));
  };

  const onCreditDelete = ({ creditId, localCreditId }) => {
    const changeHandler = onFieldDelete('credits');
    const value = creditId || localCreditId;
    const key = creditId ? '_id' : 'localId';

    changeHandler(value, key);
  };

  return {
    projectVideo,
    assets,
    assetsOrder,
    onVideoDrop,
    onAssetsDrop,
    reorderAssets,
    values,
    errors,
    submitted,
    submitting,
    dirty,
    onMediaCoverageAdd,
    onMediaCoverageDelete,
    onPublishDataSave,
    onDraftSave,
    handleCancel,
    onAssetRemove,
    onVideoRemove,
    isEditMode,
    onFieldChange,
    onFieldDelete,
    onFieldInsert,
    project,
    saveAssetDescription,
    onPortfolioSave,
    handleOpenVideoInstruction,
    assetSizeError,
    triggerErrors,
    onCollaboratorCustomValidate,
    onCreditAdd,
    onCreditDelete,
    projectVideoSizeError,
    updateProjectProperty,
    loading
  };
};

export default usePortfolio;
