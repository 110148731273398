import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import Spinner from 'common/Spinner';
import { userService } from 'api';

const TeamUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [teamUsers, setTeamUsers] = useState([]);
  const [teamId, setTeamId] = useState();

  const adminTeams = ['111', '222', '123', '1234'];
  const teamOptions = adminTeams.map(team => ({ value: team, label: `Team - ${team}` }));

  const handleTeamChanged = e => {
    setTeamId(e.value);
  };

  const handleUpdateUser = () => {};

  const customStyle = {
    control: provided => ({
      ...provided,
      color: 'white',
      backgroundColor: 'black'
    }),
    singleValue: provided => ({
      ...provided,
      color: 'white',
      backgroundColor: 'black'
    }),
    option: provided => ({
      ...provided,
      color: 'white',
      backgroundColor: 'black'
    })
  };

  useEffect(() => {
    const loadTeamUsers = () => {
      setIsLoading(true);
      userService
        .getTeamUsers(teamId)
        .then(response => {
          setTeamUsers(response.data);
        })
        .catch(error => {
          console.log('error: ', error);
          setTeamUsers([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (teamId) {
      loadTeamUsers();
    }
  }, [teamId]);

  return (
    <>
      <h1>Team Users</h1>
      <Select options={teamOptions} onChange={handleTeamChanged} styles={customStyle} />
      {isLoading && <Spinner centered />}
      {teamUsers.length === 0 && <div>No Users to display...</div>}
      {teamUsers.length > 0 && !isLoading && (
        <table>
          <thead>
            <tr>
              <td>#</td>
              <td>First Name</td>
              <td>Last Name</td>
              <td>Email</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {teamUsers &&
              teamUsers.map((user, index) => (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>
                    <button onClick={() => handleUpdateUser(user._id)}>Update</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TeamUsers;
