import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(189, 200, 255, 0.05);
  border-radius: 9999px;
  width: 48px;
  height: 48px;
`;
