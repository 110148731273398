import React from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import commonStyles from 'utils/common-styles';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import { userService } from 'api';

import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal';
import FormInput from 'common/form/FormInput';
import FormTextArea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import Icon from 'common/Icon';

const StyledSendButton = styled(ContainedButton)`
  margin-left: 8px;
`;

const StyledParagraph = styled.p`
  ${commonStyles.ui_text_medium}
  color: ${props => (props.error ? '#FF5151' : '#00FF00')};
  margin: 0px;
`;

const StyledStatusContainer = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-template-columns: 20px auto;
`;

const fields = {
  fullName: { initialValue: '', validate: ['isRequired'] },
  email: { initialValue: '', validate: ['isRequired', 'isEmail'] },
  message: { initialValue: '', validate: ['isRequired'] }
};

const FeedbackModal = ({ closeModal, name }) => {
  const location = useLocation();
  const { apiResponse, errors, initialize, onFieldChange, onSubmit, submitted, submitting, values } = useForm({
    fields,
    callApi: params => userService.contactSupport(params)
  });

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const formValues = {};

    if (params.get('reason')) formValues.message = params.get('reason');
    if (params.get('email')) formValues.email = params.get('email');
    if (params.get('fullName')) formValues.fullName = params.get('fullName');

    initialize(formValues);
  }, [initialize, location.search]);

  return (
    <TxplModal
      name={name}
      title="Contact support"
      renderFooter={
        <>
          {submitting && <Spinner />}

          <ContainedButton onClick={closeModal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText>
            {submitted ? 'Close' : 'Cancel'}
          </ContainedButton>

          {!submitted && (
            <StyledSendButton disabled={submitting} onClick={onSubmit} backgroundColor="#7266EE" forceDisplayText>
              Send
            </StyledSendButton>
          )}
        </>
      }
      appElement={document.getElementById('root-modal')}
      size={submitted ? 'small' : 'medium'}
    >
      {submitted ? (
        <StyledStatusContainer>
          <Icon color={errors.message ? '#FF5151' : '#00FF00'} icon={errors.message ? 'close' : 'checkmark'} />

          {errors.message ? (
            <StyledParagraph error>{errors.message}</StyledParagraph>
          ) : (
            <StyledParagraph>{apiResponse.data.message}</StyledParagraph>
          )}
        </StyledStatusContainer>
      ) : (
        <form onSubmit={onSubmit}>
          <FormInput
            label="Your full name"
            required
            value={values.fullName}
            onChange={onFieldChange('fullName')}
            error={errors.fullName}
          />

          <FormInput
            label="Your email address"
            required
            value={values.email}
            onChange={onFieldChange('email')}
            error={errors.email}
          />

          <FormTextArea
            label="Your message"
            required
            hintText="Please explain your issue and we will connect with you shortly to fix it"
            value={values.message}
            onChange={onFieldChange('message')}
            error={errors.message}
          />
        </form>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('feedback-modal')(FeedbackModal);
