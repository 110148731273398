import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const ScrollToMe = ({ offsetY = 0 }) => {
  const history = useHistory();
  const refElement = useRef();

  useEffect(() => {
    const elm = refElement.current;
    if (elm) {
      const { offsetTop } = elm;
      const { scrollY } = window;
      if (scrollY > offsetTop - offsetY) {
        window.scrollTo(0, offsetTop - offsetY);
      }
    }
  }, [history.location, offsetY]);

  return <div ref={refElement} />;
};

export default ScrollToMe;
