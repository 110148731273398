import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import useCollaborationsService from 'hooks/useCollaboration';
import Collaborations from 'common/collaborations/Collaborations';
import Footer from 'pages/Footer';
import useProfiles from 'hooks/useProfiles';
import DeclineCollaboration from './DeclineCollaboration';
import * as Styled from './StyledCommunityLayout';
import IDVerificationVideoModal from '../../components/modals/IDVerificationVideoModal/IDVerificationVideoModal';
import Typography from 'components/shared/Typography';

const CommunityLayout = () => {
  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);

  const {
    collaborations: collaborationsList,
    fetchCollaborations,
    hasMore,
    hasVetted,
    loadMore,
    loading,
    totalCollabs
  } = useCollaborationsService();
  useProfiles();

  return (
    <>
      <Styled.PurpleGradient />

      <Styled.Container>
        <Styled.CommunityPageWrapper>
          <IDVerificationVideoModal />
          <Styled.CommunityMainContent>
            <div style={{ padding: '0 0 40px 0', textTransform: 'none' }}>
              <Typography variant="h2" transform="none">
                Collaborators
              </Typography>
            </div>
            <Collaborations
              collaborationsList={!isCompanyView ? collaborationsList : []}
              hasMore={hasMore}
              hasVetted={hasVetted}
              loadMore={loadMore}
              totalCollabs={totalCollabs}
              loading={loading}
              fetchCollaborations={fetchCollaborations}
            />
          </Styled.CommunityMainContent>
        </Styled.CommunityPageWrapper>
      </Styled.Container>

      <DeclineCollaboration />
      <Styled.FooterWrapper>
        <Footer />
      </Styled.FooterWrapper>
    </>
  );
};

export default CommunityLayout;
