import isEmpty from 'lodash/fp/isEmpty';
import merge from 'lodash/fp/merge';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import decodeQuery from 'utils/decodeQuery';
import filtersQueryString from 'utils/filtersQueryString';
import isEmptyFilters from 'utils/isEmptyFilters';

function useSearch({ apiCall, initialFilters }) {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [filters, setFilters] = React.useState(initialFilters);
  const [loading, setIsLoading] = React.useState(true);
  const [update, setUpdate] = React.useState(false);

  const clearFilters = () => {
    setFilters({ ...initialFilters });
    setUpdate(!isEmptyFilters(filters));
    history.push({
      pathname
    });
  };

  const updateFilters = filters => {
    setFilters(filters);
    setUpdate(isEmptyFilters(filters));
  };

  React.useEffect(() => {
    if (!isEmpty(search)) {
      setFilters(filters => merge(filters, decodeQuery(search)));
    } else {
      setIsLoading(false);
      clearFilters();
    }
  }, [search]);

  React.useEffect(() => {
    if (!isEmptyFilters(filters) || update) {
      setIsLoading(true);
      const query = filtersQueryString(filters);

      apiCall &&
        apiCall(query, () => {
          setIsLoading(false);
          setUpdate(false);
        });
      history.push({
        pathname,
        search: query
      });
    }
  }, [filters]);

  return {
    loadingSearch: loading,
    filters,
    setFilters: updateFilters,
    clearFilters
  };
}

export default useSearch;
