import React from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';

import Frame from 'common/Frame';
import { profileSelectors } from 'store/profile';
import Typography from 'components/shared/Typography';
import GameNotFoundTooltip from 'common/GameNotFoundTooltip';
import useProfileUnlock from 'hooks/api/useProfileUnlockService';

import Icon from 'common/Icon';
import useViewport from 'hooks/useViewport';
import styled from '@emotion/styled';
import Tooltip from 'components/Tooltip/Tooltip';
import CollaboratorsBadge from './CollaboratorsBadge';
import CreditDropdown from 'pages/talent/Credits/Credit/CreditDropdown';
import LockButton from 'common/lockUnlockButtons/LockButton';
import UnlockPendingButton from 'common/lockUnlockButtons/UnlockPendingButton';
import { compose, either, equals, filter, prop, propSatisfies, when } from 'ramda';
import {
  CreditHeader,
  ReStyledTypography,
  StyledCollaboratorsContainer,
  StyledCollaboratorsView,
  StyledContainer,
  StyledCredit,
  StyledCreditBody,
  StyledCreditInfoContainer,
  StyledCreditTitleContainer,
  StyledCreditTitleContainerShow,
  StyledGameCardImage,
  StyledTypography,
  StyledTypographyText,
  TypographyWrapperTile
} from './CreditStyles';
import VettingStatus from '../VettingStatus';
import { pageSize } from '../../../../utils/devices';
import { buildCoverUrl } from 'utils';

const Credit = ({ credit, height, hideDropDown, id, isEditMode, mediumView, project }) => {
  const { isXS, width } = useViewport();
  const talentId = useSelector(profileSelectors.selectMyProfileId);

  const { _id, game, role, vetted } = credit;
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const isApproved = either(equals('approved'), equals('approved_unverified'));

  const filterApproved = filter(propSatisfies(isApproved, 'status'));

  const collaborators = compose(
    when(() => !hasPermission, filterApproved),
    prop('collaborators')
  )(credit);

  const gameImage = buildCoverUrl(game?.cover ?? game?.image?.url);
  const unknownImage = gameImage?.length === 0;
  const gameName = game ? game.title : '';
  const gameYear = game ? game.year : '';

  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);

  const { addProfileUnlockRequest } = useProfileUnlock();

  const showIcon = hideDropDown && isEditMode;

  const showDropDown = !hideDropDown && isEditMode;

  let cropLength = 50;
  let sCropLength = 50;
  if (width < 760) {
    cropLength = 140;
  }
  if (width < 715) {
    cropLength = 110;
  }
  if (width < 603) {
    cropLength = 80;
  }
  if (width < 503) {
    cropLength = 60;
  }
  if (width < 479) {
    cropLength = 40;
  }
  if (width < 375) {
    cropLength = 20;
  }
  if (width < 1349) {
    sCropLength = 80;
  }
  if (width < 1239) {
    sCropLength = 60;
  }
  if (width < 1150) {
    sCropLength = 110;
  }
  if (width < 1057) {
    sCropLength = 80;
  }
  if (width < 919) {
    sCropLength = 60;
  }
  if (width < 831) {
    sCropLength = 40;
  }
  const StyledTooltip = styled(Tooltip)`
    .__react_component_tooltip.show {
      border: 1px solid rgba(204, 213, 255, 0.22) !important;
    }
  `;
  const CreditsStyledContainer = styled(StyledContainer)`
    gap: 0;
  `;
  const StyledFrame = styled(Frame)`
    padding: 16px;
    overflow: visible;
  `;
  const GameCardImage = styled.div`
    background: url(${props => props.gameImage}) center center no-repeat;
    background-size: cover;
    width: 134px;
    height: 192px;
    border-radius: 8px;
    @media ${pageSize.XS} {
      width: 63px;
      height: 90px;
      margin-bottom: 16px;
    }
  `;
  const CartTypography = styled(Typography)`
    overflow: hidden;
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
    .vetting-icon {
      display: inline-flex;
    }
    width: 75%;
  `;
  const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ mb }) => (mb >= 0 ? `${mb}px` : '10px')};
  `;

  const StyledLockButton = styled.div`
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    ${props => props.homepage && 'top: 20px;'};
    ${props => props.homepage && 'right: 20px;'};
    @media ${pageSize.XS} {
      ${props => props.homepage && 'right: 18px;'};
    }
  `;

  const DropdownWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
  `;

  const handleLockBtnClick = () => {
    addProfileUnlockRequest({ entityId: credit._id, entityType: 'credit', talentId });
  };

  const renderDropdown = () => {
    return hasPermission && credit?.vetting?.status === 'IN_PROGRESS' && credit?.lockData?.status === 'LOCKED' ? (
      <StyledLockButton homepage>
        <LockButton onClick={handleLockBtnClick} tooltip="To request edit permissions, click here" />
      </StyledLockButton>
    ) : credit?.vetting?.status === 'IN_PROGRESS' && credit?.lockData?.status === 'UNLOCK_REQUESTED' ? (
      <StyledLockButton homepage>
        <UnlockPendingButton tooltip="Edit request pending" />
      </StyledLockButton>
    ) : (
      showDropDown && (
        <DropdownWrapper data-testid="credit-dropdown-options">
          <CreditDropdown credit={credit} hasPermission={hasPermission} />
        </DropdownWrapper>
      )
    );
  };

  const lockStatus =
    credit?.lockData?.status === 'UNLOCK_REQUESTED' || credit?.lockData?.status === 'LOCKED' ? credit : project;
  return (
    <StyledFrame
      id={id}
      project={project ? lockStatus : credit}
      homepage
      canEdit={hasPermission}
      opens="edit-credit"
      data={{ credit }}
      height={height}
      filled
      onEdit={credit.vetting?.status === 'IN_PROGRESS' && credit.lockData?.status === 'LOCKED' && handleLockBtnClick}
      noIcon={!showIcon}
    >
      {!isCompanyView && renderDropdown()}
      <CreditsStyledContainer>
        <CreditHeader>
          {gameImage && !unknownImage && <GameCardImage gameImage={gameImage} />}
          {(!gameImage || unknownImage) && (
            <StyledGameCardImage>
              <GameNotFoundTooltip />
              <Icon size={isXS ? 45 : 64} icon="blank-image" />
            </StyledGameCardImage>
          )}
          <StyledCreditTitleContainerShow>
            {gameName?.length > cropLength ? (
              <CartTypography variant="h3">
                {`${gameName.substr(0, cropLength)}...`}{' '}
                <VettingStatus className="vetting-icon" vetted={vetted} hideText />
              </CartTypography>
            ) : (
              <CartTypography variant="h3">
                {gameName} <VettingStatus className="vetting-icon" vetted={vetted} hideText />
              </CartTypography>
            )}
          </StyledCreditTitleContainerShow>
        </CreditHeader>
        <StyledCredit>
          <StyledCreditTitleContainer>
            {gameName?.length > sCropLength ? (
              <CartTypography variant="h3">
                {`${gameName.substr(0, sCropLength)}...`}{' '}
                <VettingStatus className="vetting-icon" vetted={vetted} hideText />
              </CartTypography>
            ) : (
              <CartTypography variant="h3">
                {gameName} <VettingStatus className="vetting-icon" vetted={vetted} hideText />
              </CartTypography>
            )}
          </StyledCreditTitleContainer>
          <StyledCreditBody mediumView={mediumView}>
            <StyledCreditInfoContainer>
              <TypographyWrapperTile>
                <TextWrapper>
                  <ReStyledTypography variant={'caption'}>ROLE</ReStyledTypography>
                  {role?.name?.length > 13 ? (
                    <StyledTypographyText
                      variant={'caption'}
                      data-tip
                      data-for={`yourName-${_id}`}
                    >{`${role?.name.substr(0, 13)}...`}</StyledTypographyText>
                  ) : (
                    <StyledTypographyText variant={'caption'}>{role?.name}</StyledTypographyText>
                  )}
                </TextWrapper>
                <TextWrapper>
                  <ReStyledTypography variant={'caption'}>DEVELOPER</ReStyledTypography>
                  {game?.developed_by?.length > 13 ? (
                    <StyledTypographyText
                      variant={'caption'}
                      data-tip
                      data-for={`yourGame-${_id}`}
                    >{`${game?.developed_by.substr(0, 13)}...`}</StyledTypographyText>
                  ) : (
                    <StyledTypographyText variant={'caption'}>{game?.developed_by}</StyledTypographyText>
                  )}
                </TextWrapper>
                <TextWrapper>
                  <ReStyledTypography variant={'caption'}>PUBLISHER</ReStyledTypography>
                  {game?.published_by?.length > 13 ? (
                    <StyledTypographyText
                      variant={'caption'}
                      data-tip
                      data-for={`yourPublisher-${_id}`}
                    >{`${game?.published_by.substr(0, 13)}...`}</StyledTypographyText>
                  ) : (
                    <StyledTypographyText variant={'caption'}>{game?.published_by}</StyledTypographyText>
                  )}
                </TextWrapper>
                <TextWrapper>
                  <ReStyledTypography variant={'caption'}>STATUS</ReStyledTypography>
                  <StyledTypographyText variant={'caption'} data-tip data-for={`yourYear-${_id}`}>
                    {moment(game?.year).year() <= moment().year() && 'Released'}
                  </StyledTypographyText>
                </TextWrapper>
                <TextWrapper mb={0}>
                  <ReStyledTypography>YEAR</ReStyledTypography>
                  <StyledTypographyText variant={'caption'} data-tip data-for={`yourGameYear-${_id}`}>
                    {moment(gameYear).year()}
                  </StyledTypographyText>
                </TextWrapper>
              </TypographyWrapperTile>
            </StyledCreditInfoContainer>
            <StyledCollaboratorsContainer mediumView={mediumView}>
              <StyledCollaboratorsView>
                <StyledTypography ml={mediumView ? 0 : 16}>COLLABORATORS</StyledTypography>
                <CollaboratorsBadge mediumView={mediumView} collaborators={collaborators} gameName={gameName} />
              </StyledCollaboratorsView>
            </StyledCollaboratorsContainer>
          </StyledCreditBody>
        </StyledCredit>
        <StyledTooltip tooltipId={`yourName-${_id}`} place="right">
          {role?.name}
        </StyledTooltip>
        <StyledTooltip tooltipId={`yourGame-${_id}`} place="right">
          {game?.developed_by}
        </StyledTooltip>
        <StyledTooltip tooltipId={`yourPublisher-${_id}`} place="right">
          {game?.published_by}
        </StyledTooltip>
      </CreditsStyledContainer>
    </StyledFrame>
  );
};

export default Credit;
