import ChatApi from './chatApi';

const chatApi = new ChatApi('files');

const uploadFile = formData =>
  chatApi.post('', formData, { headers: { Accept: '*/*', 'Content-Type': 'multipart/form-data' } });

export default {
  uploadFile
};
