import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { authService } from 'api';
import { portalActions } from 'store/portals';
import { pageSize } from 'utils/devices';
import logoIcon from 'images/page-header-thexplace-logo.svg';
import { authActions } from 'store/auth';
import AcceptInvitationModal from 'components/modals/AcceptInvitationModal';
import OauthModal from './OauthModal';

const StyledContainer = styled.div`
  margin: auto;
  margin-top: 30px;
  @media ${pageSize.XS} {
    content: 'XS';
    width: auto;
    margin-top: 80px;
    margin-left: 16px;
    margin-right: 16px;
  }
  @media ${pageSize.S} {
    content: 'S';
    width: 560px;
  }
  @media ${pageSize.M} {
    content: 'M';
    width: 585px;
  }
  @media ${pageSize.L} {
    content: 'L';
    width: 700px;
    margin-top: 50px;
  }
`;
const StyledBackgroundImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #6655cc 0%, rgba(74, 41, 204, 0) 100%);
  @media ${pageSize.XS} {
    width: 389px;
    height: 389px;
    top: -198px;
  }
  @media ${pageSize.S} {
    width: 971px;
    height: 971px;
    top: -650px;
  }
  @media ${pageSize.M} {
    width: 1341px;
    height: 1341px;
    top: -1023px;
  }
  @media ${pageSize.L} {
    width: 1572px;
    height: 1572px;
    top: -1200px;
  }
`;
const StyledImage = styled.img`
  margin: auto;
  display: block;
`;

const Oauth = () => {
  const [error] = useState();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { type } = useParams();

  const { code, state } = useMemo(() => {
    const params = new URLSearchParams(location.search);

    const code = params.get('code');
    const state = params.get('state');

    return { code, state };
  }, [location.search]);

  const loginUser = useMemo(() => {
    if (type === 'google') return authService.authWithGoogle;
    if (type === 'linkedin') return authService.authWithLinkedIn;
    if (type === 'fb') return authService.authWithFB;
  }, [type]);

  useEffect(() => {
    loginUser({ code, state, type })
      .then(({ data }) => {
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('role', data.user.role);

        dispatch(authActions.login(data.user));
        history.push('/profile');
      })
      .catch(err => {
        if (err.response.data.error === 'AGREED_IS_REQUIRED') {
          dispatch(
            portalActions.openPortal({
              name: 'oauth-modal',
              data: { code, state, type, tokens: err.response.data.tokens }
            })
          );
        } else {
          history.push('/?login=true');
        }
      });
  }, [dispatch, location.search]);

  return (
    <>
      <OauthModal />
      <StyledBackgroundImage />

      <StyledContainer>
        <StyledImage src={logoIcon} />
      </StyledContainer>

      <AcceptInvitationModal />
      <div>
        {error && (
          <div>
            {error}
            <Link to="/?login=true">Back</Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Oauth;
