import React from 'react';
import styled from '@emotion/styled';

import { userService } from 'api';
import { getAssetIcon } from 'utils/assets';
import { ReactComponent as IconPurpleGradient } from 'images/assets/icons_16x16/purple-gradient.svg';
import ChatHeadImg from 'images/portfolio-tip.png';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 100px;
  margin-top: 100px;
`;

const StyledVerificationContainer = styled.div`
  background: #1a1b1e;
  border-radius: 16px;
  padding: 50px 40px;
  max-width: 800px;
`;

const Spacer = styled.div`
  margin: 34px 0;
  height: 0px;
  border: 1px solid rgba(204, 213, 255, 0.11);
`;

const StyledVerificationSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledVerificationGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const StyledVerificationStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledVerificationStatusCircle = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: ${props => props.background};
  margin: 0 5px;
`;

const StyledContactSupportContainer = styled(Typography)`
  display: flex;
  flex-direction: row;
`;

const StyledVerifyButton = styled(ContainedButton)`
  width: fit-content;
`;

const StyledVerificationIcon = styled.img`
  max-width: 224px;
  width: 100%;
`;

const StyledPurpleGradient = styled(IconPurpleGradient)`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: -1;
`;

const StyledSupportContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const StyledSupportImage = styled.img`
  height: 49px;
  width: 49px;
  border-radius: 100%;
  background-color: gray;
`;

const StyledSupportText = styled(Typography)`
  background: #ffffff;
  border-radius: 16px 16px 16px 0px;
  max-height: 190px;
  max-width: 320px;
  padding: 16px;
`;

const statusDisplayEnum = {
  verified: {
    background: '#00FF00',
    text: 'Verified',
    assetIcon: 'VERIFICATION_VERIFIED'
  },
  processing: {
    background: '#FFE040',
    assetIcon: 'VERIFICATION_PROGRESS',
    text: 'In Progress'
  },
  requires_input: {
    background: '#FFE040',
    assetIcon: 'VERIFICATION_PROGRESS',
    text: 'Please Try Again'
  },
  failed: {
    background: '#FF9C40',
    assetIcon: 'VERIFICATION_PROGRESS',
    text: 'Unverified'
  },
  'not-started': {
    background: '#FFFFFF',
    assetIcon: 'VERIFICATION_NOT_STARTED',
    text: 'Not Started'
  }
};

const VerifyAccount = () => {
  const [verification, setVerification] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const status = React.useMemo(() => verification?.status || 'not-started', [verification?.status]);
  const statusOption = React.useMemo(() => statusDisplayEnum[status], [status]);

  const handleVerifyCheck = React.useCallback(
    ({ data }) => {
      setVerification(data);
      if (data.status === 'processing')
        setTimeout(() => {
          userService.currentVerification().then(({ data }) => handleVerifyCheck(data));
        }, 3000);
    },
    [setVerification, setLoading]
  );

  React.useEffect(() => {
    setLoading(true);
    userService
      .currentVerification()
      .then(({ data }) => handleVerifyCheck(data))
      .finally(() => setLoading(false));
  }, [handleVerifyCheck]);

  const handleVerificationClick = async () => {
    const { data } = await userService.requestVerification();
    window.location = data.data.url;
  };

  const icon = React.useMemo(() => getAssetIcon(statusOption.assetIcon), [statusOption.assetIcon]);
  return (
    <StyledContainer>
      <StyledPurpleGradient />
      <StyledVerificationContainer>
        <Typography variant="h2" mb={24}>
          ID Verification
        </Typography>
        <Typography variant="medium">
          Please verify your identity. Your data will be securely processed by Stripe.
        </Typography>
        <Spacer />
        {loading && <Spinner />}
        {!loading && (
          <StyledVerificationSubContainer>
            <StyledVerificationGrid>
              <StyledVerificationStatusContainer>
                <Typography variant="medium">Verification Status:</Typography>
                <StyledVerificationStatusCircle background={statusOption.background} />
                <Typography variant="medium">{statusOption.text}</Typography>
              </StyledVerificationStatusContainer>
              <StyledContactSupportContainer>
                <Typography variant="small" color="rgba(230, 234, 255, 0.35);">
                  Questions? Feel free to
                </Typography>
                <Typography variant="small" ml={5} color="#7266EE">
                  contact our friendly support team.
                </Typography>
              </StyledContactSupportContainer>
              {status !== 'verified' && status !== 'processing' && (
                <StyledVerifyButton onClick={handleVerificationClick}>Verify Identity</StyledVerifyButton>
              )}
            </StyledVerificationGrid>
            <StyledVerificationIcon src={icon} alt={statusOption.text} />
          </StyledVerificationSubContainer>
        )}
      </StyledVerificationContainer>
      <StyledSupportContainer>
        <StyledSupportText color="#111112">
          Wondering why we do this? To look out for you of course. TheXPlace is no 5-dolla-a-gig platform. It’s a
          securely vetted, quality community of top professionals built on trust—and that starts by verifying who can
          access it. Simple right?
        </StyledSupportText>
        <StyledSupportImage src={ChatHeadImg} alt="tip" />
      </StyledSupportContainer>
    </StyledContainer>
  );
};

export default VerifyAccount;
