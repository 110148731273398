import Switcher from 'common/form/Switcher';
import React from 'react';
import { BiasReducedModeContainer, BiasReducedModeHint, BiasReducedModeTitle } from './styled-components';

const BiasReducedMode = ({ checked, onChangeHandler }) => (
  <BiasReducedModeContainer>
    <BiasReducedModeTitle>Bias-reduced mode</BiasReducedModeTitle>
    <BiasReducedModeHint>
      {`Bias-Reduced mode removes pictures and names in order to help you focus on candidates' skills.`}
    </BiasReducedModeHint>
    <Switcher checked={checked} setChecked={onChangeHandler} />
  </BiasReducedModeContainer>
);

export default BiasReducedMode;
