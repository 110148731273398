import React from 'react';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import withRenderPortal from 'hocs/withRenderPortal';
import Spinner from 'common/Spinner';
import useJobServices from 'hooks/api/useJobServices';
import styled from '@emotion/styled';

const Text = styled.p`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: rgba(230, 234, 255, 0.6);
`;

const JobCloseModal = ({ closePortal, data, name }) => {
  const { profileId, jobId, closeJobCallback = () => ({}) } = data;
  const {
    changeJobStatus,
    loading: { loadingChangeStatus }
  } = useJobServices();

  const handleClose = () => {
    changeJobStatus(profileId, jobId, 'closed', () => {
      closeJobCallback();
      closePortal();
    });
  };

  return (
    <TxplModal
      title="Close this job?"
      titleMB="24"
      name={name}
      size="small"
      padding="40px 0 56px"
      noPaddingTop
      hideBorder
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {loadingChangeStatus && <Spinner />}
          <ContainedButton ml={6} mr={6} backgroundColor="rgba(204, 213, 255, 0.11)" onClick={closePortal}>
            cancel
          </ContainedButton>
          <ContainedButton onClick={handleClose}>close</ContainedButton>
        </>
      }
    >
      <Text>The job will be removed from the job board and you will no longer be able to hire cadidates.</Text>
    </TxplModal>
  );
};

export default withRenderPortal('job-close-modal')(JobCloseModal);
