import styled from '@emotion/styled';
import Icon from 'common/Icon';
import React from 'react';
import commonStyles from 'utils/common-styles';

const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: Space Grotesk;
  gap: ${({ gap }) => gap || '6'}px;
  ${commonStyles.ui_text_medium}
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  ${({ backgroundColor }) => !backgroundColor && 'border: 2px solid rgba(204, 213, 255, 0.11);'}
  color: ${({ backgroundColor, color }) => (backgroundColor ? color || '#fffff' : 'rgba(229, 234, 255, 0.6)')};
  padding: ${({ padding }) => padding || '2px 6px'};
  text-transform: ${({ textTransform }) => textTransform ?? 'initial'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  width: ${({ width }) => width ?? ''};
  height: ${({ height }) => height ?? ''};
  border-radius: ${({ borderRadius }) => borderRadius ?? '8px'};
  line-height: ${({ lineHeight }) => lineHeight ?? '22px'};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  .tag-icon,
  .tag-icon:hover {
    path {
      fill: ${({ backgroundColor, color }) => (backgroundColor ? color || '#fffff' : 'rgba(229, 234, 255, 0.6)')};
    }
  }
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const Tag = ({ children, icon, img, ...rest }) => (
  <StyledTag {...rest}>
    {icon && <Icon className="tag-icon" icon={icon} />}
    {img && <Image src={img} />}
    {children}
  </StyledTag>
);

export default Tag;
