import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { authActions } from 'store/auth';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';

const StyledList = styled.ul`
  padding-left: 18px;
`;

const StyledListItem = styled.li`
  color: rgba(230, 234, 255, 0.6);
`;

const StyledContainedButton = styled(ContainedButton)`
  button.delete-account-btn {
    border-color: rgba(204, 213, 255, 0.11);
  }
`;
const DeleteButtonWrapper = styled.div`
  display: flex;
  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    justify-content: flex-end;
  }
`;

const DeleteAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(authActions.logout());
    history.push('/account-deleted');
  };

  return (
    <>
      <DeleteAccountConfirmation onSuccess={onDelete} />

      <Typography transform="capitalize" variant="h3" mb={32}>
        Delete Account
      </Typography>

      <Typography variant="medium" mb={16} color="rgba(230, 234, 255, 0.6)">
        This is a highly dangerous zone. Deleting your account will erase all your information and connections including
      </Typography>

      <StyledList>
        <StyledListItem>
          <Typography color="rgba(230, 234, 255, 0.6)" variant="medium">
            All your portfolio projects
          </Typography>
        </StyledListItem>
        <StyledListItem>
          <Typography color="rgba(230, 234, 255, 0.6)" variant="medium">
            All your ongoing jobs
          </Typography>
        </StyledListItem>
        <StyledListItem>
          <Typography color="rgba(230, 234, 255, 0.6)" variant="medium">
            {`Your progress and status you've acquired`}
          </Typography>
        </StyledListItem>
        <StyledListItem>
          <Typography color="rgba(230, 234, 255, 0.6)" variant="medium">
            All your connections on TheXPlace
          </Typography>
        </StyledListItem>
      </StyledList>

      <Typography variant="medium" mt={16} mb={40} color="rgba(230, 234, 255, 0.6)">
        Please consider this decision thoroughly.
      </Typography>

      <DeleteButtonWrapper>
        <StyledContainedButton
          className="delete-account-btn"
          icon="delete"
          color="#FF5151"
          backgroundColor="transparent"
          opens="delete-account-confirmation"
        >
          Delete Account
        </StyledContainedButton>
      </DeleteButtonWrapper>
    </>
  );
};

export default DeleteAccount;
