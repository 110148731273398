import React, { useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';
import merge from 'lodash/fp/merge';
import cloneDeep from 'lodash/fp/cloneDeep';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { portalActions } from 'store/portals';
import useViewport from 'hooks/useViewport';

import TxplModalHeader from './TxplModalHeader';
import TxplModalContent from './TxplModalContent';
import TxplModalFooter from './TxplModalFooter';

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  overflow: visible;
`;

const baseStyle = transparentBackground => ({
  overlay: {
    zIndex: 100,
    backgroundColor: transparentBackground ? 'transparent' : 'rgba(18,18,17,0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    boxSizing: 'border-box',
    border: 'none',
    background: '#1A1B1E',
    inset: 'auto',
    maxWidth: '680px',
    width: '100%',
    margin: 'auto auto',
    padding: '32px 40px 40px 40px',
    borderRadius: '16px',
    maxHeight: '85%'
  }
});

const TxplModal = ({
  contentPosition,
  overlayPosition,
  TxplModalHeaderContainerPB,
  TxplModalHeaderContainerPT,
  XSaddPaddingTop,
  XSpadding,
  addMarginTopLong,
  addPaddingTop = false,
  addPaddingTopBig,
  allowLine = false,
  backgroundColor,
  boldWeight,
  centerFlex,
  children,
  contentLeft,
  contentPadding,
  fromJob,
  headerBorderColor = false,
  headerBorderSize = false,
  headerPaddingBottom,
  hideBorder,
  hideCloseIcon,
  hideHeaderBorder = false,
  maxWidth,
  modalXS,
  name,
  noPadding,
  noPaddingTop = false,
  noScroll,
  nonFullScreenOnMobile,
  nonFullScreenOnMobileCenter,
  onClose = false,
  padding,
  renderAdditionalHeader,
  renderFooter,
  renderHeader,
  sameFontSize,
  showSignUp,
  size,
  subTitle,
  subTitleFs,
  subTitleMargin,
  subTitleMaxWidth,
  testTitle,
  title,
  titleFontSize,
  titleFs,
  titleMB,
  titlePadding,
  titlewidth,
  transparentBackground,
  useOutsideClick = false,
  ...rest
}) => {
  const { isL, isM, width } = useViewport();
  const dispatch = useDispatch();

  useEffect(() => {
    const onPortalOpen = () => {
      document.body.style.overflow = 'hidden';
    };

    const onPortalClose = () => {
      document.body.style.overflow = 'auto';
    };

    onPortalOpen();
    return onPortalClose;
  }, []);

  const mergedStyles = useMemo(() => {
    let modalStyles = cloneDeep(baseStyle(transparentBackground));

    if (size === 'small') {
      const override = {
        content: {
          inset: 'auto',
          width: '100%',
          maxWidth: maxWidth || 580
        }
      };
      modalStyles = merge(modalStyles, override);
    }

    if (size === 'large') {
      const override = {
        content: {
          inset: 'auto',
          width: 680,
          maxWidth: 680,
          padding: contentPadding ?? '32px 40px 40px 40px'
        }
      };
      modalStyles = merge(modalStyles, override);
    }

    if (size === 'custom') {
      const override = {
        content: {
          inset: 'auto',
          width: isL ? 864 : isM ? 666 : 675,
          maxWidth: isL ? 864 : isM ? 666 : 675,
          padding: 0
        }
      };
      modalStyles = merge(modalStyles, override);
    }

    if (width < 759 && !nonFullScreenOnMobile) {
      const override = {
        content: {
          inset: '0px',
          maxWidth: '100%',
          borderRadius: 0,
          padding: 16,
          height: '100vh'
        }
      };
      modalStyles = merge(modalStyles, override);
    }

    if (width < 759 && !nonFullScreenOnMobile && modalXS) {
      const override = {
        overlay: {
          zIndex: 3,
          top: '68px',
          overflow: 'hidden'
        },
        content: {
          padding: contentPadding ?? '32px 40px 40px 40px',
          maxHeight: '100%'
        }
      };
      modalStyles = merge(modalStyles, override);
    }

    if (width < 759 && nonFullScreenOnMobile) {
      const override = {
        overlay: {
          alignItems: 'flex-end',
          paddingBottom: 16
        },
        content: {
          inset: 'initial',
          maxWidth: '90%',
          borderRadius: 8,
          padding: contentPadding ?? '24px 16px 16px 16px'
        }
      };
      modalStyles = merge(modalStyles, override);
    }
    if (width < 759 && nonFullScreenOnMobileCenter) {
      const override = {
        overlay: {
          alignItems: 'center',
          paddingBottom: 16
        },
        content: {
          inset: 'initial',
          maxWidth: '90%',
          borderRadius: 8,
          padding: contentPadding ?? '24px 16px 16px 16px',
          height: 'auto'
        }
      };
      modalStyles = merge(modalStyles, override);
    }

    if (backgroundColor) modalStyles.content.background = backgroundColor;
    if (noPadding) modalStyles.content.padding = 0;
    if (overlayPosition) {
      modalStyles.overlay.position = overlayPosition;
    }
    if (contentPosition) {
      modalStyles.content.position = contentPosition;
    }

    return {
      ...modalStyles,
      overlay: {
        ...modalStyles.overlay,
        overflow: noScroll ? 'visible' : 'auto'
      },
      content: {
        ...modalStyles.content,
        overflow: noScroll ? 'visible' : 'auto'
      }
    };
  }, [
    size,
    width,
    nonFullScreenOnMobile,
    backgroundColor,
    transparentBackground,
    noPadding,
    contentPadding,
    modalXS,
    maxWidth
  ]);

  const closePortal = () => {
    if (onClose) {
      onClose();
    } else {
      dispatch(portalActions.closePortal({ name }));
    }
  };

  const outsideClick = () => {
    if (useOutsideClick) {
      outsideClick();
    }
  };

  return (
    <ReactModal isOpen style={mergedStyles} onRequestClose={outsideClick} {...rest}>
      <StyledModalContainer>
        {renderHeader ? (
          renderHeader()
        ) : (
          <TxplModalHeader
            titlewidth={titlewidth}
            subTitleMaxWidth={subTitleMaxWidth}
            TxplModalHeaderContainerPB={TxplModalHeaderContainerPB}
            TxplModalHeaderContainerPT={TxplModalHeaderContainerPT}
            showSignUp={showSignUp}
            title={title}
            testTitle={testTitle}
            headerPaddingBottom={headerPaddingBottom}
            size={size}
            titleFontSize={titleFontSize}
            boldWeight={boldWeight}
            subTitle={subTitle}
            subTitleMargin={subTitleMargin}
            allowLine={allowLine}
            onClose={closePortal}
            hideCloseIcon={hideCloseIcon}
            renderAdditionalHeader={renderAdditionalHeader}
            titlePadding={titlePadding}
            titleMB={titleMB}
            headerBorderColor={headerBorderColor}
            hideHeaderBorder={hideHeaderBorder}
            headerBorderSize={headerBorderSize}
            nonFullScreenOnMobile={nonFullScreenOnMobile}
            fromJob={fromJob}
            titleFs={titleFs}
            subTitleFs={subTitleFs}
            modalXS={modalXS}
            sameFontSize={sameFontSize}
          />
        )}
        <TxplModalContent
          centerFlex={centerFlex}
          XSpadding={XSpadding}
          noScroll={noScroll}
          noPaddingTop={size === 'small' && !title}
          padding={padding}
        >
          {children}
        </TxplModalContent>

        {renderFooter && (
          <TxplModalFooter
            XSaddPaddingTop={XSaddPaddingTop}
            noPaddingTop={noPaddingTop}
            addPaddingTopBig={addPaddingTopBig}
            addMarginTopLong={addMarginTopLong}
            addPaddingTop={addPaddingTop}
            contentLeft={contentLeft}
            nonFullScreenOnMobile={nonFullScreenOnMobile || hideBorder}
          >
            {renderFooter}
          </TxplModalFooter>
        )}
      </StyledModalContainer>
    </ReactModal>
  );
};

export default TxplModal;
