import React from 'react';
import Spinner from 'common/Spinner';
import {
  BasicProfileInfoWrapper,
  InnerWrapper,
  SkipVerifyFooterWrapper,
  StyledContainedButton,
  StyledStartButton,
  ToolkitFooterWrapper,
  VerifyFooterWrapper,
  WelcomeFooterWrapper
} from './Steps.styled';

export const WelcomeFooter = ({ handleNext }) => {
  return (
    <WelcomeFooterWrapper>
      <StyledStartButton backgroundColor="rgba(0, 255, 0, 1)" color="rgba(17, 17, 18, 1)" onClick={handleNext}>
        Let´s start!
      </StyledStartButton>
    </WelcomeFooterWrapper>
  );
};

export const BasicProfileInfoFooter = ({ formik, handleNext, isLoading, dirtyLocation }) => {
  return (
    <BasicProfileInfoWrapper>
      {isLoading ? <Spinner /> : null}
      <StyledContainedButton onClick={() => handleNext(formik, dirtyLocation)}>Next</StyledContainedButton>
    </BasicProfileInfoWrapper>
  );
};

export const ToolkitFooter = ({ formik, handleNext, handleBack, isLoading, dirtyLocation }) => {
  return (
    <ToolkitFooterWrapper>
      <StyledContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleBack}>
        Back
      </StyledContainedButton>
      <InnerWrapper>
        {isLoading ? <Spinner /> : null}
        <StyledContainedButton onClick={() => handleNext(formik, dirtyLocation)}>Next</StyledContainedButton>
      </InnerWrapper>
    </ToolkitFooterWrapper>
  );
};

export const VerifyIdFooter = ({ formik, handleNext, dirtyLocation, isLoading }) => {
  return (
    <VerifyFooterWrapper>
      <StyledContainedButton
        backgroundColor="rgba(204, 213, 255, 0.11)"
        onClick={() => handleNext(formik, dirtyLocation)}
        disabled={isLoading}
      >
        I’ll do this later
      </StyledContainedButton>
    </VerifyFooterWrapper>
  );
};

export const SkipVerifyIdFooter = ({ formik, handleNext, handleVerifyId, dirtyLocation, isLoading }) => {
  return (
    <SkipVerifyFooterWrapper>
      <StyledContainedButton
        backgroundColor="rgba(204, 213, 255, 0.11)"
        onClick={() => handleNext(formik, dirtyLocation)}
        disabled={isLoading}
      >
        Do it later
      </StyledContainedButton>
      {isLoading ? (
        <Spinner />
      ) : (
        <StyledContainedButton backgroundColor="rgba(84, 72, 219, 1)" onClick={handleVerifyId}>
          Verify my ID
        </StyledContainedButton>
      )}
    </SkipVerifyFooterWrapper>
  );
};
