import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import ContainedButton from 'common/ContainedButton';
import commonStyles from 'utils/common-styles';
import { portalActions } from 'store/portals';
import NA from 'images/NA.png';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${props => props.mt && `margin-top: ${props.mt}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
	${({ pb }) => pb && `padding-bottom: ${pb}px;`}
	${({ mb }) => mb && `margin-bottom: ${mb}px;`}
`;

const StyledLabel = styled.label`
  ${commonStyles.ui_heading_3}
  color: white;
  ${props => props.boldWeight && 'font-weight: 700;'}
`;

const StyledHintText = styled.label`
  ${commonStyles.ui_text_small};
  color: rgba(230, 234, 255, 0.35);
  ${props => props.error && 'color: #FF5151;'}
  ${props => props.right && 'max-width: 230px; margin-left: 16px'}
  ${props => props.lightWeight && 'font-weight: 400;'}
`;

const StyledImage = styled.img`
  width: 100%;
  ${props => props.mw && `max-width: ${props.mw};`}
  padding-bottom: 24px;
`;

const AvatarUploader = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  position: relative;
`;

const UploadWrapper = styled.div`
  width: 165px;
  ${props =>
    props.center &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
  ${props => props.padding && 'padding: 20px 0;'}
  ${props => props.customPadding && `padding: ${props.customPadding};`}
  ${props =>
    props.labelRight &&
    `
    display: flex;
    align-items: center;
    width: 100%;
  `}
`;

const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);
  font-size: 25px;
  position: relative;
  top: 5px;
  left: -5px;

  &::after {
    content: '*';
    padding-left: 3px;
  }
`;

const StyledContainedButton = styled(ContainedButton)`
  position: absolute;
  top: 20px;
  right: 10px;

  ${props => props.padding && 'top: 40px'}
`;

const FormUpload = ({
  accept,
  aspectRatio,
  backgroundColor,
  boldWeight,
  center,
  className,
  customPadding,
  error,
  hintText,
  id,
  isResizable,
  label,
  labelRight,
  lightWeight,
  mb,
  minHeight,
  minWidth,
  mt,
  mw,
  name,
  noCrop,
  onChange,
  onRemoveClick,
  padding,
  pb,
  preview,
  previewUrl,
  pt,
  required,
  uploadText,
  value,
  viewMode,
  wrapperStyles
}) => {
  const [image, setImage] = React.useState();
  const inputTypeFileRef = React.useRef();

  const dispatch = useDispatch();

  const handleInputTypeFileClicked = e => {
    e.stopPropagation();
    inputTypeFileRef.current.click();
  };

  const handleNewImage = async e => {
    e.persist();
    if (noCrop) {
      setImage(e.target.files[0]);
      onChange(e.target.files[0]);
      return;
    }
    dispatch(
      portalActions.openPortal({
        name: 'cropper-modal',
        data: {
          onCrop: image => {
            setImage(image);
            onChange(image);
          },
          initialImage: e.target.files[0],
          aspectRatio,
          isResizable,
          minWidth,
          minHeight,
          viewMode
        }
      })
    );

    e.target.value = '';
  };

  const handleRemoveImage = () => {
    setImage('');
    onRemoveClick && onRemoveClick();
  };

  React.useEffect(() => setImage(previewUrl ?? value), [value]);

  return (
    <InputWrapper mt={mt} pt={pt} pb={pb} mb={mb} id={id} className={className} name={name}>
      {label && (
        <StyledLabel boldWeight={boldWeight}>
          {label} {required && <Required />}
        </StyledLabel>
      )}
      <AvatarUploader style={wrapperStyles}>
        {preview && image && (
          <StyledContainedButton
            backgroundColor="#1A1B1E;"
            forceDisplayText
            icon="delete"
            onClick={handleRemoveImage}
            padding={padding}
            type="button"
          >
            REMOVE
          </StyledContainedButton>
        )}
        {preview && image && (
          <StyledImage
            padding={padding}
            mw={mw}
            src={typeof image === 'string' ? image : image.toString().length === 0 ? NA : URL.createObjectURL(image)}
          />
        )}
        <UploadWrapper
          center={center}
          labelRight={labelRight}
          className={`upload-wrapper ${className}`}
          padding={padding}
          customPadding={customPadding}
        >
          <ContainedButton
            type="button"
            icon="upload"
            onClick={handleInputTypeFileClicked}
            backgroundColor={backgroundColor || 'rgba(204, 213, 255, 0.11)'}
            forceDisplayText
          >
            {uploadText || 'Upload'}
          </ContainedButton>
          <StyledHintText className="hint-text" lightWeight={lightWeight} error={error} right={labelRight}>
            {hintText}
          </StyledHintText>

          <input
            type="file"
            id="upload"
            accept={accept || 'image/x-png,image/gif,image/jpeg'}
            onChange={handleNewImage}
            ref={inputTypeFileRef}
            style={{ display: 'none' }}
          />
        </UploadWrapper>
      </AvatarUploader>
    </InputWrapper>
  );
};

export default FormUpload;
