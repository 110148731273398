import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import { userActions } from 'store/user';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import backOfficeService from 'api/backOfficeService';
import { companyFlags } from '../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = initialStatus => ({
  status: { initialValue: initialStatus, validate: ['isRequired'] }
});

const transformParams = params => ({
  ...params,
  status: params.status.value
});

const CompanyFlagModal = ({ closePortal, data, name }) => {
  const { accountId, status } = data;
  const dispatch = useDispatch();

  const callApi = params => {
    const apiParams = transformParams(params);

    const requestPromise = backOfficeService.setCompanyAccountFlagStatus(accountId, { ...apiParams });

    return requestPromise.then(({ data: { company } }) => {
      dispatch(userActions.updateUserProperty({ property: 'isSupply', value: company.isSupply }));
      if (data.onSuccess) data.onSuccess();
      closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({ fields: fields(status), callApi });

  return (
    <TxplModal
      title="Supply/demand flags"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <StyledActionsContainer>
          {submitting && <Spinner />}
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
            Cancel
          </ContainedButton>
          <ContainedButton form="companyFlagModal" type="submit">
            Save
          </ContainedButton>
        </StyledActionsContainer>
      }
    >
      <form onSubmit={onSubmit} id="companyFlagModal">
        <FormSelect
          autoFocus
          label="Supply/demand flags"
          value={values.supplyDemand}
          onChange={onFieldChange('status')}
          options={companyFlags}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('company-flag-modal')(CompanyFlagModal);
