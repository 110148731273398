import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';

export const StyledContainer = styled.div`
  z-index: 1;
  position: relative;
  padding: 0 0.5rem;

  @media ${pageSize.S} {
    padding: 0 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  background: rgb(17, 17, 18);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

export const Background = styled.div`
  z-index: 0;
  position: absolute;
  top: calc(278px - 588px);
  left: 50%;
  transform: translate(-50%, 0);
  content: ' ';
  width: 588px;
  height: 588px;
  border-radius: 1736px;
  background: radial-gradient(50% 50% at 50% 50%, #421ae2 33.5%, #3b114b 71%, #111112 100%);

  @media ${pageSize.S} {
    width: 1072px;
    height: 1072px;
    top: calc(435px - 1072px);
  }

  @media ${pageSize.M} {
    width: 1479px;
    height: 1479px;
    top: calc(488px - 1479px);
  }

  @media ${pageSize.L} {
    width: 1736px;
    height: 1736px;
    top: calc(573px - 1736px);
  }
`;

export const StyledHeader = styled.header`
  padding-top: 104px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMain = styled.main`
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  background-color: rgb(24, 25, 29);
  border-radius: 1.5rem;
  margin-bottom: 120px;

  & > .header {
    border-radius: 1.5rem 1.5rem 0 0;
    background-image: url('https://production-thexplace-static-content.s3.us-west-1.amazonaws.com/images/onboarding-header-background.png');
    background-size: cover;
    background-position: center;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & > .header > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  & > .header > div > h2 {
    margin-top: 32px;
    max-width: 272px;
    text-align: center;
    font-size: 26px;
    line-height: 32px;
  }

  & > .content {
    padding: 3.5rem 1rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
  }

  & > .header > img {
    width: 144px;
  }

  @media ${pageSize.S} {
    margin-bottom: 10rem;
    & > .content {
      padding: 3.5rem 2.5rem 1.5rem 2.5rem;
    }

    & > .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 200px;
      justify-content: space-between;
    }

    & > .header > div > h2 {
      margin-top: 0;
      text-align: left;
      font-size: 36px;
      line-height: 40px;
    }

    & > .header > img {
      align-self: flex-end;
      width: auto;
    }
  }
`;

// Home
export const CoverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 1572px;
  left: -111px;
  top: -1119px;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #6655cc 0%, rgba(74, 41, 204, 0) 100%);
`;

export const OnboardingContainer = styled.div`
  z-index: 0;
  position: absolute;
  top: calc(278px - 588px);
  left: 50%;
  transform: translate(-50%, 0);
  content: ' ';
  width: 588px;
  height: 588px;
  border-radius: 1736px;
  background: radial-gradient(50% 50% at 50% 50%, #421ae2 33.5%, #3b114b 71%, #111112 100%);

  @media ${pageSize.M} {
    width: 1479px;
    height: 1479px;
    top: calc(488px - 1479px);
  }
  @media ${pageSize.S} {
    width: 1072px;
    height: 1072px;
    top: calc(435px - 1072px);
  }
  @media ${pageSize.L} {
    width: 1736px;
    height: 1736px;
    top: calc(573px - 1736px);
  }
`;

export const MainContainer = styled.div`
  max-width: 1242px;
  min-height: calc(100vh - 520px);
  margin: 160px auto 100px;
  position: relative;
  display: flex;
  @media ${pageSize.M} {
    padding: 0 59px;
  }
  @media ${pageSize.S} {
    justify-content: center;
  }
  @media ${pageSize.XS} {
    justify-content: center;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-right: 54px;
  flex-grow: 1;
  @media ${pageSize.S} {
    width: 100%;
    margin: 0;
    padding: 0 81px;
  }
  @media ${pageSize.XS} {
    width: 100%;
    margin: 0;
    padding: 0 16px;
  }
`;

// HomeWelcome
export const Welcome = styled.div`
  display: flex;
  height: max-content;
  border-radius: 16px;
  padding: 24px 24px 32px;
  margin-bottom: 24px;
  background: ${props => (props.color ? props.color : '#7266ee')};
  ${props => props.closeWelcome && 'display: none;'}

  @media ${pageSize.XS} {
    padding: 16px 16px 24px;
  }
`;

export const WelcomeTextContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${pageSize.XS} {
    flex-wrap: wrap-reverse;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65px;
  @media ${pageSize.XS} {
    flex-direction: row;
    width: auto;
  }
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(204, 213, 255, 0.11);
`;

export const AvatarTitle = styled(AvatarContainer)`
  @media ${pageSize.XS} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AvatarName = styled.div`
  ${commonStyles.ui_text_small}
  margin-top: 8px;
`;

export const AvatarRole = styled.div`
  ${commonStyles.ui_text_tiny}
  margin-top: 4px;
  color: rgba(230, 234, 255, 0.6);
`;

export const WelcomeTextDescContainer = styled.div`
  display: flex;
  margin-left: 90px;

  @media ${pageSize.XS} {
    flex-wrap: wrap;
    margin-left: 0;
  }
`;

export const WelcomeText = styled.div`
  width: 100%;
  background: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  padding: 12px 24px;
  position: relative;

  @media ${pageSize.XS} {
    margin-left: 0;
    font-size: 24px;
    width: 100%;
    margin-bottom: 24px;
    margin-right: 28px;
  }
  &::before {
    content: '';
    position: absolute;
    border-color: transparent rgba(204, 213, 255, 0.11);
    border-style: solid;
    border-width: 8px 8px 8px 0px;
    height: 0px;
    width: 0px;
    left: -8px;
    top: 42%;
    @media ${pageSize.XS} {
      content: '';
      position: absolute;
      border-color: rgba(204, 213, 255, 0.11) transparent;
      border-style: solid;
      border-width: 10px 10px 0 0;
      height: 0px;
      width: 0px;
      top: 100%;
      left: 20px;
    }
  }
`;

export const WelcomeTextDescWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  flex-flow: column;
  gap: 24px;
  @media ${pageSize.L}, ${pageSize.M}, ${pageSize.S} {
    flex-flow: row;
    margin-left: 8px;
  }
`;

export const WelcomeTextDesc = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  @media ${pageSize.XS} {
    margin-top: 16px;
  }
`;

export const WelcomeTextDescQuestion = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  @media ${pageSize.L}, ${pageSize.M}, ${pageSize.S} {
    width: 170px;
    min-width: 170px;
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  align-self: start;
  @media ${pageSize.XS} {
    position: absolute;
    left: calc(100% - 64px);
  }
`;

// HomeIndustryNews
export const IndustryNewsContainer = styled.div`
  a {
    text-decoration: none;
  }

  background: #1a1b1e;
  border-radius: 16px;
  padding: 0px 16px 16px;
`;

export const IndustryNewsContainerTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 16px 0;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
`;

export const IndustryNewsItemFirst = styled.div`
  padding-top: 16px;
`;

export const IndustryNewsItemFirstTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 34px 25px 0 25px;
  border-left: 2px solid rgba(204, 213, 255, 0.11);
  border-right: 2px solid rgba(204, 213, 255, 0.11);
  margin-top: -10px;
  @media ${pageSize.XS} {
    font-size: 24px;
  }
`;

export const IndustryNewsItemFirstAuthorContainer = styled.div`
  display: flex;
  border-left: 2px solid rgba(204, 213, 255, 0.11);
  border-right: 2px solid rgba(204, 213, 255, 0.11);
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 25px 40px 25px;
`;

export const IndustryNewsItemFirstAuthor = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: rgba(230, 234, 255, 0.35);
`;

export const IndustryNewsItemFirstAuthorLink = styled(IndustryNewsItemFirstAuthor)`
  color: #ffffff;
  padding-left: 5px;
`;

export const IndustryNewsItemFirstAuthorBullet = styled(IndustryNewsItemFirstAuthor)`
  color: #ffffff;
  padding: 0 10px;
`;

export const IndustryNewsItemFirstDate = styled(IndustryNewsItemFirstAuthor)``;

export const IndustryNewsItemTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
`;

export const IndustryNewsItemDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.6);
  padding-top: 16px;
`;

export const IndustryNewsItemAuthorContainer = styled.div`
  display: flex;
  padding-top: 16px;
`;

export const IndustryNewsSeeMore = styled.div`
  background: rgba(204, 213, 255, 0.11);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

export const IndustryNewsItem = styled.div`
  padding: 20px 20px 40px 20px;
  display: flex;
  margin: 20px 0;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  @media ${pageSize.XS} {
    flex-wrap: wrap;
  }
`;

export const IndustryNewsItemImg = styled.img`
  width: 224px;
  margin-right: 24px;
  border-radius: 8px;
  @media ${pageSize.XS} {
    width: 100%;
  }
`;

export const IndustryNewsItemFirstDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: rgba(230, 234, 255, 0.6);
  padding: 24px 25px;
  border-left: 2px solid rgba(204, 213, 255, 0.11);
  border-right: 2px solid rgba(204, 213, 255, 0.11);
  @media ${pageSize.XS} {
    font-size: 16px;
  }
`;

export const IndustryNewsItemFirstImg = styled.img`
  border-radius: 8px;
  width: 100%;
`;

export const Loader = styled(Spinner)`
  margin: 15px 0 5px 0;
`;

// HomeSidebar
export const Sidebar = styled.div`
  width: 432px;
  min-width: 432px;
  @media ${pageSize.S}, ${pageSize.XS} {
    display: none;
  }
`;

export const Accounts = styled.div`
  width: 100%;
  background: #1a1b1e;
  border-radius: 16px;
  padding-bottom: 12px;
`;

export const Updates = styled(Accounts)`
  margin-top: 24px;
  overflow: hidden;
`;

export const AccountHeader = styled.div`
  ${commonStyles.card_label}
  text-transform: uppercase;
  padding: 24px 24px 8px;
  &.updates {
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  }
`;

export const AccountItem = styled.div`
  margin: 0 12px;
  padding: 12px;
  display: flex;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    background: rgba(204, 213, 255, 0.11);
    .profile-name {
      color: #ffff;
    }
  }
  &:not(.active):hover {
    background: rgba(204, 213, 255, 0.05);
  }
`;

export const AccountName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(242, 244, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 40px;
  word-break: break-all;
`;

export const AccountNameWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

export const AccountAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(204, 213, 255, 0.11);
`;

export const AccountActive = styled.div`
  position: absolute;
  right: 12px;
  top: 4px;
  transform: translateY(50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

export const DescriptionList = styled.ul`
  margin: 0;
  padding-left: 24px;
`;
