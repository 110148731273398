import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useLocation } from 'react-router-dom';
import Icon from 'common/Icon';

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  &.active {
    background: rgba(204, 213, 255, 0.11);
    .link-text {
      color: #fff;
    }
    .icon {
      path {
        fill: #fff;
      }
    }
  }
  &:hover {
    background: rgba(204, 213, 255, 0.05);
  }
`;

export const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  text-decoration: none;
  &.active {
    background: rgba(204, 213, 255, 0.11);
    .link-text {
      color: #fff;
    }
    .icon {
      path {
        fill: #fff;
      }
    }
  }
  &:hover {
    background: rgba(204, 213, 255, 0.05);
  }
`;

export const StyledIcon = styled(Icon)`
  color: rgba(230, 234, 255, 0.35);
  margin-right: 4px;
  margin-left: 4px;
  width: 16px;
  height: 16px;
`;

export const Text = styled.div`
  ${props => props.isSelected && 'color: green'};
  flex: 1;
  margin-left: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
`;
const Avatar = styled.img`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(221, 226, 255, 0.2);
`;

const SettingsMenuItem = ({ menuItem }) => {
  const { icon, img, label, url } = menuItem || {};
  const location = useLocation();
  const isSelected = location.pathname === url;

  return (
    <StyledNavLink key={url} to={url} style={{ textDecoration: 'none' }}>
      {icon && (
        <StyledIcon
          className="icon"
          icon={icon}
          color="rgba(230, 234, 255, 0.6)"
          hoverColor="rgba(230, 234, 255, 0.6)"
        />
      )}
      {img && <Avatar src={img} alt="" />}
      <Text className="link-text" isSelected={isSelected} icon={icon}>
        {label}
      </Text>
    </StyledNavLink>
  );
};

export default SettingsMenuItem;
