import React from 'react';
import styled from '@emotion/styled';
import useViewport from 'hooks/useViewport';
import JobPostCard from '../jobs-i-posted/JobPostCard';
import { DisplayContext } from 'common/DisplaySwitcher';
import { pageSize } from 'utils/devices';
import SupplyPostJobModal from 'pages/jobs/card/JobCard/SupplyPostJobModal';
import DemandApplyJobModal from 'pages/jobs/card/JobCard/DemandApplyJobModal';
import JobCardSearch from 'pages/jobs/card/JobCardSearch/JobCardSearch';

const JobListWrapper = styled.div`
  ${({ display }) =>
    display === 'grid'
      ? `
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px 20px;
    `
      : `
        display: grid;
        gap: 32px;
    `}

  @media ${pageSize.XS} {
    gap: 16px;
  }
`;

const JobListSearch = ({ hasMore, hideApply, jobs, showDropdown, showPostJobCard, status }) => {
  const { display } = React.useContext(DisplayContext);
  const { isXS } = useViewport();

  return (
    <JobListWrapper display={!isXS ? display : 'list'}>
      {jobs?.map(job => (
        <JobCardSearch
          key={job?._id || job?.id}
          job={job}
          showDropdown={showDropdown}
          isListView={display === 'list'}
          hideApply={hideApply}
          staffedStatus={status}
        />
      ))}
      {(jobs.length > 0 || !hasMore) && showPostJobCard && <JobPostCard display={!isXS ? display : 'list'} />}
      <SupplyPostJobModal />
      <DemandApplyJobModal />
    </JobListWrapper>
  );
};

export default JobListSearch;
