import { DisplayContext } from 'common/DisplaySwitcher';
import Loader from 'common/Loader';
import Flex from 'common/styles/Flex';
import BiasReducedModeSwitch from 'components/BiasReducedModeSwitch/BiasReducedModeSwitch';
import useApplicantService from 'hooks/api/useApplicantService';
import isEmpty from 'lodash/fp/isEmpty';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { applicantsActions } from 'store/applicants';
import { authActions } from 'store/auth';
import { profileActions, profileSelectors } from 'store/profile';
import ApproveCandidateModal from '../JobModals/ApproveCandidateModal/ApproveCandidateModal';
import SelectCandidateModal from '../JobModals/SelectCandidateModal/SelectCandidateModal';
import ApplicantsFilter from './ApplicantsFilter';
import ApplicantsList from './ApplicantsList';
import ApplicantCardModal from './modal/ApplicantCardModal';
import ApplicantsFilterModal from './modal/ApplicantsFilterModal';
import ContainedButton from '../../../../common/ContainedButton';
import { RejectedCount } from '../styled.components';

import * as Styled from '../../../../components/UserCard/StyledUserCard';
import CompanyContractModal from './CompanyContractModal';
import AdminContractModal from './AdminContractModal';

const initialFilters = {
  discipline: [],
  location: [],
  timezone: {},
  tool: [],
  projects: {},
  credit: {},
  tag: [],
  genre: [],
  skill: [],
  'applicant-status': {
    value: 'all'
  }
};

const Applicants = ({ job, jobId, jobTitle}) => {
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const [biasReducedMode, setBiasReducedMode] = React.useState(
    !!useSelector(profileSelectors.selectMyProfileProperty('biasReducedMode'))
  );

  React.useEffect(() => {
    dispatch(
      authActions.updateCurrentUserProperty({ property: 'activeProfile.biasReducedMode', value: biasReducedMode })
    );
    dispatch(
      profileActions.updateProfileProperty({
        profileId: myProfileId,
        property: 'biasReducedMode',
        value: biasReducedMode,
        updateType: 'update'
      })
    );
  }, [biasReducedMode]);
  const switchBiasReducedMode = React.useCallback(() => setBiasReducedMode(latest => !latest), [setBiasReducedMode]);
  const [display, setDisplay] = React.useState('grid');
  const [counterIcon, setCounterIcon] = useState('hidden');

  const {
    applicantFilters,
    applicants,
    applicantsPage: page,
    getApplicants,
    hasMoreApplicants: hasMore,
    hiredApplicants,
    nonRejectedUnsavedApplicants,
    nonSavedHiredApplicants,
    rejectedApplicants,
    savedApplicants
  } = useApplicantService(jobId);

  const [filters, setFilters] = useState(initialFilters);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const clearFilters = () => {
    setFilters(filters => ({
      ...filters,
      discipline: [],
      location: [],
      timezone: {},
      tool: [],
      projects: {},
      credit: {},
      tag: [],
      genre: [],
      skill: []
    }));
  };

  const updateFilters = (name, value) => {
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!isEmpty(filters)) {
      setIsLoading(true);
      getApplicants(jobId, filters, 1, true, 8, () => {
        setIsLoading(false);
      });
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(applicantsActions.clearApplicants());
    };
  }, []);

  const filterRejectedCandidates = () => {
    if (counterIcon === 'hidden') {
      setCounterIcon('visible');
    } else {
      setCounterIcon('hidden');
    }
  };

  const getFilterStatusCount = status => {
    let count;
    if (status === 'all') {
      if (counterIcon === 'hidden') {
        count = nonRejectedUnsavedApplicants.length;
      } else {
        count = nonSavedHiredApplicants.length;
      }
    }
    if (status === 'saved') {
      count = savedApplicants.length;
    }
    if (status === 'hired') {
      count = hiredApplicants.length;
    }
    return count;
  };

  return (
    <>
      <DisplayContext.Provider value={{ display, setDisplay }}>
        <Flex column gap={16}>
          <ApplicantsFilter
            loading={loading}
            initialFilters={filters}
            filters={applicantFilters}
            setFilters={setFilters}
            clearFilters={clearFilters}
          />
          <Flex row justifySpaceBetween>
            <BiasReducedModeSwitch checked={biasReducedMode} setChecked={switchBiasReducedMode} />
            {!loading && filters['applicant-status'].value === 'all' && job?.appliedBy.length > 0 && (
              <Flex row alignCenter style={{ color: 'rgba(230, 234, 255, 0.6)' }}>
                {getFilterStatusCount(filters['applicant-status'].value) + ' '}
                candidate{getFilterStatusCount(filters['applicant-status'].value) !== 1 && 's'}
                <Styled.StyledIcon
                  icon="separator-dot"
                  color="rgba(230, 234, 255, 0.6)"
                  marginLeft={'8px'}
                  marginRight={'8px'}
                />
                <RejectedCount>{rejectedApplicants.length} rejected</RejectedCount>
                <ContainedButton
                  id={'counter-icon'}
                  icon={counterIcon}
                  className="transparent"
                  tooltipId={'showHideRejected'}
                  tooltip={counterIcon === 'hidden' ? 'Show rejected' : 'Hide rejected'}
                  onClick={filterRejectedCandidates}
                />
              </Flex>
            )}
            {getFilterStatusCount(filters['applicant-status'].value) &&
            !loading &&
            (filters['applicant-status'].value === 'saved' || filters['applicant-status'].value === 'hired') ? (
              <Flex row>
                {getFilterStatusCount(filters['applicant-status'].value) + ' '}
                {filters['applicant-status'].value === 'saved' && 'shortlisted'}
                {filters['applicant-status'].value === 'hired' && 'offered'}
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
          {
            <InfiniteScroll
              loadMore={() => !loading && getApplicants(jobId, filters, page)}
              loader={<Loader key={0} />}
              hasMore={hasMore || loading}
              useWindow
            >
              <ApplicantsList
                job={job}
                jobId={jobId}
                jobTitle={jobTitle}
                applicants={applicants}
                status={filters['applicant-status'].value}
                loading={loading}
                hasMore={hasMore}
                biasReducedMode={biasReducedMode}
                countFilter={counterIcon}
              />
            </InfiniteScroll>
          }
        </Flex>
      </DisplayContext.Provider>

      <SelectCandidateModal applicants={applicants} filters={filters} updateFilters={updateFilters} />
      <ApproveCandidateModal />
      <ApplicantsFilterModal />
      <ApplicantCardModal />
      <CompanyContractModal />
      <AdminContractModal />
    </>
  );
};

export default Applicants;
