import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal';
import styled from '@emotion/styled';
import ContainedButton from '../../common/ContainedButton';
import Icon from '../../common/Icon';

const InviteSuccess = styled.div`
  color: #01f501;
  display: flex;
`;

const InviteSuccessText = styled.div`
  width: 95%;
`;

const StyledIcon = styled(Icon)`
  width: 5%;
  margin-right: 10px;
`;

const UserInvitedSuccess = ({ closePortal, name }) => (
  <TxplModal
    size="small"
    title="Add company member"
    name={name}
    appElement={document.getElementById('root-modal')}
    renderFooter={
      <ContainedButton backgroundColor="primary" onClick={closePortal}>
        Done
      </ContainedButton>
    }
  >
    <InviteSuccess>
      <StyledIcon icon="checkmark" size="16px" color="#01F501" />
      <InviteSuccessText>
        The invite has been sent. A new member needs to accept the invite tobe added to the company
      </InviteSuccessText>
    </InviteSuccess>
  </TxplModal>
);

export default withRenderPortal('user-invited-success')(UserInvitedSuccess);
