import React from 'react';
import styled from '@emotion/styled';

import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Tooltip from 'components/Tooltip';

export const Wrapper = styled.span`
  ${commonStyles.ui_text_medium}
  color: rgba(242, 244, 255, 0.9);
  display: inline-block;
  height: 26px;
  min-width: 40px;
  font-weight: 400;
  padding: 0 6px;
  margin: 0 6px 6px 0;
  text-align: center;
  border: 1px solid rgba(221, 226, 255, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${props => (props.hasDragPermission ? 'grab' : 'unset')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '250px')};
  ${({ styledWidth }) => styledWidth && { width: 'max-content' }};
  @media ${pageSize.XS} {
    max-width: 250px;
    max-width: ${props => (props.maxWidthXs ? props.maxWidthXs : '245px')};
  }
`;

const Tag = ({
  className,
  hasDragPermission = false,
  id,
  maxWidth,
  maxWidthXs,
  onClick,
  styledWidth,
  tag,
  useLimit = true
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Wrapper
      hasDragPermission={hasDragPermission}
      onClick={onClick}
      className={className}
      styledWidth={styledWidth}
      maxWidthXs={maxWidthXs}
      maxWidth={useLimit ? maxWidth : 'unset'}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {tag?.name?.length > 28 && useLimit ? (
        <>
          <span data-tip data-for={`tooltip-for-${tag?._id}`}>
            {tag?.name.substr(0, 28)}...
          </span>
          <Tooltip tooltipId={`tooltip-for-${tag?._id}`} place="top">
            {tag?.name}
          </Tooltip>
        </>
      ) : (
        tag?.name
      )}
    </Wrapper>
  );
};

export default Tag;
