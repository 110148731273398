import React from 'react';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';

import { useSelector } from 'react-redux';

import Frame from 'common/Frame';
import { profileSelectors } from 'store/profile';
import TalentMediaCoverageItem from './TalentMediaCoverageItem';
import MediaCoverageModal from './MediaCoverageModal';
import MediaCoverageDeleteModal from './MediaCoverageDeleteModal';
import ClaimedMediaCoverageModal from './ClaimedMediaCoverageModal';
import ChooseMediaCoverageModal from './ChooseMediaCoverageModal';

const MediaCoverageFrame = ({
  items = [],
  project,
  lockUnlockStatus,
  chooseFromExisting,
  onDelete,
  onChoose,
  onAdd,
  hintText,
  isEditMode = true,
  mb,
  skipDeleteConfirm,
  loading,
  notClosePortal,
  onSave,
  fromPortfolio = false,
  single
}) => {
  const mediaCoverages = useSelector(profileSelectors.selectProfileProperty('mediaCoverage'));
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const displayedMediaCoverages = items
    .map(mediaCoverage => (typeof mediaCoverage !== 'object' ? mediaCoverages[mediaCoverage] : mediaCoverage))
    .filter(Boolean);
  const editMode = size(displayedMediaCoverages) ? 'edit' : 'add';

  return (
    <>
      {(displayedMediaCoverages?.length > 0 || (hasEditPermission && (project ? lockUnlockStatus : true))) && (
        <Frame
          project={project}
          style={{ marginBottom: mb }}
          color="#26FFB1"
          text="Media coverage"
          canEdit={hasEditPermission && isEditMode}
          editMode={editMode}
          opens="choose-media-coverage-modal"
          data={{ chooseFromExisting, skipDeleteConfirm, notClosePortal, fromPortfolio }}
          noDataText={hintText || 'Did any reviews brighten your day? Share away.'}
          filled={!!displayedMediaCoverages.length}
        >
          {map(
            mediaCoverage => (
              <TalentMediaCoverageItem
                key={mediaCoverage._id || mediaCoverage.localId}
                compact
                mediaCoverage={mediaCoverage}
              />
            ),
            displayedMediaCoverages
          )}
        </Frame>
      )}

      <ChooseMediaCoverageModal
        onDelete={onDelete}
        onChoose={onChoose}
        items={items}
        loading={loading}
        onSave={onSave}
        single={single}
      />
      <MediaCoverageModal onAdd={onAdd} single={single} />
      <MediaCoverageDeleteModal onDelete={onDelete} />
      <ClaimedMediaCoverageModal />
    </>
  );
};

export default MediaCoverageFrame;
