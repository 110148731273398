import styled from '@emotion/styled';
import React from 'react';
import { ReactComponent as IconPurpleGradient } from 'images/assets/icons_16x16/purple-gradient.svg';

const StyledPurpleGradient = styled(IconPurpleGradient)`
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: -1;
`;

const PurpleGradient = () => <StyledPurpleGradient />;

export default PurpleGradient;
