import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';
import Flex from 'common/styles/Flex';
import TxplModal from 'common/TxplModal';
import FilterCheckBoxes from 'components/filters/FilterCheckBoxes';
import FilterRadioBoxes from 'components/filters/FilterRadioBoxes';
import withRenderPortal from 'hocs/withRenderPortal';
import useJobSearch from 'hooks/api/useJobSearch';
import capitalize from 'lodash/fp/capitalize';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import merge from 'lodash/fp/merge';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectMyProfileId } from 'store/profile/profileSelectors';
import decodeQuery from 'utils/decodeQuery';
import filtersQueryString from 'utils/filtersQueryString';
import isPropertyPopulated from 'utils/isPropertyPopulated';
import options from 'utils/options';
import { datePostedFields, hourlyRateFields, salaryFields } from './JobSearchFilterModalData';

const getHourlyRateAndSalaryFields = filterArr => {
  const hourlyRate = [];
  const salary = [];

  for (const it of filterArr) {
    const hourlyRateField = hourlyRateFields[it.value];
    const salaryField = salaryFields[it.value];
    if (hourlyRateField) {
      hourlyRate.push(hourlyRateField);
    }
    if (salaryField) {
      salary.push(salaryField);
    }
  }
  return { hourlyRate, salary };
};

const getDatePostedFields = filterArr => {
  const options = [];

  for (const it of filterArr) {
    options.push(datePostedFields[it]);
  }

  return options;
};

const initialState = state => ({
  discipline: state?.discipline ?? [],
  role: state?.role ?? [],
  genre: state?.genre ?? [],
  platform: state?.platform ?? [],
  tool: state?.tool ?? [],
  tag: state?.tag ?? [],
  experience: state?.experience ?? [],
  type: state?.type ?? [],
  salary: state?.salary ?? [],
  'company.type': get('company.type', state) ?? [],
  'company.size': get('company.size', state) ?? [],
  location: state?.location ?? [],
  remote: state?.remote ?? [],
  relocation: state?.relocation ?? [],
  teamRole: state?.teamRole ?? [],
  createdAt: state?.createdAt ?? {}
});

const order = [
  'discipline',
  'role',
  'genre',
  'platform',
  'tool',
  'tag',
  'experience',
  'type',
  'company.type',
  'company.size',
  'location',
  'remote',
  'relocation',
  'requiredAvailability',
  'teamRole',
  'createdAt'
];

const filterTitles = {
  discipline: 'Discipline',
  role: 'Role',
  genre: 'Genres',
  platform: 'Platforms',
  tool: 'Tools',
  tag: 'Tags',
  experience: 'Experience level',
  type: 'Job type',
  'company.type': 'Company type',
  'company.size': 'company size',
  location: 'Location',
  remote: 'Remote jobs',
  relocation: 'Relocation assistance',
  requiredAvailability: 'Required Availability',
  teamRole: 'Team role',
  createdAt: 'Date posted'
};

function JobSearchFilterModal({ closePortal, data, localFilters, name }) {
  const { clearFilters, filters, found, setFilters, setShow } = data ?? {};
  const { search } = useLocation();
  const decodedQuery = decodeQuery(search);
  const initialFilters = isPropertyPopulated(localFilters) ? localFilters : decodedQuery;

  const profileId = useSelector(selectMyProfileId);
  const [checkedFilters, setCheckedFilters] = React.useState(initialState(initialFilters));
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [searched, setSearched] = React.useState(false);

  const {
    jobsFound,
    loading: { loadingJobs },
    searchJobs
  } = useJobSearch();

  const foundEl = (
    <>show {loadingJobs ? <Icon size={13} icon="loader" color="#fff" /> : searched ? jobsFound : found} results</>
  );

  const handleCheck = (e, label) => {
    const { checked, name, value } = e.target;
    let items = checkedFilters[name];

    if (checked) {
      items.push({ value, label });
    } else {
      items = items.filter(it => it.value !== value);
    }

    const newFilters = {
      [name]: items
    };

    setCheckedFilters({
      ...checkedFilters,
      ...newFilters
    });

    searchJobs(profileId, filtersQueryString({ ...checkedFilters, ...newFilters }), 1, 'desc', { update: false });
    !searched && setSearched(true);
  };

  const handleRadio = (e, label) => {
    const { name, value } = e.target;

    const newFilters = {
      [name]: { value, label }
    };

    setCheckedFilters({
      ...checkedFilters,
      ...newFilters
    });

    searchJobs(profileId, filtersQueryString({ ...checkedFilters, ...newFilters }), 1, 'desc', { update: false });
    !searched && setSearched(true);
  };

  const handleSelect = (values, { name }) => {
    setSelectedFilters({ ...selectedFilters, [name]: values });
  };

  const handleReset = () => {
    setCheckedFilters(initialState());
    setSelectedFilters({});
    clearFilters && clearFilters();
    searchJobs(profileId, filtersQueryString({}), 1, 'desc', { update: false });
    !searched && setSearched(true);
  };

  const handleSubmit = () => {
    const modalFilters = merge(checkedFilters, selectedFilters);

    setFilters(modalFilters);
    setShow(false);
    closePortal();
  };

  return (
    <TxplModal
      title="Filter jobs"
      titleMB="24"
      addPaddingTop
      XSaddPaddingTop="14px"
      padding={'56px 0'}
      name={name}
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          <ContainedButton padding="8px 10px" mr={6} backgroundColor="secondary" onClick={handleReset}>
            Reset
          </ContainedButton>
          <ContainedButton padding="8px 10px" onClick={handleSubmit}>
            {jobsFound > 0 || !searched || loadingJobs ? foundEl : 'No matching jobs found'}
          </ContainedButton>
        </>
      }
    >
      <Flex column gap={56}>
        {!isEmpty(filters) &&
          order.map(key => {
            if (!filters[key]?.length) {
              return null;
            } else {
              const title = filterTitles[key] ?? capitalize(key);

              const { hourlyRate, salary } = getHourlyRateAndSalaryFields(options(filters?.salary) ?? []);
              const isPartTimeSelected = !!checkedFilters.type?.find(it => it.value === 'Part time');
              const isFullTimeSelected = !!checkedFilters.type?.find(it => it.value === 'Full time');

              const checkboxOptions =
                key === 'remote'
                  ? [{ label: 'Include remote-friendly jobs', value: 1 }]
                  : key === 'relocation'
                  ? [{ label: 'Include relocation assistance', value: 1 }]
                  : key === 'createdAt'
                  ? getDatePostedFields(filters[key])
                  : options(filters[key], key);

              return (
                <React.Fragment key={key}>
                  {key === 'createdAt' ? (
                    <FilterRadioBoxes
                      title={title}
                      name={key}
                      options={checkboxOptions}
                      checked={checkedFilters[key]}
                      onChange={handleRadio}
                      onSelect={handleSelect}
                    />
                  ) : (
                    <FilterCheckBoxes
                      title={title}
                      name={key}
                      options={checkboxOptions}
                      checked={checkedFilters[key]}
                      onChange={handleCheck}
                      onSelect={handleSelect}
                    />
                  )}
                  {key === 'type' && isFullTimeSelected && !!salary?.length && (
                    <FilterCheckBoxes
                      title="Salary"
                      name="salary"
                      options={salary}
                      checked={checkedFilters?.salary}
                      onChange={handleCheck}
                      onSelect={handleSelect}
                    />
                  )}
                  {key === 'type' && isPartTimeSelected && !!hourlyRate?.length && (
                    <FilterCheckBoxes
                      title="Hourly rate"
                      name="salary"
                      options={hourlyRate}
                      checked={checkedFilters?.salary}
                      onChange={handleCheck}
                      onSelect={handleSelect}
                    />
                  )}
                </React.Fragment>
              );
            }
          })}
      </Flex>
    </TxplModal>
  );
}

export default withRenderPortal('job-filter-modal')(JobSearchFilterModal);
