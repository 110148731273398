import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propEq } from 'ramda';
import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';
import Icon from 'common/Icon';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import ShareURL from 'common/ShareURL';
import createSharingUrl from 'utils/create-sharing-url';

const GalleryCardDropdown = ({ lockUnlockStatus, project, pushToProject, setCurrentProject }) => {
  const isPublished = project?.status === 'published';

  const dispatch = useDispatch();

  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);

  const [shareURLVisible, setShareURLVisible] = useState(false);

  const handleEditClick = e => {
    e.stopPropagation();
    setCurrentProject(project);
    dispatch(
      portalActions.openPortal({
        name: 'project-publish-modal',
        data: {
          projectId: project._id,
          viaTheXplace: project.viaThexplace
        }
      })
    );
  };

  const handleShareClick = e => {
    e.stopPropagation();
    setShareURLVisible(true);
  };

  const handlePublishUnpublishClick = e => {
    e.stopPropagation();
    if (project.status === 'published') {
      dispatch(
        portalActions.openPortal({
          name: 'unpublish-project-modal',
          data: {
            projectId: project._id,
            status: project.status,
            forcePublishProject: true
          }
        })
      );
    } else {
      pushToProject(true);
    }
  };

  const handleDeleteClick = e => {
    e.stopPropagation();
    dispatch(
      portalActions.openPortal({
        name: 'delete-project-modal',
        data: {
          projectId: project._id
        }
      })
    );
  };
  const isDraft = propEq('status', 'draft');
  const canShareProject = !isDraft(project) && project.vetted;

  return (
    <DropDown icon="dots" noBorder backgroundColor="#1A1B1E" disabled={!lockUnlockStatus}>
      <DropDownItem onClick={handleEditClick}>
        <Icon icon="settings" color="rgba(230, 234, 255, 0.6)" mr={16} />
        Edit project settings
      </DropDownItem>
      {!isCompanyView && (
        <DropDownItem onClick={handleShareClick} disabled={!canShareProject}>
          <Icon icon="share" color="rgba(230, 234, 255, 0.6)" mr={16} />
          Share
          {shareURLVisible && (
            <ShareURL
              styles={{ container: { top: 35, withBorder: true } }}
              visible={shareURLVisible}
              setVisible={setShareURLVisible}
              url={createSharingUrl({ type: 'project', id: project._id })}
              successText="The project link has been copied"
            />
          )}
        </DropDownItem>
      )}
      <DropDownItem onClick={handlePublishUnpublishClick}>
        <Icon icon={isPublished ? 'hidden' : 'visible'} color="rgba(230, 234, 255, 0.6)" mr={16} />
        {isPublished ? 'Unpublish' : 'Publish'}
      </DropDownItem>
      <DropDownItem onClick={handleDeleteClick} data-testid="delete-portfolio">
        <Icon icon="delete" color="rgba(230, 234, 255, 0.6)" mr={16} />
        Delete
      </DropDownItem>
    </DropDown>
  );
};

export default GalleryCardDropdown;
