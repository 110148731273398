import React from 'react';

import TxplModal from 'common/TxplModal/TxplModal';
import ContainedButton from 'common/ContainedButton';
import withRenderPortal from 'hocs/withRenderPortal';
import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import * as Styled from './StyledJobSuccessModal';

const JobSuccessModal = ({ closePortal, data, name }) => {
  const handleClick = async () => {
    if (data.onDoneClick) {
      await data.onDoneClick();
    }
    closePortal();
  };

  return (
    <TxplModal
      name={name}
      padding="56px 0 48px"
      XSpadding="56px 0 48px"
      allowLine
      size="large"
      titleMB="16"
      noPaddingTop
      hideBorder
      title="Success"
      appElement={document.getElementById('talent-modal-placeholder')}
      renderFooter={
        <ContainedButton backgroundColor="#7266EE" forceDisplayText onClick={handleClick}>
          Done
        </ContainedButton>
      }
    >
      <Styled.IconWrapper>
        <Icon icon="tick" width="51px" height="48px" color="#00FF00" noFill />
      </Styled.IconWrapper>
      <Typography
        fontFamily="Space Grotesk"
        fontStyle="normal"
        fontWeight="bold"
        fontSize="19px"
        lineHeight="26px"
        color="rgba(242, 244, 255, 0.9)"
        textAlign="center"
      >
        {data.text}
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('job-success-modal')(JobSuccessModal);
