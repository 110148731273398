import React from 'react';
import { api } from 'api';

const formatUrl = (url, params) => {
  let formattedUrl = url;

  for (const key of params) {
    formattedUrl = formattedUrl.replace(`:${key}`, params[key]);
  }

  return formattedUrl;
};

const useQuery = ({ delayQuery, initialData, onSuccess, params, queryParams, transformData, url }) => {
  const [data, setData] = React.useState(initialData);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();

  const getData = React.useCallback(async () => {
    try {
      const requestUrl = params ? formatUrl(url, params) : url;

      setLoading(true);
      const { data } = await api.get(requestUrl, { params: queryParams });

      const responseData = transformData ? transformData(data) : data;

      if (!responseData) setError('No Data');

      setData(responseData);

      if (onSuccess) onSuccess(responseData);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url, params, queryParams, transformData, setError, onSuccess]);

  React.useEffect(() => {
    if (loading || data || error || delayQuery) return;
    getData();
  }, [getData, loading, data, error, delayQuery]);

  return {
    loading,
    data,
    error,
    refetch: getData
  };
};

export default useQuery;
