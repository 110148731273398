import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import commonStyles from 'utils/common-styles';
import { portalActions } from 'store/portals';
import { backOfficeActions } from 'store/backOffice';
import LinkWithIcon from 'common/LinkWithIcon';
import { userActions, userSelectors } from 'store/user';
import useQuery from 'hooks/useQuery';
import Label from 'common/Label';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import talentService from 'api/talentService';
import { profileActions } from 'store/profile';
import { funnelStatusesEnum, reasons } from '../../constants';
import BackofficePanel from './BackofficePanel';
import ActivityStatus from './ActivityStatus';
import FunnelStatus from './FunnelStatus';
import VettingStatus from './VettingStatus';
import VettingOutcome from './VettingOutcome';
import SoloistSupplyDemandStatus from './SoloistSupplyDemandStatus';
import { collaborationsActions } from 'store/collaborations';

const Header = styled.div`
  ${commonStyles.ui_heading_2}
  margin: 32px 0;
`;
const StyledBackOfficePanel = styled(BackofficePanel)`
  width: 550px;
  ${props => props.height && `height: ${props.height}px;`}
`;
const AccountInfoWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 550px 1fr;
`;
const Spacer = styled.div`
  height: 0px;
  border: 1px solid rgba(204, 213, 255, 0.11);
  margin-top: 80px;
`;
const StyledDivFunnel = styled.div`
  word-break: break-all;
`;
const LinkButton = styled(Link)`
  align-self: flex-end;
  text-decoration: none;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Soloist = props => {
  const {
    match: {
      params: { talentId }
    }
  } = props;

  const [talent, setTalent] = useState({});

  const user = useSelector(userSelectors.selectUser);

  const loadTalent = () => {
    talentService
      .getTalent(talentId)
      .then(response => {
        setTalent(response.data.data);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  useEffect(() => {
    loadTalent();
  }, []);

  const { data: statusLog, refetch: statusRefetch } = useQuery({
    url: `/users/status-logs/${talentId}/latest`,
    transformData: data => data.data
  });

  const { data: vettingLog, refetch: vettingRefetch } = useQuery({
    url: `/users/vetting-logs/${talentId}/latest`,
    transformData: data => data.data
  });

  const { data: funnelStatus, refetch } = useQuery({
    url: `/users/funnel-status-logs/${talentId}/latest`,
    transformData: data => data.data,
    onSuccess: () => {
      loadTalent();
    }
  });

  useQuery({
    url: `/backoffice/talents/${talentId}`,
    onSuccess: data => {
      dispatch(userActions.initializeUser(get('talent.userId', data)));
      dispatch(profileActions.initializeProfiles([get('talent', data)]));
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      backOfficeActions.updateBackOfficeTitle([
        { label: 'Operations' },
        { label: 'Soloist accounts', url: '/backoffice/operations/soloists' },
        { label: '' }
      ])
    );
  }, [dispatch]);

  const openEditUserModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'backOffice-user-info-modal',
        data: {
          userId: user._id,
          talent
        }
      })
    );
  };

  useEffect(() => {
    if (talentId) {
      dispatch(profileActions.clearProfiles());
      dispatch(collaborationsActions.clearCollaborations());
      dispatch(userActions.clearUser());
    }
  }, [talentId, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'Operations' },
          { label: 'Soloist accounts', url: '/backoffice/operations/soloists' },
          { label: `${user.firstName || ''} ${user.lastName || ''}` }
        ])
      );
    }
  }, [user, dispatch]);

  const openActivityModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'activity-status-modal',
        data: {
          talentId,
          status: statusLog ? statusLog.status : talent.status,
          onSuccess: () => statusRefetch()
        }
      })
    );
  };

  const openFlagModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'flag-modal',
        data: {
          accountId: talentId,
          status: talent.isSupply,
          onSuccess: () => refetch()
        }
      })
    );
  };

  const openVettingModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'vetting-status-modal',
        data: {
          on: 'user',
          userId: talentId,
          status: vettingLog?.status,
          outcome: vettingLog?.outcome,
          additionalComment: vettingLog?.additionalComment,
          element: 'profile',
          onSuccess: () => vettingRefetch()
        }
      })
    );
  };

  const openFunnelStatusModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'funnel-status-modal',
        data: {
          on: 'user',
          userId: talentId,
          status: funnelStatus?.status,
          additionalComment: funnelStatus?.additionalComment,
          onSuccess: () => refetch()
        }
      })
    );
  };

  if (!user) return null;
  if (!talent) return null;

  return (
    <>
      <Header>Account</Header>

      <AccountInfoWrapper>
        <StyledBackOfficePanel title="Account Info" onEdit={openEditUserModal}>
          <Typography variant="medium" opacity={0.35}>
            User ID
          </Typography>
          <div>{user._id}</div>
          <Typography variant="medium" opacity={0.35}>
            First name
          </Typography>
          <div>{user.firstName}</div>
          <Typography variant="medium" opacity={0.35}>
            Last name
          </Typography>
          <div>{user.lastName}</div>
          <Typography variant="medium" opacity={0.35}>
            Email
          </Typography>
          <div>{user.email}</div>
          <Typography variant="medium" opacity={0.35}>
            Public profile
          </Typography>
          <div>{talent && talent.publicUrl && <Link to={`/talent/${talent.publicUrl}`}>/{talent.publicUrl}</Link>}</div>
        </StyledBackOfficePanel>
        <StyledBackOfficePanel title="Supply/demand flags" onEdit={openFlagModal}>
          <Typography variant="medium" opacity={0.35}>
            Current flag
          </Typography>
          <SoloistSupplyDemandStatus status={talent.isSupply} />
        </StyledBackOfficePanel>
      </AccountInfoWrapper>

      <Spacer />

      <Header>Activity</Header>

      <AccountInfoWrapper>
        <StyledBackOfficePanel title="Activity Status" onEdit={openActivityModal}>
          <Typography variant="medium" opacity={0.35}>
            Activity status
          </Typography>
          <ActivityStatus status={statusLog?.status ?? talent.status} />
          <Typography variant="medium" opacity={0.35}>
            Reason
          </Typography>{' '}
          <div>{statusLog ? reasons[statusLog.status][0].label : ''}</div>
          <Typography variant="medium" opacity={0.35}>
            Additional comments
          </Typography>{' '}
          <div>{statusLog?.additionalComment}</div>
          <div />{' '}
          <LinkWithIcon icon="info">
            <Link to={`/backoffice/operations/soloists/${talent._id}/status-log`}>Show status log ↗︎</Link>
          </LinkWithIcon>
        </StyledBackOfficePanel>
        <StyledBackOfficePanel title="Funnel Status" onEdit={openFunnelStatusModal}>
          <Label>Funnel status</Label>{' '}
          <FunnelStatus status={funnelStatusesEnum[funnelStatus?.status]} value={funnelStatus?.status} />
          <Label>Additional comments</Label> <StyledDivFunnel>{funnelStatus?.additionalComment}</StyledDivFunnel>
          <div />{' '}
          <LinkWithIcon icon="info">
            <Link to={`/backoffice/operations/soloists/${talent._id}/funnel-log`}>Show funnel log ↗︎</Link>
          </LinkWithIcon>
        </StyledBackOfficePanel>
      </AccountInfoWrapper>
      <Spacer />
      <Header>Vetting</Header>
      <AccountInfoWrapper>
        <StyledBackOfficePanel title="Vetting Status" onEdit={openVettingModal}>
          <Typography variant="medium" opacity={0.35}>
            Vetting status
          </Typography>{' '}
          <VettingStatus status={vettingLog?.status} />
          <Typography variant="medium" opacity={0.35}>
            Vetting outcome
          </Typography>{' '}
          <VettingOutcome status={vettingLog?.outcome} />
          <Typography variant="medium" opacity={0.35}>
            Additional comments
          </Typography>{' '}
          <div>{vettingLog?.additionalComment}</div>
          <div style={{ paddingTop: '110px' }} />{' '}
          <LinkWithIcon pt="110px" icon="info">
            <Link>Show vetting log ↗︎</Link>
          </LinkWithIcon>
        </StyledBackOfficePanel>
        <Wrapper>
          <StyledBackOfficePanel
            title="Vetting Inputs"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            marginBottom="16"
            height="200"
          >
            <Typography
              variant="medium"
              color="rgba(230, 234, 255, 0.35)"
              fontFamily="Space Grotesk;"
              fontSize="13px"
              lineHeight="16px"
            >
              Click EDIT VETTING INPUTS, generate an outcome and change the vetting status manually.
            </Typography>
            <LinkButton to={`/backoffice/operations/soloists/${talent._id}/vetting-inputs`}>
              <ContainedButton>edit vetting inputs</ContainedButton>
            </LinkButton>
          </StyledBackOfficePanel>

          <StyledBackOfficePanel
            title="Lock/Unlock profile inputs"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            marginBottom="16"
            height="200"
          >
            <Typography
              variant="medium"
              color="rgba(230, 234, 255, 0.35)"
              fontFamily="Space Grotesk;"
              fontSize="13px"
              lineHeight="16px"
            >
              Click EDIT USER INPUTS to lock/unlock certain inputs on the user’s profile. Inputs would be unlock only
              for 24 hours.
            </Typography>
            <LinkButton to={`/backoffice/operations/soloists/${talent._id}/lock-page`}>
              <ContainedButton>lock/unlock inputs</ContainedButton>
            </LinkButton>
          </StyledBackOfficePanel>
        </Wrapper>
      </AccountInfoWrapper>
    </>
  );
};

export default Soloist;
