import { useCallback, useState } from 'react';
import { talentService } from 'api';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';

const initialLoading = {
  loadingTalents: false
};

const useTalentSearch = () => {
  const [loading, setLoading] = useState(initialLoading);

  const [talents, setTalents] = useState([]);
  const [talentsFilters, setFilters] = useState({});
  const [relatedSearch, setRelatedSearch] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const { openErrorSnackbar } = useErrorSnackbar();

  const searchTalents = useCallback(
    async (talentId, query = '', page = 1, clear = false, callback, limit = 8) => {
      if (!loading.loadingTalents) {
        try {
          setLoading({ ...loading, loadingTalents: true });
          const {
            data: { data, filters, pagination, recommendedRoles }
          } = await talentService.search(talentId, query, page, limit);

          setTalents(talents => (clear ? data : [...talents, ...data]));
          setFilters(filters);

          setRelatedSearch(recommendedRoles);

          const talentsLength = clear ? 0 : talents.length;

          setHasMore(talentsLength + limit < pagination.total);
          setTotal(pagination.total);
          setPage(page + 1);
        } catch {
          openErrorSnackbar('Oops! something went wrong');
          setHasMore(false);
        } finally {
          setLoading({ ...loading, loadingTalents: false });
          callback && callback();
        }
      }
    },
    [loading, talents.length, openErrorSnackbar]
  );

  return {
    loading,
    talents,
    talentsFilters,
    hasMoreTalents: hasMore,
    talentsPage: page,
    talentsFound: total,
    relatedSearch,
    searchTalents
  };
};

export default useTalentSearch;
