import { combineReducers } from 'redux';

import ListsReducer from './reducers/listsReducer';
import companiesReducer from './reducers/companiesReducer';
import { apiCallsReducer } from './apiCall';
import { tagsReducer } from './tags';
import { currentUserReducer } from './auth';
import { portalsReducer } from './portals';
import { notificationReducer } from './notifications';
import { backOfficeReducer } from './backOffice';
import { talentReducer } from './talent';
import { profileReducer, profilesUIReducer } from './profile';
import { userReducer } from './user';
import { audioReducer } from './audio';
import { industryReducer } from './industry';
import { jobsReducer } from './jobs';
import { applicantsReducer } from './applicants';
import { collaborationsReducer } from './collaborations';

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  lists: ListsReducer,
  tags: tagsReducer,
  industry: industryReducer,
  audio: audioReducer,
  companies: companiesReducer,
  backOffice: backOfficeReducer,
  talent: talentReducer,
  profiles: profileReducer,
  jobs: jobsReducer,
  applicants: applicantsReducer,
  notifications: notificationReducer,
  user: userReducer,
  collaborations: collaborationsReducer,
  ui: combineReducers({
    portals: portalsReducer,
    profiles: profilesUIReducer,
    apiCalls: apiCallsReducer
  })
});

export default rootReducer;
