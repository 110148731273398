import React from 'react';

import Icon from 'common/Icon';
import Typography from 'components/shared/Typography';
import * as Styled from './NotificationsStyles';

const NotificationsEmpty = () => (
  <Styled.EmptyStateContainer>
    <Icon icon="bell" size={64} />
    <Typography
      fontfamily="Space Grotesk"
      fontStyle="normal"
      fontWeight="normal"
      fontSize="16px"
      lineHeight="22px"
      mt="24"
      color="rgba(230, 234, 255, 0.35)"
      transform="none"
    >
      {"You're all caught up."}
    </Typography>
  </Styled.EmptyStateContainer>
);

export default NotificationsEmpty;
