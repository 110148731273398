export const LINKEDIN_STATE = 'random_string';
const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress';
const LINKEDIN_RIDERECT = `${window.location.origin}/auth/linkedin`;
const LINKEDIN_CLIENT_ID = '86jcenu4gyg5dp';

const query = new URLSearchParams({
  response_type: 'code',
  client_id: LINKEDIN_CLIENT_ID,
  redirect_uri: LINKEDIN_RIDERECT,
  state: LINKEDIN_STATE,
  scope: LINKEDIN_SCOPE
});

export const LINKEDIN_URL = `https://www.linkedin.com/oauth/v2/authorization?${query}`;
