import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { backOfficeActions } from 'store/backOffice';
import FormInput from 'common/form/FormInput';
import useForm from 'hooks/useForm';
import FormSelect from 'common/form/FormSelect';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import { backOfficeService } from 'api';
import useCheckPublicUrl from 'hooks/api/useCheckPublicUrl';
import commonStyles from 'utils/common-styles';

const StyledGridContainer = styled.div`
  display: grid;
  grid-row-gap: 40px;
  margin-top: 32px;
  max-width: 550px;
`;

const GridWrapper = styled.div`
  display: grid;
  position: relative;
`;

const StyledOrigin = styled.span`
  position: absolute;
  z-index: 100;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-family: Space Grotesk;
  font-size: 16px;
  line-height: 22px;
  color: rgba(230, 234, 255, 0.35);
`;

const StyledFormInput = styled(FormInput)`
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.width}px;
`;

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  justify-content: end;
`;

const ErrorMessage = styled.div`
  ${commonStyles.ui_error_text}
`;

const options = [
  { label: 'Supply', value: 'supply' },
  { label: 'Demand', value: 'demand' },
  { label: 'Both', value: 'both' }
];

const fields = {
  owner: { initialValue: '', validate: ['isRequired'] },
  publicUrl: { initialValue: '', validate: [] },
  supplyDemand: { initialValue: 'both', validate: [] }
};

const BackofficeCreateSoloist = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const prefixRef = React.useRef();

  React.useEffect(() => {
    dispatch(
      backOfficeActions.updateBackOfficeTitle([
        { label: 'Operations' },
        { url: '/backoffice/operations/soloists', label: 'Soloist Accounts' },
        { label: 'Create Soloist account' }
      ])
    );
  }, [dispatch]);

  const { errors, onFieldChange, onSubmit, submitting, triggerErrors, values } = useForm({
    fields,
    callApi: params => backOfficeService.createSoloist(params),
    onSubmitSuccess: () => history.push('/backoffice/operations/soloists')
  });

  const { existsMessage } = useCheckPublicUrl({ publicUrl: values.publicUrl });

  const handleSubmit = () => {
    const { success } = triggerErrors()();
    if (existsMessage || !success) {
      return;
    }
    onSubmit();
  };

  return (
    <StyledGridContainer>
      <FormInput
        label="A user to link this account with"
        hintText="Enter the ID of a user that will own this company account"
        value={values.owner}
        onChange={onFieldChange('owner')}
        error={errors.owner || errors?.message === 'USER_NOT_FOUND' ? 'User ID is not found' : ''}
      />

      <GridWrapper>
        <StyledFormInput
          value={values.publicUrl}
          width={prefixRef.current?.getBoundingClientRect().width + 17}
          hintText="Specify a unique url address for this company account"
          onChange={onFieldChange('publicUrl')}
          label="Account url"
          error={errors.publicUrl ?? existsMessage ?? ''}
        >
          <StyledOrigin ref={prefixRef} error={errors.publicUrl}>
            {window.location.origin}/talent/
          </StyledOrigin>
        </StyledFormInput>
      </GridWrapper>

      <FormSelect
        label="Supply/demand flags"
        options={options}
        value={options.find(curr => curr.value === values.supplyDemand)}
        onChange={option => onFieldChange('supplyDemand')(option.value)}
      />
      {errors?.response?.data?.message && <ErrorMessage>{errors.response.data.message}</ErrorMessage>}
      <StyledButtonsContainer>
        {submitting && <Spinner />}
        <ContainedButton onClick={handleSubmit}>Create Soloist account</ContainedButton>
      </StyledButtonsContainer>
    </StyledGridContainer>
  );
};

export default BackofficeCreateSoloist;
