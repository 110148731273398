import styled from '@emotion/styled';

export const Wrapper = styled.div`
  & iframe {
    margin-top: 2rem;
    border: none;
  }

  & .loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
