import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { fromJS } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { getFormSelectTypeArray, getFormSelectValue } from 'utils/tagHelpers';
import useTagService from 'hooks/api/useTagService';
import { tagActions, tagSelectors } from 'store/tags';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import ContainedButton from 'common/ContainedButton';
import FormDateInput from 'common/form/FormDateInput';
import FormCheckbox from 'common/form/FormCheckbox';
import TxplModal from 'common/TxplModal';
import { pageSize } from 'utils/devices';
import CompanySearch from 'components/CompanySearch';
import FormSelect from 'common/form/FormSelect';
import { tagService } from 'api';
import TalentExperienceContext from './TalentExperienceContext';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';

const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;
const StyledInputsContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 56px;
`;
const StyledDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  & input {
    width: 150px;
  }
  @media ${pageSize.XS} {
    flex-direction: column;
    & input {
      width: 88%;
    }
  }
`;
const StyledFormCheckbox = styled(FormCheckbox)`
  margin-top: -39px;
`;

const fields = ({ companyAndRole }, defaultIndustry) => {
  let initialIndustryType = defaultIndustry;

  if (companyAndRole.industryType) {
    if (companyAndRole.industryType._id) {
      initialIndustryType = getFormSelectValue(companyAndRole.industryType);
    } else {
      initialIndustryType = companyAndRole.industryType;
    }
  }

  return {
    company: { initialValue: companyAndRole.company || '', validate: ['isRequired'] },
    role: { initialValue: getFormSelectValue(companyAndRole, 'role', 'role') || '', validate: ['isRequired'] },
    startDate: { initialValue: companyAndRole.startDate || '', validate: ['isRequired', 'isDate', 'isDateBefore'] },
    endDate: { initialValue: companyAndRole.endDate || '', validate: ['isDate'] },
    currentPosition: { initialValue: !!companyAndRole.currentPosition },
    industryType: { initialValue: initialIndustryType, validate: ['isRequired'] },
    viaThexplace: { initialValue: companyAndRole?.viaThexplace || false}
  };
};

const onValidate = formFields => {
  const errors = {};
  const currentPosition = formFields.getIn(['currentPosition', 'value']);
  const startDate = formFields.getIn(['startDate', 'value']);
  const endDate = formFields.getIn(['endDate', 'value']);
  if (!currentPosition && !endDate) {
    errors.endDate = 'End date is required';
  }

  if (!currentPosition && endDate && endDate <= startDate) {
    errors.endDate = 'End date can not be before or equal to start date';
  }

  if (startDate >= Date.now()) {
    errors.startDate = 'Start date can not be in future';
  }

  if (!currentPosition && endDate >= Date.now()) {
    errors.endDate = 'End date can not be in future';
  }

  return errors;
};

const CompanyAndRoleModal = ({ closePortal, name }) => {
  const { openErrorSnackbar } = useErrorSnackbar();
  const dispatch = useDispatch();

  const { getTags } = useTagService();

  const tagRoles = useSelector(tagSelectors.selectTagsByType('roles'));

  const { onFieldChange: onFormFieldChange, values: formValues } = useContext(TalentExperienceContext);
  const [roles, setRoles] = useState({
    options: getFormSelectTypeArray({ arr: tagRoles.data }),
    value: null
  });
  const industrytypes = useSelector(state => state.industry.data);

  const videoGames = industrytypes.find(item => item.name === 'Video Games');
  const industryTypeInitialValue = { label: videoGames.name, values: videoGames._id };

  const { errors, onFieldChange, onSubmit, values } = useForm({
    doNotScroll: true,
    fields: fields(formValues, industryTypeInitialValue),
    onValidate,
    onSubmitSuccess: closePortal,
    callApi: params => {
      onFormFieldChange('companyAndRole')(
        fromJS({
          company: params.company,
          role: params.role.label,
          startDate: params.startDate,
          currentPosition: params.currentPosition,
          industryType: params.industryType,
          endDate: params.currentPosition ? null : params.endDate,
          viaThexplace: params?.viaThexplace
        })
      );

      return Promise.resolve(params);
    }
  });

  const handleCreate = async inputVal => {
    try {
      const {
        data: { data }
      } = await tagService.requestTag({ name: inputVal, type: 'role' });
      const tag = { value: data._id, label: data.name };

      dispatch(tagActions.addTag('roles', data));
      onFieldChange('role')(tag);
    } catch {
      openErrorSnackbar('Oops! Something went wrong');
    }
  };

  const handleRolesOptionsOpen = () => {
    const fetchTags = async () => {
      await getTags('role');
    };

    if (!tagRoles.isLoaded) {
      fetchTags();
    }
  };

  useEffect(() => {
    const rolesOptions = tagRoles.data.map(discipline => ({ value: discipline._id, label: discipline.name }));
    setRoles(currentRoles => ({ value: currentRoles.value, options: rolesOptions }));
  }, [tagRoles]);

  return (
    <TxplModal
      title="Company and role"
      appElement={document.getElementById('root-modal')}
      name={name}
      addPaddingTop
      XSaddPaddingTop
      allowLine
      padding="56px 0px 83px 0"
      XSpadding="56px 0px 83px 0"
      renderFooter={
        <StyledButtonsContainer>
          <ContainedButton onClick={closePortal} backgroundColor="secondary">
            Cancel
          </ContainedButton>

          <ContainedButton form="companyAndRoleModal" type="submit">
            Save
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <StyledInputsContainer onSubmit={onSubmit} id="companyAndRoleModal">
        <CompanySearch
          label="Company"
          value={values.company.name}
          onChange={onFieldChange('company')}
          error={errors.company}
          required
          autoFocus
          className="company-required"
        />
        <FormSelect
          label="Role"
          isCreatable
          required
          handleCreate={handleCreate}
          error={errors.role}
          value={values.role}
          options={roles.options}
          onChange={option => onFieldChange('role')(option)}
          onMenuOpen={handleRolesOptionsOpen}
          className="role-required"
          isLoading={tagRoles.loading}
        />
        <FormSelect
          required
          label="Type of industry"
          error={errors.industryType}
          value={values.industryType}
          options={industrytypes.map(item => ({ label: item.name, value: item._id }))}
          onChange={event => onFieldChange('industryType')(event)}
          hintText="Select the type of industry"
          className="-required"
        />
        <StyledDatesContainer>
          <FormDateInput
            value={values.startDate}
            onChange={onFieldChange('startDate')}
            label="Started"
            error={errors.startDate}
            placeholder="MM/DD/YYYY"
            required
            className="startDate-required"
          />
          {!values.currentPosition && (
            <FormDateInput
              value={values.endDate}
              onChange={onFieldChange('endDate')}
              label="Ended"
              error={errors.endDate}
              minDate={values.startDate}
              placeholder="MM/DD/YYYY"
              required
              className="endDate-required"
            />
          )}
        </StyledDatesContainer>
        <StyledFormCheckbox
          checked={values.currentPosition}
          textColor
          onChange={event => onFieldChange('currentPosition')(event.target.checked)}
          text="I am currently working here"
        />

        <StyledFormCheckbox
          checked={values.viaThexplace}
          textColor
          onChange={event => onFieldChange('viaThexplace')(event.target.checked)}
          text="TheXPlace helped me find this opportunity"
        />
      </StyledInputsContainer>
    </TxplModal>
  );
};

export default withRenderPortal('company-and-role-modal')(CompanyAndRoleModal);
