import ContainedButton from 'common/ContainedButton';
import styled from '@emotion/styled';
import FormInput from 'common/form/FormInput';

export const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const StyledShareButton = styled(ContainedButton)`
  width: 100%;
  height: 40px;
  justify-content: center;
`;
export const StyledFormInput = styled(FormInput)`
  position: relative;
  padding-right: 75px;
`;

export const StyledCopyButton = styled(ContainedButton)`
  position: absolute;
  top: 6px;
  right: 6px;
`;
