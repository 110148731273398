import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { pageSize } from 'utils/devices';
import EditButton from '../../../../common/EditButton';

export const ModalContent = styled.div`
  margin-top: 40px;
`;

export const SelectFromProfileButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: white;
  text-transform: uppercase;
  font-family: Space Grotesk;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 32px;
  ${props => props.disabled && 'opacity: .5; pointer-events: none'}
`;

export const ThumbnailTabsWrapper = styled.ul`
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(204, 213, 255, 0.2);
  border-radius: 8px;
`;

export const ThumbnailTab = styled.li`
  padding: 6px 10px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(230, 234, 255, 0.6);
  cursor: pointer;
  position: relative;

  &:hover {
    color: white;
  }

  &.active {
    color: white;
    background: rgba(204, 213, 255, 0.11);

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: -2px;
      border-top: 2px solid rgba(204, 213, 255, 0.11);
      border-bottom: 2px solid rgba(204, 213, 255, 0.11);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.active.active-left {
    &:after {
      left: -2px;
      border-left: 2px solid rgba(204, 213, 255, 0.11);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  &.active.active-right {
    &:after {
      right: -2px;
      border-right: 2px solid rgba(204, 213, 255, 0.11);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export const ImagesGrid = styled.div`
  margin-top: 34px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 189px);
  grid-gap: 16px;
`;

export const ImageWrapper = styled.div`
  width: 189px;
  height: 106px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background: rgba(204, 213, 255, 0.11);
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover,
  &.selected {
    border-color: #7266ee;
  }
`;

export const ThumbnailContainer = styled.div`
  //width: 100%;
  //height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;

  /* Black/Black */
  background: #111112;
  /* White/White 11% */
  border: ${p => (p.title ? '2px solid rgba(204, 213, 255, 0.11)' : '2px dashed rgba(204, 213, 255, 0.11)')};
  border-radius: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 16.34%, rgba(0, 0, 0, 0) 29.58%);
  }
`;

export const ThumbnailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  height: 250px;
  position: relative;
  flex: 0 0 50%;

  @media ${pageSize.XS} {
    flex: 0 0 100%;
    order: 2;
    height: 204px;
  }
`;

export const ThumbnailContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  top: 24px;
  left: 24px;
  line-height: 40px;
  font-size: 36px;
  //word-break: break-word;
  //overflow-wrap: break-word;
  //text-overflow: ellipsis;
  //-webkit-line-clamp: 3;
`;

export const ThumbnailCoverContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  height: 250px;
  flex: 0 0 50%;

  @media ${pageSize.XS} {
    flex: 0 0 100%;
    order: 1;
    height: 200px;
  }
`;

export const StyledEditTitleButton = styled(EditButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  gap: 8px;

  position: absolute;
  width: 36px;
  height: 32px;
  right: 10px;
  top: 10px;

  ${props => props.dark && 'background: #111112'}
`;

export const JobTitle = styled.span`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: ${p => (p.len > 30 ? '26px' : '36px')};
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  left: 24px;
  top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  width: 10em;

  @media ${pageSize.M}, ${pageSize.S}, ${pageSize.XS} {
    font-size: ${p => (p.len > 30 ? '24px' : '26px')};
  }
`;

export const JobTitleEmpty = styled.div`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  left: 24px;
  top: 24px;
  line-height: 40px;
  font-size: 36px;
  text-transform: uppercase;

  color: rgba(221, 226, 255, 0.2);
`;

export const CompanyIdentifier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  position: relative;
  bottom: 32px;
  left: 24px;
`;

export const CompanyLogo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const CompanyTitle = styled(Link)`
  margin: 0;

  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: none;
  width: 14em;
  &:hover {
    text-decoration-line: underline;
  }

  @media ${pageSize.M}, ${pageSize.S} {
    width: 10em;
  }
`;
export const CompanyLogoWrapper = styled(Link)`
  position: relative;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
`;

export const ImageOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  z-index: 20;
  &:hover {
    opacity: 0.7;
  }
`;
