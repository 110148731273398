import * as profileActionTypes from './profileActionTypes';

const profilesUIReducer = (state = {}, action) => {
  switch (action.type) {
    case profileActionTypes.SET_ACTIVE_PROFILE_ID: {
      return { ...state, _id: action.payload.profileId };
    }

    default:
      return state;
  }
};

export default profilesUIReducer;
