import _ from 'lodash';

export const isJobRoleEmpty = role => {
  const { bonus, youHave, youWill, ...roleData } = role;

  // * This should be changed - needs migration in job collection
  if (youWill !== `""` || youHave !== `""` || bonus !== `""`) {
    return false;
  }

  if (!_.values(roleData).every(_.isEmpty)) {
    return false;
  }

  return true;
};
