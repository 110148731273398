import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import { pageSize } from 'utils/devices';

export const CollaborationsList = styled.div`
  padding: 8px 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  background: #111112;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 16px;
  &.empty {
    height: 365px;
  }
`;
export const CollaborationsSeeMoreButton = styled(ContainedButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 816px;
  width: 100%;
  height: 40px;
  background: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  margin: 24px 0px;
  align-self: center;
`;
export const CollaborationsIconContainer = styled.div`
  width: fit-content;
  height: fit-content;
  align-self: center;
`;
export const CollaborationsText = styled.span`
  width: fit-content;
  height: 22px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  align-self: center;
  margin-top: 24px;
`;

export const CollaborationListHeader = styled.div`
  width: 100%;
  border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  padding: 16px 0 24px;
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  @media ${pageSize.XS} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const CollabTitle = styled.div`
  font-weight: bold;
`;

export const AllCollabsButton = styled(ContainedButton)`
  @media ${pageSize.XS} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
