import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userService from '../../api/userService';
import ContainedButton from '../../common/ContainedButton';
import FormInput from '../../common/form/FormInput';
import FormTextarea from '../../common/form/FormTextarea';
import Spinner from '../../common/Spinner';
import { portalActions } from '../../store/portals';
import { currentUserEmailSelector, currentUserNameSelector } from '../../store/selectors/currentUser';

const HelpModal = ({ closePortal }) => {
  const dispatch = useDispatch();

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: {
      fullName: { initialValue: useSelector(currentUserNameSelector), validate: ['isRequired'], trim: true },
      email: { initialValue: useSelector(currentUserEmailSelector), validate: ['isRequired', 'isEmail'] },
      message: { initialValue: '', validate: ['isRequired'], trim: true }
    },
    callApi: async formFields => userService.contactSupport(formFields),
    onSuccess: () => {
      closePortal();
      dispatch(
        portalActions.openPortal({
          name: 'contact-support-sent'
        })
      );
    }
  });

  return (
    <TxplModal
      title="Contact Support"
      name="help-modal"
      appElement={document.getElementById('root-modal')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <ContainedButton mr="6" backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton form="helpModal" type="submit" backgroundColor="primary">
            Send
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={onSubmit} id="helpModal">
        <FormInput
          mb={40}
          value={values.fullName}
          error={errors.fullName}
          onChange={e => onFieldChange('fullName')(e.target.value)}
          label="Your full name"
          required
        />

        <FormInput
          mb={40}
          value={values.email}
          error={errors.email}
          onChange={e => onFieldChange('email')(e.target.value)}
          label="Your email address"
          required
        />

        <FormTextarea
          value={values.message}
          error={errors.message}
          onChange={e => onFieldChange('message')(e.target.value)}
          label="Your message"
          hintText="Please explain your issue and we will connect with you shortly to fix it"
          required
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('help-modal')(HelpModal);
