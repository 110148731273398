import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';
import { currentUserHasFFSelector } from 'store/selectors/currentUser';
import useProfilePath from 'hooks/useProfilePath';
import useProfileSwitcher from 'hooks/useProfileSwitcher';

import styled from '@emotion/styled';
import HeaderMobileDropdownItem from './HeaderMobileDropdownItem';
import { StyledHeaderDropdownItems } from './HeaderMobileStyles';
import MobileSearch from './MobileSearch';
import { getDecodedIdToken } from 'utils/cookies';

const HeaderMobileAuthorized = ({ icon, onChange, onClick, value }) => {
  const profiles = useSelector(profileSelectors.selectProfileItems);
  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);
  const profileId = useSelector(profileSelectors.selectActiveProfileId);

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { switchProfile } = useProfileSwitcher();
  const { getProfilePath } = useProfilePath();

  const talentPath = isCompanyView ? 'company' : 'talent';
  const conversationUrl = `${talentPath}/${profileId}/conversations`;
  const jobsUrl = `${talentPath}/${profileId}/all-jobs`;

  const userHasWalletEnabled = useSelector(currentUserHasFFSelector('hasWalletEnabled'));
  const displayWallet = userHasWalletEnabled && !isCompanyView;
  const userHasContractEnabled = useSelector(currentUserHasFFSelector('hasContractEnabled'));
  const displayContracts = userHasContractEnabled && !isCompanyView;

  const decoded = getDecodedIdToken();

  const currentUserIsAnalyst = decoded?.roles.includes('analyst');
  const isSuperAdmin = decoded?.roles.includes('super-admin');
  const shouldShowBackOffice = currentUserIsAnalyst || isSuperAdmin;

  const ItemsWrapper = styled.div`
    border-top: 1px solid rgba(191, 202, 255, 0.1);
  `;

  const handleSwitch = profile => e => {
    switchProfile(profile, () => {
      const isCompany = profile.type === 'company';
      history.push(`${getProfilePath(profile.publicUrl, isCompany)}`);
      onClick(e);
    });
  };

  const handleRedirect = path => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/${path}`);
  };

  const helpPopup = () => {
    dispatch(
      portalActions.openPortal({
        name: 'help-modal'
      })
    );
  };

  return (
    <StyledHeaderDropdownItems style={{ padding: '9px 16px' }}>
      {!showMobileSearch && (
        <>
          <HeaderMobileDropdownItem text="Search" icon="search" onClick={() => setShowMobileSearch(true)} />
          {profiles.length > 1 && (
            <ItemsWrapper>
              {profiles.map(profile => (
                <HeaderMobileDropdownItem
                  key={profile._id}
                  avatar={profile.avatar || `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`}
                  text={profile.name}
                  onClick={handleSwitch(profile)}
                />
              ))}
            </ItemsWrapper>
          )}

          <ItemsWrapper>
            <HeaderMobileDropdownItem text="Jobs" icon="jobs" to={`/${jobsUrl}`} onClick={onClick} />
            {displayContracts && (
              <HeaderMobileDropdownItem
                text="Contracts"
                icon="crossed-hammer"
                onClick={() => handleRedirect('contracts')}
              />
            )}
            <HeaderMobileDropdownItem text="Events" icon="events" onClick={() => handleRedirect('events')} />
            {displayWallet && (
              <HeaderMobileDropdownItem text="Wallet" icon="wallet" onClick={() => handleRedirect('wallet')} />
            )}
            <HeaderMobileDropdownItem text="Messages" icon="message" onClick={onClick} to={`/${conversationUrl}`} />
            <HeaderMobileDropdownItem text="View Profile" icon="profile" onClick={onClick} to="/profile" />
            <HeaderMobileDropdownItem text="Settings" icon="settings" onClick={onClick} to="/settings" />
            <HeaderMobileDropdownItem text="Help" icon="question" onClick={() => helpPopup()} />
            {shouldShowBackOffice && (
              <HeaderMobileDropdownItem text="Back office" icon="backoffice" onClick={onClick} to="/backoffice" />
            )}
            <HeaderMobileDropdownItem icon="logout" onClick={onClick} text="Log out" to="/logout" />
          </ItemsWrapper>
        </>
      )}
      <MobileSearch
        style={{ width: '85%', 'border-radius': '8px' }}
        value={value}
        icon={icon}
        onChange={onChange}
        showMobileSearch={showMobileSearch}
        setShowMobileSearch={setShowMobileSearch}
        onClick={onClick}
      />
    </StyledHeaderDropdownItems>
  );
};

export default HeaderMobileAuthorized;
