import React from 'react';
import withRenderPortal from 'hocs/withRenderPortal';
import FormInput from 'common/form/FormInput';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import useForm from 'hooks/useForm';

const ProjectNameModal = ({ closePortal, data, loading, name, onSave }) => {
  const { errors, onFieldChange, onSubmit, values } = useForm({
    fields: {
      name: {
        initialValue: data.name ?? '',
        validate: ['isRequired'],
        trim: true
      }
    },
    callApi: async fields => onSave(fields.name),
    closePortal
  });

  return (
    <TxplModal
      size="large"
      padding="0 0 58px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      addPaddingTop
      XSaddPaddingTop
      renderFooter={
        <>
          {loading && <Spinner />}
          <ContainedButton
            mr={6}
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            forceDisplayText
            disabled={loading}
          >
            Cancel
          </ContainedButton>
          <ContainedButton
            form="projectNameModal"
            type="submit"
            backgroundColor="#7266EE"
            forceDisplayText
            disabled={loading}
          >
            Save
          </ContainedButton>
        </>
      }
    >
      <form onSubmit={onSubmit} id="projectNameModal">
        <FormInput
          value={values.name}
          onChange={e => onFieldChange('name')(e.target.value)}
          error={errors.name}
          label="Project name"
          maxLength={50}
          placeholder="e.g. Rich library of sounds"
          hintText="What makes this project stand out? Give it a good ol’ Hollywood headline."
          required
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('project-name-modal')(ProjectNameModal);
