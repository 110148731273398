import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { userService } from 'api';
import useForm from 'hooks/useForm';
import Icon from 'common/Icon';
import Spinner from 'common/Spinner';
import FormInput from 'common/form/FormInput';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import ForgotPassword from 'pages/splash-page/ForgotPassword';
import * as Styled from 'pages/splash-page/StyledSplashForm';

const fields = {
  password: { initialValue: '', validate: ['isRequired', 'strongPassword'] },
  passwordAgain: { initialValue: '', validate: ['typeItAgain', 'confirmPassword'], dependOn: 'password' }
};

const passRequirements = [
  { title: '8 or more characters', key: 'length' },
  { title: 'Upper and lowercase letters', key: 'upperLower' },
  { title: 'At least one number', key: 'number' }
];

const initErrors = { length: '', upperLower: '', number: '' };

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const token = params.get('token');

  const [showPass, setShowPass] = useState(false);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(initErrors);
  const [layout, setLayout] = useState('resetPassword');

  const onValidate = () => {
    const errorsObj = {};

    if (pass.length < 8) errorsObj.length = 'length';
    if (!hasBothCases()) errorsObj.upperLower = 'upperLower';
    if (!hasNumber()) errorsObj.number = 'number';

    setError(errorsObj);
  };
  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: fields,
    callApi: params => userService.resetPassword({ email, token, ...params }),
    onSuccess: () => setLayout('resetPasswordSuccess')
  });

  useEffect(() => {
    onValidate();
  }, [values.password]);

  useEffect(() => {
    if (errors && errors.message === 'RESET_PASSWORD_EXPIRED') {
      setLayout('resetPasswordExpire');
    }
  }, [errors]);

  const pass = values.password;
  const handleBackTo = () => history.push('/login');
  const handleShowPass = () => setShowPass(!showPass);
  const hasNumber = () => /\d/.test(pass);
  const hasBothCases = () => /[A-Z]/.test(pass) && /[a-z]/.test(pass);
  const handleColorChange = item => (touched ? (error[item.key] ? '#FF5151' : '#00FF00') : 'rgba(230, 234, 255, 0.6)');

  const renderTitle = () => {
    let title = 'Enter new password';

    switch (layout) {
      case 'resetPassword':
        title = 'Enter new password';
        break;
      case 'resetPasswordSuccess':
        title = 'Successful password reset';
        break;
    }

    return title;
  };

  const passwordInput = (field, label) => (
    <FormInput
      label={label}
      value={values[field]}
      error={errors[field]}
      onChange={onFieldChange(field)}
      onIconClick={handleShowPass}
      type={!showPass && 'password'}
      icon={!showPass ? 'hidden' : 'visible'}
      onBlur={() => setTouched(true)}
      p={field === 'password' && '40px 0'}
      iconRight={18.57}
      iconBottom={23}
      hideMaxLength
    />
  );

  const formButton = () => {
    const errorMessage = errors.message !== 'RESET_PASSWORD_EXPIRED';
    if (layout === 'resetPassword' && errorMessage)
      return (
        <ContainedButton padding="12px 16px" type="submit" form="resetPassword">
          reset password
        </ContainedButton>
      );
    if (layout === 'resetPasswordSuccess' && errorMessage)
      return (
        <ContainedButton padding="12px 16px" onClick={handleBackTo}>
          sign in
        </ContainedButton>
      );
  };

  return (
    <>
      {layout === 'resetPasswordExpire' ? (
        <ForgotPassword
          title="The link has expired"
          subTitle="To reset password, you can request a new link"
          linkExpired
        />
      ) : (
        <Styled.SplashFormWrapper>
          <Styled.SplashHeader>
            <Typography variant="h2" fontSize="36px" lineHeight="40px">
              {renderTitle()}
            </Typography>
          </Styled.SplashHeader>
          <Styled.SplashForm onSubmit={onSubmit} id="resetPassword" autoComplete="on">
            {layout === 'resetPassword' && (
              <>
                <Styled.RequirementsList>
                  <Styled.RequirementsListItem color="rgba(230, 234, 255, 0.6)">
                    Your password must have:
                  </Styled.RequirementsListItem>
                  {passRequirements.map(item => (
                    <Styled.RequirementsListItem color={handleColorChange(item)} key={item.key}>
                      <Icon icon="checkmark-circle" noFill color={handleColorChange(item)} />
                      <Styled.RequirementTitle>&nbsp;{item?.title}</Styled.RequirementTitle>
                    </Styled.RequirementsListItem>
                  ))}
                </Styled.RequirementsList>
                {passwordInput('password', 'Your new password')}
                {passwordInput('passwordAgain', 'Type it again')}
              </>
            )}
            {layout === 'resetPasswordSuccess' && (
              <Styled.SuccessMessage padding="135px 32px">
                <Icon icon="checkmark-circle" size={40} color="#00FF00" noFill />
                <Styled.SuccessMessageText>
                  Cool! You can use your new password to log in into your account.
                </Styled.SuccessMessageText>
              </Styled.SuccessMessage>
            )}
            <Styled.SplashFormFooter buttonToEnd>
              {submitting && <Spinner style={{ marginRight: '8px' }} />}
              {formButton()}
            </Styled.SplashFormFooter>
          </Styled.SplashForm>
        </Styled.SplashFormWrapper>
      )}
    </>
  );
};

export default ResetPassword;
