import React, { useEffect, useState } from 'react';
import Icon from 'common/Icon';
import ContainedButton from 'common/ContainedButton';
import Benefits from 'common/Benefits/Benefits';
import { profileSelectors } from 'store/profile';
import { useSelector } from 'react-redux';
import {
  StyledLifeFrame,
  StyledLifeFrameContentContainer,
  StyledLifeFrameText,
  StyledLifeFramesContainer
} from './StyledLifeFrames';
import LifeAddBlockDropdown from '../LifeAddBlockDropdown';

const LifeFrames = ({ activeButton, categoryId, jobsPage, listOfCategories }) => {
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const [frameIcon, setFrameIcon] = useState('');
  const [frameButton, setFrameButton] = useState('');

  useEffect(() => {
    if (listOfCategories && listOfCategories.length === 0) {
      setFrameIcon('lifeFrame_newPage');
      setFrameButton('add page');
    } else {
      switch (activeButton) {
        case 'who-we-are':
          setFrameIcon('lifeFrame_whatWeAre');
          setFrameButton('add block');
          break;
        case 'what-we-stand-for':
          setFrameIcon('lifeFrame_whatWeStandFor');
          setFrameButton('add block');
          break;
        default:
          setFrameIcon('lifeFrame_newPage');
          setFrameButton('add block');
          break;
      }
    }
  }, [listOfCategories, activeButton]);

  const renderText = () => {
    if (listOfCategories && listOfCategories.length === 0) {
      return (
        <span>
          {`Here is your new page in the “Life” section. Tell everyone about what the life in your company is like. `}
          <a href="#"> See examples</a>
          {` for inspiration. Add a page to start building.`}
        </span>
      );
    }
    switch (activeButton) {
      case 'who-we-are':
        return (
          <span>
            {`“Who we are” is a place where you can tell what it is like to work and live in your company. Share photos
            and introduce your team.`}{' '}
            <a href="#">See examples</a>
            {` for inspiration. Don’t feel you need it now? No
            worries, you can fill it out later.`}
          </span>
        );
      case 'what-we-stand-for':
        return (
          <span>
            {`“What we stand for” is a place to tell about your values and culture. `}
            <a href="#"> See examples</a>
            {` for inspiration. Don’t feel you need it now? No worries, you can fill it out later.`}
          </span>
        );
      default:
        return (
          <span>
            {`Here is your new page in the “Life” section. Tell everyone about what the life in your company is like. `}
            <a href="#"> See examples</a>
            {` for inspiration.`}
          </span>
        );
    }
  };

  return (
    <StyledLifeFramesContainer>
      {activeButton === 'what-we-offer' ? (
        <Benefits categoryId={categoryId} jobsPage={jobsPage} />
      ) : (
        <>
          {hasEditPermission && !jobsPage && (
            <StyledLifeFrame className={frameButton === 'add page' ? 'lifeFrame' : ''}>
              <StyledLifeFrameContentContainer>
                <Icon icon={frameIcon} size={64} />
                <StyledLifeFrameText className={frameIcon === 'lifeFrame_newPage' ? 'lifeFrameText--newPage' : ''}>
                  {renderText()}
                </StyledLifeFrameText>
                {frameButton === 'add page' ? (
                  <ContainedButton
                    opens="life-pages-edit-modal"
                    icon="plus"
                    size={12}
                    backgroundColor="rgba(204, 213, 255, 0.11)"
                    radius="6px"
                    padding="8px 10px"
                  >
                    {frameButton}
                  </ContainedButton>
                ) : (
                  <LifeAddBlockDropdown categoryId={categoryId} />
                )}
              </StyledLifeFrameContentContainer>
            </StyledLifeFrame>
          )}
        </>
      )}
    </StyledLifeFramesContainer>
  );
};

export default LifeFrames;
