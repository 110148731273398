import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { profileSelectors } from 'store/profile';
import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';
import Typography from 'components/shared/Typography';
import useProfileUnlock from 'hooks/api/useProfileUnlockService';

import EditButton from './EditButton';
import Icon from './Icon';
import LockButton from './lockUnlockButtons/LockButton';
import UnlockPendingButton from './lockUnlockButtons/UnlockPendingButton';
import Required from './styles/Required';

const Wrapper = styled.div`
  position: relative;
  padding: ${props => (props.padding ? props.padding : `32px 24px`)};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  background-color: #111112;

  &.center {
    overflow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${props => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  border: 2px solid rgba(204, 213, 255, 0.11);
  ${props => props.canEdit && !props.filled && 'border: 2px dashed rgba(204, 213, 255, 0.11);'}
  ${props => !props.filled && 'border: 2px dashed rgba(204, 213, 255, 0.11);'}
  ${props => props.error && 'border: 2px dashed #FF5151 !important;'}
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius || 16}px;
  ${props => props.withMarginBottom && 'margin-bottom: 16px;'}
  ${props => props.transparent && 'background-color: transparent;'}
  ${props => props.noBorder && 'border: 2px solid transparent'}
  ${props => props.outline && `outline: ${props.outline};`}
  ${props => props.mt && `margin-top: ${props.mt}px;`}
  ${props => props.mb && `margin-bottom: ${props.mb}px;`}
`;
const Header = styled.div`
  ${commonStyles.card_label}
  font-weight: 700;
  ${props => props.smallHeader && `font-size: 19px; text-transform: unset`}
  ${props => props.headerFS && `font-size: ${props.headerFS}px`}
`;
const ColorWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  overflow: hidden;
  width: 50px;
  height: 70px;
  display: flex;
  align-items: center;
`;
const Color = styled.div`
  width: 5px;
  height: 24px;
  background: ${props => props.color};
  box-shadow: 0px 0px 20px 2px ${props => props.color};
`;
const Content = styled.div`
  font-size: ${props => (props.textFontSize ? props.textFontSize : '16px')};
  ${props => props.fullHeight && 'height: 100%;'}
`;
const StyledEditButton = styled(EditButton)`
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;

  ${props => props.dark && `background: #111112`}
`;
const StyledLockButton = styled.div`
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  ${props => props.homepage && 'top: 20px;'};
  ${props => props.homepage && 'right: 20px;'};
  @media ${pageSize.XS} {
    ${props => props.homepage && 'right: 18px;'};
  }
`;
const PlaceHolder = styled.div`
  color: rgba(230, 234, 255, 0.35);
  font-size: ${props => (props.holderFontSize ? props.holderFontSize : '16px')};
`;
const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ titlePB }) => (titlePB ? `${titlePB}px` : '16px')};

  @media ${pageSize.XS} {
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
  }
  @media ${pageSize.S} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const StyledHeaderWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;
`;
const StyledInstructions = styled(Typography)`
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 10px;
  margin-left: auto;

  @media ${pageSize.XS} {
    margin-top: 10px;
    margin-left: 0;
  }
  @media ${pageSize.S} {
    margin-top: 10px;
    margin-left: 0;
  }
`;
const StyledInstructionIcon = styled(Icon)``;
const StyledInstructionSpan = styled.span`
  text-decoration: underline;
`;

const Frame = (
  {
    canEdit,
    children,
    className,
    color,
    darkEditBtn,
    data,
    editMode = 'edit',
    error,
    fullHeight,
    headerFS,
    hidden = false,
    holderFontSize,
    homepage,
    instruction,
    mb,
    mt,
    noDataText = 'No Data',
    noIcon,
    onEdit,
    onInstructionClick,
    opens,
    outline,
    project,
    renderFooter,
    required,
    smallHeader,
    style,
    text,
    textFontSize,
    titleIcon,
    titleIconSize,
    titlePB,
    transparent,
    withMarginBottom,
    ...rest
  },
  ref
) => {
  const talentId = useSelector(profileSelectors.selectMyProfileId);

  const { addProfileUnlockRequest } = useProfileUnlock();

  const handleLockBtnClick = e => {
    e.stopPropagation();
    addProfileUnlockRequest({ entityId: project._id, entityType: `${project?.name ? 'project' : 'credit'}`, talentId });
  };

  if (hidden) return null;

  return (
    <Wrapper
      error={error}
      outline={outline}
      mt={mt}
      mb={mb}
      transparent={transparent}
      style={style}
      className={className}
      withMarginBottom={withMarginBottom}
      canEdit={canEdit}
      {...rest}
      ref={ref}
    >
      {text && (
        <StyledHeaderContainer titlePB={titlePB}>
          <StyledHeaderWithIconContainer>
            {titleIcon && <Icon size={titleIconSize} icon={titleIcon} />}
            <Header smallHeader={smallHeader} headerFS={headerFS}>
              {text} {required && canEdit && <Required />}
            </Header>
          </StyledHeaderWithIconContainer>
          {instruction && (
            <StyledInstructions onClick={onInstructionClick}>
              <StyledInstructionIcon icon="question" color="#fff" size={16} />
              <StyledInstructionSpan>See Instruction</StyledInstructionSpan>
            </StyledInstructions>
          )}
        </StyledHeaderContainer>
      )}
      {color && (
        <ColorWrapper>
          <Color color={color} />
        </ColorWrapper>
      )}

      {canEdit && !noIcon && (
        <>
          {project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'LOCKED' ? (
            <StyledLockButton homepage={homepage}>
              <LockButton onClick={handleLockBtnClick} tooltip="To request edit permissions, click here" />
            </StyledLockButton>
          ) : project?.vetting?.status === 'IN_PROGRESS' && project?.lockData?.status === 'UNLOCK_REQUESTED' ? (
            <StyledLockButton homepage={homepage}>
              <UnlockPendingButton tooltip="Edit request pending" />
            </StyledLockButton>
          ) : (
            <StyledEditButton
              className="btn"
              type="button"
              iconName={editMode === 'edit' ? 'edit' : 'add'}
              opens={opens}
              data={data}
              onClick={onEdit}
              dark={darkEditBtn}
              tooltip={
                homepage &&
                project?.vetting?.status === 'IN_PROGRESS' &&
                project?.lockData?.status === 'UNLOCKED' &&
                project?.lockData?.unlockedDate &&
                'Edit unlocked for 24 hours'
              }
            />
          )}
        </>
      )}
      {canEdit && editMode === 'add' && <PlaceHolder holderFontSize={holderFontSize}>{noDataText}</PlaceHolder>}

      <Content className={'content'} textFontSize={textFontSize} fullHeight={fullHeight}>
        {children}
      </Content>

      {renderFooter}
    </Wrapper>
  );
};

export default React.forwardRef(Frame);
