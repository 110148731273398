import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

export const LifeContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media ${pageSize.XL}, ${pageSize.L}, ${pageSize.M} {
    max-width: 1240px;
    width: 100%;
    margin: 0px auto;
  }
`;
