import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { industryService } from 'api';
import useAPICall from 'hooks/useAPICall';
import Page404 from 'pages/service-page/Page404';
import { useDispatch, useSelector } from 'react-redux';
import { initializeIndustries } from 'store/industry/industryAction';
import { profileSelectors } from 'store/profile';
import { tagActions } from 'store/tags';
import useProfileView from 'hooks/useProfileView';
import useViewport from 'hooks/useViewport';
import TalentInfoPageLayout from './TalentInfoPageLayout';
import TalentJobPageLayout from './TalentJobPageLayout';
import TalentPortfolioPageLayout from './TalentPortfolioPageLayout';

const TalentPortfolioPageLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const isPortfolioLayout = pathname => {
  switch (pathname) {
    case '/talent/:talentId/portfolio/:portfolioId':
      return true;
    case '/company/:talentId/portfolio/:portfolioId':
      return true;

    default:
      return false;
  }
};

const isJobLayout = pathname => {
  switch (pathname) {
    case '/talent/:talentId/jobs/:jobId':
      return true;
    case '/company/:talentId/jobs/:jobId':
      return true;

    default:
      return false;
  }
};

const TalentPageLayout = ({
  location: { pathname },
  match: {
    params: { jobId, portfolioId, talentId },
    path
  }
}) => {
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const publicUrl = useSelector(profileSelectors.selectProfileProperty('publicUrl'));
  const { isXS } = useViewport();

  const { loading: fetchingTags } = useAPICall({
    name: 'fetch-tags',
    url: '/tags?noPagination=true',
    method: 'get',
    transformData: response => response.data,
    optimisticUpdater: tagActions.initializeTags
  });

  const { isProfileLoaded: profileLoaded, loading: fetchingTalent, notFound } = useProfileView(talentId);
  const profile = useSelector(profileSelectors.selectProfile);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await industryService.getIndustry();
        dispatch(initializeIndustries(res.data.data));
      } catch (err) {
        console.log('err', err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const styles = `
        border-left: 2px solid rgba(204, 213, 255, 0.11);
        border-right: 2px solid rgba(204, 213, 255, 0.11);
      `;
    if (!isXS) {
      document.getElementById('root').setAttribute('style', styles);
    }

    return () => document.getElementById('root').removeAttribute('style');
  }, [isXS]);

  const showPortfolioLayout = isPortfolioLayout(path);
  const showJobLayout = isJobLayout(path);

  if (notFound) {
    return <Page404 />;
  }

  const profilesNotEqual = talentId !== publicUrl && talentId !== profileId && talentId !== profile?.userId;

  return (
    <>
      {!showPortfolioLayout && !showJobLayout && (
        <TalentInfoPageLayout
          isProfileLoaded={profileLoaded}
          fetchingTags={fetchingTags}
          path={path}
          pathname={pathname}
          profile={profile}
        />
      )}
      {showPortfolioLayout && (
        <TalentPortfolioPageLayoutWrapper>
          <TalentPortfolioPageLayout portfolioId={portfolioId} fetchingTalent={fetchingTalent} />
        </TalentPortfolioPageLayoutWrapper>
      )}
      {showJobLayout && (
        <TalentJobPageLayout userId={talentId} jobId={jobId} loadingUser={!!fetchingTalent || profilesNotEqual} />
      )}
    </>
  );
};

export default TalentPageLayout;
