import React, { useState } from 'react';
import styled from '@emotion/styled';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import FormInput from 'common/form/FormInput';
import Typography from 'components/shared/Typography';
import { backOfficeService } from 'api';
import useForm from 'hooks/useForm';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;

const ActionResetPasswordModal = ({ closePortal, data: { userId }, name }) => {
  const [passwordChanged, setPasswordChanged] = useState(false);

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields: {
      newPassword: { initialValue: '', validate: ['isRequired', 'strongPassword'] },
      reNewPassword: { initialValue: '', validate: ['typeItAgain', 'confirmPassword'], dependOn: 'newPassword' }
    },
    callApi: async formFields => backOfficeService.resetUserPassword(userId, formFields),
    onSuccess: async () => setPasswordChanged(true)
  });

  const handleSubmit = async e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <TxplModal
      title="Reset password"
      name={name}
      size="small"
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <>
          {submitting && <Spinner />}

          {!passwordChanged && (
            <>
              <StyledContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
                Cancel
              </StyledContainedButton>
              <StyledContainedButton
                form="actionResetPasswordModal"
                type="submit"
                backgroundColor="#7266EE"
                disabled={submitting}
              >
                OK
              </StyledContainedButton>
            </>
          )}

          {passwordChanged && (
            <>
              <StyledContainedButton onClick={closePortal} backgroundColor="#7266EE" forceDisplayText>
                OK
              </StyledContainedButton>
            </>
          )}
        </>
      }
    >
      {passwordChanged && (
        <Typography success variant="medium">
          The new password has been set for this profile
        </Typography>
      )}

      {!passwordChanged && (
        <form onSubmit={handleSubmit} id="actionResetPasswordModal" disabled>
          <FormInput
            label="New password"
            value={values.newPassword}
            onChange={e => onFieldChange('newPassword')(e.target.value)}
            type="password"
            autoFocus
            error={errors.newPassword}
          />
          <FormInput
            label="Confirm new password"
            value={values.reNewPassword}
            onChange={e => onFieldChange('reNewPassword')(e.target.value)}
            type="password"
            error={errors.reNewPassword}
          />
        </form>
      )}
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-reset-user-password-modal')(ActionResetPasswordModal);
