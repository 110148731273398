import React, { useState } from 'react';
import styled from '@emotion/styled';

import ContainedButton from 'common/ContainedButton';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import useStartConversation from 'hooks/chatApi/useStartConversation';
import { authSelectors } from 'store/auth';
import useViewport from 'hooks/useViewport';
import ShareURL from 'common/ShareURL';
import createSharingUrl from 'utils/create-sharing-url';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const StyledContainedButton = styled(ContainedButton)`
  margin-right: 4px;
  position: relative;
`;

const PortfolioActions = ({ portfolio }) => {
  const profile = useSelector(profileSelectors.selectProfile);
  const isAuthed = useSelector(authSelectors.isAuthed);
  const { redirectToStartConversation } = useStartConversation();
  const { isXS } = useViewport();

  const [shareURLVisible, setShareURLVisible] = useState(false);

  const canShareProject = isAuthed && portfolio.vetted;

  return (
    <Wrapper>
      <StyledContainedButton
        icon="addToCollection"
        disabled
        tooltip="Coming Soon 😉"
        tooltipId="tooltip-for-add-to-collection-header"
      >
        {!isXS && 'Add To Collection'}
      </StyledContainedButton>
      {portfolio.profileModel === 'talents' && (
        <StyledContainedButton icon="share" onClick={() => setShareURLVisible(true)} disabled={!canShareProject}>
          {!isXS && 'Share'}
          {shareURLVisible && (
            <ShareURL
              styles={{ container: { top: 40 } }}
              visible={shareURLVisible}
              setVisible={setShareURLVisible}
              url={createSharingUrl({ type: 'project', id: portfolio._id })}
              successText="The project link has been copied"
            />
          )}
        </StyledContainedButton>
      )}
      {portfolio.profileModel === 'companyprofile' && (
        <StyledContainedButton icon="share" tooltip="Share" tooltipId="tooltip-for-share-footer">
          {!isXS && 'Share'}
        </StyledContainedButton>
      )}
      <StyledContainedButton icon="like" disabled tooltip="Coming Soon 😉" tooltipId="tooltip-for-like-header">
        {!isXS && 'Like'}
      </StyledContainedButton>
      {isAuthed && (
        <StyledContainedButton icon="message" onClick={() => redirectToStartConversation(profile)}>
          {!isXS && 'Message'}
        </StyledContainedButton>
      )}
    </Wrapper>
  );
};

export default PortfolioActions;
