import { talentService } from 'api';
import { useErrorSnackbar } from 'hooks/useReactSnackbar';

const useTalentServices = () => {
  const { openErrorSnackbar } = useErrorSnackbar();

  const updateTalentService = async (profileId, items) => {
    try {
      await talentService.updateTalentServices(profileId, items);
    } catch {
      openErrorSnackbar('Oops! something went wrong');
    }
  };

  return { updateTalentService };
};

export default useTalentServices;
