import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import withRenderPortal from 'hocs/withRenderPortal';
import commonStyles from 'utils/common-styles';

import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import { talentService } from 'api';
import { profileActions, profileSelectors } from 'store/profile';

const StyledText = styled.p`
  ${commonStyles.ui_text_medium}
`;

const StyledCancelButton = styled(ContainedButton)`
  margin-right: 6px;
  margin-left: 6px;
`;

const MediaCoverageDeleteModal = ({ closePortal, data: { localMediaId, mediaId }, name, onDelete }) => {
  const dispatch = useDispatch();
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const [loading, updateLoading] = React.useState(false);

  const deleteMediaCoverage = () => {
    if (onDelete) {
      onDelete({ mediaId, localMediaId });
      closePortal();
      return;
    }

    updateLoading(true);
    talentService
      .deleteMediaCoverage(profileId, mediaId)
      .then(() => {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'mediaCoverage',
            value: mediaId,
            updateType: 'delete',
            profileId
          })
        );
      })
      .finally(() => {
        updateLoading(false);
        closePortal();
      });
  };

  return (
    <TxplModal
      name={name}
      padding="24px 0 60px 0"
      XSpadding="0 0 24px 0"
      title="Delete this media coverage"
      appElement={document.getElementById('root-modal')}
      nonFullScreenOnMobile
      noPaddingTop
      titlePadding="0"
      size="small"
      noBorderXs
      renderFooter={
        <>
          {loading && <Spinner />}

          <StyledCancelButton backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText onClick={closePortal}>
            Cancel
          </StyledCancelButton>

          <ContainedButton backgroundColor="#FF5151" forceDisplayText onClick={deleteMediaCoverage}>
            Delete
          </ContainedButton>
        </>
      }
    >
      <StyledText>
        This will delete this media coverage from all parts of your profile where it appears. Proceed?
      </StyledText>
    </TxplModal>
  );
};

export default withRenderPortal('delete-media-coverage-modal')(MediaCoverageDeleteModal);
