import styled from '@emotion/styled';
import React from 'react';
import { pageSize } from 'utils/devices';

const StyledSidebarLinkList = styled.div`
  display: flex;
  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    flex-flow: column;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);
  }
  @media ${pageSize.XS} {
    flex-direction: column;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 2px solid rgba(204, 213, 255, 0.11);

    & > a {
      width: 100%;
    }
  }
`;

const SidebarList = ({ children }) => <StyledSidebarLinkList>{children}</StyledSidebarLinkList>;

export default SidebarList;
