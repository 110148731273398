import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { authActions } from 'store/auth';
import { collaborationsActions } from 'store/collaborations';
import { authService } from 'api';

import Spinner from 'common/Spinner';
import { profileActions } from 'store/profile';
import { tagActions } from 'store/tags';
import { notificationActions } from 'store/notifications';
import useTalkSession from 'hooks/useTalkSession';
import { portalActions } from 'store/portals';
import { cookieAuthAsName, getCookie } from 'utils/cookies';

const Logout = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { endSession } = useTalkSession();

  const clearRedux = () => {
    dispatch(profileActions.clearProfiles());
    dispatch(collaborationsActions.clearCollaborations());
    dispatch(tagActions.clearTags());
    dispatch(portalActions.clearPortal());
    dispatch(notificationActions.clearNotifications());
  };

  useEffect(() => {
    const doLogout = () => {
      const onBehalfOf = getCookie(cookieAuthAsName);
      const isAdminMode = !!onBehalfOf;
      setIsLoading(true);
      authService
        .logout({})
        .then(() => {
          dispatch(authActions.logout());
          clearRedux();
          endSession();
          if (isAdminMode) {
            window.location.href = `/app/backoffice/operations/users/${onBehalfOf}`;
          } else {
            history.push('/');
          }
        })
        .catch(err => {
          console.log('Error: ', err);
          setIsLoading(false);
        });
    };
    doLogout();
  }, [dispatch, history, location]); // eslint-disable-line

  return <>{isLoading && <Spinner />}</>;
};

export default Logout;
