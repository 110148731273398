import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useAPICall from 'hooks/useAPICall';
import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import { profileActions, profileSelectors } from 'store/profile';
import useProfilePath from 'hooks/useProfilePath';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const DeleteProjectModal = ({ closePortal, data, name }) => {
  const profileId = useSelector(profileSelectors.selectProfileProperty('_id'));
  const dispatch = useDispatch();
  const history = useHistory();
  const { myProfilePath } = useProfilePath();

  const { callApi, loading } = useAPICall({
    url: `/project/${profileId}/project/${data.projectId}`,
    method: 'delete',
    name: 'delete-project',
    transformData: response => response.data,
    optimisticUpdater: projectId => {
      closePortal();
      if (data.isGeneral) {
        data.closeParentModal();
      } else {
        !data.isGeneral && history.push(`${myProfilePath}/portfolio`);
      }

      return dispatch(
        profileActions.updateProfileProperty({
          property: 'generalProjects',
          value: projectId,
          updateType: 'delete',
          profileId
        })
      );
    },
    onSuccess: projectId => {
      dispatch(
        profileActions.updateProfileProperty({
          property: 'projects',
          value: projectId,
          updateType: 'delete',
          profileId
        })
      );
      if (data.afterDelete) {
        data.afterDelete(projectId);
      }
    }
  });

  return (
    <TxplModal
      title="Delete this project?"
      name={name}
      appElement={document.getElementById('root')}
      size="small"
      padding="55px 0 40px"
      XSpadding="0 0 24px"
      nonFullScreenOnMobile
      renderFooter={
        <StyledButtonsContainer>
          {loading && <Spinner />}
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton backgroundColor="#FF5151" onClick={() => callApi()} data-testid="delete-project-modal">
            Delete
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <Typography variant="medium">Are you sure you want to delete the project? This cannot be undone.</Typography>
    </TxplModal>
  );
};

export default withRenderPortal('delete-project-modal')(DeleteProjectModal);
