import React from 'react';
import size from 'lodash/fp/size';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'store/profile';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import useDragEnd from 'hooks/useDragEnd';
import normalize from 'utils/normalize';
import AddFirstExperience from './AddFirstExperience';
import AddNewExperience from './AddNewExperience';
import TalentExperienceItem from './TalentExperienceItem';
import AddExperienceButton from './AddExperienceButton';
import DeleteExperienceModal from './DeleteExperienceModal';
import Icon from '../../../common/Icon';
import talentService from '../../../api/talentService';
import { pageSize } from '../../../utils/devices';

const DragIcon = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 1;
  right: 40px;
  top: 0;
`;
const Wrapper = styled.div`
  &:hover .drag-icon-${props => props.id} {
    display: block;
  }
  @media ${pageSize.XS} {
    .drag-icon-${props => props.id} {
      display: block;
    }
  }
`;
const StyledDragIcon = styled(DragIcon)`
  @media ${pageSize.XS} {
    top: 29px;
  }
`;

const TalentExperience = () => {
  const experience = useSelector(profileSelectors.selectProfileProperty('experience')) || {};
  const [mode, updateMode] = React.useState();
  const [experienceId, updateExperienceId] = React.useState();
  const hasPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const talentId = useSelector(profileSelectors.selectProfileProperty('id'));

  const dispatch = useDispatch();

  const callApi = (ids, sortedList) =>
    talentService.updateExperienceSort(talentId, ids).then(() => {
      dispatch(
        profileActions.updateProfileProperty({
          property: 'experience',
          value: normalize(sortedList),
          profileId: talentId
        })
      );
    });

  const { list: experiences, onDragEnd } = useDragEnd({
    items: experience,
    callApi
  });

  switch (true) {
    case ['add', 'edit'].includes(mode):
      return (
        <>
          <AddNewExperience
            experienceId={experienceId}
            updateExperienceId={updateExperienceId}
            mode={mode}
            toggleMode={updateMode}
          />

          <DeleteExperienceModal />
        </>
      );

    case !size(experience):
      return hasPermission && <AddFirstExperience toggleAddMode={() => updateMode('add')} />;

    default:
      return (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {experiences.map(
                    (item, index) =>
                      item && (
                        <Wrapper id={item._id} key={index}>
                          <Draggable
                            key={item._id}
                            draggableId={item._id}
                            index={index}
                            isDragDisabled={!hasPermission && true}
                          >
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <div
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  {hasPermission && experiences.length > 1 && (
                                    <StyledDragIcon {...provided.dragHandleProps} className={`drag-icon-${item._id}`}>
                                      <Icon icon="draggable_touch" size={32} noFill />
                                    </StyledDragIcon>
                                  )}
                                  <TalentExperienceItem
                                    toggleAddMode={() => updateMode('edit')}
                                    updateEditedExperienceId={updateExperienceId}
                                    key={item}
                                    job={item}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </Wrapper>
                      )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {hasPermission && <AddExperienceButton onClick={() => updateMode('add')} />}
        </>
      );
  }
};

export default TalentExperience;
