import React from 'react';
import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Sidebar from 'common/Sidebar/Sidebar';
import SidebarList from 'common/Sidebar/SidebarList';
import SidebarNavLink from 'common/Sidebar/SidebarNavLink';
import useProfilePath from 'hooks/useProfilePath';
import { useHistory } from 'react-router';
import { pageSize } from 'utils/devices';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { useDispatch } from 'react-redux';
import { portalActions } from 'store/portals';
import useCompanyRole from 'hooks/useCompanyRole';

const StyledButton = styled(ContainedButton)`
  display: flex;
  justify-content: center;
  @media ${pageSize.XS}, ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    width: 100%;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const StyledMessage = styled.span`
  color: var(--white-white-60, rgba(229, 234, 255, 0.6));
  text-align: center;
  /* UI Text Small */
  font-family: Space Grotesk;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const LinkToModal = styled.span`
  color: var(--white-white-100, #fff);
  /* UI Text Small */
  font-family: Space Grotesk;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
`;

const Wrapper = styled.div``;

const JobsSidebar = () => {
  const history = useHistory();
  const { myProfilePath } = useProfilePath();
  const dispatch = useDispatch();
  const currentProfile = useSelector(profileSelectors.selectMyProfile);

  const { isMember } = useCompanyRole();

  const profileJobType = useSelector(profileSelectors.selectMyProfileProperty('isSupply'));

  const clickPostJob = () => {
    if (profileJobType === 'supply') {
      dispatch(portalActions.openPortal({ name: 'supply-post-job-modal', data: { talentId: currentProfile?._id } }));
    } else {
      history.push(`${myProfilePath}/jobs/add`);
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <SidebarList>
          <SidebarNavLink to={`${myProfilePath}/all-jobs`} icon="jobs" data-testid="all-jobs-tab">
            Recommended jobs
          </SidebarNavLink>
          {profileJobType !== 'demand' && (
            <SidebarNavLink to={`${myProfilePath}/my-jobs`} icon="bookmark" data-testid="my-jobs-tab">
              My jobs
            </SidebarNavLink>
          )}
          {profileJobType !== 'supply' && (
            <SidebarNavLink to={`${myProfilePath}/jobs-i-posted`} icon="jobsIPosted" data-testid="jobs-i-posted-tab">
              Jobs I posted
            </SidebarNavLink>
          )}
        </SidebarList>
        {profileJobType !== 'supply' ? (
          <StyledButton
            icon="add"
            padding="12px"
            height="auto"
            onClick={clickPostJob}
            data-testid="post-job-button"
            disabled={isMember}
          >
            Post A Job
          </StyledButton>
        ) : (
          <MessageWrapper>
            <StyledMessage>
              You want to post jobs?{' '}
              <LinkToModal onClick={clickPostJob}>Connect with our friendly support team </LinkToModal>to activate job
              creation.
            </StyledMessage>
          </MessageWrapper>
        )}
      </Sidebar>
    </Wrapper>
  );
};

export default JobsSidebar;
