import React, { useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';

function useAudioWaveSurfer({ audioRef, surferRef, url }) {
  const [playingAudio, setPlaying] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const toggleAudio = e => {
    e.stopPropagation && e.stopPropagation();
    if (!loading) {
      if (audioRef.current.isPlaying()) {
        pauseAudio();
      } else {
        audioRef.current.play();
        setPlaying(true);
      }
    }
  };

  const pauseAudio = React.useCallback(() => {
    audioRef.current.pause();
    setPlaying(false);
  }, [playingAudio]);

  useEffect(() => {
    if (surferRef.current) {
      const wavesurfer = WaveSurfer.create({
        container: surferRef.current,
        interact: false,
        waveColor: '#A678F6',
        progressColor: '#B678F6',
        cursorColor: 'transparent'
      });

      wavesurfer.load(url);

      wavesurfer.on('ready', () => {
        setLoading(false);
      });

      audioRef.current = wavesurfer;
    }
    return () => {
      if (audioRef.current && surferRef.current) {
        pauseAudio();
        audioRef.current.destroy();
      }
    };
  }, []);

  return {
    playingAudio,
    loadingAudio: loading,
    toggleAudio,
    pauseAudio
  };
}

export default useAudioWaveSurfer;
