import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { domainByEnv, getCookie } from 'utils/cookies';

const ProtectedRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = getCookie(`thexplace.${domainByEnv[process.env.REACT_APP_ENVIRONMENT]}.it`);
      if (!token) return <Redirect to="/?login=true" />;

      const decoded = jwt_decode(token);

      const currentRoles = decoded['cognito:groups'];

      const allowed = roles.map(role => currentRoles.includes(role));

      if (!allowed.every(v => v === true)) return <Redirect to="/profile" />;

      return <Component {...props} />;
    }}
  />
);

export default ProtectedRoute;
