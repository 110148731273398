import styled from '@emotion/styled';
import { DisplayContext } from 'common/DisplaySwitcher';
import useViewport from 'hooks/useViewport';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserProperty } from 'store/auth/authSelectors';
import SearchEmptyState from '../../../components/SearchEmptyState';
import TalentCard from './TalentCard';
import TalentsSearchHeader from './TalentsSearchHeader';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
`;

function TalentsSearchResult({ hasMore, loading, relatedSearch, talents, talentsFound }) {
  const [display, setDisplay] = React.useState('cards-list');
  const { isXS } = useViewport();
  const biasReducedMode = !!useSelector(selectCurrentUserProperty('activeProfile'))?.biasReducedMode;

  return (
    <Wrapper>
      <DisplayContext.Provider value={{ display, setDisplay }}>
        <TalentsSearchHeader talentsFound={talentsFound} relatedSearch={relatedSearch} />
        {hasMore && !talents.length ? (
          <></>
        ) : !talents?.length && !loading ? (
          <SearchEmptyState />
        ) : (
          <>
            {!!talents?.length &&
              !loading &&
              talents.map(talent => (
                <TalentCard
                  key={talent.id ?? talent._id}
                  talent={talent}
                  isListView={isXS ? false : display === 'list'}
                  biasReducedMode={biasReducedMode}
                />
              ))}
          </>
        )}
      </DisplayContext.Provider>
    </Wrapper>
  );
}

export default TalentsSearchResult;
