import styled from '@emotion/styled';
import AllAdditionalTags from 'pages/talent/AllAdditionalTags';
import ContainedButton from 'common/ContainedButton';

import { pageSize } from 'utils/devices';

export const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 16px !important;
  display: grid;
  grid-template-columns: 1fr !important;
`;

export const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media ${pageSize.M} {
    grid-template-columns: 1fr;
  }

  @media ${pageSize.S} {
    grid-template-columns: 1fr;
  }

  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

export const ApplyNowButton = styled(ContainedButton)`
  width: 100%;
  justify-content: center;
`;
