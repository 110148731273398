import React from 'react';
import kebabCase from 'lodash/fp/kebabCase';
import { StyledLifePageBarButton, StyledText } from './StyledLifeBarButton';

const LifePageBar = ({ activeButton, name, setActiveButton }) => {
  const handleClick = () => {
    setActiveButton(kebabCase(name));
  };

  return (
    <StyledLifePageBarButton
      type="button"
      onClick={handleClick}
      className={`${activeButton === kebabCase(name) ? ' active' : ''}`}
    >
      <StyledText className={`${activeButton !== kebabCase(name) ? ' activeText' : ''}`}>{name}</StyledText>
    </StyledLifePageBarButton>
  );
};

export default LifePageBar;
