import React from 'react';
import styled from '@emotion/styled';

const StyledIframe = styled.iframe`
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 70vh;
`;

const TOS = () => (
  <>
    <h1>Terms of Service &amp; Privacy Policy</h1>
    <StyledIframe src="https://thexplace-dev.s3.us-west-1.amazonaws.com/v+1.1+Terms+of+Service.pdf" />
    <br />
    <a
      href="https://thexplace-dev.s3.us-west-1.amazonaws.com/v+1.1+Terms+of+Service.pdf"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Service &amp; Privacy Policy
    </a>
    <br />
    <br />
    &nbsp;
  </>
);

export default TOS;
