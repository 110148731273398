import { T, compose, cond, prop } from 'ramda';

const concatFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

export const getFullName = cond([
  [prop('collaboratorProfile'), compose(concatFullName, prop('collaboratorProfile'))],
  [prop('collaborator'), compose(concatFullName, prop('collaborator'))],
  [T, prop('name')]
]);

export const getCreatorName = cond([
  [prop('creator'), compose(concatFullName, prop('creator'))],
  [T, prop('name')]
]);

const collaborationTypeOptions = [
  { label: 'Peer', value: 'Peer' },
  { label: 'Client', value: 'Client' },
  { label: 'Supervisor', value: 'Supervisor' },
  { label: 'Report', value: 'Report' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'Other', value: 'Other' }
];

export default { collaborationTypeOptions };
