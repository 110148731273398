import React from 'react';
import styled from '@emotion/styled';

import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import Icon from 'common/Icon';

const StyledAddMediaCoverageContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  padding: 32px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-bottom: 54px;
`;

const StyledTypography = styled(Typography)`
  max-width: 70%;
`;

const emptyText = 'Build your Xfolio, showcase your glorious skills, and prepare to launch';

const AddExperienceButton = ({ fullScreen, onClick }) => (
  <StyledAddMediaCoverageContainer>
    {fullScreen && <Icon icon="experience" size="64" color="rgba(221, 226, 255, 0.2);" />}
    {fullScreen && (
      <StyledTypography mb={38} mt={38} variant="medium">
        {emptyText}
      </StyledTypography>
    )}
    <ContainedButton icon="plus" forceDisplayText onClick={onClick}>
      Add experience
    </ContainedButton>
  </StyledAddMediaCoverageContainer>
);

export default AddExperienceButton;
