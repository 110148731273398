import set from 'lodash/fp/set';
import * as backOfficeActionTypes from './backOfficeActionTypes';

const initialState = {
  title: [{ label: 'TheXPlace Back Office' }]
};

const backOfficeReducer = (state = initialState, action) => {
  switch (action.type) {
    case backOfficeActionTypes.UPDATE_BACKOFFICE_TITLE: {
      return set('title', action.payload.title, state);
    }

    default:
      return state;
  }
};

export default backOfficeReducer;
