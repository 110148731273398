import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import withRenderPortal from 'hocs/withRenderPortal';
import { portalActions } from 'store/portals';
import { selectProfileProperty } from 'store/profile/profileSelectors';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import FormCheckbox from 'common/form/FormCheckbox';
import AddProjectModal from 'pages/talent/Projects/AddProject/AddProjectModal';
import { Container, CreateNewProjectButton, ProjectList } from './styled.components';

const StyledFormCheckbox = styled(FormCheckbox)`
  padding: 16px;
  background-color: rgba(204, 212, 255, 0.05);
  border-radius: 8px;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .checkbox {
    border-radius: 4px;
  }
`;

const allowedStatuses = ['published', 'in-progress'];

const JobProjectModal = ({ closePortal, data, name, onSave }) => {
  const { projects } = data;
  const allProjects = useSelector(selectProfileProperty('generalProjects')) || [];
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState(
    Object.values(allProjects).filter(({ status }) => allowedStatuses.includes(status))
  );
  const [selectedProjects, setSelectedProject] = useState(projects || []);

  const handleSave = () => {
    onSave(selectedProjects);
    closePortal();
  };

  const handleCheckboxChange = project => {
    const selectedProjectsIds = selectedProjects.map(project => project._id);
    let updatedProject = [...selectedProjects];
    if (selectedProjectsIds.includes(project._id)) {
      const index = selectedProjects.findIndex(item => item._id === project._id);
      updatedProject.splice(index, 1);
    } else {
      updatedProject = [project, ...updatedProject];
    }
    setSelectedProject(updatedProject);
  };

  const handleNewProjectClick = () => {
    dispatch(portalActions.openPortal({ name: 'add-project-modal' }));
  };

  const handleNewProjectSuccess = project => {
    setProjectList([project, ...projectList]);
    setSelectedProject([project, ...selectedProjects]);
    onSave([project, ...selectedProjects]);
  };

  const selectedProjectsIds = selectedProjects.map(project => project._id);

  return (
    <TxplModal
      required
      title="Project"
      titleMB={8}
      subTitle="Add one or multiple projects related to this job from your ongoing projects or create a new one. Keep blank if this job is not related to any particular project."
      padding="0 0 80px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      width
      size="large"
      addPaddingTopBig
      fromJob
      renderFooter={
        <>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleSave} backgroundColor="#7266EE" forceDisplayText>
            save
          </ContainedButton>
        </>
      }
    >
      <Container>
        <ProjectList>
          {projectList &&
            projectList.map(project => (
              <StyledFormCheckbox
                key={project._id}
                value={project._id}
                checked={selectedProjectsIds.includes(project._id)}
                onChange={() => handleCheckboxChange(project)}
                text={project.name}
                iconBorder="rgba(204, 212, 255, 0.11)"
              />
            ))}
        </ProjectList>
        <CreateNewProjectButton onClick={handleNewProjectClick}>create new project</CreateNewProjectButton>
      </Container>
      <AddProjectModal onSuccess={handleNewProjectSuccess} />
    </TxplModal>
  );
};

export default withRenderPortal('job-project-modal')(JobProjectModal);
