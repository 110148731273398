import React, { useEffect, useState } from 'react';

import withRenderPortal from 'hocs/withRenderPortal';

import jobService from 'api/jobService';

import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal';
import ContainedButton from 'common/ContainedButton';
import FormUpload from 'common/form/FormUpload';
import { ModalContent, SelectFromProfileButton, ThumbnailTab, ThumbnailTabsWrapper } from './styled.components';
import JobThumbnailGallery from './JobThumbnailGallery';
import { useSelector } from 'react-redux';
import { selectIsMyProfileCompany } from 'store/profile/profileSelectors';

const JobThumbnailModal = ({ closePortal, data, name, onSave, talentId }) => {
  const { thumbnail } = data;
  const [selectFromProfileMode, setSelectFromProfileMode] = useState(false);
  const [coverImage, setCoverImage] = useState(thumbnail?.url || '');
  const [activeTab, setActiveTab] = useState('life');
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(thumbnail || null);
  const [loading, setLoading] = useState(false);
  const isCompany = useSelector(selectIsMyProfileCompany);

  const renderTabs = () => (
    <ThumbnailTabsWrapper>
      {isCompany && (
        <ThumbnailTab
          className={`${activeTab === 'life' && 'active active-left'}`}
          onClick={() => handleTabChange('life')}
        >
          life
        </ThumbnailTab>
      )}
      <ThumbnailTab className={activeTab === 'project' && 'active'} onClick={() => handleTabChange('project')}>
        projects
      </ThumbnailTab>
      <ThumbnailTab
        className={activeTab === 'profile' && 'active active-right'}
        onClick={() => handleTabChange('profile')}
      >
        profile cover
      </ThumbnailTab>
    </ThumbnailTabsWrapper>
  );

  const handleSave = () => {
    const updatedCoverImage = coverImage || selectedImage;
    onSave(updatedCoverImage);
    closePortal();
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const { data } = await jobService.getImages(talentId);
        setImages(data.data);
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);
  const filteredImages = images.filter(item => item.type === activeTab);

  return (
    <TxplModal
      title="Job Thumbnail"
      titleMB={8}
      subTitle="Select one or multiple images to represent this job. Use project-related pictures, company-related photos or basically whatever you want."
      padding="0 0 80px 0"
      name={name}
      appElement={document.getElementById('root-modal')}
      width
      allowLine
      size="large"
      addPaddingTopBig
      renderFooter={
        <>
          <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)" forceDisplayText mr={6}>
            Cancel
          </ContainedButton>
          <ContainedButton onClick={handleSave} backgroundColor="#7266EE" forceDisplayText>
            {selectFromProfileMode ? 'use selected' : 'save'}
          </ContainedButton>
        </>
      }
    >
      <ModalContent>
        {loading && <Spinner />}
        {!loading && (
          <SelectFromProfileButton
            onClick={() => setSelectFromProfileMode(!selectFromProfileMode)}
            disabled={coverImage}
          >
            {selectFromProfileMode ? 'upload new image' : 'select from profile images'}
          </SelectFromProfileButton>
        )}
        {selectFromProfileMode ? (
          renderTabs()
        ) : (
          <FormUpload
            label="Upload new image"
            hintText="Should be at least 700x400 pixels. PNG, JPG, GIF, 500Kb or less."
            // error={errors.coverImage}
            value={coverImage || null}
            onChange={val => setCoverImage(val)}
            preview
            image={coverImage || null}
            wrapperStyles={{ flexDirection: 'column', width: '100%' }}
            labelRight
            padding
            customPadding="24px 0 0 0"
            onRemoveClick={() => {
              setCoverImage('');
              setSelectedImage('');
            }}
            aspectRatio={7 / 4}
          />
        )}
        {selectFromProfileMode && data && (
          <JobThumbnailGallery images={filteredImages} selected={selectedImage} setSelected={setSelectedImage} />
        )}
      </ModalContent>
    </TxplModal>
  );
};

export default withRenderPortal('job-thumbnail-modal')(JobThumbnailModal);
