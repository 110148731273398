import React from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import commonStyles from 'utils/common-styles';
import { backOfficeActions } from 'store/backOffice';
import { userActions, userSelectors } from 'store/user';
import { portalActions } from 'store/portals';
import useQuery from 'hooks/useQuery';
import LinkWithIcon from 'common/LinkWithIcon';
import ContainedButton from 'common/ContainedButton';
import { Column, Table } from 'components/Table';
import { funnelStatusesEnum, reasons } from '../../constants';
import BackofficePanel from './BackofficePanel';
import ActivityStatus from './ActivityStatus';
import SupplyDemandStatus from './SupplyDemandStatus';
import ActionLoginAs from './actions/ActionLoginAs';
import ActionProfileActivation from './actions/ActionProfileActivation';
import ActionResetPassword from './actions/ActionResetPassword';
import VerificationStatus from './BackOfficeUsers/VerificationStatus';
import useProfilePath from 'hooks/useProfilePath';
import FunnelStatus from './FunnelStatus';
import first from 'lodash/fp/first';

const StyledBackOfficeUserDetailsContainer = styled.div`
  display: grid;
  grid-row-gap: 80px;
  margin-bottom: 80px;
`;

const StyledBackOfficePanel = styled(BackofficePanel)`
  width: 550px;
`;

const StyledSectionContainer = styled.div`
  border-top: 2px solid rgba(204, 213, 255, 0.11);
`;

const StyledStatusesSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid rgba(204, 213, 255, 0.11);
  gap: 20px;
`;

const StyledSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const StyledSectionTitle = styled.h2`
  ${commonStyles.ui_heading_2}
  margin-top: 40px;
  margin-bottom: 32px;
  grid-column: 1/-1;
`;

const Label = styled.div`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.35);
`;

const StyledActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
`;
// COMMENTED FOR NOW
// const LimitText = styled.div`
// word-wrap: break-word;
// width: 300px;
// `
// const StyledTooltip = styled(Tooltip)`
//   .__react_component_tooltip.show {
//     max-width:200px;
//     word-break:break-all;
//     border: 1px solid rgba(204, 213, 255, 0.22) !important;
//   }
// `

const BackOfficeUserDetails = () => {
  const user = useSelector(userSelectors.selectUser);
  const talent = user?.activeProfile;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { getPathByProfile } = useProfilePath();

  useQuery({
    url: `/backoffice/user/${params.userId}`,
    onSuccess: ({ user: item }) => {
      dispatch(userActions.initializeUser(item));
      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'Operations' },
          { label: 'Users', url: '/backoffice/operations/users' },
          { label: `${item.firstName} ${item.lastName}` }
        ])
      );
    }
  });

  const verificationStatus = React.useMemo(
    () => user?.verificationStatusLogs?.[0] || {},
    [user.verificationStatusLogs]
  );

  const openStatusModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'activity-status-modal',
        data: {
          on: 'user',
          userId: user._id,
          status: user.status
        }
      })
    );
  };

  // COMMENTED FOR NOW
  // const openFunnelStatusModal = () => {
  //   dispatch(portalActions.openPortal({
  //     name: 'funnel-status-modal',
  //     data: {
  //       on: 'user',
  //       userId: user._id,
  //       status: funnelStatus.status,
  //       additionalComment: funnelStatus?.additionalComment,
  //     }
  //   }))
  // }

  const openVerificationStatusModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'id-verification-status-modal',
        data: {
          userId: user._id,
          status: verificationStatus
        }
      })
    );
  };

  const openUserInfoModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'backOffice-user-info-modal',
        data: {
          userId: user._id,
          showPublicUrl: false,
          user,
          talent
        }
      })
    );
  };

  const latestStatusLog = React.useMemo(() => {
    const { statusLogs = [] } = user;

    // COMMENTED FOR NOW
    // if (!statusLogs.length) return null

    const lastLog = statusLogs[statusLogs.length - 1] || {};
    const reason = (user.status && lastLog && reasons[user.status]?.find(curr => curr.value === lastLog.reason)) || {};

    return (
      <>
        <Label>Reason</Label> <div>{reason.label}</div>
        <Label>Additional comments</Label> <div>{lastLog.additionalComment}</div>
      </>
    );
  }, [user]);

  // COMMENTED FOR NOW
  // const funnelStatus = React.useMemo(
  //   () => user?.funnelStatusLogs?.[user?.funnelStatusLogs?.length - 1] || {},
  //   [user.funnelStatusLogs]
  // )

  return (
    <StyledBackOfficeUserDetailsContainer>
      <StyledSectionContainer>
        <StyledSectionTitle>User</StyledSectionTitle>
        <StyledBackOfficePanel title="User Info" onEdit={openUserInfoModal}>
          <Label>Candidate ID</Label>
          <div>{user?.candidateId}</div>
          <Label>User ID</Label>
          <div>{user._id}</div>
          <Label>First name</Label>
          <div>{user.firstName}</div>
          <Label>Last name</Label>
          <div>{user.lastName}</div>
          <Label>Email</Label>
          <div>{user.email}</div>
        </StyledBackOfficePanel>
      </StyledSectionContainer>

      <StyledStatusesSectionContainer>
        <StyledSection>
          <StyledSectionTitle>Statuses</StyledSectionTitle>
          <StyledBackOfficePanel title="Activity status" onEdit={openStatusModal}>
            <Label>Current status</Label> <ActivityStatus status={user.status} />
            {latestStatusLog}
            <div />{' '}
            <LinkWithIcon icon="info">
              <Link to={`/backoffice/operations/users/${user._id}/status-log`}>Show status log ↗︎</Link>
            </LinkWithIcon>
          </StyledBackOfficePanel>

          <StyledBackOfficePanel title="ID verification status" onEdit={openVerificationStatusModal}>
            <Label>Current Status</Label> <VerificationStatus verificationStatus={verificationStatus.status} />
            <Label>Reason</Label> <div>{verificationStatus.reason}</div>
            <Label>Additional comments</Label> <div>{verificationStatus.additionalComment}</div>
            <div />{' '}
            <LinkWithIcon icon="info">
              <Link to={`/backoffice/operations/users/${user._id}/id-verification-logs`}>Show status log ↗︎</Link>
            </LinkWithIcon>
          </StyledBackOfficePanel>
        </StyledSection>

        <StyledSection>
          {/* COMMENTED FOR NOW */}
          {/* <StyledSectionTitle>Funnel Statuses</StyledSectionTitle>
          <StyledBackOfficePanel title="User Info" onEdit={openFunnelStatusModal}>
            <Label>Funnel status</Label> <FunnelStatus status={funnelStatusesEnum[funnelStatus.status]} value={funnelStatus.status} />
            <Label>Additional comments</Label> 
             <LimitText data-tip data-for={"additionalcomment"}>{`${funnelStatus?.additionalComment?.length > 200 ? funnelStatus?.additionalComment?.substr(0,200) + '...' : funnelStatus?.additionalComment}`}</LimitText>
             {funnelStatus?.additionalComment?.length > 200 && <StyledTooltip tooltipId={"additionalcomment"} place="right" >{funnelStatus?.additionalComment}</StyledTooltip>}
            <div /> <LinkWithIcon icon="info"><Link to={`/backoffice/operations/users/${user._id}/funnel-log`}>Show funnel log ↗︎</Link></LinkWithIcon>
          </StyledBackOfficePanel> */}
        </StyledSection>
      </StyledStatusesSectionContainer>

      <StyledSectionContainer>
        <StyledSectionTitle>Accounts</StyledSectionTitle>
        <Table keyId="_id" dataKey="talents" staticData={user.accounts || []} noSort adjustHeight>
          <Column title="Account ID" keyName="_id" />
          <Column title="Name" keyName="name" />
          <Column title="Type" render={profile => (profile.type === 'talent' ? 'Soloist' : 'Company')} />
          <Column
            title="Public profile URL"
            render={profile => (
              <Link to={`${getPathByProfile(profile)}/about`}>/{profile.talentId ?? profile.publicUrl}</Link>
            )}
          />
          <Column
            title="Supply / Demand"
            keyName="isSupply"
            render={profile => (
              <>
                <SupplyDemandStatus isSupply={profile.isSupply ?? profile.supplyDemand} />
              </>
            )}
          />
          <Column
            title="Funnel Status"
            keyName="status"
            render={talent => (
              <FunnelStatus
                value={first(talent?.funnelStatusLogs)?.status}
                status={funnelStatusesEnum[first(talent?.funnelStatusLogs)?.status] || 'Account Created'}
              />
            )}
          />
          <Column
            title="Activity Status"
            keyName="status"
            render={talent => <ActivityStatus status={talent.status} />}
          />
          <Column
            title=""
            width={50}
            type="action"
            render={item => (
              <ContainedButton
                icon="visit"
                size={13}
                backgroundColor="transparent"
                onClick={() =>
                  history.push(`/backoffice/operations/${item.type === 'company' ? 'company' : 'soloists'}/${item._id}`)
                }
              />
            )}
          />
        </Table>
      </StyledSectionContainer>

      <StyledSectionContainer>
        <StyledSectionTitle>Actions</StyledSectionTitle>

        <StyledActionsContainer>
          <ActionLoginAs userId={user._id} />
          <ActionProfileActivation userId={user._id} />
          <ActionResetPassword userId={user._id} />
        </StyledActionsContainer>
      </StyledSectionContainer>
    </StyledBackOfficeUserDetailsContainer>
  );
};

export default BackOfficeUserDetails;
