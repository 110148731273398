import { get, sortBy } from 'lodash/fp';

export const isValidEmail = email => {
  const regEx = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  return regEx.test(email);
};

export const isValidPublicUrl = str => {
  const regEx = /^[\w]{3,}$/;
  return regEx.test(str);
};

export const isStrongPassword = pass => {
  const passwordRegex = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/;
  return passwordRegex.test(pass);
};

export const secondsToDate = seconds => {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(seconds);
  return t.toDateString();
};

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${base}?${query}`;
};

export const buildCoverUrl = url => {
  if (isValidUrl(url)) {
    return url;
  } else if (process.env.REACT_APP_GAME_COVERS_CDN_ENDPOINT) {
    return process.env.REACT_APP_GAME_COVERS_CDN_ENDPOINT + url;
  } else {
    return process.env.REACT_APP_COVERS_BUCKET + url;
  }
};

export const getDate = dt => {
  try {
    const d = new Date(dt);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${month} ${day}, ${year}`;
  } catch (e) {
    console.log('Error on getDate() function: ', e);
  }
  return '';
};

export function isValidUrl(value) {
  const pattern = /^(http|https):\/\/[^ "]+(\.[a-z]{2,})$/;

  return pattern.test(value);
}

export const getCoverImageUrl = imageId => {
  if (!imageId) {
    return '';
  }
  if (imageId && imageId.startsWith('/assets/temp/')) {
    return imageId;
  }

  return imageId.includes('t_thumb')
    ? `https:${imageId.replace('t_thumb', 't_cover_big')}`
    : `https://images.igdb.com/igdb/image/upload/t_cover_big/${imageId}.jpg`;
};

export const isFullPage = url => {
  const fullPages = [
    '',
    '/home',
    '/login',
    '/account-deleted',
    '/reset-password',
    '/forgot-password',
    '/splash-page',
    '/accept-invitation',
    '/activate-account',
    '/collaboration-request'
  ];
  if (!url) {
    return false;
  }
  const urlToCheck = url.replace(/\/$/, '');
  return fullPages.includes(urlToCheck.toLowerCase());
};

export const isBackOffice = url => {
  if (!url) {
    return false;
  }
  return url.includes('/backoffice');
};

export const sortInputFirst = (data, field = '', input) => {
  const first = [];
  const others = [];
  const lowerInput = (input || '').toLowerCase();
  for (let i = 0; i < data.length; i++) {
    if (parseInt((get(field, data[i]) || '').toLowerCase().indexOf(lowerInput)) === 0) {
      first.push(data[i]);
    } else {
      others.push(data[i]);
    }
  }
  const sortedFirst = sortBy('name', first);
  const sortedOthers = sortBy('name', others);
  return sortedFirst.concat(sortedOthers);
};

const roundToPrecision = number => Math.round((number + Number.EPSILON) * 10) / 10;

const roundToK = number => {
  if (isNaN(number)) return '';
  if (number < 1000) return `${Math.round(number)}`;
  const out = `${roundToPrecision(number / 1000)}`.replace('.0', '');
  return `${out}K`;
};

export const selectOptionsToArrayOfStrings = selectOptions =>
  selectOptions ? selectOptions.map(option => option.value) : [];

export const processValidationErrors = err => {
  const errors = err.response?.data?.errors;
  if (errors && errors.length) {
    return errors.reduce((curr, item) => {
      return Object.assign(curr, { [item.param]: item.msg });
    }, {});
  }
  if (err.response?.data?.field) {
    return {
      [err.response?.data?.field]: err.response?.data?.message
    };
  }
  if (err.response?.data.error) {
    return {
      message: err.response?.data.error
    };
  }
  return err;
};

export function isJSON(str, type = 'object') {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null && type === 'object') {
      return true;
    }
    if (obj && typeof obj === 'string' && obj !== null && type === 'string') {
      return true;
    }
  } catch (err) {}
  return false;
}

export function parseJson(str, type = 'string') {
  return isJSON(str, type) ? JSON.parse(str) : str;
}

export function isOverflowing(el) {
  var curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

  var overflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverflow;

  return overflowing;
}

export function toSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/^-+|-+$/g, '');
}

export default {
  isValidEmail,
  isValidPublicUrl,
  isStrongPassword,
  secondsToDate,
  getURLWithQueryParams,
  getCoverImageUrl,
  getDate,
  isFullPage,
  isValidUrl,
  sortInputFirst,
  roundToK,
  selectOptionsToArrayOfStrings,
  processValidationErrors,
  isJSON,
  parseJson,
  isOverflowing,
  toSlug
};
