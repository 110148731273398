import React from 'react';
import styled from '@emotion/styled';

import Icon from 'common/Icon';

const StyledColumnItem = styled.td`
  height: 50px;
  text-align: center;
  width: 10%;
  border-right: 1px solid rgba(204, 213, 255, 0.11);
  cursor: pointer;
  ${props => props.width && `width: ${props.width}px;`}
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 32px;
  transition: all 0.5s ease-in;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;

  & path {
    fill: rgba(134, 139, 163, 0.35);
  }

  &:hover {
    & path {
      fill: white;
    }
  }
`;

const ActionColumn = ({ onClick = () => {}, opens, data, width }) => (
  <StyledColumnItem width={width}>
    <IconWrapper>
      <Icon onClick={onClick} opens={opens} icon="edit" data={data} />
    </IconWrapper>
  </StyledColumnItem>
);

export default ActionColumn;
