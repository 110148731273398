import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Icon from 'common/Icon';
import Flex from 'common/styles/Flex';
import TagItem from 'common/Tag/Tag';
import ContainedButton from 'common/ContainedButton';
import UserTopCredits from 'common/User/UserTopCredits';
import UserTopProjects from 'common/User/UserTopProjects';
import UserAtAGlance from 'common/User/UserAtAGlance';
import UserTags from 'common/User/UserTags';
import MoreLanguages from 'common/MoreLanguages';

import { portalActions } from 'store/portals';
import { selectMyProfile } from 'store/profile/profileSelectors';
import getBiosReducedName from 'utils/getBiosReducedName';
import useViewport from 'hooks/useViewport';
import useApplicantService from 'hooks/api/useApplicantService';
import HiddenAvatar from 'components/HiddenAvatar/HiddenAvatar';

import { Spacer } from 'pages/talent/Credits/EditCreditModalStyles';
import ApplicantComments from './ApplicantComments';
import ApplicantDropdown from './ApplicantDropdown';
import { profileSelectors } from 'store/profile';
import useProfilePath from '../../../../hooks/useProfilePath';
import * as Styled from '../../../../components/UserCard/StyledUserCard';
import {
  AbsoluteButtonWrapper,
  AbsoluteContractStatusWrapper,
  ApplicantDescription,
  ApplicantInfo,
  ApplicantName,
  AppliedInfo,
  Avatar,
  ContractStatus,
  Grid,
  StyledCard,
  UnderLinedText
} from './ApplicantCard.styled';
import { currentUserHasFFSelector } from 'store/selectors/currentUser';
import useCompanyRole from 'hooks/useCompanyRole';

const contractStatusOptions = {
  'in-draft': 'Proposal draft',
  'in-review': 'Changes pending',
  'pending-for-approval': 'Proposal sent',
  active: 'Accepted',
  rejected: 'Declined',
  'in-progress': 'Ongoing project',
  'on-hold': 'On hold',
  finished: 'Ended',
  cancelled: 'Canceled',
  expired: 'Expired'
};

const contractStatusIcon = {
  'in-draft': 'purpleDot',
  'pending-for-approval': 'orangeDot',
  'in-progress': 'orangeDot',
  'in-review': 'orangeDot',
  'on-hold': 'orangeDot',
  expired: 'redDot',
  active: 'greenDot',
  finished: 'cyanDot',
  cancelled: 'redDot',
  rejected: 'redDot'
};

const ApplicantCard = ({
  applicant,
  biasReducedMode = false,
  handleMessageClick,
  isExtended = false,
  isListView,
  job,
  jobId,
  enableCompanyProposal,
  isCompany,
  activeProfile,
  isCompanyOwner
}) => {
  const talentprofile = useSelector(profileSelectors.selectMyTalentProfile);
  const { hiredApplicant, savedApplicant } = applicant;
  const defaultAvatar = `${process.env.PUBLIC_URL}/assets/temp/temp_default_avatar.png`;
  const { isXS } = useViewport();
  const initialComments =
    job?.comment?.filter(comment => !comment?.deletedAt && comment.talentId === applicant._id) || [];
  const [comments, setComments] = React.useState(initialComments);
  const [showMore, setShowMore] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(savedApplicant);
  const [isHired, setIsHired] = React.useState(hiredApplicant);
  const [newApplication, setNewApplication] = useState(false);
  const [applicationDate, setApplicationDate] = useState('');
  const { changeApplicantStatus, commentOnApplicant, deleteComment, editComment, hireApplicant, unrejectApplicant } =
    useApplicantService();

  const userHasWalletEnabled = useSelector(currentUserHasFFSelector('hasWalletEnabled'));
  const userHasContractEnabled = useSelector(currentUserHasFFSelector('hasContractEnabled'));

  const openCompanyModal = isCompany ? true : false;
  const modalToOpen = isCompanyOwner ? 'company-contract-modal' : 'admin-contract-modal';

  const jobType = job?.details?.jobType;

  let rejected = !!applicant?.rejectedApplicant;

  const cardRef = React.useRef();
  const profile = useSelector(selectMyProfile);
  const userProfile = useSelector(profileSelectors.selectProfile);
  const dispatch = useDispatch();
  const { getProfilePath } = useProfilePath();

  const { isMember } = useCompanyRole();

  const extendedView = isExtended || (!isListView && !isXS);

  const hasProjects = !!applicant?.projects?.length;
  const hasCredits = !!applicant?.credit?.length;

  const hasExperience = !!applicant.atGlanceYears;
  const hasGamesShipped = !!applicant.gamesShipped;
  const hasAAAGames = !!applicant.aaaGames;
  const hasIndieGames = !!applicant.indieGames;

  const hasTags = !!applicant?.tag?.length;
  const hasSkills = !!applicant?.about?.skills?.length;
  const hasGenres = !!applicant?.genre?.length;

  const first3 = comments.slice(0, 3);
  const rest = comments.slice(3);

  const [dataHidden, setDataHidden] = React.useState(userProfile.type === 'talent' ? biasReducedMode : false);
  const changeMode = React.useCallback(() => setDataHidden(latest => !latest), [setDataHidden]);
  React.useEffect(() => setDataHidden(biasReducedMode), [biasReducedMode]);

  useEffect(() => {
    job &&
      job.newAppliedBy.forEach(newApplicant => {
        if (applicant?.id === newApplicant?.talentId) {
          setNewApplication(true);
        }
      });

    job &&
      job.appliedBy.forEach(app => {
        if (applicant?.userId === app?.appliedData?.creator) {
          setApplicationDate(app.appliedData.createdAt);
        }
      });
  }, [job, applicant]);

  const handleSave = () => {
    const status = isSaved ? 'unsave' : 'saved';
    changeApplicantStatus(applicant._id, jobId, status, () => {
      setIsSaved(!isSaved);
    });
  };

  const handleContract = async () => {
    await hireApplicant(applicant._id, jobId, '', response => {
      setIsHired(!isHired);
      setIsSaved(!isSaved);
      const url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${response.contractId}/edit`;
      window.location.href = url;
    });
  };

  const updateComments = comment => {
    const newComments = comments;
    newComments.push(comment);

    setComments(newComments);
  };

  const handleComment = text => {
    const creatorId = talentprofile?._id;
    commentOnApplicant(creatorId, applicant._id, profile.basicInfo.imageUri.url, profile.name, jobId, text, res => {
      const { name, img, date, text, _id } = res.data;
      updateComments({ name, img, date, text, _id });

      cardRef.current && cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    });
  };

  const openCommentModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'text-modal',
        data: {
          applicantId: applicant._id,
          callApi: handleComment
        }
      })
    );
  };

  const handleEditComment = (comment, text) => {
    editComment(jobId, comment._id, text);
  };

  const editCommentModal = comment => {
    dispatch(
      portalActions.openPortal({
        name: 'text-modal',
        data: {
          applicantId: applicant._id,
          initialText: comment.text,
          label: 'Edit a note',
          callApi: text => handleEditComment(comment, text),
          onSuccess: text => {
            const editedComment = {
              ...comment,
              text
            };

            setComments(comments.map(item => (item._id === editedComment._id ? editedComment : item)));
          }
        }
      })
    );
  };

  const handleCommentDelete = async (jobId, commentId) => {
    await deleteComment(jobId, commentId, () => {
      const newComments = comments;

      setComments(newComments.filter(({ _id }) => _id !== commentId));
    });
  };

  const openApplicantCardModal = () => {
    dispatch(
      portalActions.openPortal({
        name: 'applicant-card-modal',
        data: {
          applicant,
          biasReducedMode: dataHidden,
          job
        }
      })
    );
  };

  const closeApplicantCardModal = () => {
    dispatch(
      portalActions.closePortal({
        name: 'applicant-card-modal'
      })
    );
  };

  const removeFromRejected = () => {
    unrejectApplicant(applicant?._id || applicant?._id, jobId).then(() => {
      rejected = false;
    });
  };

  const useContracting = process.env.REACT_APP_FF_USE_CONTRACTING === 'true';

  return (
    <StyledCard ref={cardRef}>
      <AbsoluteContractStatusWrapper>
        {applicant?.hiredApplicant && (
          <div style={{ marginLeft: '16px' }}>
            <Styled.StyledIcon icon={contractStatusIcon[applicant?.contractStatus]} marginLeft={'10px'} />
            <ContractStatus>
              {applicant?.contractStatus && contractStatusOptions[applicant?.contractStatus]}
            </ContractStatus>
          </div>
        )}
      </AbsoluteContractStatusWrapper>
      <AbsoluteButtonWrapper className={'buttons-wrapper'}>
        <Flex gap={6}>
          {isListView && !isExtended && (
            <ContainedButton className="transparent" icon="zoom-in" onClick={openApplicantCardModal} />
          )}
          {userHasWalletEnabled && userHasContractEnabled && applicant.savedApplicant && (
            <ContainedButton
              id={'create-contract'}
              icon={'like'}
              data={{ job, jobId, isCompany, isCompanyOwner, activeProfile, applicant }}
              disabled={!useContracting || jobType !== 'contractor' || !enableCompanyProposal || isMember}
              opens={openCompanyModal && modalToOpen}
              tooltipId={'create-contract'}
              onClick={event => !isCompany && handleContract(event)}
              background="#FF5151"
            >
              Create Proposal
            </ContainedButton>
          )}
          {!applicant.savedApplicant && !applicant.hiredApplicant && (
            <ContainedButton
              backgroundColor="rgba(204, 213, 255, 0.11)"
              icon={'like'}
              disabled={rejected || isMember}
              data={{ jobId }}
              onClick={handleSave}
            >
              Shortlist
            </ContainedButton>
          )}
          {!applicant?.hiredApplicant && (
            <ContainedButton
              id={'rejected-' + applicant._id}
              icon="thumbs-down"
              opens={!rejected && 'reject-application-modal'}
              className={rejected ? 'red' : 'transparent'}
              onClick={rejected && removeFromRejected}
              tooltip={rejected ? 'Remove from rejected' : 'Reject'}
              tooltipId={'rejected-' + applicant._id}
              data={{ applicantId: applicant?._id || applicant?.id, jobId, applicantName: applicant.name }}
            />
          )}

          <ApplicantDropdown
            applicantId={applicant._id}
            applicantPublicUrl={applicant.url}
            applicantType={applicant.type}
            jobId={jobId}
            applicantName={applicant?.name}
            isHired={hiredApplicant}
            openCommentModal={openCommentModal}
            biasReducedMode={biasReducedMode}
            handleMessage={handleMessageClick}
            applicant={applicant}
            isMember={isMember}
          />

          {isListView && isExtended && (
            <ContainedButton className="transparent" icon="close" onClick={closeApplicantCardModal} />
          )}
        </Flex>
      </AbsoluteButtonWrapper>
      <Flex column gap={16} flexGrow={1}>
        <ApplicantInfo>
          <div>
            {!dataHidden && <Avatar onClick={changeMode} src={applicant?.basicInfo?.imageUri?.url ?? defaultAvatar} />}
            {dataHidden && <HiddenAvatar changeMode={changeMode} credit={applicant.credit} />}
          </div>
          <Flex column gap={16} flexGrow={1}>
            <Flex row alignCenter gap={10}>
              <ApplicantName
                to={`${getProfilePath(
                  applicant?.talentId ?? applicant?.url ?? applicant?._id,
                  false
                )}/about?bios-reduced=${biasReducedMode}`}
                target="_blank"
                variant="h2"
              >
                {applicant?.name
                  ? getBiosReducedName({ name: applicant.name, isBiosReduced: dataHidden })
                  : 'Without Name'}
              </ApplicantName>
              {rejected && (
                <TagItem
                  fontFamily={'Inter'}
                  font-size={11}
                  backgroundColor="#ff5151"
                  color="#111112"
                  fontWeight={400}
                  style={{ marginRight: '10px' }}
                  lineHeight={'14px'}
                  borderRadius={'6px'}
                  padding={'2px 6px'}
                  height={'18px'}
                >
                  Rejected
                </TagItem>
              )}
              {applicant?.theX && (
                <ApplicantDescription>
                  <Icon mr={8} icon="the-x" color="rgba(230, 234, 255, 0.35)" hoverColor="rgba(230, 234, 255, 0.35)" />
                  {applicant.theX}
                </ApplicantDescription>
              )}
            </Flex>
            <Flex column justifyStart className="wrap" gap={6} alignCenter>
              <Styled.FlexRowLocation isCompanyView={false} width={'100%'} marginBottom={'0px'}>
                {newApplication && (
                  <TagItem
                    fontFamily={'Inter'}
                    fontSize={'11px'}
                    lineHeight={'14px'}
                    backgroundColor="#00FF00"
                    color="#111112"
                    fontWeight={400}
                    borderRadius={'4px'}
                    style={{ marginRight: '10px' }}
                    padding={'2px 6px'}
                  >
                    New
                  </TagItem>
                )}
                <AppliedInfo>Applied {moment(applicant.appliedJobOn).fromNow()}</AppliedInfo>

                {applicant?.basicInfo?.location && !dataHidden && (
                  <>
                    <Styled.StyledIcon
                      icon="separator-dot"
                      color="rgba(230, 234, 255, 0.6)"
                      marginLeft={'8px'}
                      marginRight={'8px'}
                    />
                    <Styled.StyledIcon icon="location" color="rgba(230, 234, 255, 0.6)" marginRight={'8px'} />
                    <Styled.LocationContainer>{applicant?.basicInfo?.location}</Styled.LocationContainer>
                  </>
                )}
                {applicant?.basicInfo?.location && dataHidden && (
                  <>
                    <Styled.StyledIcon
                      icon="separator-dot"
                      color="rgba(230, 234, 255, 0.6)"
                      marginLeft={'8px'}
                      marginRight={'8px'}
                    />
                    <Styled.StyledIcon icon="location" color="rgba(230, 234, 255, 0.6)" marginRight={'8px'} />
                    <Styled.LocationContainer>Location hidden</Styled.LocationContainer>
                  </>
                )}

                {applicant?.basicInfo?.languages && !!applicant?.basicInfo?.languages.length && (
                  <>
                    <Styled.StyledIcon
                      icon="separator-dot"
                      color="rgba(230, 234, 255, 0.6)"
                      marginLeft={'8px'}
                      marginRight={'8px'}
                    />
                    <Styled.Languages title={applicant?.basicInfo?.languages}>
                      <Styled.StyledIcon icon="languages" color="rgba(230, 234, 255, 0.6)" marginRight={'6px'} />
                      <MoreLanguages languages={applicant?.basicInfo?.languages} initialLength={2} />
                    </Styled.Languages>
                  </>
                )}
              </Styled.FlexRowLocation>
            </Flex>
            <Flex className="wrap" gap={6}>
              {!!applicant?.topOverall && (
                <TagItem icon="about" backgroundColor="#00FF00" color="#111112">
                  Top overall match
                </TagItem>
              )}
              {!!applicant?.topPortfolio && (
                <TagItem icon="portfolio" backgroundColor="#00FF00" color="#111112">
                  Top portfolio match
                </TagItem>
              )}

              {applicant?.basicInfo?.roles?.map(({ name }, key) => (
                <TagItem key={key} fontSize="14px" borderRadius={'8px'} padding={'2px 6px'} lineHeight={'22px'}>
                  {name}
                </TagItem>
              ))}
            </Flex>
          </Flex>
        </ApplicantInfo>

        {extendedView && (
          <>
            {(hasProjects || hasCredits) && (
              <>
                <Spacer margin="16px 0" />

                <Grid>
                  <UserTopProjects projects={applicant.projects} limit={1} />
                  <UserTopCredits credits={applicant.credit} limit={3} />
                </Grid>
              </>
            )}

            {(hasExperience || hasGamesShipped || hasAAAGames || hasIndieGames) && (
              <>
                <Spacer margin="16px 0" />

                <Grid>
                  <UserAtAGlance
                    experience={applicant.atGlanceYears}
                    gamesShipped={applicant.gamesShipped}
                    aaaGames={applicant.aaaGames}
                    indieGames={applicant.indieGames}
                  />
                </Grid>
              </>
            )}

            {(hasTags || hasSkills || hasGenres) && (
              <>
                <Spacer margin="16px 0" />

                <UserTags title="Tags" tags={applicant.tag} />
                <UserTags title="Skills" tags={applicant?.about?.skills} />
                <UserTags title="Genres" tags={applicant.genre} />
              </>
            )}
          </>
        )}

        {!!comments?.length && (
          <>
            <Spacer margin="16px 0" />
            <Flex column>
              {
                <ApplicantComments
                  comments={first3}
                  jobId={jobId}
                  onEdit={editCommentModal}
                  onDelete={handleCommentDelete}
                />
              }
              {!!rest.length && (
                <>
                  {!showMore ? (
                    <UnderLinedText onClick={() => setShowMore(true)}>
                      See all {comments?.length} comments
                    </UnderLinedText>
                  ) : (
                    <ApplicantComments
                      comments={rest}
                      jobId={jobId}
                      onEdit={editCommentModal}
                      onDelete={handleCommentDelete}
                    />
                  )}
                </>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </StyledCard>
  );
};

export default ApplicantCard;
