import React, { useState } from 'react';
import styled from '@emotion/styled';
import ContainedButton from '../ContainedButton';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;

  & button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
  }
  & button:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & button:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &.with-border {
    border: 2px solid rgba(204, 213, 255, 0.11);
    border-radius: 8px;
    display: flex;
    padding: 2px;
    width: max-content;
    button.switcher-button {
      padding: 8px 14px;
    }
  }
`;

const StyledButton = styled(ContainedButton)`
  &:hover {
    ${({ disabled }) => !disabled && 'background-color: rgba(204, 213, 255, 0.05);'}
  }
`;

const FormSwitcher = ({ backgroundColor, disabled, initialValue, limit, onChange, options, ...rest }) => {
  const [active, setActive] = useState(initialValue || options[0]?.value);

  const handleChange = val => () => {
    if (onChange) onChange(val);
    setActive(val);
  };

  const firstLimit = limit ? options.slice(0, limit) : options;
  const restLimit = limit ? options.slice(limit) : [];

  return (
    <StyledContainer {...rest}>
      {firstLimit?.map(option => (
        <StyledButton
          className="switcher-button"
          onClick={handleChange(option.value)}
          key={option.value}
          disabled={disabled ?? option?.disabled}
          tooltip={option?.tooltip ?? null}
          tooltipId={option?.tooltipId}
          backgroundColor={
            active === option.value ? 'rgba(204, 213, 255, 0.11)' : `${backgroundColor || 'rgba(204, 213, 255, 0.11)'};`
          }
          color={active === option.value ? '#FFFFFF' : 'rgba(230, 234, 255, 0.35);'}
          noBorder
          icon={option?.icon}
          indicator={option?.indicator}
          tooltipSecondary
        >
          {option?.name}
        </StyledButton>
      ))}
      {!!restLimit.length && (
        <DropDown className="transparent text-uppercase" text="more" icon="arrow-down">
          {restLimit.map(option => (
            <DropDownItem
              onClick={handleChange(option.value)}
              key={option.value}
              disabled={disabled ?? option?.disabled}
              tooltip={option?.tooltip ?? null}
              tooltipId={option?.tooltipId}
              backgroundColor={
                active === option.value
                  ? 'rgba(204, 213, 255, 0.11)'
                  : `${backgroundColor || 'rgba(204, 213, 255, 0.11)'};`
              }
              color={active === option.value ? '#FFFFFF' : 'rgba(230, 234, 255, 0.35);'}
              noBorder
              icon={option?.icon}
              tooltipSecondary
              padding="8px"
            >
              {option.name}
            </DropDownItem>
          ))}
        </DropDown>
      )}
    </StyledContainer>
  );
};

export default FormSwitcher;
