import { useSelector } from 'react-redux';
import { selectIsMyProfileCompany, selectMyProfile } from 'store/profile/profileSelectors';

const useProfilePath = () => {
  const myProfile = useSelector(selectMyProfile);
  const isMyProfileCompany = useSelector(selectIsMyProfileCompany);

  const getProfilePath = (publicUrl, isCompany) => `/${isCompany ? 'company' : 'talent'}/${publicUrl}`;

  const getPathByProfile = profile =>
    `/${profile.type === 'talent' ? 'talent' : 'company'}/${profile.publicUrl ?? profile.talentId}`;

  return {
    myProfilePath: getProfilePath(myProfile?.publicUrl, isMyProfileCompany),
    getProfilePath,
    getPathByProfile
  };
};

export default useProfilePath;
