import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from 'store/portals';
import { profileSelectors } from 'store/profile';
import useAppliedJobService from './api/useAppliedJobService';

function useApplyToJob(options) {
  const { applyToJobCallback = () => ({}) } = options ?? {};

  const dispatch = useDispatch();

  const mySoloictProfile = useSelector(profileSelectors.selectMyProfile);
  const isMyProfileComapny = useSelector(profileSelectors.selectIsMyProfileCompany);
  const {
    applyToJob,
    loading: { loadingApply }
  } = useAppliedJobService();

  const applyProfileToJob = async jobId => {
    if (isMyProfileComapny) {
      dispatch(
        portalActions.openPortal({
          name: 'apply-as-a-soloist',
          data: {
            jobId,
            applyToJobCallback
          }
        })
      );
    } else {
      await applyToJob(mySoloictProfile._id, jobId, applyToJobCallback);
    }
  };

  return {
    loadingApply,
    applyProfileToJob
  };
}

export default useApplyToJob;
