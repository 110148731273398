/* eslint-disable unused-imports/no-unused-vars */
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import useProfilePath from 'hooks/useProfilePath';
import { useSuccessSnackbar } from 'hooks/useReactSnackbar';
import React, { useMemo } from 'react';
import useStartConversation from '../../../../hooks/chatApi/useStartConversation';
import { portalActions } from '../../../../store/portals';
import { useDispatch } from 'react-redux';
import jobService from '../../../../api/jobService';
import { applicantsActions } from '../../../../store/applicants';
import { curry } from 'ramda';
import useCompanyRole from 'hooks/useCompanyRole';

const isOneOf = curry((options, value) => {
  return options.includes(value);
});

const ApplicantDropdown = ({
  applicantId,
  applicantPublicUrl,
  applicantType,
  jobId,
  applicantName,
  openCommentModal,
  isHired,
  biasReducedMode,
  handleMessage,
  applicant
}) => {
  const { getProfilePath } = useProfilePath();
  const { openSuccessSnackbar } = useSuccessSnackbar();
  const { redirectToStartConversation } = useStartConversation();
  const { isMember, isCompanyProfile } = useCompanyRole();

  const applicantContractStatus = applicant?.contractStatus;

  const dispatch = useDispatch();

  const editContract = () => {
    let url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${applicant?.contractId}/edit`;
    if (isMember) {
      url = `${process.env.REACT_APP_NEXT_BASE_URL}/contracts/${applicant?.contractId}/review`;
    }
    window.location.href = url;
  };

  const confirmDelete = () => {
    dispatch(
      portalActions.openPortal({
        name: 'confirm-modal',
        data: {
          onConfirm: async () => {
            try {
              const res = await jobService.deleteContract(applicant?.contractId, jobId, applicantId);
              const { success } = res.data;
              if (success) {
                dispatch(applicantsActions.updateApplicantStatus({ jobId, applicantId, status: 'saved' }));
              }
            } catch (err) {
              console.log('error: ', err);
            }
          },
          title: 'Delete draft',
          description: 'Are you sure you want to delete this proposal draft'
        }
      })
    );
  };

  const confirmCancel = () => {
    dispatch(
      portalActions.openPortal({
        name: 'cancel-contract-modal',
        data: {
          contractId: applicant?.contractId,
          title: 'Cancel proposal',
          jobId,
          applicantId
        }
      })
    );
  };

  const talentPath = getProfilePath(applicantPublicUrl ?? applicantId, applicantType !== 'talent');

  //TODO: Check with Sebacattaneo the use of handlecopy
  // eslint-disable-next-line unused-imports/no-unused-vars
  const handleCopy = () => {
    const clipboard = `${window.location.origin}/app${talentPath}`;
    navigator.clipboard.writeText(clipboard);
    openSuccessSnackbar(`Profile link copied`);
  };

  const isCancellableContract = isOneOf(['in-progress', 'in-review', 'on-hold', 'active']);
  const isRehirableContract = isOneOf(['cancelled', 'in-progress', 'active']);
  const isEditableContract = isOneOf(['in-draft', 'in-review']);
  const isDeletableContract = isOneOf(['in-draft']);

  // TODO: put back these when cancel contract (with payments) is done
  // const canCancelContract = useMemo(
  //   () => isHired && isCancellableContract(applicant?.contractStatus),
  //   [isHired, applicant?.contractStatus]
  // );
  // const canRehire = useMemo(
  //   () => isHired && isRehirableContract(applicant?.contractStatus),
  //   [isHired, applicant?.contractStatus]
  // );

  const canCancelContract = false;
  const canRehire = false;

  const canEdit = useMemo(
    () => isHired && isEditableContract(applicant?.contractStatus),
    [isHired, applicant?.contractStatus]
  );
  const canDelete = useMemo(
    () => isHired && isDeletableContract(applicant?.contractStatus),
    [isHired, applicant?.contractStatus]
  );

  const startConversation = async () => {
    await jobService.saveContacted(applicantId, jobId);
    redirectToStartConversation(applicant);
  };

  return (
    <DropDown>
      {canEdit && (
        <DropDownItem icon="info" target="_blank" onClick={() => editContract()} disabled={isMember}>
          {isMember ? 'Review Proposal' : 'Edit Proposal'}
        </DropDownItem>
      )}
      <DropDownItem icon="message" target="_blank" onClick={startConversation} disabled={isMember}>
        Message
      </DropDownItem>
      <DropDownItem icon="edit" onClick={openCommentModal} disabled={isMember}>
        Leave a note
      </DropDownItem>
      {canDelete && (
        <DropDownItem icon="ignore" target="_blank" onClick={confirmDelete} disabled={isMember}>
          Delete Draft
        </DropDownItem>
      )}
      {canCancelContract && (
        <DropDownItem icon="ignore" target="_blank" onClick={confirmCancel} disabled={isMember}>
          Cancel Proposal
        </DropDownItem>
      )}
      {canRehire && (
        <DropDownItem icon="info" disabled target="_blank">
          Rehire
        </DropDownItem>
      )}
    </DropDown>
  );
};

export default ApplicantDropdown;
