import React from 'react';
import styled from '@emotion/styled';
import JobsHeader from '../commons/JobsHeader';
import AppliedJobs from './AppliedJobs';
import SavedJobs from './SavedJobs';
import SelectedJobs from './SelectedJobs';
import HiredJobs from './HiredJobs';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  flex-grow: 1;
`;

const MyJobs = ({ setStatus, status }) => (
  <Wrapper>
    <JobsHeader header="favorite" status={status} setStatus={setStatus} />
    {status === 'applied' && <AppliedJobs />}
    {status === 'saved' && <SavedJobs />}
    {status === 'selected' && <SelectedJobs />}
    {status === 'hired' && <HiredJobs />}
  </Wrapper>
);

export default MyJobs;
