export const statuses = [
  { value: 'created', label: 'Created' },
  { value: 'active', label: 'Active' },
  { value: 'suspended', label: 'Suspended' },
  { value: 'deleted', label: 'Deactivated/Deleted' }
];

export const idVerificationStatuses = [
  { value: 'verified', label: 'Verified' },
  { value: 'failed', label: 'Failed' },
  { value: 'stripe-response', label: 'In Progress, waiting Stripe response' },
  { value: 'support-needed', label: 'In Progress, Support needed' },
  { value: 'failed-retry', label: 'Failed, waiting for User to retry' },
  { value: 'reset', label: 'Verification Reset' },
  { value: 'compromised', label: 'Compromised' },
  { value: 'not-started', label: 'Blank/Not Started' }
];

export const flags = [
  { value: 'supply', label: 'Supply' },
  { value: 'demand', label: 'Demand' },
  { value: 'both', label: 'Both' }
];
export const companyFlags = [
  { value: 'supply', label: 'Supply' },
  { value: 'demand', label: 'Demand' },
  { value: 'both', label: 'Both' }
];

export const funnelStatuses = [
  { value: 'ACCOUNT_CREATED', label: 'Account Created' },
  { value: 'APPLICATION_STARTED_ID_INCOMPLETE', label: 'Application started - Id Incomplete' },
  { value: 'APPLICATION_STARTED_ID_COMPLETE', label: 'Application started - Id Complete' },
  { value: 'COLLABORATORS_PROVIDED', label: 'Collaborators Provided' },
  { value: 'VETTING_PENDING', label: 'Vetting Pending' },
  { value: 'VETTING_IN_PROGRESS', label: 'Vetting In Progress' },
  { value: 'VETTING_COMPLETE', label: 'Vetting Complete' }
];

export const funnelStatusesEnum = {
  ACCOUNT_CREATED: 'Account Created',
  APPLICATION_STARTED_ID_COMPLETE: 'Application started - Id Complete',
  APPLICATION_STARTED_ID_INCOMPLETE: 'Application started - Id Incomplete',
  COLLABORATORS_PROVIDED: 'Collaborators Provided',
  VETTING_PENDING: 'Vetting Pending',
  VETTING_IN_PROGRESS: 'Vetting In Progress',
  VETTING_COMPLETE: 'Vetting Complete',
  TDB: 'TBD'
};

export const vettingStatuses = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'COMPLETE', label: 'Complete' }
];

export const creditVettingOutcomes = [
  { value: 'PROBLEM', label: 'Problem - User review' },
  { value: 'FURTHER_EXAMINATION', label: 'Further Examination' },
  { value: 'FAIL', label: 'Fail' },
  { value: 'PASS', label: 'Pass' }
];

export const soloistVettingOutcomes = [
  { value: 'DECLINED', label: 'Declined' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'SECOND_REVIEW', label: 'Second Review' }
];

export const vettingOutcomes = [
  { value: 'FAIL', label: 'Fail' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'PROBLEM', label: 'Problem - User review' },
  { value: 'FURTHER_EXAMINATION', label: 'Further Examination' }
];

export const reasons = {
  active: [{ value: 'backOffice-manual-activation', label: 'Manual activation through the backoffice' }],
  created: [{ value: 'backOffice-manual-creation', label: 'Manual creation through the backoffice' }],
  suspended: [
    { value: 'not-authenticated-30-days', label: 'Authentication has not been performed for 30 days' },
    { value: 'policy-violation', label: 'User violated Terms of service (type 1)' }
  ],
  deleted: [
    { value: 'fraudulent-account', label: 'The account is fraudulent' },
    {
      value: 'consistent-policy-violation',
      label: 'The user has consistently perfomed badly or violated the policies'
    },
    { value: 'user-asked-to-delete', label: "The account is inactive and doesn't respond" }
  ]
};

export const roles = [
  { label: 'Member', value: 'member' },
  { label: 'Admin', value: 'admin' }
];

export const tagKeys = {
  platform: 'platforms',
  genre: 'genres',
  skill: 'skills',
  tool: 'tools',
  tag: 'tags',
  discipline: 'disciplines',
  role: 'roles',
  company: 'companyTypes',
  keyword: 'keywords'
};

export const explanataionVideosUrls = {
  collaborators: 'https://thexplace-assets.s3.us-west-1.amazonaws.com/Collaborations_Explanational_video.mp4',
  id_verification_first:
    'https://thexplace-assets.s3.us-west-1.amazonaws.com/ID_Verification_Explanational_video_1.mp4',
  id_verification_second:
    'https://thexplace-assets.s3.us-west-1.amazonaws.com/ID_Verification_Explanational_video_2.mp4'
};

export const locations = [
  {
    id: 'Pacific/Midway',
    name: 'Pacific/Midway (GMT -11:00)',
    country_code: 'um',
    offset: -39600
  },
  {
    id: 'Pacific/Pago_Pago',
    name: 'Pacific/Pago_Pago (GMT -11:00)',
    country_code: 'as',
    offset: -39600
  },
  {
    id: 'Pacific/Niue',
    name: 'Pacific/Niue (GMT -11:00)',
    country_code: 'nu',
    offset: -39600
  },
  {
    id: 'America/Adak',
    name: 'America/Adak (GMT -10:00)',
    country_code: 'us',
    offset: -36000
  },
  {
    id: 'Pacific/Tahiti',
    name: 'Pacific/Tahiti (GMT -10:00)',
    country_code: 'pf',
    offset: -36000
  },
  {
    id: 'Pacific/Rarotonga',
    name: 'Pacific/Rarotonga (GMT -10:00)',
    country_code: 'ck',
    offset: -36000
  },
  {
    id: 'Pacific/Honolulu',
    name: 'Pacific/Honolulu (GMT -10:00)',
    country_code: 'us',
    offset: -36000
  },
  {
    id: 'Pacific/Marquesas',
    name: 'Pacific/Marquesas (GMT -09:30)',
    country_code: 'pf',
    offset: -34200
  },
  {
    id: 'America/Sitka',
    name: 'America/Sitka (GMT -09:00)',
    country_code: 'us',
    offset: -32400
  },
  {
    id: 'Pacific/Gambier',
    name: 'Pacific/Gambier (GMT -09:00)',
    country_code: 'pf',
    offset: -32400
  },
  {
    id: 'America/Yakutat',
    name: 'America/Yakutat (GMT -09:00)',
    country_code: 'us',
    offset: -32400
  },
  {
    id: 'America/Juneau',
    name: 'America/Juneau (GMT -09:00)',
    country_code: 'us',
    offset: -32400
  },
  {
    id: 'America/Nome',
    name: 'America/Nome (GMT -09:00)',
    country_code: 'us',
    offset: -32400
  },
  {
    id: 'America/Anchorage',
    name: 'America/Anchorage (GMT -09:00)',
    country_code: 'us',
    offset: -32400
  },
  {
    id: 'America/Metlakatla',
    name: 'America/Metlakatla (GMT -09:00)',
    country_code: 'us',
    offset: -32400
  },
  {
    id: 'America/Los_Angeles',
    name: 'America/Los_Angeles (GMT -08:00)',
    country_code: 'us',
    offset: -28800
  },
  {
    id: 'America/Tijuana',
    name: 'America/Tijuana (GMT -08:00)',
    country_code: 'mx',
    offset: -28800
  },
  {
    id: 'America/Whitehorse',
    name: 'America/Whitehorse (GMT -08:00)',
    country_code: 'ca',
    offset: -28800
  },
  {
    id: 'America/Vancouver',
    name: 'America/Vancouver (GMT -08:00)',
    country_code: 'ca',
    offset: -28800
  },
  {
    id: 'America/Dawson',
    name: 'America/Dawson (GMT -08:00)',
    country_code: 'ca',
    offset: -28800
  },
  {
    id: 'Pacific/Pitcairn',
    name: 'Pacific/Pitcairn (GMT -08:00)',
    country_code: 'pn',
    offset: -28800
  },
  {
    id: 'America/Mazatlan',
    name: 'America/Mazatlan (GMT -07:00)',
    country_code: 'mx',
    offset: -25200
  },
  {
    id: 'America/Fort_Nelson',
    name: 'America/Fort_Nelson (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Yellowknife',
    name: 'America/Yellowknife (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Inuvik',
    name: 'America/Inuvik (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Edmonton',
    name: 'America/Edmonton (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Denver',
    name: 'America/Denver (GMT -07:00)',
    country_code: 'us',
    offset: -25200
  },
  {
    id: 'America/Chihuahua',
    name: 'America/Chihuahua (GMT -07:00)',
    country_code: 'mx',
    offset: -25200
  },
  {
    id: 'America/Boise',
    name: 'America/Boise (GMT -07:00)',
    country_code: 'us',
    offset: -25200
  },
  {
    id: 'America/Ojinaga',
    name: 'America/Ojinaga (GMT -07:00)',
    country_code: 'mx',
    offset: -25200
  },
  {
    id: 'America/Cambridge_Bay',
    name: 'America/Cambridge_Bay (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Dawson_Creek',
    name: 'America/Dawson_Creek (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Phoenix',
    name: 'America/Phoenix (GMT -07:00)',
    country_code: 'us',
    offset: -25200
  },
  {
    id: 'America/Hermosillo',
    name: 'America/Hermosillo (GMT -07:00)',
    country_code: 'mx',
    offset: -25200
  },
  {
    id: 'America/Creston',
    name: 'America/Creston (GMT -07:00)',
    country_code: 'ca',
    offset: -25200
  },
  {
    id: 'America/Matamoros',
    name: 'America/Matamoros (GMT -06:00)',
    country_code: 'mx',
    offset: -21600
  },
  {
    id: 'America/Menominee',
    name: 'America/Menominee (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/Indiana/Knox',
    name: 'America/Indiana/Knox (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/Managua',
    name: 'America/Managua (GMT -06:00)',
    country_code: 'ni',
    offset: -21600
  },
  {
    id: 'America/Bahia_Banderas',
    name: 'America/Bahia_Banderas (GMT -06:00)',
    country_code: 'mx',
    offset: -21600
  },
  {
    id: 'America/Indiana/Tell_City',
    name: 'America/Indiana/Tell_City (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/Belize',
    name: 'America/Belize (GMT -06:00)',
    country_code: 'bz',
    offset: -21600
  },
  {
    id: 'America/Chicago',
    name: 'America/Chicago (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/Guatemala',
    name: 'America/Guatemala (GMT -06:00)',
    country_code: 'gt',
    offset: -21600
  },
  {
    id: 'America/El_Salvador',
    name: 'America/El_Salvador (GMT -06:00)',
    country_code: 'sv',
    offset: -21600
  },
  {
    id: 'America/Merida',
    name: 'America/Merida (GMT -06:00)',
    country_code: 'mx',
    offset: -21600
  },
  {
    id: 'America/Costa_Rica',
    name: 'America/Costa_Rica (GMT -06:00)',
    country_code: 'cr',
    offset: -21600
  },
  {
    id: 'America/Mexico_City',
    name: 'America/Mexico_City (GMT -06:00)',
    country_code: 'mx',
    offset: -21600
  },
  {
    id: 'America/Winnipeg',
    name: 'America/Winnipeg (GMT -06:00)',
    country_code: 'ca',
    offset: -21600
  },
  {
    id: 'Pacific/Galapagos',
    name: 'Pacific/Galapagos (GMT -06:00)',
    country_code: 'ec',
    offset: -21600
  },
  {
    id: 'America/Resolute',
    name: 'America/Resolute (GMT -06:00)',
    country_code: 'ca',
    offset: -21600
  },
  {
    id: 'America/Regina',
    name: 'America/Regina (GMT -06:00)',
    country_code: 'ca',
    offset: -21600
  },
  {
    id: 'America/Rankin_Inlet',
    name: 'America/Rankin_Inlet (GMT -06:00)',
    country_code: 'ca',
    offset: -21600
  },
  {
    id: 'America/Rainy_River',
    name: 'America/Rainy_River (GMT -06:00)',
    country_code: 'ca',
    offset: -21600
  },
  {
    id: 'America/North_Dakota/New_Salem',
    name: 'America/North_Dakota/New_Salem (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/North_Dakota/Beulah',
    name: 'America/North_Dakota/Beulah (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/North_Dakota/Center',
    name: 'America/North_Dakota/Center (GMT -06:00)',
    country_code: 'us',
    offset: -21600
  },
  {
    id: 'America/Tegucigalpa',
    name: 'America/Tegucigalpa (GMT -06:00)',
    country_code: 'hn',
    offset: -21600
  },
  {
    id: 'America/Swift_Current',
    name: 'America/Swift_Current (GMT -06:00)',
    country_code: 'ca',
    offset: -21600
  },
  {
    id: 'America/Monterrey',
    name: 'America/Monterrey (GMT -06:00)',
    country_code: 'mx',
    offset: -21600
  },
  {
    id: 'America/Pangnirtung',
    name: 'America/Pangnirtung (GMT -05:00)',
    country_code: 'ca',
    offset: -18000
  },
  {
    id: 'America/Indiana/Petersburg',
    name: 'America/Indiana/Petersburg (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Indiana/Marengo',
    name: 'America/Indiana/Marengo (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Bogota',
    name: 'America/Bogota (GMT -05:00)',
    country_code: 'co',
    offset: -18000
  },
  {
    id: 'America/Toronto',
    name: 'America/Toronto (GMT -05:00)',
    country_code: 'ca',
    offset: -18000
  },
  {
    id: 'America/Detroit',
    name: 'America/Detroit (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Panama',
    name: 'America/Panama (GMT -05:00)',
    country_code: 'pa',
    offset: -18000
  },
  {
    id: 'America/Cancun',
    name: 'America/Cancun (GMT -05:00)',
    country_code: 'mx',
    offset: -18000
  },
  {
    id: 'America/Rio_Branco',
    name: 'America/Rio_Branco (GMT -05:00)',
    country_code: 'br',
    offset: -18000
  },
  {
    id: 'America/Port-au-Prince',
    name: 'America/Port-au-Prince (GMT -05:00)',
    country_code: 'ht',
    offset: -18000
  },
  {
    id: 'America/Cayman',
    name: 'America/Cayman (GMT -05:00)',
    country_code: 'ky',
    offset: -18000
  },
  {
    id: 'America/Grand_Turk',
    name: 'America/Grand_Turk (GMT -05:00)',
    country_code: 'tc',
    offset: -18000
  },
  {
    id: 'America/Havana',
    name: 'America/Havana (GMT -05:00)',
    country_code: 'cu',
    offset: -18000
  },
  {
    id: 'America/Indiana/Indianapolis',
    name: 'America/Indiana/Indianapolis (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Indiana/Vevay',
    name: 'America/Indiana/Vevay (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Guayaquil',
    name: 'America/Guayaquil (GMT -05:00)',
    country_code: 'ec',
    offset: -18000
  },
  {
    id: 'America/Nipigon',
    name: 'America/Nipigon (GMT -05:00)',
    country_code: 'ca',
    offset: -18000
  },
  {
    id: 'America/Indiana/Vincennes',
    name: 'America/Indiana/Vincennes (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Atikokan',
    name: 'America/Atikokan (GMT -05:00)',
    country_code: 'ca',
    offset: -18000
  },
  {
    id: 'America/Indiana/Winamac',
    name: 'America/Indiana/Winamac (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/New_York',
    name: 'America/New_York (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Iqaluit',
    name: 'America/Iqaluit (GMT -05:00)',
    country_code: 'ca',
    offset: -18000
  },
  {
    id: 'America/Jamaica',
    name: 'America/Jamaica (GMT -05:00)',
    country_code: 'jm',
    offset: -18000
  },
  {
    id: 'America/Nassau',
    name: 'America/Nassau (GMT -05:00)',
    country_code: 'bs',
    offset: -18000
  },
  {
    id: 'America/Kentucky/Louisville',
    name: 'America/Kentucky/Louisville (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Kentucky/Monticello',
    name: 'America/Kentucky/Monticello (GMT -05:00)',
    country_code: 'us',
    offset: -18000
  },
  {
    id: 'America/Eirunepe',
    name: 'America/Eirunepe (GMT -05:00)',
    country_code: 'br',
    offset: -18000
  },
  {
    id: 'Pacific/Easter',
    name: 'Pacific/Easter (GMT -05:00)',
    country_code: 'cl',
    offset: -18000
  },
  {
    id: 'America/Lima',
    name: 'America/Lima (GMT -05:00)',
    country_code: 'pe',
    offset: -18000
  },
  {
    id: 'America/Thunder_Bay',
    name: 'America/Thunder_Bay (GMT -05:00)',
    country_code: 'ca',
    offset: -18000
  },
  {
    id: 'America/Guadeloupe',
    name: 'America/Guadeloupe (GMT -04:00)',
    country_code: 'gp',
    offset: -14400
  },
  {
    id: 'America/Manaus',
    name: 'America/Manaus (GMT -04:00)',
    country_code: 'br',
    offset: -14400
  },
  {
    id: 'America/Guyana',
    name: 'America/Guyana (GMT -04:00)',
    country_code: 'gy',
    offset: -14400
  },
  {
    id: 'America/Halifax',
    name: 'America/Halifax (GMT -04:00)',
    country_code: 'ca',
    offset: -14400
  },
  {
    id: 'America/Puerto_Rico',
    name: 'America/Puerto_Rico (GMT -04:00)',
    country_code: 'pr',
    offset: -14400
  },
  {
    id: 'America/Porto_Velho',
    name: 'America/Porto_Velho (GMT -04:00)',
    country_code: 'br',
    offset: -14400
  },
  {
    id: 'America/Port_of_Spain',
    name: 'America/Port_of_Spain (GMT -04:00)',
    country_code: 'tt',
    offset: -14400
  },
  {
    id: 'America/Montserrat',
    name: 'America/Montserrat (GMT -04:00)',
    country_code: 'ms',
    offset: -14400
  },
  {
    id: 'America/Moncton',
    name: 'America/Moncton (GMT -04:00)',
    country_code: 'ca',
    offset: -14400
  },
  {
    id: 'America/Martinique',
    name: 'America/Martinique (GMT -04:00)',
    country_code: 'mq',
    offset: -14400
  },
  {
    id: 'America/Kralendijk',
    name: 'America/Kralendijk (GMT -04:00)',
    country_code: 'bq',
    offset: -14400
  },
  {
    id: 'America/La_Paz',
    name: 'America/La_Paz (GMT -04:00)',
    country_code: 'bo',
    offset: -14400
  },
  {
    id: 'America/Marigot',
    name: 'America/Marigot (GMT -04:00)',
    country_code: 'mf',
    offset: -14400
  },
  {
    id: 'America/Lower_Princes',
    name: 'America/Lower_Princes (GMT -04:00)',
    country_code: 'sx',
    offset: -14400
  },
  {
    id: 'America/Grenada',
    name: 'America/Grenada (GMT -04:00)',
    country_code: 'gd',
    offset: -14400
  },
  {
    id: 'America/Santo_Domingo',
    name: 'America/Santo_Domingo (GMT -04:00)',
    country_code: 'do',
    offset: -14400
  },
  {
    id: 'America/Goose_Bay',
    name: 'America/Goose_Bay (GMT -04:00)',
    country_code: 'ca',
    offset: -14400
  },
  {
    id: 'America/Campo_Grande',
    name: 'America/Campo_Grande (GMT -04:00)',
    country_code: 'br',
    offset: -14400
  },
  {
    id: 'America/St_Barthelemy',
    name: 'America/St_Barthelemy (GMT -04:00)',
    country_code: 'bl',
    offset: -14400
  },
  {
    id: 'America/Aruba',
    name: 'America/Aruba (GMT -04:00)',
    country_code: 'aw',
    offset: -14400
  },
  {
    id: 'America/St_Kitts',
    name: 'America/St_Kitts (GMT -04:00)',
    country_code: 'kn',
    offset: -14400
  },
  {
    id: 'America/St_Lucia',
    name: 'America/St_Lucia (GMT -04:00)',
    country_code: 'lc',
    offset: -14400
  },
  {
    id: 'America/Barbados',
    name: 'America/Barbados (GMT -04:00)',
    country_code: 'bb',
    offset: -14400
  },
  {
    id: 'America/St_Thomas',
    name: 'America/St_Thomas (GMT -04:00)',
    country_code: 'vi',
    offset: -14400
  },
  {
    id: 'America/Blanc-Sablon',
    name: 'America/Blanc-Sablon (GMT -04:00)',
    country_code: 'ca',
    offset: -14400
  },
  {
    id: 'America/Boa_Vista',
    name: 'America/Boa_Vista (GMT -04:00)',
    country_code: 'br',
    offset: -14400
  },
  {
    id: 'America/Caracas',
    name: 'America/Caracas (GMT -04:00)',
    country_code: 've',
    offset: -14400
  },
  {
    id: 'America/Antigua',
    name: 'America/Antigua (GMT -04:00)',
    country_code: 'ag',
    offset: -14400
  },
  {
    id: 'America/St_Vincent',
    name: 'America/St_Vincent (GMT -04:00)',
    country_code: 'vc',
    offset: -14400
  },
  {
    id: 'America/Thule',
    name: 'America/Thule (GMT -04:00)',
    country_code: 'gl',
    offset: -14400
  },
  {
    id: 'America/Cuiaba',
    name: 'America/Cuiaba (GMT -04:00)',
    country_code: 'br',
    offset: -14400
  },
  {
    id: 'America/Curacao',
    name: 'America/Curacao (GMT -04:00)',
    country_code: 'cw',
    offset: -14400
  },
  {
    id: 'America/Tortola',
    name: 'America/Tortola (GMT -04:00)',
    country_code: 'vg',
    offset: -14400
  },
  {
    id: 'America/Dominica',
    name: 'America/Dominica (GMT -04:00)',
    country_code: 'dm',
    offset: -14400
  },
  {
    id: 'Atlantic/Bermuda',
    name: 'Atlantic/Bermuda (GMT -04:00)',
    country_code: 'bm',
    offset: -14400
  },
  {
    id: 'America/Glace_Bay',
    name: 'America/Glace_Bay (GMT -04:00)',
    country_code: 'ca',
    offset: -14400
  },
  {
    id: 'America/Anguilla',
    name: 'America/Anguilla (GMT -04:00)',
    country_code: 'ai',
    offset: -14400
  },
  {
    id: 'America/St_Johns',
    name: 'America/St_Johns (GMT -03:30)',
    country_code: 'ca',
    offset: -12600
  },
  {
    id: 'America/Argentina/San_Juan',
    name: 'America/Argentina/San_Juan (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Belem',
    name: 'America/Belem (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'Atlantic/Stanley',
    name: 'Atlantic/Stanley (GMT -03:00)',
    country_code: 'fk',
    offset: -10800
  },
  {
    id: 'America/Recife',
    name: 'America/Recife (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Paramaribo',
    name: 'America/Paramaribo (GMT -03:00)',
    country_code: 'sr',
    offset: -10800
  },
  {
    id: 'America/Santarem',
    name: 'America/Santarem (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Fortaleza',
    name: 'America/Fortaleza (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Santiago',
    name: 'America/Santiago (GMT -03:00)',
    country_code: 'cl',
    offset: -10800
  },
  {
    id: 'America/Argentina/Salta',
    name: 'America/Argentina/Salta (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'Antarctica/Rothera',
    name: 'Antarctica/Rothera (GMT -03:00)',
    country_code: 'aq',
    offset: -10800
  },
  {
    id: 'Antarctica/Palmer',
    name: 'Antarctica/Palmer (GMT -03:00)',
    country_code: 'aq',
    offset: -10800
  },
  {
    id: 'America/Montevideo',
    name: 'America/Montevideo (GMT -03:00)',
    country_code: 'uy',
    offset: -10800
  },
  {
    id: 'America/Punta_Arenas',
    name: 'America/Punta_Arenas (GMT -03:00)',
    country_code: 'cl',
    offset: -10800
  },
  {
    id: 'America/Cayenne',
    name: 'America/Cayenne (GMT -03:00)',
    country_code: 'gf',
    offset: -10800
  },
  {
    id: 'America/Miquelon',
    name: 'America/Miquelon (GMT -03:00)',
    country_code: 'pm',
    offset: -10800
  },
  {
    id: 'America/Sao_Paulo',
    name: 'America/Sao_Paulo (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Bahia',
    name: 'America/Bahia (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Argentina/Catamarca',
    name: 'America/Argentina/Catamarca (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/Mendoza',
    name: 'America/Argentina/Mendoza (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/Jujuy',
    name: 'America/Argentina/Jujuy (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/Cordoba',
    name: 'America/Argentina/Cordoba (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Asuncion',
    name: 'America/Asuncion (GMT -03:00)',
    country_code: 'py',
    offset: -10800
  },
  {
    id: 'America/Maceio',
    name: 'America/Maceio (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Argentina/Buenos_Aires',
    name: 'America/Argentina/Buenos_Aires (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/La_Rioja',
    name: 'America/Argentina/La_Rioja (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Araguaina',
    name: 'America/Araguaina (GMT -03:00)',
    country_code: 'br',
    offset: -10800
  },
  {
    id: 'America/Argentina/Rio_Gallegos',
    name: 'America/Argentina/Rio_Gallegos (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/San_Luis',
    name: 'America/Argentina/San_Luis (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/Tucuman',
    name: 'America/Argentina/Tucuman (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Argentina/Ushuaia',
    name: 'America/Argentina/Ushuaia (GMT -03:00)',
    country_code: 'ar',
    offset: -10800
  },
  {
    id: 'America/Godthab',
    name: 'America/Godthab (GMT -03:00)',
    country_code: 'gl',
    offset: -10800
  },
  {
    id: 'America/Noronha',
    name: 'America/Noronha (GMT -02:00)',
    country_code: 'br',
    offset: -7200
  },
  {
    id: 'Atlantic/South_Georgia',
    name: 'Atlantic/South_Georgia (GMT -02:00)',
    country_code: 'gs',
    offset: -7200
  },
  {
    id: 'Atlantic/Azores',
    name: 'Atlantic/Azores (GMT -01:00)',
    country_code: 'pt',
    offset: -3600
  },
  {
    id: 'Atlantic/Cape_Verde',
    name: 'Atlantic/Cape_Verde (GMT -01:00)',
    country_code: 'cv',
    offset: -3600
  },
  {
    id: 'America/Scoresbysund',
    name: 'America/Scoresbysund (GMT -01:00)',
    country_code: 'gl',
    offset: -3600
  },
  {
    id: 'Europe/Lisbon',
    name: 'Europe/Lisbon (GMT +00:00)',
    country_code: 'pt',
    offset: 0
  },
  {
    id: 'Europe/Jersey',
    name: 'Europe/Jersey (GMT +00:00)',
    country_code: 'je',
    offset: 0
  },
  {
    id: 'Europe/London',
    name: 'Europe/London (GMT +00:00)',
    country_code: 'gb',
    offset: 0
  },
  {
    id: 'Europe/Isle_of_Man',
    name: 'Europe/Isle_of_Man (GMT +00:00)',
    country_code: 'im',
    offset: 0
  },
  {
    id: 'Antarctica/Troll',
    name: 'Antarctica/Troll (GMT +00:00)',
    country_code: 'aq',
    offset: 0
  },
  {
    id: 'Europe/Guernsey',
    name: 'Europe/Guernsey (GMT +00:00)',
    country_code: 'gg',
    offset: 0
  },
  {
    id: 'Europe/Dublin',
    name: 'Europe/Dublin (GMT +00:00)',
    country_code: 'ie',
    offset: 0
  },
  {
    id: 'Atlantic/St_Helena',
    name: 'Atlantic/St_Helena (GMT +00:00)',
    country_code: 'sh',
    offset: 0
  },
  {
    id: 'Atlantic/Reykjavik',
    name: 'Atlantic/Reykjavik (GMT +00:00)',
    country_code: 'is',
    offset: 0
  },
  {
    id: 'Atlantic/Madeira',
    name: 'Atlantic/Madeira (GMT +00:00)',
    country_code: 'pt',
    offset: 0
  },
  {
    id: 'Atlantic/Faroe',
    name: 'Atlantic/Faroe (GMT +00:00)',
    country_code: 'fo',
    offset: 0
  },
  {
    id: 'Atlantic/Canary',
    name: 'Atlantic/Canary (GMT +00:00)',
    country_code: 'es',
    offset: 0
  },
  {
    id: 'Africa/Accra',
    name: 'Africa/Accra (GMT +00:00)',
    country_code: 'gh',
    offset: 0
  },
  {
    id: 'Africa/Abidjan',
    name: 'Africa/Abidjan (GMT +00:00)',
    country_code: 'ci',
    offset: 0
  },
  {
    id: 'UTC',
    name: 'UTC (GMT +00:00)',
    country_code: '??',
    offset: 0
  },
  {
    id: 'America/Danmarkshavn',
    name: 'America/Danmarkshavn (GMT +00:00)',
    country_code: 'gl',
    offset: 0
  },
  {
    id: 'Africa/Nouakchott',
    name: 'Africa/Nouakchott (GMT +00:00)',
    country_code: 'mr',
    offset: 0
  },
  {
    id: 'Africa/Conakry',
    name: 'Africa/Conakry (GMT +00:00)',
    country_code: 'gn',
    offset: 0
  },
  {
    id: 'Africa/Monrovia',
    name: 'Africa/Monrovia (GMT +00:00)',
    country_code: 'lr',
    offset: 0
  },
  {
    id: 'Africa/Freetown',
    name: 'Africa/Freetown (GMT +00:00)',
    country_code: 'sl',
    offset: 0
  },
  {
    id: 'Africa/Bissau',
    name: 'Africa/Bissau (GMT +00:00)',
    country_code: 'gw',
    offset: 0
  },
  {
    id: 'Africa/Banjul',
    name: 'Africa/Banjul (GMT +00:00)',
    country_code: 'gm',
    offset: 0
  },
  {
    id: 'Africa/Ouagadougou',
    name: 'Africa/Ouagadougou (GMT +00:00)',
    country_code: 'bf',
    offset: 0
  },
  {
    id: 'Africa/Sao_Tome',
    name: 'Africa/Sao_Tome (GMT +00:00)',
    country_code: 'st',
    offset: 0
  },
  {
    id: 'Africa/Bamako',
    name: 'Africa/Bamako (GMT +00:00)',
    country_code: 'ml',
    offset: 0
  },
  {
    id: 'Africa/Lome',
    name: 'Africa/Lome (GMT +00:00)',
    country_code: 'tg',
    offset: 0
  },
  {
    id: 'Africa/Dakar',
    name: 'Africa/Dakar (GMT +00:00)',
    country_code: 'sn',
    offset: 0
  },
  {
    id: 'Europe/Gibraltar',
    name: 'Europe/Gibraltar (GMT +01:00)',
    country_code: 'gi',
    offset: 3600
  },
  {
    id: 'Europe/Luxembourg',
    name: 'Europe/Luxembourg (GMT +01:00)',
    country_code: 'lu',
    offset: 3600
  },
  {
    id: 'Africa/Casablanca',
    name: 'Africa/Casablanca (GMT +01:00)',
    country_code: 'ma',
    offset: 3600
  },
  {
    id: 'Europe/Ljubljana',
    name: 'Europe/Ljubljana (GMT +01:00)',
    country_code: 'si',
    offset: 3600
  },
  {
    id: 'Africa/Ceuta',
    name: 'Africa/Ceuta (GMT +01:00)',
    country_code: 'es',
    offset: 3600
  },
  {
    id: 'Europe/Brussels',
    name: 'Europe/Brussels (GMT +01:00)',
    country_code: 'be',
    offset: 3600
  },
  {
    id: 'Europe/Copenhagen',
    name: 'Europe/Copenhagen (GMT +01:00)',
    country_code: 'dk',
    offset: 3600
  },
  {
    id: 'Europe/Busingen',
    name: 'Europe/Busingen (GMT +01:00)',
    country_code: 'de',
    offset: 3600
  },
  {
    id: 'Europe/Budapest',
    name: 'Europe/Budapest (GMT +01:00)',
    country_code: 'hu',
    offset: 3600
  },
  {
    id: 'Europe/Malta',
    name: 'Europe/Malta (GMT +01:00)',
    country_code: 'mt',
    offset: 3600
  },
  {
    id: 'Europe/Bratislava',
    name: 'Europe/Bratislava (GMT +01:00)',
    country_code: 'sk',
    offset: 3600
  },
  {
    id: 'Europe/Berlin',
    name: 'Europe/Berlin (GMT +01:00)',
    country_code: 'de',
    offset: 3600
  },
  {
    id: 'Europe/Belgrade',
    name: 'Europe/Belgrade (GMT +01:00)',
    country_code: 'rs',
    offset: 3600
  },
  {
    id: 'Europe/Andorra',
    name: 'Europe/Andorra (GMT +01:00)',
    country_code: 'ad',
    offset: 3600
  },
  {
    id: 'Europe/Amsterdam',
    name: 'Europe/Amsterdam (GMT +01:00)',
    country_code: 'nl',
    offset: 3600
  },
  {
    id: 'Europe/Madrid',
    name: 'Europe/Madrid (GMT +01:00)',
    country_code: 'es',
    offset: 3600
  },
  {
    id: 'Europe/Prague',
    name: 'Europe/Prague (GMT +01:00)',
    country_code: 'cz',
    offset: 3600
  },
  {
    id: 'Europe/Monaco',
    name: 'Europe/Monaco (GMT +01:00)',
    country_code: 'mc',
    offset: 3600
  },
  {
    id: 'Europe/Vatican',
    name: 'Europe/Vatican (GMT +01:00)',
    country_code: 'va',
    offset: 3600
  },
  {
    id: 'Africa/Algiers',
    name: 'Africa/Algiers (GMT +01:00)',
    country_code: 'dz',
    offset: 3600
  },
  {
    id: 'Africa/Bangui',
    name: 'Africa/Bangui (GMT +01:00)',
    country_code: 'cf',
    offset: 3600
  },
  {
    id: 'Africa/Brazzaville',
    name: 'Africa/Brazzaville (GMT +01:00)',
    country_code: 'cg',
    offset: 3600
  },
  {
    id: 'Europe/Zurich',
    name: 'Europe/Zurich (GMT +01:00)',
    country_code: 'ch',
    offset: 3600
  },
  {
    id: 'Europe/Zagreb',
    name: 'Europe/Zagreb (GMT +01:00)',
    country_code: 'hr',
    offset: 3600
  },
  {
    id: 'Europe/Warsaw',
    name: 'Europe/Warsaw (GMT +01:00)',
    country_code: 'pl',
    offset: 3600
  },
  {
    id: 'Europe/Vienna',
    name: 'Europe/Vienna (GMT +01:00)',
    country_code: 'at',
    offset: 3600
  },
  {
    id: 'Europe/Vaduz',
    name: 'Europe/Vaduz (GMT +01:00)',
    country_code: 'li',
    offset: 3600
  },
  {
    id: 'Europe/Oslo',
    name: 'Europe/Oslo (GMT +01:00)',
    country_code: 'no',
    offset: 3600
  },
  {
    id: 'Europe/Tirane',
    name: 'Europe/Tirane (GMT +01:00)',
    country_code: 'al',
    offset: 3600
  },
  {
    id: 'Europe/Stockholm',
    name: 'Europe/Stockholm (GMT +01:00)',
    country_code: 'se',
    offset: 3600
  },
  {
    id: 'Europe/Skopje',
    name: 'Europe/Skopje (GMT +01:00)',
    country_code: 'mk',
    offset: 3600
  },
  {
    id: 'Europe/Sarajevo',
    name: 'Europe/Sarajevo (GMT +01:00)',
    country_code: 'ba',
    offset: 3600
  },
  {
    id: 'Europe/San_Marino',
    name: 'Europe/San_Marino (GMT +01:00)',
    country_code: 'sm',
    offset: 3600
  },
  {
    id: 'Europe/Rome',
    name: 'Europe/Rome (GMT +01:00)',
    country_code: 'it',
    offset: 3600
  },
  {
    id: 'Europe/Podgorica',
    name: 'Europe/Podgorica (GMT +01:00)',
    country_code: 'me',
    offset: 3600
  },
  {
    id: 'Europe/Paris',
    name: 'Europe/Paris (GMT +01:00)',
    country_code: 'fr',
    offset: 3600
  },
  {
    id: 'Africa/Douala',
    name: 'Africa/Douala (GMT +01:00)',
    country_code: 'cm',
    offset: 3600
  },
  {
    id: 'Africa/El_Aaiun',
    name: 'Africa/El_Aaiun (GMT +01:00)',
    country_code: 'eh',
    offset: 3600
  },
  {
    id: 'Africa/Luanda',
    name: 'Africa/Luanda (GMT +01:00)',
    country_code: 'ao',
    offset: 3600
  },
  {
    id: 'Africa/Libreville',
    name: 'Africa/Libreville (GMT +01:00)',
    country_code: 'ga',
    offset: 3600
  },
  {
    id: 'Arctic/Longyearbyen',
    name: 'Arctic/Longyearbyen (GMT +01:00)',
    country_code: 'sj',
    offset: 3600
  },
  {
    id: 'Africa/Kinshasa',
    name: 'Africa/Kinshasa (GMT +01:00)',
    country_code: 'cd',
    offset: 3600
  },
  {
    id: 'Africa/Malabo',
    name: 'Africa/Malabo (GMT +01:00)',
    country_code: 'gq',
    offset: 3600
  },
  {
    id: 'Africa/Lagos',
    name: 'Africa/Lagos (GMT +01:00)',
    country_code: 'ng',
    offset: 3600
  },
  {
    id: 'Africa/Ndjamena',
    name: 'Africa/Ndjamena (GMT +01:00)',
    country_code: 'td',
    offset: 3600
  },
  {
    id: 'Africa/Niamey',
    name: 'Africa/Niamey (GMT +01:00)',
    country_code: 'ne',
    offset: 3600
  },
  {
    id: 'Africa/Porto-Novo',
    name: 'Africa/Porto-Novo (GMT +01:00)',
    country_code: 'bj',
    offset: 3600
  },
  {
    id: 'Africa/Tunis',
    name: 'Africa/Tunis (GMT +01:00)',
    country_code: 'tn',
    offset: 3600
  },
  {
    id: 'Africa/Harare',
    name: 'Africa/Harare (GMT +02:00)',
    country_code: 'zw',
    offset: 7200
  },
  {
    id: 'Africa/Gaborone',
    name: 'Africa/Gaborone (GMT +02:00)',
    country_code: 'bw',
    offset: 7200
  },
  {
    id: 'Africa/Windhoek',
    name: 'Africa/Windhoek (GMT +02:00)',
    country_code: 'na',
    offset: 7200
  },
  {
    id: 'Europe/Athens',
    name: 'Europe/Athens (GMT +02:00)',
    country_code: 'gr',
    offset: 7200
  },
  {
    id: 'Europe/Bucharest',
    name: 'Europe/Bucharest (GMT +02:00)',
    country_code: 'ro',
    offset: 7200
  },
  {
    id: 'Asia/Damascus',
    name: 'Asia/Damascus (GMT +02:00)',
    country_code: 'sy',
    offset: 7200
  },
  {
    id: 'Europe/Helsinki',
    name: 'Europe/Helsinki (GMT +02:00)',
    country_code: 'fi',
    offset: 7200
  },
  {
    id: 'Europe/Kaliningrad',
    name: 'Europe/Kaliningrad (GMT +02:00)',
    country_code: 'ru',
    offset: 7200
  },
  {
    id: 'Europe/Kiev',
    name: 'Europe/Kiev (GMT +02:00)',
    country_code: 'ua',
    offset: 7200
  },
  {
    id: 'Africa/Johannesburg',
    name: 'Africa/Johannesburg (GMT +02:00)',
    country_code: 'za',
    offset: 7200
  },
  {
    id: 'Europe/Mariehamn',
    name: 'Europe/Mariehamn (GMT +02:00)',
    country_code: 'ax',
    offset: 7200
  },
  {
    id: 'Asia/Beirut',
    name: 'Asia/Beirut (GMT +02:00)',
    country_code: 'lb',
    offset: 7200
  },
  {
    id: 'Asia/Amman',
    name: 'Asia/Amman (GMT +02:00)',
    country_code: 'jo',
    offset: 7200
  },
  {
    id: 'Europe/Riga',
    name: 'Europe/Riga (GMT +02:00)',
    country_code: 'lv',
    offset: 7200
  },
  {
    id: 'Africa/Maseru',
    name: 'Africa/Maseru (GMT +02:00)',
    country_code: 'ls',
    offset: 7200
  },
  {
    id: 'Africa/Tripoli',
    name: 'Africa/Tripoli (GMT +02:00)',
    country_code: 'ly',
    offset: 7200
  },
  {
    id: 'Africa/Blantyre',
    name: 'Africa/Blantyre (GMT +02:00)',
    country_code: 'mw',
    offset: 7200
  },
  {
    id: 'Africa/Bujumbura',
    name: 'Africa/Bujumbura (GMT +02:00)',
    country_code: 'bi',
    offset: 7200
  },
  {
    id: 'Africa/Cairo',
    name: 'Africa/Cairo (GMT +02:00)',
    country_code: 'eg',
    offset: 7200
  },
  {
    id: 'Europe/Zaporozhye',
    name: 'Europe/Zaporozhye (GMT +02:00)',
    country_code: 'ua',
    offset: 7200
  },
  {
    id: 'Africa/Mbabane',
    name: 'Africa/Mbabane (GMT +02:00)',
    country_code: 'sz',
    offset: 7200
  },
  {
    id: 'Europe/Vilnius',
    name: 'Europe/Vilnius (GMT +02:00)',
    country_code: 'lt',
    offset: 7200
  },
  {
    id: 'Europe/Sofia',
    name: 'Europe/Sofia (GMT +02:00)',
    country_code: 'bg',
    offset: 7200
  },
  {
    id: 'Africa/Maputo',
    name: 'Africa/Maputo (GMT +02:00)',
    country_code: 'mz',
    offset: 7200
  },
  {
    id: 'Africa/Lusaka',
    name: 'Africa/Lusaka (GMT +02:00)',
    country_code: 'zm',
    offset: 7200
  },
  {
    id: 'Europe/Uzhgorod',
    name: 'Europe/Uzhgorod (GMT +02:00)',
    country_code: 'ua',
    offset: 7200
  },
  {
    id: 'Africa/Lubumbashi',
    name: 'Africa/Lubumbashi (GMT +02:00)',
    country_code: 'cd',
    offset: 7200
  },
  {
    id: 'Europe/Tallinn',
    name: 'Europe/Tallinn (GMT +02:00)',
    country_code: 'ee',
    offset: 7200
  },
  {
    id: 'Africa/Kigali',
    name: 'Africa/Kigali (GMT +02:00)',
    country_code: 'rw',
    offset: 7200
  },
  {
    id: 'Africa/Khartoum',
    name: 'Africa/Khartoum (GMT +02:00)',
    country_code: 'sd',
    offset: 7200
  },
  {
    id: 'Europe/Chisinau',
    name: 'Europe/Chisinau (GMT +02:00)',
    country_code: 'md',
    offset: 7200
  },
  {
    id: 'Asia/Hebron',
    name: 'Asia/Hebron (GMT +02:00)',
    country_code: 'ps',
    offset: 7200
  },
  {
    id: 'Asia/Nicosia',
    name: 'Asia/Nicosia (GMT +02:00)',
    country_code: 'cy',
    offset: 7200
  },
  {
    id: 'Asia/Famagusta',
    name: 'Asia/Famagusta (GMT +02:00)',
    country_code: 'cy',
    offset: 7200
  },
  {
    id: 'Asia/Gaza',
    name: 'Asia/Gaza (GMT +02:00)',
    country_code: 'ps',
    offset: 7200
  },
  {
    id: 'Asia/Jerusalem',
    name: 'Asia/Jerusalem (GMT +02:00)',
    country_code: 'il',
    offset: 7200
  },
  {
    id: 'Asia/Kuwait',
    name: 'Asia/Kuwait (GMT +03:00)',
    country_code: 'kw',
    offset: 10800
  },
  {
    id: 'Africa/Juba',
    name: 'Africa/Juba (GMT +03:00)',
    country_code: 'ss',
    offset: 10800
  },
  {
    id: 'Europe/Simferopol',
    name: 'Europe/Simferopol (GMT +03:00)',
    country_code: 'ua',
    offset: 10800
  },
  {
    id: 'Antarctica/Syowa',
    name: 'Antarctica/Syowa (GMT +03:00)',
    country_code: 'aq',
    offset: 10800
  },
  {
    id: 'Asia/Riyadh',
    name: 'Asia/Riyadh (GMT +03:00)',
    country_code: 'sa',
    offset: 10800
  },
  {
    id: 'Europe/Minsk',
    name: 'Europe/Minsk (GMT +03:00)',
    country_code: 'by',
    offset: 10800
  },
  {
    id: 'Europe/Moscow',
    name: 'Europe/Moscow (GMT +03:00)',
    country_code: 'ru',
    offset: 10800
  },
  {
    id: 'Asia/Aden',
    name: 'Asia/Aden (GMT +03:00)',
    country_code: 'ye',
    offset: 10800
  },
  {
    id: 'Europe/Kirov',
    name: 'Europe/Kirov (GMT +03:00)',
    country_code: 'ru',
    offset: 10800
  },
  {
    id: 'Europe/Istanbul',
    name: 'Europe/Istanbul (GMT +03:00)',
    country_code: 'tr',
    offset: 10800
  },
  {
    id: 'Africa/Dar_es_Salaam',
    name: 'Africa/Dar_es_Salaam (GMT +03:00)',
    country_code: 'tz',
    offset: 10800
  },
  {
    id: 'Africa/Djibouti',
    name: 'Africa/Djibouti (GMT +03:00)',
    country_code: 'dj',
    offset: 10800
  },
  {
    id: 'Asia/Baghdad',
    name: 'Asia/Baghdad (GMT +03:00)',
    country_code: 'iq',
    offset: 10800
  },
  {
    id: 'Asia/Qatar',
    name: 'Asia/Qatar (GMT +03:00)',
    country_code: 'qa',
    offset: 10800
  },
  {
    id: 'Africa/Kampala',
    name: 'Africa/Kampala (GMT +03:00)',
    country_code: 'ug',
    offset: 10800
  },
  {
    id: 'Africa/Mogadishu',
    name: 'Africa/Mogadishu (GMT +03:00)',
    country_code: 'so',
    offset: 10800
  },
  {
    id: 'Africa/Nairobi',
    name: 'Africa/Nairobi (GMT +03:00)',
    country_code: 'ke',
    offset: 10800
  },
  {
    id: 'Africa/Addis_Ababa',
    name: 'Africa/Addis_Ababa (GMT +03:00)',
    country_code: 'et',
    offset: 10800
  },
  {
    id: 'Africa/Asmara',
    name: 'Africa/Asmara (GMT +03:00)',
    country_code: 'er',
    offset: 10800
  },
  {
    id: 'Indian/Mayotte',
    name: 'Indian/Mayotte (GMT +03:00)',
    country_code: 'yt',
    offset: 10800
  },
  {
    id: 'Indian/Comoro',
    name: 'Indian/Comoro (GMT +03:00)',
    country_code: 'km',
    offset: 10800
  },
  {
    id: 'Indian/Antananarivo',
    name: 'Indian/Antananarivo (GMT +03:00)',
    country_code: 'mg',
    offset: 10800
  },
  {
    id: 'Asia/Bahrain',
    name: 'Asia/Bahrain (GMT +03:00)',
    country_code: 'bh',
    offset: 10800
  },
  {
    id: 'Asia/Tehran',
    name: 'Asia/Tehran (GMT +03:30)',
    country_code: 'ir',
    offset: 12600
  },
  {
    id: 'Indian/Reunion',
    name: 'Indian/Reunion (GMT +04:00)',
    country_code: 're',
    offset: 14400
  },
  {
    id: 'Asia/Yerevan',
    name: 'Asia/Yerevan (GMT +04:00)',
    country_code: 'am',
    offset: 14400
  },
  {
    id: 'Europe/Astrakhan',
    name: 'Europe/Astrakhan (GMT +04:00)',
    country_code: 'ru',
    offset: 14400
  },
  {
    id: 'Asia/Dubai',
    name: 'Asia/Dubai (GMT +04:00)',
    country_code: 'ae',
    offset: 14400
  },
  {
    id: 'Asia/Muscat',
    name: 'Asia/Muscat (GMT +04:00)',
    country_code: 'om',
    offset: 14400
  },
  {
    id: 'Asia/Tbilisi',
    name: 'Asia/Tbilisi (GMT +04:00)',
    country_code: 'ge',
    offset: 14400
  },
  {
    id: 'Asia/Baku',
    name: 'Asia/Baku (GMT +04:00)',
    country_code: 'az',
    offset: 14400
  },
  {
    id: 'Europe/Ulyanovsk',
    name: 'Europe/Ulyanovsk (GMT +04:00)',
    country_code: 'ru',
    offset: 14400
  },
  {
    id: 'Europe/Volgograd',
    name: 'Europe/Volgograd (GMT +04:00)',
    country_code: 'ru',
    offset: 14400
  },
  {
    id: 'Indian/Mauritius',
    name: 'Indian/Mauritius (GMT +04:00)',
    country_code: 'mu',
    offset: 14400
  },
  {
    id: 'Europe/Samara',
    name: 'Europe/Samara (GMT +04:00)',
    country_code: 'ru',
    offset: 14400
  },
  {
    id: 'Europe/Saratov',
    name: 'Europe/Saratov (GMT +04:00)',
    country_code: 'ru',
    offset: 14400
  },
  {
    id: 'Indian/Mahe',
    name: 'Indian/Mahe (GMT +04:00)',
    country_code: 'sc',
    offset: 14400
  },
  {
    id: 'Asia/Kabul',
    name: 'Asia/Kabul (GMT +04:30)',
    country_code: 'af',
    offset: 16200
  },
  {
    id: 'Indian/Maldives',
    name: 'Indian/Maldives (GMT +05:00)',
    country_code: 'mv',
    offset: 18000
  },
  {
    id: 'Asia/Karachi',
    name: 'Asia/Karachi (GMT +05:00)',
    country_code: 'pk',
    offset: 18000
  },
  {
    id: 'Asia/Aqtobe',
    name: 'Asia/Aqtobe (GMT +05:00)',
    country_code: 'kz',
    offset: 18000
  },
  {
    id: 'Antarctica/Mawson',
    name: 'Antarctica/Mawson (GMT +05:00)',
    country_code: 'aq',
    offset: 18000
  },
  {
    id: 'Asia/Dushanbe',
    name: 'Asia/Dushanbe (GMT +05:00)',
    country_code: 'tj',
    offset: 18000
  },
  {
    id: 'Asia/Ashgabat',
    name: 'Asia/Ashgabat (GMT +05:00)',
    country_code: 'tm',
    offset: 18000
  },
  {
    id: 'Asia/Atyrau',
    name: 'Asia/Atyrau (GMT +05:00)',
    country_code: 'kz',
    offset: 18000
  },
  {
    id: 'Indian/Kerguelen',
    name: 'Indian/Kerguelen (GMT +05:00)',
    country_code: 'tf',
    offset: 18000
  },
  {
    id: 'Asia/Aqtau',
    name: 'Asia/Aqtau (GMT +05:00)',
    country_code: 'kz',
    offset: 18000
  },
  {
    id: 'Asia/Qyzylorda',
    name: 'Asia/Qyzylorda (GMT +05:00)',
    country_code: 'kz',
    offset: 18000
  },
  {
    id: 'Asia/Oral',
    name: 'Asia/Oral (GMT +05:00)',
    country_code: 'kz',
    offset: 18000
  },
  {
    id: 'Asia/Tashkent',
    name: 'Asia/Tashkent (GMT +05:00)',
    country_code: 'uz',
    offset: 18000
  },
  {
    id: 'Asia/Samarkand',
    name: 'Asia/Samarkand (GMT +05:00)',
    country_code: 'uz',
    offset: 18000
  },
  {
    id: 'Asia/Yekaterinburg',
    name: 'Asia/Yekaterinburg (GMT +05:00)',
    country_code: 'ru',
    offset: 18000
  },
  {
    id: 'Asia/Kolkata',
    name: 'Asia/Kolkata (GMT +05:30)',
    country_code: 'in',
    offset: 19800
  },
  {
    id: 'Asia/Colombo',
    name: 'Asia/Colombo (GMT +05:30)',
    country_code: 'lk',
    offset: 19800
  },
  {
    id: 'Asia/Kathmandu',
    name: 'Asia/Kathmandu (GMT +05:45)',
    country_code: 'np',
    offset: 20700
  },
  {
    id: 'Asia/Bishkek',
    name: 'Asia/Bishkek (GMT +06:00)',
    country_code: 'kg',
    offset: 21600
  },
  {
    id: 'Asia/Almaty',
    name: 'Asia/Almaty (GMT +06:00)',
    country_code: 'kz',
    offset: 21600
  },
  {
    id: 'Asia/Dhaka',
    name: 'Asia/Dhaka (GMT +06:00)',
    country_code: 'bd',
    offset: 21600
  },
  {
    id: 'Asia/Qostanay',
    name: 'Asia/Qostanay (GMT +06:00)',
    country_code: 'kz',
    offset: 21600
  },
  {
    id: 'Antarctica/Vostok',
    name: 'Antarctica/Vostok (GMT +06:00)',
    country_code: 'aq',
    offset: 21600
  },
  {
    id: 'Asia/Omsk',
    name: 'Asia/Omsk (GMT +06:00)',
    country_code: 'ru',
    offset: 21600
  },
  {
    id: 'Indian/Chagos',
    name: 'Indian/Chagos (GMT +06:00)',
    country_code: 'io',
    offset: 21600
  },
  {
    id: 'Asia/Urumqi',
    name: 'Asia/Urumqi (GMT +06:00)',
    country_code: 'cn',
    offset: 21600
  },
  {
    id: 'Asia/Thimphu',
    name: 'Asia/Thimphu (GMT +06:00)',
    country_code: 'bt',
    offset: 21600
  },
  {
    id: 'Indian/Cocos',
    name: 'Indian/Cocos (GMT +06:30)',
    country_code: 'cc',
    offset: 23400
  },
  {
    id: 'Asia/Yangon',
    name: 'Asia/Yangon (GMT +06:30)',
    country_code: 'mm',
    offset: 23400
  },
  {
    id: 'Asia/Krasnoyarsk',
    name: 'Asia/Krasnoyarsk (GMT +07:00)',
    country_code: 'ru',
    offset: 25200
  },
  {
    id: 'Asia/Novokuznetsk',
    name: 'Asia/Novokuznetsk (GMT +07:00)',
    country_code: 'ru',
    offset: 25200
  },
  {
    id: 'Asia/Hovd',
    name: 'Asia/Hovd (GMT +07:00)',
    country_code: 'mn',
    offset: 25200
  },
  {
    id: 'Asia/Ho_Chi_Minh',
    name: 'Asia/Ho_Chi_Minh (GMT +07:00)',
    country_code: 'vn',
    offset: 25200
  },
  {
    id: 'Indian/Christmas',
    name: 'Indian/Christmas (GMT +07:00)',
    country_code: 'cx',
    offset: 25200
  },
  {
    id: 'Asia/Jakarta',
    name: 'Asia/Jakarta (GMT +07:00)',
    country_code: 'id',
    offset: 25200
  },
  {
    id: 'Asia/Vientiane',
    name: 'Asia/Vientiane (GMT +07:00)',
    country_code: 'la',
    offset: 25200
  },
  {
    id: 'Asia/Pontianak',
    name: 'Asia/Pontianak (GMT +07:00)',
    country_code: 'id',
    offset: 25200
  },
  {
    id: 'Asia/Tomsk',
    name: 'Asia/Tomsk (GMT +07:00)',
    country_code: 'ru',
    offset: 25200
  },
  {
    id: 'Asia/Phnom_Penh',
    name: 'Asia/Phnom_Penh (GMT +07:00)',
    country_code: 'kh',
    offset: 25200
  },
  {
    id: 'Antarctica/Davis',
    name: 'Antarctica/Davis (GMT +07:00)',
    country_code: 'aq',
    offset: 25200
  },
  {
    id: 'Asia/Bangkok',
    name: 'Asia/Bangkok (GMT +07:00)',
    country_code: 'th',
    offset: 25200
  },
  {
    id: 'Asia/Barnaul',
    name: 'Asia/Barnaul (GMT +07:00)',
    country_code: 'ru',
    offset: 25200
  },
  {
    id: 'Asia/Novosibirsk',
    name: 'Asia/Novosibirsk (GMT +07:00)',
    country_code: 'ru',
    offset: 25200
  },
  {
    id: 'Asia/Hong_Kong',
    name: 'Asia/Hong_Kong (GMT +08:00)',
    country_code: 'hk',
    offset: 28800
  },
  {
    id: 'Asia/Singapore',
    name: 'Asia/Singapore (GMT +08:00)',
    country_code: 'sg',
    offset: 28800
  },
  {
    id: 'Asia/Manila',
    name: 'Asia/Manila (GMT +08:00)',
    country_code: 'ph',
    offset: 28800
  },
  {
    id: 'Asia/Irkutsk',
    name: 'Asia/Irkutsk (GMT +08:00)',
    country_code: 'ru',
    offset: 28800
  },
  {
    id: 'Asia/Ulaanbaatar',
    name: 'Asia/Ulaanbaatar (GMT +08:00)',
    country_code: 'mn',
    offset: 28800
  },
  {
    id: 'Australia/Perth',
    name: 'Australia/Perth (GMT +08:00)',
    country_code: 'au',
    offset: 28800
  },
  {
    id: 'Asia/Macau',
    name: 'Asia/Macau (GMT +08:00)',
    country_code: 'mo',
    offset: 28800
  },
  {
    id: 'Asia/Brunei',
    name: 'Asia/Brunei (GMT +08:00)',
    country_code: 'bn',
    offset: 28800
  },
  {
    id: 'Asia/Taipei',
    name: 'Asia/Taipei (GMT +08:00)',
    country_code: 'tw',
    offset: 28800
  },
  {
    id: 'Asia/Shanghai',
    name: 'Asia/Shanghai (GMT +08:00)',
    country_code: 'cn',
    offset: 28800
  },
  {
    id: 'Asia/Makassar',
    name: 'Asia/Makassar (GMT +08:00)',
    country_code: 'id',
    offset: 28800
  },
  {
    id: 'Asia/Kuching',
    name: 'Asia/Kuching (GMT +08:00)',
    country_code: 'my',
    offset: 28800
  },
  {
    id: 'Antarctica/Casey',
    name: 'Antarctica/Casey (GMT +08:00)',
    country_code: 'aq',
    offset: 28800
  },
  {
    id: 'Asia/Choibalsan',
    name: 'Asia/Choibalsan (GMT +08:00)',
    country_code: 'mn',
    offset: 28800
  },
  {
    id: 'Asia/Kuala_Lumpur',
    name: 'Asia/Kuala_Lumpur (GMT +08:00)',
    country_code: 'my',
    offset: 28800
  },
  {
    id: 'Australia/Eucla',
    name: 'Australia/Eucla (GMT +08:45)',
    country_code: 'au',
    offset: 31500
  },
  {
    id: 'Asia/Seoul',
    name: 'Asia/Seoul (GMT +09:00)',
    country_code: 'kr',
    offset: 32400
  },
  {
    id: 'Asia/Dili',
    name: 'Asia/Dili (GMT +09:00)',
    country_code: 'tl',
    offset: 32400
  },
  {
    id: 'Asia/Tokyo',
    name: 'Asia/Tokyo (GMT +09:00)',
    country_code: 'jp',
    offset: 32400
  },
  {
    id: 'Pacific/Palau',
    name: 'Pacific/Palau (GMT +09:00)',
    country_code: 'pw',
    offset: 32400
  },
  {
    id: 'Asia/Chita',
    name: 'Asia/Chita (GMT +09:00)',
    country_code: 'ru',
    offset: 32400
  },
  {
    id: 'Asia/Khandyga',
    name: 'Asia/Khandyga (GMT +09:00)',
    country_code: 'ru',
    offset: 32400
  },
  {
    id: 'Asia/Pyongyang',
    name: 'Asia/Pyongyang (GMT +09:00)',
    country_code: 'kp',
    offset: 32400
  },
  {
    id: 'Asia/Yakutsk',
    name: 'Asia/Yakutsk (GMT +09:00)',
    country_code: 'ru',
    offset: 32400
  },
  {
    id: 'Asia/Jayapura',
    name: 'Asia/Jayapura (GMT +09:00)',
    country_code: 'id',
    offset: 32400
  },
  {
    id: 'Australia/Darwin',
    name: 'Australia/Darwin (GMT +09:30)',
    country_code: 'au',
    offset: 34200
  },
  {
    id: 'Asia/Vladivostok',
    name: 'Asia/Vladivostok (GMT +10:00)',
    country_code: 'ru',
    offset: 36000
  },
  {
    id: 'Pacific/Chuuk',
    name: 'Pacific/Chuuk (GMT +10:00)',
    country_code: 'fm',
    offset: 36000
  },
  {
    id: 'Pacific/Saipan',
    name: 'Pacific/Saipan (GMT +10:00)',
    country_code: 'mp',
    offset: 36000
  },
  {
    id: 'Pacific/Port_Moresby',
    name: 'Pacific/Port_Moresby (GMT +10:00)',
    country_code: 'pg',
    offset: 36000
  },
  {
    id: 'Antarctica/DumontDUrville',
    name: 'Antarctica/DumontDUrville (GMT +10:00)',
    country_code: 'aq',
    offset: 36000
  },
  {
    id: 'Australia/Brisbane',
    name: 'Australia/Brisbane (GMT +10:00)',
    country_code: 'au',
    offset: 36000
  },
  {
    id: 'Pacific/Guam',
    name: 'Pacific/Guam (GMT +10:00)',
    country_code: 'gu',
    offset: 36000
  },
  {
    id: 'Australia/Lindeman',
    name: 'Australia/Lindeman (GMT +10:00)',
    country_code: 'au',
    offset: 36000
  },
  {
    id: 'Asia/Ust-Nera',
    name: 'Asia/Ust-Nera (GMT +10:00)',
    country_code: 'ru',
    offset: 36000
  },
  {
    id: 'Australia/Adelaide',
    name: 'Australia/Adelaide (GMT +10:30)',
    country_code: 'au',
    offset: 37800
  },
  {
    id: 'Australia/Broken_Hill',
    name: 'Australia/Broken_Hill (GMT +10:30)',
    country_code: 'au',
    offset: 37800
  },
  {
    id: 'Pacific/Bougainville',
    name: 'Pacific/Bougainville (GMT +11:00)',
    country_code: 'pg',
    offset: 39600
  },
  {
    id: 'Asia/Srednekolymsk',
    name: 'Asia/Srednekolymsk (GMT +11:00)',
    country_code: 'ru',
    offset: 39600
  },
  {
    id: 'Asia/Sakhalin',
    name: 'Asia/Sakhalin (GMT +11:00)',
    country_code: 'ru',
    offset: 39600
  },
  {
    id: 'Australia/Hobart',
    name: 'Australia/Hobart (GMT +11:00)',
    country_code: 'au',
    offset: 39600
  },
  {
    id: 'Pacific/Pohnpei',
    name: 'Pacific/Pohnpei (GMT +11:00)',
    country_code: 'fm',
    offset: 39600
  },
  {
    id: 'Pacific/Noumea',
    name: 'Pacific/Noumea (GMT +11:00)',
    country_code: 'nc',
    offset: 39600
  },
  {
    id: 'Australia/Lord_Howe',
    name: 'Australia/Lord_Howe (GMT +11:00)',
    country_code: 'au',
    offset: 39600
  },
  {
    id: 'Antarctica/Macquarie',
    name: 'Antarctica/Macquarie (GMT +11:00)',
    country_code: 'au',
    offset: 39600
  },
  {
    id: 'Pacific/Kosrae',
    name: 'Pacific/Kosrae (GMT +11:00)',
    country_code: 'fm',
    offset: 39600
  },
  {
    id: 'Asia/Magadan',
    name: 'Asia/Magadan (GMT +11:00)',
    country_code: 'ru',
    offset: 39600
  },
  {
    id: 'Australia/Melbourne',
    name: 'Australia/Melbourne (GMT +11:00)',
    country_code: 'au',
    offset: 39600
  },
  {
    id: 'Pacific/Guadalcanal',
    name: 'Pacific/Guadalcanal (GMT +11:00)',
    country_code: 'sb',
    offset: 39600
  },
  {
    id: 'Australia/Sydney',
    name: 'Australia/Sydney (GMT +11:00)',
    country_code: 'au',
    offset: 39600
  },
  {
    id: 'Pacific/Efate',
    name: 'Pacific/Efate (GMT +11:00)',
    country_code: 'vu',
    offset: 39600
  },
  {
    id: 'Australia/Currie',
    name: 'Australia/Currie (GMT +11:00)',
    country_code: 'au',
    offset: 39600
  },
  {
    id: 'Pacific/Fiji',
    name: 'Pacific/Fiji (GMT +12:00)',
    country_code: 'fj',
    offset: 43200
  },
  {
    id: 'Pacific/Wake',
    name: 'Pacific/Wake (GMT +12:00)',
    country_code: 'um',
    offset: 43200
  },
  {
    id: 'Pacific/Wallis',
    name: 'Pacific/Wallis (GMT +12:00)',
    country_code: 'wf',
    offset: 43200
  },
  {
    id: 'Pacific/Funafuti',
    name: 'Pacific/Funafuti (GMT +12:00)',
    country_code: 'tv',
    offset: 43200
  },
  {
    id: 'Pacific/Norfolk',
    name: 'Pacific/Norfolk (GMT +12:00)',
    country_code: 'nf',
    offset: 43200
  },
  {
    id: 'Asia/Anadyr',
    name: 'Asia/Anadyr (GMT +12:00)',
    country_code: 'ru',
    offset: 43200
  },
  {
    id: 'Pacific/Nauru',
    name: 'Pacific/Nauru (GMT +12:00)',
    country_code: 'nr',
    offset: 43200
  },
  {
    id: 'Asia/Kamchatka',
    name: 'Asia/Kamchatka (GMT +12:00)',
    country_code: 'ru',
    offset: 43200
  },
  {
    id: 'Pacific/Majuro',
    name: 'Pacific/Majuro (GMT +12:00)',
    country_code: 'mh',
    offset: 43200
  },
  {
    id: 'Pacific/Kwajalein',
    name: 'Pacific/Kwajalein (GMT +12:00)',
    country_code: 'mh',
    offset: 43200
  },
  {
    id: 'Pacific/Tarawa',
    name: 'Pacific/Tarawa (GMT +12:00)',
    country_code: 'ki',
    offset: 43200
  },
  {
    id: 'Pacific/Tongatapu',
    name: 'Pacific/Tongatapu (GMT +13:00)',
    country_code: 'to',
    offset: 46800
  },
  {
    id: 'Pacific/Enderbury',
    name: 'Pacific/Enderbury (GMT +13:00)',
    country_code: 'ki',
    offset: 46800
  },
  {
    id: 'Antarctica/McMurdo',
    name: 'Antarctica/McMurdo (GMT +13:00)',
    country_code: 'aq',
    offset: 46800
  },
  {
    id: 'Pacific/Fakaofo',
    name: 'Pacific/Fakaofo (GMT +13:00)',
    country_code: 'tk',
    offset: 46800
  },
  {
    id: 'Pacific/Auckland',
    name: 'Pacific/Auckland (GMT +13:00)',
    country_code: 'nz',
    offset: 46800
  },
  {
    id: 'Pacific/Chatham',
    name: 'Pacific/Chatham (GMT +13:45)',
    country_code: 'nz',
    offset: 49500
  },
  {
    id: 'Pacific/Apia',
    name: 'Pacific/Apia (GMT +14:00)',
    country_code: 'ws',
    offset: 50400
  },
  {
    id: 'Pacific/Kiritimati',
    name: 'Pacific/Kiritimati (GMT +14:00)',
    country_code: 'ki',
    offset: 50400
  }
]
  .sort((a, b) => a - b)
  .map(location => ({ value: location.id, label: location.name }));

export const workingHours = Array.from({ length: 24 })
  .map((_, index) => index)
  .map(curr => (curr + 10 > 24 ? curr + 10 - 24 : curr + 10))
  .map(time => ({
    value: time,
    label: time < 10 ? `0${time}:00` : `${time}:00`
  }));
