import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';

import TxplModal from 'common/TxplModal';
import FormSelect from 'common/form/FormSelect';
import FormTextarea from 'common/form/FormTextarea';
import Spinner from 'common/Spinner';
import ContainedButton from 'common/ContainedButton';
import backOfficeService from 'api/backOfficeService';
import { reasons, statuses } from '../../constants';

const StyledActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const fields = initialStatus => ({
  status: { initialValue: statuses.find(curr => curr.value === initialStatus), validate: ['isRequired'] },
  reason: { initialValue: '', validate: ['isRequired'] },
  additionalComment: { initialValue: '' }
});

const transformParams = params => ({
  ...params,
  reason: params.reason.value,
  status: params.status.value,
  accountType: 'company'
});

const CompanyActivityStatusModal = ({ closePortal, data, name }) => {
  const { status, userId } = data;

  const callApi = params => {
    const apiParams = transformParams(params);

    return backOfficeService.updateCompanyActivityStatus(userId, { ...apiParams }).then(({ data }) => {
      // if (!['deleted', 'suspended'].includes(apiParams.status)) {
      //   const action = userId
      //       ? userActions.updateUserProperty
      //       : profileActions.updateProfileProperty
      //
      //   dispatch(action({ property: 'status', value: apiParams.status, profileId: accountId }))
      //   dispatch(action({ property: 'statusLogs', value: statusLog, updateType: 'insert', profileId: accountId }))
      //
      //   if (userId) {
      //     dispatch(action({ property: 'accounts', value: accounts, profileId: accountId }))
      //   }
      // }
      if (data.onSuccess) data.onSuccess();

      closePortal();
    });
  };

  const { onFieldChange, onSubmit, submitting, values } = useForm({ fields: fields(status), callApi });

  useEffect(() => {
    const newReasons = reasons[values.status.value];
    onFieldChange('reason')(newReasons[0]);
  }, [values.status.value]);

  return (
    <TxplModal
      title="Activity status"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <>
          <StyledActionsContainer>
            {submitting && <Spinner />}
            <ContainedButton onClick={closePortal} backgroundColor="rgba(204, 213, 255, 0.11)">
              Cancel
            </ContainedButton>
            <ContainedButton form="companyActivityStatusModal" type="submit">
              Save
            </ContainedButton>
          </StyledActionsContainer>
        </>
      }
    >
      <form onSubmit={onSubmit} id="companyActivityStatusModal">
        <FormSelect
          autoFocus
          label="Current status"
          value={values.status}
          onChange={onFieldChange('status')}
          options={statuses}
        />

        <FormSelect
          label="Reason"
          value={values.reason}
          onChange={onFieldChange('reason')}
          options={reasons[values.status.value]}
        />

        <FormTextarea
          label="Additional comments"
          placeholder="Add any comment here"
          onChange={onFieldChange('additionalComment')}
          value={values.additionalComment}
          maxLength={200}
        />
      </form>
    </TxplModal>
  );
};

export default withRenderPortal('company-activity-status-modal')(CompanyActivityStatusModal);
