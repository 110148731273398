/* eslint-disable no-case-declarations */
import * as ActionTypes from './collaborationsActionsTypes';

const initialState = {
  data: {},
  hasMore: true,
  hasVetted: false,
  totalCollabs: 0
};

const normalize = arr => (arr || []).reduce((result, curr) => ({ ...result, [curr._id]: curr }), {});

const collaborationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COLLABORATIONS:
      const {
        collaborations,
        hasMore,
        hasVetted,
        isFirstVouch,
        refetch = false,
        totalCollabs,
        vouchedAsUnverified,
        vouchedCollaborators
      } = action.payload;
      return {
        ...state,
        data: refetch
          ? normalize(collaborations)
          : {
              ...state.data,
              ...normalize(collaborations)
            },
        hasMore,
        hasVetted,
        isFirstVouch,
        totalCollabs,
        vouchedAsUnverified,
        vouchedCollaborators
      };

    case ActionTypes.ADD_COLLABORATION: {
      const { collaboration } = action.payload;

      const collaborations = [...Object.values(state.data)];
      const unverifiedCollaborations = collaborations.filter(collaboration =>
        collaboration.status.includes('unverified')
      );
      const pendingCollaborations = collaborations.filter(collaboration => collaboration.status === 'pending');

      return {
        ...state,
        data:
          unverifiedCollaborations.length > 0
            ? {
                ...normalize(unverifiedCollaborations),
                collaboration,
                ...normalize(pendingCollaborations)
              }
            : {
                [collaboration._id]: collaboration,
                ...state.data
              },
        totalCollabs: state.totalCollabs + 1
      };
    }

    case ActionTypes.UPDATE_COLLABORATION_PROPERTY: {
      const { key, property, value } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [key]: {
            ...state.data[key],
            [property]: value
          }
        }
      };
    }

    case ActionTypes.DELETE_COLLABORATION: {
      const { ...newState } = state.data;
      console.log('newState', newState);
      return {
        ...state,
        data: {
          ...newState
        }
      };
    }

    case ActionTypes.CLEAR_COLLABORATIONS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default collaborationsReducer;
