import styled from '@emotion/styled';
import Frame from 'common/Frame';
import Icon from 'common/Icon';
import useProfilePath from 'hooks/useProfilePath';
import React from 'react';
import { Link } from 'react-router-dom';
import commonStyles from 'utils/common-styles';

const StyledFrame = styled(Frame)`
  ${({ display }) => display === 'grid' && ' min-height: 476px;'}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  ${commonStyles.caption}
  padding: 8px 10px;
  background: #7266ee;
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 2px 16px rgba(114, 102, 238, 0.5);
  }
`;

const JobPostCard = ({ display }) => {
  const { myProfilePath } = useProfilePath();

  return (
    <StyledFrame display={display} className="center" backgroundColor="transparent">
      <StyledLink icon="add" to={`${myProfilePath}/jobs/add`} data-testid="post-job-card-button">
        <Icon icon="add" />
        Post a job
      </StyledLink>
    </StyledFrame>
  );
};

export default JobPostCard;
