import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';

const StyledDescription = styled(Typography)`
  color: rgba(229, 234, 255, 0.6);
`;

const StyledBox = styled.div`
  background-color: rgba(204, 213, 255, 0.05);
  border-radius: 16px;
  padding: 21px 24px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 16px auto;
  grid-column-gap: 10px;
  align-items: center;
  margin-bottom: 6px;

  @media ${pageSize.XS} {
    align-items: start;
  }
`;

const ProjectOverviewInstructionsModal = ({ closePortal, name }) => (
  <TxplModal
    title="Use the “Project Overview” write-up, to set the stage for your work"
    titleMB={16}
    appElement={document.getElementById('root-modal')}
    name={name}
    addPaddingTopBig
    XSaddPaddingTop
    renderFooter={<ContainedButton onClick={closePortal}>Done</ContainedButton>}
  >
    <StyledDescription mb={16}>Provide some background on the project from a birds-eye view</StyledDescription>

    <StyledBox>
      <Icon color="#26CBFF" icon="calendar" />
      <StyledDescription>How long did you work on this project?</StyledDescription>
    </StyledBox>

    <StyledBox>
      <Icon color="#CAFF33" icon="user-checked" />
      <StyledDescription>What were your responsibilities?</StyledDescription>
    </StyledBox>

    <StyledBox>
      <Icon color="#FFE040;" icon="experience" />
      <StyledDescription>What were your meaningful contributions?</StyledDescription>
    </StyledBox>
  </TxplModal>
);

export default withRenderPortal('project-overview-instructions-modal')(ProjectOverviewInstructionsModal);
