import React from 'react';
import { IconBackgorund, StyledTooltip } from './IndustryIconStyles';
import Icon from 'common/Icon';

const IndustryIcons = ({ iconHeight, iconMinWidth, iconWidth, marginRight, name }) => {
  const generateIcon = () => {
    let icon = '';
    switch (name?.toLowerCase()) {
      case 'others':
        icon = 'videoGames';
        break;
      case 'music':
        icon = 'music';
        break;
      case '3d interactive':
        icon = '3D';
        break;
      case 'movies and tv':
        icon = 'movie';
        break;
      default:
        break;
    }
    return icon;
  };

  if (!name) return null;

  return (
    <>
      {name === 'Others' ? (
        <StyledTooltip
          tooltipId={name}
          place="top"
        >{`This work has been done for other types of industry`}</StyledTooltip>
      ) : (
        <StyledTooltip tooltipId={name} place="top">{`This work has been done for the ${name} industry`}</StyledTooltip>
      )}
      {name !== 'Video Games' && (
        <IconBackgorund
          marginRight={marginRight}
          iconWidth={iconWidth}
          iconMinWidth={iconMinWidth}
          iconHeight={iconHeight}
          data-tip
          data-for={name}
        >
          <Icon size={21} icon={generateIcon()} />
        </IconBackgorund>
      )}
    </>
  );
};

export default IndustryIcons;
