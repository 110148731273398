import get from 'lodash/fp/get';
import assign from 'lodash/fp/assign';
import { createSelector } from 'reselect';

const selectDomain = state => get('ui.apiCalls', state);

export const selectApiCall = name => createSelector([selectDomain], apiCalls => get(name, apiCalls));

export const selectFailedServerCalls = createSelector([selectDomain], apiCalls =>
  Object.keys(apiCalls).reduce((curr, key) => {
    if (apiCalls[key].error && apiCalls[key].error.status === 502) {
      curr = assign(curr, { [key]: apiCalls[key] });
    }
    return curr;
  }, {})
);
