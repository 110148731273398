import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import React from 'react';
import TalentCard from './TalentCard';

function TalentCardModal({ data, name }) {
  const { biasReducedMode, talent } = data;
  return (
    <TxplModal
      name={name}
      appElement={document.getElementById('root-modal')}
      titleMB="0"
      size="custom"
      padding="0"
      hideCloseIcon
    >
      <TalentCard talent={talent} biasReducedMode={biasReducedMode} isListView isExtended />
    </TxplModal>
  );
}

export default withRenderPortal('talent-card-modal')(TalentCardModal);
