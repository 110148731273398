import Api from './api';

const api = new Api('notifications');

const getNotifications = (profileId, query) => api.get(`/${profileId}?${query}`);

const setNotificationsAsRead = (profileId, data) => api.put(`${profileId}/read`, data);

const setNotificationsAsSeen = (profileId, data) => api.put(`${profileId}/seen`, data);

export default {
  getNotifications,
  setNotificationsAsRead,
  setNotificationsAsSeen
};
