import React from 'react';
import styled from '@emotion/styled';

import withRenderPortal from 'hocs/withRenderPortal';
import TxplModal from 'common/TxplModal/TxplModal';
import Typography from 'components/shared/Typography';
import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import useApplicantService from 'hooks/api/useApplicantService';

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const RejectApplicationModal = ({ closePortal, data, name, rejectedApplicants, setRejectedApplicants }) => {
  const { loading, rejectApplicant } = useApplicantService();
  const { applicantId, applicantName, jobId } = data;

  const handleReject = () => {
    rejectApplicant(applicantId, jobId, closePortal);
    setRejectedApplicants(...rejectedApplicants, applicantId);
  };

  return (
    <TxplModal
      title="Reject application"
      name={name}
      appElement={document.getElementById('root')}
      size="small"
      padding="40px 0 56px"
      XSpadding="0 0 24px"
      nonFullScreenOnMobile
      hideBorder
      noPaddingTop
      renderFooter={
        <StyledButtonsContainer>
          {loading.loadingRejectApplicant && <Spinner />}
          <ContainedButton backgroundColor="secondary" onClick={closePortal}>
            Cancel
          </ContainedButton>
          <ContainedButton icon="thumbs-down" backgroundColor="#FF5151" onClick={handleReject}>
            Reject
          </ContainedButton>
        </StyledButtonsContainer>
      }
    >
      <Typography color="rgba(242, 244, 255, 0.9)" fontSize="17px" lineHeight="28px" variant="medium">
        {`Are you sure you want to reject ${applicantName}'s application?`}
      </Typography>
    </TxplModal>
  );
};

export default withRenderPortal('reject-application-modal')(RejectApplicationModal);
