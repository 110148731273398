import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import { closePortal } from 'store/portals/portalActions';
import { StyledButtonsContainer, StyledContainedButton } from './JoinEventsNowModal.styled';

const Footer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(profileSelectors.selectProfile);

  const handleEventsClick = useCallback(() => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/events`);
  }, []);

  const handleProfileClick = useCallback(async () => {
    history.push(`/talent/${profile.talentId}/about`);
    dispatch(closePortal({ name: 'join-events-now-modal' }));
  }, []);

  return (
    <StyledButtonsContainer>
      <StyledContainedButton backgroundColor="rgba(204, 213, 255, 0.11)" onClick={handleEventsClick}>
        Explore Events
      </StyledContainedButton>
      <StyledContainedButton backgroundColor="primary" onClick={handleProfileClick}>
        Keep building profile
      </StyledContainedButton>
    </StyledButtonsContainer>
  );
};

export default Footer;
