import React from 'react';
import styled from '@emotion/styled';
import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import Flex from 'common/styles/Flex';
import CheckboxIcon from './CheckboxIcon';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.btn {
    width: 100%;
    background: rgba(204, 213, 255, 0.05);
    border-radius: 16px;
  }

  &.reverse {
    .wrapper {
      padding: 22px 24px;
      flex-direction: row-reverse;
      justify-content: space-between;

      .checkbox-wrapper {
        margin-right: 0;
      }
    }
  }

  &.btn .text {
    color: rgba(230, 234, 255, 0.6);
  }

  &.btn.checked .text {
    color: #fff;
  }

  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
`;

const Label = styled.label`
  ${commonStyles.ui_heading_3}
  color: white;
`;

const StyledHintText = styled.label`
  ${commonStyles.ui_text_small};
  color: ${({ hintError }) => (hintError ? '#FF5151' : 'rgba(230, 234, 255, 0.35)')};
  ${props => props.lightWeight && 'font-weight: 400;'}
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const CheckboxIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  align-self: flex-start;
`;

const Text = styled.div`
  ${commonStyles.ui_text_medium}
  ${props => (props.error ? 'color: #FF5151;' : props.color ? `color: ${props.color};` : '')}
  ${props => props.textColor && 'color: rgba(242, 244, 255, 0.9);'}
  ${({ disabled }) => disabled && 'color: rgb(84, 86, 91);'}
  display: flex;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledError = styled.div`
  ${commonStyles.ui_text_small}
  color: #FF5151;
`;

const FormCheckbox = ({
  checkBoxRadius,
  checked,
  className,
  color,
  disabled,
  error,
  gap,
  hintText,
  icon,
  iconBorder,
  label,
  lightWeight,
  mb,
  mt,
  name,
  onChange,
  text,
  textColor,
  value
}) => (
  <InputWrapper className={className} mb={mb} mt={mt}>
    {label && <Label>{label}</Label>}
    <StyledLabel className="wrapper">
      <input type="checkbox" name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
      <CheckboxIconWrapper className="checkbox-wrapper">
        <CheckboxIcon
          border={error ? '#FF5151' : iconBorder}
          color={color}
          checked={checked}
          disabled={disabled}
          borderRadius={checkBoxRadius}
        />
      </CheckboxIconWrapper>
      {(typeof text !== 'undefined' || icon) && (
        <Flex gap={4}>
          <Flex gap={gap ?? 10} alignCenter>
            {icon && <Icon icon={icon} />}
            {typeof text !== 'undefined' && (
              <>
                <Text className="text" textColor={textColor} error={error} color={textColor} disabled={disabled}>
                  {text}
                </Text>
                <StyledHintText lightWeight={lightWeight}>{hintText}</StyledHintText>
              </>
            )}
          </Flex>
          {error && (
            <FlexRow>
              <StyledError>{error}&nbsp;</StyledError>
            </FlexRow>
          )}
        </Flex>
      )}
    </StyledLabel>
  </InputWrapper>
);

export default FormCheckbox;
