import * as userActionTypes from './userActionTypes';

export const initializeUser = user => ({
  type: userActionTypes.INITIALIZE_USER,
  payload: {
    user
  }
});

export const updateUserProperty = ({ property, updateKey = '_id', updateType, value }) => ({
  type: userActionTypes.UPDATE_USER_PROPERTY,
  payload: {
    property,
    updateKey,
    updateType,
    value
  }
});

export const clearUser = () => ({
  type: userActionTypes.CLEAR_USER
});
