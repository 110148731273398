import React from 'react';
import styled from '@emotion/styled';
import { pageSize } from 'utils/devices';

const StyledWrapper = styled.div`
  padding-top: ${props => (props.noPaddingTop ? '0px' : '16px')};
  padding-top: ${props => (props.addPaddingTop ? '22px' : '')};
  ${props => props.addPaddingTopBig && 'padding-top: 24px;'}
  ${props => props.addMarginTopLong && 'margin-top: 0px;'}
  ${props => !props.hideBorder && 'border-top: 2px solid rgba(204, 213, 255, 0.11);'}

  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.hasContentLeft ? 'space-between' : 'flex-end')};
  @media ${pageSize.XS} {
    padding-top: ${props => (props.XSaddPaddingTop ? props.XSaddPaddingTop : '16px')};
  }
`;
const StyledChildrenContainer = styled.div`
  display: flex;
`;

const TxplModalFooter = ({
  XSaddPaddingTop,
  addMarginTopLong,
  addPaddingTop,
  addPaddingTopBig,
  children,
  contentLeft,
  noPaddingTop,
  nonFullScreenOnMobile
}) => (
  <StyledWrapper
    XSaddPaddingTop={XSaddPaddingTop}
    hideBorder={nonFullScreenOnMobile}
    hasContentLeft={Boolean(contentLeft)}
    noPaddingTop={noPaddingTop}
    addPaddingTop={addPaddingTop}
    addPaddingTopBig={addPaddingTopBig}
    addMarginTopLong={addMarginTopLong}
  >
    {contentLeft}

    {contentLeft ? <StyledChildrenContainer>{children}</StyledChildrenContainer> : children}
  </StyledWrapper>
);

export default TxplModalFooter;
