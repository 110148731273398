import styled from '@emotion/styled';

const Required = styled.span`
  color: rgba(229, 234, 255, 0.35);

  ${({ ml }) => ml && `margin-left: ${ml}px;`}
  &::after {
    content: '*';
    position: relative;
    left: -4px;
  }
`;
export default Required;
