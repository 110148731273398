import React from 'react';
import styled from '@emotion/styled';

import ContainedButton from 'common/ContainedButton';

const StyledAddMediaCoverageContainer = styled.div`
  border: 2px dashed rgba(221, 226, 255, 0.2);
  padding: 32px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

const AddMediaCoverage = () => (
  <StyledAddMediaCoverageContainer>
    <ContainedButton icon="plus" forceDisplayText opens="media-coverage-modal">
      Add media coverage
    </ContainedButton>
  </StyledAddMediaCoverageContainer>
);

export default AddMediaCoverage;
