import React from 'react';
import styled from '@emotion/styled';
import { DropDownContext } from './Dropdown';
import ContainedButton from 'common/ContainedButton';
import commonStyles from 'utils/common-styles';
import SidebarNavLink from 'common/Sidebar/SidebarNavLink';

const StyledDropDownItem = styled.div`
  width: 100%;
`;
const DropdownButton = styled(ContainedButton)`
  width: 100%;
  background-color: transparent;
  border: none;
  .button-text {
    ${commonStyles.ui_text_medium}
    color: rgba(230, 234, 255, 0.6);
    text-transform: initial;
    display: flex;
    align-items: center;
    ${({ textPaddingLeft }) => textPaddingLeft && `padding-left:${textPaddingLeft}px;`}
  }
  & path {
    fill: rgba(230, 234, 255, 0.6) !important;
  }
  &:hover {
    background-color: rgba(204, 213, 255, 0.05);
    box-shadow: none;
    & path {
      fill: rgba(230, 234, 255, 0.6) !important;
    }
  }
  &:disabled {
    background-color: transparent;
  }
`;
const DropdownLink = styled(SidebarNavLink)`
  height: 32px;
  padding: 0 10px;
  width: 100% !important;
  justify-content: flex-start !important;
  .link-icon {
    display: block !important;
  }
`;

const DropDownItem = ({ children, disabled, padding, to, ...rest }) => {
  const { isOpen, setIsOpen } = React.useContext(DropDownContext);

  return (
    <StyledDropDownItem onClick={() => !disabled && isOpen && setIsOpen(!isOpen)}>
      {to ? (
        <DropdownLink to={to} disabled={disabled} {...rest}>
          {children}
        </DropdownLink>
      ) : (
        <DropdownButton type="button" disabled={disabled} padding={padding} {...rest}>
          {children}
        </DropdownButton>
      )}
    </StyledDropDownItem>
  );
};

export default DropDownItem;
