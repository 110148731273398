import useTalkSession from 'hooks/useTalkSession';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

function TalkSession() {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const isProfileLoaded = useSelector(profileSelectors.selectIsProfileLoaded);
  const conversations = useSelector(profileSelectors.selectMyProfileProperty('conversations')) ?? {};

  const { endSession, startSession } = useTalkSession();

  useEffect(() => {
    if (isProfileLoaded) {
      !window.TalkSession && startSession();
    }

    return () => {
      endSession();
    };
  }, [profileId, isProfileLoaded, Object.values(conversations).length]);

  return <></>;
}

export default TalkSession;
