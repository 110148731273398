import React from 'react';
import styled from '@emotion/styled';

import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import { pageSize } from 'utils/devices';
import Icon from 'common/Icon';
import { getAssetIcon } from 'utils/assets';

const StyledPortfolioWrapper = styled.div`
  padding-top: 66px;
  padding-bottom: 22px;
  @media ${pageSize.XS} {
    padding-top: 24px;
  }
`;
const StyledButtonsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
`;

const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
  gap: 8px;

  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
  }
`;

const StyledGridItem = styled.div`
  background: ${props => props.background};
  height: ${props => (props.large ? '198px' : '167px')};
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
`;

const Spacer = styled.div`
  height: 0px;
  margin: 56px 0 27px;
  border: 1px solid rgba(204, 213, 255, 0.11);
  @media ${pageSize.XS} {
    margin: 52px 0 24px;
  }
`;

const ProjectVideoInstructionModal = ({ closePortal, name }) => (
  <TxplModal
    title="Bring your professional identity to life with Portfolio Projects"
    appElement={document.getElementById('root-modal')}
    name={name}
    allowLine
    renderFooter={
      <StyledButtonsContainer>
        <ContainedButton onClick={closePortal}>DONE</ContainedButton>
      </StyledButtonsContainer>
    }
  >
    <StyledPortfolioWrapper>
      <Typography smediumText variant="h3">
        Record a short video, to highlight additional facets of your work
      </Typography>
      <Typography mediumText color="rgba(229,234,255,0.6)" mt={28} mtXS={24} variant="medium">
        We strongly recommend recording a short (up to 90 sec) video in addition to the write up as it allows clients to
        get to know you better — how you communicate, your personality. There, you can cover:
      </Typography>

      <StyledGridContainer>
        <StyledGridItem background="linear-gradient(180deg, #8000FF 0%, #CC3DFF 100%)">
          <Icon size={38} mt={10} icon="goal" />
          <Typography mediumText mt={16} variant="medium">
            {`What did you set out to achieve when you started out? How did you know when you'd finally got there?`}
          </Typography>
        </StyledGridItem>
        <StyledGridItem background="linear-gradient(0deg, #FF3C8E 0%, #A400F1 100%)">
          <Icon size={38} mt={10} icon="magnifier" />
          <Typography mediumText mt={16} variant="medium">
            What were the quality expectations?
          </Typography>
        </StyledGridItem>
        <StyledGridItem background="linear-gradient(0deg, #FF7A00 0%, #C40000 100%)">
          <Icon size={38} mt={10} icon="games-shipped" />
          <Typography mediumText mt={16} variant="medium">
            What was the biggest challenge you encountered during development?
          </Typography>
        </StyledGridItem>
        <StyledGridItem background="linear-gradient(180deg, #303543 0%, #7B7C9A 100%)">
          <Icon size={38} mt={10} icon="years-of-experience" />
          <Typography mediumText mt={16} variant="medium">
            What tools and tech did you rely on? Did you bring some new toys to support your efforts on this project?
          </Typography>
        </StyledGridItem>
        <StyledGridItem background="linear-gradient(359.43deg, #00E941 0.44%, #027800 99.47%)">
          <Icon size={38} mt={10} icon="skills" />
          <Typography mediumText mt={16} variant="medium">
            What skills and lessons have you gained over this project?
          </Typography>
        </StyledGridItem>
        <StyledGridItem background="linear-gradient(0deg, #0066FF 0%, #0400C9 100%)">
          <Icon size={38} mt={10} icon="calendar" />
          <Typography mediumText mt={16} variant="medium">
            Hindsight is 20/20 — is there anything you would do differently?
          </Typography>
        </StyledGridItem>
      </StyledGridContainer>

      <Spacer />

      <Typography smediumText mb={27} mbXS={24} variant="h3">
        Follow our tips to create an engaging video
      </Typography>

      <StyledGridContainer>
        <StyledGridItem large background="rgba(204, 213, 255, 0.05)">
          <img alt="TIMER" src={getAssetIcon('TIMER')} width={160} />
          <Typography mediumText color="rgba(229,234,255,0.6)" mt={16} variant="medium">
            {'Keep it short and to the point (ideally <90 seconds).'}
          </Typography>
        </StyledGridItem>
        <StyledGridItem large background="rgba(204, 213, 255, 0.05)">
          <img alt="BACKGROUND_LIGHT" src={getAssetIcon('BACKGROUND_LIGHT')} width={160} />
          <Typography mediumText color="rgba(229,234,255,0.6)" mt={16} variant="medium">
            Take care of background and lighting.
          </Typography>
        </StyledGridItem>
        <StyledGridItem large background="rgba(204, 213, 255, 0.05)">
          <img alt="SCREEN_CAST" src={getAssetIcon('SCREEN_CAST')} width={160} />
          <Typography mediumText color="rgba(229,234,255,0.6)" mt={16} variant="medium">
            Camera shy? Consider incorporating a screencast with your audio narration
          </Typography>
        </StyledGridItem>
        <StyledGridItem large background="rgba(204, 213, 255, 0.05)">
          <img alt="DIMENSIONS" src={getAssetIcon('DIMENSIONS')} width={160} />
          <Typography mediumText color="rgba(229,234,255,0.6)" mt={16} variant="medium">
            Stick with 1920x1080 video (horizontal please).
          </Typography>
        </StyledGridItem>
      </StyledGridContainer>
    </StyledPortfolioWrapper>
  </TxplModal>
);

export default withRenderPortal('project-video-instruction-modal')(ProjectVideoInstructionModal);
