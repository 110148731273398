import styled from '@emotion/styled';
import useOutsideClick from 'hooks/useOutsideClick';
import useProfilePath from 'hooks/useProfilePath';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { pageSize } from 'utils/devices';
import { currentUserHasFFSelector } from 'store/selectors/currentUser';
import { profileSelectors } from '../store/profile';
const HeaderMenuItem = styled.div`
  display: flex;
  cursor: pointer;
  font-family: Space Grotesk;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  position: relative;
  padding: 8px 16px;
  border-bottom: 2px solid transparent;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  &:nth-of-type(1) {
    flex-shrink: 2;
  }

  .header-Icon path {
    fill: rgba(230, 234, 255, 0.35) !important;
  }
  &:hover,
  &.opened {
    .header-MenuLabel {
      color: white;
    }
    .header-Icon path {
      fill: white !important;
    }
  }
  &.disabled {
    cursor: default;
    .header-Icon path {
      fill: rgba(230, 234, 255, 0.35);
    }
    .header-MenuLabel {
      color: rgba(230, 234, 255, 0.35);
    }
    &:hover,
    &.opened {
      .header-Icon path {
        fill: rgba(230, 234, 255, 0.35) !important;
      }
    }
  }
  ${({ active }) =>
    active &&
    `
    .header-MenuLabel {
      color: white;
    }
  `}
`;

const HeaderMenuLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: rgba(230, 234, 255, 0.6);
  text-transform: capitalize;
  padding-top: 6px;
  letter-spacing: 0.04em;
  @media ${pageSize.S}, ${pageSize.XS} {
    display: none;
  }
`;

const HeaderLinkContainer = () => {
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();

  const { myProfilePath } = useProfilePath();

  const [updateMenuIsOpen, setUpdateMenuIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const isCompanyView = useSelector(profileSelectors.selectIsMyProfileCompany);
  const { pathname } = location;

  const closeNotifications = e => {
    e && e.stopPropagation();
    setActiveTab(null);
    setUpdateMenuIsOpen(false);
  };

  useOutsideClick(ref, () => {
    if (updateMenuIsOpen) {
      closeNotifications();
    }
  });

  const handleEventsClick = () => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/events`);
  };

  const handleJobsClick = () => {
    history.push(`${myProfilePath}/all-jobs`);
  };

  const isAllJobs = pathname.includes('all-jobs');
  const isMyJobs = pathname.includes('my-jobs');
  const isPostedJobs = pathname.includes('jobs-i-posted');
  const isJobPath = isAllJobs || isMyJobs || isPostedJobs;

  const userHasContractEnabled = useSelector(currentUserHasFFSelector('hasContractEnabled'));
  const displayContracts = userHasContractEnabled && !isCompanyView;

  const handleContractsClick = () => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/contracts`);
  };

  return (
    <>
      <HeaderMenuItem onClick={handleJobsClick} active={activeTab === 2 || isJobPath} data-testid="jobs-links-header">
        <HeaderMenuLabel className="header-MenuLabel">Jobs</HeaderMenuLabel>
      </HeaderMenuItem>
      {displayContracts && (
        <HeaderMenuItem active={activeTab === 5} onClick={handleContractsClick} data-testid="contracts-links-header">
          <HeaderMenuLabel className="header-MenuLabel" data-tip data-for="contracts">
            Contracts
          </HeaderMenuLabel>
        </HeaderMenuItem>
      )}
      <HeaderMenuItem onClick={handleEventsClick} data-testid="events-links-header">
        <HeaderMenuLabel className="header-MenuLabel">Events</HeaderMenuLabel>
      </HeaderMenuItem>
    </>
  );
};

export default HeaderLinkContainer;
