import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import useCategories from 'hooks/api/useCategories';
import useProfilePath from 'hooks/useProfilePath';
import Life from 'pages/talent/Life';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelectors } from 'store/profile';

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function JobLife({ JobCategories, onFieldChange }) {
  const name = useSelector(profileSelectors.selectProfileProperty('name'));
  const publicUrl = useSelector(profileSelectors.selectProfileProperty('publicUrl'));
  const isCompany = useSelector(profileSelectors.selectIsCompanyView);
  const {
    categories,
    loading: { loadingCategories }
  } = useCategories();
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const { getProfilePath } = useProfilePath();
  const profilePath = `${getProfilePath(publicUrl, isCompany)}/about`;

  const handleAdd = categories => {
    onFieldChange('categories')(categories);
  };

  if (!JobCategories?.length && !hasEditPermission) {
    return null;
  }

  return (
    <Frame
      id="life-section"
      text={
        <>
          Life At&nbsp;
          <StyledLink to={profilePath} target="_blank">
            {name}
          </StyledLink>
        </>
      }
      overflow="visible"
      titlePB={24}
      color="#FF7CBB"
      noDataText="Describe the culture of your company. Tell everyone why it is a great place to work."
      canEdit={hasEditPermission}
      opens="job-life-modal"
      data={{ categories, selectedCategories: JobCategories, onAdd: handleAdd }}
      noIcon={!JobCategories.length}
      editMode={!JobCategories.length ? 'add' : 'edit'}
      filled={!!JobCategories.length}
    >
      {!JobCategories.length && (
        <Frame className="center" padding="16px" mt={24} borderRadius={12}>
          <ContainedButton
            icon="add"
            type="button"
            backgroundColor="rgba(204, 213, 255, 0.11)"
            opens="job-life-modal"
            data={{ categories, selectedCategories: JobCategories, onAdd: handleAdd }}
            disabled={loadingCategories}
          >
            Add Section
          </ContainedButton>
        </Frame>
      )}
      {!!JobCategories.length && <Life jobsPage categories={JobCategories} />}
    </Frame>
  );
}

export default JobLife;
