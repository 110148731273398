import React from 'react';
import { ProgressContent } from './ProgressButton.styled';
import CircularProgressBar from './CircularProgressBar.component';
import BigCheckmarkIcon from 'common/IconComponents/BigCheckmarkIcon';

export default function ProgressButton({ dataTestId, percentage, completed, onClick }) {
  return (
    <ProgressContent data-testid={dataTestId} onClick={onClick} isCompleted={completed}>
      {completed && <BigCheckmarkIcon width={36} height={36} />}
      {!completed && <CircularProgressBar size={44} strokeWidth={4} percentage={percentage} color="white" />}
    </ProgressContent>
  );
}
