import * as tagActionTypes from './tagActionTypes';

export const initializeTags = (type, tags) => ({
  type: tagActionTypes.INITIALIZE_TAGS,
  payload: {
    type,
    tags
  }
});

export const addTag = (type, tag) => ({
  type: tagActionTypes.ADD_TAG,
  payload: {
    type,
    tag
  }
});

export const setTagsLoading = type => ({
  type: tagActionTypes.SET_TAGS_LOADING,
  payload: {
    type
  }
});

export const setTagsLoaded = type => ({
  type: tagActionTypes.SET_TAGS_LOADED,
  payload: {
    type
  }
});

export const updateTag = tag => ({
  type: tagActionTypes.UPDATE_TAG,
  payload: tag
});

export const deleteTag = tagId => ({
  type: tagActionTypes.DELETE_TAG,
  payload: {
    tagId
  }
});

export const clearTags = () => ({
  type: tagActionTypes.CLEAR_TAGS
});
