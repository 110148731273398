import React, { useState } from 'react';
import useViewport from 'hooks/useViewport';

import { isJobRoleEmpty } from 'utils/isJobRoleEmpty';

import ContainedButton from 'common/ContainedButton';
import Spinner from 'common/Spinner';
import DropDown from 'common/Dropdown/Dropdown';
import DropDownItem from 'common/Dropdown/DropDownItem';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/auth';
import { profileSelectors } from 'store/profile';
import {
  Container,
  LoadingContainer,
  SidebarFooter,
  SidebarFooterTopButtons,
  SidebarItem,
  SidebarList
} from './styled.components';
import ShareURL from 'common/ShareURL';
import createSharingUrl from 'utils/create-sharing-url';
import { getDecodedIdToken } from 'utils/cookies';

const JobSidebar = ({
  alreadyApplied,
  alreadyContracted,
  categories,
  handleApplyClick,
  handleSaveClick,
  handleWithdrawClick,
  handleReview,
  isClosed = true,
  isContractActive = false,
  isSaved,
  job,
  loading,
  viewMode
}) => {
  const { isL, isM, isS, isXS } = useViewport();
  const [activeItem, setActiveItem] = useState(0);
  const isAuthed = useSelector(authSelectors.isAuthed);
  const isCompany = useSelector(profileSelectors.selectIsCompanyView);
  const name = useSelector(profileSelectors.selectProfileProperty('name'));
  const showSaveButton = !isXS && !isS && !alreadyApplied && !alreadyContracted;
  const saveButtonDisabled = loading.saveLoading || alreadyApplied || alreadyContracted || !isAuthed || isClosed;
  const showShareButon = alreadyApplied || alreadyContracted;

  const [shareURLVisible, setShareURLVisible] = useState(false);
  const decoded = getDecodedIdToken();
  const isSuperAdmin = decoded?.roles.includes('super-admin');

  return (
    <Container>
      {(isM || isL) && (
        <SidebarList>
          {(!viewMode || job?.overview) && (
            <SidebarItem
              href="#job_overview"
              className={`${activeItem === 0 && 'active'}`}
              onClick={() => setActiveItem(0)}
            >
              Job overview
            </SidebarItem>
          )}
          {(!viewMode || job?.projects.length > 0) && (
            <SidebarItem href="#project" className={`${activeItem === 1 && 'active'}`} onClick={() => setActiveItem(1)}>
              Project
            </SidebarItem>
          )}
          {(!viewMode || (job?.role && !isJobRoleEmpty(job.role))) && (
            <SidebarItem href="#role" className={`${activeItem === 2 && 'active'}`} onClick={() => setActiveItem(2)}>
              Role
            </SidebarItem>
          )}
          {isCompany && (!viewMode || categories.length > 0) && (
            <SidebarItem
              href="#life-section"
              className={`${activeItem === 3 && 'active'}`}
              onClick={() => setActiveItem(3)}
            >
              {name}
            </SidebarItem>
          )}
        </SidebarList>
      )}
      {viewMode && (
        <SidebarFooter>
          {loading && (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          )}
          {!loading && (
            <>
              <SidebarFooterTopButtons>
                {showSaveButton && (
                  <ContainedButton
                    className="save-btn"
                    padding="12px"
                    height="auto"
                    backgroundColor="rgba(204, 212, 255, 0.11)"
                    icon="bookmark"
                    mr={6}
                    onClick={() => {
                      handleSaveClick(isSaved ? 'remove' : 'save');
                    }}
                    disabled={saveButtonDisabled}
                  >
                    {isSaved ? 'UNSAVE' : 'SAVE'}
                  </ContainedButton>
                )}
                {showShareButon && (
                  <ContainedButton
                    className="save-btn"
                    padding="12px"
                    height="auto"
                    backgroundColor="rgba(204, 212, 255, 0.11)"
                    icon="bookmark"
                    mr={6}
                    disabled={!isSuperAdmin}
                    onClick={() => {
                      setShareURLVisible(true);
                    }}
                  >
                    Share
                  </ContainedButton>
                )}

                <DropDown className="secondary" padding="11px 14px" noRight>
                  {(isXS || isS) && (
                    <DropDownItem
                      icon="bookmark"
                      onClick={() => {
                        handleSaveClick(isSaved ? 'remove' : 'save');
                      }}
                      disabled={loading.saveLoading || alreadyApplied}
                    >
                      {isSaved ? 'Unsave' : 'Save'}
                    </DropDownItem>
                  )}
                  {!alreadyApplied && (
                    <DropDownItem icon="share" disabled={!isSuperAdmin} onClick={() => setShareURLVisible(true)}>
                      Share
                    </DropDownItem>
                  )}
                  <DropDownItem icon="refer" disabled>
                    Refer a person
                  </DropDownItem>
                </DropDown>
                {shareURLVisible && (
                  <ShareURL
                    styles={{ container: { top: 170 } }}
                    visible={shareURLVisible}
                    setVisible={setShareURLVisible}
                    url={createSharingUrl({ type: 'job', id: job._id })}
                    successText="The job link has been copied"
                  />
                )}
              </SidebarFooterTopButtons>
              {!isContractActive ? (
                <ContainedButton
                  className="apply-btn"
                  padding="12px"
                  height="auto"
                  onClick={alreadyApplied ? handleWithdrawClick : handleApplyClick}
                  text="apply now"
                  backgroundColor="#7266EE"
                  icon="info"
                  disabled={loading || !isAuthed || isClosed}
                >
                  {alreadyApplied || alreadyContracted ? 'WITHDRAW' : 'APPLY NOW'}
                </ContainedButton>
              ) : (
                <ContainedButton
                  className="apply-btn"
                  padding="12px"
                  height="auto"
                  onClick={handleReview}
                  text="review proposal"
                  backgroundColor="#7266EE"
                  icon="info"
                >
                  REVIEW PROPOSAL
                </ContainedButton>
              )}
            </>
          )}
        </SidebarFooter>
      )}
    </Container>
  );
};

export default JobSidebar;
