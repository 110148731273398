import React from 'react';
import ContainedButton from 'common/ContainedButton';
import Icon from 'common/Icon';

const UnlockButton = ({ mr, tooltip }) => (
  <ContainedButton backgroundColor="#1A1B1E" mr={mr} tooltip={tooltip} tooltipId={tooltip}>
    <Icon icon="unlock" height="16px" width="12px" />
  </ContainedButton>
);

export default UnlockButton;
