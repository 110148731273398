import React from 'react';

import TxplModal from 'common/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';

const ActivationLinkSentModal = ({ closePortal, name }) => (
  <TxplModal
    name={name}
    title="User activation"
    size="small"
    appElement={document.getElementById('root-modal')}
    renderFooter={<ContainedButton onClick={closePortal}>ok</ContainedButton>}
  >
    <Typography success variant="medium">
      The user activation link has been sent
    </Typography>
  </TxplModal>
);

export default withRenderPortal('activation-link-sent-modal')(ActivationLinkSentModal);
