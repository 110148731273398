import styled from '@emotion/styled';
import { backOfficeService } from 'api';
import ContainedButton from 'common/ContainedButton';
import FormInput from 'common/form/FormInput';
import Spinner from 'common/Spinner';
import TxplModal from 'common/TxplModal/TxplModal';
import withRenderPortal from 'hocs/withRenderPortal';
import useForm from 'hooks/useForm';
import get from 'lodash/fp/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from 'store/profile';
import { userActions, userSelectors } from 'store/user';
import { pageSize } from 'utils/devices';

const StyledContainedButton = styled(ContainedButton)`
  margin-left: 6px;
`;
const GridWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  @media ${pageSize.XS} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const UserInfoEditor = ({ closePortal, data: { showPublicUrl = true, talent, userId }, name }) => {
  const user = useSelector(userSelectors.selectUser);
  const dispatch = useDispatch();

  const publicUrlFiled = showPublicUrl
    ? {
        publicUrl: { initialValue: talent?.talentId ?? '', validate: ['isRequired'] }
      }
    : {};

  const fields = {
    ...publicUrlFiled,
    firstName: { initialValue: user.firstName ?? '', validate: ['isRequired'] },
    lastName: { initialValue: user.lastName ?? '', validate: ['isRequired'] },
    email: { initialValue: user.email ?? '', validate: ['isRequired'] }
  };

  const { errors, onFieldChange, onSubmit, submitting, values } = useForm({
    fields,
    callApi: formFields => {
      const data = {
        ...formFields,
        userId: user._id,
        talentId: talent?._id || talent?.profile
      };
      return backOfficeService.updateUserInfo(userId, data).then(response => {
        const responseStatus = get('data.status', response);
        if (responseStatus === 'OK') {
          const responseTalent = get('data.talent', response);

          dispatch(userActions.updateUserProperty({ property: 'firstName', value: formFields.firstName }));
          dispatch(userActions.updateUserProperty({ property: 'lastName', value: formFields.lastName }));
          dispatch(userActions.updateUserProperty({ property: 'email', value: formFields.email }));

          if (responseTalent) {
            if (formFields.publicUrl) {
              dispatch(
                profileActions.updateProfileProperty({
                  property: 'talentId',
                  value: formFields.publicUrl,
                  profileId: talent._id
                })
              );
            }

            dispatch(userActions.updateUserProperty({ property: 'accounts', value: [responseTalent] }));
          }

          closePortal();
        }
      });
    }
  });

  const handleSave = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <TxplModal
      title="Account info"
      name={name}
      appElement={document.getElementById('backoffice-modal-placeholder')}
      renderFooter={
        <>
          {submitting && <Spinner />}
          <StyledContainedButton
            onClick={closePortal}
            backgroundColor="rgba(204, 213, 255, 0.11)"
            disabled={submitting}
          >
            Cancel
          </StyledContainedButton>
          <StyledContainedButton form="userInfoModal" type="submit" backgroundColor="#7266EE" disabled={submitting}>
            Save
          </StyledContainedButton>
        </>
      }
    >
      <GridWrapper onSubmit={handleSave} id="userInfoModal">
        <FormInput label="User ID" disabled value={userId} />

        <FormInput
          label="First name"
          error={errors.firstName}
          value={values.firstName}
          autoFocus
          onChange={onFieldChange('firstName')}
        />

        <FormInput
          label="Last name"
          error={errors.lastName}
          value={values.lastName}
          onChange={onFieldChange('lastName')}
        />

        <FormInput label="Email" error={errors.email} value={values.email} onChange={onFieldChange('email')} />

        {showPublicUrl && (
          <FormInput
            label="Public url"
            error={errors.publicUrl}
            value={values.publicUrl}
            onChange={onFieldChange('publicUrl')}
          />
        )}
      </GridWrapper>
    </TxplModal>
  );
};

export default withRenderPortal('backOffice-user-info-modal')(UserInfoEditor);
