import styled from '@emotion/styled';
import DisplaySwitcher from 'common/DisplaySwitcher';
import { Card } from 'common/styles/Card';
import Flex from 'common/styles/Flex';
import BiasReducedModeSwitch from 'components/BiasReducedModeSwitch/BiasReducedModeSwitch';
import Typography from 'components/shared/Typography';
import useViewport from 'hooks/useViewport';
import SearchBox from 'pages/jobs/details/all-jobs/search-box/SearchBox';
import { SearchedBoxColors } from 'pages/jobs/details/all-jobs/search-box/styled.components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'store/auth';
import { profileActions, profileSelectors } from 'store/profile';

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
`;

function TalentsSearchHeader({ relatedSearch, talentsFound }) {
  const dispatch = useDispatch();
  const { isXS } = useViewport();
  const myProfileId = useSelector(profileSelectors.selectMyProfileId);
  const [biasReducedMode, setBiasReducedMode] = React.useState(
    !!useSelector(profileSelectors.selectMyProfileProperty('biasReducedMode'))
  );
  const switchBiasReducedMode = React.useCallback(() => setBiasReducedMode(latest => !latest), [setBiasReducedMode]);

  React.useEffect(() => {
    dispatch(
      authActions.updateCurrentUserProperty({ property: 'activeProfile.biasReducedMode', value: biasReducedMode })
    );
    dispatch(
      profileActions.updateProfileProperty({
        profileId: myProfileId,
        property: 'biasReducedMode',
        value: biasReducedMode,
        updateType: 'update'
      })
    );
  }, [biasReducedMode]);

  return (
    <Flex column gap={16}>
      <Flex justifySpaceBetween>
        <StyledTypography variant="medium" color="rgba(230, 234, 255, 0.6)">
          {process.env.REACT_APP_SHOULD_SHOW_TOTAL_COUNT === 'true' && (
            <div>
              {talentsFound} talent {!isXS && <>found</>}
            </div>
          )}
        </StyledTypography>
        <Flex alignCenter gap={16}>
          <BiasReducedModeSwitch checked={biasReducedMode} setChecked={switchBiasReducedMode} />
          {!isXS && <DisplaySwitcher order={['cards-list', 'list']} />}
        </Flex>
      </Flex>
      {!isXS && !!relatedSearch.length && (
        <Card column overflow="hidden">
          <Typography variant="h3" mb={16}>
            Recommended related requests
          </Typography>
          <Flex minWidth={1240}>
            {relatedSearch.map((search, key) => (
              <SearchBox
                key={key}
                icon="search"
                label={search}
                search={`search=${search}`}
                color={SearchedBoxColors[key % 4]}
              />
            ))}
          </Flex>
        </Card>
      )}
    </Flex>
  );
}

export default TalentsSearchHeader;
