import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { backOfficeActions } from 'store/backOffice';
import { Column, Table } from 'components/Table';
import { userActions } from 'store/user';

import useQuery from 'hooks/useQuery';
import ActivityStatus from './ActivityStatus';

const format = date => moment(date).format('MMM DD YYYY');

const BackOfficeUserStatusLog = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useQuery({
    url: `/backoffice/user/${params.userId}`,
    onSuccess: ({ user }) => {
      dispatch(userActions.initializeUser(user));

      dispatch(
        backOfficeActions.updateBackOfficeTitle([
          { label: 'OPERATIONS' },
          { label: 'Users', url: '/backoffice/operations/users' },
          { label: `${user.firstName} ${user.lastName}`, url: `/backoffice/operations/users/${user._id}` },
          { label: 'Activity Status Log' }
        ])
      );
    }
  });

  return (
    <div>
      <Table keyId="_id" endpoint={`/backoffice/accounts/${params.userId}/status-log`} dataKey="data">
        <Column
          title="CHANGED TO"
          keyName="status"
          render={statusLog => <ActivityStatus status={statusLog.status} />}
          width="108px"
        />
        <Column title="CHANGED ON" keyName="createdAt" render={statusLog => format(statusLog.createdAt)} />
        <Column
          title="CHANGED BY"
          keyName="actionUserId"
          render={statusLog => `${statusLog.actionUserId.firstName} ${statusLog.actionUserId.lastName}`}
        />
        <Column title="REASON" keyName="reason" />
        <Column title="ADDITIONAL COMMENTS" keyName="additionalComment" />
      </Table>
    </div>
  );
};

export default BackOfficeUserStatusLog;
