import React from 'react';

function ProfileVettedIcon({ width = '145', height = '120' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 145 120">
      <g fillRule="evenodd" clipRule="evenodd" filter="url(#filter0_ii_139_29976)">
        <path
          fill="url(#paint0_angular_139_29976)"
          d="M55.645 11.276a7.586 7.586 0 01-5.292 1.696l-15.08-.978a7.585 7.585 0 00-7.827 5.644L23.59 32.33a7.585 7.585 0 01-3.267 4.475L7.541 44.933a7.585 7.585 0 00-3.004 9.137l5.51 14.244a7.585 7.585 0 01-.016 5.515L4.448 88.017a7.585 7.585 0 002.93 9.141l12.791 8.297a7.585 7.585 0 013.22 4.478l3.797 14.793a7.587 7.587 0 007.757 5.688l15.124-.82a7.588 7.588 0 015.26 1.742l11.679 9.711a7.585 7.585 0 009.65.041l11.7-9.564a7.583 7.583 0 015.291-1.696l15.08.978a7.583 7.583 0 007.827-5.644l3.857-14.692a7.583 7.583 0 013.267-4.475l12.782-8.128a7.585 7.585 0 003.004-9.137l-5.511-14.244a7.589 7.589 0 01.016-5.515l5.584-14.188a7.585 7.585 0 00-2.93-9.141l-12.791-8.297a7.589 7.589 0 01-3.22-4.478l-3.797-14.793a7.585 7.585 0 00-7.757-5.688l-15.124.82a7.585 7.585 0 01-5.26-1.742L76.995 1.753a7.585 7.585 0 00-9.65-.04l-11.7 9.563z"
        ></path>
        <path
          fill="url(#paint1_radial_139_29976)"
          d="M55.645 11.276a7.586 7.586 0 01-5.292 1.696l-15.08-.978a7.585 7.585 0 00-7.827 5.644L23.59 32.33a7.585 7.585 0 01-3.267 4.475L7.541 44.933a7.585 7.585 0 00-3.004 9.137l5.51 14.244a7.585 7.585 0 01-.016 5.515L4.448 88.017a7.585 7.585 0 002.93 9.141l12.791 8.297a7.585 7.585 0 013.22 4.478l3.797 14.793a7.587 7.587 0 007.757 5.688l15.124-.82a7.588 7.588 0 015.26 1.742l11.679 9.711a7.585 7.585 0 009.65.041l11.7-9.564a7.583 7.583 0 015.291-1.696l15.08.978a7.583 7.583 0 007.827-5.644l3.857-14.692a7.583 7.583 0 013.267-4.475l12.782-8.128a7.585 7.585 0 003.004-9.137l-5.511-14.244a7.589 7.589 0 01.016-5.515l5.584-14.188a7.585 7.585 0 00-2.93-9.141l-12.791-8.297a7.589 7.589 0 01-3.22-4.478l-3.797-14.793a7.585 7.585 0 00-7.757-5.688l-15.124.82a7.585 7.585 0 01-5.26-1.742L76.995 1.753a7.585 7.585 0 00-9.65-.04l-11.7 9.563z"
          style={{ mixBlendMode: 'multiply' }}
        ></path>
      </g>
      <path
        fill="url(#paint2_linear_139_29976)"
        d="M19.054 100.548a5.855 5.855 0 10.077-11.71 5.855 5.855 0 00-.077 11.71z"
      ></path>
      <g filter="url(#filter1_bi_139_29976)">
        <path
          fill="#BFCAFF"
          fillOpacity="0.1"
          d="M.572 43.191a3.946 3.946 0 014.715-5.756c1.036.376 25.442 9.522 36.956 36.424a3.941 3.941 0 11-7.244 3.1C24.803 53.204 2.81 44.927 2.59 44.848A3.938 3.938 0 01.573 43.19z"
        ></path>
      </g>
      <g filter="url(#filter2_di_139_29976)">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12"
          d="M51.797 67.497l17.696 17.695 30.672-30.673"
        ></path>
      </g>
      <path
        fill="url(#paint3_linear_139_29976)"
        d="M19.695 18.052a7.8 7.8 0 10.105-15.6 7.8 7.8 0 00-.105 15.6z"
      ></path>
      <path fill="url(#paint4_linear_139_29976)" d="M82 35.627a4 4 0 10.054-8 4 4 0 00-.054 8z"></path>
      <path fill="url(#paint5_linear_139_29976)" d="M140.8 48.827a4 4 0 10.053-8.001 4 4 0 00-.053 8z"></path>
      <path fill="url(#paint6_linear_139_29976)" d="M108.4 102.827a4 4 0 10.053-8 4 4 0 00-.053 8z"></path>
      <g filter="url(#filter3_bi_139_29976)">
        <path
          fill="#BFCAFF"
          fillOpacity="0.1"
          d="M95.82 25.722a3.349 3.349 0 00-.069 2.585c1.058 2.755 2.676 4.88 4.677 6.137 2.118 1.332 4.564 1.618 6.72.792 3.785-1.448 6.326-6.303 3.844-12.776-.963-2.518-.436-3.896-.048-4.04.384-.15 1.697.52 2.664 3.036 1.058 2.756 2.676 4.88 4.677 6.141 2.119 1.331 4.566 1.621 6.721.792 3.783-1.455 6.325-6.307 3.842-12.772-.703-1.836-.578-2.935-.438-3.424.117-.39.278-.576.389-.62.385-.144 1.695.513 2.664 3.037a3.37 3.37 0 004.367 1.943 3.376 3.376 0 001.879-1.779 3.378 3.378 0 00.064-2.586c-2.477-6.467-7.609-8.382-11.397-6.929-3.789 1.457-6.325 6.307-3.846 12.78.706 1.835.575 2.926.435 3.417-.113.39-.276.58-.384.623-.387.148-1.696-.516-2.664-3.04-2.481-6.467-7.61-8.383-11.398-6.93-3.783 1.453-6.325 6.304-3.841 12.773.702 1.835.573 2.933.437 3.423-.119.396-.281.578-.388.62-.384.148-1.696-.516-2.664-3.036a3.376 3.376 0 00-3.066-2.17 3.38 3.38 0 00-3.178 2.003z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_ii_139_29976"
          width="136.161"
          height="142.8"
          x="3.92"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="3.6"></feOffset>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.54902 0 0 0 0 0.164706 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" mode="overlay" result="effect1_innerShadow_139_29976"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.2"></feOffset>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.831373 0 0 0 0 0.145098 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="effect1_innerShadow_139_29976" result="effect2_innerShadow_139_29976"></feBlend>
        </filter>
        <filter
          id="filter1_bi_139_29976"
          width="66.609"
          height="66.258"
          x="-12"
          y="25.2"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_139_29976"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_139_29976" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.2"></feOffset>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_139_29976"></feBlend>
        </filter>
        <filter
          id="filter2_di_139_29976"
          width="79.568"
          height="64.272"
          x="36.197"
          y="43.719"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="7.2"></feOffset>
          <feGaussianBlur stdDeviation="4.8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.14902 0 0 0 0 0.568627 0 0 0 0 0.227451 0 0 0 0.3 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" mode="multiply" result="effect1_dropShadow_139_29976"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_139_29976" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-4.8"></feOffset>
          <feGaussianBlur stdDeviation="3.6"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.0980392 0 0 0 0 0.113725 0 0 0 0.2 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_139_29976"></feBlend>
        </filter>
        <filter
          id="filter3_bi_139_29976"
          width="66.476"
          height="54.9"
          x="83.524"
          y="-7.2"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_139_29976"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_139_29976" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.2"></feOffset>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0.74 0 0 0 0 0.783333 0 0 0 0 1 0 0 0 0.2 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_139_29976"></feBlend>
        </filter>
        <radialGradient
          id="paint0_angular_139_29976"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(72.453 -19.023 93.43) scale(57.5779 60.1715)"
          gradientUnits="userSpaceOnUse"
        >
          {/* <stop offset="0.132" stopColor="#109729"></stop> */}
          {/* <stop offset="0.375" stopColor="#00D624"></stop> */}
          {/* <stop offset="0.498" stopColor="#03CB25"></stop> */}
          <stop offset="0.622" stopColor="#129229"></stop>
          <stop offset="0.883" stopColor="#19752B"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_139_29976"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-37.37758 -118.20835 117.63912 -37.1976 89.891 127.98)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11B82E" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#00A61D"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear_139_29976"
          x1="19.704"
          x2="19.666"
          y1="100.552"
          y2="88.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C1D44"></stop>
          <stop offset="1" stopColor="#B800BC"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_139_29976"
          x1="20.562"
          x2="20.511"
          y1="18.058"
          y2="2.456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C1D44"></stop>
          <stop offset="1" stopColor="#B800BC"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_139_29976"
          x1="82.444"
          x2="82.418"
          y1="35.63"
          y2="27.629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C1D44"></stop>
          <stop offset="1" stopColor="#B800BC"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_139_29976"
          x1="141.245"
          x2="141.219"
          y1="48.83"
          y2="40.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C1D44"></stop>
          <stop offset="1" stopColor="#B800BC"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_139_29976"
          x1="108.845"
          x2="108.819"
          y1="102.83"
          y2="94.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C1D44"></stop>
          <stop offset="1" stopColor="#B800BC"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProfileVettedIcon;
