import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';

const StyledPageLoader = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => (props.height ? `height: ${props.height};` : 'height: calc(100vh - 100px);')}
    ${props => props.width && `width: ${props.width};`}
`;

const PageLoader = ({ height, margin, message, width }) => (
  <StyledPageLoader height={height} width={width} margin={margin}>
    <Icon icon="loader" size="40" spin />
    {message}
  </StyledPageLoader>
);

export default PageLoader;
