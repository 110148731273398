import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from 'store/portals';
import { currentUserPropertySelector } from 'store/selectors/currentUser';
import { cookieAuthAsName, getCookie } from 'utils/cookies';

function AutoCheckTermsService() {
  const dispatch = useDispatch();
  const settings = useSelector(currentUserPropertySelector('settings'));
  const loginAs = getCookie(cookieAuthAsName);

  const shouldShowTermsServiceModal =
    settings?.termsOfService === false && !loginAs && !['/app/login', '/app/logout'].includes(window.location.pathname);

  useEffect(() => {
    if (shouldShowTermsServiceModal) {
      const timeout = setTimeout(
        () =>
          dispatch(
            portalActions.openPortal({
              name: 'terms-of-service-modal'
            })
          ),
        500
      );
      return () => clearTimeout(timeout);
    }
  }, [window.location.pathname, dispatch, shouldShowTermsServiceModal]);

  return null;
}

export default AutoCheckTermsService;
